import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Box, Button, useTheme, Paper, Container, makeStyles } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import { subDays, subMonths, addDays, addWeeks, addMonths, subWeeks } from "date-fns";
import clsx from "clsx";
import { BookingByWeek, BookingByDay, BookingByMonth } from "./components";
import { Processing, BglobalJSC } from "components";
import { AppConstant } from "const";
import PlaceCategoryAction from "redux/place-category.redux";
import MnReservationHeader, { createReservationAction } from "./components/MnReservationHeader";

const MnReservation = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [newReservation, isPostSuccessReservationListStatus, updateReservationSuccess, isFetching] = useSelector(
    ({ reservationRedux }) => [
      reservationRedux.newReservation,
      reservationRedux.isPostSuccessReservationListStatus,
      reservationRedux.updateReservationSuccess,
      reservationRedux.isFetchingGetReservation,
    ],
    shallowEqual,
  );

  const isUpdateStatusSuccess = useSelector(
    ({ reservationSettingRedux }) => reservationSettingRedux.isUpdateStatusSuccess,
  );
  const placeArray = useSelector(({ placeCategoryRedux }) => placeCategoryRedux.placeArray, shallowEqual);
  const isSuccessPostWaitingConsumer = useSelector(
    ({ waitingConsumerRedux }) => waitingConsumerRedux.isPostSuccess,
    shallowEqual,
  );

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [optionView, setOptionView] = useState(0);

  const getReservation = () => {
    let getReservationAction = createReservationAction(optionView);
    dispatch(getReservationAction());
  };

  const onPrevious = () => {
    // Go to pass date when user does not have scroll
    // Else go to previous column
    if (isNoScroll()) {
      let newSelectedDate = new Date();
      switch (optionView) {
        case AppConstant.ReservationViewBy.day: // Case display by day
          newSelectedDate = subDays(selectedDate, 1);
          break;
        case AppConstant.ReservationViewBy.week: // Case display by week
          newSelectedDate = subWeeks(selectedDate, 1);
          break;
        case AppConstant.ReservationViewBy.month: // Case display by month
          newSelectedDate = subMonths(selectedDate, 1);
          break;

        default:
          break;
      }
      setSelectedDate(newSelectedDate, optionView);
      controlScroll();
    } else {
      controlScroll(-1);
    }
  };

  const onNext = () => {
    // Go to next date when user scroll to end reservation
    // Else go to next column
    if (isEndScroll()) {
      let newSelectedDate =
        optionView === 0
          ? addDays(selectedDate, 1)
          : optionView === 1
          ? addWeeks(selectedDate, 1)
          : addMonths(selectedDate, 1);
      setSelectedDate(newSelectedDate);
      // Reset scroll
      controlScroll();
    } else {
      controlScroll(1);
    }
  };

  useEffect(() => {
    let isCallApi = Boolean(
      isUpdateStatusSuccess ||
        isPostSuccessReservationListStatus ||
        isSuccessPostWaitingConsumer ||
        updateReservationSuccess,
    );
    if (isCallApi) {
      getReservation();
    }
  }, [
    isUpdateStatusSuccess,
    isPostSuccessReservationListStatus,
    isSuccessPostWaitingConsumer,
    updateReservationSuccess,
    newReservation,
  ]);

  useEffect(() => {
    if (!placeArray.length) {
      dispatch(PlaceCategoryAction.getPlaceCategory({}));
    }
  }, []);

  return (
    <Box className="hidden-scroll">
      <Paper elevation={1} square className="mn-reservation-root">
        <Container maxWidth="xl" className={clsx("container", classes.container)} id="container">
          <MnReservationHeader onChangeTab={setOptionView} date={selectedDate} />
          <Box className={classes.boxBodyCenter}>
            <Button
              variant="contained"
              classes={{ root: "button-left", startIcon: "start-icon" }}
              startIcon={<ChevronLeft style={{ color: theme.palette.white, fontSize: 40 }} />}
              onClick={onPrevious}
            />
            <Button
              variant="contained"
              classes={{ root: "button-right", startIcon: "start-icon" }}
              startIcon={<ChevronRight style={{ color: theme.palette.white, fontSize: 40 }} />}
              onClick={onNext}
            />
            <Box classes={{ root: "box-wrap-calendar" }}>
              {optionView !== AppConstant.ReservationViewBy.month && <Box className="footer-mobile" />}
              {optionView === AppConstant.ReservationViewBy.day && (
                <BookingByDay selectedDate={selectedDate} optionView={optionView} />
              )}
              {optionView === AppConstant.ReservationViewBy.week && (
                <BookingByWeek selectedDate={selectedDate} optionView={optionView} />
              )}
              {optionView === AppConstant.ReservationViewBy.month && (
                <BookingByMonth selectedDate={selectedDate} optionView={optionView} />
              )}
            </Box>
          </Box>
        </Container>
      </Paper>
      <BglobalJSC className={classes.logoFooter} />
      <Processing isShow={isFetching} />
    </Box>
  );
};

MnReservation.prototype = {};
MnReservation.defaultProps = {};

export default memo(MnReservation);

export const HTML_CONTAINER_ID = "reserveRoot";
export const RESERVATION_BOX_ID = "reservationBox";
export const WIDTH_COLUMN = 175;
export const getContainerEl = () => document.querySelector("#" + RESERVATION_BOX_ID);

export const isEndScroll = () => {
  let scrollEl = getContainerEl();
  return scrollEl && scrollEl.scrollLeft + scrollEl.clientWidth >= scrollEl.scrollWidth;
};

export const isNoScroll = () => {
  let scrollEl = getContainerEl();
  return scrollEl && 0 === scrollEl.scrollLeft;
};

export const controlScroll = nextStep => {
  let scrollEl = getContainerEl();
  // Detect not exist
  if (!scrollEl) return;

  if (nextStep) {
    let nextColumn = Math.floor(scrollEl.scrollLeft / WIDTH_COLUMN) + nextStep;
    scrollEl.scrollLeft = nextColumn * WIDTH_COLUMN;
  } else {
    scrollEl.scrollLeft = 0;
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  logoFooter: {
    marginTop: 20,
    marginBottom: 30,
    minHeight: 0,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 100,
    },
  },
  boxBodyCenter: {
    position: "relative",
    paddingLeft: 60,
    paddingRight: 60,
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));
