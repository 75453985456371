import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import { getCommonKey } from "utils";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";

const EditModeDialogActions = ({ onCancel, onSave, isDisableSaveButton }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  return (
    <>
      <Button onClick={onCancel} classes={{ root: classes.buttonCancelFooter, label: classes.labelFooter }}>
        {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
      </Button>
      <Button
        onClick={onSave}
        classes={{
          root: classes.buttonRootFooter,
          label: classes.labelFooter,
        }}
        disabled={isDisableSaveButton}
      >
        {getLabel(getCommonKey(LangConstant.TXT_SAVE_BUTTON))}
      </Button>
    </>
  );
};

EditModeDialogActions.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,

  isDisableSaveButton: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
  buttonRootFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.8,
      color: "unset",
    },
  },

  buttonCancelFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.black,
  },

  labelFooter: {
    width: "100%",
    height: 24,
    marginTop: 3,
    marginBottom: 3,
  },
}));

export default EditModeDialogActions;
