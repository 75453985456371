import { call, put } from "redux-saga/effects";
import { deepCloneJsonObject, toCamel, toSnake } from "utils";
import { ApiConstant, AppConstant, SystemConstant } from "const";
import ReservationAction from "redux/reservation-setting.redux";
import { ReservationSettingService } from "services";

export function* requestGetSetting(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationSettingService.getSetting, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data).map(item =>
        item.type === AppConstant.RESERVATION_SETTING_OPTION.order
          ? {
              ...formatPublishStatus(item, false),
            }
          : item,
      );

      yield put(ReservationAction.getReservationSettingSuccess(responseData));
    } else {
      yield put(ReservationAction.getReservationSettingFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationSettingFailure(error));
  }
}

export function* requestGetNotification(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationSettingService.getNotification, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(ReservationAction.getReservationNotificationSuccess(responseData));
    } else {
      yield put(ReservationAction.getReservationNotificationFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationNotificationFailure(error));
  }
}

export function* requestUpdateSetting(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationSettingService.updateSetting, [toSnake(formatPublishStatus(data[0], true))]);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.updateReservationSettingSuccess(data));
    } else {
      yield put(ReservationAction.updateReservationSettingFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.updateReservationSettingFailure(error));
  }
}

export function* requestUpdateStatus(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationSettingService.updateStatus, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.updateReservationStatusSuccess(data));
    } else {
      yield put(ReservationAction.updateReservationStatusFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.updateReservationStatusFailure(error));
  }
}

export function* requestSeenNotification(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationSettingService.updateSeenNotification, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.putSeenNotificationSuccess(data));
    } else {
      yield put(ReservationAction.putSeenNotificationFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.putSeenNotificationFailure(error));
  }
}

const formatPublishStatus = (data, isBooleanToNumber) => {
  if (!data) return {};

  let newData = deepCloneJsonObject(data);
  let newDataDetails = newData.details;

  let depositInfoArray = [
    {
      type: SystemConstant.PAYMENT_METHOD.cash,
      publish: convertBooleanType(
        newDataDetails.depositInformation?.details?.find(item => item.type === SystemConstant.PAYMENT_METHOD.cash)
          ?.publish,
        isBooleanToNumber,
      ),
    },
    {
      type: SystemConstant.PAYMENT_METHOD.internetBanking,
      publish: convertBooleanType(
        newDataDetails.depositInformation?.details?.find(
          item => item.type === SystemConstant.PAYMENT_METHOD.internetBanking,
        )?.publish,
        isBooleanToNumber,
      ),
      transactionInformation:
        newDataDetails.depositInformation?.details?.find(
          item => item.type === SystemConstant.PAYMENT_METHOD.internetBanking,
        )?.transactionInformation || [],
    },
  ];

  newData = {
    details: {
      depositInformation: {
        publish: convertBooleanType(newDataDetails.depositInformation?.publish, isBooleanToNumber),
        details: depositInfoArray,
      },
      shippingInformation: {
        publish: convertBooleanType(newDataDetails.shippingInformation?.publish, isBooleanToNumber),
        takeAway: convertBooleanType(newDataDetails.shippingInformation?.takeAway, isBooleanToNumber),
        shipping: convertBooleanType(newDataDetails.shippingInformation?.shipping, isBooleanToNumber),
        content: newDataDetails.shippingInformation?.content || "",
      },
      publish: convertBooleanType(newDataDetails.shippingInformation?.publish, isBooleanToNumber),
    },
    uid: data.uid,
    type: data.type,
    publish: convertBooleanType(
      data.details.depositInformation?.publish || data.details.shippingInformation?.publish,
      isBooleanToNumber,
    ),
  };

  return newData;
};

const convertBooleanType = (data, isBooleanToNumber) => {
  return isBooleanToNumber ? (data ? 1 : 0) : Boolean(data);
};
