import React, { memo, useState, useRef, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import * as XLSX from "xlsx";
import { Alert } from "components";
import { getCommonKey, textNormalize } from "utils";

const ImportExcelFileDialog = ({ open, onClose, onSubmit }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_CONSUMER);
  const inputElementRef = useRef();
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isWrongFormat, setIsWrongFormat] = useState(false);

  const onClick = () => {
    inputElementRef.current.click();
  };

  const onUploadFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      let uploadFile = event.target.files[0];

      let isExcelFile = AppConstant.MIME_EXCEL_TYPE.includes(uploadFile.type);
      if (isExcelFile) {
        setFileName(uploadFile.name);
        const reader = new FileReader();
        reader.onload = event => {
          // Parse data
          const dataFile = event.target.result;
          const fileXcel = XLSX.read(dataFile, { type: "binary", dateNF: AppConstant.FM_DD_MM_YYYY, cellDates: true });
          // Get first worksheet
          const name = fileXcel.SheetNames[0];
          const ws = fileXcel.Sheets[name];
          // Convert array of arrays
          const data = XLSX.utils.sheet_to_csv(ws, {
            header: 1,
            blankrows: false,
          });
          let dataConvert = convertExcel(data, getLabel);
          setSelectedFile(dataConvert);
        };
        reader.readAsBinaryString(uploadFile);
      } else setIsWrongFormat(true);
    }

    event.persist();
  };

  const onContinue = () => {
    onSubmit(selectedFile);
  };

  useEffect(() => {
    setFileName("");
  }, [open]);

  let displayFileName = fileName || getLabel(LangConstant.TXT_NO_FILE_SELECTED);

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.titleRoot }}>
        <Typography color="inherit" variant="h6" component="p">
          {getLabel(LangConstant.TXT_EXCEL_IMPORT_TITLE)}
        </Typography>

        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box className={classes.importBox}>
          <Typography className={classes.selectFileText}>{getLabel(LangConstant.TXT_SELECT_FILE)}</Typography>
          <Button variant="outlined" className={classes.importBtn} onClick={onClick}>
            {getLabel(LangConstant.TXT_SELECT_FILE)}
          </Button>
          <Typography className={clsx("medium-sm-txt", "ellipsis")}>{displayFileName}</Typography>
        </Box>
        <Button href={LINK_EXCEL} target="_blank" className={clsx("medium-md-txt", classes.sampleFileText)}>
          {getLabel(LangConstant.TXT_SAMPLE_FILE_DOWNLOAD)}
        </Button>
      </DialogContent>
      <DialogActions classes={{ root: classes.rootActions, spacing: classes.actionSpacing }}>
        <Button
          onClick={onContinue}
          classes={{
            root: classes.button,
            contained: classes.contained,
            disabled: classes.disabled,
          }}
          color="primary"
          size="small"
          variant="contained"
          disabled={!fileName}
        >
          {getLabel(LangConstant.TXT_BTN_CONTINUE)}
        </Button>
      </DialogActions>
      <input
        ref={inputElementRef}
        onChange={onUploadFile}
        id="uploadFile"
        type="file"
        style={{ visibility: "hidden" }}
        accept={AppConstant.ACCEPT_EXCEL}
        multiple={false}
      />
      <Alert
        isShow={isWrongFormat}
        onClose={() => setIsWrongFormat(false)}
        type="error"
        message={getLabel(LangConstant.TXT_WRONG_FORMAT_FILE_UPLOAD)}
      />
    </Dialog>
  );
};

export default memo(ImportExcelFileDialog);

const LINK_EXCEL = "https://docs.google.com/spreadsheets/d/1pS-WGekpPiUuiAC9wL2jlYdakB1KcScZ/export?format=xlsx";

const convertExcel = (data, getLabel) => {
  let lines = data.split("\n");
  let result = [];
  let headers = ["name", "phoneNumber", "email", "birthday", "gender"];

  lines.forEach((item, index) => {
    if (index > 0 && index < lines.length + 1) {
      let obj = {};
      let currentLine = item.replace(/,,/g, "").split(",");
      headers.forEach((itemHeader, indexHeader) => {
        obj[itemHeader] = currentLine[indexHeader];
      });
      if (obj.name !== "" && Boolean(obj.email || obj.phoneNumber || obj.gender || obj.birthday)) {
        result.push(obj);
      }
    }
  });
  result = refactorData(result, getLabel);

  return result;
};

const refactorData = (data, getLabel) => {
  let result = data.map(item => {
    return {
      ...item,
      gender: Boolean(item.gender) ? Number(getKeyByValue(textNormalize(item.gender), getLabel)) : "",
    };
  });

  return result;
};

const getKeyByValue = (value, getLabel) => {
  return Object.keys(LangConstant.OBJ_GENDER).find(
    key => textNormalize(getLabel(getCommonKey(LangConstant.OBJ_GENDER[key]))) === value,
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "0 20px",
    boxShadow: "none",
    borderRadius: 5,
    maxWidth: 500,
    minHeight: 200,
    minWidth: 448,
  },

  titleRoot: {
    backgroundColor: theme.palette.secondary.dark,

    "&>*": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: theme.palette.white,
    },
  },

  closeButton: {
    color: theme.palette.white,
  },

  content: {
    padding: "24px 24px",
    borderTop: "none",
    lineHeight: "22px",
  },
  importBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
    "&>*": {
      color: "#3E4045",
    },
  },
  importBtn: {
    width: 120,
    height: 30,
    padding: 0,
    borderRadius: 0,
    marginRight: 21,
    textTransform: "capitalize",
    "&>*": {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.grey[500],
    },
  },
  importTextBox: {
    display: "flex",
    width: 120,
    height: 30,
    border: "1px solid #D4D5D8",
    marginRight: 21,
    alignItems: "center",
    justifyContent: "center",
  },
  selectFileText: {
    marginRight: 34,
    fontSize: 13,
    fontWeight: 600,
  },

  sampleFileText: {
    color: theme.palette.text.link,
    marginLeft: 91,
    textTransform: "none",
  },

  rootActions: {
    height: 60,
    padding: "8px 30px",
  },
  actionSpacing: {
    "&>*:not(:first-child)": {
      marginLeft: 16,
    },
  },
  selectionBox: {
    marginTop: 16,
  },
  button: {
    minWidth: 90,
    borderRadius: 0,
    color: theme.palette.white,
    textTransform: "capitalize",
    "&:hover": {
      opacity: 1,
    },
  },
  contained: {
    "&$disabled": {
      backgroundColor: "#EF5845",
      opacity: 0.6,
      color: theme.palette.white,
    },
  },
  disabled: {
    backgroundColor: "#EF5845",
    opacity: 0.6,
  },
}));
