/**
 * Saga index: connects action type and saga
 */

import { takeLatest, all } from "redux-saga/effects";

/* ------------- Types ------------- */
import { AuthTypes } from "../redux/auth.redux";
import { ReportBookingTypes } from "../redux/report-booking.redux";
import { ReportConsumerTypes } from "../redux/report-consumer.redux";
import { ReportStaffTypes } from "../redux/report-staff.redux";
import { ShopInfoTypes } from "../redux/shop-info.redux";
import { PlaceCategoryTypes } from "../redux/place-category.redux";
import { PlaceItemTypes } from "../redux/place-item.redux";
import { ReservationTypes } from "../redux/reservation.redux";
import { ReservationHistoryTypes } from "../redux/reservation-history.redux";
import { ServiceCategoryTypes } from "../redux/service-category.redux";
import { ConsumerTypes } from "../redux/consumer.redux";
import { ShopTypes } from "../redux/shop.redux";
import { UserTypes } from "../redux/user.redux";
import { ReservationSettingTypes } from "../redux/reservation-setting.redux";
import { StaffTypes } from "../redux/staff.redux";
import { MnNewsTypes } from "../redux/mn-news.redux";
import { ReportDashboardTypes } from "../redux/report-dashboard.redux";
import { ReservationActivityTypes } from "../redux/reservation-activity.redux";
import { ReservationNewTypes } from "../redux/reservation-new.redux";
import { ComboItemTypes } from "redux/combo-item.redux";
import { ServiceItemTypes } from "redux/service-item.redux";
import { ItemDetailsTypes } from "redux/item-details.redux";
import { ItemDetailsContentTypes } from "redux/item-details-content.redux";
import { ProductCategoryTypes } from "redux/mn-product.redux";
import { MenuTypes } from "redux/menu.redux";
import { WaitingConsumerTypes } from "redux/waiting-consumer.redux";
import { PaymentTypes } from "redux/payment.redux";
import { RatingTypes } from "redux/rating.redux";
import { BillTypes } from "redux/bill.redux";
import { GroupAdminTypes } from "redux/group-admin.redux";
import { UploadFileTypes } from "redux/upload-file.redux";

/* ------------- Sagas ------------- */
import { requestLogin, requestPassword } from "./auth.saga";
import { reportBookingDay, reportBookingWeek, reportBookingMonth } from "./report-booking.saga";
import { reportConsumer } from "./report-consumer.saga";
import { requestGetInfo, requestUpdateInfo, requestUploadInfo, requestFbPage, requestPutFbPage } from "./shop.saga";

import {
  requestGetUserInfo,
  requestGetUserShopInfo,
  requestUpdateUserInfo,
  requestUpdateUserPassword,
  requestUploadInfo as requestUploadUserInfo,
} from "./user.saga";
import {
  requestGetSetting,
  requestUpdateSetting,
  requestGetNotification,
  requestUpdateStatus,
  requestSeenNotification,
} from "./reservation-setting.saga";
import { getShopInfoData, updateShopInfoData } from "./shop-info.saga";
import {
  getPlaceCategory,
  createPlaceCategory,
  createPlaceCategoryItem,
  removePlaceCategory,
  updatePlaceCategory,
  getPlaceDialogData,
} from "./place-category.saga";
import {
  getPlaceItem,
  createPlaceItem,
  patchPlaceItem,
  updatePlaceItem,
  removePlaceItem,
  getPlaceBusyStatus,
  getPlaceItemAutoFill,
} from "./place-item.saga";
import {
  getServiceCategory,
  createServiceCategory,
  updateServiceCategory,
  removeServiceCategory,
} from "./service-category.saga";
import {
  getReservationDay,
  getReservationWeek,
  getReservationMonth,
  postReservation,
  getReservationSettingOption,
  putReservationStatus,
  putReservationDetails,
  getReservationPeriodic,
  getReservation,
  postReservationExtend,
  postReservationListStatus,
  getAvailableStatus,
  getDisplaySetting,
  updateDisplaySetting,
  createRemoteOrder,
  getReservationType,
  putRemoteOrderDetails,
} from "./reservation.saga";
import { getReportStaff } from "./report-staff.saga";

import {
  getStaffTable,
  getWorkingShift,
  getStaffPosition,
  patchInvitationStaff,
  postStaffWorkingShift,
  postStaffPosition,
  postCreateStaff,
  putEditStaff,
  getWorkingShifts,
  getStaff,
  getPosition,
  getWorkingPotision,
  getWorking,
  removePostWorking,
  removeStaffPosition,
  updateStaffPosition,
  updateWorkingShift,
  deleteStaff,
  getStaffBusyStatus,
} from "./staff.saga";
import {
  getConsumer,
  patchInvitationConsumer,
  getEmailConsumerQuantity,
  getInvitationFacebook,
  patchInvitationFacebook,
  getNumberBookingByPhoneNumber,
  postConsumerList,
} from "./consumer.saga";

import {
  requestGetNews,
  requestUpdateNews,
  requestRemoveNews,
  requestCreateNews,
  requestUploadPostImage,
} from "./mn-news.saga";

import {
  getProductCategory,
  createProductCategory,
  updateProductCategory,
  removeProductCategory,
  getProductItem,
  createProductItem,
  updateProductItem,
  removeProductItem,
  getDetails,
  getDetailsUid,
  createDetails,
  updateDetails,
  updateDetailsEditContent,
  removeDetails,
  getDetailsContent,
  getDetailsContentUid,
  createDetailsContent,
  updateDetailsContent,
  removeDetailsContent,
  getProductItemUidCategory,
} from "./mn-product.saga";

import { getMenu, postMenu } from "./menu.saga";

import { getReservationActivity } from "./reservation-activity.saga";

import { getReservationNewBooking, getReservationNewOrder } from "./reservation-new.saga";

import { raiseError, resetError } from "./error.saga";
import {
  requestReportSummaryCustomer,
  requestCreateDisplaySetting,
  requestReportSummaryReservation,
  requestReportSummaryPlace,
  requestReportSummaryService,
} from "./report-dashboard.saga";
import { postComboItem, putComboItem, getComboItemByUid, getListComboItem, deleteComboItem } from "./combo-item.saga";
import {
  postServiceItem,
  putServiceItem,
  getServiceItemByUid,
  getServiceItemByCategoryUid,
  getListServiceItem,
  deleteServiceItem,
} from "./service-item.saga";
import {
  postItemDetails,
  putItemDetails,
  getItemDetailsByUid,
  getListItemDetails,
  deleteItemDetails,
  putEditContent,
} from "./item-details.saga";
import {
  postItemDetailsContent,
  putItemDetailsContent,
  getItemDetailsContentByUid,
  getListItemDetailsContent,
  deleteItemDetailsContent,
} from "./item-details-content.saga";
import {
  getWaitingConsumer,
  postWaitingConsumer,
  putWaitingConsumer,
  deleteWaitingConsumer,
} from "./waiting-consumer.saga";
import { getBookingHistory, batchUpdateStatus } from "./reservation-history.saga";
import { getCheckPayment, getPackageCurrent, getPackage, getPaymentHistory, getPaymentDetails } from "./payment.saga";
import { getRatingItem, getRatingItemSummary, getRatingShop, getRatingShopSummary, reportRating } from "./rating.saga";
import {
  createBill,
  updateBill,
  patchUpdateBillStatus,
  getBillList,
  deleteBill,
  getReservationBillDetail,
} from "./bill.saga";

import { getGroupAdmin, joinGroupAdmin } from "./group-admin.saga";

/* ------------- Connect Types To Sagas ------------- */
export default function* root() {
  yield all([
    //authentication
    takeLatest(AuthTypes.REQUEST_LOGIN, requestLogin),
    takeLatest(AuthTypes.REQUEST_PASSWORD, requestPassword),

    // get report booking by day
    takeLatest(ReportBookingTypes.REQUEST_REPORT_BOOKING_DAY, reportBookingDay),

    // get report booking by week
    takeLatest(ReportBookingTypes.REQUEST_REPORT_BOOKING_WEEK, reportBookingWeek),

    // get report booking by month
    takeLatest(ReportBookingTypes.REQUEST_REPORT_BOOKING_MONTH, reportBookingMonth),

    // get report consumer
    takeLatest(ReportConsumerTypes.REQUEST_REPORT_CONSUMER, reportConsumer),

    // update shop info
    takeLatest(ShopTypes.REQUEST_UPDATE_SHOP_INFO, requestUpdateInfo),

    // upload shop info
    takeLatest(ShopTypes.REQUEST_UPLOAD_SHOP_IMAGE, requestUploadInfo),

    // get user info
    takeLatest(UserTypes.REQUEST_GET_USER_INFO, requestGetUserInfo),

    // get shop user info
    takeLatest(UserTypes.REQUEST_GET_USER_SHOP_INFO, requestGetUserShopInfo),

    //update user info
    takeLatest(UserTypes.REQUEST_UPDATE_USER_INFO, requestUpdateUserInfo),

    // update user pass
    takeLatest(UserTypes.REQUEST_UPDATE_USER_PASSWORD, requestUpdateUserPassword),

    // update user pass
    takeLatest(UserTypes.REQUEST_UPLOAD_USER_IMAGE, requestUploadUserInfo),

    //get reservation info
    takeLatest(ReservationSettingTypes.REQUEST_GET_RESERVATION_SETTING, requestGetSetting),

    //update reservation info
    takeLatest(ReservationSettingTypes.REQUEST_UPDATE_RESERVATION_SETTING, requestUpdateSetting),

    //get reservation info
    takeLatest(ReservationSettingTypes.REQUEST_GET_RESERVATION_NOTIFICATION, requestGetNotification),

    //update reservation status
    takeLatest(ReservationSettingTypes.REQUEST_UPDATE_RESERVATION_STATUS, requestUpdateStatus),

    // get shop info
    takeLatest(ShopTypes.REQUEST_GET_SHOP_INFO, requestGetInfo),
    //News
    takeLatest(MnNewsTypes.GET_NEWS, requestGetNews),
    takeLatest(MnNewsTypes.CREATE_NEWS, requestCreateNews),
    takeLatest(MnNewsTypes.UPDATE_NEWS, requestUpdateNews),
    takeLatest(MnNewsTypes.REMOVE_NEWS, requestRemoveNews),
    takeLatest(MnNewsTypes.REQUEST_UPLOAD_POST_IMAGE, requestUploadPostImage),
    takeLatest(ShopTypes.REQUEST_GET_FACEBOOK_PAGE, requestFbPage),
    takeLatest(ShopTypes.REQUEST_PUT_FACEBOOK_LINK, requestPutFbPage),

    // post working shift
    takeLatest(StaffTypes.REQUEST_POST_WORKING_SHIFT, postStaffWorkingShift),
    // get working shift
    takeLatest(StaffTypes.GET_WORKING, getWorking),
    // remove working shift
    takeLatest(StaffTypes.REMOVE_POST_WORKING, removePostWorking),
    // remove staffposition
    takeLatest(StaffTypes.REMOVE_STAFF_POSITION, removeStaffPosition),
    //update staffpotion
    takeLatest(StaffTypes.UPDATE_STAFF_POSITION, updateStaffPosition),
    //update working shift
    takeLatest(StaffTypes.UPDATE_WORKING_SHIFT, updateWorkingShift),

    takeLatest(StaffTypes.GET_WORKING_POTISION, getWorkingPotision),
    // post position
    takeLatest(StaffTypes.REQUEST_POST_POSITION, postStaffPosition),

    // create staff
    takeLatest(StaffTypes.REQUEST_CREATE_STAFF, postCreateStaff),
    // edit staff
    takeLatest(StaffTypes.REQUEST_EDIT_STAFF, putEditStaff),
    //get workingshift
    takeLatest(StaffTypes.REQUEST_WORKING_SHIFTS, getWorkingShifts),
    //get staff
    takeLatest(StaffTypes.REQUEST_STAFF, getStaff),
    //get position
    takeLatest(StaffTypes.REQUEST_POSITION, getPosition),
    //delete staff
    takeLatest(StaffTypes.DELETE_STAFF, deleteStaff),
    //check staff busy status
    takeLatest(StaffTypes.GET_STAFF_BUSY_STATUS, getStaffBusyStatus),

    // get consumer
    takeLatest(ConsumerTypes.REQUEST_GET_CONSUMER, getConsumer),
    //patch invitation consumer
    takeLatest(ConsumerTypes.PATCH_INVITATION_CONSUMER, patchInvitationConsumer),
    // get email consumer quantity
    takeLatest(ConsumerTypes.REQUEST_GET_EMAIL_QUANTITY, getEmailConsumerQuantity),
    // get invitation facebook
    takeLatest(ConsumerTypes.REQUEST_GET_INVITATION_FACEBOOK, getInvitationFacebook),
    // patch invitation facebook
    takeLatest(ConsumerTypes.PATCH_INVITATION_FACEBOOK, patchInvitationFacebook),

    // post seen reservation
    takeLatest(ReservationSettingTypes.REQUEST_SEEN_NOTIFICATION, requestSeenNotification),
    //get number booking by phone number
    takeLatest(ConsumerTypes.GET_NUMBER_BOOKING_BY_PHONE_NUMBER, getNumberBookingByPhoneNumber),
    // Post file consumer
    takeLatest(ConsumerTypes.POST_CONSUMER_LIST, postConsumerList),

    // report dashboard
    takeLatest(ReportDashboardTypes.REQUEST_REPORT_SUMMARY_RESERVATION, requestReportSummaryReservation),
    takeLatest(ReportDashboardTypes.CREATE_REPORT_SUMMARY_RESERVATION_SETTING, requestCreateDisplaySetting),
    takeLatest(ReportDashboardTypes.REQUEST_REPORT_SUMMARY_CUSTOMER, requestReportSummaryCustomer),
    takeLatest(ReportDashboardTypes.CREATE_REPORT_SUMMARY_CUSTOMER_SETTING, requestCreateDisplaySetting),
    takeLatest(ReportDashboardTypes.REQUEST_REPORT_SUMMARY_PLACE, requestReportSummaryPlace),
    takeLatest(ReportDashboardTypes.CREATE_REPORT_SUMMARY_PLACE_SETTING, requestCreateDisplaySetting),
    takeLatest(ReportDashboardTypes.REQUEST_REPORT_SUMMARY_SERVICE, requestReportSummaryService),
    takeLatest(ReportDashboardTypes.CREATE_REPORT_SUMMARY_SERVICE_SETTING, requestCreateDisplaySetting),

    // get reservation activity
    takeLatest(ReservationActivityTypes.GET_RESERVATION_ACTIVITY, getReservationActivity),

    //product goods
    takeLatest(ProductCategoryTypes.GET_PRODUCT_CATEGORY, getProductCategory),
    takeLatest(ProductCategoryTypes.CREATE_PRODUCT_CATEGORY, createProductCategory),
    takeLatest(ProductCategoryTypes.UPDATE_PRODUCT_CATEGORY, updateProductCategory),
    takeLatest(ProductCategoryTypes.REMOVE_PRODUCT_CATEGORY, removeProductCategory),
    takeLatest(ProductCategoryTypes.GET_PRODUCT_ITEM, getProductItem),
    takeLatest(ProductCategoryTypes.GET_PRODUCT_ITEM_UID_CATEGORY, getProductItemUidCategory),
    takeLatest(ProductCategoryTypes.CREATE_PRODUCT_ITEM, createProductItem),
    takeLatest(ProductCategoryTypes.UPDATE_PRODUCT_ITEM, updateProductItem),
    takeLatest(ProductCategoryTypes.REMOVE_PRODUCT_ITEM, removeProductItem),

    // Menu
    takeLatest(MenuTypes.GET_MENU, getMenu),
    takeLatest(MenuTypes.POST_MENU, postMenu),

    //Details
    takeLatest(ProductCategoryTypes.GET_DETAILS, getDetails),
    takeLatest(ProductCategoryTypes.GET_DETAILS_UID, getDetailsUid),
    takeLatest(ProductCategoryTypes.CREATE_DETAILS, createDetails),
    takeLatest(ProductCategoryTypes.UPDATE_DETAILS, updateDetails),
    takeLatest(ProductCategoryTypes.UPDATE_DETAILS_EDIT_CONTENT, updateDetailsEditContent),
    takeLatest(ProductCategoryTypes.REMOVE_DETAILS, removeDetails),
    takeLatest(ProductCategoryTypes.GET_DETAILS_CONTENT, getDetailsContent),
    takeLatest(ProductCategoryTypes.GET_DETAILS_CONTENT_UID, getDetailsContentUid),
    takeLatest(ProductCategoryTypes.CREATE_DETAILS_CONTENT, createDetailsContent),
    takeLatest(ProductCategoryTypes.UPDATE_DETAILS_CONTENT, updateDetailsContent),
    takeLatest(ProductCategoryTypes.REMOVE_DETAILS_CONTENT, removeDetailsContent),

    // get reservation new
    takeLatest(ReservationNewTypes.GET_RESERVATION_NEW_ORDER, getReservationNewOrder),
    takeLatest(ReservationNewTypes.GET_RESERVATION_NEW_BOOKING, getReservationNewBooking),

    // combo item
    takeLatest(ComboItemTypes.POST_COMBO_ITEM, postComboItem),
    takeLatest(ComboItemTypes.PUT_COMBO_ITEM, putComboItem),
    takeLatest(ComboItemTypes.GET_COMBO_ITEM, getComboItemByUid),
    takeLatest(ComboItemTypes.GET_LIST_COMBO_ITEM, getListComboItem),
    takeLatest(ComboItemTypes.DELETE_COMBO_ITEM, deleteComboItem),

    // service item
    takeLatest(ServiceItemTypes.POST_SERVICE_ITEM, postServiceItem),
    takeLatest(ServiceItemTypes.PUT_SERVICE_ITEM, putServiceItem),
    takeLatest(ServiceItemTypes.GET_SERVICE_ITEM, getServiceItemByUid),
    takeLatest(ServiceItemTypes.GET_CATEGORY_SERVICE_ITEM, getServiceItemByCategoryUid),
    takeLatest(ServiceItemTypes.GET_LIST_SERVICE_ITEM, getListServiceItem),
    takeLatest(ServiceItemTypes.DELETE_SERVICE_ITEM, deleteServiceItem),

    // item details
    takeLatest(ItemDetailsTypes.POST_ITEM_DETAILS, postItemDetails),
    takeLatest(ItemDetailsTypes.PUT_ITEM_DETAILS, putItemDetails),
    takeLatest(ItemDetailsTypes.GET_ITEM_DETAILS, getItemDetailsByUid),
    takeLatest(ItemDetailsTypes.GET_LIST_ITEM_DETAILS, getListItemDetails),
    takeLatest(ItemDetailsTypes.DELETE_ITEM_DETAILS, deleteItemDetails),
    takeLatest(ItemDetailsTypes.PUT_EDIT_CONTENT, putEditContent),

    // item details content
    takeLatest(ItemDetailsContentTypes.POST_ITEM_DETAILS_CONTENT, postItemDetailsContent),
    takeLatest(ItemDetailsContentTypes.PUT_ITEM_DETAILS_CONTENT, putItemDetailsContent),
    takeLatest(ItemDetailsContentTypes.GET_ITEM_DETAILS_CONTENT, getItemDetailsContentByUid),
    takeLatest(ItemDetailsContentTypes.GET_LIST_ITEM_DETAILS_CONTENT, getListItemDetailsContent),
    takeLatest(ItemDetailsContentTypes.DELETE_ITEM_DETAILS_CONTENT, deleteItemDetailsContent),
    // waiting consumer
    takeLatest(WaitingConsumerTypes.GET_WAITING_CONSUMER, getWaitingConsumer),
    takeLatest(WaitingConsumerTypes.POST_WAITING_CONSUMER, postWaitingConsumer),
    takeLatest(WaitingConsumerTypes.PUT_WAITING_CONSUMER, putWaitingConsumer),
    takeLatest(WaitingConsumerTypes.DELETE_WAITING_CONSUMER, deleteWaitingConsumer),

    // payment
    takeLatest(PaymentTypes.GET_CHECK_PAYMENT, getCheckPayment),
    takeLatest(PaymentTypes.GET_PACKAGE_CURRENT, getPackageCurrent),
    takeLatest(PaymentTypes.GET_PACKAGE, getPackage),
    takeLatest(PaymentTypes.GET_PAYMENT_HISTORY, getPaymentHistory),
    takeLatest(PaymentTypes.GET_PAYMENT_DETAILS, getPaymentDetails),

    // Rating
    takeLatest(RatingTypes.GET_RATING_ITEM, getRatingItem),
    takeLatest(RatingTypes.GET_RATING_SHOP, getRatingShop),
    takeLatest(RatingTypes.GET_RATING_ITEM_SUMMARY, getRatingItemSummary),
    takeLatest(RatingTypes.GET_RATING_SHOP_SUMMARY, getRatingShopSummary),
    takeLatest(RatingTypes.REPORT_RATING, reportRating),

    // Bill
    takeLatest(BillTypes.CREATE_BILL, createBill),
    takeLatest(BillTypes.UPDATE_BILL, updateBill),
    takeLatest(BillTypes.PATCH_UPDATE_BILL_STATUS, patchUpdateBillStatus),
    takeLatest(BillTypes.GET_BILL_LIST, getBillList),
    takeLatest(BillTypes.DELETE_BILL, deleteBill),
    takeLatest(BillTypes.GET_RESERVATION_BILL_DETAIL, getReservationBillDetail),

    // Group admin
    takeLatest(GroupAdminTypes.GET_GROUP_ADMIN, getGroupAdmin),
    takeLatest(GroupAdminTypes.JOIN_GROUP_ADMIN, joinGroupAdmin),

    // Upload file
    takeLatest(UploadFileTypes.REQUEST_UPLOAD_IMAGE, requestUploadPostImage),

    // Reset error before call api
    takeLatest(
      [
        //shop
        ShopTypes.REQUEST_GET_SHOP_INFO,
        ShopTypes.REQUEST_UPDATE_SHOP_INFO,
        ShopTypes.REQUEST_UPLOAD_SHOP_IMAGE,
        ShopTypes.REQUEST_PUT_FACEBOOK_LINK,
        ShopTypes.REQUEST_GET_FACEBOOK_PAGE,

        //User
        UserTypes.REQUEST_GET_USER_INFO,
        UserTypes.REQUEST_GET_USER_SHOP_INFO,
        UserTypes.REQUEST_UPDATE_USER_INFO,
        UserTypes.REQUEST_UPDATE_USER_PASSWORD,
        UserTypes.REQUEST_UPLOAD_USER_IMAGE,

        //Report
        ReportBookingTypes.REQUEST_REPORT_BOOKING_DAY,
        ReportBookingTypes.REQUEST_REPORT_BOOKING_WEEK,
        ReportBookingTypes.REQUEST_REPORT_BOOKING_MONTH,
        ReportConsumerTypes.REQUEST_REPORT_CONSUMER,
        ReportDashboardTypes.REQUEST_REPORT_SUMMARY_RESERVATION,
        ReportDashboardTypes.CREATE_REPORT_SUMMARY_RESERVATION_SETTING,
        ReportDashboardTypes.REQUEST_REPORT_SUMMARY_CUSTOMER,
        ReportDashboardTypes.CREATE_REPORT_SUMMARY_CUSTOMER_SETTING,
        ReportDashboardTypes.REQUEST_REPORT_SUMMARY_PLACE,
        ReportDashboardTypes.CREATE_REPORT_SUMMARY_PLACE_SETTING,

        //Reservation
        ReservationSettingTypes.REQUEST_GET_RESERVATION_SETTING,
        ReservationSettingTypes.REQUEST_UPDATE_RESERVATION_SETTING,
        ReservationSettingTypes.REQUEST_GET_RESERVATION_NOTIFICATION,
        ReservationSettingTypes.REQUEST_UPDATE_RESERVATION_STATUS,

        // ShopInfo
        ShopInfoTypes.GET_SHOP_INFO_DATA,
        ShopInfoTypes.UPDATE_SHOP_INFO_DATA,
        // PlaceCategory
        PlaceCategoryTypes.GET_PLACE_CATEGORY,
        PlaceCategoryTypes.CREATE_PLACE_CATEGORY,
        PlaceCategoryTypes.CREATE_PLACE_CATEGORY_ITEM,
        PlaceCategoryTypes.REMOVE_PLACE_CATEGORY,
        PlaceCategoryTypes.UPDATE_PLACE_CATEGORY,
        PlaceCategoryTypes.GET_PLACE_DIALOG_DATA,

        // PlaceItem
        PlaceItemTypes.GET_PLACE_ITEM,
        PlaceItemTypes.CREATE_PLACE_ITEM,
        PlaceItemTypes.PATCH_PLACE_ITEM,
        PlaceItemTypes.UPDATE_PLACE_ITEM,
        PlaceItemTypes.REMOVE_PLACE_ITEM,
        PlaceItemTypes.GET_PLACE_BUSY_STATUS,
        PlaceItemTypes.GET_PLACE_ITEM_AUTO_FILL,

        // ServiceCategory
        ServiceCategoryTypes.GET_SERVICE_CATEGORY,
        ServiceCategoryTypes.CREATE_SERVICE_CATEGORY,
        ServiceCategoryTypes.UPDATE_SERVICE_CATEGORY,
        ServiceCategoryTypes.REMOVE_SERVICE_CATEGORY,
        //Reservation
        ReservationTypes.GET_RESERVATION_DAY,
        ReservationTypes.GET_RESERVATION_WEEK,
        ReservationTypes.GET_RESERVATION_MONTH,
        ReservationTypes.POST_RESERVATION,
        ReservationTypes.GET_RESERVATION_SETTING_OPTION,
        ReservationTypes.PUT_RESERVATION_STATUS,
        ReservationTypes.PUT_RESERVATION_DETAILS,
        ReservationTypes.GET_RESERVATION_PERIODIC,
        ReservationTypes.GET_RESERVATION,
        ReservationTypes.POST_RESERVATION_EXTEND,
        ReservationTypes.POST_RESERVATION_LIST_STATUS,
        ReservationTypes.GET_AVAILABLE_STATUS,
        ReservationTypes.GET_DISPLAY_SETTING,
        ReservationTypes.UPDATE_DISPLAY_SETTING,
        ReservationTypes.GET_RESERVATION_TYPE,
        // Reservation History
        ReservationHistoryTypes.GET_BOOKING_HISTORY,

        //Report staff
        ReportStaffTypes.GET_REPORT_STAFF,
        //news
        MnNewsTypes.GET_NEWS,
        MnNewsTypes.CREATE_NEWS,
        MnNewsTypes.UPDATE_NEWS,
        MnNewsTypes.REMOVE_NEWS,
        MnNewsTypes.REQUEST_UPLOAD_POST_IMAGE,
        ReservationSettingTypes.REQUEST_UPDATE_RESERVATION_STATUS,

        //Staff
        StaffTypes.REQUEST_POST_WORKING_SHIFT,
        StaffTypes.REQUEST_POST_POSITION,
        StaffTypes.REQUEST_CREATE_STAFF,
        StaffTypes.REQUEST_EDIT_STAFF,
        StaffTypes.REQUEST_WORKING_SHIFTS,
        StaffTypes.REQUEST_STAFF,
        StaffTypes.REQUEST_POSITION,
        StaffTypes.DELETE_STAFF,
        StaffTypes.GET_STAFF_BUSY_STATUS,

        //Consumer
        ConsumerTypes.REQUEST_GET_CONSUMER,

        //Reservation activity
        ReservationActivityTypes.GET_RESERVATION_ACTIVITY,

        //product category
        ProductCategoryTypes.GET_PRODUCT_CATEGORY,
        ProductCategoryTypes.CREATE_PRODUCT_CATEGORY,
        ProductCategoryTypes.UPDATE_PRODUCT_CATEGORY,
        ProductCategoryTypes.REMOVE_PRODUCT_CATEGORY,
        ProductCategoryTypes.GET_PRODUCT_ITEM,
        ProductCategoryTypes.CREATE_PRODUCT_ITEM,
        ProductCategoryTypes.UPDATE_PRODUCT_ITEM,
        ProductCategoryTypes.REMOVE_PRODUCT_ITEM,

        // Details
        ProductCategoryTypes.GET_DETAILS,
        ProductCategoryTypes.CREATE_DETAILS,
        ProductCategoryTypes.UPDATE_DETAILS,
        ProductCategoryTypes.REMOVE_DETAILS,
        ProductCategoryTypes.GET_DETAILS_CONTENT,
        ProductCategoryTypes.CREATE_DETAILS_CONTENT,
        ProductCategoryTypes.UPDATE_DETAILS_CONTENT,
        ProductCategoryTypes.REMOVE_DETAILS_CONTENT,

        //Reservation new
        ReservationNewTypes.GET_RESERVATION_NEW_ORDER,
        ReservationNewTypes.GET_RESERVATION_NEW_BOOKING,

        // Combo Item
        ComboItemTypes.POST_COMBO_ITEM,
        ComboItemTypes.PUT_COMBO_ITEM,
        ComboItemTypes.GET_COMBO_ITEM,
        ComboItemTypes.GET_LIST_COMBO_ITEM,
        ComboItemTypes.DELETE_COMBO_ITEM,

        // Service Item
        ServiceItemTypes.POST_SERVICE_ITEM,
        ServiceItemTypes.PUT_SERVICE_ITEM,
        ServiceItemTypes.GET_SERVICE_ITEM,
        ServiceItemTypes.GET_LIST_SERVICE_ITEM,
        ServiceItemTypes.DELETE_SERVICE_ITEM,

        // Item Details
        ItemDetailsTypes.POST_ITEM_DETAILS,
        ItemDetailsTypes.PUT_ITEM_DETAILS,
        ItemDetailsTypes.GET_ITEM_DETAILS,
        ItemDetailsTypes.GET_LIST_ITEM_DETAILS,
        ItemDetailsTypes.DELETE_ITEM_DETAILS,
        ItemDetailsTypes.PUT_EDIT_CONTENT,

        // Item Details Content
        ItemDetailsContentTypes.POST_ITEM_DETAILS_CONTENT,
        ItemDetailsContentTypes.PUT_ITEM_DETAILS_CONTENT,
        ItemDetailsContentTypes.GET_ITEM_DETAILS_CONTENT,
        ItemDetailsContentTypes.GET_LIST_ITEM_DETAILS_CONTENT,
        ItemDetailsContentTypes.DELETE_ITEM_DETAILS_CONTENT,

        // Menu
        MenuTypes.GET_MENU,
        // Waiting Consumer
        WaitingConsumerTypes.GET_WAITING_CONSUMER,
        WaitingConsumerTypes.POST_WAITING_CONSUMER,
        WaitingConsumerTypes.PUT_WAITING_CONSUMER,
        WaitingConsumerTypes.DELETE_WAITING_CONSUMER,

        // Payment
        PaymentTypes.GET_CHECK_PAYMENT,

        // Rating
        RatingTypes.GET_RATING_ITEM,
        RatingTypes.GET_RATING_SHOP,
        RatingTypes.GET_RATING_ITEM_SUMMARY,
        RatingTypes.GET_RATING_SHOP_SUMMARY,
        RatingTypes.REPORT_RATING,

        // Bill
        BillTypes.CREATE_BILL,
        BillTypes.UPDATE_BILL,
        BillTypes.PATCH_UPDATE_BILL_STATUS,
        BillTypes.GET_BILL_LIST,
        BillTypes.DELETE_BILL,
        BillTypes.GET_RESERVATION_BILL_DETAIL,

        // Group admin
        GroupAdminTypes.GET_GROUP_ADMIN,
        GroupAdminTypes.JOIN_GROUP_ADMIN,

        // Upload file
        UploadFileTypes.REQUEST_UPLOAD_IMAGE,
      ],
      resetError,
    ),
    takeLatest(
      [
        //Shop
        ShopTypes.GET_SHOP_INFO_FAILURE,
        ShopTypes.UPDATE_SHOP_INFO_FAILURE,
        ShopTypes.UPLOAD_SHOP_IMAGE_FAILURE,
        ShopTypes.GET_FACEBOOK_PAGE_FAILURE,
        ShopTypes.PUT_FACEBOOK_LINK_FAILURE,

        //User
        UserTypes.GET_USER_INFO_FAILURE,
        UserTypes.GET_USER_SHOP_INFO_FAILURE,
        UserTypes.UPDATE_USER_INFO_FAILURE,
        UserTypes.UPDATE_USER_PASSWORD_FAILURE,
        UserTypes.UPLOAD_USER_IMAGE_FAILURE,

        //Report
        ReportBookingTypes.REPORT_BOOKING_FAILURE_DAY,
        ReportBookingTypes.REPORT_BOOKING_FAILURE_WEEK,
        ReportBookingTypes.REPORT_BOOKING_FAILURE_MONTH,
        ReportConsumerTypes.REPORT_CONSUMER_FAILURE,

        //Reservation
        ReservationSettingTypes.GET_RESERVATION_SETTING_FAILURE,
        ReservationSettingTypes.UPDATE_RESERVATION_SETTING_FAILURE,
        ReservationSettingTypes.GET_RESERVATION_NOTIFICATION_FAILURE,
        ReservationSettingTypes.UPDATE_RESERVATION_STATUS_FAILURE,

        // ShopInfo failure
        ShopInfoTypes.GET_SHOP_INFO_DATA_FAILURE,
        ShopInfoTypes.UPDATE_SHOP_INFO_DATA_FAILURE,
        // PlaceCategory failure
        PlaceCategoryTypes.GET_PLACE_CATEGORY_FAILURE,
        PlaceCategoryTypes.CREATE_PLACE_CATEGORY_FAILURE,
        PlaceCategoryTypes.CREATE_PLACE_CATEGORY_ITEM_FAILURE,
        PlaceCategoryTypes.REMOVE_PLACE_CATEGORY_FAILURE,
        PlaceCategoryTypes.UPDATE_PLACE_CATEGORY_FAILURE,
        PlaceCategoryTypes.PLACE_CATEGORY_FAILURE,

        // PlaceItem failure
        PlaceItemTypes.GET_PLACE_ITEM_FAILURE,
        PlaceItemTypes.CREATE_PLACE_ITEM_FAILURE,
        PlaceItemTypes.UPDATE_PLACE_ITEM_FAILURE,
        PlaceItemTypes.REMOVE_PLACE_ITEM_FAILURE,
        PlaceItemTypes.GET_PLACE_BUSY_STATUS_FAILURE,

        // ServiceCategory failure
        ServiceCategoryTypes.GET_SERVICE_CATEGORY_FAILURE,
        ServiceCategoryTypes.CREATE_SERVICE_CATEGORY_FAILURE,
        ServiceCategoryTypes.UPDATE_SERVICE_CATEGORY_FAILURE,
        ServiceCategoryTypes.REMOVE_SERVICE_CATEGORY_FAILURE,

        // Reservation failure
        ReservationTypes.GET_RESERVATION_DAY_FAILURE,
        ReservationTypes.GET_RESERVATION_WEEK_FAILURE,
        ReservationTypes.GET_RESERVATION_MONTH_FAILURE,
        ReservationTypes.POST_RESERVATION_FAILURE,
        ReservationTypes.GET_RESERVATION_SETTING_OPTION_FAILURE,
        ReservationTypes.PUT_RESERVATION_STATUS_FAILURE,
        ReservationTypes.PUT_RESERVATION_DETAILS_FAILURE,
        ReservationTypes.GET_RESERVATION_PERIODIC_FAILURE,
        ReservationTypes.GET_RESERVATION_FAILURE,
        ReservationTypes.POST_RESERVATION_EXTEND_FAILURE,
        ReservationTypes.POST_RESERVATION_LIST_STATUS_FAILURE,
        ReservationTypes.GET_AVAILABLE_STATUS_FAILURE,
        ReservationTypes.GET_DISPLAY_SETTING_FAILURE,
        ReservationTypes.UPDATE_DISPLAY_SETTING_FAILURE,
        ReservationTypes.RESERVATION_FAILURE,

        // Reservation History
        ReservationHistoryTypes.HISTORY_FAILURE,

        //news failure
        MnNewsTypes.GET_NEWS_FAILURE,
        MnNewsTypes.CREATE_NEWS_FAILURE,
        MnNewsTypes.UPDATE_NEWS_FAILURE,
        MnNewsTypes.REMOVE_NEWS_FAILURE,
        MnNewsTypes.UPLOAD_POST_IMAGE_FAILURE,
        ReservationSettingTypes.UPDATE_RESERVATION_STATUS_FAILURE,
        StaffTypes.POST_WORKING_SHIFT_FAILURE,
        StaffTypes.POST_POSITION_FAILURE,
        ConsumerTypes.GET_CONSUMER_FAILURE,

        StaffTypes.GET_WORKING_POTISION_FAILURE,
        StaffTypes.GET_WORKING_FAILURE,
        StaffTypes.REMOVE_POST_WORKING_FAILURE,
        StaffTypes.REMOVE_STAFF_POSITION_FAILURE,
        StaffTypes.UPDATE_STAFF_POSITION_FAILURE,
        StaffTypes.UPDATE_WORKING_SHIFT_FAILURE,

        //Report staff
        ReportStaffTypes.GET_REPORT_STAFF_FAILURE,

        //Staff
        StaffTypes.POST_WORKING_SHIFT_FAILURE,
        StaffTypes.POST_POSITION_FAILURE,
        StaffTypes.CREATE_STAFF_FAILURE,
        StaffTypes.EDIT_STAFF_FAILURE,
        StaffTypes.GET_WORKING_SHIFT_FAILURE,
        StaffTypes.GET_STAFF_FAILURE,
        StaffTypes.GET_POSITION_FAILURE,
        StaffTypes.DELETE_STAFF_FAILURE,
        StaffTypes.GET_STAFF_BUSY_STATUS_FAILURE,

        //Reservation activity
        ReservationActivityTypes.GET_RESERVATION_ACTIVITY_FAILURE,

        //Reservation new
        ReservationNewTypes.GET_RESERVATION_NEW_FAILURE,

        // Combo Item
        ComboItemTypes.POST_COMBO_ITEM_FAILURE,
        ComboItemTypes.PUT_COMBO_ITEM_FAILURE,
        ComboItemTypes.GET_COMBO_ITEM_FAILURE,
        ComboItemTypes.GET_LIST_COMBO_ITEM_FAILURE,
        ComboItemTypes.DELETE_COMBO_ITEM_FAILURE,

        // Service Item
        ServiceItemTypes.POST_SERVICE_ITEM_FAILURE,
        ServiceItemTypes.PUT_SERVICE_ITEM_FAILURE,
        ServiceItemTypes.GET_SERVICE_ITEM_FAILURE,
        ServiceItemTypes.GET_LIST_SERVICE_ITEM_FAILURE,
        ServiceItemTypes.DELETE_SERVICE_ITEM_FAILURE,

        // Item Details
        ItemDetailsTypes.POST_ITEM_DETAILS_FAILURE,
        ItemDetailsTypes.PUT_ITEM_DETAILS_FAILURE,
        ItemDetailsTypes.GET_ITEM_DETAILS_FAILURE,
        ItemDetailsTypes.GET_LIST_ITEM_DETAILS_FAILURE,
        ItemDetailsTypes.DELETE_ITEM_DETAILS_FAILURE,
        ItemDetailsTypes.PUT_EDIT_CONTENT_FAILURE,

        // Item Details Content
        ItemDetailsContentTypes.POST_ITEM_DETAILS_CONTENT_FAILURE,
        ItemDetailsContentTypes.PUT_ITEM_DETAILS_CONTENT_FAILURE,
        ItemDetailsContentTypes.GET_ITEM_DETAILS_CONTENT_FAILURE,
        ItemDetailsContentTypes.GET_LIST_ITEM_DETAILS_CONTENT_FAILURE,
        ItemDetailsContentTypes.DELETE_ITEM_DETAILS_CONTENT_FAILURE,

        // Menu
        MenuTypes.GET_MENU_FAILURE,
        // Waiting Consumer
        WaitingConsumerTypes.WAITING_CONSUMER_FAILURE,

        // Payment
        PaymentTypes.GET_PAYMENT_FAILURE,

        // Rating
        RatingTypes.RATING_FAILURE,

        // Bill
        BillTypes.BILL_FAILURE,
      ],
      raiseError,
    ),

    // Handling errors
    // takeLatest([AuthTypes.AUTH_FAILURE], raiseError),

    // Get shop info
    takeLatest(ShopInfoTypes.GET_SHOP_INFO_DATA, getShopInfoData),
    // Reset error before call api
    takeLatest(ShopInfoTypes.GET_SHOP_INFO_DATA_SUCCESS, resetError),

    // Update shop info
    takeLatest(ShopInfoTypes.UPDATE_SHOP_INFO_DATA, updateShopInfoData),

    /* ------------- Place Category ------------- */
    // Place category
    takeLatest(PlaceCategoryTypes.GET_PLACE_CATEGORY, getPlaceCategory),
    takeLatest(PlaceCategoryTypes.CREATE_PLACE_CATEGORY, createPlaceCategory),
    takeLatest(PlaceCategoryTypes.CREATE_PLACE_CATEGORY_ITEM, createPlaceCategoryItem),
    takeLatest(PlaceCategoryTypes.REMOVE_PLACE_CATEGORY, removePlaceCategory),
    takeLatest(PlaceCategoryTypes.UPDATE_PLACE_CATEGORY, updatePlaceCategory),
    takeLatest(PlaceCategoryTypes.GET_PLACE_DIALOG_DATA, getPlaceDialogData),

    /* ------------- Place Item ------------- */
    // Get place item
    takeLatest(PlaceItemTypes.GET_PLACE_ITEM, getPlaceItem),

    // Create place item
    takeLatest(PlaceItemTypes.CREATE_PLACE_ITEM, createPlaceItem),

    // Patch place item
    takeLatest(PlaceItemTypes.PATCH_PLACE_ITEM, patchPlaceItem),

    // Update place item
    takeLatest(PlaceItemTypes.UPDATE_PLACE_ITEM, updatePlaceItem),

    // Remove place item
    takeLatest(PlaceItemTypes.REMOVE_PLACE_ITEM, removePlaceItem),
    // Get place busy status
    takeLatest(PlaceItemTypes.GET_PLACE_BUSY_STATUS, getPlaceBusyStatus),
    // get place item auto fill
    takeLatest(PlaceItemTypes.GET_PLACE_ITEM_AUTO_FILL, getPlaceItemAutoFill),

    /* ------------- Service Category ------------- */
    // Get service category
    takeLatest(ServiceCategoryTypes.GET_SERVICE_CATEGORY, getServiceCategory),

    // Create service category
    takeLatest(ServiceCategoryTypes.CREATE_SERVICE_CATEGORY, createServiceCategory),

    // Create service category
    takeLatest(ServiceCategoryTypes.UPDATE_SERVICE_CATEGORY, updateServiceCategory),

    // Remove service category
    takeLatest(ServiceCategoryTypes.REMOVE_SERVICE_CATEGORY, removeServiceCategory),

    //Reservation
    takeLatest(ReservationTypes.GET_RESERVATION_DAY, getReservationDay),
    takeLatest(ReservationTypes.GET_RESERVATION_WEEK, getReservationWeek),
    takeLatest(ReservationTypes.GET_RESERVATION_MONTH, getReservationMonth),
    takeLatest(ReservationTypes.POST_RESERVATION, postReservation),
    takeLatest(ReservationTypes.CREATE_REMOTE_ORDER, createRemoteOrder),

    takeLatest(ReservationTypes.GET_RESERVATION_SETTING_OPTION, getReservationSettingOption),
    takeLatest(ReservationTypes.PUT_RESERVATION_STATUS, putReservationStatus),
    takeLatest(ReservationTypes.PUT_RESERVATION_DETAILS, putReservationDetails),
    takeLatest(ReservationTypes.PUT_REMOTE_ORDER_DETAILS, putRemoteOrderDetails),

    takeLatest(ReservationTypes.GET_RESERVATION_PERIODIC, getReservationPeriodic),
    takeLatest(ReservationTypes.GET_RESERVATION, getReservation),
    takeLatest(ReservationTypes.POST_RESERVATION_EXTEND, postReservationExtend),
    takeLatest(ReservationTypes.POST_RESERVATION_LIST_STATUS, postReservationListStatus),
    takeLatest(ReservationTypes.GET_AVAILABLE_STATUS, getAvailableStatus),

    takeLatest(ReservationTypes.GET_DISPLAY_SETTING, getDisplaySetting),
    takeLatest(ReservationTypes.UPDATE_DISPLAY_SETTING, updateDisplaySetting),
    takeLatest(ReservationTypes.GET_RESERVATION_TYPE, getReservationType),

    // Staff
    takeLatest(StaffTypes.REQUEST_STAFF_TABLE, getStaffTable),
    takeLatest(StaffTypes.REQUEST_WORKING_SHIFT, getWorkingShift),
    takeLatest(StaffTypes.REQUEST_STAFF_POSITION, getStaffPosition),
    takeLatest(StaffTypes.PATCH_INVITATION_STAFF, patchInvitationStaff),

    /* ------------- Report staff ------------- */
    takeLatest(ReportStaffTypes.GET_REPORT_STAFF, getReportStaff),

    // Reservation History
    takeLatest(ReservationHistoryTypes.GET_BOOKING_HISTORY, getBookingHistory),
    takeLatest(ReservationHistoryTypes.BATCH_UPDATE_STATUS, batchUpdateStatus),
  ]);
}
