import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import LinkFacebookPage from "components/social/LinkFacebookPage";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import SinglePageRowLinked from "./SingelPageRowLinked";
const ListPagesFacebook = props => {
  const { t: getLabel } = useTranslation();
  const socialRef = useRef();
  const classes = useStyle();
  const { pages } = props;
  return (
    <>
      <Box>
        <Typography variant="h5" className={classes.boxHeader}>
          {getLabel(LangConstant.TXT_FACEBOOK_PAGE_LIST)}
        </Typography>
      </Box>

      <Box className={classes.contentContainer}>
        <Grid container className={classes.gridContainer}>
          <SinglePageRowLinked listPages={pages} />
        </Grid>
      </Box>

      <Box className={classes.buttonContainer}>
        <LinkFacebookPage
          ref={socialRef}
          linkButton={
            <Button
              color="primary"
              variant="contained"
              size="large"
              className={classes.addPageBtn}
              onClick={() => socialRef.current.openSocialLink()}
            >
              <Typography variant="subtitle2" color="inherit">
                {getLabel(LangConstant.TXT_ADD_NEW_PAGE)}
              </Typography>
            </Button>
          }
        />
      </Box>
    </>
  );
};
export default ListPagesFacebook;
const useStyle = makeStyles(theme => ({
  boxHeader: {
    marginTop: "6vh",
    textAlign: "center",
  },
  gridItemLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  gridItemContent: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 30,
    },
  },
  gridContainer: {
    width: "90%",
    display: "flex",
    marginLeft: "5%",
    minWidth: "20vw",
  },
  pagesName: {
    maxWidth: "25vw",
    marginLeft: "1vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      paddingLeft: 0,
    },
  },
  contentContainer: {},
  buttonContainer: {
    textAlign: "center",
    position: "relative",
    marginTop: 260,
  },
  addPageBtn: { textTransform: "none" },
  txtBtn: {
    fontSize: 16,
    fontWeight: 600,
    color: "#ffffff",
  },
}));
