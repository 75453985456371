import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Popper,
  Typography,
  makeStyles,
  Box,
  List,
  Grid,
  useMediaQuery,
  Paper,
  CircularProgress,
  ClickAwayListener,
} from "@material-ui/core";
import Cookie from "js-cookie";
import { getCommonKey } from "utils";
import { AppConstant, LangConstant } from "const";
import EmptyNotice from "components/Notification/EmptyNotice";
import ReservationSettingAction from "redux/reservation-setting.redux";
import NotificationSettingMenu from "./NotificationSettingMenu";
import { MARK_ALL, SHOW_SETTING } from "./NotificationSettingMenu";
import NotificationSettingDialog from "../dialog/NotificationSettingDialog";
import NotificationItem from "./NotificationItem";
import ReservationAction from "redux/reservation-setting.redux";

const Notification = ({ id, anchorEl, onClose, data }) => {
  const role = Cookie.get(AppConstant.ROLE);
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [isShow, setIsShow] = useState(false);
  const dataNotificationRedux = useSelector(state => state.reservationSettingRedux);
  const { dataNotification: notificationData, totalPage, page, isFetchingNotification } = dataNotificationRedux;
  const isUpdateSeenNotification = useSelector(state => state.reservationSettingRedux.isUpdateSeenNotification);
  const [notification, setNotification] = useState(data);
  const [currentPage, setCurrentPage] = useState();
  const [keepLoading, setKeepLoading] = useState(true);

  const showDialog = () => {
    setIsShow(true);
  };
  const onCloseDialog = () => {
    setIsShow(false);
  };
  const dispatch = useDispatch();

  const updateSeen = uids => {
    dispatch(ReservationSettingAction.requestSeenNotification({ uids: uids }));
  };

  const onMarkAll = () => {
    if (data && Object.keys(data).length > 0) updateSeen(Object.keys(data));
  };

  const onSelectSetting = (event, index) => {
    event.stopPropagation();
    switch (index) {
      case MARK_ALL:
        onMarkAll();
        break;
      case SHOW_SETTING:
        showDialog();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isUpdateSeenNotification) {
      dispatch(ReservationAction.resetSeenNotification());
      dispatch(ReservationAction.requestGetReservationNotification({ size: 20, page: 1 }));
    }
  }, [isUpdateSeenNotification]);

  //Logic load more when scroll
  let isLoadMore = page < totalPage;

  const onLoadMore = () => {
    if (noticeLoadMoreTimeout) {
      clearTimeout(noticeLoadMoreTimeout);
    }
    noticeLoadMoreTimeout = setTimeout(() => {
      if (isLoadMore && keepLoading) {
        dispatch(ReservationAction.requestGetReservationNotification({ size: 20, page: page + 1 }));
        setKeepLoading(false);
      }
    }, 500);
  };

  useEffect(() => {
    if (notificationData && !isFetchingNotification) {
      let newData = notificationData;
      if (page > currentPage) {
        newData = Object.assign(notification, notificationData);
      }
      setNotification(newData);
      setCurrentPage(page);
      setKeepLoading(true);
    }
  }, [notificationData, page]);

  const matches = useMediaQuery("(min-width:960px)");
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper id={id} open={Boolean(anchorEl)} anchorEl={anchorEl} className={classes.popoverPaper}>
        <NotificationSettingDialog isShow={isShow} onClose={onCloseDialog} />
        <Box className={classes.main}>
          <Box className={classes.header}>
            <Typography variant="subtitle2" component="p" className={classes.typoHeader}>
              {getLabel(LangConstant.TXT_NOTIFICATION_TITLE)}
            </Typography>

            <NotificationSettingMenu onSelect={onSelectSetting} isShopOwner={AppConstant.SHOP_OWNER === role} />
          </Box>
          <Box>
            <Grid container direction="row">
              <Grid item xs={matches ? 6 : 12} className={classes.notice}>
                <Paper className={classes.bodyBox}>
                  <List className={classes.listRoot} disablePadding={true}>
                    {Object.keys(notification).length > 0 && (
                      <InfiniteScroll
                        initialLoad={false}
                        pageStart={1}
                        loadMore={() => onLoadMore()}
                        hasMore={isLoadMore}
                        loader={isFetchingNotification && <CircularProgress />}
                        useWindow={false}
                      >
                        {Object.keys(notification).map(key => (
                          <NotificationItem
                            key={key}
                            data={notification}
                            uidKey={key}
                            onClose={onClose}
                            updateSeen={updateSeen}
                          />
                        ))}
                      </InfiniteScroll>
                    )}
                    {Object.keys(notification).length === 0 && (
                      <EmptyNotice message={getLabel(getCommonKey(LangConstant.TXT_EMPTY_NOTICE_MESSAGE))} />
                    )}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

var noticeLoadMoreTimeout;

const useStyles = makeStyles(theme => ({
  popoverPaper: {
    overflow: "unset",
    marginTop: 13,
    zIndex: theme.zIndex.drawer,
    "&::before": {
      position: "absolute",
      top: "-9px",
      right: "22%",
      borderBottom: `10px solid ${theme.palette.secondary.main}`,
      borderRight: "20px solid transparent",
      borderLeft: "20px solid transparent",
      content: `''`,
      [theme.breakpoints.down("md")]: {
        right: 43,
      },
      [theme.breakpoints.down("sm")]: {
        right: 101,
      },
      [theme.breakpoints.down("sm")]: {
        right: "32%",
      },
    },
  },
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    wordBreak: "break-all",
  },
  header: {
    width: "100%",
    backgroundColor: "#65b39d",
    height: "40px",
    color: "#ffffff",
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
  },
  typoHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "10px",
    color: "#ffffff",
    position: "relative",
    flexGrow: 1,
  },

  bodyBox: {},
  listRoot: {
    maxHeight: "800px",
    minWidth: "300px",
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "550px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "400px",
    },
  },
  notification: {
    height: "60px",
    backgroundColor: "#f1f3f6",
    paddingTop: "8px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
  },
  notice: {
    "@media only screen and (min-width: 960px)": {
      maxWidth: "480px !important",
      minWidth: "480px",
    },
    height: "100%",
    maxHeight: "600px",
    width: "100%",
    minWidth: "200px",
    maxWidth: "456px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    paddingRight: "2px",
  },
  closeItem: {
    position: "absolute",
    right: "10px",
    width: "40px",
    height: "40px",
    zIndex: 10,
  },
}));

Notification.propTypes = {
  id: PropTypes.any,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  data: PropTypes.object,
};
Notification.defaultProps = {
  data: {},
};

export default memo(Notification);
