import React, { memo } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Stepper,
  Step,
  StepConnector,
  StepLabel,
  StepContent,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import { convertTimeStampToDate } from "utils/date";
import AddingProduct from "./AddingProduct";

const PackageHistory = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const ObjFmPackageType = getLabel(LangConstant.OBJ_FM_PACKAGE_TYPE, { returnObjects: true });
  const ObjFmPackageUsingType = getLabel(LangConstant.OBJ_PACKAGE_USING_TYPE, { returnObjects: true });
  const objColor = {
    [AppConstant.SHOP_PACKAGE.premium]: classes.premium,
    [AppConstant.SHOP_PACKAGE.standard]: classes.standard,
    [AppConstant.SHOP_PACKAGE.freeTrial]: classes.freeTrials,
  };

  const packageData = useSelector(({ paymentRedux }) => paymentRedux.package);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{getLabel(LangConstant.TXT_PLAN_PACKAGE_HISTORY)}</Typography>
      <Stepper
        className={classes.paddingNone}
        orientation="vertical"
        connector={
          <StepConnector classes={{ vertical: classes.paddingNone, lineVertical: classes.lineVerticalStepper }} />
        }
      >
        {packageData.map((item, index) => (
          <Step active key={index}>
            <StepLabel
              StepIconComponent={() => <Radio className={classes.paddingNone} checked color="primary" />}
            >{`${convertTimeStampToDate(item.startDate, AppConstant.FM_DD_MM_YYYY)} - ${convertTimeStampToDate(
              item.expiryDate,
              AppConstant.FM_DD_MM_YYYY,
            )}`}</StepLabel>
            <StepContent className={classes.contentStepper}>
              <Box className={clsx(classes.boxContent, classes.margin)}>
                <Typography className={clsx("medium-md-txt", classes.textLabel)}>
                  {getLabel(LangConstant.TXT_PLAN_PACKAGE)}
                </Typography>
                <Typography className={clsx("medium-md-txt", objColor[item.plan])}>
                  {convertTextPlanUsing(item, ObjFmPackageType, ObjFmPackageUsingType)}
                </Typography>
              </Box>
              <Box className={classes.boxContent}>
                <Typography className={clsx("medium-md-txt", classes.textLabel)}>
                  {getLabel(LangConstant.TXT_ADDITION_PRODUCT)}
                </Typography>
                <AddingProduct data={item.packageDetails} />
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const FM_GUARD = "({0})";

const convertTextPlanUsing = (item, ObjFmPackageType, ObjFmPackageUsingType) => {
  let textPlan = "";
  item.packageDetails.forEach(child => {
    if (
      child.type == AppConstant.PACKAGE_USING_TYPE.bookingFunction ||
      child.type == AppConstant.PACKAGE_USING_TYPE.orderFunction
    ) {
      if (child.default) {
        // Check if type are Booking or Ordering and default value, i will create data with type equals package
        textPlan = StringFormat(FM_GUARD, ObjFmPackageUsingType[child.type]);
        // if value default is create, i will add type not default
        item.packageDetails.forEach(child => {
          if (
            (child.type == AppConstant.PACKAGE_USING_TYPE.bookingFunction ||
              child.type == AppConstant.PACKAGE_USING_TYPE.orderFunction) &&
            !child.default
          ) {
            textPlan = textPlan.replace(")", ` + ${ObjFmPackageUsingType[child.type]}`);
            textPlan = textPlan.replace("(", "");
            return;
          }
        });
      }
    }
  });

  let textPlanUsing =
    textPlan.length > 0
      ? StringFormat(ObjFmPackageType[item.plan], textPlan)
      : StringFormat(ObjFmPackageType[item.plan], textPlan).replace("()", "");
  return textPlanUsing;
};

export default memo(PackageHistory);

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: "50%",
    paddingLeft: 24,
    [theme.breakpoints.down(1250)]: {
      flexBasis: "100%",
      paddingLeft: 0,
      paddingBottom: 48,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    color: "#3e4045",
    marginBottom: 24,
  },
  paddingNone: {
    padding: 0,
  },
  contentStepper: {
    marginTop: 0,
    paddingBottom: 48,
    borderLeft: "1px dashed #d4d5d8",
  },
  lineVerticalStepper: {
    borderLeft: "1px dashed #d4d5d8",
  },
  boxContent: {
    display: "flex",
    alignItems: "start",
  },
  margin: {
    margin: "14px 0",
  },
  textLabel: {
    minWidth: 118,
    marginRight: 24,
  },
  premium: {
    color: "#feba40",
  },
  standard: {
    color: theme.palette.secondary.main,
  },
  freeTrials: {
    color: theme.palette.reservation.seated,
  },
}));
