import React, { forwardRef, memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Checkbox, Box, Button } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import clsx from "clsx";

const MultiSelectMenuItemList = forwardRef((props, ref) => {
  const { onCheckItem, selectedValue, onCheckCategory, selectedCategory, items, category, multiLevel, onShowItem } =
    props;
  const classes = useStyles();
  const [isShowItem, setIsShowItem] = useState(true);

  const onValueCheck = uid => {
    onCheckItem(uid);
  };

  const onCategoryCheck = uid => {
    onCheckCategory(uid);
  };

  const onShowItemInCategory = (uid, condition) => {
    setIsShowItem(condition);
    onShowItem(uid, condition);
  };

  useEffect(() => {
    if (multiLevel) {
      setIsShowItem(category.isShowItem);
    }
  }, [category]);

  return (
    <Box className={classes.menuItemsRoot}>
      {multiLevel ? (
        <Box className={classes.menuItemContainer} ref={ref}>
          <Box className={classes.categoryContentBox}>
            <Box onClick={() => onCategoryCheck(category.uid)} className={classes.categoryContentBox}>
              <Checkbox
                classes={{ root: classes.categoryCheckbox, checked: classes.checked }}
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                value={category.uid}
                checked={selectedCategory.includes(category.uid)}
              />
              <Typography color="inherit" className="semiBold-md-txt">
                {category.name}
              </Typography>
            </Box>
            <Button className={classes.buttonIcon} onClick={() => onShowItemInCategory(category.uid, !isShowItem)}>
              {isShowItem ? <ArrowDropUp className={classes.icon} /> : <ArrowDropDown className={classes.icon} />}
            </Button>
          </Box>
          {items.map(valueItem => (
            <Box
              key={valueItem.uid}
              className={clsx(
                isShowItem
                  ? Boolean(valueItem.isBusy)
                    ? classes.itemContentBoxDisabled
                    : classes.itemContentBox
                  : "hidden",
              )}
              onClick={() => onValueCheck(valueItem.uid)}
            >
              <Checkbox
                classes={{ root: classes.itemCheckbox, checked: classes.checked }}
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                checked={selectedValue.includes(valueItem.uid)}
              />
              <Typography color="inherit" className="regular-md-txt">
                {valueItem.name}
              </Typography>
              <Typography color="inherit" className={clsx("medium-md-txt", classes.numberPeople)}>
                {valueItem.capacity}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box className={classes.menuItemContainer} ref={ref}>
          <Box
            className={clsx(Boolean(category.isBusy) ? classes.itemContentBoxDisabled : classes.itemContentBox)}
            ref={ref}
            onClick={() => onValueCheck(category.uid)}
          >
            <Checkbox
              classes={{ root: classes.itemCheckbox, checked: classes.checked }}
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              checked={selectedCategory.includes(category.uid)}
            />
            <Typography color="inherit" className="regular-md-txt">
              {category.name}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
});

MultiSelectMenuItemList.propTypes = {
  onCheckItem: PropTypes.func,
  onCheckCategory: PropTypes.func,
  selectedValue: PropTypes.array,
  selectedCategory: PropTypes.array,
  multiLevel: PropTypes.bool,
};

MultiSelectMenuItemList.defaultProps = {
  onCheckItem: () => {},
  onCheckCategory: () => {},
  selectedValue: [],
  selectedCategory: [],
  multiLevel: false,
};

export default memo(MultiSelectMenuItemList);

const useStyles = makeStyles(theme => ({
  menuItemContainer: {
    "&:not(:last-child)": {
      borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    },
  },
  categoryContentBox: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  itemContentBox: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  itemContentBoxDisabled: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
    backgroundColor: "#fee9da",
  },
  numberPeople: {
    position: "absolute",
    right: 20,
  },
  buttonIcon: {
    position: "absolute",
    right: 0,
    minWidth: 20,
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  categoryCheckbox: {
    color: "#4b4d53",
  },
  itemCheckbox: {
    color: "#979aa1",
  },
  checked: {
    color: `${theme.palette.text.link} !important`,
  },
  icon: {
    color: "#6c7078",
  },
}));
