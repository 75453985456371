import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon, withStyles } from "@material-ui/core";

const PlaceStatusIcon = ({ width, height, ...otherProps }) => {
  const SvgIconStyled = withStyles({
    root: { width: width, height: height },
  })(props => <SvgIcon {...props} {...otherProps} />);

  return (
    <SvgIconStyled width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1179 -86) translate(1179 86)">
          <rect width="29" height="29" x=".5" y=".5" stroke="#D4D5D8" rx="2" />
          <g transform="translate(5 5)">
            <path d="M0 0L20 0 20 20 0 20z" />
            <path
              fill="#6C7078"
              d="M10 1.667c-3.225 0-5.833 2.608-5.833 5.833C4.167 11.875 10 18.333 10 18.333s5.833-6.458 5.833-10.833c0-3.225-2.608-5.833-5.833-5.833zM5.833 7.5C5.833 5.2 7.7 3.333 10 3.333S14.167 5.2 14.167 7.5c0 2.4-2.4 5.992-4.167 8.233C8.267 13.508 5.833 9.875 5.833 7.5z"
            />
            <circle cx="10" cy="7.5" r="2.083" fill="#6C7078" />
          </g>
        </g>
      </g>
    </SvgIconStyled>
  );
};

PlaceStatusIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
PlaceStatusIcon.defaultProps = {
  width: 30,
  height: 30,
};

export default memo(PlaceStatusIcon);
