import React, { memo, useState, useEffect, useLayoutEffect } from "react";
import { makeStyles, Paper, Container, Box } from "@material-ui/core";
import { BglobalJSC } from "../../components";
import { OptionBar, BodyTable, PaginationFooter } from "./components";
import { AppConstant, LangConstant } from "../../const";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import StaffTableAction from "../../redux/staff.redux";
import ShopInfoAction from "../../redux/shop-info.redux";
const MnStaff = () => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const headData = LangConstant.ARR_MANAGE_STAFF;
  const data = useSelector(state => state.staffRedux.data);
  const workingShift = useSelector(state => state.staffRedux.workingShift);
  const staffPosition = useSelector(state => state.staffRedux.staffPosition);
  const staff = useSelector(state => state.staffRedux.dataEditStaff);
  const isSuccessEdit = useSelector(state => state.staffRedux.isSuccessEditStaff);
  const isSuccessCreate = useSelector(state => state.staffRedux.isSuccessCreateStaff);
  const isSuccessDeleteStaff = useSelector(state => state.staffRedux.successDeleteStaff);
  const dispatch = useDispatch();
  const [showData, setShowData] = useState(null);
  const [showTime, setShowTime] = useState([]);
  const [showPosition, setShowPosition] = useState([]);
  const [infoPage, setInfoPage] = useState(null);
  const [page, setPage] = useState(0);
  const [searchForm, setSearchForm] = useState({
    page: 1,
    paging: 1,
    size: 30,
    role: null,
    workingShift: null,
    staffPosition: null,
    state: null,
    isUser: null,
    orderBy: null,
    asc: null,
  });

  useLayoutEffect(() => {
    dispatch(StaffTableAction.requestStaffTable(searchForm));
    dispatch(StaffTableAction.requestWorkingShift({}));
    dispatch(StaffTableAction.requestStaffPosition({}));
    dispatch(ShopInfoAction.getShopInfoData({}));
    if (isSuccessDeleteStaff) {
      dispatch(StaffTableAction.requestStaffTable(searchForm));
      dispatch(StaffTableAction.resetStaff());
    }
  }, [isSuccessDeleteStaff]);

  const showFilter = [
    getLabel(LangConstant.TXT_FULL_NAME),
    getLabel(LangConstant.TXT_ROLE),
    getLabel(LangConstant.TXT_BIRTH_DAY),
    getLabel(LangConstant.TXT_SEX),
    getLabel(LangConstant.TXT_STARTED_DATE),
    getLabel(LangConstant.TXT_CODE_STAFF),
  ];

  const showDefault = [
    getLabel(LangConstant.TXT_FULL_NAME),
    getLabel(LangConstant.TXT_ROLE),
    getLabel(LangConstant.TXT_CODE_STAFF),
    getLabel(LangConstant.TXT_WORK_TIME),
    getLabel(LangConstant.TXT_POSITION),
    getLabel(LangConstant.TXT_ACCOUNT_STATUS),
  ];

  const [displayColumn, setDisplayColumn] = useState(
    localStorage.getItem(AppConstant.KEY_DISPLAY_COLUMN)
      ? JSON.parse(localStorage.getItem(AppConstant.KEY_DISPLAY_COLUMN))
      : showDefault,
  );

  useEffect(() => {
    if (isSuccessEdit) {
      setShowData(showData.map(x => (x.uid === staff.uid ? staff : x)));
      dispatch(dispatch(StaffTableAction.resetSuccess({})));
    }
  }, [isSuccessEdit]);

  useEffect(() => {
    if (isSuccessCreate) {
      dispatch(StaffTableAction.requestStaffTable(searchForm));
      dispatch(StaffTableAction.resetStaff());
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    if (data && data.data) {
      setShowData(data.data);
    }
    if (data) {
      setInfoPage(data);
    }
  }, [data]);

  useEffect(() => {
    if (workingShift && workingShift.data) {
      setShowTime(workingShift.data);
    }
  }, [workingShift]);

  useEffect(() => {
    if (staffPosition && staffPosition.data) {
      setShowPosition(staffPosition.data);
    }
  }, [staffPosition]);

  return (
    <Box className="hidden-scroll">
      <Paper elevation={1} square>
        <Container className={classes.rootContainer}>
          <Box className={classes.boxMain}>
            <OptionBar
              showTime={showTime}
              showPosition={showPosition}
              setPage={setPage}
              searchForm={searchForm}
              setSearchForm={setSearchForm}
            />
            <BodyTable
              showFilter={showFilter}
              showData={showData}
              setShowData={setShowData}
              showTime={showTime}
              showPosition={showPosition}
              headData={headData}
              displayColumn={displayColumn}
              searchForm={searchForm}
              setSearchForm={setSearchForm}
            />
            <PaginationFooter
              showData={infoPage}
              headData={headData}
              displayColumn={displayColumn}
              setDisplayColumn={setDisplayColumn}
              showDefault={showDefault}
              page={page}
              setPage={setPage}
            />
          </Box>
        </Container>
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  rootContainer: {
    maxWidth: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  boxMain: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    padding: "20px 0 20px",
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));

export default memo(MnStaff);
