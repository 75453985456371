export const KEY_TOKEN = "token";
export const KEY_MULTI_TOKEN = "multi_token";
export const KEY_GBOSS = "gboss-shop";
export const KEY_GBOSS_LOGIN = "gboss-shop-select";
export const KEY_GBOSS_CONS_ACCESS = "cons-access";
export const NAME_LOGIN = "name-login";
export const ROLE = "role";
export const KEY_BOOK_CUSTOMER = "customer";
export const KEY_BOOK_CUSTOMER_CHILD = "child";
export const KEY_BOOK_PERIODIC = "periodic";
export const KEY_BOOK_PLACE = "place";
export const KEY_BOOK_SERVICE = "service";
export const KEY_BOOK_USING_TIME = "usingTime";
export const KEY_BOOK_USING_TIME_SETTING_TIME = "usingTimeSettingTime";
export const KEY_BOOK_END_TIME = "endTime";
export const KEY_BOOK_DEPOSIT = "deposit";
export const KEY_BOOK_PRICE = "price";
export const KEY_BOOK_TAX_INCLUSIVE_F = "taxInclusiveF";
export const KEY_BOOK_SERVICE_USING_TIME = "serviceUsingTime";
export const KEY_BOOK_NOTE = "note";
export const KEY_BOOK_PRODUCT = "product";
export const KEY_BOOK_HELPER_NOTE_HOLDER = "guideNote";
export const EXPIRES_TOKEN = 9;
export const FACEBOOK_LINKED = "fb_token";
export const KEY_EMAIL = "email";
export const KEY_NAME = "name";
export const KEY_DAY = "day";
export const KEY_MONTH = "month";
export const KEY_YEAR = "year";
export const KEY_SEX = "gender";
export const KEY_USERNAME = "username";
export const KEY_PHONE = "phone_number";
export const KEY_OLD = "password";
export const KEY_NEW = "newPassword";
export const KEY_CONFIRM = "confirmPassword";
export const KEY_BIRTHDAY = "birthday";
export const KEY_USER_ID = "userId";
export const KEY_DATA_USER = "dataUser";
export const KEY_AVATAR = "avatar";
export const KEY_SHOP_NAME = "shopName";
export const KEY_SHOP_AVATA = "cover";
export const KEY_SHOP_PHONE = "phoneNumber";
export const KEY_SHOP_ADDRESS = "address";
export const KEY_SHOP_WELCOME_SPEECH = "description";
export const KEY_SHOP_INVITATION = "invitation";
export const KEY_SHOP_INVITATION_TYPE = "type";
export const KEY_REGISTER = "register";
export const KEY_SHOP_CLOSE_POPUP = "close";
export const KEY_ACTION = "action";
export const KEY_DISPLAY_COLUMN = "displayColumn";
export const KEY_OTHER_INFORMATION = "otherInformation";
export const KEY_START_TIME = "start_time";
export const KEY_UID = "uid";
export const KEY_REMOTE_ORDER = "remoteOrder";

export const GOOGLE_API_KEY = "AIzaSyC3K5v-EbeVSAKKACBdPjkBhQUvXeO8z8U";
export const ACCEPT_EXCEL = [".xlsx", ".xls", ".csv"];
export const MIME_EXCEL_TYPE = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/csv",
];

// day-month-year
export const FORMAT_BIRTHDAY = "{0}-{1}-{2}";

export const FM_YYYY_MM_DD = "yyyy/MM/dd";
export const FM_DD_MM = "dd/MM";
export const FM_DD_MM_YYYY = "dd/MM/yyyy";
export const FM_HH_MM = "HH:mm";
export const FM_HH_MM_DD_MM_YYYY = "HH:mm dd/MM/yyyy";
export const FM_HH_MM_DD_MM = "HH:mm dd/MM";
export const FM_DD_MM_YYYY_SECOND = "dd-MM-yyyy";

export const STT_RESERVATION = {
  pending: 1,
  confirm: 2,
  seated: 3,
  active: 4,
  finished: 5,
  cancel: 6,
  reject: 7,
  waitingPayment: 8,
};

export const STT_VAT_BILL = {
  pending: 1,
  transferred: 4,
  billExported: 5,
};

export const STT_RESERVATION_ACTIVE = [
  STT_RESERVATION.pending,
  STT_RESERVATION.confirm,
  STT_RESERVATION.seated,
  STT_RESERVATION.active,
  STT_RESERVATION.waitingPayment,
];

export const SETTING_RESERVATION_TYPE = {
  1: `${KEY_BOOK_PLACE}`,
  2: `${KEY_BOOK_SERVICE}`,
  3: `${KEY_BOOK_PRODUCT}`,
  4: `${KEY_BOOK_CUSTOMER}`,
  5: `${KEY_BOOK_PERIODIC}`,
  6: `${KEY_BOOK_NOTE}`,
  7: `${KEY_BOOK_USING_TIME}`,
  8: `${KEY_BOOK_END_TIME}`,
  9: `${KEY_BOOK_DEPOSIT}`,
  10: `${KEY_REMOTE_ORDER}`,
  11: `${KEY_BOOK_PRICE}`,
  12: `${KEY_BOOK_TAX_INCLUSIVE_F}`,
  13: `${KEY_BOOK_SERVICE_USING_TIME}`,
};

export const SETTING_ADVANCES = [
  `${KEY_BOOK_CUSTOMER}`,
  `${KEY_BOOK_PERIODIC}`,
  `${KEY_BOOK_PLACE}`,
  `${KEY_BOOK_SERVICE}`,
  `${KEY_BOOK_USING_TIME}`,
  `${KEY_BOOK_END_TIME}`,
  `${KEY_BOOK_DEPOSIT}`,
  `${KEY_BOOK_PRICE}`,
  `${KEY_BOOK_TAX_INCLUSIVE_F}`,
  `${KEY_BOOK_SERVICE_USING_TIME}`,
  `${KEY_BOOK_NOTE}`,
];
export const SETTING_BASICS = [`${KEY_BOOK_NOTE}`];

export const VALUE_THREAD_HOLD_RETRY_REQUEST = 3;
export const VALUE_RETRY_REQUEST_TIME_BASE = 2000;
export const VALUE_AMOUNT_NUMBER_PLACE_ITEM = 100;
export const SIZE_SCREEN_XL = 1920;

export const ACCEPT_IMAGE = ["image/png", "image/jpeg", "image/svg+xml"];

//Gender
export const GENDER_PICKER = {
  male: 1,
  female: 2,
  other: 3,
};

// role shop
export const SHOP_OWNER = "2"; // Chủ cửa hàng
export const SHOP_MANAGER = "3"; //  Quản lý
export const SHOP_STAFF = "4"; // Nhân viên

export const FILE_NAME_EXPORT_BOOKING = "Booking.xlsx";
export const FILE_NAME_EXPORT_BILL = "VATBill.xlsx";
export const TYPE_CSV = "text/plain;charset=utf-8";
export const UNIVERSAL_BOM = "\uFEFF";

//
export const NOTIFICATION_TYPE = {
  news: 1, // Có tin tức mới
  reservation_new: 2, // Thông báo có đơn đặt chỗ mới
  reservation_info_updated: 3, // Thông báo thay đổi thông tin đặt chỗ
  reservation_status_updated: 4, // Thông báo thay đổi trạng thái tin đặt chỗ
  role_updated: 5, // Thay đổi quyền
  invite_consumer: 6, // Mời trở thành consumer của shop
  invite_reservation: 7, // Mời tham gia reservation
  invite_reservation_status: 8, // Trạng thái mời tham gia reservation
  reservation_extend: 9, // Mở rộng đặt chỗ
  reservation_assign: 10, // Chỉ định nhân viên
  payment: 11,
};

export const ERROR_CODE = {
  invalid_birthday: 1,
  empty_field: 2,
  invalid_phone: 3,
};

export const DATE_VALID_CODE = {
  future: 1,
  past: 0,
};

export const STT_AVAILABLE = {
  unavailable: 0,
  available: 1,
};

export const STATE = {
  active: 1,
  inactive: 2,
  delete: 3,
};

export const TAB_BOOKING_DETAIL = {
  reservation: 0,
  preOrder: 1,
  shopConsumer: 2,
  periodic: 3,
};

export const PAYMENT_TYPE = {
  byOption: 1,
  byPackage: 2,
};

export const ARR_REPEAT_TIME = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const PAGE_SIZE_DEFAULT = 30;
export const CATEGORY_TYPE = {
  place: 1,
  service: 2,
  product: 3, // product-category, product-item
};

export const DISPLAY_CHART_SETTING_TYPE = {
  reportByPlace: 1,
  reportByService: 2,
  reportByProduct: 3, // product-category, product-item
  reportByReservation: 4,
  reportByCustomer: 5,
};

export const CHART_VIEW_BY = {
  hour: 1,
  day: 2,
  week: 3,
  month: 4,
};

export const ReservationViewBy = {
  day: 0,
  week: 1,
  month: 2,
};

export const CHART_COLORS = ["#ef5845", "#ffab6b", "#ffb4b1", "#8f7fe4", "#00b49d", "#447aff"];

export const MINIMUM_NUMBER_DISPLAY_SETTINGS = 6;

export const CHART_VIEW_MODE = {
  overview: 1,
  detail: 2,
};

export const ROLE_TYPE = {
  1: "TXT_BGLOBAL",
  2: "TXT_SHOP_OWNER",
  3: "TXT_MANAGER",
  4: "TXT_STAFF",
  5: "TXT_SHOP_CONSUMER",
};

export const TYPE_RESERVATION_DETAILS = {
  place: 1,
  staff: 2,
};

export const TYPE_DETAILS_PRE_ORDER = {
  category: 1,
  menu: 2,
};

export const DISPLAY_SETTING_BOOKING_MANAGEMENT_TYPE = 6;
export const DISPLAY_SETTING_BOOKING_DETAIL_IDS = {
  service: 1,
  place: 2,
  staff: 3,
  consumerNumber: 4,
};

export const QUALITY_IMAGE = 0.8;

export const PRODUCT_URL = "https://gboss.io/";
export const FM_SHOP_SHORT_LINK = "{0}.gboss.io"; // 0 is shop's alias
export const FM_BOOKING_SHORT_LINK = "cons.gboss.io/shops/{0}"; // 0 is shop's alias
export const FM_BOOKING_SHORT_LINK_URL = "https://cons.gboss.io/shops/{0}"; // 0 is shop's alias
export const FM_SHOP_FB_SHORT_LINK = "facebook.com/{0}"; // 0 is shop's alias
export const FM_SHOP_GMB_SHORT_LINK = "google.com/maps/{0}"; // 0 is shop's alias

export const TWO_LINE_HEIGHT = 40;

export const TYPE_PRODUCT_ICON = {
  notMarked: 1,
  marked: 2,
};

export const DATA_TYPE = {
  booking: 1,
  order: 2,
};

export const TYPE_CATEGORY = {
  default: 0,
  service: 2,
  product: 3,
  combo: 4,
  service_info: 5,
  product_info: 6,
};

export const TYPE_BUTTON_CATEGORY = {
  deleted: 1,
  removed: 2,
  choose: 3,
  removedSingle: 4,
};

export const TYPE_TAX = {
  noTax: 0,
  fivePercent: 1,
  tenPercent: 2,
};

export const TAX_TYPE_VALUE = {
  0: 0,
  1: 0.05,
  2: 0.1,
};

export const CHECKBOX_INPUT_PRICE = {
  before: 0,
  after: 1,
};

export const TYPE_TIME_USED = {
  minute: 1,
  hour: 2,
  day: 3,
};

export const TYPE_TIME_USED_CALENDAR = {
  minute: 1,
  hour: 2,
};

export const TYPE_UNIT_PRICE_CALCULATE = {
  times: 2,
  time: 1,
};

export const TYPE_DEPOSIT = {
  currency: 1,
  percent: 2,
};

export const AMOUNT_THRESHOLD = 100000;

export const RESERVATION_SETTING_OPTION = {
  place: 1,
  service: 2,
  product: 3,
  customer: 4,
  periodic: 5,
  note: 6,
  usingTime: 7,
  endTime: 8,
  deposit: 9,
  order: 10,
  price: 11,
  taxInclusiveF: 12,
  serviceUsingTime: 13,
  useBooking: 14,
  useOrdering: 15,
  currentPackage: 16,
};

export const SHOP_PACKAGE = {
  freeTrial: 1,
  standard: 2,
  premium: 3,
  business: 4,
};

export const STATUS_PAYMENT = {
  paid: 1,
  unPaid: 2,
  canceled: 3,
};

export const DEPOSIT_TYPE = {
  money: 1,
  percent: 2,
};
export const OVER_DATED_TIME = 10 * 60; // Limiting time of reservation

export const ACTION_TYPE = {
  create: 1,
  edit: 2,
  changeStatus: 3,
  assign: 4,
  copy: 5,
  extend: 6,
  ordering: 8,
};

export const RESERVATION_TAB_INDEX = {
  booking: 0,
  order: 1,
};
export const DEBOUNCE_TIMEOUT = 500;

export const STT_PUBLISH = {
  private: 0,
  publish: 1,
};

export const NOTIFICATION_REQUEST_INTERVAL_TIME = 30000;

export const READ_TYPE_NOTIFICATION = {
  seenDetail: 1,
  confirmAll: 2,
};

export const ITEM_TYPE = {
  serviceItem: 2,
  productItem: 3,
  comboItem: 4,
};

export const PACKAGE_USING_TYPE = {
  package: 1,
  shop: 2,
  orderFunction: 3,
  bookingFunction: 4,
  item: 5,
  staff: 6,
  place: 7,
};

export const PAYMENT_FOR = {
  features: 1,
  package: 2,
};

export const PAYMENT_STATUS = {
  paid: 1,
  unpaid: 2,
};

export const RATING_SCORE_ARRAY = [5, 4, 3, 2, 1];

// Item Url
export const ITEM_DETAIL_URL = "?tab=1&name={0}&item={1}";
export const INVITATION_METHOD = {
  email: 1,
  sms: 2,
};
export const GBOSS_COORDINATE = {
  latitude: 21.019282,
  longitude: 105.79109,
};

export const STEP_ARR_ORDER_PAGE = ["TXT_PRODUCT", "TXT_INFORMATION"];

export const LIMITED_PRICE_NUMBER = 10000000000;
