import React, { memo, useEffect, useRef, useState } from "react";
import {
  Typography,
  makeStyles,
  OutlinedInput,
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { textNormalize } from "utils";
import { ReportProblemOutlined } from "@material-ui/icons";
import { HoverMessage } from "components";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import clsx from "clsx";

const InfoDropdown = ({ label, value, array, nameOption, onChange, isEdit, isCopying, detail, classes }) => {
  const defaultClasses = useStyles();

  const [assign, setAssign] = useState(detail && detail.assign ? detail.assign : {});
  const [service, setService] = useState(detail && detail.service ? detail.service : {});
  const [staff, setStaff] = useState(detail && detail.staff ? detail.staff : {});

  useEffect(() => {
    if (nameOption === PLACE) {
      if (assign) onChange(assign);
    } else if (nameOption === SERVICE) {
      if (service) onChange(service);
    } else {
      if (staff) onChange(staff);
    }
  }, [assign, service, staff]);

  return (
    <>
      <Box className={clsx(classes.rootBox, defaultClasses.rootLine)}>
        <Typography className={clsx(defaultClasses.titleLine, classes.title)}>{label}</Typography>
        {isEdit || isCopying ? (
          <InfoEditDrop
            text={value}
            array={array}
            nameOption={nameOption}
            assign={assign}
            service={service}
            staff={staff}
            setStaff={setStaff}
            setService={setService}
            setAssign={setAssign}
            classes={classes}
          />
        ) : (
          <Typography className={defaultClasses.contentLine}>{value}</Typography>
        )}
      </Box>
    </>
  );
};

const InfoEditDrop = ({
  text,
  array,
  assign,
  service,
  staff,
  setStaff,
  setService,
  setAssign,
  nameOption,
  classes,
}) => {
  const defaultClasses = useStyles();
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState(text);
  const [filterArray, setFilterArray] = useState([]);

  const getFilterArray = (value, search, nameOption) => {
    let filterItems = [];
    if (nameOption === PLACE || (value.items && value.items.length > 0)) {
      filterItems = value?.items?.filter(item => textNormalize(item.name).includes(search));
    }
    value.items = filterItems;
    return textNormalize(value.name).includes(search) || filterItems.length > 0;
  };
  const onShowFilterArray = search => {
    var searchValue = textNormalize(search);
    const cloneArray = JSON.parse(JSON.stringify(array));
    const filteredArray = Boolean(search)
      ? cloneArray.filter(category => getFilterArray(category, searchValue, nameOption))
      : cloneArray;
    setFilterArray(filteredArray);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelectItem = selectedItem => {
    setSearchValue(selectedItem.name);
    if (nameOption === PLACE) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].uid === selectedItem.categoryUid || array[i].uid === selectedItem.uid) {
          setAssign({
            ...assign,
            categoryName: array[i].name,
            categoryUid: array[i].uid,
            itemName: array[i].items && array[i].items.length > 0 ? selectedItem.name : null,
            itemUid: array[i].items && array[i].items.length > 0 ? selectedItem.uid : null,
            name: selectedItem.name,
            uid: selectedItem.uid,
          });
        }
      }
    } else if (nameOption === SERVICE) {
      setService({
        ...service,
        categoryName: selectedItem.name,
        categoryUid: selectedItem.uid,
      });
    } else {
      setStaff({
        ...staff,
        name: selectedItem.name,
        uid: selectedItem.uid,
      });
    }
    onClose();
  };

  useEffect(() => {
    setFilterArray(array);
  }, [array]);

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      if (array) {
        onShowFilterArray(searchValue);
      }
    }, 500);
    return () => clearTimeout(searchTimeout);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(text);
  }, [text]);

  return (
    <>
      <OutlinedInput
        fullWidth
        value={searchValue}
        classes={{
          input: defaultClasses.input,
          root: `${defaultClasses.inputRoot} ${classes.inputRoot}`,
          multiline: defaultClasses.multiline,
          inputMultiline: defaultClasses.inputMultiline,
        }}
        ref={dropdownRef}
        onChange={event => {
          setSearchValue(event.target.value);
          setAnchorEl(dropdownRef.current);
        }}
        onClick={() => setAnchorEl(dropdownRef.current)}
      />
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{ paper: defaultClasses.infoDropdown }}
      >
        {filterArray.map((entry, index) => (
          <DropdownCategory
            data={entry}
            isDivider={index < filterArray.length - 1}
            key={index}
            onChange={onSelectItem}
          />
        ))}
      </Popover>
    </>
  );
};

const DropdownCategory = ({ data, isDivider, onChange }) => {
  const { items, name, isBusy } = data;

  const [anchorEl, setAnchorEl] = useState(null);
  const defaultClasses = useStyles();

  const isSubMenu = Boolean(items && items.length > 0);
  const onShow = event => {
    if (isSubMenu) setAnchorEl(event.currentTarget);
    else {
      onSelectItem(data);
    }
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelectItem = selectedItem => {
    if (onChange) onChange(selectedItem);
    onClose();
  };

  return (
    <>
      <List className={defaultClasses.rootList} component="div" disablePadding>
        <DropdownItem item={name} onClick={onShow} isDivider={isDivider} isBusy={isBusy} />
      </List>
      {isSubMenu && (
        <Popover
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className={defaultClasses.rootPopover}
        >
          <List className={defaultClasses.rootList} component="div" disablePadding>
            {items.map((item, index) => (
              <DropdownItem
                item={item.name}
                isBusy={item.isBusy}
                isDivider={index < items.length - 1}
                key={index}
                onClick={() => onSelectItem(item)}
              />
            ))}
          </List>
        </Popover>
      )}
    </>
  );
};

const DropdownItem = ({ item, onClick, isDivider, isBusy }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();
  const onEventClick = event => {
    if (Boolean(onClick)) onClick(event);
  };

  return (
    <>
      <ListItem
        className={defaultClasses.buttonListItem}
        button
        onClick={event => {
          onEventClick(event);
        }}
      >
        <ListItemText
          primary={
            <Typography variant="body1" classes={{ body1: "medium-md-txt" }} color="inherit">
              {item}
            </Typography>
          }
        />
        {Boolean(isBusy) && <BusyWarning label={getLabel(LangConstant.TXT_BUSY_AT_THIS_TIME)} />}
      </ListItem>
      {isDivider && <Divider className={defaultClasses.rootDivider} />}
    </>
  );
};

const BusyWarning = ({ label }) => {
  const defaultClasses = useStyles();
  const busyMessageRef = useRef();
  const [HoverMessageAnchor, setHoverMessageAnchor] = useState(null);
  return (
    <>
      <IconButton
        ref={busyMessageRef}
        classes={{ root: defaultClasses.warningIconBtn }}
        onMouseEnter={() => {
          setHoverMessageAnchor(busyMessageRef.current);
        }}
        onMouseLeave={() => {
          setHoverMessageAnchor(null);
        }}
      >
        <ReportProblemOutlined className={defaultClasses.warningIcon} />
      </IconButton>

      <HoverMessage message={label} anchorEl={HoverMessageAnchor} />
    </>
  );
};
const useStyles = makeStyles(theme => ({
  rootLine: {
    width: "100%",
    height: 30,
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  titleLine: {
    minWidth: 82,
    fontSize: 13,
  },
  contentLine: {
    marginLeft: 88,
    height: 22,
    fontSize: 13,
  },
  inputRoot: {
    marginLeft: 80,
    paddingLeft: 0,
    borderRadius: 4,
    marginBottom: "0",
  },
  input: {
    padding: "4px 16px",
    height: 20,
    fontSize: 13,
  },
  multiline: {
    padding: "inherit",
  },
  inputMultiline: {
    height: "auto",
  },
  rootPopover: {
    marginLeft: 2,
  },
  infoDropdown: {
    maxHeight: 150,
  },
  rootList: {
    maxHeight: 140,
    overflow: "auto",
  },
  buttonListItem: {
    padding: "0 10px",
    minHeight: 30,
    width: 311,
    color: theme.palette.grey[400],
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
      color: "white",
    },
  },
  rootDivider: {
    color: theme.palette.grey[800],
  },
  warningIcon: {
    color: "#feba40",
    width: 18,
  },
  warningIconBtn: {
    padding: 0,
  },
}));

InfoDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  array: PropTypes.array,
  onChange: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default memo(InfoDropdown);

export const PLACE = "place";

export const SERVICE = "service";
