import React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  makeStyles,
  OutlinedInput,
  Popover,
  Typography,
} from "@material-ui/core";
import { LangConstant } from "const";
import { Close } from "@material-ui/icons";
import { getCommonKey } from "utils";
import { useTranslation } from "react-i18next";

const AddNoteDialog = ({ isAddingNote, onCloseAddingNoteModal, dialogAnchor, onAddInternalNote, setInternalNote }) => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  return (
    <Dialog open={isAddingNote} onClose={onCloseAddingNoteModal}>
      <Popover
        open={isAddingNote}
        onClose={onCloseAddingNoteModal}
        anchorEl={dialogAnchor}
        classes={{ paper: classes.addNotePopover }}
      >
        <Card className={classes.addNoteDialog}>
          <AppBar className={classes.addNoteDialogTitle}>
            <CardHeader
              title={getLabel(LangConstant.TXT_ADD_NOTE_DIALOG_TITLE)}
              classes={{
                root: classes.rootHeader,
                action: classes.actionHeader,
                content: classes.contentHeader,
                title: classes.titleHeader,
              }}
              action={
                <IconButton className={classes.iconClose} onClick={onCloseAddingNoteModal}>
                  <Close />
                </IconButton>
              }
            />
          </AppBar>
          <CardContent className={classes.addNoteDialogContent}>
            <Typography className={classes.addNoteContent}>{getLabel(LangConstant.TXT_CONTENT)}</Typography>
            <OutlinedInput
              classes={{ root: classes.inputNote, input: classes.inputNoteTextField }}
              multiline={true}
              onChange={event => {
                setInternalNote(event.target.value);
              }}
            />
          </CardContent>
          <CardActions className={classes.addNoteDialogAction}>
            <Button
              onClick={onCloseAddingNoteModal}
              classes={{ root: classes.buttonCancelFooter, label: classes.labelFooter }}
            >
              {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
            </Button>
            <Button
              onClick={onAddInternalNote}
              classes={{
                root: classes.buttonRootFooter,
                label: classes.labelFooter,
              }}
            >
              {getLabel(getCommonKey(LangConstant.TXT_SAVE_BUTTON))}
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  isAddingNote: PropTypes.bool,

  dialogAnchor: PropTypes.any,

  onCloseAddingNoteModal: PropTypes.func,
  onAddInternalNote: PropTypes.func,
  setInternalNote: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  rootHeader: {
    position: "relative",
    padding: "15px 24px",
    color: theme.palette.white,
    height: 54,
    marginBottom: 1,
  },

  contentHeader: {
    flex: "none",
  },

  actionHeader: {
    marginTop: 0,
    marginLeft: "auto",
    marginRight: 0,
  },

  titleHeader: {
    ...theme.typography.subtitle2,
    color: theme.palette.white,
    fontSize: 20,
    fontWeight: 500,
    "@media only screen and (max-width: 375px)": {
      fontSize: 16,
    },
  },

  iconClose: {
    color: theme.palette.white,
    padding: 0,
  },

  buttonRootFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.8,
      color: "unset",
    },
  },

  buttonCancelFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.black,
  },

  labelFooter: {
    width: "100%",
    height: 24,
    marginTop: 3,
    marginBottom: 3,
  },

  addNoteDialog: {
    margin: "auto",
    position: "relative",
    height: "auto",
    width: 450,
    "@media only screen and (max-width: 500px)": {
      width: 300,
    },
  },

  addNoteDialogTitle: {
    position: "relative",
    background: theme.palette.reservation.active,
  },

  addNoteDialogContent: {
    display: "grid",
    padding: 20,
  },

  inputNote: {
    marginTop: 20,
    height: 70,
    fontSize: 13,
    fontWeight: "normal",
  },

  inputNoteTextField: {
    maxHeight: 70,
    overflow: "auto !important",
  },

  addNoteDialogAction: {
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },

  addNoteContent: {
    fontSize: 12,
    fontWeight: 600,
  },

  addNotePopover: {
    borderRadius: 4,
    backgroundColor: "transparent",
  },
}));

export default AddNoteDialog;
