import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const CustomSearchBar = ({ placeholder, className, ...otherProps }) => {
  const classes = useStyles();

  return (
    <OutlinedInput
      placeholder={placeholder}
      type="text"
      className={className}
      classes={{
        input: `${classes.input} medium-md-txt`,
        root: `${classes.root} `,
        notchedOutline: classes.notchedOutline,
      }}
      startAdornment={
        <InputAdornment position="start" className={classes.icon}>
          <SearchIcon className={classes.customSearchIcon} />
        </InputAdornment>
      }
      {...otherProps}
    />
  );
};
const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    width: "100%",
    border: `1px solid ${theme.palette.grey["200"]}`,
  },
  notchedOutline: {
    border: "none",
  },
  input: {
    height: "unset",
    padding: "0",
    color: theme.palette.text.primary,
    "&::placeholder": {},
    "@media only screen and (max-width: 414px)": {
      padding: "0 10px",
    },
  },
  icon: {
    minWidth: "60px",
    width: "60px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "30px",
    justifyContent: "center",
    marginRight: 0,
    "@media only screen and (max-width: 414px)": {
      minWidth: "40px",
      width: "40px",
    },
  },
  customSearchIcon: {
    fontSize: 24,
    color: theme.palette.grey["700"],
  },
}));
CustomSearchBar.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
CustomSearchBar.defaultProps = { placeholder: "", className: "" };
export default memo(CustomSearchBar);
