import React, { memo, useEffect, useState } from "react";
import { ApiConstant, PathConstant } from "const";
import { Alert, DialogNotice } from "./dialog";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import ErrorAction from "redux/error.redux";
import { removeAllCookie } from "utils";

function ErrorHandling() {
  const history = useHistory();
  const dispatch = useDispatch();

  // Get data from redux
  const errorRedux = useSelector(({ errorRedux }) => errorRedux);

  // Create state
  const [isError, setIsError] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const onLogout = () => {
    removeAllCookie(Cookie);
    history.push(PathConstant.LOGIN);
  };

  useEffect(() => {
    const { error, status } = errorRedux;
    if (error && String(error).length > 0) {
      let isAuthenticate = status === ApiConstant.STT_UNAUTHORIZED || status === ApiConstant.STT_FORBIDDEN;
      setIsAuthenticate(isAuthenticate);
      setIsError(!isAuthenticate);
      setErrorContent(error);
    }
  }, [errorRedux]);

  useEffect(() => {
    if (!isError && errorContent) {
      setTimeout(() => {
        // After show error, reset
        dispatch(ErrorAction.resetError());
      }, 1000);
    }
  }, [isError, errorContent, dispatch]);

  return isAuthenticate ? (
    <DialogNotice
      isShow={isAuthenticate}
      message="Bạn cần đăng nhập lại"
      onClose={() => setIsAuthenticate(false)}
      onSubmit={() => onLogout()}
    />
  ) : (
    <Alert isShow={isError} onClose={() => setIsError(false)} type="error" message={errorContent} />
  );
}

export default memo(ErrorHandling);
