import React, { memo, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import {
  calculatePriceTaxIn,
  deepCloneJsonObject,
  formatVietnameseCurrency,
  isObjectNotEqual,
  putCursorBeforeTheLastCharacter,
  removeCurrencyFormat,
} from "utils";

const TwoClassificationTable = ({ data, arrHeader, isContainStockColumn, taxTypeValue, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const [dataTable, setDataTable] = useState(data);

  const onChangePrice = (event, indexModel, indexItem) => {
    let newPrice = event.target.value;
    newPrice = parseInt(removeCurrencyFormat(newPrice));
    let arrNewItem = deepCloneJsonObject(dataTable.modelList);
    let itemNew = { ...arrNewItem[indexModel].groupModel.modelList[indexItem].item };

    if (isNaN(newPrice)) {
      itemNew.price = "";
      itemNew.priceTaxIn = "";
    } else if (newPrice < AppConstant.LIMITED_PRICE_NUMBER) {
      itemNew.price = newPrice;
      itemNew.priceTaxIn = calculatePriceTaxIn(newPrice, taxTypeValue);
    }

    itemNew.tax = itemNew.priceTaxIn - itemNew.price;
    arrNewItem[indexModel].groupModel.modelList[indexItem].item = itemNew;

    setDataTable({ ...dataTable, modelList: arrNewItem });
    onChange({ ...dataTable, modelList: arrNewItem });
  };

  const onChangeStock = (event, indexModel, indexItem) => {
    let newStock = parseInt(event.target.value);
    let arrNewItem = deepCloneJsonObject(dataTable.modelList);
    let itemNew = { ...arrNewItem[indexModel].groupModel.modelList[indexItem].item };

    if (isNaN(newStock)) itemNew.stock = "";
    else if (newStock < AppConstant.LIMITED_PRICE_NUMBER) itemNew.stock = newStock;

    arrNewItem[indexModel].groupModel.modelList[indexItem].item = itemNew;
    setDataTable({ ...dataTable, modelList: arrNewItem });
    onChange({ ...dataTable, modelList: arrNewItem });
  };

  useEffect(() => {
    const firstInput = document.getElementById(THE_FIRST_INPUT_ID);
    const secondInput = document.getElementById(THE_SECOND_INPUT_ID);
    putCursorBeforeTheLastCharacter(firstInput);
    putCursorBeforeTheLastCharacter(secondInput);
  }, [dataTable]);

  useEffect(() => {
    if (isObjectNotEqual(data, dataTable)) setDataTable(data);
  }, [data]);

  useEffect(() => {
    let dataMap = deepCloneJsonObject(data);
    dataMap.modelList.forEach(dataModel => {
      dataModel.groupModel.modelList.forEach(dataItem => {
        dataItem.item.priceTaxIn = calculatePriceTaxIn(dataItem.item.price, taxTypeValue);
        dataItem.item.tax = dataItem.item.priceTaxIn - dataItem.item.price;
        dataItem.item.taxInclusiveF = Number(Boolean(taxTypeValue));
      });
    });
    setDataTable(dataMap);
  }, [taxTypeValue]);

  let rowSpan = data.modelList[0].groupModel.modelList.length;

  return (
    <TableContainer className={classes.tableRoot}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow className={classes.cellHeadRoot}>
            {arrHeader.map((data, index) => (
              <TableCell key={index} className={classes.cellHeadRoot}>
                {getLabel(data)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable?.modelList?.map((mapData, index) => (
            <Fragment key={index}>
              <TableRow className={classes.cellHeadRoot}>
                <TableCell rowSpan={rowSpan} className={classes.cellHeadRoot}>
                  {mapData.name}
                </TableCell>
                <TableCell className={classes.cellHeadRoot}>{mapData.groupModel.modelList[0].name}</TableCell>
                <TableCell className={classes.cellHeadRoot}>
                  <OutlinedInput
                    id={THE_FIRST_INPUT_ID}
                    classes={{
                      root: clsx(classes.outlinedInput, "regular-md-txt"),
                      notchedOutline: classes.notchedOutline,
                      input: classes.textCenter,
                    }}
                    placeholder={getLabel(LangConstant.P_VALUE_INPUT)}
                    onChange={event => onChangePrice(event, index, 0)}
                    value={getPriceString(mapData.groupModel.modelList[0].item.price)}
                  />
                </TableCell>
                <TableCell className={classes.cellHeadRoot}>
                  <Typography variant="body2">
                    {getPriceString(mapData.groupModel.modelList[0].item.priceTaxIn)}
                  </Typography>
                </TableCell>
                {isContainStockColumn && (
                  <TableCell className={classes.cellHeadRoot}>
                    <OutlinedInput
                      classes={{
                        root: clsx(classes.outlinedInput, "regular-md-txt"),
                        notchedOutline: classes.notchedOutline,
                        input: classes.textCenter,
                      }}
                      placeholder={getLabel(LangConstant.P_VALUE_INPUT)}
                      onChange={event => onChangeStock(event, index, 0)}
                      value={mapData.groupModel.modelList[0].item.stock}
                    />
                  </TableCell>
                )}
              </TableRow>
              {mapData?.groupModel?.modelList?.map((mapItem, indexItem) => (
                <Fragment key={indexItem}>
                  {indexItem > 0 && (
                    <TableRow>
                      <TableCell className={classes.cellHeadRoot}>{mapItem.name}</TableCell>
                      <TableCell className={classes.cellHeadRoot}>
                        <OutlinedInput
                          id={THE_SECOND_INPUT_ID}
                          classes={{
                            root: clsx(classes.outlinedInput, "regular-md-txt"),
                            notchedOutline: classes.notchedOutline,
                            input: classes.textCenter,
                          }}
                          placeholder={getLabel(LangConstant.P_VALUE_INPUT)}
                          onChange={event => onChangePrice(event, index, indexItem)}
                          value={getPriceString(mapItem.item.price)}
                        />
                      </TableCell>
                      <TableCell className={classes.cellHeadRoot}>
                        <Typography variant="body2">{getPriceString(mapItem.item.priceTaxIn)}</Typography>
                      </TableCell>
                      {isContainStockColumn && (
                        <TableCell className={classes.cellHeadRoot}>
                          <OutlinedInput
                            classes={{
                              root: clsx(classes.outlinedInput, "regular-md-txt"),
                              notchedOutline: classes.notchedOutline,
                              input: classes.textCenter,
                            }}
                            placeholder={getLabel(LangConstant.P_VALUE_INPUT)}
                            onChange={event => onChangeStock(event, index, indexItem)}
                            value={mapItem.item.stock}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TwoClassificationTable.propTypes = {
  onChange: PropTypes.func,
  arrHeader: PropTypes.array,
  isContainStockColumn: PropTypes.bool,
  data: PropTypes.object,
  taxTypeValue: PropTypes.number,
};

TwoClassificationTable.defaultProps = {
  arrHeader: [],
  isContainStockColumn: false,
  data: {},
  taxTypeValue: 0,
  onChange: () => {},
};

export default memo(TwoClassificationTable);

const THE_FIRST_INPUT_ID = "first-classification-input";
const THE_SECOND_INPUT_ID = "second-classification-input";

const getPriceString = priceNumber => {
  if (!priceNumber) return "";
  return formatVietnameseCurrency(priceNumber);
};

const useStyles = makeStyles(theme => ({
  tableRoot: {
    overflow: "unset",
  },
  cellHeadRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
    textAlign: "center",
    minWidth: 180,
  },
  outlinedInput: {
    height: 30,
  },
  notchedOutline: {
    border: "none",
  },
  textCenter: {
    textAlign: "center",
  },
}));
