import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import { convertTimeStampToDate } from "utils/date";
const PackageInfo = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const objPackageType = getLabel(LangConstant.OBJ_PACKAGE_TYPE, { returnObjects: true });
  const objPackageStatus = getLabel(LangConstant.OBJ_PACKAGE_STATUS, { returnObjects: true });
  const objColor = {
    [AppConstant.SHOP_PACKAGE.premium]: classes.premium,
    [AppConstant.SHOP_PACKAGE.standard]: classes.standard,
    [AppConstant.SHOP_PACKAGE.freeTrial]: classes.freeTrials,
  };

  const packageCurrent = useSelector(({ paymentRedux }) => paymentRedux.packageCurrent);

  return (
    <Box className={classes.root}>
      <Box className={clsx(classes.boxLine, classes.boxNotMarginTop)}>
        <Typography className={clsx("medium-md-txt", classes.title)}>{getLabel(LangConstant.TXT_PLAN_NAME)}</Typography>
        {packageCurrent?.plan && (
          <Box className={clsx("center-root", classes.borderBox, objColor[packageCurrent.plan])}>
            <Typography className={clsx("semiBold-sm-txt", classes.textBox)}>
              {objPackageType[packageCurrent.plan]}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_PLAN_PERIOD)}
        </Typography>
        {packageCurrent?.period && (
          <Typography className="medium-md-txt">
            {StringFormat(getLabel(LangConstant.FM_MONTHS), packageCurrent.period)}
          </Typography>
        )}
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_PLAN_START_DATE)}
        </Typography>
        <Typography className="medium-md-txt">
          {packageCurrent?.startDate ? convertTimeStampToDate(packageCurrent.startDate, AppConstant.FM_DD_MM_YYYY) : ""}
        </Typography>
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_PLAN_END_DATE)}
        </Typography>
        <Typography className="medium-md-txt">
          {packageCurrent?.expiryDate
            ? convertTimeStampToDate(packageCurrent.expiryDate, AppConstant.FM_DD_MM_YYYY)
            : ""}
        </Typography>
      </Box>
      <Box className={clsx(classes.boxLine, classes.boxNotMarginBottom)}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_PLAN_STATUS)}
        </Typography>
        {packageCurrent?.state && (
          <Box className={clsx("center-root", classes.borderBox, classes.statusColor)}>
            <Typography className={clsx("semiBold-sm-txt", classes.textBox)}>
              {objPackageStatus[packageCurrent.state]}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(PackageInfo);

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: "40%",
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
    },
  },
  boxLine: {
    display: "flex",
    alignItems: "center",
    height: 30,
    marginTop: 14,
    marginBottom: 14,
  },
  boxNotMarginBottom: {
    marginBottom: 0,
  },
  boxNotMarginTop: {
    marginTop: 0,
  },
  title: {
    width: 80,
    marginRight: 24,
    lineHeight: 1.57,
  },
  borderBox: {
    height: 30,
    padding: "5px 18px",
    borderRadius: 4,
  },
  textBox: {
    color: theme.palette.common.white,
    lineHeight: 1.67,
  },
  statusColor: {
    backgroundColor: theme.palette.secondary.main,
  },
  premium: {
    backgroundColor: "#feba40",
  },
  standard: {
    backgroundColor: theme.palette.secondary.main,
  },
  freeTrials: {
    backgroundColor: theme.palette.reservation.seated,
  },
}));
