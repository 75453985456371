import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Edit } from "@material-ui/icons";
import { Box, IconButton, makeStyles, TableBody, TableRow, Typography } from "@material-ui/core";
import ReservationAction from "redux/reservation.redux";
import { AppConstant, LangConstant } from "const";
import { deepCloneJsonObject, formatCurrency, getAttachmentUrl, getCommonKey, onLoadImageError } from "utils";
import { CellBody, DialogListTopping } from "components";
import { formatClassificationItem } from "components/select-items/ListItems";

const OrderTableRow = ({ dataRow, orderDetailData }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();

  const listComboItem = useSelector(({ comboItemRedux }) => comboItemRedux.dataGetListComboItem, shallowEqual);
  const listProductItem = useSelector(({ mnProductRedux }) => mnProductRedux.dataItem, shallowEqual);
  const listServiceItem = useSelector(({ serviceItemRedux }) => serviceItemRedux.dataGetListServiceItem, shallowEqual);
  const updateOrderSuccess = useSelector(({ reservationRedux }) => reservationRedux.updateReservationSuccess);

  const [toppingCondition, setToppingCondition] = useState(FORM_TOPPING_DEFAULT);
  const [dataRowDetail, setDataRowDetail] = useState(dataRow);
  const [orderDetail, setOrderDetail] = useState(orderDetailData);
  const [arrTopping, setArrTopping] = useState([]);
  const [itemDetail, setItemDetail] = useState();
  const [classification, setClassification] = useState(null);

  const onEditTopping = () => {
    let tmpItemDetails = { ...dataRowDetail };
    let itemDataType = tmpItemDetails.type;

    let itemToppingDetail = {};
    let mainItemDetail = {};
    let classificationItem = null;

    if (dataRowDetail.mainItemUid) {
      let allItemList = [
        ...deepCloneJsonObject(listProductItem.data),
        ...deepCloneJsonObject(listComboItem.data),
        ...deepCloneJsonObject(listServiceItem.data),
      ];

      mainItemDetail = allItemList.find(item => item.uid === dataRowDetail.mainItemUid);
      classificationItem = mainItemDetail.classificationList.find(item => item.item.uid === dataRowDetail.itemUid);

      tmpItemDetails = {
        ...deepCloneJsonObject(mainItemDetail),
        classification: classificationItem,
        type: dataRowDetail.type,
      };
    }

    switch (itemDataType) {
      case AppConstant.ITEM_TYPE.serviceItem:
        itemToppingDetail = getItemTopping(tmpItemDetails, listServiceItem);
        break;
      case AppConstant.ITEM_TYPE.productItem:
        itemToppingDetail = getItemTopping(tmpItemDetails, listProductItem);
        break;
      case AppConstant.ITEM_TYPE.comboItem:
        itemToppingDetail = getItemComboTopping(tmpItemDetails, listComboItem);
        break;
    }
    setItemDetail({ ...itemToppingDetail, selectedTopping: tmpItemDetails.orderDetailsContentList });

    if (dataRow.orderDetailsContentList.length > 0) {
      let selectedTopping = [];
      dataRow.orderDetailsContentList.forEach(itemRow => {
        itemToppingDetail.orderDetailsContentList?.forEach((itemDetail, index) => {
          if (itemDetail.uid === itemRow.itemUid) selectedTopping.push(index);
        });
      });
      setToppingCondition({
        ...toppingCondition,
        isShow: true,
        isEditItem: true,
        selectedToppingIndex: selectedTopping,
      });
    } else if (dataRow.orderDetailsContentList.length === 0) {
      setToppingCondition({ ...toppingCondition, isShow: true, isEditItem: true });
    }
  };

  const onCloseTopping = () => setToppingCondition(FORM_TOPPING_DEFAULT);

  const onCheckedTopping = (event, index, data) => {
    let updateOrderData = { ...orderDetail };
    let updatedItemDetail = { ...itemDetail };

    let arrayToppingIndex = [...toppingCondition.selectedToppingIndex];
    let newSelectedToppingList = [...itemDetail.selectedTopping];

    let updatedItemIndex = updateOrderData.orderDetailsList.findIndex(dataMap => dataMap.uid === dataRowDetail.uid);

    if (updatedItemIndex > -1) {
      let notExistedTopping = false;
      let toppingIndex;
      if (newSelectedToppingList.length > 0) {
        toppingIndex = newSelectedToppingList.findIndex(topping =>
          topping.itemUid ? topping.itemUid === data.uid || topping.itemUid === data.itemUid : topping.uid === data.uid,
        );
        notExistedTopping = toppingIndex === KEY_NOT_EXIST;
      } else notExistedTopping = true;

      if (event.target.checked) {
        arrayToppingIndex.push(index);
        // Update topping list on List Topping Dialog
        if (notExistedTopping) newSelectedToppingList.push(data);
      } else {
        arrayToppingIndex = arrayToppingIndex.filter(dataMap => dataMap !== index);
        // Update topping list on List Topping Dialog
        if (!notExistedTopping) newSelectedToppingList.splice(toppingIndex, 1);
      }
    }

    let updatedOrderDetail = {
      ...updateOrderData.orderDetailsList[updatedItemIndex],
      orderDetailsContentList: newSelectedToppingList,
    };
    // Update orderDetailsList in orderDetailData
    updateOrderData.orderDetailsList[updatedItemIndex] = updatedOrderDetail;

    setItemDetail({ ...updatedItemDetail, selectedTopping: newSelectedToppingList });
    setOrderDetail(updateOrderData);
    setToppingCondition({
      ...toppingCondition,
      selectedToppingIndex: arrayToppingIndex,
    });
  };
  const onSaveTopping = orderNumber => {
    let updatedData = { ...orderDetail };
    let updatedItemIndex = updatedData.orderDetailsList.findIndex(dataMap => dataMap.uid === dataRowDetail.uid);
    updatedData.orderDetailsList[updatedItemIndex].classification = classification;
    updatedData.orderDetailsList[updatedItemIndex] = {
      ...deepCloneJsonObject(updatedData.orderDetailsList[updatedItemIndex]),
      ...formatClassificationItem(classification, deepCloneJsonObject(updatedData.orderDetailsList[updatedItemIndex])),
      uid: null,
    };
    if (orderNumber === 0) {
      // Delete item when its order number equal 0
      updatedData.orderDetailsList.splice(updatedItemIndex, 1);
    } else updatedData.orderDetailsList[updatedItemIndex].orderNumber = orderNumber;

    setClassification(null);
    dispatch(ReservationAction.putRemoteOrderDetails({ details: updatedData, uid: updatedData.uid }));
  };

  useEffect(() => {
    if (dataRow && dataRow.orderDetailsContentList) {
      let tempDataRow = { ...dataRow };
      let toppingList = [];
      tempDataRow.orderDetailsContentList?.forEach(topping => toppingList.push(topping.name));
      setArrTopping(toppingList);
      setDataRowDetail(dataRow);
    }
  }, [dataRow]);

  useEffect(() => {
    if (orderDetailData.orderDetailsList.length === KEY_LAST_EXISTENCE) {
      setToppingCondition({ ...toppingCondition, isLastItem: true });
    }
    setOrderDetail(orderDetailData);
  }, [orderDetailData]);

  useEffect(() => {
    if (updateOrderSuccess) setToppingCondition(FORM_TOPPING_DEFAULT);
  }, [updateOrderSuccess]);

  useEffect(() => {
    if (itemDetail) {
      let newItemDetails = deepCloneJsonObject(itemDetail);
      newItemDetails.classification = classification;
      setItemDetail(newItemDetails);
    }
  }, [classification]);

  return (
    <>
      <TableBody>
        <TableRow className={classes.headerRow}>
          <CellBody
            cellData={
              <Box className={classes.itemBox}>
                <img
                  className={classes.itemImage}
                  onError={onLoadImageError}
                  src={getAttachmentUrl(dataRowDetail?.image)}
                />
                <Typography color="inherit" className="medium-md-txt">
                  {dataRowDetail.name}
                </Typography>
              </Box>
            }
            className={classes.twoFirstHeader}
          />
          <CellBody
            cellData={
              <Typography color="inherit" className="medium-md-txt">
                {arrTopping.join(", ") || ""}
              </Typography>
            }
            className={classes.twoFirstHeader}
          />
          <CellBody
            cellData={
              <Typography color="inherit" className="medium-md-txt">
                {dataRowDetail.orderNumber}
              </Typography>
            }
            className={classes.commonHeader}
          />
          <CellBody
            cellData={
              <Typography color="inherit" className="medium-md-txt">
                {formatCurrency(
                  dataRowDetail.price * dataRowDetail.orderNumber,
                  getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
                ) || ""}
              </Typography>
            }
            className={classes.commonHeader}
          />
          <CellBody
            cellData={
              <Typography color="inherit" className="medium-md-txt">
                {formatCurrency(
                  getTotalPrice(dataRowDetail),
                  getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
                ) || ""}
              </Typography>
            }
            className={classes.commonHeader}
          />
          <CellBody
            cellData={
              <IconButton
                size="small"
                edge="end"
                color="inherit"
                className={classes.editButton}
                onClick={onEditTopping}
              >
                <Edit className={classes.editIcon} />
              </IconButton>
            }
            className={classes.editIem}
          />
        </TableRow>
      </TableBody>
      <DialogListTopping
        isShow={toppingCondition.isShow}
        isEditItem={toppingCondition.isEditItem}
        isLastItem={toppingCondition.isLastItem}
        data={itemDetail}
        listDataChecked={toppingCondition.selectedToppingIndex}
        onClose={onCloseTopping}
        onCheckedTopping={onCheckedTopping}
        onSave={onSaveTopping}
        onChangeClassification={item => {
          setClassification(item);
        }}
        classificationItem={classification || itemDetail?.classification?.item}
      />
    </>
  );
};

const FORM_TOPPING_DEFAULT = {
  isShow: false,
  isEditItem: false,
  isLastItem: false,
  selectedToppingIndex: [],
};

const KEY_LAST_EXISTENCE = 1;
const KEY_NOT_EXIST = -1;

const getTotalPrice = data => {
  if (data) {
    let orderNumber = data.orderNumber || 0;
    let itemPrice = data.priceTaxIn || 0;
    let totalItemPrice = itemPrice * orderNumber;

    if (data.type !== AppConstant.ITEM_TYPE.comboItem) {
      // Calculate toppings price of item which isn't belong to Combo category
      let toppingList = data.orderDetailsContentList || [];
      if (toppingList.length > 0) {
        toppingList.forEach(topping => {
          let priceTaxInItem = topping.priceTaxIn || 0;
          totalItemPrice += orderNumber * priceTaxInItem;
        });
      }
    }

    return totalItemPrice;
  }
};

const getItemComboTopping = (item, listItems) => {
  let toppingList = [];
  let selectedItemDetail = { ...item };
  listItems.data.forEach(dataMap => {
    if (dataMap.uid === selectedItemDetail.itemUid) {
      toppingList = dataMap.itemDetails?.itemDetailsContentList.sort();
    }
  });
  selectedItemDetail.orderDetailsContentList = toppingList;
  return selectedItemDetail;
};

const getItemTopping = (item, listItems) => {
  let toppingList = [];
  let selectedItemDetail = { ...item };
  listItems.data.forEach(dataMap => {
    if (dataMap.uid === selectedItemDetail.itemUid) {
      dataMap.itemDetailsList.map(itemDetail => (toppingList = itemDetail.itemDetailsContentList.sort()));
    }
  });
  selectedItemDetail.orderDetailsContentList = toppingList;
  return selectedItemDetail;
};

export default memo(OrderTableRow);

OrderTableRow.propTypes = {
  dataRow: PropTypes.object.isRequired,
  orderDetailData: PropTypes.object.isRequired,
};

OrderTableRow.defaultProps = {
  dataRow: {},
  orderDetailData: {},
};

const useStyles = makeStyles(() => ({
  headerRow: {
    height: 53,
    borderColor: "none",
    borderRadius: 8,

    "&:hover": {
      backgroundColor: "#f2f5f7",
    },
  },
  twoFirstHeader: {
    width: "25%",
    textAlign: "left",
    padding: 16,
    color: "#3e4045",
    "&:first-child": {
      paddingLeft: 23,
    },
  },
  commonHeader: {
    width: "15%",
    padding: "16px 8px",
    textAlign: "center",
    color: "#3e4045",
  },
  editIem: {
    width: "5%",
    textAlign: "right",
    padding: "16px 24px 16px 16px",
    color: "#7f838c",
  },
  itemBox: {
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
  },
  itemImage: {
    width: 30,
    marginRight: 20,
  },
  iconButton: {
    maxWidth: 18,
    minWidth: 18,
    maxHeight: 18,
  },
  editButton: {
    width: 16,
    height: 16,
    padding: 0,
  },
  editIcon: {
    width: "inherit",
    height: "inherit",
  },
}));
