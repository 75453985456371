/* eslint-disable no-unused-vars */
import "date-fns";
import { AppConstant, LangConstant } from "../const";
import viLocale from "date-fns/locale/vi";
import enLocale from "date-fns/locale/en-US";
import {
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format as DateFormat,
  isSameYear,
  isSunday,
  isAfter,
  isSameDay,
  isDate,
} from "date-fns";
import { format, format as StringFormat } from "react-string-format";
import { convertSmallNumber, getCommonKey } from ".";
import { getLabel } from "language";

export const getDateLang = lang => {
  if (LangConstant.EN_LANG === lang) {
    return enLocale;
  }
  return viLocale;
};

export const isSameWeek = (firstDay, secondDay) => {
  // TODO: Check currentDay is sunday, selectedDay is monday of next week
  // differenceWeekNumber still is 0 => Wrong
  let isSundayDate = isSunday(firstDay) || isSunday(secondDay);
  let differenceWeekNumber = differenceInCalendarWeeks(firstDay, secondDay);
  return differenceWeekNumber === 0 || (isSundayDate && differenceWeekNumber === 1);
};

export const getCreatedTime = (created, getLabel) => {
  let result = "";
  if (created) {
    let currentDate = new Date();
    let differenceDaysWithCurrent = differenceInDays(currentDate, created);
    let differenceHoursWithCurrent = differenceInHours(currentDate, created);
    let differenceMinutesWithCurrent = differenceInMinutes(currentDate, created);
    let differenceSecsWithCurrent = differenceInSeconds(currentDate, created);
    if (differenceDaysWithCurrent >= 1) {
      if (differenceDaysWithCurrent >= 7) {
        let isSame = isSameYear(created, currentDate);
        let primaryFormat = isSame ? AppConstant.FM_DD_MM : AppConstant.FM_DD_MM_YYYY;
        result = DateFormat(created, primaryFormat);
      } else {
        result = StringFormat(getLabel(getCommonKey(LangConstant.FM_NEW_CREATED_BY_DAY)), differenceDaysWithCurrent);
      }
    } else if (differenceHoursWithCurrent >= 1) {
      result = StringFormat(getLabel(getCommonKey(LangConstant.FM_NEW_CREATED_BY_HOUR)), differenceHoursWithCurrent);
    } else if (differenceMinutesWithCurrent >= 1) {
      result = StringFormat(
        getLabel(getCommonKey(LangConstant.FM_NEW_CREATED_BY_MINUTE)),
        differenceMinutesWithCurrent,
      );
    } else if (differenceSecsWithCurrent < 60) {
      result = getLabel(getCommonKey(LangConstant.TXT_NEW_CREATED_BY_SECOND));
    }
  }
  return result;
};

export const getUseTime = (useTime, realityStartDay, getLabel) => {
  let result = "";
  if (useTime) {
    let differenceDaysWithCurrent = differenceInDays(realityStartDay + useTime, realityStartDay);
    let daySecond = 60 * 60 * 24;
    let hourSecond = 60 * 60;
    let minSecond = 60;
    if (useTime < minSecond) {
      result = StringFormat(getLabel(LangConstant.FM_BOOKING_USE_TIME_FM_SS), useTime);
    } else if (useTime < hourSecond) {
      result = StringFormat(
        getLabel(LangConstant.FM_BOOKING_USE_TIME_FM_MM_SS),
        Math.floor(useTime / minSecond),
        useTime - Math.floor(useTime / minSecond) * minSecond,
      );
    } else if (useTime < daySecond && useTime / hourSecond >= 1) {
      result = StringFormat(
        getLabel(LangConstant.FM_BOOKING_USE_TIME_FM_HH_MM),
        Math.floor(useTime / hourSecond),
        Math.floor((useTime - Math.floor(useTime / hourSecond) * hourSecond) / minSecond),
      );
    } else if (useTime === daySecond) {
      result = StringFormat(getLabel(LangConstant.FM_BOOKING_USE_TIME_FM_DD), Math.floor(useTime / daySecond));
    } else if (useTime > daySecond) {
      if (differenceDaysWithCurrent) {
        result = StringFormat(
          getLabel(LangConstant.FM_BOOKING_USE_TIME_FM_DD_HH),
          Math.floor(useTime / daySecond),
          Math.floor((useTime - Math.floor(useTime / daySecond) * daySecond) / hourSecond),
        );
      }
    }
  }
  return result;
};

export const checkFuture = date => {
  let result = "";
  let currentDate = new Date();
  if (isAfter(date, currentDate)) {
    result = AppConstant.DATE_VALID_CODE.future;
  } else result = AppConstant.DATE_VALID_CODE.past;
  return result;
};

export const checkIsNotPast = timeStamp => {
  let result = "";
  let currentDate = Math.floor(new Date().getTime() / 1000);
  if (timeStamp > currentDate) {
    result = AppConstant.DATE_VALID_CODE.future;
  } else result = AppConstant.DATE_VALID_CODE.past;
  return result;
};

export const isValidDate = date => {
  var temp = date.split("/");
  var d = new Date(temp[1] + "/" + temp[0] + "/" + temp[2]);
  return d && d.getMonth() + 1 == temp[1] && d.getDate() == Number(temp[0]) && d.getFullYear() == Number(temp[2]);
};
export const isValidDateTime = inputField => {
  var isValid = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])?$/.test(inputField);
  return isValid;
};

export const removeSecond = date => new Date(date.setSeconds(0));

export const getStartTime = (time, current) => {
  let result =
    Math.floor(new Date(time).getTime() / 1000) -
    current.getHours() * 60 * 60 -
    current.getMinutes() * 60 -
    current.getSeconds();
  return result;
};

export const getStartOfDay = (day = new Date()) => {
  let result = Math.floor(new Date(day.setHours(0, 0, 0)).getTime() / 1000);
  return result;
};

export const getEndOfDay = (day = new Date()) => {
  let result = Math.floor(new Date(day.setHours(23, 59, 59)).getTime() / 1000);
  return result;
};

export const getTimestamp = date => {
  let tmpDate = new Date(date);
  return Math.floor(tmpDate.getTime() / 1000);
};

export const fetchDateToText = (date, getLabel) => {
  let dayIndex = new Date(date).getDay();
  let dateNum = new Date(date).getDate();
  let monthNum = new Date(date).getMonth();
  let yearNum = new Date(date).getFullYear();

  let formattedDateText = format(
    getLabel(getCommonKey(LangConstant.FM_DATE_INPUT)),
    getLabel(getCommonKey(LangConstant.ARR_DAY_OF_WEEK[dayIndex > 0 ? dayIndex - 1 : 6])),
    `${dateNum < 10 ? "0" : ""}${dateNum}`,
    `${monthNum + 1 < 10 ? "0" : ""}${monthNum + 1}`,
    yearNum,
  );
  return formattedDateText;
};

export const convertTimeStampToDate = (timeStamp, formatConstant) => {
  let resultDate;
  if (timeStamp) {
    if (formatConstant) {
      resultDate = DateFormat(new Date(timeStamp * 1000), formatConstant);
    } else {
      resultDate = new Date(timeStamp * 1000);
    }
  }
  return resultDate;
};

export const getRealUseTimeByHour = (startTime = 0, endTime = 0, getLabel) => {
  let result;
  let diffInTime = getTimestamp(endTime) - getTimestamp(startTime);

  let hourDiff = Math.floor(diffInTime / 3600);
  let minDiff = Math.round((diffInTime % 3600) / 60);

  if (diffInTime > 0) {
    result = format(getLabel(getCommonKey(LangConstant.FM_USE_TIME_IN_HOUR)), hourDiff, minDiff > 0 ? minDiff : "00");
  } else {
    result = format(getLabel(getCommonKey(LangConstant.FM_USE_TIME_IN_HOUR)), "00", "00");
  }
  return result;
};

export const checkExpiredBookingDate = date => {
  let startDateObj = new Date(date);
  // Return result is past or not
  return checkIsNotPast(getTimestamp(startDateObj)) || isSameDay(startDateObj, new Date());
};

export const fromDateAndHourToDateObject = (date = new Date(), hour = "00:00") => {
  let resultDate;
  let hourAndMinuteArray = (hour && hour.split(":")) || [];
  if (hourAndMinuteArray.length > 0 && isDate(date)) {
    let HourToSet = hourAndMinuteArray[0];
    let MinToSet = hourAndMinuteArray[1];
    resultDate = getTimeStampFromMiliSec(date.setHours(parseInt(HourToSet), parseInt(MinToSet)));
  }
  return resultDate ? convertTimeStampToDate(resultDate) : new Date();
};

export const getTimeStampFromMiliSec = miliSec => {
  if (miliSec && miliSec > 0) {
    return Math.floor(miliSec / 1000);
  } else {
    return 0;
  }
};

export const convertFormat = (date, strFormat, options = {}) => {
  try {
    return DateFormat(date, strFormat, options);
  } catch (error) {
    console.log(error);
  }
  return "";
};

const convertToDate = seconds => {
  const time = new Date();
  time.setHours(0, 0, 0, 0);
  time.setSeconds(seconds);
  return time;
};

export const getShopWorkingTime = workingTime => {
  let tmpWorkingTime = workingTime ? { ...workingTime } : {};
  let { open, close } = tmpWorkingTime;
  let openTime = "",
    closeTime = "";

  if (Number.isInteger(open) && open >= 0) {
    let openDate = convertToDate(tmpWorkingTime.open);
    openTime = convertFormat(openDate, AppConstant.FM_HH_MM);
  }
  if (Number.isInteger(close) && close >= 0) {
    let closeDate = convertToDate(workingTime.close);
    closeTime = convertFormat(closeDate, AppConstant.FM_HH_MM);
  }

  let isWorking = openTime || closeTime;

  return isWorking ? `${openTime} - ${closeTime}` : "";
};

export const convertSecondToHoursAndMinutes = second => {
  if (!second || second < 0)
    return {
      hour: 0,
      min: 0,
    };
  let hours = Math.floor(second / 3600);
  let minutes = Math.floor((second % 3600) / 60);
  let result = {
    hour: hours,
    min: minutes,
  };
  return result;
};

export const getWaitingTime = (createdTime, startCountingTimeStamp) => {
  if (!createdTime) return "";
  let tmpWaitingTime = (startCountingTimeStamp || getTimestamp(new Date())) - createdTime;

  if (tmpWaitingTime < 0) {
    tmpWaitingTime = 0;
  }

  let timeObject = convertSecondToHoursAndMinutes(tmpWaitingTime);
  let result = "";

  if (timeObject.hour) {
    if (timeObject.min) {
      result = timeObject.hour + "h" + timeObject.min + "m";
    } else {
      result = timeObject.hour + "h";
    }
  } else if (timeObject.min) {
    result = timeObject.min + "m";
  } else {
    result = "1m";
  }

  return result;
};

Date.prototype.isValid = function () {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  return this.getTime() === this.getTime();
};
