export default {
  FM_CONSUMER_CAPACITY: "{0} người",
  FM_DELETE_PLACE_CATEGORY: "Xóa vị trí {0}",
  FM_CONFIRM_DELETE_TEXT: "Bạn có chắc muốn xóa vị trí {0}?",

  FM_IS_EMPTY: "Đang trống ({0})",
  FM_IS_USING: "Đang sử dụng ({0})",

  TXT_IS_GOING_IN_USE: " : Sắp sử dụng",
  TXT_IS_BUSY: " : Đang sử dụng",

  TXT_PLACE_STATUS_IN_STOCK: "Tình trạng các vị trí trong cửa hàng",
};
