import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import { Box, Menu, MenuItem, IconButton } from "@material-ui/core";
import clsx from "clsx";

const MenuStatus = props => {
  const {
    onChange,
    style,
    rowNum,
    classes,
    includeDefault,
    titleDefault,
    status,
    categoryText,
    arrayItemsText,
    isShowIcon,
  } = props;

  const defaultClasses = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(status);

  const onClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChange(index);
  };

  useEffect(() => {
    if (status !== selectedIndex) setSelectedIndex(status ? status - 1 : null);
  }, [status]);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={clsx(defaultClasses.boxRoot, classes?.root)} style={style}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onClickListItem}
        classes={{
          root: clsx(defaultClasses.btnRoot, classes?.rootButton),
          label: clsx("medium-md-txt", classes?.label, defaultClasses.btnLabel),
        }}
      >
        {selectedIndex != null ? arrayItemsText[selectedIndex] : categoryText}
        {isShowIcon &&
          (anchorEl ? (
            <ArrowDropUp className={defaultClasses.icon} />
          ) : (
            <ArrowDropDown className={defaultClasses.icon} />
          ))}
      </IconButton>
      <Menu
        id="lock-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{ list: defaultClasses.menuList, paper: clsx(defaultClasses.menuPaper, classes?.paper) }}
      >
        {includeDefault && (
          <MenuItem
            onClick={event => onMenuItemClick(event, null)}
            className={clsx("medium-md-txt", defaultClasses.menuItemRoot, classes?.item)}
          >
            {titleDefault}
          </MenuItem>
        )}
        {arrayItemsText.map(
          (option, index) =>
            index <= rowNum && (
              <MenuItem
                onClick={event => onMenuItemClick(event, index)}
                className={clsx("medium-md-txt", defaultClasses.menuItemRoot, classes?.item)}
                key={index}
              >
                {option}
              </MenuItem>
            ),
        )}
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  btnRoot: {
    width: "100%",
    height: "100%",
    color: "#3b404c",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[200]}`,
    paddingRight: 45,
  },
  btnLabel: {
    justifyContent: "unset",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 8,
    },
  },
  menuPaper: {
    marginTop: "2px",
    borderRadius: "0%",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: " calc(100% - 30px)",
    overflow: "auto",
  },
  menuList: {
    paddingTop: `0px`,
    paddingBottom: `0px`,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    minHeight: 40,
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: 125,
    },
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  icon: {
    right: 5,
    [theme.breakpoints.down("sm")]: {
      right: 8,
    },
    position: "absolute",
    color: theme.palette.grey[500],
  },
}));

MenuStatus.propTypes = {
  rowNum: PropTypes.number,
  onChange: PropTypes.func,
  style: PropTypes.object,
  classes: PropTypes.shape({
    root: PropTypes.string,
    item: PropTypes.string,
    paper: PropTypes.string,
    label: PropTypes.string,
    rootButton: PropTypes.string,
  }),
  includeDefault: PropTypes.bool,
  isShowIcon: PropTypes.bool,
  titleDefault: PropTypes.string,
  categoryText: PropTypes.string.isRequired,
  arrayItemsText: PropTypes.array.isRequired,
  value: PropTypes.number,
};

MenuStatus.defaultProps = {
  style: {},
  rowNum: 6,
  includeDefault: false,
  titleDefault: "",
  categoryText: "",
  arrayItemsText: [],
  value: 0,
  isShowIcon: true,
};

export default memo(MenuStatus);
