import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  DialogTitle,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Line } from "pages/MnReservation/components/DialogBooking/componentDialog";
import { LangConstant } from "const";
import { getCommonKey, validatePhone } from "utils";

const DialogInviteWaitingConsumer = ({ isShow, data, onSubmit, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const primaryTitle = data?.uid
    ? getLabel(LangConstant.TXT_EDIT_WAITING_CONSUMER)
    : getLabel(LangConstant.TXT_INVITE_WAITING_CONSUMER);

  const [formInputData, setFormInputData] = useState(FORMAT_DEFAULT_WAITING_CONSUMER);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);

  const onChangeFormInvite = event => {
    let newFormInputData = { ...formInputData, [event.target.name]: event.target.value };
    setFormInputData(newFormInputData);
    if (
      newFormInputData.name &&
      validatePhone(newFormInputData.phoneNumber) &&
      newFormInputData.man + newFormInputData.child >= 1
    ) {
      setIsDisabledSubmit(false);
    } else {
      setIsDisabledSubmit(true);
    }
  };

  const onSubmitForm = () => {
    onSubmit(formInputData);
  };

  useEffect(() => {
    if (data?.uid) {
      setFormInputData({
        uid: data.uid,
        name: data.name || "",
        phoneNumber: data.phoneNumber || "",
        man: data.consumer?.man || data.consumer?.total || FORMAT_DEFAULT_WAITING_CONSUMER.man,
        child: data.consumer?.child || FORMAT_DEFAULT_WAITING_CONSUMER.child,
      });
    }
  }, [data]);

  return (
    <Dialog open={isShow} classes={{ paperScrollPaper: classes.dialogContainer }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography classes={{ body1: classes.textDialogTitle }}>{primaryTitle}</Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box padding="24px 20px 0">
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_NAME))}
            onChange={onChangeFormInvite}
            isEditing={true}
            content={formInputData.name}
            name="name"
          />
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_PHONE))}
            onChange={onChangeFormInvite}
            isEditing={true}
            content={formInputData.phoneNumber}
            name="phoneNumber"
            inputProps={{
              type: "tel",
            }}
          />
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_PEOPLE))}
            onChange={onChangeFormInvite}
            isEditing={true}
            content={formInputData.man}
            name="man"
            inputProps={{
              type: "number",
            }}
          />
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_CHILDREN))}
            onChange={onChangeFormInvite}
            isEditing={true}
            content={formInputData.child}
            name="child"
            inputProps={{
              type: "number",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.buttonCancel} onClick={onClose}>
          {getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonInvite}
          onClick={onSubmitForm}
          disabled={isDisabledSubmit}
        >
          <Typography className={classes.textButtonInvite}>{getLabel(getCommonKey(LangConstant.TXT_SAVE))}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FORMAT_DEFAULT_WAITING_CONSUMER = {
  uid: "",
  name: "",
  phoneNumber: "",
  man: 1,
  child: 0,
};

DialogInviteWaitingConsumer.propTypes = {
  isShow: PropTypes.bool,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default memo(DialogInviteWaitingConsumer);

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 531,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    "@media only screen and (max-width: 550px)": {
      width: "100%",
      margin: 0,
      height: "100%",
      maxHeight: "none",
    },
  },
  dialogTitle: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: "fixed",
    width: 531,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 60,
    padding: "0 24px",
    zIndex: 100,
    "@media only screen and (max-width: 550px)": {
      width: "100%",
    },
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 20,
  },
  closeButton: {
    position: "absolute",
    right: 24,
    top: 18,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    marginTop: 34,
    padding: "26px 0 0",
    display: "flex",
    flexDirection: "column",
    border: "none",
    "&:first-child": {
      paddingTop: 26,
      "@media only screen and (max-width: 550px)": {
        padding: "26px 0 20px",
      },
    },
  },
  dialogActions: {
    display: "block",
    textAlign: "right",
    padding: "35px 24px 27px",
  },
  buttonCancel: {
    borderRadius: 2,
    minWidth: 58,
    padding: 0,
    minHeight: 30,
    textTransform: "none",
    fontWeight: 500,
  },
  buttonInvite: {
    borderRadius: 2,
    minWidth: 58,
    padding: 0,
    minHeight: 30,
    marginLeft: 8,
    backgroundColor: "#ef5845",
    border: "unset",
    color: theme.palette.white,
    textTransform: "none",
  },
  textButtonInvite: {
    fontSize: 14,
    color: theme.palette.white,
  },
}));
