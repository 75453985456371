import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { Paper, makeStyles, Box, Typography, Button, Hidden } from "@material-ui/core";
import { getCommonKey, isArrayNotEqual, onCloseBrowser, uuid, getFeatureConditions } from "utils";
import MenuAction from "redux/menu.redux";
import ServiceCategoryAction from "redux/service-category.redux";
import ServiceItemAction from "redux/service-item.redux";
import MnProductAction from "redux/mn-product.redux";
import ComboItemAction from "redux/combo-item.redux";
import { ConfirmDialog, DialogAddCategory } from "components/dialog";
import { IconButtonMProductItem } from "components/mn-master-product";
import { BglobalJSC } from "components";
import { MainCategoryAll, MainItemAll, TabSelect } from "./component";
import { LangConstant, AppConstant } from "const";
import DisplayModeTabBar from "./component/DisplayModeTabBar";

const MnMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const childMenuModeArr = [
    { name: getLabel(getCommonKey(LangConstant.TXT_RESERVATION)), id: idTabMenu.booking },
    { name: getLabel(LangConstant.TXT_ORDER_MENU_SETUP), id: idTabMenu.order },
  ];

  const [
    dataDefault,
    dataDisplay,
    dataOrderDefault,
    dataOrderDisplay,
    isTabBooking,
    dataMenu,
    isSuccessMenu,
    isPostSuccessMenu,
  ] = useSelector(
    ({ menuRedux }) => [
      menuRedux.dataDefault,
      menuRedux.dataDisplay,
      menuRedux.dataOrderDefault,
      menuRedux.dataOrderDisplay,
      menuRedux.isTabBooking,
      menuRedux.data,
      menuRedux.isSuccess,
      menuRedux.isPostSuccess,
    ],
    shallowEqual,
  );
  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { isPremium, isOnlyBooking, isOnlyOrdering } = getFeatureConditions(reservationSetting);

  const [isOpenDialogAddCategory, setIsOpenDialogAddCategory] = useState(false);
  const [dataDialogAddCategory, setDataDialogAddCategory] = useState(null);
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [data, setData] = useState([]);
  const [nameCategory, setNameCategory] = useState("");
  const [childProductModeId, setChildProductModeId] = useState(0);
  const [childMenuModeId, setChildMenuModeId] = useState(childMenuModeArr[0].id);
  const [isShowButtonCopy, setIsShowButtonCopy] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const onClickSelectTab = event => {
    setChildProductModeId(event);
  };

  const onCreateCategory = () => {
    setIsOpenDialogAddCategory(true);
  };

  const onClose = () => {
    setIsOpenDialogAddCategory(false);
  };

  const onChangeNameCategory = value => {
    setNameCategory(value);
  };

  const onSubmitCategory = () => {
    let dataMenuNew = [...data];
    let dataMenuPost = [...(isTabBooking ? dataDefault.menuList : dataOrderDefault.menuList)];
    let menuSetupData = {};

    let dataNew = {
      keys: uuid(),
      name: nameCategory,
      itemList: [],
    };
    dataMenuNew.push(dataNew);
    dataMenuPost.push(dataNew);

    if (isTabBooking) {
      menuSetupData.dataDefault = { type: AppConstant.DATA_TYPE.booking, menuList: dataMenuPost };
      menuSetupData.dataDisplay = { type: AppConstant.DATA_TYPE.booking, menuList: dataMenuNew };
    } else {
      menuSetupData.dataOrderDefault = { type: AppConstant.DATA_TYPE.order, menuList: dataMenuPost };
      menuSetupData.dataOrderDisplay = { type: AppConstant.DATA_TYPE.order, menuList: dataMenuNew };
    }

    dispatch(MenuAction.setMenu(menuSetupData));
    setIsShowButtonCopy(false);
    setIsOpenDialogAddCategory(false);
    setDataDialogAddCategory({ name: "" });
    setIsEnableSave(true);
  };

  const onSubmitDataAll = () => {
    if (Boolean(dataDefault.menuList) && isTabBooking) {
      dispatch(MenuAction.postMenu(dataDefault));
    }
    if (Boolean(dataOrderDefault.menuList) && !isTabBooking) {
      dispatch(MenuAction.postMenu(dataOrderDefault));
    }
  };

  const onChangeMode = event => {
    const newChildServiceModeId = event.currentTarget.id;
    if (newChildServiceModeId != childMenuModeId) {
      setChildMenuModeId(newChildServiceModeId);
    }
    if (newChildServiceModeId === idTabMenu.order && dataOrderDisplay.length === 0) {
      let data = { type: AppConstant.DATA_TYPE.booking, menuList: [] };
      dispatch(MenuAction.menuSuccess({ dataOrderDefault: data, dataOrderDisplay: data }));
    }
  };

  const onConfirm = (event, isConfirm) => {
    event.stopPropagation();
    if (isConfirm) {
      let data = [],
        dataPost = [];
      dataDisplay.menuList.forEach(element => {
        let itemList = [];
        if (element.itemList.length > 0) {
          element.itemList.forEach(item => {
            let itemNews = { ...item };
            delete itemNews.uid;
            let dataItemNew = { ...itemNews, key: uuid() };
            itemList.push(dataItemNew);
          });
        }
        dataPost.push({
          name: element.name,
          itemList: itemList,
        });
      });
      dataDefault.menuList.forEach(element => {
        let itemList = [];
        if (element.itemList.length > 0) {
          element.itemList.forEach(item => {
            let itemNews = { ...item };
            delete itemNews.uid;
            let dataItemNew = { ...itemNews, key: uuid() };
            itemList.push(dataItemNew);
          });
        }
        data.push({
          name: element.name,
          itemList: itemList,
        });
      });
      dispatch(
        MenuAction.menuSuccess({
          dataOrderDefault: { type: AppConstant.DATA_TYPE.order, menuList: data },
          dataOrderDisplay: { type: AppConstant.DATA_TYPE.order, menuList: dataPost },
        }),
      );
      setData(data);
      setIsShowButtonCopy(false);
      setIsEnableSave(true);
    }
    setIsOpenConfirm(false);
  };

  const onEnableButton = value => {
    setIsEnableSave(value);
  };

  useEffect(() => {
    dispatch(MenuAction.getMenu({ type: AppConstant.DATA_TYPE.booking }));
  }, []);

  useEffect(() => {
    if (isPostSuccessMenu) {
      setIsEnableSave(false);
    }
  }, [isPostSuccessMenu]);

  useEffect(() => {
    if (isSuccessMenu) {
      let data = [];
      if (isTabBooking) {
        data = { type: AppConstant.DATA_TYPE.booking, menuList: dataMenu };
        dispatch(MenuAction.menuSuccess({ dataDefault: data, dataDisplay: data }));
      } else {
        data = { type: AppConstant.DATA_TYPE.order, menuList: dataMenu };
        dispatch(MenuAction.menuSuccess({ dataOrderDefault: data, dataOrderDisplay: data }));
      }
      setData(dataMenu);
    }
    if (dataMenu.length > 0) {
      setIsShowButtonCopy(false);
    }
  }, [dataMenu]);

  useEffect(() => {
    if (isTabBooking) {
      setData(dataDisplay.menuList);
      setIsEnableSave(isArrayNotEqual(dataDisplay.menuList, data));
    } else {
      setData(dataOrderDisplay.menuList);
      setIsEnableSave(isArrayNotEqual(dataOrderDisplay.menuList, data));
    }
  }, [dataDisplay, dataOrderDisplay]);

  useEffect(() => {
    if (childMenuModeId === idTabMenu.order) {
      if (dataOrderDisplay.menuList.length === 0) {
        setIsShowButtonCopy(true);
        dispatch(MenuAction.getMenu({ type: AppConstant.DATA_TYPE.order }));
      } else {
        setData(dataOrderDisplay.menuList);
        setIsShowButtonCopy(false);
      }
      dispatch(MenuAction.menuSuccess({ isTabBooking: false }));
    } else {
      setData(dataDisplay.menuList);
      dispatch(MenuAction.menuSuccess({ isTabBooking: true }));
      setIsShowButtonCopy(false);
    }
  }, [childMenuModeId]);

  useEffect(() => {
    if (isEnableSave) {
      window.addEventListener("beforeunload", onCloseBrowser);

      return () => {
        // handling will unmounted component
        window.removeEventListener("beforeunload", onCloseBrowser);
      };
    }
  }, [isEnableSave]);

  useEffect(() => {
    dispatch(ServiceItemAction.resetServiceItem());
    dispatch(ComboItemAction.resetComboItem());
    dispatch(MnProductAction.resetMnProduct());
    dispatch(ServiceCategoryAction.getServiceCategory());
    dispatch(ServiceItemAction.getListServiceItem());
    dispatch(MnProductAction.getProductCategory());
    dispatch(MnProductAction.getProductItem());
    dispatch(ComboItemAction.getListComboItem(DATA_COMBO_DEFAULT));
  }, []);

  useEffect(() => {
    if (isOnlyOrdering && !(isOnlyBooking || isPremium)) {
      setChildMenuModeId(childMenuModeArr[1].id);
      let data = { type: AppConstant.DATA_TYPE.booking, menuList: [] };
      dispatch(MenuAction.menuSuccess({ dataOrderDefault: data, dataOrderDisplay: data }));
    }
  }, [isOnlyOrdering]);

  return (
    <Box>
      <Paper>
        <Box className={classes.header}>
          <Typography className={classes.textHeader}>{getLabel(getCommonKey(LangConstant.TXT_MENU_SETUP))}</Typography>
          {((dataMenu && dataMenu.length > 0) || (data && data.length > 0)) && (
            <Box display="flex">
              <Hidden smDown>
                {Boolean(childProductModeId) && (
                  <IconButtonMProductItem
                    label={getLabel(LangConstant.TXT_ADD_CATEGORY_PRODUCT)}
                    onClick={onCreateCategory}
                    buttonClasses={{ root: classes.addButtonTop }}
                  />
                )}
              </Hidden>
              <Button
                onClick={onSubmitDataAll}
                classes={{ root: `semiBold-lg-txt ${classes.buttonSave}`, disabled: classes.disabledSaveButton }}
                disabled={!isEnableSave}
              >
                <Typography className={classes.typography}>{getLabel(getCommonKey(LangConstant.TXT_SAVE))}</Typography>
              </Button>
            </Box>
          )}
        </Box>
        <DisplayModeTabBar
          modeId={childMenuModeId}
          modeArray={childMenuModeArr}
          onChange={onChangeMode}
          isShowButton={isShowButtonCopy}
          onClick={() => setIsOpenConfirm(true)}
        />
        {data && data.length > 0 && (
          <Box className={classes.tabSelect}>
            <Box className={classes.textTabSelect}>
              <TabSelect value={childProductModeId} onClick={onClickSelectTab} />
            </Box>
            <Hidden mdUp>
              <IconButtonMProductItem
                label={getLabel(LangConstant.TXT_ADD_CATEGORY_PRODUCT)}
                onClick={onCreateCategory}
                buttonClasses={{ root: classes.addButtonTop }}
              />
            </Hidden>
            <Hidden smDown>
              {Boolean(!childProductModeId) && (
                <IconButtonMProductItem
                  label={getLabel(LangConstant.TXT_ADD_CATEGORY_PRODUCT)}
                  onClick={onCreateCategory}
                  buttonClasses={{ root: classes.addButtonTop }}
                />
              )}
            </Hidden>
          </Box>
        )}
        {data && data.length > 0 ? (
          Boolean(childProductModeId) ? (
            <MainCategoryAll onEnableButton={onEnableButton} />
          ) : (
            <MainItemAll onEnableButton={onEnableButton} />
          )
        ) : (
          <Box className={classes.createCategory}>
            <IconButtonMProductItem
              label={getLabel(LangConstant.TXT_ADD_CATEGORY_PRODUCT)}
              onClick={onCreateCategory}
              buttonClasses={{ root: classes.addButton }}
            ></IconButtonMProductItem>
          </Box>
        )}
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
      <DialogAddCategory
        isShow={isOpenDialogAddCategory}
        data={dataDialogAddCategory}
        onClose={onClose}
        onChange={onChangeNameCategory}
        onSubmit={onSubmitCategory}
      />
      <ConfirmDialog
        isShow={isOpenConfirm}
        onSelectConfirm={onConfirm}
        title={getLabel(LangConstant.TXT_COPY_TITLE_ORDER)}
        titleConfirm={getLabel(LangConstant.TXT_COPY_TITLE_ORDER_CONFIRM)}
        confirmText={getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
        cancelText={getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
        classes={{
          dialogAction: classes.confirmDialogAction,
        }}
      />
      <Prompt when={isEnableSave} message={getLabel(getCommonKey(LangConstant.TXT_NOTIFICATION_MESSAGE))} />
    </Box>
  );
};

const DATA_COMBO_DEFAULT = {
  page: 1,
  paging: 0,
  size: 10,
  total: 0,
  totalPage: 0,
};

const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    padding: "15px 30px",
    display: "flex",
    borderBottom: "1px solid #ebeced",
    backgroundColor: "#fafafb",
    minHeight: 71,
  },
  textHeader: {
    float: "left",
    width: "95%",
    paddingTop: 6,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      width: "85%",
    },
  },
  textTabSelect: {
    float: "left",
    width: "80%",
    paddingTop: 6,
    [theme.breakpoints.down(600)]: {
      width: "85%",
    },
  },
  buttonSave: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    float: "right",
    height: 40,
    width: 78,
    [theme.breakpoints.down(600)]: {
      width: "15%",
    },
    "&.MuiButton-root:hover": {
      backgroundColor: theme.palette.primary["main"],
    },
  },
  createCategory: {
    height: "calc(100vh - 230px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  addButtonTop: {
    minWidth: 170,
    height: 30,
    marginRight: 10,
    paddingLeft: 20,
    paddingRight: 24,
  },
  boxCategoryListItem: {
    width: "100%",
    height: 40,
  },
  tabSelect: {
    paddingTop: 30,
    paddingLeft: 30,
    width: "100%",
    display: "flex",
  },
  bottomSelect: {
    marginLeft: "25%",
    display: "flex",
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down(600)]: {
      width: "100%",
      paddingLeft: 16,
      display: "block",
      marginLeft: "10%",
    },
  },
  disabledSaveButton: {
    opacity: 0.6,
  },
  typography: {
    color: theme.palette.white,
  },
  boxJSC: {
    paddingBottom: 20,
  },
  confirmDialogAction: {
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
  },
}));

export default MnMenu;

const idTabMenu = { order: "order", booking: "booking" };
