import { ApiConstant } from "../const";
import { createApiRegistration, createApiAttachmentUpload } from "../api";
import { format } from "react-string-format";

export const getInfo = data => createApiRegistration().get(format(ApiConstant.GET_USER_INFO, data.userId), {});

export const getShopInfo = () => createApiRegistration().get(ApiConstant.GET_USER_SHOP_INFO, {});

export const updateInfo = data => createApiRegistration().put(format(ApiConstant.UPDATE_USER_INFO, data.id), data);

export const updatePassword = data => createApiRegistration().put(ApiConstant.UPDATE_USER_PASSWORD, data);

export const upload = data => createApiAttachmentUpload().post(ApiConstant.UPLOAD_ATTACHMENT, data);

export const getChartDisplaySetting = data => createApiRegistration().get(ApiConstant.USER_CHART_DISPLAY_SETTING, data);

export const createChartDisplaySetting = data =>
  createApiRegistration().post(ApiConstant.USER_CHART_DISPLAY_SETTING, data);
