import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, CardMedia, makeStyles, Typography, Grid } from "@material-ui/core";
import clsx from "clsx";
import { ImageConstant, LangConstant } from "const";
import { getAttachmentUrl, getNSKey, onLoadImageError } from "utils";
import { ClassificationCheckbox } from "./index";

const ItemClassification = ({ data, onChange, selectedClassification, isService }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  const [secondModel, setSecondModel] = useState([]);
  const [imageSelected, setImageSelected] = useState(ImageConstant.EmptyImage);
  const [selectedFirstClass, setSelectedFirstClass] = useState(selectedClassification);

  const onSelectItemCategory = item => {
    if (item) {
      setImageSelected(item.image);
      if (item.groupModel) {
        setSecondModel(item.groupModel);
        setSelectedFirstClass(item);
        onChange(null);
      } else {
        onChange(item);
        setSecondModel(null);
      }
    } else {
      setSelectedFirstClass(null);
      onChange(null);
      setSecondModel(null);
    }
  };

  const onSelectSecondCategory = item => {
    if (item) {
      onChange(item);
    } else onChange(null);
  };

  useEffect(() => {
    if (selectedFirstClass) {
      data.modelList.forEach(modelItem => {
        if (selectedFirstClass.uid === modelItem.uid) setSecondModel(modelItem.groupModel);
      });
    }
  }, [selectedFirstClass]);

  useEffect(() => {
    if (selectedClassification) {
      let selectedFirstData = null;
      data?.modelList?.forEach(item => {
        if (item?.groupModel?.modelList?.find(modelItem => modelItem.uid === selectedClassification.uid)) {
          selectedFirstData = item;
        }
      });
      setSelectedFirstClass(selectedFirstData);
    }
  }, [selectedClassification]);

  return (
    <Box className={classes.itemListBox}>
      <Box className={classes.titleBox}>
        <Typography className={clsx(classes.chooseClassification, "bold-lg-txt")} color="inherit">
          {getLabel(getNSKey(LangConstant.NS_MANAGE_BOOKING, LangConstant.TXT_CHOOSE_REQUIRED_CATEGORY))}
        </Typography>
      </Box>

      <Box className={classes.itemBox}>
        <CardMedia
          component="img"
          onError={onLoadImageError}
          alt={imageSelected}
          src={getAttachmentUrl(imageSelected) || ImageConstant.EmptyImage}
          className={classes.itemImg}
        />
        <Box className={classes.categoryGroupContainer}>
          <Grid container className={classes.categoryRow}>
            <Grid item xs={2}>
              <Typography className={clsx("medium-md-txt", classes.categoryName)}>{data.name}</Typography>
            </Grid>
            <Grid item xs={10}>
              <ClassificationCheckbox
                arrayData={data.modelList}
                onClick={onSelectItemCategory}
                selectedItem={selectedFirstClass}
                isService={isService}
                isFirstClass={true}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.categoryRow}>
            <Grid item xs={2}>
              <Typography className={clsx("medium-md-txt", classes.categoryName)}>{secondModel?.name}</Typography>
            </Grid>
            <Grid item xs={10}>
              {secondModel && (
                <ClassificationCheckbox
                  arrayData={secondModel.modelList}
                  onClick={onSelectSecondCategory}
                  selectedItem={selectedClassification}
                  isService={isService}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

ItemClassification.propTypes = {
  data: PropTypes.object,
  selectedClassification: PropTypes.object,

  isService: PropTypes.bool,

  onChange: PropTypes.func,
};

ItemClassification.defaultProps = {
  data: {},
  selectedClassification: null,

  isService: false,

  onChange: () => {},
};

export default memo(ItemClassification);

const useStyles = makeStyles(theme => ({
  itemListBox: {
    width: "100%",
    marginTop: 36,
    padding: "24px 25px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 12px",
    },
  },

  titleBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
  },

  itemBox: {
    display: "flex",
  },

  itemBoxTitleIcon: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    width: 36,
    height: 36,
    boxShadow: "1px 0 4px 0 rgba(0, 0, 0, 0.16)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
  },

  starIcon: {
    color: theme.palette.black,
  },

  categoryGroupContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 16,
    width: "100%",
    paddingTop: 12,
  },

  categoryRow: {
    display: "flex",
    flexBasis: "auto",
    alignItems: "baseline",
    margin: "6px 0",
  },

  categoryName: {
    color: theme.palette.grey[500],
  },

  itemImg: {
    maxWidth: 178,
    height: 178,
    alignSelf: "flex-start",
  },

  chooseClassification: {
    textTransform: "uppercase",
  },
}));
