import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ItemDescriptionBlock } from "components/mn-master-product";
import { ProductItemInList } from "components/mnbooking";
import { AppConstant, LangConstant } from "const";
import { convertPricePreOrder, isArrayNotEqual, isArrayEqual, deepCloneJsonObject, formatUsingTime } from "utils";
import DialogListTopping from "./DialogListTopping";

const ListItems = ({ data, viewOption, onChooseItem, selectedData, setSelectedData }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();

  const [displayData, setDisplayData] = useState(data || []);
  const [itemDetail, setItemDetail] = useState(FORM_TOPPING_DEFAULT);
  const [classification, setClassification] = useState(null);

  const onOpenTopping = (data, selectedIndex, selectedItemIndex) => {
    let arrToppingIndex = [...data.toppingListIndex];
    // Select all toppings in item if item belongs to combo category
    if (data.type === AppConstant.TYPE_CATEGORY.combo) {
      let toppingArr = data.orderDetailsContentList || data.itemDetailsContentList;
      toppingArr.forEach((_, index) => arrToppingIndex.push(index));
    }
    setItemDetail({
      parentIndex: selectedIndex,
      childIndex: selectedItemIndex,
      isShow: true,
      data: data,
      listToppingIndex: arrToppingIndex || [],
    });
  };

  const onCheckedTopping = (event, index) => {
    let arrayToppingIndex = [...itemDetail.listToppingIndex];
    if (event.target.checked) {
      arrayToppingIndex.push(index);
    } else {
      arrayToppingIndex = arrayToppingIndex.filter(dataMap => dataMap !== index);
    }
    setItemDetail(dataMap => ({
      ...dataMap,
      listToppingIndex: arrayToppingIndex,
    }));
  };

  const onCloseTopping = () => {
    setItemDetail(FORM_TOPPING_DEFAULT);
  };

  const onSaveTopping = orderNumber => {
    let newSelectedItem = { ...itemDetail.data };
    newSelectedItem.orderNumber = orderNumber;
    newSelectedItem.classification = classification;
    newSelectedItem.toppingListIndex = [...itemDetail.listToppingIndex];
    newSelectedItem.toppingList = itemDetail.listToppingIndex.map(
      index => itemDetail.data.itemDetailsContentList[index],
    );
    let arrayItems = [...selectedData];
    let existedItemIndex = arrayItems.findIndex(dataMap => {
      let isResult = false;
      let isSameTopping = dataMap.isSelectedItem
        ? newSelectedItem.toppingList.length == dataMap.orderDetailsContentList?.length
        : newSelectedItem.toppingList.length == dataMap.toppingList?.length;

      if (newSelectedItem.itemUid) {
        // New item & Selected item belongs to Menu items
        isResult = dataMap.itemUid === newSelectedItem.itemUid;
      } else {
        // New item belongs to Category items
        if (dataMap.itemUid) {
          // Selected item belongs to Menu items
          isResult = dataMap.itemUid === newSelectedItem.uid;
        } else {
          // Selected item belongs to Menu itemsCategory items
          isResult = dataMap.uid === newSelectedItem.uid;
        }
      }

      let isSameItem = isResult && isSameTopping;
      if (isSameItem) {
        let arrSelectedDetailUid = newSelectedItem.toppingList.map(item => item.uid).sort();
        let arrDetailUid = [];
        dataMap.isSelectedItem
          ? (arrDetailUid = dataMap.orderDetailsContentList.map(item => item.itemUid).sort())
          : (arrDetailUid = dataMap.toppingList.map(item => item.uid).sort());
        // Compare 2 list topping
        isSameItem = isArrayEqual(arrSelectedDetailUid, arrDetailUid);
      }

      return isSameItem;
    });

    let existedItem = arrayItems[existedItemIndex];
    // Increase orderNumber item if item existed
    if (existedItem) arrayItems[existedItemIndex].orderNumber = existedItem.orderNumber + orderNumber;
    else {
      // Delete uid because this uid is uid of item table. Server just receive uid of order detail table
      if (newSelectedItem.itemUid) delete newSelectedItem[AppConstant.KEY_UID];

      newSelectedItem = {
        ...deepCloneJsonObject(newSelectedItem),
        ...formatClassificationItem(classification, deepCloneJsonObject(newSelectedItem)),
      };
      // Add new item when not exist
      arrayItems.push(newSelectedItem);
    }

    setClassification(null);
    setSelectedData(arrayItems);
    onCloseTopping();
  };

  const onClickItem = (dataChild, parentIndex, childIndex) => {
    if (dataChild.itemDetailsContentList.length > 0 || dataChild.groupModel?.modelList?.length > 0) {
      onOpenTopping(dataChild, parentIndex, childIndex);
    } else {
      onChooseItem(parentIndex, childIndex);
    }
  };

  useEffect(() => {
    if (isArrayNotEqual(data, displayData)) {
      setDisplayData(data);
    }
  }, [data]);

  useEffect(() => {
    let newItemDetails = deepCloneJsonObject(itemDetail);
    newItemDetails.data.classification = classification;
    setItemDetail(newItemDetails);
  }, [classification]);

  return (
    <Box className={defaultClasses.root}>
      {displayData.length > 0 &&
        displayData.map((dataParent, parentIndex) => (
          <Box id={`scroll-id-${parentIndex}`} key={parentIndex} marginTop={parentIndex !== 0 ? "54px" : "unset"}>
            <Typography className={defaultClasses.title}>{dataParent.name}</Typography>
            {viewOption == OPTION_VIEW.listView ? (
              <>
                {dataParent.itemList.length > 0 &&
                  dataParent.itemList.map((dataChild, childIndex) => (
                    <ProductItemInList
                      key={childIndex}
                      data={dataChild}
                      isChoose={true}
                      onChoose={() => {
                        onClickItem(dataChild, parentIndex, childIndex);
                      }}
                    />
                  ))}
              </>
            ) : (
              <Grid container spacing={3} className={defaultClasses.gridViewBox}>
                {dataParent.itemList.map((dataChild, childIndex) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={childIndex}>
                    <ItemDescriptionBlock
                      key={childIndex}
                      data={dataChild}
                      amount={getLabel(LangConstant.FM_ITEM_STOCK, { stock: dataChild.stock || 0 })}
                      time={formatUsingTime(dataChild, getLabel)}
                      price={convertPricePreOrder(dataChild)}
                      onChoose={() => {
                        onClickItem(dataChild, parentIndex, childIndex);
                      }}
                      typeButton={AppConstant.TYPE_BUTTON_CATEGORY.choose}
                      classes={{
                        root: defaultClasses.gridViewContent,
                        chooseItem: defaultClasses.chooseItem,
                        prefixContentCustom: defaultClasses.prefixContentCustom,
                        prefixTypoCustom: defaultClasses.prefixTypoCustom,
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        ))}

      <DialogListTopping
        isShow={itemDetail.isShow}
        data={itemDetail.data}
        listDataChecked={itemDetail.listToppingIndex || []}
        onClose={onCloseTopping}
        onCheckedTopping={onCheckedTopping}
        onSave={onSaveTopping}
        onChangeClassification={setClassification}
        classificationItem={classification || itemDetail?.data?.classification?.item}
      />
    </Box>
  );
};

export const formatClassificationItem = (classificationItem, detail) => {
  if (!(classificationItem && detail)) return {};

  let detailItem = classificationItem.item;

  return {
    itemUid: classificationItem ? detailItem.uid : detail.itemUid,
    name: classificationItem ? detailItem.name : detail.name,
    price: classificationItem ? detailItem.price : detail.price,
    priceRange: classificationItem ? detailItem.priceRange : detail.priceRange,
    priceTaxIn: classificationItem ? detailItem.priceTaxIn : detail.priceTaxIn,
    priceTaxInRange: classificationItem ? detailItem.priceTaxInRange : detail.priceTaxInRange,
  };
};

const FORM_TOPPING_DEFAULT = {
  isShow: false,
  index: null,
  listToppingIndex: [],
  parentIndex: 0,
  childIndex: 0,
  data: {
    name: "",
    orderNumber: 0,
    image: "",
    price: 0,
    priceTaxIn: 0,
    itemDetailsContentList: [],
    toppingList: [],
  },
};

export const OPTION_VIEW = {
  listView: 0,
  gridView: 1,
};

ListItems.propTypes = {
  data: PropTypes.array,
  viewOption: PropTypes.number,
  onChooseItem: PropTypes.func,
};
ListItems.defaultProps = {
  viewOption: OPTION_VIEW.listView,
};

export default memo(ListItems);

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(99% - 36px)",
    width: "100%",
    padding: "0 24px",
    marginTop: 36,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title: {
    marginBottom: 24,
    lineHeight: 1.5,
    color: "#3e4045",
    fontWeight: 600,
  },
  gridViewBox: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  gridViewContent: {},
  chooseItem: {
    height: 24,
    padding: 0,
    minWidth: 61,
    fontSize: 11,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
    "&.Mui-disabled": {
      opacity: 0.6,
      color: theme.palette.white,
    },
  },
  prefixContentCustom: {
    display: "flex",
    alignItems: "flex-start",
    color: theme.palette.text.link,
  },
}));
