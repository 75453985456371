import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";
import { BglobalJSC } from "components";
import { UpgradePlanTable, AdditionalPackage } from "./components";

const UpgradePlan = () => {
  const classes = useStyles();

  return (
    <>
      <UpgradePlanTable />
      <AdditionalPackage />
      <BglobalJSC className={classes.boxJSC} />
    </>
  );
};

export default memo(UpgradePlan);

const useStyles = makeStyles(() => ({
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));
