import React, { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { CropFree, Clear } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const UploadImageCell = props => {
  const { url, id, onOpenCrop, onRemoveImage, isDisabled } = props;
  const classes = useStyle();
  const { t: getLabel } = useTranslation();

  const [hovered, setHovered] = useState();

  return (
    <Box
      className={classes.singleImageCell}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img id={url} key={id} multiple src={url} alt="..." className={classes.imgPreviewThumbnail} />
      {hovered && !isDisabled && (
        <Box className={classes.hoverBox}>
          <Box className={classes.cropButton} onClick={onOpenCrop}>
            <CropFree className={classes.cropIcon} />
            <Typography className="medium-sm-txt">{getLabel(LangConstant.TXT_EDIT)}</Typography>
          </Box>
          <Clear onClick={onRemoveImage} className={classes.removeIcon} />
        </Box>
      )}
    </Box>
  );
};

export default UploadImageCell;
const useStyle = makeStyles(theme => ({
  singleImageCell: {
    width: 80,
    height: 80,
    borderRadius: 3,
    border: "solid 1px #d4d5d8",
    position: "relative",
  },

  imgPreviewThumbnail: {
    objectFit: "cover",
    width: 80,
    height: 80,
  },

  hoverBox: {
    position: "absolute",
    top: 0,
    width: 80,
    height: 80,
    justifyContent: "center",
    display: "flex",
    backgroundColor: "rgb(0, 0, 0, 0.3)",
  },

  cropButton: {
    display: "flex",
    height: 30,
    position: "absolute",
    width: "fit-content",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&>*": {
      color: theme.palette.white,
    },
  },

  cropIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
  },

  removeIcon: {
    position: "absolute",
    right: "6%",
    top: "6%",
    cursor: "pointer",
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: theme.palette.white,
    color: theme.palette.grey[700],
    display: "unset",
  },
}));
