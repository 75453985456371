import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Paper,
  Box,
  List,
  ListItem,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowForward,
  ArrowBack,
  Menu,
  DateRangeOutlined,
  PersonOutline,
  Lock,
  BusinessCenterOutlined,
  Store,
  HomeWork,
  Settings,
  AssignmentOutlined,
  AccountBalanceWalletOutlined,
  RateReview,
  Receipt,
  Notifications,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import SidebarItem from "./SidebarItem";
import Cookie from "js-cookie";
import { useLocation } from "react-router-dom";
import { PathConstant, AppConstant, LangConstant } from "const";
import { NewsListIcon, CustomerIcon, ReportIcon, BookingHistoryIcon, ActivityHistoryIcon, ProductIcon } from "icons";
import SidebarWithChildren from "./SidebarWithChildren";
import { SwitchAccount } from "components";
import clsx from "clsx";
import { HEIGHT_APP_BAR } from "./CustomAppBar";
import { forwardRef } from "react";
import { getFeatureConditions, getNSKey } from "utils";

const Sidebar = forwardRef((props, ref) => {
  const { isTop } = props;
  const { t: getLabel } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const role = Cookie.get(AppConstant.ROLE);
  const SIDEBAR_DEFAULT_PATHS = getDefaultSidebar(getLabel);
  const SIDEBAR_MANAGE_PATHS = getManageSidebar(getLabel);
  const SIDEBAR_ACCOUNT_PATHS = getAccountSidebar(getLabel);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { isPremium, isOnlyBooking, isOnlyOrdering } = getFeatureConditions(reservationSetting);

  const [isSidebar, setIsSidebar] = useState(true);
  const [isHover, setIsHover] = useState(false);
  const [sidebarData, setSidebarData] = useState([]);

  useEffect(() => {
    let newSidebar = [];
    if (ACCOUNT_PATHS.includes(location.pathname)) {
      newSidebar = SIDEBAR_ACCOUNT_PATHS;
    } else if (MANAGE_PATHS.includes(location.pathname)) {
      newSidebar = SIDEBAR_MANAGE_PATHS;
    } else {
      newSidebar = SIDEBAR_DEFAULT_PATHS;
    }
    let packageObj = { isPremium, isOnlyBooking, isOnlyOrdering };
    let sidebarCheck = newSidebar.filter(item => item.role >= role && checkFeature(item, packageObj));

    setSidebarData(sidebarCheck);
  }, [location.pathname, isPremium, isOnlyBooking, isOnlyOrdering]);

  useEffect(() => {
    if (Boolean(sidebarTimeout)) {
      clearSidebarTimeout();
    }

    if (isSidebar) {
      sidebarTimeout = setTimeout(() => {
        setIsSidebar(false);
      }, SIDEBAR_TIMEOUT);
    }
  }, [isSidebar]);

  return (
    <Paper
      ref={ref}
      className={`${classes.sidebar} ${isTop || isSidebar ? classes.sidebarOpen : classes.sidebarClose}`}
      elevation={1}
      square
      role="presentation"
    >
      {!isTop && (
        <ListItem className={`center-root ${classes.sidebarAction}`}>
          {isSidebar && <Box flexGrow={1} />}

          <IconButton
            edge={isSidebar ? "end" : "start"}
            onClick={() => setIsSidebar(!isSidebar)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {isHover ? isSidebar ? <ArrowBack /> : <ArrowForward /> : <Menu />}
          </IconButton>
        </ListItem>
      )}
      <List className={classes.list}>
        {sidebarData.map((item, index) => (
          <Tooltip
            key={index}
            title={item.text}
            classes={{ tooltip: clsx("medium-sm-txt", classes.customTooltip) }}
            placement="bottom-start"
            disableHoverListener={isSidebar || isMobile}
          >
            {item.children ? (
              <SidebarWithChildren
                data={item}
                listItems={item.children?.filter(child =>
                  checkFeature(child, { isOnlyBooking, isOnlyOrdering, isPremium }),
                )}
                isSidebar={isSidebar}
                onShowSidebar={() => setIsSidebar(true)}
              />
            ) : (
              <SidebarItem item={item} />
            )}
          </Tooltip>
        ))}
      </List>
      <SwitchAccount isFullContent={isTop || isSidebar} />
    </Paper>
  );
});

Sidebar.propTypes = {
  data: PropTypes.bool,
};

Sidebar.defaultProps = {
  isTop: false,
};
export default memo(Sidebar);

var sidebarTimeout = null;
const clearSidebarTimeout = () => {
  clearTimeout(sidebarTimeout);
  sidebarTimeout = null;
};

const checkFeature = (itemData, packageObj) =>
  !itemData.feature ||
  (itemData.feature === AppConstant.RESERVATION_SETTING_OPTION.useBooking && packageObj.isOnlyBooking) ||
  (itemData.feature === AppConstant.RESERVATION_SETTING_OPTION.useOrdering && packageObj.isOnlyOrdering) ||
  packageObj.isPremium;

export const SIDEBAR_WIDTH_OPEN = 260;
export const SIDEBAR_WIDTH_CLOSE = 64;
export const SIDEBAR_TIMEOUT = 30000;

const useStyles = makeStyles(theme => ({
  sidebar: {
    width: SIDEBAR_WIDTH_OPEN,
    height: "100%",
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: `calc(100vh - ${HEIGHT_APP_BAR}px)`,
    },
  },
  sidebarOpen: {
    width: SIDEBAR_WIDTH_OPEN,
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      zIndex: 101,
    },
  },
  sidebarClose: {
    overflowX: "hidden",
    width: SIDEBAR_WIDTH_CLOSE,
  },
  sidebarAction: { minHeight: "60px", padding: "0 20px", marginBottom: "-7px" },
  list: { padding: 0 },
  customTooltip: {
    height: "fit-content",
    width: "fit-content",
    backgroundColor: "#7f838c",
    color: theme.palette.white,
    padding: "2px 8px",
    alignItems: "center",
  },
}));

const MANAGE_PATHS = [
  PathConstant.MANAGE_SHOP_INFO,
  PathConstant.MANAGE_BOOKING,
  PathConstant.MANAGE_REPORT_DASHBOARD,
  PathConstant.MANAGE_REPORT_BOOKING,
  PathConstant.MANAGE_REPORT_CONSUMERS,
  PathConstant.MANAGE_REPORT_STAFF,
  PathConstant.MANAGE_NEWS,
  PathConstant.MANAGE_STAFF,
  PathConstant.MANAGE_CONSUMER,
  PathConstant.STAFF_SETTING,
  PathConstant.MANAGE_ACTIVITY_HISTORY,
  PathConstant.BOOKING_HISTORY,
  PathConstant.MANAGE_SERVICE,
  PathConstant.MANAGE_PRODUCT,
  PathConstant.MANAGE_COMBO,
  PathConstant.MANAGE_PLACE,
  PathConstant.CALENDAR_SETUP,
  PathConstant.MENU_SETUP,
  PathConstant.PAYMENT_SETUP,
  PathConstant.VAT_MANAGEMENT,
  PathConstant.MN_REVIEW,
];
const ACCOUNT_PATHS = [
  PathConstant.ACCOUNT,
  PathConstant.ACCOUNT_PASS,
  PathConstant.SUBSCRIPTION,
  PathConstant.UPGRADE_PLAN,
  PathConstant.PAYMENT_HISTORY,
  PathConstant.NOTIFICATION_SETTING,
];

const getDefaultSidebar = getLabel => {
  const role = Cookie.get(AppConstant.ROLE);

  return [
    {
      IconComponent: <DateRangeOutlined />,
      text: getLabel(LangConstant.TXT_RESERVATION),
      path: PathConstant.MANAGE_BOOKS,
      role: AppConstant.SHOP_STAFF,
      feature: AppConstant.RESERVATION_SETTING_OPTION.useBooking,
    },
    {
      IconComponent: <AssignmentOutlined />,
      text: getLabel(LangConstant.TXT_ORDER_TAB),
      path: PathConstant.MANAGE_ORDER,
      role: AppConstant.SHOP_STAFF,
      feature: AppConstant.RESERVATION_SETTING_OPTION.useOrdering,
    },
    {
      IconComponent: <BusinessCenterOutlined />,
      text: getLabel(LangConstant.TXT_MANAGER),
      path: role <= AppConstant.SHOP_OWNER ? PathConstant.MANAGE_SHOP_INFO : PathConstant.MANAGE_NEWS,
      isNewTab: true,
      role: AppConstant.SHOP_STAFF,
    },
  ];
};

const getManageSidebar = getLabel => {
  return [
    {
      IconComponent: <Store />,
      text: getLabel(LangConstant.TXT_SHOP_INFO_SIDEBAR),
      path: PathConstant.MANAGE_SHOP_INFO,
      role: AppConstant.SHOP_OWNER,
    },
    {
      IconComponent: <ProductIcon />,
      text: getLabel(LangConstant.TXT_MASTER_PRODUCT),
      role: AppConstant.SHOP_OWNER,
      feature: AppConstant.RESERVATION_SETTING_OPTION.useOrdering,
      children: [
        {
          text: getLabel(LangConstant.TXT_SERVICE),
          path: PathConstant.MANAGE_SERVICE,
        },
        {
          text: getLabel(LangConstant.TXT_PRODUCT),
          path: PathConstant.MANAGE_PRODUCT,
        },
        {
          text: getLabel(LangConstant.TXT_COMBO),
          path: PathConstant.MANAGE_COMBO,
        },
      ],
    },
    {
      IconComponent: <HomeWork />,
      text: getLabel(LangConstant.TXT_RESOURCES),
      role: AppConstant.SHOP_OWNER,
      children: [
        {
          text: getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE),
          path: PathConstant.MANAGE_PLACE,
          feature: AppConstant.RESERVATION_SETTING_OPTION.useBooking,
        },
        {
          text: getLabel(LangConstant.TXT_SELECT_SHOP_STAFF),
          path: PathConstant.MANAGE_STAFF,
        },
      ],
    },
    {
      IconComponent: <NewsListIcon />,
      text: getLabel(LangConstant.TXT_NEWS),
      path: PathConstant.MANAGE_NEWS,
      role: AppConstant.SHOP_STAFF,
    },
    {
      IconComponent: <CustomerIcon />,
      text: getLabel(LangConstant.TXT_SHOP_CONSUMER),
      path: PathConstant.MANAGE_CONSUMER,
      role: AppConstant.SHOP_OWNER,
    },
    {
      IconComponent: <BookingHistoryIcon />,
      text: getLabel(LangConstant.TXT_BOOKING_HISTORY),
      path: PathConstant.BOOKING_HISTORY,
      role: AppConstant.SHOP_MANAGER,
      feature: AppConstant.RESERVATION_SETTING_OPTION.useBooking,
    },
    {
      IconComponent: <ActivityHistoryIcon />,
      text: getLabel(LangConstant.TXT_ACTIVITY_HISTORY),
      path: PathConstant.MANAGE_ACTIVITY_HISTORY,
      role: AppConstant.SHOP_MANAGER,
    },
    {
      IconComponent: <ReportIcon />,
      text: getLabel(LangConstant.TXT_STATISTIC),
      role: AppConstant.SHOP_OWNER,
      children: [
        {
          text: getLabel(LangConstant.TXT_REPORT_SUMMARY),
          path: PathConstant.MANAGE_REPORT_DASHBOARD,
          feature: AppConstant.RESERVATION_SETTING_OPTION.useBooking,
        },
        {
          text: getLabel(LangConstant.TXT_RESERVATION),
          path: PathConstant.MANAGE_REPORT_BOOKING,
          feature: AppConstant.RESERVATION_SETTING_OPTION.useBooking,
        },
        {
          text: getLabel(LangConstant.TXT_SHOP_CONSUMER),
          path: PathConstant.MANAGE_REPORT_CONSUMERS,
        },
        {
          text: getLabel(LangConstant.TXT_STAFF_KPI),
          path: PathConstant.MANAGE_REPORT_STAFF,
        },
      ],
    },
    {
      IconComponent: <Receipt />,
      text: getLabel(getNSKey(LangConstant.NS_VAT, LangConstant.TXT_VAT_BILL)),
      path: PathConstant.VAT_MANAGEMENT,
      role: AppConstant.SHOP_STAFF,
    },
    {
      IconComponent: <RateReview />,
      text: getLabel(getNSKey(LangConstant.NS_RATING_MANAGEMENT, LangConstant.TXT_MN_REVIEW)),
      path: PathConstant.MN_REVIEW,
      role: AppConstant.SHOP_STAFF,
    },
    {
      IconComponent: <Settings />,
      text: getLabel(LangConstant.TXT_SETTING),
      role: AppConstant.SHOP_OWNER,
      children: [
        {
          text: getLabel(LangConstant.TXT_CALENDAR),
          path: PathConstant.CALENDAR_SETUP,
          feature: AppConstant.RESERVATION_SETTING_OPTION.useBooking,
        },
        {
          text: getLabel(LangConstant.TXT_BOOKING_PAGE),
          path: PathConstant.MANAGE_BOOKING,
          feature: AppConstant.RESERVATION_SETTING_OPTION.useBooking,
        },
        {
          text: getLabel(LangConstant.TXT_MENU),
          path: PathConstant.MENU_SETUP,
          feature: AppConstant.RESERVATION_SETTING_OPTION.useOrdering,
        },
        {
          text: getLabel(LangConstant.TXT_PAYMENT),
          path: PathConstant.PAYMENT_SETUP,
          feature: AppConstant.RESERVATION_SETTING_OPTION.useOrdering,
        },
      ],
    },
  ];
};

const getAccountSidebar = getLabel => {
  return [
    {
      IconComponent: <PersonOutline />,
      text: getLabel(LangConstant.TXT_ACCOUNT_STATUS),
      path: PathConstant.ACCOUNT,
      role: AppConstant.SHOP_STAFF,
    },
    {
      IconComponent: <Lock />,
      text: getLabel(LangConstant.TXT_PASS_WORD),
      path: PathConstant.ACCOUNT_PASS,
      role: AppConstant.SHOP_STAFF,
    },
    {
      IconComponent: <Notifications />,
      text: getLabel(LangConstant.TXT_NOTIFICATION_TITLE),
      path: PathConstant.NOTIFICATION_SETTING,
      role: AppConstant.SHOP_STAFF,
    },
    {
      IconComponent: <AccountBalanceWalletOutlined />,
      text: getLabel(LangConstant.TXT_MANAGE_PACKAGE),
      role: AppConstant.SHOP_OWNER,
      children: [
        {
          text: getLabel(LangConstant.TXT_MANAGE_SUBSCRIPTION),
          path: PathConstant.SUBSCRIPTION,
        },
        {
          text: getLabel(LangConstant.TXT_MANAGE_UPGRADE_PLAN),
          path: PathConstant.UPGRADE_PLAN,
        },
        {
          text: getLabel(LangConstant.TXT_MANAGE_PAYMENT_HISTORY),
          path: PathConstant.PAYMENT_HISTORY,
        },
      ],
    },
  ];
};
