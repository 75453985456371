import { AppConstant } from "const";

export default {
  TXT_SUBSCRIPTION: "Đăng ký",
  TXT_MY_PLAN_PACKAGE: "Gói cước:",
  TXT_MY_ACCOUNT: "Tài khoản",
  TXT_PLAN_PACKAGE_HISTORY: "Lịch sử gói cước",
  TXT_UPGRADE_PLAN: "Lựa chọn gói phù hợp với doanh nghiệp của bạn",

  TXT_PACKAGE_STANDARD: "Gói Tiêu Chuẩn",
  TXT_PACKAGE_PREMIUM: "Gói Cao Cấp",
  TXT_PACKAGE_ENTERPRISE: "Gói Doanh Nghiệp",
  TXT_PAYMENT_HISTORY: "Lịch sử thanh toán",
  TXT_MY_PAYMENT: "Chi tiết thanh toán",
  TXT_PAYMENT_DETAIL: "Chi tiết thanh toán",

  TXT_PLAN_NAME: "Tên gói:",
  TXT_PLAN_PERIOD: "Thời hạn:",
  TXT_PLAN_START_DATE: "Thời gian bắt đầu:",
  TXT_PLAN_END_DATE: "Thời gian kết thúc:",
  TXT_PLAN_STATUS: "Trạng thái gói:",

  TXT_NUMBER_OF_SHOPS: "Số lượng Shop:",
  TXT_NUMBER_OF_PRODUCTS: "Số lượng sản phẩm:",
  TXT_NUMBER_OF_LOCATIONS: "Số lượng vị trí:",
  TXT_NUMBER_OF_STAFFS: "Số lượng nhân viên:",
  TXT_FEATURES_PACKAGE: "Các tính năng:",

  TXT_ACCOUNT_USER_ID: "Mã người dùng:",
  TXT_ACCOUNT_PHONE_NUMBER: "Số điện thoại:",
  TXT_ACCOUNT_EMAIL: "Email:",
  TXT_ACCOUNT_SHOPS: "Shops:",

  TXT_PLAN_PACKAGE: "Gói cước:",
  TXT_ADDITION_PRODUCT: "Mở rộng tính năng:",

  TXT_INCLUDE_BOOKING_ORDER: "Bao gồm tính năng đặt chỗ và đặt hàng",
  TXT_ONLINE_APPOINTMENT: "Đặt lịch trực tuyến 24/7 - Trực quan và tối ưu trên mọi thiết bị di động",
  TXT_CREATE_SHOP: "Tạo Shop",
  TXT_BASIC_MN_BOOKING: "Quản lý đặt chỗ cơ bản",
  TXT_BASIC_BOOKING_DESCRIPTION:
    "Tạo/Xem/Chỉnh sửa đơn đặt chỗ, tính năng đặt hàng trước, xuất file excel từ danh sách quản lý đơn đặt chỗ",
  TXT_ADVANCE_MN_BOOKING: "Quản lý đặt chỗ nâng cao",
  TXT_ADVANCE_BOOKING_DESCRIPTION: "Danh sách khách chờ, quản lý thời gian sử dụng vị trí, lọc nhân viên",
  TXT_TIME_DISPLAY_REMOTE: "Giới hạn hiển thị dữ liệu đặt hàng",

  FM_PREMIUM_PACKAGE: "Gói nâng cao ({0})",
  FM_STANDARD_PACKAGE: "Gói tiêu chuẩn ({0})",
  FM_FREE_TRIALS: "Bản dùng thử ({0})",
  FM_MONTHS: "{0} tháng",
  FM_YEAR: "{0} năm",
  TXT_TB_PAYMENT_ID: "Mã thanh toán",
  TXT_TB_PAYMENT_DATE: "Ngày thanh toán",
  TXT_TB_START_DATE: "Thời gian bắt đầu",
  TXT_TB_END_DATE: "Thời gian kết thúc",
  TXT_TB_NAME: "Tên gói",
  TXT_TB_PERIOD: "Thời hạn",
  TXT_TB_AMOUNT: "Giá gói",
  TXT_TB_STATUS: "Trạng thái gói",
  TXT_TB_ITEMS: "Gói & Tính năng",
  TXT_TB_KIND: "Loại",
  TXT_TB_QTY: "Số lượng",
  TXT_TB_UNIT_PRICE: "Giá",
  TXT_TB_TOTAL: "Tổng cộng",

  TXT_STATUS_PAID: "Đã thanh toán",
  TXT_STATUS_UN_PAID: "Chưa thanh toán",
  TXT_STATUS_CANCELED: "Đã huỷ",

  TXT_ADDITIONAL_PACKAGE: "Mở rộng chức năng của gói",
  TXT_ADDITIONAL_DESCRIPTION:
    "Tất cả các gói đều có thể bổ sung các chức năng dưới đây (có giá trị 1 tháng từ khi bắt đầu đăng ký thêm)",

  TXT_CONTACT_US: "Liên hệ",
  TXT_BOTH: "Tất cả",
  TXT_UNLIMITED: "Không giới hạn",

  FM_CURRENCY: "{0} {1}",
  FM_TIME_MONTH: "{0} tháng",
  FM_ADDING_PRODUCT: "Thêm {0}",

  OBJ_FM_PACKAGE_TYPE: {
    [AppConstant.SHOP_PACKAGE.freeTrial]: "Bản dùng thử ({0})",
    [AppConstant.SHOP_PACKAGE.standard]: "Gói tiêu chuẩn ({0})",
    [AppConstant.SHOP_PACKAGE.premium]: "Gói nâng cao ({0})",
    [AppConstant.SHOP_PACKAGE.business]: "Gói doanh nghiệp ({0})",
  },
  OBJ_PACKAGE_TYPE: {
    [AppConstant.SHOP_PACKAGE.freeTrial]: "Bản dùng thử",
    [AppConstant.SHOP_PACKAGE.standard]: "Gói tiêu chuẩn",
    [AppConstant.SHOP_PACKAGE.premium]: "Gói nâng cao",
    [AppConstant.SHOP_PACKAGE.business]: "Gói doanh nghiệp",
  },
  OBJ_PACKAGE_USING_TYPE: {
    [AppConstant.PACKAGE_USING_TYPE.package]: "Tính năng",
    [AppConstant.PACKAGE_USING_TYPE.shop]: "Shop",
    [AppConstant.PACKAGE_USING_TYPE.orderFunction]: "Đặt hàng ",
    [AppConstant.PACKAGE_USING_TYPE.bookingFunction]: "Đặt chỗ",
    [AppConstant.PACKAGE_USING_TYPE.item]: "Sản phẩm",
    [AppConstant.PACKAGE_USING_TYPE.staff]: "Nhân viên",
    [AppConstant.PACKAGE_USING_TYPE.place]: "Vị trí",
  },
  OBJ_PACKAGE_STATUS: {
    [AppConstant.STATE.active]: "Đang hoạt động",
    [AppConstant.STATE.inactive]: "Không hoạt động",
  },
  OBJ_ADDITIONAL_PACKAGE: {
    [AppConstant.PACKAGE_USING_TYPE.package]: {
      title: "Thêm đặt chỗ hoặc đặt hàng",
      description: "Thêm chức năng này mà không cần thay đổi gói dịch vụ đang sử dụng",
      price: 250000,
      numberItem: 1,
    },
    [AppConstant.PACKAGE_USING_TYPE.shop]: {
      title: "Thêm Shop",
      description: "Thêm chức năng này mà không cần thay đổi gói dịch vụ đang sử dụng",
      price: 500000,
      numberItem: 1,
    },
    [AppConstant.PACKAGE_USING_TYPE.staff]: {
      title: "Thêm nhân viên",
      description: "Thêm chức năng này mà không cần thay đổi gói dịch vụ đang sử dụng",
      price: 50000,
      numberItem: 1,
    },
    [AppConstant.PACKAGE_USING_TYPE.item]: {
      title: "Thêm sản phẩm ",
      description: "Thêm chức năng này mà không cần thay đổi gói dịch vụ đang sử dụng",
      price: 50000,
      numberItem: 10,
    },
    [AppConstant.PACKAGE_USING_TYPE.place]: {
      title: "Thêm vị trí ",
      description: "Thêm chức năng này mà không cần thay đổi gói dịch vụ đang sử dụng",
      price: 50000,
      numberItem: 10,
    },
  },
};
