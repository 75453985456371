import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LangConstant } from "../../../../const";
import ShopAction from "../../../../redux/shop.redux";

const SinglePageRowLinked = props => {
  const classes = useStyle();
  const { listPages } = props;
  const heightRef = useRef();
  const [height, setHeight] = useState(0);
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (heightRef.current) {
      setHeight(heightRef.current.offsetHeight);
    }
  }, []);

  const changeLinked = uid => {
    dispatch(ShopAction.requestPutFacebookLink({ uid: uid }));
    dispatch(ShopAction.requestGetFacebookPage());
  };
  return (
    <>
      {listPages.map((data, index) => (
        <React.Fragment key={index}>
          <Grid
            item
            xs={12}
            sm={8}
            className={`${height < 40 ? classes.pageAvaNameSingleRow : classes.pageAvaNameMultiRow}`}
          >
            <Avatar src={data.avatar}></Avatar>
            <Typography className={classes.pagesName} ref={heightRef}>
              {data.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.linkStatusBtn}>
            <Typography
              component="a"
              className={classes.linkedStatus}
              onClick={() => {
                changeLinked(data.uid);
              }}
            >
              {data.state === 1
                ? getLabel(LangConstant.TXT_SHOP_FACEBOOK_MOUNTSTATUS_MOUNTED)
                : getLabel(LangConstant.TXT_SHOP_FACEBOOK_MOUNTSTATUS_UNMOUNTED)}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

export default SinglePageRowLinked;
const useStyle = makeStyles(theme => ({
  pageAvaNameSingleRow: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "28vw",
    paddingTop: "2vh",
    [theme.breakpoints.down(600)]: {
      maxWidth: "70%",
      paddingLeft: 0,
    },
  },
  pageAvaNameMultiRow: {
    display: "flex",
    textAlign: "left",
    alignItems: "end",
    paddingTop: "2vh",
    justifyContent: "flex-start",
    paddingLeft: "28vw",
    [theme.breakpoints.down(600)]: {
      maxWidth: "70%",
      paddingLeft: 0,
      paddingTop: "2vh",
    },
  },
  linkStatusBtn: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "22vw",
    paddingTop: "2vh",
    height: "10vh",
    [theme.breakpoints.down(600)]: {
      textAlign: "center",
      alignItems: "center",
      maxWidth: "30%",
      paddingRight: 0,
    },
  },

  pagesName: {
    marginLeft: "16px",
  },
  linkedStatus: {
    color: theme.palette.text.link,
    fontSize: "13px",
    fontWeight: theme.typography.h5.fontWeight,
  },
}));
