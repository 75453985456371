import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { InputPrice } from "components/mn-master-product";
import DialogPreOrder from "./DialogPreOrder";
import { LangConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { getCommonKey } from "utils";

const AddPreOrderAddReservation = ({ dataOrder, depositTotal, onSubmit, onChangePrice }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting, shallowEqual);
  const reservationDepositSetting = reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit]?.details;
  let isSettingDeposit = Boolean(
    reservationSetting &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit] &&
      Boolean(reservationDepositSetting?.depositInformation?.publish),
  );

  const [dataOrderDisplay, setDataOrderDisplay] = useState();
  const [displayDialog, setDisplayDialog] = useState(PRE_ORDER_ACTION.close);
  const [preOrderDialogStatus, setPreOrderDialogStatus] = useState(PRE_ORDER_ACTION.noData);
  const [depositTotalDisplay, setDepositTotalDisplay] = useState("");

  const onSubmitFormData = (data, price) => {
    if (data.length > 0) {
      switch (displayDialog) {
        case PRE_ORDER_ACTION.openMenu:
          setPreOrderDialogStatus(PRE_ORDER_ACTION.detailsMenu);
          onSubmit(data, price);
          break;
        case PRE_ORDER_ACTION.openProduct:
          setPreOrderDialogStatus(PRE_ORDER_ACTION.detailsProduct);
          onSubmit(data, price);
          break;
      }
    } else {
      setPreOrderDialogStatus(PRE_ORDER_ACTION.noData);
      onSubmit([], 0);
      setDataOrderDisplay([]);
    }
    onCloseDialogPreOrder();
  };

  const onSeenDetails = () => {
    switch (preOrderDialogStatus) {
      case PRE_ORDER_ACTION.detailsMenu:
        setDataOrderDisplay(dataOrder);
        setDisplayDialog(PRE_ORDER_ACTION.openMenu);
        break;
      case PRE_ORDER_ACTION.detailsProduct:
        setDataOrderDisplay(dataOrder);
        setDisplayDialog(PRE_ORDER_ACTION.openProduct);
        break;
    }
  };

  const onCloseDialogPreOrder = () => {
    setDisplayDialog(PRE_ORDER_ACTION.close);
  };

  useEffect(() => {
    setDepositTotalDisplay(depositTotal ? depositTotal.toString() : "");
  }, [depositTotal]);

  return (
    <Box className={defaultClasses.root}>
      <Typography className={defaultClasses.titleLabel}>{getLabel(LangConstant.TXT_INFO_PRE_BOOKING)}</Typography>
      <Box className={defaultClasses.chooseProductBox}>
        <Typography className={`semiBold-sm-txt ${defaultClasses.textProduct}`}>
          {getLabel(getCommonKey(LangConstant.TXT_MASTER_PRODUCT))}
        </Typography>
        <Box className={defaultClasses.buttonChooseBox}>
          {preOrderDialogStatus === PRE_ORDER_ACTION.noData ? (
            <>
              <Button
                onClick={() => setDisplayDialog(PRE_ORDER_ACTION.openMenu)}
                classes={{ root: defaultClasses.rootButton, text: defaultClasses.textButton }}
              >
                {getLabel(LangConstant.TXT_CHOOSE_FROM_MENU)}
              </Button>
              <Button
                onClick={() => setDisplayDialog(PRE_ORDER_ACTION.openProduct)}
                classes={{ root: defaultClasses.rootButton, text: defaultClasses.textButton }}
              >
                {getLabel(LangConstant.TXT_CHOOSE_FROM_PRODUCT_CATEGORY)}
              </Button>
            </>
          ) : (
            <Button
              onClick={onSeenDetails}
              classes={{ root: defaultClasses.rootButton, text: defaultClasses.textButton }}
            >
              {getLabel(getCommonKey(LangConstant.TXT_SEEN_DETAIL))}
            </Button>
          )}
        </Box>
      </Box>
      {isSettingDeposit && (
        <Box className={defaultClasses.prePaymentBox}>
          <Typography className={`semiBold-sm-txt ${defaultClasses.textPayment}`}>
            {getLabel(LangConstant.TXT_PRE_ORDER)}
          </Typography>
          <Box className={defaultClasses.buttonChooseBox}>
            <InputPrice
              onChange={onChangePrice}
              currencyUnit={getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON))}
              valueInput={depositTotalDisplay}
              classPrice={defaultClasses.rootOverridePrice}
              classes={{
                inputOverridePrice: defaultClasses.inputOverridePrice,
                inputOverride: defaultClasses.inputOverride,
              }}
            />
          </Box>
        </Box>
      )}
      {PRE_ORDER_ACTION.close !== displayDialog && (
        <DialogPreOrder
          data={dataOrderDisplay || []}
          isShow={Boolean(PRE_ORDER_ACTION.close !== displayDialog)}
          isShowCategory={Boolean(PRE_ORDER_ACTION.openProduct === displayDialog)}
          onSubmitFormData={onSubmitFormData}
          onClose={onCloseDialogPreOrder}
        />
      )}
    </Box>
  );
};

AddPreOrderAddReservation.propTypes = {
  dataOrder: PropTypes.array,
  depositTotal: PropTypes.number,
  onSubmit: PropTypes.func,
  onChangePrice: PropTypes.func,
};
AddPreOrderAddReservation.defaultProps = {};

export default memo(AddPreOrderAddReservation);

export const PRE_ORDER_ACTION = {
  close: 0,
  noData: 1,
  openMenu: 2,
  openProduct: 3,
  detailsMenu: 4,
  detailsProduct: 5,
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: "11px 0",
  },
  titleLabel: {
    height: 24,
    lineHeight: 1.71,
    color: "#3e4045",
    fontSize: 14,
    marginBottom: 20,
  },
  chooseProductBox: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 13,
    marginBottom: 15,
  },
  textProduct: {
    height: 20,
    marginTop: 1,
    marginBottom: 1,
    lineHeight: 1.67,
    color: "#3e4045",
  },
  buttonChooseBox: {
    display: "flex",
    width: "60%",
    justifyContent: "space-between",
    "@media only screen and (max-width: 414px)": {
      alignItems: "start",
      flexFlow: "column",
    },
  },
  rootButton: {
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  textButton: {
    padding: 0,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.69,
    color: theme.palette.text.link,
    textTransform: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  prePaymentBox: {
    display: "flex",
    height: 30,
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 13,
  },
  textPayment: {
    height: 20,
    lineHeight: 1.67,
    color: "#3e4045",
  },
  rootOverridePrice: {
    height: 30,
    maxWidth: 159,
  },
  inputOverridePrice: {
    fontSize: 13,
    color: "#565c6a",
  },
  inputOverride: {
    justifyContent: "center",
    borderLeft: "solid 0.5px #cecfd2",
    marginLeft: 0,
    minWidth: 40,
    width: 40,
    height: "100%",
    maxHeight: "100%",
    minHeight: 30,
  },
}));
