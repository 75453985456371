import { createReducer, createActions } from "reduxsauce";
import { AppConstant } from "../const";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestGetUserInfo: ["data"],
  getUserInfoSuccess: ["data"],
  getUserInfoFailure: ["data"],
  requestGetUserShopInfo: ["data"],
  getUserShopInfoSuccess: ["data"],
  getUserShopInfoFailure: ["data"],
  requestUpdateUserInfo: ["data"],
  updateUserInfoSuccess: ["data"],
  updateUserInfoFailure: ["data"],
  resetUserUpdate: ["data"],
  requestUpdateUserPassword: ["data"],
  updateUserPasswordSuccess: ["data"],
  updateUserPasswordFailure: ["data"],
  requestUploadUserImage: ["data"],
  uploadUserImageSuccess: ["data"],
  uploadUserImageFailure: ["data"],
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  //User read
  isFetchingUser: false,
  errorUser: null,
  isUserSuccess: false,
  dataUser: null,
  // shop user read
  isFetchingUserShop: false,
  errorUserShop: null,
  isUserShopSuccess: false,
  dataUserShop: null,
  //User read
  isFetchingUserUpdate: false,
  errorUserUpdate: null,
  isUserUpdateSuccess: false,
  //User update pass word
  isFetchingPassUpdate: false,
  errorUserPassUpdate: null,
  isPassUpdateSuccess: false,
  //upload
  isUpLoadFetching: false,
  isUploadSuccess: false,
};

/* ------------- Reducers ------------- */
export const requestGetUserInfo = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingUser: true,
});

export const getUserInfoSuccess = (state = INITIAL_STATE, action) => {
  if (action.data && action.data[AppConstant.KEY_BIRTHDAY]) {
    let date = new Date(action.data[AppConstant.KEY_BIRTHDAY] * 1000);
    action.data[AppConstant.KEY_DAY] = date.getUTCDate();
    action.data[AppConstant.KEY_MONTH] = date.getUTCMonth() + 1;
    action.data[AppConstant.KEY_YEAR] = date.getUTCFullYear();
  }
  return {
    ...state,
    isFetchingUser: false,
    isUserSuccess: true,
    dataUser: action.data,
  };
};

export const getUserInfoFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUser: false,
    isUserSuccess: false,
    errorUser: action.error,
  };
};

/* ------------- Reducers ------------- */
export const requestGetUserShopInfo = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingUserShop: true,
});

export const getUserShopInfoSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUserShop: false,
    isUserShopSuccess: true,
    dataUserShop: action.data,
  };
};

export const getUserShopInfoFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingUserShop: false,
  isUserShopSuccess: false,
  errorUserShop: action.error,
});

/* ------------- Reducers ------------- */
export const requestUpdateUserInfo = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingUserUpdate: true,
});

export const updateUserInfoSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUserUpdate: false,
    isUserUpdateSuccess: true,
    dataUser: action.data,
  };
};

export const updateUserInfoFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUserUpdate: false,
    errorUserUpdate: action.error,
    isUserUpdateSuccess: false,
  };
};

export const resetUserUpdate = (state = INITIAL_STATE) => {
  return {
    ...state,
    isUserUpdateSuccess: false,
    isPassUpdateSuccess: false,
  };
};

/* ------------- Reducers ------------- */
export const requestUpdateUserPassword = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingPassUpdate: true,
});

export const updateUserPasswordSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetchingPassUpdate: false,
    isPassUpdateSuccess: true,
  };
};

export const updateUserPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingPassUpdate: false,
    errorUserPassUpdate: JSON.stringify(action.error),
    isPassUpdateSuccess: false,
  };
};

/* ------------- Reducers ------------- */
export const requestUploadUserImage = (state = INITIAL_STATE) => ({
  ...state,
  isUploadFetching: true,
});

export const uploadUserImageSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpLoadFetching: false,
    isUploadSuccess: true,
    dataUser: action.data,
  };
};

export const uploadUserImageFailure = (state = INITIAL_STATE) => ({
  ...state,
  isUploadFetching: false,
  isUploadSuccess: false,
  // errorUpdate: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_GET_USER_INFO]: requestGetUserInfo,
  [Types.GET_USER_INFO_SUCCESS]: getUserInfoSuccess,
  [Types.GET_USER_INFO_FAILURE]: getUserInfoFailure,
  [Types.REQUEST_GET_USER_SHOP_INFO]: requestGetUserShopInfo,
  [Types.GET_USER_SHOP_INFO_SUCCESS]: getUserShopInfoSuccess,
  [Types.GET_USER_SHOP_INFO_FAILURE]: getUserShopInfoFailure,
  [Types.REQUEST_UPDATE_USER_INFO]: requestUpdateUserInfo,
  [Types.UPDATE_USER_INFO_SUCCESS]: updateUserInfoSuccess,
  [Types.UPDATE_USER_INFO_FAILURE]: updateUserInfoFailure,
  [Types.RESET_USER_UPDATE]: resetUserUpdate,
  [Types.REQUEST_UPDATE_USER_PASSWORD]: requestUpdateUserPassword,
  [Types.UPDATE_USER_PASSWORD_SUCCESS]: updateUserPasswordSuccess,
  [Types.UPDATE_USER_PASSWORD_FAILURE]: updateUserPasswordFailure,
  [Types.REQUEST_UPLOAD_USER_IMAGE]: requestUploadUserImage,
  [Types.UPLOAD_USER_IMAGE_SUCCESS]: uploadUserImageSuccess,
  [Types.UPLOAD_USER_IMAGE_FAILURE]: uploadUserImageFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
