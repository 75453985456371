import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, ListItemText, ListItem, ListItemIcon, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

const SidebarItem = forwardRef((props, ref) => {
  const { item, isChildren, ...otherProps } = props;
  const classes = useStyles();
  const location = useLocation();

  const configLink = item.isNewTab ? { target: "_blank" } : {};
  if (!item) return;

  return (
    <Link {...otherProps} to={item.path} className="no-style-link" {...configLink}>
      <ListItem
        button
        classes={{ root: classes.item, selected: isChildren ? classes.selectedSubItem : classes.selectedItem }}
        selected={item.path === location.pathname}
      >
        <ListItemIcon ref={ref} className={classes.itemIcon}>
          {item.IconComponent}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography className="semiBold-md-txt" color="inherit">
              {item.text}
            </Typography>
          }
          className={classes.itemText}
        />
      </ListItem>
    </Link>
  );
});

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  isChildren: PropTypes.bool,
};
SidebarItem.defaultProps = { isChildren: false };

const useStyles = makeStyles(theme => ({
  item: {
    minHeight: 40,
    padding: "0 20px",
    margin: "7px 0",
    color: theme.palette.grey[800],
    "&:hover": { backgroundColor: theme.palette.grey[700] + "!important", color: theme.palette.white },
  },
  itemIcon: {
    minWidth: 30,
    color: "inherit",
    "&>*": {
      width: 24,
      height: 24,
    },
  },
  itemText: {
    marginLeft: 20,
    color: "inherit",
  },
  selectedItem: {
    backgroundColor: theme.palette.grey[700] + "!important",
    color: theme.palette.white,
  },
  selectedSubItem: {
    backgroundColor: "white !important",
    color: theme.palette.text.link,
  },
}));

export default memo(SidebarItem);
