import React, { memo, useEffect, useState, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, ListItem, Collapse, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SidebarItem from "./SidebarItem";

const SidebarWithChildren = forwardRef((props, ref) => {
  const { data, listItems, isSidebar, onShowSidebar, ...otherProps } = props;
  const location = useLocation();
  const classes = useStyles();

  const [isSelected] = useState(listItems.map(item => item.path).includes(location.pathname));
  const [isShowChildren, setIsShowChildren] = useState(isSelected);

  const onClickItem = () => {
    if (!isSidebar) {
      onShowSidebar();
      setIsShowChildren(true);
    } else {
      setIsShowChildren(!isShowChildren);
    }
  };

  useEffect(() => {
    if (isSidebar && isSelected) {
      setIsShowChildren(true);
    }
  }, [isSidebar]);

  return (
    <>
      <ListItem
        {...otherProps}
        button
        classes={{ root: classes.item, selected: classes.selectedItem }}
        selected={isSelected}
        onClick={onClickItem}
      >
        <ListItemIcon ref={ref} className={classes.itemIcon}>
          {data.IconComponent}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography className="semiBold-md-txt" color="inherit">
              {data.text}
            </Typography>
          }
          className={classes.itemText}
        />
        {isShowChildren ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isSidebar && isShowChildren} timeout="auto" unmountOnExit>
        {listItems.map((item, index) => (
          <SidebarItem item={item} isChildren={true} key={index} />
        ))}
      </Collapse>
    </>
  );
});

SidebarWithChildren.propTypes = {
  data: PropTypes.object.isRequired,
  listItems: PropTypes.array.isRequired,
  isSidebar: PropTypes.bool,
  onShowSidebar: PropTypes.func,
};
SidebarWithChildren.defaultProps = { isSidebar: false };

export default memo(SidebarWithChildren);

const useStyles = makeStyles(theme => ({
  item: {
    minHeight: 40,
    padding: "0 16px",
    margin: "7px 0",
    color: theme.palette.grey[800],
    "&:hover": { backgroundColor: theme.palette.grey[700] + "!important", color: theme.palette.white },
  },
  itemIcon: {
    minWidth: 30,
    color: "inherit",
    "&>*": {
      width: 24,
      height: 24,
    },
  },
  itemText: {
    marginLeft: 20,
    color: "inherit",
  },
  selectedItem: {
    backgroundColor: theme.palette.grey[700] + "!important",
    color: theme.palette.white,
  },
}));
