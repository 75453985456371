import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography, Paper, makeStyles, Button, InputAdornment, OutlinedInput } from "@material-ui/core";
import ShopInfoAction from "redux/shop-info.redux";
import ReservationAction from "redux/reservation.redux";
import { AppConstant, LangConstant } from "const";
import DisplayBookingDetailSettingMenuCalendar from "./DisplayBookingDetailSettingMenuCalendar";
import CalendarPreview from "./CalendarPreview";
import { checkInteger, createArrayWithLabel, getCommonKey, onCloseBrowser } from "utils";
import { InputUsedTime } from "components/mn-master-product";

const HeaderCalendar = () => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const shopInfoRedux = useSelector(state => state.shopInfoRedux.data);

  const [isShowSettingMenu, setIsShowSettingMenu] = useState(false);
  const [timeServer, setTimeServer] = useState({});
  const [dataDetailsSetting, setDataDetailsSetting] = useState([]);
  const [consumerCapacity, setConsumerCapacity] = useState("");
  const [valueInputTime, setValueInputTime] = useState();
  const [timeValueDisplay, setTimeValueDisplay] = useState(30);
  const [typeTimeValue, setTypeTimeValue] = useState(AppConstant.TYPE_TIME_USED_CALENDAR.minute);
  const [isEnableSave, setIsEnableSave] = useState(false);
  const classes = useStyles();

  const settingDay = () => {
    setIsShowSettingMenu(true);
  };
  const onClickDataDetailsSetting = data => {
    setIsEnableSave(true);
    setDataDetailsSetting(data);
  };

  const onChangeTime = timeValue => {
    setIsEnableSave(true);
    if (checkInteger(timeValue) || timeValue === "") {
      if (parseInt(timeValue) === 0) {
        setValueInputTime(timeValue);
        setTimeValueDisplay(30);
      } else if (timeValue === "") {
        setValueInputTime(timeValue);
        setTimeValueDisplay(30);
      } else {
        if (typeTimeValue === AppConstant.TYPE_TIME_USED_CALENDAR.minute) {
          setTimeValueDisplay(timeValue);
        } else {
          setTimeValueDisplay(timeValue * 60);
        }
        setValueInputTime(timeValue);
      }
    }
  };

  const onChangeConsumerCapacity = value => {
    setIsEnableSave(true);
    let peopleValue = value.target.value;
    if (checkInteger(peopleValue) && peopleValue.length < 9) {
      setConsumerCapacity(parseInt(value.target.value));
    } else if (peopleValue === "" && peopleValue.length < 9) {
      setConsumerCapacity(0);
    }
  };

  const saveSetting = () => {
    if (shopInfoRedux) {
      var timeFrame;
      if (typeTimeValue === 1) {
        timeFrame = valueInputTime * 60;
      } else {
        timeFrame = valueInputTime * 60 * 60;
      }
      var details = {
        ...shopInfoRedux.details,
        timeFrame: timeFrame,
        consumerCapacity: consumerCapacity,
      };
      var updatedData = { ...shopInfoRedux, details: details };
      dispatch(ReservationAction.updateDisplaySetting(dataDetailsSetting));
      dispatch(ShopInfoAction.updateShopInfoData(updatedData));
      setIsEnableSave(false);
    }
  };

  const onClickTimeUsed = value => {
    setTypeTimeValue(value);
  };

  useEffect(() => {
    if (shopInfoRedux && timeValueDisplay) {
      var i = 0,
        timeArray = [],
        timeArrayObject = {},
        open = 28800,
        close = 79200;
      while (open + timeValueDisplay * 60 * i <= close) {
        var time = open + i * (timeValueDisplay * 60);
        timeArray.push(convertTime(time));
        i++;
      }
      for (let i = 0; i < timeArray.length; i++) {
        if (i === 2 || i === 10 || i === 6) {
          timeArrayObject[timeArray[i]] = DATA_DEFAULT_PREVIEW;
        } else {
          timeArrayObject[timeArray[i]] = previewNodata;
        }
      }
      setTimeServer(timeArrayObject);
    }
  }, [shopInfoRedux, valueInputTime]);

  useEffect(() => {
    if (shopInfoRedux) {
      var data = shopInfoRedux;
      var timeFrame = data.details.timeFrame / 60;
      if (timeFrame % 60 === 0 && timeFrame !== 0) {
        setValueInputTime(timeFrame / 60 || "");
        setTypeTimeValue(AppConstant.TYPE_TIME_USED_CALENDAR.hour);
      } else {
        setValueInputTime(timeFrame || "");
        setTypeTimeValue(AppConstant.TYPE_TIME_USED_CALENDAR.minute);
      }
      setTimeValueDisplay(timeFrame || 30);
      setConsumerCapacity(data.details.consumerCapacity || "");
    }
  }, [shopInfoRedux]);

  useEffect(() => {
    dispatch(ShopInfoAction.getShopInfoData({}));
  }, []);

  useEffect(() => {
    if (isEnableSave) {
      window.addEventListener("beforeunload", onCloseBrowser);

      return () => {
        // handling will unmounted component
        window.removeEventListener("beforeunload", onCloseBrowser);
      };
    }
  }, [isEnableSave]);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.header}>
        <Typography className={classes.textHeader}>
          {getLabel(getCommonKey(LangConstant.TXT_CALENDER_SETUP))}
        </Typography>
        <Button
          onClick={saveSetting}
          classes={{ root: `semiBold-lg-txt ${classes.buttonSave}`, disabled: classes.disabledSaveButton }}
          disabled={!isEnableSave}
        >
          <Typography className={classes.typography}>{getLabel(getCommonKey(LangConstant.TXT_SAVE))}</Typography>
        </Button>
      </Box>
      <Box className={classes.body}>
        <Typography className={classes.textTimeSetup}>{getLabel(getCommonKey(LangConstant.TXT_TIME_FRAME))}</Typography>
        {(valueInputTime || valueInputTime === "" || valueInputTime === 0) && (
          <InputUsedTime
            valueTime={valueInputTime}
            valueTimeType={typeTimeValue}
            dataChange={createArrayWithLabel(
              AppConstant.TYPE_TIME_USED_CALENDAR,
              LangConstant.ARR_TYPE_TIME_USED_CALENDAR,
              getLabel,
              true,
            )}
            onChange={onChangeTime}
            onClick={onClickTimeUsed}
            className={classes.inputUsedTime}
            placeholderTime={getLabel(getCommonKey(LangConstant.TXT_MINUTES))}
            placeholderValue="30"
          />
        )}
      </Box>
      <Box className={classes.body}>
        <Typography className={classes.textTimeSetup}>
          {getLabel(getCommonKey(LangConstant.TXT_MAXIMUM_PEOPLE))}
        </Typography>
        <OutlinedInput
          classes={{ root: classes.rootInput, input: classes.inputOutline }}
          placeholder={"0"}
          value={consumerCapacity}
          onChange={onChangeConsumerCapacity}
          endAdornment={
            <InputAdornment position="end" className={classes.title}>
              <Typography variant="body1">{getLabel(getCommonKey(LangConstant.TXT_PEOPLE))}</Typography>
            </InputAdornment>
          }
        />
      </Box>
      <Box className={classes.body}>
        <Typography className={classes.textTimeSetup}>
          {getLabel(getCommonKey(LangConstant.TXT_INFORMATION_DISPLAYED))}
        </Typography>
        <Button onClick={settingDay} className={`medium-md-txt ${classes.textSetting}`}>
          {getLabel(getCommonKey(LangConstant.TXT_SETTING))}
        </Button>
      </Box>
      <CalendarPreview data={timeServer} dataSetting={dataDetailsSetting} consumerCapacity={consumerCapacity} />
      <DisplayBookingDetailSettingMenuCalendar
        onSaveDataSetting={onClickDataDetailsSetting}
        isOpen={isShowSettingMenu}
        setIsOpen={setIsShowSettingMenu}
      />
      <Prompt when={isEnableSave} message={getLabel(getCommonKey(LangConstant.TXT_NOTIFICATION_MESSAGE))} />
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "44%",
    flexGrow: 1,
    minWidth: 200,
  },
  header: {
    width: "100%",
    padding: 30,
    display: "flex",
    borderBottom: "1px solid #F1F3F6",
  },
  textHeader: {
    float: "left",
    width: "95%",
    paddingTop: 6,
    color: theme.palette.black,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      width: "85%",
    },
  },
  buttonSave: {
    textTransform: "none",
    backgroundColor: "#ef5845",
    color: "white",
    float: "right",
    height: 40,
    width: 78,
    [theme.breakpoints.down(600)]: {
      width: "15%",
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "#ef5845",
    },
  },
  body: {
    width: "100%",
    padding: 30,
    display: "flex",
  },
  inputUsedTime: {
    width: 188,
  },
  textTimeSetup: {
    width: "15%",
    paddingTop: 6,
    marginRight: "5%",
    color: "#3E4045",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textSetting: {
    width: 100,
    height: 30,
    textTransform: "none",
    backgroundColor: "#447AFF",
    color: "white",
    "&.MuiButton-root:hover": {
      backgroundColor: "#447AFF",
    },
  },
  title: {
    justifyContent: "center",
    borderLeft: "solid 0.5px #cecfd2",
    marginLeft: 0,
    width: 80,
    maxHeight: "100%",
    height: 40,
  },
  inputOutline: {
    width: 80,
  },
  rootInput: {
    height: 40,
  },

  disabledSaveButton: {
    opacity: 0.6,
  },
  typography: {
    color: theme.palette.white,
  },
}));

export default HeaderCalendar;

const DATA_DEFAULT_PREVIEW = {
  total: {
    consumer: {
      total: 20,
      child: 0,
      man: 0,
    },
    reservationCount: 100,
  },
  reservation: [
    {
      periodic: [],
      uid: "de469205657c43e384f1adfcc5addcd2",
      shopId: 10,
      state: 1,
      details: null,
      created: 1617793929,
      modified: null,
      userId: 470,
      periodicCode: null,
      code: "7890-64",
      type: 0,
      status: 1,
      startTime: 1617793860,
      endTime: null,
      consumer: {
        total: 1,
        child: null,
        man: null,
      },
      name: "Lê Quang vinh",
      email: "locnd@bglobalcorp.com",
      phoneNumber: "1234567890",
      shopName: null,
      shopAlias: null,
      address: null,
      note: null,
      internalNote: null,
      invitationUid: null,
      invitationStatus: null,
      reservationDetails: {
        1: {
          type: 1,
          categoryUid: "cf675677855144919d0fefb476832bbd",
          itemUid: "a07404e7813e4c9dbdd10cda153e056e",
          categoryName: "Bàn sinh nhật",
          itemName: "Bàn sinh nhật ",
          staffUid: null,
          staffName: null,
        },
        2: {
          type: 2,
          categoryUid: "05b694b77691490b8024d9333706ac1b",
          itemUid: "27c4b36c4bb4413c8621819532e54125",
          categoryName: "Dich vu 5",
          itemName: "Dich vu 5",
          staffUid: "f0c36aab5a3f4ac9b9d4d635defa861d",
          staffName: "Lý Minh Nghĩa",
        },
      },
      realityStartTime: 1617793860,
      realityEndTime: null,
      totalConsumer: 20,
      man: 1,
      child: null,
      date: "07/04/2021",
      time: "18:11",
      service: {
        categoryUid: "05b694b77691490b8024d9333706ac1b",
        categoryName: "Dich vu 5",
        itemUid: "27c4b36c4bb4413c8621819532e54125",
        itemName: "Dich vu 5",
        uid: "27c4b36c4bb4413c8621819532e54125",
        name: "Dich vu 5",
      },
      assign: {
        categoryUid: "cf675677855144919d0fefb476832bbd",
        categoryName: "Bàn sinh nhật",
        itemUid: "a07404e7813e4c9dbdd10cda153e056e",
        itemName: "Bàn sinh nhật ",
        uid: "a07404e7813e4c9dbdd10cda153e056e",
        name: "Bàn sinh nhật ",
      },
      staff: {
        name: "Lý Minh Nghĩa",
        uid: "f0c36aab5a3f4ac9b9d4d635defa861d",
      },
    },
  ],
};
const previewNodata = {
  total: {
    consumer: {
      total: 0,
      child: 0,
      man: 0,
    },
    reservationCount: 0,
  },
  reservation: [],
};

const convertTime = secs => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  return [hours, minutes]
    .map(v => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};
