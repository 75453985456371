import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const ReportChartLegend = ({ arraySelected }) => {
  const classes = useStyles();

  const [arrayLegend, setArrayLegend] = useState([]);

  useEffect(() => {
    if (arraySelected && arraySelected != arrayLegend) setArrayLegend(arraySelected);
  }, [arraySelected]);

  return (
    <Box className={classes.root}>
      {arrayLegend.map((item, index) => (
        <LegendItem key={item.name || index} data={item} />
      ))}
    </Box>
  );
};

ReportChartLegend.propTypes = {
  arraySelected: PropTypes.array,
};
export default memo(ReportChartLegend);

const LegendItem = props => {
  const { data } = props;
  const classes = useStyles(data);

  return (
    <Box className={classes.legendItem}>
      <FiberManualRecord className={classes.legendIcon} />
      <Typography className="regular-md-txt">{data.name}</Typography>
    </Box>
  );
};
LegendItem.propTypes = {
  data: PropTypes.object,
};

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridGap: 8,
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    paddingTop: 16,
    paddingLeft: 70,
    paddingRight: 70,
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      color: props => props.color || "inherit",
    },
  },
  legendIcon: {
    fontSize: 21,
    marginRight: 8,
  },
});
