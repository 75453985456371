import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography, makeStyles, Button, MenuItem, Menu } from "@material-ui/core";
import { Dehaze, MoreHoriz } from "@material-ui/icons";
import { LangConstant } from "const";

const CategorySubCategoryMenu = props => {
  const { onClick, data, index, classes } = props;
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClickAdd = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClickMenu = (event, index) => {
    onClick(event, index);
    setAnchorEl(null);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box classes={{ root: classes?.root }}>
      <Box className={defaultClasses.boxName}>
        <Dehaze />
        <Typography className={defaultClasses.typography}>{data?.name}</Typography>
      </Box>
      <Box className={defaultClasses.boxButton}>
        <Button
          className={defaultClasses.menuBtn}
          onClick={onClickAdd}
          startIcon={<MoreHoriz className={defaultClasses.icon} />}
        />
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
          <MenuItem className={defaultClasses.menu} onClick={() => onClickMenu(ACTION_MENU.add, index)}>
            {getLabel(LangConstant.TXT_ADD_PRODUCT)}
          </MenuItem>
          <MenuItem className={defaultClasses.menu} onClick={() => onClickMenu(ACTION_MENU.edit, index)}>
            {getLabel(LangConstant.TXT_EDIT_CATEGORY_SUB_CATEGORY)}
          </MenuItem>
          <MenuItem className={defaultClasses.menu} onClick={() => onClickMenu(ACTION_MENU.delete, index)}>
            {getLabel(LangConstant.TXT_REMOVE_CATEGORY_SUB_CATEGORY)}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  typography: {
    marginLeft: 20,
  },
  icon: {
    width: 24,
    height: 24,
    textAlign: " center",
    marginLeft: "22%",
  },
  boxName: {
    display: "flex",
    height: "100%",
    width: "96%",
    paddingLeft: 25,
    alignItems: "center",
  },
  boxButton: {
    width: "4%",
    height: "100%",
    [theme.breakpoints.down(600)]: {
      width: "5%",
      marginRight: 25,
    },
  },
  menu: {
    "&.MuiListItem-button:hover": {
      backgroundColor: theme.palette.grey["700"],
      color: "#ffffff",
    },
  },
  menuBtn: {
    height: "100%",
    width: "100%",
    minWidth: "unset",
    padding: 0,
  },
}));

CategorySubCategoryMenu.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

CategorySubCategoryMenu.defaultProps = {
  onClick: () => {},
  classes: {},
};

export default CategorySubCategoryMenu;
const ACTION_MENU = { add: "add", edit: "edit", delete: "delete" };
