import React, { memo } from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";
import { LangConstant } from "../../../../../const";
import { useTranslation } from "react-i18next";
import { Line } from "../componentDialog";

const TabConsumer = ({ value, index, detail, setDetail, isEditing, isCopying, setIsEdited, ...other }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  let isShow = value === index;

  const onChangeDetail = event => {
    setIsEdited(true);
    setDetail({ ...detail, [event.target.name]: event.target.value });
  };

  return (
    <Box className={isShow ? classes.root : classes.rootHidden} {...other}>
      {isShow && (
        <Grid container spacing={1} className={classes.root}>
          <Line
            title={getLabel(LangConstant.L_BOOKING_NAME)}
            content={detail.name || ""}
            isEditing={isEditing}
            isCopying={isCopying}
            onChange={onChangeDetail}
            name="name"
          />
          <Line
            title={getLabel(LangConstant.L_BOOKING_EMAIL)}
            content={detail.email || ""}
            isEditing={isEditing}
            isCopying={isCopying}
            onChange={onChangeDetail}
            name="email"
          />
          <Line
            title={getLabel(LangConstant.L_BOOKING_PHONE)}
            content={detail.phoneNumber || ""}
            isEditing={isEditing}
            isCopying={isCopying}
            onChange={onChangeDetail}
            name="phoneNumber"
          />
        </Grid>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    display: "block",
  },
  rootHidden: {
    display: "none",
  },
}));

export default memo(TabConsumer);
