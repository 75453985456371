import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Hidden, Avatar, Typography, Grid } from "@material-ui/core";
import { LoginImage, Login2XImage, Login3XImage, GbossTxtBlackLogo } from "theme/images";
import { AppConstant, LangConstant, PathConstant } from "const";
import { useTranslation } from "react-i18next";
import { BglobalJSC } from "components";
import { useSelector, useDispatch } from "react-redux";
import AuthAction from "redux/auth.redux";
import { authenticate, removeAllCookie } from "utils";
import { matchPath, useHistory } from "react-router-dom";
import Cookies from "js-cookie";

const AuthLayout = props => {
  const { children, intro, intro2, isForgot } = props;
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = history.location;
  const isLoginSuccess = useSelector(state => state.authRedux.isLogin);

  useEffect(() => {
    return () => dispatch(AuthAction.authReset());
  }, []);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    let isInvitationStaff =
      location.pathname === PathConstant.LOGIN && searchParams.get(AppConstant.KEY_SHOP_INVITATION);
    let matchGroupObj = matchPath(location.pathname, PathConstant.LOGIN_GROUP_ADMIN);
    let isInvitationGroup = Boolean(matchGroupObj && matchGroupObj.isExact);

    if ((isInvitationStaff || isInvitationGroup) && !isLoginSuccess) {
      // Clear all cookie to logout
      removeAllCookie(Cookies);
    } else authenticate(history, isLoginSuccess);
  }, [location.search, isLoginSuccess]);

  return (
    <Grid container className="auth-root">
      <Hidden smDown>
        <Grid item md={6} lg={5} className="left-root">
          <img src={LoginImage} srcSet={`${Login2XImage} 2x, ${Login3XImage} 3x`} alt="login" className="related-img" />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6} lg={7} className="right-root">
        <Box flexGrow={1} overflow="hidden auto">
          <Box className="auth-intro">
            <Avatar variant="square" src={GbossTxtBlackLogo} alt="gboss logo" className="logo" />
            {isForgot && (
              <Box className="center-root" mb={3} mt={4}>
                <Typography variant="body1" classes={{ body1: "semiBold-xl-txt" }} component="h1" color="inherit">
                  {getLabel(LangConstant.TXT_FORGOT_PASSWORD)}
                </Typography>
              </Box>
            )}
            <Typography variant="body1" classes={{ body1: "medium-md-txt" }} color="inherit">
              {intro}
            </Typography>
            <Typography variant="body1" classes={{ body1: "medium-md-txt" }} color="inherit">
              {intro2}
            </Typography>
          </Box>
          {children}
        </Box>
        <BglobalJSC />
      </Grid>
    </Grid>
  );
};

AuthLayout.propTypes = { intro: PropTypes.string };

AuthLayout.defaultProps = {};

export default memo(AuthLayout);
