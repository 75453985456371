import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const postItemDetails = data => createApiReservation().post(ApiConstant.ITEM_DETAILS, data);

export const putItemDetails = data => createApiReservation().put(format(ApiConstant.ITEM_DETAILS_UID, data.uid), data);

export const getListItemDetails = data => createApiReservation().get(ApiConstant.ITEM_DETAILS, data);

export const getItemDetailsByUid = data =>
  createApiReservation().get(format(ApiConstant.ITEM_DETAILS_UID, data.uid), data);

export const deleteItemDetails = data =>
  createApiReservation().delete(format(ApiConstant.ITEM_DETAILS_UID, data.uid), data);

export const putEditContent = data =>
  createApiReservation().put(format(ApiConstant.EDIT_CONTENT, data.uid), data.itemDetailsContentList);
