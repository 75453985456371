import React from "react";
import PropTypes from "prop-types";
import { Box, Button, makeStyles } from "@material-ui/core";
import { getCommonKey } from "utils";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";

const ViewModeDialogActions = ({ onOpenEditing, setIsEdited, onOpenCopying, onOpenExtending }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  return (
    <Box className={classes.rootFooter}>
      <Button
        onClick={() => {
          onOpenEditing();
          setIsEdited(false);
        }}
        classes={{ root: classes.buttonRootFooter, label: classes.labelFooter }}
      >
        {getLabel(getCommonKey(LangConstant.TXT_EDIT))}
      </Button>
      <Button
        onClick={() => {
          onOpenCopying();
          setIsEdited(false);
        }}
        classes={{ root: classes.buttonRootFooter, label: classes.labelFooter }}
      >
        {getLabel(getCommonKey(LangConstant.TXT_COPY))}
      </Button>
      <Button
        onClick={() => {
          onOpenExtending();
          setIsEdited(false);
        }}
        classes={{ root: classes.buttonRootFooter, label: classes.labelFooter }}
      >
        {getLabel(getCommonKey(LangConstant.TXT_EXTEND))}
      </Button>
    </Box>
  );
};

ViewModeDialogActions.propTypes = {
  onOpenEditing: PropTypes.func,
  setIsEdited: PropTypes.func,
  onOpenCopying: PropTypes.func,
  onOpenExtending: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  rootFooter: {
    right: 10,
    textAlign: "right",
    width: "100%",
    padding: 0,
    "@media only screen and (max-width: 375px)": {
      position: "fixed",
      bottom: 10,
      padding: "0px 12px",
    },
  },

  buttonRootFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.8,
      color: "unset",
    },
  },

  labelFooter: {
    width: "100%",
    height: 24,
    marginTop: 3,
    marginBottom: 3,
  },
}));

export default ViewModeDialogActions;
