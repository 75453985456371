import React, { memo } from "react";
import { Grid, Typography, makeStyles, Box } from "@material-ui/core";
import { StaffWorkTime } from "./components";
import { StaffPosition } from "./components";
import { BglobalJSC } from "../../components";
import { useTranslation } from "react-i18next";
import { LangConstant } from "../../const";

const StaffSetting = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  return (
    <Box>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography className={classes.StaffSetiing}> {getLabel(LangConstant.TXT_STAFF_SETTING)}</Typography>
            <StaffWorkTime />
            <StaffPosition />
          </div>
        </Grid>
      </Grid>
      <footer className={classes.footer}>
        <BglobalJSC />
      </footer>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 50,
    textAlign: "center",
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
  StaffSetiing: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: "center",
    color: " #08080a",
  },
  button: {
    fontWeight: theme.typography.h5.fontWeight,
    width: 102,
    height: 46,
    fontSize: "24px",
    backgroundColor: "#ef5845",
    borderRadius: "24px",
    color: "#ffffff",
    textAlign: " center",
    paddingTop: "5px",
    textTransform: "none",
    marginLeft: 0,
    border: "1px #ef5845",
    marginRight: "auto",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "5%",
    backgroundColor: "white",
    textAlign: "center",
  },
}));
export default memo(StaffSetting);
