import React from "react";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import ReportDashboardAction from "redux/report-dashboard.redux";
import { useDispatch, useSelector } from "react-redux";
import BookingByPlaceChart from "./BookingByPlaceChart";
import ReportLayout from "../ReportLayout";
import ReportChartLegend from "../ReportChartLegend";
import ReportDisplaySetting from "../ReportDisplaySetting";
import { DEFAULT_QUERY_CHART_BY_PLACE } from "../../index";

const BookingByPlace = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);
  const dispatch = useDispatch();
  const [displaySetting, list] = useSelector(({ reportDashboard }) => [
    reportDashboard.summaryPlace.setting.details,
    reportDashboard.summaryPlace.list,
  ]);
  let isDisplaySettings = list && list.length > AppConstant.MINIMUM_NUMBER_DISPLAY_SETTINGS;

  const onChangeQueryParams = queryParams => dispatch(ReportDashboardAction.requestReportSummaryPlace(queryParams));

  const onChangeDisplaySetting = displaySettings => {
    dispatch(ReportDashboardAction.createReportSummaryPlaceSetting({ ...DISPLAY_SETTING_QUERY, ...displaySettings }));
  };

  return (
    <ReportLayout
      title={getLabel(LangConstant.TXT_CHART_TITLE_THIRD)}
      onChangeQueryParams={onChangeQueryParams}
      defaultQueryParams={DEFAULT_QUERY_CHART_BY_PLACE}
    >
      <BookingByPlaceChart />
      <ReportChartLegend arraySelected={displaySetting} />
      {isDisplaySettings && (
        <ReportDisplaySetting arraySelected={displaySetting} data={list} onChange={onChangeDisplaySetting} />
      )}
    </ReportLayout>
  );
};

BookingByPlace.propTypes = {};
export default BookingByPlace;
const DISPLAY_SETTING_QUERY = { type: AppConstant.DISPLAY_CHART_SETTING_TYPE.reportByPlace };
