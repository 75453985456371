import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getServiceCategory: ["data"],
  getServiceCategorySuccess: ["data"],
  getServiceCategoryFailure: ["data"],

  createServiceCategory: ["data"],
  createServiceCategorySuccess: ["data"],
  createServiceCategoryFailure: ["data"],

  updateServiceCategory: ["data"],
  updateServiceCategorySuccess: ["data"],
  updateServiceCategoryFailure: ["data"],

  removeServiceCategory: ["data"],
  removeServiceCategorySuccess: ["data"],
  removeServiceCategoryFailure: ["data"],

  resetServiceCategory: ["data"],
  resetErrorServiceCategory: ["data"],
});

export const ServiceCategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  isCreateFetching: false,
  isUpdateFetching: false,
  isRemoveFetching: false,
  isCreateSuccess: false,
  data: null,
  createData: null,
  updateData: null,
  removeData: null,
  errorUpdateServiceCategory: null,
  errorCreateServiceCategory: null,
};

/* ------------- Reducers Get Service category ------------- */
export const getServiceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getServiceCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    data: action.data,
  };
};

export const getServiceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

/* ------------- Reducers Create Service category ------------- */
export const createServiceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
});

export const createServiceCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreateFetching: false,
    isCreateSuccess: true,
    createData: action.data,
  };
};

export const createServiceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isCreateSuccess: false,
  errorCreateServiceCategory: action?.data?.message,
});

/* ------------- Reducers Update Service category ------------- */
export const updateServiceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updateServiceCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isUpdateSuccess: true,
    updateData: action.data,
  };
};

export const updateServiceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isUpdateSuccess: false,
  errorUpdateServiceCategory: action?.data?.message,
});

/* ------------- Reducers Remove Service category ------------- */
export const removeServiceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetching: true,
});

export const removeServiceCategorySuccess = (state = INITIAL_STATE, action) => {
  let placeDeleted = { ...state.data };
  delete placeDeleted[action.data.uid];
  return {
    ...state,
    isRemoveFetching: false,
    isRemoveSuccess: true,
    removeData: action.data,
  };
};

export const removeServiceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetching: false,
  isRemoveSuccess: false,
  errorRemove: action.error,
});

/* ------------- Reducers Reset ------------- */
export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  isCreateSuccess: false,
  isUpdateSuccess: false,
  isRemoveSuccess: false,
});

export const resetError = (state = INITIAL_STATE) => ({
  ...state,
  errorUpdateServiceCategory: null,
  errorCreateServiceCategory: null,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_SERVICE_CATEGORY]: getServiceCategory,
  [Types.GET_SERVICE_CATEGORY_SUCCESS]: getServiceCategorySuccess,
  [Types.GET_SERVICE_CATEGORY_FAILURE]: getServiceCategoryFailure,

  [Types.CREATE_SERVICE_CATEGORY]: createServiceCategory,
  [Types.CREATE_SERVICE_CATEGORY_SUCCESS]: createServiceCategorySuccess,
  [Types.CREATE_SERVICE_CATEGORY_FAILURE]: createServiceCategoryFailure,

  [Types.UPDATE_SERVICE_CATEGORY]: updateServiceCategory,
  [Types.UPDATE_SERVICE_CATEGORY_SUCCESS]: updateServiceCategorySuccess,
  [Types.UPDATE_SERVICE_CATEGORY_FAILURE]: updateServiceCategoryFailure,

  [Types.REMOVE_SERVICE_CATEGORY]: removeServiceCategory,
  [Types.REMOVE_SERVICE_CATEGORY_SUCCESS]: removeServiceCategorySuccess,
  [Types.REMOVE_SERVICE_CATEGORY_FAILURE]: removeServiceCategoryFailure,

  // Reset Service Item
  [Types.RESET_SERVICE_CATEGORY]: reset,
  [Types.RESET_ERROR_SERVICE_CATEGORY]: resetError,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
