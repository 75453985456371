import { call, put } from "redux-saga/effects";
import { toCamel } from "utils";
import { ApiConstant } from "../const";
import ReservationActivityAction from "../redux/reservation-activity.redux";
import { ReservationActivityService } from "../services";

export function* getReservationActivity(action) {
  const { data } = action;
  try {
    let response = yield call(ReservationActivityService.get, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(ReservationActivityAction.getReservationActivitySuccess(validate(toCamel(responseData))));
    } else {
      yield put(ReservationActivityAction.getReservationActivityFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationActivityAction.getReservationActivityFailure(error));
  }
}

const validate = responseData => {
  var arrayData = responseData.data;

  const validatedData = {
    ...responseData,
    data: arrayData
      ? arrayData.map(value => {
          return validateReservationActivity(value);
        })
      : [],
  };

  return validatedData;
};

const validateReservationActivity = data => {
  //handle detail data
  const actionDetails = data.actionDetails ? data.actionDetails : {};
  const fromName = actionDetails ? actionDetails.fromName : "";
  const actionDetailsData = actionDetails ? actionDetails.data : {};
  const oldCode = actionDetails ? actionDetails.code : "";
  const reservationUid = actionDetails ? actionDetails.reservationUid : "";
  actionDetails.fromName = fromName;
  actionDetails.data = actionDetailsData;
  actionDetails.code = oldCode;
  actionDetails.reservationUid = reservationUid;
  data.actionDetails = actionDetails;

  const validatedData = {
    ...data,
    uid: data.uid ? data.uid : "",
    type: data.type ? data.type : 0,
    userId: data.userId ? data.userId : 0,
    staffUid: data.staffUid ? data.staffUid : "",
    shopId: data.shopId ? data.shopId : 0,
    reservationCreated: data.reservationCreated ? data.reservationCreated : 0,
    reservationUid: data.reservationUid ? data.reservationUid : "",
    created: data.created ? data.created : 0,
    modified: data.modified ? data.modified : 0,
    code: data.code ? data.code : "",
    action: data.action ? data.action : 0,
  };

  return validatedData;
};
