import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import CustomStepButton from "./components/CustomStepButton";
import RemoteOrderInfo from "./components/RemoteOrderInfo";

const OrderPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.bookingRootBox}>
      <CustomStepButton />
      <RemoteOrderInfo step={1} />
    </Box>
  );
};

OrderPage.propTypes = {
  data: PropTypes.object,

  isMenu: PropTypes.bool,
};

OrderPage.defaultProps = {};

export default OrderPage;

const useStyles = makeStyles(theme => ({
  bookingRootBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    borderRadius: "unset",
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 16,
      paddingRight: 0,
    },
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 24,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    "&>*:first-child": {
      width: "100%",
      marginBottom: 16,
    },
    margin: "0 10px 16px 10px",
  },
  closedDay: {
    marginBottom: 7,
    color: "#feba40",
  },
  txtDate: {
    marginBottom: 7,
    color: theme.palette.grey[800],
  },
}));
