import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, FormControlLabel, makeStyles, Radio, RadioGroup, Typography, withStyles } from "@material-ui/core";
import { RadioButtonUnchecked, CheckCircle } from "@material-ui/icons";
import { AppConstant, LangConstant } from "const";

const ValueTax = ({ onChange, valueTax }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const [selectedValue, setSelectedValue] = useState(valueTax);

  const onChangeCheckbox = event => {
    let value = parseInt(event.target.value);
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <Box>
      <RadioGroup className={classes.root}>
        <FormControlLabel
          className={classes.rootFormControlNoTax}
          control={
            <GreenRadio
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={selectedValue === AppConstant.TYPE_TAX.noTax}
              onChange={onChangeCheckbox}
              value={AppConstant.TYPE_TAX.noTax}
            />
          }
          label={<Typography variant="body2">{getLabel(LangConstant.ARR_TYPE_TAX.noTax)}</Typography>}
        />
        <FormControlLabel
          className={classes.rootFormControlFivePercent}
          control={
            <GreenRadio
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={selectedValue === AppConstant.TYPE_TAX.fivePercent}
              onChange={onChangeCheckbox}
              value={AppConstant.TYPE_TAX.fivePercent}
            />
          }
          label={<Typography variant="body2">{getLabel(LangConstant.ARR_TYPE_TAX.fivePercent)}</Typography>}
        />
        <FormControlLabel
          className={classes.formControlTenPercent}
          control={
            <GreenRadio
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              checked={selectedValue === AppConstant.TYPE_TAX.tenPercent}
              onChange={onChangeCheckbox}
              value={AppConstant.TYPE_TAX.tenPercent}
            />
          }
          label={<Typography variant="body2">{getLabel(LangConstant.ARR_TYPE_TAX.tenPercent)}</Typography>}
        />
      </RadioGroup>
    </Box>
  );
};

ValueTax.propTypes = {
  onChange: PropTypes.func,
  valueTax: PropTypes.number,
};

ValueTax.defaultProps = { onChange: () => {}, valueTax: 0 };

const GreenRadio = withStyles(theme => ({
  root: {
    color: "#d4d5d8",
    "&$checked": {
      color: theme.palette.secondary.dark,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />);

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "block",
  },
  rootFormControlNoTax: {
    width: "38%",
    justifyContent: "left",
  },
  rootFormControlFivePercent: {
    width: "20%",
    justifyContent: "center",
  },
  formControlTenPercent: {
    width: "38%",
    justifyContent: "end",
  },
});

export default memo(ValueTax);
