import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles, Box, Hidden } from "@material-ui/core";
import { ErrorHandling } from "components";
import CustomAppBar, { HEIGHT_APP_BAR } from "./components/CustomAppBar";
import Sidebar from "./components/Sidebar";
import Routes from "router";
import { authenticate, getShopToken } from "utils";
import ShopInfoAction from "redux/shop-info.redux";
import ReservationAction from "redux/reservation.redux";
import { useDispatch } from "react-redux";

const MainLayout = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isBar, setIsBar] = useState(false);

  const onChangeSidebar = () => setIsBar(!isBar);

  useEffect(() => {
    // Control authentication redirect
    authenticate(history);

    // Common call API
    let hasShopToken = Boolean(getShopToken());
    if (hasShopToken) {
      dispatch(ShopInfoAction.getShopInfoData());
      dispatch(ReservationAction.getReservationSettingOption());
    }
  }, []);

  return (
    <>
      <CustomAppBar onClickTopBar={onChangeSidebar} />
      <main className={classes.main}>
        <Hidden mdDown>
          <Sidebar />
        </Hidden>

        <Box className={classes.container}>
          <Routes />
        </Box>
      </main>
      <ErrorHandling />
    </>
  );
};

MainLayout.propTypes = {};

MainLayout.defaultProps = {};

export const CONTAINER_SPACE = 10;

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    height: `calc(100vh - ${HEIGHT_APP_BAR}px)`,
    paddingTop: CONTAINER_SPACE,
  },
  container: {
    flexGrow: 1,
    height: "100%",
    padding: "0 10px",
    overflow: "auto",
  },
}));

export default memo(MainLayout);
