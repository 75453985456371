import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const postComboItem = data => createApiReservation().post(ApiConstant.COMBO_ITEM, data);

export const putComboItem = data => createApiReservation().put(format(ApiConstant.COMBO_ITEM_UID, data.uid), data);

export const getListComboItem = data => createApiReservation().get(ApiConstant.COMBO_ITEM, data);

export const getComboItemByUid = data => createApiReservation().get(format(ApiConstant.COMBO_ITEM_UID, data.uid), data);

export const deleteComboItem = data =>
  createApiReservation().delete(format(ApiConstant.COMBO_ITEM_UID, data.uid), data);
