import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography, Button } from "@material-ui/core";
import { UploadImage, Warning } from "components/mn-master-product";
import { Input, Alert } from "components";
import { Close, Error } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getCommonKey } from "utils";
import { useSelector, useDispatch } from "react-redux";
import ItemDetailsContentAction from "redux/item-details-content.redux";

const DialogEditAndDeleteAccompaniedService = ({ data, isWatch, isEdit, onClose, warning }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();
  const [isDuplicate, setIsDuplicate] = useState(false);

  const errorPostItemDetailsContent = useSelector(state => state.itemDetailsContentRedux.errorPostItemDetailsContent);

  const [formInput, setFormInput] = useState({
    uid: data.uid ? data.uid : "",
    name: data.name ? data.name : "",
    image: data.image ? data.image : "",
    type: AppConstant.TYPE_CATEGORY.service,
    description: data.description ? data.description : "",
    itemUid: data?.itemUid,
    detailsUidList: [],
  });
  const [notify, setNotify] = useState({
    value: false,
    message: "",
    type: TYPES_NOTIFICATION.not_warning,
  });
  const [isWarning, setIsWarning] = useState(warning);

  const onTypingName = value => {
    setFormInput({
      ...formInput,
      name: value,
    });
  };

  const onUploadFile = (value, error) => {
    if (!error) {
      setFormInput({
        ...formInput,
        image: value[0],
      });
    } else {
      setNotify({
        value: true,
        message: error,
        type: TYPES_NOTIFICATION.warning_image,
      });
    }
  };

  const onSubmitForm = () => {
    if (formInput.name) {
      dispatch(ItemDetailsContentAction.putItemDetailsContent(formInput));
    } else {
      setNotify({
        value: true,
        message: getLabel(LangConstant.FN_NOTIFY_INPUT_NAME)(getLabel(LangConstant.L_ATTACHED_SERVICE)),
        type: TYPES_NOTIFICATION.incomplete_attribute,
      });
    }
  };

  const onCloseNotify = () => {
    setIsWarning(false);
    setNotify({
      value: false,
      message: "",
      type: TYPES_NOTIFICATION.not_warning,
    });
  };

  useEffect(() => {
    if (errorPostItemDetailsContent) {
      if (errorPostItemDetailsContent?.name[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dispatch(ItemDetailsContentAction.resetErrorItemDetailsContent());
      }
    }
  }, [errorPostItemDetailsContent]);

  return (
    <Dialog open={isEdit || isWatch} fullScreen>
      {isEdit && (
        <Warning
          isShow={isWarning}
          onClose={onCloseNotify}
          message={getLabel(LangConstant.TXT_NOTIFY_WARNING_SERVICE)}
        />
      )}
      <Box className={classes.rootHeader}>
        <Typography className={classes.nameHeaderTypo}>
          {isWatch
            ? getLabel(LangConstant.TXT_DETAIL_SERVICE_ACCOMPANIED)
            : getLabel(LangConstant.TXT_EDIT_SERVICE_ACCOMPANIED)}
        </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.infoBox}>
        <Box padding="8px 0 16px">
          <Box className={classes.infoBasic}>
            <Typography className={classes.nameTypo}>{getLabel(LangConstant.TXT_NAME_SERVICE)}</Typography>
          </Box>
          <Input
            value={formInput.name}
            placeholder={getLabel(LangConstant.TXT_NAME_SERVICE)}
            classes={{ root: classes.rootName, input: classes.inputName }}
            required
            disabled={isWatch}
            onChange={onTypingName}
          />
          {isDuplicate && (
            <Box className={classes.warningName}>
              <Error color="primary" className={classes.iconError} />
              <Typography color="primary" className={classes.textWarning}>
                {getLabel(LangConstant.TXT_WARNING_NAME_SERVICE)}
              </Typography>
            </Box>
          )}
          {notify.value && notify.type == TYPES_NOTIFICATION.incomplete_attribute && (
            <Alert isShow={notify.value} onClose={onCloseNotify} type="error" message={notify.message} />
          )}
        </Box>
        <Box padding="8px 0 16px">
          <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_ILLUSTRATION)}</Typography>
          <Box marginTop="8px">
            <UploadImage
              onChange={onUploadFile}
              imageList={Boolean(formInput.image) ? [formInput.image] : []}
              isDisabled={isWatch}
            />
            {notify.value && notify.type == TYPES_NOTIFICATION.warning_image && (
              <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
            )}
          </Box>
          {isEdit && (
            <Box className={classes.boxButtonSave}>
              <Button className={classes.buttonSave} onClick={onSubmitForm}>
                {getLabel(getCommonKey(LangConstant.TXT_SAVE_BUTTON))}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
export default memo(DialogEditAndDeleteAccompaniedService);

const TYPES_NOTIFICATION = {
  not_warning: 0,
  warning_image: 1,
  incomplete_attribute: 2,
};

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: "25%",
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down(600)]: {
      height: 136,
    },
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  infoBox: {
    width: 430,
    marginTop: 46,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  infoBasic: {
    display: "flex",
    alignItems: "center",
    height: 22,
    marginBottom: 8,
  },
  nameTypo: {
    height: 22,
    lineHeight: 1.57,
    fontSize: 13,
    fontWeight: 600,
  },
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  rootName: {
    margin: 0,
    borderRadius: 3,
    "&.Mui-disabled": {
      color: "#3e4045",
    },
  },
  inputName: {
    height: 22,
    padding: "9px 16px",
    fontSize: 14,
    lineHeight: 1.57,
  },
  boxButtonSave: {
    display: "flex",
    justifyContent: "center",
    margin: "50px 0 54px",
  },
  buttonSave: {
    height: 46,
    padding: 0,
    minWidth: 102,
    fontSize: 16,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
}));
