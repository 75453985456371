import { makeStyles, Popper } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const HoverMessage = ({ message, className, anchorEl, messagePosition }) => {
  const classes = useStyles(messagePosition);

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      className={className ? className : classes.hoverMessageContainer}
      placement="bottom-start"
    >
      {message}
    </Popper>
  );
};
export default HoverMessage;

HoverMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.object,
  anchorEl: PropTypes.any,
  messagePosition: PropTypes.object,
};
HoverMessage.defaultProps = {
  messagePosition: { left: 0, right: 0, top: 0, bottom: "auto" },
};

const useStyles = makeStyles(() => ({
  hoverMessageContainer: {
    backgroundColor: "#7f838c",
    borderRadius: 1,
    height: "fit-content",
    width: "fit-content",
    top: props => props.top + `!important`,
    right: props => props.right + `!important`,
    left: props => props.left + `!important`,
    bottom: props => props.bottom + `!important`,
    zIndex: 1500,
    color: "#fff",
    padding: "0 8px",
    alignItems: "center",
    display: "flex",
    fontSize: 12,
    fontWeight: 500,
  },
}));
