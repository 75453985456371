import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  Link,
} from "@material-ui/core";
import { Email, Facebook, Phone, Place, Timelapse, Web } from "@material-ui/icons";
import { LangConstant } from "const";
import { getCommonKey, onDoNothing } from "utils";
import { getShopWorkingTime } from "utils/date";
import { useTranslation } from "react-i18next";
import PreviewConsBookingPage from "./PreviewConsBookingPage";
import StepButton from "./components/StepsLayout/StepButton";

const BookingPage = ({ data, displayData }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Box className={classes.bookingRootBox}>
      <StepButton currentStep={BOOKING_STEPS.info} goToStep={onDoNothing} />
      {isMobile && <ShopInfoMobile data={displayData} />}
      <PreviewConsBookingPage data={data && Object.values(data)} />
    </Box>
  );
};

BookingPage.propTypes = {
  data: PropTypes.object,

  isMenu: PropTypes.bool,
};

BookingPage.defaultProps = {};

export default BookingPage;

const ShopInfoMobile = props => {
  const { data: shop } = props;
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const classes = useStyles();

  const [workingDays, setWorkingDays] = useState([]);

  useEffect(() => {
    if (shop && Boolean(shop.id)) {
      let workingDaysListByText = [];
      let workingDaysByIndex = [];
      let notWorkingList = [...LangConstant.ARR_DAY_OF_WEEK];
      if (shop.details && shop.details.workingTime) {
        shop.details.workingTime.forEach(workingTimeElement => {
          workingTimeElement.days.forEach(workingDayIndex => {
            let selectedDay = LangConstant.ARR_DAY_OF_WEEK[workingDayIndex];
            workingDaysListByText.push(selectedDay);
            workingDaysByIndex.push({
              day: workingDayIndex,
              open: workingTimeElement.open,
              close: workingTimeElement.close,
              name: LangConstant.ARR_DAY_OF_WEEK[workingDayIndex],
            });

            let notWorkingDayIndex = notWorkingList.indexOf(selectedDay);

            if (notWorkingDayIndex >= 0) {
              notWorkingList.splice(notWorkingDayIndex, 1);
            }
          });
        });
      }
      setWorkingDays(workingDaysByIndex);
    }
  }, [shop]);

  const primaryInfo = [
    {
      icon: <Timelapse color="inherit" />,
      text: (
        <>
          {LangConstant.ARR_DAY_OF_WEEK.map(day =>
            workingDays.find(workDay => workDay.name === day) ? (
              <Typography variant="body2" component="p" className={classes.txtDate} key={day}>
                {getLabel(getCommonKey(day))} : {getShopWorkingTime(workingDays.find(workDay => workDay.name === day))}
              </Typography>
            ) : (
              <Typography variant="body2" component="p" className={classes.closedDay} key={day}>
                {getLabel(getCommonKey(day))} : {getLabel(getCommonKey(LangConstant.TXT_CLOSED))}
              </Typography>
            ),
          )}
        </>
      ),
    },

    {
      icon: <Phone color="inherit" />,
      text: (
        <Typography variant="body2" component="p">
          {shop.phoneNumber}
        </Typography>
      ),
    },

    {
      icon: <Place color="inherit" />,
      text: (
        <Typography variant="body2" component="p">
          {shop.address}
        </Typography>
      ),
    },

    {
      icon: <Web color="inherit" />,
      text: (
        <Link href={shop.website} target="_blank" rel="noopener noreferrer">
          <Typography variant="body2" component="p" color="inherit" className="eclipse">
            {shop.website}
          </Typography>
        </Link>
      ),
    },

    {
      icon: <Facebook color="inherit" />,
      text: (
        <Link href={shop.facebook} target="_blank" rel="noopener noreferrer">
          <Typography variant="body2" component="p" color="inherit" className="eclipse">
            {shop.facebook}
          </Typography>
        </Link>
      ),
    },

    {
      icon: <Email color="inherit" />,
      text: (
        <Typography variant="body2" component="p">
          {shop.email}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Paper className={classes.paperRoot}>
        <Typography variant="h6" component="p">
          {getLabel(LangConstant.TXT_INFORMATION)}
        </Typography>
        {primaryInfo.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon color="inherit">{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </Paper>
    </>
  );
};

const ListItem = withStyles({
  gutters: {
    alignItems: "start",
    padding: 0,
    minHeight: 43,
  },
})(MuiListItem);

const ListItemIcon = withStyles(theme => ({
  root: {
    minWidth: 30,
    color: theme.palette.grey[600],
  },
}))(MuiListItemIcon);

const BOOKING_STEPS = {
  info: 1,
  contact: 2,
  confirm: 3,
};

const useStyles = makeStyles(theme => ({
  bookingRootBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    borderRadius: "unset",
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 16,
      paddingRight: 0,
    },
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 24,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    "&>*:first-child": {
      width: "100%",
      marginBottom: 16,
    },
    margin: "0 10px 16px 10px",
  },
  closedDay: {
    marginBottom: 7,
    color: "#feba40",
  },
  txtDate: {
    marginBottom: 7,
    color: theme.palette.grey[800],
  },
}));
