import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import { convertTimeStampToDate } from "utils/date";

const MyPlanPackage = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const objPackageType = getLabel(LangConstant.OBJ_PACKAGE_TYPE, { returnObjects: true });
  const objPackageStatus = getLabel(LangConstant.OBJ_PACKAGE_STATUS, { returnObjects: true });

  const paymentDetails = useSelector(({ paymentRedux }) => paymentRedux.paymentDetails, shallowEqual);

  return (
    <Box className={classes.cellItem}>
      <Typography className={clsx("semiBold-lg-txt", classes.titleCell)}>
        {getLabel(LangConstant.TXT_MY_PLAN_PACKAGE)}
      </Typography>
      {paymentDetails?.plan && (
        <Typography className={clsx("medium-md-txt", classes.contentCell)}>
          {StringFormat(FM_CONTENT, getLabel(LangConstant.TXT_PLAN_NAME), objPackageType[paymentDetails.plan])}
        </Typography>
      )}
      <Typography className={clsx("medium-md-txt", classes.contentCell)}>
        {StringFormat(
          FM_CONTENT,
          getLabel(LangConstant.TXT_PLAN_START_DATE),
          paymentDetails?.startDate ? convertTimeStampToDate(paymentDetails.startDate, AppConstant.FM_DD_MM_YYYY) : "",
        )}
      </Typography>
      <Typography className={clsx("medium-md-txt", classes.contentCell)}>
        {StringFormat(
          FM_CONTENT,
          getLabel(LangConstant.TXT_PLAN_END_DATE),
          paymentDetails?.expiryDate
            ? convertTimeStampToDate(paymentDetails.expiryDate, AppConstant.FM_DD_MM_YYYY)
            : "",
        )}
      </Typography>
      <Box className={clsx(classes.centerStatus, classes.boxLine)}>
        <Typography className={clsx("medium-md-txt", classes.contentCell, classes.titleStatus)}>
          {getLabel(LangConstant.TXT_PLAN_STATUS)}
        </Typography>
        {paymentDetails?.state && (
          <Box className={clsx("center-root", classes.borderBox)}>
            <Typography className={clsx("semiBold-sm-txt", classes.textBox)}>
              {objPackageStatus[paymentDetails.state]}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const FM_CONTENT = "{0} {1}";

export default MyPlanPackage;

const useStyles = makeStyles(theme => ({
  cellItem: {
    width: `calc(${100 / 3}%)`,
    minWidth: 300,
    paddingBottom: 30,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  titleCell: {
    lineHeight: 1.5,
    color: "#3e4045",
    marginBottom: 24,
  },
  contentCell: {
    lineHeight: 1.57,
    color: "#3e4045",
    marginBottom: 22,
  },
  boxLine: {
    display: "flex",
    alignItems: "start",
    minHeight: 30,
  },
  titleStatus: {
    marginBottom: 0,
  },
  centerStatus: {
    display: "flex",
    alignItems: "center",
  },
  borderBox: {
    height: 30,
    marginLeft: 4,
    padding: "5px 18px",
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main,
  },
  textBox: {
    color: theme.palette.common.white,
    lineHeight: 1.67,
  },
}));
