import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Checkbox, Button, Typography } from "@material-ui/core";
import { CheckCircleRounded } from "@material-ui/icons";
import { LangConstant } from "const";

const StatusInvite = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { accountStatus, setOpen, onSelect, checked, checkAll, setSelected, data, index } = props;

  const onInvite = () => {
    setSelected(data);
    setOpen(true);
  };

  let isNotExistInviteInfo = Boolean(data && !data.email && !data.phoneNumber);

  return (
    <>
      {isNotExistInviteInfo ? (
        <Typography>{NO_INVITE_INFO}</Typography>
      ) : accountStatus === ACCOUNT_STATUS.isCustomer ? (
        <CheckCircleRounded className={classes.iconStatus} />
      ) : accountStatus === ACCOUNT_STATUS.invited ? (
        checkAll ? (
          <Checkbox
            color="primary"
            onChange={e => onSelect(e, index)}
            checked={checked}
            className={classes.checkBoxStatus}
          />
        ) : (
          <Button disableRipple onClick={onInvite} className={classes.inviteButton}>
            {getLabel(LangConstant.TXT_INVITE)}
          </Button>
        )
      ) : checkAll ? (
        <Checkbox
          color="primary"
          disabled
          checked
          classes={{ root: classes.checkBoxStatus, disabled: classes.disabled, colorPrimary: classes.colorPrimary }}
        />
      ) : (
        <Button disabled disableRipple classes={{ root: classes.inviteButton, disabled: classes.disabled }}>
          {getLabel(LangConstant.TXT_INVITED)}
        </Button>
      )}
    </>
  );
};

export default memo(StatusInvite);

const NO_INVITE_INFO = "-";

const ACCOUNT_STATUS = {
  isCustomer: 1,
  invited: 2,
};

const useStyles = makeStyles(() => ({
  iconStatus: {
    width: 24,
    height: 24,
    color: "#65b39d",
  },
  checkBoxStatus: {
    padding: "0 0 0",
    width: 16,
    height: 16,
  },
  disabled: {
    opacity: 0.6,
  },
  colorPrimary: {
    "&$disabled": {
      color: "#ef5845",
      opacity: 0.6,
    },
  },
  inviteButton: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    color: "#447aff",
    minWidth: 30,
    opacity: 1,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&$disabled": {
      color: "#447aff",
      opacity: 0.6,
    },
  },
}));
