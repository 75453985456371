import palette from "./palette";

export default {
  fontFamily: ["Inter", "Roboto", "Arial", "sans-serif"].join(","),
  h1: {
    color: palette.grey[700],
    fontWeight: 700,
    fontSize: "60px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h2: {
    color: palette.grey[700],
    fontWeight: 700,
    fontSize: "48px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h3: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "36px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h4: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "28px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h5: {
    color: palette.grey[700],
    fontWeight: 500,
    fontSize: "24px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  h6: {
    color: palette.grey[700],
    fontWeight: 500,
    fontSize: "20px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  subtitle1: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "20px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  subtitle2: {
    color: palette.grey[700],
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  body1: {
    color: palette.grey[700],
    fontWeight: 500,
    fontSize: "16px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  body2: {
    color: palette.grey[700],
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  button: {
    color: palette.white,
    fontWeight: 700,
    fontSize: "14px",
    letterSpacing: "0.4px",
    lineHeight: "normal",
  },
  caption: {
    color: palette.grey[700],
    fontWeight: 400,
    fontSize: "10px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
};
