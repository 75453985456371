import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  DialogTitle,
  IconButton,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Tooltip,
  TableSortLabel,
} from "@material-ui/core";
import { Close, Add, EventAvailable, CreateOutlined, DeleteOutlined } from "@material-ui/icons";
import { getCommonKey, isArrayNotEqual } from "utils";
import { getWaitingTime } from "utils/date";
import { LangConstant } from "const";
import { CellHead, CellBody } from "components/table";
import { DISPLAY_OPTION_DIALOG } from "layouts/MainLayout/components/CustomAppBar/WaitingListIcon";
import { EmptyWaitingConsumerImage } from "theme/images";
import WaitingConsumerAction from "redux/waiting-consumer.redux";

const DialogWaitingConsumer = ({ isShow, onClickOption, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const data = useSelector(state => state.waitingConsumerRedux.data, shallowEqual);

  const [listData, setListData] = useState([]);
  const [statusSort, setStatusSort] = useState(SORT_OPTION.noSort);

  const onSortByName = () => {
    let mapData = [...data];
    if (data) {
      switch (statusSort) {
        case SORT_OPTION.noSort:
          setStatusSort(SORT_OPTION.asc);
          sortByName(mapData, true);
          setListData(mapData);
          break;
        case SORT_OPTION.asc:
          setStatusSort(SORT_OPTION.desc);
          sortByName(mapData, false);
          setListData(mapData);
          break;
        case SORT_OPTION.desc:
          setStatusSort(SORT_OPTION.noSort);
          setListData(mapData);
          break;
      }
    }
  };

  useEffect(() => {
    dispatch(WaitingConsumerAction.getWaitingConsumer());
  }, []);

  useEffect(() => {
    if (isArrayNotEqual(data)) {
      setListData(data);
    }
  }, [data]);

  return (
    <Dialog open={isShow} classes={{ paperScrollPaper: classes.dialogContainer }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography classes={{ body1: classes.textDialogTitle }}>
          {getLabel(LangConstant.TXT_LIST_WAITING_CONSUMER)}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box padding="16px 24px">
          {data && data.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <CellHead
                      cellData={
                        <Box display="flex">
                          <Typography className={classes.typoHeader}>
                            {getLabel(getCommonKey(LangConstant.TXT_FULL_NAME))}
                          </Typography>
                          <TableSortLabel onClick={onSortByName} classes={{ icon: classes.iconSort }}></TableSortLabel>
                        </Box>
                      }
                      className={classes.bodyFullName}
                    />
                    <CellHead
                      cellData={
                        <Typography className={classes.typoHeader}>
                          {getLabel(getCommonKey(LangConstant.TXT_PHONE_NUMBER))}
                        </Typography>
                      }
                      className={classes.bodyLeftContent}
                    />
                    <CellHead
                      cellData={
                        <Typography className={classes.typoHeader}>
                          {getLabel(getCommonKey(LangConstant.P_BOOKING_PEOPLE))}
                        </Typography>
                      }
                      className={classes.bodyCenterContent}
                    />
                    <CellHead
                      cellData={
                        <Typography className={classes.typoHeader}>
                          {getLabel(getCommonKey(LangConstant.P_BOOKING_CHILDREN))}
                        </Typography>
                      }
                      className={classes.bodyCenterContent}
                    />
                    <CellHead
                      cellData={
                        <Typography className={classes.typoHeader}>
                          {getLabel(getCommonKey(LangConstant.TXT_RESERVATION_COUNT))}
                        </Typography>
                      }
                      className={classes.bodyCenterContent}
                    />
                    <CellHead
                      cellData={
                        <Typography className={classes.typoHeader}>{getLabel(LangConstant.TXT_PLACE)}</Typography>
                      }
                      className={classes.bodyLeftContent}
                    />
                    <CellHead
                      cellData={
                        <Typography className={classes.typoHeader}>
                          {getLabel(LangConstant.TXT_WAITING_TIME)}
                        </Typography>
                      }
                      className={classes.bodyLeftContent}
                    />
                    <CellHead
                      cellData={
                        <Typography className={classes.typoHeader}>
                          {getLabel(LangConstant.TXT_STATE_WAITING_CONSUMER)}
                        </Typography>
                      }
                      className={classes.bodyLastContent}
                    />
                    <CellHead className={classes.bodyLastContent} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listData.length > 0 &&
                    listData.map((dataMap, index) => (
                      <TableRow className={classes.tableRow} key={index}>
                        <CellBody
                          cellData={<Typography className={classes.buttonName}>{dataMap.name}</Typography>}
                          className={classes.bodyFullName}
                        />
                        <CellBody
                          cellData={<Typography className={classes.typoContent}>{dataMap.phoneNumber}</Typography>}
                          className={classes.bodyLeftContent}
                        />
                        <CellBody
                          cellData={
                            <Typography className={classes.typoContent}>
                              {dataMap.consumer?.man || dataMap.consumer?.total}
                            </Typography>
                          }
                          className={classes.bodyCenterContent}
                        />
                        <CellBody
                          cellData={
                            <Typography className={classes.typoContent}>{dataMap.consumer?.child || 0}</Typography>
                          }
                          className={classes.bodyCenterContent}
                        />
                        <CellBody
                          cellData={<Typography className={classes.typoContent}>{dataMap.reservationCount}</Typography>}
                          className={classes.bodyCenterContent}
                        />
                        <CellBody
                          cellData={
                            <Box>
                              {dataMap.placeItemList &&
                                dataMap.placeItemList.map((item, index) => (
                                  <Typography key={index} className={classes.typoPlaceContent}>
                                    {item}
                                  </Typography>
                                ))}
                            </Box>
                          }
                          className={classes.bodyLeftContent}
                        />
                        <CellBody
                          cellData={
                            <Typography className={classes.typoContent}>{getWaitingTime(dataMap.created)}</Typography>
                          }
                          className={classes.bodyLeftContent}
                        />
                        <CellBody
                          cellData={
                            <Typography className={classes.typoContent}>
                              {checkTypeWaitingConsumer(dataMap.reservationUid, getLabel, dataMap.isLate)}
                            </Typography>
                          }
                          className={classes.bodyLastContent}
                        />
                        <CellBody
                          className={classes.bodyLastContent}
                          cellData={
                            <Box display="flex" justifyContent="center">
                              <Tooltip
                                title={getLabel(LangConstant.TXT_ARRANGE_SEAT)}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  disableRipple
                                  className={classes.borderIconEvent}
                                  onClick={() => onClickOption(dataMap, DISPLAY_OPTION_DIALOG.isArrangeSeat)}
                                >
                                  {<EventAvailable className={classes.iconStyle} />}
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={getLabel(getCommonKey(LangConstant.TXT_EDIT))}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  disableRipple
                                  className={classes.borderIconEdit}
                                  onClick={() => onClickOption(dataMap, DISPLAY_OPTION_DIALOG.isDisplayEdit)}
                                >
                                  {<CreateOutlined className={classes.iconStyle} />}
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={getLabel(getCommonKey(LangConstant.TXT_DELETE))}
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  disableRipple
                                  className={classes.borderIconDelete}
                                  onClick={() => onClickOption(dataMap, DISPLAY_OPTION_DIALOG.isDisplayDelete)}
                                >
                                  {<DeleteOutlined className={classes.iconStyle} />}
                                </IconButton>
                              </Tooltip>
                            </Box>
                          }
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box textAlign="center">
              <img width="500px" src={EmptyWaitingConsumerImage} alt="empty" />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClickOption(null, DISPLAY_OPTION_DIALOG.isDisplayInvite)}
          classes={{ root: classes.buttonAdd, startIcon: classes.startIcon }}
          startIcon={<Add />}
        >
          <Typography className={classes.textBtn}>{getLabel(getCommonKey(LangConstant.TXT_ADD))}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const checkTypeWaitingConsumer = (reservationUid, getLabel, isLate) =>
  reservationUid
    ? isLate
      ? getLabel(getCommonKey(LangConstant.TXT_LATE))
      : getLabel(getCommonKey(LangConstant.TXT_SOON))
    : getLabel(LangConstant.TXT_NO_RESERVATION_YET);

const sortByName = (data, isAsc) =>
  data.sort((nameFirst, nameSecond) => {
    if (nameFirst.name.toLowerCase() < nameSecond.name.toLowerCase()) {
      return isAsc ? -1 : 1;
    }
    if (nameFirst.name.toLowerCase() > nameSecond.name.toLowerCase()) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });

DialogWaitingConsumer.propTypes = {
  isShow: PropTypes.bool,
  onClickOption: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const SORT_OPTION = {
  noSort: 0,
  asc: 1,
  desc: 2,
};

export default memo(DialogWaitingConsumer);

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 1175,
    minWidth: 1175,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    "@media only screen and (max-width: 1200px)": {
      width: "100%",
      minWidth: "100%",
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      height: "100%",
      marginBottom: 0,
      maxHeight: "none",
    },
  },
  dialogTitle: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: "fixed",
    width: 1175,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 60,
    padding: "0 24px",
    zIndex: 100,
    "@media only screen and (max-width: 1200px)": {
      width: "100%",
    },
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 20,
  },
  closeButton: {
    position: "absolute",
    right: 24,
    top: 18,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    marginTop: 34,
    padding: "26px 0 14px",
    display: "flex",
    flexDirection: "column",
    border: "none",
    "&:first-child": {
      paddingTop: 26,
      "@media only screen and (max-width: 515px)": {
        padding: "26px 0 20px",
      },
    },
  },
  tableRow: {
    border: "1px solid " + theme.palette.grey[200],
  },
  iconSort: {
    display: "none",
  },
  iconSwag: {
    marginLeft: 10,
    opacity: 1,
    fill: "#4b4d53",
    width: 20,
    height: 20,
  },
  bodyFullName: {
    width: "20%",
    textAlign: "left",
    padding: "10px 15px",
    minWidth: 105,
    borderBottom: "1px solid " + theme.palette.grey[200],
  },
  buttonName: {
    padding: 0,
    fontWeight: 500,
    textTransform: "none",
    color: "#447aff",
    fontSize: 13,
  },
  typoHeader: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.67,
  },
  typoContent: {
    fontSize: 13,
    color: theme.palette.grey[500],
  },
  typoPlaceContent: { fontSize: 13, "&:not(:last-child)": { marginBottom: 5 } },
  bodyLeftContent: {
    width: "12%",
    padding: "10px 24px",
    minWidth: 105,
    textAlign: "left",
    borderBottom: "1px solid " + theme.palette.grey[200],
  },
  bodyCenterContent: {
    width: "10%",
    textAlign: "center",
    padding: "10px 15px",
    minWidth: 80,
    borderBottom: "1px solid " + theme.palette.grey[200],
  },
  bodyLastContent: {
    width: "10%",
    padding: "10px 0",
    minWidth: 100,
    textAlign: "left",
    borderBottom: "1px solid " + theme.palette.grey[200],
    "&:not(:last-child)": {
      paddingRight: 20,
    },
  },
  tooltip: {
    marginLeft: 50,
    marginTop: 2,
    backgroundColor: "#7f838c",
    fontWeight: 500,
    fontSize: 12,
    borderRadius: 1,
  },
  borderIconEvent: {
    minWidth: 24,
    height: 24,
    margin: 2,
    padding: 0,
    color: "#6c7078",
    border: "1px solid #fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#447aff",
      border: "1px solid #447aff",
    },
  },
  borderIconEdit: {
    minWidth: 24,
    height: 24,
    margin: 2,
    padding: 0,
    color: "#6c7078",
    border: "1px solid #fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#feba40",
      border: "1px solid #feba40",
    },
  },
  borderIconDelete: {
    minWidth: 24,
    height: 24,
    margin: "2px 0 2px 2px",
    padding: 0,
    color: "#6c7078",
    borderRadius: 0,
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#ef5845",
      border: "1px solid #ef5845",
    },
  },
  iconStyle: {
    fontSize: 18,
  },
  dialogActions: {
    display: "block",
    textAlign: "center",
    padding: "24px 0",
  },
  buttonAdd: {
    borderRadius: 2,
    minWidth: 91,
    padding: 0,
    minHeight: 30,
    backgroundColor: "#ef5845",
    border: "unset",
    color: theme.palette.white,
    textTransform: "none",
  },
  startIcon: {
    marginRight: 8,
    color: theme.palette.white,
    fontSize: 20,
  },
  textBtn: {
    fontSize: 14,
    lineHeight: "28px",
    color: theme.palette.white,
  },
}));
