import { call, put } from "redux-saga/effects";
import { toCamel, toSnake } from "utils";
import { ApiConstant } from "../const";
import ReportBookingAction from "../redux/report-booking.redux";
import { ReportBookingService } from "../services";

export function* reportBookingDay(action) {
  try {
    const { data } = action;
    let response = yield call(ReportBookingService.reportBookingDay, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReportBookingAction.reportBookingSuccessDay(toCamel(responseData)));
    } else {
      yield put(ReportBookingAction.reportBookingFailureDay(response.data));
    }
  } catch (error) {
    yield put(ReportBookingAction.reportBookingFailureDay(error));
  }
}

export function* reportBookingWeek(action) {
  try {
    const { data } = action;
    let tmpObj = {
      start: data.start,
      end: data.end,
    };
    if (data.placeCategoryUid || data.serviceCategoryUid || data.status) {
      if (data.placeCategoryUid) {
        tmpObj = {
          ...tmpObj,
          placeCategoryUid: data.placeCategoryUid,
        };
      }
      if (data.serviceCategoryUid) {
        tmpObj = {
          ...tmpObj,
          serviceCategoryUid: data.serviceCategoryUid,
        };
      }
      if (data.status) {
        tmpObj = {
          ...tmpObj,
          status: data.status,
        };
      }
    } else {
      tmpObj = { ...tmpObj };
    }

    let response = yield call(ReportBookingService.reportBookingWeek, toSnake(tmpObj));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReportBookingAction.reportBookingSuccessWeek(toCamel(responseData)));
    } else {
      yield put(ReportBookingAction.reportBookingFailureWeek(response.data));
    }
  } catch (error) {
    yield put(ReportBookingAction.reportBookingFailureWeek(error));
  }
}

export function* reportBookingMonth(action) {
  try {
    const { data } = action;
    let tmpObj = {
      start: data.start,
      end: data.end,
    };
    if (data.placeCategoryUid || data.serviceCategoryUid || data.status) {
      if (data.placeCategoryUid) {
        tmpObj = {
          ...tmpObj,
          placeCategoryUid: data.placeCategoryUid,
        };
      }
      if (data.serviceCategoryUid) {
        tmpObj = {
          ...tmpObj,
          serviceCategoryUid: data.serviceCategoryUid,
        };
      }
      if (data.status) {
        tmpObj = {
          ...tmpObj,
          status: data.status,
        };
      }
    } else {
      tmpObj = { ...tmpObj };
    }

    let response = yield call(ReportBookingService.reportBookingMonth, toSnake(tmpObj));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReportBookingAction.reportBookingSuccessMonth(toCamel(responseData)));
    } else {
      yield put(ReportBookingAction.reportBookingFailureMonth(response.data));
    }
  } catch (error) {
    yield put(ReportBookingAction.reportBookingFailureMonth(error));
  }
}
