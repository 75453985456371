import React, { memo, useState } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { BglobalJSC } from "components";
import { SwitchMProductItem } from "components/mn-master-product";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { MainProductAll, MainProductCategory, AccompaniedProductAll, AccompaniedProductCategory } from "./components";

const MnProduct = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const productModeArr = [
    { name: getLabel(LangConstant.L_MAIN_PRODUCT), id: "main" },
    { name: getLabel(LangConstant.L_ATTACHED_PRODUCT), id: "attached" },
  ];
  const childProductModeArr = [
    { name: getLabel(LangConstant.L_All_PRODUCT), id: "all" },
    { name: getLabel(LangConstant.L_CATEGORY_PRODUCT), id: "category" },
  ];

  const [productModeId, setProductModeId] = useState(productModeArr[0]?.id);
  const [childProductModeId, setChildProductModeId] = useState(childProductModeArr[0]?.id);

  const onSwitchTab = event => {
    const newProductModeId = event.currentTarget.id;
    if (newProductModeId != productModeId) {
      setProductModeId(newProductModeId);
      setChildProductModeId(childProductModeArr[0]?.id);
    }
  };

  const onChangeMode = event => {
    const newChildProductModeId = event.currentTarget.id;
    if (newChildProductModeId != childProductModeId) {
      setChildProductModeId(newChildProductModeId);
    }
  };

  const checkTabAccompaniedProductComponent = (childProductModeId, onChangeMode) => {
    switch (childProductModeId) {
      case childProductModeArr[0].id:
        return (
          <AccompaniedProductAll
            childProductModeId={childProductModeId}
            childProductModeArr={childProductModeArr}
            onChangeMode={onChangeMode}
          />
        );
      case childProductModeArr[1].id:
        return (
          <AccompaniedProductCategory
            childProductModeId={childProductModeId}
            childProductModeArr={childProductModeArr}
            onChangeMode={onChangeMode}
          />
        );
    }
  };

  const checkTabMainProductComponent = (childProductModeId, onChangeMode) => {
    switch (childProductModeId) {
      case childProductModeArr[0].id:
        return (
          <MainProductAll
            childProductModeId={childProductModeId}
            childProductModeArr={childProductModeArr}
            onChangeMode={onChangeMode}
          />
        );
      case childProductModeArr[1].id:
        return (
          <MainProductCategory
            childProductModeId={childProductModeId}
            childProductModeArr={childProductModeArr}
            onChangeMode={onChangeMode}
          />
        );
    }
  };

  return (
    <Box>
      <Paper elevation={1} square className={classes.container}>
        <SwitchMProductItem itemInfos={productModeArr} onTabClick={onSwitchTab} selectedId={productModeId} />
        {productModeArr[0].id == productModeId && checkTabMainProductComponent(childProductModeId, onChangeMode)}
        {productModeArr[1].id == productModeId && checkTabAccompaniedProductComponent(childProductModeId, onChangeMode)}
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};

export default memo(MnProduct);

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    margin: "0 10px",
    minHeight: "calc(100vh - 200px)",
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));
