import { call, put, select } from "redux-saga/effects";
import { ApiConstant } from "const";
import { WaitingConsumerService } from "services";
import WaitingConsumerAction from "redux/waiting-consumer.redux";
import { toCamel, toSnake } from "utils";

export function* getWaitingConsumer() {
  try {
    let response = yield call(WaitingConsumerService.get);
    if (response.status === ApiConstant.STT_OK) {
      let changeFormData = yield response.data.data.map(data => toCamel(data));
      yield put(WaitingConsumerAction.waitingConsumerSuccess({ data: changeFormData, isSuccess: true }));
    } else {
      yield put(WaitingConsumerAction.waitingConsumerFailure(response.data));
    }
  } catch (error) {
    yield put(WaitingConsumerAction.waitingConsumerFailure(error));
  }
}

export function* postWaitingConsumer(action) {
  try {
    const { waitingConsumerRedux } = yield select();
    const { data } = action;
    let response = yield call(WaitingConsumerService.create, toSnake(formatDataRequest(data)));
    if (response.status === ApiConstant.STT_OK) {
      // get old data form redux
      let oldData = waitingConsumerRedux.data;
      // reformat data from api response
      let newFormatResponse = formatDataResponse(response.data.data);
      // create new array data and set to redux
      let newData = [...oldData, newFormatResponse];
      yield put(WaitingConsumerAction.waitingConsumerSuccess({ data: newData, isPostSuccess: true }));
    } else {
      yield put(WaitingConsumerAction.waitingConsumerFailure(response.data));
    }
  } catch (error) {
    yield put(WaitingConsumerAction.waitingConsumerFailure(error));
  }
}

export function* putWaitingConsumer(action) {
  try {
    const { waitingConsumerRedux } = yield select();
    const { data } = action;
    let response = yield call(WaitingConsumerService.update, toSnake(formatDataRequest(data)));
    if (response.status === ApiConstant.STT_OK) {
      // get old data form redux
      let oldData = waitingConsumerRedux.data;
      // reformat data from api response
      let newFormatResponse = formatDataResponse(response.data.data);
      // create new array data and set to redux
      let newData = oldData.map(dataMap => (dataMap.uid === data.uid ? newFormatResponse : dataMap));
      yield put(WaitingConsumerAction.waitingConsumerSuccess({ data: newData, isSuccess: true }));
    } else {
      yield put(WaitingConsumerAction.waitingConsumerFailure(response.data));
    }
  } catch (error) {
    yield put(WaitingConsumerAction.waitingConsumerFailure(error));
  }
}

export function* deleteWaitingConsumer(action) {
  try {
    const { waitingConsumerRedux } = yield select();
    const { data } = action;
    let response = yield call(WaitingConsumerService.remove, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      // get old data form redux
      let oldData = waitingConsumerRedux.data;
      // remove uid have remove from oldData and set newData to Redux
      let newData = oldData.filter(dataMap => dataMap.uid !== data.uid);
      yield put(WaitingConsumerAction.waitingConsumerSuccess({ data: newData, isSuccess: true }));
    } else {
      yield put(WaitingConsumerAction.waitingConsumerFailure(response.data));
    }
  } catch (error) {
    yield put(WaitingConsumerAction.waitingConsumerFailure(error));
  }
}

const formatDataRequest = data => ({
  uid: data?.uid || undefined,
  name: data.name,
  phoneNumber: data.phoneNumber,
  consumer: {
    total: data.total || data.man + data.child,
    man: data.man,
    child: data.child,
  },
  reservationUid: data?.uid || data?.reservationUid || undefined,
});

const formatDataResponse = data => ({
  uid: data?.uid,
  name: data?.name,
  phoneNumber: data?.phone_number,
  man: data?.consumer?.man,
  child: data?.consumer?.child,
  reservationUid: data?.reservation_uid,
  reservationCount: data?.reservation_count,
});
