import { createReducer, createActions } from "reduxsauce";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestUploadImage: ["data"],
  uploadImageSuccess: ["data"],
  uploadImageFailure: ["data"],

  resetUploadFile: [],
});

export const UploadFileTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  //upload
  postImgUrl: [],
  ImageURL: [],
  isUpLoadFetching: false,
  isUploadSuccess: false,
  errorUploadImage: null,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isUploadFetching: true,
  };
};

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpLoadFetching: false,
    isUploadSuccess: true,
    postImgUrl: action.data.uri,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUploadFetching: false,
  isUploadSuccess: false,
  errorUploadImage: action.error,
});

export const resetUploadFile = () => INITIAL_STATE;
/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_UPLOAD_IMAGE]: request,
  [Types.UPLOAD_IMAGE_SUCCESS]: success,
  [Types.UPLOAD_IMAGE_FAILURE]: failure,

  [Types.RESET_UPLOAD_FILE]: resetUploadFile,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
