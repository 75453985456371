import React, { memo } from "react";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const Loading = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid container spacing={isDesktop ? 4 : 2}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </Grid>
  );
};

export default memo(Loading);
