import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase, TableCell } from "@material-ui/core";
import clsx from "clsx";

const CellBody = props => {
  const classes = useStyles();
  const { cellData, style, textAlign, className, onChange, isEdit, valueName, isError, ...otherProps } = props;

  return (
    <TableCell
      align={textAlign}
      className={clsx("regular-md-txt", className)}
      classes={{
        body: classes.body,
      }}
      style={style}
      {...otherProps}
    >
      {isEdit ? (
        <InputBase
          error
          value={cellData}
          onChange={onChange}
          name={valueName}
          {...otherProps}
          classes={{ input: clsx("regular-md-txt", isError ? classes.error : classes.input) }}
        />
      ) : (
        cellData
      )}
    </TableCell>
  );
};

const useStyles = makeStyles(theme => ({
  body: {
    color: "#787878",
    padding: "6px 16px",
  },
  error: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    textUnderlinePosition: "under",
  },
  input: {
    textDecoration: "none",
  },
}));

CellBody.propTypes = {
  isError: PropTypes.bool,
  firstCol: PropTypes.bool,
  isStatus: PropTypes.bool,
  style: PropTypes.object,
  cellData: PropTypes.node,
  textAlign: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

CellBody.defaultProps = {
  isError: false,
  style: { isStatus: false },
  textAlign: "center",
  onChange: () => {},
};

export default memo(CellBody);
