import React, { memo } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";

const SelectedTimeTooltip = props => {
  const { children, open, value } = props;
  const classes = useStyle();

  return (
    <Tooltip
      open={open}
      title={value}
      classes={{
        root: classes.root,
        arrow: classes.arrow,
        tooltipArrow: classes.tooltipArrow,
      }}
      placement="top"
      arrow={true}
    >
      {children}
    </Tooltip>
  );
};

export default memo(SelectedTimeTooltip);

const useStyle = makeStyles(() => ({
  tooltipArrow: {
    backgroundColor: "#65b39d",
    borderRadius: 12,
    border: "solid 1px #fff",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)",
  },
  arrow: {
    "&::before": {
      backgroundColor: "#65b39d",
      border: "solid 1px #fff",
    },
  },
}));
