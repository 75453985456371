import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { Close, ArrowBack } from "@material-ui/icons";
import { makeStyles, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { CategoryItemListHeader, CategoryItemListBody } from "components/mn-master-product";
import { SearchBar } from "components";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, convertTimeItemDescription, formatCurrency, textNormalize, getUidList } from "utils";
import ServiceItemAction from "redux/service-item.redux";
import DialogViewEditMainService from "./DialogViewEditMainService";

const DialogAddToCategory = ({ categoryData, isOpen, onClose, onBack }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const dataServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem?.data, shallowEqual);
  const isPutServiceItemSuccess = useSelector(state => state.serviceItemRedux.isPutServiceItemSuccess, shallowEqual);

  const [listServiceItem, setListServiceItem] = useState([]);

  const [isOpenService, setIsOpenService] = useState({
    viewService: false,
    targetUid: null,
  });

  const onSearch = searchValue => {
    if (searchValue == "" || !searchValue) {
      setListServiceItem(dataServiceItem);
    } else {
      const cloneData = JSON.parse(JSON.stringify(dataServiceItem));
      const filterList = cloneData.filter(data => textNormalize(data?.name).includes(textNormalize(searchValue)));
      setListServiceItem(filterList);
    }
  };

  const onAddToCategoryService = (e, serviceItem) => {
    let newCategoryUidList = getUidList(serviceItem?.categoryList);
    if (!newCategoryUidList.includes(categoryData.uid)) {
      newCategoryUidList.push(categoryData.uid);
    }

    const placeUidList = getUidList(serviceItem?.placeList);
    const staffList = getUidList(serviceItem?.staffList);
    const itemDetailsUidList = getUidList(serviceItem?.itemDetailsList);

    dispatch(ServiceItemAction.resetServiceItem());
    dispatch(
      ServiceItemAction.putServiceItem({
        ...serviceItem,
        categoryList: newCategoryUidList,
        placeList: placeUidList,
        staffList: staffList,
        itemDetailsList: itemDetailsUidList,
      }),
    );
  };

  const onWatchServiceItem = () => {
    //TODO pj9 later can be used
    // setIsOpenService({ ...isOpenService, viewService: true, targetUid: uid });
  };

  const onCloseViewService = () => {
    setIsOpenService({
      viewService: false,
      targetUid: null,
    });
  };

  useEffect(() => {
    if (isPutServiceItemSuccess) {
      dispatch(ServiceItemAction.resetServiceItem());
      dispatch(ServiceItemAction.getListServiceItem({}));
      dispatch(ServiceItemAction.getCategoryServiceItem({ categoryUid: categoryData?.uid }));
    }
  }, [isPutServiceItemSuccess]);

  useEffect(() => {
    if (dataServiceItem) {
      setListServiceItem(dataServiceItem);
    }
  }, [dataServiceItem]);

  return (
    <>
      {
        <Dialog open={isOpen} fullScreen>
          <Box className={classes.rootHeader}>
            <Box className={classes.boxIconBack}>
              <IconButton onClick={onBack} className={classes.iconButton}>
                <ArrowBack className={classes.icon} />
              </IconButton>
            </Box>
            <Typography className={classes.nameHeaderTypo}>
              {getLabel(LangConstant.FN_ADD_TO_CATEGORY_SERVICE)(categoryData?.name)}
            </Typography>
            <Box className={classes.boxIconClose}>
              <IconButton onClick={onClose} className={classes.iconButton}>
                <Close className={classes.icon} />
              </IconButton>
            </Box>
          </Box>
          <Box textAlign="center">
            <SearchBar
              className={classes.searchBar}
              onKeyUp={onSearch}
              placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
            />
            <Box className={classes.rootBody}>
              <CategoryItemListHeader isProduct={false} />
              {listServiceItem.map(dataServiceItem => (
                <CategoryItemListBody
                  key={dataServiceItem.uid}
                  uid={dataServiceItem.uid}
                  onClickAdd={e => onAddToCategoryService(e, dataServiceItem)}
                  categoryName={dataServiceItem.name}
                  isAdd={true}
                  timeUsed={
                    dataServiceItem.usingTime &&
                    dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.times
                      ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                      : ""
                  }
                  image={dataServiceItem.image}
                  price={
                    dataServiceItem.price
                      ? `${formatCurrency(`${dataServiceItem.price}`)} ${getLabel(LangConstant.TXT_CURRENCY)}/${
                          dataServiceItem.usingTime &&
                          dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.time
                            ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                            : getLabel(getCommonKey(LangConstant.P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT))
                        }`
                      : ""
                  }
                  disabled={dataServiceItem.categoryList?.map(dataMap => dataMap.uid).includes(categoryData?.uid)}
                  onClickWatch={onWatchServiceItem}
                />
              ))}
            </Box>
          </Box>
        </Dialog>
      }
      {isOpenService.viewService && (
        <DialogViewEditMainService
          data={listServiceItem ? listServiceItem.find(data => data.uid === isOpenService.targetUid) : {}}
          isOpen={isOpenService.viewService}
          isEdit={false}
          onClose={onCloseViewService}
        />
      )}
    </>
  );
};
export default memo(DialogAddToCategory);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: 400,
    height: 30,
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  rootBody: {
    marginTop: 48,
    marginBottom: 48,
    width: "80%",
    marginLeft: "10%",
    [theme.breakpoints.down(600)]: {
      width: "90%",
      marginLeft: "5%",
      overflowX: "auto",
    },
  },
}));
