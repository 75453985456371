import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant } from "const";

const ConfirmDialog = props => {
  const { onSelectConfirm, title, titleConfirm, isShow, confirmText, cancelText, classes } = props;
  const { t: getLabel } = useTranslation();
  const defaultClasses = useStyles();

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={isShow} classes={{ paper: defaultClasses.dialogPaper }}>
      <DialogTitle disableTypography className={defaultClasses.dialogTitle}>
        <Typography
          variant="subtitle2"
          className={defaultClasses.textTitle}
          classes={{ subtitle2: "medium-xl-txt" }}
          color={"inherit"}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={defaultClasses.dialogContent}>
        <Typography variant="body2" classes={{ body2: "medium-md-txt" }}>
          {titleConfirm}
        </Typography>
      </DialogContent>
      <DialogActions className={clsx(classes?.dialogAction, defaultClasses.dialogAction)}>
        <Button onClick={e => onSelectConfirm(e, true)} color="primary" className={defaultClasses.button}>
          <Typography variant="body2" classes={{ body2: "semiBold-lg-txt" }} color={"inherit"}>
            {confirmText || getLabel(LangConstant.TXT_AGREE)}
          </Typography>
        </Button>
        <Button
          onClick={e => onSelectConfirm(e, false)}
          color="primary"
          className={defaultClasses.button}
          style={{ marginLeft: 30 }}
        >
          <Typography variant="body2" classes={{ body2: "semiBold-lg-txt" }} color={"inherit"}>
            {cancelText || getLabel(LangConstant.TXT_DISAGREE)}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: "400px",
    borderRadius: "0%",
    maxWidth: "fit-content",
    padding: "30px 30px",
  },
  dialogTitle: {
    alignItems: "center",
    color: theme.palette.primary,
    padding: "0px 0px",
  },
  textTitle: {
    marginLeft: "auto",
    whiteSpace: "pre-wrap",
  },
  dialogContent: {
    padding: "15px 0 31px",
    color: "#9d9fa5",
  },
  dialogAction: {
    padding: "0px 0px",
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
    color: theme.palette.grey[400],
  },
}));

ConfirmDialog.propTypes = {
  onSelectConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  titleConfirm: PropTypes.string.isRequired,
  isShow: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  title: "",
  titleConfirm: "",
  isShow: true,
};

export default memo(ConfirmDialog);
