import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { CategoryItemListHeader, CategoryItemListBody } from "components/mn-master-product";
import { SearchBar } from "components";
import { Close, ArrowBack } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, formatCurrency, removeVietnameseTones } from "utils";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import MnProductRedux from "redux/mn-product.redux";

const DialogAddTableAccompaniedProductDetails = ({ isOpen, onClose, onBack, dataChild }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const [listDetailsContent, setListDetailsContent] = useState([]);

  const dataDetailsContent = useSelector(state => state.mnProductRedux.dataDetailsContent, shallowEqual);
  const isDetailsContent = useSelector(state => state.mnProductRedux.isGetDetailsContentSuccess, shallowEqual);

  const onSearch = name => {
    let newListSearch = dataDetailsContent?.data.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListDetailsContent(newListSearch);
  };

  const onClick = () => {};

  const onAddProductMainToAccompaniedProduct = uid => {
    let DetailsContent = dataDetailsContent?.data.filter(itemSearch => itemSearch.uid === uid)[0];
    var itemDetailsContentList = dataChild.itemDetailsContentList.map(data => data);
    itemDetailsContentList.push(DetailsContent);
    var detailsContentObjectList = [];
    for (var i = 0; i < itemDetailsContentList.length; i++) {
      detailsContentObjectList.push({ uid: itemDetailsContentList[i].uid, amount: 0 });
    }
    dispatch(MnProductRedux.updateDetailsEditContent({ data: detailsContentObjectList, uid: dataChild.uid }));
  };

  useEffect(() => {
    dispatch(MnProductRedux.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
  }, []);

  useEffect(() => {
    if (dataDetailsContent && dataDetailsContent.data && isDetailsContent) {
      setListDetailsContent(dataDetailsContent.data);
    }
  }, [isDetailsContent]);

  return (
    <Dialog open={isOpen} fullScreen>
      <Box className={classes.rootHeader}>
        <Box className={classes.boxIconBack}>
          <IconButton onClick={onBack} className={classes.iconButton}>
            <ArrowBack className={classes.icon} />
          </IconButton>
        </Box>
        <Typography className={classes.nameHeaderTypo}>
          {getLabel(LangConstant.TXT_ADD_PRODUCT_CATEGORY_FROM_ACCOMPANIED_GOOD)}
          {dataChild.name}
        </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
      <Box textAlign="center">
        <SearchBar
          className={classes.searchBar}
          onKeyUp={onSearch}
          placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
        />
        <Box className={classes.rootBody}>
          <CategoryItemListHeader isProduct={true} />
          {listDetailsContent.map(dataProductItem => (
            <CategoryItemListBody
              key={dataProductItem.uid}
              uid={dataProductItem.uid}
              onClickAdd={onAddProductMainToAccompaniedProduct}
              onClickWatch={onClick}
              categoryName={dataProductItem.name}
              isAdd={true}
              stock={dataProductItem.stock ? dataProductItem.stock : 0}
              image={dataProductItem.image}
              price={dataProductItem.price ? formatCurrency(`${dataProductItem.price}`) : ""}
              disabled={Boolean(
                dataChild.itemDetailsContentList.find(dataChildMap => dataChildMap.uid === dataProductItem.uid),
              )}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
export default memo(DialogAddTableAccompaniedProductDetails);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: 400,
    height: 30,
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  rootBody: {
    marginTop: 48,
    marginBottom: 48,
    width: "80%",
    marginLeft: "10%",
    [theme.breakpoints.down(600)]: {
      width: "90%",
      marginLeft: "5%",
      overflowX: "auto",
    },
  },
}));
