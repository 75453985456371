import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import GirdTime from "./GirdTime";
import { WeekHeader, TotalComponent } from "./index";
import { AppConstant } from "const";
import { HTML_CONTAINER_ID, RESERVATION_BOX_ID } from "pages/MnReservation";
import { useSelector } from "react-redux";

const BookingByWeek = ({ selectedDate, optionView }) => {
  const classes = useStyles();
  let maxRow = DEFAULT_MAX_ROW;
  const sizeScreen = screen.width;
  const data = useSelector(({ reservationRedux }) => reservationRedux.reservationWeek) || {};

  Object.values(data).forEach(value => {
    if (value.total.reservationCount > maxRow) maxRow = value.total.reservationCount;
  });
  let totalOfColumn = Object.keys(data).length;

  const [widthColumn, setWidthColumn] = useState(0);

  useEffect(() => {
    if (sizeScreen) {
      if (sizeScreen >= AppConstant.SIZE_SCREEN_XL) {
        setWidthColumn(parseInt((sizeScreen - 240) / DEFAULT_MAX_ROW));
      } else {
        setWidthColumn(WIDTH_COLUMN);
      }
    }
  }, [data]);

  return (
    <Box classes={{ root: classes.dayContent }} id={HTML_CONTAINER_ID}>
      <Box style={{ height: HEIGHT_DAY_HEADER }}>
        <WeekHeader selectedDate={selectedDate} />
      </Box>
      <Box style={{ width: totalOfColumn * widthColumn }} classes={{ root: classes.dayBody }} id={RESERVATION_BOX_ID}>
        <Box classes={{ root: classes.dayBodyWrap }}>
          {Object.keys(data).map((key, index) => (
            <GirdTime
              widthColumn={widthColumn}
              key={index}
              reservationList={data[key].reservation}
              maxRow={maxRow}
              optionView={optionView}
            />
          ))}
        </Box>
      </Box>
      <Box style={{ height: HEIGHT_DAY_FOOTER }}>
        <Box style={{ width: totalOfColumn * widthColumn }} classes={{ root: classes.dayFooter }}>
          {Object.keys(data).map((key, index) => (
            <Box width={widthColumn} key={index} borderRight={1} classes={{ root: classes.columnDayFooter }}>
              <TotalComponent total={data[key].total} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

BookingByWeek.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  data: PropTypes.object,
  optionView: PropTypes.number,
};

BookingByWeek.defaultProps = {
  selectedDate: new Date(),
  data: {},
};

export const HEIGHT_DAY_HEADER = 42;
const HEIGHT_DAY_FOOTER = 40;
const WIDTH_COLUMN = 175;
export const DEFAULT_MAX_ROW = 7;

const useStyles = makeStyles(theme => ({
  dayContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    width: "100%",
    height: `calc(100vh - 200px)`,
    backgroundColor: theme.palette.white,
  },
  dayBody: {
    width: "fit-content",
    flexGrow: 1,
    overflowY: "auto",
    msOverflowStyle: `none`,
    scrollbarWidth: `none`,
    "&::-webkit-scrollbar": {
      display: `none`,
      width: 0,
    },
  },
  dayBodyWrap: {
    width: "100%",
    flexGrow: 1,
    display: "-webkit-box",
    borderLeft: "solid 1px #ebeced",
    borderRight: "solid 1px #ebeced",
  },
  girdTime: {
    borderRightColor: theme.palette.grey[200],
  },
  reservationBox: {
    paddingRight: 5,
    paddingBottom: 5,
    borderBottomColor: theme.palette.grey[200],
  },
  dayFooter: {
    display: "inline-flex",
    height: "100%",
    backgroundColor: "#f1f3f6",
  },
  columnDayFooter: {
    borderRightColor: theme.palette.grey[200],
  },
}));

export default memo(BookingByWeek);
