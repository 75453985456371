import { call, put } from "redux-saga/effects";
import { toCamel, toSnake } from "utils";
import { ApiConstant } from "../const";
import ConsumerAction from "../redux/consumer.redux";
import { ConsumerService } from "../services";

export function* getConsumer(action) {
  try {
    const { data } = action;
    let response = yield call(ConsumerService.getConsumer, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(ConsumerAction.getConsumerSuccess(toCamel(responseData)));
    } else {
      yield put(ConsumerAction.getConsumerFailure(response.data));
    }
  } catch (error) {
    yield put(ConsumerAction.getConsumerFailure(error));
  }
}

export function* patchInvitationConsumer(action) {
  try {
    const { data } = action;
    let response = yield call(ConsumerService.patchInvitationConsumer, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ConsumerAction.invitationConsumerSuccess(toCamel(responseData)));
    } else {
      yield put(ConsumerAction.invitationConsumerFailure(response.data));
    }
  } catch (error) {
    yield put(ConsumerAction.invitationConsumerFailure(error));
  }
}

export function* getEmailConsumerQuantity() {
  try {
    let response = yield call(ConsumerService.getEmailConsumerQuantity);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ConsumerAction.getEmailQuantitySuccess(responseData));
    } else {
      yield put(ConsumerAction.getEmailQuantityFailure(response.data));
    }
  } catch (error) {
    yield put(ConsumerAction.getEmailQuantityFailure(error));
  }
}

export function* getInvitationFacebook(action) {
  try {
    const { data } = action;
    let response = yield call(ConsumerService.getInvitationFacebook, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ConsumerAction.getInvitationFacebookSuccess(toCamel(responseData)));
    } else {
      yield put(ConsumerAction.getInvitationFacebookFailure(response.data));
    }
  } catch (error) {
    yield put(ConsumerAction.getInvitationFacebookFailure(error));
  }
}

export function* patchInvitationFacebook(action) {
  try {
    const { data } = action;
    let response = yield call(ConsumerService.patchInvitationFacebook, toCamel(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ConsumerAction.invitationFacebookSuccess(toSnake(responseData)));
    } else {
      yield put(ConsumerAction.invitationFacebookFailure(response.data));
    }
  } catch (error) {
    yield put(ConsumerAction.invitationFacebookFailure(error));
  }
}

export function* getNumberBookingByPhoneNumber(action) {
  try {
    const { data } = action;
    let response = yield call(ConsumerService.getNumberBookingByPhoneNumber, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ConsumerAction.consumerSuccess({ numberBooking: toCamel(responseData), isNumberBookingSuccess: true }));
    } else {
      yield put(ConsumerAction.consumerFailure(response.data));
    }
  } catch (error) {
    yield put(ConsumerAction.consumerFailure(error));
  }
}

export function* postConsumerList(action) {
  try {
    const { data } = action;
    let response = yield call(ConsumerService.postConsumerList, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ConsumerAction.consumerSuccess({ data: toCamel(responseData), isUploadConsumerSuccess: true }));
    } else {
      yield put(ConsumerAction.consumerFailure(response.data));
    }
  } catch (error) {
    yield put(ConsumerAction.consumerFailure(error));
  }
}
