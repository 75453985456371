import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { LangConstant } from "const";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import StaffAction from "redux/staff.redux";

export default function DialogCreatePositionStaff({ isShow, onSubmit, onClose, data }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const [value, setValue] = useState(data ? data.name : "");
  const [positionStatus, positionFetch] = useSelector(
    ({ staffRedux }) => [staffRedux.isSuccessPosition, staffRedux.isFetchingPosition],
    shallowEqual,
  );

  const handlerSubmit = event => {
    event.preventDefault();
    if (!positionFetch && value !== "") {
      if (data) {
        dispatch(StaffAction.updateStaffPosition({ ...data, name: value }));
      } else {
        dispatch(StaffAction.requestPostPosition({ name: value }));
      }
    }
    onSubmit();
    setValue("");
  };

  const onChange = event => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (positionStatus) onSubmit();
  }, [positionStatus]);

  useEffect(() => {
    setValue(data ? data.name : "");
  }, [data]);

  return (
    <Dialog open={isShow} maxWidth="sm">
      <form onSubmit={handlerSubmit}>
        <DialogTitle className={classes.header}>
          <IconButton className={classes.closeItem} onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <Typography variant="body1" className={classes.typoHeader}>
            {data !== null
              ? getLabel(LangConstant.TXT_UPDATE_LOCATION)
              : getLabel(LangConstant.T_CREATE_POSITION_STAFF_DIALOG)}
          </Typography>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Typography component="p" className={`${classes.title} medium-md-txt}`}>
            {getLabel(LangConstant.T_CREATE_POSITION_STAFF_TITLE)}
          </Typography>
          <TextField
            type="input"
            required={true}
            className={classes.input}
            inputProps={{ style: { textAlign: "center", fontSize: "14px" } }}
            placeholder={getLabel(LangConstant.L_CREATE_POSITION_STAFF_INPUT)}
            value={value}
            onChange={onChange}
            variant="outlined"
            size="small"
          />
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button type="submit" className={classes.button} autoFocus variant="contained" color="primary">
            {data !== null ? getLabel(LangConstant.TXT_UPDATE) : getLabel(LangConstant.T_CREATE_POSITION_STAFF_ADD)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  input: {
    width: "270px",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
  },
  header: {
    padding: 0,
    maxWidth: "330px",
    width: "100%",
    minWidth: "200px",
    backgroundColor: "#65b39d",
    height: "40px",
    color: "#ffffff",
    position: "relative",
  },
  typoHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    color: "#ffffff",
    position: "relative",
  },
  closeItem: {
    position: "absolute",
    right: "10px",
    width: "40px",
    height: "40px",
    zIndex: 10,
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    width: "20px",
    height: "20px",
    justifyContent: "center",
    color: "white",
  },
  button: {
    height: "30px",
    boxShadow: "none",
    borderRadius: "20px",
    textTransform: "none",
    minHeight: "20px",
    color: "#ffffff",
    borderColor: "#ef5845",
    backgroundColor: "#ef5845",
    paddingLeft: "24px",
    paddingRight: "24px",
    fontSize: "12px",
  },
  footer: {
    justifyContent: "center",
    padding: 0,
    marginBottom: "20px",
    marginTop: "24px",
  },
  content: {
    zIndex: theme.zIndex.appBar + 100,
    padding: "30px",
    justifyContent: "center",
    fontSize: "14px",
  },
}));

DialogCreatePositionStaff.propTypes = {
  isShow: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
};
DialogCreatePositionStaff.defaultProps = {
  data: null,
};
