import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, Box } from "@material-ui/core";
import { format as DateFormat } from "date-fns";
import clsx from "clsx";
import StringFormat from "string-format";
import { updateQueryParams } from "pages/BookingHistory/components/FilterMenu";
import { jumpToTargetBooking, getCommonKey, toSnake } from "utils";
import { convertTimeStampToDate } from "utils/date";
import { LangConstant, AppConstant, PathConstant } from "const";
import { CellHead, CellBody } from "components";

const ActivityHistoryTable = props => {
  const { showData, headData, onSort } = props;
  const { t: getLabel } = useTranslation(LangConstant.NS_ACTIVITY_HISTORY);
  const classes = useStyles();
  const history = useHistory();

  const activityDetails = (actionDetail, actionType, name, updatedField, currentCode) => {
    switch (actionType) {
      case AppConstant.ACTION_TYPE.create:
        return StringFormat(getLabel(LangConstant.FM_ACTIVITY_DO_CREATE), name);
      case AppConstant.ACTION_TYPE.edit: {
        const updateField = getUpdatedField(updatedField).join(", ");
        return StringFormat(getLabel(LangConstant.FM_ACTIVITY_DO_EDIT), name, updateField, currentCode);
      }
      case AppConstant.ACTION_TYPE.changeStatus: {
        const toData = actionDetail.data["status"] ? actionDetail.data["status"].to : "";
        return StringFormat(
          getLabel(LangConstant.FM_ACTIVITY_DO_CHANGE),
          name,
          currentCode,
          getLabel(getCommonKey(LangConstant.ARR_STATUS_FILTER[toData])),
        );
      }
      case AppConstant.ACTION_TYPE.assign: {
        const updateField = getUpdatedAssign(actionDetail.data, updatedField).join(", ");
        return StringFormat(getLabel(LangConstant.FM_ACTIVITY_DO_ASSIGN), name, updateField, currentCode);
      }
      case AppConstant.ACTION_TYPE.copy:
        return StringFormat(getLabel(LangConstant.FM_ACTIVITY_DO_COPY), name, actionDetail.code);
      case AppConstant.ACTION_TYPE.extend: {
        return StringFormat(getLabel(LangConstant.FM_ACTIVITY_DO_EXTEND), name, actionDetail.code);
      }
      case AppConstant.ACTION_TYPE.ordering: {
        return StringFormat(getLabel(LangConstant.FM_ACTIVITY_DO_EDIT_ORDER), name, currentCode);
      }
    }
  };

  const onShowBooking = (event, data) => {
    event.stopPropagation();
    let nextStatus = data.current_status;

    let isCompleteReservation = false === AppConstant.STT_RESERVATION_ACTIVE.includes(nextStatus);
    if (isCompleteReservation) {
      updateQueryParams(
        {
          from: convertTimeStampToDate(data.reservationCreated),
          uid: data.actionDetails.reservationUid,
        },
        history,
        PathConstant.BOOKING_HISTORY,
      );
    } else {
      jumpToTargetBooking(history, data.reservationCreated, data.actionDetails.reservationUid, false);
    }
  };

  const getUpdatedField = dataArr => {
    const updatedFieldArr = dataArr.map(key => getLabel(UPDATED_FIELD[`${key}`]));

    return updatedFieldArr;
  };

  const getUpdatedAssign = (detailData, updatedFieldArr) => {
    let tmpDetail = toSnake(detailData);
    const updatedValueArr = updatedFieldArr.map(
      key => `${getLabel(UPDATED_FIELD[key])} "${tmpDetail[key].to ? tmpDetail[key].to : ""}"`,
    );
    return updatedValueArr;
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headData.map((cell, index) => (
              <CellHead
                textAlign="left"
                cellData={getLabel(cell)}
                onSort={e => onSort(e, getLabel(cell))}
                isSort={index === 0}
                className={classes.cellHeadRoot}
                key={index}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {showData !== null &&
            showData.map((dataShow, index) => (
              <TableRow key={index} className={classes.rootTableRow}>
                <CellBody
                  textAlign="left"
                  className={clsx(classes.cellBodyRoot, classes.firstRow)}
                  cellData={DateFormat(new Date(dataShow.created * 1000), AppConstant.FM_HH_MM_DD_MM_YYYY)}
                />
                <CellBody
                  textAlign="left"
                  className={classes.cellBodyRoot}
                  cellData={
                    <Box className={classes.codeCell} onClick={e => onShowBooking(e, dataShow)}>
                      {dataShow.code}
                    </Box>
                  }
                  style={{ width: "20%" }}
                />
                <CellBody
                  textAlign="left"
                  className={classes.cellBodyRoot}
                  cellData={
                    dataShow.action === AppConstant.ACTION_TYPE.ordering
                      ? getLabel(LangConstant.ARR_ACTIVITY_TYPE[dataShow.action - 1])
                      : getLabel(LangConstant.ARR_ACTIVITY_TYPE[dataShow.action])
                  }
                  style={{ width: "20%" }}
                />
                <CellBody
                  textAlign="left"
                  className={classes.cellBodyRoot}
                  cellData={
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: activityDetails(
                          dataShow.actionDetails,
                          dataShow.action,
                          dataShow.actionDetails.fromName,
                          dataShow.actionDetails.data &&
                            dataShow.actionDetails &&
                            dataShow.actionDetails.data.updatedField
                            ? dataShow.actionDetails.data.updatedField
                            : [],
                          dataShow.code,
                        ),
                      }}
                    />
                  }
                  style={{ width: "40px" }}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UPDATED_FIELD = {
  name: LangConstant.TXT_ACTIVITY_UPDATED_NAME,
  email: LangConstant.TXT_ACTIVITY_UPDATED_EMAIL,
  start_time: LangConstant.TXT_ACTIVITY_UPDATED_START_TIME,
  consumer: LangConstant.TXT_ACTIVITY_UPDATED_CONSUMER,
  staff: LangConstant.TXT_ACTIVITY_UPDATED_STAFF,
  note: LangConstant.TXT_ACTIVITY_UPDATED_NOTE,
  phone_number: LangConstant.TXT_ACTIVITY_UPDATED_PHONE,
  address: LangConstant.TXT_ACTIVITY_UPDATED_ADDRESS,
  internal_note: LangConstant.TXT_ACTIVITY_UPDATED_INTERNAL_NOTE,
  place_category: LangConstant.TXT_ACTIVITY_UPDATED_PLACE_CATEGORY,
  place_item: LangConstant.TXT_ACTIVITY_UPDATED_PLACE_ITEM,
  service_category: LangConstant.TXT_ACTIVITY_UPDATED_SERVICE_CATEGORY,
  service_item: LangConstant.TXT_ACTIVITY_UPDATED_SERVICE_ITEM,
  staff_name: LangConstant.TXT_ACTIVITY_UPDATED_STAFF_NAME,
  reality_start_time: LangConstant.TXT_ACTIVITY_UPDATED_REALITY_START_TIME,
  reality_end_time: LangConstant.TXT_ACTIVITY_UPDATED_REALITY_END_TIME,
};

const useStyles = makeStyles(theme => ({
  rootTableRow: {
    height: 40,
  },
  tableContainer: {
    border: "1px solid #ebeced",
    height: "75vh",
    "@media only screen and (max-height: 850px)": {
      height: "69vh",
    },
    "@media only screen and (max-height: 812px)": {
      height: "53vh",
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  cellHeadRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  cellBodyRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  activityDetail: {
    width: "100%",
  },
  highlight: {
    fontWeight: "bold",
  },
  codeCell: {
    color: "#447aff",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  firstRow: {
    width: "20%",
  },
}));

export default memo(ActivityHistoryTable);
