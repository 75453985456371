import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import TextPackageDetails from "./TextPackageDetails";

const QuantityInfo = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const objFmPackageUsingType = getLabel(LangConstant.OBJ_PACKAGE_USING_TYPE, { returnObjects: true });

  const packageCurrent = useSelector(({ paymentRedux }) => paymentRedux.packageCurrent);

  let packageDetails = packageCurrent?.packageDetails;

  let isExistPackageBookingDefault =
    packageDetails &&
    packageDetails.find(item => item.type === AppConstant.PACKAGE_USING_TYPE.bookingFunction && item.default);
  let isExistPackageOrderDefault =
    packageDetails &&
    packageDetails.find(item => item.type === AppConstant.PACKAGE_USING_TYPE.orderFunction && item.default);
  let isExistPackageBookingNotDefault =
    packageDetails &&
    packageDetails.find(item => item.type === AppConstant.PACKAGE_USING_TYPE.bookingFunction && !item.default);
  let isExistPackageOrderNotDefault =
    packageDetails &&
    packageDetails.find(item => item.type === AppConstant.PACKAGE_USING_TYPE.orderFunction && !item.default);
  return (
    <Box className={classes.root}>
      <Box className={clsx(classes.boxLine, classes.boxNotMarginTop)}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_NUMBER_OF_SHOPS)}
        </Typography>
        <TextPackageDetails data={packageDetails} type={AppConstant.PACKAGE_USING_TYPE.shop} />
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_NUMBER_OF_PRODUCTS)}
        </Typography>
        <TextPackageDetails data={packageDetails} type={AppConstant.PACKAGE_USING_TYPE.item} />
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_NUMBER_OF_LOCATIONS)}
        </Typography>
        <TextPackageDetails data={packageDetails} type={AppConstant.PACKAGE_USING_TYPE.place} />
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_NUMBER_OF_STAFFS)}
        </Typography>
        <TextPackageDetails data={packageDetails} type={AppConstant.PACKAGE_USING_TYPE.staff} />
      </Box>
      <Box className={clsx(classes.boxLine, classes.boxNotMarginBottom)}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_FEATURES_PACKAGE)}
        </Typography>
        <Box component="span">
          {isExistPackageBookingDefault && (
            <Typography component="span" className="medium-md-txt">
              {objFmPackageUsingType[AppConstant.PACKAGE_USING_TYPE.bookingFunction]}
            </Typography>
          )}
          {isExistPackageOrderDefault && (
            <Typography component="span" className="medium-md-txt">
              {objFmPackageUsingType[AppConstant.PACKAGE_USING_TYPE.orderFunction]}
            </Typography>
          )}
          {isExistPackageBookingNotDefault && (
            <Typography component="span" className={clsx("medium-md-txt", classes.otherNumber)}>
              {objFmPackageUsingType[AppConstant.PACKAGE_USING_TYPE.bookingFunction]}
            </Typography>
          )}
          {isExistPackageOrderNotDefault && (
            <Typography component="span" className={clsx("medium-md-txt", classes.otherNumber)}>
              {StringFormat(
                FM_GUARD,
                StringFormat(FM_PLUS, objFmPackageUsingType[AppConstant.PACKAGE_USING_TYPE.orderFunction]),
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const FM_GUARD = "({0})";
const FM_PLUS = "+{0}";

export default memo(QuantityInfo);

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: "60%",
    paddingLeft: 24,
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
      paddingLeft: 0,
      paddingTop: 24,
    },
  },
  boxLine: {
    display: "flex",
    alignItems: "center",
    height: 30,
    marginTop: 14,
    marginBottom: 14,
  },
  boxNotMarginBottom: {
    marginBottom: 0,
  },
  boxNotMarginTop: {
    marginTop: 0,
  },
  title: {
    width: 145,
    marginRight: 24,
    lineHeight: 1.57,
  },
  otherNumber: {
    color: theme.palette.text.link,
    marginLeft: 4,
  },
}));
