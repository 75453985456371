import React from "react";
import PropTypes from "prop-types";
import { Star } from "@material-ui/icons";
import { Box, Checkbox, FormControlLabel, Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { AppConstant } from "const";
import { getRatingPercentage } from "..";

const FilterByScore = ({ ratingData, filterByScore, onSelectScore }) => {
  const classes = useStyles();

  const onChangeScoreFilter = score => {
    onSelectScore(score);
  };
  return (
    <Box className={classes.filterByScore}>
      {AppConstant.RATING_SCORE_ARRAY.map((score, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              disableRipple={true}
              disableTouchRipple={true}
              checked={filterByScore.includes(score)}
              onChange={() => onChangeScoreFilter(score)}
              color="primary"
              className={classes.scoreFilterCheckbox}
            />
          }
          label={
            <Grid container className={classes.checkboxLabelRoot}>
              <Grid item xs={2}>
                <Typography variant="body2" className={classes.scoreAmount}>
                  {score} <Star />
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <LinearProgress
                  classes={{
                    root: classes.progressBarRoot,
                    bar: classes.progressBar,
                  }}
                  value={getRatingPercentage(ratingData, score)}
                  variant="determinate"
                />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.ratedPeopleNumber}>
                  {ratingData.ratingCount && ratingData.ratingCount[score]}
                </Typography>
              </Grid>
            </Grid>
          }
          classes={{
            root: classes.filterByScoreLabel,
            label: classes.filterCheckboxLabel,
          }}
        />
      ))}
    </Box>
  );
};

FilterByScore.propTypes = {
  ratingData: PropTypes.object,
  filterByScore: PropTypes.array,

  onSelectScore: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  filterByScore: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 10,
    borderBottom: "1px solid #CECFD2",
  },

  checkboxLabelRoot: {
    display: "flex",
    alignItems: "center",
  },

  scoreAmount: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    marginLeft: 10,
    "&>svg": {
      color: "#FEBA40",
      fontSize: 15,
    },
  },

  ratedPeopleNumber: {
    fontSize: 12,
    minWidth: "max-content",
  },

  progressBarRoot: {
    height: 8,
    borderRadius: 10,
    backgroundColor: theme.palette.grey[200],
    margin: "0 8px",
  },

  progressBar: {
    borderRadius: 10,
    backgroundColor: "#FEBA40",
  },

  filterByScoreLabel: {
    width: "100%",
    margin: 0,
    marginBottom: 10,
  },

  filterCheckboxLabel: {
    width: "100%",
  },

  scoreFilterCheckbox: {
    padding: 0,
  },
}));

export default FilterByScore;
