import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { Pagination, PaginationItem } from "@material-ui/lab";
import clsx from "clsx";
import { format } from "date-fns";
import { Add } from "@material-ui/icons";
import {
  makeStyles,
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  TableContainer,
  Paper,
  Container,
  Button,
} from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import { BglobalJSC } from "components";
import {
  MenuStatus,
  SearchBar,
  CellHead,
  CellBody,
  InviteDialog,
  ImportExcelFileDialog,
  MnConsumerTableDialog,
} from "./components";
import PopupInvite from "./inviteConsumer/PopupInvite";
import ConsumerAction from "redux/consumer.redux";
import ShopAction from "redux/shop.redux";
import { getNSKey, isBlank } from "utils";
import { ImportIcon } from "icons";

const MnConsumers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();

  const [consumers, isInvitationConsumerSuccess, isSuccessUploadConsumer] = useSelector(
    ({ consumerRedux }) => [
      consumerRedux.consumers,
      consumerRedux.isInvitationConsumerSuccess,
      consumerRedux.isUploadConsumerSuccess,
    ],
    shallowEqual,
  );
  const dataShopRedux = useSelector(state => state.shopRedux.dataShop, shallowEqual);

  const headData = LangConstant.ARR_MANAGE_CONSUMER;

  const [arrSelected, setArrSelected] = useState([]);
  const [dataShow, setDataShow] = useState(consumers || DEFAULT_PAGINATION);
  const [page, setPage] = useState(dataShow.page ? dataShow.page : DEFAULT_PARAMS.page);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [consumerList, setConsumerList] = useState([]);
  const [alias, setAlias] = useState("");
  const [shopName, setShopName] = useState("");
  const [status, setStatus] = useState(null);
  const [checked, setChecked] = useState(false); // To check all the consumer
  const [openInvite, setOpenInvite] = useState(false);
  const [sortBy, setSortBy] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenConsumerTableDialog, setIsOpenTableConsumerDialog] = useState(false);

  const onSearch = name => {
    let param = { ...DEFAULT_PARAMS };
    if (!isBlank(name)) param = { ...param, filter: name };
    setPage(DEFAULT_PARAMS.page);
    dispatch(ConsumerAction.requestGetConsumer(param));
  };

  const onChangeStatus = status => {
    setStatus(status);
    setPage(DEFAULT_PARAMS.page);
  };

  const onChangePage = (_, newPage) => {
    dispatch(ConsumerAction.requestGetConsumer({ paging: 1, size: 50, page: newPage }));
    setPage(newPage);
  };

  const onInvite = () => {
    setOpenInvite(true);
  };

  const onInviteAll = () => {
    setOpen(true);
  };

  const onSelectAll = e => {
    if (e.target.checked) {
      let arrData = dataShow.data
        .filter(data => !data.userId || data.isInvited === INVITE_STATUS.notInvited)
        .map(data => data);

      setArrSelected([...arrData]);
      setChecked(true);
      return;
    }
    setArrSelected([]);
    setChecked(false);
  };

  const onSelect = (e, index) => {
    let checked = e.target.checked;
    let data = dataShow.data[index];

    if (checked) {
      let newArray = [...arrSelected];
      newArray.push(data);
      setArrSelected(newArray);
      return;
    }
    let arrData = [];
    arrData = arrSelected.filter(item => item.email !== data.email && item.phoneNumber !== data.phoneNumber);
    setArrSelected([...arrData]);
  };

  const onFilter = (_, value) => {
    let fill;
    if (value === getLabel(LangConstant.TXT_NAME)) {
      fill = "name";
    } else if (value === getLabel(LangConstant.TXT_BIRTH_DAY)) {
      fill = "birthDay";
    } else {
      fill = "sex";
    }
    if (fill === filter) setSortBy(!sortBy);
    else setSortBy(false);
    setFilter(fill);
    setPage(DEFAULT_PARAMS.page);
  };

  const onCloseUploadExcelDialog = () => setIsOpenImport(false);

  const onSubmitExcelData = data => {
    setIsOpenImport(false);
    setIsOpenTableConsumerDialog(true);
    setConsumerList(data);
  };

  useLayoutEffect(() => {
    const appData = Cookie.get(AppConstant.KEY_GBOSS);
    if (appData && appData.length > 0) {
      let shopId = JSON.parse(appData).shopId;
      dispatch(ShopAction.requestGetShopInfo({ data: { shopId: shopId } }));
    }
    dispatch(ConsumerAction.requestGetConsumer({ paging: 1, size: 50, page: page }));
  }, []);

  useEffect(() => {
    if (isInvitationConsumerSuccess || isSuccessUploadConsumer)
      dispatch(ConsumerAction.requestGetConsumer({ paging: 1, size: 50, page: page }));
  }, [isInvitationConsumerSuccess, isSuccessUploadConsumer]);

  useEffect(() => {
    if (consumers != null) {
      let dataConsumers = consumers.data;

      if (status !== null)
        dataConsumers = consumers.data.filter(consumer => {
          if (status === ACCOUNT_STATUS.registered) return Boolean(consumer.userId);
          else return !Boolean(consumer.userId);
        });

      dataConsumers.sort(function (a, b) {
        if (filter === "name") {
          return ("" + a.name).localeCompare(b.name);
        } else if (filter === "sex") {
          return ("" + a.gender).localeCompare(b.gender);
        } else if (filter === "birthDay") {
          return a.birthday - b.birthday;
        }
      });
      if (sortBy) {
        dataConsumers.reverse();
      }
      let stateConsumer = { ...consumers, data: dataConsumers };
      setDataShow(stateConsumer ? stateConsumer : DEFAULT_PAGINATION);
      setPage(stateConsumer.page);
    }
    if (dataShopRedux) {
      setAlias(dataShopRedux.alias);
      setShopName(dataShopRedux.name);
    }
  }, [consumers, status, filter, sortBy, dataShopRedux]);

  return (
    <Box className="hidden-scroll">
      <Paper elevation={1} square className={classes.paperRoot}>
        <Container className={classes.containerRoot}>
          <Box className={classes.mainRoot}>
            <Box className={classes.optionBar}>
              <MenuStatus className={classes.MenuStatus} onChange={onChangeStatus} includeDefault={true} />
              <SearchBar placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)} onKeyUp={onSearch} />
              <Button
                classes={{ root: classes.importBtn, iconSizeSmall: classes.icon }}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => setIsOpenImport(true)}
                startIcon={<ImportIcon />}
              >
                <Typography className={clsx(classes.inviteBtnText, "medium-md-txt")}>
                  {getLabel(getNSKey(LangConstant.NS_CONSUMER, LangConstant.TXT_BTN_IMPORT))}
                </Typography>
              </Button>
              <ImportExcelFileDialog
                open={isOpenImport}
                onClose={onCloseUploadExcelDialog}
                onSubmit={onSubmitExcelData}
              />
              <MnConsumerTableDialog
                open={isOpenConsumerTableDialog}
                onClose={() => setIsOpenTableConsumerDialog(false)}
                data={consumerList}
              />
              {checked ? (
                <Button
                  className={classes.buttonInviteAll}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={onInviteAll}
                  startIcon={<Add fontSize="small" />}
                >
                  <Typography className={clsx(classes.inviteBtnText, "medium-md-txt")}>
                    {getLabel(LangConstant.TXT_INVITE_ALL)}
                  </Typography>
                </Button>
              ) : (
                <Button
                  className={classes.buttonSendInvite}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={onInvite}
                  startIcon={<Add fontSize="small" />}
                >
                  <Typography className={clsx(classes.inviteBtnText, "medium-md-txt")}>
                    {getLabel(LangConstant.TXT_SEND_INVITE)}
                  </Typography>
                </Button>
              )}
              <PopupInvite open={openInvite} setOpen={setOpenInvite} shopData={alias} shopName={shopName} />
            </Box>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headData.map((cell, index) => (
                      <CellHead
                        cellData={getLabel(cell)}
                        onSelect={onSelectAll}
                        checked={checked}
                        listDisabled={dataShow.data.filter(
                          data => !data.userId && data.isInvited === INVITE_STATUS.notInvited,
                        )}
                        onFilter={event => onFilter(event, getLabel(cell))}
                        key={index}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataShow.data.map((dataShow, index) => (
                    <TableRow key={index} className={classes.rootTableRow}>
                      <CellBody headData={getLabel(LangConstant.TXT_NAME)} cellData={dataShow.name} />
                      <CellBody headData={getLabel(LangConstant.TXT_PHONE_NUMBER)} cellData={dataShow.phoneNumber} />
                      <CellBody headData={getLabel(LangConstant.TXT_EMAIL)} cellData={dataShow.email} />
                      <CellBody
                        headData={getLabel(LangConstant.TXT_BIRTH_DAY)}
                        cellData={
                          dataShow.birthday
                            ? format(new Date(dataShow.birthday * 1000), AppConstant.FM_DD_MM_YYYY)
                            : null
                        }
                      />
                      <CellBody
                        headData={getLabel(LangConstant.TXT_SEX)}
                        cellData={getLabel(LangConstant.ARR_GENDER[dataShow.gender - 1])}
                      />
                      <CellBody
                        headData={getLabel(LangConstant.TXT_ACCOUNT_STATUS)}
                        cellData={dataShow.userId ? 1 : dataShow.isInvited === INVITE_STATUS.notInvited ? 2 : 0}
                        onSelect={e => onSelect(e, index)}
                        checked={isChecked(arrSelected, dataShow)}
                        checkAll={checked}
                        data={dataShow}
                        index={index}
                        open={open}
                        setSelected={data => setArrSelected([data])}
                        setOpen={setOpen}
                        shopAlias={alias}
                      />
                    </TableRow>
                  ))}
                </TableBody>
                <InviteDialog open={open} setOpen={setOpen} listCustomers={arrSelected} shopAlias={alias} />
              </Table>
            </TableContainer>
            <Box className={classes.paginationParent}>
              <TablePagination
                classes={{
                  root: classes.tablePagination,
                  selectRoot: classes.selectRoot,
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                  actions: classes.actions,
                }}
                component="div"
                onChangePage={() => null}
                count={dataShow.total}
                page={page > 0 ? page - 1 : 0}
                rowsPerPage={ROW_PER_PAGE}
                labelRowsPerPage={``}
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography
                    variant="body2"
                    classes={{ root: classes.paginationTypo, body2: "regular-sm-txt" }}
                    component="span"
                  >
                    {getLabel(LangConstant.FN_PAGINATION)(from, to, count)}
                  </Typography>
                )}
              />
              <Pagination
                className={classes.tablePagination}
                count={parseInt(dataShow.totalPage)}
                onChange={onChangePage}
                page={page}
                size="small"
                renderItem={item => (
                  <PaginationItem
                    classes={{
                      page: classes.paginationItem,
                      selected: classes.selected,
                    }}
                    {...item}
                  />
                )}
              />
            </Box>
          </Box>
        </Container>
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};

const ROW_PER_PAGE = 50;
const DEFAULT_FILTER = "name";
const DEFAULT_PARAMS = { paging: 1, size: 50, page: 1 };
const DEFAULT_PAGINATION = { data: [], totalPage: 0, total: 0, size: 0, paging: 1, page: 0 };
const INVITE_STATUS = {
  invited: 1,
  notInvited: 0,
};
const ACCOUNT_STATUS = {
  registered: 0,
  unRegistered: 1,
};

const isChecked = (arrSelected, data) => {
  return Boolean(arrSelected.find(item => item.email === data.email && item.phoneNumber === data.phoneNumber));
};

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 10,
  },
  rootMain: {
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      display: "none",
    },
  },
  containerRoot: {
    maxWidth: "100%",
  },
  mainRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    padding: "20px 0 20px",
  },
  MenuStatus: {
    height: 30,
  },
  tableContainer: {
    border: "1px solid #ebeced",
    height: "75vh",
    [theme.breakpoints.down("max-height: 840px")]: {
      height: "60vh",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  rootTableRow: {
    height: 40,
  },
  optionBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 20,
    height: 30,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      minHeight: 150,
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 10,
    },
  },
  buttonSendInvite: {
    padding: "0 16px 0",
    minHeight: 30,
    fontWeight: "inherit",
    height: 30,
    minWidth: 125,
    borderRadius: 2,
    textTransform: "capitalize",
    color: theme.palette.white,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      minWidth: 125,
      maxWidth: 125,
    },
  },
  buttonInviteAll: {
    padding: "0 16px 0",
    minHeight: 30,
    fontWeight: "inherit",
    height: 30,
    minWidth: 135,
    borderRadius: 2,
    textTransform: "capitalize",
    color: theme.palette.white,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      minWidth: 135,
      maxWidth: 135,
    },
  },
  importBtn: {
    minHeight: 30,
    height: 30,
    minWidth: 100,
    fontWeight: "inherit",
    padding: "0 16px 0",
    marginRight: 16,
    borderRadius: 2,
    textTransform: "capitalize",
    color: theme.palette.white,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      minWidth: 135,
      maxWidth: 135,
    },
  },
  icon: {
    width: 14,
    height: 14,
    fontSize: 14,
  },
  inviteBtnText: {
    color: "inherit",
    width: "100%",
  },
  newStatus: {
    color: "#447aff",
    fontSize: "small",
    textDecoration: "none !important",
  },
  paginationParent: {
    display: "flex",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "inline-block",
    },
  },
  tablePagination: {
    marginTop: 15,
    display: "flex",
    textAlign: "center",
    color: theme.palette.grey[600],
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  paginationItem: {
    fontSize: "12px !important",
  },
  paginationTypo: {
    marginLeft: 10,
  },
  selected: {
    color: "#447aff",
    backgroundColor: "unset !important",
  },
  selectRoot: {
    display: "none",
    paddingLeft: 0,
  },
  toolbar: {
    paddingLeft: 0,
  },
  caption: {
    paddingBottom: 4,
  },
  actions: {
    display: "none",
  },
  boxJSC: {
    marginBottom: 50,
  },
}));

export default memo(MnConsumers);
