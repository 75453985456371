import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Button, Paper, Grid, Typography, Box, Menu, MenuItem } from "@material-ui/core";
import { Add, MoreHoriz } from "@material-ui/icons";
import clsx from "clsx";
import StaffAction from "redux/staff.redux";
import { LangConstant } from "const";
import { ConfirmDialog, DialogCreatePositionStaff } from "components/dialog";

const StaffPosition = () => {
  const { t: getLabel } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const [isRemovePositionSuccess, isSuccessPosition, isSuccessrUpdatePosition, appDataWorking] = useSelector(
    ({ staffRedux }) => [
      staffRedux.isRemovePositionSuccess,
      staffRedux.isSuccessPosition,
      staffRedux.isSuccessrUpdatePosition,
      staffRedux.dataWorkKingShift,
    ],
    shallowEqual,
  );

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [dataState, setDataState] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [line, setLine] = useState("");
  const onClick = valueOption => {
    if (valueOption === STAFF_POSITION_OPTION.delete) {
      setOpenConfirm(true);
      setDataState({ data: appDataWorking[line].uid });
    } else if (valueOption === STAFF_POSITION_OPTION.addNew) {
      setIsShow(true);
      setDataState(appDataWorking[line]);
    }
    setAnchorEl(null);
  };

  const onConfirm = (event, isAgree) => {
    event.stopPropagation();
    if (isAgree) {
      dispatch(StaffAction.removeStaffPosition({ uid: dataState.data }));
    }
    setOpenConfirm(false);
  };

  const onClickAdd = event => {
    setAnchorEl(event.currentTarget);
    setLine(event.currentTarget.id);
  };

  const onCloseDialog = () => {
    setIsShow(false);
  };

  const onChange = () => {
    setDataState(null);
    setIsShow(true);
  };

  useEffect(() => {
    if (isRemovePositionSuccess) {
      dispatch(StaffAction.getWorkingPotision());
    }
  }, [isRemovePositionSuccess]);

  useEffect(() => {
    if (isSuccessPosition) {
      dispatch(StaffAction.getWorkingPotision());
    }
  }, [isSuccessPosition]);

  useEffect(() => {
    if (isSuccessrUpdatePosition) {
      dispatch(StaffAction.getWorkingPotision());
    }
  }, [isSuccessrUpdatePosition]);

  useEffect(() => {
    if (!appDataWorking) {
      dispatch(StaffAction.getWorkingPotision());
    }
  }, [appDataWorking]);

  return (
    <Paper className={classes.paper}>
      <Box>
        <Grid item xs={12} sm={8} className={classes.pageAvaNameSingleRow}>
          <Typography classes={clsx("semiBold-lg-txt", classes.header)}>
            {getLabel(LangConstant.TXT_POSITION)}
          </Typography>
          <Box className={classes.headerButton}>
            <Button
              startIcon={<Add className={classes.icon} />}
              className={classes.cancelButton}
              onClick={onChange}
            ></Button>
            <DialogCreatePositionStaff
              isShow={isShow}
              onSubmit={onCloseDialog}
              onClose={onCloseDialog}
              data={dataState}
            />
          </Box>
        </Grid>
      </Box>
      <Box>
        {appDataWorking &&
          appDataWorking.map((item, index) => (
            <Grid key={index} item xs={12} sm={8} className={classes.pageTimeBottom}>
              <Typography classes={clsx("medium-md-txt", classes.bottom)}>{item.name}</Typography>
              <Box className={classes.headerButton}>
                <Button
                  className={classes.bottomButton}
                  id={index}
                  onClick={onClickAdd}
                  startIcon={<MoreHoriz className={classes.icon} />}
                ></Button>
                {openConfirm && (
                  <ConfirmDialog
                    onSelectConfirm={onConfirm}
                    title={getLabel(LangConstant.TXT_DELETE_WORK_POSITION)}
                    titleConfirm={getLabel(LangConstant.TXT_DELETE_WORK_POSITION_QUESTION)}
                  />
                )}
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClick}>
                  <MenuItem className={classes.menu} onClick={() => onClick(STAFF_POSITION_OPTION.addNew)}>
                    {getLabel(LangConstant.TXT_NEWS_POSTLIST_POSTEDITBTN)}
                  </MenuItem>
                  <MenuItem className={classes.menu} onClick={() => onClick(STAFF_POSITION_OPTION.delete)}>
                    {getLabel(LangConstant.TXT_DELETE)}
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
          ))}
      </Box>
    </Paper>
  );
};

const STAFF_POSITION_OPTION = {
  addNew: 1,
  delete: 2,
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    minWidth: 200,
    boxShadow: "none",
  },
  menu: {
    width: 215,
    height: 30,
    boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
  },
  bottomButton: {
    minWidth: 40,
  },
  icon: {
    width: 24,
    height: 24,
    textAlign: " center",
    marginLeft: "22%",
  },
  pageAvaNameSingleRow: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginLeft: "8%",
    marginTop: "2%",
    marginRight: "5%",
    paddingBottom: "5%",
    paddingTop: "6%",
    [theme.breakpoints.down(600)]: {},
  },
  pageTimeBottom: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginLeft: "8%",
    marginTop: "2%",
    marginRight: "5%",
    paddingBottom: "2%",
    borderBottom: "solid 1px #d4d5d8 ",
    [theme.breakpoints.down(600)]: {},
  },
  header: {
    color: theme.palette.grey[800],
    width: "65%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  bottom: {
    color: theme.palette.grey[800],
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  headerButton: {
    minWidth: 40,
    height: 24,
    width: "35%",
    textAlign: "right",
  },
  cancelButton: {
    fontWeight: theme.typography.h5.fontWeight,
    minWidth: 40,
    height: 24,
    backgroundColor: theme.palette.reservation.finished,
    borderRadius: "21.5px",
    color: theme.palette.white,
    textAlign: " center",
    paddingTop: "5px",
    textTransform: "none",
    marginLeft: 0,
    marginRight: "auto",
  },
}));

export default StaffPosition;
