import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { isObjectNotEqual } from "utils";
import { NUMBER_CLASSIFICATION } from "../GroupClassification";
import OneClassificationTable from "./OneClassificationTable";
import TwoClassificationTable from "./TwoClassificationTable";

const TableClassification = ({ isContainStockColumn, dataTable, taxTypeValue, onChange }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const [arrHeader, setArrHeader] = useState([]);
  const [data, setData] = useState(dataTable);

  useEffect(() => {
    if (dataTable) {
      if (isObjectNotEqual(data, dataTable)) {
        setData(dataTable);
      }
      let defaultHeader = [LangConstant.TXT_UNIT_PRICE, LangConstant.TXT_PRICE_AFTER_TAX];
      let dataHeader = [];
      if (isContainStockColumn) {
        defaultHeader.push(LangConstant.TXT_WARE_HOUSE);
      }

      dataHeader.push(
        Boolean(dataTable.name)
          ? dataTable.name
          : getLabel(LangConstant.FM_CLASSIFICATION_GROUP, { name: NUMBER_CLASSIFICATION.one }),
      );

      if (!Boolean(dataTable.modelList[0].item)) {
        dataHeader.push(
          dataTable.modelList[0].groupModel.name === ""
            ? getLabel(LangConstant.FM_CLASSIFICATION_GROUP, { name: NUMBER_CLASSIFICATION.two })
            : dataTable.modelList[0].groupModel.name,
        );
      }
      setArrHeader([...dataHeader, ...defaultHeader]);
    }
  }, [dataTable]);

  return Boolean(data.modelList[0].item) ? (
    <OneClassificationTable
      arrHeader={arrHeader}
      data={data}
      isContainStockColumn={isContainStockColumn}
      taxTypeValue={taxTypeValue}
      onChange={onChange}
    />
  ) : (
    <TwoClassificationTable
      arrHeader={arrHeader}
      data={data}
      isContainStockColumn={isContainStockColumn}
      taxTypeValue={taxTypeValue}
      onChange={onChange}
    />
  );
};

TableClassification.propTypes = {
  isContainStockColumn: PropTypes.bool,
  dataTable: PropTypes.object,
  taxTypeValue: PropTypes.number,
  onChange: PropTypes.func,
};

TableClassification.defaultProps = {
  isContainStockColumn: false,
  dataTable: {},
  taxTypeValue: 0,
  onChange: () => {},
};

export default memo(TableClassification);
