import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Hidden,
  ClickAwayListener,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
} from "@material-ui/core";
import { Menu, Sort, Close, Refresh } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import StringFormat from "string-format";
import { PathConstant, LangConstant, AppConstant } from "const";
import { GbossWhiteLogo } from "theme/images";
import { CONTAINER_SPACE } from "../../index";
import { AppAlert, ALERT_TYPE } from "components";
import Sidebar, { SIDEBAR_WIDTH_CLOSE } from "../Sidebar";
import ButtonGroupMobile from "./ButtonGroupMobile";
import GroupButtonWithBadge from "./GroupButtonWithBadge";
import AccountInfo from "./AccountInfo";
import PaymentHistoryDetail from "pages/PaymentHistory/components/PaymentHistoryDetail";
import PaymentAction from "redux/payment.redux";
import Cookie from "js-cookie";

const CustomAppBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("sm"));
  const role = Cookie.get(AppConstant.ROLE);

  const [checkPayment, isLimitItem, packageCurrent] = useSelector(
    ({ paymentRedux }) => [paymentRedux.checkPayment, paymentRedux.isLimitItem, paymentRedux.packageCurrent],
    shallowEqual,
  );

  const dataUser = useSelector(({ userRedux }) => userRedux.dataUser, shallowEqual);

  const [isBar, setIsBar] = useState(false);
  const [isShowButtonGroup, setIsShowButtonGroup] = useState(false);
  const [headerButtonState, setHeaderButtonState] = useState(HEADER_CONDITION_OBJ);

  const [isShowErrorLimit, setIsShowErrorLimit] = useState(false);
  const [isUnpaid, setIsUnpaid] = useState(false);
  const [unpaidPackageUid, setUnpaidPackageUid] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const onChangeSidebar = () => setIsBar(!isBar);

  const onOpenPackageDetail = () => {
    setIsUnpaid(false);
    setIsOpen(!isOpen);
  };

  const onReloadPage = () => {
    window.location.reload();
  };

  const onClosePaymentWarning = () => setIsUnpaid(false);

  const onCloseErrorLimit = () => {
    setIsShowErrorLimit(false);
    dispatch(PaymentAction.resetPayment());
  };

  let buttonGroupClass = !isShowButtonGroup && "hidden";

  useEffect(() => {
    if (!checkPayment.paymentUid && role === AppConstant.SHOP_OWNER) {
      dispatch(PaymentAction.getCheckPayment());
      if (dataUser?.id && !packageCurrent) dispatch(PaymentAction.getPackageCurrent({ userId: dataUser?.id }));
    }
  }, [role, dataUser?.id]);

  useEffect(() => {
    if (isLimitItem) {
      setIsShowErrorLimit(true);
    }
  }, [isLimitItem]);

  useEffect(() => {
    if (packageCurrent && packageCurrent.paymentStatus && checkPayment) {
      setIsUnpaid(packageCurrent.paymentStatus === AppConstant.PAYMENT_STATUS.unpaid);
      if (checkPayment.paymentUid) setUnpaidPackageUid(checkPayment.paymentUid);
    }
  }, [packageCurrent, checkPayment]);

  return (
    <>
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton onClick={onChangeSidebar}>
              <Menu style={{ color: "white" }} />
            </IconButton>
          </Hidden>
          <Link to={PathConstant.MANAGE_BOOKS}>
            <Avatar variant="square" src={GbossWhiteLogo} alt="gboss logo" className={classes.logo} />
          </Link>
          <Button
            onClick={onReloadPage}
            classes={{ root: classes.buttonRefresh, startIcon: classes.startIcon }}
            startIcon={<Refresh />}
          />
          <Box flexGrow={1} hidden={isNotDesktop} />
          <Box className={isNotDesktop ? "hidden" : classes.buttonGroup}>
            <GroupButtonWithBadge headerButtonState={headerButtonState} setHeaderButtonState={setHeaderButtonState} />
            <AccountInfo isShowPopup={headerButtonState.isAccountInfo} setHeaderButtonState={setHeaderButtonState} />
          </Box>
          <IconButton className={isNotDesktop ? "" : "hidden"} onClick={() => setIsShowButtonGroup(!isShowButtonGroup)}>
            <Sort className={classes.sortIcon} />
          </IconButton>
        </Toolbar>
        {isNotDesktop && (
          <ButtonGroupMobile
            classes={{
              root: buttonGroupClass,
            }}
          />
        )}
      </AppBar>
      <Hidden lgUp>
        {isBar && (
          <ClickAwayListener onClickAway={onChangeSidebar}>
            <Sidebar isTop={true} />
          </ClickAwayListener>
        )}
      </Hidden>
      <AppAlert
        isOpen={isUnpaid}
        onClose={onClosePaymentWarning}
        classesSnackbar={{ anchorOriginTopCenter: classes.anchorOrigin }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={
          <IconButton onClick={onClosePaymentWarning} className={classes.button}>
            <Close className={classes.iconClose} />
          </IconButton>
        }
        severity={ALERT_TYPE.warning}
        classes={{ root: classes.rootAlert, action: classes.action, icon: classes.icon, message: classes.message }}
      >
        <Box className="center-root" height="100%">
          <Typography variant="body2" component="p" className={classes.contentExpire}>
            {getLabel(
              checkPayment.type == AppConstant.PAYMENT_FOR.package
                ? LangConstant.TXT_PACKAGE_PAYMENT_NOTICE
                : LangConstant.TXT_FEATURES_PAYMENT_NOTICE,
            )}
            <Typography onClick={onOpenPackageDetail} className={classes.paymentText}>
              {getLabel(LangConstant.TXT_PAYMENT)}
            </Typography>
            {StringFormat(getLabel(LangConstant.FM_EXPIRED_PAYMENT_USING_FEATURES))}
          </Typography>
        </Box>
      </AppAlert>
      <AppAlert isOpen={isShowErrorLimit} duration={3000} onClose={onCloseErrorLimit} severity={ALERT_TYPE.error}>
        {getLabel(LangConstant.MSG_LIMIT_CREATE_ITEM)}
      </AppAlert>

      {isOpen && <PaymentHistoryDetail isOpen={isOpen} onClose={() => setIsOpen(false)} uid={unpaidPackageUid} />}
    </>
  );
};

export const HEADER_CONDITION_OBJ = {
  isWarning: false,
  isBooking: false,
  isOrder: false,
  isAccountInfo: false,
  isNotification: false,
};

CustomAppBar.propTypes = {};

CustomAppBar.defaultProps = {};

export const HEIGHT_APP_BAR = 60;
export const SMALL_PADDING_LEFT = 20;
export const SMALL_PADDING_RIGHT = 16;

export default memo(CustomAppBar);

const useStyles = makeStyles(theme => ({
  toolbar: {
    height: HEIGHT_APP_BAR,
    minHeight: HEIGHT_APP_BAR,
    [theme.breakpoints.up("md")]: {
      paddingLeft: SIDEBAR_WIDTH_CLOSE + CONTAINER_SPACE,
      paddingRight: SIDEBAR_WIDTH_CLOSE + CONTAINER_SPACE,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: SMALL_PADDING_LEFT,
      paddingRight: SMALL_PADDING_RIGHT,
      justifyContent: "space-between",
    },
  },
  logo: {
    width: "100px",
    height: "max-content",
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
  },
  sortIcon: {
    color: theme.palette.white,
  },
  startIcon: {
    color: theme.palette.white,
  },
  buttonRefresh: {
    maxWidth: 24,
    maxHeight: 24,
    marginTop: 5,
  },
  contentExpire: {
    display: "flex",
    flexDirection: "row",
    fontSize: 13,
    lineHeight: 1.69,
    color: "#3e4045",
  },
  linkExpire: {
    marginLeft: 4,
    fontSize: 13,
    lineHeight: 1.69,
    color: theme.palette.text.link,
  },
  anchorOrigin: {
    [theme.breakpoints.up("sm")]: {
      top: 84,
    },
  },
  boxItem: {
    width: "100%",
    minHeight: 40,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  rootAlert: {
    padding: 7,
    minHeight: 40,
    minWidth: "80vw",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 0.5px #feba40",
    backgroundColor: "#fef6d8",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    padding: 0,
    marginRight: 8,
    display: "flex",
  },
  action: {
    marginRight: 0,
    paddingTop: 2,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    lineHeight: "22px",
    padding: 0,
  },
  paymentText: {
    margin: "0 4px",
    fontSize: 13,
    lineHeight: 1.69,
    color: theme.palette.text.link,
    textTransform: "lowercase",
    cursor: "pointer",
  },
}));
