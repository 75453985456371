import React, { memo, useState, createRef, useEffect } from "react";
import { PathConstant, LangConstant } from "../../../const";
import { Button, Box, Typography, FormControlLabel, Checkbox, Link as MuiLink } from "@material-ui/core";
import { PersonOutlineRounded } from "@material-ui/icons/";
import { useTranslation } from "react-i18next";
import { validatePassword, isSttException } from "../../../utils";
import { FormError, FormInput, InputPassword, Processing } from "../../../components";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthAction from "../../../redux/auth.redux";
import * as AppConstant from "../../../const/app.const";
import SocialLogin from "../../../components/social/SocialLogin";

const LoginForm = () => {
  const { t: getLabel } = useTranslation();
  const submitRef = createRef();
  const dispatch = useDispatch();
  const { isFetching, error: errorResponse, status: sttResponse } = useSelector(({ authRedux }) => authRedux);

  const [isSaved, setIsSaved] = useState(!!localStorage.getItem("username"));
  const [errContent, setErrContent] = useState([]);
  const [data, setData] = useState({
    username: localStorage.getItem("username") ? localStorage.getItem("username") : "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const onChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  let location = useLocation();

  const onSubmit = event => {
    const { password } = data;
    let waring = [];

    if (password && !Boolean(validatePassword(password))) {
      waring.push(getLabel(LangConstant.ERR_PASSWORD));
    }

    if (waring.length > 0) {
      // Set warning to display when valid form
      setErrContent(waring);
    } else {
      let sendData = data;
      let searchParams = new URLSearchParams(location.search);
      if (
        searchParams &&
        searchParams.get(AppConstant.KEY_SHOP_INVITATION) &&
        searchParams.get(AppConstant.KEY_SHOP_INVITATION) !== ""
      ) {
        sendData = { ...data, invitation: searchParams.get(AppConstant.KEY_SHOP_INVITATION) };
      }
      dispatch(AuthAction.requestLogin(sendData));
      if (isSaved) {
        localStorage.setItem("username", data.username);
      } else {
        localStorage.removeItem("username");
      }
      setErrContent([]);
      setIsSubmit(true);
    }
    event.preventDefault();
  };

  // Call Api Error
  useEffect(() => {
    if (!isSubmit) return;
    let isError = !isFetching && errorResponse;
    if (isError) {
      setErrContent([getLabel(isSttException(sttResponse) ? LangConstant.ERR_EXCEPTION : LangConstant.ERR_LOGIN)]);
    }
  }, [isFetching, errorResponse]);

  // After complete calling api, update isSubmit is false
  useEffect(() => {
    if (!isFetching && isSubmit) {
      setIsSubmit(false);
    }
  }, [isFetching]);

  // After show error, rest error in redux
  useEffect(() => {
    if (errContent && errContent.length > 0) {
      dispatch(AuthAction.authReset());
    }
  }, [errContent]);

  useEffect(() => {
    if (isSaved) {
      localStorage.setItem("username", data.username);
    } else {
      localStorage.removeItem("username");
    }
  }, [isSaved]);

  return (
    <form onSubmit={onSubmit} className="login-form-root">
      <Box className="auth_input-box">
        <FormInput
          name="username"
          icon={<PersonOutlineRounded />}
          placeholder={getLabel(LangConstant.P_USERNAME)}
          onChange={onChange}
          value={localStorage.getItem("username") ? localStorage.getItem("username") : ""}
          required
        />
        <InputPassword
          name="password"
          placeholder={getLabel(LangConstant.P_PASSWORD)}
          onChange={onChange}
          required
          autoComplete="current-password"
        />
      </Box>
      <Box className="forgot-root">
        <FormControlLabel
          control={
            <Checkbox
              checked={isSaved}
              onChange={event => setIsSaved(event.target.checked)}
              name="isSaved"
              color="primary"
            />
          }
          label={
            <Typography variant="body2" classes={{ body2: "regular-sm-txt" }} component="p">
              {getLabel(LangConstant.TXT_SAVE_INFO)}
            </Typography>
          }
        />
        <Link to={PathConstant.FORGOT_PASSWORD}>
          <MuiLink component="span" className="regular-sm-txt">
            {getLabel(LangConstant.TXT_FORGOT_PASSWORD)}
          </MuiLink>
        </Link>
      </Box>

      <Box className="center-root submit-root">
        {errContent.length > 0 &&
          errContent.map((error, index) => <FormError className="center-root" error={error} key={index} />)}
        <Button color="primary" variant="contained" size="large" onClick={() => submitRef.current.click()}>
          {getLabel(LangConstant.TXT_LOGIN)}
        </Button>
        <input type="submit" ref={submitRef} style={{ display: "none" }} />
      </Box>
      <SocialLogin />
      {isFetching && <Processing isShow={true} />}
    </form>
  );
};

LoginForm.propTypes = {};
LoginForm.defaultProps = {};

export default memo(LoginForm);
