import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import {
  CategorySubcategory,
  DialogAddCategoryItem,
  DisplayModeBar,
  IconButtonMProductItem,
} from "components/mn-master-product";
import { getCommonKey, textNormalize } from "utils";
import { DialogAddCategory, DialogDelete } from "components/dialog";
import ServiceCategoryAction from "redux/service-category.redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DELETE_ACTION, EDIT_ACTION } from "components/mn-master-product/CategorySubcategory";
import { DialogAddToCategory, DialogCreateMainService } from "../main-service";

const ServiceSubcategoryList = ({
  parentData,
  onJumpToChild,
  onJumpToMain,
  onJumpToCategoryItemList,
  ...otherProps
}) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataCategory = useSelector(state => state.serviceCategoryRedux?.data, shallowEqual);
  const errorCreateServiceCategory = useSelector(state => state.serviceCategoryRedux.errorCreateServiceCategory);
  const errorUpdateServiceCategory = useSelector(state => state.serviceCategoryRedux.errorUpdateServiceCategory);
  const isCreateSuccess = useSelector(state => state.serviceCategoryRedux.isCreateSuccess, shallowEqual);
  const isCreateFetching = useSelector(state => state.serviceCategoryRedux.isCreateFetching, shallowEqual);
  const isUpdateSuccess = useSelector(state => state.serviceCategoryRedux.isUpdateSuccess, shallowEqual);
  const isUpdateFetching = useSelector(state => state.serviceCategoryRedux.isUpdateFetching, shallowEqual);
  const isRemoveSuccess = useSelector(state => state.serviceCategoryRedux.isRemoveSuccess, shallowEqual);
  const isRemoveFetching = useSelector(state => state.serviceCategoryRedux.isRemoveFetching, shallowEqual);

  const dataCategoryServiceItem = useSelector(state => state.serviceItemRedux.dataCategoryServiceItem);
  const isPostServiceItemSuccess = useSelector(state => state.serviceItemRedux.isPostServiceItemSuccess, shallowEqual);
  const isPutServiceItemSuccess = useSelector(state => state.serviceItemRedux.isPutServiceItemSuccess, shallowEqual);

  const [isOpen, setIsOpen] = useState({
    addServiceType: false,
    addService: false,
    createService: false,
    create: false,
    delete: false,
    edit: false,
    targetUid: null,
  });
  const [targetCategory, setTargetCategory] = useState({});
  const [showData, setShowData] = useState(parentData?.child);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const onOpenCreateCategory = () => {
    setIsOpen({ ...isOpen, create: true });
  };

  const onClose = () => {
    setIsDuplicate(false);
    setIsOpen({
      ...isOpen,
      addServiceType: false,
      addService: false,
      createService: false,
      create: false,
      delete: false,
      edit: false,
      targetUid: null,
    });
    setTargetCategory({});
    if (dataCategoryServiceItem && dataCategoryServiceItem?.length > 0) {
      onJumpToCategoryItemList(parentData);
    }
  };

  const onChangeCategoryData = value => {
    setTargetCategory(newData => ({ ...newData, name: value }));
  };

  const onBackAddServiceType = () => {
    setIsOpen({ ...isOpen, addServiceType: true, addService: false });
  };

  const onOpenAddServiceType = () => {
    setIsOpen({ ...isOpen, addServiceType: true });
  };

  const onOpenAddService = () => {
    setIsOpen({ ...isOpen, addService: true });
  };

  const onOpenCreateService = () => {
    setIsOpen({ ...isOpen, createService: true });
  };

  const onCreateCategory = () => {
    if (!isCreateFetching) {
      dispatch(ServiceCategoryAction.createServiceCategory({ name: targetCategory.name, parentUid: parentData.uid }));
    }
  };

  const onUpdateCategory = () => {
    if (!isUpdateFetching) {
      dispatch(ServiceCategoryAction.updateServiceCategory({ ...targetCategory, parentUid: parentData.uid }));
    }
  };

  const onDeleteCategory = () => {
    if (!isRemoveFetching) {
      dispatch(ServiceCategoryAction.removeServiceCategory({ uid: targetCategory.uid }));
    }
  };

  const onEditAndDelete = (uid, action) => {
    const targetData = showData.find(dataMap => dataMap.uid === uid);
    setTargetCategory(targetData);
    switch (action) {
      case EDIT_ACTION:
        setIsOpen({ ...isOpen, edit: true });
        break;
      case DELETE_ACTION:
        setIsOpen({ ...isOpen, delete: true });
        break;
    }
  };

  const onSearch = newSearchValue => {
    setShowData(doSearch(parentData?.uid?.child, newSearchValue));
  };

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess || isRemoveSuccess) {
      onClose();
    }
  }, [isCreateSuccess, isUpdateSuccess, isRemoveSuccess]);

  useEffect(() => {
    if (dataCategory) {
      setShowData(dataCategory[parentData?.uid]?.child);
    }
  }, [dataCategory]);

  useEffect(() => {
    if (isPostServiceItemSuccess || isPutServiceItemSuccess) {
      if (isPostServiceItemSuccess) {
        onClose();
      }
    }
  }, [isPostServiceItemSuccess, isPutServiceItemSuccess]);

  useEffect(() => {
    if (errorCreateServiceCategory) {
      if (errorCreateServiceCategory?.name[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dispatch(ServiceCategoryAction.resetErrorServiceCategory());
      }
    }
    if (errorUpdateServiceCategory) {
      if (errorUpdateServiceCategory?.name[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dispatch(ServiceCategoryAction.resetErrorServiceCategory());
      }
    }
  }, [errorCreateServiceCategory, errorUpdateServiceCategory]);

  return (
    <>
      <DisplayModeBar
        labelBtn={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
          LangConstant.TXT_CATEGORY_LOWER_CASE,
        )}`}
        onAddItem={onOpenCreateCategory}
        onSearch={onSearch}
        showSearch={parentData?.child && parentData?.child?.length > 0}
        {...otherProps}
      />
      <Box display="flex" margin="24px 0" paddingLeft="24px">
        <Box className={classes.categorySourceBox} onClick={e => onJumpToMain(e)}>
          <Typography color="inherit" className="medium-md-txt">
            {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)}
          </Typography>
        </Box>
        &nbsp; / &nbsp;
        <Box className={classes.currentSourceBox}>
          <Typography color="inherit" className="medium-md-txt">
            {parentData?.name}
          </Typography>
        </Box>
      </Box>
      {showData && showData.length > 0 ? (
        <Box className={classes.contentBox}>
          <Grid container spacing={3} className={classes.listItem}>
            {showData.map(dataMap => (
              <Grid item xs={12} sm={4} md={3} key={dataMap.uid}>
                <CategorySubcategory
                  uid={dataMap.uid}
                  text={dataMap.name}
                  onClick={e => onJumpToChild(e, dataMap)}
                  onContextMenu={onEditAndDelete}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box className={classes.noContentBox}>
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
              LangConstant.TXT_CATEGORY_LOWER_CASE,
            )}`}
            onClick={onOpenCreateCategory}
            buttonClasses={{ root: classes.createCategoryButton }}
          />
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_ADD))} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`}
            onClick={onOpenAddServiceType}
            buttonClasses={{ root: classes.addServiceButton }}
          />
        </Box>
      )}

      {isOpen.create && (
        <DialogAddCategory
          isShow={isOpen.create}
          onClose={onClose}
          onSubmit={onCreateCategory}
          onChange={onChangeCategoryData}
          isWarning={isDuplicate}
          nameWaring={isDuplicate ? getLabel(LangConstant.TXT_WARNING_CATEGORY) : ""}
        />
      )}
      {isOpen.edit && (
        <DialogAddCategory
          isShow={isOpen.edit}
          onClose={onClose}
          onSubmit={onUpdateCategory}
          onChange={onChangeCategoryData}
          data={targetCategory}
          isWarning={isDuplicate}
          nameWaring={isDuplicate ? getLabel(LangConstant.TXT_WARNING_CATEGORY) : ""}
        />
      )}
      {isOpen.delete && (
        <DialogDelete
          uid={targetCategory.uid}
          isShow={isOpen.delete}
          onCancel={onClose}
          onConfirm={onDeleteCategory}
          title={getLabel(LangConstant.FN_DELETE_CATEGORY_SERVICE_TITLE)(targetCategory.name)}
          content={getLabel(LangConstant.FN_DELETE_CATEGORY_SERVICE_CONTENT)(targetCategory.name)}
        />
      )}
      {isOpen.addServiceType && (
        <DialogAddCategoryItem
          isShow={isOpen.addServiceType}
          titleName={{
            header: getLabel(LangConstant.TXT_CHOOSE_TYPE_ADD_SERVICE),
            add: getLabel(LangConstant.TXT_ADD_FROM_ALL_CATEGORY_SERVICE),
            created: `${getLabel(LangConstant.TXT_CREATE_NEW)} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`,
          }}
          action={onClose}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddFromMainService={onOpenAddService}
          onCreateAccompaniedService={onOpenCreateService}
        />
      )}
      {isOpen.addService && (
        <DialogAddToCategory
          categoryData={parentData}
          isOpen={isOpen.addService}
          onClose={onClose}
          onBack={onBackAddServiceType}
        />
      )}
      {isOpen.createService && (
        <DialogCreateMainService isOpen={isOpen.createService} onClose={onClose} categoryUid={[parentData?.uid]} />
      )}
    </>
  );
};

const doSearch = (iniData, searchValue) => {
  if (searchValue == "" || !searchValue) {
    return iniData;
  } else {
    let filterList = {};
    Object.keys(iniData).map(key => {
      if (textNormalize(iniData[key]?.name).includes(textNormalize(searchValue))) {
        filterList[key] = iniData[key];
      }
    });
    return filterList;
  }
};

const useStyles = makeStyles(theme => ({
  categorySourceBox: { color: "#47494e", cursor: "pointer", "&:hover": { color: "#4d85ff" } },
  currentSourceBox: { color: "#4d85ff" },
  contentBox: {
    width: "100%",
    minHeight: "720px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
      overflowX: "auto",
    },
  },
  noContentBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "720px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
      overflowX: "auto",
    },
  },
  createCategoryButton: {
    width: 162,
    minHeight: 40,
    paddingLeft: 20,
    paddingRight: 24,
    margin: "auto auto 8px auto",
  },
  addServiceButton: { width: 162, minHeight: 40, paddingLeft: 20, paddingRight: 24, margin: "0 auto auto auto" },
  dialogAddTypo: {
    color: "#3e4045",
    lineHeight: 1.5,
    fontWeight: "bold",
  },
}));

ServiceSubcategoryList.propTypes = {
  parentData: PropTypes.object,
  onJumpToChild: PropTypes.func,
  onJumpToMain: PropTypes.func,
  onJumpToCategoryItemList: PropTypes.func,
};

ServiceSubcategoryList.defaultProps = {
  parentData: {},
  onJumpToChild: () => {},
  onJumpToMain: () => {},
  onJumpToCategoryItemList: () => {},
};

export default memo(ServiceSubcategoryList);
