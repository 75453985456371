import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { EmptyNotiImg } from "theme/images";

const EmptyNotice = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={EmptyNotiImg} alt="emptyNoti" />
      <Typography variant="body2" className={classes.message}>
        {message}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "300px",
    textAlign: "center",
    padding: "45px 0",
  },
  image: {
    width: "85%",
    height: "85%",
  },
  message: {
    marginTop: 15,
    color: theme.palette.grey[500],
  },
}));

EmptyNotice.propTypes = { message: PropTypes.string };
EmptyNotice.defaultProps = { message: "" };

export default memo(EmptyNotice);
