import React, { memo, useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  OutlinedInput,
  Button,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import UserAction from "redux/user.redux";
import { Alert } from "./dialog";
const ChangePassWord = () => {
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  const isPassUpdateSuccess = useSelector(state => state.userRedux.isPassUpdateSuccess);
  const [showPassState, setPassState] = useState({ password: false, newPassword: false, confirmPassword: false });
  const [inputState, setInputState] = useState({ password: "", newPassword: "", confirmPassword: "" });
  const [isAlert, setIsAlert] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const onShowPassWord = event => {
    const name = event.currentTarget.name;
    setPassState({
      ...showPassState,
      [name]: event.currentTarget.value === "true",
    });
  };
  const onChangePassWord = event => {
    const name = event.currentTarget.name;
    setInputState({
      ...inputState,
      [name]: event.currentTarget.value,
    });
    setIsEdited(true);
  };

  const onSubmit = () => {
    dispatch(UserAction.requestUpdateUserPassword({ ...inputState }));
    setInputState({ password: "", newPassword: "", confirmPassword: "" });
    setIsEdited(false);
  };

  useEffect(() => {
    if (isPassUpdateSuccess) {
      setIsAlert(true);
      setIsEdited(false);
      dispatch(UserAction.resetUserUpdate());
    }
  }, [isPassUpdateSuccess]);

  return (
    <>
      <Grid className="account-info-detail">
        <Grid container direction="column" alignItems="center" className="root">
          <Grid item xs={12} className="titleBox">
            <Typography className="title" variant="h4">
              {getLabel(LangConstant.TXT_ACCOUNT_SETTING)}
            </Typography>
          </Grid>
          <Grid item xs={12} container direction="column" className="main" spacing={4}>
            <Grid item xs={12} container direction="column" className="head" spacing={4}></Grid>
            <Grid item xs={12} container className="info" spacing={4}>
              <Grid item xs={12}>
                <Grid item xs container direction="column" spacing={1}>
                  <Grid item>
                    <Typography className="semiBold-xl-txt"> {getLabel(LangConstant.TXT_PASS_WORD)}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="warning medium-md-txt">{getLabel(LangConstant.TXT_WARNING_PASS)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container className="info" spacing={4}>
              <Grid item xs={12}>
                <Grid item xs container direction="column" spacing={1}>
                  <Grid item>
                    <Typography className="semiBold-md-txt"> {getLabel(LangConstant.TXT_OLD_PASS)}</Typography>
                  </Grid>
                  <Grid item container direction="row" justify="flex-end" alignItems="center">
                    <OutlinedInput
                      className="input"
                      name={AppConstant.KEY_OLD}
                      value={inputState[AppConstant.KEY_OLD]}
                      type={showPassState[AppConstant.KEY_OLD] ? "text" : "password"}
                      onChange={onChangePassWord}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={event => onShowPassWord(event)}
                            name={AppConstant.KEY_OLD}
                            value={!showPassState[AppConstant.KEY_OLD]}
                          >
                            {!showPassState[AppConstant.KEY_OLD] ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <Typography className="semiBold-md-txt"> {getLabel(LangConstant.TXT_NEW_PASS)}</Typography>
                  </Grid>
                  <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center">
                    <OutlinedInput
                      className="input"
                      name={AppConstant.KEY_NEW}
                      value={inputState[AppConstant.KEY_NEW]}
                      type={showPassState[AppConstant.KEY_NEW] ? "text" : "password"}
                      onChange={onChangePassWord}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={event => onShowPassWord(event)}
                            name={AppConstant.KEY_NEW}
                            value={!showPassState[AppConstant.KEY_NEW]}
                          >
                            {!showPassState[AppConstant.KEY_NEW] ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                    {inputState[AppConstant.KEY_NEW] !== "" && inputState[AppConstant.KEY_NEW].length < 8 && (
                      <FormControl error>
                        <FormHelperText>{getLabel(LangConstant.ERR_PASSWORD)}</FormHelperText>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <Typography className="semiBold-md-txt">{getLabel(LangConstant.TXT_CONFIRM_PASS)}</Typography>
                  </Grid>
                  <Grid item xs={12} container direction="row" justify="flex-end" alignItems="center">
                    <OutlinedInput
                      className="input"
                      name={AppConstant.KEY_CONFIRM}
                      value={inputState[AppConstant.KEY_CONFIRM]}
                      type={showPassState[AppConstant.KEY_CONFIRM] ? "text" : "password"}
                      onChange={onChangePassWord}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={event => onShowPassWord(event)}
                            name={AppConstant.KEY_CONFIRM}
                            value={!showPassState[AppConstant.KEY_CONFIRM]}
                          >
                            {!showPassState[AppConstant.KEY_CONFIRM] ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    ></OutlinedInput>
                    {inputState[AppConstant.KEY_NEW] !== inputState[AppConstant.KEY_CONFIRM] && (
                      <FormControl error>
                        <FormHelperText>{getLabel(LangConstant.ERR_PASSWORD_DIFF)}</FormHelperText>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <Grid item container direction="row" justify="center">
                      {isEdited && (
                        <Button
                          color="primary"
                          className="buttonUpdate"
                          variant="contained"
                          disabled={
                            inputState[AppConstant.KEY_NEW] !== inputState[AppConstant.KEY_CONFIRM] ||
                            inputState[AppConstant.KEY_NEW].length < 8
                          }
                          onClick={onSubmit}
                        >
                          <Typography variant="body1" classes={{ body1: `semiBold-md-txt  typoAccept` }}>
                            {getLabel(LangConstant.TXT_UPDATE)}
                          </Typography>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isAlert && (
        <Alert
          isShow={true}
          onClose={() => setIsAlert(false)}
          type="success"
          message={getLabel(LangConstant.MSG_UPDATE_SUCCESS)}
        />
      )}
    </>
  );
};

ChangePassWord.propTypes = {};
ChangePassWord.defaultProps = {};

export default memo(ChangePassWord);
