import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, OutlinedInput, Popover, Box, Typography } from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import clsx from "clsx";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format as DateFormat } from "date-fns";
import { getDateLang } from "utils/date";
import { AppConstant } from "const";

const DatePickerWithLabelAndIcon = ({ onChange, selectedDateProp, label, ...otherProps }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(selectedDateProp);
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  const onDateChange = date => {
    setSelectedDate(date);
    onChange(date);
    setCalendarAnchor(null);
  };

  const onClick = event => {
    event.preventDefault();
    setCalendarAnchor(event.currentTarget);
  };

  useEffect(() => {
    setSelectedDate(selectedDateProp);
  }, [selectedDateProp]);

  return (
    <>
      <Box className={classes.boxRoot}>
        {label && <Typography className={clsx(classes.datePickerLabel, "medium-md-txt")}>{label}</Typography>}
        <OutlinedInput
          onClick={onClick}
          startAdornment={<DateRange className={classes.adornedStart} />}
          readOnly
          classes={{
            root: clsx(classes.inputRoot, "medium-md-txt"),
            input: classes.inputField,
          }}
          value={DateFormat(selectedDate, AppConstant.FM_DD_MM_YYYY)}
        />
      </Box>
      <Popover
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(calendarAnchor)}
        onClose={() => setCalendarAnchor(null)}
        classes={{ paper: classes.datepickerPaper }}
      >
        <Box className="datepicker-root">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateLang(i18n.language)}>
            <DatePicker
              disableToolbar
              variant="static"
              format={AppConstant.FM_DD_MM_YYYY}
              value={selectedDate}
              onChange={onDateChange}
              className={clsx(classes.datePickerRoot, "datepicker-root")}
              {...otherProps}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Popover>
    </>
  );
};

DatePickerWithLabelAndIcon.propTypes = {
  label: PropTypes.string,

  selectedDateProp: PropTypes.objectOf(Date),

  onChange: PropTypes.func,
};

DatePickerWithLabelAndIcon.defaultProps = {
  label: "",

  selectedDateProp: new Date(),

  onChange: () => {},
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    width: 137,
    height: 30,
    color: theme.palette.text.primary,
    cursor: "pointer",
  },

  datepickerPaper: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },

  datePickerRoot: {
    pointerEvents: `none`,
  },

  boxRoot: {
    display: "flex",
    alignItems: "center",
    marginRight: 13,
    width: "max-content",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      justifyContent: "space-between",
    },
  },

  inputField: {
    cursor: "pointer",
    paddingRight: 0,
  },

  adornedStart: {
    paddingRight: 0,
    marginRight: 8,
  },

  datePickerLabel: {
    marginRight: 9,
  },
}));

export default memo(DatePickerWithLabelAndIcon);
