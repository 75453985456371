export default {
  TXT_BTN_IMPORT: "Import",
  TXT_INVITE_ALL_BY_PHONE_NUMBER: "Mời mọi người qua số điện thoại",
  TXT_INVITE_ALL_BY_EMAIL: "Mời mọi người qua email",
  TXT_INVITE_BY_PHONE_NUMBER: "Mời qua số điện thoại",
  TXT_INVITE_BY_EMAIL: "Mời qua email",
  TXT_BTN_CANCEL: "Hủy",
  TXT_INVITE_TITLE: "Xác nhận mời",
  TXT_INVITE_OPTION_DESCRIPTION:
    "Bạn có chắc chắn muốn mời người này trở thành khách hàng của bạn theo phương thức nào?",
  TXT_INVITE_ALL_OPTION_DESCRIPTION:
    "Bạn muốn mời tất cả những người này trở thành khách hàng của bạn theo phương thức nào?",

  TXT_EXCEL_IMPORT_TITLE: "Thêm từ file excel",
  TXT_SELECT_FILE: "Chọn file",
  TXT_NO_FILE_SELECTED: "Không có file nào được chọn",
  TXT_SAMPLE_FILE_DOWNLOAD: "Tải file mẫu",
  TXT_BTN_CONTINUE: "Tiếp tục",
  TXT_WRONG_FORMAT_FILE_UPLOAD: "Vui lòng chỉ chọn định dạng file Excel",
  TXT_INVITE_CONSUMER: "Gửi lời mời tham gia Gboss qua",
  TXT_CONSUMER_LIST_INCLUDE_ERROR: "Danh sách khách hàng lỗi",
  TXT_INVITE_BY_PHONE_NUMBER_FREE_TRIAl: "Số điện thoại (dùng thử)",
  TXT_INVITE_BY_EMAIL_: "Email",

  P_INVITATION_METHOD: "Phương thức liên hệ",

  FN_ERROR_CONSUMER_NOTICE: "Có {0}/{1} Khách hàng lỗi,  nhấn Tiếp tục nếu bỏ qua",
  FN_DELETE_SUCCESS_NOTICE: "Đã xóa {0} khách hàng lỗi",
  FN_UPLOADED_CONSUMER_LIST: "Đã tải lên danh sách gồm {0} khách hàng",
};
