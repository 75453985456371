import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppConstant, PathConstant } from "const";
import PropTypes from "prop-types";
import Cookie from "js-cookie";

import {
  MnReservation,
  MnConsumers,
  MnStaff,
  MnReportBooking,
  MnReportConsumer,
  MnReportStaff,
  MnShopInfo,
  MnActivityHistory,
  StaffSetting,
  AccountInfo,
  MnBooking,
  MnNews,
  MnReportDashboard,
  BookingHistory,
  MnService,
  MnProduct,
  MnCombo,
  MnPlace,
  CalendarSetup,
  MenuSetup,
  PaymentSetup,
  MnOrder,
  Subscription,
  UpgradePlan,
  PaymentHistory,
  MnReview,
  VATManagement,
  NotificationSetting,
} from "pages";

import { removeAllCookie, getFeatureConditions } from "utils";

function AuthenticationRoute(props) {
  const { component: Component, ...rest } = props;
  // Check authentication with the page need to be protected
  const appData = Cookie.get(AppConstant.KEY_GBOSS_LOGIN);
  let role = AppConstant.SHOP_STAFF;
  const roleToken = Cookie.get(AppConstant.ROLE);
  let token = null;
  let roleStaff = {
    [AppConstant.SHOP_MANAGER]: [
      PathConstant.MANAGE_BOOKS,
      PathConstant.MANAGE_NEWS,
      PathConstant.BOOKING_HISTORY,
      PathConstant.MANAGE_ACTIVITY_HISTORY,
      PathConstant.SELECT_SHOP,
      PathConstant.ACCOUNT,
      PathConstant.ACCOUNT_PASS,
      PathConstant.MANAGE_SERVICE,
      PathConstant.MANAGE_PRODUCT,
      PathConstant.MANAGE_COMBO,
      PathConstant.MANAGE_PLACE,
      PathConstant.CALENDAR_SETUP,
      PathConstant.MENU_SETUP,
      PathConstant.PAYMENT_SETUP,
      PathConstant.MANAGE_ORDER,
      PathConstant.MN_REVIEW,
      PathConstant.VAT_MANAGEMENT,
      PathConstant.NOTIFICATION_SETTING,
    ],
    [AppConstant.SHOP_STAFF]: [
      PathConstant.MANAGE_BOOKS,
      PathConstant.MANAGE_NEWS,
      PathConstant.SELECT_SHOP,
      PathConstant.ACCOUNT,
      PathConstant.ACCOUNT_PASS,
      PathConstant.MAINTENANCE_PAGE,
      PathConstant.MANAGE_ORDER,
    ],
  };

  try {
    let shopToken = null;
    const appDataLogin = Cookie.get(AppConstant.KEY_GBOSS);
    if (appDataLogin) shopToken = JSON.parse(appDataLogin).token;
    if (appData) token = JSON.parse(appData).responseData;
    if (roleToken && roleToken !== 0) {
      role = roleToken;
    } else if (shopToken && !roleToken) {
      removeAllCookie(Cookie);
    }
  } catch (error) {
    console.log(error);
  }
  return Boolean(token) ? (
    role <= AppConstant.SHOP_OWNER || roleStaff[role].includes(rest.path) ? (
      <Route {...rest} render={matchProps => <Component {...matchProps} />} />
    ) : (
      redirectPath(PathConstant.MANAGE_BOOKS, rest)
    )
  ) : (
    redirectPath(PathConstant.LOGIN, rest)
  );
}

function redirectPath(path, rest) {
  return (
    <Redirect
      to={{
        pathname: path,
        search: rest.location.search,
        state: {
          from: rest.path,
        },
      }}
    />
  );
}

AuthenticationRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

export { AuthenticationRoute };

const Routes = () => {
  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { reservationSettingIsValid, isPremium, isOnlyBooking, isOnlyOrdering } =
    getFeatureConditions(reservationSetting);

  let isBookingFeature = Boolean(isOnlyBooking || isPremium);
  let isOrderFeature = Boolean(isOnlyOrdering || isPremium);

  return (
    reservationSettingIsValid && (
      <Switch>
        <AuthenticationRoute component={AccountInfo} exact path={PathConstant.ACCOUNT} />
        <AuthenticationRoute component={MnReportConsumer} exact path={PathConstant.MANAGE_REPORT_CONSUMERS} />
        <AuthenticationRoute component={AccountInfo} exact path={PathConstant.ACCOUNT_PASS} />
        <AuthenticationRoute component={MnConsumers} exact path={PathConstant.MANAGE_CONSUMER} />
        <AuthenticationRoute component={MnStaff} exact path={PathConstant.MANAGE_STAFF} />
        <AuthenticationRoute component={MnReportStaff} exact path={PathConstant.MANAGE_REPORT_STAFF} />
        <AuthenticationRoute component={MnShopInfo} exact path={PathConstant.MANAGE_SHOP_INFO} />
        <AuthenticationRoute component={MnActivityHistory} exact path={PathConstant.MANAGE_ACTIVITY_HISTORY} />
        <AuthenticationRoute component={MnNews} exact path={PathConstant.MANAGE_NEWS} />
        <AuthenticationRoute component={StaffSetting} exact path={PathConstant.STAFF_SETTING} />
        <AuthenticationRoute component={MnReview} exact path={PathConstant.MN_REVIEW} />
        <AuthenticationRoute component={NotificationSetting} exact path={PathConstant.NOTIFICATION_SETTING} />

        {/* Plan & Package */}
        <AuthenticationRoute component={Subscription} exact path={PathConstant.SUBSCRIPTION} />
        <AuthenticationRoute component={UpgradePlan} exact path={PathConstant.UPGRADE_PLAN} />
        <AuthenticationRoute component={PaymentHistory} exact path={PathConstant.PAYMENT_HISTORY} />

        <AuthenticationRoute component={VATManagement} exact path={PathConstant.VAT_MANAGEMENT} />

        {/* Check type package: Shop use Booking or Premium */}
        {isBookingFeature && <AuthenticationRoute component={MnReservation} exact path={PathConstant.MANAGE_BOOKS} />}
        {isBookingFeature && <AuthenticationRoute component={MnPlace} exact path={PathConstant.MANAGE_PLACE} />}
        {isBookingFeature && (
          <AuthenticationRoute component={BookingHistory} exact path={PathConstant.BOOKING_HISTORY} />
        )}
        {isBookingFeature && (
          <AuthenticationRoute component={MnReportDashboard} exact path={PathConstant.MANAGE_REPORT_DASHBOARD} />
        )}
        {isBookingFeature && <AuthenticationRoute component={MnBooking} exact path={PathConstant.MANAGE_BOOKING} />}
        {isBookingFeature && <AuthenticationRoute component={CalendarSetup} exact path={PathConstant.CALENDAR_SETUP} />}
        {isBookingFeature && (
          <AuthenticationRoute component={MnReportBooking} exact path={PathConstant.MANAGE_REPORT_BOOKING} />
        )}

        {/* Check type package: Shop use Ordering or Premium */}
        {isOrderFeature && <AuthenticationRoute component={MnOrder} exact path={PathConstant.MANAGE_ORDER} />}
        {isOrderFeature && <AuthenticationRoute component={MnCombo} exact path={PathConstant.MANAGE_COMBO} />}
        {isOrderFeature && <AuthenticationRoute component={MnProduct} exact path={PathConstant.MANAGE_PRODUCT} />}
        {isOrderFeature && <AuthenticationRoute component={MnService} exact path={PathConstant.MANAGE_SERVICE} />}
        {isOrderFeature && <AuthenticationRoute component={MenuSetup} exact path={PathConstant.MENU_SETUP} />}
        {isOrderFeature && <AuthenticationRoute component={PaymentSetup} exact path={PathConstant.PAYMENT_SETUP} />}

        <Redirect
          from={PathConstant.ROOT}
          to={
            isBookingFeature
              ? PathConstant.MANAGE_BOOKS
              : isOnlyOrdering
              ? PathConstant.MANAGE_ORDER
              : PathConstant.MANAGE_SHOP_INFO
          }
        />
        <Redirect to={PathConstant.NOT_FOUND} />
      </Switch>
    )
  );
};

export default Routes;
