import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { format as formatDate } from "date-fns";
import StringFormat from "string-format";
import clsx from "clsx";
import { jumpToTargetOrder } from "utils";
import { LangConstant, AppConstant, SystemConstant } from "const";
import NewOrderConfirm from "./NewOrderConfirm";

const NewOrderNotifyItem = ({ data, onClose, ...otherProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_MAIN_LAYOUT);

  const onItemClick = event => {
    event.preventDefault();
    const startTime = data.startTime;
    const uid = data.uid;
    onClose(event);
    jumpToTargetOrder(history, startTime, uid);
  };

  let noticeContent = useMemo(() => getContent(data, getLabel), [data, getLabel]);

  return (
    <ListItem
      key={data.uid}
      id={data.uid}
      button={true}
      className={classes.notification}
      selected={data.status === AppConstant.STT_RESERVATION.pending}
      classes={{ root: classes.item }}
      onClick={onItemClick}
    >
      <Grid item container direction="row" justify="flex-start">
        <Grid item xs container direction="row" className={clsx(classes.noticeIconForm, classes.directionCenter)}>
          <Grid item>
            <ListItemIcon>
              <Box className={classes.bgItem}>
                <DateRangeIcon color="inherit" />
              </Box>
            </ListItemIcon>
          </Grid>
        </Grid>
        <Grid item xs={8} md={7} container>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                className={clsx("medium-md-txt", classes.typoNotice)}
                dangerouslySetInnerHTML={{
                  __html: noticeContent,
                }}
              />
            }
          />
        </Grid>
        <Grid item xs container className={classes.noticeIconForm}>
          <ListItemSecondaryAction>
            <Box>
              <Typography variant="body1" className="medium-md-txt">
                {formatDate(data.created * 1000, AppConstant.FM_HH_MM)}
              </Typography>
              <NewOrderConfirm data={data} {...otherProps} />
            </Box>
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
    </ListItem>
  );
};

NewOrderNotifyItem.propTypes = {
  data: PropTypes.object,
};
NewOrderNotifyItem.defaultProps = {
  data: {},
};

export default memo(NewOrderNotifyItem);

const getContent = (data, getLabel) => {
  const notificationObjContent = getLabel(LangConstant.OBJ_ORDER_NOTIFICATION, { returnObjects: true });

  let result = "";
  if (data.details?.depositType) {
    let isCash = data.details.depositType === SystemConstant.PAYMENT_METHOD.cash;
    let content = isCash ? notificationObjContent.cash : notificationObjContent.banking;
    result = StringFormat(content, data.name || "");
  } else {
    result = getLabel(LangConstant.FM_NEW_ORDER_REQUEST_CONTENT);
    result = StringFormat(result, data.name);
  }
  return result;
};

const useStyles = makeStyles(theme => ({
  bgItem: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.white,
    background: "#ffaa6e",
  },
  item: {
    marginBottom: 8,
  },
  typoNotice: {
    lineHeight: "1.57",
    fontWeight: "normal",
    fontStretch: "normal",
    color: "#3b404c",
  },
  noticeIconForm: {
    width: 48,
  },
  directionCenter: {
    alignItems: "center",
  },
}));
