import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Dialog,
  withStyles,
  CardHeader,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  makeStyles,
  Box,
  Typography,
  OutlinedInput,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Close } from "@material-ui/icons";
import StringFormat from "string-format";
import BillAction from "redux/bill.redux";
import { LangConstant } from "const";
import { getCommonKey, isObjectNotEqual } from "utils";
import ConfirmDialog from "./ConfirmDialog";

const BillDetailDialog = ({ data, onClose, isEditing, onSubmitAddBill }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);
  const isDeleteSuccess = useSelector(state => state.billRedux.isDeleteSuccess);

  const [formData, setFormData] = useState(data || DEFAULT_DATA_OBJ);
  const [isShowDeleteConfirmDialog, setIsShowDeleteConfirmDialog] = useState(false);

  const onChangeForm = event => {
    let name = event.target.name;
    let value = event.target.value;
    let newFormValue = { ...formData };
    newFormValue[name] = value;
    setFormData(newFormValue);
  };

  const onUpdateBill = () => {
    dispatch(
      BillAction.updateBill({
        ...formData,
        reservationUid: formData.reservation.uid || "",
      }),
    );
  };

  const onOpenDeleteConfirmDialog = () => {
    setIsShowDeleteConfirmDialog(true);
  };

  const onDeleteBill = () => {
    dispatch(
      BillAction.deleteBill({
        uid: formData.uid,
      }),
    );
    onClose();
  };

  const onAddBill = () => {
    onSubmitAddBill(formData);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      onClose();
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isObjectNotEqual(data, formData)) {
      setFormData(data);
    }
  }, [data]);

  return isShowDeleteConfirmDialog ? (
    <ConfirmDialog
      isShow={true}
      onSelectConfirm={onDeleteBill}
      onSelectCancel={() => setIsShowDeleteConfirmDialog(false)}
      title={getLabel(LangConstant.TXT_DELETE_BILL)}
      titleConfirm={StringFormat(getLabel(LangConstant.FM_DELETE_BILL), data.reservation?.code || "")}
      confirmText={getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
      cancelText={getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
    />
  ) : (
    <Dialog open={true}>
      <DialogContent className={classes.dialogContent}>
        <Header
          title={getLabel(isEditing ? LangConstant.TXT_VAT_BILL_EDIT : LangConstant.TXT_ADD_BILL_TITLE)}
          action={
            <IconButton className={classes.closeButton} onClick={onClose}>
              <Close />
            </IconButton>
          }
        />
        <Box className={classes.formContainer}>
          <Box className={classes.formRow}>
            <Typography className={classes.inputLabel} variant="body2">
              {getLabel(LangConstant.TXT_COMPANY_NAME)}
            </Typography>
            <OutlinedInput
              value={formData.companyName}
              classes={{
                input: classes.input,
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
              }}
              name={OJB_INPUT_NAME.companyName}
              onChange={onChangeForm}
              placeholder={getLabel(LangConstant.PH_COMPANY_NAME)}
            />
          </Box>
          <Box className={classes.formRow}>
            <Typography className={classes.inputLabel} variant="body2">
              {getLabel(LangConstant.TXT_COMPANY_TAX_NUMBER)}
            </Typography>
            <OutlinedInput
              value={formData.taxCode}
              classes={{
                input: classes.input,
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
              }}
              name={OJB_INPUT_NAME.taxCode}
              onChange={onChangeForm}
              placeholder={getLabel(LangConstant.PH_TAX_CODE)}
            />
          </Box>
          <Box className={classes.formRow}>
            <Typography className={classes.inputLabel} variant="body2">
              {getLabel(LangConstant.TXT_COMPANY_ADDRESS)}
            </Typography>
            <OutlinedInput
              multiline
              value={formData.address}
              classes={{
                input: classes.input,
                root: classes.inputAddressRoot,
                notchedOutline: classes.notchedOutline,
              }}
              name={OJB_INPUT_NAME.address}
              onChange={onChangeForm}
              placeholder={getLabel(LangConstant.PH_COMPANY_ADDRESS)}
            />
          </Box>
          <Box className={classes.formRow}>
            <Typography className={classes.inputLabel} variant="body2">
              {getLabel(LangConstant.TXT_RECEIVER_NAME)}
            </Typography>
            <OutlinedInput
              value={formData.contactName}
              classes={{
                input: classes.input,
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
              }}
              name={OJB_INPUT_NAME.contactName}
              onChange={onChangeForm}
              placeholder={getLabel(getCommonKey(LangConstant.TXT_FULLNAME))}
            />
          </Box>
          <Box className={classes.formRow}>
            <Typography className={classes.inputLabel} variant="body2">
              {getLabel(getCommonKey(LangConstant.TXT_EMAIL))}
            </Typography>
            <OutlinedInput
              value={formData.email}
              classes={{
                input: classes.input,
                root: classes.inputRoot,
                notchedOutline: classes.notchedOutline,
              }}
              name={OJB_INPUT_NAME.email}
              onChange={onChangeForm}
              placeholder={getLabel(LangConstant.PH_EMAIL)}
            />
          </Box>
        </Box>
        <DialogActions className={classes.dialogActions}>
          {isEditing ? (
            <Button
              variant="outlined"
              color="primary"
              className={clsx(classes.button, classes.buttonCancel, "medium-md-txt")}
              onClick={onOpenDeleteConfirmDialog}
            >
              {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
            </Button>
          ) : (
            <Button
              variant="text"
              className={clsx(classes.button, classes.buttonCancel, "medium-md-txt")}
              onClick={onClose}
            >
              {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={!isObjectNotEqual(formData, DEFAULT_DATA_OBJ)}
            className={clsx(classes.button, classes.buttonAccept, "medium-md-txt")}
            onClick={isEditing ? onUpdateBill : onAddBill}
          >
            {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const Header = withStyles(theme => ({
  root: {
    position: "relative",
    padding: "16px 24px",
    background: theme.palette.secondary.dark,
    color: theme.palette.white,
    minHeight: 50,
  },

  action: {
    flex: "none",
    position: "absolute",
    right: 29,
  },

  title: {
    ...theme.typography.body1,
    fontSize: 20,
    color: theme.palette.white,
  },
}))(CardHeader);

const OJB_INPUT_NAME = {
  companyName: "companyName",
  taxCode: "taxCode",
  contactName: "contactName",
  address: "address",
  email: "email",
};

const DEFAULT_DATA_OBJ = {
  companyName: "",
  taxCode: "",
  contactName: "",
  address: "",
  email: "",
};

BillDetailDialog.propTypes = {
  isEditing: PropTypes.bool,

  data: PropTypes.object,

  onClose: PropTypes.func,
  onSubmitAddBill: PropTypes.func,
};

BillDetailDialog.defaultProps = {
  isEditing: true,

  data: DEFAULT_DATA_OBJ,

  onClose: () => {},
  onSubmitAddBill: () => {},
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 0,
    position: "relative",
    borderRadius: "unset",
    boxShadow: "none",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "unset",
    minWidth: 530,
    "&:first-child": {
      paddingTop: 0,
    },
  },

  button: {
    height: 30,
    minHeight: 20,
    width: 60,
    minWidth: "unset",
    padding: 0,
    textTransform: "none",
    borderRadius: 2,
  },

  buttonCancel: {
    marginRight: 10,
  },

  dialogActions: {
    padding: "20px 24px",
  },

  formContainer: {
    padding: 24,
  },

  formRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
  },

  inputLabel: {
    display: "flex",
    fontSize: 13,
    color: "#565C6A",
    alignItems: "center",
    minWidth: "max-content",
  },

  inputRoot: {
    width: "65%",
    borderRadius: 3,
    height: 30,
    border: `1px solid #D4D5D8`,
  },

  inputAddressRoot: {
    width: "65%",
    borderRadius: 3,
    border: `1px solid #D4D5D8`,
    padding: 0,
    minHeight: 30,
  },

  input: {
    height: "unset",
    padding: "4px 16px",
    color: theme.palette.text.primary,
    ...theme.typography.body2,
    fontSize: 13,
    "&::placeholder": {
      color: "#9D9FA5",
    },
  },

  notchedOutline: {
    border: "none",
  },

  closeButton: {
    color: theme.palette.white,
  },
}));

export default BillDetailDialog;
