import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { LangConstant } from "const";
import { getCommonKey } from "utils";

const DeleteDialog = ({ isShow, onClose, title, instruction, onConfirm }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_PLACE_MANAGE);
  const classes = useStyles();

  return (
    <Dialog open={isShow} onClose={onClose} classes={{ paper: classes.deleteDialog }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogTitle}>{instruction}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} className={classes.actionBtns}>
          {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
        </Button>
        <Button onClick={onConfirm} autoFocus className={classes.actionBtns}>
          {getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  isShow: PropTypes.bool,

  onConfirm: PropTypes.func,
  onClose: PropTypes.func,

  title: PropTypes.string,
  instruction: PropTypes.string,
};

DeleteDialog.defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
};

export default memo(DeleteDialog);

const useStyles = makeStyles(theme => ({
  dialogActions: {
    borderTop: "1px solid #ebeced",
    paddingRight: 24,
  },
  actionBtns: {
    color: theme.palette.grey[400],
  },
  dialogTitle: {
    color: theme.palette.grey[500],
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
  },
  deleteDialog: {
    minHeight: "188px",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
}));
