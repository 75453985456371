import { call, put, select } from "redux-saga/effects";
import { ApiConstant } from "const";
import PaymentAction from "redux/payment.redux";
import { PaymentService } from "services";
import { toCamel, toSnake } from "utils";

export function* getCheckPayment() {
  try {
    let response = yield call(PaymentService.getCheckPayment, {});
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(PaymentAction.getPaymentSuccess({ checkPayment: toCamel(responseData) }));
    } else {
      yield put(PaymentAction.getPaymentFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentAction.getPaymentFailure(error));
  }
}

export function* getPackageCurrent(action) {
  try {
    const { data } = action;
    let response = yield call(PaymentService.getPackageCurrent, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(PaymentAction.getPaymentSuccess({ packageCurrent: toCamel(responseData) }));
    } else {
      yield put(PaymentAction.getPaymentFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentAction.getPaymentFailure(error));
  }
}

export function* getPackage(action) {
  try {
    const { data } = action;
    let response = yield call(PaymentService.getPackage, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(PaymentAction.getPaymentSuccess({ package: toCamel(responseData) }));
    } else {
      yield put(PaymentAction.getPaymentFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentAction.getPaymentFailure(error));
  }
}

export function* getPaymentHistory(action) {
  try {
    const {
      paymentRedux: { paymentHistory: dataPayment },
    } = yield select();

    const { data } = action;

    let formDataPage = formatPage(dataPayment);

    let response = yield call(PaymentService.getPaymentHistory, toSnake({ ...formDataPage, ...data }));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data);
      yield put(PaymentAction.getPaymentSuccess({ paymentHistory: responseData }));
    } else {
      yield put(PaymentAction.getPaymentFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentAction.getPaymentFailure(error));
  }
}

export function* getPaymentDetails(action) {
  try {
    const { data } = action;
    let response = yield call(PaymentService.getPaymentDetails, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(PaymentAction.getPaymentSuccess({ paymentDetails: toCamel(responseData) }));
    } else {
      yield put(PaymentAction.getPaymentFailure(response.data));
    }
  } catch (error) {
    yield put(PaymentAction.getPaymentFailure(error));
  }
}

const formatPage = data => ({
  page: data?.page || 1,
  size: data?.size || 10,
  paging: data?.paing || 1,
});
