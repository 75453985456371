import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import MenuAction from "redux/menu.redux";
import { MenuService } from "services";
import { toCamel, toSnake } from "utils";

export function* getMenu(action) {
  try {
    const { data } = action;
    let response = yield call(MenuService.get, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(MenuAction.getMenuSuccess({ data: toCamel(responseData) }));
    } else {
      yield put(MenuAction.getMenuFailure(response.data));
    }
  } catch (error) {
    yield put(MenuAction.getMenuFailure(error));
  }
}

export function* postMenu(action) {
  try {
    const { data } = action;
    let response = yield call(MenuService.createMenu, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MenuAction.postMenuSuccess(toCamel(responseData)));
    } else {
      yield put(MenuAction.postMenuFailure(response.data));
    }
  } catch (error) {
    yield put(MenuAction.postMenuFailure(error));
  }
}
