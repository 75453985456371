import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import ComboItemAction from "redux/combo-item.redux";
import PaymentAction from "redux/payment.redux";
import { ComboItemService } from "services";
import { toCamel, toSnake } from "utils";

export function* postComboItem(action) {
  try {
    const { data } = action;
    let response = yield call(ComboItemService.postComboItem, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ComboItemAction.postComboItemSuccess(toCamel(responseData)));
    } else if (response.status === ApiConstant.STT_UN_PROCESSABLE_ENTITY) {
      yield put(PaymentAction.getUnProcessableEntity());
    } else {
      yield put(ComboItemAction.postComboItemFailure(response.data));
    }
  } catch (error) {
    yield put(ComboItemAction.postComboItemFailure(error));
  }
}

export function* putComboItem(action) {
  try {
    const { data } = action;
    let response = yield call(ComboItemService.putComboItem, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ComboItemAction.putComboItemSuccess(toCamel(responseData)));
    } else {
      yield put(ComboItemAction.putComboItemFailure(response.data));
    }
  } catch (error) {
    yield put(ComboItemAction.putComboItemFailure(error));
  }
}

export function* getListComboItem(action) {
  try {
    const { data } = action;
    let response = yield call(ComboItemService.getListComboItem, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ComboItemAction.getListComboItemSuccess(toCamel(responseData)));
    } else {
      yield put(ComboItemAction.getListComboItemFailure(responseData));
    }
  } catch (error) {
    yield put(ComboItemAction.getListComboItemFailure(error));
  }
}

export function* getComboItemByUid(action) {
  try {
    const { data } = action;
    let response = yield call(ComboItemService.getComboItemByUid, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ComboItemAction.getComboItemSuccess(toCamel(responseData)));
    } else {
      yield put(ComboItemAction.getComboItemFailure(response.data));
    }
  } catch (error) {
    yield put(ComboItemAction.getComboItemFailure(error));
  }
}

export function* deleteComboItem(action) {
  try {
    const { data } = action;
    let response = yield call(ComboItemService.deleteComboItem, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ComboItemAction.deleteComboItemSuccess(toCamel(responseData)));
    } else {
      yield put(ComboItemAction.deleteComboItemFailure(response.data));
    }
  } catch (error) {
    yield put(ComboItemAction.deleteComboItemFailure(error));
  }
}
