import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const GridViewIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <path d="M3,3v8h8V3H3z M9,9H5V5h4V9z M3,13v8h8v-8H3z M9,19H5v-4h4V19z M13,3v8h8V3H13z M19,9h-4V5h4V9z M13,13v8h8v-8H13z M19,19h-4v-4h4V19z" />
    </SvgIcon>
  );
};

GridViewIcon.propTypes = {
  className: PropTypes.string,
};
GridViewIcon.defaultProps = {};

export default memo(GridViewIcon);
