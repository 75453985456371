export default {
  TXT_NOTIFICATION_NEW: "Tin tức mới",
  TXT_INFORMATION_BOOKING_EDIT: "Thông tin đặt lịch bị chỉnh sửa",
  TXT_NEW_BOOKING: "Đặt lịch mới",
  TXT_APPOINTMENT_BOOKING_EDIT: "Trạng thái đặt lịch bị chỉnh sửa",
  TXT_NEW_ORDER: "Đơn hàng mới",
  TXT_DESIGNATED_STAFF: "Chỉ định nhân viên",
  TXT_INFORMATION_ORDER_EDIT: "Thông tin đơn hàng bị chỉnh sửa",
  TXT_APPOINTMENT_ORDER_EDIT: "Trạng thái đơn hàng bị chỉnh sửa",
  TXT_NOTICE_OF_PAYMENT_REQUEST: "Thông báo yêu cầu thanh toán",
  TXT_PHONE_NUMBER_TO_NOTIFICATION: "Số điện thoại nhận thông báo",
  TXT_NOTIFICATION_WEBSITE: "Thông báo trên Website",
  TXT_NOTIFICATION_EMAIL: "Thông báo về Email",
  TXT_NOTIFICATION_PHONE_NUMBER: "Thông báo về số điện thoại",
  TXT_NOTIFICATION_GMAIL: "Gmail nhận thông báo",
  TXT_ADD_GMAIL: "Thêm Email",
  TXT_ADD_PHONE_NUMBER: "Thêm số điện thoại",
  TXT_NOTIFICATION_CONFIRMATION_SMS: "Khi xác nhận số di động sẽ được Gboss gửi thông báo qua SMS",
  TXT_NOTIFICATION_CONFIRMATION_EMAIL: "Khi xác nhận email sẽ được Gboss gửi thông báo qua email",
  TXT_EDIT_EMAIL: "Chỉnh sửa Email",
  TXT_EDIT_SMS: "Chỉnh sửa số điện thoại",
  FM_CONFIRMATION_HEADER: `Bạn có muốn xóa Email :"{0}"?`,
  TXT_CONFIRMATION_BODY: "Bạn có chắc muốn xóa Email này không?",
  FM_CONFIRMATION_HEADER_SMS: `Bạn có muốn xóa số điện thoại :"{0}"?`,
  TXT_CONFIRMATION_BODY_SMS: "Bạn có chắc muốn xóa số điện thoại này không?",
};
