import { AppConstant } from "const";
import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getBookingHistory: ["data"],
  batchUpdateStatus: ["data"],

  historySuccess: ["data"],
  historyFailure: ["data"],

  resetSuccess: [],
});

export const ReservationHistoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  bookingHistory: {},
  filter: {
    total: 0,
    totalPage: 1,
    orderBy: "created",
    desc: 1,
    size: AppConstant.PAGE_SIZE_DEFAULT,
    paging: 1,
    page: 1,
    status: null,
    staffUid: null,
    placeCategoryUid: null,
    placeItemUid: null,
  },
};

/* ------------- Reducers ------------- */

/* ------------- Get ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

export const resetSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isSuccess: false,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_BOOKING_HISTORY]: request,
  [Types.BATCH_UPDATE_STATUS]: request,

  [Types.HISTORY_SUCCESS]: success,
  [Types.HISTORY_FAILURE]: failure,

  [Types.RESET_SUCCESS]: resetSuccess,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
