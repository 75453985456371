import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCookiesDataShopSelect } from "utils";

const MyAccount = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  const dataUser = useSelector(({ userRedux }) => userRedux.dataUser, shallowEqual);

  let dataShops = getCookiesDataShopSelect();

  return (
    <Box className={classes.cellItem}>
      <Typography className={clsx("semiBold-lg-txt", classes.titleCell)}>
        {getLabel(LangConstant.TXT_MY_ACCOUNT)}
      </Typography>
      <Typography className={clsx("medium-md-txt", classes.contentCell)}>
        {StringFormat(FM_CONTENT, getLabel(LangConstant.TXT_ACCOUNT_USER_ID), dataUser?.id || "")}
      </Typography>
      <Typography className={clsx("medium-md-txt", classes.contentCell)}>
        {StringFormat(FM_CONTENT, getLabel(LangConstant.TXT_ACCOUNT_PHONE_NUMBER), dataUser?.phoneNumber || "")}
      </Typography>
      <Typography className={clsx("medium-md-txt", classes.contentCell)}>
        {StringFormat(FM_CONTENT, getLabel(LangConstant.TXT_ACCOUNT_EMAIL), dataUser?.email || "")}
      </Typography>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.titleCell)}>
          {getLabel(LangConstant.TXT_ACCOUNT_SHOPS)}
        </Typography>
        <Box marginLeft="4px">
          {dataShops.map((item, index) => (
            <Typography key={index} className={clsx("medium-md-txt", classes.shopColor)}>
              {item.name}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const FM_CONTENT = "{0} {1}";

export default MyAccount;

const useStyles = makeStyles(theme => ({
  cellItem: {
    width: `calc(${100 / 3}%)`,
    minWidth: 300,
    paddingBottom: 30,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  titleCell: {
    lineHeight: 1.5,
    color: "#3e4045",
    marginBottom: 24,
  },
  contentCell: {
    lineHeight: 1.57,
    color: "#3e4045",
    marginBottom: 22,
  },
  boxLine: {
    display: "flex",
    alignItems: "start",
    minHeight: 30,
  },
  shopColor: {
    lineHeight: 1.57,
    color: theme.palette.text.link,
  },
}));
