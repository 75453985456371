import React, { memo, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { makeStyles, Box } from "@material-ui/core";
import { vi as viLocale, enUS as enLocale } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "../../../../../const";
import { format } from "date-fns";

const CustomTimePicker = ({ onChange, selectedTimeProp, index, ...otherProps }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState(selectedTimeProp);
  const [isOpen, setIsOpen] = useState(false);

  const onChangeTime = time => {
    setSelectedTime(time);
    let currentTime = new Date(selectedTime).getHours() + ":" + new Date(selectedTime).getMinutes();
    let changeTime = format(time, AppConstant.FM_HH_MM);
    onChange(currentTime, changeTime, index);
  };

  const onClick = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <Box onClick={e => onClick(e)} className={classes.boxRoot}>
        <TimePicker
          disableToolbar
          value={selectedTime}
          minutesStep={5}
          onChange={onChangeTime}
          variant="inline"
          InputProps={{
            disableUnderline: true,
            className: "regular-md-txt",
            classes: { input: classes.inputText },
          }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          className={classes.input}
          ampm={false}
          {...otherProps}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: 0,
    color: theme.palette.text.link,
    minWidth: 30,
    height: "100%",
    opacity: 1,
    cursor: `pointer`,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  input: {
    pointerEvents: `none`,
    height: "100%",
  },
  inputText: {
    width: "100%",
    height: 20,
    color: theme.palette.text.link,
    fontSize: 13,
    fontWeight: "inherit",
    padding: "0px !important",
  },
}));

const localeMap = {};
localeMap[LangConstant.EN_LANG] = enLocale;
localeMap[LangConstant.DEFAULT_LANG] = viLocale;

export default memo(CustomTimePicker);
