import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import PlaceCategoryAction from "redux/place-category.redux";
import PlaceItemAction from "redux/place-item.redux";
import { PlaceCategoryService } from "services";
import { toCamel, toSnake } from "utils";

export function* getPlaceCategory() {
  try {
    let response = yield call(PlaceCategoryService.get);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validateResponseArray(responseData);
      yield put(
        PlaceCategoryAction.getPlaceCategorySuccess({
          jsonData: placeArrayToJson(validatedResponseData),
          arrayData: toCamel(restructuredPlaceArray(validatedResponseData)),
        }),
      );
    } else {
      yield put(PlaceCategoryAction.getPlaceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceCategoryAction.getPlaceCategoryFailure(error));
  }
}

export function* createPlaceCategory(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceCategoryService.create, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validatePlaceData(responseData);
      yield put(PlaceCategoryAction.createPlaceCategorySuccess(placeArrayToJson([validatedResponseData])));
    } else {
      yield put(PlaceCategoryAction.createPlaceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceCategoryAction.createPlaceCategoryFailure(error));
  }
}

export function* createPlaceCategoryItem(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceCategoryService.create, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validatePlaceData(responseData);
      const items = createItems(data, responseData.uid);
      yield put(PlaceItemAction.patchPlaceItem(items));
      yield put(PlaceCategoryAction.createPlaceCategorySuccess(placeArrayToJson([validatedResponseData])));
      yield put(PlaceCategoryAction.getPlaceCategory());
    } else {
      yield put(PlaceCategoryAction.createPlaceCategoryItemFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceCategoryAction.createPlaceCategoryItemFailure(error));
  }
}

export function* removePlaceCategory(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceCategoryService.remove, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(PlaceCategoryAction.removePlaceCategorySuccess(data));
    } else {
      yield put(PlaceCategoryAction.removePlaceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceCategoryAction.removePlaceCategoryFailure(error));
  }
}

export function* updatePlaceCategory(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceCategoryService.update, data);
    if (response.status === ApiConstant.STT_OK) {
      if (data.updateAll === 0) {
        let responseData = response.data.data;
        if (data.totalAmount !== 0) {
          const items = createItems(data, responseData.uid);
          yield put(PlaceItemAction.patchPlaceItem(items));
        }
        yield put(PlaceCategoryAction.updatePlaceCategorySuccess(responseData));
      } else {
        yield put(PlaceCategoryAction.updatePlaceCategorySuccess(data));
      }
    } else {
      yield put(PlaceCategoryAction.updatePlaceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceCategoryAction.updatePlaceCategoryFailure(error));
  }
}

export function* getPlaceDialogData(action) {
  try {
    const { data } = action;
    let filterParams = {
      start: data.start,
      end: data.end,
      placeCategoryUid: data.placeFilter.uid,
      filter: data.filter,
    };
    let response = yield call(PlaceCategoryService.getPlaceDialogData, toSnake(filterParams));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(
        PlaceCategoryAction.placeCategorySuccess({
          placeDialog: {
            empty: responseData.empty || [],
            goingToUse: responseData.going_to_use || [],
            active: responseData.in_use || [],
          },
          timeRangeFiltering: {
            start: filterParams.start,
            end: filterParams.end,
          },
        }),
      );
    } else {
      yield put(PlaceCategoryAction.placeCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceCategoryAction.placeCategoryFailure(error));
  }
}

const createItems = (placeCategory, categoryUid) => {
  let placeItemList = [];
  for (var i = 0; i < placeCategory.totalAmount; i++) {
    const placeItem = {
      category_uid: categoryUid,
      name: `${placeCategory.name} ${i}`,
      capacity: placeCategory.capacity,
    };

    placeItemList.push(placeItem);
  }
  return placeItemList;
};

const placeArrayToJson = array => {
  let jsonData = {};
  for (var i = 0; i < array.length; i++) {
    // Create item json object
    let jsonItems = {};
    const items = array[i].items;
    for (var j = 0; j < items.length; j++) {
      jsonItems[items[j].uid] = items[j];
    }
    array[i].items = jsonItems;
    // array[i]
    //Create data json object
    jsonData[array[i].uid] = toCamel(array[i]);
  }
  return jsonData;
};

const validateResponseArray = responseArray => {
  const validatedArray = responseArray
    ? responseArray.map(value => {
        return validatePlaceData(value);
      })
    : [];
  return validatedArray;
};

const validatePlaceData = placeData => {
  const validatedPlaceData = {
    name: placeData.name ? placeData.name : "",
    uid: placeData.uid ? placeData.uid : "",
    items: placeData.items
      ? placeData.items.map(value => {
          return validatePlaceDataItems(value);
        })
      : [],
  };

  return validatedPlaceData;
};

const validatePlaceDataItems = item => {
  const validatedItem = {
    uid: item.uid ? item.uid : "",
    state: item.state ? item.state : "",
    name: item.name ? item.name : "",
    code: item.code ? item.code : "",
    sort_order: item.sort_order ? item.sort_order : 0,
    description: item.description ? item.description : "",
    category_uid: item.category_uid ? item.category_uid : "",
    capacity: item.capacity ? item.capacity : 0,
  };
  return validatedItem;
};

const restructuredPlaceArray = placeCategory => {
  return placeCategory.map(value => {
    return {
      name: value.name,
      uid: value.uid,
      items: Object.values(value.items).map(ele => {
        return {
          ...ele,
          category_uid: value.uid,
        };
      }),
    };
  });
};
