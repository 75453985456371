import React, { memo } from "react";
import PropTypes from "prop-types";
import { withStyles, Dialog as MuiDialog, DialogContent as MuiDialogContent } from "@material-ui/core/";
import DialogTitle from "./DialogTitle";

const PrimaryDialog = props => {
  const { isShow, children, title, onClose, classes, ...otherProps } = props;

  return (
    <Dialog
      onClose={onClose}
      open={isShow}
      scroll="paper"
      classes={{
        paper: classes.paper,
      }}
      {...otherProps}
    >
      <DialogTitle
        onClose={onClose}
        classes={{
          root: classes.title.titleRoot,
          icon: classes.title.titleIcon,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
    </Dialog>
  );
};

PrimaryDialog.propTypes = {
  isShow: PropTypes.bool,
  title: PropTypes.any,
  message: PropTypes.string,

  classes: PropTypes.shape({
    paper: PropTypes.string,
    title: PropTypes.shape({
      titleRoot: PropTypes.string,
      titleIcon: PropTypes.string,
    }),
    content: PropTypes.string,
  }),

  onClose: PropTypes.func.isRequired,
};
PrimaryDialog.defaultProps = {
  classes: {
    paper: "",
    title: {
      titleRoot: "",
      titleIcon: "",
    },
    content: "",
  },
};

export default memo(PrimaryDialog);

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const Dialog = withStyles(theme => ({
  root: {},

  paper: {
    [theme.breakpoints.down("md")]: {
      margin: 12,
    },
  },
}))(MuiDialog);
