import React, { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Add, Close, Error, Link } from "@material-ui/icons";
import clsx from "clsx";
import ServiceItemAction from "redux/service-item.redux";
import { AppConstant, LangConstant } from "const";
import { createArrayWithLabel, getCommonKey, getItemUrl } from "utils";
import { Alert, Input, TextArea } from "components";
import {
  CategoryAllMenu,
  CategoryMenu,
  CategorySubcategoryMenu,
  DropdownMenu,
  InputPrice,
  InputUsedTime,
  UploadImage,
  UploadVideo,
  Warning,
  ValueTax,
} from "components/mn-master-product";
import GroupClassifiedService from "./GroupClassifiedService";
import { TYPE_NOTIFY } from "./DialogCreateMainService";

const DialogViewEditMainService = ({ data, isOpen, onClose, isEdit, warning, isShowEdit }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const attachedModeArray = [
    { name: getLabel(getCommonKey(LangConstant.TXT_CATEGORY)), id: "category" },
    { name: getLabel(getCommonKey(LangConstant.TXT_ALL)), id: "all" },
  ];

  const dataCategoryService = useSelector(state => state.serviceCategoryRedux?.data, shallowEqual);
  const itemDetailsList = useSelector(state => state.itemDetailsRedux.dataGetListItemDetails?.data, shallowEqual);
  const dataCategoryPlace = useSelector(state => state.placeCategoryRedux?.data, shallowEqual);
  const staffList = useSelector(state => state.staffRedux?.staff, shallowEqual);
  const dataItemDetailsContent = useSelector(
    state => state.itemDetailsContentRedux?.dataGetListItemDetailsContent?.data,
  );
  const errorPutServiceItem = useSelector(state => state.serviceItemRedux?.errorPutServiceItem);
  const shopInfo = useSelector(state => state.shopInfoRedux.data, shallowEqual);

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isEditable, setIsEditable] = useState(isEdit);
  const [isWarning, setIsWarning] = useState(warning);
  const [formInput, setFormInput] = useState({
    uid: data?.uid ? data?.uid : "",
    name: data?.name ? data?.name : "",
    categoryList: data?.categoryList ? data?.categoryList.map(data => data.uid) : [],
    image: data?.image ? data?.image : "",
    imageList: data?.imageList || [],
    usingTime: data?.usingTime ? data?.usingTime : 0,
    usingTimeType: data?.usingTimeType ? data?.usingTimeType : 0,
    price: data?.price,
    priceTaxIn: data?.priceTaxIn,
    unitType: data?.unitType ? data?.unitType : 1,
    tax: data?.tax ? data?.tax : 0,
    taxInclusiveF: data?.taxInclusiveF ? data?.taxInclusiveF : 0,
    description: data?.description ? data?.description : "",
    staffList: data?.staffList ? data?.staffList.map(data => data.uid) : [],
    placeList: data?.placeList ? data?.placeList.map(data => data.uid) : [],
    itemDetailsUidList: data?.itemDetailsList ? data?.itemDetailsList.map(data => data.uid) : [],
    itemDetails: data?.itemDetails,
    details: data?.details,
    groupModel: data?.groupModel,
  });
  const [taxTypeValue, setTaxTypeValue] = useState(
    data?.details?.defaultId
      ? AppConstant.TAX_TYPE_VALUE[data?.details?.defaultId]
      : AppConstant.TAX_TYPE_VALUE[AppConstant.TYPE_TAX.noTax],
  );
  const [notify, setNotify] = useState({
    value: false,
    message: "",
    type: 0,
  });
  const [isShowGroupClassified, setIsShowGroupClassified] = useState(Boolean(data?.groupModel));

  const onTypingName = value => {
    setFormInput({
      ...formInput,
      name: value,
    });
  };

  const onSelectServiceCategory = array => {
    setFormInput({
      ...formInput,
      categoryList: array,
    });
  };

  const onUploadFile = (value, error) => {
    if (!error) {
      setFormInput({
        ...formInput,
        imageList: value,
      });
    } else {
      setNotify({
        value: true,
        message: error,
        type: 1,
      });
    }
  };

  const onUploadVideo = (value, isError) => {
    let newFormValue = {
      ...formInput,
    };
    if (isError) delete newFormValue.video;
    else newFormValue.video = value;

    setFormInput(newFormValue);
  };

  const onTypingDescription = event => {
    let descriptionInput = event.target.value;
    setFormInput({
      ...formInput,
      description: descriptionInput,
    });
  };

  const onChangeTime = value => {
    setFormInput({
      ...formInput,
      usingTime: value,
    });
  };

  const onClickTimeUsed = value => {
    setFormInput({
      ...formInput,
      usingTimeType: value,
    });
  };

  const onInputPrice = value => {
    const newPrice = value;
    const newPriceTaxIn = Math.round(newPrice * (1 + taxTypeValue));
    const tax = newPriceTaxIn - newPrice;
    setFormInput({
      ...formInput,
      price: newPrice,
      priceTaxIn: newPriceTaxIn,
      tax: tax,
    });
  };

  const onChangeUnitType = value => {
    setFormInput({
      ...formInput,
      unitType: value,
    });
  };

  const onChangeTaxType = taxTypeId => {
    const newTaxTypeValue = AppConstant.TAX_TYPE_VALUE[taxTypeId];
    setTaxTypeValue(newTaxTypeValue);

    const newPriceTaxIn = Math.round(formInput.price * (1 + newTaxTypeValue));
    const tax = newPriceTaxIn - formInput.price;
    setFormInput({
      ...formInput,
      tax: tax,
      priceTaxIn: newPriceTaxIn,
      taxInclusiveF: Boolean(newTaxTypeValue),
      details: { ...formInput.details, defaultId: taxTypeId },
    });
  };

  const onSelectAttachedService = data => {
    if (data[attachedModeArray[0].id]) {
      setFormInput({ ...formInput, itemDetailsUidList: data[attachedModeArray[0].id], itemDetails: null });
    } else if (data[attachedModeArray[1].id]) {
      setFormInput({
        ...formInput,
        itemDetailsUidList: [],
        itemDetails: {
          name: formInput?.name,
          type: AppConstant.TYPE_CATEGORY.service,
          description: formInput?.description ? formInput?.description : "",
          details: "",
          itemDetailsContentUidList: data[attachedModeArray[1].id],
        },
      });
    }
  };

  const onSelectPlace = data => {
    setFormInput({ ...formInput, placeList: data });
  };

  const onSelectStaff = data => {
    setFormInput({ ...formInput, staffList: data });
  };

  const onSubmitForm = () => {
    if (formInput.name) {
      dispatch(
        ServiceItemAction.putServiceItem({
          ...formInput,
          usingTimeType: formInput.usingTimeType || AppConstant.TYPE_TIME_USED.minute,
        }),
      );
    } else {
      setNotify({
        value: true,
        message: getLabel(LangConstant.FN_NOTIFY_INPUT_NAME)(getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)),
        type: 2,
      });
    }
  };

  const onEnableEdit = () => {
    setIsEditable(true);
  };

  const onCloseNotify = () => {
    setIsWarning(false);
    setNotify({
      value: false,
      message: "",
      type: 0,
    });
  };

  const onChangeDataGroup = data => {
    setFormInput(data);
  };

  useEffect(() => {
    if (errorPutServiceItem) {
      if (errorPutServiceItem?.name?.[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dialogRef.current.querySelector(".MuiDialog-paperScrollPaper").scrollTop = 0;
        dispatch(ServiceItemAction.resetErrorServiceItem());
      }
    }
  }, [errorPutServiceItem]);

  return (
    <Dialog open={isOpen} fullScreen ref={dialogRef}>
      {isOpen && isEditable && (
        <Warning
          isShow={isWarning}
          onClose={onCloseNotify}
          message={getLabel(LangConstant.TXT_NOTIFY_WARNING_SERVICE)}
        />
      )}
      <Box className={classes.rootHeader}>
        <Typography className={classes.nameHeaderTypo}>
          {isEditable
            ? `${getLabel(getCommonKey(LangConstant.TXT_EDIT))} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`
            : `${getLabel(LangConstant.TXT_DETAIL_MAIN_SERVICE)} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`}
        </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <Box className={classes.infoBox}>
          <Box marginBottom="30px">
            <Box className={classes.paddingBox}>
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_INFORMATION_BASIC)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.FN_NOTICE_FILL)(getLabel(LangConstant.TXT_SERVICE_LOWER_CASE))}
              </Typography>
            </Box>
            <Box className={classes.paddingBox}>
              <Box className={classes.infoBasic}>
                <Typography className={classes.nameComboTypo}>{getLabel(LangConstant.TXT_NAME_SERVICE)}</Typography>
                <Typography color="primary" className={classes.isRequire}>
                  &nbsp;&#42;
                </Typography>
              </Box>
              <Input
                value={formInput.name}
                placeholder={getLabel(LangConstant.TXT_NAME_SERVICE)}
                classes={{ root: classes.rootNameCombo, input: classes.inputNameCombo }}
                required
                disabled={!isEditable}
                onChange={onTypingName}
              />
              {isDuplicate && (
                <Box className={classes.warningName}>
                  <Error color="primary" className={classes.iconError} />
                  <Typography color="primary" className={classes.textWarning}>
                    {getLabel(LangConstant.TXT_WARNING_NAME_SERVICE)}
                  </Typography>
                </Box>
              )}
              {notify.value && notify.type == TYPE_NOTIFY.warningName && (
                <Alert isShow={notify.value} onClose={onCloseNotify} type="error" message={notify.message} />
              )}

              <Box className={classes.copyContainer}>
                <CopyToClipboard text={getItemUrl(shopInfo?.alias, formInput.uid, formInput.name)}>
                  <Button variant="text" className={classes.copyButton} startIcon={<Link />}>
                    {getLabel(LangConstant.TXT_COPY_ITEM_LINK)}
                  </Button>
                </CopyToClipboard>
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{`${getLabel(
                LangConstant.TXT_ADD_CATEGORY_GOOD,
              )} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`}</Typography>
              <CategorySubcategoryMenu
                data={dataCategoryService ? Object.values(dataCategoryService) : []}
                label={getLabel(LangConstant.L_CHOOSE_SERVICE_CATEGORY)}
                onChange={onSelectServiceCategory}
                itemsKey="child"
                selectedArr={formInput.categoryList}
                isDisable={!isEditable}
              />
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_ILLUSTRATION)}</Typography>
              <Box marginTop="8px">
                <UploadImage
                  imageList={
                    formInput.imageList ? formInput.imageList : Boolean(formInput.image) ? [formInput.image] : []
                  }
                  onChange={onUploadFile}
                  isDisabled={!isEditable}
                />
                {notify.value && notify.type == TYPE_NOTIFY.warningImage && (
                  <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
                )}
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>
                {getLabel(LangConstant.TXT_ILLUSTRATION_VIDEO)}
              </Typography>
              <Box marginTop="8px">
                <UploadVideo defaultUrl={formInput.video || ""} onChange={onUploadVideo} />
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{`${getLabel(
                getCommonKey(LangConstant.L_SERVICE_CATEGORY_DIALOG_DESCRIPTION),
              )} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`}</Typography>
              <TextArea
                value={formInput.description}
                placeholder={`${getLabel(LangConstant.TXT_ADD_DESCRIPTION)} ${getLabel(
                  LangConstant.TXT_SERVICE_LOWER_CASE,
                )}`}
                className={classes.desTextArea}
                rowMax={4}
                onChange={onTypingDescription}
                disabled={!isEditable}
              />
            </Box>
          </Box>
          <Box>
            <Box className={classes.paddingBox}>
              <Typography variant="h6" className={classes.informationSales}>
                {getLabel(LangConstant.TXT_INFORMATION_SALES)}
              </Typography>
              <Typography variant="body2" className={classes.informationSalesFill}>
                {getLabel(LangConstant.TXT_FILL_IN_INFORMATION_SALES)}
              </Typography>
            </Box>
            {!isShowGroupClassified && (
              <>
                <Box className={classes.paddingBox}>
                  <Typography variant="subtitle2" className={classes.classificationProduct}>
                    {getLabel(LangConstant.TXT_PRODUCT_CLASSIFICATION)}
                  </Typography>
                  <Button
                    className={clsx(classes.buttonAdd, "regular-md-txt")}
                    startIcon={<Add style={{ fontSize: 20 }} />}
                    onClick={() => setIsShowGroupClassified(true)}
                  >
                    {getLabel(LangConstant.TXT_ADD_GROUP)}
                  </Button>
                </Box>
                <Box className={classes.paddingBox}>
                  <Typography variant="subtitle2" className={classes.classificationProduct}>
                    {getLabel(LangConstant.TXT_TAX_APPLY)}
                  </Typography>
                  <ValueTax onChange={onChangeTaxType} valueTax={formInput.details.defaultId} />
                </Box>
              </>
            )}
            {isShowGroupClassified && (
              <GroupClassifiedService
                onClose={() => setIsShowGroupClassified(false)}
                isShowGroupClassifiedOne={isShowGroupClassified}
                dataFormInput={formInput}
                onChange={onChangeDataGroup}
              />
            )}
            {!isShowGroupClassified && (
              <>
                <Box padding="8px 0 46px" display="flex">
                  <Box minWidth="158px">
                    <Typography className={classes.inputTitleTypo}>
                      {getLabel(LangConstant.TXT_PRICE_ACCORDING_TO)}
                    </Typography>
                    <DropdownMenu
                      valueTax={formInput.unitType}
                      onChange={onChangeUnitType}
                      dataChange={createArrayWithLabel(
                        AppConstant.TYPE_UNIT_PRICE_CALCULATE,
                        LangConstant.ARR_TYPE_UNIT_PRICE_CALCULATE,
                        getLabel,
                        true,
                      )}
                      dropdownClass={classes.dropdownClass}
                      placeholder={getLabel(getCommonKey(LangConstant.P_UNIT))}
                      isDisabled={!isEditable}
                    />
                  </Box>
                  <Box minWidth="158px" margin="0 90px">
                    <Typography className={classes.inputTitleTypo}>
                      {getLabel(getCommonKey(LangConstant.TXT_BOOK_USED_TIME))}
                    </Typography>
                    <InputUsedTime
                      valueTime={formInput.usingTime}
                      valueTimeType={formInput.usingTimeType}
                      dataChange={createArrayWithLabel(
                        AppConstant.TYPE_TIME_USED,
                        LangConstant.ARR_TYPE_TIME_USED,
                        getLabel,
                        true,
                      )}
                      onChange={onChangeTime}
                      onClick={onClickTimeUsed}
                      className={classes.inputUsedTime}
                      placeholderTime={getLabel(getCommonKey(LangConstant.TXT_MINUTES))}
                      placeholderValue="30"
                      isDisabled={!isEditable}
                      unitPriceCalculateType={formInput.unitType}
                    />
                  </Box>
                </Box>
                <Box padding="8px 0 46px" display="flex">
                  <Box minWidth="158px">
                    <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_UNIT_PRICE)}</Typography>
                    <InputPrice
                      valueInput={`${formInput.price}`}
                      onChange={onInputPrice}
                      currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                      checkDisable={!isEditable}
                    />
                  </Box>
                  <Box minWidth="158px" margin="0 90px">
                    <Typography className={classes.inputTitleTypo}>
                      {getLabel(LangConstant.TXT_PRICE_AFTER_TAX)}
                    </Typography>
                    <InputPrice
                      valueInput={formInput.priceTaxIn ? formInput.priceTaxIn.toString() : ""}
                      currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                      checkDisable={true}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box padding="54px 0 16px">
            <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.L_ATTACHED_SERVICE)}</Typography>
            <Typography className={classes.noticeFillTypo}>
              {getLabel(LangConstant.TXT_SETTING_SERVICE_ACCOMPANIED)}
            </Typography>
            <Box padding="24px 0 46px" minWidth={430}>
              <CategoryAllMenu
                allItemData={dataItemDetailsContent}
                data={itemDetailsList}
                modeViewAr={attachedModeArray}
                label={getLabel(LangConstant.L_CHOOSE_ATTACHED_SERVICE)}
                onChange={onSelectAttachedService}
                itemsKey="itemDetailsContentList"
                selectedArr={formInput.itemDetailsUidList}
                isDisable={!isEditable}
              />
            </Box>
          </Box>

          <Box padding="8px 0 0">
            <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.L_POWER_SOURCE)}</Typography>
            <Typography className={classes.noticeFillTypo}>
              {getLabel(LangConstant.TXT_SETTING_POWER_DESCRIPTION)}
            </Typography>
            <Box padding="24px 0 46px">
              <Box marginRight="24px" minWidth="430px">
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.L_LOCATION)}</Typography>
                <CategorySubcategoryMenu
                  data={
                    dataCategoryPlace
                      ? Object.values(dataCategoryPlace).map(data => {
                          data.items = Object.values(data?.items);
                          return data;
                        })
                      : []
                  }
                  label={getLabel(LangConstant.L_CHOOSE_LOCATION)}
                  itemsKey="items"
                  onChange={onSelectPlace}
                  selectedArr={formInput.placeList}
                  isDisable={!isEditable}
                />
              </Box>
              <Box padding="24px 0 0" minWidth="430px">
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.L_LABOR)}</Typography>
                <CategoryMenu
                  label={getLabel(LangConstant.L_CHOOSE_LABOR)}
                  data={staffList}
                  onChange={onSelectStaff}
                  selectedArr={formInput.staffList}
                  isDisable={!isEditable}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Box className={classes.boxButton}>
          {isShowEdit && (
            <>
              <Button className={classes.buttonCancel} onClick={onClose}>
                {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
              </Button>
              {isEditable ? (
                <Button className={clsx(classes.buttonSave, "semiBold-lg-txt")} onClick={onSubmitForm}>
                  {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
                </Button>
              ) : (
                <Button className={clsx(classes.buttonEdit, "semiBold-lg-txt")} onClick={onEnableEdit}>
                  {getLabel(getCommonKey(LangConstant.TXT_EDIT))}
                </Button>
              )}
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  infoBox: {
    width: 430,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  infoBasic: {
    display: "flex",
    alignItems: "center",
    height: 22,
    marginBottom: 8,
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  infoBasicTypo: {
    fontSize: 18,
    fontWeight: 600,
    height: 28,
    color: "#3e4045",
    lineHeight: 1.56,
  },
  nameComboTypo: {
    height: 22,
    lineHeight: 1.57,
    fontSize: 13,
    fontWeight: 600,
  },
  isRequire: {
    height: 22,
  },
  noticeFillTypo: {
    fontSize: 14,
    minHeight: 22,
    lineHeight: 1.57,
    marginTop: 8,
    color: "#3e4045",
  },
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  rootNameCombo: {
    margin: 0,
    borderRadius: 3,
    "&.Mui-disabled": {
      color: "#3e4045",
    },
  },
  inputNameCombo: {
    height: 22,
    padding: "9px 16px",
    fontSize: 14,
    lineHeight: 1.57,
  },
  desTextArea: {
    height: 80,
    margin: 0,
    padding: "8px 16px",
    borderRadius: 3,
  },
  inputUsedTime: {
    width: 158,
  },
  dropdownClass: {
    width: "100%",
  },
  boxButton: {
    display: "flex",
    justifyContent: "center",
  },
  buttonSave: {
    height: 46,
    padding: 0,
    minWidth: 102,
    marginLeft: 30,
    borderRadius: 3,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
  buttonEdit: {
    height: 46,
    padding: 0,
    minWidth: 152,
    marginLeft: 30,
    borderRadius: 3,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },

  copyButton: {
    minHeight: "unset",
    borderRadius: 4,
    textTransform: "none",
    color: theme.palette.text.link,
    padding: "4px 8px",
    fontSize: 13,
  },

  copyContainer: {
    display: "flex",
    width: "100%",
    marginTop: 5,
  },
  buttonCancel: {
    height: 46,
    padding: 0,
    minWidth: 102,
    fontSize: 16,
    borderRadius: 3,
    fontWeight: 600,
    backgroundColor: "#fffff",
    color: theme.palette.black,
    textTransform: "none",
    border: "1px solid #D4D5D8",
    "&:hover": {
      backgroundColor: "#fffff",
    },
  },
  informationSales: {
    fontSize: 18,
  },
  informationSalesFill: {
    paddingTop: 10,
  },
  classificationProduct: {
    fontSize: 13,
  },
  buttonAdd: {
    height: 40,
    width: "100%",
    border: "1px solid #447AFF",
    boxSizing: "border-box",
    borderRadius: 3,
    textTransform: "none",
    marginTop: 15,
    color: "#447AFF",
  },
  dialogAction: {
    justifyContent: "center",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.103529)",
    padding: "30px 0",
  },
  paddingBox: {
    padding: "8px 0 16px",
  },
}));

DialogViewEditMainService.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  warning: PropTypes.bool,
  isShowEdit: PropTypes.bool,

  onClose: PropTypes.func,
};

DialogViewEditMainService.defaultProps = { title: "", isShowEdit: true };

export default memo(DialogViewEditMainService);
