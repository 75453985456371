import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { CategoryItemListHeader, CategoryItemListBody } from "components/mn-master-product";
import { SearchBar } from "components";
import { Close, ArrowBack } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, formatCurrency, removeVietnameseTones } from "utils";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import MnProductAction from "redux/mn-product.redux";

const DialogAddTableAccompaniedProductAll = ({ isOpen, onClose, onBack, dataChild, nameHeader }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const [listProductItem, setListProductItem] = useState([]);

  const listDataItem = useSelector(state => state.mnProductRedux.dataItem, shallowEqual);
  const isListItemSuccess = useSelector(state => state.mnProductRedux.isGetSuccessItem, shallowEqual);
  const isPutItemSuccess = useSelector(state => state.mnProductRedux.isPutSuccessItem, shallowEqual);

  const onSearch = name => {
    let newListSearch = listDataItem?.data.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListProductItem(newListSearch);
  };

  const onClick = () => {};

  const onAddProductMainToAccompaniedProduct = uid => {
    let productItem = listDataItem?.data.filter(itemSearch => itemSearch.uid === uid)[0];
    dispatch(
      MnProductAction.createDetailsContent({
        type: AppConstant.TYPE_CATEGORY.product,
        name: productItem.name,
        image: productItem.image,
        description: productItem.description,
        itemUid: productItem.uid,
        detailsUidList: [],
      }),
    );
  };

  useEffect(() => {
    dispatch(MnProductAction.getProductItem());
  }, []);

  useEffect(() => {
    if (listDataItem && listDataItem.data && isListItemSuccess) {
      setListProductItem(listDataItem.data);
    }
  }, [listDataItem]);

  useEffect(() => {
    if (isPutItemSuccess) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItem());
    }
  }, [isPutItemSuccess]);

  return (
    <Dialog open={isOpen} fullScreen>
      <Box className={classes.rootHeader}>
        <Box className={classes.boxIconBack}>
          <IconButton onClick={onBack} className={classes.iconButton}>
            <ArrowBack className={classes.icon} />
          </IconButton>
        </Box>
        <Typography className={classes.nameHeaderTypo}>{nameHeader}</Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
      <Box textAlign="center">
        <SearchBar
          className={classes.searchBar}
          onKeyUp={onSearch}
          placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
        />
        <Box className={classes.rootBody}>
          <CategoryItemListHeader isProduct={true} />
          {listProductItem.map(dataProductItem => (
            <CategoryItemListBody
              key={dataProductItem.uid}
              uid={dataProductItem.uid}
              onClickAdd={onAddProductMainToAccompaniedProduct}
              onClickWatch={onClick}
              categoryName={dataProductItem.name}
              isAdd={true}
              stock={dataProductItem.stock}
              image={dataProductItem.image}
              price={formatCurrency(`${dataProductItem.price}`)}
              disabled={Boolean(dataChild.find(dataChildMap => dataChildMap.itemUid === dataProductItem.uid))}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
export default memo(DialogAddTableAccompaniedProductAll);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: 400,
    height: 30,
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  rootBody: {
    marginTop: 48,
    marginBottom: 48,
    width: "80%",
    marginLeft: "10%",
    [theme.breakpoints.down(600)]: {
      width: "90%",
      marginLeft: "5%",
      overflowX: "auto",
    },
  },
}));
