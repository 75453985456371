import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Box, makeStyles, Tab, Tabs, Typography, Button } from "@material-ui/core";
import { getFeatureConditions } from "utils";
import { LangConstant } from "const";

const DisplayModeTabBar = props => {
  const { modeId, modeArray, onChange, isShowButton, onClick } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { isPremium, isOnlyBooking, isOnlyOrdering } = getFeatureConditions(reservationSetting);

  const [value, setValue] = useState(modeId ? mode[modeId] : 0);

  const onChangeTab = event => {
    if (event.currentTarget.id !== value) {
      setValue(mode[event.currentTarget.id]);
      onChange(event);
    }
  };

  let isBooking = isOnlyBooking || isPremium;
  let isOrdering = isOnlyOrdering || isPremium;
  return (
    <Box className={classes.barRoot}>
      <Tabs
        className={classes.tabsRoot}
        value={value}
        onChange={onChangeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        {isBooking && (
          <Tab
            id={modeArray[mode.booking].id}
            label={
              <Typography color="inherit" className="medium-md-txt">
                {modeArray[mode.booking].name}
              </Typography>
            }
            className={classes.tabRoot}
          />
        )}
        {isOrdering && (
          <Tab
            id={modeArray[mode.order].id}
            label={
              <Typography color="inherit" className="medium-md-txt">
                {modeArray[mode.order].name}
              </Typography>
            }
            className={classes.tabRoot}
          />
        )}
      </Tabs>
      {isShowButton && (
        <Button className={clsx("medium-md-txt", classes.button)} onClick={onClick}>
          {getLabel(LangConstant.TXT_COPY_FROM_BOOKING)}
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  barRoot: {
    display: "flex",
    borderBottom: "solid 1px #ebeced",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },
  tabsRoot: {
    width: "100%",
  },
  tabRoot: {
    minWidth: 150,
    textTransform: "none",
    color: "#3e4045",
  },
  searchBar: {
    width: 400,
    float: "left",
    [theme.breakpoints.down("sm")]: {
      margin: 8,
      width: "calc(100% - 16px)",
    },
  },
  button: {
    textTransform: "none",
    minWidth: 210,
    minHeight: 32,
    marginLeft: 8,
    marginRight: 24,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: "#ef5845",
    },
  },
}));

DisplayModeTabBar.propTypes = {
  modeArray: PropTypes.array,
  onChange: PropTypes.func,
  onAddItem: PropTypes.func,
  onSearch: PropTypes.func,
  labelBtn: PropTypes.string,
};

DisplayModeTabBar.defaultProps = {
  modeArray: [],
  onChange: () => {},
  onAddItem: () => {},
  onSearch: () => {},
  labelBtn: "",
};

export default memo(DisplayModeTabBar);

const mode = { booking: 0, order: 1 };
