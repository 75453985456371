import { ApiConstant } from "../const";
import { createApiRegistration, createApiReservation } from "../api";
import { format } from "react-string-format";

export const postWorkingShift = data => createApiRegistration().post(ApiConstant.POST_WORKING_SHIFT, data);

export const postStaffPosition = data => createApiRegistration().post(ApiConstant.POST_POSITION, data);

export const getWorkingPotision = data => createApiRegistration().get(ApiConstant.GET_POSITION, data);

export const getWorking = data => createApiRegistration().get(ApiConstant.GET_WORKING_SHIFT, data);

export const removePostWorking = data =>
  createApiRegistration().delete(format(ApiConstant.DELETE_WORKING_SHIFT, data.uid), {});

export const removeStaffPosition = data =>
  createApiRegistration().delete(format(ApiConstant.DELETE_GET_POSITION, data.uid), {});

export const updateStaffPosition = data =>
  createApiRegistration().put(format(ApiConstant.PUSH_GET_POSITION, data.uid), data);

export const updateWorkingShift = data =>
  createApiRegistration().put(format(ApiConstant.PUSH_WORKING_SHIFT, data.uid), data);

export const getStaffTable = data => createApiRegistration().get(ApiConstant.GET_STAFF_TABLE, data);

export const getWorkingShift = () => createApiRegistration().get(ApiConstant.GET_WORKING_SHIFT, {});

export const getStaffPosition = () => createApiRegistration().get(ApiConstant.GET_STAFF_POSITION, {});

export const patchInvitationStaff = data => createApiRegistration().patch(ApiConstant.PATCH_INVITATION_STAFF, data);

export const putStaff = data => createApiRegistration().put(format(ApiConstant.UPDATE_STAFF, data.uid), data);

export const postStaff = data => createApiRegistration().post(ApiConstant.STAFF, data);

export const getWorkingShifts = data => createApiRegistration().get(ApiConstant.WORKING_SHIFT, data);

export const requestPosition = data => createApiRegistration().get(ApiConstant.STAFF_POSITION, data);

export const requestStaff = data => createApiRegistration().get(ApiConstant.STAFF, data);

export const deleteStaff = data => {
  return createApiRegistration().delete(format(ApiConstant.UPDATE_STAFF, data), {});
};

export const getStaffBusyStatus = data =>
  createApiReservation().get(ApiConstant.GET_STAFF_BUSY_STATUS, {
    start: data.start,
    end: data.end,
  });
