import React, { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  Dialog,
  Box,
  IconButton,
  Button,
  Typography,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Add, Close, Error } from "@material-ui/icons";
import clsx from "clsx";
import MnProductAction from "redux/mn-product.redux";
import {
  UploadImage,
  InputPrice,
  CategorySubcategoryMenu,
  Warning,
  CategoryAllMenu,
  UploadVideo,
  ValueTax,
} from "components/mn-master-product";
import { LangConstant, AppConstant } from "const";
import { Input, TextArea, Alert } from "components";
import { getCommonKey } from "utils";
import GroupClassifiedProduct from "./GroupClassifiedProduct";
import { TYPE_NOTIFY } from "./WatchAndEditProduct";

const CreateProductCategory = ({ data, isOpen, onClose, dataDetailsView, uidCategory, dataDetailsContent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const errorCreateItem = useSelector(state => state.mnProductRedux.errorCreateItem);

  const [isDuplicate, setIsDuplicate] = useState(false);
  const dialogRef = useRef(null);
  const [notify, setNotify] = useState({
    value: false,
    message: "",
    type: 0,
  });
  const modeViewAr = [
    { id: "category", name: getLabel(getCommonKey(LangConstant.TXT_CATEGORY)) },
    { id: "all", name: getLabel(getCommonKey(LangConstant.TXT_ALL)) },
  ];
  const [formInputProduct, setFormInputProduct] = useState({
    categoryUidList: uidCategory,
    name: "",
    image: "",
    price: "",
    priceTaxIn: "",
    costPrice: null,
    tax: 0,
    taxInclusiveF: 0,
    stock: "",
    description: "",
    details: { defaultId: AppConstant.TYPE_TAX.noTax },
  });
  const [taxTypeValue, setTaxTypeValue] = useState(AppConstant.TAX_TYPE_VALUE[AppConstant.TYPE_TAX.noTax]);
  const [isShowGroupClassifiedOne, setIsShowGroupClassifiedOne] = useState(false);

  const onTypingName = value => {
    setFormInputProduct({ ...formInputProduct, name: value });
  };

  const onChangeDescription = value => {
    setFormInputProduct({ ...formInputProduct, description: value.target.value });
  };
  const onChangeCostPrice = value => {
    setFormInputProduct({ ...formInputProduct, costPrice: parseInt(value) });
  };

  const onChangeStock = value => {
    setFormInputProduct({ ...formInputProduct, stock: parseInt(value) });
  };

  const onChangePrice = value => {
    const newPrice = value;
    const newPriceTaxIn = Math.round(newPrice * (1 + taxTypeValue));
    const tax = newPriceTaxIn - newPrice;
    setFormInputProduct({
      ...formInputProduct,
      price: newPrice,
      priceTaxIn: newPriceTaxIn,
      tax: tax,
    });
  };

  const onCloseNotify = () => {
    setNotify({
      value: false,
      message: "",
      type: 0,
    });
  };

  const onChangeTax = taxTypeId => {
    const newTaxTypeValue = AppConstant.TAX_TYPE_VALUE[taxTypeId];
    setTaxTypeValue(newTaxTypeValue);

    const newPriceTaxIn = Math.round(formInputProduct.price * (1 + newTaxTypeValue));
    const tax = newPriceTaxIn - formInputProduct.price;
    setFormInputProduct({
      ...formInputProduct,
      tax: tax,
      priceTaxIn: newPriceTaxIn,
      taxInclusiveF: Number(Boolean(taxTypeId)),
      details: { ...formInputProduct.details, defaultId: taxTypeId },
    });
  };

  const onChangeCategorySub = value => {
    setFormInputProduct({ ...formInputProduct, categoryUidList: value });
  };

  const onUploadVideo = (value, isError) => {
    let newFormValue = {
      ...formInputProduct,
    };
    if (isError) delete newFormValue.video;
    else newFormValue.video = value;

    setFormInputProduct(newFormValue);
  };

  const onChangeImage = (value, error) => {
    if (!error) {
      setFormInputProduct({ ...formInputProduct, imageList: value });
    } else {
      setNotify({
        value: true,
        message: error,
        type: 1,
      });
    }
  };

  const onChangeCategoryMenu = value => {
    if (value.category != undefined) {
      setFormInputProduct({ ...formInputProduct, itemDetailsUidList: value.category });
    } else {
      setFormInputProduct({
        ...formInputProduct,
        itemDetails: {
          name: formInputProduct.name,
          type: 3,
          description: formInputProduct.description,
          itemDetailsContentUidList: value.all,
          details: "",
        },
      });
    }
  };

  const addItem = () => {
    if (formInputProduct.name) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.createProductItem(formInputProduct));
    } else {
      setNotify({
        value: true,
        message: getLabel(LangConstant.TXT_NOTIFY_INPUT_NAME_GOOD),
        type: 2,
      });
    }
  };

  const onChangeDataGroup = data => {
    setFormInputProduct(data);
  };

  useEffect(() => {
    if (errorCreateItem) {
      if (errorCreateItem?.name?.[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dialogRef.current.querySelector(".MuiDialog-paperScrollPaper").scrollTop = 0;
        dispatch(MnProductAction.resetErrorMnProduct());
      }
    }
  }, [errorCreateItem]);

  return (
    <Dialog open={isOpen} fullScreen ref={dialogRef}>
      <Box className={classes.rootHeader}>
        <Typography className={classes.nameHeaderTypo}>{getLabel(LangConstant.L_CREATE_PRODUCT)} </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButtonClose}>
            <Close className={classes.iconClose} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <Box className={classes.infoBox}>
          <Box marginBottom="30px">
            <Box className={classes.paddingBox}>
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_INFORMATION_BASIC)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.FN_NOTICE_FILL)(getLabel(LangConstant.TXT_PRODUCT_LOWER_CASE))}
              </Typography>
            </Box>
            <Box className={classes.paddingBox}>
              <Box className={classes.infoBasic}>
                <Typography className={classes.nameComboTypo}>{getLabel(LangConstant.TXT_NAME_PRODUCT)}</Typography>
                <Typography color="primary" className={classes.isRequire}>
                  &nbsp;&#42;
                </Typography>
              </Box>
              <Input
                placeholder={getLabel(LangConstant.TXT_NAME_PRODUCT)}
                classes={{ root: classes.rootNameCombo, input: classes.inputNameCombo }}
                required
                onChange={onTypingName}
              />
              {isDuplicate && (
                <Box className={classes.warningName}>
                  <Error color="primary" className={classes.iconError} />
                  <Typography color="primary" className={classes.textWarning}>
                    {getLabel(LangConstant.TXT_WARNING_NAME_GOOD)}
                  </Typography>
                </Box>
              )}
              {notify.value && notify.type === TYPE_NOTIFY.warningName && (
                <Alert isShow={notify.value} onClose={onCloseNotify} type="error" message={notify.message} />
              )}
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.L_CATEGORY_GOODS)}</Typography>
              <Box>
                <CategorySubcategoryMenu
                  label={getLabel(LangConstant.L_CATEGORY_GOODS)}
                  customClasses={{ root: classes.categoryMenu }}
                  data={data}
                  itemsKey={"child"}
                  onChange={onChangeCategorySub}
                  selectedArr={uidCategory}
                />
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_ILLUSTRATION)}</Typography>
              <Box marginTop="8px">
                <UploadImage onChange={onChangeImage} />
                {notify.value && notify.type == TYPE_NOTIFY.warningImage && (
                  <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
                )}
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>
                {getLabel(LangConstant.TXT_ILLUSTRATION_VIDEO)}
              </Typography>
              <Box marginTop="8px">
                <UploadVideo defaultUrl={""} onChange={onUploadVideo} />
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_GOODS_DESCRIPTION)}</Typography>
              <TextArea
                onChange={onChangeDescription}
                placeholder={getLabel(LangConstant.TXT_GOODS_DESCRIPTION)}
                className={classes.comboDesTextArea}
                rowMax={4}
              />
            </Box>
          </Box>
          <Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.infoBasicTypo}>
                {getLabel(LangConstant.TXT_COSTS_AND_INVENTORY)}
              </Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.TXT_SETTING_COSTS_AND_INVENTORY_GOODS)}
              </Typography>
            </Box>
            <Box padding="8px 0 46px" display="flex">
              <Box minWidth="159px" marginRight="24px">
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_COST_PRICE)}</Typography>
                <InputPrice
                  currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                  onChange={onChangeCostPrice}
                  classPrice={classes.inputUsedTime}
                  valueInput=""
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className={classes.paddingBox}>
              <Typography variant="h6" className={classes.informationSales}>
                {getLabel(LangConstant.TXT_INFORMATION_SALES)}
              </Typography>
              <Typography variant="body2" className={classes.informationSalesFill}>
                {getLabel(LangConstant.TXT_FILL_IN_INFORMATION_SALES)}
              </Typography>
            </Box>
            {!isShowGroupClassifiedOne && (
              <>
                <Box className={classes.paddingBox}>
                  <Typography variant="subtitle2" className={classes.classificationProduct}>
                    {getLabel(LangConstant.TXT_PRODUCT_CLASSIFICATION)}
                  </Typography>
                  <Button
                    className={clsx(classes.buttonAdd, "regular-md-txt")}
                    startIcon={<Add />}
                    onClick={() => setIsShowGroupClassifiedOne(true)}
                  >
                    {getLabel(LangConstant.TXT_ADD_GROUP)}
                  </Button>
                </Box>
                <Box className={classes.paddingBox}>
                  <Typography variant="subtitle2" className={classes.classificationProduct}>
                    {getLabel(LangConstant.TXT_TAX_APPLY)}
                  </Typography>
                  <ValueTax onChange={onChangeTax} />
                </Box>
              </>
            )}
            {isShowGroupClassifiedOne && (
              <GroupClassifiedProduct
                onClose={() => setIsShowGroupClassifiedOne(false)}
                isShowGroupClassifiedOne={isShowGroupClassifiedOne}
                dataFormInput={formInputProduct}
                onChange={onChangeDataGroup}
              />
            )}
            {!isShowGroupClassifiedOne && (
              <>
                <Box className={classes.paddingBox}>
                  <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_WARE_HOUSE)}</Typography>
                  <Input
                    onChange={onChangeStock}
                    placeholder={"0"}
                    classes={{ root: classes.rootNameWarehouse, input: classes.inputNameCombo }}
                    value={formInputProduct.stock ? formInputProduct.stock : ""}
                  />
                </Box>
                <Box height="100px" display="flex">
                  <Box minWidth="159px" marginRight="24px">
                    <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_UNIT_PRICE)}</Typography>
                    <InputPrice
                      currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                      onChange={onChangePrice}
                      classPrice={classes.inputUsedTime}
                      valueInput=""
                    />
                  </Box>
                  <Box minWidth="159px" marginLeft="50px">
                    <Typography className={classes.inputTitleTypo}>
                      {getLabel(LangConstant.TXT_PRICE_AFTER_TAX)}
                    </Typography>
                    <InputPrice
                      currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                      onChange={onChangePrice}
                      classPrice={classes.inputUsedTime}
                      valueInput={formInputProduct.priceTaxIn ? formInputProduct.priceTaxIn.toString() : ""}
                      checkDisable={true}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_GOODS_INCLUDED)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.TXT_GOODS_INCLUDED_SERVICE)}
              </Typography>
            </Box>
            <Box>
              <CategoryAllMenu
                modeViewAr={modeViewAr}
                data={dataDetailsView}
                onChange={onChangeCategoryMenu}
                itemsKey="itemDetailsContentList"
                allItemData={dataDetailsContent}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button className={clsx(classes.buttonCancel, "semiBold-lg-txt")} onClick={onClose}>
          {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
        </Button>
        <Button onClick={addItem} onClose={onClose} className={clsx(classes.buttonSave, "semiBold-lg-txt")}>
          {getLabel(LangConstant.TXT_ADD_MASTER_PRODUCT)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButtonClose: {
    padding: 0,
  },
  iconClose: {
    width: 36,
    height: 36,
  },
  infoBox: {
    width: 430,
    marginLeft: "auto",
    marginRight: "auto",
  },
  infoBasic: {
    display: "flex",
    alignItems: "center",
    height: 22,
    marginBottom: 8,
  },
  DropdownMenuTax: {
    width: 160,
  },
  infoBasicTypo: {
    fontSize: 18,
    fontWeight: 600,
    height: 28,
    color: "#3e4045",
    lineHeight: 1.56,
  },
  nameComboTypo: {
    height: 22,
    lineHeight: 1.57,
    fontSize: 13,
    fontWeight: 600,
  },
  isRequire: {
    height: 22,
  },
  noticeFillTypo: {
    fontSize: 14,
    minHeight: 22,
    lineHeight: 1.57,
    marginTop: 8,
    color: "#3e4045",
  },
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  rootNameCombo: {
    width: 430,
    margin: 0,
    borderRadius: 3,
  },
  categoryMenu: {
    height: "max-content",
    wordWrap: "anywhere",
    width: 430,
    border: `solid 1px ${theme.palette.grey[200]}`,
    color: "#d4d5d8",
    padding: "10px 16px 10px",
  },
  rootNameWarehouse: {
    width: 159,
    margin: 0,
    borderRadius: 3,
  },
  inputNameCombo: {
    height: 22,
    padding: "9px 16px",
    fontSize: 14,
    lineHeight: 1.57,
  },
  comboDesTextArea: {
    height: 80,
    margin: 0,
    padding: "8px 16px",
    borderRadius: 3,
  },
  inputUsedTime: {
    width: 158,
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  buttonSave: {
    height: 46,
    padding: 0,
    minWidth: 102,
    borderRadius: 2,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
  informationSales: {
    fontSize: 18,
  },
  informationSalesFill: {
    paddingTop: 10,
  },
  classificationProduct: {
    fontSize: 13,
  },
  buttonAdd: {
    height: 40,
    width: "100%",
    border: "1px solid #447AFF",
    boxSizing: "border-box",
    borderRadius: 3,
    textTransform: "none",
    marginTop: 15,
    color: theme.palette.text.link,
  },
  dialogAction: {
    justifyContent: "center",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.103529)",
    padding: "30px 0",
  },
  buttonCancel: {
    height: 46,
    padding: 0,
    minWidth: 102,
    borderRadius: 3,
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    textTransform: "none",
    border: "1px solid #D4D5D8",
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  paddingBox: {
    padding: "8px 0 16px",
  },
}));

export default memo(CreateProductCategory);
CreateProductCategory.propTypes = {
  data: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  dataDetailsView: PropTypes.array.isRequired,
  uidCategory: PropTypes.array,
};

CreateProductCategory.defaultProps = { uidCategory: [] };
