import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import MultiSelectMenuSearchBar from "./MultiSelectMenuSearchBar";

const ChipMenuIFilter = forwardRef(({ onFilter, onClearFilter, value }, ref) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.container }} ref={ref}>
      <MultiSelectMenuSearchBar
        placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)}
        className={classes.searchBar}
        customClasses={{
          input: clsx(classes.searchBarInput, "regular-sm-txt"),
          icon: classes.searchBarIcon,
          searchIcon: classes.searchBarSearchIcon,
          closeIcon: classes.searchBarCloseIcon,
        }}
        enableClear={true}
        onChange={onFilter}
        onClear={onClearFilter}
        value={value}
      />
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  container: {
    height: 38,
    width: "100%",
    display: "flex",
    padding: "7px 16px",
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
  },
  searchBar: {
    width: "100%",
    cursor: "pointer",
    paddingRight: 0,
  },
  searchBarViewMode: {
    width: "100%",
    cursor: "pointer",
    marginLeft: 8,
    paddingRight: 0,
  },
  searchBarInput: {
    height: "unset",
    padding: "0 20px 0 0",
    color: theme.palette.text.primary,
    "&::placeholder": {
      color: theme.palette.grey["400"],
      opacity: 1,
    },
    "@media only screen and (max-width: 375px)": {
      padding: "0 10px",
    },
  },
  searchBarIcon: {
    minWidth: "40px",
    width: "40px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "24px",
    justifyContent: "center",
    marginRight: 0,
    "@media only screen and (max-width: 375px)": {
      minWidth: "40px",
      width: "40px",
    },
  },
  searchBarSearchIcon: {
    fontSize: 16,
    color: theme.palette.grey["400"],
  },
  searchBarCloseIcon: {
    fontSize: 10,
    color: theme.palette.grey["500"],
  },
  viewModeContainer: {
    minWidth: "max-content",
    height: 22,
    paddingTop: 2,
    background: "initial",
    borderBottom: "none",
  },
  activeMode: {
    height: "100%",
    minHeight: "100%",
    width: "fit-content",
    minWidth: "fit-content",
    color: theme.palette.white,
    background: theme.palette.text.link,
    textTransform: "none",
    borderRadius: "2px",
    "&:hover": {
      background: theme.palette.text.link,
    },
    padding: "4px 4px",
    marginRight: 8,
  },
  inactiveMode: {
    height: "100%",
    minHeight: "100%",
    width: "fit-content",
    minWidth: "fit-content",
    color: "#7f838c",
    background: theme.palette.grey[100],
    textTransform: "none",
    borderRadius: "2px",
    "&:hover": {
      background: theme.palette.grey[100],
    },
    padding: "4px 4px",
    marginRight: 8,
  },
}));

ChipMenuIFilter.propTypes = {
  customClasses: PropTypes.object,
  enableChangeViewMode: PropTypes.bool,
  onFilter: PropTypes.func,
  onChangeMode: PropTypes.func,
  onClearFilter: PropTypes.func,
  selectedId: PropTypes.string.isRequired,
  value: PropTypes.string,
};

ChipMenuIFilter.defaultProps = {
  customClasses: {},
  enableChangeViewMode: false,
  selectedId: "",
  onFilter: () => {},
  onChangeMode: () => {},
  onClearFilter: () => {},
  value: "",
};

export default memo(ChipMenuIFilter);
