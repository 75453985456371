import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Accordion, AccordionSummary, Typography, Box, IconButton, Grid, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ExpandMore, AddCircle, DeleteOutlined, CreateOutlined } from "@material-ui/icons";
import StringFormat from "string-format";
import clsx from "clsx";
import PlaceCategoryAction from "redux/place-category.redux";
import PlaceItemAction from "redux/place-item.redux";
import { getCommonKey } from "utils";
import { LangConstant } from "const";
import EditDialog from "pages/MnShopInfo/components/AdvancedInfo/CategoryAccordion/SubCategoryAccordion/EditDialog";
import PlaceDialog from "pages/MnShopInfo/components/AdvancedInfo/CategoryAccordion/PlaceDialog";
import ConfirmDialog from "pages/MnShopInfo/components/AdvancedInfo/CategoryAccordion/ConfirmDialog";
import SubPlaceCategory from "./SubPlaceCategory";
import DeleteDialog from "./DeleteDialog";

const BodyPlaceCategory = ({ data, className, style, onDelete: onDeletePlaceCategory }) => {
  const placeItemRedux = useSelector(state => state.placeItemRedux);
  const placeItemFetchData = placeItemRedux.data;
  const { t: getLabel } = useTranslation(LangConstant.NS_PLACE_MANAGE);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [addPlaceItemDialog, setAddPlaceItemDialog] = useState(false);
  const [placeCategory, setPlaceCategory] = useState(data);
  const [apiType, setApiType] = useState();
  const [openPlace, setOpenPlace] = useState(false);
  const [dataState, setDataState] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [newPlaceCategory, setNewPlaceCategory] = useState({});
  const [open, setOpen] = useState(false);
  const [totalPlaceCapacity, setTotalPlaceCapacity] = useState(0);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [categoryItem, setCategoryItem] = useState(placeItemFetchData);

  const triangleToggle = () => {
    setOpen(!open);
  };

  const onOpenAddDialog = event => {
    event.stopPropagation();
    setAddPlaceItemDialog(true);
  };

  const onOpenEditDialog = (event, data) => {
    setDataState(data);
    event.stopPropagation();
    setOpenPlace(true);
  };

  const onSubmitPlaceCategory = (event, newData) => {
    if (newData.updateAll === 0 && newData.name !== "") {
      if (newData.totalAmount === 0) {
        dispatch(PlaceCategoryAction.updatePlaceCategory(newData));
        setOpenPlace(false);
      } else {
        setNewPlaceCategory(newData);
        setOpenPlace(false);
        setOpenConfirm(true);
      }
    } else if (newData.name !== "") {
      dispatch(PlaceCategoryAction.updatePlaceCategory(newData));
      setOpenPlace(false);
    }
  };

  const onConfirmCategory = (event, isAgree) => {
    if (isAgree) {
      dispatch(PlaceCategoryAction.updatePlaceCategory({ ...newPlaceCategory }));
      setOpenConfirm(false);
    } else {
      setOpenConfirm(false);
    }
  };

  const onCloseDialog = () => {
    setOpenPlace(false);
  };

  const onAddItem = (event, newData) => {
    event.stopPropagation();

    if (newData.name !== null && newData.name !== "" && newData.capacity !== null && newData.capacity !== "") {
      event.preventDefault();
      dispatch(
        PlaceItemAction.createPlaceItem({
          categoryUid: placeCategory.uid,
          name: newData.name,
          capacity: newData.capacity,
        }),
      );
      setApiType(TYPE_API_PLACE.API_CREATE_PLACE_ITEM);
    }
  };

  const onDeleteCategory = event => {
    event.stopPropagation();
    onDeletePlaceCategory(event, placeCategory.uid);
  };

  const onDeletePlaceItem = (event, itemUid) => {
    event.stopPropagation();

    dispatch(PlaceItemAction.removePlaceItem({ uid: itemUid }));
    setApiType(TYPE_API_PLACE.API_DELETE_PLACE_ITEM);
  };

  const onCloseAddDialog = event => {
    event.stopPropagation();
    setAddPlaceItemDialog(false);
  };
  const onCloseDeleteDialog = () => {
    setIsShowDeleteDialog(false);
  };

  useEffect(() => {
    if (
      apiType === TYPE_API_PLACE.API_CREATE_PLACE_ITEM &&
      placeItemFetchData != null &&
      !placeItemRedux.isCreateFetching
    ) {
      setCategoryItem(placeItemFetchData);
      setApiType(null);
      setAddPlaceItemDialog(false);
    } else if (
      apiType === TYPE_API_PLACE.API_DELETE_PLACE_ITEM &&
      placeItemFetchData != null &&
      !placeItemRedux.isRemoveFetching
    ) {
      let editedCategory = { ...placeCategory };
      delete editedCategory.items[placeItemFetchData.uid];

      setApiType(null);
      setPlaceCategory(editedCategory);
    }
    if (placeItemFetchData) {
      let tmpTotal = 0;
      Object.values(data.items).map(item => {
        tmpTotal += item.capacity;
      });
      setTotalPlaceCapacity(tmpTotal);
    }
  }, [placeItemFetchData]);

  useEffect(() => {
    if (data) {
      setPlaceCategory(data);
      let tmpTotal = 0;
      Object.values(data.items).map(item => {
        tmpTotal += item.capacity;
      });
      setTotalPlaceCapacity(tmpTotal);
    }
  }, [data]);

  return (
    <Box className={`${classes.boxRoot} ${className}`} style={style}>
      <Accordion square={true} classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          aria-controls="panel1c-content"
          id="panel1c-header"
          classes={{ root: classes.sumaryRoot, content: classes.summaryContent, expanded: classes.summaryExpand }}
        >
          <Grid container className={classes.summaryBox} onClick={triangleToggle}>
            <Grid item xs={2} className={classes.boxExpand}>
              <Grid item xs={4} className={classes.expandButton}>
                {placeCategory.items && Object.keys(placeCategory.items).length > 0 && (
                  <ExpandMore className={clsx(!open && classes.close, open && classes.open)} />
                )}
              </Grid>
              <Grid item xs={8} className={classes.placeContainer}>
                <Typography variant="subtitle2" className={clsx("medium-md-txt", classes.textHeading)} color="inherit">
                  {placeCategory.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.itemBox} xs={4}>
              {placeCategory.items && (
                <Typography variant="subtitle2" className={clsx("medium-md-txt", classes.textHeading)}>
                  {Object.keys(data.items).length}
                </Typography>
              )}
            </Grid>
            <Grid item className={classes.itemBox} xs={3}>
              <Typography variant="subtitle2" className={clsx("medium-md-txt", classes.textHeading)}>
                {Boolean(totalPlaceCapacity) &&
                  StringFormat(getLabel(LangConstant.FM_CONSUMER_CAPACITY), totalPlaceCapacity)}
              </Typography>
            </Grid>
            <Grid item style={{ textAlign: "right" }} xs={3}>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={
                  <Typography className={`${classes.popoverCopyIcon} medium-sm-txt`}>
                    {getLabel(getCommonKey(LangConstant.TXT_ADD_NEW))}
                  </Typography>
                }
              >
                <IconButton onClick={e => onOpenAddDialog(e)} className={clsx(classes.iconButton, classes.addBtn)}>
                  <AddCircle className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={
                  <Typography className={`${classes.popoverCopyIcon} medium-sm-txt`}>
                    {getLabel(getCommonKey(LangConstant.TXT_EDIT))}
                  </Typography>
                }
              >
                <IconButton
                  onClick={e => onOpenEditDialog(e, placeCategory)}
                  className={clsx(classes.iconButton, classes.editBtn)}
                >
                  <CreateOutlined className={classes.icon} />
                </IconButton>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={
                  <Typography className={`${classes.popoverCopyIcon} medium-sm-txt`}>
                    {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
                  </Typography>
                }
              >
                <IconButton
                  onClick={event => {
                    event.stopPropagation();
                    setIsShowDeleteDialog(true);
                  }}
                  className={clsx(classes.iconButton, classes.deleteBtn)}
                >
                  <DeleteOutlined className={classes.icon}> </DeleteOutlined>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>
        {Object.keys(placeCategory.items).map(key => (
          <SubPlaceCategory
            key={key}
            data={placeCategory.items[key]}
            placeItemData={categoryItem}
            titleName={placeCategory.name}
            onDelete={onDeletePlaceItem}
          />
        ))}
      </Accordion>
      {addPlaceItemDialog && (
        <EditDialog
          onClose={onCloseAddDialog}
          titleName={placeCategory.name}
          onSubmit={onAddItem}
          isAdd={true}
        ></EditDialog>
      )}
      {openPlace && (
        <PlaceDialog onClose={onCloseDialog} onSubmit={onSubmitPlaceCategory} data={dataState}></PlaceDialog>
      )}
      {openConfirm && (
        <ConfirmDialog onSelectConfirm={onConfirmCategory} categoryName={newPlaceCategory.name}></ConfirmDialog>
      )}
      {isShowDeleteDialog && (
        <DeleteDialog
          isShow={isShowDeleteDialog}
          onClose={onCloseDeleteDialog}
          title={StringFormat(getLabel(LangConstant.FM_DELETE_PLACE_CATEGORY), placeCategory.name)}
          instruction={StringFormat(getLabel(LangConstant.FM_CONFIRM_DELETE_TEXT), placeCategory.name)}
          onConfirm={onDeleteCategory}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    width: "100%",
    justifyContent: "space-between",
  },
  accordionRoot: {
    height: "100%",
    backgroundColor: "#fafafb",
  },
  summaryRoot: {},
  summaryExpand: {
    minHeight: "50px !important",
    height: 50,

    "&.MuiIconButton-root": {
      border: "unset !important",
      textAlign: "right",
    },
    "&:first-child": {
      borderBottom: `solid 0.5px ${theme.palette.grey[300]}`,
    },
  },
  summaryContent: {
    margin: "0px 0px",
  },
  summaryBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  boxExpand: {
    display: "flex",
  },
  expandButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  open: {
    transform: "scaleY(-1)",
  },
  close: {
    transform: "scaleY(1)",
  },
  expandMore: {
    "&:onClick": {
      transform: "rotateY(180deg)",
    },
  },
  placeContainer: {
    paddingLeft: "10%",
  },
  itemBox: {
    textAlign: "center",
  },
  textHeading: {
    color: theme.palette.grey[600],
  },
  iconExpand: {
    color: theme.palette.grey[500],
  },
  iconButton: {
    borderRadius: 2,
    marginRight: 19,
    padding: 4,
    border: "1px solid transparent",
  },
  addBtn: {
    "&:hover": {
      border: "1px solid #447aff",
      "&>span>svg": {
        color: theme.palette.text.link,
      },
    },
  },
  editBtn: {
    "&:hover": {
      border: "1px solid #447aff",
      "&>span>svg": {
        color: theme.palette.text.link,
      },
    },
  },
  deleteBtn: {
    "&:hover": {
      border: "1px solid #ef5845",
      "&>span>svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  icon: {
    color: theme.palette.grey[500],
    width: 21,
    height: 21,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tooltip: {
    width: 80,
    height: 16,
    flexGrow: 0,
    margin: "2px 0",
    borderRadius: 1,
    backgroundColor: "#7f838c",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 50,
  },
  popoverCopyIcon: {
    width: "100%",
    height: 20,
    flexGrow: 0,
    lineHeight: 1.67,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const TYPE_API_PLACE = {
  API_CREATE_PLACE_ITEM: 1,
  API_UPDATE: 2,
  API_DELETE_PLACE_ITEM: 3,
};

BodyPlaceCategory.propTypes = {
  style: PropTypes.object,
  data: PropTypes.object,
  className: PropTypes.string,
};

BodyPlaceCategory.defaultProps = { style: {} };

export default memo(BodyPlaceCategory);
