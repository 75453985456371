import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment, IconButton } from "@material-ui/core";
import { VisibilityOutlined, VisibilityOffOutlined, LockOutlined } from "@material-ui/icons";
import Input from "./Input";
import FormInput from "./FormInput";

const InputPassword = props => {
  const { isIcon, ...otherProps } = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onMouseDownPassword = event => {
    event.preventDefault();
  };

  const commonProps = {
    className: classes.root,
    type: showPassword ? "text" : "password",
    endAdornment: (
      <InputAdornment position="end" className={classes.icon}>
        <IconButton
          aria-label="toggle password visibility"
          onClick={onShowPassword}
          onMouseDown={onMouseDownPassword}
          color="inherit"
        >
          {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
        </IconButton>
      </InputAdornment>
    ),
    required: true,
    ...otherProps,
  };

  return isIcon ? <FormInput icon={<LockOutlined />} {...commonProps} /> : <Input {...commonProps} />;
};

const useStyles = makeStyles(theme => ({
  root: { paddingRight: 0 },
  icon: {
    marginLeft: 0,
    color: theme.palette.grey[400],
  },
}));

InputPassword.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  isIcon: PropTypes.bool,

  onChange: PropTypes.func,
};
InputPassword.defaultProps = { isIcon: true };

export default memo(InputPassword);
