import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const WaitingConsumerIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z" transform="translate(-1200 -18) translate(1200 18)" />
        <g fill="#ffffff" transform="translate(-1200 -18) translate(1200 18) translate(1 2)">
          <circle cx="10.5" cy="4.5" r="4.5" />
          <path d="M10.976 10.026c-.261-.013-.522-.026-.796-.026-2.874 0-5.559.861-7.852 2.34-1.045.669-1.65 1.929-1.65 3.253V19h11c-.94-1.453-1.498-3.227-1.498-5.143 0-1.376.297-2.661.796-3.831zM18.667 8h-3.334v1.636h3.334V8zm-2.5 9h1.666v-3.818h-1.666V17zm4.739-4.97l.788-.774c-.238-.278-.5-.54-.783-.769l-.789.775c-.86-.677-1.944-1.08-3.122-1.08-2.761 0-5 2.198-5 4.909C12 17.8 14.233 20 17 20s5-2.198 5-4.91c0-1.15-.411-2.214-1.094-3.06zM17 18.365c-1.843 0-3.333-1.464-3.333-3.273 0-1.81 1.49-3.273 3.333-3.273 1.843 0 3.333 1.464 3.333 3.273 0 1.81-1.49 3.273-3.333 3.273z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default memo(WaitingConsumerIcon);
