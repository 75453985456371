import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestPostWorkingShift: ["data"],
  postWorkingShiftSuccess: ["data"],
  postWorkingShiftFailure: ["data"],

  requestPostPosition: ["data"],
  postPositionSuccess: ["data"],
  postPositionFailure: ["data"],

  getWorkingPotision: ["data"],
  getWorkingPotisionSuccess: ["data"],
  getWorkingPotisionFailure: ["data"],

  getWorking: ["data"],
  getWorkingSuccess: ["data"],
  getWorkingFailure: ["data"],

  removePostWorking: ["data"],
  removePostWorkingSuccess: ["data"],
  removePostWorkingFailure: ["data"],

  removeStaffPosition: ["data"],
  removeStaffPositionSuccess: ["data"],
  removeStaffPositionFailure: ["data"],

  requestCreateStaff: ["data"],
  createStaffSuccess: ["data"],
  createStaffFailure: ["data"],

  requestEditStaff: ["data"],
  editStaffSuccess: ["data"],
  editStaffFailure: ["data"],
  resetSuccess: ["data"],

  requestWorkingShifts: ["data"],
  getWorkingShiftSuccess: ["data"],
  getWorkingShiftFailure: ["data"],

  requestStaff: ["data"],
  getStaffSuccess: ["data"],
  getStaffFailure: ["data"],

  requestPosition: ["data"],
  getPositionSuccess: ["data"],
  getPositionFailure: ["data"],

  updateStaffPosition: ["data"],
  updateStaffPositionSuccess: ["data"],
  updateStaffPositionFailure: ["data"],

  updateWorkingShift: ["data"],
  updateWorkingShiftSuccess: ["data"],
  updateWorkingShiftFailure: ["data"],

  requestStaffTable: ["data"],
  staffTableSuccess: ["data"],
  staffTableFailure: ["data"],

  requestWorkingShift: ["data"],
  workingShiftSuccess: ["data"],
  workingShiftFailure: ["data"],

  requestStaffPosition: ["data"],
  staffPositionSuccess: ["data"],
  staffPositionFailure: ["data"],

  patchInvitationStaff: ["data"],
  invitationStaffSuccess: ["data"],
  invitationStaffFailure: ["data"],

  deleteStaff: ["data"],
  deleteStaffSuccess: ["data"],
  deleteStaffFailure: ["data"],

  getStaffBusyStatus: ["data"],
  getStaffBusyStatusSuccess: ["data"],
  getStaffBusyStatusFailure: ["data"],

  resetStaff: ["data"],
});

export const StaffTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isFetchingUpdateWorkingShift: false,
  isSuccessUpdatesWorkingShift: false,
  isSuccesUpdatesWorkingShift: false,
  isFetchingrUpdatePosition: false,
  isRemoveFetchingPosition: false,
  isRemovePositionSuccess: false,
  isFetchingWorkingShift: false,
  isSuccessWorking: false,
  isFetchingWork: false,

  updateDataPosition: null,
  updateDataWorkingShift: null,
  error: null,
  isSuccess: false,
  isFetchingPosition: false,
  errorPosition: null,
  isSuccessPosition: false,

  errorRemove: null,
  isRemoveSuccess: false,
  isremovePostWorking: false,

  isFetchingCreateStaff: false,
  errorCreateStaff: null,
  isSuccessCreateStaff: false,
  newStaff: null,

  isFetchingStaffTable: false,
  errorStaffTable: null,
  isStaffTable: false,
  data: null,

  errorWorkingShift: null,
  isWorkingShift: false,
  workingShift: null,

  isFetchingStaffPosition: false,
  errorStaffPosition: null,
  isStaffPosition: false,
  staffPosition: null,

  isFetchingInvitationStaff: false,
  errorInvitationStaff: null,
  isInvitationStaffSuccess: false,
  invitationStaff: null,

  dataEditStaff: null,
  isFetchingEditStaff: false,
  errorEditStaff: null,
  isSuccessEditStaff: false,

  workingShifts: [],
  isFetchingGetWorkingShift: false,
  errorGetWorkingShift: null,
  isSuccessGetWorkingShift: false,

  staff: [],
  isFetchingGetStaff: false,
  errorGetStaff: null,
  isSuccessGetStaff: false,

  position: [],
  isFetchingGetPosition: false,
  errorGetPosition: null,
  isSuccessGetPosition: false,

  newPosition: {},
  newWorkShift: {},

  isFetchingDeleteStaff: false,
  successDeleteStaff: false,
  errorDeleteStaff: null,

  isFetchingGetStaffBusyStatus: false,
  isSuccessGetStaffBusyStatus: false,
  staffBusyStatus: [],
};

/* ------------- Reducers ------------- */
export const requestPostWorkingShift = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  error: null,
  isSuccess: false,
});

export const postWorkingShiftSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    newWorkShift: action.data,
    isFetching: false,
    error: null,
    isSuccess: true,
  };
};

export const postWorkingShiftFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
  isSuccess: false,
});

/* ------------- Reducers ------------- */
export const requestPostPosition = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingPosition: true,
  errorPosition: null,
  isSuccessPosition: false,
});

export const postPositionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    newPosition: action.data,
    isFetchingPosition: false,
    errorPosition: null,
    isSuccessPosition: true,
  };
};

export const postPositionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingPosition: false,
  errorPosition: action.error,
  isSuccessPosition: false,
});

/* ------------- Reducers ------------- */
export const requestCreateStaff = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingCreateStaff: true,
  errorCreateStaff: null,
  isSuccessCreateStaff: false,
});

export const createStaffSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingCreateStaff: false,
    errorCreateStaff: null,
    isSuccessCreateStaff: true,
    newStaff: action.data,
  };
};

export const createStaffFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingCreateStaff: false,
  errorCreateStaff: action.error,
  isSuccessCreateStaff: false,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingCreateStaff: false,
  successDeleteStaff: false,
  isSuccessCreateStaff: false,
  isSuccessEditStaff: false,
  isRemoveSuccess: false,
});
/* ------------- Reducers ------------- */
export const requestEditStaff = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingEditStaff: true,
  errorEditStaff: null,
  isSuccessEditStaff: false,
});

export const editStaffSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingEditStaff: false,
    errorEditStaff: null,
    isSuccessEditStaff: true,
    dataEditStaff: action.data,
  };
};
export const resetSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isSuccessEditStaff: false,
  };
};

export const editStaffFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingEditStaff: false,
  errorEditStaff: action.error,
  isSuccessEditStaff: false,
});

export const requestWorkingShifts = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetWorkingShift: true,
  errorGetWorkingShift: null,
  isSuccessGetWorkingShift: false,
});
export const getWorkingShiftSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  workingShifts: [action],
  isFetchingGetWorkingShift: false,
  errorGetWorkingShift: null,
  isSuccessGetWorkingShift: true,
});

export const getWorkingShiftFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetWorkingShift: false,
  errorGetWorkingShift: action.error,
  isSuccessGetWorkingShift: false,
});

export const requestStaff = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetStaff: true,
  errorGetStaff: null,
  isSuccessGetStaff: false,
});

export const getStaffSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  staff: action.data,
  isFetchingGetStaff: false,
  errorGetStaff: null,
  isSuccessGetStaff: true,
});

export const getStaffFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetStaff: false,
  errorGetStaff: action.error,
  isSuccessGetStaff: false,
});

export const requestPosition = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetPosition: true,
  errorGetPosition: null,
  isSuccessGetPosition: false,
});

export const getPositionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    position: [action],
    isFetchingGetPosition: false,
    errorGetPosition: null,
    isSuccessGetPosition: true,
  };
};

export const getPositionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetPosition: false,
  errorGetPosition: action.error,
  isSuccessGetPosition: false,
});

/* ------------- Reducers ------------- */

/* ------------- Staff Table ------------- */
export const requestStaffTable = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingStaffTable: true,
});

export const staffTableSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingStaffTable: false,
    isStaffTable: true,
    data: action.data,
  };
};

export const staffTableFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingStaffTable: false,
  errorStaffTable: action.error,
});

/* ------------- Working Shift List ------------- */
export const requestWorkingShift = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingWorkingShift: true,
});

export const workingShiftSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingWorkingShift: false,
    isWorkingShift: true,
    workingShift: action.data,
  };
};

export const workingShiftFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingWorkingShift: false,
  errorWorkingShift: action.error,
});

/* ------------- Staff Position List ------------- */
export const requestStaffPosition = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingStaffPosition: true,
});

export const staffPositionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingStaffPosition: false,
    isStaffPosition: true,
    staffPosition: action.data,
  };
};

export const staffPositionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingStaffPosition: false,
  errorStaffPosition: action.error,
});

/* ------------- Staff Position List ------------- */
export const patchInvitationStaff = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingInvitationStaff: true,
});

export const invitationStaffSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingInvitationStaff: false,
    isInvitationStaffSuccess: true,
    invitationStaff: action.data,
  };
};

export const invitationStaffFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingInvitationStaff: false,
  errorInvitationStaff: action.error,
});

export const getWorkingPotision = (state = INITIAL_STATE) => ({
  ...state,
  isSuccessWorkingShift: false,
});

export const getWorkingPotisionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingWorkingShift: false,
    isSuccessWorkingShift: true,
    dataWorkKingShift: action.data,
  };
};

export const getWorkingPotisionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingWorkingShift: false,
  isSuccessWorkingShift: false,
  error: action.error,
});

export const getWorking = (state = INITIAL_STATE) => ({
  ...state,
  isSuccessWorking: false,
});

export const getWorkingSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingWork: false,
    isSuccessWorking: true,
    dataWork: action.data,
  };
};

export const getWorkingFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingWork: false,
  isSuccessWorking: false,
  error: action.error,
});

export const removePostWorking = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveSuccess: false,
});

export const removePostWorkingSuccess = (state = INITIAL_STATE, action) => {
  let placeDeleted = { ...state.data };
  delete placeDeleted[action.data.uid];
  return {
    ...state,
    isremovePostWorking: false,
    isRemoveSuccess: true,
    data: placeDeleted,
  };
};

export const removePostWorkingFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isremovePostWorking: false,
  isRemoveSuccess: false,
  error: action.error,
});

export const removeStaffPosition = (state = INITIAL_STATE) => ({
  ...state,
  isRemovePositionSuccess: false,
});

export const removeStaffPositionSuccess = (state = INITIAL_STATE, action) => {
  let placeDeleted = { ...state.data };
  delete placeDeleted[action.data.uid];
  return {
    ...state,
    isRemoveFetchingPosition: false,
    isRemovePositionSuccess: true,
    data: placeDeleted,
  };
};

export const removeStaffPositionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetchingPosition: false,
  isRemovePositionSuccess: false,
  error: action.error,
});

export const updateStaffPosition = (state = INITIAL_STATE) => ({
  ...state,
  isSuccessrUpdatePosition: false,
});

export const updateStaffPositionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingrUpdatePosition: false,
    updateDataPosition: action.data,
    isSuccessrUpdatePosition: true,
  };
};

export const updateStaffPositionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingrUpdatePosition: false,
  isSuccessrUpdatePosition: false,
  error: action.error,
});

export const updateWorkingShift = (state = INITIAL_STATE) => ({
  ...state,
  isSuccessUpdatesWorkingShift: false,
});

export const updateWorkingShiftSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUpdateWorkingShift: false,
    updateDataWorkingShift: action.data,
    isSuccessUpdatesWorkingShift: true,
  };
};

export const updateWorkingShiftFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingUpdateWorkingShift: false,
  isSuccessUpdateWorkingShift: false,
  error: action.error,
});

export const deleteStaff = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingDeleteStaff: true,
});

export const deleteStaffSuccess = (state = INITIAL_STATE) => ({
  ...state,
  successDeleteStaff: true,
});

export const deleteStaffFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
});

export const getStaffBusyStatus = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetStaffBusyStatus: true,
});
export const getStaffBusyStatusSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingGetStaffBusyStatus: false,
    staffBusyStatus: action.data,
  };
};

export const getStaffBusyStatusFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetStaffBusyStatus: false,
  isSuccessGetStaffBusyStatus: false,
  error: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  // post working shift
  [Types.REQUEST_POST_WORKING_SHIFT]: requestPostWorkingShift,
  [Types.POST_WORKING_SHIFT_SUCCESS]: postWorkingShiftSuccess,
  [Types.POST_WORKING_SHIFT_FAILURE]: postWorkingShiftFailure,
  // post position
  [Types.REQUEST_POST_POSITION]: requestPostPosition,
  [Types.POST_POSITION_SUCCESS]: postPositionSuccess,
  [Types.POST_POSITION_FAILURE]: postPositionFailure,
  //get potison
  [Types.GET_WORKING_POTISION]: getWorkingPotision,
  [Types.GET_WORKING_POTISION_SUCCESS]: getWorkingPotisionSuccess,
  [Types.GET_WORKING_POTISION_FAILURE]: getWorkingPotisionFailure,
  //get working shirt
  [Types.GET_WORKING]: getWorking,
  [Types.GET_WORKING_SUCCESS]: getWorkingSuccess,
  [Types.GET_WORKING_FAILURE]: getWorkingFailure,
  //remove working shirt :
  [Types.REMOVE_POST_WORKING]: removePostWorking,
  [Types.REMOVE_POST_WORKING_SUCCESS]: removePostWorkingSuccess,
  [Types.REMOVE_POST_WORKING_FAILURE]: removePostWorkingFailure,
  // remove posittion
  [Types.REMOVE_STAFF_POSITION]: removeStaffPosition,
  [Types.REMOVE_STAFF_POSITION_SUCCESS]: removeStaffPositionSuccess,
  [Types.REMOVE_STAFF_POSITION_FAILURE]: removeStaffPositionFailure,
  //update position
  [Types.UPDATE_STAFF_POSITION]: updateStaffPosition,
  [Types.UPDATE_STAFF_POSITION_SUCCESS]: updateStaffPositionSuccess,
  [Types.UPDATE_STAFF_POSITION_FAILURE]: updateStaffPositionFailure,
  //update working shift
  [Types.UPDATE_WORKING_SHIFT]: updateWorkingShift,
  [Types.UPDATE_WORKING_SHIFT_SUCCESS]: updateWorkingShiftSuccess,
  [Types.UPDATE_WORKING_SHIFT_FAILURE]: updateWorkingShiftFailure,

  //create staff
  [Types.REQUEST_CREATE_STAFF]: requestCreateStaff,
  [Types.CREATE_STAFF_SUCCESS]: createStaffSuccess,
  [Types.CREATE_STAFF_FAILURE]: createStaffFailure,

  //edit staff
  [Types.REQUEST_EDIT_STAFF]: requestEditStaff,
  [Types.EDIT_STAFF_SUCCESS]: editStaffSuccess,
  [Types.EDIT_STAFF_FAILURE]: editStaffFailure,
  [Types.RESET_SUCCESS]: resetSuccess,

  //working shift
  [Types.REQUEST_WORKING_SHIFTS]: requestWorkingShifts,
  [Types.GET_WORKING_SHIFT_SUCCESS]: getWorkingShiftSuccess,
  [Types.GET_WORKING_SHIFT_FAILURE]: getWorkingShiftFailure,

  // get Staff
  [Types.REQUEST_STAFF]: requestStaff,
  [Types.GET_STAFF_SUCCESS]: getStaffSuccess,
  [Types.GET_STAFF_FAILURE]: getStaffFailure,

  //get position
  [Types.REQUEST_POSITION]: requestPosition,
  [Types.GET_POSITION_SUCCESS]: getPositionSuccess,
  [Types.GET_POSITION_FAILURE]: getPositionFailure,

  [Types.REQUEST_STAFF_TABLE]: requestStaffTable,
  [Types.STAFF_TABLE_SUCCESS]: staffTableSuccess,
  [Types.STAFF_TABLE_FAILURE]: staffTableFailure,

  [Types.REQUEST_WORKING_SHIFT]: requestWorkingShift,
  [Types.WORKING_SHIFT_SUCCESS]: workingShiftSuccess,
  [Types.WORKING_SHIFT_FAILURE]: workingShiftFailure,

  [Types.REQUEST_STAFF_POSITION]: requestStaffPosition,
  [Types.STAFF_POSITION_SUCCESS]: staffPositionSuccess,
  [Types.STAFF_POSITION_FAILURE]: staffPositionFailure,

  [Types.PATCH_INVITATION_STAFF]: patchInvitationStaff,
  [Types.INVITATION_STAFF_SUCCESS]: invitationStaffSuccess,
  [Types.INVITATION_STAFF_FAILURE]: invitationStaffFailure,

  [Types.DELETE_STAFF]: deleteStaff,
  [Types.DELETE_STAFF_SUCCESS]: deleteStaffSuccess,
  [Types.DELETE_STAFF_FAILURE]: deleteStaffFailure,

  [Types.GET_STAFF_BUSY_STATUS]: getStaffBusyStatus,
  [Types.GET_STAFF_BUSY_STATUS_SUCCESS]: getStaffBusyStatusSuccess,
  [Types.GET_STAFF_BUSY_STATUS_FAILURE]: getStaffBusyStatusFailure,

  [Types.RESET_STAFF]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
