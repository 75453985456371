import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { debounce } from "@material-ui/core";
import { TablePagination } from "components/table";
import { AppConstant } from "const";
import ReservationAction from "redux/reservation.redux";

const BookingOrderPagination = () => {
  const dispatch = useDispatch();
  const [total, totalPage, page, rowsPerPage] = useSelector(
    ({ reservationRedux }) => [
      reservationRedux.filter?.total,
      reservationRedux.filter?.totalPage,
      reservationRedux.filter?.page,
      reservationRedux.filter?.size,
    ],
    shallowEqual,
  );

  const onCallApi = debounce(
    params => dispatch(ReservationAction.getReservationType(params)),
    AppConstant.DEBOUNCE_TIMEOUT,
  );

  return (
    <TablePagination
      total={total || 0}
      totalPage={totalPage}
      page={page || 0}
      rowsPerPage={rowsPerPage}
      onChange={newPage =>
        onCallApi({
          page: newPage,
        })
      }
    />
  );
};
export default BookingOrderPagination;
