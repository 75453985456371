import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Menu, Typography, IconButton, Box, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import StringFormat from "string-format";
import { LangConstant } from "const";

const MultiLayerMenu = ({ array, onChange, labelText, className, includeDefault, value, itemWidth }) => {
  const dropdownRef = useRef(null);
  const classes = useStyles({ itemWidth: itemWidth });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  const onShow = () => {
    setAnchorEl(dropdownRef.current);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelectItem = item => {
    onClose();
    onChange(item);
    setSelectedItem(item);
  };

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(selectedItem)) setSelectedItem(value);
  }, [value]);

  return (
    <Box className={clsx("menu-status", className)}>
      <IconButton
        ref={dropdownRef}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onShow}
        classes={{ root: classes.btnRoot, label: clsx("medium-md-txt", classes.btnLabel) }}
      >
        {selectedItem?.name || labelText || ""}
        <ArrowDropDown className={classes.icon} />
      </IconButton>
      <Menu
        id="lock-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        {includeDefault && (
          <MenuItem
            onClick={() => {
              onSelectItem(null);
            }}
            className={clsx("semiBold-md-txt", classes.menuItemRoot)}
          >
            {labelText}
          </MenuItem>
        )}
        {array.map((entry, index) => (
          <DropdownCategory data={entry} key={index} onChange={onSelectItem} />
        ))}
      </Menu>
    </Box>
  );
};

const DropdownCategory = ({ data, onChange }) => {
  const { items, name } = data;
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);
  const [placeCapacity, setPlaceCapacity] = useState(0);

  const onSelectItem = selectedItem => {
    onChange(selectedItem);
  };

  useEffect(() => {
    if (data && items && items.length > 0) {
      let tmpCapacity = 0;
      items.map(item => {
        tmpCapacity += item.capacity;
      });
      setPlaceCapacity(tmpCapacity);
    }
  }, [data]);

  return (
    <>
      <MenuItem className={classes.menuItemRoot} onClick={() => onSelectItem(data)}>
        <Typography color="inherit" className="semiBold-md-txt">
          {placeCapacity > 0 ? StringFormat(getLabel(LangConstant.FM_PLACE_WITH_OPACITY), name, placeCapacity) : name}
        </Typography>
      </MenuItem>
      {items?.length > 0 &&
        items.map((item, index) => (
          <MenuItem className={classes.menuItemRootChild} key={index} onClick={() => onSelectItem(item)}>
            <Typography variant="body1" classes={{ body1: "medium-md-txt ellipsis" }} color="inherit">
              {item.name || ""}
            </Typography>
          </MenuItem>
        ))}
    </>
  );
};

export default MultiLayerMenu;
const useStyles = makeStyles(theme => ({
  boxRoot: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 8,
    },
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  btnRoot: {
    width: "100%",
    color: theme.palette.grey[600],
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[200]}`,
    height: "100%",
  },
  btnLabel: {
    justifyContent: "unset",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 8,
    },
  },
  menuPaper: {
    marginTop: 2,
    borderRadius: "0%",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: "60%",
  },
  icon: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      right: 8,
    },
    color: theme.palette.grey[500],
  },
  inline: {
    display: "flex",
    alignItems: "center",
    minHeight: "38px",
  },
  inputRoot: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginBottom: "0",
  },
  input: {
    padding: "5px 10px",
    height: 20,
  },
  multiline: {
    padding: "inherit",
  },
  inputMultiline: {
    height: "auto",
  },
  infoDropdown: {
    width: props => props.itemWidth,
    maxHeight: "150px",
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  menuItemRoot: {
    minHeight: 40,
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
      "&>*": {
        color: theme.palette.white,
      },
    },
    "&>*:hover": {
      color: theme.palette.white,
    },
    cursor: "pointer",
    color: "#3e4045",
    alignItems: "center",
    paddingLeft: 16,
  },
  menuItemRootChild: {
    minHeight: 40,
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    paddingLeft: 16,
    alignItems: "center",
    color: "#3e4045",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  subMenu: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 140,
    overflow: "auto",
    padding: 0,
    width: 160,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&>*": {
      minHeight: 40,
      display: "flex",
      justifyContent: "space-between",
      padding: "0 16px",
      "&:hover": {
        color: theme.palette.white,
        backgroundColor: theme.palette.grey[700],
        "&>*": {
          color: theme.palette.white,
        },
      },
      "&>*:hover": {
        color: theme.palette.white,
        backgroundColor: theme.palette.grey[700],
      },
    },
  },
  subMenuRoot: { marginLeft: 11 },
  subMenuPaper: {
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.03)",
  },
}));
