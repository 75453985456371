import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { validateFileSize } from "utils";
import { AppConstant, LangConstant } from "const";
import ImageCell from "./ImageCell";
import { useDispatch } from "react-redux";
import MnNewsAction from "redux/mn-news.redux";
import CropImage from "components/CropImage";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const MultipleImageUploadComponent = props => {
  const { classes, onActiveUploadButton, fileArr, setFileArr } = props;
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();

  const [clickBoxAvailableStatus, setClickBoxAvailableStatus] = useState(true);
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const onRemoveIndexedImage = id => {
    let tmpArrFile = fileArr;
    tmpArrFile.splice(id, 1);
    setFileArr(tmpArrFile);
    setTriggerUseEffect(!triggerUseEffect);
  };

  const onUploadMultipleFiles = e => {
    let uploadFile = e.target.files;
    let arrFiles = [];
    let maxFileNumber = 5 - fileArr.length;
    if (maxFileNumber > 0) {
      if (uploadFile.length <= maxFileNumber) maxFileNumber = uploadFile.length;
      for (let j = 0; j < maxFileNumber; j++) {
        arrFiles.push(uploadFile[j]);
      }
      for (let i = 0; i < arrFiles.length; i++) {
        if (validateFileSize(arrFiles[i].size) && AppConstant.ACCEPT_IMAGE.includes(arrFiles[i].type)) {
          setFileArr(oldArray => [...oldArray, arrFiles[i]]);
        }
      }
    }
    e.target.value = null;
  };

  const onChangeCroppedFile = file => {
    setFileArr(file);
    setTriggerUseEffect(!triggerUseEffect);
  };

  useEffect(() => {
    if (fileArr.length > 0) {
      setClickBoxAvailableStatus(fileArr.length < 5);
      onActiveUploadButton(true);
      dispatch(MnNewsAction.requestUploadPostImage({ file: fileArr }));
    }
  }, [fileArr, triggerUseEffect]);

  return (
    <>
      <Box className={classes.uploadFormGroup}>
        <Box className={fileArr.length > 0 ? classes.previewBoxNotNull : classes.previewBoxIsNull}>
          <Box className={fileArr.length > 0 ? classes.imagePreviewFieldNotNull : classes.imagePreviewFieldisNull}>
            <ImageCell
              fileArr={fileArr}
              onRemove={onRemoveIndexedImage}
              onOpenCrop={index => {
                setSelectedImage(index);
                setIsOpenCropDialog(true);
              }}
            />
          </Box>
          <label
            htmlFor="uploadInput"
            className={clsx(
              fileArr.length > 0 ? classes.inputClickBoxPickedImg : classes.inputClickBoxInitial,
              clickBoxAvailableStatus ? classes.inputClickBoxNotFull : classes.inputClickBoxFull,
            )}
          >
            <Box>
              <AddIcon className={fileArr.length > 0 ? classes.addIconPickedInput : classes.addIconInitial} />
            </Box>
          </label>
        </Box>
        <Box>
          <input
            type="file"
            onChange={onUploadMultipleFiles}
            multiple
            className={classes.fileUploadInput}
            id="uploadInput"
            accept={AppConstant.ACCEPT_IMAGE.join(",")}
          />
        </Box>
        <CropImage
          isMultiple={true}
          isEnableCrop={false}
          inputArray={fileArr}
          aspect={3 / 2}
          onSubmit={file => {
            onChangeCroppedFile(file);
          }}
          isShow={isOpenCropDialog}
          onClose={() => setIsOpenCropDialog(false)}
          title={getLabel(LangConstant.TXT_EDIT_IMAGE)}
          selectedImage={selectedImage}
          onRemoveIndexedImage={onRemoveIndexedImage}
        />
      </Box>
    </>
  );
};

MultipleImageUploadComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  inputClickBoxInitial: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "30vh",
    width: "-webkit-fill-available",
    border: "unset",
    cursor: "pointer",
    "@media only screen and (max-width: 414px)": {
      minHeight: "6vw",
      height: "15vh",
      backgroundColor: "#ffffff",
    },
  },
  inputClickBoxPickedImg: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "5vw",
    width: "7vw",
    cursor: "pointer",
    border: "1px dashed #d4d5d8",
    "@media only screen and (max-width: 414px)": {
      backgroundColor: "#ffffff",
      height: "6vh",
      width: "15vw",
    },
  },
  inputClickBoxFull: {
    display: "none",
  },
  inputClickBoxNotFull: {
    display: "flex",
  },
  previewBoxNotNull: {
    border: "none",
    color: "#d4d5d8",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    display: "-webkit-box",
    "@media only screen and (max-width: 414px)": {
      maxHeight: "140px",
    },
  },
  previewBoxIsNull: {
    border: "1px dashed #d4d5d8",
  },

  uploadFormGroup: {
    textAlign: "center",
  },
  addIconInitial: {
    position: "relative",
    width: "80px",
    height: "80px",
    color: "#d4d5d8",
  },
  addIconPickedInput: {
    position: "relative",
    width: "28px",
    height: "28px",
    color: "#d4d5d8",
  },
  fileUploadInput: {
    display: "none",
  },
  imagePreviewFieldNotNull: {
    display: "flex",
  },
  imagePreviewFieldIsNull: {
    display: "flex",
  },
});
export default withStyles(styles)(MultipleImageUploadComponent);
