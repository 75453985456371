import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  debounce,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import PlaceCategoryAction from "redux/place-category.redux";
import ReservationAction from "redux/reservation.redux";
import ServiceCategoryAction from "redux/service-category.redux";
import { Alert, CellBody } from "components";
import StatusBatchUpdateMenu from "./StatusBatchUpdateMenu";
import BookingOrderTableRow from "./BookingOrderTableRow";
import FilterButtonOrder from "./FilterButtonOrder";

const BookingOrderTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [
    dataOrder,
    isPutReservationStatus,
    isPostSuccessReservationListStatus,
    updateReservationSuccess,
    isPostReservationOrderSuccess,
  ] = useSelector(
    ({ reservationRedux }) => [
      reservationRedux.dataOrder,
      reservationRedux.isPutReservationStatus,
      reservationRedux.isPostSuccessReservationListStatus,
      reservationRedux.updateReservationSuccess,
      reservationRedux.isPostReservationOrderSuccess,
    ],
    shallowEqual,
  );

  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);

  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [checkedBookingList, setCheckedBookingList] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [showData, setShowData] = useState([]);

  const onCheckAll = () => {
    let tmpCheckedArr = [],
      data = [];
    setIsCheckedAll(!isCheckedAll);
    let isNextCheckedAll = !isCheckedAll;
    data = dataOrder.map(data => (data = { ...data, isCheck: isNextCheckedAll }));
    if (isNextCheckedAll) {
      tmpCheckedArr = [...showData];
    }
    setShowData(data);
    setCheckedBookingList(tmpCheckedArr);
  };

  const onClickTableRow = selectedRow => {
    let arrSelectedRow = [];

    let listRows = showData.map(data => {
      // Update isCheck with selected row
      let isSelected = data.uid === selectedRow.uid;
      let row = { ...data, isCheck: isSelected ? !data.isCheck : data.isCheck };
      // Add data to list if row is checked
      if (row.isCheck) arrSelectedRow.push(row);
      return row;
    });

    setShowData(listRows);
    setCheckedBookingList(arrSelectedRow);
  };

  const onCallApi = debounce(
    params => dispatch(ReservationAction.getReservationType(params)),
    AppConstant.DEBOUNCE_TIMEOUT,
  );

  const onFilterButton = (index, type) => {
    onCallApi({
      orderBy: FILTER_TYPE[index].value,
      asc: type,
    });
  };

  useEffect(() => {
    dispatch(PlaceCategoryAction.getPlaceCategory({}));
    dispatch(ServiceCategoryAction.getServiceCategory({}));
    dispatch(ReservationAction.getReservationSettingOption());
  }, []);

  useEffect(() => {
    if (dataOrder) {
      let data = dataOrder.map(data => (data = { ...data, isCheck: false }));
      setShowData(data);
    }
  }, [dataOrder]);

  useEffect(() => {
    if (updateReservationSuccess || isPostReservationOrderSuccess) {
      if (isPostReservationOrderSuccess) {
        setIsShowSuccess(true);
      }
      onCallApi();
      dispatch(ReservationAction.resetPutReservation());
    }
  }, [updateReservationSuccess, isPostReservationOrderSuccess]);

  useEffect(() => {
    let isNextCheckedAll = Boolean(checkedBookingList.length === showData.length && showData.length > 0);
    setIsCheckedAll(isNextCheckedAll);
  }, [checkedBookingList, showData]);

  useEffect(() => {
    if (isPutReservationStatus) {
      onCallApi();
      dispatch(ReservationAction.resetPutReservationStatus());
    }
    if (isPostSuccessReservationListStatus) {
      setIsCheckedAll(false);
      setCheckedBookingList([]);
      onCallApi();
    }
  }, [isPutReservationStatus, isPostSuccessReservationListStatus]);

  let isShowStatusSelect = checkedBookingList.length > 0;

  return (
    <>
      {isShowStatusSelect && <StatusBatchUpdateMenu selectedBookingList={checkedBookingList} />}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {ARR_ORDER_TABLE_HEADER.map((cell, index) => (
                <CellBody
                  cellData={
                    <Box className={classes.firstTableCellHead}>
                      {index === 0 && (
                        <Checkbox
                          checked={isCheckedAll}
                          onChange={onCheckAll}
                          color="default"
                          classes={{
                            root: clsx(classes.tableHeadCheckBox, classes.checkboxBasis),
                            checked: clsx(classes.checkboxChecked, classes.checkboxBasis),
                          }}
                        />
                      )}
                      <Box className={clsx(classes.tableHeadLabel, "semiBold-sm-txt")}>
                        {getLabel(cell)}
                        <FilterButtonOrder onClick={onFilterButton} index={index} />
                      </Box>
                    </Box>
                  }
                  className={clsx(classes.cellHeadRoot, "semiBold-sm-txt")}
                  key={index}
                />
              ))}
              <CellBody className={classes.cellHeadRoot} />
            </TableRow>
          </TableHead>
          <TableBody>
            {showData.map((dataShow, index) => (
              <BookingOrderTableRow key={index} dataShow={dataShow} onClick={onClickTableRow} />
            ))}
          </TableBody>
        </Table>
        <Alert
          isShow={isShowSuccess}
          onClose={() => setIsShowSuccess(false)}
          type="success"
          message={getLabel(LangConstant.TXT_SUCCESS_POST_ORDER)}
        />
      </TableContainer>
    </>
  );
};

export default BookingOrderTable;

BookingOrderTable.propTypes = {};

const ARR_ORDER_TABLE_HEADER = [
  LangConstant.TXT_CODE_ORDER,
  LangConstant.TXT_BOOKING_DATE_ORDER,
  LangConstant.TXT_NAME_CONSUMER_ORDER,
  LangConstant.TXT_ADDRESS_ORDER,
  LangConstant.TXT_TOTAL_PRICE_ORDER,
  LangConstant.TXT_STATUS_ORDER,
];

const FILTER_TYPE = [
  { key: 0, value: "code" },
  { key: 1, value: AppConstant.KEY_START_TIME },
  { key: 2, value: AppConstant.KEY_NAME },
  { key: 3, value: AppConstant.KEY_SHOP_ADDRESS },
  { key: 4, value: "total" },
  { key: 5, value: "status" },
];

const useStyles = makeStyles(theme => ({
  rootTableRow: {
    height: 40,
  },
  tableContainer: {
    border: `1px solid ${theme.palette.grey[200]}`,
    "&>table": {
      overflowX: "auto",
      minWidth: 800,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cellHeadRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  firstTableCellHead: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  checkboxBasis: {
    width: 15,
    height: 15,
    marginRight: 25,
    " &>span>svg": {
      width: 20,
      height: 20,
    },
  },
  tableHeadCheckBox: {
    color: "#4b4d53",
  },
  checkboxChecked: {
    color: "#447aff",
  },
  tableHeadLabel: {
    width: "100%",
    textAlign: "center",
    minWidth: 100,
  },
  iconButton: {
    width: 18,
    minWidth: 18,
    maxHeight: 18,
  },
}));
