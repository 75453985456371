import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import MyAccount from "./MyAccount";
import MyPlanPackage from "./MyPlanPackage";
import MyPayment from "./MyPayment";

const BodyInfo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <MyAccount />
      <MyPlanPackage />
      <MyPayment />
    </Box>
  );
};

export default BodyInfo;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0 106px",
    margin: "0 54px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: "0 16px",
    },
  },
}));
