import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  billSuccess: ["data"],
  billFailure: ["data"],
  billReset: [""],

  createBill: ["data"],
  updateBill: ["data"],
  patchUpdateBillStatus: ["data"],
  getBillList: ["data"],
  deleteBill: ["data"],
  getReservationBillDetail: ["data"],
});

export const BillTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isSuccess: false,
  error: null,

  billList: [],
  billDetailReservation: {},
  paging: {
    page: 1,
    size: 10,
    total: 0,
    totalPage: 0,
  },
  isUpdateSuccess: false,
  isPatchUpdateSuccess: false,
  isDeleteSuccess: false,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, error: action.error };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.BILL_SUCCESS]: success,
  [Types.BILL_FAILURE]: failure,
  [Types.BILL_RESET]: reset,

  [Types.CREATE_BILL]: request,
  [Types.UPDATE_BILL]: request,
  [Types.PATCH_UPDATE_BILL_STATUS]: request,
  [Types.GET_BILL_LIST]: request,
  [Types.DELETE_BILL]: request,
  [Types.GET_RESERVATION_BILL_DETAIL]: request,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
