import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import { Place } from "@material-ui/icons";
import { AppConstant } from "const";

const Map = ({ onChangeAddress, selectedAddress, markerLabel }) => {
  const classes = useStyles();

  const [displayAddress, setDisplayAddress] = useState(selectedAddress);
  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);

  const onResetAddressToDefault = () => {
    onChangeAddress(null);
    let tmpAddress = { ...selectedAddress };
    setMapZoom(20);
    tmpAddress = { ...tmpAddress, ...AppConstant.GBOSS_COORDINATE };
    setDisplayAddress(tmpAddress);
  };

  const onMapClicked = (
    location = {
      lat: AppConstant.GBOSS_COORDINATE.latitude,
      lng: AppConstant.GBOSS_COORDINATE.longitude,
    },
  ) => {
    setDisplayAddress({
      latitude: location.lat,
      longitude: location.lng,
    });
  };

  const onAPILoaded = (map, maps) => {
    maps.event.addListener(map, "click", event => {
      onMapClicked({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    });
    onResetAddressToDefault();
  };

  useEffect(() => {
    let tmpAddress = { ...selectedAddress };
    setMapZoom(20);
    tmpAddress = { ...tmpAddress, ...AppConstant.GBOSS_COORDINATE };
    setDisplayAddress(tmpAddress);
  }, [selectedAddress]);

  return (
    <Box className={classes.mapContainer}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: AppConstant.GOOGLE_API_KEY }}
        defaultCenter={{
          lat: AppConstant.GBOSS_COORDINATE.latitude,
          lng: AppConstant.GBOSS_COORDINATE.longitude,
        }}
        center={{
          lat: displayAddress.latitude,
          lng: displayAddress.longitude,
        }}
        zoom={mapZoom}
        defaultZoom={DEFAULT_MAP_ZOOM}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          if (map && maps) onAPILoaded(map, maps);
        }}
      >
        <Marker
          lat={displayAddress.latitude}
          lng={displayAddress.longitude}
          text={markerLabel || selectedAddress?.address || ""}
        />
      </GoogleMapReact>
    </Box>
  );
};

const DEFAULT_MAP_ZOOM = 15;

Map.propTypes = {
  markerLabel: PropTypes.string,

  selectedAddress: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    address: PropTypes.string,
  }),

  onChangeAddress: PropTypes.func,
};

Map.defaultProps = {
  markerLabel: "",

  selectedAddress: {
    latitude: AppConstant.GBOSS_COORDINATE.latitude,
    longitude: AppConstant.GBOSS_COORDINATE.longitude,
    address: "",
  },

  onChangeAddress: () => {},
};

const Marker = props => {
  const { text } = props;
  return (
    <Tooltip
      arrow
      placement="top"
      PopperProps={{
        disablePortal: true,
      }}
      open={true}
      title={text}
    >
      <Place color="primary" fontSize="large" />
    </Tooltip>
  );
};
Marker.propTypes = {
  text: PropTypes.string,
};

const useStyles = makeStyles(() => ({
  mapContainer: {
    height: "100%",
    width: "100%",
  },
}));

export default Map;
