import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { CategorySubcategory, DisplayModeBar, IconButtonMProductItem } from "components/mn-master-product";
import { getCommonKey, textNormalize } from "utils";
import { DialogAddCategory, DialogDelete } from "components/dialog";
import ServiceCategoryAction from "redux/service-category.redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DELETE_ACTION, EDIT_ACTION } from "components/mn-master-product/CategorySubcategory";

const ServiceCategoryList = ({ onJumpToChild, ...otherProps }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataCategory = useSelector(state => state.serviceCategoryRedux?.data, shallowEqual);
  const errorCreateServiceCategory = useSelector(state => state.serviceCategoryRedux.errorCreateServiceCategory);
  const errorUpdateServiceCategory = useSelector(state => state.serviceCategoryRedux.errorUpdateServiceCategory);
  const isCreateSuccess = useSelector(state => state.serviceCategoryRedux.isCreateSuccess, shallowEqual);
  const isCreateFetching = useSelector(state => state.serviceCategoryRedux.isCreateFetching, shallowEqual);
  const isUpdateSuccess = useSelector(state => state.serviceCategoryRedux.isUpdateSuccess, shallowEqual);
  const isUpdateFetching = useSelector(state => state.serviceCategoryRedux.isUpdateFetching, shallowEqual);
  const isRemoveSuccess = useSelector(state => state.serviceCategoryRedux.isRemoveSuccess, shallowEqual);
  const isRemoveFetching = useSelector(state => state.serviceCategoryRedux.isRemoveFetching, shallowEqual);

  const [isOpen, setIsOpen] = useState({ create: false, delete: false, edit: false, targetUid: null });
  const [targetCategory, setTargetCategory] = useState({});
  const [showData, setShowData] = useState(dataCategory);
  const [isDuplicate, seIsDuplicate] = useState(false);

  const onOpenAddCategory = () => {
    setIsOpen({ ...isOpen, create: true });
  };

  const onClose = () => {
    seIsDuplicate(false);
    setIsOpen({ ...isOpen, create: false, delete: false, edit: false, targetUid: null });
    setTargetCategory({});
  };

  const onChangeCategoryData = value => {
    setTargetCategory(newData => ({ ...newData, name: value }));
  };

  const onCreateCategory = () => {
    if (!isCreateFetching) {
      dispatch(ServiceCategoryAction.createServiceCategory({ name: targetCategory.name }));
    }
  };

  const onUpdateCategory = () => {
    if (!isUpdateFetching) {
      dispatch(ServiceCategoryAction.updateServiceCategory(targetCategory));
    }
  };

  const onDeleteCategory = () => {
    if (!isRemoveFetching) {
      dispatch(ServiceCategoryAction.removeServiceCategory({ uid: targetCategory.uid }));
    }
  };

  const onEditAndDelete = (uid, action) => {
    const targetData = showData[uid];
    setTargetCategory(targetData);
    switch (action) {
      case EDIT_ACTION:
        setIsOpen({ ...isOpen, edit: true });
        break;
      case DELETE_ACTION:
        setIsOpen({ ...isOpen, delete: true });
        break;
    }
  };

  const onSearch = newSearchValue => {
    setShowData(doSearch(dataCategory, newSearchValue));
  };

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess || isRemoveSuccess) {
      onClose();
    }
  }, [isCreateSuccess, isUpdateSuccess, isRemoveSuccess]);

  useEffect(() => {
    if (dataCategory) {
      setShowData(dataCategory);
    }
  }, [dataCategory]);

  useEffect(() => {
    if (errorCreateServiceCategory) {
      if (errorCreateServiceCategory?.name[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        seIsDuplicate(true);
        dispatch(ServiceCategoryAction.resetErrorServiceCategory());
      }
    }
    if (errorUpdateServiceCategory) {
      if (errorUpdateServiceCategory?.name[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        seIsDuplicate(true);
        dispatch(ServiceCategoryAction.resetErrorServiceCategory());
      }
    }
  }, [errorCreateServiceCategory, errorUpdateServiceCategory]);

  return (
    <>
      <DisplayModeBar
        labelBtn={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
          LangConstant.TXT_CATEGORY_LOWER_CASE,
        )}`}
        onAddItem={onOpenAddCategory}
        onSearch={onSearch}
        showSearch={dataCategory && Object.keys(dataCategory).length > 0}
        {...otherProps}
      />
      {showData && Object.keys(showData).length > 0 ? (
        <Box className={classes.contentBox} padding="50px 0">
          <Box className={classes.listItem}>
            {Object.values(showData).map(dataMap => (
              <Box margin="12px" display="grid" key={dataMap.uid}>
                <CategorySubcategory
                  uid={dataMap.uid}
                  text={dataMap.name}
                  onClick={e => onJumpToChild(dataMap, e)}
                  onContextMenu={onEditAndDelete}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box className={classes.noContentBox}>
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
              LangConstant.TXT_CATEGORY_LOWER_CASE,
            )}`}
            onClick={onOpenAddCategory}
            buttonClasses={{ root: classes.addButton }}
          />
        </Box>
      )}

      {isOpen.create && (
        <DialogAddCategory
          isShow={isOpen.create}
          onClose={onClose}
          onSubmit={onCreateCategory}
          onChange={onChangeCategoryData}
          isWarning={isDuplicate}
          nameWaring={isDuplicate ? getLabel(LangConstant.TXT_WARNING_CATEGORY) : ""}
        />
      )}
      {isOpen.edit && (
        <DialogAddCategory
          isShow={isOpen.edit}
          onClose={onClose}
          onSubmit={onUpdateCategory}
          onChange={onChangeCategoryData}
          data={targetCategory}
          isWarning={isDuplicate}
          nameWaring={isDuplicate ? getLabel(LangConstant.TXT_WARNING_CATEGORY) : ""}
        />
      )}
      {isOpen.delete && (
        <DialogDelete
          uid={targetCategory.uid}
          isShow={isOpen.delete}
          onCancel={onClose}
          onConfirm={onDeleteCategory}
          title={getLabel(LangConstant.FN_DELETE_CATEGORY_SERVICE_TITLE)(targetCategory.name)}
          content={getLabel(LangConstant.FN_DELETE_CATEGORY_SERVICE_CONTENT)(targetCategory.name)}
        />
      )}
    </>
  );
};

const doSearch = (iniData, searchValue) => {
  if (searchValue == "" || !searchValue) {
    return iniData;
  } else {
    let filterList = {};
    Object.keys(iniData).map(key => {
      if (textNormalize(iniData[key]?.name).includes(textNormalize(searchValue))) {
        filterList[key] = iniData[key];
      }
    });
    return filterList;
  }
};

const useStyles = makeStyles(theme => ({
  contentBox: {
    width: "100%",
    minHeight: "720px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
      overflowX: "auto",
    },
  },
  noContentBox: {
    display: "flex",
    width: "100%",
    minHeight: "720px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
      overflowX: "auto",
    },
  },
  listItem: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(296px, 1fr))",
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
}));

ServiceCategoryList.propTypes = { onJumpToChild: PropTypes.func };

ServiceCategoryList.defaultProps = { onJumpToChild: () => {} };

export default memo(ServiceCategoryList);
