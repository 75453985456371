import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography, Dialog, Button, Grid, IconButton } from "@material-ui/core";
import { Close, Add, Remove, Stop } from "@material-ui/icons";
import { LangConstant } from "const";
import { format } from "react-string-format";
import { useTranslation } from "react-i18next";
import CustomDayOfWeek from "./CustomDayOfWeek";
import { MinuteHourTimePicker } from "components";
import { useDispatch, useSelector } from "react-redux";
import ShopInfoAction from "redux/shop-info.redux";
import Alert from "components/dialog/Alert";

const WorkingTimeDialog = ({ open, close }) => {
  const dataShopInfo = useSelector(state => state.shopInfoRedux.data);
  const workingTimes = dataShopInfo?.details?.workingTime;

  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const workingTimeData = workingTimes && workingTimes.length > 0 ? workingTimes : DEFAULT_WORKING_TIME;

  const [arrWorkingTimes, setArrWorkingTimes] = useState(workingTimeData);
  const [selectedArr, setSelectedArr] = useState(
    workingTimeData.reduce((selectedArray, index) => {
      selectedArray.push(...index.days);
      return selectedArray;
    }, []),
  );
  const [unavailableDays, setUnavailableDays] = useState(true);
  const [isDisableSaveButton, setDisableSaveButton] = useState();
  const [openTimeWarning, setOpenTimeWarning] = useState(false);
  const [isDisableSelectTime, setIsDisableSelectTime] = useState();
  const [invalidBlock, setInvalidBlock] = useState();
  const [formatWarningMessage, setFormatWarningMessage] = useState();

  // variables for conditional checking
  const isExistSelectedDay = Boolean(arrWorkingTimes.length == 1 && selectedArr?.length > 0);
  const isExistWorkingTimeBlock = Boolean(arrWorkingTimes && arrWorkingTimes.length > 1);
  const isExistWorkingTimeBlocks = Boolean(arrWorkingTimes && arrWorkingTimes.length > 1);
  const isInValidTime = Boolean(invalidBlock?.includes(true));

  const isEnableRemoveButton = isExistSelectedDay || isExistWorkingTimeBlock;
  // Add open time into working time block
  const onChangeStartHour = (hour, min, blockIndex) => {
    setArrWorkingTimes(arrWorkingTimes => {
      let startTime = {
        min: min,
        hour: hour,
      };
      let startTimeToSecond = startTime.min * 60 + startTime.hour * 3600;
      let tempStartTime = [...arrWorkingTimes];

      if (startTime.hour == 23) {
        let endTimeToSecond = (startTime.min + 1) * 60 + startTime.hour * 3600;
        tempStartTime[blockIndex] = { ...arrWorkingTimes[blockIndex], open: startTimeToSecond, close: endTimeToSecond };
      } else {
        let endTimeToSecond = (startTime.hour + 1) * 3600;
        tempStartTime[blockIndex] = { ...arrWorkingTimes[blockIndex], open: startTimeToSecond, close: endTimeToSecond };
      }

      return tempStartTime;
    });
  };

  // Add close time into working time block
  const onChangeEndHour = (hour, min, blockIndex) => {
    setArrWorkingTimes(arrWorkingTimes => {
      let closeTime = {
        min: min,
        hour: hour,
      };
      let closeTimeToSecond = closeTime.min * 60 + closeTime.hour * 3600;
      let tempCLoseTime = [...arrWorkingTimes];
      tempCLoseTime[blockIndex] = { ...arrWorkingTimes[blockIndex], close: closeTimeToSecond };
      return tempCLoseTime;
    });
  };

  // Add setting working time block when click into add button
  const onAddWorkingTimeBlock = () => {
    let workingTime = {
      days: [],
      open: 0,
      close: 0,
    };
    if (arrWorkingTimes?.length <= 6) {
      setArrWorkingTimes([...arrWorkingTimes, workingTime]);
    }
  };

  // Remove setting working time block when click into remove button
  const onRemoveWorkingTimeBlock = () => {
    let tempArrWorkingTime = [...arrWorkingTimes];

    let lastWorkingTimeDay = tempArrWorkingTime[tempArrWorkingTime?.length - 1].days;
    let tempSelectedArr = [...selectedArr];

    if (arrWorkingTimes?.length > 1) {
      tempSelectedArr = tempSelectedArr.filter(day => !lastWorkingTimeDay.includes(day));
      setSelectedArr(tempSelectedArr);
      tempArrWorkingTime.pop();
    } else {
      tempArrWorkingTime = Object.assign(tempArrWorkingTime, DEFAULT_WORKING_TIME);
      setSelectedArr([]);
    }
    setArrWorkingTimes(tempArrWorkingTime);
  };

  // Add days into selected arrays
  const onAddDayIntoSelectedArr = day => {
    const tempSelectedArr = [...selectedArr];
    tempSelectedArr.push(day);
    setSelectedArr(tempSelectedArr);
  };

  // Remove days from selected arrays
  const onRemoveDayFromSelectedArr = day => {
    const tempSelectedArr = [...selectedArr];

    if (selectedArr?.length > 1) {
      var dayIndex = tempSelectedArr.indexOf(day);
      if (dayIndex !== -1) {
        tempSelectedArr.splice(dayIndex, 1);
      }
    } else if (selectedArr?.length == 1) {
      tempSelectedArr.pop(day);
    }

    setSelectedArr(tempSelectedArr);
  };

  // Add days into working time block
  const onAddDayIntoWorkingTime = (day, blockIndex) => {
    const add = [...arrWorkingTimes[blockIndex].days];
    add.push(day);

    let tmpArrWorkingTimes = [...arrWorkingTimes];
    tmpArrWorkingTimes[blockIndex].days = add;
    setArrWorkingTimes(tmpArrWorkingTimes);
  };

  // Remove days from working time block
  const onDeleteDayFromWorkingTime = (day, blockIndex) => {
    const remove = [...arrWorkingTimes[blockIndex].days];
    if (arrWorkingTimes[blockIndex].days?.length > 1) {
      var dayIndex = remove.indexOf(day);
      if (dayIndex !== -1) {
        remove.splice(dayIndex, 1);
      }
    } else if (arrWorkingTimes[blockIndex].days?.length == 1) {
      remove.pop(day);
    }

    let tmpArrWorkingTimes = [...arrWorkingTimes];
    tmpArrWorkingTimes[blockIndex].days = remove;

    setArrWorkingTimes(tmpArrWorkingTimes);
  };

  // On change day in  working time block
  const onChangeWorkingDay = (selectedDay, selectedBlock) => {
    if (selectedArr.includes(selectedDay) === true) {
      if (arrWorkingTimes[selectedBlock]?.days.includes(selectedDay) == true) {
        onRemoveDayFromSelectedArr(selectedDay);
        onDeleteDayFromWorkingTime(selectedDay, selectedBlock);
      }
    } else {
      onAddDayIntoSelectedArr(selectedDay);
      onAddDayIntoWorkingTime(selectedDay, selectedBlock);
    }
  };

  const onHandleCloseTimeWarning = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenTimeWarning(false);
  };

  const onClose = () => {
    close(isInValidTime);
  };

  const onCloseSetting = () => {
    close(false);
  };

  // get array of invalid time setting blocks index
  const getInvalidBlock = () => {
    let invalidBlockOfInteger = [],
      index = -1;
    while (invalidBlock && (index = invalidBlock.indexOf(true, index + 1)) != -1) {
      invalidBlockOfInteger.push(index + 1);
    }

    let warningMessageBlock = invalidBlockOfInteger.join(", ");
    setFormatWarningMessage(warningMessageBlock);
  };

  // delete all empty working time blocks
  const onDeleteEmptyWorkingTimeBlock = () => {
    let tempArrWorkingTime = [...arrWorkingTimes];
    let sortedArrWorkingTime = tempArrWorkingTime.filter(workingTime => workingTime.days.length !== 0);

    let details = { ...dataShopInfo.details, workingTime: sortedArrWorkingTime };
    let data = { ...dataShopInfo, details: details };
    if (!isInValidTime) {
      dispatch(ShopInfoAction.updateShopInfoData(data));
    }
  };

  const onSave = () => {
    getInvalidBlock();

    if (isInValidTime) {
      setOpenTimeWarning(isInValidTime);
    }
    onDeleteEmptyWorkingTimeBlock();
  };

  useEffect(() => {
    let tempUnavailableArr = [];
    let disableSaveButtonCondition = true;
    let disableSelectTime = [];
    let invalidSettingTimeBlock = [];

    arrWorkingTimes.map(block => {
      // Get unavailable days
      let workingTimeDay = block.days;
      let tempUnavailableDays = [...selectedArr];
      tempUnavailableDays = tempUnavailableDays.filter(day => !workingTimeDay.includes(day));
      tempUnavailableArr.push({ disableDays: tempUnavailableDays });

      // Enable select time for working time setting
      if (workingTimeDay && workingTimeDay.length == 0) {
        disableSelectTime.push(true);
      } else {
        disableSelectTime.push(false);
      }

      // Get open time
      let startWorkingTime = block.open;

      // Get close time
      let closeWorkingTime = block.close;

      // validate time of current working time block setting before create new working time block
      if (startWorkingTime >= closeWorkingTime) {
        if (workingTimeDay.length !== 0) {
          invalidSettingTimeBlock.push(true);
        }
      } else {
        invalidSettingTimeBlock.push(false);
      }

      // disable save button condition
      if (workingTimes?.length === 0 && selectedArr?.length === 0) {
        disableSaveButtonCondition = true;
      } else if (
        workingTimes?.days?.length != 0 &&
        workingTimeDay?.length == 0 &&
        !startWorkingTime &&
        !closeWorkingTime
      ) {
        disableSaveButtonCondition = false;
      } else disableSaveButtonCondition = false;
    });
    getInvalidBlock();
    setUnavailableDays(tempUnavailableArr);
    setIsDisableSelectTime(disableSelectTime);
    setDisableSaveButton(disableSaveButtonCondition);
    setInvalidBlock(invalidSettingTimeBlock);
  }, [arrWorkingTimes]);

  return (
    <Dialog fullScreen open={open} classes={{ paper: classes.paperProps }}>
      <Box className={classes.rootPaper}>
        <Box className={classes.closeContainer}>
          <Button
            onClick={onCloseSetting}
            classes={{
              root: classes.rootButton,
              label: classes.label,
              startIcon: classes.startIcon,
              iconSizeMedium: classes.iconSizeMedium,
            }}
            startIcon={<Close className={classes.rootIcon} />}
          />
        </Box>
        <Box className={classes.rootBox}>
          <Box className={classes.boxContainer}>
            <Typography variant="h5"> {getLabel(LangConstant.TXT_SETTING_WORKING_TIME)}</Typography>
            <Box>
              {arrWorkingTimes.map((workingTimeItem, index) => (
                <Box className={classes.workingTimeRoot} key={index}>
                  <CustomWorkingTime
                    classes={classes}
                    data={workingTimeItem}
                    onChangeWorkingDay={selectedDay => onChangeWorkingDay(selectedDay, index)}
                    onChangeStartHour={(hour, min) => onChangeStartHour(hour, min, index)}
                    onChangeEndHour={(hour, min) => onChangeEndHour(hour, min, index)}
                    isDisableDays={unavailableDays[index]?.disableDays}
                    isDisableSelectTime={isDisableSelectTime && isDisableSelectTime[index]}
                  />
                </Box>
              ))}
              <Grid className={classes.blockController} item xs={12} container>
                {isEnableRemoveButton && <RemoveCustomDay classes={classes} onRemoveDay={onRemoveWorkingTimeBlock} />}
                {selectedArr?.length != TOTAL_DAYS_OF_WEEK && (
                  <AddCustomDay classes={classes} onAddCustomDay={onAddWorkingTimeBlock} />
                )}
              </Grid>
            </Box>
            <Box className={classes.saveBox}>
              <Button
                onClick={() => {
                  onSave();
                  !isInValidTime && onClose();
                }}
                classes={{ root: `semiBold-lg-txt ${classes.saveSetting}`, disabled: classes.disabledSaveButton }}
                disabled={isExistWorkingTimeBlocks && isDisableSaveButton}
                color="primary"
                size="large"
                variant="contained"
              >
                {getLabel(LangConstant.TXT_SAVE)}
              </Button>
            </Box>
            {openTimeWarning && (
              <Alert
                isShow={openTimeWarning}
                onClose={onHandleCloseTimeWarning}
                type="error"
                message={format(getLabel(LangConstant.FM_INVALID_TIME), formatWarningMessage)}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.boxIllustrate}>
          <Box className={classes.workingTimeContainer}>
            <Box color="inherit" size="small">
              <Stop color="inherit" className={classes.illustrateBox} />
            </Box>
            <Typography className={`medium-sm-txt ${classes.workingTimeBox}`}>
              {getLabel(LangConstant.TXT_WORKING_TIME_ILLUSTRATE)}
            </Typography>
          </Box>
          <Box className={classes.breakTimeContainer}>
            <Box color="inherit" size="small">
              <Stop color="inherit" className={classes.illustrateBox} />
            </Box>
            <Typography className={`medium-sm-txt ${classes.breakTimeBox}`}>
              {getLabel(LangConstant.TXT_BREAK_TIME_ILLUSTRATE)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const CustomWorkingTime = ({
  classes,
  data,
  onChangeStartHour,
  onChangeEndHour,
  onChangeWorkingDay,
  isDisableDays,
  isDisableSelectTime,
}) => {
  const { t: getLabel } = useTranslation();

  // Convert seconds to hours and Minutes

  const convertSecondsToHm = secs => {
    let hours = Math.floor(secs / 3600);
    let minutes = Math.floor((secs % 3600) / 60);

    let convertTime = {
      hours: hours,
      minutes: minutes,
    };
    return convertTime;
  };
  const [openTime, setOpenTime] = useState(convertSecondsToHm(data.open));
  const [closeTime, setCloseTime] = useState(convertSecondsToHm(data.close));
  const [hourArr, setHourArr] = useState(HOURS_ARR);
  const [minArr, setMinArr] = useState(MINUTE_ARR);
  // Generate close hour array
  const onGetCloseHourArray = () => {
    let tempCloseHourArray = HOURS_ARR;
    let closeHourArray = tempCloseHourArray.filter(hour => hour >= openTime.hours);
    setHourArr(closeHourArray);
  };

  // Generate close minute array
  const onGetCloseMinuteArray = () => {
    let tempCloseMinuteArray = MINUTE_ARR;
    let closeMinuteArr = [];
    if (closeTime && closeTime.hours && openTime?.hours === closeTime.hours) {
      let tempCloseMinuteArr = tempCloseMinuteArray.filter(min => min > openTime.minutes);
      closeMinuteArr = tempCloseMinuteArr.slice(0);
    } else {
      closeMinuteArr = tempCloseMinuteArray.slice(0);
    }
    setMinArr(closeMinuteArr);
  };

  useEffect(() => {
    setOpenTime(convertSecondsToHm(data.open));
    setCloseTime(convertSecondsToHm(data.close));
  }, [data]);

  useEffect(() => {
    onGetCloseHourArray();
    onGetCloseMinuteArray();
  }, [openTime, closeTime]);

  return (
    <Box>
      <Grid item xs={12} className={classes.dailyGrid}>
        <CustomDayOfWeek selectedButton={data.days} onSelect={onChangeWorkingDay} isDisableDays={isDisableDays} />
      </Grid>
      <Grid item xs={12} container className={classes.timePickerContainer}>
        <Grid className={classes.startTimePicker}>
          <Typography className={`medium-md-txt ${classes.startTime}`}>
            {getLabel(LangConstant.TXT_START_TIME_PICKER)}
          </Typography>
          <MinuteHourTimePicker
            onChange={onChangeStartHour}
            minuteArray={MINUTE_ARR}
            hourArray={HOURS_ARR}
            hourValue={openTime.hours}
            minuteValue={openTime.minutes}
            classes={{ rootInputBox: classes.timePicker }}
            isDisableSelectTime={isDisableSelectTime}
            required
          />
        </Grid>
        <Grid className={classes.timePicker}>
          <Typography className={`medium-md-txt ${classes.closeTime}`}>
            {getLabel(LangConstant.TXT_END_TIME_PICKER)}
          </Typography>

          <MinuteHourTimePicker
            onChange={onChangeEndHour}
            minuteArray={minArr}
            hourArray={hourArr}
            hourValue={closeTime.hours}
            minuteValue={closeTime.minutes}
            classes={{ rootInputBox: classes.timePicker }}
            isDisableSelectTime={isDisableSelectTime}
            required
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const RemoveCustomDay = ({ classes, onRemoveDay }) => {
  const { t: getLabel } = useTranslation();

  return (
    <Grid className={classes.removeContainer}>
      <IconButton aria-label="remove" color="inherit" size="small" onClick={onRemoveDay}>
        <Remove color="inherit" />
      </IconButton>
      <Typography className={`medium-md-txt ${classes.removeText}`}>
        {getLabel(LangConstant.TXT_REMOVE_CUSTOM_WORKING_TIME)}
      </Typography>
    </Grid>
  );
};

const AddCustomDay = ({ classes, onAddCustomDay }) => {
  const { t: getLabel } = useTranslation();

  return (
    <Grid className={classes.removeContainer}>
      <IconButton aria-label="remove" color="inherit" size="small" onClick={onAddCustomDay}>
        <Add color="inherit" />
      </IconButton>
      <Typography className="medium-md-txt">{getLabel(LangConstant.TXT_ADD_CUSTOM_WORKING_TIME)}</Typography>
    </Grid>
  );
};

WorkingTimeDialog.propTypes = {
  basicData: PropTypes.object,
};
WorkingTimeDialog.defaultProps = {
  basicData: {},
};

const DEFAULT_WORKING_TIME = [
  {
    days: [],
    open: 0,
    close: 0,
  },
];

const TOTAL_DAYS_OF_WEEK = 7;
const HOURS_ARR = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const MINUTE_ARR = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

export default memo(WorkingTimeDialog);

const useStyles = makeStyles(theme => ({
  paperProps: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
  },
  rootPaper: {
    width: "100%",
    minHeight: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
  },
  elevation1: {
    boxShadow: "none !important",
  },
  root: {
    padding: theme.spacing(4),
  },
  closeContainer: {
    position: "relative",
    top: "44px",
    right: "44px",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      top: "26px",
      right: "26px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "10px",
      right: "3px",
    },
    [theme.breakpoints.down(541)]: {
      height: "42px",
    },
  },
  rootButton: {
    width: "50px",
    height: "100%",
    display: "block",
    marginLeft: "auto",
  },
  label: {
    height: "100%",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  iconSizeMedium: {
    fontSize: "30px",
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },
  rootBox: {
    display: "flex",
    width: "70%",
    minHeight: "calc(100vh - 233px)",
    flexDirection: "column",
    marginTop: "4%",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down(541)]: {
      width: "93%",
    },
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.down(541)]: {
      paddingTop: "30px",
    },
  },
  workingTimeRoot: {
    paddingBottom: "4.5%",
    "&:first-child": {
      paddingTop: "9%",
    },
    "&:not(:first-child)": {
      paddingTop: "4.5%",
      borderTop: "1px solid #ebeced",
    },
  },

  blockController: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.down(541)]: {
      width: "96%",
    },
  },

  dailyGrid: {
    display: "flex",
    alignItems: "center",
    marginBottom: "3%",
  },
  timePickerContainer: {
    width: "65%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  timePicker: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  startTimePicker: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "16px",
    },
  },
  startTime: {
    margin: "0px 16px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 16px 0px 0px",
    },
  },
  closeTime: {
    margin: "0px 16px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 34px 0px 0px",
    },
  },
  removeContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#729fff",
  },
  removeText: { marginRight: "24px" },
  saveBox: {
    width: "100%",
    height: "100%",
    marginTop: "48px",
  },
  boxIllustrate: {
    display: "flex",
    justifyContent: "center",
    marginTop: "63px",
    marginBottom: "37px",
  },
  illustrateBox: {
    fontSize: "44px",
    cursor: "text",
  },
  workingTimeContainer: {
    display: "flex",
    placeItems: "center",
    color: "#729fff",
  },
  workingTimeBox: { marginRight: "48px" },
  breakTimeContainer: {
    display: "flex",
    placeItems: "center",
    color: "#f2f2f2",
  },
  breakTimeBox: { marginRight: "24px" },
  saveSetting: {
    width: "100px",
    height: "45px",
    borderRadius: 4,
    textTransform: "none",
  },
  disabledSaveButton: {
    backgroundColor: "rgba(239, 88, 69, 0.6) !important",
    color: `${theme.palette.white} !important`,
  },
}));
