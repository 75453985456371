import { call, put, select } from "redux-saga/effects";
import { ApiConstant, AppConstant } from "const";
import ReservationNewAction from "redux/reservation-new.redux";
import { ReservationNewService } from "services";
import { getJsonArray, toCamel, toSnake } from "utils";

export function* getReservationNewOrder(action) {
  const { data } = action;
  const {
    reservationNewRedux: { defaultParams },
  } = yield select();
  try {
    let params = { ...defaultParams, ...data, type: AppConstant.DATA_TYPE.order };
    let response = yield call(ReservationNewService.get, toSnake(params));
    if (response.status === ApiConstant.STT_OK) {
      let dataNotification = getNewDataFromResponse(response, params);
      yield put(
        ReservationNewAction.getReservationNewSuccess({
          ...dataNotification,
        }),
      );
    } else {
      yield put(ReservationNewAction.getReservationNewFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationNewAction.getReservationNewFailure(error));
  }
}

export function* getReservationNewBooking(action) {
  const { data } = action;
  const {
    reservationNewRedux: { defaultParams },
  } = yield select();
  try {
    let params = { ...defaultParams, ...data, type: AppConstant.DATA_TYPE.booking };
    let response = yield call(ReservationNewService.get, toSnake(params));
    if (response.status === ApiConstant.STT_OK) {
      let dataNotification = getNewDataFromResponse(response, params);
      yield put(
        ReservationNewAction.getReservationNewSuccess({
          ...dataNotification,
        }),
      );
    } else {
      yield put(ReservationNewAction.getReservationNewFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationNewAction.getReservationNewFailure(error));
  }
}

const validate = responseData => {
  const validatedData = responseData
    ? responseData.map(value => {
        return validateReservationNew(value);
      })
    : [];

  return validatedData;
};

const validateReservationNew = data => {
  const validatedData = {
    ...data,
    created: data.created ? data.created : 0,
    uid: data.uid ? data.uid : "",
    periodicReservationUid: data.periodicReservationUid ? data.periodicReservationUid : [],
    periodicCode: data.periodicCode ? data.periodicCode : "",
    name: data.name ? data.name : "",
    email: data.email ? data.email : "",
    phoneNumber: data.phoneNumber ? data.phoneNumber : "",
    startTime: data.startTime ? data.startTime : 0,
  };

  return validatedData;
};

const getNewDataFromResponse = (response, params) => {
  const total = response.data.total ? response.data.total : 0;
  const page = response.data.page ? response.data.page : 1;
  const totalPage = toCamel(response.data).totalPage ? toCamel(response.data).totalPage : 1;
  let responseData = response.data.data;
  let validatedData = validate(toCamel(responseData));
  let jsonArrayData = getJsonArray(validatedData);
  let notificationData = {
    total: total,
    data: jsonArrayData,
    totalPage: totalPage,
    page: page,
  };
  let dataNotification =
    params.type === AppConstant.DATA_TYPE.booking
      ? {
          bookingNotification: {
            ...notificationData,
          },
        }
      : {
          orderNotification: {
            ...notificationData,
          },
        };

  return dataNotification;
};
