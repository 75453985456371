import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";
import { formatCurrency, getCommonKey } from "utils";
import { CartImage } from "theme/images";

const PackageAndProductItem = ({ keyPackage }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const objPackageUsing = getLabel(LangConstant.OBJ_PACKAGE_USING_TYPE, { returnObjects: true });
  const objAdditionalPackage = getLabel(LangConstant.OBJ_ADDITIONAL_PACKAGE, { returnObjects: true });
  let itemAdditionalPackage = objAdditionalPackage[keyPackage];

  return (
    <Box className={classes.root}>
      <Box className={classes.boxImage}>
        <img src={CartImage} />
      </Box>
      <Typography className={classes.title}>{itemAdditionalPackage.title}</Typography>
      <Typography className={classes.description}>{itemAdditionalPackage.description}</Typography>
      <Box className="center-root">
        <Typography className={classes.price}>
          {StringFormat(
            getLabel(LangConstant.FM_CURRENCY),
            formatCurrency(itemAdditionalPackage.price.toString()),
            getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
          )}
        </Typography>
        <Typography className={clsx("medium-sm-txt", classes.packageUsing)}>
          {StringFormat(
            FM_PER_PACKAGE,
            itemAdditionalPackage.numberItem > 1 ? `${itemAdditionalPackage.numberItem} ` : "",
            objPackageUsing[keyPackage],
          )}
        </Typography>
      </Box>
    </Box>
  );
};

const FM_PER_PACKAGE = "/ {0}{1}";

PackageAndProductItem.propTypes = {
  keyPackage: PropTypes.number,
};

PackageAndProductItem.defaultProps = {};

export default PackageAndProductItem;

const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    minHeight: 304,
    padding: 36,
    backgroundColor: theme.palette.white,
    border: "1px solid #d4d5d8",
  },
  boxImage: {
    display: "flex",
    justifyContent: "center",
    height: 72,
    marginBottom: 24,
  },
  title: {
    fontSize: 15,
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: 1.67,
    color: "#3e4045",
    marginBottom: 8,
  },
  description: {
    fontSize: 13,
    textAlign: "center",
    fontWeight: 500,
    lineHeight: 1.69,
    color: "#47494e",
    marginBottom: 36,
  },
  price: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.53,
    color: "#3e4045",
  },
  packageUsing: {
    marginLeft: 4,
    lineHeight: 1.67,
    color: "#0e151f",
  },
}));
