import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { makeStyles } from "@material-ui/core";
import { TextArea } from "components";
import { onDoNothing } from "utils";

const InputNote = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const classes = useStyles();

  const note = "";

  return (
    <TextArea
      value={note}
      placeholder={getLabel(LangConstant.P_BOOK_NOTE)}
      onChange={onDoNothing}
      name="note"
      rows={5}
      multiline={true}
      classes={{
        root: classes.noteInputRoot,
      }}
    />
  );
};

InputNote.propTypes = {};

InputNote.defaultProps = {};

export default memo(InputNote);

const useStyles = makeStyles(() => ({
  noteInputRoot: {
    minHeight: 100,
    padding: 12,
  },
}));
