import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Button, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { getRealUseTimeByHour } from "utils/date";
import { useTranslation } from "react-i18next";

const StatusButton = ({
  color,
  isActive,
  isNext,
  disabled,
  children,
  onClick,
  className,
  isShowFinishedButtonMobile,
  realEndTime,
  realStartTime,
}) => {
  let statusStyle = createColorStyle(color, isActive, disabled);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const { t: getLabel } = useTranslation();

  let nextStatus = {
    borderWidth: 3,
  };
  if (isNext) {
    statusStyle = { ...statusStyle, ...nextStatus };
  }

  const classes = useStyles({
    statusStyle,
    isMobile: isMobile,
    isShowFinishedButtonMobile: isShowFinishedButtonMobile,
  });

  return (
    <Box className={classes.root}>
      {isShowFinishedButtonMobile && (
        <Box className={classes.endTimeConnector}>
          <Box className={classes.endTimeConnectorLineVertical}></Box>
          <Typography className={classes.realUseTimeText}>
            {getRealUseTimeByHour(realStartTime, realEndTime, getLabel)}
          </Typography>
          <Box className={classes.endTimeConnectorLineVertical}></Box>
        </Box>
      )}
      <Button
        size="small"
        disabled={disabled}
        onClick={onClick}
        classes={{ root: clsx(classes.btnRoot, className), disabled: classes.disabled }}
      >
        {children}
      </Button>
    </Box>
  );
};

StatusButton.propTypes = {
  color: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isNext: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
StatusButton.defaultProps = { disabled: false };

export default memo(StatusButton);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    position: props => (props.isShowFinishedButtonMobile ? "relative" : "unset"),
    marginTop: props => (props.isShowFinishedButtonMobile ? 70 : 0),
  },
  btnRoot: props => ({
    borderRadius: 2,
    borderStyle: "solid",
    fontWeight: "bold",
    borderWidth: 1,
    padding: "0 10px",
    minWidth: 90,
    minHeight: 40,
    fontSize: 10,
    ...props.statusStyle,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 22,
      marginRight: 16,
    },
  }),
  disabled: { opacity: 0.3, color: "white !important" },
  endTimeConnector: {
    position: "absolute",
    top: "-90px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  endTimeConnectorLineVertical: {
    width: 2,
    height: 24,
    backgroundColor: "#ebeced",
    margin: "0 2px",
  },
  realUseTimeText: {
    fontSize: 10,
    fontWeight: "bold",
    lineHeight: 2,
    margin: "0 2px",
  },
}));

const createColorStyle = (color, isActive, isDisabled) => {
  let activeStyle = {
    background: color,
    color: "white",
  };
  let jsonStyle = {
    borderColor: color,
    "&:hover": activeStyle,
  };
  if (isActive || isDisabled) {
    jsonStyle = { ...jsonStyle, ...activeStyle };
  }
  return jsonStyle;
};
