import { ApiConstant, AppConstant } from "../const";
import apisauce from "apisauce";
import Cookie from "js-cookie";

export const API_CONFIG_REG = {
  baseURL: ApiConstant.HOST_REGISTRATION,
  headers: ApiConstant.HEADER_DEFAULT,
  timeout: ApiConstant.TIMEOUT,
};

export const API_CONFIG_RESERVATION = {
  baseURL: ApiConstant.HOST_RESERVATION,
  headers: ApiConstant.HEADER_DEFAULT,
  timeout: ApiConstant.TIMEOUT,
};

export const API_CONFIG_ATTACHMENT = {
  baseURL: ApiConstant.HOST_ATTACHMENT,
  headers: ApiConstant.HEADER_DEFAULT,
  timeout: ApiConstant.TIMEOUT,
};

export const API_CONFIG_ATTACHMENT_UPLOAD = {
  baseURL: ApiConstant.HOST_ATTACHMENT,
  headers: ApiConstant.HEADER_DEFAULT_UPLOAD_FILE,
  timeout: ApiConstant.TIMEOUT,
};

export const API_CONFIG_DOWNLOAD_EXCEL = {
  baseURL: ApiConstant.HOST_RESERVATION,
  headers: ApiConstant.HEADER_DEFAULT,
  timeout: ApiConstant.TIMEOUT,
  responseType: ApiConstant.RESPONSE_TYPE_DOWNLOAD_EXCEL,
};

export const createApiWithToken = (initConfig = API_CONFIG_REG) => {
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  if (appData && appData.length > 0) {
    const token = JSON.parse(appData).token;
    initConfig.headers.Authorization = `Bearer ${token}`;
    const shop_id = JSON.parse(appData).shopId;
    initConfig.headers.shop_id = shop_id;
  }
  return apisauce.create(initConfig);
};

export const createApiWithTokenCustom = (initConfig = API_CONFIG_REG, token) => {
  if (token) {
    initConfig.headers.Authorization = `Bearer ${token}`;
  }
  return apisauce.create(initConfig);
};

export const createApiRegistration = () => createApiWithToken(API_CONFIG_REG);
export const createApiRegistrationCustom = token => createApiWithTokenCustom(API_CONFIG_REG, token);
export const createApiReservation = () => createApiWithToken(API_CONFIG_RESERVATION);
export const createApiAttachment = () => createApiWithToken(API_CONFIG_ATTACHMENT);
export const createApiAttachmentUpload = () => createApiWithToken(API_CONFIG_ATTACHMENT_UPLOAD);
export const createApiDownloadExcel = () => createApiWithToken(API_CONFIG_DOWNLOAD_EXCEL);
