import { call, put, select } from "redux-saga/effects";
import { ApiConstant } from "const";
import { RatingService } from "services";
import RatingAction from "redux/rating.redux";
import { toCamel, toSnake } from "utils";

export function* getRatingItem(action) {
  try {
    const { data } = action;
    let response = yield call(RatingService.getRatingItem, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(
        RatingAction.ratingSuccess({
          ratingList: toCamel(responseData.data),
          pagination: getPaginationObject(response.data),
        }),
      );
    } else {
      yield put(RatingAction.ratingFailure(response));
    }
  } catch (error) {
    yield put(RatingAction.ratingFailure(error));
  }
}

export function* getRatingShop(action) {
  try {
    const { data } = action;
    let response = yield call(RatingService.getRatingShop, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(
        RatingAction.ratingSuccess({
          ratingList: toCamel(responseData.data),
          pagination: getPaginationObject(response.data),
        }),
      );
    } else {
      yield put(RatingAction.ratingFailure(response));
    }
  } catch (error) {
    yield put(RatingAction.ratingFailure(error));
  }
}

export function* getRatingItemSummary(action) {
  try {
    const { data } = action;
    let response = yield call(RatingService.getRatingItemSummary, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(RatingAction.ratingSuccess({ ratingSummary: toCamel(responseData.data) }));
    } else {
      yield put(RatingAction.ratingFailure(response.data));
    }
  } catch (error) {
    yield put(RatingAction.ratingFailure(error));
  }
}

export function* getRatingShopSummary(action) {
  try {
    const { data } = action;
    let response = yield call(RatingService.getRatingShopSummary, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(RatingAction.ratingSuccess({ ratingSummary: toCamel(responseData.data) }));
    } else {
      yield put(RatingAction.ratingFailure(response));
    }
  } catch (error) {
    yield put(RatingAction.ratingFailure(error));
  }
}

export function* reportRating(action) {
  try {
    const { data } = action;
    const {
      ratingRedux: { ratingList },
    } = yield select();
    let response = yield call(RatingService.reportRating, data.uid);
    if (response.status === ApiConstant.STT_OK) {
      let newRatingList = [...ratingList];
      newRatingList = newRatingList.map(item => {
        if (item.uid === data.uid) {
          return {
            ...item,
            isReported: 1,
          };
        } else return item;
      });
      yield put(
        RatingAction.ratingSuccess({
          isReportSuccess: true,
          ratingList: newRatingList,
        }),
      );
    } else {
      yield put(RatingAction.ratingFailure(response.data));
    }
  } catch (error) {
    yield put(RatingAction.ratingFailure(error));
  }
}

const getPaginationObject = response => {
  return {
    totalPage: response.total_page || 0,
    size: response.size || 0,
    total: response.total || 0,
    page: response.page || 0,
  };
};
