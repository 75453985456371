import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close, ArrowBack, Dehaze } from "@material-ui/icons";

const DialogAddCategoryMenu = forwardRef((props, ref) => {
  const classes = useStyles();
  const { isShow, onAddAllService, onAddCategoryService, onClose, inputProps, titleName, onBack } = props;

  return (
    <Dialog
      classes={{ paper: classes.dialogBackground }}
      fullScreen
      open={isShow}
      onClose={() => onClose(false)}
      ref={ref}
    >
      <Box className={classes.rootHeader}>
        <Box className={classes.boxIconBack}>
          <IconButton onClick={onBack} className={classes.iconButton}>
            <ArrowBack className={classes.iconClose} />
          </IconButton>
        </Box>
        <Typography className={classes.nameHeaderTypo}>{titleName.header}</Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={() => onClose(false)} className={classes.iconButton}>
            <Close className={classes.iconClose} />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.addItem} onClick={onAddAllService}>
        <Box className={classes.boxHeader}>
          <Box className={classes.icon}>
            <Dehaze className={classes.colorIcon} />
          </Box>
          <Box className={classes.textProduct}>
            <Typography {...inputProps}>{titleName.add}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.createItem} onClick={onAddCategoryService}>
        <Box className={classes.boxHeader}>
          <Box className={classes.icon}>
            <Dehaze className={classes.colorIcon} />
          </Box>
          <Box className={classes.textProduct}>
            <Typography {...inputProps}>{titleName.created}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
});

export default DialogAddCategoryMenu;
const useStyles = makeStyles(theme => ({
  dialogBackground: {
    background: "#f2f2f7",
  },
  header: {
    textAlign: "center",
    color: theme.palette.grey[800],
    fontSize: 24,
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  addItem: {
    width: "70%",
    minHeight: 80,
    margin: "68px 148px 41px 0",
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    marginLeft: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  createItem: {
    width: "70%",
    minHeight: 80,
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    marginLeft: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  icon: {
    marginLeft: "10%",
    backgroundColor: "#ebf7fe",
    marginTop: 28,
    paddingTop: 5,
    width: 36,
    height: 36,
    textAlign: "center",
  },
  textProduct: {
    marginTop: 35,
    marginBottom: 28,
    marginLeft: 10,
    width: "85%",
    height: "100%",
    fontSize: 16,
    textAlign: "left",
  },
  boxHeader: {
    display: "flex",
    cursor: "pointer",
  },
  colorIcon: {
    color: "#7f838c",
  },
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  iconClose: {
    width: 36,
    height: 36,
  },
}));

DialogAddCategoryMenu.propTypes = {
  isShow: PropTypes.bool.isRequired,
  action: PropTypes.func,
  inputProps: PropTypes.object,
  titleName: PropTypes.object.isRequired,
};

DialogAddCategoryMenu.defaultProps = {};
