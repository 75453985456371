import React from "react";
import { debounce } from "@material-ui/core";
import { TablePagination } from "components/table";
import { AppConstant } from "const";
import { useDispatch, useSelector } from "react-redux";
import ReservationHistoryAction from "redux/reservation-history.redux";

const BookingHistoryPagination = () => {
  const dispatch = useDispatch();
  const [total, totalPage] = useSelector(state => [
    state.reservationHistoryRedux.bookingHistory.total,
    state.reservationHistoryRedux.bookingHistory.totalPage,
  ]);
  const [page, rowsPerPage] = useSelector(state => [
    state.reservationHistoryRedux.filter.page,
    state.reservationHistoryRedux.filter.size,
  ]);

  const onCallApi = debounce(
    params => dispatch(ReservationHistoryAction.getBookingHistory(params)),
    AppConstant.DEBOUNCE_TIMEOUT,
  );

  return (
    <TablePagination
      total={total || 0}
      totalPage={totalPage}
      page={page || 0}
      rowsPerPage={rowsPerPage}
      onChange={newPage =>
        onCallApi({
          page: newPage,
        })
      }
    />
  );
};
export default BookingHistoryPagination;
