import React, { memo, useState, useEffect } from "react";
import { LangConstant, AppConstant } from "const";
import { Button, Box, makeStyles } from "@material-ui/core";
import { PersonOutlineRounded } from "@material-ui/icons/";
import { useTranslation } from "react-i18next";
import { validatePassword, isSttException, getCommonKey } from "utils";
import { FormError, FormInput, InputPassword, Processing } from "components";
import { useDispatch, useSelector } from "react-redux";
import AuthAction from "redux/auth.redux";

const FormJoinGroup = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_GROUP_ADMIN);
  const dispatch = useDispatch();
  const classes = useStyles();
  const isFetching = useSelector(({ authRedux }) => authRedux.isFetching);
  const errorResponse = useSelector(({ authRedux }) => authRedux.error);
  const sttResponse = useSelector(({ authRedux }) => authRedux.status);

  const [errContent, setErrContent] = useState([]);
  const [data, setData] = useState({
    username: localStorage.getItem(AppConstant.KEY_USERNAME) || "",
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const onChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const onSubmit = event => {
    const { password } = data;
    let waring = [];

    if (password && !Boolean(validatePassword(password))) {
      waring.push(getLabel(getCommonKey(LangConstant.ERR_PASSWORD)));
    }

    if (waring.length > 0) {
      // Set warning to display when valid form
      setErrContent(waring);
    } else {
      let sendData = { ...data, isGroup: true };
      dispatch(AuthAction.requestLogin(sendData));
      setErrContent([]);
      setIsSubmit(true);
    }
    event.preventDefault();
  };

  // Call Api Error
  useEffect(() => {
    if (!isSubmit) return;
    let isError = !isFetching && errorResponse;
    if (isError) {
      setErrContent([
        getLabel(getCommonKey(isSttException(sttResponse) ? LangConstant.ERR_EXCEPTION : LangConstant.ERR_LOGIN)),
      ]);
    }
  }, [isFetching, errorResponse]);

  // After complete calling api, update isSubmit is false
  useEffect(() => {
    if (!isFetching && isSubmit) {
      setIsSubmit(false);
    }
  }, [isFetching]);

  // After show error, rest error in redux
  useEffect(() => {
    if (errContent && errContent.length > 0) {
      dispatch(AuthAction.authReset());
    }
  }, [errContent]);

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <Box className={classes.authInputBox}>
        <FormInput
          name={AppConstant.KEY_USERNAME}
          icon={<PersonOutlineRounded />}
          placeholder={getLabel(getCommonKey(LangConstant.P_USERNAME))}
          onChange={onChange}
          value={data.username}
          required
        />
        <InputPassword
          name={AppConstant.KEY_OLD}
          placeholder={getLabel(getCommonKey(LangConstant.P_PASSWORD))}
          onChange={onChange}
          required
          autoComplete="current-password"
        />
      </Box>
      <Box className={classes.boxError}>
        {errContent.length > 0 &&
          errContent.map((error, index) => <FormError className={classes.formError} error={error} key={index} />)}
        <Button type="submit" color="primary" variant="contained" size="large">
          {getLabel(LangConstant.TXT_JOIN)}
        </Button>
      </Box>
      {isFetching && <Processing isShow={true} />}
    </form>
  );
};

FormJoinGroup.propTypes = {};
FormJoinGroup.defaultProps = {};

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 454,
  },
  authInputBox: {
    width: "100%",
    maxWidth: 454,
    marginTop: 40,
  },
  boxError: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 30,
    marginBottom: 30,

    " &:has(.error-container)": {
      marginTop: 20,
    },

    button: {
      marginTop: 30,
    },
  },
  formError: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default memo(FormJoinGroup);
