import React, { memo } from "react";
import { makeStyles, Box, Typography, OutlinedInput, useTheme } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import InfoStatus from "./InfoStatus";
import { createStyleStatus } from "utils";

const Line = ({
  title,
  content,
  name,
  onChange,
  placeholder,
  isStatus,
  isEditing,
  isCopying,
  disable,
  isHasPreOrder,
  inputProps,
}) => {
  const theme = useTheme();
  const classes = useStyles(createStyleStatus(theme.palette.reservation, parseInt(content)));
  const { t: getLabel } = useTranslation();

  return (
    <>
      {!(isEditing || isCopying) ? (
        <Box className={classes.rootLine}>
          <Typography className={classes.titleLine}>{title}</Typography>
          <Typography className={!isStatus ? classes.contentLine : classes.contentStatus}>
            {!isStatus ? content : getLabel(LangConstant.ARR_STATUS_RESERVATION[content - 1])}
          </Typography>
        </Box>
      ) : !isStatus ? (
        <Box className={classes.rootLine}>
          <Typography className={classes.titleLine}>{title}</Typography>
          <OutlinedInput
            fullWidth
            disabled={disable}
            value={content}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
            {...inputProps}
          />
        </Box>
      ) : (
        <Box className={classes.rootLine}>
          <InfoStatus
            label={title}
            status={content || 1}
            name={name}
            onChange={onChange}
            isCopying={isCopying}
            isEditing={isEditing}
            isHasPreOrder={isHasPreOrder}
          />
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  rootLine: {
    width: "100%",
    minHeight: 30,
    display: "flex",
    marginBottom: 4,
  },
  titleLine: {
    minWidth: "40%",
    height: 22,
    fontSize: 13,
    marginTop: 4,
  },
  contentLine: {
    maxWidth: 300,
    minHeight: 22,
    fontSize: 13,
    marginTop: 4,
    paddingTop: 2,
  },
  contentStatus: {
    height: 22,
    minWidth: 87,
    fontSize: 12,
    marginTop: 4,
    paddingTop: 3,
    textAlign: "center",
    color: theme.palette.white,
    backgroundColor: props => props.color,
  },
  contentLineEdit: {
    height: 30,
    fontSize: 13,
    padding: "4px 16px",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  inputEdit: {
    padding: 0,
  },
}));

export default memo(Line);
