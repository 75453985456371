import React, { memo } from "react";
import { makeStyles, TableCell, TableSortLabel } from "@material-ui/core";
import { SwapVert } from "@material-ui/icons";
import { LangConstant } from "../../../../const";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CellHead = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { cellData, onFilter, className } = props;
  const showFilter = [
    getLabel(LangConstant.TXT_FULL_NAME),
    getLabel(LangConstant.TXT_ROLE),
    getLabel(LangConstant.TXT_BIRTH_DAY),
    getLabel(LangConstant.TXT_SEX),
    getLabel(LangConstant.TXT_STARTED_DATE),
    getLabel(LangConstant.TXT_CODE_STAFF),
  ];
  const showFilterMiddle = [getLabel(LangConstant.TXT_SEX)];
  const Middle = [getLabel(LangConstant.TXT_ACCOUNT_STATUS)];

  const tableSortLabel = (Cell, Classes) => (
    <TableCell align="center" className={`${Classes} ${className}`}>
      {Cell}
      <TableSortLabel onClick={onFilter} classes={{ icon: classes.iconSort }}>
        <SwapVert className={classes.iconSwag} />
      </TableSortLabel>
    </TableCell>
  );

  const table = (Cell, Classes) => (
    <TableCell align="center" className={`${Classes} ${className}`}>
      {Cell}
    </TableCell>
  );

  return (
    <>
      {showFilter.includes(cellData)
        ? tableSortLabel(cellData, showFilterMiddle.includes(cellData) ? classes.rootMiddle : classes.rootLeft)
        : table(cellData, Middle.includes(cellData) ? classes.rootMiddle : classes.rootLeft)}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  rootLeft: {
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: "#ffffff",
    minWidth: 160,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "left",
  },
  rootMiddle: {
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: "#ffffff",
    minWidth: 100,
    padding: "8px 0px",
  },
  iconSort: {
    display: "none",
  },
  iconSwag: {
    marginLeft: 10,
    opacity: 1,
    fill: "#4b4d53",
  },
}));

CellHead.propTypes = {
  cellData: PropTypes.string,
  style: PropTypes.object,
};

export default memo(CellHead);
