import React, { useState } from "react";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import { Box, Button, makeStyles } from "@material-ui/core";

const FilterButtonOrder = ({ onClick, index }) => {
  const classes = useStyles();
  const [isDisabledButtonUp, setIsDisabledButtonUp] = useState(true);
  const [isDisabledButtonDown, setIsDisabledButtonDown] = useState(false);

  const onClickButton = (index, type) => {
    if (type === FILTER.up) {
      setIsDisabledButtonUp(true);
      setIsDisabledButtonDown(false);
    } else {
      setIsDisabledButtonUp(false);
      setIsDisabledButtonDown(true);
    }
    onClick(index, type);
  };

  return (
    <Box className={classes.root}>
      <Button
        classes={{ root: classes.iconButtonTop, startIcon: classes.startIcon }}
        startIcon={<ArrowDropUp />}
        onClick={() => onClickButton(index, FILTER.up)}
        disabled={isDisabledButtonUp}
      />
      <Button
        classes={{ root: classes.iconButton, startIcon: classes.startIcon }}
        startIcon={<ArrowDropDown />}
        onClick={() => onClickButton(index, FILTER.down)}
        disabled={isDisabledButtonDown}
      />
    </Box>
  );
};

export const FILTER = { up: 0, down: 1 };

const useStyles = makeStyles({
  root: {
    display: "inline-grid",
    marginLeft: 5,
  },
  iconButtonTop: {
    width: 18,
    minWidth: 18,
    height: 12,
    marginBottom: -5,
  },
  iconButton: {
    width: 18,
    minWidth: 18,
    height: 12,
  },
  startIcon: {
    display: "inline-grid",
    marginLeft: 0,
    marginRight: 0,
  },
});

export default FilterButtonOrder;
