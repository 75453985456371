import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dialog, makeStyles, Typography, DialogTitle, IconButton, Box, Button } from "@material-ui/core";
import { Close, LocationOnOutlined } from "@material-ui/icons";
import { LangConstant } from "const";
import AvailableBlock from "./AvailablePlaceList";
import UsingPlacesBlock from "./UsingPlacesList";
import PlaceDialogFilter from "./PlaceDialogFilter";

const PlaceStatusDialog = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLACE_MANAGE);

  const [isShow, setIsShow] = useState(false);

  const onCreateEvent = isShow => () => setIsShow(isShow);

  return (
    <>
      <Button onClick={onCreateEvent(true)} className={classes.locationButton}>
        <LocationOnOutlined />
      </Button>
      <Dialog
        open={isShow}
        fullScreen
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Typography className={classes.dialogTitleText} variant="h5">
            {getLabel(LangConstant.TXT_PLACE_STATUS_IN_STOCK)}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onCreateEvent(false)}
            id={CLOSE_BUTTON_ID}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        <Box className={classes.dialogContent}>
          <PlaceDialogFilter />
          <Box className={classes.dialogMainContent}>
            <AvailableBlock />
            <UsingPlacesBlock />
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const CLOSE_BUTTON_ID = "close-dialog-button";

PlaceStatusDialog.propTypes = {
  onCloseDialog: PropTypes.func,

  isShow: PropTypes.bool,
};

PlaceStatusDialog.defaultProps = {
  onCloseDialog: () => {},

  isShow: false,
};

export default memo(PlaceStatusDialog);

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: 30,
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },

  closeButton: {
    position: "absolute",
    right: "1.5%",
    top: 36,
  },

  closeIcon: {
    width: 36,
    height: 36,
  },

  dialogContent: {
    height: "100%",
    padding: "0 135px",
    marginTop: 24,
    paddingBottom: 16,
    [theme.breakpoints.down(1000)]: {
      padding: "0 50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
  },

  dialogMainContent: {
    marginTop: 4,
    display: "grid",
    justifyContent: "space-between",
    minHeight: "calc(100% - 66px)",
    gridAutoFlow: "column",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: 20,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  locationButton: {
    borderRadius: 0,
    width: 30,
    height: 30,
    backgroundColor: theme.palette.white,
    margin: "0px 2px",
    border: "solid 1px " + theme.palette.grey["200"],
    textTransform: "none",
    padding: 0,
    minWidth: "unset",
    "&>*>svg": {
      width: "75%",
      height: "75%",
    },
    "&:hover": {
      backgroundColor: "#3e4045",
      color: "#fff",
      "&>span>p": {
        color: "#fff",
      },
    },
  },
}));
