import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, makeStyles, Menu, MenuItem, OutlinedInput, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

const MinuteHourTimePicker = props => {
  const { onChange, classes, minuteValue, hourValue, minuteArray, hourArray, isDisableSelectTime, ...otherProps } =
    props;
  const defaultClass = useStyle();
  const hourArr = hourArray || HOURS_ARR;
  const minArr = minuteArray || MINUTE_ARR;
  const [selectedHour, setSelectedHour] = useState(hourValue);
  const [selectedMin, setSelectedMin] = useState(minuteValue);
  const [disableSelectTime, setDisableSelectTime] = useState(isDisableSelectTime);

  useEffect(() => {
    setSelectedHour(hourValue);
    setSelectedMin(minuteValue);
  }, [hourValue, minuteValue]);

  const onChangeHour = value => {
    setSelectedHour(value);
    onChange(value, selectedMin);
  };
  const onChangeMinute = value => {
    setSelectedMin(value);
    onChange(selectedHour, value);
  };

  useEffect(() => {
    setDisableSelectTime(isDisableSelectTime);
  }, [isDisableSelectTime]);
  return (
    <Box className={clsx(defaultClass.minAndHourContainerBox, classes?.rootInputBox)}>
      <SelectTimePicker
        timeArray={hourArr}
        onChange={onChangeHour}
        selectedValue={selectedHour}
        classes={{ input: defaultClass.hourPickerInput }}
        isDisableSelectTime={disableSelectTime}
        {...otherProps}
      />
      <Typography className={`medium-md-txt ${defaultClass.timeDivider}`}>:</Typography>
      <SelectTimePicker
        timeArray={minArr}
        onChange={onChangeMinute}
        selectedValue={selectedMin}
        classes={{ input: defaultClass.minPickerInput }}
        isDisableSelectTime={disableSelectTime}
        {...otherProps}
      />
    </Box>
  );
};

MinuteHourTimePicker.propTypes = {
  minuteValue: PropTypes.number,
  hourValue: PropTypes.number,

  onChange: PropTypes.func.isRequired,

  classes: PropTypes.object,
};

MinuteHourTimePicker.defaultProps = {
  minuteValue: 0,
  hourValue: 0,
};

export default memo(MinuteHourTimePicker);
const SelectTimePicker = ({ timeArray, onChange, selectedValue, classes, isDisableSelectTime, ...otherProps }) => {
  const inputRef = useRef();
  const defaultClass = useStyle({ isDisableSelectTime });
  let maxValueInput = timeArray[timeArray.length - 1] === 55 ? MAX_MINUTE_VALUE : timeArray[timeArray.length - 1];
  const [selectMenuAnchor, setSelectMenuAnchor] = useState(null);
  const [timeSelected, setTimeSelected] = useState(selectedValue);
  let isShowList = Boolean(selectMenuAnchor);

  const onChangeInputValue = value => {
    if (value <= maxValueInput) {
      onChange(parseInt(value));
      onCloseSelectList();
    }
  };

  const onChangeValue = e => {
    let inputValue = parseInt(e.target.value) || 0;
    onChangeInputValue(inputValue);
  };

  const onCloseSelectList = () => {
    setSelectMenuAnchor(null);
  };

  const onClickDropIcon = () => {
    if (!isDisableSelectTime) setSelectMenuAnchor(isShowList ? null : inputRef.current);
  };

  useEffect(() => {
    setTimeSelected(selectedValue);
  }, [selectedValue]);

  return (
    <Box className={classes?.input}>
      <OutlinedInput
        ref={inputRef}
        type="text"
        inputProps={{
          max: maxValueInput,
          min: 0,
          maxLength: 2,
          pattern: "d*",
        }}
        value={timeSelected}
        endAdornment={
          <IconButton onClick={onClickDropIcon} className={defaultClass.arrowButton}>
            {isShowList ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        }
        onChange={onChangeValue}
        classes={{
          root: defaultClass.inputTimeRoot,
          inputAdornedEnd: defaultClass.inputField,
        }}
        disabled={isDisableSelectTime}
        {...otherProps}
      />
      <Menu
        open={!isDisableSelectTime && isShowList}
        onBackdropClick={onCloseSelectList}
        anchorEl={selectMenuAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        classes={{ paper: defaultClass.selectMenu }}
      >
        {timeArray &&
          timeArray.map((ele, index) => (
            <MenuItem
              key={index}
              value={ele}
              onClick={e => onChangeInputValue(e.target.value)}
              classes={{
                root: defaultClass.menuItemRoot,
              }}
            >
              {ele}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

SelectTimePicker.propTypes = {
  selectedValue: PropTypes.number,
  timeArray: PropTypes.array,

  onChange: PropTypes.func,

  classes: PropTypes.object,
};
SelectTimePicker.defaultProps = {
  selectedValue: 0,
  timeArray: [],
};

const HOURS_ARR = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const MINUTE_ARR = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
const MAX_MINUTE_VALUE = 59;
const useStyle = makeStyles(theme => ({
  selectMenu: {
    height: "fit-content",
    maxHeight: 150,
    marginTop: 3,
    width: 75,
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#d4d5d8",
    },
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&>ul": {
      padding: 0,
    },
  },
  inputTimeRoot: {
    width: 80,
    height: 30,
    padding: 0,
    [theme.breakpoints.down(320)]: {
      width: "auto",
    },
  },
  inputField: {
    padding: "5px 16px",
    fontSize: 12,
    color: "#3e4045",
    height: "auto",
    backgroundColor: props => (props.isDisableSelectTime ? " #f2f2f2" : theme.palette.white),
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
      MozAppearance: "textfield",
    },
  },
  arrowButton: {
    padding: 0,
    width: 30,
    height: "100%",
    borderLeft: "1px solid #d4d5d8",
    borderRadius: "unset",
    color: theme.palette.grey[500],
    backgroundColor: props => (props.isDisableSelectTime ? " #f2f2f2" : theme.palette.white),
  },
  minAndHourContainerBox: {
    display: "flex",
    alignItems: "center",
  },
  hourPickerInput: {
    marginRight: 7,
  },
  minPickerInput: {
    marginLeft: 7,
  },
  menuItemRoot: {
    "&:hover": {
      backgroundColor: "#4b4d53",
      color: "#ffffff",
    },
  },
  timeDivider: {
    color: "#3e4045",
  },
}));
