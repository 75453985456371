import { call, put } from "redux-saga/effects";
import { ApiConstant, AppConstant } from "const";
import StaffAction from "redux/staff.redux";
import PaymentAction from "redux/payment.redux";
import { StaffService } from "services";
import { toCamel, toSnake } from "utils";

export function* postStaffWorkingShift(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.postWorkingShift, toSnake(data));
    let responseData = validate(toCamel(response.data.data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.postWorkingShiftSuccess(responseData));
    } else {
      yield put(StaffAction.postWorkingShiftFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.postWorkingShiftFailure(error));
  }
}

export function* postStaffPosition(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.postStaffPosition, data);
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.postPositionSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.postPositionFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.postPositionFailure(error));
  }
}

export function* getWorkingPotision() {
  try {
    let response = yield call(StaffService.getWorkingPotision);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(StaffAction.getWorkingPotisionSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.getWorkingPotisionFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.getWorkingPotisionFailure(error));
  }
}

export function* getWorking() {
  try {
    let response = yield call(StaffService.getWorking);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(StaffAction.getWorkingSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.getWorkingFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.getWorkingFailure(error));
  }
}

export function* removePostWorking(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.removePostWorking, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.removePostWorkingSuccess(toCamel(data)));
    } else {
      yield put(StaffAction.removePostWorkingFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.removePostWorkingFailure(error));
  }
}

export function* removeStaffPosition(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.removeStaffPosition, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.removeStaffPositionSuccess(toCamel(data)));
    } else {
      yield put(StaffAction.removeStaffPositionFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.removeStaffPositionFailure(error));
  }
}

export function* updateStaffPosition(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.updateStaffPosition, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.updateStaffPositionSuccess(toCamel(response.data)));
    } else {
      yield put(StaffAction.updateStaffPositionFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.updateStaffPositionFailure(error));
  }
}

export function* updateWorkingShift(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.updateWorkingShift, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.updateWorkingShiftSuccess(toCamel(response.data)));
    } else {
      yield put(StaffAction.updateWorkingShiftFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.updateWorkingShiftFailure(error));
  }
}

export function* getStaffTable(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.getStaffTable, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(StaffAction.staffTableSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.staffTableFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.staffTableFailure(error));
  }
}

export function* getWorkingShift() {
  try {
    let response = yield call(StaffService.getWorkingShift);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(StaffAction.workingShiftSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.workingShiftFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.workingShiftFailure(error));
  }
}

export function* getStaffPosition() {
  try {
    let response = yield call(StaffService.getStaffPosition);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(StaffAction.staffPositionSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.staffPositionFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.staffPositionFailure(error));
  }
}

export function* patchInvitationStaff(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.patchInvitationStaff, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      yield put(StaffAction.invitationStaffSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.invitationStaffFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.invitationStaffFailure(error));
  }
}

export function* postCreateStaff(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.postStaff, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.createStaffSuccess(toCamel(responseData)));
    } else if (response.status === ApiConstant.STT_UN_PROCESSABLE_ENTITY) {
      yield put(PaymentAction.getUnProcessableEntity());
    } else {
      yield put(StaffAction.createStaffFailure(response.data));
    }
    yield put(StaffAction.resetStaff(responseData));
  } catch (error) {
    yield put(StaffAction.createStaffFailure(error));
  }
}

export function* putEditStaff(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.putStaff, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.editStaffSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.editStaffFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.editStaffFailure(error));
  }
}

export function* getWorkingShifts() {
  try {
    let response = yield call(StaffService.getWorkingShifts, {});
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.getWorkingShiftSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.getWorkingShiftFailure(responseData));
    }
  } catch (error) {
    yield put(StaffAction.getWorkingShiftFailure(error));
  }
}

export function* getStaff() {
  try {
    let response = yield call(StaffService.requestStaff, { state: AppConstant.STATE.active });
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.getStaffSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.getStaffFailure(responseData));
    }
  } catch (error) {
    yield put(StaffAction.getStaffFailure(error));
  }
}

export function* getPosition() {
  try {
    let response = yield call(StaffService.requestPosition, {});
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.getPositionSuccess(toCamel(responseData)));
    } else {
      yield put(StaffAction.getPositionFailure(responseData));
    }
  } catch (error) {
    yield put(StaffAction.getPositionFailure(error));
  }
}

export function* deleteStaff(action) {
  try {
    const { uid } = action.data;
    let response = yield call(StaffService.deleteStaff, uid);
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.deleteStaffSuccess(toCamel(response.data)));
    } else {
      yield put(StaffAction.deleteStaffFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.deleteStaffFailure(error));
  }
}

export function* getStaffBusyStatus(action) {
  try {
    const { data } = action;
    let response = yield call(StaffService.getStaffBusyStatus, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(StaffAction.getStaffBusyStatusSuccess(toCamel(restructureBusyStatusArray(response.data.data))));
    } else {
      yield put(StaffAction.getStaffBusyStatusFailure(response.data));
    }
  } catch (error) {
    yield put(StaffAction.getStaffBusyStatusFailure(error));
  }
}

const validate = item => {
  const validatedItem = {
    uid: item.uid ? item.uid : "",
    name: item.name ? item.name : "",
    endTime: item.endTime ? item.endTime : 0,
    startTime: item.startTime ? item.startTime : 0,
  };
  return validatedItem;
};

const restructureBusyStatusArray = object => {
  return toCamel(
    Object.entries(object).map(entry => {
      return { time: entry[0], staff: entry[1].staff };
    }),
  );
};
