export default {
  TXT_FULL_SCREEN: "Toàn màn hình",
  TXT_CONTENT: "Nội dung",
  TXT_ADD_NOTE_DIALOG_TITLE: "Thêm ghi chú nội bộ",
  TXT_ADD_INTERNAL_NOTE: "Thêm ghi chú",
  TXT_OUT_OF_DATE: "Quá hạn",
  TXT_INTERNAL_NOTE_EDIT_MILESTONE: "Chỉnh sửa lần cuối vào lúc {0} bởi {1}",
  TXT_FILTER: "Lọc",
  TXT_DOWNLOAD: "Tải xuống",
  TXT_EXIT_FULL_SCREEN: "Thoát toàn màn hình",
  TXT_BOOKING_DISPLAY_SETTING: "Cài đăt thông tin hiển thị",
  TXT_SETTING_DIALOG_TITLE: "Cài đặt thông tin",
  TXT_BASIC_DISPLAY_INFO_SETTING: "Thông tin mặc định",
  TXT_BASIC_DISPLAY_INFO_SETTING_DESCRIPTION: "Các thông tin hiển thị mặc định trên thẻ lịch hẹn",
  TXT_OPTIONAL_INFO_SETTING: "Thông tin tuỳ chọn",
  TXT_OPTIONAL_INFO_SETTING_DESCRIPTION: "Chọn những thông tin bạn muốn hiển thị trên thẻ lịch hẹn",
  TXT_ASSIGNED_STAFF: "Nhân viên được chỉ định",
  TXT_BOOKING_STATUS: "Trạng thái lịch hẹn",
  TXT_CUSTOMER_NUMBER: "Số khách hàng",
  TXT_DECLINE_RESERVATION: "Hủy đơn đặt chỗ \n {0} - {1}?",
  TXT_CONFIRM_DECLINE_RESERVATION: "Bạn có chắc muốn hủy đơn đặt chỗ này không?",
  TXT_REJECT_RESERVATION: "Từ chối đơn đặt chỗ \n {0} - {1}?",
  TXT_CONFIRM_REJECT_RESERVATION: "Bạn có chắc muốn từ chối đơn đặt chỗ này không?",
  TXT_HAVE_CHOOSE: "Đã chọn",
  TXT_TOTAL: "Tổng cộng",
  TXT_INCLUDED_TAX: "Đã bao gồm thuế",
  TXT_BEFORE_BOOKING: "Đặt trước",
  TXT_CATEGORY_PRODUCT: "Danh mục sản phẩm",
  TXT_TOPPING: "Dịch vụ đi kèm",
  TXT_PRE_ORDER: "Thanh toán trước",
  TXT_INFO_PRE_BOOKING: "Thông tin đặt trước",
  TXT_CHOOSE_FROM_MENU: "Chọn từ menu",
  TXT_CHOOSE_FROM_PRODUCT_CATEGORY: "Chọn từ danh mục sản phẩm",

  FM_NUMBER_PRODUCT: "{0} sản phẩm",
  TXT_WAITING_CONSUMER: "Khách chờ",
  TXT_LIST_WAITING_CONSUMER: "Danh sách khách chờ",
  TXT_INVITE_WAITING_CONSUMER: "Thêm khách chờ",
  TXT_EDIT_WAITING_CONSUMER: "Chỉnh sửa khách chờ",
  TXT_ARRANGE_SEAT: "Xếp chỗ",
  TXT_CREATE_BOOKING_FROM_WAITING_CONSUMER: "Tạo đặt chỗ từ khách chờ",
  TXT_LATE: "Đến muộn",
  TXT_NO_RESERVATION_YET: "Chưa đặt chỗ",
  TXT_STATE_WAITING_CONSUMER: "Tình trạng",
  TXT_WAITING_TIME: "Thời gian chờ",
  TXT_PLACE: "Vị trí",
  TXT_ADD_ORDER_FROM_MENU: "Thêm mới đơn hàng từ Menu",
  TXT_EXPORT_BILL: "Xuất hóa đơn",
  TXT_CHOOSE_REQUIRED_CATEGORY: "Chọn phân loại (Bắt buộc)",

  MSG_MOVE_TO_PENDING_CONSUMER_LIST: "Chuyển sang danh sách khách chờ?",
  MSG_MOVE_TO_PENDING_CONSUMER_LIST_CONFIRM_MESSAGE:
    'Bạn có chắc muốn chuyển đơn đặt chỗ "{0}" thành danh sách khách chờ không?',
  FM_NOTICE_TITLE: 'Xóa "{0}" khỏi danh sách khách chờ?',
  FM_NOTICE_CONTENT: 'Bạn có chắc muốn xóa "{0}" khỏi danh sách khách chờ không?',
};
