import React, { useState } from "react";
import PostList from "./PostList";
import PostUpload from "./PostUpload";
import { Box, makeStyles } from "@material-ui/core";
import Cookie from "js-cookie";
import { AppConstant } from "const";

const PostManageLayout = () => {
  let avt = [];
  const classes = useStyles();
  const [changeTabs, setChangeTabs] = useState(true);

  const onChangeTabs = () => {
    setChangeTabs(!changeTabs);
  };
  const role = Cookie.get(AppConstant.ROLE);
  if (role === AppConstant.SHOP_STAFF) {
    return (
      <Box className={classes.postLayout}>
        <Box className={classes.postMainLayoutStaff}>
          <PostList
            className={`${changeTabs ? classes.postListContainer : classes.postListContainerHiddenMobile}`}
            changeTabs={onChangeTabs}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className={classes.postMainLayout}>
        <Box className={`${changeTabs ? classes.postListLayout : classes.postListLayoutHidden}`}>
          <PostList
            className={`${changeTabs ? classes.postListContainer : classes.postListContainerHiddenMobile}`}
            changeTabs={onChangeTabs}
          />
        </Box>
        <Box className={classes.postUploadContainer}>
          <PostUpload
            className={`${changeTabs ? classes.postUploadHiddenMobile : classes.postUploadShowing}`}
            avt={avt}
          />
        </Box>
      </Box>
    );
  }
};

export default PostManageLayout;

const useStyles = makeStyles(() => ({
  postMainLayout: {
    display: "-webkit-inline-box",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    "@media only screen and (max-width: 414px)": {
      height: "auto",
    },
    overflow: "hidden",
    height: "94vh",
  },
  postMainLayoutStaff: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    "@media only screen and (max-width: 414px)": {
      width: "100%",
    },
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    marginRight: "0.175%",
    maxWidth: 680,
  },
  postLayout: { display: "flex", justifyContent: "center" },
  postListContainer: {
    width: "100%",
    textAlign: "-webkit-center",
    "@media only screen and (max-width: 414px)": {
      width: "100%",
      backgroundColor: "#ffffff",
    },
  },
  postListContainerHiddenMobile: {
    "@media only screen and (max-width: 414px)": {
      display: "none",
    },
  },
  postListLayoutStaff: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    marginRight: "0.175%",
    maxWidth: 680,
    width: "100%",
    textAlign: "center",
    overflow: "auto",
    "@media only screen and (max-width: 414px)": {
      width: "100%",
    },
  },
  postListLayout: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    marginRight: "0.175%",
    width: "50%",
    overflow: "auto",
    "@media only screen and (max-width: 414px)": {
      width: "100%",
    },
  },
  postListLayoutHidden: {
    "@media only screen and (max-width: 414px)": {
      display: "none",
    },
  },
  postUploadShowing: {
    width: "100%",
    height: "fit-content",
    minHeight: "90%",
    textAlign: "-webkit-center",
    marginBottom: "10%",
  },
  postUploadHiddenMobile: {
    "@media only screen and (max-width: 414px)": {
      display: "none",
    },
  },
  postUploadContainer: {
    width: " 50%",
    overflow: "auto",
    "@media only screen and (max-width: 414px)": {
      width: "100%",
    },
  },
}));
