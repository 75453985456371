import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";
import { Button, makeStyles, Table, TableContainer, TableFooter, Typography, Paper, TableRow } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import OrderTableHeader from "./OrderTableHeader";
import OrderTableRow from "./OrderTableRow";
import DialogAddNewOrder from "../../DialogAddNewOrder";
import { formatCurrency, getCommonKey } from "utils";
import { CellBody } from "components";

const OrderDetailTable = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);

  const [open, setOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const onAddItems = () => setOpen(true);

  useEffect(() => {
    if (data.orderDetailsList.length > 0) {
      let newTotal = {
        totalPrice: 0,
        totalItems: 0,
      };

      data.orderDetailsList.forEach(dataMap => {
        let orderNumber = dataMap.orderNumber || 0;
        let priceTaxIn = dataMap.priceTaxIn || 0;

        newTotal.totalItems += orderNumber;
        newTotal.totalPrice += priceTaxIn * orderNumber;

        if (dataMap.type !== AppConstant.ITEM_TYPE.comboItem) {
          // calculate toppings price of item which isn't belong to Combo category
          dataMap.orderDetailsContentList?.forEach(item => {
            let priceTaxInItem = item.priceTaxIn || 0;

            newTotal.totalPrice += priceTaxInItem * orderNumber;
          });
        }
      });

      setTotalItems(newTotal.totalItems);
      setTotalPrice(newTotal.totalPrice);
    }
  }, [data]);

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="order detail table">
          <OrderTableHeader />

          {data.orderDetailsList.map((dataRow, index) => (
            <OrderTableRow key={index} dataRow={dataRow} orderDetailData={data} />
          ))}
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              <CellBody
                cellData={
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.addItemButton}
                    onClick={onAddItems}
                    startIcon={<Add className={classes.addIcon} />}
                  >
                    <Typography color="inherit" className="medium-md-txt">
                      {getLabel(LangConstant.TXT_ADD_ITEMS)}
                    </Typography>
                  </Button>
                }
              />
              <CellBody />
              <CellBody cellData={<Typography className="semiBold-md-txt">{totalItems}</Typography>} />
              <CellBody />
              <CellBody
                cellData={
                  <Typography className="semiBold-md-txt">
                    {formatCurrency(totalPrice, getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON))) || ""}
                  </Typography>
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {open && <DialogAddNewOrder open={open} onClose={() => setOpen(false)} selectedItems={data} />}
    </>
  );
};

export default memo(OrderDetailTable);

OrderDetailTable.propTypes = {
  data: PropTypes.object.isRequired,
};
OrderDetailTable.defaultProps = {
  data: {},
};

const useStyles = makeStyles(theme => ({
  tableContainer: {
    border: `0.5px solid ${theme.palette.grey[200]}`,
    "&>table": {
      overflowX: "auto",
      minWidth: 800,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 20,
    },
  },
  addItemButton: {
    minWidth: 120,
    padding: 3,
    borderRadius: 2,
    textTransform: "capitalize",
  },
  addIcon: {
    width: 20,
    height: 20,
  },
  tableFooter: {
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 8,
    boxShadow: " 0 2px 8px 0 rgba(0, 0, 0, 0.1)",
  },
}));
