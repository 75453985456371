import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useMediaQuery, useTheme, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { Close, LocalMallOutlined } from "@material-ui/icons";
import { ListCategoryByMenu, ListItems, ListSelectedProduct, NotifyBadge, OptionView } from "components";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, getNSKey, removeVietnameseTones } from "utils";
import ComboItemAction from "redux/combo-item.redux";
import MnProductAction from "redux/mn-product.redux";
import ServiceItemAction from "redux/service-item.redux";
import MenuAction from "redux/menu.redux";
import ReservationAction from "redux/reservation.redux";
import ShippingInfoDialog from "./ShippingInfoDialog";

const DialogSelectItems = ({ selectedItems, isShowCategory, onClose, isUpdatedOrder }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1240));
  const dispatch = useDispatch();

  const listMenuData = useSelector(state => state.menuRedux.data);
  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem);
  const listProductItem = useSelector(state => state.mnProductRedux.dataItem);
  const listServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem);

  const [updatedOrderDetail, setUpdatedOrderDetail] = useState({});
  const [arrayData, setArrayData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedData, setSelectedData] = useState(selectedItems?.orderDetailsList || []);
  const [value, setValue] = useState(0);
  const [viewOption, setViewOption] = useState(0);
  const [isShowSelectedProduct, setIsShowSelectedProduct] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const [openShippingDialog, setOpenShippingDialog] = useState(false);

  const defaultCategory =
    isShowCategory && ARRAY_CATEGORY.map(mapData => ({ ...mapData, name: getLabel(getCommonKey(mapData.name)) }));

  const onSubmitData = () => {
    if (!isUpdatedOrder && Object.keys(selectedItems).length > 0 && selectedItems?.orderDetailsList.length > 0) {
      let newOrderData = { ...selectedItems };
      newOrderData.orderDetailsList = [...selectedData];
      dispatch(ReservationAction.putRemoteOrderDetails({ details: newOrderData, uid: newOrderData.uid }));
      onClose();
    } else {
      let updatedOrderDetail = { ...selectedItems, isUpdatedOrder: isUpdatedOrder };
      updatedOrderDetail.orderDetailsList = [...selectedData];
      setUpdatedOrderDetail(updatedOrderDetail);
      setOpenShippingDialog(true);
    }
  };
  const onChangeTabs = (_, newValue) => {
    setValue(newValue);
  };

  const onChangeViewOption = (_, newValue) => {
    setViewOption(newValue);
  };

  const onSearchItem = name => {
    let convertName = removeVietnameseTones(name.toLowerCase());
    let newListSearch = [];
    arrayData.forEach(categorySearch => {
      if (removeVietnameseTones(categorySearch?.name).toLowerCase().includes(convertName)) {
        newListSearch.push(categorySearch);
      } else {
        let newItemSearch = categorySearch.itemList.filter(itemSearch =>
          removeVietnameseTones(itemSearch?.name).toLowerCase().includes(convertName),
        );
        if (newItemSearch.length > 0) {
          newListSearch.push({
            ...categorySearch,
            itemList: newItemSearch,
          });
        }
      }
    });
    setSearchData(newListSearch);
  };

  const onChooseItem = (parentIndex, childIndex) => {
    // Function for select item without topping
    let arrayItems = [...selectedData];
    let newSelectedItem =
      searchData.length > 0
        ? searchData[parentIndex].itemList[childIndex]
        : arrayData[parentIndex].itemList[childIndex];
    let existedItemIndex = selectedData.findIndex(dataMap =>
      newSelectedItem.itemUid
        ? dataMap.itemUid === newSelectedItem.itemUid
        : dataMap.itemUid
        ? dataMap.itemUid === newSelectedItem.uid
        : dataMap.uid === newSelectedItem.uid,
    );
    let existedItem = arrayItems[existedItemIndex];

    // Increase +1 item if item existed
    if (existedItem) {
      arrayItems[existedItemIndex].orderNumber = existedItem.orderNumber + 1;
    } else {
      // Delete uid because it's uid of item table (for item identify). Sever just receive uid of order detail table
      if (newSelectedItem.itemUid) delete newSelectedItem[AppConstant.KEY_UID];
      // Add new item when not exist
      arrayItems.push(newSelectedItem);
    }

    setSelectedData(arrayItems);
  };

  const onRemoveItem = removedIndex => {
    let newSelectedData = [...selectedData];
    newSelectedData.splice(removedIndex, 1);
    setSelectedData(newSelectedData);
  };

  const onOpenListSelected = () => {
    setIsShowSelectedProduct(true);
  };

  const onComeBack = () => {
    setIsShowSelectedProduct(false);
  };

  const onChangeTotalItem = number => {
    setTotalItem(number);
  };

  useEffect(() => {
    if (isShowCategory && !(listComboItem && listProductItem && listServiceItem)) {
      dispatch(ComboItemAction.getListComboItem());
      dispatch(MnProductAction.getProductItem());
      dispatch(ServiceItemAction.getListServiceItem());
    } else if (listMenuData.length === 0) {
      dispatch(MenuAction.getMenu({ type: AppConstant.DATA_TYPE.order }));
    }
  }, [isShowCategory]);

  useEffect(() => {
    if (!isShowCategory && listMenuData) {
      let newArrayData = listMenuData.map(dataMap => ({
        ...dataMap,
        itemList: dataMap.itemList.map(dataItem => ({
          ...dataItem,
          orderNumber: 1,
          toppingList: [],
          toppingListIndex: [],
        })),
      }));
      setArrayData(newArrayData);
      setSearchData(newArrayData);
    }
  }, [listMenuData, isShowCategory]);

  useEffect(() => {
    if (isShowCategory && Boolean(listComboItem) && Boolean(listProductItem) && Boolean(listServiceItem)) {
      let newArrayData = [...defaultCategory];
      // Format Data Service Item
      let newFormListServiceItem = [...listServiceItem.data];
      newArrayData[0].itemList = formatFormDataPreOrder(newFormListServiceItem, AppConstant.TYPE_CATEGORY.service);

      // Format Data Product Item
      let newFormListProductItem = [...listProductItem.data];
      newArrayData[1].itemList = formatFormDataPreOrder(newFormListProductItem, AppConstant.TYPE_CATEGORY.product);

      // Format Data Combo Item
      let newFormListComboItem = [...listComboItem.data];
      newArrayData[2].itemList = formatFormDataPreOrder(newFormListComboItem, AppConstant.TYPE_CATEGORY.combo);

      setArrayData(newArrayData);
      setSearchData(newArrayData);
    }
  }, [listComboItem, listProductItem, listServiceItem, isShowCategory]);

  return (
    <>
      <Dialog open={true} fullScreen>
        <Box className={defaultClasses.root}>
          <Box className={defaultClasses.boxIconClose}>
            <IconButton onClick={onClose} className={defaultClasses.iconButtonClose}>
              <Close className={defaultClasses.iconClose} />
            </IconButton>
          </Box>
          <Box className={defaultClasses.boxHeader}>
            {!isDesktop && (
              <IconButton
                onClick={onOpenListSelected}
                classes={{ root: defaultClasses.buttonBadge, label: defaultClasses.buttonBadgeLabel }}
              >
                <NotifyBadge data={totalItem} badgeClass={defaultClasses.badgeClass}>
                  <LocalMallOutlined className={defaultClasses.iconSelectedItem} />
                </NotifyBadge>
              </IconButton>
            )}
            {Object.keys(selectedItems).length > 0 ? (
              isUpdatedOrder ? (
                <Box className={defaultClasses.boxTitle}>
                  <Typography className={defaultClasses.titleText}>
                    {getLabel(getNSKey(LangConstant.NS_MANAGE_ORDER, LangConstant.TXT_UPDATE_ORDER))}
                  </Typography>
                </Box>
              ) : (
                <Box className={defaultClasses.boxTitle}>
                  <Typography className={defaultClasses.titleText}>
                    {getLabel(
                      getNSKey(
                        LangConstant.NS_MANAGE_ORDER,
                        isShowCategory ? LangConstant.TXT_ADD_ITEM_FROM_CATEGORY : LangConstant.TXT_ADD_ITEM_FROM_MENU,
                      ),
                    )}
                  </Typography>
                </Box>
              )
            ) : (
              <Box className={defaultClasses.boxTitle}>
                <Typography className={defaultClasses.titleText}>
                  {getLabel(
                    getNSKey(
                      LangConstant.NS_MANAGE_ORDER,
                      isShowCategory ? LangConstant.TXT_NEW_ITEM_FROM_CATEGORY : LangConstant.TXT_NEW_ITEM_FROM_MENU,
                    ),
                  )}
                </Typography>
              </Box>
            )}
            {!isDesktop && <Box width="40px" height="40px" marginRight="16px" />}
          </Box>
          <Box className={defaultClasses.boxBody}>
            <Box className={defaultClasses.listCategoryBox}>
              <ListCategoryByMenu
                value={value}
                isShowCategory={isShowCategory}
                data={searchData}
                onChange={onChangeTabs}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="on"
              />
              <Box className={defaultClasses.bodyProduct}>
                <OptionView value={viewOption} onSearch={onSearchItem} onChangeView={onChangeViewOption} />
                <ListItems
                  data={searchData}
                  viewOption={viewOption}
                  onChooseItem={onChooseItem}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                />
              </Box>
            </Box>
            <ListSelectedProduct
              isShow={isShowSelectedProduct}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              onChangeTotalItem={onChangeTotalItem}
              onRemoveItem={onRemoveItem}
              onSubmitFormData={onSubmitData}
              onCloseAll={onClose}
              onComeBack={onComeBack}
              continueOrder={
                !isUpdatedOrder && Object.keys(selectedItems).length > 0 && selectedItems?.orderDetailsList.length > 0
                  ? getLabel(getCommonKey(LangConstant.TXT_SAVE))
                  : getLabel(getNSKey(LangConstant.NS_MANAGE_ORDER, LangConstant.TXT_ORDER_CONTINUE))
              }
            />
          </Box>
        </Box>
      </Dialog>
      {openShippingDialog && (
        <ShippingInfoDialog openDialog={openShippingDialog} orderItemsData={updatedOrderDetail} onClose={onClose} />
      )}
    </>
  );
};

export const ARRAY_CATEGORY = [
  {
    name: LangConstant.TXT_SERVICE,
    itemList: [],
  },
  {
    name: LangConstant.TXT_PRODUCT,
    itemList: [],
  },
  {
    name: LangConstant.TXT_COMBO,
    itemList: [],
  },
];

export const formatFormDataPreOrder = (data, type) => {
  if (data) {
    let newFormDataPreOrder = [];
    if (type === AppConstant.TYPE_CATEGORY.combo) {
      data.forEach(dataMap => {
        newFormDataPreOrder.push({
          ...dataMap,
          itemDetailsContentList: dataMap.itemDetails?.itemDetailsContentList || [],
          type: AppConstant.TYPE_CATEGORY.combo,
          orderNumber: 1,
          toppingList: [],
          toppingListIndex: [],
        });
      });
    } else if (type === AppConstant.TYPE_CATEGORY.service || type === AppConstant.TYPE_CATEGORY.product) {
      data.forEach(dataMap => {
        let saveContent = [];
        let listDetailsData = dataMap.itemDetailsList;
        if (listDetailsData && Array.isArray(listDetailsData)) {
          listDetailsData.forEach(dataDetails => {
            let listContentData = dataDetails.itemDetailsContentList;
            if (listContentData && Array.isArray(listContentData) && listContentData.length > 0) {
              saveContent = [...saveContent, ...listContentData];
            }
          });
        }
        let newContentList = [];
        saveContent.forEach(objectData => {
          if (!newContentList.find(dataFind => dataFind.uid === objectData.uid)) {
            newContentList.push(objectData);
          }
        });
        newFormDataPreOrder.push({
          ...dataMap,
          itemDetailsContentList: newContentList,
          type:
            type === AppConstant.TYPE_CATEGORY.service
              ? AppConstant.TYPE_CATEGORY.service
              : AppConstant.TYPE_CATEGORY.product,
          orderNumber: 1,
          toppingList: [],
          toppingListIndex: [],
        });
      });
    }
    return newFormDataPreOrder;
  }
  return [];
};

DialogSelectItems.propTypes = {
  isShowCategory: PropTypes.bool,
  isUpdatedOrder: PropTypes.bool,
  selectedItems: PropTypes.object,
  data: PropTypes.array,
  onClose: PropTypes.func,
};
DialogSelectItems.defaultProps = {
  isShowCategory: false,
  isUpdatedOrder: false,
  selectedData: {},
  data: [],
  onClose: () => {},
};

export default memo(DialogSelectItems);

const useStyles = makeStyles(theme => ({
  root: {
    padding: "28px 36px 0",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0 0",
    },
  },
  boxIconClose: {
    height: 36,
    margin: "8px 0",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      margin: "0 16px",
    },
  },
  iconButtonClose: {
    width: 36,
    height: 36,
    padding: 0,
  },
  iconClose: {
    width: "inherit",
    height: "inherit",
  },
  boxHeader: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 54,
    [theme.breakpoints.down(1240)]: {
      margin: "24px 24px 32px",
      height: 40,
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0 32px",
      height: 40,
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  buttonBadge: {
    height: 40,
    width: 40,
    padding: 0,
    marginLeft: 16,
  },
  buttonBadgeLabel: {
    height: 40,
    alignItems: "flex-end",
  },
  badgeClass: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    top: 2,
    left: 6,
  },
  iconSelectedItem: {
    height: 32,
  },
  headerButton: {
    padding: 0,
  },
  boxTitle: {
    textAlign: "center",
    height: 32,
  },
  titleText: {
    fontSize: 24,
    height: "inherit",
    color: theme.palette.black,
    lineHeight: 1.33,
  },
  boxBody: {
    width: "100%",
    height: "calc(100% - 138px)",
    padding: "0 40px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  listCategoryBox: {
    width: "66.6%",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  bodyProduct: {
    height: "calc(100vh - 258px)",
    minHeight: 700,
    padding: "24px 0",
    marginBottom: 36,
    border: "0.5px solid " + theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      padding: "24px 12px",
    },
  },
}));
