import React, { memo, useState, useEffect } from "react";
import { Box, Button, Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import { createArrayWithLabel, getCommonKey } from "utils";
import { Alert, Input, TextArea } from "components";
import {
  CategoryMenu,
  CategorySubcategoryMenu,
  DropdownMenu,
  InputPrice,
  InputPriceChecked,
  InputUsedTime,
  UploadImage,
  UploadVideo,
  Warning,
} from "components/mn-master-product";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ServiceItemAction from "redux/service-item.redux";

const DialogEditAndWatchService = ({ uid, isWatch, isEdit, onClose }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const classes = useStyles();
  const dispatch = useDispatch();

  const getServiceItemSuccess = useSelector(state => state.serviceItemRedux.isGetServiceItemSuccess, shallowEqual);

  const dataGetServiceItem = useSelector(state => state.serviceItemRedux.dataGetServiceItem, shallowEqual);
  const dataStaff = useSelector(state => state.staffRedux.data, shallowEqual);
  const dataPlace = useSelector(state => state.placeCategoryRedux.data, shallowEqual);
  const dataServiceCategory = useSelector(state => state.serviceCategoryRedux.data, shallowEqual);

  const [staffList, setStaffList] = useState({
    listData: [],
    selectedData: [],
  });
  const [placeList, setPlaceList] = useState({
    listData: [],
    selectedData: [],
  });
  const [serviceCategoryList, setServiceCategoryList] = useState({
    listData: [],
    selectedData: [],
  });
  const [formInput, setFormInput] = useState();
  const [taxTypeValue, setTaxTypeValue] = useState(AppConstant.TAX_TYPE_VALUE[AppConstant.TYPE_TAX.noTax]);
  const [notify, setNotify] = useState({
    value: true,
    message: getLabel(LangConstant.TXT_WARNING_EDIT_SERVICE),
    type: TYPES_NOTIFICATION.notEdit,
  });

  const onTypingName = value => {
    setFormInput({
      ...formInput,
      name: value,
    });
  };

  const onUploadFile = (value, error) => {
    if (!error) {
      setFormInput({
        ...formInput,
        imageList: value,
      });
    } else {
      setNotify({
        value: true,
        message: error,
        type: TYPES_NOTIFICATION.warningImage,
      });
    }
  };

  const onUploadVideo = (value, isError) => {
    let newFormValue = {
      ...formInput,
    };
    if (isError) delete newFormValue.video;
    else newFormValue.video = value;

    setFormInput(newFormValue);
  };

  const onTypingDescription = event => {
    let descriptionInput = event.target.value;
    setFormInput({
      ...formInput,
      description: descriptionInput,
    });
  };

  const onChangeTime = value => {
    setFormInput({
      ...formInput,
      usingTime: value,
    });
  };

  const onClickTimeUsed = value => {
    setFormInput({
      ...formInput,
      usingTimeType: value,
    });
  };

  const onInputPrice = value => {
    const newPrice = value;
    const newPriceTaxIn = Math.round(newPrice * (1 + taxTypeValue));
    const tax = newPriceTaxIn - newPrice;
    setFormInput({
      ...formInput,
      price: newPrice,
      priceTaxIn: newPriceTaxIn,
      tax: tax,
    });
  };

  const onChangeDropdownTax = taxTypeId => {
    const newTaxTypeValue = AppConstant.TAX_TYPE_VALUE[taxTypeId];
    setTaxTypeValue(newTaxTypeValue);

    const newPriceTaxIn = Math.round(formInput.price * (1 + newTaxTypeValue));
    const tax = newPriceTaxIn - formInput.price;
    setFormInput({
      ...formInput,
      tax: tax,
      priceTaxIn: newPriceTaxIn,
      details: { ...formInput.details, defaultId: taxTypeId },
    });
  };

  const onClickPriceTax = value => {
    setFormInput({
      ...formInput,
      taxInclusiveF: parseInt(value),
    });
  };

  const onSubmitFormCombo = () => {
    if (!formInput.name) {
      setNotify({
        value: true,
        message: getLabel(LangConstant.TXT_NOTIFY_INPUT_NAME_COMBO),
        type: TYPES_NOTIFICATION.incompleteAttribute,
      });
    }
  };

  const onCloseNotify = () => {
    setNotify({
      value: false,
      message: "",
      type: TYPES_NOTIFICATION.notWarning,
    });
  };

  useEffect(() => {
    dispatch(ServiceItemAction.getServiceItem({ uid: uid }));
  }, []);

  useEffect(() => {
    if (dataGetServiceItem && getServiceItemSuccess) {
      dispatch(ServiceItemAction.resetServiceItem());
      setFormInput(dataGetServiceItem);
      setTaxTypeValue(
        dataGetServiceItem?.details?.defaultId
          ? AppConstant.TAX_TYPE_VALUE[dataGetServiceItem?.details?.defaultId]
          : AppConstant.TAX_TYPE_VALUE[AppConstant.TYPE_TAX.noTax],
      );
      let selectedArrStaff = [];
      dataGetServiceItem.staffList.forEach(data => selectedArrStaff.push(data.uid));
      setStaffList({ ...staffList, selectedData: selectedArrStaff });
      let selectedArrPlace = [];
      dataGetServiceItem.placeList.forEach(data => selectedArrPlace.push(data.uid));
      setPlaceList({ ...placeList, selectedData: selectedArrPlace });
      let selectedArrCategory = [];
      dataGetServiceItem.categoryList.forEach(data => selectedArrCategory.push(data.uid));
      setServiceCategoryList({ ...serviceCategoryList, selectedData: selectedArrCategory });
    }
  }, [getServiceItemSuccess]);

  useEffect(() => {
    if (dataServiceCategory) {
      let arrayMap = Object.values(dataServiceCategory).map(mapData => mapData);
      setServiceCategoryList({ ...serviceCategoryList, listData: arrayMap });
    }
  }, [dataServiceCategory]);

  useEffect(() => {
    if (dataStaff?.data) {
      let arrayMap = Object.values(dataStaff.data).map(mapData => mapData);
      setStaffList({ ...staffList, listData: arrayMap });
    }
  }, [dataStaff]);

  useEffect(() => {
    if (dataPlace) {
      let arrayMap = Object.values(dataPlace).map(mapData => ({
        ...mapData,
        items: Object.values(mapData.items).map(dataChild => dataChild),
      }));
      setPlaceList({ ...placeList, listData: arrayMap });
    }
  }, [dataPlace]);

  return (
    <Dialog open={isWatch || isEdit} fullScreen>
      {formInput && (
        <>
          <Box className={classes.rootHeader}>
            <Typography className={classes.nameHeaderTypo}>
              {isWatch ? getLabel(LangConstant.TXT_DETAIL_SERVICE) : getLabel(LangConstant.TXT_EDIT_SERVICE)}
            </Typography>
            <Box className={classes.boxIconClose}>
              <IconButton onClick={onClose} className={classes.iconButton}>
                <Close className={classes.icon} />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.infoBox}>
            <Box marginBottom="30px">
              <Box padding="8px 0 16px">
                <Typography className={classes.infoBasicTypo}>
                  {getLabel(LangConstant.TXT_INFORMATION_BASIC)}
                </Typography>
                <Typography className={classes.noticeFillTypo}>
                  {getLabel(LangConstant.FN_NOTICE_FILL)(getLabel(LangConstant.TXT_SERVICE_LOWER_CASE))}
                </Typography>
              </Box>
              <Box padding="8px 0 16px">
                <Box className={classes.infoBasic}>
                  <Typography className={classes.nameComboTypo}>{getLabel(LangConstant.TXT_NAME_SERVICE)}</Typography>
                  <Typography color="primary" className={classes.isRequire}>
                    &nbsp;&#42;
                  </Typography>
                </Box>
                <Input
                  placeholder={getLabel(LangConstant.TXT_NAME_SERVICE)}
                  classes={{ root: classes.rootNameCombo, input: classes.inputNameCombo }}
                  required
                  value={formInput?.name ? formInput.name : ""}
                  disabled={isWatch}
                  onChange={onTypingName}
                />
                {notify.value && notify.type == TYPES_NOTIFICATION.incompleteAttribute && (
                  <Alert isShow={notify.value} onClose={onCloseNotify} type="error" message={notify.message} />
                )}
              </Box>
              <Box padding="8px 0 16px">
                <Typography className={classes.inputTitleTypo}>{`${getLabel(
                  LangConstant.TXT_ADD_CATEGORY_GOOD,
                )} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`}</Typography>
                <CategoryMenu
                  data={serviceCategoryList?.listData && serviceCategoryList.listData}
                  selectedArr={formInput?.categoryList ? serviceCategoryList.selectedData : []}
                  label={getLabel(LangConstant.L_CHOOSE_SERVICE_CATEGORY)}
                  isDisable={isWatch}
                />
              </Box>
              <Box padding="8px 0 16px">
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_ILLUSTRATION)}</Typography>
                <Box marginTop="8px">
                  <UploadImage
                    onChange={onUploadFile}
                    isDisabled={isWatch}
                    imageList={
                      formInput.imageList ? formInput.imageList : Boolean(formInput?.image) ? [formInput.image] : []
                    }
                  />
                  {isEdit && notify.value && notify.type == TYPES_NOTIFICATION.warningImage && (
                    <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
                  )}
                </Box>
              </Box>
              <Box padding="8px 0 16px">
                <Typography className={classes.inputTitleTypo}>
                  {getLabel(LangConstant.TXT_ILLUSTRATION_VIDEO)}
                </Typography>
                <Box marginTop="8px">
                  <UploadVideo defaultUrl={formInput?.video || ""} onChange={onUploadVideo} />
                </Box>
              </Box>
              <Box padding="8px 0 16px">
                <Typography className={classes.inputTitleTypo}>{`${getLabel(
                  getCommonKey(LangConstant.L_SERVICE_CATEGORY_DIALOG_DESCRIPTION),
                )} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`}</Typography>
                <TextArea
                  placeholder={`${getLabel(LangConstant.TXT_ADD_DESCRIPTION)} ${getLabel(
                    LangConstant.TXT_SERVICE_LOWER_CASE,
                  )}`}
                  className={classes.comboDesTextArea}
                  rowMax={4}
                  value={formInput?.description ? formInput.description : ""}
                  onChange={onTypingDescription}
                  disabled={isWatch}
                />
              </Box>
            </Box>
            <Box>
              <Box padding="8px 0 16px">
                <Typography className={classes.infoBasicTypo}>
                  {getLabel(LangConstant.TXT_UNIT_PRICE_AND_TIME_USED)}
                </Typography>
                <Typography className={classes.noticeFillTypo}>
                  {getLabel(LangConstant.TXT_SETTING_UNIT_PRICE_AND_TIME_USED)}
                </Typography>
              </Box>
              <Box padding="8px 0 46px" display="flex">
                <Box minWidth="117px">
                  <Typography className={classes.inputTitleTypo}>
                    {getLabel(LangConstant.TXT_PRICE_ACCORDING_TO)}
                  </Typography>
                  <DropdownMenu
                    valueTax={formInput?.unitType ? formInput.unitType.toString() : ""}
                    onChange={onInputPrice}
                    dataChange={createArrayWithLabel(
                      AppConstant.TYPE_UNIT_PRICE_CALCULATE,
                      LangConstant.ARR_TYPE_UNIT_PRICE_CALCULATE,
                      getLabel,
                      true,
                    )}
                    isDisabled={isWatch}
                    dropdownClass={classes.dropdownClass}
                    placeholder={getLabel(getCommonKey(LangConstant.P_UNIT))}
                  />
                </Box>
                <Box minWidth="158px" margin="0 24px">
                  <Typography className={classes.inputTitleTypo}>
                    {getLabel(getCommonKey(LangConstant.TXT_BOOK_USED_TIME))}
                  </Typography>
                  <InputUsedTime
                    valueTime={formInput?.usingTime ? formInput.usingTime : ""}
                    valueTimeType={formInput?.usingTimeType ? formInput.usingTimeType : ""}
                    dataChange={createArrayWithLabel(
                      AppConstant.TYPE_TIME_USED,
                      LangConstant.ARR_TYPE_TIME_USED,
                      getLabel,
                      true,
                    )}
                    onChange={onChangeTime}
                    onClick={onClickTimeUsed}
                    isDisabled={isWatch}
                    className={classes.inputUsedTime}
                    placeholderTime={getLabel(getCommonKey(LangConstant.TXT_MINUTES))}
                    placeholderValue="30"
                  />
                </Box>
                <Box minWidth="159px">
                  <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_UNIT_PRICE)}</Typography>
                  <InputPrice
                    valueInput={formInput?.price ? formInput.price.toString() : ""}
                    onChange={onInputPrice}
                    checkDisable={isWatch}
                    currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box padding="8px 0 16px">
                <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_TAX_AND_PRICE)}</Typography>
                <Typography className={classes.noticeFillTypo}>
                  {getLabel(LangConstant.TXT_SETTING_TAX_AND_PRICE_COMBO)}
                </Typography>
              </Box>
              <Box height="100px" display="flex">
                <Box marginRight="24px" minWidth="151px">
                  <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_TAX)}</Typography>
                  <DropdownMenu
                    valueTax={formInput?.details?.defaultId}
                    onChange={onChangeDropdownTax}
                    dataChange={createArrayWithLabel(AppConstant.TYPE_TAX, LangConstant.ARR_TYPE_TAX, getLabel)}
                    isDisabled={isWatch}
                    dropdownClass={classes.dropdownClass}
                    placeholder={getLabel(LangConstant.TXT_CHOOSE_TAX)}
                  />
                </Box>
                <Box minWidth="326px">
                  <Typography className={classes.inputTitleTypo}>
                    {getLabel(getCommonKey(LangConstant.TXT_PRICE))}
                  </Typography>
                  <InputPriceChecked
                    taxInclusiveF={formInput?.taxInclusiveF ? formInput.taxInclusiveF : null}
                    taxPrice={{
                      taxBefore: formInput?.price ? formInput.price.toString() : "",
                      taxAfter: formInput?.priceTaxIn ? formInput.priceTaxIn.toString() : "",
                    }}
                    isDisabled={isWatch}
                    currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                    onClick={onClickPriceTax}
                  />
                </Box>
              </Box>
            </Box>

            <Box padding="8px 0 0">
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.L_POWER_SOURCE)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.TXT_SETTING_POWER_DESCRIPTION)}
              </Typography>
              <Box padding="24px 0 46px">
                <Box marginRight="24px" minWidth="430px">
                  <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.L_LOCATION)}</Typography>
                  <CategorySubcategoryMenu
                    data={placeList?.listData && placeList.listData}
                    selectedArr={formInput?.placeList ? placeList.selectedData : []}
                    label={getLabel(LangConstant.L_CHOOSE_LOCATION)}
                    itemsKey="items"
                    isDisable={isWatch}
                  />
                </Box>
                <Box padding="24px 0 0" minWidth="430px">
                  <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.L_LABOR)}</Typography>
                  <CategoryMenu
                    data={staffList?.listData && staffList.listData}
                    selectedArr={formInput?.staffList ? staffList.selectedData : []}
                    label={getLabel(LangConstant.L_CHOOSE_LABOR)}
                    isDisable={isWatch}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {isEdit && (
        <Box className={classes.boxButtonSave}>
          <Button className={classes.buttonSave} onClick={onSubmitFormCombo}>
            {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
          </Button>
        </Box>
      )}

      {isEdit && notify.value && notify.type == TYPES_NOTIFICATION.notEdit && (
        <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
      )}
    </Dialog>
  );
};

const TYPES_NOTIFICATION = {
  notWarning: 0,
  warningImage: 1,
  incompleteAttribute: 2,
  notEdit: 3,
};

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  infoBox: {
    width: 430,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  infoBasic: {
    display: "flex",
    alignItems: "center",
    height: 22,
    marginBottom: 8,
  },
  infoBasicTypo: {
    fontSize: 18,
    fontWeight: 600,
    height: 28,
    color: "#3e4045",
    lineHeight: 1.56,
  },
  nameComboTypo: {
    height: 22,
    lineHeight: 1.57,
    fontSize: 13,
    fontWeight: 600,
  },
  isRequire: {
    height: 22,
  },
  noticeFillTypo: {
    fontSize: 14,
    minHeight: 22,
    lineHeight: 1.57,
    marginTop: 8,
    color: "#3e4045",
  },
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  rootNameCombo: {
    margin: 0,
    borderRadius: 3,
    "&.Mui-disabled": {
      color: "#3e4045",
    },
  },
  inputNameCombo: {
    height: 22,
    padding: "9px 16px",
    fontSize: 14,
    lineHeight: 1.57,
  },
  comboDesTextArea: {
    height: 80,
    margin: 0,
    padding: "8px 16px",
    borderRadius: 3,
  },
  inputUsedTime: {
    width: 158,
  },
  dropdownClass: {
    width: "100%",
  },
  boxButtonSave: {
    display: "flex",
    justifyContent: "center",
    margin: "50px 0 0",
    paddingBottom: "50px",
  },
  buttonSave: {
    height: 46,
    padding: 0,
    minWidth: 102,
    fontSize: 16,
    borderRadius: 3,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
}));

export default memo(DialogEditAndWatchService);
