import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import ServiceItemAction from "redux/service-item.redux";
import PaymentAction from "redux/payment.redux";
import { ServiceItemService } from "services";
import { deepCloneJsonObject, toCamel, toSnake } from "utils";

export function* postServiceItem(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceItemService.postServiceItem, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ServiceItemAction.postServiceItemSuccess(toCamel(responseData)));
    } else if (response.status === ApiConstant.STT_UN_PROCESSABLE_ENTITY) {
      yield put(PaymentAction.getUnProcessableEntity());
    } else {
      yield put(ServiceItemAction.postServiceItemFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceItemAction.postServiceItemFailure(error));
  }
}

export function* putServiceItem(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceItemService.putServiceItem, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ServiceItemAction.putServiceItemSuccess(toCamel(responseData)));
    } else {
      yield put(ServiceItemAction.putServiceItemFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceItemAction.putServiceItemFailure(error));
  }
}

export function* getListServiceItem(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceItemService.getListServiceItem, data);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ServiceItemAction.getListServiceItemSuccess(formatGroupModelService(toCamel(responseData))));
    } else {
      yield put(ServiceItemAction.getListServiceItemFailure(responseData));
    }
  } catch (error) {
    yield put(ServiceItemAction.getListServiceItemFailure(error));
  }
}

export function* getServiceItemByUid(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceItemService.getServiceItemByUid, data);
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ServiceItemAction.getServiceItemSuccess(toCamel(responseData)));
    } else {
      yield put(ServiceItemAction.getServiceItemFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceItemAction.getServiceItemFailure(error));
  }
}

export function* getServiceItemByCategoryUid(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceItemService.getServiceItemByCategoryUid, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ServiceItemAction.getCategoryServiceItemSuccess(toCamel(responseData)));
    } else {
      yield put(ServiceItemAction.getCategoryServiceItemFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceItemAction.getCategoryServiceItemFailure(error));
  }
}

export function* deleteServiceItem(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceItemService.deleteServiceItem, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ServiceItemAction.deleteServiceItemSuccess(responseData));
    } else {
      yield put(ServiceItemAction.deleteServiceItemFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceItemAction.deleteServiceItemFailure(error));
  }
}

const formatGroupModelService = data => {
  // TODO optimal logic
  let newData = deepCloneJsonObject(data);
  newData.data.forEach(dataService => {
    if (Boolean(dataService.groupModel)) {
      if (Boolean(dataService.groupModel.modelList[0].item)) {
        dataService.classificationList = dataService.groupModel.modelList;
      } else {
        let arrData = [];
        dataService.groupModel.modelList.forEach(dataModel => {
          dataModel.groupModel.modelList.forEach(dataItem => {
            arrData.push(dataItem);
          });
        });
        dataService.classificationList = arrData;
      }
    }
    return dataService;
  });

  return newData;
};
