import React, { memo } from "react";
import { Box, Checkbox, FormControlLabel, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { onDoNothing } from "utils";

const ShopDetailHeader = ({ shopName }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const classes = useStyles();

  let primaryContent = (
    <FormControlLabel
      control={
        <Checkbox checked={true} onChange={onDoNothing} name="isConsumer" color="primary" className={classes.checkBox} />
      }
      label={<Typography className="semiBold-md-txt">{getLabel(LangConstant.TXT_MY_PLACES)}</Typography>}
    />
  );

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.item}>
          <Typography variant="h3" component="h1" color="primary" className={classes.shopName}>
            {shopName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.item} container justify="flex-end">
          <Box className={classes.actionContent}>{primaryContent}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

ShopDetailHeader.propTypes = {};

export default memo(ShopDetailHeader);

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 14,
    paddingBottom: 8,
    paddingLeft: 0,
    paddingRight: 0,
  },
  item: {
    display: "flex",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  shopName: {},
  actionContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "unset",
      paddingLeft: 8,
    },
    "&>p": {
      flexGrow: 1,
      paddingLeft: 8,
    },
  },

  button: {
    minWidth: 107,
    textTransform: "unset",
    padding: 0,
  },
  checkBox: {
    padding: 5,
    "& svg": {
      width: 32,
      height: 32,
    },
  },
}));
