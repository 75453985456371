import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { format as StringFormat } from "react-string-format";
import {
  Card as MuiCard,
  CardContent,
  CardActions,
  CardHeader,
  withStyles,
  Button,
  IconButton,
  useTheme,
  Box,
  makeStyles,
  Typography,
  Dialog,
  InputLabel,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from "@material-ui/core";
import { Close, EditOutlined } from "@material-ui/icons";
import { format, set } from "date-fns";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import CustomStepper from "./CustomStepper";
import StatusGroup from "./StatusGroup";
import { convertTimeStampToDate, getRealUseTimeByHour, getTimestamp, removeSecond } from "utils/date";
import CropDialogTitle from "components/CropImage/CropImageDialogTitle";
import { CustomDatePicker, MinuteHourTimePicker } from "components";
import DialogContainer from "../DialogContainer";
import ReservationAction from "redux/reservation.redux";
import ConfirmDialog from "components/dialog/ConfirmDialog";
import { getCommonKey } from "utils";

const StatusDialog = ({ anchorEl, status, onClose, detail, selectedId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const dispatch = useDispatch();

  const classes = useStyles({ isMobile });
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const [dialogAnchorEl, setDialogAnchorEl] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(status);
  const [realStartTime, setRealStartTime] = useState(
    convertTimeStampToDate(detail.realityStartTime || getTimestamp(removeSecond(new Date())) || detail.startTime),
  );
  const [realEndTime, setRealEndTime] = useState(
    detail.realityEndTime ? convertTimeStampToDate(detail.realityEndTime) : new Date(),
  );

  const [isOpenRealStartTimeDialog, setIsOpenRealStartTimeDialog] = useState(false);
  const [isOpenRealEndTimeDialog, setIsOpenRealEndTimeDialog] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [nameDialog, setNameDialog] = useState("");
  const [nameDialogConfirm, setNameDialogConfirm] = useState("");
  const [confirmState, setConfirmState] = useState({
    state: AppConstant.STT_RESERVATION.cancel,
    action: "status",
    detail: null,
  });

  const isDone =
    (status === AppConstant.STT_RESERVATION.finished || selectedStatus === AppConstant.STT_RESERVATION.finished) &&
    (AppConstant.STT_RESERVATION.cancel > selectedStatus || AppConstant.STT_RESERVATION.cancel > status);

  const onConfirmCancelBooking = (event, isAgree) => {
    setIsConfirm(false);
    if (isAgree) {
      if (confirmState.action === "status") {
        dispatch(ReservationAction.putReservationStatus({ status: confirmState.state, uid: detail.uid }));
      } else {
        dispatch(ReservationAction.putReservationDetails({ details: confirmState.detail, uid: detail.uid }));
      }
      onClose();
    }
  };

  const onSubmitStatusChange = () => {
    if (
      selectedStatus === AppConstant.STT_RESERVATION.reject ||
      selectedStatus === AppConstant.STT_RESERVATION.cancel
    ) {
      setIsConfirm(true);
    } else {
      dispatch(
        ReservationAction.putReservationDetails({
          details: {
            ...detail,
            realityStartTime: getTimestamp(realStartTime),
            realityEndTime:
              selectedStatus === AppConstant.STT_RESERVATION.finished
                ? detail.realityEndTime || getTimestamp(realEndTime)
                : null,
            status: selectedStatus,
          },

          uid: detail.uid,
        }),
      );
      onClose();
    }
  };

  useEffect(() => {
    if (selectedStatus === AppConstant.STT_RESERVATION.cancel) {
      setNameDialog(StringFormat(getLabel(LangConstant.TXT_DECLINE_RESERVATION), detail.code, detail.name));
      setNameDialogConfirm(getLabel(LangConstant.TXT_CONFIRM_DECLINE_RESERVATION));
      setConfirmState({ ...confirmState, state: selectedStatus, action: "status" });
    } else if (selectedStatus === AppConstant.STT_RESERVATION.reject) {
      setNameDialog(StringFormat(getLabel(LangConstant.TXT_REJECT_RESERVATION), detail.code, detail.name));
      setNameDialogConfirm(getLabel(LangConstant.TXT_CONFIRM_REJECT_RESERVATION));
      setConfirmState({ ...confirmState, state: selectedStatus, action: "status" });
    }
  }, [isConfirm, selectedStatus]);

  useEffect(() => {
    if (anchorEl) {
      setDialogAnchorEl(anchorEl);
    }
  }, [anchorEl]);

  let isContainPreOrder = detail?.orderDetailsList.length > 0;

  return (
    <DialogContainer anchorEl={dialogAnchorEl} selectedId={selectedId}>
      <Card>
        <Header
          title={getLabel(getCommonKey(LangConstant.TXT_CHANGE_STT))}
          action={
            <IconButton aria-label="close" onClick={onClose} color="inherit">
              <Close color="inherit" />
            </IconButton>
          }
        />
        <ConfirmDialog
          onSelectConfirm={onConfirmCancelBooking}
          title={nameDialog}
          titleConfirm={nameDialogConfirm}
          isShow={isConfirm}
        />
        <Content>
          <CustomStepper
            isHasPreOrder={isContainPreOrder}
            status={status}
            selectedStatus={selectedStatus}
            onChange={status => {
              setSelectedStatus(status);
            }}
            classes={classes}
            realStartTime={realStartTime}
            realEndTime={realEndTime}
            setIsOpenRealStartTimeDialog={setIsOpenRealStartTimeDialog}
            setIsOpenRealEndTimeDialog={setIsOpenRealEndTimeDialog}
          />
          {!isMobile &&
            AppConstant.STT_RESERVATION.cancel > (selectedStatus || status) &&
            (selectedStatus || status) > AppConstant.STT_RESERVATION.confirm && (
              <Box className={isContainPreOrder ? classes.startEndTimeInputWithPreOrder : classes.startEndTimeInput}>
                <Box className={classes.startEndTimeBoxRoot}></Box>

                <Box className={classes.startEndTimeConnectorTransparent}></Box>
                <Box
                  className={clsx(classes.startEndTimeBoxRoot, classes.startTimeBox)}
                  onClick={() => setIsOpenRealStartTimeDialog(true)}
                >
                  <Typography className={classes.realTimeHourFormat}>
                    {format(realStartTime, AppConstant.FM_HH_MM)}
                  </Typography>
                  <Typography className={classes.realTimeDateFormat}>
                    {format(realStartTime, AppConstant.FM_DD_MM_YYYY)}
                  </Typography>
                  <EditOutlined className={classes.editIcon} />
                </Box>

                <Box className={classes.endTimeConnector}>
                  <Box
                    className={
                      isDone ? classes.endTimeConnectorLineHorizontal : classes.startEndTimeConnectorTransparent
                    }
                  ></Box>
                  {isDone && (
                    <Typography className={classes.realUseTimeText}>
                      {getRealUseTimeByHour(realStartTime, realEndTime, getLabel)}
                    </Typography>
                  )}
                  <Box
                    className={
                      isDone ? classes.endTimeConnectorLineHorizontal : classes.startEndTimeConnectorTransparent
                    }
                  ></Box>
                </Box>

                <Box
                  className={clsx(classes.startEndTimeBoxRoot, isDone && classes.endTimeBox)}
                  onClick={() => {
                    isDone && setIsOpenRealEndTimeDialog(true);
                  }}
                >
                  <Typography className={classes.realTimeHourFormat}>
                    {isDone && format(realEndTime, AppConstant.FM_HH_MM)}
                  </Typography>
                  <Typography className={classes.realTimeDateFormat}>
                    {isDone && format(realEndTime, AppConstant.FM_DD_MM_YYYY)}
                  </Typography>
                  {isDone && <EditOutlined className={classes.editIcon} />}
                </Box>
              </Box>
            )}

          <Box className={classes.sttGroup}>
            <StatusGroup
              color={theme.palette.grey[400]}
              status={{
                text: getLabel(getCommonKey(LangConstant.TXT_CANCEL)),
                value: AppConstant.STT_RESERVATION.cancel,
              }}
              isActive={selectedStatus === AppConstant.STT_RESERVATION.cancel}
              onClick={status => setSelectedStatus(status)}
            />
            <StatusGroup
              color={theme.palette.grey[400]}
              status={{
                text: getLabel(getCommonKey(LangConstant.TXT_REJECT)),
                value: AppConstant.STT_RESERVATION.reject,
              }}
              isActive={selectedStatus === AppConstant.STT_RESERVATION.reject}
              onClick={status => setSelectedStatus(status)}
            />
          </Box>
        </Content>
        <Actions>
          <Button
            classes={{ label: classes.saveButton }}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              onSubmitStatusChange(selectedStatus);
            }}
          >
            {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
          </Button>
        </Actions>
      </Card>
      <RealTimeDialog
        isOpen={isOpenRealStartTimeDialog}
        classes={classes}
        onClose={() => {
          setIsOpenRealStartTimeDialog(false);
        }}
        onChange={time => {
          setRealStartTime(time);
        }}
        initialTime={realStartTime}
        getLabel={getLabel}
        title={getLabel(getCommonKey(LangConstant.TXT_REALITY_START_TIME))}
      />
      <RealTimeDialog
        isOpen={isOpenRealEndTimeDialog}
        classes={classes}
        onClose={() => {
          setIsOpenRealEndTimeDialog(false);
        }}
        onChange={time => {
          setRealEndTime(time);
        }}
        isEndTimeDialog={true}
        initialTime={realEndTime}
        getLabel={getLabel}
        title={getLabel(getCommonKey(LangConstant.TXT_REALITY_END_TIME))}
      />
    </DialogContainer>
  );
};

export default memo(StatusDialog);

const RealTimeDialog = ({ initialTime, onChange, isOpen, classes, getLabel, title, onClose, isEndTimeDialog }) => {
  let timeAndHour = { hour: initialTime.getHours(), min: initialTime.getMinutes() };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onChangeStartHour = (hour, min) => {
    let dateOpt = createDateOpt(hour, min);
    let newDate = set(initialTime, dateOpt);
    onChange(newDate);
  };

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.inputTimeDialog, paperFullScreen: classes.inputTimeDialogMobile }}
      onClose={onClose}
      fullScreen={isMobile}
    >
      <CropDialogTitle onClose={onClose}>
        <Typography className={`${classes.inputTimeTitle} medium-xl-txt`}>{title}</Typography>
      </CropDialogTitle>
      <DialogContent classes={{ root: classes.inputTimeDialogContent }}>
        <Box className={classes.addFormInputTime}>
          <InputLabel classes={{ root: classes.inputLabel }}>
            {getLabel(getCommonKey(LangConstant.L_BOOKING_TIME))}
          </InputLabel>
          <MinuteHourTimePicker
            onChange={onChangeStartHour}
            classes={{ rootInputBox: classes.timePicker }}
            hourValue={timeAndHour.hour}
            minuteValue={timeAndHour.min}
            required
          />
        </Box>

        <Box className={classes.addFormInput}>
          <InputLabel classes={{ root: classes.inputLabel }}>
            {getLabel(getCommonKey(LangConstant.L_BOOKING_DATE))}
          </InputLabel>
          <CustomDatePicker
            disablePast={isEndTimeDialog}
            overrideInputClass={classes.datepickerInput}
            inputBox={classes.inputBox}
            onChange={time => {
              onChange(time);
            }}
            displayDate={format(initialTime, AppConstant.FM_DD_MM_YYYY)}
            selectedDateProp={initialTime}
            isUseEffect={true}
            includeIcon={false}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box className={classes.rootFooter}>
          <Button
            onClick={onClose}
            classes={{
              root: classes.buttonRootFooter,
              label: classes.labelFooter,
            }}
          >
            {getLabel(getCommonKey(LangConstant.TXT_OK))}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  sttGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    "&>div": {
      width: props => (props.isMobile ? "unset" : 185),
      justifyContent: "space-evenly",
      "&>:last-child": {
        width: props => props.isMobile && "100%",
      },
    },
    "&>:last-child": {
      flexDirection: props => !props.isMobile && "row-reverse",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  startEndTimeInputWithPreOrder: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 92,
    padding: "0 3px",
  },
  startEndTimeInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 14px",
  },
  startEndTimeConnector: {
    width: "100%",
    height: 1,
    backgroundColor: "#d4d5d8",
    margin: "0 2px",
    [theme.breakpoints.down(600)]: {
      width: 2,
      height: 24,
      backgroundColor: "#ebeced",
      position: "absolute",
      top: 42,
    },
  },
  startEndTimeConnectorTransparent: {
    width: "100%",
    height: 1,
    backgroundColor: "transparent",
    margin: "0 2px",
  },
  startEndTimeBoxRoot: {
    display: "grid",
    justifyContent: "center",
    minWidth: 90,
    minHeight: 40,
    borderRadius: 2,
    position: "relative",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      marginLeft: 22,
      marginRight: 16,
    },
    "&:hover": {
      "&>svg": {
        display: "block",
      },
    },
  },
  startEndTimeInputMobile: {
    marginRight: 19,
    marginLeft: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 6,
    position: "relative",
  },
  startTimeBox: {
    cursor: "pointer",
    backgroundColor: "#f4f3ff",
  },
  endTimeBox: {
    cursor: "pointer",
    backgroundColor: "#fff4f3",
  },
  realTimeHourFormat: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#3b404c",
    lineHeight: 2,
    textAlign: "center",
  },
  realTimeDateFormat: {
    fontSize: 10,
    fontWeight: "normal",
    color: "#6c7078",
    lineHeight: 2,
    textAlign: "center",
  },
  addFormInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 13,
    marginBottom: 5,
    [theme.breakpoints.down(600)]: {
      justifyContent: "space-evenly",
    },
  },
  addFormInputTime: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    marginBottom: 5,
    [theme.breakpoints.down(600)]: {
      justifyContent: "space-evenly",
    },
  },
  inputLabel: {
    fontSize: "inherit",
    lineHeight: 1.69,
  },
  timePicker: {
    marginLeft: 18,
  },
  inputTimeDialog: {
    minWidth: 440,
    minHeight: 200,
  },
  inputTimeDialogMobile: {
    minWidth: "unset",
    minHeight: "unset",
    height: "30%",
    margin: 10,
  },
  inputBox: {
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    width: "80%",
    minWidth: "unset",
    height: 30,
    "&:hover": {
      backgroundColor: "unset",
    },
    padding: "4px 16px",
    marginRight: 0,
    marginLeft: 5,
    [theme.breakpoints.down(600)]: {
      width: "60%",
    },
  },
  rootFooter: {
    right: 10,
    textAlign: "right",
    width: "100%",
    padding: "0 16px 8px 16px",
  },
  buttonCancelFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.black,
  },
  labelFooter: {
    width: "100%",
    height: 24,
    marginTop: 3,
    marginBottom: 3,
  },
  buttonRootFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.8,
      color: "unset",
    },
  },
  datepickerInput: {
    fontSize: 13,
    color: "#565c6a",
  },
  inputTimeDialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 0,
  },
  inputTimeTitle: {},
  saveButton: {
    textTransform: "none",
  },
  editIcon: {
    position: "absolute",
    right: 2,
    top: 2,
    width: 16,
    height: 16,
    display: "none",
  },
  fullScreenDialog: {
    marginTop: 64,
    height: "88%",
    width: "96%",
  },
  endTimeConnector: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  endTimeConnectorLineHorizontal: {
    height: 1,
    width: 28,
    backgroundColor: "#ebeced",
    margin: "0 2px",
  },
  realUseTimeText: {
    fontSize: 10,
    fontWeight: "bold",
    lineHeight: 2,
    margin: "0 2px",
  },
}));

const Card = withStyles(theme => ({
  root: {
    position: "relative",
    borderRadius: "unset",
    boxShadow: "none",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      width: 630,
    },
  },
}))(MuiCard);

const Header = withStyles(theme => ({
  root: {
    position: "relative",
    padding: 8,
    background: "#65b39d",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 40,
  },
  content: {
    flex: "none",
  },
  action: {
    flex: "none",
    position: "absolute",
    right: 10,
  },
  title: {
    ...theme.typography.subtitle2,
    color: "white",
  },
}))(CardHeader);

const Content = withStyles(() => ({
  root: {
    width: "100%",
    height: "266px",
    overflow: "auto",
    padding: "30px 20px",
    flexGrow: 1,
    "&>*": {
      lineHeight: "20px",
      color: "#787878",
    },
  },
}))(CardContent);

const Actions = withStyles(() => ({
  root: {
    padding: 14.5,
    justifyContent: "center",
    alignItems: "center",
    borderTop: "solid 1px #f1f3f6",
  },
}))(CardActions);

const createDateOpt = (hours, minutes) => ({
  hours: hours,
  minutes: minutes,
  seconds: 0,
});
