import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import {
  Paper,
  Popper,
  Box,
  Typography,
  List,
  Grid,
  makeStyles,
  useMediaQuery,
  CircularProgress,
  useTheme,
  ClickAwayListener,
} from "@material-ui/core";
import { LangConstant } from "const";
import ReservationNewAction from "redux/reservation-new.redux";
import EmptyNotice from "components/Notification/EmptyNotice";
import { uuid } from "utils";
import NewOrderNotifyItem from "./NewOrderNotifyItem";

const NewOrderNotifyContent = ({ onClose, anchorEl }) => {
  const defaultClasses = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const screenBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t: getLabel } = useTranslation(LangConstant.NS_MAIN_LAYOUT);

  const [orderNotification, isFetching] = useSelector(
    ({ reservationNewRedux }) => [reservationNewRedux.orderNotification, reservationNewRedux.isFetching],
    shallowEqual,
  );

  const { page, totalPage, data } = orderNotification;

  const [newBooking, setNewBooking] = useState({});
  const [currentPage, setCurrentPage] = useState(page);
  const [keepLoading, setKeepLoading] = useState(true);

  let isLoadMore = page < totalPage;

  const onLoadMore = () => {
    if (newBookingLoadMoreTimeout) {
      clearTimeout(newBookingLoadMoreTimeout);
    }
    newBookingLoadMoreTimeout = setTimeout(() => {
      if (isLoadMore && keepLoading) {
        dispatch(
          ReservationNewAction.getReservationNewOrder({
            page: currentPage + 1,
          }),
        );
        setKeepLoading(false);
      }
    }, 500);
  };

  useEffect(() => {
    if (data && !isFetching) {
      let newData = data;
      if (page > currentPage) {
        newData = Object.assign(newBooking, data);
      }
      setNewBooking(newData);
      setCurrentPage(page);
      setKeepLoading(true);
    }
  }, [data, page]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} className={defaultClasses.popoverPaper}>
        <Box className={defaultClasses.main}>
          <Box className={defaultClasses.header}>
            <Typography variant="subtitle2" className={defaultClasses.typoHeader}>
              {getLabel(LangConstant.TXT_NEW_ORDER_NOTIFY_TITLE)}
            </Typography>
          </Box>
          <Box>
            <Grid container direction="row">
              <Grid item xs={screenBreakpoint ? 6 : 12} className={defaultClasses.notice}>
                <Paper className={defaultClasses.bodyBox}>
                  <List className={defaultClasses.listRoot} disablePadding={true}>
                    {newBooking && Object.keys(newBooking).length > 0 && (
                      <InfiniteScroll
                        initialLoad={false}
                        pageStart={1}
                        loadMore={onLoadMore}
                        hasMore={isLoadMore}
                        loader={isFetching && <CircularProgress key={uuid()} />}
                        useWindow={false}
                      >
                        {Object.values(newBooking).map((notification, index) => (
                          <NewOrderNotifyItem key={index} data={notification} onClose={onClose} />
                        ))}
                      </InfiniteScroll>
                    )}
                    {newBooking && Object.keys(newBooking).length === 0 && (
                      <EmptyNotice message={getLabel(LangConstant.TXT_EMPTY_NEW_ORDER_NOTICE_MESSAGE)} />
                    )}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

var newBookingLoadMoreTimeout;

const useStyles = makeStyles(theme => ({
  popoverPaper: {
    zIndex: theme.zIndex.drawer,
    overflow: "unset",
    "&::before": {
      position: "absolute",
      top: "-9px",
      right: "43%",
      borderBottom: `10px solid ${theme.palette.secondary.main}`,
      borderRight: "20px solid transparent",
      borderLeft: "20px solid transparent",
      content: `''`,
      marginTop: 10,
      [theme.breakpoints.down("md")]: {
        right: "31%",
      },
      [theme.breakpoints.down("sm")]: {
        right: "49%",
      },
    },
  },
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    wordBreak: "break-all",
    marginTop: 10,
  },
  header: {
    width: "100%",
    height: 40,
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: theme.palette.white,
    backgroundColor: "#65b39d",
    padding: "0 16px",
  },
  typoHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "10px",
    color: theme.palette.white,
    position: "relative",
    flexGrow: 1,
  },
  listRoot: {
    height: "100%",
    maxHeight: 800,
    minWidth: 300,
    [theme.breakpoints.down("lg")]: {
      maxHeight: "600px",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "550px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: 400,
    },
    overflow: "auto",
  },
  notice: {
    width: "100%",
    height: "100%",
    maxHeight: 600,
    minWidth: 200,
    maxWidth: 456,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    paddingRight: 2,
    [theme.breakpoints.up("md")]: {
      maxWidth: 480 + "!important",
      minWidth: 480,
    },
  },
}));

NewOrderNotifyContent.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  onClose: PropTypes.func,
  isFetching: PropTypes.bool,
  open: PropTypes.bool,
  classes: PropTypes.shape({
    popover: PropTypes.string,
  }),
};
NewOrderNotifyContent.defaultProps = {
  data: {},
  id: "",
  onClose: () => {},
  isFetching: false,
};

export default memo(NewOrderNotifyContent);
