import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const get = () => createApiReservation().get(ApiConstant.WAITING_CONSUMER, {});

export const create = data => createApiReservation().post(ApiConstant.WAITING_CONSUMER, data);

export const update = data => createApiReservation().put(format(ApiConstant.WAITING_CONSUMER_BY_UID, data.uid), data);

export const remove = data => createApiReservation().delete(format(ApiConstant.WAITING_CONSUMER_BY_UID, data.uid), {});
