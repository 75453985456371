import React, { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, useTheme, useMediaQuery, makeStyles, debounce, Grid, Hidden } from "@material-ui/core";
import { CropFree, FullscreenExit } from "@material-ui/icons";
import { saveAs } from "file-saver";
import { getTime, isSameDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { AddReservation, ExportFile, FilterMenuMnBooking } from "../index";
import { PrimaryCustomDatePicker, HoverMessage, SearchInPeriod } from "components";
import CustomSearchBar from "pages/MnNews/components/CustomSearchBar";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, getFeatureConditions } from "utils";
import { ReservationService } from "services";
import ReservationAction from "redux/reservation.redux";
import ServiceCategoryAction from "redux/service-category.redux";
import StaffAction from "redux/staff.redux";
import { controlScroll, getContainerEl, isEndScroll, WIDTH_COLUMN } from "pages/MnReservation";
import PlaceStatusDialog from "../PlaceStatusDialog";

const MnReservationHeader = ({ onChangeTab, date }) => {
  const [reservationSetting, reservationStatus, newReservation, isPostReservationOrderSuccess] = useSelector(
    ({ reservationRedux }) => [
      reservationRedux.reservationSetting,
      reservationRedux.reservationStatus,
      reservationRedux.newReservation,
      reservationRedux.isPostReservationOrderSuccess,
    ],
    shallowEqual,
  );

  let { isPremium } = getFeatureConditions(reservationSetting);

  const placeArray = useSelector(({ placeCategoryRedux }) => placeCategoryRedux.placeArray, shallowEqual);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const classes = useStyles();

  const exportBtnRef = useRef();
  const fullScreenBtnRef = useRef();
  const locationBtnRef = useRef();

  const theme = useTheme();
  const isDesktopUp = useMediaQuery(theme.breakpoints.up("md"));
  const isMobileUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [selectedDate, setSelectedDate] = useState({ from: new Date(), to: new Date() });
  const [optionView, setOptionView] = useState(AppConstant.ReservationViewBy.day);
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState("");
  const [filterByStaff, setFilterByStaff] = useState();
  const [filterByPlace, setFilterByPlace] = useState({
    placeItemUid: null,
    placeCategoryUid: null,
  });
  const [hoverMessageAnchor, setHoverMessageAnchor] = useState("");

  const getReservation = debounce(() => {
    let getReservationAction = createReservationAction(optionView);

    dispatch(
      getReservationAction({
        start: Math.floor(getTime(startDate.setHours(0, 0, 0)) / 1000),
        end: Math.floor(getTime(endDate.setHours(23, 59, 59)) / 1000),
        status: status,
        staffUid: filterByStaff,
        placeItemUid: filterByPlace.placeItemUid,
        placeCategoryUid: filterByPlace.placeCategoryUid,
        filter: searchValue,
      }),
    );
  }, 500);

  const onSubmitNewReservation = (newReservation, isSettingTotal, event) => {
    dispatch(ReservationAction.postReservation({ newReservation: newReservation, isSettingTotal: isSettingTotal }));
    event.preventDefault();
  };

  const onChangeSelectedDate = date => {
    removeDisplayDetail();
    onChangeDate(date.from, optionView);
  };

  const onClickTodayBtn = toDayObj => {
    onChangeDate(toDayObj.from, AppConstant.ReservationViewBy.day);
    setOptionView(AppConstant.ReservationViewBy.day);
  };

  const onChangeFilterByStatus = status => {
    setStatus(status > 0 && status != null ? status : null);
  };

  const onChangeModeView = option => {
    removeDisplayDetail();
    setOptionView(option);
    onChangeDate(selectedDate.from, option);
  };

  const removeDisplayDetail = () => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams) {
      searchParams.delete(AppConstant.KEY_START_TIME);
      searchParams.delete(AppConstant.KEY_UID);
      history.replace({
        search: searchParams.toString(),
      });
    }
  };

  const onChangeDate = (newSelectedDate, option) => {
    let startDate = newSelectedDate,
      endDate = newSelectedDate;
    if (option === AppConstant.ReservationViewBy.week) {
      startDate = startOfWeek(newSelectedDate, { weekStartsOn: 1 });
      endDate = endOfWeek(newSelectedDate, { weekStartsOn: 1 });
    } else if (option === AppConstant.ReservationViewBy.month) {
      startDate = startOfMonth(newSelectedDate);
      endDate = endOfMonth(newSelectedDate);
    }
    setSelectedDate({ from: startDate, to: endDate });
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const negativeScroll = () => {
    let scrollEl = getContainerEl();
    // Detect not exist or end scroll
    if (window.scrollTimeout) {
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = null;
    }
    if (!scrollEl || isEndScroll() || scrollEl.scrollLeft <= scrollEl.clientWidth) return;
    if (!window.lastScrollTop) window.lastScrollTop = 0;

    let isCorrectScroll = scrollEl.scrollLeft % WIDTH_COLUMN === 0;
    if (!isCorrectScroll) {
      let isScrollTop = scrollEl.scrollLeft > window.lastScrollTop;
      window.scrollTimeout = setTimeout(() => {
        if (isDesktopUp) {
          let nextColumn = Math.floor(scrollEl.scrollLeft / WIDTH_COLUMN);
          nextColumn = isScrollTop ? nextColumn + 1 : nextColumn - 1;
          let scrollToPosition = nextColumn * WIDTH_COLUMN;

          scrollEl.scrollLeft = scrollToPosition > scrollEl.scrollWidth ? scrollEl.scrollWidth : scrollToPosition;
          window.lastScrollTop = scrollEl.scrollLeft;
        } else {
          controlScroll(isScrollTop ? 1 : -1);
        }
      }, 500);
    }
  };

  const onExportFile = async () => {
    let data = {
      start: Math.floor(getTime(startDate.setHours(0, 0, 0)) / 1000),
      end: Math.floor(getTime(endDate.setHours(23, 59, 59)) / 1000),
      status: status,
    };
    let fileResponse = await ReservationService.getReservationExport(data);
    try {
      saveAs(new Blob([fileResponse.data]), AppConstant.FILE_NAME_EXPORT_BOOKING);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearchingBooking = event => {
    let inputValue = event.target.value;
    if (inputValue) {
      if (window.searchTimeout) {
        clearTimeout(window.searchTimeout);
        window.searchTimeout = null;
      }
      window.searchTimeout = setTimeout(() => {
        setSearchValue(inputValue);
      }, 500);
    } else {
      setSearchValue("");
    }
  };

  const onFullScreenHandle = () => {
    let elem = document.documentElement;
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const onHoverMessageAnchor = () => {
    switch (hoverMessageAnchor) {
      case exportBtnRef.current:
        return getLabel(LangConstant.TXT_DOWNLOAD);
      case fullScreenBtnRef.current:
        if (!document.fullscreenElement) {
          return getLabel(LangConstant.TXT_FULL_SCREEN);
        } else {
          return getLabel(LangConstant.TXT_EXIT_FULL_SCREEN);
        }
      case locationBtnRef.current:
        return getLabel(getCommonKey(LangConstant.TXT_BOOK_PREVIEW_PLACE));
    }
  };

  useEffect(() => {
    let scrollInterval = setInterval(() => addEventScroll(scrollInterval, negativeScroll), RECALL_TIME);
    onChangeTab(optionView);

    return () => {
      clearInterval(scrollInterval);
      scrollInterval = null;
    };
  }, [optionView]);

  useEffect(() => {
    getReservation();
    return () => getReservation.clear();
  }, [
    startDate,
    endDate,
    status,
    filterByStaff,
    filterByPlace,
    optionView,
    reservationStatus,
    newReservation,
    searchValue,
  ]);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams && searchParams.get(AppConstant.KEY_START_TIME) && searchParams.get(AppConstant.KEY_UID)) {
      setStatus(null);
      setOptionView(AppConstant.ReservationViewBy.day);
      onChangeDate(new Date(searchParams.get(AppConstant.KEY_START_TIME) * 1000), AppConstant.ReservationViewBy.day);
    } else if (searchParams && searchParams.get(AppConstant.KEY_START_TIME)) {
      removeDisplayDetail();
    }
  }, [location]);

  useEffect(() => {
    dispatch(ReservationAction.getDisplaySetting());
    dispatch(ServiceCategoryAction.getServiceCategory({}));
    dispatch(ReservationAction.getReservationSettingOption());
    dispatch(StaffAction.requestStaff({ page: 0 }));
    var scrollInterval = setInterval(() => addEventScroll(scrollInterval, negativeScroll), RECALL_TIME);

    // When close this screen, reset function
    return () => {
      removeEventScroll(negativeScroll);
    };
  }, []);

  useEffect(() => {
    let isNotSameDay = !isSameDay(date, selectedDate.from);
    if (isNotSameDay) {
      onChangeDate(date, optionView);
    }
  }, [date]);

  useEffect(() => {
    if (isPostReservationOrderSuccess) {
      dispatch(ReservationAction.resetPutReservation());
    }
  }, [isPostReservationOrderSuccess]);

  return (
    <>
      <Box className="header">
        <Grid container spacing={2}>
          <Grid item xs="auto" lg={4}>
            <Box className="header-left">
              <PrimaryCustomDatePicker
                onChange={onChangeSelectedDate}
                dateRange={selectedDate}
                optionView={optionView}
                onClickToday={onClickTodayBtn}
              />
              {optionView === AppConstant.ReservationViewBy.month && (
                <SearchInPeriod
                  onChange={onChangeModeView}
                  optionViewSelected={optionView}
                  className={classes.optionViewDesktop}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs="auto" lg={5}>
            {optionView !== AppConstant.ReservationViewBy.month && isMobileUp && (
              <Box className={classes.boxCenter}>
                <Box display="flex">
                  <SearchInPeriod
                    onChange={onChangeModeView}
                    optionViewSelected={optionView}
                    className={classes.optionViewDesktop}
                  />
                  <CustomSearchBar
                    placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
                    className={classes.searchBarDesktop}
                    onChange={onSearchingBooking}
                  />
                </Box>
              </Box>
            )}
            <Box className={classes.searchBarContainerMediaSmall}>
              <Box display="flex">
                <SearchInPeriod
                  onChange={onChangeModeView}
                  optionViewSelected={optionView}
                  className={classes.optionViewMobile}
                />
                {optionView !== AppConstant.ReservationViewBy.month && (
                  <CustomSearchBar
                    placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
                    className={classes.searchBarMobileSmall}
                    onChange={onSearchingBooking}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs="auto" lg={3}>
            <Box className={classes.additionalFunctionContainer}>
              {optionView !== AppConstant.ReservationViewBy.month && (
                <CustomSearchBar
                  placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
                  className={classes.searchBarMobileMedium}
                  onChange={onSearchingBooking}
                />
              )}
              {isPremium && <PlaceStatusDialog />}
              <Box
                ref={exportBtnRef}
                onMouseEnter={() => setHoverMessageAnchor(exportBtnRef.current)}
                onMouseLeave={() => setHoverMessageAnchor("")}
              >
                <ExportFile onExportFile={onExportFile} extraClass={classes.exportButton} />
              </Box>
              <Button
                className={classes.fullScreenBtn}
                onClick={onFullScreenHandle}
                ref={fullScreenBtnRef}
                onMouseEnter={() => setHoverMessageAnchor(fullScreenBtnRef.current)}
                onMouseLeave={() => setHoverMessageAnchor("")}
              >
                {document.fullscreenElement ? <FullscreenExit /> : <CropFree />}
              </Button>
              {optionView !== AppConstant.ReservationViewBy.month && (
                <AddReservation
                  onSubmitNewReservation={onSubmitNewReservation}
                  reservationSetting={reservationSetting}
                />
              )}
            </Box>
          </Grid>
          <Hidden lgDown>
            <Grid item lg={4} />
          </Hidden>
          <Grid item xs={12} lg={5}>
            <FilterMenuMnBooking
              onChangeFilter={onChangeFilterByStatus}
              onChangeFilterByStaff={staff => setFilterByStaff(staff.uid)}
              onChangeFilterByPlace={place => {
                setFilterByPlace({
                  placeItemUid: place.categoryUid ? place.uid : null,
                  placeCategoryUid: place.categoryUid || place.uid,
                });
              }}
              status={status}
              placeCategoryArray={placeArray}
            />
          </Grid>
          <Hidden lgDown>
            <Grid item xs="auto" lg={3} />
          </Hidden>
          {isMobileUp && (
            <HoverMessage
              messagePosition={{
                top: "5px",
              }}
              anchorEl={hoverMessageAnchor}
              message={hoverMessageAnchor ? onHoverMessageAnchor() : ""}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

MnReservationHeader.prototype = {
  onChangeTab: PropTypes.func,
};
MnReservationHeader.defaultProps = {};

export default memo(MnReservationHeader);

const RECALL_TIME = 1000;

const addEventScroll = (scrollInterval, negativeScroll) => {
  let scrollEl = getContainerEl();
  if (scrollInterval) {
    if (scrollEl) {
      scrollEl = getContainerEl();
      // Add event scrolling
      scrollEl.addEventListener("scroll", negativeScroll);
      clearInterval(scrollInterval);
    }
  }
};

const removeEventScroll = (scrollInterval, negativeScroll) => {
  let scrollEl = getContainerEl();
  if (scrollEl) scrollEl.removeEventListener("scroll", negativeScroll);
  clearInterval(scrollInterval);
};

export const createReservationAction = optionView => {
  switch (optionView) {
    case AppConstant.ReservationViewBy.day:
      return ReservationAction.getReservationDay;
    case AppConstant.ReservationViewBy.week:
      return ReservationAction.getReservationWeek;
    case AppConstant.ReservationViewBy.month:
      return ReservationAction.getReservationMonth;
    default:
      return ReservationAction.getReservationDay;
  }
};

const useStyles = makeStyles(theme => ({
  searchBarDesktop: {
    marginLeft: 9,
    minWidth: 240,
    maxWidth: 525,
    height: 30,
    backgroundColor: "#f6f5f9",
    "@media only screen and (max-width: 700px)": {
      display: "none",
    },
  },
  searchBarMobileMedium: {
    margin: "0 9px",
    width: "auto",
    height: 30,
    display: "none",
    backgroundColor: "#f6f5f9",
    "@media only screen and (max-width: 700px)": {
      display: "flex",
      height: 30,
      marginLeft: 0,
    },
    "@media only screen and (max-width: 550px)": {
      display: "none",
      height: 30,
    },
  },
  searchBarMobileSmall: {
    display: "none",
    height: 30,
    backgroundColor: "#f6f5f9",
    "@media only screen and (max-width: 549px)": {
      display: "flex",
      height: 30,
      marginLeft: 9,
    },
  },
  fullScreenBtn: {
    height: 30,
    width: 30,
    marginLeft: 2,
    border: "solid 1px #ebeced",
    color: theme.palette.grey[600],
    minWidth: "unset",
    "&:hover": {
      backgroundColor: "#3e4045",
      color: "#fff",
      "&>span>p": {
        color: "#fff",
      },
    },
  },
  additionalFunctionContainer: {
    display: "flex",
    height: "fit-content",
    justifyContent: "end",
    "@media only screen and (max-width: 700px)": {
      display: "flex",
      margin: "5px 0",
    },
  },
  exportButton: {
    height: 30,
    "@media only screen and (max-width: 600px)": {
      marginLeft: 0,
    },
  },
  optionViewDesktop: {
    height: 30,
    "@media only screen and (max-width: 550px)": {
      display: "none",
    },
  },
  optionViewMobile: {
    display: "none",
    "@media only screen and (max-width: 550px)": {
      display: "flex",
      height: 30,
    },
  },
  searchBarContainerMediaSmall: {
    display: "none",
    "@media only screen and (max-width: 700px)": {
      display: "block",
      marginBottom: 5,
    },
  },

  todayButton: {
    minWidth: 81,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    border: "solid 1px #ebeced",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
  todayButtonSelected: {
    minWidth: 80,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    padding: "4px 8px",
    backgroundColor: "#3e4045",
    color: theme.palette.white,
    "&>span>p": {
      color: theme.palette.white,
    },
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
  buttonCalendarLeft: {
    minWidth: 28,
    height: 30,
    padding: "3px 2px",
    borderTop: "solid 1px #ebeced",
    borderBottom: "solid 1px #ebeced",
    borderRadius: 2,
  },
  buttonCalendarRight: {
    borderRight: "solid 1px #ebeced",
    marginRight: 26,
  },
  iconButtonCalendar: {
    width: 24,
    height: 24,
  },
  boxCenter: {
    display: "block",
    maxWidth: 700,
    minWidth: 400,
    flexGrow: 1,
  },
}));
