import React, { memo, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { vi as viLocale, enUS as enLocale } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "../../../../../const";

const CustomDatePicker = ({ onChange, selectedDateProp, index, ...otherProps }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(selectedDateProp);
  const [isOpen, setIsOpen] = useState(false);

  const onDateChange = date => {
    setSelectedDate(date);
    onChange(date, index);
    setIsOpen(false);
  };

  const onClick = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <Box onClick={e => onClick(e)} className={classes.boxRoot}>
        <DatePicker
          minDateMessage={null}
          disableToolbar
          variant="inline"
          format={AppConstant.FM_DD_MM_YYYY}
          value={selectedDate}
          onChange={onDateChange}
          InputProps={{
            disableUnderline: true,
            className: classes.inputRoot,
          }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          className={classes.datePickerRoot}
          disablePast
          {...otherProps}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    width: "100%",
    height: 20,
    color: theme.palette.text.link,
    fontSize: 13,
    fontWeight: "inherit",
    padding: "0px !important",
  },
  datePickerRoot: {
    pointerEvents: `none`,
    height: "100%",
  },
  boxRoot: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: 0,
    color: theme.palette.text.link,
    minWidth: 30,
    height: "100%",
    opacity: 1,
    cursor: `pointer`,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  textField: {
    fontSize: 13,
    color: theme.palette.black,
  },

  input: {
    pointerEvents: `none`,
    height: "100%",
  },
}));

const localeMap = {};
localeMap[LangConstant.EN_LANG] = enLocale;
localeMap[LangConstant.DEFAULT_LANG] = viLocale;

export default memo(CustomDatePicker);
