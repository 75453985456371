import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Checkbox, Grid, makeStyles, OutlinedInput, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import { LangConstant, SystemConstant } from "const";
import { deepCloneJsonObject, getCommonKey, isArrayNotEqual, isObjectNotEqual } from "utils";
import { TabPanel } from "components";
import SelectOptionButton from "components/SelectOptionButton";
import ConfirmDialog from "pages/BillManagement/components/ConfirmDialog";
import { BankingInfoItem, DialogAddBankingInfo } from ".";

const OrderPaymentTab = ({ selectedTab, tabIndex, onChange }) => {
  const classes = useStyles();

  const reservationSetting = useSelector(state => state.reservationSettingRedux.dataSetting?.remoteOrder);

  const [previewData, setPreviewData] = useState(DEFAULT_DEPOSIT_INFO);

  const onChangePreviewData = data => {
    setPreviewData(data);
    onChange(data, isObjectNotEqual(data, previewData));
  };

  useEffect(() => {
    if (reservationSetting) {
      let orderPaymentData = reservationSetting;

      let paymentByCash = orderPaymentData.details.depositInformation?.details?.find(
        item => item.type === SystemConstant.PAYMENT_METHOD.cash,
      );

      let paymentByBanking = orderPaymentData.details.depositInformation?.details?.find(
        item => item.type === SystemConstant.PAYMENT_METHOD.internetBanking,
      );

      let shippingInfo = orderPaymentData.details.shippingInformation;

      orderPaymentData = {
        details: {
          depositInformation: {
            publish: orderPaymentData.details.depositInformation.publish,
            details: [
              {
                type: SystemConstant.PAYMENT_METHOD.cash,
                publish: paymentByCash?.publish || false,
              },
              {
                type: SystemConstant.PAYMENT_METHOD.internetBanking,
                publish: paymentByBanking?.publish || false,
                transactionInformation: paymentByBanking?.transactionInformation || [],
              },
            ],
          },
          shippingInformation: {
            publish: shippingInfo?.publish || false,
            takeAway: shippingInfo?.takeAway || false,
            shipping: shippingInfo?.shipping || false,
            content: shippingInfo?.content || "",
          },
          publish: orderPaymentData.details?.depositInformation?.publish || shippingInfo?.publish,
        },
        uid: orderPaymentData.uid,
        type: orderPaymentData.type,
        publish: orderPaymentData.publish,
      };

      setPreviewData(orderPaymentData);
    }
  }, [reservationSetting]);

  return (
    <>
      <TabPanel value={selectedTab} index={tabIndex}>
        {Boolean(previewData) && (
          <Grid item xs={12} sm={6} container className={classes.boxBody} spacing={1}>
            <Grid item>
              <PaymentMethodSetting onChange={onChangePreviewData} data={previewData} />
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </>
  );
};

OrderPaymentTab.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  isDisable: PropTypes.bool,
};

OrderPaymentTab.defaultProps = {
  onChange: () => {},
};

const PaymentMethodSetting = ({ data, onChange }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_PAYMENT_SETUP);
  const classes = useStyles();

  const [isShowAddBankingInfo, setIsShowAddBankingInfo] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [bankingInfoList, setBankingInfoList] = useState([]);
  const [isShowConfirmDeleteDialog, setIsShowConfirmDeleteDialog] = useState(false);

  const onSelectPaymentMethod = method => {
    let newSubmitForm = deepCloneJsonObject(data);
    let newDetails = newSubmitForm.details.depositInformation.details || [];

    switch (method) {
      case SystemConstant.PAYMENT_METHOD.cash:
        newDetails = newDetails.map(item =>
          item.type === SystemConstant.PAYMENT_METHOD.cash ? { ...item, publish: !Boolean(item.publish) } : item,
        );
        break;

      case SystemConstant.PAYMENT_METHOD.internetBanking:
        newDetails = newDetails.map(item =>
          item.type === SystemConstant.PAYMENT_METHOD.internetBanking
            ? { ...item, publish: !Boolean(item.publish) }
            : item,
        );
        break;

      default:
        break;
    }

    newSubmitForm.details.depositInformation.details = newDetails;
    onChange(newSubmitForm);
  };

  const onChangeShippingMethod = method => {
    let newSubmitForm = deepCloneJsonObject(data);
    let newShippingInformation = newSubmitForm.details.shippingInformation;

    switch (method) {
      case SystemConstant.SHIPPING_METHOD.shipping:
        newShippingInformation = {
          ...newShippingInformation,
          shipping: !Boolean(newShippingInformation.shipping),
        };
        break;

      case SystemConstant.SHIPPING_METHOD.takeAway:
        newShippingInformation = {
          ...newShippingInformation,
          takeAway: !Boolean(newShippingInformation.takeAway),
        };
        break;

      default:
        break;
    }

    newSubmitForm.details.shippingInformation = newShippingInformation;

    onChange(newSubmitForm);
  };

  const onChangeShippingContent = content => {
    let newSubmitForm = deepCloneJsonObject(data);
    let newShippingInformation = newSubmitForm.details.shippingInformation;
    newShippingInformation.content = content;
    newSubmitForm.details.shippingInformation = newShippingInformation;
    onChange(newSubmitForm);
  };

  const onChangeBankingInfo = newForm => {
    let newList = [...bankingInfoList];

    if (selectedIndex !== null) {
      newList[selectedIndex] = newForm;
      setSelectedIndex(null);
    } else newList.push(newForm);

    onChange(formatBankingList(data, newList));
  };

  const onCloseAddDialog = () => {
    setSelectedIndex(null);
    setIsShowAddBankingInfo(false);
  };

  const onEdit = index => {
    setSelectedIndex(index);
    setIsShowAddBankingInfo(true);
  };

  const onOpenDeleteDialog = index => {
    setSelectedIndex(index);
    setIsShowConfirmDeleteDialog(true);
  };

  const onConfirmDelete = () => {
    let newList = [...bankingInfoList];
    newList.splice(selectedIndex + 1, 1);
    setSelectedIndex(null);
    setIsShowConfirmDeleteDialog(false);

    onChange(formatBankingList(data, newList));
  };

  const onCancelDelete = () => {
    setSelectedIndex(null);
    setIsShowConfirmDeleteDialog(false);
  };

  const onChecked = (name, value) => {
    let isPublish = Boolean(value);
    let previewObj = { ...data.details };

    switch (name) {
      case DEPOSIT_INFORMATION:
        previewObj = {
          ...previewObj,
          depositInformation: {
            ...previewObj.depositInformation,
            publish: isPublish,
          },
        };
        break;

      case ORDER_DELIVERY_INFORMATION:
        previewObj = {
          ...previewObj,
          shippingInformation: {
            ...previewObj.shippingInformation,
            publish: isPublish,
            content: "",
          },
        };
        break;
    }
    onChange({ ...data, details: previewObj });
  };

  useEffect(() => {
    let bankingList = data.details.depositInformation.details?.find(
      item => item.type === SystemConstant.PAYMENT_METHOD.internetBanking,
    )?.transactionInformation;

    if (isArrayNotEqual(bankingList, bankingInfoList)) setBankingInfoList(bankingList);
  }, [data]);

  let isShowPaymentMethod = Boolean(data.details.depositInformation.publish);
  let isShowShippingInfo = Boolean(data.details.shippingInformation.publish);
  let paymentMethodDetails = data.details.depositInformation.details;
  let shippingContent = data.details.shippingInformation.content;

  return (
    <>
      <Grid item container direction="column" justify="flex-start" className="inputBooking">
        <Grid item container direction="row" justify="space-between" alignItems="baseline">
          <Grid item>
            <Typography className="medium-md-txt">{getLabel(LangConstant.TXT_SHOW_PAYMENT_INFORMATION)}</Typography>
          </Grid>
          <Box className={classes.boxValueTime}>
            <Grid item>
              <Checkbox
                color="primary"
                className="checkbox"
                checked={isShowPaymentMethod}
                onChange={event => {
                  let isChecked = event.currentTarget.checked;
                  onChecked(DEPOSIT_INFORMATION, isChecked);
                }}
              />
            </Grid>
          </Box>
        </Grid>
        {isShowPaymentMethod && (
          <>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="baseline"
              className={classes.orderPaymentInfo}
            >
              <SelectOptionButton
                data={{
                  name: getLabel(LangConstant.TXT_CASH),
                }}
                onClick={() => onSelectPaymentMethod(SystemConstant.PAYMENT_METHOD.cash)}
                isSelected={checkPaymentPublishStatus(SystemConstant.PAYMENT_METHOD.cash, paymentMethodDetails)}
              />
              <SelectOptionButton
                data={{
                  name: getLabel(LangConstant.TXT_INTERNET_BANKING),
                }}
                onClick={() => onSelectPaymentMethod(SystemConstant.PAYMENT_METHOD.internetBanking)}
                isSelected={checkPaymentPublishStatus(
                  SystemConstant.PAYMENT_METHOD.internetBanking,
                  paymentMethodDetails,
                )}
              />
            </Grid>
            {checkPaymentPublishStatus(SystemConstant.PAYMENT_METHOD.internetBanking, paymentMethodDetails) && (
              <>
                <Grid
                  item
                  container
                  direction="column"
                  wrap="nowrap"
                  alignItems="baseline"
                  className={classes.bankingListContainer}
                >
                  {bankingInfoList.map((item, index) => (
                    <BankingInfoItem
                      key={index}
                      label={getLabel(LangConstant.FM_BANKING_INFO, { index: index + 1 })}
                      data={item}
                      onEdit={() => onEdit(index)}
                      onDelete={() => onOpenDeleteDialog(index)}
                    />
                  ))}
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  wrap="nowrap"
                  alignItems="baseline"
                  className={classes.orderPaymentInfo}
                >
                  <Button
                    className={clsx(classes.addBankingInfoBtn, "semiBold-sm-txt")}
                    classes={{
                      iconSizeMedium: classes.iconMedium,
                    }}
                    endIcon={<Add className={classes.addIcon} />}
                    onClick={() => setIsShowAddBankingInfo(true)}
                  >
                    {getLabel(LangConstant.TXT_ADD_BANKING_INFO)}
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid item container direction="column" justify="flex-start" className={classes.shippingInfoSetup}>
        <Grid item container direction="row" justify="space-between" alignItems="baseline">
          <Grid item>
            <Typography className={"medium-md-txt typo"}>{getLabel(LangConstant.TXT_ORDER_DELIVERY)}</Typography>
          </Grid>
          <Box className={classes.boxValueTime}>
            <Grid item>
              <Checkbox
                color="primary"
                className="checkbox"
                checked={isShowShippingInfo}
                onChange={event => {
                  let isChecked = event.currentTarget.checked;
                  onChecked(ORDER_DELIVERY_INFORMATION, isChecked);
                }}
              />
            </Grid>
          </Box>
        </Grid>
        {isShowShippingInfo && (
          <>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              alignItems="baseline"
              className={classes.orderPaymentInfo}
            >
              <SelectOptionButton
                data={{
                  name: getLabel(LangConstant.TXT_SHIP_AWAY),
                }}
                onClick={() => onChangeShippingMethod(SystemConstant.SHIPPING_METHOD.shipping)}
                isSelected={Boolean(data.details.shippingInformation.shipping)}
              />
              <SelectOptionButton
                data={{
                  name: getLabel(LangConstant.TXT_TAKE_AWAY),
                }}
                onClick={() => onChangeShippingMethod(SystemConstant.SHIPPING_METHOD.takeAway)}
                isSelected={Boolean(data.details.shippingInformation.takeAway)}
              />
            </Grid>
            <Grid item>
              <OutlinedInput
                value={shippingContent || ""}
                placeholder={getLabel(LangConstant.P_ORDER_DELIVERY)}
                rowsMax={10}
                onChange={event => {
                  let newContent = event.target.value;
                  onChangeShippingContent(newContent);
                }}
                fullWidth={true}
                multiline={true}
                classes={{
                  root: clsx(classes.inputRoot, "medium-md-txt"),
                  input: classes.infoInput,
                  multiline: classes.multilineInput,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <DialogAddBankingInfo
        isShow={isShowAddBankingInfo}
        onClose={onCloseAddDialog}
        formData={bankingInfoList[selectedIndex]}
        isEditing={Boolean(bankingInfoList[selectedIndex])}
        onChange={onChangeBankingInfo}
      />
      <ConfirmDialog
        isShow={isShowConfirmDeleteDialog}
        title={getLabel(LangConstant.TXT_DELETE_BANKING_INFO)}
        titleConfirm={getLabel(LangConstant.FM_DELETE_MESSAGE, { name: bankingInfoList[selectedIndex]?.serviceName })}
        cancelText={getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
        confirmText={getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
        onSelectConfirm={onConfirmDelete}
        onSelectCancel={onCancelDelete}
      />
    </>
  );
};

export default memo(OrderPaymentTab);

const checkPaymentPublishStatus = (type, data) => {
  if (!(type && data)) return false;
  return Boolean(data.find(item => item.type === type)?.publish);
};

const formatBankingList = (submitForm, newList) => {
  if (!(submitForm, newList)) return {};

  let newSubmitForm = deepCloneJsonObject(submitForm);
  let paymentMethodDetailList = newSubmitForm.details.depositInformation.details;

  paymentMethodDetailList = paymentMethodDetailList.map(item =>
    item.type === SystemConstant.PAYMENT_METHOD.internetBanking ? { ...item, transactionInformation: newList } : item,
  );

  newSubmitForm.details.depositInformation.details = paymentMethodDetailList;

  return newSubmitForm;
};

const ORDER_DELIVERY_INFORMATION = "shippingInformation";
const DEPOSIT_INFORMATION = "depositInformation";

const DEFAULT_DEPOSIT_INFO = {
  details: {
    depositInformation: {
      publish: false,
      details: [
        {
          type: SystemConstant.PAYMENT_METHOD.cash,
          publish: false,
        },
        {
          type: SystemConstant.PAYMENT_METHOD.internetBanking,
          publish: false,
          transactionInformation: [],
        },
      ],
    },
    shippingInformation: {
      publish: false,
      takeAway: false,
      shipping: false,
      content: "",
    },
    publish: false,
  },
};

const useStyles = makeStyles(theme => ({
  boxBody: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 250px)",
    paddingLeft: 54,
    paddingTop: 37,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 24,
    },
  },

  outlineInput: {
    paddingLeft: 15,
    [theme.breakpoints.down(600)]: {
      paddingLeft: 0,
    },
  },

  boxInput: {
    paddingTop: 15,
    paddingBottom: 15,
  },

  boxInputValue: {
    paddingLeft: 15,
  },

  orderPaymentInfo: {
    maxWidth: "calc(100% - 12px)",
  },

  inputRoot: {
    minHeight: 60,
    marginTop: 24,
  },

  infoInput: {
    height: "100%",
    lineHeight: 1.57,
    "&::placeholder": {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.57,
    },
  },

  multilineInput: {
    alignItems: "flex-start",
    padding: "9px 8px",
  },

  addBankingInfoBtn: {
    textTransform: "none",
    color: theme.palette.primary.main,
    padding: 0,
    marginTop: 24,
  },

  addIcon: {
    border: "1px solid " + theme.palette.primary.main,
    color: theme.palette.primary.main,
  },

  iconMedium: {
    "&>*:first-child": {
      fontSize: 26,
    },
  },

  bankingListContainer: {
    paddingRight: 9,
  },

  shippingInfoSetup: {
    marginTop: 54,
  },
}));
