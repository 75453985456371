import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Badge, Typography } from "@material-ui/core";
import clsx from "clsx";

const NotifyBadge = ({ badgeClass, children, data }) => {
  const isSingleDigit = Boolean(data && data.toString().length === SINGLE_DIGIT);
  const classes = useStyles({ isSingleDigit });

  return (
    <Badge
      badgeContent={
        data > TOTAL_NOTIFICATION_THRESHOLD ? (
          <Exceeded />
        ) : (
          <Typography color="inherit" variant="caption">
            {data}
          </Typography>
        )
      }
      invisible={false}
      classes={{ badge: clsx(classes.badgeRoot, badgeClass), anchorOriginTopRightRectangle: classes.anchorBadge }}
    >
      {children}
    </Badge>
  );
};

NotifyBadge.propTypes = {
  children: PropTypes.node,
};

NotifyBadge.defaultProps = {};

export const TOTAL_NOTIFICATION_THRESHOLD = 99;
export const SINGLE_DIGIT = 1;

const Exceeded = () => {
  const classes = useStyles();

  return (
    <Box className={classes.exceedBox}>
      <Typography color="inherit" variant="caption" className={classes.exceedValue}>
        {TOTAL_NOTIFICATION_THRESHOLD}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  badgeRoot: {
    width: props => (props.isSingleDigit ? 20 : 24),
    height: 20,
    borderRadius: props => (props.isSingleDigit ? "50%" : 9.5),
    color: theme.palette.white,
  },
  exceedBox: {
    display: "flex",
    color: theme.palette.white,
  },
  exceedValue: {
    paddingTop: 1,
  },
  anchorBadge: {
    top: -4,
    right: -3,
  },
}));

export default memo(NotifyBadge);
