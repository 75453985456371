import React, { memo, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, InputAdornment, IconButton, Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import DropDown from "./DropDown";
import { ArrowDropDownOutlined } from "@material-ui/icons";

const InputDropDownNeked = forwardRef((props, ref) => {
  const { icon, onChange, className, readOnly, data, onSelect, disabled, title, ...otherProps } = props;
  const classes = useStyles();
  const onTyping = event => {
    if (readOnly) {
      return;
    }
    if (Boolean(onChange)) {
      onChange(event.target.value, event);
    }
  };

  const [inputState, setInputState] = useState("0");
  const handlerChange = event => {
    setInputState(event.currentTarget.value);
    if (Boolean(onSelect)) {
      onSelect(event.currentTarget.value);
    }
    onClose();
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onClick = event => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  function generate(arr, value) {
    return (
      <RadioGroup value={value} onChange={handlerChange}>
        {arr.map((value, index) => (
          <FormControlLabel
            className={classes.inputSelect}
            key={index}
            value={`${index}`}
            label={value}
            control={<Radio />}
          ></FormControlLabel>
        ))}
      </RadioGroup>
    );
  }

  return (
    <Box>
      <InputBase
        fullWidth
        type="text"
        onChange={onTyping}
        onClick={onClick}
        classes={{
          input: classes.input,
          root: `${classes.root} ${className}`,
        }}
        value={data[inputState] ? data[inputState] : ""}
        startAdornment={
          <InputAdornment position="start">
            <IconButton className={classes.icon}>{icon}</IconButton>
          </InputAdornment>
        }
        endAdornment={
          <IconButton edge="end" className={classes.secondaryIcon}>
            <ArrowDropDownOutlined color="inherit" />
          </IconButton>
        }
        inputProps={{ "aria-label": "naked" }}
        ref={ref}
        {...otherProps}
      ></InputBase>
      <DropDown title={title} anchorEl={anchorEl} onClose={onClose}>
        {generate(data, inputState)}
      </DropDown>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  root: {
    height: "35px",
    paddingLeft: 0,
    borderRadius: "unset",
    marginBottom: "8px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.57,
    color: "#3b404c",
  },
  input: { padding: "16px 0px" },
  inputSelect: {
    paddingLeft: "8px",
  },
  icon: {
    width: "50px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "50px",
    justifyContent: "center",
    marginRight: 0,
  },
  secondaryIcon: {
    color: theme.palette.grey[400],
    padding: 16,
  },
}));

InputDropDownNeked.propTypes = {
  icon: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  data: PropTypes.array,
  title: PropTypes.string,
};
InputDropDownNeked.defaultProps = { placeholder: "", className: "", data: [], title: "" };

export default memo(InputDropDownNeked);
