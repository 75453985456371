import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Stepper as MuiStepper,
  Step as MuiStep,
  useTheme,
  StepLabel,
  useMediaQuery,
  Box,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import StatusButton from "./StatusButton";
import StatusIcon from "./StatusIcon";
import { createStyleStatus } from "utils";
import clsx from "clsx";
import { format } from "date-fns";
import { EditOutlined } from "@material-ui/icons";

const CustomStepper = ({
  status: currentStatus,
  selectedStatus,
  onChange,
  classes,
  realStartTime,
  realEndTime,
  setIsOpenRealStartTimeDialog,
  setIsOpenRealEndTimeDialog,
  isHasPreOrder,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const { t: getLabel } = useTranslation();

  let statusArray = isHasPreOrder
    ? [...STT_RESERVATION_WITH_INDEX]
    : [
        ...[...STT_RESERVATION_WITH_INDEX].filter(
          status => status.value !== AppConstant.STT_RESERVATION.waitingPayment,
        ),
      ];

  let currentSelected = [...STT_RESERVATION_WITH_INDEX].find(entry => entry.value === selectedStatus);
  let currentStatusStep = [...STT_RESERVATION_WITH_INDEX].find(entry => entry.value === currentStatus);
  let arrayStatusLength = isHasPreOrder ? 6 : 5;

  const steps = statusArray.slice(0, arrayStatusLength).map((entry, index) => {
    let sttValue = entry.value;
    let sttStyle = createStyleStatus(theme.palette.reservation, sttValue);

    let isNext = statusArray.indexOf(currentStatusStep) + 1 === index;
    let isActive = index === statusArray.indexOf(currentSelected) || index === statusArray.indexOf(currentStatusStep);
    let isPassed = index < statusArray.indexOf(currentStatusStep);

    const isDone =
      selectedStatus === AppConstant.STT_RESERVATION.finished || selectedStatus > AppConstant.STT_RESERVATION.active;

    const isShowFinishedButtonMobile =
      isDone &&
      sttValue === AppConstant.STT_RESERVATION.finished &&
      isMobile &&
      AppConstant.STT_RESERVATION.cancel > selectedStatus;

    return (
      <Step key={entry.value}>
        <StepLabel
          StepIconComponent={() => (
            <StatusIcon
              isShowFinishedButtonMobile={isShowFinishedButtonMobile}
              isActive={isActive}
              color={sttStyle.color}
              sttValue={sttValue}
              currentStatus={currentStatus}
              isMobile={isMobile}
            >
              {index + 1}
            </StatusIcon>
          )}
        >
          <StatusButton
            isShowFinishedButtonMobile={isShowFinishedButtonMobile}
            disabled={isPassed}
            isNext={isNext}
            isActive={isActive}
            color={sttStyle.color}
            onClick={() => onChange(sttValue)}
            realEndTime={realEndTime}
            realStartTime={realStartTime}
          >
            {getLabel(LangConstant.ARR_STATUS_RESERVATION[sttValue - 1])}
          </StatusButton>

          {isShowFinishedButtonMobile && AppConstant.STT_RESERVATION.cancel > (selectedStatus || currentStatus) && (
            <Box className={classes.startEndTimeInputMobile}>
              <Box
                className={clsx(classes.startEndTimeBoxRoot, isDone && classes.endTimeBox)}
                onClick={() => {
                  currentStatus > AppConstant.STT_RESERVATION.active && setIsOpenRealEndTimeDialog(true);
                }}
              >
                <Typography className={classes.realTimeHourFormat}>
                  {isDone && format(realEndTime, AppConstant.FM_HH_MM)}
                </Typography>
                <Typography className={classes.realTimeDateFormat}>
                  {isDone && format(realEndTime, AppConstant.FM_DD_MM_YYYY)}
                </Typography>
                <EditOutlined className={classes.editIcon} />
              </Box>
            </Box>
          )}

          {sttValue === AppConstant.STT_RESERVATION.seated &&
            isMobile &&
            selectedStatus > AppConstant.STT_RESERVATION.confirm &&
            AppConstant.STT_RESERVATION.cancel > selectedStatus && (
              <Box className={classes.startEndTimeInputMobile}>
                <Box
                  className={clsx(classes.startEndTimeBoxRoot, classes.startTimeBox)}
                  onClick={() => setIsOpenRealStartTimeDialog(true)}
                >
                  <Typography className={classes.realTimeHourFormat}>
                    {format(realStartTime, AppConstant.FM_HH_MM)}
                  </Typography>
                  <Typography className={classes.realTimeDateFormat}>
                    {format(realStartTime, AppConstant.FM_DD_MM_YYYY)}
                  </Typography>
                  <EditOutlined className={classes.editIcon} />
                </Box>
                <Box className={classes.startEndTimeConnector}></Box>
              </Box>
            )}
        </StepLabel>
      </Step>
    );
  });

  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  let stepProps = { alternativeLabel: isDesktop, orientation: isDesktop ? "horizontal" : "vertical" };

  return (
    <Stepper activeStep={currentStatus - 1} {...stepProps}>
      {steps}
    </Stepper>
  );
};

CustomStepper.propTypes = {
  status: PropTypes.number,
  onChange: PropTypes.func,
};
CustomStepper.defaultProps = { status: AppConstant.STT_RESERVATION.pending };

export const STT_RESERVATION_WITH_INDEX = [
  { key: "pending", value: 1 },
  { key: " waitingPayment", value: 8 },
  { key: "confirm", value: 2 },
  { key: "seated", value: 3 },
  { key: " active", value: 4 },
  { key: "finished", value: 5 },
  { key: " cancel", value: 6 },
  { key: "reject", value: 7 },
];

export default memo(CustomStepper);

const Stepper = withStyles({
  root: {
    padding: "10px 0",
  },
})(MuiStepper);

const Step = withStyles({
  root: {
    padding: 0,
  },
})(MuiStep);
