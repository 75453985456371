import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, DialogContent, DialogActions, Button, OutlinedInput, Box } from "@material-ui/core";
import { LangConstant } from "../../../../../../const";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import DialogLayout from "../DialogLayout";

const EditDialog = props => {
  const { titleName, data, onClose, onSubmit, isAdd } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const initData = isAdd ? { name: "", capacity: "" } : data;
  const [inputData, setInputData] = React.useState(initData);

  const onChangeInputData = event => {
    if (event.target.name === "capacity") {
      const inputValue = parseInt(event.target.value);
      if (!isNaN(inputValue)) {
        setInputData({ ...inputData, [event.target.name]: inputValue });
      } else {
        setInputData({ ...inputData, [event.target.name]: 0 });
      }
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }
  };

  return (
    <DialogLayout onClose={onClose} titleName={titleName}>
      <form>
        <DialogContent className={classes.dialogContent}>
          <ContentBlock
            text={inputData.name}
            label={getLabel(LangConstant.L_EDIT_DIALOG_NAME)}
            placeholder={getLabel(LangConstant.P_EDIT_DIALOG_NAME)}
            inputProps={{
              name: "name",
              onChange: onChangeInputData,
            }}
          />
          <ContentBlock
            text={inputData.capacity}
            label={getLabel(LangConstant.L_EDIT_DIALOG_CAPACITY)}
            placeholder={getLabel(LangConstant.P_EDIT_DIALOG_CAPACITY)}
            style={{ width: 150 }}
            inputProps={{
              name: "capacity",
              onChange: onChangeInputData,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={e => onSubmit(e, inputData)}
          >
            <Typography variant="body2" classes={{ body2: "medium-md-txt" }} style={{ color: theme.palette.white }}>
              {getLabel(LangConstant.TXT_SAVE_BUTTON)}
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};

EditDialog.propTypes = {
  titleName: PropTypes.string,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isAdd: PropTypes.bool,
};

EditDialog.defaultProps = { isAdd: false };

const ContentBlock = props => {
  const { text, label, placeholder, style, inputProps } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.contentItem} style={style}>
      <Typography variant="body2" classes={{ body2: "medium-md-txt" }} style={{ color: theme.palette.grey[600] }}>
        {label}
      </Typography>
      <OutlinedInput
        value={text}
        placeholder={placeholder}
        className={classes.outlineInput}
        inputProps={{ classes: { input: `${classes.input} regular-md-txt` }, style: { textAlign: "center" } }}
        {...inputProps}
        required
      ></OutlinedInput>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: "flex",
    alignItems: "center",
    borderBottom: `solid 0.5px ${theme.palette.grey[300]}`,
    padding: "30px 30px",
  },
  dialogAction: {
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    width: 73,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    minHeight: "unset",
    padding: 0,
  },
  textButton: {},
  contentItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  outlineInput: {
    borderRadius: "0%",
    marginTop: 21,
    height: 40,
    marginLeft: 5,
    color: theme.palette.grey[600],
  },
  input: {
    textAlign: "center",
    "&::placeholder": {
      opacity: 1,
    },
    color: theme.palette.grey[400],
  },
}));

export default memo(EditDialog);
