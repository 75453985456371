import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestGetShopInfo: ["data"],
  getShopInfoSuccess: ["data"],
  getShopInfoFailure: ["data"],

  requestUpdateShopInfo: ["data"],
  updateShopInfoSuccess: ["data"],
  updateShopInfoFailure: ["data"],

  requestUploadShopImage: ["data"],
  uploadShopImageSuccess: ["data"],
  uploadShopImageFailure: ["data"],

  requestGetFacebookPage: ["data"],
  getFacebookPageSuccess: ["data"],
  getFacebookPageFailure: ["data"],

  requestPutFacebookLink: ["data"],
  putFacebookLinkSuccess: ["data"],
  putFacebookLinkFailure: ["data"],
});

export const ShopTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  dataShop: null,
  //update
  isUpdateFetching: false,
  errorUpdate: null,
  isUpdateSuccess: false,
  //upload
  isUploadFetching: false,
  errorUpload: null,
  isUploadSuccess: false,

  //facebook
  facebookPage: [],
  isLoggedIn: false,
  isGetFacebookFetching: false,
  isGetFacebookSuccess: false,
  errorGetFacebook: null,

  isPutFacebookLinkFetching: false,
  isPutFacebookLinkSuccess: false,
  errorPutFacebookLink: null,
};

/* ------------- Reducers ------------- */
export const requestGetShopInfo = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getShopInfoSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    dataShop: action.data,
  };
};

export const getShopInfoFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isisSuccess: false,
  error: action.error,
});

/* ------------- Reducers ------------- */
export const requestUpdateShopInfo = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updateShopInfoSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isUpdateSuccess: true,
    dataShop: action.data,
  };
};

export const updateShopInfoFailure = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: false,
  isUpdateSuccess: false,
  // errorUpdate: action.error,
});

/* ------------- Reducers ------------- */
export const requestUploadShopImage = (state = INITIAL_STATE) => ({
  ...state,
  isUploadFetching: true,
});

export const uploadShopImageSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isUpLoadFetching: false,
    isUploadSuccess: true,
  };
};

export const uploadShopImageFailure = (state = INITIAL_STATE) => ({
  ...state,
  isUploadFetching: false,
  isUploadSuccess: false,
  // errorUpdate: action.error,
});

export const requestGetFacebookPage = (state = INITIAL_STATE) => ({
  ...state,
  isGetFacebookFetching: true,
  isGetFacebookSuccess: false,
  errorGetFacebook: null,
});

export const getFacebookPageSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    facebookPage: action.data.data,
    isGetFacebookFetching: false,
    isGetFacebookSuccess: true,
    errorGetFacebook: null,
    isLoggedIn: action.data.isLogged,
  };
};

export const getFacebookPageFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetFacebookFetching: false,
  isGetFacebookSuccess: false,
  errorGetFacebook: action.error,
});

export const requestPutFacebookLink = (state = INITIAL_STATE) => ({
  ...state,
  isPutFacebookLinkFetching: true,
  isPutFacebookLinkSuccess: false,
});

export const putFacebookLinkSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isPutFacebookLinkFetching: false,
  isPutFacebookLinkSuccess: true,
});

export const putFacebookLinkFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPutFacebookLinkFetching: false,
  isPutFacebookLinkSuccess: false,
  errorPutFacebookLink: action.error,
});
/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_GET_SHOP_INFO]: requestGetShopInfo,
  [Types.GET_SHOP_INFO_SUCCESS]: getShopInfoSuccess,
  [Types.GET_SHOP_INFO_FAILURE]: getShopInfoFailure,
  [Types.REQUEST_UPDATE_SHOP_INFO]: requestUpdateShopInfo,
  [Types.UPDATE_SHOP_INFO_SUCCESS]: updateShopInfoSuccess,
  [Types.UPDATE_SHOP_INFO_FAILURE]: updateShopInfoFailure,
  [Types.REQUEST_UPLOAD_SHOP_IMAGE]: requestUploadShopImage,
  [Types.UPLOAD_SHOP_IMAGE_SUCCESS]: uploadShopImageSuccess,
  [Types.UPLOAD_SHOP_IMAGE_FAILURE]: uploadShopImageFailure,

  [Types.REQUEST_GET_FACEBOOK_PAGE]: requestGetFacebookPage,
  [Types.GET_FACEBOOK_PAGE_SUCCESS]: getFacebookPageSuccess,
  [Types.GET_FACEBOOK_PAGE_FAILURE]: getFacebookPageFailure,
  [Types.REQUEST_PUT_FACEBOOK_LINK]: requestPutFacebookLink,
  [Types.PUT_FACEBOOK_LINK_SUCCESS]: putFacebookLinkSuccess,
  [Types.PUT_FACEBOOK_LINK_FAILURE]: putFacebookLinkFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
