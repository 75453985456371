import React, { memo } from "react";
import { makeStyles, Popover, Button, Box, Typography, Paper } from "@material-ui/core";
import { LangConstant } from "../../../../const";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import MenuBodyInvite from "./MenuBodyInvite";
import { useDispatch } from "react-redux";
import StaffTableAction from "../../../../redux/staff.redux";

const PopupSendInvite = ({ open, setOpen, data }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (index, indexEmail) => {
    let newData = data;
    newData[indexEmail].role = index;
  };

  const onSendInvite = () => {
    dispatch(StaffTableAction.patchInvitationStaff({ staffs: data }));
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      anchorEl={document.body}
      anchorPosition={{ left: 0, top: 0 }}
      marginThreshold={0}
      classes={{ paper: classes.PaperProps }}
    >
      <Paper elevation={1} square classes={{ root: classes.rootPaper, elevation1: classes.elevation1 }}>
        <Box className={classes.boxCloseParent}>
          <Box className={classes.boxCloseChildren}>
            <Button
              onClick={() => onClose()}
              classes={{
                root: classes.rootButton,
                label: classes.label,
                startIcon: classes.startIcon,
                iconSizeMedium: classes.iconSizeMedium,
              }}
              startIcon={<Close className={classes.rootIcon} />}
            />
          </Box>
        </Box>
        <Box className={classes.boxBodyParent}>
          <Box className={classes.boxBodyChildren}>
            <Box className={classes.boxTitle}>
              <Typography variant="h5">{getLabel(LangConstant.FN_NOTI_INVITE_STAFF)(data.length)}</Typography>
            </Box>
            <Box className={classes.boxContentParent}>
              <Box className={classes.boxContentChildren}>
                {data.map((result, index) => (
                  <Box className={classes.boxList} key={index}>
                    <Typography className={classes.titleList}>{result.email}</Typography>
                    <Box className={classes.boxMenu}>
                      <MenuBodyInvite cellData={result.role} indexEmail={index} onChange={onChange} />
                    </Box>
                  </Box>
                ))}
                <Box className={classes.boxBtn}>
                  <Button
                    className={classes.rootBtnEmail}
                    onClick={onSendInvite}
                    color="primary"
                    size="medium"
                    variant="contained"
                  >
                    {getLabel(LangConstant.TXT_SEND_INVITATION)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Popover>
  );
};
const useStyles = makeStyles(theme => ({
  PaperProps: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
  },
  rootPaper: {
    height: "70%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  elevation1: {
    boxShadow: "none",
  },
  boxCloseParent: {
    marginRight: 0,
    height: 50,
    marginTop: 20,
    maxWidth: "100%",
  },
  boxCloseChildren: {
    height: "100%",
    width: "20%",
    marginLeft: "80%",
  },
  boxBodyParent: {
    width: "100%",
    height: "100%",
  },
  boxBodyChildren: {
    height: "100%",
    width: "90%",
    margin: "auto",
  },
  boxTitle: {
    marginTop: 200,
    width: 460,
    marginLeft: "auto",
    marginRight: "auto",
    "@media only screen and (max-width: 600px)": {
      marginTop: 50,
      width: "100%",
      textAlign: "center",
    },
  },
  rootButton: {
    width: 50,
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: 20,
  },
  label: {
    height: "100%",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },
  iconSizeMedium: {
    fontSize: 30,
  },
  boxContentParent: {
    marginTop: 50,
    "@media only screen and (max-width: 600px)": {
      marginTop: 38,
    },
  },
  boxContentChildren: {
    width: 460,
    margin: "auto",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      textAlign: "center",
    },
  },
  boxList: {
    display: "flex",
    height: 70,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  titleList: {
    marginTop: "auto",
    marginBottom: "auto",
    fontSize: 14,
    color: "#3e4045",
  },
  rootBtnEmail: {
    marginTop: 50,
    padding: "0 16px 0",
    minHeight: 46,
    fontWeight: "inherit",
    borderRadius: 25,
    minWidth: 152,
    textTransform: "capitalize",
    color: "#ffffff",
    marginLeft: "auto",
    marginRight: "auto",
    "@media only screen and (max-width: 600px)": {
      maxWidth: 160,
      minHeight: 40,
      borderRadius: 20,
    },
  },
  boxBtn: {
    display: "flex",
    height: 70,
  },
  boxMenu: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
}));
export default memo(PopupSendInvite);
