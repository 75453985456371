import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const getDetails = data => createApiReservation().get(ApiConstant.GET_DETAILS, data);

export const getDetailsUid = data => createApiReservation().get(format(ApiConstant.GET_DETAILS_UID, data.uid), {});

export const createDetails = data => createApiReservation().post(ApiConstant.POST_DETAILS, data);

export const updateDetails = data => createApiReservation().put(format(ApiConstant.PUSH_DETAILS, data.uid), data);

export const updateDetailsEditContent = data =>
  createApiReservation().put(format(ApiConstant.PUSH_DETAILS_EDIT_CONTENT, data.uid), data.data);

export const removeDetails = data => createApiReservation().delete(format(ApiConstant.DELETE_DETAILS, data.uid), {});

export const getDetailsContent = data => createApiReservation().get(ApiConstant.GET_DETAILS_CONTENT, data);

export const getDetailsContentUid = data =>
  createApiReservation().get(format(ApiConstant.GET_DETAILS_CONTENT_UID, data.uid), {});

export const createDetailsContent = data => createApiReservation().post(ApiConstant.POST_DETAILS_CONTENT, data);

export const updateDetailsContent = data =>
  createApiReservation().put(format(ApiConstant.PUSH_DETAILS_CONTENT, data.uid), data);

export const removeDetailsContent = data =>
  createApiReservation().delete(format(ApiConstant.DELETE_DETAILS_CONTENT, data.uid), {});
