import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button, CardMedia, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ImageConstant } from "const";

const SelectOptionButton = ({ data, onClick, isSelected, classes, ...otherProps }) => {
  const defaultClasses = useStyles();

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onClick}
      classes={{
        root: clsx(isSelected && defaultClasses.selectedRoot, classes?.buttonRoot, defaultClasses.root),
        outlinedPrimary: clsx(defaultClasses.text, "medium-md-txt"),
      }}
      {...otherProps}
    >
      {isSelected && (
        <>
          <CardMedia component="img" className={defaultClasses.rectangleImage} src={ImageConstant.RectangleImage} />
          <CardMedia component="img" className={defaultClasses.checkIcon} src={ImageConstant.CheckIcon} />
        </>
      )}
      {data.name}
    </Button>
  );
};

SelectOptionButton.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.shape({
    buttonRoot: PropTypes.string,
  }),
  isSelected: PropTypes.bool,

  onClick: PropTypes.func,
};
SelectOptionButton.defaultProps = {
  data: {},
  isSelected: false,

  onClick: () => {},
};

export default memo(SelectOptionButton);

const useStyles = makeStyles(theme => ({
  root: {
    width: "fit-content",
    height: 36,
    padding: "7px 24px",
    textAlign: "center",
    textTransform: "none",
    borderRadius: 4,
    border: "1px solid " + theme.palette.grey[600],
    boxShadow: "0px 1px 4px rgba(62, 64, 69, 0.08)",
    backgroundColor: theme.palette.white,
    "&:not(:last-child)": {
      marginRight: 5,
    },
    "&:disabled": {
      border: 0,
      opacity: 0.4,
      color: theme.palette.grey[800],
    },
  },

  selectedRoot: {
    position: "relative",
    border: "1px solid" + theme.palette.primary.main,
  },

  text: {
    color: theme.palette.text.primary,
  },

  rectangleImage: {
    position: "absolute",
    width: 19,
    height: 19,
    bottom: -1,
    right: -1,
  },

  checkIcon: {
    position: "absolute",
    width: 6,
    height: 5,
    bottom: 3,
    right: 3,
  },
}));
