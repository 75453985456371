import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  postServiceItem: ["data"],
  postServiceItemSuccess: ["data"],
  postServiceItemFailure: ["data"],

  putServiceItem: ["data"],
  putServiceItemSuccess: ["data"],
  putServiceItemFailure: ["data"],

  getServiceItem: ["data"],
  getServiceItemSuccess: ["data"],
  getServiceItemFailure: ["data"],

  getListServiceItem: ["data"],
  getListServiceItemSuccess: ["data"],
  getListServiceItemFailure: ["data"],

  getCategoryServiceItem: ["data"],
  getCategoryServiceItemSuccess: ["data"],
  getCategoryServiceItemFailure: ["data"],

  deleteServiceItem: ["data"],
  deleteServiceItemSuccess: ["data"],
  deleteServiceItemFailure: ["data"],

  resetServiceItem: ["data"],
  resetErrorServiceItem: ["data"],
});

export const ServiceItemTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  // post
  isPostServiceItemFetching: false,
  errorPostServiceItem: null,
  isPostServiceItemSuccess: false,
  dataPostServiceItem: null,

  // put
  isPutServiceItemFetching: false,
  errorPutServiceItem: null,
  isPutServiceItemSuccess: false,
  dataPutServiceItem: null,

  // get by Uid
  isGetServiceItemFetching: false,
  errorGetServiceItem: null,
  isGetServiceItemSuccess: false,
  dataGetServiceItem: null,

  // get by category uid
  isGetCategoryServiceItemFetching: false,
  errorGetCategoryServiceItem: null,
  isGetCategoryServiceItemSuccess: false,
  dataCategoryServiceItem: null,

  // get list
  isGetListServiceItemFetching: false,
  errorGetListServiceItem: null,
  isGetListServiceItemSuccess: false,
  dataGetListServiceItem: null,

  // delete
  isDeleteServiceItemFetching: false,
  errorDeleteServiceItem: null,
  isDeleteServiceItemSuccess: false,
  dataDeleteServiceItem: null,
};

/* ------------- Reducers ------------- */
/* ------------- Reducers Post Service Item ------------- */
export const postServiceItem = (state = INITIAL_STATE) => ({
  ...state,
  isPostServiceItemFetching: true,
  errorPostServiceItem: null,
});

export const postServiceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPostServiceItemFetching: false,
    isPostServiceItemSuccess: true,
    dataPostServiceItem: action.data,
  };
};

export const postServiceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPostServiceItemFetching: false,
  isPostServiceItemSuccess: false,
  errorPostServiceItem: action?.data?.message,
});

/* ------------- Reducers Put Service Item ------------- */
export const putServiceItem = (state = INITIAL_STATE) => ({
  ...state,
  isPutServiceItemFetching: true,
  errorPutServiceItem: null,
});

export const putServiceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPutServiceItemFetching: false,
    isPutServiceItemSuccess: true,
    dataPutServiceItem: action.data,
  };
};

export const putServiceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPutServiceItemFetching: false,
  isPutServiceItemSuccess: false,
  errorPutServiceItem: action?.data?.message,
});

/* ------------- Reducers Get By Uid Service Item ------------- */
export const getServiceItem = (state = INITIAL_STATE) => ({
  ...state,
  isGetServiceItemFetching: true,
});

export const getServiceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetServiceItemFetching: false,
    isGetServiceItemSuccess: true,
    dataGetServiceItem: action.data,
  };
};

export const getServiceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetServiceItemFetching: false,
  isGetServiceItemSuccess: false,
  errorGetServiceItem: action.error,
});

/* ------------- Reducers Get By Category uid Service Item ------------- */
export const getCategoryServiceItem = (state = INITIAL_STATE) => ({
  ...state,
  isGetCategoryServiceItemFetching: true,
});

export const getCategoryServiceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetCategoryServiceItemFetching: false,
    isGetCategoryServiceItemSuccess: true,
    dataCategoryServiceItem: action?.data,
  };
};

export const getCategoryServiceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetCategoryServiceItemFetching: false,
  isGetCategoryServiceItemSuccess: false,
  errorGetCategoryServiceItem: action.error,
});

/* ------------- Reducers Get List Service Item ------------- */
export const getListServiceItem = (state = INITIAL_STATE) => ({
  ...state,
  isGetListServiceItemFetching: true,
});

export const getListServiceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetListServiceItemFetching: false,
    isGetListServiceItemSuccess: true,
    dataGetListServiceItem: action.data,
  };
};

export const getListServiceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetListServiceItemFetching: false,
  isGetListServiceItemSuccess: false,
  errorGetListServiceItem: action.error,
});

/* ------------- Reducers Delete Service Item ------------- */
export const deleteServiceItem = (state = INITIAL_STATE) => ({
  ...state,
  isDeleteServiceItemFetching: true,
});

export const deleteServiceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isDeleteServiceItemFetching: false,
    isDeleteServiceItemSuccess: true,
    dataDeleteServiceItem: action.data,
  };
};

export const deleteServiceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isDeleteServiceItemFetching: false,
  isDeleteServiceItemSuccess: false,
  errorDeleteServiceItem: action.error,
});

/* ------------- Reducers Reset ------------- */
export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isPostServiceItemSuccess: false,
  isPutServiceItemSuccess: false,
  isGetServiceItemSuccess: false,
  isGetListServiceItemSuccess: false,
  isDeleteServiceItemSuccess: false,
  isGetCategoryServiceItemSuccess: false,
});

export const resetError = (state = INITIAL_STATE) => ({
  ...state,
  errorPostServiceItem: null,
  errorPutServiceItem: null,
});
/* ------------- Mapping ------------- */
export const HANDLERS = {
  // Map Post Service Item
  [Types.POST_SERVICE_ITEM]: postServiceItem,
  [Types.POST_SERVICE_ITEM_SUCCESS]: postServiceItemSuccess,
  [Types.POST_SERVICE_ITEM_FAILURE]: postServiceItemFailure,

  // Map Put Service Item
  [Types.PUT_SERVICE_ITEM]: putServiceItem,
  [Types.PUT_SERVICE_ITEM_SUCCESS]: putServiceItemSuccess,
  [Types.PUT_SERVICE_ITEM_FAILURE]: putServiceItemFailure,

  // Map Get By Uid Service Item
  [Types.GET_SERVICE_ITEM]: getServiceItem,
  [Types.GET_SERVICE_ITEM_SUCCESS]: getServiceItemSuccess,
  [Types.GET_SERVICE_ITEM_FAILURE]: getServiceItemFailure,

  // Map Get Service Item By Category Uid
  [Types.GET_CATEGORY_SERVICE_ITEM]: getCategoryServiceItem,
  [Types.GET_CATEGORY_SERVICE_ITEM_SUCCESS]: getCategoryServiceItemSuccess,
  [Types.GET_CATEGORY_SERVICE_ITEM_FAILURE]: getCategoryServiceItemFailure,

  // Map Get List Service Item
  [Types.GET_LIST_SERVICE_ITEM]: getListServiceItem,
  [Types.GET_LIST_SERVICE_ITEM_SUCCESS]: getListServiceItemSuccess,
  [Types.GET_LIST_SERVICE_ITEM_FAILURE]: getListServiceItemFailure,

  // Map Delete Service Item
  [Types.DELETE_SERVICE_ITEM]: deleteServiceItem,
  [Types.DELETE_SERVICE_ITEM_SUCCESS]: deleteServiceItemSuccess,
  [Types.DELETE_SERVICE_ITEM_FAILURE]: deleteServiceItemFailure,

  // Reset Service Item
  [Types.RESET_SERVICE_ITEM]: reset,
  [Types.RESET_ERROR_SERVICE_ITEM]: resetError,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
