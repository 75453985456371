import React, { memo } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import BookingOrderTable from "./components/BookingOrderTable";
import FilterMenu from "./components/FilterMenu";
import BookingOrderPagination from "./components/BookingOrderPagination";
import { BglobalJSC } from "components";

const MnOrder = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Paper className={classes.bookingHistoryPaper}>
        <FilterMenu />
        <BookingOrderTable />
        <BookingOrderPagination />
      </Paper>
      <BglobalJSC className={classes.boxJSC} />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  bookingHistoryPaper: {
    width: "100%",
    padding: 16,
    height: "fit-content",
  },
  boxJSC: {
    marginTop: "auto",
    marginBottom: 24,
  },
}));
export default memo(MnOrder);
