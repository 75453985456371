import React, { memo, useState } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { BglobalJSC } from "components";
import { SwitchMProductItem } from "components/mn-master-product";
import { MainServiceAll, MainServiceCategory, AccompaniedServiceAll, AccompaniedServiceCategory } from "./components";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const MnService = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const serviceModeArr = [
    { name: getLabel(LangConstant.L_MAIN_SERVICE), id: "main" },
    { name: getLabel(LangConstant.L_ATTACHED_SERVICE), id: "attached" },
  ];
  const childServiceModeArr = [
    { name: getLabel(LangConstant.L_All_SERVICE), id: "all" },
    { name: getLabel(LangConstant.L_CATEGORY_SERVICE), id: "category" },
  ];

  const [serviceModeId, setServiceModeId] = useState(serviceModeArr[0]?.id);
  const [childServiceModeId, setChildServiceModeId] = useState(childServiceModeArr[0]?.id);

  const onSwitchTab = event => {
    const newServiceModeId = event.currentTarget.id;
    if (newServiceModeId != serviceModeId) {
      setServiceModeId(newServiceModeId);
      setChildServiceModeId(childServiceModeArr[0]?.id);
    }
  };

  const onChangeMode = event => {
    const newChildServiceModeId = event.currentTarget.id;
    if (newChildServiceModeId != childServiceModeId) {
      setChildServiceModeId(newChildServiceModeId);
    }
  };

  const checkTabAccompaniedServiceComponent = (childServiceModeId, onChangeMode) => {
    switch (childServiceModeId) {
      case childServiceModeArr[0].id:
        return (
          <AccompaniedServiceAll
            childServiceModeId={childServiceModeId}
            childServiceModeArr={childServiceModeArr}
            onChangeMode={onChangeMode}
          />
        );
      case childServiceModeArr[1].id:
        return (
          <AccompaniedServiceCategory
            childServiceModeId={childServiceModeId}
            childServiceModeArr={childServiceModeArr}
            onChangeMode={onChangeMode}
          />
        );
    }
  };

  const checkTabMainServiceComponent = (childServiceModeId, onChangeMode) => {
    switch (childServiceModeId) {
      case childServiceModeArr[0].id:
        return (
          <MainServiceAll
            childServiceModeId={childServiceModeId}
            childServiceModeArr={childServiceModeArr}
            onChangeMode={onChangeMode}
          />
        );
      case childServiceModeArr[1].id:
        return (
          <MainServiceCategory
            childServiceModeId={childServiceModeId}
            childServiceModeArr={childServiceModeArr}
            onChangeMode={onChangeMode}
          />
        );
    }
  };

  return (
    <Box>
      <Paper elevation={1} square className={classes.container}>
        <SwitchMProductItem itemInfos={serviceModeArr} onTabClick={onSwitchTab} selectedId={serviceModeId} />
        {serviceModeArr[0].id == serviceModeId && checkTabMainServiceComponent(childServiceModeId, onChangeMode)}
        {serviceModeArr[1].id == serviceModeId && checkTabAccompaniedServiceComponent(childServiceModeId, onChangeMode)}
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};

export default memo(MnService);

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    margin: "0 10px",
    minHeight: "calc(100vh - 200px)",
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));
