import React, { memo, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { MailOutline, EditOutlined } from "@material-ui/icons";
import { InputShopInfo } from "components/mnbooking";
import { AntSwitchPrimary } from "./CustomizeSwitch";
import { DialogEmail } from "./DialogNotificationSetting/index";
import { LangConstant } from "const";
import { OBJECT_TYPE } from "../index";

const NotificationEmail = ({ data, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_NOTIFICATION_SETTING);

  const [dataEmailNotification, setDataEmailNotification] = useState(data.emailSetting || {});
  const [arrEmail, setArrEmail] = useState(data.emailSettingList || []);
  const [isShowAdd, setIsShowAdd] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [indexEmail, setIndexEmail] = useState(null);

  const onAddEmail = () => {
    setIsShowAdd(true);
  };

  const onEditEmail = index => {
    setEmail(arrEmail[index]);
    setIndexEmail(index);
    setIsShowEdit(true);
  };

  const onClose = () => {
    setIsShowAdd(false);
    setIsShowEdit(false);
    setEmail("");
    setIndexEmail(null);
  };

  const onChangEmail = data => {
    setEmail(data);
  };

  const onSubmitEmail = () => {
    let arr = [...arrEmail];
    if (isShowEdit) {
      arr[indexEmail] = email;
    } else {
      arr.push(email);
    }
    setArrEmail(arr);
    onChange(arr, OBJECT_TYPE.email);
    onClose();
  };

  const onDeleteEmail = () => {
    let arrNew = arrEmail.filter((_, index) => index != indexEmail);
    setArrEmail(arrNew);
    onChange(arrNew, OBJECT_TYPE.email);
    onClose();
  };

  const onChecked = event => {
    let name = event.currentTarget.name;
    let checked = event.currentTarget.checked;
    let dataCheck = {
      ...dataEmailNotification,
      [name]: Number(checked),
    };
    setDataEmailNotification(dataCheck);
    onChange(dataCheck);
  };

  useEffect(() => {
    setDataEmailNotification(data.emailSetting || {});
    setArrEmail(data.emailSettingList || []);
  }, [data]);

  return (
    <Box>
      <Box className={classes.boxHeader}>
        <Grid item xs={12} md={6} container direction="row" justify="space-between">
          <Grid item className={classes.grid}>
            <MailOutline />
            <Typography className={clsx("semiBold-lg-txt", classes.typography)}>
              {getLabel(LangConstant.TXT_NOTIFICATION_EMAIL)}
            </Typography>
          </Grid>
          <Grid item>
            <AntSwitchPrimary
              checked={Boolean(dataEmailNotification.disableAll)}
              name={LangConstant.OBJECT_KEY_NOTIFICATION.disableAll}
              className={classes.toggleGg}
              onChange={onChecked}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={Boolean(dataEmailNotification.disableAll) ? classes.bodyHeader : "hidden"}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} container direction="column">
            <Typography className={clsx("semiBold-sm-txt", classes.typographyEmail)}>
              {getLabel(LangConstant.TXT_NOTIFICATION_GMAIL)}
            </Typography>
          </Grid>
        </Grid>
        {arrEmail.length > 0 &&
          arrEmail.map((item, index) => (
            <Box key={index}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} container direction="column">
                  <Typography className={"medium-sm-txt"}>
                    {item}
                    <IconButton onClick={() => onEditEmail(index)}>
                      <EditOutlined className={classes.editIcon} />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} container direction="column">
            <Button
              classes={{
                root: clsx("medium-sm-txt", classes.btnEmail),
                label: classes.labelEmail,
                text: classes.textEmail,
              }}
              onClick={onAddEmail}
            >
              {getLabel(LangConstant.TXT_ADD_GMAIL)}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.news}
              checkBox
              checked={Boolean(dataEmailNotification.news)}
              title={getLabel(LangConstant.TXT_NOTIFICATION_NEW)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationInfoUpdated}
              checkBox
              checked={Boolean(dataEmailNotification.reservationInfoUpdated)}
              title={getLabel(LangConstant.TXT_INFORMATION_BOOKING_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationNew}
              checkBox
              checked={Boolean(dataEmailNotification.reservationNew)}
              title={getLabel(LangConstant.TXT_NEW_BOOKING)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationStatusUpdated}
              checkBox
              checked={Boolean(dataEmailNotification.reservationStatusUpdated)}
              title={getLabel(LangConstant.TXT_APPOINTMENT_BOOKING_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.remoteOrderNew}
              checkBox
              checked={Boolean(dataEmailNotification.remoteOrderNew)}
              title={getLabel(LangConstant.TXT_NEW_ORDER)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationAssign}
              checkBox
              checked={Boolean(dataEmailNotification.reservationAssign)}
              title={getLabel(LangConstant.TXT_DESIGNATED_STAFF)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.remoteOrderUpdated}
              checkBox
              checked={Boolean(dataEmailNotification.remoteOrderUpdated)}
              title={getLabel(LangConstant.TXT_INFORMATION_ORDER_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.remoteOrderStatusUpdated}
              checkBox
              checked={Boolean(dataEmailNotification.remoteOrderStatusUpdated)}
              title={getLabel(LangConstant.TXT_APPOINTMENT_ORDER_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.packagePayment}
              checkBox
              checked={Boolean(dataEmailNotification.packagePayment)}
              title={getLabel(LangConstant.TXT_NOTICE_OF_PAYMENT_REQUEST)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
      </Box>
      <DialogEmail
        isShowAdd={isShowAdd}
        isShowEdit={isShowEdit}
        onClose={onClose}
        onChange={onChangEmail}
        data={email}
        onSubmit={onSubmitEmail}
        onDelete={onDeleteEmail}
      />
    </Box>
  );
};

export default memo(NotificationEmail);

const useStyles = makeStyles(theme => ({
  boxHeader: {
    padding: "15px 30px 10px 25px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 60px 10px 25px",
    },
  },
  bodyHeader: {
    padding: "0 57px 20px 57px",
  },
  typography: {
    paddingLeft: 10,
    paddingTop: 2,
  },
  toggleGg: {
    marginRight: "0.5vw",
    marginTop: "3px",
    color: "#3e4045",
    width: 30,
  },
  grid: {
    display: "flex",
  },
  editIcon: {
    height: 16,
    width: 16,
    padding: 0,
    color: "#7f838c",
  },
  btnEmail: {
    textTransform: "none",
    color: theme.palette.text.link,
    width: 100,
    height: 30,
  },
  labelEmail: {
    justifyContent: "flex-start",
  },
  textEmail: {
    padding: "6px 0",
  },
  typographyEmail: {
    height: 25,
    paddingTop: 10,
  },
}));
