import React, { memo, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Close, Error } from "@material-ui/icons";
import {
  Box,
  makeStyles,
  InputLabel,
  OutlinedInput,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  FormHelperText,
} from "@material-ui/core";
import clsx from "clsx";
import StringFormat from "string-format";
import ReservationAction from "redux/reservation.redux";
import PlaceItemAction from "redux/place-item.redux";
import ConsumerAction from "redux/consumer.redux";
import { AppConstant, LangConstant } from "const";
import { validatePhone, isBlank, getNSKey } from "utils";

const ShippingInfoDialog = ({ openDialog, orderItemsData, onClose }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef([]);
  const dispatch = useDispatch();
  const [dataBooking, isSuccessNumberBooking] = useSelector(
    ({ consumerRedux }) => [consumerRedux.numberBooking, consumerRedux.isNumberBookingSuccess],
    shallowEqual,
  );
  const shopId = useSelector(({ shopInfoRedux }) => shopInfoRedux.data?.id, shallowEqual);

  const [isClickSave, setIsClickSave] = useState(false);
  const [open, setOpen] = useState(false);
  const [warningCondition, setWarningCondition] = useState(WARNING_DEFAULT);
  const [textLabelOrderNumber, setTextLabelOrderNumber] = useState("");
  const [shippingInfo, setShippingInfo] = useState(getShippingInfo(orderItemsData));

  const onCloseDialog = () => {
    dispatch(PlaceItemAction.resetPlaceAutoFill());
    dispatch(ReservationAction.resetGetAvailableStatus());
    setOpen(false);
    setWarningCondition(WARNING_DEFAULT);
  };

  const onSubmit = event => {
    setIsClickSave(true);
    let isValidPhone = !isBlank(shippingInfo.phoneNumber) && validatePhone(shippingInfo.phoneNumber);
    let isValidName = !isBlank(shippingInfo.name);
    let isExistAddress = !isBlank(shippingInfo.address);
    let warningOpt = { name: !isValidName, phoneNumber: !isValidPhone, address: !isExistAddress };

    let isValid = Object.values(warningOpt).findIndex(condition => true === condition) < 0;
    if (isValid) {
      let orderDetail = {
        ...orderItemsData,
        ...shippingInfo,
        orderDetailsList: orderItemsData?.orderDetailsList || [],
        shopId: orderItemsData.shopId || shopId,
        type: orderItemsData.type || AppConstant.DATA_TYPE.order,
      };
      if (orderItemsData.isUpdatedOrder)
        dispatch(ReservationAction.putRemoteOrderDetails({ details: orderDetail, uid: orderDetail.uid }));
      else dispatch(ReservationAction.createRemoteOrder({ newReservation: orderDetail }));

      dispatch(PlaceItemAction.resetPlaceAutoFill());
      onClose();
      setWarningCondition(WARNING_DEFAULT);
    }
    event.preventDefault();
    setWarningCondition(warningOpt);
  };

  const onChangeDetail = event => {
    let name = event.target.name;
    let value = event.target.value;

    setShippingInfo({ ...shippingInfo, [name]: value });
  };

  const onEnterKey = (event, index) => {
    if (event.keyCode === ENTER_KEY || event.which === ENTER_KEY) {
      index === INDEX_INPUT.internalNote ? inputRef.current[0].focus() : inputRef.current[index + 1].focus();
    }
  };

  const onCreateEventPushRef = (ref, position) => {
    if (inputRef.current[position]?.name === ref?.name) {
      inputRef.current[position] = ref;
    } else if (Boolean(ref)) {
      inputRef.current.push(ref);
    }
  };

  useEffect(() => {
    if (isClickSave) {
      setWarningCondition({
        ...warningCondition,
        name: isBlank(shippingInfo.name),
        address: isBlank(shippingInfo.address),
      });
    }
  }, [shippingInfo]);

  useEffect(() => {
    if (!isBlank(shippingInfo.phoneNumber)) {
      setWarningCondition({ ...warningCondition, phoneNumber: false });
      if (validatePhone(shippingInfo.phoneNumber)) {
        dispatch(
          ConsumerAction.getNumberBookingByPhoneNumber({
            phoneNumber: shippingInfo.phoneNumber,
            type: AppConstant.DATA_TYPE.order,
          }),
        );
      }
      setTextLabelOrderNumber(StringFormat(getLabel(LangConstant.TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS), 0));
    }
  }, [shippingInfo.phoneNumber]);

  useEffect(() => {
    if (isSuccessNumberBooking) {
      setTextLabelOrderNumber(
        StringFormat(getLabel(LangConstant.TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS), dataBooking.count || 0),
      );
      dispatch(ConsumerAction.resetNumberBooking());
    }
  }, [isSuccessNumberBooking]);

  useEffect(() => {
    if (orderItemsData) setShippingInfo(getShippingInfo(orderItemsData));
    setOpen(openDialog);
    setIsClickSave(false);
  }, [orderItemsData]);

  return (
    <Dialog
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
      scroll="paper"
      classes={{
        paperScrollPaper: classes.dialogContainer,
        scrollPaper: classes.dialogAlignment,
        paper: classes.dialogPaper,
        paperWidthFalse: classes.dialogInMobile,
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onCloseDialog}>
        {orderItemsData && orderItemsData.isUpdatedOrder
          ? getLabel(getNSKey(LangConstant.NS_MANAGE_ORDER, LangConstant.TXT_EDIT_SHIPPING_INFO))
          : getLabel(getNSKey(LangConstant.NS_MANAGE_ORDER, LangConstant.TXT_NEW_ORDER_INFOR))}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>
            {getLabel(LangConstant.P_BOOKING_NAME)} <Typography color="primary">&nbsp; &#42;</Typography>
          </InputLabel>
          <Box className={classes.rightErrorAlert}>
            <OutlinedInput
              error={warningCondition.name}
              value={shippingInfo.name}
              classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
              onChange={onChangeDetail}
              onKeyDown={event => onEnterKey(event, INDEX_INPUT.name)}
              required
              fullWidth
              inputProps={{
                name: "name",
                ref: ref => onCreateEventPushRef(ref, INDEX_INPUT.name),
              }}
            />
            {warningCondition.name && (
              <FormHelperText className={classes.boxError}>
                <Error className={classes.iconError} /> {getLabel(LangConstant.TXT_ADD_NEW_BOOKING_WARNING_NAME)}
              </FormHelperText>
            )}
          </Box>
        </Box>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.P_BOOKING_EMAIL)}</InputLabel>
          <OutlinedInput
            value={shippingInfo.email}
            classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
            onChange={onChangeDetail}
            onKeyDown={event => onEnterKey(event, INDEX_INPUT.email)}
            inputProps={{
              ref: ref => onCreateEventPushRef(ref, INDEX_INPUT.email),
              name: "email",
              type: "email",
            }}
          />
        </Box>
        <Box className={classes.addFormInputTelephone}>
          <InputLabel className={classes.inputLabel}>
            {getLabel(LangConstant.P_BOOKING_PHONE)} <Typography color="primary">&nbsp; &#42;</Typography>
          </InputLabel>
          <Box>
            <OutlinedInput
              value={shippingInfo.phoneNumber}
              classes={{
                root: classes.contentLineBefore,
                input: classes.inputEdit,
                disabled: classes.disabled,
              }}
              onChange={onChangeDetail}
              onKeyDown={event => onEnterKey(event, INDEX_INPUT.phoneNumber)}
              required
              error={warningCondition.phoneNumber}
              inputProps={{
                type: "tel",
                name: "phoneNumber",
                ref: ref => onCreateEventPushRef(ref, INDEX_INPUT.phoneNumber),
              }}
            />
            {warningCondition.phoneNumber && (
              <FormHelperText className={classes.boxError}>
                <Error className={classes.iconError} /> {getLabel(LangConstant.TXT_ADD_NEW_BOOKING_WARNING_TELEPHONE)}
              </FormHelperText>
            )}
          </Box>
          <OutlinedInput
            classes={{
              root: classes.contentLineAfter,
              input: classes.inputEdit,
              disabled: classes.disabledNumberBooking,
            }}
            required
            disabled
            value={textLabelOrderNumber}
            placeholder={StringFormat(getLabel(LangConstant.TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS), 0)}
          />
        </Box>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>
            {getLabel(getNSKey(LangConstant.NS_MANAGE_ORDER, LangConstant.TXT_RECEIVER_ADDRESS))}
            <Typography color="primary">&nbsp; &#42;</Typography>
          </InputLabel>
          <Box className={classes.rightErrorAlert}>
            <OutlinedInput
              value={shippingInfo.address}
              classes={{
                root: classes.contentLineEdit,
                input: classes.outlineInput,
                disabled: classes.disabled,
              }}
              name="address"
              onChange={onChangeDetail}
              onKeyDown={event => onEnterKey(event, INDEX_INPUT.address)}
              required
              multiline={true}
              rowsMax={4}
              inputProps={{
                ref: ref => onCreateEventPushRef(ref, INDEX_INPUT.address),
              }}
            />
            {warningCondition.address && (
              <FormHelperText className={classes.boxError}>
                <Error className={classes.iconError} />
                {getLabel(getNSKey(LangConstant.NS_MANAGE_ORDER, LangConstant.TXT_EMPTY_RECEIVER_ADDRESS_WARNING))}
              </FormHelperText>
            )}
          </Box>
        </Box>

        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.TXT_CONSUMER_NOTE)}</InputLabel>
          <OutlinedInput
            value={shippingInfo.note}
            classes={{
              root: classes.outlineInputRoot,
              input: classes.outlineInput,
              disabled: classes.disabled,
            }}
            name="note"
            onChange={onChangeDetail}
            onKeyDown={event => onEnterKey(event, INDEX_INPUT.note)}
            required
            multiline={true}
            rowsMax={4}
            inputProps={{
              ref: ref => onCreateEventPushRef(ref, INDEX_INPUT.note),
            }}
          />
        </Box>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.TXT_INTERNAL_NOTE)}</InputLabel>
          <OutlinedInput
            value={shippingInfo.internalNote}
            classes={{
              root: classes.outlineInputRoot,
              input: classes.outlineInput,
              disabled: classes.disabled,
            }}
            name="internalNote"
            onChange={onChangeDetail}
            onKeyDown={event => onEnterKey(event, INDEX_INPUT.internalNote)}
            required
            multiline={true}
            rowsMax={4}
            inputProps={{
              ref: ref => onCreateEventPushRef(ref, INDEX_INPUT.internalNote),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCloseDialog} className={clsx("medium-md-txt", classes.buttonCancel)}>
          {getLabel(LangConstant.TXT_SHOP_CANCEL)}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          className={clsx("medium-md-txt", classes.buttonSubmit)}
        >
          {getLabel(LangConstant.TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitle = props => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography className={classes.textDialogTitle}>{children}</Typography>
      {Boolean(onClose) && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

ShippingInfoDialog.prototype = {
  openDialog: PropTypes.bool.isRequired,
  orderItemsData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

ShippingInfoDialog.defaultProps = {
  openDialog: false,
  onClose: () => {},
  orderItemsData: {},
};

export default memo(ShippingInfoDialog);

const ENTER_KEY = 13;
const WARNING_DEFAULT = { name: false, phoneNumber: false, address: false };

const INDEX_INPUT = {
  name: 0,
  email: 1,
  phoneNumber: 2,
  address: 3,
  note: 4,
  internalNote: 5,
};

const DEFAULT_ORDER = {
  name: null,
  email: null,
  phoneNumber: null,
  address: null,
  note: null,
  internalNote: null,
};

const getShippingInfo = data => {
  if (data.isUpdatedOrder) {
    return {
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      address: data.address,
      note: data.note,
      internalNote: data.internalNote.content,
    };
  } else return DEFAULT_ORDER;
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginRight: 0,
    },
  },
  dialogAlignment: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "start",
    },
  },
  dialogContainer: {
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 0,
    overflowX: "auto",
  },
  dialogInMobile: {
    maxWidth: "100%",
  },

  dialogTitle: {
    minWidth: 531,
    width: "100%",
    height: 60,
    display: "flex",
    background: "#65b39d",
    color: theme.palette.white,
    padding: "0 0 0 24px",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textDialogTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.white,
  },
  closeButton: {
    height: 20,
    width: 20,
    padding: 0,
    marginRight: 24,
    color: theme.palette.grey[500],
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },

  dialogContent: {
    display: "flex",
    flexDirection: "column",
    minWidth: 531,
    maxHeight: "calc(100vh - 165px)",
    padding: "36px 24px 0",
    "&:first-child": {
      [theme.breakpoints.down("xs")]: {
        padding: "26px 10px 20px",
        borderBottom: "none",
      },
    },
  },
  contentLineEdit: {
    width: "100%",
    height: 30,
    padding: "4px 16px",
    fontSize: 13,
    "& $disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  contentLineBefore: {
    height: 30,
    padding: "4px 16px",
    fontSize: 13,
    "& $disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  contentLineAfter: {
    height: 30,
    marginLeft: 15,
    padding: "4px 16px",
    fontSize: 13,
    "& $disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  outlineInputRoot: {
    width: "60%",
    minHeight: 30,
    maxHeight: 62,
    padding: "4px 16px",
    fontSize: 13,
    "& $disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  outlineInput: {
    maxHeight: 60,
    overflow: "auto",
    margin: "2px 0",
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  disabledNumberBooking: {
    color: "#565c6a",
  },
  inputEdit: {
    padding: 0,
  },
  addFormInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 13,
    marginBottom: 16,
  },
  addFormInputTelephone: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 13,
    marginBottom: 16,
  },
  rightErrorAlert: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  inputLabel: {
    display: "flex",
    minWidth: "40%",
    height: 22,
    fontSize: "inherit",
    lineHeight: 1.69,
    color: "#565c6a",
  },
  icon: {
    width: 24,
    height: 24,
    alignItems: "center",
    color: "#65b39d",
  },

  boxError: {
    display: "flex",
    alignSelf: "flex-end",
    color: "#ef5845",
    paddingLeft: 3,
  },
  iconError: {
    width: 12,
    height: 12,
  },

  dialogActions: {
    display: "block",
    minWidth: 531,
    textAlign: "right",
    padding: "15px 0",
  },
  buttonCancel: {
    width: 58,
    height: 30,
    minHeight: "unset",
    margin: 0,
    padding: "3px 16px",
    borderRadius: 2,
    backgroundColor: "#b3b5ba",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#b3b5ba",
    },
  },
  buttonSubmit: {
    width: 58,
    height: 30,
    minHeight: "unset",
    margin: "0 24px 0",
    padding: "3px 16px",
    borderRadius: 2,
    textTransform: "none",
  },
}));
