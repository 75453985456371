import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ReservationAction from "redux/reservation.redux";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, isArrayNotEqual } from "utils";
import { CellBody } from "components";
import BookingDialogHistory from "pages/BookingHistory/components/BookingDialogHistory";
import DialogOrderDetail from "pages/MnOrder/components/DialogOrderDetail";
import { BillStatusBatchUpdate, BillTableRow } from "./index";

const BillTable = ({ selectedList, setSelectedList }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);
  const reservation = useSelector(state => state.reservationRedux.reservation, shallowEqual);
  const isFetching = useSelector(state => state.reservationRedux.isFetching, shallowEqual);
  const reduxBillList = useSelector(state => state.billRedux.billList, shallowEqual);

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [billList, setBillList] = useState([]);
  const [isOpenBookingDialog, setIsOpenBookingDialog] = useState(false);
  const [isOpenOrderDialog, setIsOpenOrderDialog] = useState(false);

  const onCheckAll = () => {
    if (isCheckedAll) {
      setSelectedList([]);
    } else {
      setSelectedList([...billList]);
    }
  };

  const onCheckItem = newItem => {
    let newList = [...selectedList];
    if (newList.includes(newItem)) {
      newList = newList.filter(item => item.uid !== newItem.uid);
    } else {
      newList.push(newItem);
    }
    setSelectedList(newList);
  };

  const onOpenDetailDialog = uid => {
    dispatch(
      ReservationAction.getReservation({
        uid: uid,
      }),
    );
  };

  const onCloseBookingDialog = () => {
    setIsOpenBookingDialog(false);
  };

  const onCloseOrderDialog = () => {
    setIsOpenOrderDialog(false);
  };

  useEffect(() => {
    if (isArrayNotEqual(reduxBillList, billList)) {
      setBillList(reduxBillList);
    }
  }, [reduxBillList]);

  useEffect(() => {
    if (billList.length > 0 && selectedList.length === billList.length) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  }, [selectedList]);

  useEffect(() => {
    setSelectedList([]);
    setIsCheckedAll(false);
  }, [billList]);

  useEffect(() => {
    if (!isFetching && Object.keys(reservation).length > 0) {
      if (reservation.type === AppConstant.DATA_TYPE.order) {
        setIsOpenOrderDialog(true);
      } else if (reservation.type === AppConstant.DATA_TYPE.booking) {
        setIsOpenBookingDialog(true);
      }
    }
  }, [reservation, isFetching]);

  let isShowStatusSelect = selectedList.length > 0;

  return (
    <>
      {isShowStatusSelect && <BillStatusBatchUpdate selectedBookingList={selectedList} />}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {ARR_ORDER_TABLE_HEADER.map((cell, index) => (
                <CellBody
                  cellData={
                    <Box className={classes.firstTableCellHead}>
                      {index === 0 && (
                        <Checkbox
                          checked={isCheckedAll}
                          onChange={onCheckAll}
                          color="default"
                          disableRipple={true}
                          disableTouchRipple={true}
                          classes={{
                            root: classes.checkboxBasis,
                            checked: clsx(classes.checkboxChecked, classes.checkboxBasis),
                          }}
                        />
                      )}
                      <Typography className={clsx("semiBold-sm-txt", classes.tableTitle)}>{getLabel(cell)}</Typography>
                    </Box>
                  }
                  className={clsx(
                    classes.cellHeadRoot,
                    index === ARR_ORDER_TABLE_HEADER.length - 1 && classes.statusCellHead,
                  )}
                  key={index}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {billList.map((dataShow, index) => (
              <BillTableRow
                onShowDetail={onOpenDetailDialog}
                key={index}
                dataShow={dataShow}
                onClick={onCheckItem}
                isCheck={selectedList.includes(dataShow)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isOpenBookingDialog && Object.keys(reservation).length > 0 && (
        <BookingDialogHistory open={true} onClose={onCloseBookingDialog} />
      )}
      {isOpenOrderDialog && Object.keys(reservation).length > 0 && (
        <DialogOrderDetail onClose={onCloseOrderDialog} orderData={reservation} />
      )}
    </>
  );
};

export default BillTable;

BillTable.propTypes = {
  selectedList: PropTypes.array,
  setSelectedList: PropTypes.func,
};

BillTable.defaultProps = {
  selectedList: [],
  setSelectedList: () => {},
};

const ARR_ORDER_TABLE_HEADER = [
  LangConstant.TXT_BILL_CODE,
  LangConstant.TXT_COMPANY_NAME,
  LangConstant.TXT_COMPANY_TAX_NUMBER,
  getCommonKey(LangConstant.TXT_EMAIL),
  getCommonKey(LangConstant.P_BOOKING_TIME),
  getCommonKey(LangConstant.TXT_STATUS),
];

const useStyles = makeStyles(theme => ({
  tableContainer: {
    border: `1px solid ${theme.palette.grey[200]}`,
    "&>table": {
      overflowX: "auto",
      minWidth: 800,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  cellHeadRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },

  firstTableCellHead: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    position: "relative",
  },

  checkboxBasis: {
    width: 25,
    height: 25,
    position: "absolute",
    left: 0,
    padding: 5,
    " &>span>svg": {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },

  checkboxChecked: {
    color: theme.palette.text.link,
  },

  statusCellHead: {
    paddingRight: 32,
  },

  tableTitle: {
    width: "100%",
  },
}));
