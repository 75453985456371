import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, debounce, makeStyles, Typography, Button, Hidden } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import CustomSearchBar from "pages/MnNews/components/CustomSearchBar";
import { getCommonKey, getLabelArray } from "utils";
import { SearchInPeriod, MenuStatus, PrimaryCustomDatePicker } from "components";
import { getEndOfDay, getStartOfDay } from "utils/date";
import ReservationAction from "redux/reservation.redux";
import { getQueryData, updateQueryParams } from "pages/BookingHistory/components/FilterMenu";
import DialogAddNewOrder from "./DialogAddNewOrder";

const FilterMenu = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isPostReservationOrderSuccess = useSelector(
    ({ reservationRedux }) => reservationRedux.isPostReservationOrderSuccess,
  );

  const [selectedDates, setSelectedDates] = useState({ from: new Date(), to: new Date() });
  const [filterByStatus, setFilterByStatus] = useState(0);
  const [isOpenDialogAddOrder, setIsOpenDialogAddOrder] = useState(false);
  const [optionViewSelected, setOptionViewSelected] = useState(AppConstant.ReservationViewBy.day);

  const getReservationOrder = debounce(() => {
    let status =
      filterByStatus > AppConstant.STT_RESERVATION.confirm
        ? filterByStatus + 1
        : filterByStatus !== 0
        ? filterByStatus
        : null;

    dispatch(
      ReservationAction.getReservationType({
        start: getStartOfDay(selectedDates.from),
        end: getEndOfDay(selectedDates.to),
        type: AppConstant.DATA_TYPE.order,
        status: status,
      }),
    );
  }, AppConstant.DEBOUNCE_TIMEOUT);

  const onFilterTimeRange = rangeDate => {
    if (rangeDate.from && rangeDate.to) {
      onChangeDate(rangeDate, optionViewSelected);
    }
  };

  const onCallApi = debounce(
    params => dispatch(ReservationAction.getReservationType(params)),
    AppConstant.DEBOUNCE_TIMEOUT,
  );

  const onChangeOption = value => {
    onChangeDate(selectedDates, value);
  };

  const onChangeDate = (newSelectedDate, option) => {
    let startDate = newSelectedDate.from,
      endDate = newSelectedDate.to;
    if (option === AppConstant.ReservationViewBy.week) {
      startDate = startOfWeek(newSelectedDate.from, { weekStartsOn: 1 });
      endDate = endOfWeek(newSelectedDate.from, { weekStartsOn: 1 });
      setOptionViewSelected(AppConstant.ReservationViewBy.week);
    } else if (option === AppConstant.ReservationViewBy.month) {
      startDate = startOfMonth(newSelectedDate.from);
      endDate = endOfMonth(newSelectedDate.to);
      setOptionViewSelected(AppConstant.ReservationViewBy.month);
    } else if (option === AppConstant.ReservationViewBy.day) {
      startDate = startOfDay(newSelectedDate.from);
      endDate = endOfDay(newSelectedDate.from);
      setOptionViewSelected(AppConstant.ReservationViewBy.day);
    }
    setSelectedDates({ from: startDate, to: endDate });
    onCallApi({
      type: AppConstant.DATA_TYPE.order,
      start: getStartOfDay(startDate),
      end: getEndOfDay(endDate),
    });
    updateQueryParams({ from: startDate, to: endDate }, history);
  };

  const onClickOpenAddOrder = () => {
    setIsOpenDialogAddOrder(true);
  };

  const onClose = () => {
    setIsOpenDialogAddOrder(false);
  };

  const onClickTodayBtn = toDayObj => onChangeDate(toDayObj, AppConstant.ReservationViewBy.day);

  useEffect(() => {
    const queryParams = getQueryData(history.location.search, history);
    setSelectedDates(queryParams);
    dispatch(
      ReservationAction.getReservationType({
        type: AppConstant.DATA_TYPE.order,
        start: getStartOfDay(queryParams.from),
        end: getEndOfDay(queryParams.to),
      }),
    );
  }, []);

  useEffect(() => {
    getReservationOrder();
    return () => getReservationOrder.clear();
  }, [filterByStatus]);

  useEffect(() => {
    if (isPostReservationOrderSuccess) {
      setIsOpenDialogAddOrder(false);
    }
  }, [isPostReservationOrderSuccess]);

  let tmpArr = [LangConstant.TXT_STATUS, ...LangConstant.ARR_STATUS_ORDER];
  let arrayItemsText = getLabelArray(tmpArr, getLabel, true);

  return (
    <Box className={classes.bookingOrder}>
      <Box className={classes.dateAndSearchBox}>
        <PrimaryCustomDatePicker
          onChange={onFilterTimeRange}
          dateRange={selectedDates}
          optionView={optionViewSelected}
          onClickToday={onClickTodayBtn}
        />
        <Hidden mdUp>
          <Box display="flex">
            <SearchInPeriod
              className={classes.optionViewDesktop}
              onChange={onChangeOption}
              optionViewSelected={optionViewSelected}
            />
            <MenuStatus
              rowNum={LangConstant.ARR_STATUS_FILTER.length}
              onChange={setFilterByStatus}
              status={filterByStatus}
              classes={{ root: classes.filterByStatus, item: "menu-item-status", paper: classes.menuStatusPaper }}
              categoryText={getLabel(LangConstant.TXT_STATUS)}
              arrayItemsText={arrayItemsText}
            />
          </Box>
          <CustomSearchBar
            placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
            className={classes.bookingOrderSearch}
            onChange={event => onCallApi({ filter: event.target.value })}
          />
          <Button
            variant="contained"
            color="primary"
            classes={{ root: classes.buttonAdd, startIcon: classes.startIcon }}
            startIcon={<Add style={{ fontSize: 20 }} />}
            onClick={onClickOpenAddOrder}
          >
            <Typography variant="body2" className={clsx(classes.textBtn, "medium-md-txt")}>
              {getLabel(getCommonKey(LangConstant.TXT_ADD))}
            </Typography>
          </Button>
        </Hidden>
      </Box>
      <Hidden smDown>
        <SearchInPeriod
          className={classes.optionViewDesktop}
          onChange={onChangeOption}
          optionViewSelected={optionViewSelected}
        />
        <MenuStatus
          rowNum={LangConstant.ARR_STATUS_FILTER.length}
          onChange={setFilterByStatus}
          status={filterByStatus}
          classes={{ root: classes.filterByStatus, item: "menu-item-status", paper: classes.menuStatusPaper }}
          categoryText={getLabel(LangConstant.TXT_STATUS)}
          arrayItemsText={arrayItemsText}
        />
        <CustomSearchBar
          placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
          className={classes.bookingOrderSearch}
          onChange={event => onCallApi({ filter: event.target.value })}
        />
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.buttonAdd, startIcon: classes.startIcon }}
          startIcon={<Add style={{ fontSize: 20 }} />}
          onClick={onClickOpenAddOrder}
        >
          <Typography variant="body2" className={clsx(classes.textBtn, "medium-md-txt")}>
            {getLabel(getCommonKey(LangConstant.TXT_ADD))}
          </Typography>
        </Button>
      </Hidden>
      <DialogAddNewOrder open={isOpenDialogAddOrder} onClose={onClose} />
    </Box>
  );
};

export default FilterMenu;

const useStyles = makeStyles(theme => ({
  bookingOrder: {
    height: 108,
    display: "flex",
    marginBottom: 20,
    padding: "16px 24px",
    [theme.breakpoints.down(660)]: {
      height: "auto",
      flexDirection: "column",
      padding: 0,
    },
  },
  bookingOrderSearch: {
    height: 30,
    [theme.breakpoints.down(660)]: {
      marginTop: 10,
    },
  },
  optionViewDesktop: {
    height: 30,
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },

  dateAndSearchBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonAdd: {
    borderRadius: 2,
    minWidth: 91,
    height: 30,
    padding: 0,
    minHeight: 30,
    backgroundColor: theme.palette.reservation.finished,
    border: "unset",
    color: theme.palette.white,
    textTransform: "none",
    marginLeft: 30,
    [theme.breakpoints.down(960)]: {
      width: 54,
      height: 54,
      boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.3)",
      backgroundColor: "#f54e5e",
      borderRadius: "50%",
      position: "fixed",
      bottom: 50,
      right: 21,
      border: "none",
      minWidth: 50,
      zIndex: 1000,
      "&:hover": {
        backgroundColor: "#f54e5e",
      },
    },
  },
  startIcon: {
    marginLeft: "unset !important",
    marginRight: 4,
    color: theme.palette.white,
    [theme.breakpoints.down(900)]: {
      marginRight: "unset !important",
      fontSize: 28,
    },
  },
  textBtn: {
    color: "inherit",
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  filterByStatus: {
    height: 30,
    minWidth: 170,
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down(900)]: {
      width: 150,
      marginLeft: "10px !important",
      marginBottom: "10px !important",
      marginRight: "0 !important",
      marginTop: "10px !important",
    },
  },
  menuStatusPaper: {
    width: 170,
  },
}));
