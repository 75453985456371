import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  FormHelperText,
  CardMedia,
  Dialog,
} from "@material-ui/core";
import { Edit, Person, Phone, LocalShipping, Description, Comment, Error, CropFree } from "@material-ui/icons";
import clsx from "clsx";
import ReservationAction from "redux/reservation.redux";
import { ImageConstant, LangConstant, SystemConstant } from "const";
import { convertHex2rgba, getAttachmentUrl, getCommonKey, isBlank, validatePhone } from "utils";
import { CustomTooltip } from "components";

const ShippingInfo = ({ data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);

  const isUpdateSuccess = useSelector(({ reservationRedux }) => reservationRedux.updateReservationSuccess);

  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const [shippingInfo, setShippingInfo] = useState({});
  const [warningCondition, setWarningCondition] = useState(WARNING_DEFAULT);
  const [isHoveredImage, setIsHoveredImage] = useState(false);
  const [isShowPreviewImage, setIsShowPreviewImage] = useState(false);

  const onEditReceiverInfo = () => {
    setIsEditingInfo(true);
  };
  const onEditNote = () => {
    setIsEditingNote(true);
  };
  const onChangeInfo = event => {
    let name = event.target.name;
    let value = event.target.value;

    let valueToUpdate = name === "internalNote" ? { ...shippingInfo[name], content: value } : value;

    setShippingInfo({ ...shippingInfo, [name]: valueToUpdate });
    setEnableSaveBtn(true);
  };

  const onSaveInfo = event => {
    let isValidPhone = validatePhone(shippingInfo.phoneNumber);
    let isValidName = !isBlank(shippingInfo.name);
    let isExistAddress = !isBlank(shippingInfo.address);
    let warningOpt = { name: !isValidName, phoneNumber: !isValidPhone, address: !isExistAddress };

    let isValid = Object.values(warningOpt).findIndex(condition => true === condition) < 0;
    if (isValid) {
      dispatch(ReservationAction.putRemoteOrderDetails({ details: shippingInfo, uid: shippingInfo.uid }));
      setWarningCondition(WARNING_DEFAULT);
    }
    event.preventDefault();
    setWarningCondition(warningOpt);
  };

  const onShowImage = () => {
    setIsShowPreviewImage(true);
  };

  useEffect(() => {
    if (data) setShippingInfo(data);
  }, [data]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setEnableSaveBtn(false);
      setIsEditingNote(false);
      setIsEditingInfo(false);
    }
  }, [isUpdateSuccess]);

  let depositValue = shippingInfo.details
    ? getLabel(
        shippingInfo.details.depositType === SystemConstant.PAYMENT_METHOD.cash
          ? LangConstant.TXT_CASH
          : LangConstant.TXT_INTERNET_BANKING,
      )
    : "";

  let shippingValue = shippingInfo.details
    ? getLabel(
        shippingInfo.details.shippingType === SystemConstant.SHIPPING_METHOD.takeAway
          ? LangConstant.TXT_TAKE_AWAY
          : LangConstant.TXT_SHIP_AWAY,
      )
    : "";

  let depositImage = shippingInfo.details?.depositImage;

  return (
    <Box className={classes.rootBox}>
      <Box className={isEditingInfo && enableSaveBtn ? classes.formRootBtn : classes.formRoot}>
        <Box className={classes.formTitle}>
          <Typography color="inherit" className={classes.title}>
            {getLabel(LangConstant.TXT_RECEIVER_INFO)}
          </Typography>
          <IconButton onClick={onEditReceiverInfo}>
            <Edit className={classes.editIcon} />
          </IconButton>
        </Box>
        <TextFieldForm
          classes={classes}
          icon={Person}
          value={shippingInfo.name}
          name="name"
          title={getLabel(LangConstant.TXT_RECEIVER_NAME)}
          isEditing={isEditingInfo}
          onChange={onChangeInfo}
        />
        {warningCondition.name && (
          <FormHelperText className={classes.boxError}>
            <Error className={classes.iconError} />
            {getLabel(LangConstant.TXT_EMPTY_NAME_WARNING)}
          </FormHelperText>
        )}
        <TextFieldForm
          classes={classes}
          icon={Phone}
          value={shippingInfo.phoneNumber}
          name="phoneNumber"
          title={getLabel(getCommonKey(LangConstant.TXT_PHONE_NUMBER))}
          isEditing={isEditingInfo}
          onChange={onChangeInfo}
        />
        {warningCondition.phoneNumber && (
          <FormHelperText className={classes.boxError}>
            <Error className={classes.iconError} />
            {getLabel(LangConstant.TXT_INVALID_PHONE_WARNING)}
          </FormHelperText>
        )}
        <TextFieldForm
          classes={classes}
          icon={LocalShipping}
          value={shippingInfo.address}
          name="address"
          title={getLabel(LangConstant.TXT_ADDRESS_ORDER)}
          isEditing={isEditingInfo}
          onChange={onChangeInfo}
        />
        {shippingValue && (
          <TextFieldForm
            classes={classes}
            icon={LocalShipping}
            value={shippingValue}
            title={getLabel(LangConstant.TXT_DEPOSIT_METHOD)}
            isEditing={false}
          />
        )}
        {depositValue && (
          <TextFieldForm
            classes={classes}
            icon={ImageConstant.PaymentsIcon}
            value={depositValue}
            title={getLabel(LangConstant.TXT_DEPOSIT_METHOD)}
            isEditing={false}
          />
        )}
        {depositImage && (
          <>
            <Box
              className={classes.imagePreviewContainer}
              onMouseEnter={() => setIsHoveredImage(true)}
              onMouseLeave={() => setIsHoveredImage(false)}
            >
              <CardMedia src={getAttachmentUrl(depositImage)} component="img" className={classes.uploadedImage} />
              {isHoveredImage && (
                <Box className={clsx(classes.imageOverlay, "no-select-text")} onClick={onShowImage}>
                  <Box className={classes.cropButton}>
                    <CropFree className={classes.cropIcon} />
                    <Typography className="medium-sm-txt">{getLabel(LangConstant.TXT_WATCH_UPLOADED_IMAGE)}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Dialog
              classes={{
                root: classes.dialogPreviewImage,
              }}
              open={Boolean(depositImage && isShowPreviewImage)}
              onClose={() => setIsShowPreviewImage(false)}
            >
              <CardMedia
                src={getAttachmentUrl(depositImage)}
                component="img"
                className={classes.uploadedImageFullScreen}
              />
            </Dialog>
          </>
        )}
        {warningCondition.address && (
          <FormHelperText className={classes.boxError}>
            <Error className={classes.iconError} />
            {getLabel(LangConstant.TXT_EMPTY_ADDRESS_WARNING)}
          </FormHelperText>
        )}
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={isEditingInfo && enableSaveBtn ? classes.saveButton : classes.disableSaveBtn}
          onClick={onSaveInfo}
        >
          {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
        </Button>
      </Box>

      <Box className={isEditingNote && enableSaveBtn ? classes.formRootBtn : classes.formRoot}>
        <Box className={classes.formTitle}>
          <Typography color="inherit" className={classes.title}>
            {getLabel(LangConstant.TXT_ORDER_NOTE)}
          </Typography>
          <IconButton onClick={onEditNote}>
            <Edit className={classes.editIcon} />
          </IconButton>
        </Box>
        <TextFieldForm
          classes={classes}
          icon={Description}
          value={shippingInfo.note}
          placeholder={getLabel(LangConstant.P_EMPTY_NOTE)}
          name="note"
          title={getLabel(getCommonKey(LangConstant.TXT_CONSUMER_NOTE))}
          isEditing={isEditingNote}
          onChange={onChangeInfo}
          isNote={isBlank(shippingInfo.note)}
        />
        <TextFieldForm
          classes={classes}
          icon={Comment}
          value={shippingInfo?.internalNote?.content}
          placeholder={getLabel(LangConstant.P_EMPTY_NOTE)}
          name="internalNote"
          title={getLabel(getCommonKey(LangConstant.TXT_INTERNAL_NOTE))}
          isEditing={isEditingNote}
          onChange={onChangeInfo}
          isNote={isBlank(shippingInfo?.internalNote?.content)}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={isEditingNote && enableSaveBtn ? classes.saveButton : classes.disableSaveBtn}
          onClick={onSaveInfo}
        >
          {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
        </Button>
      </Box>
    </Box>
  );
};

const TextFieldForm = ({ classes, icon, name, value, title, isEditing, onChange, isNote, ...otherProps }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);
  const Icon = icon;
  return (
    <Box className={classes.textFieldForm}>
      <CustomTooltip title={title} classes={{ tooltip: classes.tooltip }}>
        <Box className={classes.icon}>
          <Icon />
        </Box>
      </CustomTooltip>
      {isEditing ? (
        <TextField
          size="small"
          variant="outlined"
          name={name}
          value={value}
          fullWidth
          multiline
          rowsMax={4}
          disabled={!isEditing}
          onChange={onChange}
          InputProps={{
            classes: { root: clsx("medium-md-txt", classes.contentLineEdit), disabled: classes.disabled },
          }}
          {...otherProps}
        />
      ) : isNote ? (
        <Typography className={clsx("medium-md-txt", classes.typography)}>
          {getLabel(LangConstant.P_EMPTY_NOTE)}
        </Typography>
      ) : (
        <Typography className={clsx("medium-md-txt", classes.typography)}>{value}</Typography>
      )}
    </Box>
  );
};

ShippingInfo.propTypes = {
  data: PropTypes.object,
};
ShippingInfo.defaultProps = {
  data: {},
};

export default memo(ShippingInfo);

const WARNING_DEFAULT = { name: false, phoneNumber: false, address: false };

const useStyles = makeStyles(theme => ({
  rootBox: {
    width: "25%",
    height: "fit-content",
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
      marginBottom: 15,
    },
  },

  formRootBtn: {
    textAlign: "center",
    border: "solid 0.5px #ebeced",
    borderRadius: 8,
    "&:first-child": { marginBottom: 24 },
  },
  formRoot: {
    textAlign: "center",
    border: "solid 0.5px #ebeced",
    borderRadius: 8,
    paddingBottom: 36,
    "&:first-child": { marginBottom: 24 },
  },

  formTitle: {
    display: "flex",
    padding: "15px 0px 15px 25px",
    alignItems: "center",
    color: "#3e4045",
    backgroundColor: "#fafafb",
  },
  title: {
    fontSize: 13,
    fontWeight: 600,
    marginRight: 8,
  },
  editIcon: {
    height: 16,
    width: 16,
    padding: 0,
    color: "#7f838c",
  },

  textFieldForm: {
    display: "flex",
    alignItems: "center",
    padding: "0px 25px",
    "&:not(:last-child)": {
      marginTop: 20,
    },
  },
  icon: {
    color: theme.palette.secondary.dark,
    marginRight: 16,
  },
  shippingInfo: {
    color: "#3e4045",
  },
  contentLineEdit: {
    minHeight: 30,
    padding: "4px 16px",
  },
  disabled: {
    opacity: 0.9,
    color: "#3e4045",
    border: "none",
    cursor: "no-drop",
  },
  tooltip: {
    marginTop: 2,
  },

  saveButton: {
    width: 98,
    borderRadius: 2,
    margin: "25px 0px",
    textTransform: "capitalize",
  },
  disableSaveBtn: {
    display: "none",
  },

  boxError: {
    display: "flex",
    paddingRight: 25,
    justifyContent: "flex-end",
    color: "#ef5845",
  },
  iconError: {
    width: 12,
    height: 12,
  },
  typography: {
    textAlign: "left",
  },
  imagePreviewContainer: {
    position: "relative",
    borderRadius: 2,
    marginTop: 8,
    padding: "0 25px",
    width: "fit-content",
  },
  uploadedImage: {
    width: 164,
    height: 164,
  },
  imageOverlay: {
    width: 164,
    height: 164,
    backgroundColor: convertHex2rgba("#000000", 0.6),
    position: "absolute",
    top: 0,
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
  },
  cropButton: {
    display: "flex",
    height: 30,
    position: "absolute",
    width: "fit-content",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    "&>*": {
      color: theme.palette.white,
    },
  },
  cropIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
  uploadedImageFullScreen: {
    maxWidth: "100%",
    padding: 24,
  },
}));
