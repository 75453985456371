import React, { memo } from "react";
import { Snackbar, makeStyles, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";

const Warning = ({ isShow, onClose, message }) => {
  const classes = useStyles();
  return (
    <Snackbar open={isShow} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
      <div className={classes.root}>
        <Alert
          action={
            <IconButton onClick={onClose} className={classes.button}>
              <Close className={classes.iconClose} />
            </IconButton>
          }
          severity="warning"
          classes={{ root: classes.rootAlert, action: classes.action, icon: classes.icon, message: classes.message }}
        >
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    minHeight: 40,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  rootAlert: {
    padding: "7px",
    minHeight: 40,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 0.5px #feba40",
    backgroundColor: "#fef6d8",
  },
  button: {
    padding: 0,
  },
  iconClose: {
    width: 20,
    height: 20,
  },
  icon: {
    padding: 0,
    marginRight: 8,
    display: "flex",
  },
  action: {
    marginRight: 0,
    paddingLeft: 8,
    display: "unset",
  },
  message: {
    lineHeight: "22px",
    padding: 0,
  },
}));

Warning.propTypes = {
  isShow: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Warning.defaultProps = {
  isShow: false,
  isAutoClose: true,
};

export default memo(Warning);
