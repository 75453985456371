import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Grid } from "@material-ui/core";
import ServiceCategoryAction from "redux/service-category.redux";
import PlaceCategoryAction from "redux/place-category.redux";
import ItemDetailAction from "redux/item-details.redux";
import StaffAction from "redux/staff.redux";
import ServiceItemAction from "redux/service-item.redux";
import ItemDetailsContentAction from "redux/item-details-content.redux";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, textNormalize, convertPricePreOrder, formatUsingTime } from "utils";
import { Processing } from "components";
import { DialogDelete } from "components/dialog";
import { DisplayModeBar, IconButtonMProductItem, ItemDescriptionBlock } from "components/mn-master-product";
import { DialogCreateMainService } from "./main-service";
import DialogViewEditMainService from "./main-service/DialogViewEditMainService";

const MainServiceAll = ({ childServiceModeId, childServiceModeArr, onChangeMode }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const dataCategoryService = useSelector(state => state.serviceCategoryRedux?.data, shallowEqual);
  const isFetchingCategoryService = useSelector(state => state.serviceCategoryRedux.isFetching, shallowEqual);

  const itemDetailsList = useSelector(state => state.itemDetailsRedux.dataGetListItemDetails?.data, shallowEqual);
  const isFetchingItemDetails = useSelector(state => state.itemDetailsRedux.isGetListItemDetailsFetching, shallowEqual);

  const dataCategoryPlace = useSelector(state => state.placeCategoryRedux?.data, shallowEqual);
  const isFetchingCategoryPlace = useSelector(state => state.placeCategoryRedux?.isFetching, shallowEqual);

  const staffList = useSelector(state => state.staffRedux?.staff, shallowEqual);
  const isFetchingStaff = useSelector(state => state.staffRedux.isFetching, shallowEqual);

  const dataServiceItem = useSelector(state => state.serviceItemRedux?.dataGetListServiceItem?.data, shallowEqual);
  const isFetchingServiceItem = useSelector(state => state.serviceItemRedux.isGetListServiceItemFetching, shallowEqual);
  const isPostServiceItemSuccess = useSelector(state => state.serviceItemRedux.isPostServiceItemSuccess, shallowEqual);
  const isPutServiceItemSuccess = useSelector(state => state.serviceItemRedux.isPutServiceItemSuccess, shallowEqual);
  const isDeleteServiceItemSuccess = useSelector(
    state => state.serviceItemRedux.isDeleteServiceItemSuccess,
    shallowEqual,
  );

  const dataItemDetailsContent = useSelector(
    state => state.itemDetailsContentRedux?.dataGetListItemDetailsContent?.data,
  );
  const isFetchingItemDetailsContent = useSelector(
    state => state.itemDetailsContentRedux.isGetListItemDetailsContentFetching,
    shallowEqual,
  );

  const [serviceItemList, setServiceItemList] = useState(dataServiceItem);
  const [isOpen, setIsOpen] = useState({
    createService: false,
    viewService: false,
    editService: false,
    deleteService: false,
    targetUid: null,
    targetName: "",
  });

  const onOpenCreateService = () => {
    setIsOpen({ ...isOpen, createService: true });
  };

  const onOpenViewService = uid => {
    setIsOpen({ ...isOpen, viewService: true, targetUid: uid });
  };

  const onDeleteService = uid => {
    const targetName = serviceItemList.find(data => data.uid == uid)?.name;
    setIsOpen({ ...isOpen, deleteService: true, targetUid: uid, targetName: targetName });
  };

  const onOpenEditService = uid => {
    setIsOpen({ ...isOpen, editService: true, targetUid: uid });
  };

  const onConfirmDelete = (e, uid) => {
    dispatch(ServiceItemAction.deleteServiceItem({ uid: uid }));
  };

  const onClose = () => {
    setIsOpen({
      createService: false,
      viewService: false,
      editService: false,
      deleteService: false,
      targetUid: null,
      targetName: "",
    });
  };

  const onSearch = searchValue => {
    if (searchValue == "" || !searchValue) {
      setServiceItemList(dataServiceItem);
    } else {
      const cloneData = JSON.parse(JSON.stringify(dataServiceItem));
      const filterList = cloneData.filter(data => textNormalize(data?.name).includes(textNormalize(searchValue)));
      setServiceItemList(filterList);
    }
  };

  useEffect(() => {
    if (!dataCategoryService && !isFetchingCategoryService) {
      dispatch(ServiceCategoryAction.getServiceCategory({}));
    }
    if (!dataCategoryPlace && !isFetchingCategoryPlace) {
      dispatch(PlaceCategoryAction.getPlaceCategory({}));
    }
    if (!itemDetailsList && !isFetchingItemDetails) {
      dispatch(ItemDetailAction.getListItemDetails({ type: AppConstant.TYPE_CATEGORY.service }));
    }
    if ((!staffList || staffList?.length) == 0 && !isFetchingStaff) {
      dispatch(StaffAction.requestStaff({ state: 1 }));
    }
    if (!dataServiceItem && !isFetchingServiceItem) {
      dispatch(ServiceItemAction.getListServiceItem({}));
    } else {
      setServiceItemList(dataServiceItem);
    }
    if (!dataItemDetailsContent && !isFetchingItemDetailsContent) {
      dispatch(ItemDetailsContentAction.getListItemDetailsContent({ type: AppConstant.TYPE_CATEGORY.service }));
    }
  }, [dataServiceItem]);

  useEffect(() => {
    if (isPostServiceItemSuccess || isPutServiceItemSuccess || isDeleteServiceItemSuccess) {
      if (isPostServiceItemSuccess || isPutServiceItemSuccess) {
        dispatch(ItemDetailAction.getListItemDetails({ type: AppConstant.TYPE_CATEGORY.service }));
      }
      dispatch(ServiceItemAction.resetServiceItem());
      dispatch(ServiceItemAction.getListServiceItem({}));
      dispatch(ItemDetailsContentAction.resetItemDetailsContent());
      dispatch(ItemDetailsContentAction.getListItemDetailsContent({ type: AppConstant.TYPE_CATEGORY.service }));
      onClose();
    }
  }, [isPostServiceItemSuccess, isPutServiceItemSuccess, isDeleteServiceItemSuccess]);

  return (
    <>
      <DisplayModeBar
        modeId={childServiceModeId}
        modeArray={childServiceModeArr}
        labelBtn={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
          LangConstant.TXT_SERVICE_LOWER_CASE,
        )}`}
        onChange={onChangeMode}
        onAddItem={onOpenCreateService}
        onSearch={onSearch}
        showSearch={serviceItemList && serviceItemList?.length > 0}
      />

      {serviceItemList && serviceItemList?.length > 0 ? (
        <Grid container spacing={3} className={classes.listItem}>
          {serviceItemList &&
            serviceItemList.map(dataMap => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={dataMap.uid}>
                <ItemDescriptionBlock
                  data={dataMap}
                  time={formatUsingTime(dataMap, getLabel)}
                  price={convertPricePreOrder(dataMap)}
                  onOpenDetail={onOpenViewService}
                  onOpenEdit={onOpenEditService}
                  onOpenDelete={onDeleteService}
                />
              </Grid>
            ))}
        </Grid>
      ) : (
        <Box className={classes.contentBox}>
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
              LangConstant.TXT_SERVICE_LOWER_CASE,
            )}`}
            onClick={onOpenCreateService}
            buttonClasses={{ root: classes.addButton }}
          />
        </Box>
      )}
      {isOpen.createService && <DialogCreateMainService isOpen={isOpen.createService} onClose={onClose} />}
      {(isOpen.viewService || isOpen.editService) && (
        <DialogViewEditMainService
          data={serviceItemList ? serviceItemList.find(data => data.uid === isOpen.targetUid) : {}}
          isOpen={isOpen.viewService || isOpen.editService}
          isEdit={isOpen.editService}
          onClose={onClose}
          warning={true}
        />
      )}
      {isOpen.targetUid && isOpen.deleteService && (
        <DialogDelete
          uid={isOpen.targetUid}
          isShow={isOpen.deleteService}
          onCancel={onClose}
          onConfirm={onConfirmDelete}
          title={getLabel(LangConstant.FN_DELETE_MAIN_SERVICE_TITLE)(isOpen.targetName)}
          content={getLabel(LangConstant.FN_DELETE_MAIN_SERVICE_CONTENT)(isOpen.targetName)}
        />
      )}

      {(isFetchingServiceItem ||
        isFetchingCategoryService ||
        isFetchingCategoryPlace ||
        isFetchingItemDetails ||
        isFetchingStaff) && <Processing isShow={true} />}
    </>
  );
};

export default memo(MainServiceAll);

const useStyles = makeStyles(theme => ({
  contentBox: {
    width: "100%",
    minHeight: "720px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  listItem: {
    padding: "60px 15px",
    minHeight: "calc(100% - 363px)",
    [theme.breakpoints.down("md")]: {
      padding: "60px 0",
    },
  },
}));
