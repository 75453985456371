import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import Reservation from "./Reservation";
import { AppConstant } from "const";

const GirdTime = ({ reservationList, maxRow, widthColumn, selectedDate }) => {
  let classes = useStyles();
  const [reservationListItem, setReservationListItem] = useState([]);
  const [totalOfEmpty, setTotalOfEmpty] = useState(0);

  useEffect(() => {
    let data = reservationList.filter(item => AppConstant.STT_RESERVATION_ACTIVE.includes(item.status));
    let lengthOfReservationList = data.length;
    setTotalOfEmpty(Math.abs(maxRow - lengthOfReservationList + 1));
    setReservationListItem(data);
  }, [reservationList]);

  return (
    <Box width={widthColumn} classes={{ root: classes.girdTime }}>
      {reservationListItem.map(value => {
        return (
          <Box key={value?.uid} borderBottom={1} borderRight={1} classes={{ root: classes.reservationBox }}>
            <Reservation data={value} selectedDate={selectedDate} />
          </Box>
        );
      })}
      {Array.from(Array(totalOfEmpty).keys()).map((_, index) => (
        <Box key={index} borderBottom={1} borderRight={1} classes={{ root: classes.reservationBox }} />
      ))}
    </Box>
  );
};

const WIDTH_COLUMN = 175;
const DEFAULT_MAX_ROW = 5;
const HEIGHT_RESERVATION_HEIGHT = 150;

const useStyles = makeStyles(theme => ({
  girdTime: {
    borderRightColor: theme.palette.grey[200],
  },
  reservationBox: {
    paddingRight: 5,
    paddingBottom: 5,
    borderColor: theme.palette.grey[200],
    height: HEIGHT_RESERVATION_HEIGHT,
    "&:last-child": {
      borderBottom: 0,
    },
  },
}));

GirdTime.propTypes = {
  reservationList: PropTypes.array.isRequired,
  maxRow: PropTypes.number.isRequired,
  widthColumn: PropTypes.number,
  placeCategory: PropTypes.array,
};

GirdTime.defaultProps = {
  reservationList: [],
  maxRow: DEFAULT_MAX_ROW,
  widthColumn: WIDTH_COLUMN,
};

export default memo(GirdTime);
