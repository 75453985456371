import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, makeStyles } from "@material-ui/core";
import StringFormat from "string-format";
import {
  NotifyBadge,
  DialogArrangeSeat,
  DialogWaitingConsumer,
  DialogVerify,
  DialogInviteWaitingConsumer,
} from "components";
import { LangConstant } from "const";
import { WaitingConsumerIcon } from "icons";
import ServiceCategoryAction from "redux/service-category.redux";
import WaitingConsumerAction from "redux/waiting-consumer.redux";
import ReservationAction from "redux/reservation.redux";

const WaitingListIcon = ({ onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  const [waitingConsumerArray, isSuccessWaitingConsumer, isAddNewWaitingSuccess] = useSelector(
    ({ waitingConsumerRedux }) => [
      waitingConsumerRedux.data,
      waitingConsumerRedux.isSuccess,
      waitingConsumerRedux.isPostSuccess,
    ],
    shallowEqual,
  );
  const [updateReservationSuccess, newReservation] = useSelector(
    ({ reservationRedux }) => [reservationRedux.updateReservationSuccess, reservationRedux.newReservation],
    shallowEqual,
  );

  const [displayWaitingConsumerOption, setDisplayWaitingConsumerOption] = useState(DISPLAY_OPTION_DIALOG.isHiddenAll);
  const [dataFocusWaitingConsumer, setDataFocusWaitingConsumer] = useState();

  const onClickOptionDisplay = (data, type) => {
    setDisplayWaitingConsumerOption(type);
    if (data) setDataFocusWaitingConsumer(data || null);
  };

  const onSubmitInviteWaitingConsumer = data => {
    if (displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayInvite) {
      dispatch(WaitingConsumerAction.postWaitingConsumer(data));
    } else if (displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayEdit) {
      dispatch(WaitingConsumerAction.putWaitingConsumer(data));
    }
  };

  const onSubmitDeleteWaitingConsumer = () => {
    dispatch(WaitingConsumerAction.deleteWaitingConsumer({ uid: dataFocusWaitingConsumer.uid }));
  };

  const onSubmitArrangeSeat = (data, isSettingTotal) => {
    let submitForm = data?.reservationUid ? data : { ...data, waitingConsumerUid: data.uid };
    if (data?.reservationUid) {
      dispatch(ReservationAction.putReservationDetails({ details: submitForm, uid: submitForm.reservationUid }));
    } else {
      dispatch(ReservationAction.postReservation({ newReservation: submitForm, isSettingTotal: isSettingTotal }));
    }
  };

  const onShowDialog = () => {
    setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isDisplayList);
    onClick();
  };

  useEffect(() => {
    if (isSuccessWaitingConsumer || isAddNewWaitingSuccess) {
      dispatch(WaitingConsumerAction.resetWaitingConsumer());
      if (displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayInvite) {
        setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isDisplayList);
      } else if (
        displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayEdit ||
        displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayDelete
      ) {
        setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isDisplayList);
      }
      setDataFocusWaitingConsumer(null);
    }
  }, [isSuccessWaitingConsumer, isAddNewWaitingSuccess]);

  useEffect(() => {
    dispatch(ServiceCategoryAction.getServiceCategory());
    if (!waitingConsumerArray) dispatch(WaitingConsumerAction.getWaitingConsumer());
  }, []);

  useEffect(() => {
    if (
      (newReservation || updateReservationSuccess) &&
      displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isArrangeSeat
    ) {
      if (updateReservationSuccess) {
        dispatch(ReservationAction.resetPutReservation());
      }
      setDataFocusWaitingConsumer(null);
      setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isDisplayList);
    }
  }, [newReservation, updateReservationSuccess]);

  return (
    <>
      <IconButton color="inherit" onClick={onShowDialog} className={classes.waitingConsumer} id={WAITING_LIST_ICON_ID}>
        {waitingConsumerArray && waitingConsumerArray.length > 0 ? (
          <NotifyBadge data={waitingConsumerArray && waitingConsumerArray.length} badgeClass={classes.waitingBadge}>
            <WaitingConsumerIcon />
          </NotifyBadge>
        ) : (
          <WaitingConsumerIcon />
        )}
      </IconButton>

      {displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayList && (
        <DialogWaitingConsumer
          isShow={true}
          onClose={() => setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isHiddenAll)}
          onClickOption={onClickOptionDisplay}
        />
      )}
      {(displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayInvite ||
        displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayEdit) && (
        <DialogInviteWaitingConsumer
          isShow={true}
          data={dataFocusWaitingConsumer || {}}
          onClose={() => setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isDisplayList)}
          onSubmit={onSubmitInviteWaitingConsumer}
        />
      )}

      {displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isDisplayDelete && (
        <DialogVerify
          isShow={true}
          title={StringFormat(getLabel(LangConstant.FM_NOTICE_TITLE), dataFocusWaitingConsumer?.name)}
          content={StringFormat(getLabel(LangConstant.FM_NOTICE_CONTENT), dataFocusWaitingConsumer?.name)}
          onCancel={() => setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isDisplayList)}
          onConfirm={onSubmitDeleteWaitingConsumer}
        />
      )}

      {displayWaitingConsumerOption === DISPLAY_OPTION_DIALOG.isArrangeSeat && (
        <DialogArrangeSeat
          isShow={true}
          data={dataFocusWaitingConsumer || null}
          onClose={() => setDisplayWaitingConsumerOption(DISPLAY_OPTION_DIALOG.isDisplayList)}
          onSubmit={onSubmitArrangeSeat}
        />
      )}
    </>
  );
};

export const DISPLAY_OPTION_DIALOG = {
  isHiddenAll: 0,
  isDisplayList: 1,
  isDisplayInvite: 2,
  isArrangeSeat: 3,
  isDisplayEdit: 4,
  isDisplayDelete: 5,
};

const useStyles = makeStyles(theme => ({
  waitingConsumer: {
    marginRight: 10,
  },
  waitingBadge: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const WAITING_LIST_ICON_ID = "waiting-list-icon-id";

export default memo(WaitingListIcon);
