import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { makeStyles, Box, Paper, Container, Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ServiceItemAction from "redux/service-item.redux";
import MnProductAction from "redux/mn-product.redux";
import ComboItemAction from "redux/combo-item.redux";
import { LangConstant } from "const";
import { getCommonKey, removeVietnameseTones, convertPricePreOrder, formatUsingTime } from "utils";
import { BglobalJSC, SearchBar } from "components";
import { DialogDelete } from "components/dialog";
import { IconButtonMProductItem, ItemDescriptionBlock } from "components/mn-master-product";
import { CreateComBo, WatchAndEditCombo } from "./components";

const MnCombo = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const listComboItemSuccess = useSelector(state => state.comboItemRedux.isGetListComboItemSuccess, shallowEqual);
  const deleteComboItemSuccess = useSelector(state => state.comboItemRedux.isDeleteComboItemSuccess, shallowEqual);
  const postComboItemSuccess = useSelector(state => state.comboItemRedux.isPostComboItemSuccess, shallowEqual);
  const putComboItemSuccess = useSelector(state => state.comboItemRedux.isPutComboItemSuccess, shallowEqual);

  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem, shallowEqual);
  const dataListServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem, shallowEqual);
  const dataListProductItem = useSelector(state => state.mnProductRedux.dataItem, shallowEqual);

  const [dataListComboItem, setDataListComboItem] = useState([]);
  const [saveComboItem, setSaveComboItem] = useState([]);
  const [isOpen, setIsOpen] = useState({
    watchCombo: false,
    createCombo: false,
    editCombo: false,
    deleteCombo: false,
    uid: null,
    name: "",
  });
  const [searchForm, setSearchForm] = useState({
    page: 1,
    paging: 0,
    size: 10,
    total: 0,
    totalPage: 0,
  });

  const onSearchInCombo = name => {
    let newListSearch = saveComboItem.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setDataListComboItem(newListSearch);
  };

  const onOpenCreateCombo = () => {
    setIsOpen({ ...isOpen, createCombo: true });
  };

  const onClose = () => {
    setIsOpen({
      watchCombo: false,
      createCombo: false,
      editCombo: false,
      deleteCombo: false,
      uid: null,
      name: "",
    });
  };

  const onOpenComboItemDetail = uid => {
    setIsOpen({ ...isOpen, watchCombo: true, uid: uid });
  };

  const onEditComboItemDetail = uid => {
    setIsOpen({ ...isOpen, editCombo: true, uid: uid });
  };

  const onDeleteComboItemDetail = uid => {
    let name = dataListComboItem.find(data => data.uid === uid)?.name;
    setIsOpen({ ...isOpen, deleteCombo: true, uid: uid, name: name });
  };

  const onConfirmDeleteCombo = (e, uid) => {
    dispatch(ComboItemAction.deleteComboItem({ uid: uid }));
  };

  useEffect(() => {
    if (!listComboItem) {
      dispatch(ComboItemAction.getListComboItem(searchForm));
    } else {
      if (listComboItem && listComboItem.data) {
        setDataListComboItem(listComboItem.data);
        setSaveComboItem(listComboItem.data);
        setSearchForm({ ...searchForm, total: listComboItem.total, totalPage: listComboItem.totalPage });
      }
    }
  }, []);

  useEffect(() => {
    if (!dataListServiceItem) {
      dispatch(ServiceItemAction.getListServiceItem({}));
    }
    if (!dataListProductItem) {
      dispatch(MnProductAction.getProductItem({}));
    }
  }, []);

  useEffect(() => {
    if (listComboItem?.data && listComboItemSuccess) {
      dispatch(ComboItemAction.resetComboItem());
      setDataListComboItem(listComboItem.data);
      setSaveComboItem(listComboItem.data);
      setSearchForm({ ...searchForm, total: listComboItem.total, totalPage: listComboItem.totalPage });
    }
  }, [listComboItemSuccess]);

  useEffect(() => {
    if (deleteComboItemSuccess) {
      dispatch(ComboItemAction.resetComboItem());
      dispatch(ComboItemAction.getListComboItem(searchForm));
      onClose();
    }
  }, [deleteComboItemSuccess]);

  useEffect(() => {
    if (postComboItemSuccess) {
      dispatch(ComboItemAction.resetComboItem());
      dispatch(ComboItemAction.getListComboItem(searchForm));
      onClose();
    }
  }, [postComboItemSuccess]);

  useEffect(() => {
    if (putComboItemSuccess) {
      dispatch(ComboItemAction.resetComboItem());
      dispatch(ComboItemAction.getListComboItem(searchForm));
      onClose();
    }
  }, [putComboItemSuccess]);

  return (
    <Box>
      <Paper elevation={1} square>
        <Container className={classes.root}>
          <Box className={classes.boxMain}>
            <Box className={classes.headerBox}>
              <Typography className={classes.headerText}>{getLabel(LangConstant.L_MAIN_COMBO)}</Typography>
            </Box>
            {saveComboItem && saveComboItem.length == 0 ? (
              <Box className={classes.createCombo}>
                <IconButtonMProductItem
                  onClick={onOpenCreateCombo}
                  label={getLabel(LangConstant.TXT_CREATE_COMBO)}
                  buttonClasses={{
                    root: classes.buttonCreateLarge,
                    typo: classes.textButtonCreateLarge,
                  }}
                  size="large"
                />
              </Box>
            ) : (
              <>
                <Box className={classes.optionBar}>
                  <SearchBar
                    placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
                    className={classes.searchBar}
                    onKeyUp={onSearchInCombo}
                  />
                  <IconButtonMProductItem
                    label={getLabel(LangConstant.TXT_CREATE_COMBO)}
                    buttonClasses={{
                      root: classes.buttonCreate,
                    }}
                    onClick={onOpenCreateCombo}
                  />
                </Box>
                <Grid container spacing={3} className={classes.listCombo}>
                  {dataListComboItem.map(dataMap => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={dataMap.uid}>
                      <ItemDescriptionBlock
                        data={dataMap}
                        time={formatUsingTime(dataMap, getLabel)}
                        price={convertPricePreOrder(dataMap)}
                        onOpenDetail={onOpenComboItemDetail}
                        onOpenEdit={onEditComboItemDetail}
                        onOpenDelete={onDeleteComboItemDetail}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Box>
        </Container>
      </Paper>
      {isOpen.createCombo && <CreateComBo isOpen={isOpen.createCombo} onClose={onClose} />}
      {isOpen.uid && (isOpen.editCombo || isOpen.watchCombo) && (
        <WatchAndEditCombo
          data={dataListComboItem.filter(dataMap => dataMap.uid === isOpen.uid)[0]}
          isOpenEdit={isOpen.editCombo}
          isOpenWatch={isOpen.watchCombo}
          onClose={onClose}
        />
      )}
      {isOpen.uid && isOpen.deleteCombo && (
        <DialogDelete
          uid={isOpen.uid}
          isShow={isOpen.deleteCombo}
          onCancel={onClose}
          onConfirm={onConfirmDeleteCombo}
          title={getLabel(LangConstant.FN_DELETE_COMBO_ITEM_TITLE)(isOpen.name)}
          content={getLabel(LangConstant.FN_DELETE_COMBO_ITEM_CONTENT)(isOpen.name)}
        />
      )}
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "100%",
    padding: "16px 12px 12px",
  },
  boxMain: {
    minHeight: "calc(100vh - 180px)",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  headerBox: {
    height: "28px",
    padding: "0px 12px",
  },
  headerText: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.56,
  },
  createCombo: {
    height: "calc(100vh - 230px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonCreateLarge: {
    padding: "9px 24px 9px 16px",
    minHeight: 40,
  },
  optionBar: {
    padding: "34px 12px 12px",
    display: "flex",
  },
  textButtonCreateLarge: {
    fontSize: 16,
  },
  searchBar: {
    width: "calc(100% - 16px)",
    float: "left",
  },
  buttonCreate: {
    minWidth: 152,
    minHeight: 32,
    marginLeft: 8,
    paddingLeft: 12,
    paddingRight: 24,
  },
  listCombo: {
    height: "fit-content",
    paddingLeft: 10,
    paddingRight: 10,
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));

export default memo(MnCombo);
