import React, { memo, useState } from "react";
import PropTypes from "prop-types";

import { Button, Typography, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { LangConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey, jumpToTargetBooking } from "utils";
import { useDispatch } from "react-redux";
import ReservationAction from "redux/reservation.redux";
import { useHistory } from "react-router-dom";

const NewBookingConfirm = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MAIN_LAYOUT);
  const dispatch = useDispatch();
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);

  const onClick = event => {
    event.stopPropagation();
    const isPeriodic = Boolean(data.periodicCode && data.periodicCode !== "");
    if (isPeriodic) {
      setOpenDialog(true);
    } else {
      onConfirm([data.uid]);
    }
  };

  const onCloseDialog = event => {
    event.stopPropagation();
    setOpenDialog(false);
  };

  const onClickDialog = (event, index) => {
    event.stopPropagation();
    switch (index) {
      case CANCEL:
        setOpenDialog(false);
        break;
      case SEEN_DETAIL:
        setOpenDialog(false);
        jumpToTargetBooking(history, data.startTime, data.uid);
        break;
      case CONFIRM_ALL:
        setOpenDialog(false);
        onConfirm(data.periodicReservationUid);
        break;
    }
  };

  const onConfirm = uids => {
    dispatch(
      ReservationAction.postReservationListStatus({
        status: AppConstant.STT_RESERVATION.confirm,
        uids: uids,
      }),
    );
  };

  return (
    <>
      <Button color="primary" size="small" className={classes.mainButton} onClick={onClick}>
        <Typography color="inherit" className={`${classes.mainButtonTypo} medium-sm-txt`}>
          {getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
        </Typography>
      </Button>
      {openDialog && <ConfirmDialog open={true} getLabel={getLabel} onClick={onClickDialog} onClose={onCloseDialog} />}
    </>
  );
};

const ConfirmDialog = props => {
  const classes = useStyles();
  const { open, getLabel, onClick, onClose } = props;

  return (
    <Dialog aria-labelledby="new-booking-confirm-dialog" open={open} onClose={e => onClose(e)}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6" component="p">
          {getLabel(LangConstant.TXT_NEW_BOOKING_DIALOG_TITLE)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography color="inherit" variant="body2" component="p">
          {getLabel(LangConstant.TXT_NEW_BOOKING_DIALOG_CONTENT)}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {LangConstant.ARR_NEW_BOOKING_DIALOG_BUTTON.map((value, index) => (
          <Button key={index} onClick={e => onClick(e, index)} className={classes.buttonDialogActions}>
            {getLabel(getCommonKey(value))}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

const CANCEL = 0;
const SEEN_DETAIL = 1;
const CONFIRM_ALL = 2;

const useStyles = makeStyles(theme => ({
  mainButton: {
    marginTop: "8px",
    padding: "0px 4px",
    width: "70px",
    minHeight: "20px",
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "unset",
    "&:hover": {
      backgroundColor: "#ef5845",
    },
  },
  mainButtonTypo: {},
  dialogTitle: {
    padding: "24px 24px",
  },
  dialogContent: {
    borderBottom: `solid 1px ${theme.palette.grey[100]}`,
    color: theme.palette.grey[500],
    paddingBottom: "24px",
  },
  dialogActions: {
    display: "flex",
  },
  buttonDialogActions: {
    color: theme.palette.grey[400],
  },
}));

NewBookingConfirm.propTypes = {
  data: PropTypes.object,
};
NewBookingConfirm.defaultProps = {
  data: {},
};

export default memo(NewBookingConfirm);
