import { Box, Dialog, IconButton, makeStyles, Slide } from "@material-ui/core";
import React, { forwardRef, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import CreateStaffForm from "./CreateStaffForm";
// eslint-disable-next-line no-unused-vars
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} />;
});
// eslint-disable-next-line no-unused-vars
const CreateStaff = forwardRef((props, ref) => {
  const classes = useStyles();
  const { isShow, action, showTime, showPosition } = props;
  const submitRef = useRef();
  return (
    <>
      <Dialog fullScreen open={isShow} onClose={() => action(false)} TransitionComponent={Transition}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => action(false)}
          aria-label="close"
          className={classes.closeDialogBtn}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <Box className={classes.createStaffForm}>
          <CreateStaffForm ref={submitRef} showTime={showTime} showPosition={showPosition} action={action} />
        </Box>
      </Dialog>
    </>
  );
});
export default CreateStaff;
const useStyles = makeStyles(theme => ({
  closeDialogBtn: {
    alignSelf: "flex-end",
    width: "36px",
    height: "36px",
    marginTop: "3vh",
    marginRight: "3vw",
  },
  closeIcon: {},
  createStaffForm: {
    textAlign: "center",
    minHeight: "80%",
    [theme.breakpoints.down("xs")]: {
      minHeight: "unset",
      textAlign: "center",
    },
  },
  createStaffFormDialog: {
    display: "contents",
  },
}));
