import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Button, Typography, useTheme, makeStyles } from "@material-ui/core";

export default function DialogConfirm({ isShow, children, message, onSubmit, onReject }) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: "dialog-common dialog-notice-root" }}
      aria-labelledby="dialog-notice-title"
      open={isShow}
      maxWidth="sm"
    >
      <DialogContent classes={{ root: "dialog__main" }} dividers style={{ zIndex: theme.zIndex.appBar + 100 }}>
        <Typography variant="h3" component="p" className="highlight">
          XÁC NHẬN
        </Typography>
        <Typography variant="subtitle1" component="p" color="textSecondary">
          {message}
        </Typography>
        {children}
      </DialogContent>
      <DialogActions classes={{ root: "dialog__footer" }}>
        <Button
          autoFocus
          className={`${classes.button} ${classes.buttonAccept}`}
          variant="contained"
          onClick={onSubmit}
          color="primary"
        >
          <Typography classes={{ body1: `semiBold-sm-txt ${classes.typo} ${classes.typoAccept}` }}>Xác nhận</Typography>
        </Button>
        <Button
          autoFocus
          className={`${classes.button} ${classes.buttonReject}`}
          variant="contained"
          onClick={onReject}
          color="primary"
        >
          <Typography classes={{ body1: `semiBold-sm-txt ${classes.typo} ${classes.typoAccept}` }}>Hủy</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    height: "30px",
    boxShadow: "none",
    borderRadius: "20px",
    textTransform: "none",
    minHeight: "20px",
    maxWidth: "140px",
    minWidth: "140px",
  },
  buttonReject: {
    color: "#ef5845",
    borderColor: "#ef5845",
  },
  buttonAccept: {
    color: "#ffffff",
    borderColor: "#ef5845",
    backgroundColor: "#ef5845",
  },
  typo: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
  },
  typoAccept: {
    color: "#ffffff",
  },
}));
DialogConfirm.propTypes = {
  isShow: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};
DialogConfirm.defaultProps = {};
