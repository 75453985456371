import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, OutlinedInput } from "@material-ui/core";
const TextArea = ({ placeholder, value, onChange, className, rowMax, ...otherProps }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const onTyping = event => {
    if (Boolean(onChange)) {
      onChange(event);
    }
    setInputValue(event.target.value);
  };

  return (
    <OutlinedInput
      fullWidth
      height={"60px"}
      placeholder={placeholder}
      type="text"
      value={inputValue}
      onChange={onTyping}
      className="regular-md-txt"
      classes={{
        root: `${classes.root} ${className}`,
        multiline: classes.multiline,
        input: classes.input,
      }}
      multiline
      rowsMax={rowMax ? rowMax : 2}
      {...otherProps}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: "4px",
    marginBottom: "4px",
    height: "60px",
  },
  multiline: {
    padding: "9px 21px",
    alignItems: "flex-start",
  },
  input: {
    color: theme.palette.text.primary,
    "&::placeholder": {
      opacity: 1,
      color: theme.palette.grey[400],
    },
    "&.Mui-disabled": {
      cursor: "no-drop",
    },
  },
}));

TextArea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = { placeholder: "", value: "", className: "", type: "" };
export default memo(TextArea);
