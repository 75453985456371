import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import BillAction from "redux/bill.redux";
import { BillManagementFilter, BillTable } from "./components";
import { TablePagination } from "components/table";
import { getEndOfDay, getStartOfDay } from "utils/date";

const VATManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isUpdateSuccess = useSelector(state => state.billRedux.isPatchUpdateSuccess);
  const paging = useSelector(state => state.billRedux.paging);

  const [selectedList, setSelectedList] = useState([]);
  const [filterObject, setFilterObject] = useState({
    startTime: getStartOfDay(new Date()),
    endTime: getEndOfDay(new Date()),
    status: null,
    paging: 1,
    page: 1,
    filter: "",
  });

  useEffect(() => {
    dispatch(BillAction.getBillList(filterObject));
    setSelectedList([]);
  }, [filterObject]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(BillAction.getBillList(filterObject));
    }
  }, [isUpdateSuccess]);

  return (
    <Box className={classes.VATPaper}>
      <BillManagementFilter filterObject={filterObject} onChangeFilterObject={setFilterObject} />
      <BillTable selectedList={selectedList} setSelectedList={setSelectedList} />
      <TablePagination
        onChange={nextPage =>
          setFilterObject({
            ...filterObject,
            page: nextPage,
          })
        }
        page={filterObject.page}
        rowsPerPage={paging.size}
        total={paging.total}
      />
    </Box>
  );
};

VATManagement.propTypes = {};

const useStyles = makeStyles(theme => ({
  VATPaper: {
    backgroundColor: theme.palette.white,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.103529)",
  },
}));

export default VATManagement;
