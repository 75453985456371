import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, TableHead, TableRow, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { CellBody } from "components";

const OrderTableHeader = () => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        {TWO_FIRST_HEADER_CELLS.map((cell, index) => (
          <CellBody
            cellData={
              <Typography color="inherit" className={classes.tableHeadLabel}>
                {getLabel(cell)}
              </Typography>
            }
            className={classes.twoFirstHeader}
            key={index}
          />
        ))}
        {OTHER_HEADER_CELLS.map((cell, index) => (
          <CellBody
            cellData={
              <Typography olor="inherit" className={classes.tableHeadLabel}>
                {getLabel(cell)}
              </Typography>
            }
            className={classes.commonHeader}
            key={index}
          />
        ))}
        <CellBody className={classes.commonHeader} />
      </TableRow>
    </TableHead>
  );
};

export default memo(OrderTableHeader);

const TWO_FIRST_HEADER_CELLS = [LangConstant.TXT_ORDER_ITEM, LangConstant.TXT_ACCOMPANIED_ITEM];
const OTHER_HEADER_CELLS = [
  LangConstant.TXT_ORDER_ITEM_AMOUNT,
  LangConstant.TXT_ITEM_PRICE,
  LangConstant.TXT_TOTAL_PRICE,
];

const useStyles = makeStyles(() => ({
  headerRow: {
    height: 53,
    borderColor: "none",
    borderRadius: 8,
    color: "#3e4045",
    backgroundColor: "#fafafb",
  },
  twoFirstHeader: {
    width: "25%",
    textAlignLast: "left",
    "&:first-child": { paddingLeft: 23 },
  },
  commonHeader: {
    width: "15%",
    textAlignLast: "center",
  },
  tableHeadLabel: {
    width: "100%",
    textAlign: "center",
    fontSize: 13,
    fontWeight: 600,
  },
}));
