import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const postItemDetailsContent = data => createApiReservation().post(ApiConstant.ITEM_DETAILS_CONTENT, data);

export const putItemDetailsContent = data =>
  createApiReservation().put(format(ApiConstant.ITEM_DETAILS_CONTENT_UID, data.uid), data);

export const getListItemDetailsContent = data => createApiReservation().get(ApiConstant.ITEM_DETAILS_CONTENT, data);

export const getItemDetailsContentByUid = data =>
  createApiReservation().get(format(ApiConstant.ITEM_DETAILS_CONTENT_UID, data.uid), data);

export const deleteItemDetailsContent = data =>
  createApiReservation().delete(format(ApiConstant.ITEM_DETAILS_CONTENT_UID, data.uid), data);
