import React, { memo, useEffect, useState } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Box, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { getStatusColor, roundNumber } from "utils";

const BookingByTimeChart = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [summaryData, viewBy, arraySetting, viewMode] = useSelector(({ reportDashboard }) => [
    reportDashboard.summaryReservation.data,
    reportDashboard.summaryReservation.viewBy,
    reportDashboard.summaryReservation.setting.details,
    reportDashboard.summaryReservation.setting.view_mode,
  ]);

  const [chartData, setChartData] = useState();
  const [columnLayout, setColumnLayout] = useState([]);

  useEffect(() => {
    if (!arraySetting) return;

    setColumnLayout(
      arraySetting.map(item => ({
        ...item,
        filed: item.display_id,
        color: getStatusColor(item.display_id),
      })),
    );
  }, [arraySetting]);

  useEffect(() => {
    if (!summaryData) return;
    setChartData(summaryData);
  }, [summaryData]);

  useEffect(() => {
    if (!chartData || !Array.isArray(chartData)) return;
    // Create chart instance
    let chart = window.am4core.create(chartHtmlId, am4charts.XYChart);
    chart.scrollbarX = new window.am4core.Scrollbar();
    chart.scrollbarX.marginBottom = 24;
    chart.scrollbarX.marginTop = -8;

    // Add data
    if (AppConstant.CHART_VIEW_BY.week === viewBy) {
      let arrDayOfWeekLang = LangConstant.ARR_DAY_OF_WEEK_SHORT.map(itemLang => getLabel(itemLang));

      chart.data = chartData.map(item => ({
        ...item,
        date: arrDayOfWeekLang[parseInt(item.date)],
      }));
    } else {
      chart.data = chartData;
    }

    // Create axes
    createCategoryAxis(chart, AppConstant.CHART_VIEW_BY.hour === viewBy && chartData.length);
    chart.yAxes.push(new am4charts.ValueAxis());

    if (AppConstant.CHART_VIEW_MODE.overview == viewMode) {
      createColumnChartTotal(chart);
    } else if (AppConstant.CHART_VIEW_MODE.detail == viewMode) {
      columnLayout.forEach(column => createColumnLayoutChart(chart, column));
    }

    chart.cursor = new am4charts.XYCursor();
  }, [chartData, columnLayout, viewMode]);

  return <Box id={chartHtmlId} className={classes.chart} />;
};

BookingByTimeChart.propTypes = {};
export default memo(BookingByTimeChart);

const chartHtmlId = "bookingByTimeChart";
const COLUMN_COLOR = "#447aff";
export const MIN_WIDTH_COLUMN = 60;

const useStyles = makeStyles({
  chart: {
    width: "100%",
    height: 450,
  },
});

export const createCategoryAxis = (chart, length) => {
  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "date";
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.renderer.grid.template.location = 0;
  preZoomChart(categoryAxis, length);

  return categoryAxis;
};

// Create series
export const createColumnChartTotal = chart => {
  var series = chart.series.push(new am4charts.ColumnSeries());
  series.sequencedInterpolation = true;
  series.dataFields.valueY = "total";
  series.dataFields.categoryX = "date";
  series.tooltipText = "[bold][#fff]{valueY}[/]";
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = window.window.am4core.color(COLUMN_COLOR);

  // Configure columns
  let columnTemplate = series.columns.template;
  columnTemplate.strokeWidth = 0;
  columnTemplate.column.fillOpacity = 0.7;
  columnTemplate.column.fill = window.window.am4core.color(COLUMN_COLOR);

  // on hover, make corner radiuses bigger
  var hoverState = columnTemplate.column.states.create("hover");
  hoverState.properties.fillOpacity = 1;

  return series;
};

export const createColumnLayoutChart = (chart, data) => {
  var series = chart.series.push(new am4charts.ColumnSeries());
  series.sequencedInterpolation = true;
  series.dataFields.valueY = data.filed;
  series.dataFields.categoryX = "date";
  series.tooltipText = "[bold][#fff]{valueY}[/]";
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = window.window.am4core.color(data.color);
  series.name = data.name;
  series.stacked = true;
  series.showOnInit = true;

  // Configure columns
  let columnTemplate = series.columns.template;
  columnTemplate.strokeWidth = 0;
  columnTemplate.column.fill = window.window.am4core.color(data.color);

  // on hover, make corner radiuses bigger
  var hoverState = columnTemplate.column.states.create("hover");
  hoverState.properties.fillOpacity = 1;

  return series;
};

export const preZoomChart = (categoryAxis, length) => {
  // Pre-zoom the chart
  if (length && length > 12) {
    let percentWidth = roundNumber(document.getElementById(chartHtmlId).offsetWidth / (MIN_WIDTH_COLUMN * length));
    if (percentWidth < 1) {
      categoryAxis.start = 0;
      categoryAxis.end = percentWidth;
      categoryAxis.keepSelection = true;
    }
  }
};
