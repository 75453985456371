import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Tooltip } from "@material-ui/core";

const CustomTooltip = props => {
  const classes = useStyles({ isMedium: props.size === SIZE.medium });

  return <Tooltip arrow classes={classes} {...props} interactive />;
};

export const SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
};
CustomTooltip.propTypes = {
  title: PropTypes.node,
  size: PropTypes.string,
  placement: PropTypes.string,
};

CustomTooltip.defaultProps = {
  size: SIZE.small,
};

export default CustomTooltip;

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "#7f838c",
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 500,
    padding: ({ isMedium }) => (isMedium ? "4px 8px" : "3px 6px"),
  },
  arrow: {
    borderStyle: "solid",
  },
  popperArrow: {
    "&[x-placement*='bottom'] $arrow": {
      width: 12,
      height: 3,
      marginTop: -3,
      borderWidth: "0 6px 3px 6px",
      borderColor: "transparent transparent #7f838c transparent",
    },
    "&[x-placement*='top'] $arrow": {
      width: 12,
      height: 3,
      marginBottom: -3,
      borderWidth: "3px 6px 0 6px",
      borderColor: " #7f838c transparent transparent transparent",
    },
    "&[x-placement*='left'] $arrow": {
      width: 3,
      height: 12,
      marginRight: -3,
      borderWidth: "6px 0px 6px 3px",
      borderColor: "transparent transparent transparent #7f838c",
    },
    "&[x-placement*='right'] $arrow": {
      width: 3,
      height: 12,
      marginLeft: -3,
      borderWidth: "6px 3px 6px 0",
      borderColor: "transparent #7f838c transparent transparent",
    },
  },
  tooltipPlacementBottom: {
    marginTop: 8,
  },
  tooltipPlacementTop: {
    marginBottom: 8,
  },
  tooltipPlacementRight: {
    marginLeft: 8,
  },
  tooltipPlacementLeft: {
    marginRight: 8,
  },
}));
