import React, { useState, useEffect, memo } from "react";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import { Box, Paper, makeStyles } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import { BglobalJSC, SettingComponentHeader } from "components";
import { BookingPaymentTab, OrderPaymentTab } from "./components";
import { useDispatch, useSelector } from "react-redux";
import ReservationAction from "redux/reservation-setting.redux";
import { getCommonKey, getFeatureConditions } from "utils";

const PaymentSetup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_PAYMENT_SETUP);

  const dataReservationSetting = useSelector(state => state.reservationSettingRedux.dataSetting);
  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { isPremium, isOnlyBooking, isOnlyOrdering } = getFeatureConditions(reservationSetting);

  const [dataUpdated, setDataUpdated] = useState(dataReservationSetting);
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TAB.booking);

  const onSelectedTab = (_, selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
  };

  const onChangeBookingPayment = (data, isEnableSave) => {
    let bookingPaymentUpdated = {
      ...data,
      details: {
        ...data?.details,
        depositInformation: {
          ...data?.details?.depositInformation,
          value: parseInt(data?.details?.depositInformation.value),
        },
      },
    };

    setDataUpdated(bookingPaymentUpdated);
    setIsEnableSave(isEnableSave);
  };

  const onChangeOrderPayment = (data, isEnableSave) => {
    setDataUpdated(data);
    setIsEnableSave(isEnableSave);
  };

  const onSubmitDataAll = () => {
    let result = [];
    result.push(dataUpdated);
    dispatch(ReservationAction.requestUpdateReservationSetting(result));
    setIsEnableSave(false);
  };
  useEffect(() => {
    dispatch(ReservationAction.requestGetReservationSetting());
  }, []);

  useEffect(() => {
    if (isOnlyOrdering && !isOnlyBooking && !isPremium) {
      setSelectedTab(TAB.ordering);
    }
  }, [isOnlyOrdering]);

  let isOrder = Boolean(isOnlyOrdering || isPremium);
  let isBooking = Boolean(isOnlyBooking || isPremium);
  return (
    <Box>
      <Paper>
        {isOrder && (
          <SettingComponentHeader
            title={getLabel(LangConstant.TXT_PAYMENT_SETUP)}
            isDisable={!isEnableSave}
            onChange={onSelectedTab}
            onSubmit={onSubmitDataAll}
            value={selectedTab}
          />
        )}
        {isBooking && (
          <BookingPaymentTab
            selectedTab={selectedTab}
            tabIndex={AppConstant.RESERVATION_TAB_INDEX.booking}
            onChange={onChangeBookingPayment}
          />
        )}
        <OrderPaymentTab
          selectedTab={selectedTab}
          tabIndex={AppConstant.RESERVATION_TAB_INDEX.order}
          onChange={onChangeOrderPayment}
        />
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
      <Prompt when={isEnableSave} message={getLabel(getCommonKey(LangConstant.TXT_NOTIFICATION_MESSAGE))} />
    </Box>
  );
};

const TAB = {
  booking: 0,
  ordering: 1,
};

export default memo(PaymentSetup);

const useStyles = makeStyles(() => ({
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));
