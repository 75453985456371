import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const NewsListIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M2.538 5.923h12.693v-1.48H2.538v1.48zm6.981 8.885h4.443v-1.481H9.519v1.48zm0-2.962h5.712v-1.48H9.519v1.48zm0-2.961h5.712V7.404H9.519v1.48zm-6.98 5.923H8.25V7.404H2.538v7.404zm15.23-11.846V0H0v16.288s0 2.962 2.538 2.962h15.866s1.904-.046 1.904-2.962V2.962h-2.539zM2.54 17.769c-1.27 0-1.27-1.48-1.27-1.48V1.48H16.5v14.807c0 .682.18 1.154.429 1.481H2.539z"
        transform="translate(-40 -206) translate(40 206) translate(2) translate(0 4.442)"
      />
    </SvgIcon>
  );
};

NewsListIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
NewsListIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(NewsListIcon);
