import React, { memo, useState, useEffect } from "react";
import { makeStyles, Popover, Button, Box, Typography, Paper } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { LangConstant } from "../../../const";
import { BglobalJSC } from "../../../components";
import { useTranslation } from "react-i18next";
import { DialogConfirm, ListFacebook, SearchBar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import ConsumerAction from "../../../redux/consumer.redux";

const PopupFacebook = props => {
  const { openFacebook, setOpenFacebook, shopData } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const listFacebook = useSelector(state => state.consumerRedux.getInvitationFacebook);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [dataFaceBook, setDataFacebook] = useState([]);
  const [save, setSave] = useState([]);
  const [next, setNext] = useState(null);

  const onClose = () => {
    setOpenFacebook(false);
    setDataFacebook([]);
  };

  const onSearch = name => {
    if (save.length <= dataFaceBook.length) {
      setSave(dataFaceBook);
      let updatedList = dataFaceBook.filter(person => person.name.toLowerCase().includes(name.toLowerCase()));
      setDataFacebook(updatedList);
    } else if (!name) {
      setDataFacebook(save);
    } else {
      setDataFacebook(save);
      let updatedList = dataFaceBook.filter(person => person.name.toLowerCase().includes(name.toLowerCase()));
      setDataFacebook(updatedList);
    }
  };

  const onInviteAll = () => {
    setOpenConfirm(true);
  };

  const onSubmitAll = () => {
    setOpenConfirm(false);
    dispatch(ConsumerAction.patchInvitationFacebook({ senders: dataFaceBook, invitation_link: shopData }));
    setDataFacebook([]);
  };

  useEffect(() => {
    setNext(listFacebook && listFacebook.next ? listFacebook.next : null);
    if (listFacebook && listFacebook.senders) {
      if (!dataFaceBook) {
        setDataFacebook(listFacebook.senders);
      } else {
        setDataFacebook([...dataFaceBook, ...listFacebook.senders]);
      }
    }
  }, [listFacebook]);

  return (
    <Popover open={openFacebook} anchorEl={document.body} marginThreshold={0} classes={{ paper: classes.paperProps }}>
      <Paper elevation={1} square classes={{ root: classes.rootPaper, elevation1: classes.elevation1 }}>
        <Paper
          elevation={1}
          square
          className={dataFaceBook && dataFaceBook.length <= 8 ? classes.defaultPaperChild : classes.rootPaperChild}
        >
          <Box className={classes.boxCloseParent}>
            <Box className={classes.boxCloseChildren}>
              <Button
                onClick={() => onClose()}
                classes={{
                  root: classes.rootButton,
                  label: classes.label,
                  startIcon: classes.startIcon,
                  iconSizeMedium: classes.iconSizeMedium,
                }}
                startIcon={<Close className={classes.rootIcon} />}
              />
            </Box>
          </Box>
          <Box className={classes.boxBodyParent}>
            <Box className={classes.boxHeader}>
              <Typography variant="h5">{getLabel(LangConstant.TXT_LIST_INVITE_FACEBOOK)}</Typography>
            </Box>
            <Box className={classes.boxSupport}>
              <Typography variant="body2">{getLabel(LangConstant.TXT_TEXTED_FACEBOOK)}</Typography>
            </Box>
            <SearchBar
              className={classes.search}
              placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)}
              onKeyUp={onSearch}
            />
            <Box className={dataFaceBook && dataFaceBook.length > 0 ? classes.noneData : classes.boxSupport}>
              <Typography variant="body2" style={{ opacity: 0.6, textAlign: "center", marginTop: "20vh" }}>
                {getLabel(LangConstant.TXT_DONT_HAVE_LIST_VIEW)}
              </Typography>
            </Box>
            <Box className={dataFaceBook && dataFaceBook.length > 0 ? classes.boxInviteAll : classes.noneData}>
              <Button disableRipple onClick={onInviteAll} className={classes.inviteButtonAll}>
                {getLabel(LangConstant.TXT_INVITE_ALL)}
              </Button>
              <DialogConfirm open={openConfirm} setOpen={setOpenConfirm} onSubmit={onSubmitAll} />
            </Box>
            <ListFacebook user={dataFaceBook} setUser={setDataFacebook} next={next} shopData={shopData} />
          </Box>
        </Paper>
      </Paper>
      <BglobalJSC className={classes.BglobalJSC} />
    </Popover>
  );
};

const useStyles = makeStyles(() => ({
  paperProps: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
  },
  rootPaper: {
    height: "auto",
    width: "40%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
    "@media only screen and (max-width: 1200px)": {
      width: "60%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      height: "95%",
      marginTop: 0,
    },
  },
  rootPaperChild: {
    height: "auto",
  },
  defaultPaperChild: {
    height: 850,
  },
  noneData: {
    display: "none",
  },
  elevation1: {
    boxShadow: "none",
  },
  boxCloseParent: {
    marginRight: 0,
    paddingTop: 15,
    height: 40,
    maxWidth: "100%",
    "@media only screen and (max-width: 1000px)": {
      height: 50,
    },
  },
  boxCloseChildren: {
    height: "100%",
    width: 25,
    marginLeft: "auto",
    marginRight: 20,
    "@media only screen and (max-width: 1000px)": {
      height: 50,
    },
  },
  boxBodyParent: {
    width: "60%",
    marginLeft: "20%",
    paddingBottom: 50,
    "@media only screen and (max-width: 1000px)": {
      width: "auto",
      marginLeft: 10,
      marginRight: 15,
    },
  },
  boxHeader: {
    marginTop: 10,
    marginBottom: 10,
    "@media only screen and (max-width: 1000px)": {
      marginBottom: 4,
    },
  },
  boxSupport: {
    marginBottom: 30,
    "@media only screen and (max-width: 1000px)": {
      marginBottom: 24,
    },
  },
  boxInviteAll: {
    textAlign: "right",
  },
  search: {
    margin: "0 0 0",
    height: 30,
    width: "100%",
    marginBottom: 16,
  },
  rootButton: {
    width: 25,
    minWidth: 25,
    height: "100%",
    marginLeft: "auto",
    marginRight: 0,
    padding: "0 0 0",
    "@media only screen and (max-width: 1000px)": {
      width: 35,
      height: 35,
    },
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },
  label: {
    height: "100%",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  inviteButtonAll: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    color: "#447aff",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  BglobalJSC: {
    marginTop: 24,
  },
}));
export default memo(PopupFacebook);
