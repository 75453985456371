import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { TotalComponent, DayHeader, GirdTime } from ".";
import { useHistory, useLocation } from "react-router-dom";

const Day = ({ data, htmlId, consumerCapacity, selectedDate, reservationBoxId, optionView, dataSetting }) => {
  const classes = useStyles();
  let maxRow = DEFAULT_MAX_ROW;

  const [arrayColumns, setArrayColumns] = useState([]);
  const history = useHistory();
  let location = useLocation();
  const removeDisplayDetail = () => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams) {
      searchParams.delete("start_time");
      searchParams.delete("uid");
      history.replace({
        search: searchParams.toString(),
      });
    }
  };
  const onControlHiddenColumns = selectedIndex => {
    removeDisplayDetail();
    let newArray = [...arrayColumns];
    let column = newArray[selectedIndex];
    column.isHidden = !column.isHidden;
    setArrayColumns(newArray);
  };

  useEffect(() => {
    try {
      let optimize = onOptimizeData(data);
      setArrayColumns(optimize);
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  return (
    <Box classes={{ root: classes.dayContent }} id={htmlId}>
      <Box style={{ height: HEIGHT_DAY_HEADER }}>
        <DayHeader data={arrayColumns} onControlHiddenColumns={onControlHiddenColumns} />
      </Box>
      <Box classes={{ root: classes.dayBody }} id={reservationBoxId}>
        <Box classes={{ root: classes.dayBodyWrap }}>
          {arrayColumns.map(columnData => {
            let isShowHidden = !columnData.isHidden && columnData.emptyData;
            let arrayRender = isShowHidden ? columnData.emptyData : [columnData];
            return arrayRender.map(({ reservation }, index) => (
              <GirdTime
                key={index}
                reservationList={reservation}
                maxRow={maxRow}
                selectedDate={selectedDate}
                optionView={optionView}
                dataSetting={dataSetting}
              />
            ));
          })}
        </Box>
      </Box>
      <Box style={{ height: HEIGHT_DAY_FOOTER }}>
        <Box classes={{ root: classes.dayFooter }}>
          {arrayColumns.map(columnData => {
            let isShowHidden = !columnData.isHidden && columnData.emptyData;
            let arrayRender = isShowHidden ? columnData.emptyData : [columnData];

            return arrayRender.map(({ total }, index) => (
              <Box width={WIDTH_COLUMN} key={index} borderRight={1} classes={{ root: classes.columnDayFooter }}>
                <TotalComponent total={total} consumerCapacity={consumerCapacity} />
              </Box>
            ));
          })}
        </Box>
      </Box>
    </Box>
  );
};

Day.protoType = {
  data: PropTypes.object.isRequired,
  htmlId: PropTypes.string.isRequired,
  consumerCapacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reservationBoxId: PropTypes.string,
  optionView: PropTypes.number,
};

Day.defaultProps = {
  data: {},
};

export default memo(Day);
export const HEIGHT_DAY_HEADER = 42;

const MIN_COLUMN_NUMBER = 7;
const DEFAULT_MAX_ROW = 4;

const HEIGHT_DAY_FOOTER = 40;
const WIDTH_COLUMN = 175;

const onOptimizeData = data => {
  let primaryData = Object.keys(data).map(key => {
    let cloneItem = { ...data[key] };
    cloneItem.time = key;
    return cloneItem;
  });
  if (data.length <= MIN_COLUMN_NUMBER) {
    return data;
  }

  let result = [];
  let isAllEmpty = false;
  let startEmptyColumn = -1;
  // make group empty column
  primaryData.every((columnData, index) => {
    let lengthLatestColumn = primaryData.length - index;
    let lengthResult = result.length;
    let lengthRequire = MIN_COLUMN_NUMBER - lengthResult - 1;

    // Latest column
    if (1 === lengthLatestColumn) {
      if (startEmptyColumn >= 0) {
        // Create empty column
        let emptyColumn = primaryData[startEmptyColumn];
        emptyColumn.isHidden = true;
        emptyColumn.emptyData = primaryData.slice(startEmptyColumn, index - 1);
        result.push({ ...emptyColumn });
      }

      result.push({ ...columnData });

      // Break loop
      return false;
    }

    if (lengthRequire === lengthLatestColumn) {
      if (startEmptyColumn >= 0) {
        // Create empty column
        let emptyColumn = primaryData[startEmptyColumn];
        emptyColumn.isHidden = true;
        emptyColumn.emptyData = primaryData.slice(startEmptyColumn, index - 1);
        result.push({ ...emptyColumn });
      }

      let arrayLatestColumn = primaryData.slice(index, primaryData.length);
      // When all column is empty, restructure data again
      isAllEmpty = 0 === lengthResult && arrayLatestColumn.filter(column => column.reservation.length > 0).length === 0;

      if (!isAllEmpty) {
        result = result.concat(arrayLatestColumn);
      }

      // Break loop
      return false;
    }

    if (columnData.reservation.length > 0) {
      if (startEmptyColumn >= 0) {
        if (index > startEmptyColumn) {
          // Create empty column
          let emptyColumn = primaryData[startEmptyColumn];
          emptyColumn.isHidden = true;
          emptyColumn.emptyData = primaryData.slice(startEmptyColumn, index);
          result.push({ ...emptyColumn });
        }
        startEmptyColumn = -1;
      }
      result.push({ ...columnData });
    } else if (startEmptyColumn < 0) {
      // Store start index of empty column
      startEmptyColumn = index;
    }
    return true;
  });

  if (isAllEmpty) {
    result = onOptimizeEmptyData(primaryData);
  }

  return result;
};

const onOptimizeEmptyData = data => {
  if (!data) return data;

  let result = [];
  let length = data.length;
  let stepNumber = Math.floor((2 * length) / MIN_COLUMN_NUMBER);

  let endColumn = length > MIN_COLUMN_NUMBER ? MIN_COLUMN_NUMBER : length;
  for (let run = 0; run < endColumn - 2; run++) {
    if (run % 2 !== 0) {
      let selectedIndex = ((run + 1) * stepNumber) / 2;
      result.push({ ...data[selectedIndex] });
    } else {
      let startEmptyColumn = (run * stepNumber) / 2 + 1;
      let endEmptyColumn = (run * stepNumber) / 2 + stepNumber - 1;

      let emptyColumn = data[startEmptyColumn];
      emptyColumn.isHidden = true;
      emptyColumn.emptyData = data.slice(startEmptyColumn, endEmptyColumn);
      result.push({ ...emptyColumn });
    }
  }

  let startEmptyColumn = length - stepNumber - 2;
  let emptyColumn = { ...data[startEmptyColumn] };

  emptyColumn.isHidden = true;
  emptyColumn.emptyData = data.slice(startEmptyColumn, length - 2);
  result.push({ ...emptyColumn });
  result.push({ ...data[length - 1] });

  return result;
};

const useStyles = makeStyles(theme => ({
  dayContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    width: "100%",
    height: `calc(100vh - 182px)`,
  },
  dayBody: {
    width: "fit-content",
    flexGrow: 1,
    overflowY: "auto",
    msOverflowStyle: `none`,
    scrollbarWidth: `none`,
    "&::-webkit-scrollbar": {
      display: `none`,
      width: 0,
    },
  },
  dayBodyWrap: {
    width: "100%",
    flexGrow: 1,
    display: "-webkit-box",
  },
  girdTime: {
    borderRightColor: theme.palette.grey[200],
  },
  reservationBox: {
    paddingRight: 5,
    paddingBottom: 5,
    borderBottomColor: theme.palette.grey[200],
  },
  dayFooter: {
    display: "inline-flex",
    height: "100%",
    backgroundColor: "#f1f3f6",
  },
  columnDayFooter: {
    borderRightColor: theme.palette.grey[200],
  },
}));
