import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Checkbox, Box } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

const CategoryAllMenuItem = forwardRef((props, ref) => {
  const { data, onCheck, selectedValue } = props;
  const classes = useStyles();

  const onValueCheck = event => {
    onCheck(event.currentTarget.id);
  };

  return (
    <Box className={classes.menuItemsRoot}>
      {data.map(value => (
        <Box className={classes.menuItemContainer} key={value.uid} ref={ref}>
          <Box key={value.uid} className={classes.contentBox} ref={ref} onClick={onValueCheck} id={value.uid}>
            <Checkbox
              classes={{ root: classes.checkbox, checked: classes.checked }}
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              checked={selectedValue.includes(value.uid)}
            />
            <Typography color="inherit" className="regular-md-txt">
              {value.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  menuItemsRoot: { maxHeight: 150 },
  menuItemContainer: {
    paddingLeft: 4,
  },
  contentBox: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  checkbox: {
    color: "#4b4d53",
  },
  checked: {
    color: `${theme.palette.text.link} !important`,
  },
}));

CategoryAllMenuItem.propTypes = {
  data: PropTypes.array,
  onCheck: PropTypes.func,
  selectedValue: PropTypes.array,
};

CategoryAllMenuItem.defaultProps = {
  data: [],
  onCheck: () => {},
  selectedValue: [],
};

export default memo(CategoryAllMenuItem);
