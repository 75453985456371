import React from "react";
import { makeStyles, Box, Typography, Checkbox, IconButton } from "@material-ui/core";
import { AppConstant } from "../../../../../../const";
import { CustomTimePicker, CustomDatePicker } from "../../componentDialog";
import { Add, Clear, EventAvailable, EventBusy } from "@material-ui/icons";
import { format as formatTime } from "date-fns";

const LineBodyPeriodic = ({
  data,
  index,
  isExtend,
  isLast,
  isCoincident,
  onRemoveRepeat,
  onAddRepeat,
  onChangeSelectedDate,
  onChangeSelectedTime,
}) => {
  const classes = useStyles();
  let isPast = Boolean(Date.now() > data.startTime * 1000);

  return (
    <Box className={classes.rootBody} bgcolor={isCoincident || isPast ? (isExtend ? "#fee9da" : "#d9e8ff") : "inherit"}>
      <Box height="22px" width="164px" marginTop="4px">
        <Typography className={!isPast ? classes.typoCode : classes.typoCodePast}>{data.code}</Typography>
      </Box>
      <Box height="22px" width="191px" display="flex" marginTop="4px">
        <Box width="95px">
          {!isExtend ? (
            <Typography className={!isPast ? classes.typoTime : classes.typoPast}>
              {formatTime(new Date(data.startTime * 1000), AppConstant.FM_DD_MM_YYYY)}
            </Typography>
          ) : (
            <CustomDatePicker onChange={onChangeSelectedDate} selectedDateProp={data.startTime * 1000} index={index} />
          )}
        </Box>
        <Box width="96px">
          {!isExtend ? (
            <Typography className={!isPast ? classes.typoTime : classes.typoPast}>
              {formatTime(new Date(data.startTime * 1000), AppConstant.FM_HH_MM)}
            </Typography>
          ) : (
            <CustomTimePicker onChange={onChangeSelectedTime} selectedTimeProp={data.startTime * 1000} index={index} />
          )}
        </Box>
      </Box>
      <Box width="58px" marginTop="4px" textAlign="center">
        {!isExtend ? (
          isPast ? (
            <Checkbox
              checked={data.status !== AppConstant.STT_RESERVATION.pending}
              disabled
              classes={{
                root: classes.checkbox,
                disabled: classes.disabled,
                checked: classes.checked,
                colorPrimary: classes.colorPrimary,
              }}
              color="primary"
              size="small"
            />
          ) : (
            <Checkbox
              checked={data.status !== AppConstant.STT_RESERVATION.pending}
              classes={{
                root: classes.checkbox,
                disabled: classes.disabled,
                checked: classes.checked,
                colorPrimary: classes.colorPrimary,
              }}
              color="primary"
              size="small"
            />
          )
        ) : (
          !isPast &&
          (data.available ? (
            <EventAvailable className={classes.eventAvailable} />
          ) : (
            <EventBusy className={classes.eventBusy} />
          ))
        )}
      </Box>
      <Box width="73px">
        {isExtend && (
          <Box display="flex" justifyContent="flex-end">
            {isLast && (
              <Box marginRight="8px">
                <IconButton onClick={() => onAddRepeat()} className={classes.iconButtonExtend}>
                  <Add className={classes.iconExtend} />
                </IconButton>
              </Box>
            )}
            <Box>
              <IconButton onClick={() => onRemoveRepeat(index)} className={classes.iconButtonExtend}>
                <Clear className={classes.iconExtend} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LineBodyPeriodic;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    display: "block",
    "@media only screen and (max-width: 375px)": {
      width: 660,
      overflowX: "scroll",
    },
  },
  rootBody: {
    display: "flex",
    height: 30,
    marginTop: 3,
    padding: "0px 20px 0px 24px",
  },
  checkbox: {
    padding: 0,
    marginTop: 1,
    cursor: "unset",
  },
  disabled: {
    opacity: 0.3,
    color: theme.palette.text.link,
  },
  checked: {
    "&$disabled": {
      color: "#ef5845",
      opacity: 0.3,
    },
  },
  colorPrimary: {
    "&$disabled": {
      opacity: 0.3,
    },
  },
  eventAvailable: {
    fontSize: 18,
    marginTop: 1,
    color: theme.palette.reservation.active,
  },
  eventBusy: {
    fontSize: 18,
    marginTop: 1,
    color: "#feba40",
  },
  typoCode: {
    fontSize: 13,
    fontWeight: 500,
    padding: "3px 0px",
  },
  typoCodePast: {
    fontSize: 13,
    fontWeight: 500,
    opacity: 0.3,
    padding: "3px 0px",
  },
  typoTime: {
    fontSize: 13,
    fontWeight: 400,
    padding: "3px 0px",
  },
  typoPast: {
    fontSize: 13,
    fontWeight: 400,
    opacity: 0.3,
    padding: "3px 0px",
  },
  iconButtonExtend: {
    padding: 0,
    width: 18,
    height: 18,
    marginTop: 6,
  },
  iconExtend: {
    width: 16,
    height: 16,
  },
}));
