export default {
  TXT_NEW_ORDER_INFOR: "Thông tin giao hàng",
  TXT_RECEIVER_ADDRESS: "Địa chỉ nhận hàng",
  TXT_EMPTY_RECEIVER_ADDRESS_WARNING: "Bạn chưa điền địa chỉ nhận hàng",
  TXT_ORDER_CONTINUE: "Tiếp tục",
  TXT_SELECT_ITEM_OPTION: "Chọn kiểu thêm item",
  TXT_UPDATE_ITEM_OPTION: "Chọn kiểu chỉnh sửa item",
  TXT_ADD_FROM_MENU: "Thêm từ Menu (Đã chọn)",
  TXT_ADD_NEW_ITEM_FROM_MENU: "Thêm từ Menu",
  TXT_ADD_FROM_CATEGORY: "Thêm từ danh mục sản phẩm",
  TXT_CODE_ORDER: "Mã đặt hàng",
  TXT_TOTAL_PRICE_ORDER: "Tổng giá",
  TXT_BOOKING_DATE_ORDER: "Ngày đặt",
  TXT_NAME_CONSUMER_ORDER: "Khách hàng",
  TXT_ADDRESS_ORDER: "Địa chỉ",
  TXT_STATUS_ORDER: "Trạng thái",
  TXT_CANCEL_ORDER: "Hủy",
  TXT_ORDER_DETAIL: "Chi tiết đơn hàng",
  TXT_ORDER_ITEM: "Sản phẩm",
  TXT_ACCOMPANIED_ITEM: "Sản phẩm đi kèm",
  TXT_ORDER_ITEM_AMOUNT: "Số lượng",
  TXT_ITEM_PRICE: "Đơn giá",
  TXT_TOTAL_PRICE: "Tổng giá",
  TXT_ADD_ITEMS: "Thêm sản phẩm",
  TXT_RECEIVER_INFO: "Thông tin người nhận",
  TXT_ORDER_NOTE: "Ghi chú đơn hàng",
  TXT_EMPTY_NAME_WARNING: "Bạn cần điền đầy đủ tên người nhận",
  TXT_INVALID_PHONE_WARNING: "Số điện thoại chưa chính xác",
  TXT_EMPTY_ADDRESS_WARNING: "Bạn cần điền đầy đủ thông tin người nhận",
  TXT_RECEIVER_NAME: "Tên người nhận",
  TXT_SUCCESS_POST_ORDER: "Đơn hàng của bạn đã được thêm thành công",
  TXT_EDIT_SHIPPING_INFO: "Chỉnh sửa thông tin giao hàng",
  TXT_NEW_ITEM_FROM_MENU: "Thêm mới Item từ Menu",
  TXT_NEW_ITEM_FROM_CATEGORY: "Thêm mới Item từ Danh mục sản phẩm",
  TXT_ADD_ITEM_FROM_MENU: "Thêm Item từ Menu",
  TXT_ADD_ITEM_FROM_CATEGORY: "Thêm Item từ Danh mục sản phẩm",
  TXT_UPDATE_ORDER: "Chỉnh sửa đơn hàng",
  TXT_CART: "Giỏ hàng",
  TXT_DEPOSIT_METHOD: "Phương thức thanh toán",
  TXT_SHIP_AWAY: "Giao tận nơi",
  TXT_TAKE_AWAY: "Tự đến lấy",
  TXT_CASH: "Tiền mặt",
  TXT_INTERNET_BANKING: "Chuyển khoản",

  P_EMPTY_NOTE: "Không có ghi chú",

  FM_CHANGE_STATUS_ORDER: `Chuyển trạng thái đơn hàng "{0}"`,
  FM_REMOTE_ORDER: "Đơn hàng: {0} ",
  FM_ORDER_TIME: "Thời gian: {0}",
};
