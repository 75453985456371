import React from "react";
import { SvgIcon } from "@material-ui/core";
import PropTypes from "prop-types";

const FileUploadIcon = ({ className, width, height }) => {
  return (
    <SvgIcon className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM7 9L8.41 10.41L11 7.83V16H13V7.83L15.59 10.41L17 9L12 4L7 9Z"
        fill="#6C7078"
      />
    </SvgIcon>
  );
};
FileUploadIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
FileUploadIcon.defaultProps = {
  width: 24,
  height: 24,
};
export default FileUploadIcon;
