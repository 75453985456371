import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import ServiceItemAction from "redux/service-item.redux";
import MnProductAction from "redux/mn-product.redux";
import { DialogAddCategory, DialogDelete } from "components/dialog";
import MenuAction from "redux/menu.redux";
import { AppConstant, LangConstant } from "const";
import {
  DialogAddCategorySelectMenu,
  DialogAddItemMenu,
  DialogAddCategoryMenu,
  DialogSelectProduct,
  CategorySubCategoryMenu,
} from "./index";

const MainCategoryAll = ({ onEnableButton }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();

  const [isOpenDialogAddCategory, setIsOpenDialogAddCategory] = useState(false);
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [dataDialogAddCategorySelectMenu, setDataDialogAddCategorySelectMenu] = useState(null);
  const [dataDialogAddItemMenu, setDataDialogAddItemMenu] = useState(null);
  const [isOpen, setIsOpen] = useState({
    selectProduct: false,
    addCategoryMenu: false,
    addFromMainService: false,
    addItemMenu: false,
  });
  const [dataDialog, setDataDialog] = useState([]);
  const [nameCategory, setNameCategory] = useState("");
  const [line, setLine] = useState();
  const [dataMenuDisplay, setDataMenuDisplay] = useState([]);
  const [dataMenuDefault, setDataMenuDefault] = useState([]);

  const [dataDefault, dataDisplay, dataOrderDefault, dataOrderDisplay, isTabBooking] = useSelector(
    ({ menuRedux }) => [
      menuRedux.dataDefault,
      menuRedux.dataDisplay,
      menuRedux.dataOrderDefault,
      menuRedux.dataOrderDisplay,
      menuRedux.isTabBooking,
    ],
    shallowEqual,
  );
  const dataCategoryService = useSelector(state => state.serviceCategoryRedux?.data);
  const dataServiceItem = useSelector(state => state.serviceItemRedux?.dataGetListServiceItem?.data);
  const dataCategoryServiceItem = useSelector(state => state.serviceItemRedux.dataCategoryServiceItem);
  const isGetCategoryServiceItemSuccess = useSelector(state => state.serviceItemRedux.isGetCategoryServiceItemSuccess);
  const dataCategory = useSelector(state => state.mnProductRedux.data);
  const listDataItem = useSelector(state => state.mnProductRedux.dataItem);
  const dataItemUidCategory = useSelector(state => state.mnProductRedux.dataItemUidCategory);
  const isGetItemUidCategory = useSelector(state => state.mnProductRedux.isGetSuccessItemUidCategory);
  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem);

  const onClickCategorySubcategoryMenu = (value, index) => {
    if (value === ACTION_MENU.edit) {
      setDataDialog(dataMenuDisplay.menuList[index]);
      setIsOpenDialogAddCategory(true);
      setLine(index);
    } else if (value === ACTION_MENU.delete) {
      setDataDialog(dataMenuDisplay.menuList[index]);
      setLine(index);
      setIsOpenDialogDelete(true);
    } else if (value === ACTION_MENU.add) {
      setLine(index);
      setDataDialog(dataMenuDisplay.menuList[index]);
      setIsOpen({ ...isOpen, selectProduct: true });
    }
  };

  const onClose = () => {
    setIsOpenDialogAddCategory(false);
    setIsOpenDialogDelete(false);
    setIsOpen({ selectProduct: false, addCategoryMenu: false, addFromMainService: false, addItemMenu: false });
  };

  const onChangeNameCategory = value => {
    setNameCategory(value);
  };

  const onSubmitCategory = () => {
    dataMenuDefault.menuList[line] = { ...dataMenuDefault.menuList[line], name: nameCategory };
    dataMenuDisplay.menuList[line] = { ...dataMenuDisplay.menuList[line], name: nameCategory };
    if (isTabBooking) {
      dispatch(MenuAction.menuSuccess({ dataDefault: dataMenuDefault, dataDisplay: dataMenuDisplay }));
    } else {
      dispatch(MenuAction.menuSuccess({ dataOrderDefault: dataMenuDefault, dataOrderDisplay: dataMenuDisplay }));
    }
    setIsOpenDialogAddCategory(false);
    setDataDialog({ name: "" });
    onEnableButton(true);
  };

  const onConfirmRemoveProduct = () => {
    var dataNew = [],
      dataNewDisplay = [];
    dataNew = dataMenuDefault.menuList.filter((_, index) => index !== line);
    dataNewDisplay = dataMenuDisplay.menuList.filter((_, index) => index !== line);
    if (isTabBooking) {
      dispatch(
        MenuAction.menuSuccess({
          dataDisplay: { ...dataMenuDisplay, menuList: dataNewDisplay },
          dataDefault: { ...dataMenuDefault, menuList: dataNew },
        }),
      );
    } else {
      dispatch(
        MenuAction.menuSuccess({
          dataOrderDisplay: { ...dataMenuDisplay, menuList: dataNewDisplay },
          dataOrderDefault: { ...dataMenuDefault, menuList: dataNew },
        }),
      );
    }
    setIsOpenDialogDelete(false);
    onEnableButton(true);
  };

  const onCloseAccompaniedProduct = value => {
    setIsOpen({ ...isOpen, selectProduct: value });
  };

  const onCloseDialogAddCategoryMenu = value => {
    setIsOpen({ ...isOpen, selectProduct: value, addCategoryMenu: value });
  };

  const onCloseDialogAddCategorySelectMenu = () => {
    setIsOpen({ ...isOpen, selectProduct: false, addCategoryMenu: false, addFromMainService: false });
  };

  const onCreateService = () => {
    if (Object.values(dataCategoryService).length > 0) {
      setIsOpen({ ...isOpen, addCategoryMenu: true, service: true });
    } else {
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_SERVICE_MENU),
        nameParent: null,
        nameChild: null,
        addItemMenu: true,
        combo: false,
        service: true,
        type: AppConstant.TYPE_CATEGORY.service,
      });
      setDataDialogAddItemMenu(dataServiceItem);
    }
  };

  const onCreateProduct = () => {
    if (dataCategory?.data.length > 0) {
      setIsOpen({ ...isOpen, addCategoryMenu: true, service: false });
    } else {
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_GOODS_MENU),
        nameParent: null,
        nameChild: null,
        addItemMenu: true,
        combo: false,
        service: false,
        type: AppConstant.TYPE_CATEGORY.product,
      });
      setDataDialogAddItemMenu(listDataItem.data);
    }
  };

  const onCreateCombo = () => {
    setIsOpen({
      ...isOpen,
      nameTable: getLabel(LangConstant.TXT_ADD_COMBO_MENU),
      nameParent: null,
      nameChild: null,
      addItemMenu: true,
      combo: true,
      service: false,
      type: AppConstant.TYPE_CATEGORY.combo,
    });
    setDataDialogAddItemMenu(listComboItem.data);
  };

  const onAddAllService = () => {
    if (isOpen.service) {
      setIsOpen({
        ...isOpen,
        addItemMenu: true,
        nameTable: getLabel(LangConstant.TXT_ADD_SERVICE_MENU),
        combo: false,
        type: AppConstant.TYPE_CATEGORY.service,
      });
      setDataDialogAddItemMenu(dataServiceItem);
    } else {
      setIsOpen({
        ...isOpen,
        addItemMenu: true,
        nameTable: getLabel(LangConstant.TXT_ADD_GOODS_MENU),
        combo: false,
        type: AppConstant.TYPE_CATEGORY.product,
      });
      setDataDialogAddItemMenu(listDataItem.data);
    }
  };

  const onAddCategoryService = () => {
    if (isOpen.service) {
      setIsOpen({ ...isOpen, addFromMainService: true, service: true });
      setDataDialogAddCategorySelectMenu(Object.values(dataCategoryService));
    } else {
      setIsOpen({ ...isOpen, addFromMainService: true, service: false });
      setDataDialogAddCategorySelectMenu(dataCategory.data);
    }
  };

  const onBackDialogAddCategorySelectMenu = () => {
    setIsOpen({ ...isOpen, addFromMainService: false });
  };

  const onBackDialogAddItemMenu = () => {
    setIsOpen({ ...isOpen, addItemMenu: false });
  };

  const onBackDialogAddCategoryMenu = () => {
    setIsOpen({ ...isOpen, addCategoryMenu: false });
  };

  const onClickCategoryMenu = (data, dataParent) => {
    if (isOpen.service) {
      dispatch(ServiceItemAction.resetServiceItem());
      dispatch(ServiceItemAction.getCategoryServiceItem({ categoryUid: data.uid }));
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_SERVICE_MENU),
        nameParent: dataParent.name || "",
        nameChild: data.name,
      });
    } else {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItemUidCategory({ categoryUid: data.uid }));
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_GOODS_MENU),
        nameParent: dataParent.name || "",
        nameChild: data.name,
      });
    }
  };

  useEffect(() => {
    if (isGetCategoryServiceItemSuccess) {
      setDataDialogAddItemMenu(dataCategoryServiceItem);
      setIsOpen({ ...isOpen, addItemMenu: true, combo: false, type: AppConstant.TYPE_CATEGORY.service });
    }
    if (isGetItemUidCategory) {
      setDataDialogAddItemMenu(dataItemUidCategory.data);
      setIsOpen({ ...isOpen, addItemMenu: true, combo: false, type: AppConstant.TYPE_CATEGORY.product });
    }
  }, [isGetCategoryServiceItemSuccess, isGetItemUidCategory]);

  useEffect(() => {
    if (Boolean(dataDisplay.menuList) && isTabBooking) {
      setDataMenuDisplay(dataDisplay);
      setDataMenuDefault(dataDefault);
    } else if (Boolean(dataOrderDisplay.menuList && !isTabBooking)) {
      setDataMenuDisplay(dataOrderDisplay);
      setDataMenuDefault(dataOrderDefault);
    }
  }, [dataDisplay, dataOrderDisplay, dataMenuDisplay, isTabBooking]);

  return (
    <Box className={classes.barRoot}>
      <Box className={classes.boxBody}>
        {Boolean(dataMenuDisplay.menuList) &&
          dataMenuDisplay.menuList.map((item, index) => (
            <CategorySubCategoryMenu
              classes={{ root: classes.boxShadowMenu }}
              key={index}
              data={item}
              index={index}
              onClick={onClickCategorySubcategoryMenu}
            />
          ))}
      </Box>
      {isOpenDialogAddCategory && (
        <DialogAddCategory
          isShow={isOpenDialogAddCategory}
          data={dataDialog}
          onClose={onClose}
          onChange={onChangeNameCategory}
          onSubmit={onSubmitCategory}
        />
      )}
      {isOpenDialogDelete && (
        <DialogDelete
          uid={dataDialog?.key || dataDialog.uid}
          isShow={isOpenDialogDelete}
          onCancel={onClose}
          onConfirm={onConfirmRemoveProduct}
          title={StringFormat(getLabel(LangConstant.FN_DELETE_CATEGORY_MENU_TITLE), dataDialog?.name)}
          content={StringFormat(getLabel(LangConstant.FN_DELETE_CATEGORY_MENU_CONTENT), dataDialog?.name)}
        />
      )}
      {isOpen.selectProduct && (
        <DialogSelectProduct
          isShow={isOpen.selectProduct}
          titleName={{
            header: getLabel(LangConstant.TXT_SELECT_PRODUCT_TYPE),
            service: getLabel(LangConstant.TXT_SERVICE),
            product: getLabel(LangConstant.TXT_PRODUCT),
            combo: getLabel(LangConstant.TXT_COMBO),
          }}
          action={onCloseAccompaniedProduct}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddService={onCreateService}
          onAddProduct={onCreateProduct}
          onAddCombo={onCreateCombo}
        />
      )}
      {isOpen.addCategoryMenu && (
        <DialogAddCategoryMenu
          isShow={isOpen.addCategoryMenu}
          titleName={{
            header: isOpen.service
              ? getLabel(LangConstant.TXT_SELECT_ADD_SERVICE)
              : getLabel(LangConstant.TXT_SELECT_ADD_PRODUCT),
            add: isOpen.service
              ? getLabel(LangConstant.TXT_SELECT_ADD_ALL_SERVICE)
              : getLabel(LangConstant.TXT_SELECT_ADD_ALL_PRODUCT),
            created: isOpen.service
              ? getLabel(LangConstant.TXT_SELECT_ADD_CATEGORY_SERVICE)
              : getLabel(LangConstant.TXT_SELECT_ADD_CATEGORY_PRODUCT),
          }}
          onClose={onCloseDialogAddCategoryMenu}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddAllService={onAddAllService}
          onAddCategoryService={onAddCategoryService}
          onBack={onBackDialogAddCategoryMenu}
        />
      )}
      {isOpen.addFromMainService && (
        <DialogAddCategorySelectMenu
          data={dataDialogAddCategorySelectMenu}
          isOpen={isOpen.addFromMainService}
          onClose={onCloseDialogAddCategorySelectMenu}
          onBack={onBackDialogAddCategorySelectMenu}
          onClick={onClickCategoryMenu}
          nameHeader={getLabel(LangConstant.TXT_SELECT_CATEGORY)}
          isChildDefault={false}
        />
      )}
      {isOpen.addItemMenu && (
        <DialogAddItemMenu
          isOpen={isOpen.addItemMenu}
          onClose={onClose}
          data={dataDialogAddItemMenu}
          nameTable={isOpen.nameTable}
          nameParent={isOpen.nameParent}
          nameChild={isOpen.nameChild}
          onBack={onBackDialogAddItemMenu}
          lineData={line}
          isProduct={isOpen.service}
          isCombo={isOpen.combo}
          type={isOpen.type}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  barRoot: {
    width: "100%",
  },
  boxBody: {
    minHeight: "calc(100vh - 380px)",
  },
  tabSelect: {
    paddingTop: 30,
    paddingLeft: 30,
    width: "100%",
  },
  checkBox: {
    marginRight: 100,
  },
  dialogAddTypo: {
    color: "#3e4045",
    lineHeight: 1.5,
    fontWeight: "bold",
  },
  boxShadowMenu: {
    display: "flex",
    with: "80%",
    height: 40,
    alignItems: "center",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: 20,
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: 20,
    border: "1px solid" + theme.palette.grey[200],
  },
}));

MainCategoryAll.propTypes = {
  onClick: PropTypes.func,
};

export default MainCategoryAll;

const ACTION_MENU = { add: "add", edit: "edit", delete: "delete" };
