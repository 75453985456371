export default {
  TXT_ACTIVITY_TYPE: "Loại hành động",
  TXT_ACTIVITY_CREATE: "Thêm mới",
  TXT_ACTIVITY_EDIT: "Chỉnh sửa",
  TXT_ACTIVITY_CHANGE: "Chuyển trạng thái",
  TXT_ACTIVITY_ASSIGN: "Chỉ định",
  TXT_ACTIVITY_COPY: "Sao chép",
  TXT_ACTIVITY_EXTEND: "Mở rộng",
  TXT_ACTIVITY_CHANGE_ORDER: "Chỉnh sửa order",
  TXT_ACTIVITY_TIME: "Thời gian",
  TXT_ACTIVITY_CODE: "Mã đặt chỗ",
  TXT_ACTIVITY_DETAIL: "Chi tiết hành động",
  TXT_ACTIVITY_UPDATED_NAME: "tên khách hàng",
  TXT_ACTIVITY_UPDATED_EMAIL: "email",
  TXT_ACTIVITY_UPDATED_CONSUMER: "số người",
  TXT_ACTIVITY_UPDATED_NOTE: "ghi chú",
  TXT_ACTIVITY_UPDATED_PHONE: "số điện thoại",
  TXT_ACTIVITY_UPDATED_ADDRESS: "địa chỉ",
  TXT_ACTIVITY_UPDATED_INTERNAL_NOTE: "ghi chú nội bộ",
  TXT_ACTIVITY_UPDATED_PLACE_CATEGORY: "vị trí",
  TXT_ACTIVITY_UPDATED_PLACE_ITEM: "vị trí",
  TXT_ACTIVITY_UPDATED_SERVICE_CATEGORY: "dịch vụ",
  TXT_ACTIVITY_UPDATED_SERVICE_ITEM: "dịch vụ",
  TXT_ACTIVITY_UPDATED_START_TIME: "ngày đặt",
  TXT_ACTIVITY_UPDATED_STAFF_NAME: "nhân viên",
  TXT_ACTIVITY_UPDATED_REALITY_START_TIME: "thời gian bắt đầu thực tế",
  TXT_ACTIVITY_UPDATED_REALITY_END_TIME: "thời gian kết thúc thực tế",

  FM_ACTIVITY_DO_CREATE: `<p><span style="font-weight:bold;">{0}</span> đã thêm mới 1 đơn đặt chỗ</p>`,
  FM_ACTIVITY_DO_EDIT: `<p><span style="font-weight:bold;">{0}</span> đã chỉnh sửa <span style="font-weight:bold;">{1}</span> của đơn đặt chỗ <span style="font-weight:bold;">{2}</span></p>`,
  FM_ACTIVITY_DO_CHANGE: `<p><span style="font-weight:bold;">{0}</span> đã chuyển trạng thái của đơn đặt chỗ <span style="font-weight:bold;">{1}</span> thành <span style="font-weight:bold;">{2}</span></p>`,
  FM_ACTIVITY_DO_EDIT_ORDER: `<p><span style="font-weight:bold;">{0}</span> đã chỉnh sửa order của đơn đặt chỗ <span style="font-weight:bold;">{1}</span> </p>`,
  FM_ACTIVITY_DO_ASSIGN: `<p><span style="font-weight:bold;">{0}</span> đã chỉ định <span style="font-weight:bold;">{1}</span> cho đơn đặt chỗ <span style="font-weight:bold;">{2}</span></p>`,
  FM_ACTIVITY_DO_COPY: `<p><span style="font-weight:bold;">{0}</span> đã sao chép thông tin từ đơn đặt chỗ <span style="font-weight:bold;">{1}</span></p>`,
  FM_ACTIVITY_DO_EXTEND: `<p><span style="font-weight:bold;">{0}</span> đã tạo 1 đơn đặt chỗ mới từ việc mở rộng đơn đặt chỗ <span style="font-weight:bold;">{1}</span></p>`,
};
