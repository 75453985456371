import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { CropFree } from "@material-ui/icons";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";

const ImageCell = props => {
  const { onRemove, onOpenCrop, fileArr } = props;
  const { t: getLabel } = useTranslation();

  const [hoveredIndex, setHoveredIndex] = useState();

  const classes = useStyle({ showRemove: hoveredIndex });

  return (
    <>
      {fileArr &&
        fileArr.map((file, index) => (
          <Box
            key={index}
            id={index}
            className={classes.singleImageCell}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <img
              id={URL.createObjectURL(file)}
              multiple
              src={URL.createObjectURL(file)}
              alt="..."
              className={classes.imgPreviewThumbnail}
            />
            {index === hoveredIndex && (
              <Box className={classes.hoverBox}>
                <Box className={classes.cropButton} onClick={() => onOpenCrop(hoveredIndex)}>
                  <CropFree className={classes.cropIcon} />
                  <Typography className="medium-sm-txt">{getLabel(LangConstant.TXT_EDIT)}</Typography>
                </Box>
                <ClearIcon
                  onClick={() => {
                    onRemove(index);
                  }}
                  className={classes.removeIcon}
                />
              </Box>
            )}
          </Box>
        ))}
    </>
  );
};

export default ImageCell;
const useStyle = makeStyles(() => ({
  removeIcon: {
    position: "absolute",
    right: "6%",
    top: "6%",
    cursor: "pointer",
    width: "1vw",
    height: "1vw",
    color: "#ffffff",
    display: "unset",
  },
  singleImageCell: {
    height: "5vw",
    width: "7vw",
    marginRight: "10px",
    position: "relative",
    display: "flex",
    "@media only screen and (max-width: 414px)": {
      height: "6vh",
      width: "15vw",
    },
  },
  imgPreviewThumbnail: {
    width: "100%",
    height: "100%",
  },
  hoverBox: {
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "rgb(0, 0, 0, 0.7)",
  },
  cropButton: {
    display: "flex",
    height: 30,
    position: "absolute",
    width: "fit-content",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&>*": {
      color: "white",
    },
  },
  cropIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
  },
}));
