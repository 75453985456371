import React, { useEffect, useState } from "react";
import { LangConstant, AppConstant, PathConstant, ApiConstant } from "const";
import { Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { GbossTxtBlackLogo } from "theme/images";
import { Typography, ListItemText, Grid, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookie from "js-cookie";
import AuthAction from "redux/auth.redux";
import ShopAction from "redux/shop.redux";
import UserAction from "redux/user.redux";
import GroupAdminAction from "redux/group-admin.redux";
import { removeAllCookie, getCookiesDataShopSelect } from "utils";

const Shop = () => {
  const appDataLogin = Cookie.get(AppConstant.KEY_GBOSS);
  const nameLogin = Cookie.get(AppConstant.NAME_LOGIN);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { dataShop } = useSelector(state => state.shopRedux);
  const { dataUser } = useSelector(state => state.userRedux);
  const dataGroupAdmin = useSelector(({ groupAdminRedux }) => groupAdminRedux.dataGroup, shallowEqual);
  const isJoinGroupSuccess = useSelector(({ groupAdminRedux }) => groupAdminRedux.isJoinGroupSuccess);
  const isGroup = useSelector(({ groupAdminRedux }) => groupAdminRedux.isJoinGroupAdmin);
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let isSelectedShop = appDataLogin && JSON.parse(appDataLogin).token;

  var toBack = Boolean(searchParams.get(TO_BACK));

  const [switchAble, setSwitchAble] = useState(!toBack);

  const onBack = () => {
    history.goBack();
  };

  const onClose = () => {
    removeAllCookie(Cookie);
    history.push(PathConstant.LOGIN);
    if (isGroup) dispatch(GroupAdminAction.groupAdminSet({ isJoinGroupAdmin: false }));
  };

  const onClick = item => {
    if (isGroup) {
      dispatch(GroupAdminAction.joinGroupAdmin({ uid: dataGroupAdmin.uid, token: item.access_token }));
    } else {
      setSwitchAble(true);
      dispatch(AuthAction.requestSelectShop(item));
      dispatch(ShopAction.requestGetShopInfo({ data: { shopId: item.shop_id } }));
      dispatch(UserAction.requestGetUserInfo({ data: { userId: item.user_id } }));
    }
  };

  let token = null;
  try {
    if (appDataLogin && appDataLogin.length > 0) {
      token = JSON.parse(appDataLogin).token;
    }
  } catch (error) {
    console.log(error);
  }

  let data = getCookiesDataShopSelect();

  useEffect(() => {
    if (Boolean(token) && switchAble && data.length > 0) {
      history.replace(PathConstant.MANAGE_BOOKS);
    }
  }, [dataShop, dataUser]);

  useEffect(() => {
    if (isJoinGroupSuccess) {
      history.replace(PathConstant.JOIN_GROUP_ADMIN);
      dispatch(GroupAdminAction.groupAdminSet({ isJoinGroupAdmin: false }));
    }
  }, [isJoinGroupSuccess]);

  return (
    <form>
      <Box className={classes.backItem}>
        <Grid container className={classes.selectShopRoot}>
          <Box className={classes.iconBar}>
            <a href={isSelectedShop && PathConstant.MANAGE_BOOKS}>
              <Avatar variant="square" src={GbossTxtBlackLogo} alt="gboss logo icon" className={classes.iconLogo} />
            </a>
            {toBack && (
              <IconButton className={classes.iconBack} onClick={onBack}>
                <Close />
              </IconButton>
            )}
          </Box>

          <Box flexGrow={1} className={classes.formSelect}>
            <Box className="auth-intro">
              <Avatar variant="square" src={GbossTxtBlackLogo} alt="gboss logo" className={classes.logo} />
              <Typography className={classes.shopSelectTxt} color="inherit">
                {getLabel(LangConstant.MSG_SHOP_SELECT)}
              </Typography>
            </Box>
            <Typography className={classes.shopSelectConvertTxt} color="inherit">
              {getLabel(LangConstant.MSG_SHOP_SELECT_SMALL)} {nameLogin}
              <Button className={classes.button} onClick={onClose}>
                {getLabel(LangConstant.MSG_SHOP_SELECT_CONVERT)}
              </Button>
            </Typography>
            <Box className={classes.listItem}>
              {data.map((item, index) => (
                <Grid key={index} item xs={12} sm={8} className={classes.pageAvaNameSingleRow}>
                  <Avatar
                    src={item.avatar ? `${ApiConstant.HOST_ATTACHMENT}${item.avatar}` : ""}
                    className={classes.avatar}
                  />
                  <ListItemText
                    primary={<Typography className={classes.pagesName}>{item.name}</Typography>}
                    secondary={
                      <Typography className={classes.pagesNamePosition}>
                        {getLabel(LangConstant.ROLE_LANG[item.role])}
                      </Typography>
                    }
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.buttonChoose}
                    onClick={() => onClick(item)}
                  >
                    {getLabel(LangConstant.TXT_SELECT_SHOP_CHOOSE)}
                  </Button>
                </Grid>
              ))}
            </Box>
          </Box>
        </Grid>
      </Box>
    </form>
  );
};

export const TO_BACK = "toBack";

const useStyles = makeStyles(theme => ({
  iconBar: {
    width: "100%",
    minHeight: 45,
    display: "flex",
    justifyContent: "space-between",
  },
  iconLogo: {
    height: 70,
    width: 70,
    marginLeft: 24,
  },
  iconBack: {
    height: 70,
    width: 70,
    background: "white",
    marginRight: 24,
  },
  listItem: { display: "inline-table" },
  formSelect: {
    width: "100%",
  },
  pageAvaNameSingleRow: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    overflow: "ellipsis",
    padding: "2vh",
    minWidth: "643px ",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)",
    marginBottom: "24px ",
    paddingBottom: "2.5vh",
    borderRadius: "5px",
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      minWidth: "100%",
      marginLeft: "10px",
      marginRight: "10px",
      marginBottom: "16px ",
    },
  },
  avatar: {
    width: "48px",
    height: "48px",
  },
  pagesName: {
    marginLeft: "16px",
    "@media only screen and (min-width: 643px)": {
      maxWidth: "643px !important",
      minWidth: "250px !important",
      textOverflow: "ellipsis !important",
      overflow: "hidden",
      whiteSpace: "nowrap !important",
    },
    overflow: "hidden",
    width: "calc((100% - 20px) / 4)",
    textOverflow: "ellipsis !important",
    fontSize: "16px",
    fontWeight: "500",
    whiteSpace: "nowrap !important",
    minWidth: "140px",
    maxWidth: "480px",
    color: "#3e4045",
  },
  pagesNamePosition: {
    marginLeft: "16px",
    fontSize: "12px",
    color: "#7f838c",
  },
  buttonChoose: {
    fontWeight: theme.typography.h5.fontWeight,
    minWidth: "68px",
    height: "30px",
    fontSize: "14px",
    backgroundColor: "#ef5845",
    borderRadius: "20px",
    border: "1px #ef5845",
    color: "#ffffff",
    textAlign: " center",
    paddingTop: "5px",
    textTransform: "none",
    marginRight: "5px",
  },
  selectShopRoot: {
    position: "relative",
    width: "100%",
    height: "100vh",
    background: "white",
    textAlign: "center",
    display: "flex",
    overflow: "auto",
    justifyContent: "center",
    [theme.breakpoints.down(600)]: {
      maxWidth: "100%",
      minWidth: "100%",
      marginBottom: "16px ",
      paddingTop: "25px",
    },
  },
  logo: {
    width: "189px",
    height: "55px",
    marginBottom: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    textTransform: " none",
    color: " #447aff",
    fontSize: "14px",
    paddingBottom: "0.5%",
    "@media only screen and (max-width: 643px)": {
      paddingBottom: "2%",
    },
  },

  shopSelectTxt: {
    fontSize: "24px ",
    fontWeight: "500 ",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "1vh",
    wordBreak: "break-word",
  },
  shopSelectConvertTxt: {
    fontSize: "14px ",
    fontWeight: "500 ",
    paddingLeft: "20px",
    paddingRight: "20px",
    wordBreak: "break-word",
    paddingBottom: "2.5vh",
  },
}));

export default Shop;
