import "date-fns";
import React, { memo, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import viLocale from "date-fns/locale/vi";
import enLocale from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "../../const";
import PropTypes from "prop-types";
import { format } from "date-fns";

const InputTime = ({ onChange, placeholder, parentClass, name, initialTime, overrideInputClass, ...otherProps }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [selectedTime, setSelectedTime] = useState(initialTime);
  const [isOpen, setIsOpen] = useState(false);

  const onChangeTime = time => {
    setSelectedTime(time);
    onChange(name, format(time, AppConstant.FM_HH_MM));
  };

  const onClick = event => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <Box onClick={e => onClick(e)} className={parentClass}>
        <TimePicker
          disableToolbar
          value={selectedTime}
          minutesStep={15}
          onChange={onChangeTime}
          InputProps={{
            disableUnderline: true,
            placeholder: placeholder,
            className: overrideInputClass || "regular-md-txt",
          }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          className={classes.input}
          ampm={false}
          {...otherProps}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

InputTime.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  parentClass: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  initialTime: PropTypes.any,
};

InputTime.defaultProps = {
  variant: "inline",
  initialTime: null,
};

const useStyles = makeStyles(theme => ({
  input: {
    borderColor: theme.palette.grey["200"],
    "&:hover": {
      borderColor: theme.palette.black,
    },
    marginRight: 10,
  },
}));

const localeMap = {};
localeMap[LangConstant.EN_LANG] = enLocale;
localeMap[LangConstant.DEFAULT_LANG] = viLocale;

export default memo(InputTime);
