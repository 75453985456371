import React, { createRef, useState, useEffect } from "react";
import { PathConstant, LangConstant } from "../const";
import { Typography, Link as MuiLink, Box, Button } from "@material-ui/core";
import AuthLayout from "../layouts/AuthLayout";
import { useTranslation } from "react-i18next";
import { FormError, DialogSuccess, FormInput, Processing } from "../components";
import { EmailOutlined } from "@material-ui/icons";
import { validateEmail, isSttException } from "../utils";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AuthAction from "../redux/auth.redux";

const ForgotPassword = () => {
  const { t: getLabel } = useTranslation();
  const submitRef = createRef();
  const dispatch = useDispatch();
  const {
    isFetching,
    isPassword,
    error: errorResponse,
    status: sttResponse,
  } = useSelector(({ authRedux }) => authRedux);

  const [errContent, setErrContent] = useState([]);
  const [email, setEmail] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    let waring = [];

    if (!Boolean(validateEmail(email))) {
      waring.push(getLabel(LangConstant.ERR_EMAIL));
    }

    if (waring.length > 0) {
      // Set warning to display when valid form
      setErrContent(waring);
    } else {
      dispatch(AuthAction.requestPassword(email));
      setErrContent([]);
      setIsSubmit(true);
    }
  };

  // Call Api Error
  useEffect(() => {
    if (!isSubmit) return;
    let isError = !isFetching && errorResponse;
    if (isError) {
      setErrContent([getLabel(isSttException(sttResponse) ? LangConstant.ERR_EXCEPTION : LangConstant.ERR_EMAIL)]);
    }
  }, [isFetching, errorResponse]);

  // Call Api success
  useEffect(() => {
    if (!isSubmit) return;
    let isSuccess = isPassword && !isFetching;
    if (isSuccess) {
      setIsShow(true);
    }
  }, [isFetching, isPassword]);

  // After complete calling api, update isSubmit is false
  useEffect(() => {
    if (!isFetching && isSubmit) {
      setIsSubmit(false);
    }
  }, [isFetching]);

  // After show error, rest error in redux
  useEffect(() => {
    if (errContent && errContent.length > 0) {
      dispatch(AuthAction.authReset());
    }
  }, [errContent]);

  return (
    <>
      <AuthLayout
        title={getLabel(LangConstant.TXT_FORGOT_PASSWORD)}
        intro={getLabel(LangConstant.MSG_FORGOT_PASSWORD_REQUIRE)}
        isForgot={true}
      >
        <form onSubmit={onSubmit}>
          <Box className="auth_input-box">
            <FormInput
              icon={<EmailOutlined />}
              placeholder={getLabel(LangConstant.P_EMAIL)}
              type="email"
              onChange={event => setEmail(event.target.value)}
              required
            />
          </Box>
          <Box className="center-root submit-root">
            {errContent.length > 0 && errContent.map((error, index) => <FormError error={error} key={index} />)}
            <Button color="primary" variant="contained" size="large" onClick={() => submitRef.current.click()}>
              {getLabel(LangConstant.TXT_SEND)}
            </Button>
            <input type="submit" ref={submitRef} style={{ display: "none" }} />
          </Box>
        </form>
        <Typography variant="body2">
          {getLabel(LangConstant.MSG_BACK_LOGIN_PAGE)}
          <Link to={PathConstant.LOGIN}>
            <MuiLink component="span" className="medium-md-txt">
              {getLabel(LangConstant.TXT_LOGIN)}
            </MuiLink>
          </Link>
        </Typography>
      </AuthLayout>
      <DialogSuccess
        isShow={isShow}
        title={"Lấy mật khẩu thành công"}
        message="Chúng tôi sẽ gửi mật khẩu tới email của bạn!"
        classes={{ root: "dialog-small" }}
        onClose={() => setIsShow(false)}
        action={
          <Link to={PathConstant.LOGIN}>
            <MuiLink component="span" className="medium-lg-txt" style={{ color: "white" }}>
              {getLabel(LangConstant.TXT_LOGIN)}
            </MuiLink>
          </Link>
        }
      />
      {isFetching && <Processing isShow={true} />}
    </>
  );
};

ForgotPassword.propTypes = {};
ForgotPassword.defaultProps = {};

export default ForgotPassword;
