import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Card, IconButton, makeStyles, Typography } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import clsx from "clsx";
import StringFormat from "string-format";
import { AppConstant, LangConstant } from "const";
import { STT_RESERVATION_WITH_INDEX } from "pages/MnReservation/components/StatusDialog/CustomStepper";
import { getStatusColor, convertHex2rgba, getCommonKey } from "utils";
import { DialogVerify } from "components";
import ReservationHistoryAction from "redux/reservation-history.redux";

const StatusBatchUpdateMenu = ({ selectedBookingList }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);
  const { isSuccess } = useSelector(state => state.reservationHistoryRedux);
  const dispatch = useDispatch();
  const classes = useStyle();

  const [isExpand, setIsExpand] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [targetStatus, setTargetStatus] = useState(null);

  let mappingArray = isExpand ? STT_RESERVATION_WITH_INDEX : COLLAPSED_MENU_ARRAY;

  const onChangeExpandMode = () => {
    setIsExpand(!isExpand);
  };

  const onClick = status => {
    setTargetStatus(status);
    setIsShowConfirm(true);
  };

  const onCancelConfirm = () => {
    setIsShowConfirm(false);
    setTargetStatus(null);
  };

  const onConfirmBatchUpdate = () => {
    dispatch(
      ReservationHistoryAction.batchUpdateStatus({
        status: targetStatus,
        uids: selectedBookingList,
      }),
    );
  };

  const confirmDialogContent = isTitle => {
    let result;
    if (isTitle) {
      result = StringFormat(
        getLabel(LangConstant.FM_BATCH_UPDATE_STATUS_DIALOG_TITLE),
        selectedBookingList.length || 0,
        getLabel(getCommonKey(LangConstant.ARR_STATUS_RESERVATION[targetStatus - 1])),
      );
    } else {
      result = StringFormat(
        getLabel(LangConstant.FM_BATCH_UPDATE_STATUS_DIALOG_CONTENT),
        selectedBookingList.length || 0,
        getLabel(getCommonKey(LangConstant.ARR_STATUS_RESERVATION[targetStatus - 1])),
      );
    }
    return result;
  };

  useEffect(() => {
    if (isSuccess) {
      setIsShowConfirm(false);
      setTargetStatus(null);
      dispatch(ReservationHistoryAction.resetSuccess());
    }
  }, [isSuccess]);

  return (
    <Card
      classes={{
        root: classes.statusMenuFilterRoot,
      }}
    >
      {mappingArray.map((valueStatus, index) => (
        <Button
          className={classes.filterByStatusBtn}
          style={{
            ...getButtonStatusStyle(valueStatus.value, targetStatus),
          }}
          key={index}
          onClick={() => onClick(valueStatus.value)}
        >
          <Typography className={clsx(classes.filterBtnLabel, "semiBold-sm-txt")} color="inherit">
            {getLabel(getCommonKey(LangConstant.ARR_STATUS_RESERVATION[valueStatus.value - 1]))}
          </Typography>
        </Button>
      ))}
      <IconButton className={classes.expandButton} onClick={onChangeExpandMode}>
        {isExpand ? <ArrowLeft /> : <ArrowRight />}
      </IconButton>

      {isShowConfirm && (
        <DialogVerify
          classes={{
            title: classes.confirmDialogTitle,
            content: classes.confirmDialogContent,
            confirmButton: classes.confirmDialogConfirmButton,
          }}
          isShow={isShowConfirm}
          title={confirmDialogContent(true)}
          content={confirmDialogContent()}
          onCancel={onCancelConfirm}
          onConfirm={onConfirmBatchUpdate}
        />
      )}
    </Card>
  );
};

StatusBatchUpdateMenu.propTypes = {
  selectedBookingList: PropTypes.array,
};

StatusBatchUpdateMenu.defaultProps = {
  selectedBookingList: [],
};

const COLLAPSED_MENU_ARRAY = [
  { value: AppConstant.STT_RESERVATION.confirm },
  { value: AppConstant.STT_RESERVATION.active },
  { value: AppConstant.STT_RESERVATION.finished },
];

export default StatusBatchUpdateMenu;

const getButtonStatusStyle = (status, selectedStatus) => {
  let style = {};
  if (status === AppConstant.STT_RESERVATION.reject || status === AppConstant.STT_RESERVATION.cancel) {
    style = {
      color: "#b3b5ba",
      border: `1px solid #b3b5ba`,
      backgroundColor: convertHex2rgba("#b3b5ba", 0.3),
    };
  } else {
    style = {
      color: status == selectedStatus ? "#fff" : getStatusColor(status),
      border: `1px solid ${getStatusColor(status)}`,
      backgroundColor: convertHex2rgba(getStatusColor(status), status == selectedStatus ? 1 : 0.3),
    };
  }
  return style;
};

const useStyle = makeStyles(theme => ({
  filterByStatusBtn: {
    textTransform: "none",
    borderRadius: 2,
    minWidth: 109,
    height: 24,
    marginRight: 10,
    "&:hover": {
      backgroundColor: "currentColor !important",
      "&>span>p": {
        color: "#fff",
      },
    },
  },
  filterBtnLabel: {},
  statusMenuFilterRoot: {
    height: 55,
    display: "flex",
    alignItems: "center",
    padding: "0 68px",
    marginTop: -24,
    overflowX: "auto",
    [theme.breakpoints.down(550)]: {
      padding: 0,
      paddingLeft: 5,
    },
  },
  expandButton: {
    width: 36,
    height: 36,
    color: "#6c7078",
    "&>span>svg": {
      width: 36,
      height: 36,
    },
  },
  confirmDialogContent: {
    fontSize: 13,
    fontWeight: "normal",
  },
  confirmDialogConfirmButton: {
    marginLeft: 26,
  },
}));
