import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const InviteStaffIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <path
        d="M15.7111111,13 L15.7111111,18.5714286 L14.5111111,18.5714286 L14.5111111,13 L15.7111111,13 Z M9.96666667,5.4 C11.8420205,5.4 13.3666667,6.89740998 13.3666667,8.75 C13.3666667,10.0123147 12.6587909,11.1097418 11.614432,11.6809104 C12.3024925,11.879165 12.9086334,12.1698597 13.2824613,12.4561145 L13.3980054,12.5510104 L12.6019946,13.4489896 C12.2132203,13.1043619 11.0065854,12.6267857 9.93831169,12.6267857 C7.83423088,12.6267857 6.3601831,14.1418386 5.78369215,16.0151333 L5.747,16.141 L11.3571429,16.1410714 L11.3571429,17.3410714 L4.29556377,17.3410714 L4.40764298,16.6456085 C4.76705472,14.4154222 6.13935616,12.3645486 8.30958415,11.6734711 C7.27021336,11.1030361 6.56666667,10.0084626 6.56666667,8.75 C6.56666667,6.89740142 8.09129357,5.4 9.96666667,5.4 Z M12.2271177,15.2457343 L18.0048954,15.2928771 L17.9951046,16.4928372 L12.2173268,16.4456943 L12.2271177,15.2457343 Z M9.96666667,6.6 C8.74924376,6.6 7.76666667,7.56503108 7.76666667,8.75 C7.76666667,9.93495187 8.74925434,10.9 9.96666667,10.9 C11.1840616,10.9 12.1666667,9.93494142 12.1666667,8.75 C12.1666667,7.56504152 11.1840722,6.6 9.96666667,6.6 Z"
        id="Shape"
      ></path>
    </SvgIcon>
  );
};

export default memo(InviteStaffIcon);
