export default {
  TXT_LOGIN: "Đăng nhập",
  TXT_PENDING: "Chờ xử lý",
  TXT_CONFIRM: "Xác nhận",
  TXT_SEATED: "Đã đến",
  TXT_ACTIVE: "Đang dùng",
  TXT_FINISHED: "Đã xong",
  TXT_CANCEL: "Khách huỷ",
  TXT_REJECT: "Từ chối",
  TXT_CHANGE_STT: "Chuyển trạng thái",
  TXT_SAVE: "Lưu",
  TXT_DETAIL_INFO: "Thông tin chi tiết",
  TXT_ADD: "Thêm",
  TXT_ADD_NEW: "Thêm mới",

  L_BOOKING_NAME: "Tên người đặt:",
  L_BOOKING_CODE: "Mã đặt hàng:",
  L_BOOKING_PHONE: "Số điện thoại:",
  L_BOOKING_PEOPLE: "Số người lớn:",
  L_BOOKING_CHILDREN: "Số trẻ em:",
  L_BOOKING_DATE: "Ngày đặt:",
  L_BOOKING_TIME: "Thời gian:",
  L_BOOKING_SERVICE: "Dịch vụ:",
  L_BOOKING_ASSIGN: "Chỉ định:",
  L_BOOKING_STATUS: "Trạng thái:",
  L_BOOKING_NOTE: "Ghi chú:",

  P_BOOKING_NAME: "Tên người đặt",
  P_BOOKING_CODE: "Mã đặt hàng",
  P_BOOKING_PHONE: "Số điện thoại",
  P_BOOKING_EMAIL: "Email",
  P_BOOKING_PEOPLE: "Số người lớn",
  P_BOOKING_CHILDREN: "Số trẻ em",
  P_BOOKING_TIME: "Thời gian",
  P_BOOKING_NOTE: "Ghi chú",
  P_BOOKING_ARRIVAL_DATE: "Ngày đến",
  P_BOOKING_SEARCH: "Tìm kiếm",

  TXT_MON: "Thứ 2",
  TXT_TUE: "Thứ 3",
  TXT_WED: "Thứ 4",
  TXT_THU: "Thứ 5",
  TXT_FRI: "Thứ 6",
  TXT_SAT: "Thứ 7",
  TXT_SUN: "Chủ nhật",
};
