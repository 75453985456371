import { ApiConstant } from "const";
import { createApiReservation } from "api";
import { getEndOfDay, getStartOfDay } from "utils/date";

export const reportSummaryReservation = data =>
  createApiReservation().get(ApiConstant.GET_REPORT_SUMMARY_RESERVATION, convertParams(data));

export const reportSummaryCustomer = data =>
  createApiReservation().get(ApiConstant.GET_REPORT_SUMMARY_CUSTOMER, convertParams(data));

export const reportSummaryCategory = data =>
  createApiReservation().get(ApiConstant.GET_REPORT_SUMMARY_CATEGORY, convertParams(data));

export const convertParams = params => {
  let newParams = { ...params };
  if (newParams.start) {
    newParams.start = getStartOfDay(newParams.start);
  }
  if (newParams.end) {
    newParams.end = getEndOfDay(newParams.end);
  }
  return newParams;
};
