import React, { memo } from "react";
import { Box, Button, Grid, makeStyles, Paper, Popover, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { LangConstant } from "const";
import { StaffPosition, StaffWorkTime } from "pages/StaffSetting/components";
import { useTranslation } from "react-i18next";

const StaffSetting = ({ open, setOpen }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      anchorEl={document.body}
      anchorPosition={{ left: 0, top: 0 }}
      anchorReference="anchorPosition"
      marginThreshold={0}
      classes={{ paper: classes.paperProps }}
    >
      <Paper elevation={1} square classes={{ root: classes.rootPaper, elevation1: classes.elevation1 }}>
        <Box className={classes.boxCloseParent}>
          <Box className={classes.boxCloseChildren}>
            <Button
              onClick={onClose}
              classes={{
                root: classes.rootButton,
                label: classes.label,
                startIcon: classes.closeIcon,
                iconSizeMedium: classes.iconSizeMedium,
              }}
              startIcon={<Close className={classes.rootIcon} />}
            />
          </Box>
        </Box>
        <Box classes={{ root: classes.rootBox }}>
          <Grid container justify="center" spacing={4}>
            <Grid item lg={6} xs={12}>
              <Box className={classes.content}>
                <Typography variant="h5" className={classes.staffSetting}>
                  {getLabel(LangConstant.TXT_STAFF_SETTING)}
                </Typography>
                <StaffWorkTime />
                <StaffPosition />
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.boxSave}>
            <Button
              onClick={onClose}
              className={`semiBold-lg-txt ${classes.saveSetting}`}
              color="primary"
              size="medium"
              variant="contained"
            >
              {getLabel(LangConstant.TXT_SAVE)}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Popover>
  );
};

const useStyles = makeStyles(theme => ({
  paperProps: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
  },
  rootPaper: {
    height: "100%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "none",
    paddingBottom: "none",
  },
  elevation1: {
    boxShadow: "none !important",
  },
  root: {
    padding: theme.spacing(4),
  },
  rootBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  boxCloseParent: {
    marginRight: "0",
    height: "50px",
    marginTop: "20px",
    maxWidth: "100%",
  },
  boxCloseChildren: {
    height: "100%",
    width: "20%",
    marginLeft: "80%",
  },
  label: {
    height: "100%",
  },
  closeIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  rootButton: {
    width: "50px",
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "20px",
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },
  content: {
    paddingTop: 50,
    textAlign: "center",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  iconSizeMedium: {
    fontSize: "30px",
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
  staffSetting: {
    textAlign: "center",
  },
  boxSave: {
    paddingTop: "8%",
    paddingBottom: "16%",
  },
  saveSetting: {
    width: "100px",
    height: "45px",
    borderRadius: 4,
    textTransform: "none",
  },
}));
export default memo(StaffSetting);
