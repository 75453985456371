import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles, Popover, Box } from "@material-ui/core";
import CustomButton from "../../Reservation/CustomButton";
import { STT_RESERVATION_WITH_INDEX } from "../../StatusDialog/CustomStepper";
import { AppConstant } from "const";

const InfoStatus = ({ label, status, onChange, isHasPreOrder }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const onShow = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onChangeStatus = status => {
    onChange(status);
    onClose();
  };

  let statusArray = isHasPreOrder
    ? [...STT_RESERVATION_WITH_INDEX]
    : STT_RESERVATION_WITH_INDEX.filter(status => status.value !== AppConstant.STT_RESERVATION.waitingPayment);

  const listStatusButton = statusArray.map(entry => {
    return (
      <Grid item xs key={entry.value}>
        <CustomButton
          status={entry.value}
          onClick={() => onChangeStatus(entry.value)}
          classes={{ root: classes.buttonList, label: classes.labelButton }}
        />
      </Grid>
    );
  });

  return (
    <>
      <Box className={classes.rootLine}>
        <Typography className={classes.titleLine}>{label}</Typography>
      </Box>
      <Grid item xs={8} className={classes.contentStatus}>
        <CustomButton
          status={status}
          onClick={onShow}
          classes={{ root: classes.customButton, label: classes.labelButton }}
        />
        <Popover
          id="info-status"
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Grid container direction="column">
            {listStatusButton}
          </Grid>
        </Popover>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  rootLine: {
    minWidth: "40%",
  },
  titleLine: {
    minWidth: 82,
    height: 22,
    fontSize: 13,
    marginTop: 4,
  },
  contentStatus: {
    height: 22,
    minWidth: 87,
    fontSize: 12,
    marginTop: 4,
    color: theme.palette.white,
  },
  customButton: {
    minWidth: 87,
    borderRadius: 0,
    height: 22,
    textTransform: "none",
  },
  buttonList: {
    marginTop: 2,
    minWidth: 87,
    height: 22,
    borderRadius: 0,
    textTransform: "none",
    width: "100%",
  },
  labelButton: {
    fontSize: 12,
    fontWeight: 400,
  },
}));

InfoStatus.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
InfoStatus.defaultProps = { isCanEdit: true };

export default memo(InfoStatus);
