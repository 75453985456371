import { ApiConstant } from "const";
import { createApiReservation } from "api";

export const getBookingHistory = data => {
  return createApiReservation().get(ApiConstant.GET_BOOKING_HISTORY, data);
};

export const batchUpdateStatus = data => {
  return createApiReservation().post(ApiConstant.POST_RESERVATION_LIST_STATUS, data);
};
