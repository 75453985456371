import CommonLang from "./common.lang";
import ReportDashboardLang from "./report-dashboard.lang";
import ManageBookingLang from "./manage-booking.lang";
import ActivityHistoryLang from "./activity-history.lang";
import BookingHistoryLang from "./booking-history.lang";
import MainLayoutLang from "./main-layout.lang";
import MaintenanceLang from "./maintenance.lang";
import ManageMProductLang from "./manage-master-product.lang";
import PaymentSetupLang from "./payment-setup.lang";
import PlaceManageLang from "./place-manage.lang";
import BookingSetupPreviewLang from "./booking-setup-preview.lang";
import ManageOrderLang from "./manage-order.lang";
import PlanPaymentLang from "./plan-payment.lang";
import VATManagementLang from "./vat-management.lang";
import RatingManagementLang from "./rating-management.lang";
import ConsumerLang from "./consumer.lang";
import NotificationSettingLang from "./notification-setting.lang";
import GroupAdmin from "./group-admin.lang";

export default {
  common: CommonLang,
  reportDashboard: ReportDashboardLang,
  manageBooking: ManageBookingLang,
  activityHistory: ActivityHistoryLang,
  bookingHistory: BookingHistoryLang,
  mainLayout: MainLayoutLang,
  maintenance: MaintenanceLang,
  manageMasterProduct: ManageMProductLang,
  paymentSetup: PaymentSetupLang,
  placeManage: PlaceManageLang,
  bookingSetupPreview: BookingSetupPreviewLang,
  manageOrder: ManageOrderLang,
  planPayment: PlanPaymentLang,
  vatManagement: VATManagementLang,
  ratingManagement: RatingManagementLang,
  consumer: ConsumerLang,
  notificationSetting: NotificationSettingLang,
  groupAdmin: GroupAdmin,
};
