import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getReservationActivity: ["data"],
  getReservationActivitySuccess: ["data"],
  getReservationActivityFailure: ["data"],
});

export const ReservationActivityTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  //get reservation activity
  isFetching: false,
  error: null,
  isSuccess: false,
  data: null,
};

/* ------------- Reducers Get Shop info data ------------- */
export const getReservationActivity = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getReservationActivitySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    data: action.data,
  };
};

export const getReservationActivityFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_RESERVATION_ACTIVITY]: getReservationActivity,
  [Types.GET_RESERVATION_ACTIVITY_SUCCESS]: getReservationActivitySuccess,
  [Types.GET_RESERVATION_ACTIVITY_FAILURE]: getReservationActivityFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
