import React, { memo } from "react";
import { makeStyles, Box, Typography, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { getCookiesDataShopSelect } from "utils";
import StringFormat from "string-format";

const AccountInfo = () => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  const dataUser = useSelector(({ userRedux }) => userRedux.dataUser, shallowEqual);

  const shopInfo = useSelector(({ shopInfoRedux }) => shopInfoRedux.data, shallowEqual);

  let dataShops = getCookiesDataShopSelect();

  return (
    <Box>
      <Box className={clsx(classes.boxLine, classes.boxNotMarginTop)}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_ACCOUNT_USER_ID)}
        </Typography>
        <Typography className={clsx("medium-md-txt", classes.content)}>{dataUser?.id || ""}</Typography>
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_ACCOUNT_PHONE_NUMBER)}
        </Typography>
        <Typography className={clsx("medium-md-txt", classes.content)}>{dataUser?.phoneNumber || ""}</Typography>
      </Box>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_ACCOUNT_EMAIL)}
        </Typography>
        <Typography className={clsx("medium-md-txt", classes.content)}>{dataUser?.email || ""}</Typography>
      </Box>
      <Box className={clsx(classes.boxLine, classes.boxMarginBottom)}>
        <Typography className={clsx("medium-md-txt", classes.title)}>
          {getLabel(LangConstant.TXT_ACCOUNT_SHOPS)}
        </Typography>
        <Box>
          {dataShops.map((item, index) => (
            <Link
              key={index}
              href={StringFormat(AppConstant.FM_BOOKING_SHORT_LINK_URL, shopInfo?.alias)}
              target="_blank"
            >
              <Typography className={clsx("medium-md-txt", classes.shopColor)}>{item.name}</Typography>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(AccountInfo);

const useStyles = makeStyles(theme => ({
  boxLine: {
    display: "flex",
    alignItems: "start",
    minHeight: 30,
    marginTop: 14,
    marginBottom: 14,
  },
  boxMarginBottom: {
    marginBottom: 40,
  },
  boxNotMarginTop: {
    marginTop: 0,
  },
  title: {
    width: 105,
    marginRight: 24,
    lineHeight: 1.57,
  },
  content: {
    lineHeight: 1.57,
  },
  shopColor: {
    marginBottom: 8,
    lineHeight: 1.57,
    color: theme.palette.text.link,
  },
}));
