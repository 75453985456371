import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Edit } from "@material-ui/icons";
import { Box, Checkbox, makeStyles, TableRow, Typography, Button, IconButton } from "@material-ui/core";
import BillAction from "redux/bill.redux";
import { AppConstant, LangConstant } from "const";
import { getBillStatusColor, getCommonKey, isObjectNotEqual } from "utils";
import { convertTimeStampToDate } from "utils/date";
import { Alert, CellBody } from "components";
import StatusDialogOrder from "./DialogStatus";
import BillDetailDialog from "./BillDetailDialog";

const BillTableRow = ({ dataShow, onClick, onShowDetail, isCheck }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);
  const isUpdateSuccess = useSelector(state => state.billRedux.isUpdateSuccess);
  const isDeleteSuccess = useSelector(state => state.billRedux.isDeleteSuccess);

  const [isShowStatusDialog, setIsShowStatusDialog] = useState(false);
  const [isShowEditDialog, setIsShowEditDialog] = useState(false);
  const [data, setData] = useState({});
  const [isNotifyDeleteSuccess, setIsNotifyDeleteSuccess] = useState(false);

  const onClickStatus = () => {
    setIsShowStatusDialog(true);
  };

  const onOpenDetail = () => {
    onShowDetail(dataShow.reservation.uid);
  };

  const onSelectButton = () => {
    setIsShowEditDialog(true);
  };

  const onUpdateStatus = status => {
    dispatch(
      BillAction.updateBill({
        ...dataShow,
        status: status,
        reservationUid: dataShow.reservation.uid || "",
      }),
    );
  };

  const onCloseNotify = () => {
    setIsNotifyDeleteSuccess(false);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsShowEditDialog(false);
      dispatch(
        BillAction.billSuccess({
          isUpdateSuccess: false,
        }),
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setIsNotifyDeleteSuccess(true);
      dispatch(
        BillAction.billSuccess({
          isDeleteSuccess: false,
        }),
      );
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isObjectNotEqual(dataShow, data)) {
      setData(dataShow);
    }
  }, [dataShow]);

  let buttonStatusContent = getLabel(
    data.status === AppConstant.STT_VAT_BILL.pending
      ? getCommonKey(LangConstant.TXT_PENDING)
      : data.status === AppConstant.STT_VAT_BILL.transferred
      ? LangConstant.TXT_TRANSFERRED
      : LangConstant.TXT_SENT,
  );

  return (
    <>
      <TableRow className={classes.rootTableRow}>
        <CellBody
          className={classes.firstTableCellHead}
          cellData={
            <Box className={classes.codeCell}>
              <Checkbox
                checked={isCheck}
                onChange={() => onClick(data)}
                color="default"
                disableRipple={true}
                disableTouchRipple={true}
                classes={{
                  root: classes.checkboxBasis,
                  checked: clsx(classes.checkboxChecked, classes.checkboxBasis),
                }}
              />
              <Typography className={classes.billCode} onClick={onOpenDetail}>
                {data.reservation?.code || ""}
              </Typography>
            </Box>
          }
        />
        <CellBody
          className={classes.cellBodyRoot}
          cellData={
            <Box>
              <Typography className={classes.companyName}>{data.companyName}</Typography>
              <Typography className={clsx(classes.companyAddress, "regular-sm-txt")}>{data.address || ""}</Typography>
            </Box>
          }
        />

        <CellBody
          className={classes.cellBodyRoot}
          cellData={<Typography className={classes.companyVATCode}>{data.taxCode || ""}</Typography>}
        />

        <CellBody
          className={classes.cellBodyRoot}
          cellData={<Typography className={classes.email}>{data.email || ""}</Typography>}
        />

        <CellBody
          className={classes.cellBodyRoot}
          cellData={
            <Typography className={classes.billCreatedTime}>
              {convertTimeStampToDate(data.created || 0, AppConstant.FM_DD_MM_YYYY)}
            </Typography>
          }
        />
        <CellBody
          className={classes.tableHeadLabelButton}
          cellData={
            <Box className={classes.statusAndEditCell}>
              <Button
                style={{
                  backgroundColor: getBillStatusColor(data.status || 1),
                }}
                className={clsx("semiBold-sm-txt", classes.statusButton)}
                onClick={onClickStatus}
              >
                {getLabel(buttonStatusContent)}
              </Button>
              <IconButton className={classes.editIconButton} onClick={onSelectButton}>
                <Edit className={classes.editIcon} />
              </IconButton>
            </Box>
          }
        />
      </TableRow>

      <Alert
        isShow={isNotifyDeleteSuccess}
        onClose={onCloseNotify}
        type="success"
        message={getLabel(LangConstant.TXT_DELETE_BILL_SUCCESS)}
      />

      {isShowStatusDialog && (
        <StatusDialogOrder dataShow={data} onClose={() => setIsShowStatusDialog(false)} onSubmit={onUpdateStatus} />
      )}
      {isShowEditDialog && <BillDetailDialog data={data} onClose={() => setIsShowEditDialog(false)} />}
    </>
  );
};

BillTableRow.propTypes = {
  isCheck: PropTypes.bool,

  dataShow: PropTypes.shape({
    reservation: PropTypes.shape({
      type: PropTypes.number,
      uid: PropTypes.string,
      code: PropTypes.string,
    }),
    companyName: PropTypes.string,
    email: PropTypes.string,
    taxCode: PropTypes.string,
    status: PropTypes.number,
    created: PropTypes.number,
    type: PropTypes.number,
  }),

  onClick: PropTypes.func,
  onShowDetail: PropTypes.func,
};

BillTableRow.defaultProps = {
  dataShow: {
    reservation: {
      type: 0,
      uid: "",
      code: "",
    },
    companyName: "",
    email: "",
    taxCode: "",
    status: 1,
    created: 0,
    type: 1,
  },

  onClick: () => {},
  onShowDetail: () => {},
};

export default BillTableRow;

const useStyles = makeStyles(theme => ({
  rootTableRow: {
    height: 75,
    "&:hover": {
      background: "#f2f5f7",
    },
  },

  cellBodyRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[500],
    fontSize: 13,
    fontWeight: "normal",
    textAlign: "center",
    "&>div": {
      justifyContent: "center",
    },
  },

  billCode: {
    width: "100%",
    color: theme.palette.text.link,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
    fontSize: 13,
  },

  firstTableCellHead: {
    alignItems: "center",
    textAlign: "center",
    borderBottom: "1px solid " + theme.palette.grey[200],
  },

  codeCell: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",
  },

  checkboxBasis: {
    width: 25,
    height: 25,
    position: "absolute",
    left: 0,
    padding: 5,
    " &>span>svg": {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },

  checkboxChecked: {
    color: theme.palette.text.link,
  },

  statusButton: {
    width: "100%",
    maxWidth: 82,
    textAlign: "center",
    color: theme.palette.white,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "max-content",
      maxWidth: "unset",
    },
  },

  tableHeadLabelButton: {
    width: "max-content",
    textAlign: "center",
    borderBottom: "1px solid #ebeced",
  },

  editIconButton: {
    width: 24,
    maxHeight: 24,
    position: "absolute",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      margin: 10,
    },
  },

  editIcon: {
    fontSize: 16,
    color: "#7F838C",
  },

  companyName: {
    fontSize: 13,
    lineHeight: "22px",
    marginBottom: 3,
  },

  companyAddress: {
    lineHeight: "22px",
  },

  companyVATCode: {
    fontSize: 13,
    [theme.breakpoints.down("sm")]: {
      minWidth: "max-content",
    },
  },

  email: {
    fontSize: 13,
    [theme.breakpoints.down("sm")]: {
      minWidth: "max-content",
    },
  },

  billCreatedTime: {
    fontSize: 13,
    [theme.breakpoints.down("sm")]: {
      minWidth: "max-content",
    },
  },

  statusAndEditCell: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 18,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
}));
