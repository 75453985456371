import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Typography, Box } from "@material-ui/core";
import { LangConstant } from "../../../../const";
import { useTranslation } from "react-i18next";
import CategoryAccordion, { PLACE_CATEGORY, SERVICE_CATEGORY } from "./CategoryAccordion";
import { PersonPinCircleOutlined, SpaOutlined } from "@material-ui/icons";

const AdvancedInfo = props => {
  const { placeData, serviceData } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={1} square className={classes.paper}>
      <Typography className="bold-lg-txt" classes={{ root: classes.header }}>
        {getLabel(LangConstant.TXT_ADVANCED_INFO_HEADER)}
      </Typography>
      <Box className={classes.box}>
        <CategoryAccordion
          data={placeData}
          icon={<PersonPinCircleOutlined className={classes.iconHeading}></PersonPinCircleOutlined>}
          name={PLACE_CATEGORY}
          headingText={getLabel(LangConstant.L_PLACE_CATEGORY)}
        ></CategoryAccordion>
        <CategoryAccordion
          data={serviceData}
          icon={<SpaOutlined className={classes.iconHeading}></SpaOutlined>}
          name={SERVICE_CATEGORY}
          headingText={getLabel(LangConstant.L_SERVICE_CATEGORY)}
          style={{ marginTop: 30 }}
          disableSubAccordion={true}
        ></CategoryAccordion>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 10,
    width: "100%",
    paddingBottom: 30,
    flexGrow: 1,
  },
  header: {
    color: theme.palette.grey[800],
    padding: "20px 60px",
    borderBottom: "solid 1px #f1f3f6",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  box: {
    padding: "25px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 10px",
    },
  },

  iconHeading: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    padding: "7px 7px",
    borderRadius: "100%",
  },
}));

AdvancedInfo.propTypes = {
  placeDate: PropTypes.object,
  serviceData: PropTypes.object,
};

AdvancedInfo.defaultProps = {};

export default memo(AdvancedInfo);
