import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";

const FormError = ({ error, className }) => (
  <Box className={`error-container ${className}`}>
    <Error className="ic-error" color="primary" />
    <Typography variant="body2" classes={{ body2: "regular-sm-txt" }}>
      {error}
    </Typography>
  </Box>
);

FormError.propTypes = {
  error: PropTypes.any,
  className: PropTypes.string,
};

FormError.defaultProps = {};

export default memo(FormError);
