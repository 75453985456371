import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Typography, Grid } from "@material-ui/core";
import MnProductAction from "redux/mn-product.redux";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, removeVietnameseTones, convertPricePreOrder } from "utils";
import { DialogDelete, DialogAddCategory } from "components/dialog";
import {
  CategorySubcategory,
  DisplayModeBar,
  IconButtonMProductItem,
  DialogAddCategoryItem,
  ItemDescriptionBlock,
} from "components/mn-master-product";
import DialogAddTable from "./DialogAddTable";
import WatchAndEditProduct from "./WatchAndEditProduct";
import CreateProductCategory from "./CreateProductCategory";

const MainProductCategory = ({ childProductModeId, childProductModeArr, onChangeMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const [checkProduct, setCheckProduct] = useState({ isCheck: false, isCheckItem: false, uid: "" });
  const [checkAddCategory, setCheckAddCategory] = useState(CATEGORY);
  const [dataCategoryChild, setDataCategoryChild] = useState(null);
  const [saveDataCategoryChild, setSaveDataCategoryChild] = useState(null);
  const [dataCategoryChildItem, setDataCategoryChildItem] = useState(null);
  const [dataDialogAddCategory, setDataDialogAddCategory] = useState(null);
  const [isOpenDialogAddCategory, setIsOpenDialogAddCategory] = useState(false);
  const [isOpenDialogAddCategoryItem, setIsOpenDialogAddCategoryItem] = useState(false);
  const [onChangeNameDialogAddCategory, setOnChangeNameDialogAddCategory] = useState(false);
  const [isOpenCreateProduct, setIsOpenCreateProduct] = useState(false);
  const [isOnSearch, setIsOnSearch] = useState(false);
  const [dataItemList, setDataItemList] = useState(null);
  const [saveDataItemList, setSaveDataItemList] = useState(null);
  const [listProductCategory, setListProductCategory] = useState([]);
  const [saveListProductCategory, setListSaveProductCategory] = useState([]);
  const [dataItemUid, setDataItemUid] = useState(null);
  const [isCategory, setIsCategory] = useState(true);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const [isOpen, setIsOpen] = useState({
    createProduct: false,
    editProduct: false,
    deleteProduct: false,
    uid: "",
    name: "",
  });

  const dataCategory = useSelector(state => state.mnProductRedux.data, shallowEqual);
  const isDataCategory = useSelector(state => state.mnProductRedux.isGetSuccess, shallowEqual);
  const dataItemUidCategory = useSelector(state => state.mnProductRedux.dataItemUidCategory, shallowEqual);
  const isGetItemUidCategory = useSelector(state => state.mnProductRedux.isGetSuccessItemUidCategory, shallowEqual);
  const dataDetails = useSelector(state => state.mnProductRedux.dataDetails, shallowEqual);
  const isRemoveCategory = useSelector(state => state.mnProductRedux.isDeleteSuccess, shallowEqual);
  const isPutCategory = useSelector(state => state.mnProductRedux.isPutSuccess, shallowEqual);
  const isPostCategory = useSelector(state => state.mnProductRedux.isPostSuccess, shallowEqual);
  const isPostItem = useSelector(state => state.mnProductRedux.isPostSuccessItem, shallowEqual);
  const isPutItem = useSelector(state => state.mnProductRedux.isPutSuccessItem, shallowEqual);
  const isDeleteItem = useSelector(state => state.mnProductRedux.isDeleteSuccessItem, shallowEqual);
  const errorCreateCategory = useSelector(state => state.mnProductRedux.errorCreateCategory);
  const errorUpdateCategory = useSelector(state => state.mnProductRedux.errorUpdateCategory);

  const onAddItem = () => {
    if (checkAddCategory === CATEGORY_CHILD) {
      setIsOpenDialogAddCategoryItem(true);
    } else {
      setIsOpenDialogAddCategory(true);
    }
  };

  const onOpenProductItem = uid => {
    var data = dataItemList?.find(data => data.uid === uid);
    var itemDetailsList = data.itemDetailsList.map(data => data.uid);
    var categoryList = data.categoryList.map(data => data.uid);
    setDataItemUid({ ...data, categoryUidList: categoryList, itemDetailsUidList: itemDetailsList });
    setIsOpen({ ...isOpen, watchProduct: true, uid: uid });
  };

  const onConfirmRemoveProduct = (e, uid) => {
    if (dataDialogAddCategory != null) {
      onClose();
      dispatch(MnProductAction.removeProductCategory({ uid: uid }));
    } else {
      onClose();
      var productItem = dataItemList.filter(itemSearch => itemSearch.uid === uid)[0];
      var categoryUidList = [];
      categoryUidList = productItem.categoryList.filter(data => data.uid !== checkProduct.uid);
      var categoryUid = [];
      categoryUid = categoryUidList.map(data => data.uid);
      var itemDetails = [];
      if (productItem.itemDetailsList.length > 0) {
        itemDetails = productItem.itemDetailsList.map(data => data.uid);
      }
      dispatch(
        MnProductAction.updateProductItem({
          data: {
            ...productItem,
            categoryUidList: categoryUid,
            itemDetailsUidList: itemDetails,
          },
        }),
      );
    }
  };

  const comBack = () => {
    setIsOpen({
      ...isOpen,
      watchProduct: false,
      createProduct: false,
      editProduct: false,
      deleteProduct: false,
      uid: null,
      name: "",
      addFromMain: false,
    });
  };

  const onClose = () => {
    setDataDialogAddCategory(null);
    setIsOpenCreateProduct(false);
    setIsOpenDialogAddCategoryItem(false);
    setIsOpenDialogAddCategory(false);
    setIsOpen({
      ...isOpen,
      watchProduct: false,
      createProduct: false,
      editProduct: false,
      deleteProduct: false,
      uid: null,
      name: "",
      addFromMain: false,
    });
  };

  const onSearch = name => {
    if (checkAddCategory === CATEGORY_CHILD) {
      let newListSearch = saveDataItemList.filter(itemSearch =>
        removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
      );
      setDataItemList(newListSearch);
    } else if (checkAddCategory === CATEGORY) {
      if (checkProduct.isCheck) {
        let newListSearch = saveDataCategoryChild.child.filter(itemSearch =>
          removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
        );
        setDataCategoryChild({ ...saveDataCategoryChild, child: newListSearch });
      } else {
        let newListSearch = saveListProductCategory.filter(itemSearch =>
          removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
        );
        setListProductCategory(newListSearch);
      }
    }
  };

  const onClickCategorySubcategory = uid => {
    setDataDialogAddCategory(null);
    setCheckProduct({ ...checkProduct, uid: uid, isCheck: true, isCheckItem: false });
    setDataCategoryChild(listProductCategory.filter(data => data.uid === uid)[0]);
    setSaveDataCategoryChild(listProductCategory.filter(data => data.uid === uid)[0]);
    dispatch(MnProductAction.resetMnProduct());
    dispatch(MnProductAction.getProductItemUidCategory({ category_uid: uid }));
  };

  const onClickCategorySubcategoryItem = uid => {
    setCheckAddCategory(CATEGORY_CHILD);
    setCheckProduct({ ...checkProduct, uid: uid, isCheck: false, isCheckItem: true });
    setDataCategoryChildItem(dataCategoryChild.child.filter(data => data.uid === uid)[0]);
    dispatch(MnProductAction.resetMnProduct());
    dispatch(MnProductAction.getProductItemUidCategory({ category_uid: uid }));
    setDataDialogAddCategory(null);
  };

  const onClickBackCategory = () => {
    setCheckAddCategory(CATEGORY);
    setCheckProduct({ ...checkProduct, uid: "", isCheck: false, isCheckItem: false });
    setDataCategoryChild(null);
  };

  const onClickBackCategoryChild = () => {
    setCheckAddCategory(CATEGORY);
    setCheckProduct({ ...checkProduct, uid: "", isCheck: true, isCheckItem: false });
  };

  const onChangeDialogAddCategory = value => {
    setOnChangeNameDialogAddCategory(value);
  };

  const onEditProductItem = uid => {
    var data = dataItemList?.find(data => data.uid === uid);
    var itemDetailsList = data.itemDetailsList.map(data => data.uid);
    var categoryList = data.categoryList.map(data => data.uid);
    setDataItemUid({ ...data, categoryUidList: categoryList, itemDetailsUidList: itemDetailsList });
    setIsOpen({ ...isOpen, editProduct: true, uid: uid });
  };

  const onDeleteProductItem = uid => {
    let name = "";
    dataItemList.forEach(data => {
      if (data.uid === uid) {
        name = data.name;
      }
    });
    setIsOpen({ ...isOpen, deleteProduct: true, uid: uid, name: name });
  };

  const onCloseDialogAddCategory = () => {
    setIsDuplicate(false);
    setDataDialogAddCategory(null);
    setIsOpenDialogAddCategory(false);
  };

  const onOpenDialogAddCategoryItem = () => {
    setIsOpenDialogAddCategoryItem(true);
  };

  const onSubmitCategory = () => {
    if (dataDialogAddCategory != null) {
      dispatch(
        MnProductAction.updateProductCategory({ ...dataDialogAddCategory, name: onChangeNameDialogAddCategory }),
      );
    } else {
      if (dataCategoryChild?.uid) {
        dispatch(
          MnProductAction.createProductCategory({
            name: onChangeNameDialogAddCategory,
            parent_uid: dataCategoryChild.uid,
          }),
        );
      } else {
        dispatch(MnProductAction.createProductCategory({ name: onChangeNameDialogAddCategory }));
      }
    }
    setDataDialogAddCategory(null);
  };

  const onContextMenuCategorySubcategory = (uid, key) => {
    if (key === EDIT) {
      listProductCategory.forEach(data => {
        if (data.uid === uid) {
          setDataDialogAddCategory({ name: data.name, uid: data.uid });
        }
      });
      setIsOpenDialogAddCategory(true);
    } else {
      let name = "";
      listProductCategory.forEach(data => {
        if (data.uid === uid) {
          name = data.name;
        }
      });
      setDataDialogAddCategory({ name: name, uid: uid });
      setIsOpen({ ...isOpen, deleteProduct: true, uid: uid, name: name });
    }
  };

  const onContextMenuCategorySubcategoryItem = (uid, key) => {
    if (key === EDIT) {
      dataCategoryChild.child.forEach(data => {
        if (data.uid === uid) {
          setDataDialogAddCategory({ name: data.name, uid: data.uid });
        }
      });
      setIsOpenDialogAddCategory(true);
    } else {
      let name = "";
      dataCategoryChild.child.forEach(data => {
        if (data.uid === uid) {
          name = data.name;
        }
      });
      setDataDialogAddCategory({ name: name, uid: uid });
      setIsOpen({ ...isOpen, deleteProduct: true, uid: uid, name: name });
    }
  };

  const onAddFromMainService = () => {
    setIsOpen({ ...isOpen, addFromMain: true, uid: checkProduct.uid });
  };

  const onCreateAccompaniedService = () => {
    setIsOpenCreateProduct(true);
    setIsOpenDialogAddCategoryItem(false);
  };

  useEffect(() => {
    dispatch(MnProductAction.getProductCategory());
    onClose();
  }, []);

  useEffect(() => {
    if (isRemoveCategory) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductCategory());
      onClose();
    }
  }, [isRemoveCategory]);

  useEffect(() => {
    if (isPutCategory) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductCategory());
      onClose();
    }
  }, [isPutCategory]);

  useEffect(() => {
    if (isDataCategory) {
      dispatch(MnProductAction.resetMnProduct());
      setDataCategoryChild(dataCategory.data.filter(data => data.uid === checkProduct.uid)[0]);
    }
  }, [isDataCategory]);

  useEffect(() => {
    if (isPostCategory) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductCategory());
      onClose();
    }
  }, [isPostCategory]);

  useEffect(() => {
    if (!dataCategory) {
      dispatch(MnProductAction.getProductCategory());
    } else {
      if (dataCategory && dataCategory.data) {
        setListProductCategory(dataCategory.data);
        setListSaveProductCategory(dataCategory.data);
      }
    }
  }, []);

  useEffect(() => {
    if (isPostItem) {
      dispatch(MnProductAction.resetMnProduct());
      onClose();
    }
  }, [isPostItem]);

  useEffect(() => {
    if (isPostItem) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItemUidCategory({ category_uid: checkProduct.uid }));
    }
  }, [isPostItem]);

  useEffect(() => {
    if (isPutItem) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItemUidCategory({ category_uid: checkProduct.uid }));
    }
  }, [isPutItem]);

  useEffect(() => {
    if (isDeleteItem) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItemUidCategory({ category_uid: checkProduct.uid }));
    }
  }, [isDeleteItem]);

  useEffect(() => {
    if (isGetItemUidCategory) {
      if (dataItemUidCategory.data.length > 0) {
        setCheckAddCategory(CATEGORY_CHILD);
        setIsOnSearch(true);
      }
      if (dataItemUidCategory.data.length === 0 && dataCategoryChild.child.length === 0) {
        setIsOnSearch(false);
      }
      if (dataItemUidCategory?.data?.length === 0) {
        setCheckAddCategory(CATEGORY);
      }
      dispatch(MnProductAction.resetMnProduct());
      setDataItemList(dataItemUidCategory.data);
      setSaveDataItemList(dataItemUidCategory.data);
    }
  }, [isGetItemUidCategory]);

  useEffect(() => {
    if (dataCategory && dataCategory.data && isDataCategory) {
      setListProductCategory(dataCategory.data);
      setListSaveProductCategory(dataCategory.data);
    }
  }, [isDataCategory]);

  useEffect(() => {
    if (listProductCategory && listProductCategory?.length === 0 && isDataCategory) {
      setIsOnSearch(false);
    } else if (listProductCategory?.length > 0) {
      setIsCategory(true);
      setIsOnSearch(true);
    }
  }, [listProductCategory]);

  useEffect(() => {
    if (dataItemList?.length === 0 && dataCategoryChild?.child.length > 0 && checkAddCategory === CATEGORY_CHILD) {
      setIsOnSearch(false);
    } else if (dataItemList?.length > 0) {
      setIsOnSearch(true);
      setIsCategory(false);
    } else if (dataCategoryChild?.child.length > 0) {
      setIsOnSearch(true);
      setIsCategory(true);
    }
  }, [dataItemList]);

  useEffect(() => {
    if (errorCreateCategory) {
      if (errorCreateCategory?.name[0] !== getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsOpenDialogAddCategory(false);
      } else {
        setIsDuplicate(true);
        dispatch(MnProductAction.resetErrorMnProduct());
      }
    }
    if (errorUpdateCategory) {
      if (errorUpdateCategory?.name[0] !== getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsOpenDialogAddCategory(false);
      } else {
        setIsDuplicate(true);
        dispatch(MnProductAction.resetErrorMnProduct());
      }
    }
  }, [errorCreateCategory, errorUpdateCategory]);

  return (
    <>
      <DisplayModeBar
        modeId={childProductModeId}
        modeArray={childProductModeArr}
        labelBtn={
          isCategory
            ? `${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
                LangConstant.TXT_CATEGORY_LOWER_CASE,
              )}`
            : getLabel(LangConstant.TXT_CREATE_PRODUCT)
        }
        onChange={onChangeMode}
        onAddItem={onAddItem}
        onSearch={onSearch}
        showSearch={isOnSearch}
      />
      {listProductCategory?.length > 0 ? (
        <Box className={classes.boxCategorySubCategory}>
          <>
            {checkProduct.isCheck ? (
              <>
                <Box display="flex" margin="24px 0" paddingLeft="24px">
                  <Typography onClick={onClickBackCategory} className={classes.categorySourceBox}>
                    {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)} &nbsp; / &nbsp;
                  </Typography>
                  <Typography className={classes.categoryName}>{dataCategoryChild?.name}</Typography>
                </Box>
                {dataCategoryChild?.child.length > 0 ? (
                  <Box className={classes.listProductCategorySub}>
                    {dataCategoryChild.child.map(dataMap => (
                      <Box margin="12px" display="grid" key={dataMap.uid}>
                        <CategorySubcategory
                          uid={dataMap.uid}
                          text={dataMap.name}
                          onClick={onClickCategorySubcategoryItem}
                          onContextMenu={onContextMenuCategorySubcategoryItem}
                        />
                      </Box>
                    ))}
                  </Box>
                ) : dataItemList && dataItemList?.length > 0 ? (
                  <>
                    <Box className={classes.listProduct}>
                      {dataItemList.map(dataMap => (
                        <Box margin="12px" display="grid" key={dataMap.uid}>
                          <ItemDescriptionBlock
                            data={dataMap}
                            typeButton={AppConstant.TYPE_BUTTON_CATEGORY.removed}
                            amount={getLabel(getCommonKey(LangConstant.FM_ITEM_STOCK), { stock: dataMap.stock || 0 })}
                            price={convertPricePreOrder(dataMap)}
                            onOpenDetail={onOpenProductItem}
                            onOpenEdit={onEditProductItem}
                            onOpenDelete={onDeleteProductItem}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                ) : (
                  <Box className={classes.contentBoxChild}>
                    <Box>
                      <IconButtonMProductItem
                        label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
                          LangConstant.TXT_CATEGORY_LOWER_CASE,
                        )}`}
                        onClick={onAddItem}
                        buttonClasses={{ root: classes.addButton }}
                      />
                    </Box>
                    <Box className={classes.buttonAddGood}>
                      <IconButtonMProductItem
                        label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
                          LangConstant.TXT_PRODUCT_LOWER_CASE,
                        )}`}
                        onClick={onOpenDialogAddCategoryItem}
                        buttonClasses={{ root: classes.addButton }}
                      />
                    </Box>
                  </Box>
                )}
              </>
            ) : checkProduct.isCheckItem ? (
              <>
                {dataItemList && dataItemList?.length > 0 ? (
                  <>
                    <Box display="flex" margin="24px 0" paddingLeft="24px">
                      <Typography onClick={onClickBackCategory} className={classes.categorySourceBox}>
                        {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)} &nbsp; / &nbsp;
                      </Typography>
                      <Typography onClick={onClickBackCategoryChild} className={classes.categorySourceBox}>
                        {dataCategoryChild.name} &nbsp; / &nbsp;
                      </Typography>
                      <Typography className={classes.categoryName}>{dataCategoryChildItem?.name}</Typography>
                    </Box>
                    <Grid container spacing={3} className={classes.listProduct}>
                      {dataItemList?.map(dataMap => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={dataMap.uid}>
                          <ItemDescriptionBlock
                            data={dataMap}
                            typeButton={AppConstant.TYPE_BUTTON_CATEGORY.removed}
                            amount={getLabel(getCommonKey(LangConstant.FM_ITEM_STOCK), { stock: dataMap.stock || 0 })}
                            price={convertPricePreOrder(dataMap)}
                            onOpenDetail={onOpenProductItem}
                            onOpenEdit={onEditProductItem}
                            onOpenDelete={onDeleteProductItem}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box display="flex" margin="24px 0" paddingLeft="24px">
                      <Typography onClick={onClickBackCategory} className={classes.categorySourceBox}>
                        {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)} &nbsp; / &nbsp;
                      </Typography>
                      <Typography onClick={onClickBackCategoryChild} className={classes.categorySourceBox}>
                        {dataCategoryChild.name} &nbsp; / &nbsp;
                      </Typography>
                      <Typography className={classes.categoryName}>{dataCategoryChildItem?.name}</Typography>
                    </Box>
                    <Box className={classes.contentBox}>
                      <IconButtonMProductItem
                        label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
                          LangConstant.TXT_PRODUCT_LOWER_CASE,
                        )}`}
                        onClick={onOpenDialogAddCategoryItem}
                        buttonClasses={{ root: classes.addButton }}
                      ></IconButtonMProductItem>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid container spacing={3} className={classes.listProductCategorySub}>
                  {listProductCategory?.map(dataMap => (
                    <Grid item xs={12} sm={4} md={3} key={dataMap.uid}>
                      <CategorySubcategory
                        uid={dataMap.uid}
                        text={dataMap.name}
                        onClick={onClickCategorySubcategory}
                        onContextMenu={onContextMenuCategorySubcategory}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>
        </Box>
      ) : (
        <Box className={classes.contentBox}>
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
              LangConstant.TXT_PRODUCT_LOWER_CASE,
            )}`}
            onClick={onAddItem}
            buttonClasses={{ root: classes.addButton }}
          ></IconButtonMProductItem>
        </Box>
      )}
      {isOpen.deleteProduct && (
        <DialogDelete
          uid={isOpen.uid}
          isShow={isOpen.deleteProduct}
          onCancel={onClose}
          onConfirm={onConfirmRemoveProduct}
          title={
            dataDialogAddCategory != null
              ? getLabel(LangConstant.FN_DELETE_GOODS_CATEGORY_TITLE)(isOpen.name)
              : getLabel(LangConstant.FN_DELETE_GOODS_ITEM_TITLE)(isOpen.name)
          }
          content={
            dataDialogAddCategory != null
              ? getLabel(LangConstant.FN_DELETE_GOODS_CATEGORY_CONTENT)(isOpen.name)
              : getLabel(LangConstant.FN_DELETE_GOODS_ITEM_CONTENT)(isOpen.name)
          }
        />
      )}
      {isOpenDialogAddCategory && (
        <DialogAddCategory
          isShow={isOpenDialogAddCategory}
          onChange={onChangeDialogAddCategory}
          onClose={onCloseDialogAddCategory}
          onSubmit={onSubmitCategory}
          data={dataDialogAddCategory}
          isWarning={isDuplicate}
          nameWaring={isDuplicate ? getLabel(LangConstant.TXT_WARNING_CATEGORY) : ""}
        />
      )}
      {isOpenDialogAddCategoryItem && (
        <DialogAddCategoryItem
          isShow={isOpenDialogAddCategoryItem}
          titleName={{
            header: getLabel(LangConstant.TXT_CATEGORY_GOOD_HEADER),
            add: getLabel(LangConstant.TXT_CATEGORY_GOOD_ADD),
            created: getLabel(LangConstant.TXT_CATEGORY_GOOD_CREATE),
          }}
          onAddFromMainService={onAddFromMainService}
          onCreateAccompaniedService={onCreateAccompaniedService}
          action={onClose}
        />
      )}
      {isOpenCreateProduct && (
        <CreateProductCategory
          dataDetailsView={dataDetails.data}
          data={listProductCategory}
          isOpen={isOpenCreateProduct}
          uidCategory={[checkProduct.uid]}
          onClose={onClose}
        />
      )}
      {isOpen.uid && (isOpen.editProduct || isOpen.watchProduct) && dataItemUid && (
        <WatchAndEditProduct
          data={dataItemUid}
          dataCategory={listProductCategory}
          dataDetailsView={dataDetails.data}
          isOpenWatch={isOpen.watchProduct}
          isOpenEdit={isOpen.editProduct}
          onClose={onClose}
          warning={true}
        />
      )}
      {isOpen.addFromMain && (
        <DialogAddTable
          uidCategory={isOpen.uid}
          isOpen={isOpen.addFromMain}
          onClose={onClose}
          onBack={comBack}
          nameTable={dataCategoryChild}
        />
      )}
    </>
  );
};

export default memo(MainProductCategory);

const useStyles = makeStyles(theme => ({
  contentBox: {
    width: "100%",
    minHeight: "720px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  contentBoxChild: {
    width: "100%",
    minHeight: "720px",
    textAlign: "center",
    paddingTop: 250,
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
      textAlign: "center",
      paddingTop: "50%",
    },
  },
  buttonAddGood: {
    marginTop: 8,
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  category: {
    textTransform: "none",
  },
  categoryName: {
    color: "#5790ff",
    textTransform: "none",
  },
  categorySourceBox: { color: "#47494e", cursor: "pointer", "&:hover": { color: "#4d85ff" } },
  container: {
    flexGrow: 1,
    margin: "0 10px",
  },
  headerBoxName: {
    width: "100%",
    height: 30,
    fontSize: 14,
    marginTop: 15,
    marginLeft: 24,
  },
  listProduct: {
    height: "fit-content",
    paddingLeft: 10,
    paddingRight: 10,
  },
  listProductCategorySub: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(304px, 1fr))",
  },
  boxCategorySubCategory: {
    height: "fit-content",
    gridTemplateColumns: "repeat(auto-fill,minmax(304px, 1fr))",
  },
}));

const CATEGORY = "category";
const CATEGORY_CHILD = "categoryChild";
const EDIT = "edit";
