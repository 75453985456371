import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box, Typography, Grid, Checkbox, Button } from "@material-ui/core";
import { EventAvailable, EventBusy } from "@material-ui/icons";
import { LangConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import { jumpToTargetBooking } from "utils";
import { useDispatch } from "react-redux";
import { CustomDatePicker, CustomTimePicker } from "../componentDialog";
import { format as formatTime } from "date-fns";
import ReservationAction from "redux/reservation.redux";
import Cookie from "js-cookie";
import { useHistory } from "react-router-dom";

const TabPeriodic = props => {
  const { value, index, dataParent, isEditing, setIsOpen } = props;
  const classes = useStyles();
  const history = useHistory();
  const role = Cookie.get(AppConstant.ROLE);
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  let isShow = value === index;
  const [dataPeriodic, setDataPeriodic] = useState(dataParent);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [listSelected, setListSelected] = useState([]);

  const onCheckedAll = e => {
    let newSelected = [];
    dataPeriodic.periodic.forEach(value => (Date.now() < value.startTime * 1000 ? newSelected.push(value.uid) : null));
    if (e.target.checked) {
      setIsCheckedAll(true);
      setListSelected(newSelected);
      return;
    }
    setListSelected([]);
    setIsCheckedAll(false);
  };

  const onChecked = (e, uid) => {
    let checked = e.target.checked;
    if (checked) {
      setListSelected([...listSelected, uid]);
      return;
    }
    setListSelected(listSelected.filter(data => data !== uid));
    if (listSelected.length <= 1) {
      setIsCheckedAll(false);
    }
  };

  const onOpenReservationOther = data => {
    setIsOpen(true);
    dispatch(ReservationAction.resetBookingDetail());
    jumpToTargetBooking(history, data.startTime, data.uid);
  };

  const onChangeSelectedDate = (date, index) => {
    let selectedTime = new Date(date).getTime() / 1000;
    let arrayTime = [];
    dataPeriodic.periodic.forEach((value, i) =>
      i !== index ? arrayTime.push(value.startTime) : arrayTime.push(selectedTime),
    );
    arrayTime.sort();
    let newExtend = { ...dataPeriodic };
    newExtend.periodic = dataPeriodic.periodic.map((value, i) => {
      value.startTime = arrayTime[i];
      return value;
    });
    setDataPeriodic(newExtend);
  };

  const onChangeSelectedTime = (currentTime, lastTime, index) => {
    let currentTimestamp = parseInt(currentTime.substring(0, 2)) * 60 * 60 + parseInt(currentTime.substring(3)) * 60;
    let lastTimestamp = parseInt(lastTime.substring(0, 2)) * 60 * 60 + parseInt(lastTime.substring(3)) * 60;
    let minusTime = lastTimestamp - currentTimestamp;
    let newTime = dataPeriodic.periodic[index].startTime + minusTime;
    let arrayTime = [];
    dataPeriodic.periodic.forEach((value, i) =>
      i !== index ? arrayTime.push(value.startTime) : arrayTime.push(newTime),
    );
    arrayTime.sort();
    let newExtend = { ...dataPeriodic };
    newExtend.periodic = dataPeriodic.periodic.map((value, i) => {
      value.startTime = arrayTime[i];
      return value;
    });
    setDataPeriodic(newExtend);
  };

  const onConfirmPeriodic = () => {
    let newExtend = { ...dataPeriodic };
    newExtend.periodic = dataPeriodic.periodic.map(value => {
      if (listSelected.includes(value.uid)) {
        value.status = AppConstant.STT_RESERVATION.confirm;
      }
      return value;
    });
    setDataPeriodic(newExtend);
    dispatch(
      ReservationAction.postReservationListStatus({ status: AppConstant.STT_RESERVATION.confirm, uids: listSelected }),
    );
  };

  useEffect(() => {
    setDataPeriodic(dataParent);
  }, [dataParent]);

  const sortPeriodicCode = data => {
    return data.sort((a, b) => (a.code > b.code ? 1 : -1));
  };

  return (
    <Box className={isShow ? classes.root : classes.rootHidden}>
      {isShow && (
        <Grid className={classes.root} container spacing={1}>
          <LineTitle onChecked={onCheckedAll} isChecked={isCheckedAll} />
          {dataPeriodic.periodic &&
            sortPeriodicCode(dataPeriodic.periodic).map((data, index) => (
              <LineBodyPeriodic
                data={data}
                index={index}
                onOpen={() => {
                  onOpenReservationOther(dataPeriodic.periodic[index]);
                }}
                isEditing={isEditing}
                onChangeSelectedDate={onChangeSelectedDate}
                onChangeSelectedTime={onChangeSelectedTime}
                listSelected={listSelected}
                onChecked={onChecked}
                key={index}
              />
            ))}
          {role !== AppConstant.SHOP_STAFF && (
            <Button onClick={onConfirmPeriodic} className={classes.buttonConfirm}>
              {getLabel(LangConstant.TXT_CONFIRM)}
            </Button>
          )}
        </Grid>
      )}
    </Box>
  );
};

const LineTitle = ({ onChecked, isChecked }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const role = Cookie.get(AppConstant.ROLE);

  return (
    <Box className={classes.rootHeader}>
      <Box width="144px">
        <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_PLACE_CODE)}</Typography>
      </Box>
      <Box width="166px">
        <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_TIME)}</Typography>
      </Box>
      <Box width={role !== AppConstant.SHOP_STAFF ? 95 : 110}>
        <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_AVAILABLE)}</Typography>
      </Box>
      <Box>
        <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_ACCEPT)}</Typography>
      </Box>
      {role == AppConstant.SHOP_STAFF && (
        <Box marginLeft="3px">
          <Checkbox
            checked={isChecked}
            onChange={onChecked}
            className={classes.checkbox}
            color="primary"
            size="small"
          />
        </Box>
      )}
    </Box>
  );
};

const LineBodyPeriodic = ({
  data,
  index,
  isEditing,
  onOpen,
  onChangeSelectedDate,
  onChangeSelectedTime,
  listSelected,
  onChecked,
}) => {
  const classes = useStyles();
  let isPast = Boolean(Date.now() > data.startTime * 1000);
  return (
    <Box className={classes.rootBody}>
      <Box height="20px" width="144px">
        {!isEditing ? (
          <Button
            onClick={() => onOpen(data.uid)}
            disabled={isPast}
            classes={{ root: classes.codeButton, disabled: classes.disabled }}
          >
            {data.code}
          </Button>
        ) : (
          <Typography className={!isPast ? classes.typoTime : classes.typoCodeHidden}>{data.code}</Typography>
        )}
      </Box>
      {!isEditing || isPast ? (
        <Box height="20px" width="166px" display="flex">
          <Box width="95px">
            <Typography className={!isPast ? classes.typoTime : classes.typoCodeHidden}>
              {formatTime(new Date(data.startTime * 1000), AppConstant.FM_DD_MM_YYYY)}
            </Typography>
          </Box>
          <Box width="81px">
            <Typography className={!isPast ? classes.typoTime : classes.typoCodeHidden}>
              {formatTime(new Date(data.startTime * 1000), AppConstant.FM_HH_MM)}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box height="20px" width="166px" display="flex">
          <Box width="95px">
            <CustomDatePicker onChange={onChangeSelectedDate} selectedDateProp={data.startTime * 1000} index={index} />
          </Box>
          <Box width="81px">
            <CustomTimePicker onChange={onChangeSelectedTime} selectedTimeProp={data.startTime * 1000} index={index} />
          </Box>
        </Box>
      )}
      <Box height="20px" width="52px" textAlign="center" marginRight="43px">
        {!(isEditing && isPast) &&
          (data.available ? (
            <EventAvailable className={classes.eventAvailable} />
          ) : (
            <EventBusy className={classes.eventBusy} />
          ))}
      </Box>
      <Box width="77px" textAlign="center">
        {isPast ? (
          <Checkbox
            checked={!(data.status === 1)}
            disabled
            classes={{
              root: classes.checkbox,
              disabled: classes.disabled,
              checked: classes.checked,
              colorPrimary: classes.colorPrimary,
            }}
            color="primary"
            size="small"
          />
        ) : (
          <Checkbox
            checked={!(data.status === 1) || Boolean(listSelected.find(value => value === data.uid))}
            disabled={!(data.status === 1)}
            onChange={e => onChecked(e, data.uid)}
            classes={{
              root: classes.checkbox,
              disabled: classes.disabled,
              checked: classes.checked,
              colorPrimary: classes.colorPrimary,
            }}
            color="primary"
            size="small"
          />
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    display: "block",
    "@media only screen and (max-width: 375px)": {
      width: 660,
      overflowX: "scroll",
    },
  },
  rootHidden: {
    display: "none",
  },
  rootHeader: {
    display: "flex",
    height: 20,
    marginBottom: 5,
  },
  rootBody: {
    display: "flex",
    height: 20,
    marginTop: 11,
  },
  rootTypoHeader: {
    fontSize: 12,
    color: "#3e4045",
    fontWeight: 600,
    height: 20,
    paddingTop: 2,
  },
  checkbox: {
    padding: 0,
  },
  disabled: {
    opacity: 0.3,
    color: theme.palette.text.link,
  },
  checked: {
    "&$disabled": {
      color: "#ef5845",
      opacity: 0.3,
    },
  },
  colorPrimary: {
    "&$disabled": {
      opacity: 0.3,
    },
  },
  codeButton: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    color: theme.palette.text.link,
    fontSize: 13,
    minWidth: 30,
    opacity: 1,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
    "&$disabled": {
      opacity: 0.3,
      color: theme.palette.text.link,
    },
  },
  buttonConfirm: {
    width: 70,
    height: 24,
    fontSize: 12,
    color: theme.palette.white,
    backgroundColor: theme.palette.reservation.active,
    textTransform: "inherit",
    fontWeight: 500,
    borderRadius: 2,
    padding: 0,
    marginTop: 17,
    "&:hover": {
      backgroundColor: theme.palette.reservation.active,
    },
  },
  eventAvailable: {
    fontSize: 18,
    marginTop: 1,
    color: theme.palette.reservation.active,
  },
  eventBusy: {
    fontSize: 18,
    marginTop: 1,
    color: "#feba40",
  },
  typoTime: {
    fontSize: 13,
    marginTop: 2,
  },
  typoCodeHidden: {
    fontSize: 13,
    marginTop: 2,
    opacity: 0.3,
  },
}));

export default memo(TabPeriodic);
