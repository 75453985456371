import { ApiConstant } from "../const";
import { createApiDownloadExcel, createApiReservation } from "../api";
import StringFormat from "string-format";

export const createBill = data => createApiReservation().post(ApiConstant.BILL_INFORMATION, data);

export const updateBill = data =>
  createApiReservation().put(StringFormat(ApiConstant.UPDATE_BILL_INFORMATION, data.uid), data);

export const deleteBill = data =>
  createApiReservation().delete(StringFormat(ApiConstant.UPDATE_BILL_INFORMATION, data.uid), data);

export const patchUpdateBillStatus = data => createApiReservation().patch(ApiConstant.BILL_INFORMATION, data);

export const getBillList = data => createApiReservation().get(ApiConstant.BILL_INFORMATION, data);

export const exportBillList = data => createApiDownloadExcel().get(ApiConstant.EXPORT_BILL_INFORMATION, data);

export const getReservationBillDetail = data =>
  createApiReservation().get(StringFormat(ApiConstant.GET_RESERVATION_BILL_DETAIL, data.uid));
