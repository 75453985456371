import { AppConstant } from "const";

// Common language
export const ARR_LANGUAGE = [
  { lang: "Tiếng Việt", code: "vi" },
  { lang: "English", code: "en" },
];

// weekdays default
export const DAY_OF_WEEK = ["Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7", "Chủ nhật"];
export const ARR_HAPPENS_EVERY = ["Ngày", "Tuần", "Tháng"];

// language code
export const DEFAULT_LANG = "vi";

// Namespace corresponding with screen
export const NS_COMMON = "common";
export const NS_REPORT_DASHBOARD = "reportDashboard";
export const NS_MANAGE_BOOKING = "manageBooking";
export const NS_ACTIVITY_HISTORY = "activityHistory";
export const NS_BOOKING_HISTORY = "bookingHistory";
export const NS_MAIN_LAYOUT = "mainLayout";
export const NS_MAINTENANCE = "maintenance";
export const NS_MANAGE_M_PRODUCT = "manageMasterProduct";
export const NS_PAYMENT_SETUP = "paymentSetup";
export const NS_PLACE_MANAGE = "placeManage";
export const NS_BOOKING_SETUP_PREVIEW = "bookingSetupPreview";
export const NS_MANAGE_ORDER = "manageOrder";
export const NS_PLAN_PAYMENT = "planPayment";
export const NS_VAT = "vatManagement";
export const NS_RATING_MANAGEMENT = "ratingManagement";
export const NS_CONSUMER = "consumer";
export const NS_NOTIFICATION_SETTING = "notificationSetting";
export const NS_GROUP_ADMIN = "groupAdmin";

/* Text Key */
export const TXT_STATUS = "TXT_STATUS";
export const TXT_LOGIN = "TXT_LOGIN";
export const TXT_PENDING = "TXT_PENDING";
export const TXT_CONFIRM = "TXT_CONFIRM";
export const TXT_SEATED = "TXT_SEATED";
export const TXT_ACTIVE = "TXT_ACTIVE";
export const TXT_ACTIVE_ORDER = "TXT_ACTIVE_ORDER";
export const TXT_FINISHED_ORDER = "TXT_FINISHED_ORDER";
export const TXT_FINISHED = "TXT_FINISHED";
export const TXT_CANCEL = "TXT_CANCEL";
export const TXT_REJECT = "TXT_REJECT";
export const TXT_CHANGE_STT = "TXT_CHANGE_STT";
export const TXT_SAVE = "TXT_SAVE";
export const TXT_DETAIL_INFO = "TXT_DETAIL_INFO";
export const TXT_ADD = "TXT_ADD";
export const TXT_ADD_NEW = "TXT_ADD_NEW";
export const TXT_ADD_NEW_PAGE = "TXT_ADD_NEW_PAGE";
export const TXT_EXPORT_FILE = "TXT_EXPORT_FILE";
export const TXT_COPYRIGHT = "TXT_COPYRIGHT";
export const TXT_SAVE_INFO = "TXT_SAVE_INFO";
export const TXT_FORGOT_PASSWORD = "TXT_FORGOT_PASSWORD";
export const TXT_SEND = "TXT_SEND";
export const TXT_MON = "TXT_MON";
export const TXT_TUE = "TXT_TUE";
export const TXT_WED = "TXT_WED";
export const TXT_THU = "TXT_THU";
export const TXT_FRI = "TXT_FRI";
export const TXT_SAT = "TXT_SAT";
export const TXT_SUN = "TXT_SUN";
export const TXT_MON_SHORT = "TXT_MON_SHORT";
export const TXT_TUE_SHORT = "TXT_TUE_SHORT";
export const TXT_WED_SHORT = "TXT_WED_SHORT";
export const TXT_THU_SHORT = "TXT_THU_SHORT";
export const TXT_FRI_SHORT = "TXT_FRI_SHORT";
export const TXT_SAT_SHORT = "TXT_SAT_SHORT";
export const TXT_SUN_SHORT = "TXT_SUN_SHORT";

export const TXT_DATE = "TXT_DATE";
export const TXT_WEEK = "TXT_WEEK";
export const TXT_MONTH = "TXT_MONTH";
export const TXT_GBOSS = "TXT_GBOSS";
export const TXT_FACEBOOK = "TXT_FACEBOOK";

export const TXT_TIME = "TXT_TIME";
export const TXT_RESERVATION_COUNT = "TXT_RESERVATION_COUNT";
export const TXT_ACCOUNT_STATUS = "TXT_ACCOUNT_STATUS";
export const TXT_CONSUMER_NUMBER = "TXT_CONSUMER_NUMBER";
export const TXT_ACCOUNT_SETTING = "TXT_ACCOUNT_SETTING";
export const TXT_LOGOUT = "TXT_LOGOUT";
export const TXT_ADD_IMAGE = "TXT_ADD_IMAGE";
export const TXT_DELETE_IMAGE = "TXT_DELETE_IMAGE";
export const TXT_USER_NAME = "TXT_USER_NAME";
export const TXT_EMAIL = "TXT_EMAIL";
export const TXT_SEX = "TXT_SEX";
export const TXT_BIRTH_DAY = "TXT_BIRTH_DAY";
export const TXT_PHONE_NUMBER = "TXT_PHONE_NUMBER";
export const TXT_NONE = "TXT_NONE";
export const TXT_MAN = "TXT_MAN";
export const TXT_WOMAN = "TXT_WOMAN";
export const TXT_OTHER = "TXT_OTHER";
export const TXT_DAY = "TXT_DAY";
export const TXT_DAY_LOWERCASE = "TXT_DAY_LOWERCASE";
export const TXT_H = "TXT_H";
export const TXT_MIN = "TXT_MIN";
export const TXT_YEAR = "TXT_YEAR";
export const TXT_ACCEPT = "TXT_ACCEPT";
export const TXT_UPDATE = "TXT_UPDATE";
export const TXT_WARNING_PASS = "TXT_WARNING_PASS";
export const TXT_OLD_PASS = "TXT_OLD_PASS";
export const TXT_NEW_PASS = "TXT_NEW_PASS";
export const TXT_CONFIRM_PASS = "TXT_CONFIRM_PASS";
export const TXT_PASS_WORD = "TXT_PASS_WORD";
export const TXT_DEL_POST = "TXT_DEL_POST";
export const TXT_POST_DEL_CONFIRM_DIALOG = "TXT_POST_DEL_CONFIRM_DIALOG";
export const TXT_OFFSYNC_GBOSS = "TXT_OFFSYNC_GBOSS";
export const TXT_OFFSYNC_CONFIRM_MESSAGE = "TXT_OFFSYNC_CONFIRM_MESSAGE";
export const TXT_HIDE_BTN = "TXT_HIDE_BTN";
export const TXT_CONFIRM_DELETE_DIALOG_TITLE = "TXT_CONFIRM_DELETE_DIALOG_TITLE";
export const TXT_CONFIRM_DELETE_DIALOG_TITLE_CONFIRM = "TXT_CONFIRM_DELETE_DIALOG_TITLE_CONFIRM";
export const TXT_CLEAR_FILTER = "TXT_CLEAR_FILTER";

export const TXT_SHOP_INFO = "TXT_SHOP_INFO";
export const TXT_SHOP_NAME = "TXT_SHOP_NAME";
export const TXT_SHOP_AVATA = "TXT_SHOP_AVATA";
export const TXT_SHOP_AVATA_HOLDER = "TXT_SHOP_AVATA_HOLDER";
export const TXT_SHOP_UPLOAD = "TXT_SHOP_UPLOAD";
export const TXT_SHOP_WELCOME_SPEECH = "TXT_SHOP_WELCOME_SPEECH";
export const TXT_SHOP_WELCOME_SPEECH_HOLDER = "TXT_SHOP_WELCOME_SPEECH_HOLDER";
export const TXT_SHOP_ADDRESS = "TXT_SHOP_ADDRESS";
export const TXT_SHOP_PHONE = "TXT_SHOP_PHONE";
export const TXT_SHOP_SAVE = "TXT_SHOP_SAVE";
export const TXT_SHOP_CANCEL = "TXT_SHOP_CANCEL";
export const TXT_BOOK_BASE = "TXT_BOOK_BASE";
export const TXT_BOOK_SETTING_PERSON = "TXT_BOOK_SETTING_PERSON";
export const TXT_BOOK_DAY = "TXT_BOOK_DAY";
export const TXT_BOOK_TIME = "TXT_BOOK_TIME";
export const TXT_BOOK_HELPER_NOTE = "TXT_BOOK_HELPER_NOTE";
export const TXT_BOOK_HELPER_NOTE_HOLDER = "TXT_BOOK_HELPER_NOTE_HOLDER";
export const TXT_BOOK_NOTE = "TXT_BOOK_NOTE";
export const TXT_BOOK_SETTING_PERSON_DETAIL = "TXT_BOOK_SETTING_PERSON_DETAIL";
export const TXT_BOOK_PERIODIC = "TXT_BOOK_PERIODIC";
export const TXT_BOOK_POSTITION_TYPE = "TXT_BOOK_POSTITION_TYPE";
export const TXT_BOOK_SERVICE_TYPE = "TXT_BOOK_SERVICE_TYPE";
export const TXT_BOOK_USED_TIME = "TXT_BOOK_USED_TIME";
export const TXT_BOOK_ADVANCE = "TXT_BOOK_ADVANCE";
export const TXT_BOOK_PREVIEW = "TXT_BOOK_PREVIEW";
export const TXT_BOOK_PREVIEW_HELPER = "TXT_BOOK_PREVIEW_HELPER";
export const TXT_BOOK_PREVIEW_PERSON = "TXT_BOOK_PREVIEW_PERSON";
export const TXT_BOOK_PREVIEW_PARENT = "TXT_BOOK_PREVIEW_PARENT";
export const TXT_BOOK_PREVIEW_CHILD = "TXT_BOOK_PREVIEW_CHILD";
export const TXT_BOOK_PREVIEW_SERVICE = "TXT_BOOK_PREVIEW_SERVICE";
export const TXT_BOOK_PREVIEW_PLACE = "TXT_BOOK_PREVIEW_PLACE";
export const TXT_BOOK_PREVIEW_PERIODIC_NOTHING = "TXT_BOOK_PREVIEW_PERIODIC_NOTHING";
export const TXT_BOOK_PREVIEW_PERIODIC_DAY = "TXT_BOOK_PREVIEW_PERIODIC_DAY";
export const TXT_BOOK_PREVIEW_PERIODIC_WEEK = "TXT_BOOK_PREVIEW_PERIODIC_WEEK";
export const TXT_BOOK_PREVIEW_PERIODIC_MONTH = "TXT_BOOK_PREVIEW_PERIODIC_MONTH";
export const TXT_NOTIFICATION_TITLE = "TXT_NOTIFICATION_TITLE";
export const TXT_NOTIFICATION_DETAIL_TITLE = "TXT_NOTIFICATION_DETAIL_TITLE";
export const TXT_NOTIFICATION_DETAIL_CONS_NAME = "TXT_NOTIFICATION_DETAIL_CONS_NAME";
export const TXT_NOTIFICATION_DETAIL_CODE = "TXT_NOTIFICATION_DETAIL_CODE";
export const TXT_NOTIFICATION_DETAIL_PHONE = "TXT_NOTIFICATION_DETAIL_PHONE";
export const TXT_NOTIFICATION_DETAIL_PARENT = "TXT_NOTIFICATION_DETAIL_PARENT";
export const TXT_NOTIFICATION_DETAIL_CHILD = "TXT_NOTIFICATION_DETAIL_CHILD";
export const TXT_NOTIFICATION_DETAIL_DATE = "TXT_NOTIFICATION_DETAIL_DATE";
export const TXT_NOTIFICATION_DETAIL_TIME = "TXT_NOTIFICATION_DETAIL_TIME";
export const TXT_NOTIFICATION_DETAIL_NOTE = "TXT_NOTIFICATION_DETAIL_NOTE";
export const TXT_NOTIFICATION_DETAIL_RESERVATION_TIME = "TXT_NOTIFICATION_DETAIL_RESERVATION_TIME";
export const TXT_NOTIFICATION_SUBJECT = "TXT_NOTIFICATION_SUBJECT";
export const TXT_NOTIFICATION_CONSUMER = "TXT_NOTIFICATION_CONSUMER";
export const FM_NOTIFICATION_SUBJECT = "FM_NOTIFICATION_SUBJECT";
export const FM_NOTIFICATION_SUBJECT_NEWS = "FM_NOTIFICATION_SUBJECT_NEWS";
export const FM_NOTIFICATION_SUBJECT_RESERVATION_INFO_UPDATE = "FM_NOTIFICATION_SUBJECT_RESERVATION_INFO_UPDATE";
export const FM_NOTIFICATION_SUBJECT_RESERVATION_STATUS_UPDATE = "FM_NOTIFICATION_SUBJECT_RESERVATION_STATUS_UPDATE";
export const FM_NOTIFICATION_SUBJECT_ROLE_UPDATE = "FM_NOTIFICATION_SUBJECT_ROLE_UPDATE";
export const FM_NOTIFICATION_SUBJECT_RESERVATION_ASSIGN = "FM_NOTIFICATION_SUBJECT_RESERVATION_ASSIGN";
export const FM_NOTIFICATION_SUBJECT_RESERVATION_PAYMENT_FOR_SCHEDULING =
  "FM_NOTIFICATION_SUBJECT_RESERVATION_PAYMENT_FOR_SCHEDULING";
export const TXT_CONSUMER_INFORMATION = "TXT_CONSUMER_INFORMATION";
export const TXT_RESERVATION_DETAIL = "TXT_RESERVATION_DETAIL";
export const TXT_CONSUMER_NOTE = "TXT_CONSUMER_NOTE";
export const TXT_INTERNAL_NOTE = "TXT_INTERNAL_NOTE";
export const TXT_CURRENCY_COMMON = "TXT_CURRENCY_COMMON";
export const TXT_TOTAL_MONEY = "TXT_TOTAL_MONEY";
export const TXT_PRE_PAYMENT = "TXT_PRE_PAYMENT";
export const TXT_SEND_REQUEST_PAYMENT = "TXT_SEND_REQUEST_PAYMENT";
export const TXT_ADD_NEW_BOOKING = "TXT_ADD_NEW_BOOKING";
export const TXT_ADD_NEW_BOOKING_WARNING_NAME = "TXT_ADD_NEW_BOOKING_WARNING_NAME";
export const TXT_ADD_NEW_BOOKING_WARNING_TELEPHONE = "TXT_ADD_NEW_BOOKING_WARNING_TELEPHONE";
export const TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS = "TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS";
export const TXT_ADD_NEW_BOOKING_EXTEND = "TXT_ADD_NEW_BOOKING_EXTEND";
export const TXT_ADD_NEW_BOOKING_SHORT_CUT = "TXT_ADD_NEW_BOOKING_SHORT_CUT";
export const TXT_TOPPING_PRODUCT_ACCOMPANIED = "TXT_TOPPING_PRODUCT_ACCOMPANIED";
export const TXT_LINK_UPGRADE = "TXT_LINK_UPGRADE";

export const FM_EXPIRED_PAYMENT_PACKAGE = "FM_EXPIRED_PAYMENT_PACKAGE";
export const FM_EXPIRED_PAYMENT_FEATURES = "FM_EXPIRED_PAYMENT_FEATURES";
export const FM_EXPIRED_PAYMENT_USING_FEATURES = "FM_EXPIRED_PAYMENT_USING_FEATURES";
export const FM_SERVICE_TIME = "FM_SERVICE_TIME";

export const MSG_LIMIT_CREATE_ITEM = "MSG_LIMIT_CREATE_ITEM";

// Staff setting
export const TXT_DELETE_WORK_TIME = "TXT_DELETE_WORK_TIME";
export const TXT_UPDATE_WORK_TIME = "TXT_UPDATE_WORK_TIME";
export const TXT_UPDATE_LOCATION = "TXT_UPDATE_LOCATION";
export const TXT_DELETE_WORK_TIME_QUESTION = "TXT_DELETE_WORK_TIME_QUESTION";
export const TXT_DELETE_WORK_POSITION = "TXT_DELETE_WORK_POSITION";
export const TXT_DELETE_WORK_POSITION_QUESTION = "TXT_DELETE_WORK_POSITION_QUESTION";
export const TXT_STAFF_SETTING = "TXT_STAFF_SETTING";
export const TXT_POSITISON = "TXT_POSITISON";
export const TXT_DELETE = "TXT_DELETE";
export const TXT_DELETE_STAFF_POPUP_TITLE = "TXT_DELETE_STAFF_POPUP_TITLE";
export const TXT_DELETE_STAFF_MSG = "TXT_DELETE_STAFF_MSG";

//Manage shop info
export const TXT_SHOP_INFO_HEADER = "TXT_SHOP_INFO_HEADER";
export const TXT_SHOP_BUSINESS_CODE = "TXT_SHOP_BUSINESS_CODE";
export const TXT_SHOP_BRANCH_NAME = "TXT_SHOP_BRANCH_NAME";
export const TXT_SHOP_OWNER = "TXT_SHOP_OWNER";
export const TXT_SHOP_PHONE_NUMBER = "TXT_SHOP_PHONE_NUMBER";
export const TXT_SHOP_EMAIL = "TXT_SHOP_EMAIL";
export const TXT_SHOP_WEBSITE = "TXT_SHOP_WEBSITE";
export const TXT_SHOP_FACEBOOK = "TXT_SHOP_FACEBOOK";
export const TXT_SHOP_GMB = "TXT_SHOP_GMB";
export const TXT_SAVE_BUTTON = "TXT_SAVE_BUTTON";
export const TXT_BASIC_INFO_HEADER = "TXT_BASIC_INFO_HEADER";
export const TXT_MONDAY = "TXT_MONDAY";
export const TXT_TUESDAY = "TXT_TUESDAY";
export const TXT_WEDNESDAY = "TXT_WEDNESDAY";
export const TXT_THURSDAY = "TXT_THURSDAY";
export const TXT_FRIDAY = "TXT_FRIDAY";
export const TXT_SATURDAY = "TXT_SATURDAY";
export const TXT_SUNDAY = "TXT_SUNDAY";
export const TXT_NAME = "TXT_NAME";
export const TXT_ADVANCED_INFO_HEADER = "TXT_ADVANCED_INFO_HEADER";
export const TXT_MINUTES = "TXT_MINUTES";
export const TXT_HOURS = "TXT_HOURS";
export const TXT_CREATE_BUTTON = "TXT_CREATE_BUTTON";
export const TXT_AGREE = "TXT_AGREE";
export const TXT_DISAGREE = "TXT_DISAGREE";
export const TXT_STARTDATE = "TXT_STARTDATE";
export const TXT_WORKSHIFT = "TXT_WORKSHIFT";
export const TXT_STAFFCODE = "TXT_STAFFCODE";
export const TXT_POSITION = "TXT_POSITION";
export const TXT_WORKING_HOUR = "TXT_WORKING_HOUR";
export const TXT_GENDER = "TXT_GENDER";
export const TXT_STOPDATE = "TXT_STOPDATE";
export const TXT_FULLNAME = "TXT_FULLNAME";
export const TXT_CREATE_NEW_STAFF = "TXT_CREATE_NEW_STAFF";
export const TXT_STAFF_STATUS_ACTIVE = "TXT_STAFF_STATUS_ACTIVE";
export const TXT_STAFF_STATUS_INACTIVE = "TXT_STAFF_STATUS_INACTIVE";
export const TXT_MANAGER = "TXT_MANAGER";
export const TXT_EDIT = "TXT_EDIT";
export const TXT_SHORT_EDIT = "TXT_SHORT_EDIT";
export const TXT_REMOVED = "TXT_REMOVED";
export const TXT_STAFF_DETAIL = "TXT_STAFF_DETAIL";
export const TXT_STAFF_EDITING = "TXT_STAFF_EDITING";
export const TXT_COPY_RESERVATION = "TXT_COPY_RESERVATION";
export const TXT_NOTIFICATION_MESSAGE = "TXT_NOTIFICATION_MESSAGE";

export const TXT_SEND_INVITE = "TXT_SEND_INVITE";
export const TXT_FILTER_ACCOUNT = "TXT_FILTER_ACCOUNT";
export const TXT_INVITE_EMAIL = "TXT_INVITE_EMAIL";
export const TXT_INVITE_FACEBOOK = "TXT_INVITE_FACEBOOK";
export const TXT_INVITE_LINK = "TXT_INVITE_LINK";
export const TXT_LIST_INVITE_FACEBOOK = "TXT_LIST_INVITE_FACEBOOK";
export const TXT_TEXTED_FACEBOOK = "TXT_TEXTED_FACEBOOK";
export const TXT_INVITE_ALL = "TXT_INVITE_ALL";
export const TXT_ALL = "TXT_ALL";
export const TXT_INVITE = "TXT_INVITE";
export const TXT_INVITED = "TXT_INVITED";
export const TXT_SEND_INVITATION = "TXT_SEND_INVITATION";
export const TXT_INVITE_CONTINUE = "TXT_INVITE_CONTINUE";
export const TXT_COPIED = "TXT_COPIED";
export const TXT_COPY = "TXT_COPY";
export const TXT_CONFIRM_INVITE_ALL = "TXT_CONFIRM_INVITE_ALL";
export const TXT_STATUS_INVITE_ALL = "TXT_STATUS_INVITE_ALL";
export const TXT_CONFIRM_INVITE = "TXT_CONFIRM_INVITE";
export const TXT_STATUS_INVITE = "TXT_STATUS_INVITE";
export const TXT_REGISTERED = "TXT_REGISTERED";
export const TXT_UNREGISTERED = "TXT_UNREGISTERED";
export const TXT_HAVE_ACCOUNT = "TXT_HAVE_ACCOUNT";
export const TXT_HAVE_NOT_ACCOUNT = "TXT_HAVE_NOT_ACCOUNT";
export const TXT_BEING_ACTIVE = "TXT_BEING_ACTIVE";
export const TXT_STOP_WORKING = "TXT_STOP_WORKING";
export const TXT_ROLE = "TXT_ROLE";
export const TXT_MANAGE = "TXT_MANAGE";
export const TXT_STAFF = "TXT_STAFF";
export const TXT_WORK_TIME = "TXT_WORK_TIME";
export const TXT_TIME_IN_WORKS = "TXT_TIME_IN_WORKS";
export const TXT_MORNING = "TXT_MORNING";
export const TXT_AFTERNOON = "TXT_AFTERNOON";
export const TXT_NIGHT = "TXT_NIGHT";
export const TXT_BROKEN_CASE = "TXT_BROKEN_CASE";
export const TXT_CHEF = "TXT_CHEF";
export const TXT_SOUS_CHEF = "TXT_SOUS_CHEF";
export const TXT_VICE_COOK = "TXT_VICE_COOK";
export const TXT_WAITER = "TXT_WAITER";
export const TXT_FULL_NAME = "TXT_FULL_NAME";
export const TXT_STARTED_DATE = "TXT_STARTED_DATE";
export const TXT_CODE_STAFF = "TXT_CODE_STAFF";
export const TXT_ADJUST_DISPLAY_COLUMN = "TXT_ADJUST_DISPLAY_COLUMN";
export const TXT_RETURN_DEFAULT = "TXT_RETURN_DEFAULT";
export const TXT_CHOOSE_ALL = "TXT_CHOOSE_ALL";
export const TXT_INVITE_TO_TEAM_STAFF = "TXT_INVITE_TO_TEAM_STAFF";
export const TXT_NEXT = "TXT_NEXT";
export const TXT_LINK = "TXT_LINK";
export const TXT_FACEBOOK_LINK = "TXT_FACEBOOK_LINK";
export const TXT_SHOP_FACEBOOK_DISMOUNT = "TXT_SHOP_FACEBOOK_DISMOUNT";
export const TXT_SHOP_FACEBOOK_MOUNTSTATUS_MOUNTED = "TXT_SHOP_FACEBOOK_MOUNTSTATUS_MOUNTED";
export const TXT_SHOP_FACEBOOK_MOUNTSTATUS_UNMOUNTED = "TXT_SHOP_FACEBOOK_MOUNTSTATUS_UNMOUNTED";
export const TXT_FACEBOOK_PAGE_LIST = "TXT_FACEBOOK_PAGE_LIST";
export const TXT_SEE_MORE = "TXT_SEE_MORE";
export const TXT_DONT_HAVE_LIST_VIEW = "TXT_DONT_HAVE_LIST_VIEW";
export const TXT_SEEN_DETAIL = "TXT_SEEN_DETAIL";
export const TXT_JOINED = "TXT_JOINED";
export const TXT_HAVE_NOT_EMAIL = "TXT_HAVE_NOT_EMAIL";
export const TXT_RESERVATION = "TXT_RESERVATION";
export const TXT_PERIODIC = "TXT_PERIODIC";
export const TXT_EXTEND = "TXT_EXTEND";
export const TXT_DONT_ASSIGN = "TXT_DONT_ASSIGN";
export const TXT_PLACE_CODE = "TXT_PLACE_CODE";
export const TXT_AVAILABLE = "TXT_AVAILABLE";
export const TXT_LINK_SHOP_CONSUMER = "TXT_LINK_SHOP_CONSUMER";
export const TXT_HAPPENS_EVERY = "TXT_HAPPENS_EVERY";
export const TXT_ADD_REPEATS = "TXT_ADD_REPEATS";
export const TXT_TIMES = "TXT_TIMES";
export const TXT_BOOK_SETTING_TIME_DEFAULT = "TXT_BOOK_SETTING_TIME_DEFAULT";
export const TXT_BOOK_SETTING_TIME = "TXT_BOOK_SETTING_TIME";
export const TXT_BOOK_SETTING_BOOKING_INFO = "TXT_BOOK_SETTING_BOOKING_INFO";
export const TXT_BOOKING_SETTING_IMAGE_COVER = "TXT_BOOKING_SETTING_IMAGE_COVER";
export const TXT_BOOKING_SETTING_OTHER_INFORMATION = "TXT_BOOKING_SETTING_OTHER_INFORMATION";
export const TXT_BOOKING_SETTING_SHOP_OPEN = "TXT_BOOKING_SETTING_SHOP_OPEN";
export const TXT_wORKING_TIME = "TXT_wORKING_TIME";
export const TXT_SETTING_BUTTON = "TXT_SETTING_BUTTON";
export const TXT_SETTING_WORKING_TIME = "TXT_SETTING_WORKING_TIME";
export const TXT_START_TIME_PICKER = "TXT_START_TIME_PICKER";
export const TXT_END_TIME_PICKER = "TXT_END_TIME_PICKER";
export const TXT_ADD_CUSTOM_WORKING_TIME = "TXT_ADD_CUSTOM_WORKING_TIME";
export const TXT_REMOVE_CUSTOM_WORKING_TIME = "TXT_REMOVE_CUSTOM_WORKING_TIME";
export const TXT_WORKING_TIME_ILLUSTRATE = "TXT_WORKING_TIME_ILLUSTRATE";
export const TXT_BREAK_TIME_ILLUSTRATE = "TXT_BREAK_TIME_ILLUSTRATE";
export const TXT_CHANGING_SETTING_WORKING_TIME = "TXT_CHANGING_SETTING_WORKING_TIME";
export const FM_INVALID_TIME = "FM_INVALID_TIME";
export const TXT_TODAY = "TXT_TODAY";

/* Waiting list start */
export const TXT_WAITING_LIST = "TXT_WAITING_LIST";
export const MSG_MOVE_INTO_WAITING_LIST_TITLE = "MSG_MOVE_INTO_WAITING_LIST_TITLE";
export const MSG_MOVE_INTO_WAITING_LIST_CONTENT = "MSG_MOVE_INTO_WAITING_LIST_CONTENT";
export const MSG_MOVE_INTO_WAITING_LIST_SUCCESS = "MSG_MOVE_INTO_WAITING_LIST_SUCCESS";
export const MSG_MOVE_INTO_WAITING_LIST_FAILURE = "MSG_MOVE_INTO_WAITING_LIST_FAILURE";
export const TXT_LATE = "TXT_LATE";
export const TXT_SOON = "TXT_SOON";
/* Waiting list end */

//dialog setting
export const TXT_NOTIFICATION_SETTING = "TXT_NOTIFICATION_SETTING";
export const TXT_BOOKING = "TXT_BOOKING";
export const TXT_NEWS = "TXT_NEWS";
export const TXT_NOTIFICATION_RESEVERVATION_NEW = "TXT_NOTIFICATION_RESEVERVATION_NEW";
export const TXT_NOTIFICATION_CHANGE_BOOKING = "TXT_NOTIFICATION_CHANGE_BOOKING";
export const TXT_NOTIFICATION_CHANGE_RESERVATION = "TXT_NOTIFICATION_CHANGE_RESERVATION";
export const TXT_NOTIFICATION_ADD_NEWS = "TXT_NOTIFICATION_ADD_NEWS";
export const TXT_SETTING = "TXT_SETTING";
export const TXT_MARK_ALL_READ = "TXT_MARK_ALL_READ";
export const TXT_SHOP_INFO_SIDEBAR = "TXT_SHOP_INFO_SIDEBAR";
export const TXT_RESOURCES = "TXT_RESOURCES";
export const TXT_CALENDAR = "TXT_CALENDAR";
export const TXT_MENU = "TXT_MENU";
export const TXT_PAYMENT = "TXT_PAYMENT";
export const TXT_PEOPLE = "TXT_PEOPLE";
export const TXT_CALENDER_SETUP = "TXT_CALENDER_SETUP";
export const TXT_TIME_FRAME = "TXT_TIME_FRAME";
export const TXT_MAXIMUM_PEOPLE = "TXT_MAXIMUM_PEOPLE";
export const TXT_INFORMATION_DISPLAYED = "TXT_INFORMATION_DISPLAYED";
export const TXT_PREVIEW_ON_DESKTOP = "TXT_PREVIEW_ON_DESKTOP";
export const TXT_SETTING_BOOKING = "TXT_SETTING_BOOKING";

//News Manage
export const TXT_NEWS_POSTUPLOAD = "TXT_NEWS_POSTUPLOAD";
export const TXT_NEWS_POSTUPLOAD_INSTRUCTION = "TXT_NEWS_POSTUPLOAD_INSTRUCTION";
export const TXT_NEWS_POSTUPLOAD_PLACEHOLDER = "TXT_NEWS_POSTUPLOAD_PLACEHOLDER";
export const TXT_NEWS_POSTLIST_POSTEDITBTN = "TXT_NEWS_POSTLIST_POSTEDITBTN";
export const TXT_NEWS_POSTLIST_POSTDELETEBTN = "TXT_NEWS_POSTLIST_POSTDELETEBTN";
export const TXT_NEWS_POSTUPLOADNAV_MOBILEBTN = "TXT_NEWS_POSTUPLOADNAV_MOBILEBTN";

//Booking Manage
export const TXT_FULL_SCREEN = "TXT_FULL_SCREEN";
export const TXT_CONTENT = "TXT_CONTENT";
export const TXT_ADD_NOTE_DIALOG_TITLE = "TXT_ADD_NOTE_DIALOG_TITLE";
export const TXT_ADD_INTERNAL_NOTE = "TXT_ADD_INTERNAL_NOTE";
export const TXT_INTERNAL_NOTE_EDIT_MILESTONE = "TXT_INTERNAL_NOTE_EDIT_MILESTONE";
export const TXT_OUT_OF_DATE = "TXT_OUT_OF_DATE";
export const TXT_FILTER = "TXT_FILTER";
export const TXT_SETTING_DIALOG_TITLE = "TXT_SETTING_DIALOG_TITLE";
export const TXT_BASIC_DISPLAY_INFO_SETTING = "TXT_BASIC_DISPLAY_INFO_SETTING";
export const TXT_BASIC_DISPLAY_INFO_SETTING_DESCRIPTION = "TXT_BASIC_DISPLAY_INFO_SETTING_DESCRIPTION";
export const TXT_OPTIONAL_INFO_SETTING = "TXT_OPTIONAL_INFO_SETTING";
export const TXT_OPTIONAL_INFO_SETTING_DESCRIPTION = "TXT_OPTIONAL_INFO_SETTING_DESCRIPTION";
export const TXT_ASSIGNED_STAFF = "TXT_ASSIGNED_STAFF";
export const TXT_BOOKING_STATUS = "TXT_BOOKING_STATUS";
export const TXT_CUSTOMER_NUMBER = "TXT_CUSTOMER_NUMBER";
export const TXT_DECLINE_RESERVATION = "TXT_DECLINE_RESERVATION";
export const TXT_CONFIRM_DECLINE_RESERVATION = "TXT_CONFIRM_DECLINE_RESERVATION";
export const TXT_REJECT_RESERVATION = "TXT_REJECT_RESERVATION";
export const TXT_CONFIRM_REJECT_RESERVATION = "TXT_CONFIRM_REJECT_RESERVATION";
export const TXT_REALITY_START_TIME = "TXT_REALITY_START_TIME";
export const TXT_REALITY_END_TIME = "TXT_REALITY_END_TIME";
export const TXT_OK = "TXT_OK";
export const TXT_BUSY_AT_THIS_TIME = "TXT_BUSY_AT_THIS_TIME";
export const TXT_HAVE_CHOOSE = "TXT_HAVE_CHOOSE";
export const TXT_TOTAL = "TXT_TOTAL";
export const TXT_INCLUDED_TAX = "TXT_INCLUDED_TAX";
export const TXT_BEFORE_BOOKING = "TXT_BEFORE_BOOKING";
export const TXT_CATEGORY_PRODUCT = "TXT_CATEGORY_PRODUCT";
export const TXT_TOPPING = "TXT_TOPPING";
export const TXT_PRE_ORDER = "TXT_PRE_ORDER";
export const TXT_INFO_PRE_BOOKING = "TXT_INFO_PRE_BOOKING";
export const TXT_CHOOSE_FROM_MENU = "TXT_CHOOSE_FROM_MENU";
export const TXT_CHOOSE_FROM_PRODUCT_CATEGORY = "TXT_CHOOSE_FROM_PRODUCT_CATEGORY";
export const FM_NUMBER_PRODUCT = "FM_NUMBER_PRODUCT";
export const TXT_EDIT_FROM_MENU = "TXT_EDIT_FROM_MENU";
export const TXT_EDIT_FROM_CATEGORY = "TXT_EDIT_FROM_CATEGORY";
export const TXT_CHOOSED = "TXT_CHOOSED";
export const TXT_WAITING_CONSUMER = "TXT_WAITING_CONSUMER";
export const TXT_LIST_WAITING_CONSUMER = "TXT_LIST_WAITING_CONSUMER";
export const TXT_INVITE_WAITING_CONSUMER = "TXT_INVITE_WAITING_CONSUMER";
export const TXT_EDIT_WAITING_CONSUMER = "TXT_EDIT_WAITING_CONSUMER";
export const TXT_ARRANGE_SEAT = "TXT_ARRANGE_SEAT";
export const TXT_CREATE_BOOKING_FROM_WAITING_CONSUMER = "TXT_CREATE_BOOKING_FROM_WAITING_CONSUMER";
export const TXT_NO_RESERVATION_YET = "TXT_NO_RESERVATION_YET";
export const TXT_STATE_WAITING_CONSUMER = "TXT_STATE_WAITING_CONSUMER";
export const TXT_WAITING_TIME = "TXT_WAITING_TIME";
export const TXT_PLACE = "TXT_PLACE";
export const FM_NOTICE_TITLE = "FM_NOTICE_TITLE";
export const FM_NOTICE_CONTENT = "FM_NOTICE_CONTENT";
export const MSG_MOVE_TO_PENDING_CONSUMER_LIST = "MSG_MOVE_TO_PENDING_CONSUMER_LIST";
export const MSG_MOVE_TO_PENDING_CONSUMER_LIST_CONFIRM_MESSAGE = "MSG_MOVE_TO_PENDING_CONSUMER_LIST_CONFIRM_MESSAGE";
export const TXT_ASSIGN_TO_MYSELF = "TXT_ASSIGN_TO_MYSELF";
export const TXT_EXPORT_BILL = "TXT_EXPORT_BILL";
export const TXT_CHOOSE_REQUIRED_CATEGORY = "TXT_CHOOSE_REQUIRED_CATEGORY";

// Booking History start
export const TXT_BOOKING_HISTORY = "TXT_BOOKING_HISTORY";
export const TXT_CREATOR = "TXT_CREATOR";
export const TXT_CONSUMER = "TXT_CONSUMER";
export const TXT_SHOP = "TXT_SHOP";
export const TXT_RANGE_TIME_PICKER = "TXT_RANGE_TIME_PICKER";
export const TXT_CHOOSE = "TXT_CHOOSE";
export const TXT_CREATED_DAY = "TXT_CREATED_DAY";
export const TXT_REAL_START_TIME = "TXT_REAL_START_TIME";
export const TXT_REAL_END_TIME = "TXT_REAL_END_TIME";
export const TXT_START_TIME = "TXT_START_TIME";
export const TXT_CODE = "TXT_CODE";
export const TXT_USE_TIME = "TXT_USE_TIME";
export const TXT_HISTORY_BOOKING_STATUS = "TXT_HISTORY_BOOKING_STATUS";
export const TXT_BOOKING_PHONE_NUMBER = "TXT_BOOKING_PHONE_NUMBER";
export const TXT_CASH_SHORT = "TXT_CASH_SHORT";
export const TXT_BANKING_SHORT = "TXT_BANKING_SHORT";

export const FM_BOOKING_USE_TIME_FM_HH_MM = "FM_BOOKING_USE_TIME_FM_HH_MM";
export const FM_BOOKING_USE_TIME_FM_MM = "FM_BOOKING_USE_TIME_FM_MM";
export const FM_BOOKING_USE_TIME_FM_MM_SS = "FM_BOOKING_USE_TIME_FM_MM_SS";
export const FM_BOOKING_USE_TIME_FM_DD_HH = "FM_BOOKING_USE_TIME_FM_DD_HH";
export const FM_BOOKING_USE_TIME_FM_DD = "FM_BOOKING_USE_TIME_FM_DD";
export const FM_BOOKING_USE_TIME_FM_SS = "FM_BOOKING_USE_TIME_FM_SS";
export const TXT_BOOKED_PLACE = "TXT_BOOKED_PLACE";
export const TXT_CONSUMER_AMOUNT = "TXT_CONSUMER_AMOUNT";
export const FM_PLACE_WITH_OPACITY = "FM_PLACE_WITH_OPACITY";

// Booking History end

export const TXT_INVALID_BOOKING_TIME_ALERT = "TXT_INVALID_BOOKING_TIME_ALERT";
export const TXT_INVALID_EMAIL_ALERT = "TXT_INVALID_EMAIL_ALERT";
export const TXT_INVALID_PHONE_ALERT = "TXT_INVALID_PHONE_ALERT";
export const TXT_CONSUMER_NUMBER_REQUIRE = "TXT_CONSUMER_NUMBER_REQUIRE";

export const TXT_FIRST_LAST_NAME = "TXT_FIRST_LAST_NAME";
export const TXT_STAFF_WORKING_TIME = "TXT_STAFF_WORKING_TIME";
export const TXT_STAFF_CODE = "TXT_STAFF_CODE";
export const TXT_SERVING_TIMES = "TXT_SERVING_TIMES";

export const TXT_FACEBOOK_SYNC = "TXT_FACEBOOK_SYNC";
export const TXT_SYNC_CONFIRM = "TXT_SYNC_CONFIRM";
export const TXT_SYNC_CONFIRM_MESSAGE = "TXT_SYNC_CONFIRM_MESSAGE";
export const TXT_LINK_TO_FACEBOOK_CF = "TXT_LINK_TO_FACEBOOK_CF";
export const TXT_LINK_FB_CONFIRM_MESSAGE = "TXT_LINK_FB_CONFIRM_MESSAGE";

export const TXT_WAITING_PAYMENT = "TXT_WAITING_PAYMENT";

// Select Shop
export const TXT_SELECT_SHOP_MANAGER = "TXT_SELECT_SHOP_MANAGER";
export const TXT_SELECT_SHOP_STAFF = "TXT_SELECT_SHOP_STAFF";
export const TXT_SELECT_SHOP_CHOOSE = "TXT_SELECT_SHOP_CHOOSE";

// Sidebar
export const TXT_BOOKING_PAGE = "TXT_BOOKING_PAGE";
export const TXT_LIST = "TXT_LIST";
export const TXT_STATISTIC = "TXT_STATISTIC";
export const TXT_STAFF_KPI = "TXT_STAFF_KPI";
export const TXT_REPORT_SUMMARY = "TXT_REPORT_SUMMARY";

//Menu
export const TXT_MENU_SETUP = "TXT_MENU_SETUP";
export const TXT_HIDE_PRICE = "TXT_HIDE_PRICE";
export const TXT_HIDE_TAX = "TXT_HIDE_TAX";
export const TXT_HIDE_USED_TIME = "TXT_HIDE_USED_TIME";
export const TXT_ADD_COMBO_MENU = "TXT_ADD_COMBO_MENU";
export const TXT_ADD_SERVICE_MENU = "TXT_ADD_SERVICE_MENU";
export const TXT_ADD_GOODS_MENU = "TXT_ADD_GOODS_MENU";
export const TXT_SELECT_PRODUCT_TYPE = "TXT_SELECT_PRODUCT_TYPE";
export const TXT_SELECT_ADD_SERVICE = "TXT_SELECT_ADD_SERVICE";
export const TXT_SELECT_ADD_PRODUCT = "TXT_SELECT_ADD_PRODUCT";
export const TXT_SELECT_ADD_ALL_SERVICE = "TXT_SELECT_ADD_ALL_SERVICE";
export const TXT_SELECT_ADD_ALL_PRODUCT = "TXT_SELECT_ADD_ALL_PRODUCT";
export const TXT_SELECT_ADD_CATEGORY_SERVICE = "TXT_SELECT_ADD_CATEGORY_SERVICE";
export const TXT_SELECT_ADD_CATEGORY_PRODUCT = "TXT_SELECT_ADD_CATEGORY_PRODUCT";
export const TXT_SELECT_CATEGORY = "TXT_SELECT_CATEGORY";
export const TXT_ADD_PRODUCT = "TXT_ADD_PRODUCT";
export const TXT_ADD_ALL = "TXT_ADD_ALL";

// Report dashboard
export const TXT_SHOW = "TXT_SHOW";
export const TXT_HIDDEN = "TXT_HIDDEN";
export const TXT_CHART_TITLE_FIRST = "TXT_CHART_TITLE_FIRST";
export const TXT_CHART_TITLE_SECOND = "TXT_CHART_TITLE_SECOND";
export const TXT_CHART_TITLE_THIRD = "TXT_CHART_TITLE_THIRD";
export const TXT_SUM_NUMBER_RESERVATION = "TXT_SUM_NUMBER_RESERVATION";
export const TXT_DISPLAY_SETTING = "TXT_DISPLAY_SETTING";
export const TXT_DISPLAY_SETTING_LIMIT = "TXT_DISPLAY_SETTING_LIMIT";
export const TXT_DISPLAY_SETTING_TITLE = "TXT_DISPLAY_SETTING_TITLE";
export const TXT_VIEW_MODE = "TXT_VIEW_MODE";
export const TXT_SUMMARY = "TXT_SUMMARY";
export const TXT_DETAIL = "TXT_DETAIL";

// Manage products
export const TXT_MASTER_PRODUCT = "TXT_MASTER_PRODUCT";
export const TXT_SERVICE = "TXT_SERVICE";
export const TXT_PRODUCT = "TXT_PRODUCT";
export const TXT_COMBO = "TXT_COMBO";
export const TXT_CATEGORY = "TXT_CATEGORY";
export const TXT_SERVICE_LOWER_CASE = "TXT_SERVICE_LOWER_CASE";
export const TXT_PRODUCT_LOWER_CASE = "TXT_PRODUCT_LOWER_CASE";
export const TXT_RESULT_NOT_FOUND = "TXT_RESULT_NOT_FOUND";
export const TXT_NO_TEXT_NOTE = "TXT_NO_TEXT_NOTE";

/* Label key */
export const L_BOOKING_NAME = "L_BOOKING_NAME";
export const L_BOOKING_CODE = "L_BOOKING_CODE";
export const L_BOOKING_PHONE = "L_BOOKING_PHONE";
export const L_BOOKING_PEOPLE = "L_BOOKING_PEOPLE";
export const L_BOOKING_CHILDREN = "L_BOOKING_CHILDREN";
export const L_BOOKING_TOTAL = "L_BOOKING_TOTAL";
export const L_BOOKING_DATE = "L_BOOKING_DATE";
export const L_BOOKING_TIME = "L_BOOKING_TIME";
export const L_BOOKING_SERVICE = "L_BOOKING_SERVICE";
export const L_BOOKING_ASSIGN = "L_BOOKING_ASSIGN";
export const L_BOOKING_STATUS = "L_BOOKING_STATUS";
export const L_BOOKING_NOTE = "L_BOOKING_NOTE";
export const L_INTERNAL_NOTE = "L_INTERNAL_NOTE";
export const L_BOOKING_EMAIL = "L_BOOKING_EMAIL";
export const L_BOOKING_ARRIVAL_DATE = "L_BOOKING_ARRIVAL_DATE";
export const L_CAPACITY = "L_CAPACITY";
export const L_WORKING_TIME = "L_WORKING_TIME";
export const L_OPEN_TIME = "L_OPEN_TIME";
export const L_CLOSE_TIME = "L_CLOSE_TIME";
export const L_PLACE_CATEGORY = "L_PLACE_CATEGORY";
export const L_SERVICE_CATEGORY = "L_SERVICE_CATEGORY";
export const L_PLACE_AMOUNT = "L_PLACE_AMOUNT";
export const L_PLACE_CAPACITY = "L_PLACE_CAPACITY";
export const L_PLACE_CATEGORY_DIALOG_NAME = "L_PLACE_CATEGORY_DIALOG_NAME";
export const L_PLACE_CATEGORY_DIALOG_AMOUNT = "L_PLACE_CATEGORY_DIALOG_AMOUNT";
export const L_PLACE_CATEGORY_DIALOG_CAPACITY = "L_PLACE_CATEGORY_DIALOG_CAPACITY";
export const L_SERVICE_CATEGORY_DIALOG_NAME = "L_SERVICE_CATEGORY_DIALOG_NAME";
export const L_SERVICE_CATEGORY_DIALOG_TIME = "L_SERVICE_CATEGORY_DIALOG_TIME";
export const L_SERVICE_CATEGORY_DIALOG_DESCRIPTION = "L_SERVICE_CATEGORY_DIALOG_DESCRIPTION";
export const L_EDIT_DIALOG_NAME = "L_EDIT_DIALOG_NAME";
export const L_EDIT_DIALOG_CAPACITY = "L_EDIT_DIALOG_CAPACITY";
export const L_STAFF = "L_STAFF";
export const L_MAX_CLIENTS_PER_PLACE = "L_MAX_CLIENTS_PER_PLACE";

// Manage products
export const L_MAIN_SERVICE = "L_MAIN_SERVICE";
export const L_MAIN_PRODUCT = "L_MAIN_PRODUCT";
export const L_ATTACHED_SERVICE = "L_ATTACHED_SERVICE";
export const L_ATTACHED_PRODUCT = "L_ATTACHED_PRODUCT";
export const L_MAIN_COMBO = "L_MAIN_COMBO";
export const L_All_SERVICE = "L_All_SERVICE";
export const L_CATEGORY_SERVICE = "L_CATEGORY_SERVICE";
export const TXT_PRICE_AFTER_TAX = "TXT_PRICE_AFTER_TAX";
export const TXT_PRETAX_PRICE = "TXT_PRETAX_PRICE";
export const TXT_UPLOAD_IMAGE = "TXT_UPLOAD_IMAGE";
export const TXT_UPLOAD_IMAGE_INSTRUCTION = "TXT_UPLOAD_IMAGE_INSTRUCTION";
export const TXT_COPY_LINK_TO_PRODUCT = "TXT_COPY_LINK_TO_PRODUCT";
export const TXT_COPY_ITEM_LINK = "TXT_COPY_ITEM_LINK";

/* Placeholder key */
export const P_BOOKING_NAME = "P_BOOKING_NAME";
export const P_BOOKING_CODE = "P_BOOKING_CODE";
export const P_BOOKING_PHONE = "P_BOOKING_PHONE";
export const P_BOOKING_EMAIL = "P_BOOKING_EMAIL";
export const P_BOOKING_PEOPLE = "P_BOOKING_PEOPLE";
export const P_BOOKING_CHILDREN = "P_BOOKING_CHILDREN";
export const P_BOOKING_PEOPLE_AND_CHILD = "P_BOOKING_PEOPLE_AND_CHILD";
export const P_BOOKING_TIME = "P_BOOKING_TIME";
export const P_BOOKING_NOTE = "P_BOOKING_NOTE";
export const P_BOOKING_ARRIVAL_DATE = "P_BOOKING_ARRIVAL_DATE";
export const P_EMAIL = "P_EMAIL";
export const P_USERNAME = "P_USERNAME";
export const P_PASSWORD = "P_PASSWORD";
export const P_BOOKING_SEARCH = "P_BOOKING_SEARCH";
export const P_SERVICE_CATEGORY_DIALOG_NAME = "P_SERVICE_CATEGORY_DIALOG_NAME";
export const P_SERVICE_CATEGORY_DIALOG_TIME = "P_SERVICE_CATEGORY_DIALOG_TIME";
export const P_SERVICE_CATEGORY_DIALOG_DESCRIPTION = "P_SERVICE_CATEGORY_DIALOG_DESCRIPTION";
export const P_EDIT_DIALOG_NAME = "P_EDIT_DIALOG_NAME";
export const P_EDIT_DIALOG_CAPACITY = "P_EDIT_DIALOG_CAPACITY";
export const P_BOOK_PREVIEW_PERIODIC_SELECT = "P_BOOK_PREVIEW_PERIODIC_SELECT";
export const P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT = "P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT";
export const P_PLACE_CATEGORY_DIALOG_NAME = "P_PLACE_CATEGORY_DIALOG_NAME";
export const P_PLACE_CATEGORY_DIALOG_AMOUNT = "P_PLACE_CATEGORY_DIALOG_AMOUNT";
export const P_PLACE_CATEGORY_DIALOG_CAPACITY = "P_PLACE_CATEGORY_DIALOG_CAPACITY";
export const P_DAY = "P_DAY";
export const P_TIME = "P_TIME";
export const P_UNIT = "P_UNIT";

//Create Position Staff Dialog
export const T_CREATE_POSITION_STAFF_DIALOG = "T_CREATE_POSITION_STAFF_DIALOG";
export const T_CREATE_POSITION_STAFF_ADD = "T_CREATE_POSITION_STAFF_ADD";
export const L_CREATE_POSITION_STAFF_INPUT = "L_CREATE_POSITION_STAFF_INPUT";
export const T_CREATE_POSITION_STAFF_TITLE = "T_CREATE_POSITION_STAFF_TITLE";
//Create Working Shift Staff Dialog
export const T_CREATE_WORKING_SHIFT_STAFF_TITLE = "T_CREATE_WORKING_SHIFT_STAFF_TITLE";
export const L_CREATE_WORKING_SHIFT_STAFF_HOURS = "L_CREATE_WORKING_SHIFT_STAFF_HOURS";
export const L_CREATE_WORKING_SHIFT_STAFF_MINUTE = "L_CREATE_WORKING_SHIFT_STAFF_MINUTE";
export const T_CREATE_WORKING_SHIFT_STAFF_START = "T_CREATE_WORKING_SHIFT_STAFF_START";
export const T_CREATE_WORKING_SHIFT_STAFF_END = "T_CREATE_WORKING_SHIFT_STAFF_END";
export const T_CREATE_WORKING_SHIFT_STAFF_NAME = "T_CREATE_WORKING_SHIFT_STAFF_NAME";
export const L_CREATE_WORKING_SHIFT_STAFF_NAME = "L_CREATE_WORKING_SHIFT_STAFF_NAME";

/*Title key*/
export const T_PLACE_CATEGORY_DIALOG = "T_PLACE_CATEGORY_DIALOG";
export const T_PLACE_EDIT_CATEGORY_DIALOG = "T_PLACE_EDIT_CATEGORY_DIALOG";
export const T_CONFIRM_DIALOG = "T_CONFIRM_DIALOG";
export const T_SERVICE_CATEGORY_DIALOG = "T_SERVICE_CATEGORY_DIALOG";

/*Function key*/
export const FN_PAGINATION = "FN_PAGINATION";
export const FB_INVITE_CONSUMER = "FN_INVITE_CONSUMER";
export const FN_LIMITED_INVITE = "FN_LIMITED_INVITE";
export const FN_INVITE_SUCCESS = "FN_INVITE_SUCCESS";
export const FN_INVITE_STAFF = "FN_INVITE_STAFF";
export const FN_NOTI_INVITE_STAFF = "FN_NOTI_INVITE_STAFF";
export const FN_CONFIRM_DIALOG = "FN_CONFIRM_DIALOG";
export const FN_FAILURE_INVITATION = "FN_FAILURE_INVITATION";

// Format
// export const FORMAT_??? = "???";
export const FM_NEW_CREATED_BY_DAY = "FM_NEW_CREATED_BY_DAY";
export const FM_NEW_CREATED_BY_HOUR = "FM_NEW_CREATED_BY_HOUR";
export const FM_NEW_CREATED_BY_MINUTE = "FM_NEW_CREATED_BY_MINUTE";
export const FM_EXTEND_RESERVATION = "FM_EXTEND_RESERVATION";
export const FM_RESERVATION_PERIODIC = "FM_RESERVATION_PERIODIC";
export const FM_RESERVATION_EXTEND = "FM_RESERVATION_EXTEND";
export const TXT_NEW_CREATED_BY_SECOND = "TXT_NEW_CREATED_BY_SECOND";
export const FM_DAY_LABEL = "FM_DAY_LABEL";
export const FM_DATE_INPUT = "FM_DATE_INPUT";
export const FM_USE_TIME_IN_HOUR = "FM_USE_TIME_IN_HOUR";

export const FM_DELETE_PLACE_CATEGORY = "FM_DELETE_PLACE_CATEGORY";
export const FM_CONFIRM_DELETE_TEXT = "FM_CONFIRM_DELETE_TEXT";

// Message
export const MSG_WELCOME = "MSG_WELCOME";
export const MSG_WELCOME_LINE2 = "MSG_WELCOME_LINE2";
export const MSG_FORGOT_PASSWORD_REQUIRE = "MSG_FORGOT_PASSWORD_REQUIRE";
export const MSG_BACK_LOGIN_PAGE = "MSG_BACK_LOGIN_PAGE";
export const MSG_SHOP_SELECT = "MSG_SHOP_SELECT";
export const MSG_SHOP_SELECT_SMALL = "MSG_SHOP_SELECT_SMALL";
export const MSG_SHOP_SELECT_CONVERT = "MSG_SHOP_SELECT_CONVERT";
export const MSG_LOGIN_WITH_SOCIAL = "MSG_LOGIN_WITH_SOCIAL";
export const MSG_UPDATE_SUCCESS = "MSG_UPDATE_SUCCESS";
export const MSG_WELCOME_GROUP_ADMIN = "MSG_WELCOME_GROUP_ADMIN";

// Error
export const ERR_EMAIL = "ERR_EMAIL";
export const ERR_PASSWORD = "ERR_PASSWORD";
export const ERR_PASSWORD_DIFF = "ERR_PASSWORD_DIFF";
export const ERR_LOGIN = "ERR_LOGIN";
export const ERR_EXCEPTION = "ERR_EXCEPTION";
export const ERR_MISSING_REQUIRED_FIELD = "ERR_MISSING_REQUIRED_FIELD";
export const ERR_INVALID_DAY = "ERR_INVALID_DAY";
export const ERR_INVALID_PHONE = "ERR_INVALID_PHONE";
export const ERR_INVALID_DAY_OR_TIME = "ERR_INVALID_DAY_OR_TIME";
export const ERR_LIMITED_FEATURE_NUMBER = "ERR_LIMITED_FEATURE_NUMBER";

export const ERR_BAD_REQUEST = "ERR_BAD_REQUEST";
export const ERR_NOT_FOUND = "ERR_NOT_FOUND";

// Array

export const ARR_STATUS_RESERVATION = [
  TXT_PENDING,
  TXT_CONFIRM,
  TXT_SEATED,
  TXT_ACTIVE,
  TXT_FINISHED,
  TXT_CANCEL,
  TXT_REJECT,
  TXT_WAITING_PAYMENT,
];

export const ARR_STATUS_ORDER = [
  TXT_PENDING,
  TXT_CONFIRM,
  TXT_ACTIVE_ORDER,
  TXT_FINISHED_ORDER,
  TXT_CANCEL,
  TXT_REJECT,
];

export const OBJECT_STATUS_ORDER = {
  1: TXT_PENDING,
  2: TXT_CONFIRM,
  4: TXT_ACTIVE_ORDER,
  5: TXT_FINISHED_ORDER,
  6: TXT_CANCEL,
  7: TXT_REJECT,
};

export const ARR_STATUS_FILTER = [TXT_STATUS, ...ARR_STATUS_RESERVATION];
// ROLE

export const TXT_SHOP_MANAGER = "TXT_SHOP_MANAGER";
export const TXT_SHOP_STAFF = "TXT_SHOP_STAFF";
export const TXT_SHOP_CONSUMER = "TXT_SHOP_CONSUMER";

export const ROLE_LANG = {
  2: TXT_SHOP_OWNER,
  3: TXT_SHOP_MANAGER,
  4: TXT_SHOP_STAFF,
  5: TXT_SHOP_CONSUMER,
};

export const ARR_PERIOD_SEARCH_RESERVATION = [TXT_DATE, TXT_WEEK, TXT_MONTH];
export const ARR_REPORT_BUTTON_TITLE = [TXT_HOURS, TXT_DATE, TXT_WEEK, TXT_MONTH];

export const ARR_REPORT_HEADER = [TXT_TIME, TXT_RESERVATION_COUNT, TXT_CONSUMER_NUMBER];

export const ARR_REPORT_CONSUMERS_HEADER = [
  TXT_NAME,
  TXT_PHONE_NUMBER,
  TXT_EMAIL,
  TXT_RESERVATION_COUNT,
  TXT_ACCOUNT_STATUS,
];

export const ARR_PERIODIC_RESERVATION = [
  TXT_BOOK_PREVIEW_PERIODIC_NOTHING,
  TXT_BOOK_PREVIEW_PERIODIC_DAY,
  TXT_BOOK_PREVIEW_PERIODIC_WEEK,
  TXT_BOOK_PREVIEW_PERIODIC_MONTH,
];

export const ARR_DAY_OF_WEEK = [
  TXT_MONDAY,
  TXT_TUESDAY,
  TXT_WEDNESDAY,
  TXT_THURSDAY,
  TXT_FRIDAY,
  TXT_SATURDAY,
  TXT_SUNDAY,
];
export const ARR_DAY_OF_WEEK_SHORT = [
  TXT_MON_SHORT,
  TXT_TUE_SHORT,
  TXT_WED_SHORT,
  TXT_THU_SHORT,
  TXT_FRI_SHORT,
  TXT_SAT_SHORT,
  TXT_SUN_SHORT,
];

export const ARR_GENDER = [TXT_MAN, TXT_WOMAN, TXT_OTHER];

export const ARR_TIME_UNIT = [TXT_MINUTES, TXT_HOURS];

export const ARR_MANAGE_CONSUMER = [TXT_NAME, TXT_PHONE_NUMBER, TXT_EMAIL, TXT_BIRTH_DAY, TXT_SEX, TXT_ACCOUNT_STATUS];

export const ARR_STATUS_MN_CONSUMER = [TXT_HAVE_ACCOUNT, TXT_HAVE_NOT_ACCOUNT];

export const ARR_WORKING_STATUS = [TXT_BEING_ACTIVE, TXT_STOP_WORKING];

export const ARR_ROLE = [TXT_SHOP_OWNER, TXT_MANAGE, TXT_STAFF];

export const ARR_WORK_TIME = [TXT_TIME_IN_WORKS, TXT_MORNING, TXT_AFTERNOON, TXT_NIGHT, TXT_BROKEN_CASE];

export const ARR_POSITION = [TXT_CHEF, TXT_SOUS_CHEF, TXT_VICE_COOK, TXT_WAITER];

export const ARR_ACCOUNT = [TXT_REGISTERED, TXT_UNREGISTERED];

export const ARR_MANAGE_STAFF = [
  TXT_FULL_NAME,
  TXT_ROLE,
  TXT_PHONE_NUMBER,
  TXT_EMAIL,
  TXT_BIRTH_DAY,
  TXT_SEX,
  TXT_STARTED_DATE,
  TXT_CODE_STAFF,
  TXT_WORK_TIME,
  TXT_POSITION,
  TXT_ACCOUNT_STATUS,
];

export const ARR_REPORT_STAFF_HEADER = [
  TXT_FIRST_LAST_NAME,
  TXT_ROLE,
  TXT_WORK_TIME,
  TXT_STAFF_CODE,
  TXT_POSITION,
  TXT_WORKING_HOUR,
  TXT_SERVING_TIMES,
];

export const NOTIFICATION_TYPE_FORMAT = {
  1: FM_NOTIFICATION_SUBJECT_NEWS,
  2: FM_NOTIFICATION_SUBJECT,
  3: FM_NOTIFICATION_SUBJECT_RESERVATION_INFO_UPDATE,
  4: FM_NOTIFICATION_SUBJECT_RESERVATION_STATUS_UPDATE,
  5: FM_NOTIFICATION_SUBJECT_ROLE_UPDATE,
  10: FM_NOTIFICATION_SUBJECT_RESERVATION_ASSIGN,
  11: FM_NOTIFICATION_SUBJECT_RESERVATION_PAYMENT_FOR_SCHEDULING,
};

export const OBJECT_KEY_NOTIFICATION = {
  reservationNew: "reservationNew", // new booking,
  reservationInfoUpdated: "reservationInfoUpdated", // Appointment information is edited,
  reservationStatusUpdated: "reservationStatusUpdated", // Booking status edited,
  reservationAssign: "reservationAssign", //designate staff,
  news: "news", //news,
  remoteOrderNew: "remoteOrderNew", //new order,
  remoteOrderUpdated: "remoteOrderUpdated", // Order information is edited,
  remoteOrderStatusUpdated: "remoteOrderStatusUpdated", // Order status edited,
  packagePayment: "packagePayment", // Notice of payment request
  disableAll: "disableAll",
};

/* MnActivityHistory start */
export const TXT_ACTIVITY_TYPE = "TXT_ACTIVITY_TYPE";
export const TXT_ACTIVITY_CREATE = "TXT_ACTIVITY_CREATE";
export const TXT_ACTIVITY_EDIT = "TXT_ACTIVITY_EDIT";
export const TXT_ACTIVITY_CHANGE = "TXT_ACTIVITY_CHANGE";
export const TXT_ACTIVITY_ASSIGN = "TXT_ACTIVITY_ASSIGN";
export const TXT_ACTIVITY_COPY = "TXT_ACTIVITY_COPY";
export const TXT_ACTIVITY_EXTEND = "TXT_ACTIVITY_EXTEND";
export const TXT_ACTIVITY_CHANGE_ORDER = "TXT_ACTIVITY_CHANGE_ORDER";
export const TXT_ACTIVITY_TIME = "TXT_ACTIVITY_TIME";
export const TXT_ACTIVITY_CODE = "TXT_ACTIVITY_CODE";
export const TXT_ACTIVITY_DETAIL = "TXT_ACTIVITY_DETAIL";
export const TXT_ACTIVITY_UPDATED_NAME = "TXT_ACTIVITY_UPDATED_NAME";
export const TXT_ACTIVITY_UPDATED_EMAIL = "TXT_ACTIVITY_UPDATED_EMAIL";
export const TXT_ACTIVITY_UPDATED_START_TIME = "TXT_ACTIVITY_UPDATED_START_TIME";
export const TXT_ACTIVITY_UPDATED_STAFF = "TXT_ACTIVITY_UPDATED_STAFF";
export const TXT_ACTIVITY_UPDATED_CONSUMER = "TXT_ACTIVITY_UPDATED_CONSUMER";
export const TXT_ACTIVITY_UPDATED_NOTE = "TXT_ACTIVITY_UPDATED_NOTE";
export const TXT_ACTIVITY_UPDATED_PHONE = "TXT_ACTIVITY_UPDATED_PHONE";
export const TXT_ACTIVITY_UPDATED_ADDRESS = "TXT_ACTIVITY_UPDATED_ADDRESS";
export const TXT_ACTIVITY_UPDATED_INTERNAL_NOTE = "TXT_ACTIVITY_UPDATED_INTERNAL_NOTE";
export const TXT_ACTIVITY_UPDATED_PLACE_CATEGORY = "TXT_ACTIVITY_UPDATED_PLACE_CATEGORY";
export const TXT_ACTIVITY_UPDATED_PLACE_ITEM = "TXT_ACTIVITY_UPDATED_PLACE_ITEM";
export const TXT_ACTIVITY_UPDATED_SERVICE_CATEGORY = "TXT_ACTIVITY_UPDATED_SERVICE_CATEGORY";
export const TXT_ACTIVITY_UPDATED_SERVICE_ITEM = "TXT_ACTIVITY_UPDATED_SERVICE_ITEM";
export const TXT_ACTIVITY_UPDATED_STAFF_NAME = "TXT_ACTIVITY_UPDATED_STAFF_NAME";
export const TXT_ACTIVITY_UPDATED_REALITY_START_TIME = "TXT_ACTIVITY_UPDATED_REALITY_START_TIME";
export const TXT_ACTIVITY_UPDATED_REALITY_END_TIME = "TXT_ACTIVITY_UPDATED_REALITY_END_TIME";

export const ARR_ACTIVITY_TYPE = [
  TXT_ACTIVITY_TYPE,
  TXT_ACTIVITY_CREATE,
  TXT_ACTIVITY_EDIT,
  TXT_ACTIVITY_CHANGE,
  TXT_ACTIVITY_ASSIGN,
  TXT_ACTIVITY_COPY,
  TXT_ACTIVITY_EXTEND,
  TXT_ACTIVITY_CHANGE_ORDER,
];
export const ARR_ACTIVITY_HEADER = [TXT_ACTIVITY_TIME, TXT_ACTIVITY_CODE, TXT_ACTIVITY_TYPE, TXT_ACTIVITY_DETAIL];

export const ARR_NOTIFICATION_SETTING = [TXT_MARK_ALL_READ, TXT_SETTING];

export const FM_ACTIVITY_DO_CREATE = "FM_ACTIVITY_DO_CREATE";
export const FM_ACTIVITY_DO_EDIT = "FM_ACTIVITY_DO_EDIT";
export const FM_ACTIVITY_DO_CHANGE = "FM_ACTIVITY_DO_CHANGE";
export const FM_ACTIVITY_DO_ASSIGN = "FM_ACTIVITY_DO_ASSIGN";
export const FM_ACTIVITY_DO_COPY = "FM_ACTIVITY_DO_COPY";
export const FM_ACTIVITY_DO_EXTEND = "FM_ACTIVITY_DO_EXTEND";
export const FM_ACTIVITY_DO_EDIT_ORDER = "FM_ACTIVITY_DO_EDIT_ORDER";

export const FM_CONSUMER_CAPACITY = "FM_CONSUMER_CAPACITY";

export const TXT_ACTIVITY_HISTORY = "TXT_ACTIVITY_HISTORY";

/* MnActivityHistory end */

/* MnOrder start */

export const TXT_CODE_ORDER = "TXT_CODE_ORDER";
export const TXT_TOTAL_PRICE_ORDER = "TXT_TOTAL_PRICE_ORDER";
export const TXT_BOOKING_DATE_ORDER = "TXT_BOOKING_DATE_ORDER";
export const TXT_NAME_CONSUMER_ORDER = "TXT_NAME_CONSUMER_ORDER";
export const TXT_ADDRESS_ORDER = "TXT_ADDRESS_ORDER";
export const TXT_STATUS_ORDER = "TXT_STATUS_ORDER";
export const TXT_CANCEL_ORDER = "TXT_CANCEL_ORDER";
export const FM_CHANGE_STATUS_ORDER = "FM_CHANGE_STATUS_ORDER";

/* MnOrder end */

/*Mn Booking History start*/

export const ARR_BOOKING_HISTORY_TABLE_HEADER = [
  TXT_CODE,
  TXT_CREATED_DAY,
  TXT_CREATOR,
  TXT_BOOKED_PLACE,
  TXT_CONSUMER_AMOUNT,
  TXT_REAL_START_TIME,
  TXT_USE_TIME,
  TXT_HISTORY_BOOKING_STATUS,
];

export const FM_BATCH_UPDATE_STATUS_DIALOG_TITLE = "FM_BATCH_UPDATE_STATUS_DIALOG_TITLE";
export const FM_BATCH_UPDATE_STATUS_DIALOG_CONTENT = "FM_BATCH_UPDATE_STATUS_DIALOG_CONTENT";

/*Mn Booking History end*/

/* Main layout */
export const TXT_ELLIPSIS = "TXT_ELLIPSIS";
export const TXT_NEW_BOOKING_NOTIFY_TITLE = "TXT_NEW_BOOKING_NOTIFY_TITLE";
export const TXT_NEW_BOOKING_DIALOG_TITLE = "TXT_NEW_BOOKING_DIALOG_TITLE";
export const TXT_NEW_BOOKING_DIALOG_CONTENT = "TXT_NEW_BOOKING_DIALOG_CONTENT";
export const TXT_CANCEL_ACTION = "TXT_CANCEL_ACTION";
export const TXT_CONFIRM_ALL = "TXT_CONFIRM_ALL";
export const ARR_NEW_BOOKING_DIALOG_BUTTON = [TXT_CANCEL_ACTION, TXT_SEEN_DETAIL, TXT_CONFIRM_ALL];
export const FM_NEW_BOOKING_REQUEST_CONTENT = "FM_NEW_BOOKING_REQUEST_CONTENT";
export const FM_NEW_BOOKING_REQUEST_PERIODIC_CONTENT = "FM_NEW_BOOKING_REQUEST_PERIODIC_CONTENT";
export const ARR_NEW_BOOKING_REQUEST_CONTENT = {
  0: FM_NEW_BOOKING_REQUEST_CONTENT,
  1: FM_NEW_BOOKING_REQUEST_PERIODIC_CONTENT,
};
export const TXT_EMPTY_NEW_BOOKING_NOTICE_MESSAGE = "TXT_EMPTY_NEW_BOOKING_NOTICE_MESSAGE";
export const TXT_EMPTY_NOTICE_MESSAGE = "TXT_EMPTY_NOTICE_MESSAGE";
export const TXT_NEW_ORDER_NOTIFY_TITLE = "TXT_NEW_ORDER_NOTIFY_TITLE";
export const TXT_EMPTY_NEW_ORDER_NOTICE_MESSAGE = "TXT_EMPTY_NEW_ORDER_NOTICE_MESSAGE";

export const OBJ_ORDER_NOTIFICATION = "OBJ_ORDER_NOTIFICATION";

export const FM_NEW_ORDER_REQUEST_CONTENT = "FM_NEW_ORDER_REQUEST_CONTENT";

/* Main layout end */

/* Report staff */
export const TXT_BGLOBAL = "TXT_BGLOBAL";
/* Report staff end*/

/* Group admin start*/
export const TXT_JOIN = "TXT_JOIN";
export const TXT_NOTIFICATION_JOIN_GROUP = "TXT_NOTIFICATION_JOIN_GROUP";
/* Group admin end*/

/* Switch team */
export const TXT_SWITCH_TEAM = "TXT_SWITCH_TEAM";

// Payment setup start
export const TXT_PAYMENT_SETUP = "TXT_PAYMENT_SETUP";
export const TXT_SHOW_PAYMENT_INFORMATION = "TXT_SHOW_PAYMENT_INFORMATION";
export const TXT_PAYMENT_REQUIRED_IN_ADVANCE = "TXT_PAYMENT_REQUIRED_IN_ADVANCE";
export const TXT_PERCENTAGE_PER_BOOKING = "TXT_PERCENTAGE_PER_BOOKING";
export const TXT_AMOUNT_PER_BOOKING = "TXT_AMOUNT_PER_BOOKING";
export const TXT_ORDER_DELIVERY = "TXT_ORDER_DELIVERY";
export const TXT_SHIP_AWAY = "TXT_SHIP_AWAY";
export const TXT_TAKE_AWAY = "TXT_TAKE_AWAY";
export const TXT_CASH = "TXT_CASH";
export const TXT_INTERNET_BANKING = "TXT_INTERNET_BANKING";
export const TXT_EDIT_BANKING_INFO = "TXT_EDIT_BANKING_INFO";
export const TXT_BANKING_SERVICE_INFO = "TXT_BANKING_SERVICE_INFO";
export const TXT_ACCOUNT_NUMBER = "TXT_ACCOUNT_NUMBER";
export const TXT_ACCOUNT_OWNER = "TXT_ACCOUNT_OWNER";
export const TXT_BANKING_CONTENT = "TXT_BANKING_CONTENT";
export const TXT_ADD_BANKING_INFO = "TXT_ADD_BANKING_INFO";
export const TXT_ADD_NEW_BANKING_INFO = "TXT_ADD_NEW_BANKING_INFO";
export const TXT_DELETE_BANKING_INFO = "TXT_DELETE_BANKING_INFO";
export const TXT_FORM_CONTAIN_EMPTY_FIELD = "TXT_FORM_CONTAIN_EMPTY_FIELD";

export const P_ORDER_PAYMENT = "P_ORDER_PAYMENT";
export const P_ORDER_DELIVERY = "P_ORDER_DELIVERY";
export const P_PAYMENT_INFORMATION = "P_PAYMENT_INFORMATION";

export const FM_BANKING_INFO = "FM_BANKING_INFO";
export const FM_DELETE_MESSAGE = "FM_DELETE_MESSAGE";
// Payment setup end

/* Maintenance Page */
export const TXT_SYSTEM_MAINTAIN = "TXT_SYSTEM_MAINTAIN";
export const TXT_SYSTEM_SORRY = "TXT_SYSTEM_SORRY";
export const TXT_TIME_SYSTEM_STOP = "TXT_TIME_SYSTEM_STOP";
export const TXT_SYSTEM_SUPPORT = "TXT_SYSTEM_SUPPORT";
export const TXT_SYSTEM_PHONE_NUMBER = "TXT_SYSTEM_PHONE_NUMBER";

//Image crop
export const TXT_EDIT_IMAGE = "TXT_EDIT_IMAGE";
export const TXT_IMAGE = "TXT_IMAGE";
export const TXT_CROP_IMAGE = "TXT_CROP_IMAGE";
export const TXT_SAVE_CROP = "TXT_SAVE_CROP";
export const TXT_REQUIRE_SAVE_MESSAGE = "TXT_REQUIRE_SAVE_MESSAGE";

export const TXT_ASSIGN_TO_ME = "TXT_ASSIGN_TO_ME";

export const TXT_DOWNLOAD = "TXT_DOWNLOAD";
export const TXT_EXIT_FULL_SCREEN = "TXT_EXIT_FULL_SCREEN";
export const TXT_BOOKING_DISPLAY_SETTING = "TXT_BOOKING_DISPLAY_SETTING";

export const WARN_IMAGE_SIZE_SMALLER_THAN_X = "WARN_IMAGE_SIZE_SMALLER_THAN_X";
/* Category */
export const TXT_ADD_CATEGORY = "TXT_ADD_CATEGORY";
export const TXT_ADD_NEW_CATEGORY = "TXT_ADD_NEW_CATEGORY";
export const TXT_EDIT_CATEGORY = "TXT_EDIT_CATEGORY";
export const TXT_ADD_CATEGORY_GOOD = "TXT_ADD_CATEGORY_GOOD";
export const TXT_CATEGORY_NAME = "TXT_CATEGORY_NAME";
export const TXT_SERVICE_NAME = "TXT_SERVICE_NAME";
export const TXT_PRODUCT_NAME = "TXT_PRODUCT_NAME";
export const TXT_PRICE = "TXT_PRICE";
export const TXT_STOCK = "TXT_STOCK";
export const TXT_CATEGORY_GOOD_HEADER = "TXT_CATEGORY_GOOD_HEADER";
export const TXT_CATEGORY_GOOD_ADD = "TXT_CATEGORY_GOOD_ADD";
export const TXT_CATEGORY_GOOD_CREATE = "TXT_CATEGORY_GOOD_CREATE";
export const TXT_WARNING_NAME_SERVICE = "TXT_WARNING_NAME_SERVICE";
export const TXT_WARNING_NAME_GOOD = "TXT_WARNING_NAME_GOOD";
export const TXT_WARNING_NAME_COMBO = "TXT_WARNING_NAME_COMBO";
export const TXT_DUPLICATE_NAME = "TXT_DUPLICATE_NAME";
export const TXT_WARNING_CATEGORY = "TXT_WARNING_CATEGORY";
export const TXT_ADD_CATEGORY_PRODUCT = "TXT_ADD_CATEGORY_PRODUCT";
export const TXT_ORDER_MENU_SETUP = "TXT_ORDER_MENU_SETUP";
export const TXT_COPY_FROM_BOOKING = "TXT_COPY_FROM_BOOKING";
export const TXT_COPY_TITLE_ORDER = "TXT_COPY_TITLE_ORDER";
export const TXT_COPY_TITLE_ORDER_CONFIRM = "TXT_COPY_TITLE_ORDER_CONFIRM";

export const FM_ITEM_STOCK = "FM_ITEM_STOCK";

/* Combo */
export const TXT_ADD_AMOUNT = "TXT_ADD_AMOUNT";
export const L_CREATE_SERVICE = "L_CREATE_SERVICE";
export const TXT_SERVICE_DESCRIPTION = "TXT_SERVICE_DESCRIPTION";
export const TXT_SETTING_SERVICE_ACCOMPANIED = "TXT_SETTING_SERVICE_ACCOMPANIED";
export const POWER = "POWER";
export const TXT_SETTING_POWER_DESCRIPTION = "TXT_SETTING_POWER_DESCRIPTION";
export const L_CREATE_PRODUCT = "L_CREATE_PRODUCT";
export const L_CATEGORY_GOODS = "L_CATEGORY_GOODS";
export const TXT_NAME_PRODUCT = "TXT_NAME_PRODUCT";
export const TXT_GOODS_DESCRIPTION = "TXT_GOODS_DESCRIPTION";
export const TXT_COSTS_AND_INVENTORY = "TXT_COSTS_AND_INVENTORY";
export const TXT_SETTING_COSTS_AND_INVENTORY_GOODS = "TXT_SETTING_COSTS_AND_INVENTORY_GOODS";
export const TXT_WARE_HOUSE = "TXT_WARE_HOUSE";
export const TXT_UNIT_PRICE_AND_TAX_AND_PRICE = "TXT_UNIT_PRICE_AND_TAX_AND_PRICE";
export const TXT_SETTING_TXT_UNIT_PRICE_AND_TAX_AND_PRICE = "TXT_SETTING_TXT_UNIT_PRICE_AND_TAX_AND_PRICE";
export const TXT_GOODS_INCLUDED = "TXT_GOODS_INCLUDED";
export const TXT_GOODS_INCLUDED_SERVICE = "TXT_GOODS_INCLUDED_SERVICE";
export const FN_DELETE_GOODS_ITEM_TITLE = "FN_DELETE_GOODS_ITEM_TITLE";
export const FN_DELETE_GOODS_ITEM_CONTENT = "FN_DELETE_GOODS_ITEM_CONTENT";
export const FN_DELETE_GOODS_CATEGORY_TITLE = "FN_DELETE_GOODS_CATEGORY_TITLE";
export const FN_DELETE_GOODS_CATEGORY_CONTENT = "FN_DELETE_GOODS_CATEGORY_CONTENT";
export const FN_DELETE_GOODS_SUB_ITEM_CONTENT = "FN_DELETE_GOODS_SUB_ITEM_CONTENT";
export const FN_DELETE_CATEGORY_MENU_TITLE = "FN_DELETE_CATEGORY_MENU_TITLE";
export const FN_DELETE_CATEGORY_MENU_CONTENT = "FN_DELETE_CATEGORY_MENU_CONTENT";

/* Combo */
export const TXT_CREATE_COMBO = "TXT_CREATE_COMBO";
export const TXT_DETAIL_COMBO = "TXT_DETAIL_COMBO";
export const TXT_EDIT_COMBO = "TXT_EDIT_COMBO";
export const TXT_INFORMATION_BASIC = "TXT_INFORMATION_BASIC";

export const TXT_NAME_COMBO = "TXT_NAME_COMBO";
export const TXT_CHOOSE_PRODUCT = "TXT_CHOOSE_PRODUCT";
export const TXT_ILLUSTRATION = "TXT_ILLUSTRATION";
export const TXT_ILLUSTRATION_VIDEO = "TXT_ILLUSTRATION_VIDEO";
export const TXT_COMBO_DESCRIPTION = "TXT_COMBO_DESCRIPTION";
export const TXT_ADD_COMBO_DESCRIPTION = "TXT_ADD_COMBO_DESCRIPTION";
export const TXT_UNIT_PRICE_AND_TIME_USED = "TXT_UNIT_PRICE_AND_TIME_USED";
export const TXT_SETTING_UNIT_PRICE_AND_TIME_USED = "TXT_SETTING_UNIT_PRICE_AND_TIME_USED";
export const TXT_PRICE_ACCORDING_TO = "TXT_PRICE_ACCORDING_TO";
export const TXT_UNIT_PRICE = "TXT_UNIT_PRICE";
export const TXT_TAX_AND_PRICE = "TXT_TAX_AND_PRICE";
export const TXT_SETTING_TAX_AND_PRICE_COMBO = "TXT_SETTING_TAX_AND_PRICE_COMBO";
export const TXT_TAX = "TXT_TAX";
export const TXT_CHOOSE_TAX = "TXT_CHOOSE_TAX";
export const TXT_NO_TAX = "TXT_NO_TAX";
export const TXT_CAN_NOT_FIND_VIDEO = "TXT_CAN_NOT_FIND_VIDEO";
export const FN_DELETE_COMBO_ITEM_TITLE = "FN_DELETE_COMBO_ITEM_TITLE";
export const FN_DELETE_COMBO_ITEM_CONTENT = "FN_DELETE_COMBO_ITEM_CONTENT";
export const FN_DELETE_MAIN_SERVICE_TITLE = "FN_DELETE_MAIN_SERVICE_TITLE";
export const FN_DELETE_MAIN_SERVICE_CONTENT = "FN_DELETE_MAIN_SERVICE_CONTENT";

export const TXT_EXCEEDED_CAPACITY_UPLOAD_IMAGE = "TXT_EXCEEDED_CAPACITY_UPLOAD_IMAGE";
export const TXT_NOTIFY_INPUT_NAME_COMBO = "TXT_NOTIFY_INPUT_NAME_COMBO";
export const TXT_INFORMATION_SALES = "TXT_INFORMATION_SALES";
export const TXT_FILL_IN_INFORMATION_SALES = "TXT_FILL_IN_INFORMATION_SALES";
export const TXT_FILL_IN_INFORMATION_SALES_COMBO = "TXT_FILL_IN_INFORMATION_SALES_COMBO";
export const TXT_PRODUCT_CLASSIFICATION = "TXT_PRODUCT_CLASSIFICATION";
export const TXT_ADD_GROUP = "TXT_ADD_GROUP";
export const TXT_NAME_CLASSIFICATION_GROUP = "TXT_NAME_CLASSIFICATION_GROUP";
export const TXT_CLASSIFICATION = "TXT_CLASSIFICATION";
export const TXT_ADD_CLASSIFICATION_SERVICE = "TXT_ADD_CLASSIFICATION_SERVICE";
export const TXT_TAX_APPLY = "TXT_TAX_APPLY";
export const TXT_LIST_OF_CATEGORIES_OF_GOOD = "TXT_LIST_OF_CATEGORIES_OF_GOOD";
export const TXT_ADD_MASTER_PRODUCT = "TXT_ADD_MASTER_PRODUCT";

export const P_FILL_CLASSIFICATION_GROUP = "P_FILL_CLASSIFICATION_GROUP";
export const P_FILL_CLASSIFICATION = "P_FILL_CLASSIFICATION";
export const P_VALUE_INPUT = "P_VALUE_INPUT";

export const FM_CLASSIFICATION_GROUP = "FM_CLASSIFICATION_GROUP";
export const FM_ADD_NAME = "FM_ADD_NAME";

export const ARR_TYPE_TIME_USED = {
  minute: TXT_MINUTES,
  hour: TXT_HOURS,
  day: TXT_DAY,
};

export const ARR_TYPE_TIME_USED_CALENDAR = {
  minute: TXT_MINUTES,
  hour: TXT_HOURS,
};

export const TXT_COST_PRICE = "TXT_COST_PRICE";

export const TXT_NOTIFY_INPUT_NAME_GOOD = "TXT_NOTIFY_INPUT_NAME_GOOD";
export const L_All_PRODUCT = "L_All_PRODUCT";
export const L_CATEGORY_PRODUCT = "L_CATEGORY_PRODUCT";

export const ARR_TYPE_TAX = {
  noTax: TXT_NO_TAX,
  fivePercent: "5%",
  tenPercent: "10%",
};

export const TXT_CHOOSE_TYPE_CREATE_ACCOMPANIED = "TXT_CHOOSE_TYPE_CREATE_ACCOMPANIED";
export const TXT_ADD_ACCOMPANIED_FROM_MAIN = "TXT_ADD_ACCOMPANIED_FROM_MAIN";
export const TXT_CREATE_SERVICE_ACCOMPANIED = "TXT_CREATE_SERVICE_ACCOMPANIED";
export const TXT_CREATE_NEW_SERVICE_ACCOMPANIED = "TXT_CREATE_NEW_SERVICE_ACCOMPANIED";
export const TXT_DETAIL_SERVICE_ACCOMPANIED = "TXT_DETAIL_SERVICE_ACCOMPANIED";
export const TXT_EDIT_SERVICE_ACCOMPANIED = "TXT_EDIT_SERVICE_ACCOMPANIED";
export const TXT_NAME_SERVICE = "TXT_NAME_SERVICE";
export const FN_NOTIFY_INPUT_NAME = "FN_NOTIFY_INPUT_NAME";

export const FN_DELETE_SERVICE_ITEM_TITLE = "FN_DELETE_SERVICE_ITEM_TITLE";
export const FN_DELETE_ACCOMPANIED_SERVICE_ITEM_CONTENT = "FN_DELETE_ACCOMPANIED_SERVICE_ITEM_CONTENT";
export const TXT_CURRENCY = "TXT_CURRENCY";

export const TXT_ADD_ACCOMPANIED_FROM_MAIN_GOOD = "TXT_ADD_ACCOMPANIED_FROM_MAIN_GOOD";
export const TXT_ADD_PRODUCT_CATEGORY_FROM_MAIN_GOOD = "TXT_ADD_PRODUCT_CATEGORY_FROM_MAIN_GOOD";
export const TXT_ADD_PRODUCT_CATEGORY_FROM_ACCOMPANIED_GOOD = "TXT_ADD_PRODUCT_CATEGORY_FROM_ACCOMPANIED_GOOD";
export const TXT_EDIT_CATEGORY_SUB_CATEGORY = "TXT_EDIT_CATEGORY_SUB_CATEGORY";
export const TXT_REMOVE_CATEGORY_SUB_CATEGORY = "TXT_REMOVE_CATEGORY_SUB_CATEGORY";
export const TXT_CREATE_PRODUCT = "TXT_CREATE_PRODUCT";
export const TXT_CATEGORY_LOWER_CASE = "TXT_CATEGORY_LOWER_CASE";
export const TXT_EDIT_SERVICE = "TXT_EDIT_SERVICE";
export const TXT_DETAIL_SERVICE = "TXT_DETAIL_SERVICE";
export const TXT_WARNING_EDIT_SERVICE = "TXT_WARNING_EDIT_SERVICE";
export const TXT_CREATE_ACCOMPANIED_PRODUCT_HEADER = "TXT_CREATE_ACCOMPANIED_PRODUCT_HEADER";
export const TXT_CREATE_ACCOMPANIED_PRODUCT_ADD = "TXT_CREATE_ACCOMPANIED_PRODUCT_ADD";
export const TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE = "TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE";
export const TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE_DIALOG = "TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE_DIALOG";
export const TXT_CREATE_ACCOMPANIED_PRODUCT_EDIT_DIALOG = "TXT_CREATE_ACCOMPANIED_PRODUCT_EDIT_DIALOG";
export const TXT_CREATE_ACCOMPANIED_PRODUCT_VIEW_DIALOG = "TXT_CREATE_ACCOMPANIED_PRODUCT_VIEW_DIALOG";
export const TXT_CREATE_ACCOMPANIED_PRODUCT_ADD_DIALOG = "TXT_CREATE_ACCOMPANIED_PRODUCT_ADD_DIALOG";
export const TXT_NOTIFY_WARNING = "TXT_NOTIFY_WARNING";
export const TXT_NOTIFY_WARNING_COMBO = "TXT_NOTIFY_WARNING_COMBO";
export const TXT_NOTIFY_WARNING_SERVICE = "TXT_NOTIFY_WARNING_SERVICE";
export const TXT_EDIT_GOOD = "TXT_EDIT_GOOD";
export const TXT_DETAIL_GOOD = "TXT_DETAIL_GOOD";

export const TAX = [
  {
    id: 0,
    title: TXT_NO_TAX,
    value: 0,
  },
  {
    id: 1,
    title: "5%",
    value: 0.05,
  },
  {
    id: 2,
    title: "10%",
    value: 0.1,
  },
];

export const FN_DELETE_SERVICE_CATEGORY_CONTENT = "FN_DELETE_SERVICE_CATEGORY_CONTENT";
export const FN_ADD_SERVICE_TO_CATEGORY = "FN_ADD_SERVICE_TO_CATEGORY";

/* Manage service start */
export const T_CREATE_MAIN_SERVICE = "T_CREATE_MAIN_SERVICE";
export const TXT_ADD_DESCRIPTION = "TXT_ADD_DESCRIPTION";
export const L_CHOOSE_SERVICE_CATEGORY = "L_CHOOSE_SERVICE_CATEGORY";
export const L_CHOOSE_ATTACHED_SERVICE = "L_CHOOSE_ATTACHED_SERVICE";
export const L_POWER_SOURCE = "L_POWER_SOURCE";
export const L_LOCATION = "L_LOCATION";
export const L_CHOOSE_LOCATION = "L_CHOOSE_LOCATION";
export const L_LABOR = "L_LABOR";
export const L_CHOOSE_LABOR = "L_CHOOSE_LABOR";
export const ARR_TYPE_UNIT_PRICE_CALCULATE = {
  time: TXT_TIME,
  times: TXT_TIMES,
};
export const TXT_SETTING_UNIT_PRICE_USED_TIME = "TXT_SETTING_UNIT_PRICE_USED_TIME";
export const TXT_SETTING_TAX_AND_PRICE = "TXT_SETTING_TAX_AND_PRICE";
export const TXT_DETAIL_MAIN_SERVICE = "TXT_DETAIL_MAIN_SERVICE";
export const FN_DELETE_CATEGORY_SERVICE_TITLE = "FN_DELETE_CATEGORY_SERVICE_TITLE";
export const FN_DELETE_CATEGORY_SERVICE_CONTENT = "FN_DELETE_CATEGORY_SERVICE_CONTENT";
export const TXT_CHOOSE_TYPE_ADD_SERVICE = "TXT_CHOOSE_TYPE_ADD_SERVICE";
export const TXT_ADD_FROM_ALL_CATEGORY_SERVICE = "TXT_ADD_FROM_ALL_CATEGORY_SERVICE";
export const TXT_CREATE_NEW = "TXT_CREATE_NEW";
export const FN_ADD_TO_CATEGORY_SERVICE = "FN_ADD_TO_CATEGORY_SERVICE";
export const FN_NOTICE_FILL = "FN_NOTICE_FILL";
export const TXT_ADD_ACCOMPANIED_FROM_ALL_ATTACHED = "TXT_ADD_ACCOMPANIED_FROM_ALL_ATTACHED";
export const FN_REMOVE_FROM_CATEGORY = "FN_REMOVE_FROM_CATEGORY";
export const FN_REMOVE = "FN_REMOVE";
/* Manage service end */

export const TXT_BOOK_SCHEDULE = "TXT_BOOK_SCHEDULE";
export const TXT_BOOKING_TIME_SUB_TITLE = "TXT_BOOKING_TIME_SUB_TITLE";
export const TXT_RESERVATION_BOOKING = "TXT_RESERVATION_BOOKING";
export const TXT_SERVICE_AND_PRODUCT = "TXT_SERVICE_AND_PRODUCT";
export const TXT_SERVICE_AND_PRODUCT_PRE_ORDER_INSTRUCTION = "TXT_SERVICE_AND_PRODUCT_PRE_ORDER_INSTRUCTION";
export const TXT_PERIODIC_BOOKING = "TXT_PERIODIC_BOOKING";
export const TXT_PERIODIC_BOX_SUB_TITLE = "TXT_PERIODIC_BOX_SUB_TITLE";
export const L_RESERVATION_NOTE = "L_RESERVATION_NOTE";
export const TXT_CONTINUE = "TXT_CONTINUE";
export const L_HIDE = "L_HIDE";
export const L_READ_MORE = "L_READ_MORE";
export const L_SEE_MORE = "L_SEE_MORE";
export const TXT_SCHEDULE = "TXT_SCHEDULE";
export const TXT_CONTACT = "TXT_CONTACT";
export const TXT_CONFIRM_PREVIEW = "TXT_CONFIRM_PREVIEW";
export const TXT_MY_PLACES = "TXT_MY_PLACES";
export const P_PEOPLE = "P_PEOPLE";
export const P_MAN = "P_MAN";
export const P_CHILDREN = "P_CHILDREN";
export const P_SELECT_SERVICE = "P_SELECT_SERVICE";
export const P_SELECT_PLACE = "P_SELECT_PLACE";
export const P_SELECT_DATE = "P_SELECT_DATE";
export const P_SELECT_TIME = "P_SELECT_TIME";
export const P_BOOK_NOTE = "P_BOOK_NOTE";
export const TXT_INFORMATION = "TXT_INFORMATION";
export const TXT_OTHER_INFORMATION = "TXT_OTHER_INFORMATION";
export const FM_FULL_DATE_TEXT = "FM_FULL_DATE_TEXT";
export const TXT_AVAILABLE_TIME = "TXT_AVAILABLE_TIME";
export const TXT_UNAVAILABLE_TIME = "TXT_UNAVAILABLE_TIME";

// Setting notification start
export const TXT_NOTIFICATION_NEW = "TXT_NOTIFICATION_NEW";
export const TXT_INFORMATION_BOOKING_EDIT = "TXT_INFORMATION_BOOKING_EDIT";
export const TXT_NEW_BOOKING = "TXT_NEW_BOOKING";
export const TXT_APPOINTMENT_BOOKING_EDIT = "TXT_APPOINTMENT_BOOKING_EDIT";
export const TXT_NEW_ORDER = "TXT_NEW_ORDER";
export const TXT_DESIGNATED_STAFF = "TXT_DESIGNATED_STAFF";
export const TXT_INFORMATION_ORDER_EDIT = "TXT_INFORMATION_ORDER_EDIT";
export const TXT_APPOINTMENT_ORDER_EDIT = "TXT_APPOINTMENT_ORDER_EDIT";
export const TXT_NOTICE_OF_PAYMENT_REQUEST = "TXT_NOTICE_OF_PAYMENT_REQUEST";
export const TXT_PHONE_NUMBER_TO_NOTIFICATION = "TXT_PHONE_NUMBER_TO_NOTIFICATION";
export const TXT_NOTIFICATION_WEBSITE = "TXT_NOTIFICATION_WEBSITE";
export const TXT_NOTIFICATION_EMAIL = "TXT_NOTIFICATION_EMAIL";
export const TXT_NOTIFICATION_PHONE_NUMBER = "TXT_NOTIFICATION_PHONE_NUMBER";
export const TXT_NOTIFICATION_GMAIL = "TXT_NOTIFICATION_GMAIL";
export const TXT_ADD_GMAIL = "TXT_ADD_GMAIL";
export const TXT_ADD_PHONE_NUMBER = "TXT_ADD_PHONE_NUMBER";
export const TXT_NOTIFICATION_CONFIRMATION_SMS = "TXT_NOTIFICATION_CONFIRMATION_SMS";
export const TXT_NOTIFICATION_CONFIRMATION_EMAIL = "TXT_NOTIFICATION_CONFIRMATION_EMAIL";
export const TXT_EDIT_EMAIL = "TXT_EDIT_EMAIL";
export const TXT_EDIT_SMS = "TXT_EDIT_SMS";
export const TXT_CONFIRMATION_BODY = "TXT_CONFIRMATION_BODY";
export const FM_CONFIRMATION_HEADER = "FM_CONFIRMATION_HEADER";
export const FM_CONFIRMATION_HEADER_SMS = "FM_CONFIRMATION_HEADER_SMS";
export const TXT_CONFIRMATION_BODY_SMS = "TXT_CONFIRMATION_BODY_SMS";

// Setting notification end

// Setting components start
export const TXT_BOOKING_TAB = "TXT_BOOKING_TAB";
export const TXT_ORDER_TAB = "TXT_ORDER_TAB";
// Setting components end

// Manage Place Start
export const FM_IS_EMPTY = "FM_IS_EMPTY";
export const FM_IS_USING = "FM_IS_USING";

export const TXT_IS_GOING_IN_USE = "TXT_IS_GOING_IN_USE";
export const TXT_IS_BUSY = "TXT_IS_BUSY";
export const TXT_PLACE_STATUS_IN_STOCK = "TXT_PLACE_STATUS_IN_STOCK";
// Manage Place End

// Remote Order start
export const TXT_NEW_ORDER_INFOR = "TXT_NEW_ORDER_INFOR";
export const TXT_RECEIVER_ADDRESS = "TXT_RECEIVER_ADDRESS";
export const TXT_EMPTY_RECEIVER_ADDRESS_WARNING = "TXT_EMPTY_RECEIVER_ADDRESS_WARNING";
export const TXT_ORDER_CONTINUE = "TXT_ORDER_CONTINUE";
export const TXT_SELECT_ITEM_OPTION = "TXT_SELECT_ITEM_OPTION";
export const TXT_UPDATE_ITEM_OPTION = "TXT_UPDATE_ITEM_OPTION";
export const TXT_ADD_FROM_MENU = "TXT_ADD_FROM_MENU";
export const TXT_ADD_NEW_ITEM_FROM_MENU = "TXT_ADD_NEW_ITEM_FROM_MENU";
export const TXT_ADD_FROM_CATEGORY = "TXT_ADD_FROM_CATEGORY";
export const TXT_ORDER_DETAIL = "TXT_ORDER_DETAIL";
export const TXT_ORDER_ITEM = "TXT_ORDER_ITEM";
export const TXT_ACCOMPANIED_ITEM = "TXT_ACCOMPANIED_ITEM";
export const TXT_ORDER_ITEM_AMOUNT = "TXT_ORDER_ITEM_AMOUNT";
export const TXT_TOTAL_PRICE = "TXT_TOTAL_PRICE";
export const TXT_ADD_ITEMS = "TXT_ADD_ITEMS";
export const TXT_RECEIVER_INFO = "TXT_RECEIVER_INFO";
export const TXT_ORDER_NOTE = "TXT_ORDER_NOTE";
export const TXT_ITEM_PRICE = "TXT_ITEM_PRICE";
export const TXT_EMPTY_NAME_WARNING = "TXT_EMPTY_NAME_WARNING";
export const TXT_INVALID_PHONE_WARNING = "TXT_INVALID_PHONE_WARNING";
export const TXT_EMPTY_ADDRESS_WARNING = "TXT_EMPTY_ADDRESS_WARNING";
export const TXT_RECEIVER_NAME = "TXT_RECEIVER_NAME";
export const TXT_SUCCESS_POST_ORDER = "TXT_SUCCESS_POST_ORDER";
export const TXT_EDIT_SHIPPING_INFO = "TXT_EDIT_SHIPPING_INFO";
export const TXT_NEW_ITEM_FROM_MENU = "TXT_NEW_ITEM_FROM_MENU";
export const TXT_NEW_ITEM_FROM_CATEGORY = "TXT_NEW_ITEM_FROM_CATEGORY";
export const TXT_ADD_ITEM_FROM_MENU = "TXT_ADD_ITEM_FROM_MENU";
export const TXT_ADD_ITEM_FROM_CATEGORY = "TXT_ADD_ITEM_FROM_CATEGORY";
export const TXT_UPDATE_ORDER = "TXT_UPDATE_ORDER";
export const TXT_CART = "TXT_CART";
export const TXT_DEPOSIT_METHOD = "TXT_DEPOSIT_METHOD";

export const P_EMPTY_NOTE = "P_EMPTY_NOTE";

export const FM_REMOTE_ORDER = "FM_REMOTE_ORDER";
export const FM_ORDER_TIME = "FM_ORDER_TIME";
// Remote order end

// Plan & Payment start
export const TXT_MANAGE_PACKAGE = "TXT_MANAGE_PACKAGE";
export const TXT_MANAGE_SUBSCRIPTION = "TXT_MANAGE_SUBSCRIPTION";
export const TXT_MANAGE_UPGRADE_PLAN = "TXT_MANAGE_UPGRADE_PLAN";
export const TXT_MANAGE_PAYMENT_HISTORY = "TXT_MANAGE_PAYMENT_HISTORY";

export const TXT_SUBSCRIPTION = "TXT_SUBSCRIPTION";
export const TXT_MY_PLAN_PACKAGE = "TXT_MY_PLAN_PACKAGE";
export const TXT_MY_ACCOUNT = "TXT_MY_ACCOUNT";
export const TXT_PLAN_PACKAGE_HISTORY = "TXT_PLAN_PACKAGE_HISTORY";
export const TXT_UPGRADE_PLAN = "TXT_UPGRADE_PLAN";

export const TXT_PACKAGE_STANDARD = "TXT_PACKAGE_STANDARD";
export const TXT_PACKAGE_PREMIUM = "TXT_PACKAGE_PREMIUM";
export const TXT_PACKAGE_ENTERPRISE = "TXT_PACKAGE_ENTERPRISE";
export const TXT_PAYMENT_HISTORY = "TXT_PAYMENT_HISTORY";
export const TXT_MY_PAYMENT = "TXT_MY_PAYMENT";
export const TXT_PAYMENT_DETAIL = "TXT_PAYMENT_DETAIL";

export const TXT_PLAN_NAME = "TXT_PLAN_NAME";
export const TXT_PLAN_PERIOD = "TXT_PLAN_PERIOD";
export const TXT_PLAN_START_DATE = "TXT_PLAN_START_DATE";
export const TXT_PLAN_END_DATE = "TXT_PLAN_END_DATE";
export const TXT_PLAN_STATUS = "TXT_PLAN_STATUS";

export const TXT_NUMBER_OF_SHOPS = "TXT_NUMBER_OF_SHOPS";
export const TXT_NUMBER_OF_PRODUCTS = "TXT_NUMBER_OF_PRODUCTS";
export const TXT_NUMBER_OF_LOCATIONS = "TXT_NUMBER_OF_LOCATIONS";
export const TXT_NUMBER_OF_STAFFS = "TXT_NUMBER_OF_STAFFS";
export const TXT_FEATURES_PACKAGE = "TXT_FEATURES_PACKAGE";

export const TXT_ACCOUNT_USER_ID = "TXT_ACCOUNT_USER_ID";
export const TXT_ACCOUNT_PHONE_NUMBER = "TXT_ACCOUNT_PHONE_NUMBER";
export const TXT_ACCOUNT_EMAIL = "TXT_ACCOUNT_EMAIL";
export const TXT_ACCOUNT_SHOPS = "TXT_ACCOUNT_SHOPS";

export const TXT_PLAN_PACKAGE = "TXT_PLAN_PACKAGE";
export const TXT_ADDITION_PRODUCT = "TXT_ADDITION_PRODUCT";

export const TXT_INCLUDE_BOOKING_ORDER = "TXT_INCLUDE_BOOKING_ORDER";
export const TXT_ONLINE_APPOINTMENT = "TXT_ONLINE_APPOINTMENT";
export const TXT_CREATE_SHOP = "TXT_CREATE_SHOP";
export const TXT_BASIC_MN_BOOKING = "TXT_BASIC_MN_BOOKING";
export const TXT_BASIC_BOOKING_DESCRIPTION = "TXT_BASIC_BOOKING_DESCRIPTION";
export const TXT_ADVANCE_MN_BOOKING = "TXT_ADVANCE_MN_BOOKING";
export const TXT_ADVANCE_BOOKING_DESCRIPTION = "TXT_ADVANCE_BOOKING_DESCRIPTION";
export const TXT_TIME_DISPLAY_REMOTE = "TXT_TIME_DISPLAY_REMOTE";

export const FM_PREMIUM_PACKAGE = "FM_PREMIUM_PACKAGE";
export const FM_STANDARD_PACKAGE = "FM_STANDARD_PACKAGE";
export const FM_FREE_TRIALS = "FM_FREE_TRIALS";
export const FM_MONTHS = "FM_MONTHS";
export const FM_YEAR = "FM_YEAR";

export const TXT_TB_PAYMENT_ID = "TXT_TB_PAYMENT_ID";
export const TXT_TB_PAYMENT_DATE = "TXT_TB_PAYMENT_DATE";
export const TXT_TB_START_DATE = "TXT_TB_START_DATE";
export const TXT_TB_END_DATE = "TXT_TB_END_DATE";
export const TXT_TB_NAME = "TXT_TB_NAME";
export const TXT_TB_PERIOD = "TXT_TB_PERIOD";
export const TXT_TB_AMOUNT = "TXT_TB_AMOUNT";
export const TXT_TB_STATUS = "TXT_TB_STATUS";
export const TXT_TB_ITEMS = "TXT_TB_ITEMS";
export const TXT_TB_KIND = "TXT_TB_KIND";
export const TXT_TB_QTY = "TXT_TB_QTY";
export const TXT_TB_UNIT_PRICE = "TXT_TB_UNIT_PRICE";
export const TXT_TB_TOTAL = "TXT_TB_TOTAL";

export const TXT_STATUS_PAID = "TXT_STATUS_PAID";
export const TXT_STATUS_UN_PAID = "TXT_STATUS_UN_PAID";
export const TXT_STATUS_CANCELED = "TXT_STATUS_CANCELED";
export const TXT_ADDITIONAL_PACKAGE = "TXT_ADDITIONAL_PACKAGE";
export const TXT_ADDITIONAL_DESCRIPTION = "TXT_ADDITIONAL_DESCRIPTION";

export const TXT_CONTACT_US = "TXT_CONTACT_US";
export const TXT_BOTH = "TXT_BOTH";
export const TXT_UNLIMITED = "TXT_UNLIMITED";

export const TXT_PACKAGE_PAYMENT_NOTICE = "TXT_PACKAGE_PAYMENT_NOTICE";
export const TXT_FEATURES_PAYMENT_NOTICE = "TXT_FEATURES_PAYMENT_NOTICE";

export const OBJ_PACKAGE_TYPE = "OBJ_PACKAGE_TYPE";
export const OBJ_FM_PACKAGE_TYPE = "OBJ_FM_PACKAGE_TYPE";
export const OBJ_PACKAGE_USING_TYPE = "OBJ_PACKAGE_USING_TYPE";
export const OBJ_PACKAGE_STATUS = "OBJ_PACKAGE_STATUS";
export const OBJ_ADDITIONAL_PACKAGE = "OBJ_ADDITIONAL_PACKAGE";

export const FM_CURRENCY = "FM_CURRENCY";
export const FM_TIME_MONTH = "FM_TIME_MONTH";
export const FM_ADDING_PRODUCT = "FM_ADDING_PRODUCT";
// Plan & Payment end

// VAT Management
export const TXT_WAITING_TO_PROCESS = "TXT_WAITING_TO_PROCESS";
export const TXT_TRANSFERRED = "TXT_TRANSFERRED";
export const TXT_BILL_EXPORTED = "TXT_BILL_EXPORTED";
export const TXT_SENT = "TXT_SENT";

export const VAT_MANAGEMENT_FILTER_STATUS_ARRAY = [TXT_WAITING_TO_PROCESS, TXT_TRANSFERRED, TXT_BILL_EXPORTED];
export const TXT_FROM = "TXT_FROM";
export const TXT_TO = "TXT_TO";

export const TXT_BILL_CODE = "TXT_BILL_CODE";
export const TXT_COMPANY_NAME = "TXT_COMPANY_NAME";
export const TXT_COMPANY_TAX_NUMBER = "TXT_COMPANY_TAX_NUMBER";
export const TXT_VAT_BILL_EDIT = "TXT_VAT_BILL_EDIT";
export const TXT_COMPANY_ADDRESS = "TXT_COMPANY_ADDRESS";
export const TXT_DELETE_BILL = "TXT_DELETE_BILL";
export const TXT_ADD_BILL_TITLE = "TXT_ADD_BILL_TITLE";
export const TXT_VAT_BILL = "TXT_VAT_BILL";
export const TXT_DELETE_BILL_SUCCESS = "TXT_DELETE_BILL_SUCCESS";

export const FM_CHANGE_VAT_BILL_STATUS = "FM_CHANGE_VAT_BILL_STATUS";
export const FM_DELETE_BILL = "FM_DELETE_BILL";

export const PH_COMPANY_NAME = "PH_COMPANY_NAME";
export const PH_TAX_CODE = "PH_TAX_CODE";
export const PH_COMPANY_ADDRESS = "PH_COMPANY_ADDRESS";
export const PH_EMAIL = "PH_EMAIL";

// Sidebar
export const TXT_MN_REVIEW = "TXT_MN_REVIEW";

// Rating Management
export const TXT_CONSUMER_RATING_MANAGEMENT = "TXT_CONSUMER_RATING_MANAGEMENT";
export const TXT_RATING_FILTER = "TXT_RATING_FILTER";
export const TXT_SHOP_RATING = "TXT_SHOP_RATING";
export const TXT_PRODUCT_RATING = "TXT_PRODUCT_RATING";
export const TXT_REPORT = "TXT_REPORT";
export const TXT_REPORTED = "TXT_REPORTED";
export const TXT_VIOLATION_REPORT = "TXT_VIOLATION_REPORT";
export const TXT_HIDDEN_REPORT_CONTENT = "TXT_HIDDEN_REPORT_CONTENT";
export const FM_TOTAL_RATING_AMOUNT = "FM_TOTAL_RATING_AMOUNT";
export const FM_TOTAL_REPORT = "FM_TOTAL_REPORT";
export const FM_RATING_SCORE = "FM_RATING_SCORE";
export const TXT_APPLY = "TXT_APPLY";
export const TXT_RATING_FROM_CONSUMER = "TXT_RATING_FROM_CONSUMER";
export const TXT_SEE_ALL_RATING = "TXT_SEE_ALL_RATING";
// Consumer start
export const TXT_BTN_IMPORT = "TXT_BTN_IMPORT";
export const TXT_BTN_CANCEL = "TXT_BTN_CANCEL";
export const TXT_INVITE_BY_PHONE_NUMBER = "TXT_INVITE_BY_PHONE_NUMBER";
export const TXT_INVITE_BY_EMAIL = "TXT_INVITE_BY_EMAIL";
export const TXT_INVITE_ALL_BY_PHONE_NUMBER = "TXT_INVITE_ALL_BY_PHONE_NUMBER";
export const TXT_INVITE_ALL_BY_EMAIL = "TXT_INVITE_ALL_BY_EMAIL";
export const TXT_INVITE_TITLE = "TXT_INVITE_TITLE";
export const TXT_INVITE_OPTION_DESCRIPTION = "TXT_INVITE_OPTION_DESCRIPTION";
export const TXT_INVITE_ALL_OPTION_DESCRIPTION = "TXT_INVITE_ALL_OPTION_DESCRIPTION";
export const TXT_EXCEL_IMPORT_TITLE = "TXT_EXCEL_IMPORT_TITLE";
export const TXT_SELECT_FILE = "TXT_SELECT_FILE";
export const TXT_NO_FILE_SELECTED = "TXT_NO_FILE_SELECTED";
export const TXT_SAMPLE_FILE_DOWNLOAD = "TXT_SAMPLE_FILE_DOWNLOAD";
export const TXT_BTN_CONTINUE = "TXT_BTN_CONTINUE";
export const TXT_WRONG_FORMAT_FILE_UPLOAD = "TXT_WRONG_FORMAT_FILE_UPLOAD";
export const TXT_INVITE_CONSUMER = "TXT_INVITE_CONSUMER";
export const TXT_CONSUMER_LIST_INCLUDE_ERROR = "TXT_CONSUMER_LIST_INCLUDE_ERROR";

export const ARR_INVITATION_METHOD = {
  email: "TXT_INVITE_BY_EMAIL_",
  sms: "TXT_INVITE_BY_PHONE_NUMBER_FREE_TRIAl",
};
export const ARR_CONSUMER_TABLE_DATA = [TXT_NAME, TXT_PHONE_NUMBER, TXT_EMAIL, TXT_BIRTH_DAY, TXT_SEX];

export const OBJ_GENDER = {
  [AppConstant.GENDER_PICKER.male]: "TXT_MAN",
  [AppConstant.GENDER_PICKER.female]: "TXT_WOMAN",
  [AppConstant.GENDER_PICKER.other]: "TXT_OTHER",
};

export const P_INVITATION_METHOD = "P_INVITATION_METHOD";

export const FN_ERROR_CONSUMER_NOTICE = "FN_ERROR_CONSUMER_NOTICE";
export const FN_DELETE_SUCCESS_NOTICE = "FN_DELETE_SUCCESS_NOTICE";
export const FN_UPLOADED_CONSUMER_LIST = "FN_UPLOADED_CONSUMER_LIST";
// Consumer end

// Booking setting preview
export const TXT_DEPOSIT_PAYMENT_INFO = "TXT_DEPOSIT_PAYMENT_INFO";
export const TXT_DEPOSIT_SHOP_PAYMENT_METHOD_INFO = "TXT_DEPOSIT_SHOP_PAYMENT_METHOD_INFO";
export const TXT_PAYMENT_METHOD = "TXT_PAYMENT_METHOD";
export const TXT_SHIPPING_COST = "TXT_SHIPPING_COST";
export const TXT_RECEIVER_PHONE = "TXT_RECEIVER_PHONE";
export const TXT_RESERVATION_ADDRESS = "TXT_RESERVATION_ADDRESS";
export const TXT_MY_ORDER = "TXT_MY_ORDER";
export const TXT_TOTAL_TAX_FREE = "TXT_TOTAL_TAX_FREE";
export const TXT_TAX_PRICE_VALUE = "TXT_TAX_PRICE_VALUE";
export const TXT_TOTAL_TAX_INCLUDED = "TXT_TOTAL_TAX_INCLUDED";
export const TXT_REMOTE_ORDER_DETAIL = "TXT_REMOTE_ORDER_DETAIL";
export const TXT_INSTRUCTION_REMOTE_ORDER = "TXT_INSTRUCTION_REMOTE_ORDER";
export const TXT_PICKUP_ADDRESS = "TXT_PICKUP_ADDRESS";
export const TXT_ORDER_PICKUP_DETAIL = "TXT_ORDER_PICKUP_DETAIL";
export const TXT_ADD_ADDRESS = "TXT_ADD_ADDRESS";
export const TXT_DEFAULT = "TXT_DEFAULT";
