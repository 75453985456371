import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import RangeDateIcon from "icons/RangeDateIcon";
import { format } from "date-fns";
import StringFormat from "string-format";
import { getCommonKey } from "utils";
import clsx from "clsx";

const ReportChartHeader = props => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);
  const primaryFormat = getLabel(getCommonKey(LangConstant.FM_DAY_LABEL));

  const [selectedDate, setSelectedDate] = useState({
    strStartDate: convertDateWithFormat(props.startDate),
    strEndDate: convertDateWithFormat(props.endDate),
  });

  useEffect(() => {
    let newData = { ...selectedDate };
    newData.strStartDate = convertDateWithFormat(props.startDate);
    newData.strEndDate = convertDateWithFormat(props.endDate);
    setSelectedDate(newData);
  }, [props]);

  return (
    <Grid item xs={12} container className={classes.selectedDateRoot}>
      <Grid item xs={6}>
        <Typography className="medium-md-txt">{getLabel(LangConstant.TXT_SUM_NUMBER_RESERVATION)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className="medium-md-txt">{StringFormat(primaryFormat, selectedDate.strStartDate)}</Typography>
        <Box className={clsx("center-root", classes.fromDateIcon)}>
          <RangeDateIcon />
        </Box>
        <Typography className="medium-md-txt">{StringFormat(primaryFormat, selectedDate.strEndDate)}</Typography>
      </Grid>
    </Grid>
  );
};

ReportChartHeader.propTypes = {
  startDate: PropTypes.objectOf(Date),
  endDate: PropTypes.objectOf(Date),
};
export default ReportChartHeader;

const convertDateWithFormat = data => format(data || new Date(), AppConstant.FM_DD_MM);

const useStyles = makeStyles(theme => ({
  selectedDateRoot: {
    marginTop: 40,
    "& > div:last-child": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",

      "&, *": {
        color: theme.palette.grey[600],
      },
    },
  },
  fromDateIcon: {
    marginLeft: 8,
    marginRight: 8,
  },
}));
