import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { LangConstant } from "../../../../../const";
import { useTranslation } from "react-i18next";

const ConfirmDialog = props => {
  const { onSelectConfirm, categoryName } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={true} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography
          variant="subtitle2"
          className={classes.textTitle}
          classes={{ subtitle2: "medium-xl-txt" }}
          color={"inherit"}
        >
          {getLabel(LangConstant.T_CONFIRM_DIALOG)}
        </Typography>
        <Typography
          variant="subtitle2"
          className={classes.textTitle}
          classes={{ subtitle2: "medium-xl-txt" }}
          color={"inherit"}
        >
          {`"${categoryName}"`}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2" classes={{ body2: "medium-md-txt" }}>
          {getLabel(LangConstant.FN_CONFIRM_DIALOG)(categoryName)}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button onClick={e => onSelectConfirm(e, true)} color="primary" className={classes.button}>
          <Typography variant="body2" classes={{ body2: "semiBold-lg-txt" }} color={"inherit"}>
            {getLabel(LangConstant.TXT_AGREE)}
          </Typography>
        </Button>
        <Button
          onClick={e => onSelectConfirm(e, false)}
          color="primary"
          className={classes.button}
          style={{ marginLeft: 30 }}
        >
          <Typography variant="body2" classes={{ body2: "semiBold-lg-txt" }} color={"inherit"}>
            {getLabel(LangConstant.TXT_DISAGREE)}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: "400px",
    borderRadius: "0%",
    maxWidth: "fit-content",
    padding: "30px 30px",
  },
  dialogTitle: {
    alignItems: "center",
    color: theme.palette.primary,
    padding: "0px 0px",
  },
  textTitle: {
    marginLeft: "auto",
  },
  dialogContent: {
    padding: "15px 0 31px",
  },
  dialogAction: {
    padding: "0px 0px",
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
    color: theme.palette.grey[400],
  },
}));

ConfirmDialog.propTypes = { onSelectConfirm: PropTypes.func, categoryName: PropTypes.string.isRequired };

ConfirmDialog.defaultProps = {};

export default memo(ConfirmDialog);
