import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import ReportStaffAction from "redux/report-staff.redux";
import { ReportStaffService } from "services";
import { toCamel, toSnake } from "utils";

export function* getReportStaff(action) {
  try {
    const { data } = action;
    let response = yield call(ReportStaffService.get, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data;
      const validatedResponseData = validateResponseArray(toCamel(responseData));
      yield put(ReportStaffAction.getReportStaffSuccess(validatedResponseData));
    } else {
      yield put(ReportStaffAction.getReportStaffFailure(response.data));
    }
  } catch (error) {
    yield put(ReportStaffAction.getReportStaffFailure(error));
  }
}

const arrayToJson = array => {
  let jsonData = {};
  for (var i = 0; i < array.length; i++) {
    jsonData[array[i].uid] = array[i];
  }
  return jsonData;
};

const validateResponseArray = data => {
  const totalPage = data ? data.totalPage : 0;
  const size = data ? data.size : 10;
  const total = data ? data.total : 0;
  const page = data ? data.page : 0;
  const staffData = data ? data.data : null;
  const validatedStaffArray = staffData
    ? staffData.map(value => {
        return validateStaffData(value);
      })
    : [];
  const validatedStaffJson = arrayToJson(validatedStaffArray);
  const validatedData = {
    staffData: validatedStaffJson,
    totalPage: totalPage,
    size: size,
    total: total,
    page: page,
  };
  return validatedData;
};

const validateStaffData = staffData => {
  const validatedStaffData = {
    count: staffData.count ? staffData.count : 0,
    uid: staffData.uid ? staffData.uid : "",
    name: staffData.name ? staffData.name : "",
    role: staffData.role ? staffData.role : 4,
    phoneNumber: staffData.role ? staffData.role : "",
    email: staffData.email ? staffData.email : "",
    birthday: staffData.birthday ? staffData.birthday : 0,
    gender: staffData.gender ? staffData.gender : 0,
    startTime: staffData.startTime ? staffData.startTime : 0,
    endTime: staffData.endTime ? staffData.endTime : 0,
    code: staffData.code ? staffData.code : 0,
    state: staffData.state ? staffData.state : 1,
    workingShift: staffData.workingShift ? validateWorkingShift(staffData.workingShift) : {},
    staffPosition: staffData.staffPosition ? staffData.staffPosition : "",
    totalWorkingTime: staffData.totalWorkingTime ? staffData.totalWorkingTime : 0,
    totalServe: staffData.totalServe ? staffData.totalServe : 0,
  };

  return validatedStaffData;
};

const validateWorkingShift = data => {
  const startTime = data ? data.startTime : 0;
  const endTime = data ? data.endTime : 0;
  const name = data ? data.name : "";
  const uid = data ? data.uid : "";
  const validatedWorkingShift = {
    startTime: startTime,
    endTime: endTime,
    name: name,
    uid: uid,
  };
  return validatedWorkingShift;
};
