import { call, put, select } from "redux-saga/effects";
import { ReservationHistoryService } from "services";
import { ApiConstant } from "const";
import ReservationHistoryAction from "redux/reservation-history.redux";
import { toCamel, toSnake } from "utils";

export function* getBookingHistory(action) {
  try {
    let data = action.data || {};
    const {
      reservationHistoryRedux: { filter },
    } = yield select();
    let params = { ...filter, ...data };
    let response = yield call(ReservationHistoryService.getBookingHistory, toSnake(params));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationHistoryAction.historySuccess({ bookingHistory: toCamel(responseData), filter: params }));
    } else {
      yield put(ReservationHistoryAction.historyFailure(responseData));
    }
  } catch (error) {
    yield put(ReservationHistoryAction.historyFailure(error));
  }
}

export function* batchUpdateStatus(action) {
  try {
    const data = action.data;
    let response = yield call(ReservationHistoryService.batchUpdateStatus, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationHistoryAction.historySuccess({ isSuccess: true }));
      yield put(ReservationHistoryAction.getBookingHistory());
    } else {
      yield put(ReservationHistoryAction.historyFailure(responseData));
    }
  } catch (error) {
    yield put(ReservationHistoryAction.historyFailure(error));
  }
}
