import { ApiConstant, AppConstant } from "const";
import { createApiRegistration } from "api";
import StringFormat from "string-format";
import Cookie from "js-cookie";

export const get = () => {
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  let shopId = null;
  if (appData && appData.length > 0) {
    shopId = JSON.parse(appData).shopId;
    return createApiRegistration().get(StringFormat(ApiConstant.GET_SHOP_INFO, shopId));
  }
};

export const update = data => {
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  let shopId = null;
  if (appData && appData.length > 0) {
    shopId = JSON.parse(appData).shopId;
    return createApiRegistration().put(StringFormat(ApiConstant.UPDATE_SHOP_INFO, shopId), data);
  }
};
