import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { InputAdornment, makeStyles, IconButton, Popover, Box, OutlinedInput } from "@material-ui/core";
import { EventAvailableOutlined, ArrowDropDownOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getDateLang } from "utils/date";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { convertSmallNumber } from "utils";

const InputDate = ({ placeholder, selectedDate, setSelectedDate, available, classes, ...otherProps }) => {
  const defaultClasses = useStyles();
  const { t: getLabel, i18n } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const dateLang = getDateLang(i18n.language);
  const inputRef = useRef(null);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [calendarAnchor, setCalendarAnchor] = useState(null);
  const onConvertDateToText = date => {
    let dayIndex = new Date(date).getDay();
    let dateNum = new Date(date).getDate();
    let monthNum = new Date(date).getMonth();
    let indexOfDate = dayIndex > 0 ? dayIndex - 1 : 6;
    let dateText = getLabel(LangConstant.ARR_DAY_OF_WEEK[indexOfDate]);
    let formattedDateText = `${dateText}, ${convertSmallNumber(dateNum)}/${convertSmallNumber(monthNum + 1)}`;
    return formattedDateText;
  };
  return (
    <>
      <OutlinedInput
        ref={inputRef}
        onClick={() => {
          setCalendarAnchor(inputRef.current);
          setOpenCalendar(true);
        }}
        fullWidth
        type="text"
        value={selectedDate ? onConvertDateToText(selectedDate) : ""}
        placeholder={placeholder || getLabel(LangConstant.P_SELECT_DATE)}
        startAdornment={
          <InputAdornment position="start" className={defaultClasses.firstIcon}>
            <EventAvailableOutlined />
          </InputAdornment>
        }
        endAdornment={
          <IconButton edge="end" className={defaultClasses.secondaryIcon}>
            <ArrowDropDownOutlined color="inherit" />
          </IconButton>
        }
        classes={{
          input: Boolean(available) ? defaultClasses.input : defaultClasses.inputInvalid,
          root: clsx(defaultClasses.inputRoot, classes.inputRoot),
        }}
      />
      <Popover
        open={openCalendar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={calendarAnchor}
        onClose={() => {
          setOpenCalendar(false);
        }}
        classes={{ paper: defaultClasses.calendarContainer }}
      >
        <Box className={"preview-date-picker-root"}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLang}>
            <DatePicker
              format={AppConstant.FM_DATE_DD_MM}
              value={selectedDate}
              disableToolbar={true}
              variant="static"
              className={defaultClasses.root}
              leftArrowButtonProps={{
                classes: { root: defaultClasses.btnArrowLeft },
              }}
              rightArrowButtonProps={{
                classes: { root: defaultClasses.btnArrowRight },
              }}
              onChange={date => {
                setSelectedDate(date);
              }}
              {...otherProps}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <CloseIcon
          id={CALENDAR_CLOSE_BUTTON}
          className={defaultClasses.closeIcon}
          onClick={() => {
            setOpenCalendar(false);
          }}
        />
      </Popover>
    </>
  );
};

InputDate.propTypes = {
  placeholder: PropTypes.string,

  setSelectedDate: PropTypes.func,

  selectedDate: PropTypes.any,
  available: PropTypes.any,

  classes: PropTypes.object,
};

InputDate.defaultProps = {
  classes: {
    inputRoot: {},
  },
};

const CALENDAR_CLOSE_BUTTON = "calendar-close-button";

export default memo(InputDate);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  inputRoot: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: "8px",
    marginBottom: "8px",
  },
  input: {
    padding: "16px 0 16px 8px",
    cursor: "pointer",
    "&::placeholder": {
      color: theme.palette.black,
      opacity: 0.3,
    },
  },
  inputInvalid: {
    padding: "16px 0 16px 8px",
    cursor: "pointer",
    color: "red",
    "&::placeholder": {
      color: theme.palette.black,
      opacity: 0.3,
    },
  },
  firstIcon: {
    minWidth: "50px",
    width: "50px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "50px",
    background: "#ebeced",
    justifyContent: "center",
    borderRight: "solid 0.5px #cecfd2",
    marginRight: 0,
  },
  secondaryIcon: {
    color: theme.palette.grey[400],
    padding: 4,
  },
  calendarContainer: {
    display: "flex",
    backgroundColor: "#ef5845",
    borderRadius: 6,
  },
  btnArrowLeft: {
    padding: 0,
    height: 24,
    width: 24,
    "& svg": {
      fontSize: 24,
    },
    "& > *:first-child": {
      width: 24,
      height: 24,
    },
    justifyContent: "flex-start",
  },
  btnArrowRight: {
    padding: 0,
    height: 24,
    width: 24,
    "& svg": {
      fontSize: 24,
    },
    "& > *:first-child": {
      width: 24,
      height: 24,
    },
    justifyContent: "flex-end",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "3%",
    zIndex: 1,
    top: 13,
    color: theme.palette.white,
    width: 24,
    height: 24,
    "@media only screen and (max-width: 590px)": {
      right: "5%",
    },
  },
}));
