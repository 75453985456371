import React, { memo } from "react";
import PropTypes from "prop-types";
import { CardMedia, Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ImageConstant } from "const";

const ClassificationCheckbox = ({ arrayData, onClick, selectedItem, isService, isFirstClass, ...otherProps }) => {
  const defaultClasses = useStyles();

  const onSelect = item => {
    let itemNotExist = !selectedItem || item.uid !== selectedItem.uid;
    let itemSelect = itemNotExist ? item : null;

    onClick(itemSelect);
  };

  return arrayData.map((item, index) => (
    <FormControlLabel
      key={index}
      className={defaultClasses.checkboxRow}
      control={
        Boolean(isFirstClass ? item.stock : item.item?.stock) || isService ? (
          <Checkbox
            classes={{
              root: defaultClasses.checkboxRoot,
            }}
            onChange={() => onSelect(item)}
            checked={checkSelectedStatus(selectedItem, item)}
            color="primary"
            {...otherProps}
          />
        ) : (
          <Checkbox
            classes={{
              root: defaultClasses.checkboxRoot,
              disabled: defaultClasses.checkboxDisabled,
            }}
            icon={<CardMedia component="img" src={ImageConstant.DisabledCheckbox} />}
            disabled={true}
            {...otherProps}
          />
        )
      }
      label={
        <Typography
          className={clsx(
            "medium-md-txt no-user-select",
            !(Boolean(isFirstClass ? item.stock : item.item?.stock) || isService) && defaultClasses.labelDisabled,
          )}
        >
          {item.name}
        </Typography>
      }
    />
  ));
};

const checkSelectedStatus = (selected, item) => {
  if (!(item && selected)) return false;
  return selected.uid === item.uid || selected.uid === item.item?.uid;
};

ClassificationCheckbox.propTypes = {
  arrayData: PropTypes.array.isRequired,
  isService: PropTypes.bool,
  isFirstClass: PropTypes.bool,
  onClick: PropTypes.func,
};
ClassificationCheckbox.defaultProps = {
  arrayData: [],
  isFirstClass: false,
  isService: false,
  onClick: () => {},
};

export default memo(ClassificationCheckbox);

const useStyles = makeStyles(theme => ({
  checkboxRow: {
    display: "flex",
    alignItems: "center",
    margin: 0,
  },

  checkboxRoot: {
    padding: 0,
    borderRadius: 2,
    marginRight: 6,
  },

  checkboxDisabled: {
    marginRight: 8,
    margin: 2,
  },

  labelDisabled: {
    color: theme.palette.grey[400],
  },
}));
