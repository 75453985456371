import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { CategoryItemListHeader, CategoryItemListBody } from "components/mn-master-product";
import { SearchBar } from "components";
import { Close, ArrowBack } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, convertTimeItemDescription, formatCurrency, removeVietnameseTones } from "utils";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ServiceItemAction from "redux/service-item.redux";
import ItemDetailsContentAction from "redux/item-details-content.redux";
import DialogViewEditMainService from "../main-service/DialogViewEditMainService";

const DialogAddFromMain = ({ isOpen, onClose, onBack }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const getListServiceItemSuccess = useSelector(
    state => state.serviceItemRedux.isGetListServiceItemSuccess,
    shallowEqual,
  );
  const postItemDetailsContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isPostItemDetailsContentSuccess,
    shallowEqual,
  );

  const dataListServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem, shallowEqual);

  const [listServiceItem, setListServiceItem] = useState([]);
  const [saveListServiceItem, setListSaveServiceItem] = useState([]);
  const [isOpenService, setIsOpenService] = useState({
    viewService: false,
    targetUid: null,
  });

  const onSearch = name => {
    let newListSearch = saveListServiceItem.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListServiceItem(newListSearch);
  };

  const onAddServiceMainToAccompaniedService = uid => {
    let serviceItemDetails = saveListServiceItem.filter(itemSearch => itemSearch.uid === uid)[0];
    dispatch(
      ItemDetailsContentAction.postItemDetailsContent({
        type: AppConstant.TYPE_CATEGORY.service,
        name: serviceItemDetails.name,
        image: serviceItemDetails.image,
        description: serviceItemDetails.description,
        itemUid: uid,
        detailsUidList: [],
      }),
    );
  };

  const onWatchServiceItem = () => {
    // TODO pj9 later can be used
    // setIsOpenService({ ...isOpenService, viewService: true, targetUid: uid });
  };

  const onCloseViewService = () => {
    setIsOpenService({
      viewService: false,
      targetUid: null,
    });
  };

  useEffect(() => {
    if (!dataListServiceItem) {
      dispatch(ServiceItemAction.getListServiceItem({}));
    } else {
      if (dataListServiceItem && dataListServiceItem.data) {
        setListServiceItem(dataListServiceItem.data);
        setListSaveServiceItem(dataListServiceItem.data);
      }
    }
  }, []);

  useEffect(() => {
    if (dataListServiceItem?.data && getListServiceItemSuccess) {
      dispatch(ServiceItemAction.resetServiceItem());
      setListServiceItem(dataListServiceItem.data);
      setListSaveServiceItem(dataListServiceItem.data);
    }
  }, [getListServiceItemSuccess]);

  useEffect(() => {
    if (postItemDetailsContentSuccess) {
      dispatch(ServiceItemAction.resetServiceItem());
      dispatch(ServiceItemAction.getListServiceItem({}));
    }
  }, [postItemDetailsContentSuccess]);

  return (
    <>
      <Dialog open={isOpen} fullScreen>
        <Box className={classes.rootHeader}>
          <Box className={classes.boxIconBack}>
            <IconButton onClick={onBack} className={classes.iconButton}>
              <ArrowBack className={classes.icon} />
            </IconButton>
          </Box>
          <Typography className={classes.nameHeaderTypo}>
            {getLabel(LangConstant.TXT_ADD_ACCOMPANIED_FROM_MAIN)}
          </Typography>
          <Box className={classes.boxIconClose}>
            <IconButton onClick={onClose} className={classes.iconButton}>
              <Close className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        <Box textAlign="center">
          <SearchBar
            className={classes.searchBar}
            onKeyUp={onSearch}
            placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
          />
          <Box className={classes.rootBody}>
            <CategoryItemListHeader isProduct={false} />
            {listServiceItem.map(dataServiceItem => (
              <CategoryItemListBody
                key={dataServiceItem.uid}
                uid={dataServiceItem.uid}
                onClickAdd={onAddServiceMainToAccompaniedService}
                categoryName={dataServiceItem.name}
                isAdd={true}
                timeUsed={
                  dataServiceItem.usingTime && dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.times
                    ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                    : ""
                }
                image={dataServiceItem.image}
                price={
                  dataServiceItem.price
                    ? `${formatCurrency(`${dataServiceItem.price}`)} ${getLabel(LangConstant.TXT_CURRENCY)}/${
                        dataServiceItem.usingTime &&
                        dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.time
                          ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                          : getLabel(getCommonKey(LangConstant.P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT))
                      }`
                    : ""
                }
                disabled={Boolean(dataServiceItem.itemDetailsContentUid)}
                onClickWatch={onWatchServiceItem}
              />
            ))}
          </Box>
        </Box>
      </Dialog>
      {isOpenService.viewService && (
        <DialogViewEditMainService
          data={listServiceItem ? listServiceItem.find(data => data.uid === isOpenService.targetUid) : {}}
          isOpen={isOpenService.viewService}
          isEdit={false}
          onClose={onCloseViewService}
        />
      )}
    </>
  );
};
export default memo(DialogAddFromMain);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: 400,
    height: 30,
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  rootBody: {
    marginTop: 48,
    marginBottom: 48,
    width: "80%",
    marginLeft: "10%",
    [theme.breakpoints.down(600)]: {
      width: "90%",
      marginLeft: "5%",
      overflowX: "auto",
    },
  },
}));
