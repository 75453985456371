import { AppConstant } from "const";
import { createReducer, createActions } from "reduxsauce";
import { getTime } from "date-fns";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getReservationDay: ["data"],
  getReservationDaySuccess: ["reservationDay"],
  getReservationDayFailure: ["data"],

  getReservationWeek: ["data"],
  getReservationWeekSuccess: ["reservationWeek"],
  getReservationWeekFailure: ["data"],

  getReservationMonth: ["data"],
  getReservationMonthSuccess: ["reservationMonth"],
  getReservationMonthFailure: ["data"],

  postReservation: ["data"],
  postReservationSuccess: ["data"],
  postReservationFailure: ["data"],

  getReservationSettingOption: [],
  getReservationSettingOptionSuccess: ["reservationSetting"],
  getReservationSettingOptionFailure: ["data"],

  putReservationStatus: ["data"],
  putReservationStatusSuccess: ["reservationStatus"],
  putReservationStatusFailure: ["data"],

  putReservationDetails: ["data"],
  putReservationDetailsSuccess: ["data"],
  putReservationDetailsFailure: ["data"],

  getReservationPeriodic: ["data"],
  getReservationPeriodicSuccess: ["reservationPeriodic"],
  getReservationPeriodicFailure: ["data"],

  getReservation: ["data"],
  getReservationSuccess: ["reservation"],
  getReservationFailure: ["data"],

  postReservationExtend: ["data"],
  postReservationExtendSuccess: ["data"],
  postReservationExtendFailure: ["data"],

  postReservationListStatus: ["data"],
  postReservationListStatusSuccess: ["reservationListStatus"],
  postReservationListStatusFailure: ["data"],

  getAvailableStatus: ["data"],
  getAvailableStatusSuccess: ["data"],
  getAvailableStatusFailure: ["data"],

  getDisplaySetting: ["data"],
  getDisplaySettingSuccess: ["data"],
  getDisplaySettingFailure: [],

  updateDisplaySetting: ["data"],
  updateDisplaySettingSuccess: ["data"],
  updateDisplaySettingFailure: [],

  createRemoteOrder: ["data"],
  putRemoteOrderDetails: ["data"],

  resetPostSetting: [],

  resetGetAvailableStatus: [],

  resetBookingDetail: [],

  resetPutReservation: [],

  resetReservation: [],

  resetDetailConditions: [],

  resetPutReservationStatus: [],

  getReservationType: ["data"],
  reservationSuccess: ["data"],
  reservationFailure: ["data"],
});

export const ReservationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,

  reservationDay: {},
  reservationWeek: {},
  reservationMonth: {},
  reservationSetting: {},
  reservationStatus: {},
  reservationPeriodic: {},
  reservation: {},
  reservationExtend: [],
  isDetailSuccess: false,

  updateReservationSuccess: false,
  getAvailableStatusSuccess: false,
  availableStatus: {},

  displaySetting: [],
  isUpdateSettingSuccess: false,
  dataOrder: [],
  isPutReservationStatus: false,
  isPostReservationOrderSuccess: false,
  filter: {
    total: 0,
    totalPage: 1,
    size: AppConstant.PAGE_SIZE_DEFAULT,
    paging: 1,
    page: 1,
  },

  advancedFilter: {
    start: Math.floor(getTime(new Date().setHours(0, 0, 0)) / 1000),
    end: Math.floor(getTime(new Date().setHours(23, 59, 59)) / 1000),
  },

  isPostSuccessReservationListStatus: false,
};

/* ------------- Reducers ------------- */

export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    isFetching: false,
    ...data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const putDetails = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  updateReservationSuccess: false,
});

export const getReservationDay = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetReservation: true,
  updateReservationSuccess: false,
});

export const getReservationDaySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingGetReservation: false,
    isSuccess: true,
    reservationDay: action.reservationDay,
  };
};

export const getReservationDayFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetReservation: false,
  error: action.error,
});

export const getReservationWeek = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetReservation: true,
});

export const getReservationWeekSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingGetReservation: false,
    isSuccess: true,
    reservationWeek: action.reservationWeek,
  };
};

export const getReservationWeekFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetReservation: false,
  error: action.error,
});

export const getReservationMonth = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetReservation: true,
});

export const getReservationMonthSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingGetReservation: false,
    isSuccess: true,
    reservationMonth: action.reservationMonth,
  };
};

export const getReservationMonthFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetReservation: false,
  error: action.error,
});

export const postReservation = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isPostReservationOrderSuccess: false,
});

export const postReservationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    isPostReservationOrderSuccess: true,
    ...action.data,
  };
};

export const postReservationFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isPostReservationOrderSuccess: false,
  error: action.error,
});

export const getReservationSettingOption = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getReservationSettingOptionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    reservationSetting: action.reservationSetting,
  };
};

export const getReservationSettingOptionFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const putReservationStatus = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isPutReservationStatus: false,
});

export const putReservationStatusSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    isPutReservationStatus: true,
    reservationStatus: action.reservationStatus,
  };
};

export const putReservationStatusFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isPutReservationStatus: false,
  error: action.error,
});

export const putReservationDetails = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  updateReservationSuccess: false,
});

export const putReservationDetailsSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetching: false,
    updateReservationSuccess: true,
  };
};

export const putReservationDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  updateReservationSuccess: false,
  error: action.error,
});

export const getReservationPeriodic = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getReservationPeriodicSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    reservationPeriodic: action.reservationPeriodic,
  };
};

export const getReservationPeriodicFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const getReservation = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getReservationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    reservation: action.reservation,
  };
};

export const getReservationFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const postReservationExtend = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const postReservationExtendSuccess = (state = INITIAL_STATE, action) => {
  let newPeriodic = [];
  if (Array.isArray(action?.data?.periodic)) {
    newPeriodic = action.data?.periodic;
  }
  return {
    ...state,
    isFetching: false,
    isDetailSuccess: true,
    reservationExtend: newPeriodic,
  };
};

export const postReservationExtendFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

export const postReservationListStatus = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isPostSuccessReservationListStatus: false,
});

export const postReservationListStatusSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    isPostSuccessReservationListStatus: true,
  };
};

export const postReservationListStatusFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isPostSuccessReservationListStatus: false,
  error: action.error,
});

export const getAvailableStatus = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  getAvailableStatusSuccess: false,
});

export const getAvailableStatusSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  availableStatus: action.data,
  getAvailableStatusSuccess: true,
});

export const getAvailableStatusFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
  getAvailableStatusSuccess: false,
});

export const getDisplaySetting = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isSuccess: false,
});

export const getDisplaySettingSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: true,
  displaySetting: action.data,
});

export const getDisplaySettingFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

export const updateDisplaySetting = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isUpdateSettingSuccess: false,
});

export const updateDisplaySettingSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isUpdateSettingSuccess: true,
  displaySetting: action.data,
});

export const updateDisplaySettingFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isUpdateSettingSuccess: false,
  error: action.error,
});

export const resetPostSetting = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateSettingSuccess: false,
});

export const resetGetAvailableStatus = (state = INITIAL_STATE) => ({
  ...state,
  getAvailableStatusSuccess: false,
  availableStatus: {},
});

export const resetBookingDetail = state => ({
  ...state,
  reservation: {},
});

export const resetPutReservation = state => ({
  ...state,
  updateReservationSuccess: false,
  isPostReservationOrderSuccess: false,
});

export const reset = () => ({
  ...INITIAL_STATE,
});

export const resetDetailConditions = state => ({
  ...state,
  isDetailSuccess: false,
});

export const resetPutReservationStatus = state => ({
  ...state,
  isPutReservationStatus: false,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_RESERVATION_DAY]: getReservationDay,
  [Types.GET_RESERVATION_DAY_SUCCESS]: getReservationDaySuccess,
  [Types.GET_RESERVATION_DAY_FAILURE]: getReservationDayFailure,

  [Types.GET_RESERVATION_WEEK]: getReservationWeek,
  [Types.GET_RESERVATION_WEEK_SUCCESS]: getReservationWeekSuccess,
  [Types.GET_RESERVATION_WEEK_FAILURE]: getReservationWeekFailure,

  [Types.GET_RESERVATION_MONTH]: getReservationMonth,
  [Types.GET_RESERVATION_MONTH_SUCCESS]: getReservationMonthSuccess,
  [Types.GET_RESERVATION_MONTH_FAILURE]: getReservationMonthFailure,

  [Types.POST_RESERVATION]: postReservation,
  [Types.POST_RESERVATION_SUCCESS]: postReservationSuccess,
  [Types.POST_RESERVATION_FAILURE]: postReservationFailure,

  [Types.GET_RESERVATION_SETTING_OPTION]: getReservationSettingOption,
  [Types.GET_RESERVATION_SETTING_OPTION_SUCCESS]: getReservationSettingOptionSuccess,
  [Types.GET_RESERVATION_SETTING_OPTION_FAILURE]: getReservationSettingOptionFailure,

  [Types.PUT_RESERVATION_STATUS]: putReservationStatus,
  [Types.PUT_RESERVATION_STATUS_SUCCESS]: putReservationStatusSuccess,
  [Types.PUT_RESERVATION_STATUS_FAILURE]: putReservationStatusFailure,

  [Types.PUT_RESERVATION_DETAILS]: putReservationDetails,
  [Types.PUT_RESERVATION_DETAILS_SUCCESS]: putReservationDetailsSuccess,
  [Types.PUT_RESERVATION_DETAILS_FAILURE]: putReservationDetailsFailure,

  [Types.GET_RESERVATION_PERIODIC]: getReservationPeriodic,
  [Types.GET_RESERVATION_PERIODIC_SUCCESS]: getReservationPeriodicSuccess,
  [Types.GET_RESERVATION_PERIODIC_FAILURE]: getReservationPeriodicFailure,

  [Types.GET_RESERVATION]: getReservationPeriodic,
  [Types.GET_RESERVATION_SUCCESS]: getReservationSuccess,
  [Types.GET_RESERVATION_FAILURE]: getReservationFailure,

  [Types.POST_RESERVATION_EXTEND]: postReservationExtend,
  [Types.POST_RESERVATION_EXTEND_SUCCESS]: postReservationExtendSuccess,
  [Types.POST_RESERVATION_EXTEND_FAILURE]: postReservationExtendFailure,

  [Types.POST_RESERVATION_LIST_STATUS]: postReservationListStatus,
  [Types.POST_RESERVATION_LIST_STATUS_SUCCESS]: postReservationListStatusSuccess,
  [Types.POST_RESERVATION_LIST_STATUS_FAILURE]: postReservationListStatusFailure,

  [Types.GET_AVAILABLE_STATUS]: getAvailableStatus,
  [Types.GET_AVAILABLE_STATUS_SUCCESS]: getAvailableStatusSuccess,
  [Types.GET_AVAILABLE_STATUS_FAILURE]: getAvailableStatusFailure,

  [Types.GET_DISPLAY_SETTING]: getDisplaySetting,
  [Types.GET_DISPLAY_SETTING_SUCCESS]: getDisplaySettingSuccess,
  [Types.GET_DISPLAY_SETTING_FAILURE]: getDisplaySettingFailure,

  [Types.UPDATE_DISPLAY_SETTING]: updateDisplaySetting,
  [Types.UPDATE_DISPLAY_SETTING_SUCCESS]: updateDisplaySettingSuccess,
  [Types.UPDATE_DISPLAY_SETTING_FAILURE]: updateDisplaySettingFailure,

  [Types.CREATE_REMOTE_ORDER]: request,
  [Types.PUT_REMOTE_ORDER_DETAILS]: putDetails,

  [Types.RESET_POST_SETTING]: resetPostSetting,

  [Types.RESET_GET_AVAILABLE_STATUS]: resetGetAvailableStatus,

  [Types.RESET_BOOKING_DETAIL]: resetBookingDetail,

  [Types.RESET_PUT_RESERVATION]: resetPutReservation,

  [Types.RESET_RESERVATION]: reset,

  [Types.RESET_DETAIL_CONDITIONS]: resetDetailConditions,

  [Types.RESET_PUT_RESERVATION_STATUS]: resetPutReservationStatus,

  [Types.GET_RESERVATION_TYPE]: request,
  [Types.RESERVATION_SUCCESS]: success,
  [Types.RESERVATION_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
