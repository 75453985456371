import React, { memo, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Button,
  Box,
} from "@material-ui/core";
import { Close, Comment, Receipt } from "@material-ui/icons";
import Cookie from "js-cookie";
import { parse, format as DateFormat } from "date-fns";
import { LangConstant, AppConstant } from "const";
import DialogContainer from "../DialogContainer";
import { getCommonKey, jumpToTargetBooking, getFeatureConditions } from "utils";
import { useTranslation } from "react-i18next";
import { TabReservation, TabConsumer, TabPeriodic, TabExtend, TabPreOrder } from "./Tab";
import { format } from "react-string-format";
import ReservationAction from "redux/reservation.redux";
import BillAction from "redux/bill.redux";
import { checkExpiredBookingDate, getTimestamp, fromDateAndHourToDateObject, convertTimeStampToDate } from "utils/date";
import WaitingConsumerAction from "redux/waiting-consumer.redux";
import AddNoteDialog from "pages/BookingHistory/components/AddNoteDialog";
import BillDetailDialog from "pages/BillManagement/components/BillDetailDialog";
import { ViewModeDialogActions, EditModeDialogActions } from "pages/BookingHistory/components";

const DialogBooking = ({ anchorEl, data, onClose, onSubmit, selectedDate, selectedId }) => {
  const classes = useStyles();
  const role = Cookie.get(AppConstant.ROLE);
  const currentRef = useRef(null);
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const dispatch = useDispatch();
  const reservation = useSelector(state => state.reservationRedux.reservation);
  const periodicData = useSelector(state => state.reservationRedux.reservationPeriodic);
  const [isDetailSuccess, copyReservation] = useSelector(
    ({ reservationRedux }) => [reservationRedux.isDetailSuccess, reservationRedux.copyReservation],
    shallowEqual,
  );
  const dataReservation = useSelector(state => state.reservationRedux);
  const isPostSuccessWaitingConsumer = useSelector(state => state.waitingConsumerRedux.isPostSuccess, shallowEqual);
  const billDetailReservation = useSelector(state => state.billRedux.billDetailReservation, shallowEqual);
  const [isUpdateSuccess, isSuccess] = useSelector(({ billRedux }) => [billRedux.isUpdateSuccess, billRedux.isSuccess]);

  const { reservationSetting } = dataReservation;

  const [valueTabs, setValueTabs] = useState(AppConstant.TAB_BOOKING_DETAIL.reservation);
  const [detail, setDetail] = useState(data);
  const [isEditing, setIsEditing] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isExtending, setIsExtending] = useState(false);
  const [dataPeriodic, setDataPeriodic] = useState({});
  const [dataExtend, setDataExtend] = useState([]);
  const [isContainPast, setIsContainPast] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isAddingBill, setIsAddingBill] = useState(false);
  const [internalNote, setInternalNote] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const [isOpenPeriodic, setIsOpenPeriodic] = useState(false);
  const [dialogAnchor, setDialogAnchor] = useState(null);

  let isSettingTotal = reservationSetting && reservationSetting[4] && reservationSetting[4].publish === 1;

  let { isPremium, isOnlyBooking, isOnlyOrdering } = getFeatureConditions(reservationSetting);

  const onOpenAddingNoteModal = () => {
    setDialogAnchor(currentRef.current);
    setIsAddingNote(true);
  };

  const onOpenAddingBill = () => {
    setIsAddingBill(true);
  };

  const onCloseAddingNoteModal = () => {
    setIsAddingNote(false);
  };

  const onChangeValueTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const onOpenEditing = () => {
    if (checkExpiredBookingDate(convertTimeStampToDate(data.startTime))) {
      setIsEditing(true);
    }
  };

  const onOpenCopying = () => {
    setIsCopying(true);
    if (valueTabs === AppConstant.TAB_BOOKING_DETAIL.periodic) {
      setValueTabs(AppConstant.TAB_BOOKING_DETAIL.reservation);
    }
    if (detail.startTime < Date.now() / 1000) {
      let currentTime = Date.now() / 1000;
      let newTime = currentTime + 15 * 60;
      let updateDate = DateFormat(new Date(newTime * 1000), AppConstant.FM_DD_MM_YYYY);
      let updateTime = DateFormat(new Date(newTime * 1000), AppConstant.FM_HH_MM);
      setDetail({ ...detail, date: updateDate, time: updateTime });
    }
  };

  const onOpenExtending = () => {
    setIsExtending(true);
    setDataExtend([]);
  };

  const onCancel = () => {
    setIsEdited(false);
    isOpenPeriodic ? setDetail(reservation) : setDetail(data);
    if (valueTabs === AppConstant.TAB_BOOKING_DETAIL.periodic) {
      dispatch(ReservationAction.getReservationPeriodic({ uid: data.uid }));
    }
    setIsEditing(false);
    setIsCopying(false);
    setIsExtending(false);
  };

  const onSave = () => {
    let startTime = getTimestamp(
      fromDateAndHourToDateObject(parse(detail.date, AppConstant.FM_DD_MM_YYYY, new Date()), detail.time),
    );
    if (startTime) {
      if (isEditing) {
        if (isSettingTotal) {
          onSubmit({
            ...detail,
            startTime: startTime,
            man: detail.man > 0 ? detail.man : 1,
            child: detail.child || 0,
          });
        } else {
          onSubmit({
            ...detail,
            startTime: startTime,
            totalConsumer: detail.totalConsumer > 0 ? detail.totalConsumer : 1,
          });
        }
      } else if (isCopying) {
        dispatch(
          ReservationAction.postReservation({
            newReservation: {
              ...detail,
              startTime: startTime,
              copyReservationUid: data.uid,
            },
            isSettingTotal: isSettingTotal,
          }),
        );
      } else if (isExtending) {
        dispatch(ReservationAction.postReservationExtend({ periodic: dataExtend, reservation_uid: detail.uid }));
      }
    }
  };

  const onSendRequestPayment = () => {
    onSubmit({
      ...detail,
      status: AppConstant.STT_RESERVATION.waitingPayment,
    });
  };

  const onAddInternalNote = () => {
    if (internalNote) {
      onSubmit({ ...detail, internalNote: { content: internalNote } });
    }
  };

  const onSubmitFormDataPreOrder = (data, price) => {
    setIsEdited(true);
    setDetail({ ...detail, orderDetailsList: data, depositTotal: price });
  };

  const onChangePriceDeposit = price => {
    setIsEdited(true);
    setDetail({ ...detail, depositTotal: price });
  };

  const onAddBillDetail = detail => {
    dispatch(
      BillAction.createBill({
        ...detail,
        reservationUid: data.uid,
      }),
    );
  };

  useEffect(() => {
    dispatch(ReservationAction.getReservationPeriodic({ uid: data.uid }));
    dispatch(BillAction.getReservationBillDetail({ uid: data.uid }));
  }, []);

  useEffect(() => {
    if (periodicData && periodicData.periodic) {
      setDataPeriodic(periodicData);
    } else {
      setDataPeriodic({
        periodic: [
          {
            code: detail.code,
            status: detail.status,
            startTime: detail.startTime,
            available: AppConstant.STT_AVAILABLE.available,
          },
        ],
      });
    }
  }, [periodicData]);

  useEffect(() => {
    if (reservation && reservation.name) {
      setDetail(reservation);
    }
  }, [reservation]);

  useEffect(() => {
    if (isDetailSuccess) {
      dispatch(ReservationAction.resetDetailConditions());
      if (isCopying && copyReservation?.uid) {
        onClose();
        jumpToTargetBooking(history, copyReservation.startTime, copyReservation.uid);
      } else if (isExtending) {
        onCancel();
        setValueTabs(AppConstant.TAB_BOOKING_DETAIL.periodic);
      }
    }
  }, [isDetailSuccess, copyReservation]);

  useEffect(() => {
    if (isPostSuccessWaitingConsumer && detail.status < AppConstant.STT_RESERVATION.seated) {
      setDetail({ ...detail, status: AppConstant.STT_RESERVATION.seated });
      dispatch(WaitingConsumerAction.resetWaitingConsumer());
    }
  }, [isPostSuccessWaitingConsumer]);

  useEffect(() => {
    if (isUpdateSuccess || isSuccess) {
      setIsAddingBill(false);
      dispatch(BillAction.getReservationBillDetail({ uid: data.uid }));
      dispatch(
        BillAction.billSuccess({
          isUpdateSuccess: false,
        }),
      );
    }
  }, [isUpdateSuccess, isSuccess]);

  let isSelectedOrderTab = valueTabs === AppConstant.TAB_BOOKING_DETAIL.preOrder;
  let isDisplayTabPreOrder = Boolean((isOnlyBooking && isOnlyOrdering) || isPremium);
  let isViewMode = !(isEditing || isCopying || isExtending);
  let isDisableSaveButton = (isContainPast || !isEdited) && !isCopying && !isExtending;
  let isIncludeInternal = detail.internalNote && detail.internalNote.content;
  let isHaveBillDetail = billDetailReservation && Boolean(billDetailReservation.uid);

  return (
    <DialogContainer selectedId={selectedId} anchorEl={anchorEl} onBackdropClick={onClose}>
      <Card className={classes.rootCardDialog}>
        {!isExtending ? (
          <AppBar className={classes.rootAppBar}>
            <CardHeader
              title={
                isCopying
                  ? getLabel(getCommonKey(LangConstant.TXT_COPY_RESERVATION))
                  : getLabel(getCommonKey(LangConstant.TXT_DETAIL_INFO))
              }
              classes={{
                root: classes.rootHeader,
                action: classes.actionHeader,
                content: classes.contentHeader,
                title: classes.titleHeader,
              }}
              action={
                <IconButton className={classes.iconClose} onClick={onClose}>
                  <Close />
                </IconButton>
              }
            />
            <Tabs
              classes={{
                root: classes.rootTabs,
                flexContainer: classes.flexContainer,
                indicator: classes.indicatorTabs,
              }}
              onChange={onChangeValueTabs}
              value={valueTabs}
            >
              <Tab label={getLabel(getCommonKey(LangConstant.TXT_RESERVATION))} className={classes.rootReservation} />
              {isDisplayTabPreOrder && (
                <Tab label={getLabel(LangConstant.TXT_BEFORE_BOOKING)} className={classes.rootReservation} />
              )}
              <Tab label={getLabel(getCommonKey(LangConstant.TXT_SHOP_CONSUMER))} className={classes.rootConsumer} />
              {detail.periodicCode && !isCopying && (
                <Tab label={getLabel(getCommonKey(LangConstant.TXT_PERIODIC))} className={classes.rootPeriodic} />
              )}
            </Tabs>
          </AppBar>
        ) : (
          <AppBar className={classes.rootAppBarExtend}>
            <CardHeader
              title={format(getLabel(getCommonKey(LangConstant.FM_EXTEND_RESERVATION)), detail.code)}
              classes={{
                root: classes.rootHeader,
                action: classes.actionHeader,
                content: classes.contentHeader,
                title: classes.titleHeader,
              }}
              action={
                <IconButton className={classes.iconClose} onClick={onClose}>
                  <Close />
                </IconButton>
              }
            />
          </AppBar>
        )}
        <CardContent
          className={
            !(isExtending || valueTabs === AppConstant.TAB_BOOKING_DETAIL.periodic)
              ? classes.rootContent
              : classes.rootExtend
          }
        >
          {!isExtending ? (
            <>
              <TabReservation
                value={valueTabs}
                index={AppConstant.TAB_BOOKING_DETAIL.reservation}
                detail={detail}
                originStatus={data.status}
                setDetail={setDetail}
                isEditing={isEditing}
                isCopying={isCopying}
                setIsEdited={setIsEdited}
              />
              {isSelectedOrderTab && isDisplayTabPreOrder && (
                <TabPreOrder
                  isEditing={isEditing || isCopying}
                  detail={detail}
                  onSendRequestPayment={onSendRequestPayment}
                  onSubmitFormData={onSubmitFormDataPreOrder}
                  onChangePrice={onChangePriceDeposit}
                />
              )}
              <TabConsumer
                value={valueTabs}
                index={AppConstant.TAB_BOOKING_DETAIL.shopConsumer}
                detail={detail}
                setDetail={setDetail}
                isEditing={isEditing && !detail.userId}
                isCopying={isCopying}
                setIsEdited={setIsEdited}
              />
              {detail.periodicCode && !isCopying && (
                <TabPeriodic
                  selectedDate={selectedDate}
                  value={valueTabs}
                  setValueTabs={setValueTabs}
                  index={AppConstant.TAB_BOOKING_DETAIL.periodic}
                  isEditing={isEditing}
                  dataParent={dataPeriodic}
                  setIsEdited={setIsEdited}
                  setIsOpen={setIsOpenPeriodic}
                />
              )}
            </>
          ) : (
            <TabExtend
              detail={detail}
              dataPeriodic={dataPeriodic.periodic ? dataPeriodic.periodic : []}
              lastCode={dataPeriodic.periodic ? dataPeriodic.periodic.length : 2}
              dataExtend={dataExtend}
              isContainPast={isContainPast}
              setIsContainPast={setIsContainPast}
              setDataExtend={setDataExtend}
            />
          )}

          {role !== AppConstant.SHOP_STAFF && (
            <Box className={classes.formActionContainer}>
              <Box className={classes.internalNoteAndBillButton}>
                {!isIncludeInternal && (
                  <Button className={classes.addInternalNoteBtn} onClick={onOpenAddingNoteModal} ref={currentRef}>
                    <Comment className={classes.addNoteIcon} />
                    {getLabel(LangConstant.TXT_ADD_INTERNAL_NOTE)}
                  </Button>
                )}
                <Button className={classes.addInternalNoteBtn} onClick={onOpenAddingBill}>
                  <Receipt className={classes.addNoteIcon} />
                  {getLabel(LangConstant.TXT_EXPORT_BILL)}
                </Button>
              </Box>
              {isViewMode ? (
                <ViewModeDialogActions
                  onOpenCopying={onOpenCopying}
                  onOpenEditing={onOpenEditing}
                  onOpenExtending={onOpenExtending}
                  setIsEdited={setIsEdited}
                />
              ) : (
                <Box className={!isExtending ? classes.rootFooter : classes.rootFooterExtending}>
                  <EditModeDialogActions
                    isDisableSaveButton={isDisableSaveButton}
                    onCancel={onCancel}
                    onSave={onSave}
                  />
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
      {role !== AppConstant.SHOP_STAFF && (
        <AddNoteDialog
          dialogAnchor={dialogAnchor}
          isAddingNote={isAddingNote}
          onAddInternalNote={onAddInternalNote}
          onCloseAddingNoteModal={onCloseAddingNoteModal}
          setInternalNote={setInternalNote}
        />
      )}

      {isAddingBill && (
        <BillDetailDialog
          isEditing={isHaveBillDetail}
          onClose={() => setIsAddingBill(false)}
          onSubmitAddBill={onAddBillDetail}
          data={isHaveBillDetail && billDetailReservation}
        />
      )}
    </DialogContainer>
  );
};

const useStyles = makeStyles(theme => ({
  rootCardDialog: {
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  rootAppBar: {
    position: "relative",
    background: "#65b39d",
    [theme.breakpoints.up("sm")]: {
      minWidth: 531,
      height: 93,
    },
  },
  rootAppBarExtend: {
    position: "relative",
    background: "#65b39d",
    [theme.breakpoints.up("sm")]: {
      minWidth: 531,
      height: 60,
    },
  },
  rootHeader: {
    position: "relative",
    padding: "15px 24px",
    color: theme.palette.white,
    height: 54,
    marginBottom: 1,
  },
  rootTabs: {
    minHeight: 38,
    height: 38,
  },
  contentHeader: {
    flex: "none",
  },
  actionHeader: {
    marginTop: 0,
    marginLeft: "auto",
    marginRight: 0,
  },
  titleHeader: {
    ...theme.typography.subtitle2,
    color: theme.palette.white,
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down(375)]: {
      fontSize: 16,
    },
  },
  iconClose: {
    color: theme.palette.white,
    padding: 0,
  },
  flexContainer: {
    height: 38,
    minHeight: 38,
  },
  rootContent: {
    width: "100%",
    minHeight: "max-content",
    padding: "12px 24px",
    flexGrow: 1,
    "&>*": {
      color: theme.palette.grey[600],
    },
    [theme.breakpoints.down(375)]: {
      maxHeight: "none",
      padding: "30px 20px 20px",
    },
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  rootExtend: {
    width: "100%",
    minHeight: "max-content",
    padding: 12,
    flexGrow: 1,
    "&>*": {
      color: theme.palette.grey[600],
    },
    [theme.breakpoints.down(375)]: {
      maxHeight: "none",
      width: 531,
      height: 500,
      overflowX: "scroll",
    },
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  indicatorTabs: {
    minWidth: 83,
    borderBottom: "2px solid #feba40",
  },
  rootReservation: {
    minWidth: 85,
    height: 38,
    minHeight: 38,
    textTransform: "none",
    fontWeight: "inherit",
    fontSize: 13,
    opacity: 1,
  },
  rootConsumer: {
    minWidth: 110,
    height: 38,
    minHeight: 38,
    textTransform: "none",
    fontWeight: "inherit",
    fontSize: 13,
    opacity: 1,
  },
  rootPeriodic: {
    minWidth: 83,
    height: 38,
    minHeight: 38,
    textTransform: "none",
    fontWeight: "inherit",
    fontSize: 13,
    opacity: 1,
  },
  rootFooter: {
    textAlign: "right",
    width: "100%",
    padding: 0,
    [theme.breakpoints.down(450)]: {
      position: "fixed",
      bottom: 16,
      padding: "0px 12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  rootFooterExtending: {
    right: 10,
    marginTop: 20,
    textAlign: "right",
    width: "100%",
    padding: "0px 24px",
    [theme.breakpoints.down(375)]: {
      position: "fixed",
      bottom: 10,
      padding: "0px 12px",
    },
  },

  addInternalNoteBtn: {
    fontSize: 13,
    height: "fit-content",
    textTransform: "none",
    minWidth: "max-content",
    display: "flex",
    alignItems: "center",
    color: "#447aff",
    paddingLeft: 0,
    fontWeight: "normal",
  },
  formActionContainer: {
    marginTop: 27,
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  },
  addNoteIcon: {
    fontSize: 16,
    marginRight: 8,
  },
}));

export default memo(DialogBooking);
