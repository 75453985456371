import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey, formatCurrency } from "utils";
import PaymentTableRow from "./PaymentTableRow";

const TablePaymentHistory = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  const paymentDetails = useSelector(({ paymentRedux }) => paymentRedux.paymentDetails, shallowEqual);

  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (paymentDetails) {
      setTotalAmount(paymentDetails.totalPrice);
    }
  }, [paymentDetails]);

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.rootContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {ARRAY_HEADER.map((item, index) => (
                <TableCell key={index} align={item.align} className={classes.headerCell}>
                  {getLabel(item.name)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentDetails?.packageDetails &&
              paymentDetails.packageDetails.map((item, index) => <PaymentTableRow key={index} data={item} />)}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.bottomParent}>
        <Box className={classes.boxTotalAmount}>
          <Typography className={clsx("semiBold-lg-txt", classes.totalText)}>
            {getLabel(LangConstant.TXT_TB_TOTAL)}
          </Typography>
          <Typography className={clsx("semiBold-lg-txt", classes.totalAmountText)}>
            {StringFormat(
              getLabel(LangConstant.FM_CURRENCY),
              formatCurrency(totalAmount.toString()),
              getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ARRAY_HEADER = [
  {
    name: LangConstant.TXT_TB_ITEMS,
    align: "left",
  },
  {
    name: LangConstant.TXT_TB_KIND,
    align: "left",
  },
  {
    name: LangConstant.TXT_TB_QTY,
    align: "left",
  },
  {
    name: LangConstant.TXT_TB_PERIOD,
    align: "left",
  },
  {
    name: LangConstant.TXT_TB_START_DATE,
    align: "left",
  },
  {
    name: LangConstant.TXT_TB_END_DATE,
    align: "left",
  },
  {
    name: LangConstant.TXT_TB_UNIT_PRICE,
    align: "right",
  },
  {
    name: LangConstant.TXT_TB_AMOUNT,
    align: "right",
  },
];

export default TablePaymentHistory;

const useStyles = makeStyles(theme => ({
  root: {
    padding: "30px 54px 60px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 16px 20px",
    },
  },
  rootContainer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  headerCell: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.69,
    color: "#3e4045",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  bottomParent: {
    display: "flex",
    justifyContent: "end",
    height: 56,
    width: "100%",
    backgroundColor: "#fafafb",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  boxTotalAmount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 392,
    height: "inherit",
    paddingRight: 16,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 16,
    },
  },
  totalText: {
    lineHeight: 1.5,
    color: "#3e4045",
  },
  totalAmountText: {
    lineHeight: 1.75,
    color: theme.palette.primary.main,
  },
}));
