import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Tabs, Tab, IconButton, Box } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import clsx from "clsx";

const ListCategoryByMenu = ({ value, isShowCategory, data, onChange, classes, ...otherProps }) => {
  const defaultClasses = useStyles();

  const onScrollSmooth = index => {
    setTimeout(() => {
      let element = document.getElementById(`scroll-id-${index}`);
      element.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  return (
    <Tabs
      value={value}
      onChange={onChange}
      className={clsx(defaultClasses.root, classes?.root)}
      ScrollButtonComponent={props => {
        if (isShowCategory) {
          return <Box width="48px" height="48px" />;
        }
        switch (props.direction) {
          case SCROLL_OPTION.left:
            return (
              <IconButton {...props} className={defaultClasses.scrollIconButton}>
                <ArrowLeft className={defaultClasses.iconScroll} />
              </IconButton>
            );
          case SCROLL_OPTION.right:
            return (
              <IconButton {...props} className={defaultClasses.scrollIconButton}>
                <ArrowRight className={defaultClasses.iconScroll} />
              </IconButton>
            );
          default:
            return null;
        }
      }}
      {...otherProps}
    >
      {data?.length > 0 &&
        data.map((dataMap, index) => (
          <Tab
            key={index}
            label={dataMap.name}
            onClick={() => onScrollSmooth(index)}
            classes={{ root: defaultClasses.tabRoot, textColorInherit: defaultClasses.tabTextColor }}
          />
        ))}
    </Tabs>
  );
};

ListCategoryByMenu.propTypes = {
  value: PropTypes.number,
  data: PropTypes.array,
  isShowCategory: PropTypes.bool,
  onChange: PropTypes.func,
  classes: PropTypes.object,
};
ListCategoryByMenu.defaultProps = {};

const SCROLL_OPTION = {
  left: "left",
  right: "right",
};

export default memo(ListCategoryByMenu);

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: 8,
  },
  scrollIconButton: {
    height: 48,
    width: 48,
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  iconScroll: {
    width: 24,
    height: 24,
    color: theme.palette.grey[500],
  },
  tabRoot: {
    minWidth: "unset",
    padding: "6px 16px",
    textTransform: "unset",
  },
  tabTextColor: {
    color: "#3e4045",
    fontWeight: 600,
    opacity: 1,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
}));
