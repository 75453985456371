import React, { memo } from "react";
import { makeStyles, TableContainer, Table, TableHead, TableRow, TableBody } from "@material-ui/core";
import { LangConstant, AppConstant } from "../../../../const";
import { useTranslation } from "react-i18next";
import { CellHead, CellBody } from "../index";
import { useDispatch } from "react-redux";
import StaffTableAction from "../../../../redux/staff.redux";
import { format } from "date-fns";

const BodyTable = props => {
  const {
    setShowData,
    showData,
    showTime,
    showPosition,
    headData,
    showFilter,
    displayColumn,
    setSelected,
    searchForm,
    setSearchForm,
  } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const valueFilter = ["name", "role", "birthday", "gender", "startTime", "code"];

  const onFilter = (e, value) => {
    let index = showFilter.indexOf(value);
    let dim = searchForm.asc;
    let newValueFilter = valueFilter[index];
    switch (dim) {
      case null:
        dim = 1;
        break;
      case 1:
        dim = 0;
        break;
      default:
        dim = null;
    }
    let newSearchForm = { ...searchForm, asc: dim, orderBy: newValueFilter };
    setSearchForm(newSearchForm);
    dispatch(StaffTableAction.requestStaffTable(newSearchForm));
  };

  const onChangeInviteAccount = uid => {
    let dataMap = showData.find(data => data.uid === uid);
    dataMap.isInvited = 1;
    setShowData(
      showData.map(data => {
        if (data.uid === uid) {
          return dataMap;
        }
        return data;
      }),
    );
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headData.map((cell, index) => (
              <CellHead
                cellData={cell !== LangConstant.TXT_ACCOUNT_STATUS ? getLabel(cell) : ""}
                onFilter={e => onFilter(e, getLabel(cell))}
                className={displayColumn.includes(getLabel(cell)) ? null : classes.dontShow}
                key={index}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {showData !== null &&
            showData.map((dataShow, index) => (
              <TableRow key={index} className={classes.rootTableRow}>
                <CellBody
                  headData={getLabel(LangConstant.TXT_FULL_NAME)}
                  displayColumn={displayColumn}
                  cellData={dataShow.name}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_ROLE)}
                  displayColumn={displayColumn}
                  cellData={dataShow.role - 2}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_PHONE_NUMBER)}
                  displayColumn={displayColumn}
                  cellData={dataShow.phoneNumber}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_EMAIL)}
                  displayColumn={displayColumn}
                  cellData={dataShow.email}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_BIRTH_DAY)}
                  displayColumn={displayColumn}
                  cellData={
                    dataShow.birthday ? format(new Date(dataShow.birthday * 1000), AppConstant.FM_DD_MM_YYYY) : null
                  }
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_SEX)}
                  displayColumn={displayColumn}
                  cellData={dataShow.gender - 1}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_STARTED_DATE)}
                  displayColumn={displayColumn}
                  cellData={
                    dataShow.startTime ? format(new Date(dataShow.startTime * 1000), AppConstant.FM_DD_MM_YYYY) : null
                  }
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_CODE_STAFF)}
                  displayColumn={displayColumn}
                  cellData={dataShow.code}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_WORK_TIME)}
                  displayColumn={displayColumn}
                  cellData={dataShow.workingShift ? dataShow.workingShift.uid : null}
                  dataAPI={showTime}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_POSITION)}
                  displayColumn={displayColumn}
                  cellData={dataShow.staffPosition ? dataShow.staffPosition.uid : null}
                  dataAPI={showPosition}
                />
                <CellBody
                  headData={getLabel(LangConstant.TXT_ACCOUNT_STATUS)}
                  displayColumn={displayColumn}
                  uid={dataShow.uid}
                  email={dataShow.email}
                  name={dataShow.name}
                  role={dataShow.role - 2}
                  showTime={showTime}
                  showPosition={showPosition}
                  showData={showData}
                  setSelected={setSelected}
                  cellData={dataShow.userId}
                  isInvited={dataShow.isInvited}
                  onChangeInvite={onChangeInviteAccount}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(() => ({
  rootTableRow: {
    height: 40,
  },
  tableContainer: {
    border: "1px solid #ebeced",
    height: "75vh",
    "@media only screen and (max-height: 850px)": {
      height: "69vh",
    },
    "@media only screen and (max-height: 812px)": {
      height: "53vh",
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  dontShow: {
    display: "none",
  },
}));

export default memo(BodyTable);
