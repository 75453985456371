import React from "react";
import PropTypes from "prop-types";
import { makeStyles, TableRow, TableCell } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { convertTimeStampToDate } from "utils/date";
import { getCommonKey, formatCurrency } from "utils";
import Status from "./Status";

const BodyTableRow = ({ data, onOpenDetail }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const ObjPackageType = getLabel(LangConstant.OBJ_PACKAGE_TYPE, { returnObjects: true });

  return (
    <TableRow className={classes.root} onClick={onOpenDetail}>
      <TableCell align="left" className={clsx(classes.contentCell, classes.link)}>
        {data.code}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data.paymentDate ? convertTimeStampToDate(data.paymentDate, AppConstant.FM_DD_MM_YYYY) : data.paymentDate}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data.startDate ? convertTimeStampToDate(data.startDate, AppConstant.FM_DD_MM_YYYY) : ""}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data.expiryDate ? convertTimeStampToDate(data.expiryDate, AppConstant.FM_DD_MM_YYYY) : ""}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {ObjPackageType[data.plan]}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {StringFormat(getLabel(LangConstant.FM_TIME_MONTH), data.period)}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {StringFormat(
          getLabel(LangConstant.FM_CURRENCY),
          formatCurrency(data.total.toString()),
          getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
        )}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        <Status status={data.status} />
      </TableCell>
    </TableRow>
  );
};

BodyTableRow.propTypes = {
  data: PropTypes.shape({
    uid: PropTypes.string,
    code: PropTypes.string,
    paymentDate: PropTypes.number,
    startDate: PropTypes.number,
    expiryDate: PropTypes.number,
    plan: PropTypes.number,
    period: PropTypes.number,
    total: PropTypes.number,
    status: PropTypes.number,
  }).isRequired,
  onOpenDetail: PropTypes.func,
};
BodyTableRow.defaultProps = {
  onOpenDetail: () => {},
};

export default BodyTableRow;

const useStyles = makeStyles(theme => ({
  root: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f2f5f7",
    },
  },
  contentCell: {
    fontWeight: 500,
    lineHeight: 1.57,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  link: {
    color: theme.palette.text.link,
  },
}));
