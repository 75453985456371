import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Chip, IconButton, makeStyles, Select, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { ArrowDropDown, ArrowDropUp, Close } from "@material-ui/icons";
import { sortDataByArray, textNormalize } from "utils";
import ChipMenuIFilter from "./MultiSelectMenuIFilter";
import MultiSelectMenuItemList from "./MultiSelectMenuItemList";

const MultiSelectMenu = ({ label, customClasses, data, itemsKey, onChange, selectedArr, isDisable }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState(selectedArr);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [showData, setShowData] = useState(data);
  const [searchValue, setSearchValue] = useState("");
  const [scroll, setScroll] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isShowListProps, setIShowListProps] = useState(false);
  const isExistItems = showData.some(data => data[itemsKey]);

  let isValidData = showData && showData.length > 0;

  const onCheckBox = selectedId => {
    let newSelectedValue = [...selectedValue];
    if (isExistItems) {
      // Subcategory
      let newSelectedSubCategory = [...selectedSubCategory];
      if (newSelectedSubCategory.includes(selectedId)) {
        // delete Subcategory
        newSelectedSubCategory = newSelectedSubCategory.filter(data => data !== selectedId);
        newSelectedValue = newSelectedValue.filter(data => data !== selectedId);
      } else {
        newSelectedSubCategory.push(selectedId);
        newSelectedValue.push(selectedId);
      }
      setSelectedSubCategory(newSelectedSubCategory);
      setSelectedValue(newSelectedValue);
      // Check category
      const targetCategory = showData.find(category => category[itemsKey].find(item => item.uid === selectedId));
      const allChecked =
        targetCategory[itemsKey] && targetCategory[itemsKey].every(item => newSelectedValue.includes(item.uid));
      let newSelectedCategory = [...selectedCategory];
      if (allChecked) {
        newSelectedCategory.push(targetCategory.uid);
      } else {
        newSelectedCategory = newSelectedCategory.filter(data => data !== targetCategory.uid);
      }
      setSelectedCategory(newSelectedCategory);
    } else {
      // Check category not isExistItems
      let newSelectedCategory = [...selectedCategory];
      if (newSelectedCategory.includes(selectedId)) {
        newSelectedCategory = newSelectedCategory.filter(data => data !== selectedId);
        newSelectedValue = newSelectedValue.filter(data => data !== selectedId);
      } else {
        newSelectedCategory.push(selectedId);
        newSelectedValue.push(selectedId);
      }
      setSelectedCategory(newSelectedCategory);
      setSelectedValue(newSelectedValue);
    }
    onChange(newSelectedValue);
  };

  const onCategoryCheckBox = selectedCategoryId => {
    const newSelectedValue = [...selectedValue];
    const newSelectedSubCategory = [...selectedSubCategory];
    //Category
    const newSelectedCategory = [...selectedCategory];
    const targetCategory = showData.find(data => data.uid === selectedCategoryId);

    if (!newSelectedCategory.includes(selectedCategoryId) && targetCategory[itemsKey]) {
      newSelectedCategory.push(selectedCategoryId);

      //For category do not have child
      if (targetCategory[itemsKey] && targetCategory[itemsKey].length === 0) {
        newSelectedValue.push(selectedCategoryId);
      }
      targetCategory[itemsKey]?.map(item => {
        //Check subcategory
        if (!newSelectedSubCategory.includes(item.uid)) {
          newSelectedSubCategory.push(item.uid);
          newSelectedValue.push(item.uid);
        }
      });
    } else {
      const deleteCategoryIndex = newSelectedCategory.indexOf(selectedCategoryId);
      if (deleteCategoryIndex > -1) {
        newSelectedCategory.splice(deleteCategoryIndex, 1);
      }

      //For category do not have subcategory
      if (targetCategory[itemsKey] && targetCategory[itemsKey].length === 0) {
        const deleteIndex = newSelectedValue.indexOf(selectedCategoryId);
        if (deleteIndex > -1) {
          newSelectedValue.splice(deleteIndex, 1);
        }
      }

      //Check subcategory
      if (targetCategory[itemsKey]) {
        targetCategory[itemsKey].map(item => {
          const deleteSubCategoryIndex = newSelectedSubCategory.indexOf(item.uid);
          if (deleteSubCategoryIndex > -1) {
            newSelectedSubCategory.splice(deleteSubCategoryIndex, 1);
          }

          const deleteIndex = newSelectedValue.indexOf(item.uid);
          if (deleteIndex > -1) {
            newSelectedValue.splice(deleteIndex, 1);
          }
        });
      } else {
        const deleteIndex = newSelectedValue.indexOf(selectedCategoryId);
        if (deleteIndex > -1) {
          newSelectedValue.splice(deleteIndex, 1);
        }
      }
    }
    setSelectedCategory(newSelectedCategory);
    setSelectedSubCategory(newSelectedSubCategory);
    setSelectedValue(newSelectedValue);
    onChange(newSelectedValue);
  };

  const onClearAll = () => {
    setSelectedValue([]);
    setSelectedCategory([]);
    setSelectedSubCategory([]);
    onChange([]);
  };

  const onCheckItem = selectedId => {
    onCheckBox(selectedId);
    setScroll(!scroll);
  };

  const onCheckCategory = selectedCategoryId => {
    onCategoryCheckBox(selectedCategoryId);
    setScroll(!scroll);
  };

  const onFilter = search => {
    var searchValue = textNormalize(search);
    let cloneData = JSON.parse(JSON.stringify(data));
    let filteredArray = [...cloneData];
    // If user is searching, fillter data
    if (Boolean(searchValue)) {
      filteredArray = cloneData.filter(category => getFilterArray(category, searchValue, itemsKey));
    }
    setShowData(filteredArray);
  };

  const onClearFilter = () => {
    setSearchValue("");
  };

  const onChangeSearching = searchValue => setSearchValue(searchValue);

  const onDeleteChip = (event, selectedId, isCategory) => {
    event.stopPropagation();
    if (!isDisable) {
      if (isCategory) {
        onCategoryCheckBox(selectedId);
      } else {
        onCheckBox(selectedId);
      }
    }
  };

  const onMouseDown = event => {
    event.stopPropagation();
  };

  const onShowItem = (uid, condition) => {
    for (let index = 0; index < showData.length; index++) {
      if (showData[index].uid === uid) {
        showData[index] = { ...showData[index], isShowItem: condition };
      }
    }
  };

  const onRenderIcon = () => {
    return isShowListProps ? (
      <ArrowDropUp className={classes.iconUp} />
    ) : (
      <IconButton
        onClick={onClearAll}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        classes={{ root: classes.buttonIcon }}
      >
        {isHover ? <Close className={classes.iconClose} /> : <ArrowDropDown className={classes.icon} />}
      </IconButton>
    );
  };

  useEffect(() => {
    if (isExistItems) {
      for (let index = 0; index < showData.length; index++) {
        showData[index] = { ...showData[index], isShowItem: true };
      }
    }
  }, [showData]);

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      onFilter(searchValue);
    }, 500);
    return () => clearTimeout(searchTimeout);
  }, [data, searchValue]);

  useEffect(() => {
    let chipBox = document.getElementById(chipsId);
    if (chipBox) {
      chipBox.scrollTo(0, chipBox.offsetHeight);
    }
  }, [scroll]);

  useEffect(() => {
    setSelectedValue(selectedArr);
    if (selectedArr.length > 0) {
      const newSelectedValue = [...selectedArr];
      const newSelectedCategory = [];
      const newSelectedSubCategory = [];
      showData.map(category => {
        let allItemChecked = true;
        if (category[itemsKey]?.length > 0) {
          category[itemsKey].map(subcategory => {
            if (newSelectedValue.includes(subcategory.uid) && !newSelectedSubCategory.includes(subcategory.uid)) {
              newSelectedSubCategory.push(subcategory.uid);
            } else {
              allItemChecked = false;
            }
          });
        } else {
          allItemChecked = false;
        }
        if (
          (allItemChecked || newSelectedValue.includes(category.uid)) &&
          !newSelectedCategory.includes(category.uid)
        ) {
          if (!newSelectedCategory.includes(category.uid)) {
            newSelectedCategory.push(category.uid);
          }
        }
      });
      setSelectedSubCategory(newSelectedSubCategory);
      setSelectedCategory(newSelectedCategory);
    }
  }, [selectedArr]);

  return (
    <Select
      open={Boolean(isShowListProps)}
      onClose={() => setIShowListProps(!isShowListProps)}
      onOpen={() => setIShowListProps(!isShowListProps)}
      disabled={isDisable}
      className={clsx(classes.root, customClasses?.root)}
      displayEmpty
      labelId="label"
      multiple
      value={selectedValue}
      IconComponent={onRenderIcon}
      renderValue={value => {
        if (value.length == 0) {
          return (
            <Typography color="inherit" className="regular-md-txt">
              {label}
            </Typography>
          );
        } else {
          return (
            <Box className={isDisable ? classes.disableChips : classes.chips} id={chipsId}>
              {sortDataByArray(getCheckedData(data, itemsKey, value), selectedValue).map(data => (
                <Chip
                  key={data.uid}
                  label={data.name}
                  classes={{
                    root: data.parentRole ? classes.categoryChip : classes.subcategoryChip,
                    deleteIcon: classes.deleteChip,
                  }}
                  deleteIcon={<Close />}
                  onDelete={isDisable ? null : event => onDeleteChip(event, data.uid, data.parentRole)}
                  onMouseDown={onMouseDown}
                  size="small"
                  clickable
                />
              ))}
            </Box>
          );
        }
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        className: classes.menuPaper,
        classes: { paper: classes.paperMenuProp },
      }}
      classes={{
        root: clsx(classes.selectRoot, customClasses?.selectRoot),
        icon: classes.selectIcon,
      }}
    >
      <ChipMenuIFilter
        enableChangeViewMode={false}
        onFilter={onChangeSearching}
        onClearFilter={onClearFilter}
        value={searchValue}
      />
      {isValidData ? (
        showData.map((category, index) => (
          <MultiSelectMenuItemList
            key={index}
            category={category}
            items={category[itemsKey] || []}
            multiLevel={isExistItems}
            onCheckItem={onCheckItem}
            onCheckCategory={onCheckCategory}
            onShowItem={onShowItem}
            selectedValue={selectedSubCategory}
            selectedCategory={selectedCategory}
          />
        ))
      ) : (
        <Box className={classes.noResultBox}>
          <Typography variant="body2" color="inherit" classes={{ body2: classes.noResult }}>
            {getLabel(LangConstant.TXT_RESULT_NOT_FOUND)}
          </Typography>
        </Box>
      )}
    </Select>
  );
};

const getFilterArray = (value, search, itemsKey) => {
  let filterItems = [];
  if (value[itemsKey] && value[itemsKey].length > 0) {
    filterItems = value[itemsKey].filter(item => textNormalize(item.name).includes(search));
  }
  value[itemsKey] = filterItems;
  return textNormalize(value.name).includes(search) || filterItems.length > 0;
};

const getCheckedData = (listData, itemsKey, checkedValue) => {
  let result = [];
  listData.map(data => {
    const cloneCategoryData = JSON.parse(JSON.stringify(data));
    if (data[itemsKey] && data[itemsKey].length > 0) {
      if (checkedValue.includes(data.uid)) {
        cloneCategoryData.parentRole = true;
        result.push(cloneCategoryData);
      }
      data[itemsKey].map(subCategory => {
        const cloneSubCategoryData = JSON.parse(JSON.stringify(subCategory));
        if (checkedValue.includes(subCategory.uid)) {
          cloneSubCategoryData.parentRole = false;
          result.push(subCategory);
        }
      });
    } else {
      if (checkedValue.includes(data.uid)) {
        cloneCategoryData.parentRole = true;
        result.push(cloneCategoryData);
      }
    }
  });
  return result;
};

export const chipsId = "chips";

MultiSelectMenu.propTypes = {
  customClasses: PropTypes.object,
  label: PropTypes.string,
  data: PropTypes.array.isRequired,
  itemsKey: PropTypes.string,
  onChange: PropTypes.func,
  selectedObj: PropTypes.array,
  isDisable: PropTypes.bool,
};

MultiSelectMenu.defaultProps = {
  label: "",
  data: [],
  itemsKey: "items",
  onChange: () => {},
  selectedArr: [],
  isDisable: false,
};

export default memo(MultiSelectMenu);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    border: `solid 1px #d4d5d8`,
    borderRadius: "3px",
    "&.MuiInput-underline": {
      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
  },
  selectRoot: {
    height: "max-content",
    width: "100%",
    color: "#d4d5d8",
    paddingLeft: 16,
    paddingRight: 16,
    "&.MuiSelect-select": {
      paddingRight: 0,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  label: { marginLeft: 16, marginRight: 30 },
  chips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
  },
  disableChips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
    "& > *": { cursor: "default" },
  },
  categoryChip: {
    height: 18,
    color: theme.palette.white,
    background: "#2244b7",
    margin: 4,
    fontSize: 11,
    lineHeight: 1.64,
  },
  subcategoryChip: {
    height: 18,
    color: theme.palette.white,
    background: theme.palette.text.link,
    margin: 4,
    fontSize: 11,
    lineHeight: 1.64,
  },
  deleteChip: {
    color: theme.palette.white,
  },
  noResultBox: {
    color: "#7f838c",
    margin: "10px 16px 0",
  },
  menuPaper: {
    marginTop: 4,
    maxHeight: 400,
    maxWidth: "none",
  },
  icon: {
    minWidth: 24,
    height: 39,
    color: theme.palette.grey[500],
  },
  iconUp: {
    minWidth: 24,
    height: 39,
    color: theme.palette.grey[500],
    marginRight: 9,
  },
  buttonIcon: {
    minWidth: 40,
    height: 39,
    color: theme.palette.grey[500],
  },
  iconClose: {
    borderRadius: 2,
    width: 20,
    height: 20,
    fontSize: 16,
  },
  paperMenuProp: {
    maxWidth: "none",
    width: 312,
  },
}));
