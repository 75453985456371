import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import BookingByTime from "./components/BookingByTime";
import BookingByCustomer from "./components/BookingByCustomer";
import BookingByPlace from "./components/BookingByPlace";
import { BglobalJSC } from "components";
import ReportDashboardAction from "redux/report-dashboard.redux";
import { AppConstant } from "const";

am4core.useTheme(am4themes_animated);
window.am4core = am4core;

const MnReportDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isShouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    Promise.all([
      dispatch(ReportDashboardAction.requestReportSummaryReservation(DEFAULT_QUERY_CHART)),
      dispatch(ReportDashboardAction.requestReportSummaryCustomer(DEFAULT_QUERY_CHART)),
      dispatch(ReportDashboardAction.requestReportSummaryPlace(DEFAULT_QUERY_CHART_BY_PLACE)),
      dispatch(ReportDashboardAction.requestReportSummaryService(DEFAULT_QUERY_PARAMS_BY_SERVICE)),
    ]);
    setTimeout(() => {
      setShouldRender(true);
    }, 600);
  }, []);

  return (
    <Box className={classes.root}>
      {isShouldRender ? (
        <>
          <BookingByTime />
          <BookingByCustomer />
          <BookingByPlace />
          <BglobalJSC />
        </>
      ) : (
        <Box className="center-root">
          <CircularProgress color="primary" size={30} />
        </Box>
      )}
    </Box>
  );
};

export default MnReportDashboard;

export const DEFAULT_QUERY_CHART = {
  view: AppConstant.CHART_VIEW_BY.hour,
  start: new Date(),
  end: new Date(),
};
export const DEFAULT_QUERY_CHART_BY_PLACE = {
  ...DEFAULT_QUERY_CHART,
  type: AppConstant.CATEGORY_TYPE.place,
};
export const DEFAULT_QUERY_PARAMS_BY_SERVICE = {
  ...DEFAULT_QUERY_CHART,
  type: AppConstant.CATEGORY_TYPE.service,
};

const useStyles = makeStyles({
  root: { paddingBottom: 16, "& > div": { marginBottom: 16 } },
});
