import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, OutlinedInput, InputAdornment, Checkbox, makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";

const InputPayment = props => {
  const {
    name,
    title,
    checkBox,
    checked,
    includeInput,
    onCheck,
    valueTime,
    currencyUnit,
    classInput,
    disabledCheckBox,
    inputProps,
    classes,
  } = props;

  const defaultClasses = useStyles();

  const onChecked = event => {
    onCheck(event.currentTarget.name, event.currentTarget.checked);
  };

  return (
    <Grid item container direction="column" justify="flex-start" className="inputBooking">
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
        className={`labelForm  ${checkBox && !includeInput ? "labelLarge" : "label"}`}
      >
        <Grid item>
          <Typography className={clsx(`medium-md-txt typo`, valueTime ? defaultClasses.typography : "")}>
            {title}
          </Typography>
        </Grid>
        <Box className={defaultClasses.boxValueTime}>
          {valueTime && (
            <Box className={classInput}>
              <OutlinedInput
                classes={{
                  root: defaultClasses.rootInput,
                  input: defaultClasses.inputOutline,
                }}
                endAdornment={
                  <InputAdornment position="end" className={defaultClasses.title}>
                    <Typography variant="body2">{currencyUnit}</Typography>
                  </InputAdornment>
                }
                {...inputProps}
              />
            </Box>
          )}
          {checkBox && (
            <Grid item>
              <Checkbox
                color="primary"
                className="checkbox"
                name={name}
                checked={checked}
                onChange={onChecked}
                disabled={disabledCheckBox}
              />
            </Grid>
          )}
        </Box>
      </Grid>
      {(includeInput || !checkBox) && (
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          className={clsx("inputFrom", classes && classes.gridItem)}
          alignItems="baseline"
        >
          <OutlinedInput
            classes={{
              root: `medium-md-txt extra`,
              input: defaultClasses.infoInput,
              multiline: defaultClasses.multilineInput,
            }}
            {...inputProps}
          />
        </Grid>
      )}
    </Grid>
  );
};

InputPayment.propTypes = {
  typeInput: PropTypes.string,
  title: PropTypes.string,
  nameInput: PropTypes.string,
  heightType: PropTypes.string,
  buttonValue: PropTypes.string,

  rowsMax: PropTypes.number,

  checkBox: PropTypes.bool,
  includeInput: PropTypes.bool,
  valueTime: PropTypes.bool,

  onCheck: PropTypes.func,
  onChangeInput: PropTypes.func,

  inputProps: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    rowsMax: PropTypes.number,
    multiline: PropTypes.bool,
  }),
  classes: PropTypes.shape({
    gridItem: PropTypes.string,
  }),
};

InputPayment.defaultProps = {
  typeInput: "text",
  title: "",
  heightType: "small",
  buttonValue: "",
  checkBox: false,
  checked: true,
  includeInput: false,
  valueTime: false,
  inputProps: {
    type: "text",
    value: "",
    placeholder: "",
    rowsMax: 1,
    multiline: true,
  },
};

const useStyles = makeStyles(() => ({
  root: {
    width: 120,
  },
  infoInput: {
    height: "100%",
    lineHeight: 1.57,
    "&::placeholder": {
      fontSize: 14,
      lineHeight: 1.57,
    },
  },
  multilineInput: {
    alignItems: "flex-start",
    padding: "9px 8px",
  },
  rootInput: {
    height: 30,
  },
  inputOutline: {
    width: 130,
  },
  title: {
    justifyContent: "center",
    borderLeft: "solid 0.5px #cecfd2",
    marginLeft: 0,
    width: 30,
    maxHeight: "100%",
    height: 30,
  },
  typography: {
    color: "#7f838c",
  },
  boxValueTime: {
    display: "flex",
    alignItems: "center",
  },
}));

export default memo(InputPayment);
