import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { CategoryItemListHeader, CategoryItemListBody } from "components/mn-master-product";
import { SearchBar } from "components";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, convertTimeItemDescription, formatCurrency, removeVietnameseTones } from "utils";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ItemDetailsAction from "redux/item-details.redux";
import ItemDetailsContentAction from "redux/item-details-content.redux";

const DialogAddServiceFromCategory = ({ uidParent, dataChild, nameParent, isOpen, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const getListItemDetailsContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isGetListItemDetailsContentSuccess,
    shallowEqual,
  );
  const putEditContentSuccess = useSelector(state => state.itemDetailsRedux.isPutEditContentSuccess, shallowEqual);

  const dataListItemDetailsContent = useSelector(
    state => state.itemDetailsContentRedux.dataGetListItemDetailsContent,
    shallowEqual,
  );

  const [listContent, setListContent] = useState([]);
  const [saveListContent, setListSaveContent] = useState([]);

  const onSearch = name => {
    let newListSearch = saveListContent.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListContent(newListSearch);
  };

  const onAddContentToCategoryService = uid => {
    dispatch(
      ItemDetailsAction.putEditContent({
        uid: uidParent,
        itemDetailsContentList: [...dataChild, { uid: uid, amount: 1 }],
      }),
    );
  };

  useEffect(() => {
    if (!dataListItemDetailsContent) {
      dispatch(
        ItemDetailsContentAction.getListItemDetailsContent({
          type: AppConstant.TYPE_CATEGORY.service,
        }),
      );
    } else {
      setListContent(dataListItemDetailsContent.data);
      setListSaveContent(dataListItemDetailsContent.data);
    }
  }, []);

  useEffect(() => {
    if (dataListItemDetailsContent?.data && getListItemDetailsContentSuccess) {
      dispatch(ItemDetailsContentAction.resetItemDetailsContent());
      setListContent(dataListItemDetailsContent.data);
      setListSaveContent(dataListItemDetailsContent.data);
    }
  }, [getListItemDetailsContentSuccess]);

  useEffect(() => {
    if (putEditContentSuccess) {
      dispatch(ItemDetailsAction.resetItemDetails());
      dispatch(
        ItemDetailsAction.getListItemDetails({
          type: AppConstant.TYPE_CATEGORY.service,
        }),
      );
    }
  }, [putEditContentSuccess]);

  return (
    <Dialog open={isOpen} fullScreen>
      <Box className={classes.rootHeader}>
        <Typography className={classes.nameHeaderTypo}>
          {getLabel(LangConstant.FN_ADD_SERVICE_TO_CATEGORY)(nameParent)}
        </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
      <Box textAlign="center">
        <SearchBar
          className={classes.searchBar}
          onKeyUp={onSearch}
          placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
        />
        <Box className={classes.rootBody}>
          <CategoryItemListHeader isProduct={false} />
          {listContent.map(content => (
            <CategoryItemListBody
              key={content.uid}
              uid={content.uid}
              onClickAdd={onAddContentToCategoryService}
              categoryName={content.name}
              isAdd={true}
              timeUsed={
                content.usingTime && content.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.times
                  ? convertTimeItemDescription(content.usingTime, content.usingTimeType)
                  : ""
              }
              image={content.image}
              price={
                content.price
                  ? `${formatCurrency(`${content.price}`)} ${getLabel(LangConstant.TXT_CURRENCY)}/${
                      content.usingTime && content.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.time
                        ? convertTimeItemDescription(content.usingTime, content.usingTimeType)
                        : getLabel(getCommonKey(LangConstant.P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT))
                    }`
                  : ""
              }
              disabled={Boolean(dataChild.find(dataChildMap => dataChildMap.uid === content.uid))}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
export default memo(DialogAddServiceFromCategory);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: 400,
    height: 30,
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  rootBody: {
    marginTop: 48,
    marginBottom: 48,
    width: "80%",
    marginLeft: "10%",
    [theme.breakpoints.down(600)]: {
      width: "90%",
      marginLeft: "5%",
      overflowX: "auto",
    },
  },
}));
