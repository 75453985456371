import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Popover, Button, Box, Tooltip, Paper } from "@material-ui/core";
import { Close, Link } from "@material-ui/icons";
import { LangConstant, ApiConstant } from "const";
import { FacebookIcon } from "theme/images";
import PopupFacebook from "./PopupFacebook";
import ConsumerAction from "redux/consumer.redux";
import InviteBySMS from "./InviteBySMS";
import InviteByEmail from "./InviteByEmail";

const PopupInvite = ({ open, setOpen, shopData }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const isSuccess = useSelector(state => state.consumerRedux.isInvitationConsumerSuccess);

  const [openFacebook, setOpenFacebook] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpenFacebook = () => {
    setOpenFacebook(true);
    dispatch(ConsumerAction.requestGetInvitationFacebook({}));
  };

  const onGetLink = () => {
    let textField = document.getElementById("link");
    textField.select();
    document.execCommand("copy");
    setTooltip(true);
    setTimeout(() => setTooltip(false), 500);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(ConsumerAction.resetConsumer());
      dispatch(ConsumerAction.requestGetEmailQuantity());
    }
  }, [isSuccess]);

  return (
    <Popover open={open} anchorEl={document.body} marginThreshold={0} classes={{ paper: classes.propPaper }}>
      <Paper elevation={1} square classes={{ root: classes.rootPaper, elevation1: classes.elevation1 }}>
        <Box className={classes.boxCloseParent}>
          <Box className={classes.boxCloseChildren}>
            <Button
              onClick={() => onClose()}
              classes={{
                root: classes.rootButton,
                label: classes.label,
                startIcon: classes.startIcon,
                iconSizeMedium: classes.iconSizeMedium,
              }}
              startIcon={<Close className={classes.rootIcon} />}
            />
          </Box>
        </Box>
        <Box className={classes.boxBodyParent}>
          <Box className={classes.boxBodyChildren}>
            <InviteByEmail />
            <InviteBySMS />
            <Box className={classes.boxContentParent}>
              <Box className={classes.boxContentChildren}>
                <Button
                  disableRipple
                  classes={{ root: classes.imageButton, startIcon: classes.optionStart }}
                  startIcon={<img onClick={() => onOpenFacebook()} src={FacebookIcon} alt="facebook" />}
                />
                {/* TODO hiện nút này khi xin được quyền nhắn tin facebook */}
                <Button disableRipple className={classes.optionButton}>
                  <Box onClick={() => onOpenFacebook()} className={classes.optionChoose}>
                    {getLabel(LangConstant.TXT_INVITE_FACEBOOK)}
                  </Box>
                </Button>
                <PopupFacebook
                  openFacebook={openFacebook}
                  setOpenFacebook={setOpenFacebook}
                  shopData={ApiConstant.HOST_CONSUMER + `/register?shop_name=${shopData}`}
                />
              </Box>
            </Box>
            <Box className={classes.boxContentParent}>
              {document.queryCommandSupported("copy") && (
                <Box className={classes.boxContentChildrenLink}>
                  <Button
                    disableRipple
                    classes={{ root: classes.imageButton, startIcon: classes.optionStart }}
                    onClick={onGetLink}
                    startIcon={<Link className={classes.linkIcon} />}
                  />

                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    placement="right-start"
                    open={tooltip}
                    title={getLabel(LangConstant.TXT_COPIED)}
                  >
                    <Button disableRipple className={classes.optionButton}>
                      <Box className={classes.optionChoose} onClick={onGetLink}>
                        {getLabel(LangConstant.TXT_INVITE_LINK)}
                      </Box>
                    </Button>
                  </Tooltip>
                </Box>
              )}
              <textarea
                id="link"
                className={classes.link}
                defaultValue={ApiConstant.HOST_CONSUMER + `/login?shop_name=${shopData}`}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Popover>
  );
};
const useStyles = makeStyles(() => ({
  propPaper: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
  },
  rootPaper: {
    height: "70%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  elevation1: {
    boxShadow: "none",
  },
  boxCloseParent: {
    marginRight: 0,
    height: 50,
    marginTop: 20,
    maxWidth: "100%",
  },
  boxCloseChildren: {
    height: "100%",
    width: "20%",
    marginLeft: "80%",
  },
  boxBodyParent: {
    width: "100%",
    height: "100%",
  },
  boxBodyChildren: {
    height: "100%",
    width: "90%",
    margin: "auto",
  },
  boxContentParent: {
    marginTop: 50,
    "@media only screen and (max-width: 1000px)": {
      marginTop: 38,
    },
  },
  boxContentChildren: {
    display: "flex",
    justifyContent: "left",
    width: 290,
    margin: "auto",
    "@media only screen and (max-width: 1000px)": {
      marginLeft: 0,
      justifyContent: "center",
      width: "auto",
      paddingLeft: 0,
    },
  },
  boxContentChildrenLink: {
    display: "flex",
    justifyContent: "left",
    width: 290,
    margin: "auto",
    "@media only screen and (max-width: 1000px)": {
      marginLeft: 0,
      justifyContent: "center",
      width: "auto",
      paddingLeft: 0,
    },
  },
  rootButton: {
    width: 50,
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: 20,
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },
  label: {
    height: "100%",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  iconSizeMedium: {
    fontSize: 30,
  },
  optionChoose: {
    width: "100%",
    marginLeft: 20,
    "&:hover": {
      color: "#447aff",
      textDecoration: "underline",
    },
  },
  optionStart: {
    marginRight: 0,
  },
  imageButton: {
    minWidth: 24,
    height: 24,
    padding: "0 0 0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  optionButton: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  link: {
    opacity: 0,
  },
  tooltip: {
    width: 85,
    height: 18,
    textAlign: "center",
    fontWeight: 300,
    fontSize: 11,
    padding: 0,
    borderRadius: 11.5,
    backgroundColor: "#447aff",
  },
  linkIcon: {
    width: 24,
    height: 24,
    color: "#447aff",
  },
}));
export default memo(PopupInvite);
