export default {
  TXT_RESERVATION_BOOKING: "Thời gian",
  TXT_BOOKING_TIME_SUB_TITLE: "Chọn thời gian đặt lịch hẹn",
  TXT_SERVICE_AND_PRODUCT: "Chọn dịch vụ đi kèm/đặt trước món",
  TXT_SERVICE_AND_PRODUCT_PRE_ORDER_INSTRUCTION:
    'Nhấn vào nút "Chọn" trong bước này để bắt đầu đặt trước những sản phâm và dịch vụ bạn mong muốn. Những sản phẩm và dịch vụ được đặt trước có thể sẽ được tính thêm cước phí đặt cọc.',
  TXT_PERIODIC_BOOKING: "Lịch hẹn định kì",
  TXT_PERIODIC_BOX_SUB_TITLE:
    "Bạn có thể tạo nhanh các lịch hẹn định kỳ để sử dụng các dịch vụ. Các sản phẩm đặt trước sẽ chỉ được áp dụng cho lịch hẹn đầu tiên trong chuỗi định kỳ.",
  L_RESERVATION_NOTE: "Ghi chú:",
  TXT_CONTINUE: "Tiếp tục",
  L_HIDE: "- Thu gọn",
  L_READ_MORE: "+ Mở rộng",
  L_SEE_MORE: "+ Xem thêm",
  TXT_SCHEDULE: "Lịch hẹn",
  TXT_CONTACT: "Liên hệ",
  TXT_CONFIRM_PREVIEW: "Xác nhận",
  TXT_MY_PLACES: "Địa điểm của tôi",
  P_PEOPLE: "Số người lớn *",
  P_MAN: "Số người *",
  P_CHILDREN: "Số trẻ em",
  P_SELECT_SERVICE: "Chọn dịch vụ",
  P_SELECT_PLACE: "Chọn loại vị trí",
  P_SELECT_DATE: "Chọn ngày *",
  P_SELECT_TIME: "Chọn giờ *",
  P_BOOK_NOTE: "Yêu cầu đặc biệt để chúng tôi phục vụ bạn tốt nhất",
  TXT_INFORMATION: "Thông tin",
  TXT_OTHER_INFORMATION: "Thông tin khác",
  FM_FULL_DATE_TEXT: "{0} tháng {1}, {2}",
  TXT_AVAILABLE_TIME: "Thời gian khả dụng",
  TXT_UNAVAILABLE_TIME: "Thời gian đã kín lịch",
  TXT_DEPOSIT_PAYMENT_INFO: "Thông tin thanh toán",
  TXT_DEPOSIT_SHOP_PAYMENT_METHOD_INFO: "Thông tin tài khoản",
  TXT_PAYMENT_METHOD: "Phương thức:",
  TXT_SHIPPING_COST: "Phí vận chuyển:",
  TXT_RECEIVER_NAME: "Tên người nhận",
  TXT_PRODUCT: "Sản phẩm",
  TXT_RECEIVER_PHONE: "Số điện thoại",
  TXT_RESERVATION_ADDRESS: "Địa chỉ",
  TXT_MY_ORDER: "Đơn hàng",
  TXT_TOTAL_TAX_FREE: "Tổng tiền:",
  TXT_TAX_PRICE_VALUE: "VAT:",
  TXT_TOTAL_TAX_INCLUDED: "Phải thanh toán",
  TXT_REMOTE_ORDER_DETAIL: "Thông tin đơn hàng",
  TXT_INSTRUCTION_REMOTE_ORDER: "Vui lòng điền thông tin liên hệ, địa chỉ nhận hàng và ghi chú của bạn nếu có.",
  TXT_PICKUP_ADDRESS: "Địa chỉ nhận hàng",
  TXT_ORDER_PICKUP_DETAIL: "Thông tin nhận hàng",
  TXT_ADD_ADDRESS: "Thêm địa chỉ",
  TXT_DEFAULT: "Mặc định",
};
