import React, { memo } from "react";
import { Grid, Typography, Divider, Paper, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "../../const";
import InputShopInfo from "./InputShopInfo";
import PropTypes from "prop-types";

const BookingBaseInfo = props => {
  const { t: getLabel } = useTranslation();
  const { previewData, onUpdatePreviewData, onAccept } = props;
  const handlerChecked = event => {
    const name = event.currentTarget.name;
    onUpdatePreviewData(name, event.currentTarget.checked);
  };
  const onClickReject = () => {
    onUpdatePreviewData("", "", "batchState", AppConstant.SETTING_ADVANCES);
  };
  const hanlderAccept = () => {
    onAccept();
  };

  return (
    <Paper className="shopInfo">
      <Grid container direction="column" justify="center" alignItems="center" className="main">
        <Grid item container direction="row" justify="flex-start">
          <Typography className="title">{getLabel(LangConstant.TXT_BOOK_ADVANCE)}</Typography>
        </Grid>
        <Grid container direction="column">
          <Divider className="divide"></Divider>
          <Grid item>
            <InputShopInfo
              name={AppConstant.KEY_BOOK_CUSTOMER}
              checkBox={true}
              checked={previewData[AppConstant.KEY_BOOK_CUSTOMER].publish === 1 ? true : false}
              onCheck={handlerChecked}
              title={getLabel(LangConstant.TXT_BOOK_SETTING_PERSON_DETAIL)}
            ></InputShopInfo>
          </Grid>
          <Grid item>
            <InputShopInfo
              name={AppConstant.KEY_BOOK_PERIODIC}
              checkBox={true}
              checked={previewData[AppConstant.KEY_BOOK_PERIODIC].publish === 1 ? true : false}
              onCheck={handlerChecked}
              title={getLabel(LangConstant.TXT_BOOK_PERIODIC)}
            ></InputShopInfo>
          </Grid>
          <Grid item>
            <InputShopInfo
              name={AppConstant.KEY_BOOK_PLACE}
              checkBox={true}
              checked={previewData[AppConstant.KEY_BOOK_PLACE].publish === 1 ? true : false}
              onCheck={handlerChecked}
              title={getLabel(LangConstant.TXT_BOOK_POSTITION_TYPE)}
            ></InputShopInfo>
          </Grid>
          <Grid item>
            <InputShopInfo
              name={AppConstant.KEY_BOOK_SERVICE}
              checkBox={true}
              checked={previewData[AppConstant.KEY_BOOK_SERVICE].publish === 1 ? true : false}
              onCheck={handlerChecked}
              title={getLabel(LangConstant.TXT_BOOK_SERVICE_TYPE)}
            ></InputShopInfo>
          </Grid>
          <Grid item container direction="row" justify="center" className="stateButtonForm" spacing={2}>
            <Grid item>
              <Button variant="contained" className="stateButton acceptButton semiBold-sm-txt" onClick={hanlderAccept}>
                {getLabel(LangConstant.TXT_SHOP_SAVE)}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" className="stateButton rejectButton semiBold-sm-txt" onClick={onClickReject}>
                {getLabel(LangConstant.TXT_SHOP_CANCEL)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

BookingBaseInfo.propTypes = {
  previewData: PropTypes.object,
};

BookingBaseInfo.defaultProps = {
  previewData: {},
};
export default memo(BookingBaseInfo);
