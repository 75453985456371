import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const ReportIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g transform="translate(-40 -418) translate(40 418) translate(2 2)">
        <path
          d="M12,10L12,10c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h0c0.55,0,1,0.45,1,1v0C13,9.55,12.55,10,12,10z M12,6L12,6 c-0.55,
        0-1-0.45-1-1V2c0-0.55,0.45-1,1-1h0c0.55,0,1,0.45,1,1v3C13,5.55,12.55,6,12,6z M7,18c-1.1,0-1.99,0.9-1.99,2S5.9,22,7,22 s2-0.9,2-2S8.1,18,7,18z M17,18c-1.1,0-1.99,0.9-1.99,2s0.89,2,1.99,2s2-0.9,2-2S18.1,18,17,18z M8.1,13h7.45 c0.75,0,1.41-0.41,1.75-1.03l3.24-6.14c0.25-0.48,0.08-1.08-0.4-1.34v0c-0.49-0.27-1.1-0.08-1.36,0.41L15.55,11H8.53L4.27,2H2 C1.45,2,1,2.45,1,3v0c0,0.55,0.45,1,1,1h1l3.6,7.59l-1.35,2.44C4.52,15.37,5.48,17,7,17h11c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7 L8.1,13z"
        />
        <path fill="currentColor" fillRule="nonzero" d="M2.35 8.95L7.65 5.65M7.15 6.35L11.5 7.5M12.95 7.95L17.5 1.5" />
      </g>
    </SvgIcon>
  );
};

ReportIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
ReportIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(ReportIcon);
