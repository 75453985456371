import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const CalendarIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0L24 0 24 24 0 24z" transform="translate(-390 -179) translate(342 171) translate(48 8)" />
              <path
                fill="#4B4D53"
                fillRule="nonzero"
                d="M19 11.5v1H5v-1h14m2-2H3v5h18v-5zm-2 2v1H5v-1h14m0 6.5v1H5v-1h14m2-8.5H3v5h18v-5zm0 6.5H3v5h18v-5zM19 5v1H5V5h14m2-2H3v5h18V3z"
                transform="translate(-390 -179) translate(342 171) translate(48 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default memo(CalendarIcon);
