import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { CheckCircleRounded } from "@material-ui/icons";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  TableContainer,
  Paper,
  Container,
  makeStyles,
  Box,
  useTheme,
} from "@material-ui/core";
import { LangConstant } from "const";
import { CellHead, CellBody } from "components";
import SearchBar from "components/SearchBar";
import ReportConsumerAction from "redux/report-consumer.redux";

const MnReportConsumer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t: getLabel } = useTranslation();

  const headData = LangConstant.ARR_REPORT_CONSUMERS_HEADER;

  const data = useSelector(state => state.reportConsumerRedux.data, shallowEqual);

  const [user, setUser] = useState(data !== null ? data.data : null);
  const [page, setPage] = useState(0);
  const [paging] = useState(1);
  const [size] = useState(10);
  const [filter, setFilter] = useState("");
  const [rowsPerPage] = useState(size);

  const onSearch = name => {
    dispatch(
      ReportConsumerAction.requestReportConsumer({
        page: page + 1,
        paging: paging,
        size: size,
        filter: name,
      }),
    );
    setFilter(name);
  };

  const onChangePage = (_, newPage) => {
    dispatch(
      ReportConsumerAction.requestReportConsumer({
        page: newPage + 1,
        paging: paging,
        size: size,
        filter: filter,
      }),
    );

    setPage(newPage);
  };

  useEffect(() => {
    if (data == null) {
      dispatch(
        ReportConsumerAction.requestReportConsumer({ page: page + 1, paging: paging, size: size, filter: filter }),
      );
    }
  }, []);

  useEffect(() => {
    setUser(data != null ? data.data : null);
  }, [data]);

  return (
    <Box className="mn-report-consumer-root">
      <Paper elevation={1} square className={"container"}>
        <Container>
          <Box className={"box-root"}>
            <SearchBar className="search-bar-root" onKeyUp={onSearch} />
            <TableContainer className={"table-container"}>
              <Table aria-label="consumer table" className={"table-root"}>
                <TableHead>
                  <TableRow>
                    {headData.map((cell, index) => (
                      <CellHead className={classes.cellHeadRoot} cellData={getLabel(cell)} key={index} />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user !== null &&
                    user.map((dataShow, index) => (
                      <TableRow key={index}>
                        <CellBody className={classes.cellBodyRoot} cellData={dataShow.name} />
                        <CellBody className={classes.cellBodyRoot} cellData={dataShow.phoneNumber} />
                        <CellBody className={classes.cellBodyRoot} cellData={dataShow.email} />
                        <CellBody className={classes.cellBodyRoot} cellData={dataShow.reservationCount} />
                        <CellBody
                          className={classes.cellBodyRoot}
                          cellData={dataShow.userId ? <CheckCircleRounded className={"icon-status"} /> : null}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              classes={{
                selectRoot: "select-root",
                toolbar: "toolbar-root",
                caption: "caption-root",
              }}
              style={{ color: theme.palette.grey[600] }}
              className={`pagination-root regular-sm-txt`}
              component="div"
              count={data?.total || 0}
              page={page}
              onChangePage={onChangePage}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={``}
              labelDisplayedRows={({ from, to, count }) => (
                <Typography variant="body2" classes={{ body2: "regular-sm-txt" }} color="textPrimary" component="span">
                  {getLabel(LangConstant.FN_PAGINATION)(from, to, count)}
                </Typography>
              )}
            />
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  cellHeadRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  cellBodyRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}));

export default memo(MnReportConsumer);
