import React from "react";
import PropTypes from "prop-types";
import { Snackbar, useTheme } from "@material-ui/core/";
import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";

export default function Alert({ isShow, type, onClose, anchorOrigin, message, isAutoClose, title }) {
  const theme = useTheme();
  return (
    <Snackbar open={isShow} autoHideDuration={isAutoClose ? 5000 : null} onClose={onClose} anchorOrigin={anchorOrigin}>
      <MuiAlert
        onClose={onClose}
        severity={type}
        variant="filled"
        classes={{ root: "alert-result", message: "alert-result__msg" }}
        style={{ zIndex: theme.zIndex.appBar + 100 }}
      >
        {title && <AlertTitle color="inherit">{title}</AlertTitle>}
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

Alert.propTypes = {
  isShow: PropTypes.bool.isRequired,
  isAutoClose: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.string,
  anchorOrigin: PropTypes.object,
  title: PropTypes.string,

  onClose: PropTypes.func.isRequired,
};

Alert.defaultProps = {
  isShow: false,
  isAutoClose: true,
  type: "info",
  anchorOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};
