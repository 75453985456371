import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const CategoryItemListHeader = ({ isProduct }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Box className={classes.root}>
      <Typography className={classes.headerServiceNameTypo}>
        {isProduct ? getLabel(LangConstant.TXT_PRODUCT_NAME) : getLabel(LangConstant.TXT_SERVICE_NAME)}
      </Typography>
      <Typography className={isProduct ? classes.headerTypoProduct : classes.headerTypo}>
        {isProduct ? getLabel(LangConstant.TXT_STOCK) : getLabel(LangConstant.TXT_BOOK_USED_TIME)}
      </Typography>
      <Typography className={classes.headerTypo}>{getLabel(LangConstant.TXT_PRICE)}</Typography>
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: 48,
    minWidth: 1000,
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    padding: "13px 16px",
    display: "flex",
  },
  headerServiceNameTypo: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "22px",
    width: "35%",
    textAlign: "left",
  },
  headerTypo: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "22px",
    width: "25%",
    textAlign: "left",
  },
  headerTypoProduct: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "22px",
    width: "25%",
    textAlign: "center",
  },
}));
CategoryItemListHeader.propTypes = {
  isStock: PropTypes.bool,
};

export default memo(CategoryItemListHeader);
