import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, DialogContent, DialogActions, Button, OutlinedInput, Box } from "@material-ui/core";
import { LangConstant, AppConstant } from "../../../../../const";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import DialogLayout from "./DialogLayout";

const PlaceDialog = props => {
  const { onClose, onSubmit, data } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [inputData, setInputData] = React.useState(
    data
      ? {
          name: data.name,
          totalAmount: Object.keys(data.items).length,
          capacity: "",
          uid: data.uid,
          updateAll: Object.keys(data.items).length,
        }
      : { name: "", totalAmount: "", capacity: "" },
  );

  const onChangeEditData = event => {
    if (event.target.name === "totalAmount") {
      const inputValue = parseInt(event.target.value);
      if (!isNaN(inputValue)) {
        if (inputValue <= AppConstant.VALUE_AMOUNT_NUMBER_PLACE_ITEM) {
          setInputData({ ...inputData, [event.target.name]: inputValue });
        } else {
          setInputData({ ...inputData, [event.target.name]: AppConstant.VALUE_AMOUNT_NUMBER_PLACE_ITEM });
        }
      } else {
        setInputData({ ...inputData, [event.target.name]: 0 });
      }
    } else if (event.target.name === "capacity") {
      const inputValue = parseInt(event.target.value);
      if (!isNaN(inputValue)) {
        setInputData({ ...inputData, [event.target.name]: inputValue });
      } else {
        setInputData({ ...inputData, [event.target.name]: 0 });
      }
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }
  };

  return (
    <DialogLayout
      onClose={onClose}
      titleName={
        data ? getLabel(LangConstant.T_PLACE_EDIT_CATEGORY_DIALOG) : getLabel(LangConstant.T_PLACE_CATEGORY_DIALOG)
      }
    >
      <form>
        <DialogContent className={classes.dialogContent}>
          <ContentBlock
            text={inputData.name}
            label={getLabel(LangConstant.L_PLACE_CATEGORY_DIALOG_NAME)}
            placeholder={getLabel(LangConstant.P_PLACE_CATEGORY_DIALOG_NAME)}
            inputProps={{
              name: "name",
              onChange: onChangeEditData,
            }}
          />
          <ContentBlock
            text={inputData.totalAmount}
            label={getLabel(LangConstant.L_PLACE_CATEGORY_DIALOG_AMOUNT)}
            placeholder={getLabel(LangConstant.P_PLACE_CATEGORY_DIALOG_AMOUNT)}
            inputProps={{
              name: "totalAmount",
              onChange: onChangeEditData,
              disabled: Boolean(data && Object.keys(data.items).length > 0),
            }}
          />
          <ContentBlock
            text={inputData.capacity}
            label={getLabel(LangConstant.L_PLACE_CATEGORY_DIALOG_CAPACITY)}
            placeholder={getLabel(LangConstant.P_PLACE_CATEGORY_DIALOG_CAPACITY)}
            inputProps={{
              name: "capacity",
              onChange: onChangeEditData,
              disabled: Boolean(data && Object.keys(data.items).length > 0),
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={e => onSubmit(e, inputData)}
          >
            <Typography variant="body2" classes={{ body2: "medium-md-txt" }} style={{ color: theme.palette.white }}>
              {data ? getLabel(LangConstant.TXT_SAVE) : getLabel(LangConstant.TXT_CREATE_BUTTON)}
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};

PlaceDialog.propTypes = { onClose: PropTypes.func, onSubmit: PropTypes.func, data: PropTypes.object };

PlaceDialog.defaultProps = {};

const ContentBlock = props => {
  const { text, label, placeholder, inputProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box className={classes.contentItem}>
      <Typography variant="body2" classes={{ body2: "medium-md-txt" }} style={{ color: theme.palette.grey[600] }}>
        {label}
      </Typography>
      <OutlinedInput
        value={text}
        placeholder={placeholder}
        className={classes.outlineInput}
        inputProps={{ classes: { input: `${classes.input} regular-md-txt` }, style: { textAlign: "center" } }}
        {...inputProps}
        required
      ></OutlinedInput>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: "flex",
    alignItems: "center",
    borderBottom: `solid 0.5px ${theme.palette.grey[300]}`,
    padding: "30px 30px",
  },
  dialogAction: {
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    width: 73,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    minHeight: "unset",
    padding: 0,
  },
  textButton: {},
  contentItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  outlineInput: {
    borderRadius: "0%",
    marginTop: 21,
    height: 40,
    marginLeft: 5,
    color: theme.palette.grey[600],
  },
  input: {
    "&::placeholder": {
      opacity: 1,
    },
    color: theme.palette.grey[400],
  },
}));

export default memo(PlaceDialog);
