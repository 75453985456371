import React, { memo, useState } from "react";
import { makeStyles, Box, Button, TablePagination, Typography } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { DialogAdjust, EditTableIcon, StaffSetting } from "../index";
import { useDispatch } from "react-redux";
import StaffTableAction from "redux/staff.redux";
import SettingsIcon from "@material-ui/icons/Settings";

const PaginationFooter = ({ headData, displayColumn, setDisplayColumn, showDefault, showData, page, setPage }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rowsPerPage] = useState(30);
  const [showColumn, setShowColumn] = useState(false);
  const [openSettingStaff, setOpenSettingStaff] = useState(false);
  const total = showData !== null ? showData.total : 0;

  const onChangePage = (_, newPage) => {
    setPage(newPage - 1);
    dispatch(StaffTableAction.requestStaffTable({ page: newPage, paging: 1, size: 30 }));
  };

  const onOpenAdjust = () => {
    setShowColumn(true);
  };
  const onOpenSettingStaff = () => {
    setOpenSettingStaff(true);
  };

  return (
    <Box className={classes.paginationParent}>
      <TablePagination
        classes={{
          root: classes.tablePagination,
          selectRoot: classes.selectRoot,
          toolbar: classes.toolbar,
          caption: classes.caption,
          actions: classes.actions,
        }}
        component="div"
        onChangePage={() => null}
        count={total}
        page={page}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={``}
        labelDisplayedRows={({ from, to, count }) => (
          <Typography
            variant="body2"
            classes={{ root: classes.paginationTypo, body2: "regular-sm-txt" }}
            component="span"
          >
            {getLabel(LangConstant.FN_PAGINATION)(from, to, count)}
          </Typography>
        )}
      />
      <Pagination
        className={classes.tablePagination}
        count={parseInt((total - 1) / rowsPerPage) + 1}
        onChange={onChangePage}
        page={page + 1}
        size="small"
        renderItem={item => (
          <PaginationItem
            classes={{
              page: classes.paginationItem,
              selected: classes.selected,
            }}
            {...item}
          />
        )}
      />
      <Box className={classes.boxAjust}>
        <Button
          disableRipple
          onClick={onOpenSettingStaff}
          startIcon={<SettingsIcon />}
          classes={{ root: classes.inviteButton, startIcon: classes.startIcon }}
        >
          {getLabel(LangConstant.TXT_STAFF_SETTING)}
        </Button>
        <Button
          disableRipple
          onClick={onOpenAdjust}
          startIcon={<EditTableIcon />}
          classes={{ root: classes.inviteButton, startIcon: classes.startIcon }}
        >
          {getLabel(LangConstant.TXT_ADJUST_DISPLAY_COLUMN)}
        </Button>
      </Box>
      {openSettingStaff && <StaffSetting open={openSettingStaff} setOpen={setOpenSettingStaff} />}
      <DialogAdjust
        titleName={getLabel(LangConstant.TXT_ADJUST_DISPLAY_COLUMN)}
        open={showColumn}
        displayColumn={displayColumn}
        setDisplayColumn={setDisplayColumn}
        setOpen={setShowColumn}
        headData={headData}
        showDefault={showDefault}
      />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  paginationParent: {
    display: "flex",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      display: "inline-block",
    },
  },
  tablePagination: {
    marginTop: 15,
    display: "flex",
    textAlign: "center",
    color: theme.palette.grey[600],
    "@media only screen and (max-width: 600px)": {
      marginTop: 0,
    },
  },
  selectRoot: {
    display: "none",
    paddingLeft: 0,
  },
  toolbar: {
    paddingLeft: 0,
  },
  caption: {
    paddingBottom: 4,
  },
  actions: {
    display: "none",
  },
  paginationTypo: {
    marginLeft: 10,
  },
  paginationItem: {
    fontSize: "12px !important",
  },
  selected: {
    color: "#447aff",
    backgroundColor: "unset !important",
  },
  boxAjust: {
    "& > *": {
      margin: theme.spacing(3),
    },
    marginLeft: "auto",
    marginRight: 0,
    marginTop: 15,
    display: "flex",
  },
  inviteButton: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    fontSize: 12,
    color: "#447aff",
    minWidth: 30,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  startIcon: {
    "& > *:first-child": {
      fontSize: 15,
    },
  },
}));

export default memo(PaginationFooter);
