import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { Box, Button, Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close, Star, StarOutline } from "@material-ui/icons";
import clsx from "clsx";
import StringFormat from "string-format";
import { useTranslation } from "react-i18next";
import RatingAction from "redux/rating.redux";
import { AppConstant, LangConstant, PathConstant } from "const";
import { getCommonKey } from "utils";
import { RatingItem } from "pages/MnReview/components";
import { TablePagination } from "components/table";

const ItemRatingDetailDialog = ({ isShow, onClose, data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_RATING_MANAGEMENT);

  const shopInfo = useSelector(state => state.shopInfoRedux.data, shallowEqual);
  const ratingSummary = useSelector(state => state.ratingRedux.ratingSummary, shallowEqual);
  const ratingListReduxData = useSelector(state => state.ratingRedux.ratingList, shallowEqual);
  const paginationReduxData = useSelector(state => state.ratingRedux.pagination, shallowEqual);

  const [filterObject, setFilterObject] = useState({
    score: null,
    byReport: false,
  });
  const [page, setPage] = useState(paginationReduxData.page || 1);

  const onFilterByReport = () => {
    setFilterObject({
      ...filterObject,
      byReport: !filterObject.byReport,
    });
  };

  const onChangeFilterByScore = score => {
    setFilterObject({
      ...filterObject,
      score: score,
    });
  };

  const onChangePage = page => {
    setPage(page);
  };

  useEffect(() => {
    if (isShow) {
      if (shopInfo && shopInfo.id) {
        let shopId = shopInfo.id;
        dispatch(
          RatingAction.getRatingItemSummary({
            itemUid: data?.uid || "",
            shopId: shopId,
          }),
        );
      }
    }
  }, [isShow]);

  useEffect(() => {
    if (isShow) {
      if (shopInfo && shopInfo.id) {
        let shopId = shopInfo.id;
        let formattedItemUid = [];
        formattedItemUid.push(data?.uid || "");
        dispatch(
          RatingAction.getRatingItem({
            itemUid: formattedItemUid,
            page: page,
            size: 10,
            isReported: Number(filterObject.byReport),
            score: filterObject.score,
            shopId: shopId,
          }),
        );
      }
    }
  }, [filterObject, page, isShow]);

  useEffect(() => {
    if (paginationReduxData.page !== page) setPage(paginationReduxData.page);
  }, [paginationReduxData]);

  return (
    <Dialog
      open={isShow}
      fullScreen
      classes={{
        paper: classes.paperRoot,
      }}
    >
      <Box className={classes.dialogTitle}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.dialogContent}>
        <Typography variant="h5" className={classes.titleHeader}>
          {getLabel(LangConstant.TXT_RATING_FROM_CONSUMER)}
        </Typography>

        <Link className={clsx(classes.toRatingScreen, "medium-md-txt")} to={PathConstant.MN_REVIEW}>
          {getLabel(LangConstant.TXT_SEE_ALL_RATING)}
        </Link>

        <Box className={classes.ratingScoreAndReportFilter}>
          <Box className={classes.scoreAndTotalRate}>
            <Typography variant="h2" className={classes.averageScore}>
              {ratingSummary.averageScore || 0}
            </Typography>

            <Rating
              emptySymbol={<StarOutline className={classes.emptyStar} />}
              fullSymbol={<Star className={classes.fullStar} />}
              readonly={true}
              initialRating={ratingSummary.averageScore || 0}
              className={classes.ratingScore}
            />
            <Typography className={clsx(classes.totalRatingTime, "medium-md-txt")}>
              {StringFormat(getLabel(LangConstant.FM_TOTAL_RATING_AMOUNT), ratingSummary.totalRating || 0)}
            </Typography>
          </Box>
          <Button
            variant="contained"
            className={clsx(
              classes.filterByReport,
              filterObject.byReport ? classes.filterByReportActive : classes.filterByReportInactive,
            )}
            onClick={onFilterByReport}
          >
            {getLabel(LangConstant.TXT_VIOLATION_REPORT)}
          </Button>
        </Box>

        <Box className={classes.filterByScoreContainer}>
          <Button
            className={clsx(classes.filterByScoreButton, "medium-lg-txt", !filterObject.score && classes.selectedScore)}
            onClick={() => onChangeFilterByScore(null)}
          >
            {getLabel(getCommonKey(LangConstant.TXT_ALL))}
          </Button>
          {AppConstant.RATING_SCORE_ARRAY.map((score, index) => (
            <Button
              key={index}
              className={clsx(
                classes.filterByScoreButton,
                score === filterObject.score && classes.selectedScore,
                "medium-lg-txt",
              )}
              onClick={() => onChangeFilterByScore(score)}
            >
              {StringFormat(getLabel(LangConstant.FM_RATING_SCORE), score)}
            </Button>
          ))}
        </Box>

        {ratingListReduxData.length > 0 && (
          <Box className={classes.ratingList}>
            {ratingListReduxData.map(item => (
              <RatingItem key={item.uid} data={item} />
            ))}
            <TablePagination
              page={page}
              rowsPerPage={paginationReduxData.size}
              total={paginationReduxData.total}
              onChange={onChangePage}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

ItemRatingDetailDialog.propTypes = {
  isShow: PropTypes.bool,

  onClose: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: "flex",
    position: "relative",
  },

  closeButton: {
    position: "absolute",
    right: 36,
  },

  paperRoot: {
    paddingTop: 36,
  },

  dialogContent: {
    margin: "0 263px",
    paddingTop: 14,
    [theme.breakpoints.down("md")]: {
      margin: "0 120px",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "0 60px",
    },

    [theme.breakpoints.down("xs")]: {
      margin: "0 10px",
    },
  },

  titleHeader: {
    marginBottom: 16,
  },

  toRatingScreen: {
    padding: 0,
    color: theme.palette.text.link,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },

  ratingScoreAndReportFilter: {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  averageScore: {
    fontSize: 36,
    lineHeight: "50px",
  },

  emptyStar: {
    color: "#FEBA40",
  },

  fullStar: {
    color: "#FEBA40",
  },

  ratingScore: {
    minWidth: "max-content",
    margin: "0 16px",
  },

  scoreAndTotalRate: {
    display: "flex",
    alignItems: "center",
  },

  filterByReport: {
    borderRadius: 4,
    border: "1px solid #D4D5D8",
    textTransform: "none",
    padding: 0,
    height: 30,
    width: 120,
    fontSize: 13,
    fontWeight: 500,
    minHeight: "unset",
  },

  filterByReportInactive: {
    backgroundColor: theme.palette.white,
  },

  filterByReportActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },

  filterByScoreContainer: {
    borderBottom: "1px solid " + theme.palette.grey[200],
    marginTop: 10,
  },

  filterByScoreButton: {
    borderRadius: 0,
    textTransform: "none",
    color: theme.palette.grey[400],
    borderBottom: "2px solid transparent",
  },

  selectedScore: {
    color: theme.palette.primary.main,
    borderBottom: "2px solid " + theme.palette.primary.main,
  },
}));

export default ItemRatingDetailDialog;
