import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
  Grid,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StaffAction from "redux/staff.redux";
import { LangConstant } from "const";

const timeSlots = Array.from(new Array(24)).map((_, index) => `${index}`);

const minusSlots = Array.from(new Array(60)).map((_, index) => `${index}`);

export default function DialogStaffWorkingShift({ isShow, onSubmit, onClose, data }) {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const workingShiftFetch = useSelector(state => state.staffRedux.isFetching);
  const dispatch = useDispatch();
  const [values, setValues] = useState(
    data
      ? {
          startHours: data.startHours,
          startMinute: data.startMinute,
          endHours: data.endHours,
          endMinute: data.endMinute,
          name: data.name,
        }
      : DEFAULT_VALUE,
  );
  const handlerSubmit = event => {
    event.preventDefault();
    if (
      !workingShiftFetch &&
      values.name !== "" &&
      values.startHours != null &&
      values.startMinute != null &&
      values.endHours != null &&
      values.endMinute != null
    ) {
      let startTime = Math.floor(
        new Date(Date.UTC(1970, 1, 1, values.startHours, values.startMinute)).getTime() / 1000,
      );
      let endTime = Math.floor(new Date(Date.UTC(1970, 1, 1, values.endHours, values.endMinute)).getTime() / 1000);
      if (data) {
        dispatch(
          StaffAction.updateWorkingShift({ ...data, startTime: startTime, endTime: endTime, name: values.name }),
        );
      } else {
        dispatch(StaffAction.requestPostWorkingShift({ startTime: startTime, endTime: endTime, name: values.name }));
      }

      onSubmit();
      setValues(DEFAULT_VALUE);
    }
  };
  const onChange = (event, value, name) => {
    setValues({ ...values, [name]: value });
  };

  const onCloseDialog = () => {
    setValues(DEFAULT_VALUE);
    onClose();
  };

  useEffect(() => {
    setValues(
      data
        ? {
            startHours: data.startHours,
            startMinute: data.startMinute,
            endHours: data.endHours,
            endMinute: data.endMinute,
            name: data.name,
          }
        : DEFAULT_VALUE,
    );
  }, [data]);

  return (
    <Dialog open={isShow} maxWidth="sm" onClose={onCloseDialog}>
      <form onSubmit={handlerSubmit}>
        <DialogTitle id="customized-dialog-title" className={classes.header}>
          <IconButton className={classes.closeItem} onClick={onCloseDialog}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <Typography variant="body1" className={classes.typoHeader}>
            {data !== null
              ? getLabel(LangConstant.TXT_UPDATE_WORK_TIME)
              : getLabel(LangConstant.T_CREATE_WORKING_SHIFT_STAFF_TITLE)}
          </Typography>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Grid container>
            <Grid container direction="row" justify="flex-start">
              <Grid>
                <Grid item>
                  <Typography variant="body2" component="p" className={classes.categoryTypo}>
                    {getLabel(LangConstant.T_CREATE_WORKING_SHIFT_STAFF_START)}
                  </Typography>
                </Grid>
                <Grid container direction="row" justify="flex-start">
                  <Autocomplete
                    id="startHours"
                    name="startHours"
                    disableClearable={true}
                    size="small"
                    options={timeSlots}
                    value={values.startHours}
                    type="input"
                    required={true}
                    onChange={(event, newValue) => onChange(event, newValue, "startHours")}
                    style={{ width: "75px" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required={true}
                        type="input"
                        InputProps={{
                          ...params.InputProps,
                          className: classes.input,
                        }}
                        placeholder={getLabel(LangConstant.L_CREATE_WORKING_SHIFT_STAFF_HOURS)}
                        variant="outlined"
                      />
                    )}
                  />
                  <Autocomplete
                    id="startMinute"
                    name="startMinute"
                    value={values.startMinute}
                    onChange={(event, newValue) => onChange(event, newValue, "startMinute")}
                    disableClearable={true}
                    type="input"
                    required={true}
                    size="small"
                    options={minusSlots}
                    style={{ width: "83px", fontSize: "14px", marginRight: "16px" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required={true}
                        type="input"
                        size={"small"}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.input,
                        }}
                        className={classes.input}
                        placeholder={getLabel(LangConstant.L_CREATE_WORKING_SHIFT_STAFF_MINUTE)}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid>
                <Typography variant="body2" component="p" className={classes.categoryTypo}>
                  {getLabel(LangConstant.T_CREATE_WORKING_SHIFT_STAFF_END)}
                </Typography>
                <Grid container direction="row">
                  <Autocomplete
                    id="endHours"
                    name="endHours"
                    type="input"
                    required={true}
                    value={values.endHours}
                    onChange={(event, newValue) => onChange(event, newValue, "endHours")}
                    disableClearable={true}
                    size="small"
                    options={timeSlots}
                    style={{ width: "75px" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required={true}
                        type="input"
                        InputProps={{
                          ...params.InputProps,
                          className: classes.input,
                        }}
                        placeholder={getLabel(LangConstant.L_CREATE_WORKING_SHIFT_STAFF_HOURS)}
                        variant="outlined"
                      />
                    )}
                  />
                  <Autocomplete
                    id="endMinute"
                    name="endMinute"
                    type="input"
                    required={true}
                    disableClearable={true}
                    size="small"
                    options={minusSlots}
                    value={values.endMinute}
                    onChange={(event, newValue) => onChange(event, newValue, "endMinute")}
                    style={{ width: "83px", fontSize: "14px", marginRight: "16px" }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required={true}
                        type="input"
                        InputProps={{
                          ...params.InputProps,
                          className: classes.input,
                        }}
                        placeholder={getLabel(LangConstant.L_CREATE_WORKING_SHIFT_STAFF_MINUTE)}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid>
                <Typography variant="body2" component="p" className={classes.categoryTypo}>
                  {getLabel(LangConstant.T_CREATE_WORKING_SHIFT_STAFF_NAME)}
                </Typography>
                <TextField
                  variant="outlined"
                  type="input"
                  required={true}
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={event => onChange(event, event.target.value, "name")}
                  placeholder={getLabel(LangConstant.L_CREATE_WORKING_SHIFT_STAFF_NAME)}
                  InputProps={{
                    className: classes.input,
                  }}
                  style={{ maxWidth: "200px", fontSize: "14px" }}
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button type="submit" className={classes.button} autoFocus variant="contained" color="primary">
            {data !== null ? getLabel(LangConstant.TXT_UPDATE) : getLabel(LangConstant.T_CREATE_POSITION_STAFF_ADD)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const DEFAULT_VALUE = { startHours: null, startMinute: null, endHours: null, endMinute: null, name: "" };

const useStyles = makeStyles(theme => ({
  header: {
    "@media only screen and (min-width: 960px)": {
      maxWidth: "600px !important",
      minWidth: "600px !important",
    },
    width: "100%",
    backgroundColor: "#65b39d",
    height: "40px",
    color: "#ffffff",
    padding: 0,
    position: "relative",
  },
  closeItem: {
    position: "absolute",
    right: "10px",
    width: "40px",
    height: "40px",
    zIndex: 100000000,
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  typoHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    color: "#ffffff",
    position: "relative",
  },
  input: {
    fontSize: "14px",
  },
  categoryTypo: {
    marginTop: "30px",
    marginBottom: "20px",
    textAlign: "center",
  },
  button: {
    height: "30px",
    boxShadow: "none",
    borderRadius: "20px",
    textTransform: "none",
    minHeight: "20px",
    color: "#ffffff",
    borderColor: "#ef5845",
    backgroundColor: "#ef5845",
    paddingLeft: "24px",
    paddingRight: "24px",
    fontSize: "12px",
  },
  footer: {
    justifyContent: "center",
    padding: 0,
    marginBottom: "20px",
    marginTop: "19px",
  },
  content: {
    zIndex: theme.zIndex.appBar + 100,
    padding: "0px 24px",
    paddingBottom: "30px",
    justifyContent: "center",
    fontSize: "14px",
  },
}));
DialogStaffWorkingShift.propTypes = {
  isShow: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
};
DialogStaffWorkingShift.defaultProps = {
  data: null,
};
