import React, { memo, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import AccountInfo from "./AccountInfo";
import GroupButtonWithBadge from "./GroupButtonWithBadge";
import { HEIGHT_APP_BAR } from "./index";
import { HEADER_CONDITION_OBJ } from "../CustomAppBar";

const ButtonGroupMobile = ({ classes }) => {
  const defaultClasses = useStyles();
  const [headerButtonState, setHeaderButtonState] = useState(HEADER_CONDITION_OBJ);

  return (
    <Box className={clsx(defaultClasses.buttonGroupRoot, classes?.root)}>
      <GroupButtonWithBadge headerButtonState={headerButtonState} setHeaderButtonState={setHeaderButtonState} />
      <AccountInfo isShowPopup={headerButtonState.isAccountInfo} setHeaderButtonState={setHeaderButtonState} />
    </Box>
  );
};

export default memo(ButtonGroupMobile);

const useStyles = makeStyles(() => ({
  buttonGroupRoot: {
    minWidth: "100%",
    height: HEIGHT_APP_BAR,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
