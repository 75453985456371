import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, makeStyles, TableRow, Typography } from "@material-ui/core";
import { format as DateFormat } from "date-fns";
import clsx from "clsx";
import { CellBody } from "components";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, getStatusColor } from "utils";
import { convertTimeStampToDate, getRealUseTimeByHour } from "utils/date";
import ReservationAction from "redux/reservation.redux";

const BookingHistoryTableRow = props => {
  const { dataShow, setOpen, showDataLength, checkedBookingList, setCheckedBookingList, setIsCheckedAll } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);

  const creatorType = (state, frontText) => {
    if (state) {
      return (
        <Box className={classes.creatorCell}>
          <Typography className={classes.creatorName}>{frontText}</Typography>
          <Typography className={classes.creatorTag}>&nbsp;(S)</Typography>
        </Box>
      );
    } else {
      return (
        <Box className={classes.creatorCell}>
          <Typography className={classes.creatorName}>{frontText}</Typography>
          <Typography className={classes.creatorTag}>&nbsp;(C)</Typography>
        </Box>
      );
    }
  };

  const onShowDialog = data => {
    dispatch(ReservationAction.getReservation({ uid: data.uid }));
    setOpen(true);
  };
  const onCheckBooking = uid => {
    let tmpUidList = [...checkedBookingList];
    if (tmpUidList.includes(uid)) {
      tmpUidList = tmpUidList.filter(checkedUid => checkedUid !== uid);
    } else {
      tmpUidList.push(uid);
    }
    setIsCheckedAll(tmpUidList.length === showDataLength);
    setCheckedBookingList(tmpUidList);
  };
  let placeDetail =
    dataShow?.reservationDetails?.filter(detail => detail.type === AppConstant.TYPE_RESERVATION_DETAILS.place) || [];

  return (
    <TableRow className={classes.rootTableRow}>
      <CellBody
        className={classes.firstTableCellHead}
        cellData={
          <Box className={classes.codeCell}>
            <Checkbox
              checked={checkedBookingList.includes(dataShow.uid || "")}
              onChange={() => onCheckBooking(dataShow.uid || "")}
              color="default"
              classes={{
                root: classes.checkboxBasis,
                checked: clsx(classes.checkboxChecked, classes.checkboxBasis),
              }}
            />
            <Typography className={classes.bookingCode} onClick={() => onShowDialog(dataShow)}>
              {dataShow.code || ""}
            </Typography>
          </Box>
        }
      />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={convertTimeStampToDate(dataShow.created || 0, AppConstant.FM_HH_MM_DD_MM)}
      />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={creatorType(Boolean(dataShow.createdByStaffUid), dataShow.name || "")}
      />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={
          placeDetail &&
          placeDetail.map((place, index) => (
            <Typography key={index} className={classes.placeItemList}>
              {place.categoryName || place.itemName + (Boolean(index < placeDetail.length - 1) ? "\n" : "")}
            </Typography>
          ))
        }
      />
      <CellBody className={classes.cellBodyRoot} cellData={dataShow?.consumer?.total || 0} />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={DateFormat(
          convertTimeStampToDate(dataShow.realityStartTime || dataShow.startTime || 0),
          AppConstant.FM_HH_MM_DD_MM,
        )}
      />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={
          dataShow.realityEndTime &&
          dataShow.realityStartTime &&
          getRealUseTimeByHour(
            convertTimeStampToDate(dataShow.realityStartTime),
            convertTimeStampToDate(dataShow.realityEndTime),
            getLabel,
          )
        }
      />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={
          <Typography
            className={classes.statusText}
            style={{
              color: getStatusColor(dataShow.status || 0),
            }}
          >
            {getLabel(getCommonKey(LangConstant.ARR_STATUS_FILTER[dataShow.status || 0]))}
          </Typography>
        }
      />
    </TableRow>
  );
};

BookingHistoryTableRow.propTypes = {
  dataShow: PropTypes.object,

  showDataLength: PropTypes.number,

  checkedBookingList: PropTypes.array,

  setOpen: PropTypes.func,
  setCheckedBookingList: PropTypes.func,
  setIsCheckedAll: PropTypes.func,
};
BookingHistoryTableRow.defaultProps = {};

export default BookingHistoryTableRow;

const useStyles = makeStyles(theme => ({
  rootTableRow: {
    height: 40,
  },

  tableContainer: {
    border: "1px solid #ebeced",
    "&>table": {
      overflowX: "auto",
      minWidth: 800,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cellHeadRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  cellBodyRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[500],
    fontSize: 13,
    fontWeight: "normal",
    textAlign: "center",
    "&>div": {
      justifyContent: "center",
    },
  },
  placeItemList: {
    fontSize: 13,
    fontWeight: "normal",
  },
  bookingCode: {
    width: "100%",
    color: theme.palette.text.link,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: 13,
  },
  creatorCell: {
    display: "flex",
  },
  creatorTag: {
    minWidth: "fit-content",
    fontSize: 13,
    fontWeight: 500,
    color: "#65b39d",
  },
  statusText: {
    width: "100%",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    padding: "2px 5px",
    fontWeight: 600,
    lineHeight: 1.69,
    objectFit: "contain",
  },
  creatorName: {
    color: theme.palette.grey[500],
    fontSize: 13,
    fontWeight: "normal",
  },
  firstTableCellHead: {
    alignItems: "center",
    textAlign: "center",
  },
  codeCell: {
    display: "flex",
    alignItems: "center",
  },
  checkboxBasis: {
    width: 15,
    height: 15,
    marginRight: 25,
    " &>span>svg": {
      width: 20,
      height: 20,
    },
  },
  tableHeadCheckBox: {
    color: "#4b4d53",
  },
  checkboxChecked: {
    color: "#447aff",
  },
  tableHeadLabel: {
    width: "100%",
    textAlign: "center",
  },
}));
