export default {
  TXT_SERVICE_LOWER_CASE: "dịch vụ",
  TXT_PRODUCT_LOWER_CASE: "hàng hóa",
  TXT_PRETAX_PRICE: "Giá trước thuế",
  TXT_PRICE_AFTER_TAX: "Giá sau thuế",

  L_MAIN_SERVICE: "Dịch vụ chính",
  L_MAIN_PRODUCT: "Hàng hóa chính",
  L_MAIN_COMBO: "Combo",
  L_ATTACHED_SERVICE: "Dịch vụ đi kèm",
  L_ATTACHED_PRODUCT: "Hàng hóa đi kèm",
  L_All_SERVICE: "Tất cả dịch vụ",
  L_CATEGORY_SERVICE: "Danh mục dịch vụ",

  TXT_ADD_AMOUNT: "Số lượng",
  L_CREATE_SERVICE: "Tạo mới dịch vụ ",
  L_CREATE_PRODUCT: "Tạo mới hàng hoá chính",
  L_CATEGORY_GOODS: "Danh mục hàng hoá",

  TXT_CREATE_COMBO: "Tạo Combo",
  TXT_DETAIL_COMBO: "Chi tiết Combo",
  TXT_EDIT_COMBO: "Chỉnh sửa Combo",
  TXT_INFORMATION_BASIC: "Thông tin cơ bản",

  TXT_NAME_COMBO: "Tên Combo",
  TXT_CHOOSE_PRODUCT: "Chọn sản phẩm cho Combo",
  TXT_ILLUSTRATION: "Hình ảnh minh họa",
  TXT_ILLUSTRATION_VIDEO: "Video minh họa",
  TXT_COMBO_DESCRIPTION: "Mô tả Combo",
  TXT_ADD_COMBO_DESCRIPTION: "Thêm mô tả cho Combo",
  TXT_UNIT_PRICE_AND_TIME_USED: "Đơn giá và thời gian sử dụng",
  TXT_SETTING_UNIT_PRICE_AND_TIME_USED: "Cài đặt đơn giá và thời gian sử dụng cho Combo",
  TXT_SETTING_UNIT_PRICE_USED_TIME: "Cài đặt đơn giá và thời gian sử dụng cho",
  TXT_PRICE_ACCORDING_TO: "Tính giá theo",
  TXT_UNIT_PRICE: "Đơn giá",
  TXT_TAX_AND_PRICE: "Thuế và giá bán",
  TXT_SETTING_TAX_AND_PRICE: "Cài đặt thuế áp dụng và giá bán cho",
  TXT_SETTING_TAX_AND_PRICE_COMBO: "Cài đặt thuế áp dụng và giá bán cho Combo",
  TXT_TAX: "Thuế",
  TXT_COST_PRICE: "Giá nhập",
  TXT_CHOOSE_TAX: "Chọn thuế",
  TXT_NO_TAX: "Không áp dụng",
  FN_DELETE_COMBO_ITEM_TITLE: name => `Xóa Combo "${name}"?`,
  FN_DELETE_COMBO_ITEM_CONTENT: name => `Bạn có chắc muốn xóa Combo "${name}" khỏi danh sách Combo không?`,
  TXT_NOTIFY_INPUT_NAME_COMBO: "Bạn chưa nhập tên Combo!",
  TXT_CHOOSE_TYPE_CREATE_ACCOMPANIED: "Chọn kiểu tạo dịch vụ đi kèm",
  TXT_ADD_ACCOMPANIED_FROM_MAIN: "Thêm dịch vụ đi kèm từ danh sách tất cả dịch vụ chính",
  TXT_ADD_ACCOMPANIED_FROM_ALL_ATTACHED: "Thêm dịch vụ đi kèm từ danh sách tất cả dịch vụ đi kèm",
  TXT_CREATE_SERVICE_ACCOMPANIED: "Tạo dịch vụ đi kèm",
  TXT_CREATE_NEW_SERVICE_ACCOMPANIED: "Tạo mới dịch vụ đi kèm",
  TXT_DETAIL_SERVICE_ACCOMPANIED: "Chi tiết dịch vụ đi kèm",
  TXT_DETAIL_MAIN_SERVICE: "Chi tiết",
  TXT_EDIT_SERVICE_ACCOMPANIED: "Chỉnh sửa dịch vụ đi kèm",
  TXT_NAME_SERVICE: "Tên dịch vụ",
  FN_NOTIFY_INPUT_NAME: name => `Bạn chưa nhập tên ${name}!`,
  TXT_SERVICE_DESCRIPTION: "Mô tả dịch vụ",
  TXT_SETTING_SERVICE_ACCOMPANIED: "Cài đặt những loại dịch vụ sẽ đi kèm với dịch vụ này",
  POWER: "Nguồn lực",
  TXT_SETTING_POWER_DESCRIPTION: " Cài đặt những nguồn lực mà dịch vụ này sẽ sử dụng",
  TXT_NAME_PRODUCT: "Tên hàng hoá",
  TXT_GOODS_DESCRIPTION: "Mô tả hàng hóa",
  TXT_COSTS_AND_INVENTORY: "Chi phí hàng hoá",
  TXT_SETTING_COSTS_AND_INVENTORY_GOODS: "Cài đặt chi phí cho hàng hoá",
  TXT_WARE_HOUSE: "Kho hàng",
  TXT_UNIT_PRICE_AND_TAX_AND_PRICE: "Đơn giá, thuế và giá bán",
  TXT_SETTING_TXT_UNIT_PRICE_AND_TAX_AND_PRICE: "Cài đặt đơn giá, thuế áp dụng và giá bán cho hàng hoá",
  TXT_GOODS_INCLUDED: "Hàng hóa đi kèm",
  TXT_GOODS_INCLUDED_SERVICE: "Cài đặt những loại hàng hóa sẽ đi kèm với dịch vụ này",
  FN_DELETE_GOODS_ITEM_TITLE: name => `Xóa hàng hóa "${name}" ?`,
  FN_DELETE_GOODS_ITEM_CONTENT: name => `Bạn có chắc muốn xoá hàng hóa "${name}" khỏi danh sách hàng hóa chính không?`,
  FN_DELETE_GOODS_CATEGORY_TITLE: name => `Xóa danh mục "${name}" ?`,
  FN_DELETE_GOODS_CATEGORY_CONTENT: name =>
    `Bạn có chắc muốn xoá danh mục "${name}" khỏi danh sách danh mục chính không?`,
  FN_DELETE_GOODS_SUB_ITEM_CONTENT: name =>
    `Bạn có chắc muốn xoá hàng hóa "${name}" khỏi danh sách hàng hóa đi kèm không?`,
  TXT_NOTIFY_INPUT_NAME_GOOD: "Bạn vui lòng điền đầy đủ thông tin!",
  L_All_PRODUCT: "Tất cả hàng hóa",
  L_CATEGORY_PRODUCT: "Danh mục hàng hóa",
  TXT_ADD_CATEGORY_GOOD: " Danh mục",
  TXT_CATEGORY_GOOD_HEADER: "Chọn kiểu thêm hàng hoá vào danh mục",
  TXT_CATEGORY_GOOD_ADD: "Thêm từ danh sách tất cả hàng hoá",
  TXT_CATEGORY_GOOD_CREATE: "Tạo mới hàng hoá",
  TXT_ADD_ACCOMPANIED_FROM_MAIN_GOOD: "Thêm hàng hoá đi kèm từ danh sách tất cả hàng hoá chính",
  TXT_ADD_PRODUCT_CATEGORY_FROM_MAIN_GOOD: "Thêm hàng hoá vào danh mục  ",
  TXT_EDIT_CATEGORY_SUB_CATEGORY: "Sửa tên",
  TXT_REMOVE_CATEGORY_SUB_CATEGORY: "Xóa danh mục",
  TXT_ADD_PRODUCT: "Thêm sản phẩm",
  TXT_CREATE_PRODUCT: "Tạo hàng hóa",
  TXT_CATEGORY_LOWER_CASE: "danh mục",
  TXT_EDIT_SERVICE: "Chỉnh sửa dịch vụ",
  TXT_DETAIL_SERVICE: "Chi tiết dịch vụ",
  TXT_WARNING_EDIT_SERVICE: "Đi tới dịch vụ chính để chỉnh sửa",
  FN_DELETE_SERVICE_ITEM_TITLE: name => `Xóa dịch vụ "${name}"?`,
  FN_DELETE_ACCOMPANIED_SERVICE_ITEM_CONTENT: name =>
    `Bạn có chắc muốn xóa dịch vụ "${name}" khỏi danh sách dịch vụ đi kèm không?`,
  FN_DELETE_SERVICE_CATEGORY_CONTENT: name =>
    `Bạn có chắc muốn xoá danh mục "${name}" khỏi danh sách danh mục dịch vụ đi kèm?`,
  FN_ADD_SERVICE_TO_CATEGORY: name => `Thêm dịch vụ vào danh mục "${name}"`,

  T_CREATE_MAIN_SERVICE: "Tạo mới dịch vụ chính",
  TXT_ADD_DESCRIPTION: "Thêm mô tả cho",
  L_CHOOSE_SERVICE_CATEGORY: "Chọn danh mục dịch vụ",
  L_CHOOSE_ATTACHED_SERVICE: "Chọn dịch vụ đi kèm",
  L_POWER_SOURCE: "Nguồn lực",
  L_LOCATION: "Địa điểm",
  L_CHOOSE_LOCATION: "Chọn địa điểm",
  L_LABOR: "Nhân công",
  L_CHOOSE_LABOR: "Chọn nhân công",
  TXT_NOTIFY_WARNING: "Hành động chỉnh sửa này của bạn sẽ cập nhật lại thông tin của hàng hóa này trên toàn hệ thống",
  TXT_NOTIFY_WARNING_COMBO:
    "Hành động chỉnh sửa này của bạn sẽ cập nhật lại thông tin của combo này trên toàn hệ thống",
  TXT_NOTIFY_WARNING_SERVICE:
    "Hành động chỉnh sửa này của bạn sẽ cập nhật lại thông tin của dịch vụ này trên toàn hệ thống",
  TXT_CURRENCY: "đ",
  TXT_CREATE_ACCOMPANIED_PRODUCT_HEADER: "Chọn kiểu tạo hàng hoá đi kèm",
  TXT_CREATE_ACCOMPANIED_PRODUCT_ADD: "Thêm hàng hoá đi kèm từ danh sách tất cả hàng hoá chính",
  TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE: "Tạo hàng hoá đi kèm",
  TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE_DIALOG: "Tạo mới hàng hoá đi kèm",
  TXT_CREATE_ACCOMPANIED_PRODUCT_EDIT_DIALOG: "Chỉnh sửa hàng hoá đi kèm",
  TXT_CREATE_ACCOMPANIED_PRODUCT_VIEW_DIALOG: "Chi tiết hàng hoá đi kèm",
  TXT_CREATE_ACCOMPANIED_PRODUCT_ADD_DIALOG: "Thêm hàng hoá đi kèm từ danh sách tất cả hàng hoá chính",
  TXT_EDIT_GOOD: "Chỉnh sửa hàng hóa",
  TXT_DETAIL_GOOD: "Chi tiết hàng hóa",
  TXT_ADD_PRODUCT_CATEGORY_FROM_ACCOMPANIED_GOOD: "Thêm hàng hoá đi kèm vào danh mục  ",
  FN_DELETE_MAIN_SERVICE_TITLE: name => `Xóa dịch vụ "${name}"?`,
  FN_DELETE_MAIN_SERVICE_CONTENT: name => `Bạn có chắc muốn xóa dịch vụ "${name}" khỏi danh sách dịch vụ chính không?`,
  FN_DELETE_CATEGORY_SERVICE_TITLE: name => `Xóa danh mục "${name}"?`,
  FN_DELETE_CATEGORY_SERVICE_CONTENT: name =>
    `Bạn có chắc muốn xóa danh mục "${name}" khỏi danh sách danh mục dịch vụ không?`,
  TXT_CHOOSE_TYPE_ADD_SERVICE: "Chọn kiểu thêm dịch vụ",
  TXT_ADD_FROM_ALL_CATEGORY_SERVICE: "Thêm từ danh sách tất cả dịch vụ",
  TXT_CREATE_NEW: "Tạo mới",
  FN_ADD_TO_CATEGORY_SERVICE: name => `Thêm mới vào danh mục "${name}"`,
  TXT_WARNING_NAME_SERVICE: "Tên dịch vụ này đã tồn tại. Hãy tạo tên mới",
  TXT_WARNING_NAME_GOOD: "Tên hàng hóa này đã tồn tại. Hãy tạo tên mới",
  TXT_WARNING_NAME_COMBO: "Tên combo này đã tồn tại. Hãy tạo tên mới",
  TXT_DUPLICATE_NAME: "duplicate name",
  TXT_WARNING_CATEGORY: "Tên danh mục này đã tồn tại. Hãy tạo tên mới",
  FN_NOTICE_FILL: name => `Điền các thông tin cơ bản của ${name} mà bạn muốn tạo`,
  FN_REMOVE_FROM_CATEGORY: (type, name, categoryName) =>
    `Bạn có chắc chắn muốn gỡ bỏ ${type} ${name} khỏi danh mục ${type} "${categoryName}" không?`,
  FN_REMOVE: (type, name) => `Gỡ bỏ ${type} "${name}"?`,
  TXT_ADD_CATEGORY_PRODUCT: "Tạo danh mục",
  TXT_ORDER_MENU_SETUP: "Đặt hàng",
  TXT_COPY_FROM_BOOKING: "Sao chép Menu từ Menu đặt chỗ",
  TXT_COPY_TITLE_ORDER: "Sao chép Menu đặt hàng từ Menu đặt chỗ?",
  TXT_COPY_TITLE_ORDER_CONFIRM: "Bạn có chắc chắn muốn sao chép toàn bộ Menu đặt chỗ không?",

  TXT_CAN_NOT_FIND_VIDEO: "Không tìm thấy video",

  TXT_COPY_ITEM_LINK: "Sao chép đường dẫn sản phẩm",
  TXT_INFORMATION_SALES: "Thông tin bán hàng",
  TXT_FILL_IN_INFORMATION_SALES: "Điền các thông tin bán hàng cho dịch vụ mà bạn muốn tạo",
  TXT_FILL_IN_INFORMATION_SALES_COMBO: "Điền các thông tin bán hàng cho combo mà bạn muốn tạo",
  TXT_PRODUCT_CLASSIFICATION: "Phân loại hàng",
  TXT_ADD_GROUP: "Thêm nhóm",
  TXT_NAME_CLASSIFICATION_GROUP: "Tên nhóm phân loại",
  TXT_CLASSIFICATION: "Tên phân loại",
  TXT_ADD_CLASSIFICATION_SERVICE: "Thêm phân loại dịch vụ",
  TXT_TAX_APPLY: "Thuế áp dụng",
  TXT_LIST_OF_CATEGORIES_OF_GOOD: "Danh sách phân loại hàng",
  TXT_ADD_MASTER_PRODUCT: "Tạo",

  P_FILL_CLASSIFICATION_GROUP: "Nhập tên nhóm phân loại",
  P_FILL_CLASSIFICATION: "Nhập tên phân loại",
  P_VALUE_INPUT: "Nhập vào",

  FM_CLASSIFICATION_GROUP: "Nhóm phân loại {{ name }}",
  FM_ADD_NAME: "Chọn {{ name }}",
};
