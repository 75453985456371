import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Box, Paper, makeStyles, Checkbox, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, onDoNothing } from "utils";
import InputNote from "./components/InputNote";
import ReservationTime from "./components/ReservationTime/index.js";
import InputPeople from "./components/InputPeople";

const PreviewConsBookingPage = ({ data: previewData }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  //shop Option
  let customizedWelcome =
    previewData && Object.values(previewData).find(opt => opt.type === AppConstant.RESERVATION_SETTING_OPTION.note);

  let isEnablePeriodic =
    previewData &&
    Object.values(previewData).find(opt => opt.type === AppConstant.RESERVATION_SETTING_OPTION.periodic)?.publish;

  let consumerSetting =
    previewData && Object.values(previewData).find(opt => opt.type === AppConstant.RESERVATION_SETTING_OPTION.customer);

  let usingTimeSetting =
    previewData &&
    Object.values(previewData).find(opt => opt.type === AppConstant.RESERVATION_SETTING_OPTION.usingTime);

  return (
    <>
      <Grid className={classes.formRoot} container spacing={2}>
        <Paper className={classes.bookPaper}>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_RESERVATION_BOOKING)}</Typography>
          <Typography className={clsx(classes.subTitle, "regular-md-txt")}>
            {getLabel(LangConstant.TXT_BOOKING_TIME_SUB_TITLE)}
          </Typography>
          <Grid item xs={isMobile ? 12 : 8}>
            <InputPeople className={classes.inputPeople} consumerSetting={consumerSetting} />
            <ReservationTime usingTimeSetting={usingTimeSetting} onClosePeriodicForm={onDoNothing} />
          </Grid>
        </Paper>
        <Paper className={classes.bookPaper}>
          <Typography className={classes.title}>{getLabel(LangConstant.TXT_SERVICE_AND_PRODUCT)}</Typography>
          <Typography className={clsx(classes.subTitle, "regular-md-txt")}>
            {getLabel(LangConstant.TXT_SERVICE_AND_PRODUCT_PRE_ORDER_INSTRUCTION)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.openProductDialogBtn, "semiBold-md-txt")}
            onClick={onDoNothing}
            id={PRODUCT_SELECT_BUTTON}
          >
            {getLabel(getCommonKey(LangConstant.TXT_CHOOSE))}
          </Button>
        </Paper>
        {Boolean(isEnablePeriodic) && (
          <Paper className={classes.bookPaper}>
            <Box className={classes.periodicBoxTitle}>
              <Typography className={classes.title}>{getLabel(LangConstant.TXT_PERIODIC_BOOKING)}</Typography>
              <Checkbox checked={false} onChange={onDoNothing} disabled={true} color="primary" />
            </Box>
            <Typography className={clsx(classes.subTitle, "regular-md-txt")}>
              {getLabel(LangConstant.TXT_PERIODIC_BOX_SUB_TITLE)}
            </Typography>
          </Paper>
        )}
        <Paper className={classes.bookPaper}>
          <Typography className={classes.title}>{getLabel(LangConstant.L_RESERVATION_NOTE)}</Typography>
          <Box className={classes.customWelcomeText}>
            <Typography variant="body2">
              {customizedWelcome ? customizedWelcome.details?.guideNote?.content : ""}
            </Typography>
          </Box>
          <Grid
            item
            xs={8}
            style={{
              width: "100%",
            }}
          >
            <InputNote />
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <Box className={clsx("center-root", classes.submitBox)}>
            <Button
              className={classes.submitBtn}
              color="primary"
              variant="contained"
              size="large"
              onClick={onDoNothing}
            >
              {getLabel(LangConstant.TXT_CONTINUE)}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

PreviewConsBookingPage.propTypes = {
  goToNextStep: PropTypes.func,

  isMenu: PropTypes.bool,
};

PreviewConsBookingPage.defaultProps = {
  goToNextStep: onDoNothing,

  isMenu: false,
};

const PRODUCT_SELECT_BUTTON = "product-select-button";

export default memo(PreviewConsBookingPage);

const useStyles = makeStyles(theme => ({
  formRoot: {
    margin: 0,
    justifyContent: "center",
    width: "100%",
  },
  bookPaper: {
    boxShadow: "1px 0 4px 0 rgba(0, 0, 0, 0.16)",
    alignItems: "center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: 24,
    marginBottom: 15,
    paddingBottom: 35,
    [theme.breakpoints.down(600)]: {
      padding: "24px 5px",
    },
  },
  periodicBoxTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    width: "100%",
  },
  subTitle: {
    width: "100%",
    textAlign: "left",
    margin: "16px 0 36px 0",
  },
  inputPeople: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
  submitBtn: {
    borderRadius: 4,
    textTransform: "none",
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
  customWelcomeText: {
    margin: "15px 0 25px 0",
    width: "100%",
  },
  openProductDialogBtn: {
    width: 70,
    height: 30,
    minHeight: "unset",
    borderRadius: 2,
    textTransform: "none",
  },
  selectedProductsPreview: {
    width: "100%",
    marginTop: 25,
    "&>div": {
      width: "100%",
      "&>:first-child": {
        boxShadow: "unset",
      },
      "&>:nth-child(2)": {
        backgroundColor: "transparent",
      },
    },
  },
  submitBox: {
    marginTop: 30,
    marginBottom: 60,
    display: "flex",
    flexDirection: "column",
  },
}));
