import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestReportSummaryReservation: ["data"],
  createReportSummaryReservationSetting: ["data"],

  requestReportSummaryCustomer: ["data"],
  createReportSummaryCustomerSetting: ["data"],

  requestReportSummaryPlace: ["data"],
  createReportSummaryPlaceSetting: ["data"],

  requestReportSummaryService: ["data"],
  createReportSummaryServiceSetting: ["data"],

  reportDashboardSuccess: ["data"],
  reportDashboardFailure: ["data"],
});

export const ReportDashboardTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,

  summaryReservation: { data: [], setting: { details: [] } }, // structure: {data: [],  setting:{details, view_mode}}

  summaryCustomer: { data: [], setting: { details: [] } },

  summaryPlace: { data: [], setting: { details: [] } },

  summaryService: { data: [], setting: { details: [] } },
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, ...action };
};

export const reset = () => INITIAL_STATE;

export const requestSelectShop = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const requestInvitationAccept = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const setSummaryReservationSetting = (state = INITIAL_STATE, action) => {
  let newData = { ...state.summaryReservation };
  let selectedSettings = action.data ? action.data : {};
  newData.setting = { ...newData.setting, ...selectedSettings };

  return {
    ...state,
    summaryReservation: newData,
  };
};

export const setSummaryCustomerSetting = (state = INITIAL_STATE, action) => {
  let newData = { ...state.summaryCustomer };
  let selectedSettings = action.data ? action.data : {};
  newData.setting = { ...newData.setting, ...selectedSettings };

  return {
    ...state,
    summaryCustomer: newData,
  };
};

export const setSummaryPlaceSetting = (state = INITIAL_STATE, action) => {
  let newData = { ...state.summaryPlace };
  let selectedSettings = action.data ? action.data : {};
  newData.setting = { ...newData.setting, ...selectedSettings };

  return {
    ...state,
    summaryPlace: newData,
  };
};

export const setSummaryServiceSetting = (state = INITIAL_STATE, action) => {
  let newData = { ...state.summaryService };
  let selectedSettings = action.data ? action.data : {};
  newData.setting = { ...newData.setting, ...selectedSettings };

  return {
    ...state,
    summaryService: newData,
  };
};

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_REPORT_SUMMARY_RESERVATION]: request,
  [Types.CREATE_REPORT_SUMMARY_RESERVATION_SETTING]: setSummaryReservationSetting,

  [Types.REQUEST_REPORT_SUMMARY_CUSTOMER]: request,
  [Types.CREATE_REPORT_SUMMARY_CUSTOMER_SETTING]: setSummaryCustomerSetting,

  [Types.REQUEST_REPORT_SUMMARY_PLACE]: request,
  [Types.CREATE_REPORT_SUMMARY_PLACE_SETTING]: setSummaryPlaceSetting,

  [Types.REQUEST_REPORT_SUMMARY_SERVICE]: request,
  [Types.CREATE_REPORT_SUMMARY_SERVICE_SETTING]: setSummaryServiceSetting,

  [Types.REPORT_DASHBOARD_SUCCESS]: success,
  [Types.REPORT_DASHBOARD_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
