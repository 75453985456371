import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import { AppConstant, LangConstant } from "../../../../const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const CustomButton = ({ status, onClick, classes, ...otherProps }) => {
  const defaultClasses = useStyles();

  const { t: getLabel } = useTranslation();
  let bgClass = "";
  switch (status) {
    case AppConstant.STT_RESERVATION.pending:
      bgClass = defaultClasses.pending;
      break;
    case AppConstant.STT_RESERVATION.confirm:
      bgClass = defaultClasses.confirm;
      break;
    case AppConstant.STT_RESERVATION.seated:
      bgClass = defaultClasses.seated;
      break;
    case AppConstant.STT_RESERVATION.active:
      bgClass = defaultClasses.active;
      break;
    case AppConstant.STT_RESERVATION.finished:
      bgClass = defaultClasses.finished;
      break;
    case AppConstant.STT_RESERVATION.cancel:
      bgClass = defaultClasses.cancel;
      break;
    case AppConstant.STT_RESERVATION.reject:
      bgClass = defaultClasses.reject;
      break;
    case AppConstant.STT_RESERVATION.waitingPayment:
      bgClass = defaultClasses.waitingPayment;
      break;
    default:
      break;
  }

  const onEventClick = event => {
    if (Boolean(onClick)) onClick(event);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      classes={{
        ...classes,
        contained: !classes.contained ? defaultClasses.contained : classes.contained,
        label: !classes.label ? defaultClasses.label : classes.label,
        root: clsx(bgClass, classes.root),
      }}
      onClick={onEventClick}
      {...otherProps}
    >
      {getLabel(LangConstant.ARR_STATUS_RESERVATION[status - 1])}
    </Button>
  );
};

CustomButton.propTypes = {
  status: PropTypes.number.isRequired,
  classes: PropTypes.object,
  onClick: PropTypes.func,
};
CustomButton.defaultProps = { status: AppConstant.STT_RESERVATION.pending, classes: {} };

const useStyles = makeStyles(theme => ({
  pending: createColorStyle(theme.palette.reservation.pending),
  confirm: createColorStyle(theme.palette.reservation.confirm),
  seated: createColorStyle(theme.palette.reservation.seated),
  active: createColorStyle(theme.palette.reservation.active),
  done: createColorStyle(theme.palette.reservation.done),
  cancel: createColorStyle(theme.palette.reservation.cancel),
  reject: createColorStyle(theme.palette.reservation.reject),
  waitingPayment: createColorStyle(theme.palette.reservation.waitingPayment),
  contained: {
    padding: "0 9px",
    borderRadius: "2px",
    minHeight: "20px",
  },
  label: {
    fontSize: "8px",
    letterSpacing: "0.62px",
    color: "white",
  },
}));

const createColorStyle = color => ({
  "&,&:hover": {
    background: color,
  },
});

export default memo(CustomButton);
