import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Star } from "@material-ui/icons";
import { makeStyles, Button, Paper, Grid, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant, PathConstant } from "const";
import { getCommonKey, getNSKey, validatePhone } from "utils";
import CustomInfo from "components/CustomInfo";

const ShopInfo = props => {
  const { shopData, onSubmit } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [isEdited, setIsEdited] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [detail, setDetail] = useState(shopData || {});

  const onChangeDetail = event => {
    setDetail({ ...detail, [event.target.name]: event.target.value });
    setIsEdited(true);
  };

  const onSave = newData => {
    if (!validatePhone(newData.phoneNumber)) {
      alert(getLabel(LangConstant.TXT_INVALID_PHONE_ALERT));
    } else {
      setIsEdited(false);
      setEnableEdit(false);
      onSubmit(newData);
    }
  };

  const onEnableEdit = () => {
    setEnableEdit(true);
  };

  const onDisableEdit = () => {
    if (!isEdited) {
      setEnableEdit(false);
    }
  };

  useEffect(() => {
    if (shopData && shopData != detail) {
      setDetail(shopData);
    }
  }, [shopData]);

  return (
    <Paper elevation={1} square className={classes.paper}>
      <Typography className="bold-lg-txt" classes={{ root: classes.header }}>
        {getLabel(LangConstant.TXT_BASIC_INFO_HEADER)}
      </Typography>
      <Grid container spacing={1} className={classes.gridContainer}>
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_BUSINESS_CODE)}
          value={detail && detail.businessId ? detail.businessId.toString() : ""}
          inputProps={{
            name: "businessId",
            onChange: onChangeDetail,
          }}
          isCanEdit={false}
          labelClass={classes.gridItemLabel}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_BRANCH_NAME)}
          value={detail && detail.name}
          inputProps={{
            name: "name",
            onChange: onChangeDetail,
          }}
          isCanEdit={false}
          labelClass={classes.gridItemLabel}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_ADDRESS)}
          value={detail && detail.address}
          inputProps={{
            name: "address",
            onChange: onChangeDetail,
          }}
          isCanEdit={false}
          labelClass={classes.gridItemLabel}
        />

        <CustomInfo
          label={getLabel(getNSKey(LangConstant.NS_RATING_MANAGEMENT, LangConstant.TXT_SHOP_RATING))}
          value={
            <Box className={classes.reviewBox}>
              <Typography className={clsx(classes.reviewSummary, "medium-md-txt")}>
                <Star />
                {(detail && detail.rating?.averageScore) || 0}
              </Typography>

              <Link className={clsx(classes.seeDetailReview, "medium-md-txt")} to={PathConstant.MN_REVIEW}>
                {getLabel(getCommonKey(LangConstant.TXT_SEEN_DETAIL))}
              </Link>
            </Box>
          }
          inputProps={{
            name: "review",
          }}
          isCanEdit={false}
          labelClass={classes.gridItemLabel}
        />

        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_OWNER)}
          value={detail && detail.ownerName}
          inputProps={{
            name: "ownerName",
            onChange: onChangeDetail,
          }}
          isCanEdit={false}
          labelClass={classes.gridItemLabel}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_PHONE_NUMBER)}
          value={detail && detail.phoneNumber}
          inputProps={{
            name: "phoneNumber",
            onChange: onChangeDetail,
          }}
          enableEdit={enableEdit}
          onEnableEdit={onEnableEdit}
          labelClass={classes.gridItemLabel}
          onCancelEdit={onDisableEdit}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_EMAIL)}
          value={detail && detail.email}
          inputProps={{
            name: "email",
            onChange: onChangeDetail,
          }}
          isCanEdit={false}
          labelClass={classes.gridItemLabel}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_WEBSITE)}
          value={detail?.socialWebsite}
          inputProps={{
            name: "socialWebsite",
            onChange: onChangeDetail,
          }}
          enableEdit={enableEdit}
          onEnableEdit={onEnableEdit}
          labelClass={classes.gridItemLabel}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_FACEBOOK)}
          value={detail?.socialFacebook}
          inputProps={{
            name: "socialFacebook",
            href: detail?.socialFacebook,
            onChange: onChangeDetail,
          }}
          enableEdit={enableEdit}
          onEnableEdit={onEnableEdit}
          labelClass={classes.gridItemLabel}
          shortenedSocialLink={detail?.facebookShortLink}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_SHOP_GMB)}
          value={detail?.socialGoogle}
          inputProps={{
            name: "socialGoogle",
            href: detail?.socialGoogle,
            onChange: onChangeDetail,
          }}
          enableEdit={enableEdit}
          onEnableEdit={onEnableEdit}
          labelClass={classes.gridItemLabel}
          shortenedSocialLink={detail?.googleShortLink}
        />
        <CustomInfo
          label={getLabel(LangConstant.TXT_LINK_SHOP_CONSUMER)}
          value={detail?.gbossBookingLink?.shortenedSocialLink}
          inputProps={{
            href: detail?.gbossBookingLink?.link,
          }}
          isCanEdit={false}
          labelClass={classes.gridItemLabel}
          shortenedSocialLink={detail?.gbossBookingLink?.shortenedSocialLink}
        />
      </Grid>

      <Box className={classes.buttonBox}>
        {isEdited && (
          <Button
            color="primary"
            variant="contained"
            className={classes.saveButton}
            onClick={() => onSave(detail)}
            size="small"
          >
            <Typography variant="body2" classes={{ body2: "medium-md-txt" }} color={"inherit"}>
              {getLabel(LangConstant.TXT_SAVE_BUTTON)}
            </Typography>
          </Button>
        )}
        {!isEdited && enableEdit && (
          <Button variant="contained" className={classes.cancelButton} onClick={() => onDisableEdit()} size="small">
            <Typography variant="body2" classes={{ body2: "medium-md-txt" }} color={"inherit"}>
              {getLabel(LangConstant.TXT_SHOP_CANCEL)}
            </Typography>
          </Button>
        )}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    paddingBottom: 30,
    flexGrow: 1,
  },
  header: {
    color: theme.palette.grey[800],
    padding: "20px 60px",
    borderBottom: "solid 1px #f1f3f6",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  label: {
    marginTop: 16,
  },
  gridContainer: {
    padding: "30px 60px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "14px 10px 0",
    },
    height: "100%",
  },
  gridItemLabel: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  buttonBox: {
    marginTop: 60,
    display: "flex",
    justifyContent: "center",
  },
  saveButton: {
    textTransform: "unset",
    minWidth: 83,
    minHeight: 30,
    borderRadius: 20,

    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cancelButton: {
    textTransform: "unset",
    minWidth: 83,
    minHeight: 30,
    borderRadius: 20,
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    color: theme.palette.white,
    backgroundColor: theme.palette.grey[400],
  },

  reviewBox: {
    display: "flex",
    alignItems: "center",
  },

  reviewSummary: {
    display: "flex",
    alignItems: "center",
    "&>svg": {
      color: "#FEBA40",
      marginRight: 10,
    },
  },

  seeDetailReview: {
    color: theme.palette.text.link,
    cursor: "pointer",
    marginLeft: 16,
    textTransform: "none",
  },
}));

ShopInfo.propTypes = {
  shopData: PropTypes.object,
};

ShopInfo.defaultProps = { shopData: {} };

export default memo(ShopInfo);
