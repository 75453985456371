import React, { memo, useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import InputShopInfo from "./InputShopInfo";
import PropTypes from "prop-types";

const BookingInfo = props => {
  const { t: getLabel } = useTranslation();
  const { previewData, onUpdatePreviewData } = props;
  const [inputState, setInputState] = useState(previewData);
  const [valueTime, setValueTime] = useState(false);

  const onChange = event => {
    let name = event.currentTarget.name;
    let value = event.currentTarget.value;

    if (name === AppConstant.KEY_BOOK_USING_TIME) {
      setInputState({
        ...inputState,
        [name]: {
          ...inputState[name],
          details: { timeFrame: parseInt(value) * 60 },
        },
      });
      onUpdatePreviewData(name, parseInt(value) * 60, AppConstant.KEY_BOOK_USING_TIME);
    } else {
      setInputState({
        ...inputState,
        [name]: {
          ...inputState[name],
          details: {
            guideNote: { ...inputState[name].details.guideNote, content: value },
          },
        },
      });
      onUpdatePreviewData(name, value, "details");
    }
  };

  const onChecked = event => {
    const name = event.currentTarget.name;
    onUpdatePreviewData(name, event.currentTarget.checked);
    if (name === AppConstant.KEY_BOOK_USING_TIME) {
      setValueTime(event.currentTarget.checked);
    }
  };

  useEffect(() => {
    if (previewData && inputState) {
      if (Boolean(previewData[AppConstant.KEY_BOOK_CUSTOMER]?.publish)) {
        setInputState({
          ...previewData,
          [AppConstant.KEY_BOOK_CUSTOMER]: {
            publish: 1,
            ...previewData[AppConstant.KEY_BOOK_CUSTOMER],
            details: {
              content: DEFAULT_DETAILS.content,
              child: DEFAULT_DETAILS.child,
            },
          },
        });
      }
    }
  }, [previewData]);

  useEffect(() => {
    if (previewData) {
      if (Boolean(previewData[AppConstant.KEY_BOOK_USING_TIME]?.publish)) {
        setValueTime(true);
      } else {
        setValueTime(false);
      }
    }
  }, [previewData]);

  return (
    <Paper className="shopInfo">
      <Grid container direction="column" justify="center" alignItems="center" className="main">
        <Grid item container direction="row" justify="flex-start">
          <Typography className="title semiBold-md-txt">
            {getLabel(LangConstant.TXT_BOOK_SETTING_BOOKING_INFO)}
          </Typography>
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <InputShopInfo
              name={AppConstant.KEY_BOOK_USING_TIME}
              checkBox={true}
              checked={Boolean(previewData[AppConstant.KEY_BOOK_USING_TIME].publish === 0)}
              onCheck={onChecked}
              title={getLabel(LangConstant.TXT_BOOK_SETTING_TIME_DEFAULT)}
            ></InputShopInfo>
          </Grid>
          <Grid item>
            <InputShopInfo
              valueTime={valueTime}
              nameInput={AppConstant.KEY_BOOK_USING_TIME}
              name={AppConstant.KEY_BOOK_USING_TIME_SETTING_TIME}
              checkBox={true}
              checked={Boolean(previewData[AppConstant.KEY_BOOK_USING_TIME].publish)}
              onCheck={onChecked}
              title={getLabel(LangConstant.TXT_BOOK_SETTING_TIME)}
              onChangeInputTime={onChange}
              value={previewData[AppConstant.KEY_BOOK_USING_TIME].details?.timeFrame / 60 || ""}
              typeInput="number"
            ></InputShopInfo>
          </Grid>
          <Grid item>
            <InputShopInfo
              name={AppConstant.KEY_BOOK_CUSTOMER}
              checkBox={true}
              checked={Boolean(previewData[AppConstant.KEY_BOOK_CUSTOMER].publish)}
              title={getLabel(LangConstant.TXT_BOOK_SETTING_PERSON)}
              onCheck={onChecked}
            />
            <Grid item>
              <InputShopInfo
                name={AppConstant.KEY_BOOK_CUSTOMER_CHILD}
                checkBox={true}
                checked={Boolean(previewData[AppConstant.KEY_BOOK_CUSTOMER].details?.child?.publish)}
                onCheck={onChecked}
                title={getLabel(LangConstant.TXT_BOOK_SETTING_PERSON_DETAIL)}
              ></InputShopInfo>
            </Grid>
            <Grid item>
              <InputShopInfo
                name={AppConstant.KEY_BOOK_PERIODIC}
                checkBox={true}
                checked={Boolean(previewData[AppConstant.KEY_BOOK_PERIODIC].publish)}
                onCheck={onChecked}
                title={getLabel(LangConstant.TXT_BOOK_PERIODIC)}
              ></InputShopInfo>
            </Grid>
            <Grid item>
              <InputShopInfo
                nameInput={AppConstant.KEY_BOOK_NOTE}
                checkBox={true}
                title={getLabel(LangConstant.TXT_BOOK_HELPER_NOTE)}
                includeInput={true}
                value={previewData.note.details.guideNote.content || ""}
                placeHolder={getLabel(LangConstant.TXT_BOOK_HELPER_NOTE_HOLDER)}
                rowsMax={4}
                heightType="extra"
                onChangeInput={onChange}
              />
            </Grid>
            <Grid item>
              <InputShopInfo checkBox={true} title={getLabel(LangConstant.TXT_BOOK_NOTE)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

BookingInfo.propTypes = {
  data: PropTypes.object,
};

BookingInfo.defaultProps = {
  data: {},
};

export default memo(BookingInfo);

const DEFAULT_DETAILS = { publish: 1, content: "", child: {} };
