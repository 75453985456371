import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import Reservation from "./Reservation";

const GirdTime = ({ reservationList, maxRow, widthColumn, selectedDate, dataSetting }) => {
  let lengthOfReservationList = reservationList ? reservationList.length : 0;
  let totalOfEmpty = Math.abs(maxRow - lengthOfReservationList);
  let classes = useStyles();

  return (
    <Box width={widthColumn} classes={{ root: classes.girdTime }}>
      {reservationList.map(value => (
        <Box key={value?.uid} borderBottom={1} borderRight={1} classes={{ root: classes.reservationBox }}>
          <Reservation data={value} selectedDate={selectedDate} dataSetting={dataSetting} />
        </Box>
      ))}
      {Array.from(Array(totalOfEmpty).keys()).map((_, index) => (
        <Box key={index} borderBottom={1} borderRight={1} classes={{ root: classes.reservationBox }} />
      ))}
    </Box>
  );
};

const WIDTH_COLUMN = 175;
const DEFAULT_MAX_ROW = 5;
const HEIGHT_RESERVATION_HEIGHT = 150;

const useStyles = makeStyles(theme => ({
  girdTime: {
    borderRightColor: theme.palette.grey[200],
  },
  reservationBox: {
    paddingRight: 5,
    paddingBottom: 5,
    borderColor: theme.palette.grey[200],
    height: HEIGHT_RESERVATION_HEIGHT,
    "&:last-child": {
      borderBottom: 0,
    },
  },
}));

GirdTime.propTypes = {
  reservationList: PropTypes.array.isRequired,
  maxRow: PropTypes.number.isRequired,
  widthColumn: PropTypes.number,
  placeCategory: PropTypes.array,
};

GirdTime.defaultProps = {
  reservationList: [],
  maxRow: DEFAULT_MAX_ROW,
  widthColumn: WIDTH_COLUMN,
};

export default memo(GirdTime);
