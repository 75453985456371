import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import {
  DropdownMenu,
  GroupClassification,
  InputUsedTime,
  TableClassification,
  UploadImageClassified,
  ValueTax,
  Warning,
} from "components/mn-master-product";
import { AppConstant, LangConstant } from "const";
import { calculatePriceTaxIn, createArrayWithLabel, deepCloneJsonObject, getCommonKey, isObjectNotEqual } from "utils";
import { NUMBER_CLASSIFICATION } from "components/mn-master-product/GroupClassification";

const GroupClassifiedService = ({ onClose, isShowGroupClassifiedOne, dataFormInput, onChange }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const classes = useStyles();

  const [data, setData] = useState(DATA_DEFAULT);
  const [isShowGroupClassifiedTwo, setIsShowGroupClassifiedTwo] = useState(false);
  const [formInput, setFormInput] = useState(dataFormInput);
  const [taxTypeValue, setTaxTypeValue] = useState(
    AppConstant.TAX_TYPE_VALUE[dataFormInput?.details?.defaultId || AppConstant.TYPE_TAX.noTax],
  );
  const [notify, setNotify] = useState({
    value: false,
    message: "",
  });

  const onChangeGroupOne = dataGroup => {
    setData(dataGroup);
    onChange({ ...formInput, groupModel: dataGroup });
  };

  const onChangeGroupTwo = dataGroup => {
    setData(dataGroup);
    onChange({ ...formInput, groupModel: dataGroup });
  };

  const onChangeImage = (dataImage, error) => {
    if (!error) {
      setData(dataImage);
      onChange({ ...formInput, groupModel: dataImage });
    } else {
      setNotify({
        value: true,
        message: error,
      });
    }
  };

  const onChangeTable = dataTable => {
    setData(dataTable);
    onChange({ ...formInput, groupModel: dataTable });
  };

  const onChangeUnitType = value => {
    setFormInput({
      ...formInput,
      unitType: value,
    });

    let dataMap = deepCloneJsonObject(data);
    if (isShowGroupClassifiedTwo) {
      dataMap.modelList.forEach(dataModel => {
        dataModel.groupModel.modelList.forEach(dataItem => {
          dataItem.item.unitType = value;
        });
      });
    } else {
      dataMap.modelList.forEach(dataItem => {
        dataItem.item.unitType = value;
      });
    }
    setData(dataMap);

    onChange({ ...formInput, unitType: value });
  };

  const onChangeTime = value => {
    setFormInput({
      ...formInput,
      usingTime: value,
    });

    let dataMap = deepCloneJsonObject(data);
    if (isShowGroupClassifiedTwo) {
      dataMap.modelList.forEach(dataModel => {
        dataModel.groupModel.modelList.forEach(dataItem => {
          dataItem.item.usingTime = value;
        });
      });
    } else {
      dataMap.modelList.forEach(dataItem => {
        dataItem.item.usingTime = value;
      });
    }
    setData(dataMap);

    onChange({ ...formInput, usingTime: value });
  };

  const onClickTimeUsed = value => {
    setFormInput({
      ...formInput,
      usingTimeType: value,
    });

    let dataMap = deepCloneJsonObject(data);
    if (isShowGroupClassifiedTwo) {
      dataMap.modelList.forEach(dataModel => {
        dataModel.groupModel.modelList.forEach(dataItem => {
          dataItem.item.usingTimeType = value;
        });
      });
    } else {
      dataMap.modelList.forEach(dataItem => {
        dataItem.item.usingTimeType = value;
      });
    }
    setData(dataMap);

    onChange({ ...formInput, usingTimeType: value });
  };

  const onChangeTaxType = taxTypeId => {
    let newTaxTypeValue = AppConstant.TAX_TYPE_VALUE[taxTypeId];
    setTaxTypeValue(newTaxTypeValue);

    let newPriceTaxIn = calculatePriceTaxIn(formInput.price, newTaxTypeValue);
    let tax = newPriceTaxIn - formInput.price;

    let newFormInput = {
      ...formInput,
      tax: tax,
      priceTaxIn: newPriceTaxIn,
      taxInclusiveF: Number(Boolean(taxTypeId)),
      details: { ...formInput.details, defaultId: taxTypeId },
    };

    let dataMap = deepCloneJsonObject(data);
    if (Boolean(data.modelList[0].item)) {
      dataMap.modelList.forEach(dataItem => {
        dataItem.item.priceTaxIn = calculatePriceTaxIn(dataItem.item.price, newTaxTypeValue);
        dataItem.item.tax = dataItem.item.priceTaxIn - dataItem.item.price;
        dataItem.item.taxInclusiveF = Number(Boolean(newTaxTypeValue));
      });
    } else {
      dataMap.modelList.forEach(dataModel => {
        dataModel.groupModel.modelList.forEach(dataItem => {
          dataItem.item.priceTaxIn = calculatePriceTaxIn(dataItem.item.price, newTaxTypeValue);
          dataItem.item.tax = dataItem.item.priceTaxIn - dataItem.item.price;
          dataItem.item.taxInclusiveF = Number(Boolean(newTaxTypeValue));
        });
      });
    }
    setData(dataMap);
    setFormInput(newFormInput);
    onChange({ ...newFormInput, groupModel: dataMap });
  };

  const onCloseNotify = () => {
    setNotify({
      value: false,
      message: "",
    });
  };

  const onRemoveGroupOne = () => {
    onChange({ ...formInput, groupModel: null });
    onClose();
  };

  const onRemoveGroupTwo = () => {
    let dataDefault = deepCloneJsonObject(data);
    delete dataDefault.uid;
    let dataCloneObject = deepCloneJsonObject(DATA_DEFAULT);
    let newData = [];
    dataDefault.modelList.map(dataMap => {
      delete dataMap.uid;
      delete dataMap.groupModel;
      newData.push({ ...dataMap, item: dataCloneObject.modelList[0].item, imageList: [] });
    });
    let dataGroupOne = { ...dataDefault, modelList: newData };
    setData(dataGroupOne);
    onChange({ ...formInput, groupModel: dataGroupOne });
    setIsShowGroupClassifiedTwo(false);
  };

  const onClickAddClassification = () => {
    let dataDefault = deepCloneJsonObject(data);
    delete dataDefault.uid;
    let dataCloneObject = deepCloneJsonObject(DATA_DEFAULT);
    let newData = [];
    dataDefault.modelList.map(dataMap => {
      delete dataMap.uid;
      newData.push({ ...dataMap, item: null, groupModel: dataCloneObject, imageList: [] });
    });

    setData({ ...dataDefault, modelList: newData });
    setIsShowGroupClassifiedTwo(true);
  };

  useEffect(() => {
    if (
      !Boolean(dataFormInput.groupModel) ||
      (isShowGroupClassifiedOne && !isObjectNotEqual(dataFormInput, formInput))
    ) {
      let newData = deepCloneJsonObject(DATA_DEFAULT);
      setData(newData);
    }
  }, [isShowGroupClassifiedOne]);

  useEffect(() => {
    if (isObjectNotEqual(dataFormInput, formInput)) {
      setFormInput(dataFormInput);
    }

    if (Boolean(dataFormInput.groupModel) && isObjectNotEqual(dataFormInput.groupModel, data)) {
      setData(dataFormInput.groupModel);
      if (Boolean(dataFormInput.groupModel.modelList[0].groupModel)) setIsShowGroupClassifiedTwo(true);
    }
  }, [dataFormInput]);

  return (
    Boolean(data) && (
      <>
        <Box>
          <GroupClassification
            onChange={onChangeGroupOne}
            onRemove={onRemoveGroupOne}
            data={data}
            isShowGroupClassifiedTwo={isShowGroupClassifiedTwo}
          />
          {isShowGroupClassifiedTwo && (
            <GroupClassification
              onChange={onChangeGroupTwo}
              onRemove={onRemoveGroupTwo}
              isTwoGroupClassification={true}
              data={data}
            />
          )}
        </Box>
        {!isShowGroupClassifiedTwo && (
          <Box className={classes.paddingBox}>
            <Typography variant="subtitle2" className={classes.classificationProduct}>
              {getLabel(LangConstant.FM_CLASSIFICATION_GROUP, { name: NUMBER_CLASSIFICATION.two })}
            </Typography>
            <Button
              className={clsx(classes.buttonAdd, "regular-md-txt")}
              startIcon={<Add />}
              onClick={onClickAddClassification}
            >
              {getLabel(LangConstant.TXT_ADD_GROUP)}
            </Button>
          </Box>
        )}
        <Box className={classes.paddingBox}>
          <Typography variant="subtitle2" className={classes.classificationProduct}>
            {getLabel(LangConstant.TXT_TAX_APPLY)}
          </Typography>
          <ValueTax onChange={onChangeTaxType} valueTax={formInput?.details?.defaultId} />
        </Box>
        <Box padding="8px 0 46px" display="flex">
          <Box minWidth="158px">
            <Typography variant="subtitle2" className={clsx(classes.inputTitleTypo, "semiBold-sm-txt")}>
              {getLabel(LangConstant.TXT_PRICE_ACCORDING_TO)}
            </Typography>
            <DropdownMenu
              valueTax={formInput.unitType}
              onChange={onChangeUnitType}
              dataChange={createArrayWithLabel(
                AppConstant.TYPE_UNIT_PRICE_CALCULATE,
                LangConstant.ARR_TYPE_UNIT_PRICE_CALCULATE,
                getLabel,
                true,
              )}
              dropdownClass={classes.dropdownClass}
              placeholder={getLabel(getCommonKey(LangConstant.P_UNIT))}
            />
          </Box>
          <Box minWidth="158px" margin="0 90px">
            <Typography variant="subtitle2" className={clsx(classes.inputTitleTypo, "semiBold-sm-txt")}>
              {getLabel(getCommonKey(LangConstant.TXT_BOOK_USED_TIME))}
            </Typography>
            <InputUsedTime
              valueTime={formInput.usingTime}
              valueTimeType={formInput.usingTimeType}
              dataChange={createArrayWithLabel(
                AppConstant.TYPE_TIME_USED,
                LangConstant.ARR_TYPE_TIME_USED,
                getLabel,
                true,
              )}
              onChange={onChangeTime}
              onClick={onClickTimeUsed}
              className={classes.inputUsedTime}
              placeholderTime={getLabel(getCommonKey(LangConstant.TXT_MINUTES))}
              placeholderValue="30"
              unitPriceCalculateType={formInput.unitType}
            />
          </Box>
        </Box>
        <Box className={classes.paddingBox}>
          <Typography variant="subtitle2" className={clsx(classes.inputTitleTypo, "semiBold-sm-txt")}>
            {getLabel(LangConstant.TXT_LIST_OF_CATEGORIES_OF_GOOD)}
          </Typography>
          <TableClassification
            dataTable={data}
            taxTypeValue={taxTypeValue}
            isShowGroupClassifiedTow={isShowGroupClassifiedTwo}
            onChange={onChangeTable}
          />
          <UploadImageClassified data={data} onChange={onChangeImage} />
          <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
        </Box>
      </>
    )
  );
};

const DATA_DEFAULT = {
  name: "",
  modelList: [
    {
      name: "",
      imageList: [],
      item: {
        name: "",
        usingTime: 0,
        usingTimeType: 0,
        price: "",
        priceTaxIn: "",
        tax: 0,
        taxInclusiveF: 0,
        unitType: 1,
        description: "",
        stock: "",
      },
    },
  ],
};

GroupClassifiedService.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,

  isShowGroupClassifiedOne: PropTypes.bool,
  dataFormInput: PropTypes.object,
};

GroupClassifiedService.defaultProps = {
  onClose: () => {},
  onChange: () => {},

  isShowGroupClassifiedOne: false,
  dataFormInput: {},
};

const useStyles = makeStyles({
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  classificationProduct: {
    fontSize: 13,
  },
  buttonAdd: {
    height: 40,
    width: "100%",
    border: "1px solid #447AFF",
    boxSizing: "border-box",
    borderRadius: 3,
    textTransform: "none",
    marginTop: 15,
    color: "#447AFF",
  },
  dropdownClass: {
    width: "100%",
  },
  inputUsedTime: {
    width: 158,
  },
  paddingBox: {
    padding: "8px 0 16px",
  },
});

export default memo(GroupClassifiedService);
