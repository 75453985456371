import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import {
  calculatePriceTaxIn,
  deepCloneJsonObject,
  formatVietnameseCurrency,
  isObjectNotEqual,
  putCursorBeforeTheLastCharacter,
  removeCurrencyFormat,
} from "utils";

const OneClassificationTable = ({ arrHeader, isContainStockColumn, data, taxTypeValue, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const [dataTable, setDataTable] = useState(data);

  const onChangePrice = (event, index) => {
    let newPrice = event.target.value;
    newPrice = parseInt(removeCurrencyFormat(newPrice));
    let newData = deepCloneJsonObject(dataTable);
    let newItem = { ...newData.modelList[index].item };

    if (isNaN(newPrice)) {
      newItem.price = "";
      newItem.priceTaxIn = "";
    } else if (newPrice < AppConstant.LIMITED_PRICE_NUMBER) {
      newItem.price = newPrice;
      newItem.priceTaxIn = calculatePriceTaxIn(newPrice, taxTypeValue);
    }
    newItem.tax = newItem.priceTaxIn - newItem.price;
    newData.modelList[index].item = newItem;

    setDataTable(newData);
    onChange(newData);
  };

  const onChangeStock = (event, index) => {
    let newStock = parseInt(event.target.value);
    let newData = deepCloneJsonObject(dataTable);
    let newItem = { ...newData.modelList[index].item };

    if (isNaN(newStock)) newItem.stock = "";
    else if (newStock < AppConstant.LIMITED_PRICE_NUMBER) newItem.stock = newStock;
    newData.modelList[index].item = newItem;

    setDataTable(newData);
    onChange(newData);
  };

  useEffect(() => {
    const priceInput = document.getElementById(PRICE_INPUT_ID);
    putCursorBeforeTheLastCharacter(priceInput);
  }, [dataTable]);

  useEffect(() => {
    if (isObjectNotEqual(data, dataTable)) {
      setDataTable(data);
    }
  }, [data]);

  useEffect(() => {
    let dataMap = deepCloneJsonObject(data);
    dataMap.modelList.forEach(dataItem => {
      dataItem.item.priceTaxIn = calculatePriceTaxIn(dataItem.item.price, taxTypeValue);
      dataItem.item.tax = dataItem.item.priceTaxIn - dataItem.item.price;
      dataItem.item.taxInclusiveF = Number(Boolean(taxTypeValue));
    });
    setDataTable(dataMap);
  }, [taxTypeValue]);

  return (
    <TableContainer className={classes.tableRoot}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow className={classes.cellHeadRoot}>
            {arrHeader.map((dataItem, index) => (
              <TableCell key={index} className={classes.cellHeadRoot}>
                <Typography className="semiBold-md-txt">{getLabel(dataItem)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable.modelList.map((dataItem, index) => (
            <TableRow key={index} className={classes.cellHeadRoot}>
              <TableCell rowSpan={1} className={classes.cellHeadRoot}>
                <Typography variant="body2">{dataItem.name}</Typography>
              </TableCell>
              <TableCell className={classes.cellHeadRoot}>
                <OutlinedInput
                  id={PRICE_INPUT_ID}
                  classes={{
                    root: clsx(classes.outlinedInput, "regular-md-txt"),
                    notchedOutline: classes.notchedOutline,
                    input: classes.textCenter,
                  }}
                  placeholder={getLabel(LangConstant.P_VALUE_INPUT)}
                  onChange={event => onChangePrice(event, index)}
                  value={dataItem.item.price ? formatVietnameseCurrency(dataItem.item.price) : ""}
                />
              </TableCell>
              <TableCell className={classes.cellHeadRoot}>
                <Typography variant="body2">
                  {dataItem.item.price ? formatVietnameseCurrency(dataItem.item.priceTaxIn) : ""}
                </Typography>
              </TableCell>
              {isContainStockColumn && (
                <TableCell className={classes.cellHeadRoot}>
                  <OutlinedInput
                    classes={{
                      root: clsx(classes.outlinedInput, "regular-md-txt"),
                      notchedOutline: classes.notchedOutline,
                      input: classes.textCenter,
                    }}
                    placeholder={getLabel(LangConstant.P_VALUE_INPUT)}
                    onChange={event => onChangeStock(event, index)}
                    value={dataItem.item.stock}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OneClassificationTable.propTypes = {
  onChange: PropTypes.func,
  arrHeader: PropTypes.array,
  isContainStockColumn: PropTypes.bool,
  data: PropTypes.object,
  taxTypeValue: PropTypes.number,
};

OneClassificationTable.defaultProps = {
  arrHeader: [],
  isContainStockColumn: false,
  data: {},
  taxTypeValue: 0,
  onChange: () => {},
};

export default memo(OneClassificationTable);

const PRICE_INPUT_ID = "price-input";

const useStyles = makeStyles(theme => ({
  tableRoot: {
    overflow: "unset",
  },
  cellHeadRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
    textAlign: "center",
    minWidth: 180,
  },
  outlinedInput: {
    height: 30,
  },
  notchedOutline: {
    border: "none",
  },
  textCenter: {
    textAlign: "center",
  },
}));
