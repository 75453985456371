import { ApiConstant } from "../const";
import { createApiReservation, createApiRegistration } from "../api";
import { format } from "react-string-format";

export const getSetting = data => createApiReservation().get(ApiConstant.GET_RESERVATION_SETTING, data);

export const updateSetting = data => createApiReservation().patch(ApiConstant.UPDATE_RESERVATION_SETTING, data);

export const getNotification = data => createApiRegistration().get(ApiConstant.GET_RESERVATION_PENDING, data);

export const updateStatus = data =>
  createApiReservation().put(format(ApiConstant.UPDATE_RESERVATION_STATUS, data.uid), data);

export const updateSeenNotification = data => createApiRegistration().patch(ApiConstant.PATCH_SEEN_NOTIFICATION, data);
