import { AppConstant } from "const";
import { HOST_CONSUMER } from "./api.const";

export const ROOT = "/";
export const MANAGE_BOOKS = "/mn/books";
export const MANAGE_BOOKING = "/mn/booking";
export const MANAGE_MEMBERS = "/mn/members";
export const MANAGE_CONSUMER = "/mn/consumers";
export const MANAGE_STAFF = "/mn/staff";
export const MANAGE_REPORT_BOOKING = "/mn/report-booking";
export const MANAGE_REPORT_CONSUMERS = "/mn/report-consumers";
export const MANAGE_REPORT_STAFF = "/mn/report-staff";
export const MANAGE_SHOP_INFO = "/mn/shop-info";
export const MANAGE_ACTIVITY_HISTORY = "/mn/activity-history";
export const LOGIN = "/login";
export const AUTH_SOCIAL = "/auth-social";
export const ACCOUNT = "/account";
export const ACCOUNT_PASS = "/account/pass";
export const NOT_FOUND = "/not-found";
export const FORGOT_PASSWORD = "/forgot-password";
export const MANAGE_NEWS = "/mn/news";
export const SELECT_SHOP = "/select-shop";
export const STAFF_SETTING = "/staff/setting";
export const MANAGE_REPORT_DASHBOARD = "/mn/report-dashboard";
export const BOOKING_HISTORY = "/booking-history";
export const MANAGE_SERVICE = "/mn/service";
export const MANAGE_PRODUCT = "/mn/product";
export const MANAGE_COMBO = "/mn/combo";
export const MANAGE_PLACE = "/mn/place";
export const CALENDAR_SETUP = "/calendar-setup";
export const MENU_SETUP = "/menu-setup";
export const PAYMENT_SETUP = "/payment-setup";
export const MANAGE_ORDER = "/mn/order";
export const SUBSCRIPTION = "/subscription";
export const UPGRADE_PLAN = "/upgrade-plan";
export const PAYMENT_HISTORY = "/payment-history";
export const VAT_MANAGEMENT = "/mn/bill-management";
export const MN_REVIEW = "/mn/review";
export const NOTIFICATION_SETTING = "/notification-setting";
export const LOGIN_GROUP_ADMIN = "/group-admin/:groupAlias/join";
export const JOIN_GROUP_ADMIN = "/group-admin";

export const FM_URL_CONSUMER_REGISTER = HOST_CONSUMER + "/register?invitation={0}";
export const FM_URL_RESERVATION_BY_UID = MANAGE_BOOKS + `?${AppConstant.KEY_START_TIME}={0}&${AppConstant.KEY_UID}={1}`;
export const FM_URL_RESERVATION_ORDER_BY_UID =
  MANAGE_ORDER + `?${AppConstant.KEY_START_TIME}={0}&${AppConstant.KEY_UID}={1}`;
export const FM_URL_LOGIN_GROUP_ADMIN = "/group-admin/{0}/join";

export const AUTHENTICATION_PATHS = [LOGIN, FORGOT_PASSWORD, AUTH_SOCIAL, LOGIN_GROUP_ADMIN];
