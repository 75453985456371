import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const getProductCategory = () => createApiReservation().get(ApiConstant.GET_PRODUCT_CATEGORY, {});

export const createProductCategory = data => createApiReservation().post(ApiConstant.POST_PRODUCT_CATEGORY, data);

export const updateProductCategory = data =>
  createApiReservation().put(format(ApiConstant.PUSH_PRODUCT_CATEGORY, data.uid), data);

export const removeProductCategory = data =>
  createApiReservation().delete(format(ApiConstant.DELETE_PRODUCT_CATEGORY, data.uid), {});

export const getProductItem = () => createApiReservation().get(ApiConstant.GET_PRODUCT_ITEM, {});

export const getProductItemUidCategory = data => {
  let requestString = format(ApiConstant.GET_PRODUCT_ITEM_UID_CATEGORY, data.category_uid.toString());
  return createApiReservation().get(requestString);
};

export const createProductItem = data => createApiReservation().post(ApiConstant.POST_PRODUCT_ITEM, data);

export const updateProductItem = data =>
  createApiReservation().put(format(ApiConstant.PUSH_PRODUCT_ITEM, data.uid), data);

export const removeProductItem = data =>
  createApiReservation().delete(format(ApiConstant.DELETE_PRODUCT_ITEM, data.uid), {});
