import { createReducer, createActions } from "reduxsauce";
import { toCamel } from "utils";
import { AppConstant } from "const";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestGetReservationSetting: ["data"],
  getReservationSettingSuccess: ["data"],
  getReservationSettingFailure: ["data"],
  requestUpdateReservationSetting: ["data"],
  updateReservationSettingSuccess: ["data"],
  updateReservationSettingFailure: ["data"],
  requestGetReservationNotification: ["data"],
  getReservationNotificationSuccess: ["data"],
  getReservationNotificationFailure: ["data"],
  requestUpdateReservationStatus: ["data"],
  updateReservationStatusSuccess: ["data"],
  updateReservationStatusFailure: ["data"],

  requestSeenNotification: ["data"],
  putSeenNotificationSuccess: ["data"],
  putSeenNotificationFailure: ["data"],
  resetSeenNotification: ["data"],
});

export const ReservationSettingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  //reservation setting read
  isFetchingSetting: false,
  errorSetting: null,
  isSettingSuccess: false,
  dataSetting: null,
  dataSettingDefault: null,
  //reservation setting update
  isFetchingUpdateSetting: false,
  errorUpdateSetting: null,
  isUpdateSettingSuccess: false,
  //reservation notification read
  isFetchingNotification: false,
  errorNotification: null,
  isNotificationSuccess: false,
  dataNotification: null,
  isFirstSuccess: false,
  news: 0,
  unreadTotal: 0,
  //reservation status
  isUpdateStatusSuccess: false,

  isFetchingSeenNotification: false,
  isUpdateSeenNotification: false,
};

/* ------------- Reducers ------------- */
export const requestGetReservationSetting = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingSetting: true,
});

export const getReservationSettingSuccess = (state = INITIAL_STATE, action) => {
  const guideNodeDefault = { publish: 1, content: "" };
  var result = action.data.reduce(function (map, reservationSetting) {
    if (reservationSetting.type === 6) {
      reservationSetting.details = reservationSetting.details
        ? reservationSetting.details[AppConstant.KEY_BOOK_HELPER_NOTE_HOLDER]
          ? reservationSetting.details
          : { ...reservationSetting.details, guideNote: guideNodeDefault }
        : { guideNote: guideNodeDefault };
    }
    map[AppConstant.SETTING_RESERVATION_TYPE[reservationSetting.type]] = reservationSetting;
    return map;
  }, {});
  return {
    ...state,
    isFetchingSetting: false,
    isSettingSuccess: true,
    dataSetting: result,
  };
};

export const getReservationSettingFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingSetting: false,
    isSettingSuccess: false,
    errorSetting: action.error,
  };
};

/* ------------- Reducers ------------- */
export const requestUpdateReservationSetting = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetchingUpdateSetting: true,
  };
};

export const updateReservationSettingSuccess = (state = INITIAL_STATE, action) => {
  const guideNodeDefault = { publish: 1, content: "" };
  var result = action.data.reduce(function (map, reservationSetting) {
    if (reservationSetting.type === 6) {
      reservationSetting.details = reservationSetting.details
        ? reservationSetting.details[AppConstant.KEY_BOOK_HELPER_NOTE_HOLDER]
          ? reservationSetting.details
          : { ...reservationSetting.details, guideNote: guideNodeDefault }
        : { guideNote: guideNodeDefault };
    }
    map[AppConstant.SETTING_RESERVATION_TYPE[reservationSetting.type]] = reservationSetting;
    return map;
  }, {});
  return {
    ...state,
    isFetchingUpdateSetting: false,
    isUpdateSettingSuccess: true,
    dataSetting: {
      ...state.dataSetting,
      ...result,
    },
  };
};

export const updateReservationSettingFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUpdateSetting: false,
    isUpdateSettingSuccess: false,
    errorUpdateSetting: action.error,
  };
};

/* ------------- Reducers ------------- */
export const requestGetReservationNotification = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingNotification: true,
});

export const getReservationNotificationSuccess = (state = INITIAL_STATE, action) => {
  let newUpdate = 0;
  let totalRecord = action.data.total ? action.data.total : 0;
  let totalPage = action.data.total_page ? action.data.total_page : 0;
  let page = action.data.page ? action.data.page : 0;
  let unreadTotal = action.data.data.totalUnread;
  let result = action.data.data.notifications.reduce(function (map, notice) {
    if (!notice) return map;
    let type = notice.type;
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    });
    const [, , , , , , { value: hourCreated }, , { value: minuteCreated }] = dateTimeFormat.formatToParts(
      new Date(notice.created * 1000),
    );
    notice["createTime"] = `${hourCreated}:${minuteCreated}`;
    if (notice.created > newUpdate) {
      newUpdate = notice.created;
    }
    switch (type) {
      case AppConstant.NOTIFICATION_TYPE.news:
      case AppConstant.NOTIFICATION_TYPE.reservation_status_updated:
      case AppConstant.NOTIFICATION_TYPE.role_updated:
      case AppConstant.NOTIFICATION_TYPE.reservation_assign:
      case AppConstant.NOTIFICATION_TYPE.payment:
      case AppConstant.NOTIFICATION_TYPE.reservation_info_updated: {
        map[notice.uid] = notice;
        break;
      }
      case AppConstant.NOTIFICATION_TYPE.reservation_new: {
        let reservationNotice = notice.content.reservation;
        if (!reservationNotice) break;
        var parent = reservationNotice.consumer
          ? reservationNotice.consumer.man && reservationNotice.consumer.man !== 0
            ? reservationNotice.consumer.man
            : reservationNotice.consumer.total
          : 0;
        var child = reservationNotice.consumer ? reservationNotice.consumer.child : 0;
        let date = new Date(reservationNotice.start_time * 1000);

        const [{ value: month }, , { value: day }, , { value: year }, , { value: hour }, , { value: minute }] =
          dateTimeFormat.formatToParts(date);
        let dateEnd = new Date(reservationNotice.start_time * 1000 + 7200000);
        const [, , , , , , { value: hourEnd }, , { value: minuteEnd }] = dateTimeFormat.formatToParts(dateEnd);
        reservationNotice["parent"] = parent;
        reservationNotice["child"] = child;
        reservationNotice["time"] = `${hour}:${minute} - ${hourEnd}:${minuteEnd}`;
        reservationNotice["date"] = `${day}-${month}-${year}`;
        notice.content.reservation = reservationNotice;
        map[notice.uid] = notice;
        break;
      }
      default:
        break;
    }
    return map;
  }, {});
  var keysSorted = Object.keys(result)
    .sort(function (a, b) {
      return result[b].created - result[a].created;
    })
    .reduce((prev, curr) => {
      prev[curr] = result[curr];
      return prev;
    }, {});
  if (newUpdate !== state.news || !state.isFirstSuccess) {
    return {
      ...state,
      isFetchingNotification: false,
      isNotificationSuccess: true,
      dataNotification: toCamel(keysSorted),
      isFirstSuccess: true,
      news: newUpdate,
      unreadTotal: unreadTotal,
      page: page,
      total: totalRecord,
      totalPage: totalPage,
    };
  } else {
    return {
      ...state,
      isFetchingNotification: false,
      isNotificationSuccess: true,
      isFirstSuccess: true,
      unreadTotal: unreadTotal,
    };
  }
};

export const getReservationNotificationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingNotification: false,
    isNotificationSuccess: false,
    errorNotification: action.error,
  };
};

/* ------------- Reducers ------------- */
export const requestUpdateReservationStatus = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetchingUpdateSetting: true,
    isUpdateStatusSuccess: false,
  };
};

export const updateReservationStatusSuccess = (state = INITIAL_STATE, action) => {
  let clone = { ...state.dataNotification };
  delete clone[action.data.noticeUid];
  return {
    ...state,
    isFetchingUpdateSetting: false,
    isUpdateStatusSuccess: true,
    dataNotification: clone,
  };
};

export const updateReservationStatusFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingUpdateSetting: false,
    isUpdateStatusSuccess: false,
    errorUpdateSetting: action.error,
  };
};

/* ------------- Reducers ------------- */
export const requestSeenNotification = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetchingSeenNotification: true,
    isUpdateSeenNotification: false,
  };
};

export const putSeenNotificationSuccess = (state = INITIAL_STATE, action) => {
  let clone = { ...state.dataNotification };
  for (let k in action.data.uids) {
    if (clone[action.data.uids[k]]) clone[action.data.uids[k]].status = 1;
  }
  return {
    ...state,
    isFetchingSeenNotification: false,
    isUpdateSeenNotification: true,
    dataNotification: clone,
  };
};

export const putSeenNotificationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingSeenNotification: false,
    isUpdateSeenNotification: false,
    errorUpdateSetting: action.error,
  };
};

export const resetSeenNotification = (state = INITIAL_STATE) => {
  return {
    ...state,
    isUpdateSeenNotification: false,
  };
};

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_GET_RESERVATION_SETTING]: requestGetReservationSetting,
  [Types.GET_RESERVATION_SETTING_SUCCESS]: getReservationSettingSuccess,
  [Types.GET_RESERVATION_SETTING_FAILURE]: getReservationSettingFailure,
  [Types.REQUEST_UPDATE_RESERVATION_SETTING]: requestUpdateReservationSetting,
  [Types.UPDATE_RESERVATION_SETTING_SUCCESS]: updateReservationSettingSuccess,
  [Types.UPDATE_RESERVATION_SETTING_FAILURE]: updateReservationSettingFailure,
  [Types.REQUEST_GET_RESERVATION_NOTIFICATION]: requestGetReservationNotification,
  [Types.GET_RESERVATION_NOTIFICATION_SUCCESS]: getReservationNotificationSuccess,
  [Types.GET_RESERVATION_NOTIFICATION_FAILURE]: getReservationNotificationFailure,
  [Types.REQUEST_UPDATE_RESERVATION_STATUS]: requestUpdateReservationStatus,
  [Types.UPDATE_RESERVATION_STATUS_SUCCESS]: updateReservationStatusSuccess,
  [Types.UPDATE_RESERVATION_STATUS_FAILURE]: updateReservationStatusFailure,
  [Types.REQUEST_SEEN_NOTIFICATION]: requestSeenNotification,
  [Types.PUT_SEEN_NOTIFICATION_SUCCESS]: putSeenNotificationSuccess,
  [Types.PUT_SEEN_NOTIFICATION_FAILURE]: putSeenNotificationFailure,
  [Types.RESET_SEEN_NOTIFICATION]: resetSeenNotification,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
