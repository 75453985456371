export default {
  TXT_NEW_ORDER_NOTIFY_TITLE: "Đặt hàng mới",
  TXT_EMPTY_NEW_ORDER_NOTICE_MESSAGE: "Bạn chưa có đơn đặt hàng mới",
  TXT_NEW_BOOKING_NOTIFY_TITLE: "Đặt chỗ mới",
  TXT_NEW_BOOKING_DIALOG_TITLE: "Xác nhận đặt chỗ định kỳ",
  TXT_NEW_BOOKING_DIALOG_CONTENT:
    "Đây là đơn đặt chỗ định kỳ. Bạn có muốn chấp nhận tất cả các đơn đặt chỗ trong chuỗi định kỳ này không?",

  OBJ_ORDER_NOTIFICATION: {
    banking: `<span style="font-weight:bold;">{0}</span> đã gửi 1 đơn đặt hàng mới tới shop của bạn. Phương thức thanh toán Chuyển khoản`,
    cash: `<span style="font-weight:bold;">{0}</span> đã gửi 1 đơn đặt hàng mới tới shop của bạn. Phương thức thanh toán Tiền mặt`,
  },

  FM_NEW_ORDER_REQUEST_CONTENT: `<span style="font-weight:bold;">{0}</span> đã gửi 1 đơn đặt hàng mới tới shop của bạn`,
  FM_NEW_BOOKING_REQUEST_CONTENT: `<span style="font-weight:bold;">{0}</span> đã gửi 1 yêu cầu đặt chỗ tới shop của bạn`,
  FM_NEW_BOOKING_REQUEST_PERIODIC_CONTENT: `<span style="font-weight:bold;">{0}</span> đã gửi 1 yêu cầu đặt chỗ định kỳ tới shop của bạn`,
};
