import React, { memo, useState } from "react";
import { makeStyles, Button, Typography, MenuItem } from "@material-ui/core";
import { Dehaze } from "@material-ui/icons";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";

const CategorySubcategory = ({ uid, text, onClick, onContextMenu }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const onClickCategory = () => {
    onClick(uid);
  };

  const onContextMenuCategory = event => {
    event.preventDefault();
    var rightClick;
    var e = window.event;
    if (e.which) rightClick = e.which == 3;
    else if (e.button) rightClick = e.button == 2;
    if (rightClick) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickMenu = e => {
    onContextMenu(uid, e);
    setAnchorEl(null);
  };

  return (
    <Button classes={{ root: classes.root, label: classes.labelButton }}>
      <Dehaze onContextMenu={onContextMenuCategory} onClick={onClickCategory} className={classes.icon} />
      <Typography onClick={onClickCategory} onContextMenu={onContextMenuCategory} className={classes.typography}>
        {text}
      </Typography>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <MenuItem className={classes.hoverSelect} onClick={() => onClickMenu(EDIT_ACTION)}>
          {getLabel(LangConstant.TXT_EDIT_CATEGORY_SUB_CATEGORY)}
        </MenuItem>
        <MenuItem className={classes.hoverSelect} onClick={() => onClickMenu(DELETE_ACTION)}>
          {getLabel(LangConstant.TXT_REMOVE_CATEGORY_SUB_CATEGORY)}
        </MenuItem>
      </Popover>
    </Button>
  );
};

export const EDIT_ACTION = "edit";
export const DELETE_ACTION = "delete";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    width: 272,
    height: 48,
    textAlign: "left",
    borderRadius: 4,
    border: "solid 1px #f2f2f2",
    textTransform: "none",
  },
  labelButton: {
    alignItems: "center",
    justifyContent: "left",
    height: "100%",
  },
  icon: {
    marginLeft: 16,
    marginRight: 16,
    fontSize: 20,
    color: "#7f838c",
    height: "100%",
  },
  typography: {
    height: 48,
    padding: "12px 16px 12px 0",
    lineHeight: 1.5,
    width: "100%",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  hoverSelect: {
    "&.MuiListItem-button:hover": {
      backgroundColor: "#3b404c",
      color: "white",
    },
  },
}));
CategorySubcategory.propTypes = {
  uid: PropTypes.string.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
export default memo(CategorySubcategory);
