import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { DayInMonth, WeekHeader } from "./index";
import { subDays, addDays, isSameDay } from "date-fns";
import { AppConstant } from "const";
import { HTML_CONTAINER_ID, RESERVATION_BOX_ID } from "pages/MnReservation";
import { useSelector } from "react-redux";

const BookingByMonth = ({ selectedDate }) => {
  const classes = useStyles();
  const data = useSelector(({ reservationRedux }) => reservationRedux.reservationMonth) || {};

  const sizeScreen = screen.width;
  const selectedYear = selectedDate.getFullYear();
  const selectMonth = selectedDate.getMonth();
  const totalDaysInMonth = new Date(selectedYear, selectMonth + 1, 0).getDate();
  const daysInMonth = Array.from(Array(totalDaysInMonth).keys());
  const firstDateInMonth = new Date(selectedYear, selectMonth, 1);
  // Mon: 0, ..., Sun: 6,
  const firstWeekDay = firstDateInMonth.getDay() === 0 ? 6 : firstDateInMonth.getDay() - 1;
  const daysInLastMonth = Array.from(Array(firstWeekDay).keys()).map(day =>
    subDays(new Date(firstDateInMonth), firstWeekDay - day),
  );
  const lastDateInMonth = new Date(selectedYear, selectMonth, totalDaysInMonth);
  const lastWeekDay = lastDateInMonth.getDay() === 0 ? 6 : lastDateInMonth.getDay() - 1;
  const daysInNextMonth = Array.from(Array(6 - lastWeekDay).keys()).map(i => addDays(new Date(lastDateInMonth), i + 1));
  let totalDayInWeek = 7;

  const [widthColumn, setWidthColumn] = useState(0);

  useEffect(() => {
    if (sizeScreen) {
      if (sizeScreen >= AppConstant.SIZE_SCREEN_XL) {
        setWidthColumn(parseInt((sizeScreen - 240) / 7));
      } else {
        setWidthColumn(WIDTH_COLUMN);
      }
    }
  }, [data]);

  return (
    <Box classes={{ root: classes.dayContent }} id={HTML_CONTAINER_ID}>
      <Box style={{ height: HEIGHT_DAY_HEADER }}>
        <WeekHeader />
      </Box>
      <Box style={{ width: totalDayInWeek * widthColumn }} classes={{ root: classes.dayBody }} id={RESERVATION_BOX_ID}>
        <Box display="flex" flexWrap="wrap" classes={{ root: classes.dayBodyWrap }}>
          {daysInLastMonth.map((day, index) => (
            <DayInMonth key={index} day={day} isDayInMonth={false} widthColumn={widthColumn} />
          ))}
          {daysInMonth.map((day, index) => (
            <DayInMonth
              key={index}
              day={new Date(selectedYear, selectMonth, day + 1)}
              detail={data.reservation ? (data.reservation[day + 1] ? data.reservation[day + 1] : {}) : {}}
              isSelectedDate={isSameDay(selectedDate, new Date(selectedYear, selectMonth, day + 1))}
              widthColumn={widthColumn}
            />
          ))}
          {daysInNextMonth.map((day, index) => (
            <DayInMonth key={index} day={day} isDayInMonth={false} widthColumn={widthColumn} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

BookingByMonth.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

BookingByMonth.defaultProps = {
  selectedDate: new Date(),
  data: {},
};

const HEIGHT_DAY_HEADER = 42;
const WIDTH_COLUMN = 175;

const useStyles = makeStyles(theme => ({
  dayContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    width: "100%",
    backgroundColor: theme.palette.white,
    height: `calc(100vh - 180px)`,
  },
  dayBody: {
    flexGrow: 1,
    overflowY: "auto",
    msOverflowStyle: `none`,
    scrollbarWidth: `none`,
    "&::-webkit-scrollbar": {
      display: `none`,
      width: 0,
    },
  },
  dayBodyWrap: {
    width: "100%",
    flexGrow: 1,
  },
}));

export default memo(BookingByMonth);
