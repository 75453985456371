import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Button, Paper, Grid, Typography, Box, MenuItem, Menu } from "@material-ui/core";
import { MoreHoriz, Add } from "@material-ui/icons";
import clsx from "clsx";
import StaffAction from "redux/staff.redux";
import DialogStaffWorkingShift from "components/dialog/DialogStaffWorkingShift";
import { LangConstant } from "const";
import { ConfirmDialog } from "components/dialog";

const StaffWorkTime = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  const [staffWorkingTimeData, isRemoveSuccess, isSuccess, isSuccessUpdatesWorkingShift] = useSelector(
    ({ staffRedux }) => [
      staffRedux.dataWork,
      staffRedux.isRemoveSuccess,
      staffRedux.isSuccess,
      staffRedux.isSuccessUpdatesWorkingShift,
    ],
    shallowEqual,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [line, setLine] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [dataState, setDataState] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);

  const onClickAdd = event => {
    setAnchorEl(event.currentTarget);
    setLine(event.currentTarget.id);
  };

  const onCloseDialog = () => {
    setIsShow(false);
  };

  const onChange = () => {
    setDataState(null);
    setIsShow(true);
  };
  const onConfirm = (event, isAgree) => {
    event.stopPropagation();
    if (isAgree) {
      dispatch(StaffAction.removePostWorking({ uid: dataState.data }));
    }
    setOpenConfirm(false);
  };
  const onClick = event => {
    if (event === 2) {
      setOpenConfirm(true);
      setDataState({ data: staffWorkingTimeData[line].uid });
    } else if (event === 1) {
      let endTime = staffWorkingTimeData[line].endTime;
      let startTime = staffWorkingTimeData[line].startTime;
      let startTimeSplit = startTime.split(":");
      let endTimeSplit = endTime.split(":");
      dataTime.startHours = startTimeSplit[0];
      dataTime.startMinute = parseInt(startTimeSplit[1], 10).toString();
      dataTime.endHours = endTimeSplit[0];
      dataTime.endMinute = parseInt(endTimeSplit[1], 10).toString();
      dataTime.name = staffWorkingTimeData[line].name;
      dataTime.uid = staffWorkingTimeData[line].uid;
      setIsShow(true);
      setDataState(dataTime);
    }
    setAnchorEl(null);
  };

  let dataTime = {};
  if (staffWorkingTimeData && staffWorkingTimeData.length > 0) {
    for (let i = 0; i < staffWorkingTimeData.length; i++) {
      if (typeof staffWorkingTimeData[i].endTime === "number") {
        let date = new Date(staffWorkingTimeData[i].startTime * 1000);
        let hours = date.getUTCHours();
        let minutes = "0" + date.getMinutes();
        let formattedTime = hours + ":" + minutes.substr(-2);
        let dateEnd = new Date(staffWorkingTimeData[i].endTime * 1000);
        let hoursEnd = dateEnd.getUTCHours();
        let minutesEnd = "0" + dateEnd.getMinutes();
        let formattedTimeEND = hoursEnd + ":" + minutesEnd.substr(-2);

        staffWorkingTimeData[i].endTime = formattedTimeEND;
        staffWorkingTimeData[i].startTime = formattedTime;
      }
    }
  }

  useEffect(() => {
    if (isRemoveSuccess) {
      dispatch(StaffAction.getWorking());
    }
  }, [isRemoveSuccess]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(StaffAction.getWorking());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessUpdatesWorkingShift) {
      dispatch(StaffAction.getWorking());
    }
  }, [isSuccessUpdatesWorkingShift]);

  useEffect(() => {
    if (!staffWorkingTimeData) {
      dispatch(StaffAction.getWorking());
    }
  }, [staffWorkingTimeData]);

  return (
    <Paper className={classes.paper}>
      <Box>
        <Grid item xs={12} sm={8} className={classes.pageAvaNameSingleRow}>
          <Typography className={clsx("semiBold-lg-txt", classes.header)}>
            {getLabel(LangConstant.TXT_WORK_TIME)}
          </Typography>
          <Box className={classes.headerButton}>
            <Button
              startIcon={<Add className={classes.icon} />}
              className={classes.cancelButton}
              onClick={onChange}
            ></Button>
            <DialogStaffWorkingShift
              isShow={isShow}
              onSubmit={onCloseDialog}
              onClose={onCloseDialog}
              data={dataState}
            />
          </Box>
        </Grid>
      </Box>
      <Box>
        {staffWorkingTimeData &&
          staffWorkingTimeData.map((item, index) => (
            <Grid key={index} item xs={12} sm={8} className={classes.pageTimeBottom}>
              <Typography className={clsx("medium-md-txt", classes.bottom)}>
                {item.name} ( {item.startTime} - {item.endTime})
              </Typography>
              <Box className={classes.headerButton}>
                <Button
                  id={index}
                  className={classes.bottomButton}
                  onClick={onClickAdd}
                  startIcon={<MoreHoriz className={classes.icon} />}
                ></Button>
                {openConfirm && (
                  <ConfirmDialog
                    onSelectConfirm={onConfirm}
                    title={getLabel(LangConstant.TXT_DELETE_WORK_TIME)}
                    titleConfirm={getLabel(LangConstant.TXT_DELETE_WORK_TIME_QUESTION)}
                  />
                )}
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClick}>
                  <MenuItem className={classes.menu} onClick={() => onClick(1)}>
                    {getLabel(LangConstant.TXT_NEWS_POSTLIST_POSTEDITBTN)}
                  </MenuItem>
                  <MenuItem className={classes.menu} onClick={() => onClick(2)}>
                    {getLabel(LangConstant.TXT_DELETE)}
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
          ))}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    minWidth: 200,
    boxShadow: "none",
  },
  menu: {
    width: 215,
    height: 30,
    boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
  },
  bottomButton: {
    minWidth: 40,
  },
  icon: {
    width: 24,
    height: 24,
    textAlign: " center",
    marginLeft: "22%",
  },
  pageAvaNameSingleRow: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginLeft: "8%",
    marginTop: "2%",
    marginRight: "5%",
    paddingBottom: "5%",
    paddingTop: "6%",
    [theme.breakpoints.down(600)]: {},
  },
  pageTimeBottom: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginLeft: "8%",
    marginTop: "2%",
    marginRight: "5%",
    paddingBottom: "2%",
    borderBottom: "solid 1px #d4d5d8 ",
    [theme.breakpoints.down(600)]: {},
  },
  header: {
    color: theme.palette.grey[800],
    width: "65%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  bottom: {
    color: theme.palette.grey[800],
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  headerButton: {
    minWidth: 40,
    height: 24,
    width: "35%",
    textAlign: "right",
  },
  cancelButton: {
    fontWeight: theme.typography.h5.fontWeight,
    minWidth: 40,
    height: 24,
    backgroundColor: theme.palette.reservation.finished,
    borderRadius: 21.5,
    color: theme.palette.white,
    textAlign: " center",
    paddingTop: 5,
    textTransform: "none",
    marginLeft: 0,
    marginRight: "auto",
  },
}));

export default StaffWorkTime;
