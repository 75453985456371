import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import MenuAction from "redux/menu.redux";
import ServiceItemAction from "redux/service-item.redux";
import MnProductAction from "redux/mn-product.redux";
import {
  DialogAddCategorySelectMenu,
  DialogAddItemMenu,
  DialogAddCategoryMenu,
  DialogSelectProduct,
  CategorySubCategoryMenu,
} from "./index";
import { ConfirmDialog, DialogAddCategory, DialogDelete } from "components/dialog";
import { ItemDescriptionBlock } from "components/mn-master-product";
import DialogViewEditMainService from "pages/MnService/components/main-service/DialogViewEditMainService";
import { WatchAndEditCombo } from "pages/MnCombo/components";
import { WatchAndEditProduct } from "pages/MnProduct/components";
import { convertPricePreOrder, formatUsingTime } from "utils";

const MainItemAll = ({ onEnableButton }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();

  const [dataDefault, dataDisplay, dataOrderDefault, dataOrderDisplay, isTabBooking] = useSelector(
    ({ menuRedux }) => [
      menuRedux.dataDefault,
      menuRedux.dataDisplay,
      menuRedux.dataOrderDefault,
      menuRedux.dataOrderDisplay,
      menuRedux.isTabBooking,
    ],
    shallowEqual,
  );
  const [dataServiceItem, dataCategoryServiceItem, isGetCategoryServiceItemSuccess] = useSelector(
    ({ serviceItemRedux }) => [
      serviceItemRedux.dataGetListServiceItem?.data,
      serviceItemRedux.dataCategoryServiceItem,
      serviceItemRedux.isGetCategoryServiceItemSuccess,
    ],
    shallowEqual,
  );
  const [dataCategory, listDataItem, dataItemUidCategory, isGetItemUidCategory, dataDetails] = useSelector(
    ({ mnProductRedux }) => [
      mnProductRedux.data,
      mnProductRedux.dataItem,
      mnProductRedux.dataItemUidCategory,
      mnProductRedux.isGetSuccessItemUidCategory,
      mnProductRedux.dataDetails,
    ],
    shallowEqual,
  );
  const dataCategoryService = useSelector(state => state.serviceCategoryRedux?.data, shallowEqual);
  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem, shallowEqual);

  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [isOpenConfirm, setISOpenConfirm] = useState(false);
  const [line, setLine] = useState();
  const [dataDialog, setDataDialog] = useState(null);
  const [isOpenDialogAddCategory, setIsOpenDialogAddCategory] = useState(false);
  const [isOpen, setIsOpen] = useState({
    selectProduct: false,
    addCategoryMenu: false,
    addFromMainService: false,
    addItemMenu: false,
  });
  const [dataDialogAddCategorySelectMenu, setDataDialogAddCategorySelectMenu] = useState(null);
  const [dataDialogAddItemMenu, setDataDialogAddItemMenu] = useState(null);
  const [nameCategory, setNameCategory] = useState("");
  const [dataDefaultNewDisplay, setDataDefaultNewDisplay] = useState();
  const [dataDisplayNewPost, setDataDisplayNewPost] = useState();
  const [nameDataChildDelete, setNameDataChildDelete] = useState();
  const [dataMenuDisplay, setDataMenuDisplay] = useState([]);
  const [dataMenuDefault, setDataMenuDefault] = useState([]);
  const [viewMode, setViewMode] = useState(VIEW_MODE.default);
  const [dataViewDialog, setDataViewDialog] = useState({});

  const onRemoveChild = (uid, index) => {
    let dataDefaultNew = dataMenuDefault.menuList[index],
      dataDisplayNew = dataMenuDisplay.menuList[index],
      data = [],
      dataPost = [];
    let dataNew = dataDefaultNew.itemList.filter(data => (data.uid || data.itemUid || data.key) !== uid);
    let dataNewDisplay = dataDisplayNew.itemList.filter(data => (data.uid || data.key) !== uid);
    let dataChildDelete = dataDisplayNew.itemList.find(data => (data.uid || data.key) === uid) || {};
    setNameDataChildDelete(dataChildDelete.name);
    data = [...dataMenuDisplay.menuList];
    dataPost = [...dataMenuDefault.menuList];
    data[index] = { ...data[index], itemList: dataNewDisplay };
    dataPost[index] = { ...dataPost[index], itemList: dataNew };
    setDataDefaultNewDisplay({ ...dataMenuDisplay, menuList: data });
    setDataDisplayNewPost({ ...dataMenuDefault, menuList: dataPost });
    setISOpenConfirm(true);
  };

  const onConfirmRemoveChild = (event, isAgree) => {
    event.stopPropagation();
    if (isAgree) {
      onEnableButton(true);
      if (isTabBooking) {
        dispatch(MenuAction.menuSuccess({ dataDefault: dataDisplayNewPost, dataDisplay: dataDefaultNewDisplay }));
      } else {
        dispatch(
          MenuAction.menuSuccess({ dataOrderDefault: dataDisplayNewPost, dataOrderDisplay: dataDefaultNewDisplay }),
        );
      }
    }
    setISOpenConfirm(false);
  };

  const onClickCategorySubcategoryMenu = (value, index) => {
    if (value === ACTION_MENU.edit) {
      setDataDialog(dataMenuDisplay.menuList[index]);
      setIsOpenDialogAddCategory(true);
      setLine(index);
    } else if (value === ACTION_MENU.delete) {
      setDataDialog(dataMenuDisplay.menuList[index]);
      setLine(index);
      setIsOpenDialogDelete(true);
    } else if (value === ACTION_MENU.add) {
      setLine(index);
      setDataDialog(dataMenuDisplay.menuList[index]);
      setIsOpen({ ...isOpen, selectProduct: true });
    }
  };

  const onClose = () => {
    setViewMode(VIEW_MODE.default);
    setIsOpenDialogAddCategory(false);
    setIsOpenDialogDelete(false);
    setIsOpen({ selectProduct: false, addCategoryMenu: false, addFromMainService: false, addItemMenu: false });
  };

  const onChangeNameCategory = value => {
    setNameCategory(value);
  };

  const onSubmitCategory = () => {
    dataMenuDefault.menuList[line] = { ...dataMenuDefault.menuList[line], name: nameCategory };
    dataMenuDisplay.menuList[line] = { ...dataMenuDisplay.menuList[line], name: nameCategory };
    if (isTabBooking) {
      dispatch(MenuAction.menuSuccess({ dataDefault: dataMenuDefault, dataDisplay: dataMenuDisplay }));
    } else {
      dispatch(MenuAction.menuSuccess({ dataOrderDefault: dataMenuDefault, dataOrderDisplay: dataMenuDisplay }));
    }
    setIsOpenDialogAddCategory(false);
    setDataDialog({ name: "" });
    onEnableButton(true);
  };

  const onConfirmRemoveProduct = () => {
    let dataNew = dataMenuDefault.menuList.filter((_, index) => index !== line);
    let dataNewDisplay = dataMenuDisplay.menuList.filter((_, index) => index !== line);
    if (isTabBooking) {
      dispatch(
        MenuAction.menuSuccess({
          dataDefault: { ...dataMenuDefault, menuList: dataNew },
          dataDisplay: { ...dataMenuDisplay, menuList: dataNewDisplay },
        }),
      );
    } else {
      dispatch(
        MenuAction.menuSuccess({
          dataOrderDefault: { ...dataMenuDefault, menuList: dataNew },
          dataOrderDisplay: { ...dataMenuDisplay, menuList: dataNewDisplay },
        }),
      );
    }
    setIsOpenDialogDelete(false);
    onEnableButton(true);
  };

  const onCloseAccompaniedProduct = value => {
    setIsOpen({ ...isOpen, selectProduct: value });
  };

  const onCloseDialogAddCategoryMenu = value => {
    setIsOpen({ ...isOpen, selectProduct: value, addCategoryMenu: value });
  };

  const onCloseDialogAddCategorySelectMenu = () => {
    setIsOpen({ ...isOpen, selectProduct: false, addCategoryMenu: false, addFromMainService: false });
  };

  const onCreateService = () => {
    if (Object.values(dataCategoryService).length > 0) {
      setIsOpen({ ...isOpen, addCategoryMenu: true, service: true });
    } else {
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_SERVICE_MENU),
        nameParent: null,
        nameChild: null,
        addItemMenu: true,
        combo: false,
        service: true,
        type: AppConstant.TYPE_CATEGORY.service,
      });
      setDataDialogAddItemMenu(dataServiceItem);
    }
  };

  const onCreateProduct = () => {
    if (dataCategory?.data.length > 0) {
      setIsOpen({ ...isOpen, addCategoryMenu: true, service: false });
    } else {
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_GOODS_MENU),
        nameParent: null,
        nameChild: null,
        addItemMenu: true,
        combo: false,
        service: false,
        type: AppConstant.TYPE_CATEGORY.product,
      });
      setDataDialogAddItemMenu(listDataItem.data);
    }
  };

  const onCreateCombo = () => {
    setIsOpen({
      ...isOpen,
      nameTable: getLabel(LangConstant.TXT_ADD_COMBO_MENU),
      nameParent: null,
      nameChild: null,
      addItemMenu: true,
      combo: true,
      service: false,
      type: AppConstant.TYPE_CATEGORY.combo,
    });
    setDataDialogAddItemMenu(listComboItem.data);
  };

  const onAddAllService = () => {
    if (isOpen.service) {
      setIsOpen({
        ...isOpen,
        addItemMenu: true,
        nameTable: getLabel(LangConstant.TXT_ADD_SERVICE_MENU),
        combo: false,
        type: AppConstant.TYPE_CATEGORY.service,
      });
      setDataDialogAddItemMenu(dataServiceItem);
    } else {
      setIsOpen({
        ...isOpen,
        addItemMenu: true,
        nameTable: getLabel(LangConstant.TXT_ADD_GOODS_MENU),
        combo: false,
        type: AppConstant.TYPE_CATEGORY.product,
      });
      setDataDialogAddItemMenu(listDataItem.data);
    }
  };

  const onAddCategoryService = () => {
    if (isOpen.service) {
      setIsOpen({ ...isOpen, addFromMainService: true, service: true });
      setDataDialogAddCategorySelectMenu(Object.values(dataCategoryService));
    } else {
      setIsOpen({ ...isOpen, addFromMainService: true, service: false });
      setDataDialogAddCategorySelectMenu(dataCategory.data);
    }
  };

  const onBackDialogAddCategorySelectMenu = () => {
    setIsOpen({ ...isOpen, addFromMainService: false });
  };

  const onBackDialogAddItemMenu = () => {
    setIsOpen({ ...isOpen, addItemMenu: false });
  };

  const onBackDialogAddCategoryMenu = () => {
    setIsOpen({ ...isOpen, addCategoryMenu: false });
  };

  const onClickCategoryMenu = (data, dataParent) => {
    if (isOpen.service) {
      dispatch(ServiceItemAction.resetServiceItem());
      dispatch(ServiceItemAction.getCategoryServiceItem({ categoryUid: data.uid }));
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_SERVICE_MENU),
        nameParent: dataParent?.name,
        nameChild: data.name,
      });
    } else {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItemUidCategory({ categoryUid: data.uid }));
      setIsOpen({
        ...isOpen,
        nameTable: getLabel(LangConstant.TXT_ADD_GOODS_MENU),
        nameParent: dataParent?.name,
        nameChild: data.name,
      });
    }
  };

  const onOpenViewItem = uid => {
    let dataChild = {};
    // Get all list items
    let arrItems = dataMenuDisplay.menuList.reduce((result, category) => {
      let isFirstHandling = !Array.isArray(result);
      let list = (isFirstHandling ? result.itemList : result) || [];
      return list.concat(category.itemList || []);
    });

    // Get selected item
    dataChild = arrItems.find(data => (data.uid || data.key) === uid) || {};

    if (dataChild.type === AppConstant.TYPE_CATEGORY.service) {
      setViewMode(VIEW_MODE.service);
    } else if (dataChild.type === AppConstant.TYPE_CATEGORY.product) {
      setViewMode(VIEW_MODE.product);
    } else if (dataChild.type === AppConstant.TYPE_CATEGORY.combo) {
      setViewMode(VIEW_MODE.combo);
    }
    setDataViewDialog(dataChild);
  };

  useEffect(() => {
    if (isGetCategoryServiceItemSuccess) {
      setDataDialogAddItemMenu(dataCategoryServiceItem);
      setIsOpen({ ...isOpen, addItemMenu: true, combo: false, type: AppConstant.TYPE_CATEGORY.service });
    }
    if (isGetItemUidCategory) {
      setDataDialogAddItemMenu(dataItemUidCategory.data);
      setIsOpen({ ...isOpen, addItemMenu: true, combo: false, type: AppConstant.TYPE_CATEGORY.product });
    }
  }, [isGetCategoryServiceItemSuccess, isGetItemUidCategory]);

  useEffect(() => {
    if (isTabBooking && Boolean(dataDisplay.menuList)) {
      setDataMenuDisplay(dataDisplay);
      setDataMenuDefault(dataDefault);
    } else if (!isTabBooking && Boolean(dataOrderDisplay.menuList)) {
      setDataMenuDisplay(dataOrderDisplay);
      setDataMenuDefault(dataOrderDefault);
    }
  }, [dataDisplay, dataOrderDisplay, dataMenuDisplay, isTabBooking]);

  return (
    <Box className={classes.barRoot}>
      <Box className={classes.boxBody}>
        {Boolean(dataMenuDisplay.menuList) &&
          dataMenuDisplay.menuList.map((item, index) => (
            <Box key={index}>
              <CategorySubCategoryMenu
                classes={{ root: classes.boxShadowMenu }}
                data={item}
                index={index}
                onClick={onClickCategorySubcategoryMenu}
              />
              <Grid container spacing={3} className={classes.listProduct}>
                {item?.itemList.length > 0 &&
                  item?.itemList.map((dataMap, indexChild) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={indexChild}>
                      <ItemDescriptionBlock
                        data={dataMap}
                        amount={getLabel(LangConstant.FM_ITEM_STOCK, { stock: dataMap.stock || 0 })}
                        time={formatUsingTime(dataMap, getLabel)}
                        price={convertPricePreOrder(dataMap)}
                        onChoose={() => onRemoveChild(dataMap.uid || dataMap.key, index)}
                        onOpenDetail={onOpenViewItem}
                        typeButton={AppConstant.TYPE_BUTTON_CATEGORY.removedSingle}
                        disabled={dataMap.isDisabled}
                        classes={{
                          root: classes.gridViewContent,
                          chooseItem: classes.chooseItem,
                          prefixContentCustom: classes.prefixContentCustom,
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ))}
      </Box>
      {isOpenDialogAddCategory && (
        <DialogAddCategory
          isShow={isOpenDialogAddCategory}
          data={dataDialog}
          onClose={onClose}
          onChange={onChangeNameCategory}
          onSubmit={onSubmitCategory}
        />
      )}
      {isOpenDialogDelete && (
        <DialogDelete
          uid={dataDialog?.key || dataDialog.uid}
          isShow={isOpenDialogDelete}
          onCancel={onClose}
          onConfirm={onConfirmRemoveProduct}
          title={StringFormat(getLabel(LangConstant.FN_DELETE_CATEGORY_MENU_TITLE), dataDialog?.name)}
          content={StringFormat(getLabel(LangConstant.FN_DELETE_CATEGORY_MENU_CONTENT), dataDialog?.name)}
        />
      )}
      {isOpen.selectProduct && (
        <DialogSelectProduct
          isShow={isOpen.selectProduct}
          titleName={{
            header: getLabel(LangConstant.TXT_SELECT_PRODUCT_TYPE),
            service: getLabel(LangConstant.TXT_SERVICE),
            product: getLabel(LangConstant.TXT_PRODUCT),
            combo: getLabel(LangConstant.TXT_COMBO),
          }}
          action={onCloseAccompaniedProduct}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddService={onCreateService}
          onAddProduct={onCreateProduct}
          onAddCombo={onCreateCombo}
        />
      )}
      {isOpen.addCategoryMenu && (
        <DialogAddCategoryMenu
          isShow={isOpen.addCategoryMenu}
          titleName={{
            header: isOpen.service
              ? getLabel(LangConstant.TXT_SELECT_ADD_SERVICE)
              : getLabel(LangConstant.TXT_SELECT_ADD_PRODUCT),
            add: isOpen.service
              ? getLabel(LangConstant.TXT_SELECT_ADD_ALL_SERVICE)
              : getLabel(LangConstant.TXT_SELECT_ADD_ALL_PRODUCT),
            created: isOpen.service
              ? getLabel(LangConstant.TXT_SELECT_ADD_CATEGORY_SERVICE)
              : getLabel(LangConstant.TXT_SELECT_ADD_CATEGORY_PRODUCT),
          }}
          onClose={onCloseDialogAddCategoryMenu}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddAllService={onAddAllService}
          onAddCategoryService={onAddCategoryService}
          onBack={onBackDialogAddCategoryMenu}
        />
      )}
      {isOpen.addFromMainService && (
        <DialogAddCategorySelectMenu
          data={dataDialogAddCategorySelectMenu}
          isOpen={isOpen.addFromMainService}
          onClose={onCloseDialogAddCategorySelectMenu}
          onBack={onBackDialogAddCategorySelectMenu}
          onClick={onClickCategoryMenu}
          nameHeader={getLabel(LangConstant.TXT_SELECT_CATEGORY)}
          isChildDefault={false}
        />
      )}
      {isOpen.addItemMenu && (
        <DialogAddItemMenu
          isOpen={isOpen.addItemMenu}
          onClose={onClose}
          data={dataDialogAddItemMenu}
          nameTable={isOpen.nameTable}
          nameParent={isOpen.nameParent}
          nameChild={isOpen.nameChild}
          onBack={onBackDialogAddItemMenu}
          lineData={line}
          isProduct={isOpen.service}
          isCombo={isOpen.combo}
          type={isOpen.type}
        />
      )}
      {isOpenConfirm && (
        <ConfirmDialog
          isShow={isOpenConfirm}
          onSelectConfirm={onConfirmRemoveChild}
          title={StringFormat(getLabel(LangConstant.TXT_CONFIRM_DELETE_DIALOG_TITLE), nameDataChildDelete)}
          titleConfirm={StringFormat(
            getLabel(LangConstant.TXT_CONFIRM_DELETE_DIALOG_TITLE_CONFIRM),
            nameDataChildDelete,
          )}
        />
      )}
      {viewMode === VIEW_MODE.service && (
        <DialogViewEditMainService data={dataViewDialog} isOpen={true} onClose={onClose} isShowEdit={false} />
      )}
      {viewMode === VIEW_MODE.product && (
        <WatchAndEditProduct
          data={dataViewDialog}
          dataCategory={dataCategory?.data}
          dataDetailsView={dataDetails?.data}
          isOpenWatch={true}
          onClose={onClose}
          isShowEdit={false}
        />
      )}
      {viewMode === VIEW_MODE.combo && (
        <WatchAndEditCombo data={dataViewDialog} isOpenWatch={true} onClose={onClose} isShowEdit={false} />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  barRoot: {
    width: "100%",
  },
  boxBody: {
    minHeight: "calc(100vh - 380px)",
  },
  tabSelect: {
    paddingTop: 30,
    paddingLeft: 30,
    width: "100%",
  },
  bottomSelect: {
    marginLeft: "25%",
    display: "flex",
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
  },
  listProduct: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  gridViewContent: {
    minWidth: "unset",
  },
  chooseItem: {
    height: 24,
    padding: 0,
    minWidth: 61,
    fontSize: 11,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: theme.palette.primary["main"],
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
    "&.Mui-disabled": {
      opacity: 0.6,
      color: theme.palette.white,
    },
  },
  prefixContentCustom: {
    display: "flex",
    alignItems: "flex-start",
    color: theme.palette.text.link,
  },
  boxShadowMenu: {
    display: "flex",
    with: "80%",
    height: 40,
    alignItems: "center",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: 20,
    marginBottom: 20,
    border: "1px solid" + theme.palette.grey[200],
  },
}));

MainItemAll.propTypes = {
  onClick: PropTypes.func,
};

export default MainItemAll;

const ACTION_MENU = { add: "add", edit: "edit", delete: "delete" };

const VIEW_MODE = {
  default: 0,
  service: 1,
  product: 2,
  combo: 3,
};
