import { ApiConstant } from "../const";
import { createApiRegistration, createApiRegistrationCustom } from "../api";

export const login = data =>
  createApiRegistration().post(ApiConstant.POST_LOGIN, data);

export const forgotPassword = data => createApiRegistration().post(ApiConstant.POST_RESET_PASSWORD, { email: data });

export const patchInvitationAccept = data =>
  createApiRegistrationCustom(data.cons_token).patch(ApiConstant.PATCH_INVITATION_ACCEPT, data.uids);
