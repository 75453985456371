import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  withStyles,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey } from "utils";
import CustomStepperOrder from "./CustomStepperOrder";
import StatusDialogBillDetail from "./StatusDialogBillDetail";

const StatusDialogOrder = ({ dataShow, onSubmit, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);

  const [selectedStatus, setSelectedStatus] = useState(dataShow.status);

  const onSubmitSelectedStatus = () => {
    onSubmit(selectedStatus);
    onClose();
  };

  return (
    <Dialog open={true} maxWidth="sm" classes={{ paper: classes.dialogPaper }}>
      <DialogContent className={classes.dialogContent}>
        <Header
          title={StringFormat(getLabel(LangConstant.FM_CHANGE_VAT_BILL_STATUS), dataShow.reservation?.code || "")}
          action={
            <IconButton aria-label="close" onClick={onClose} color="inherit">
              <Close color="inherit" />
            </IconButton>
          }
        />
        <StatusDialogBillDetail data={dataShow} />
        <CustomStepperOrder status={dataShow.status} selectedStatus={selectedStatus} onChange={setSelectedStatus} />
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonCancel, "medium-md-txt")}
            onClick={onClose}
          >
            {getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.button, classes.buttonAccept, "medium-md-txt")}
            onClick={onSubmitSelectedStatus}
          >
            {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const Header = withStyles(theme => ({
  root: {
    position: "relative",
    padding: "16px 24px",
    paddingRight: 40,
    background: theme.palette.secondary.dark,
    color: theme.palette.white,
    minHeight: 50,
  },

  action: {
    flex: "none",
    position: "absolute",
    right: 12,
  },

  title: {
    ...theme.typography.body1,
    fontSize: 18,
    color: "white",
  },
}))(CardHeader);

StatusDialogOrder.propTypes = {
  dataShow: PropTypes.object,

  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

StatusDialogOrder.defaultProps = {};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    borderRadius: 8,
  },

  dialogContent: {
    padding: 0,
    position: "relative",
    borderRadius: "unset",
    boxShadow: "none",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    overflowY: "unset",
    minWidth: 530,
    [theme.breakpoints.down("sm")]: {
      height: 365,
    },
    "&:first-child": {
      paddingTop: 0,
    },
  },

  button: {
    height: 30,
    textTransform: "none",
    minHeight: 20,
    width: 60,
    borderRadius: 2,
  },

  buttonCancel: {
    color: theme.palette.white,
    backgroundColor: "#b3b5ba",
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#b3b5ba",
    },
  },

  dialogActions: {
    padding: "20px 24px",
  },
}));

export default memo(StatusDialogOrder);
