import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableSortLabel } from "@material-ui/core";
import { SwapVert } from "@material-ui/icons";
import PropTypes from "prop-types";

const CellHead = props => {
  const classes = useStyles();
  const { style, cellData, textAlign, className, onSort, isSort, ...otherProps } = props;

  return (
    <TableCell
      align={textAlign}
      className={`medium-md-txt ${className}`}
      classes={{
        root: classes.root,
      }}
      style={style}
      {...otherProps}
    >
      {cellData}
      {isSort && (
        <TableSortLabel onClick={onSort} classes={{ icon: classes.iconSort }}>
          <SwapVert className={classes.iconSwag} />
        </TableSortLabel>
      )}
    </TableCell>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    padding: "8px 20px",
    minWidth: 180,
  },
  iconSort: {
    display: "none",
  },
  iconSwag: {
    marginLeft: 10,
    opacity: 1,
    fill: "#4b4d53",
  },
}));

CellHead.propTypes = {
  cellData: PropTypes.any,
  style: PropTypes.object,
  textAlign: PropTypes.string,
  className: PropTypes.string,
  onSort: PropTypes.func,
  isSort: PropTypes.bool,
};

CellHead.defaultProps = {
  style: {},
  textAlign: "center",
  className: "",
  isSort: false,
};

export default memo(CellHead);
