import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { LangConstant } from "../../../../../../const";
import { useTranslation } from "react-i18next";

const LineTitle = ({ isExtend }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Box className={classes.rootHeader}>
      <Box width="164px">
        <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_PLACE_CODE)}</Typography>
      </Box>
      <Box width="191px">
        <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_TIME)}</Typography>
      </Box>
      <Box width="58px">
        <Typography className={classes.rootTypoHeader}>
          {!isExtend ? getLabel(LangConstant.TXT_ACCEPT) : getLabel(LangConstant.TXT_AVAILABLE)}
        </Typography>
      </Box>
    </Box>
  );
};

export default LineTitle;

const useStyles = makeStyles(() => ({
  rootHeader: {
    display: "flex",
    height: 20,
    marginBottom: 9,
    padding: "0px 20px 0px 24px",
  },
  rootTypoHeader: {
    fontSize: 12,
    color: "#3e4045",
    fontWeight: 600,
    height: 20,
    paddingTop: 2,
  },
}));
