import React, { memo, useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Cookie from "js-cookie";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import { Grid, useMediaQuery, Paper, makeStyles, Box, Typography, Button, useTheme } from "@material-ui/core";
import ReservationAction from "redux/reservation-setting.redux";
import ShopAction from "redux/shop.redux";
import ShopInfoAction from "redux/shop-info.redux";
import { BookingShopInfo, BookingInfo } from "components/mnbooking";
import { LangConstant, AppConstant } from "const";
import { onCloseBrowser, uuid } from "utils";
import BookingPagePreviewComponent from "./PreviewBookingPage";

const MnBooking = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const theme = useTheme();
  const dataShopRedux = useSelector(state => state.shopRedux);
  const dataShop = dataShopRedux.dataShop;
  const dataReservation = useSelector(state => state.reservationSettingRedux);
  const dataReservationSetting = dataReservation.dataSetting;
  const dispatch = useDispatch();
  const [retry, setRetry] = useState({ countShop: 1, countReser: 1 });
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  var shopId = null;
  const [previewData, setPreviewData] = useState(dataReservationSetting);
  const [previewDataShop, setPreviewDataShop] = useState(dataShop ? dataShop : {});
  const isNotMobile = useMediaQuery(theme.breakpoints.up("md"));
  const [isEnableSave, setIsEnableSave] = useState(false);
  const [checkOnChange, setCheckOnChange] = useState();

  const onUpdatePreviewData = (name, value, type, arr) => {
    setCheckOnChange(uuid());
    if (name !== AppConstant.KEY_BOOK_CUSTOMER_CHILD) {
      setIsEnableSave(true);
    }
    if (type === "batchState") {
      let clone = previewData;
      for (let name of arr) {
        clone[name].publish = dataReservationSetting[name].publish;
      }
      setPreviewData({
        ...clone,
      });
    } else if (type === "details") {
      setPreviewData({
        ...previewData,
        [name]: {
          ...previewData[name],
          details: {
            guideNote: { ...previewData[name].details.guideNote, content: value },
          },
        },
      });
    } else if (type === AppConstant.KEY_BOOK_USING_TIME) {
      setPreviewData({
        ...previewData,
        [name]: {
          ...previewData[name],
          details: { timeFrame: value },
        },
      });
    } else if (name === AppConstant.KEY_BOOK_USING_TIME) {
      setPreviewData({
        ...previewData,
        [name]: {
          ...previewData[name],
          publish: 0,
        },
      });
    } else if (name === AppConstant.KEY_BOOK_USING_TIME_SETTING_TIME) {
      setPreviewData({
        ...previewData,
        [AppConstant.KEY_BOOK_USING_TIME]: {
          ...previewData[AppConstant.KEY_BOOK_USING_TIME],
          publish: 1,
        },
      });
    } else if (name === AppConstant.KEY_BOOK_CUSTOMER) {
      setPreviewData({
        ...previewData,
        [name]: {
          ...previewData[name],
          publish: value ? 1 : 0,
          details: { content: "", child: {} },
        },
      });
    } else if (
      name === AppConstant.KEY_BOOK_CUSTOMER_CHILD &&
      Boolean(previewData[AppConstant.KEY_BOOK_CUSTOMER]?.publish)
    ) {
      setIsEnableSave(true);
      setPreviewData({
        ...previewData,
        [AppConstant.KEY_BOOK_CUSTOMER]: {
          ...previewData[AppConstant.KEY_BOOK_CUSTOMER],
          details: { content: "", child: { publish: value ? 1 : 0, content: "" } },
        },
      });
    } else {
      setPreviewData({
        ...previewData,
        [name]: {
          ...previewData[name],
          publish: value === true ? 1 : 0,
        },
      });
    }
  };

  const onUpdatePreviewDataShop = (name, value, type) => {
    setIsEnableSave(true);
    setCheckOnChange(uuid());
    if (type === "batch") {
      setPreviewDataShop(value);
    } else {
      setPreviewDataShop({
        ...previewDataShop,
        [name]: value,
      });
    }
  };

  const onAcceptAdvance = () => {
    let result = AppConstant.SETTING_ADVANCES.filter(value => {
      if (
        (value === AppConstant.KEY_BOOK_CUSTOMER && Boolean(previewData[value]?.details.publish)) ||
        value === AppConstant.KEY_BOOK_NOTE ||
        (value === AppConstant.KEY_BOOK_USING_TIME && Boolean(previewData[value].publish)) ||
        dataReservationSetting[value].publish !== previewData[value].publish
      ) {
        return value;
      }
    }).map(value => previewData[value]);

    dispatch(ReservationAction.requestUpdateReservationSetting(result));
    dispatch(ShopInfoAction.updateShopInfoData(previewDataShop));
    setIsEnableSave(false);
  };

  const onAcceptBasic = () => {
    let result = AppConstant.SETTING_BASICS.filter(value => {
      if (dataReservationSetting[value].details.guideNote.content !== previewData[value].details.guideNote.content) {
        return value;
      }
    }).map(value => previewData[value]);
    dispatch(ReservationAction.requestUpdateReservationSetting(result));
  };

  const onChangeImage = file => {
    setIsEnableSave(true);
    setPreviewDataShop({ ...previewDataShop, cover: file.toString(), avatar: file.toString() });
  };

  useEffect(() => {
    setPreviewData(dataReservationSetting);
  }, [dataReservationSetting]);

  useEffect(() => {
    setPreviewDataShop(dataShop !== null ? dataShop : {});
  }, [dataShop]);

  useEffect(() => {
    let isSubscribed = true;
    if (appData && appData.length > 0) {
      shopId = JSON.parse(appData).shopId;
      if (!dataShopRedux.isFetching && retry.countShop <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
        if (isSubscribed) setRetry({ ...retry, countShop: ++retry.countShop });
        dispatch(ShopAction.requestGetShopInfo({ data: { shopId: shopId } }));
      }
      if (!dataReservation.isFetchingSetting && retry.countReser <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
        if (isSubscribed) setRetry({ ...retry, countReser: ++retry.countReser });
        dispatch(ReservationAction.requestGetReservationSetting({}));
      }
    }
    return () => (isSubscribed = false);
  }, []);

  useEffect(() => {
    if (previewData && previewDataShop) {
      let result = ARR_DEFAULT.map(value => previewData[value]);
      let dataShopPreviewTimeOut = setTimeout(() => {
        dispatch(ReservationAction.requestUpdateReservationSetting(result));
        dispatch(ShopInfoAction.updateShopInfoData(previewDataShop));
      }, 1000);
      return () => clearTimeout(dataShopPreviewTimeOut);
    }
  }, [checkOnChange]);

  useEffect(() => {
    dispatch(ShopInfoAction.getShopInfoData({}));
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (dataShop === null && retry.countReser <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
      setTimeout(() => {
        if (isSubscribed) setRetry({ ...retry, countShop: ++retry.countShop });
      }, AppConstant.VALUE_RETRY_REQUEST_TIME_BASE * retry.countShop);
    }
    if (
      dataReservationSetting === null &&
      !retry.reserSetting &&
      retry.countReser <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST
    ) {
      setTimeout(() => {
        if (isSubscribed) setRetry({ ...retry, countReser: ++retry.countReser });
      }, AppConstant.VALUE_RETRY_REQUEST_TIME_BASE * retry.countReser);
    }
    return () => (isSubscribed = false);
  }, [retry]);

  useEffect(() => {
    if (isEnableSave) {
      window.addEventListener("beforeunload", onCloseBrowser);
      return () => {
        // handling will unmounted component
        window.removeEventListener("beforeunload", onCloseBrowser);
      };
    }
  }, [isEnableSave]);

  return (
    <Box>
      <Box className={classes.header}>
        <Typography className={classes.textHeader}>{getLabel(LangConstant.TXT_SETTING_BOOKING)}</Typography>
        <Button
          onClick={onAcceptAdvance}
          classes={{ root: `semiBold-lg-txt ${classes.buttonSave}`, disabled: classes.disabledSaveButton }}
          disabled={!isEnableSave}
          id={SAVE_BUTTON_BOOKING_SETTING}
        >
          <Typography className={classes.typography}>{getLabel(LangConstant.TXT_SAVE)}</Typography>
        </Button>
      </Box>
      <Paper className="mn-booking">
        <Grid container direction={isNotMobile ? "row" : "column"} spacing={1}>
          {previewData !== null && (
            <>
              <Grid item xs={isNotMobile ? 6 : 12} container direction="column" spacing={1}>
                <Grid item>
                  <BookingShopInfo
                    data={dataShop !== null ? dataShop : {}}
                    previewData={previewDataShop}
                    onUpdatePreviewData={onUpdatePreviewDataShop}
                    onAccept={onChangeImage}
                  />
                </Grid>
              </Grid>
              <Grid item xs={isNotMobile ? 6 : 12} container direction="column" spacing={1}>
                <Grid item>
                  <BookingInfo
                    data={dataReservationSetting}
                    previewData={previewData}
                    onUpdatePreviewData={onUpdatePreviewData}
                    onAccept={onAcceptBasic}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Box className={classes.preview}>
          <DesktopMacIcon className={classes.icon} />
          <Typography className={classes.textPreview}>{getLabel(LangConstant.TXT_PREVIEW_ON_DESKTOP)}</Typography>
        </Box>
        <Grid item xs={12} container direction="column" spacing={1}>
          <BookingPagePreviewComponent displayCondition={previewData} displayData={previewDataShop} />
        </Grid>
        <Prompt when={isEnableSave} message={getLabel(LangConstant.TXT_NOTIFICATION_MESSAGE)} />
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    padding: 30,
    display: "flex",
    borderBottom: "1px solid #F1F3F6",
    backgroundColor: theme.palette.white,
  },
  textHeader: {
    float: "left",
    width: "95%",
    paddingTop: 6,
    color: theme.palette.black,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      width: "85%",
    },
  },
  buttonSave: {
    textTransform: "none",
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    float: "right",
    height: 40,
    width: 78,
    [theme.breakpoints.down(600)]: {
      width: "15%",
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "#ef5845",
    },
  },
  iframe: {
    width: "100%",
    height: "100vh",
  },
  preview: {
    backgroundColor: "#65B39D",
    height: 40,
    display: "flex",
    paddingLeft: 50,
    paddingTop: 10,
    marginBottom: 4,
  },
  icon: {
    color: theme.palette.white,
    marginRight: 15,
  },
  textPreview: {
    color: theme.palette.white,
  },
  disabledSaveButton: {
    opacity: 0.6,
  },
  typography: {
    color: theme.palette.white,
  },
}));

const SAVE_BUTTON_BOOKING_SETTING = "save-button-booking-setting";

export default memo(MnBooking);

const ARR_DEFAULT = [
  AppConstant.KEY_BOOK_USING_TIME,
  AppConstant.KEY_BOOK_PERIODIC,
  AppConstant.KEY_BOOK_CUSTOMER,
  AppConstant.KEY_BOOK_NOTE,
];
