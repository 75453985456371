import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, useTheme, makeStyles, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PersonIcon from "@material-ui/icons/Person";

const TotalComponent = ({ total, consumerCapacity }) => {
  const theme = useTheme();
  const classes = useStyles();
  const totalConsumer = total.consumer ? total.consumer.total : 0;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%">
      <CheckCircleIcon
        classes={{ root: classes.checkCircleIcon }}
        style={{ color: theme.palette.secondary.main, fontSize: 18 }}
      />
      <Typography
        classes={{
          body1: "regular-sm-txt",
        }}
      >
        {total.reservationCount}
      </Typography>
      <PersonIcon
        classes={{ root: classes.personIcon }}
        style={{ color: theme.palette.secondary.main, fontSize: 18 }}
      />
      <Typography
        classes={{
          body1: "regular-sm-txt",
        }}
      >
        {totalConsumer || 0}
        {consumerCapacity ? `/${consumerCapacity}` : ""}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  checkCircleIcon: {
    marginRight: 5,
  },
  personIcon: {
    marginRight: 5,
    marginLeft: 30,
  },
}));

TotalComponent.protoType = {
  total: PropTypes.object.isRequired,
  consumerCapacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TotalComponent.defaultProps = {
  total: {
    consumer: { total: 0, child: 0, man: 0 },
    reservationCount: 0,
  },
};

export default memo(TotalComponent);
