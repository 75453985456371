import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { Box, makeStyles } from "@material-ui/core";
import { TotalComponent, GirdTime } from "../index";
import { AppConstant } from "const";
import DayHeader from "./DayHeader";
import { HTML_CONTAINER_ID, RESERVATION_BOX_ID } from "pages/MnReservation";

const BookingByDay = ({ selectedDate, optionView }) => {
  const classes = useStyles();
  const data = useSelector(({ reservationRedux }) => reservationRedux.reservationDay, shallowEqual);
  const consumerCapacity = useSelector(({ shopInfoRedux }) => shopInfoRedux.data?.consumerCapacity);

  const [defaultMaxRow, setDefaultMaxRow] = useState(DEFAULT_MAX_ROW);
  const [arrayColumns, setArrayColumns] = useState([]);
  const [isScroll, setIsScroll] = useState(false);

  let maxRow = defaultMaxRow;
  Object.values(data).forEach(value => {
    if (value.total.reservationCount > maxRow) {
      maxRow = value.total.reservationCount;
    }
  });

  const sizeScreen = screen.width;
  const history = useHistory();
  let location = useLocation();
  const removeDisplayDetail = () => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams) {
      searchParams.delete(AppConstant.KEY_START_TIME);
      searchParams.delete(AppConstant.KEY_UID);
      history.replace({
        search: searchParams.toString(),
      });
    }
  };
  const onControlHiddenColumns = selectedIndex => {
    removeDisplayDetail();
    let newArray = [...arrayColumns];
    let column = newArray[selectedIndex];
    column.isHidden = !column.isHidden;
    setArrayColumns(newArray);
  };

  useEffect(() => {
    if (sizeScreen && Object.keys(data).length > 0) {
      let minColumnNumber = 0;
      if (sizeScreen >= AppConstant.SIZE_SCREEN_XL) {
        minColumnNumber = Math.ceil((sizeScreen - 300) / WIDTH_COLUMN) + 1;
        setDefaultMaxRow(6);
      } else {
        minColumnNumber = MIN_COLUMN_NUMBER;
        setDefaultMaxRow(DEFAULT_MAX_ROW);
      }
      let optimize = onOptimizeData(data, minColumnNumber);
      setArrayColumns(optimize);
    }
  }, [data]);

  const onScroll = () => {
    setIsScroll(true);
  };

  return (
    <Box className={classes.dayContent} id={HTML_CONTAINER_ID}>
      <ScrollSync>
        <Box>
          <ScrollSyncPane group="horizontal">
            <Box className={classes.boxHeader}>
              <DayHeader data={arrayColumns} onControlHiddenColumns={onControlHiddenColumns} />
            </Box>
          </ScrollSyncPane>
          <ScrollSyncPane group="horizontal">
            <Box
              className={isScroll ? classes.overflow : classes.overflowHidden}
              id={RESERVATION_BOX_ID}
              onScroll={onScroll}
            >
              <Box className={classes.dayBodyWrap}>
                {arrayColumns.map(columnData => {
                  let isShowHidden = !columnData.isHidden && columnData.emptyData;
                  let arrayRender = isShowHidden ? columnData.emptyData : [columnData];
                  return arrayRender.map(({ reservation }, index) => (
                    <GirdTime
                      key={index}
                      reservationList={reservation}
                      maxRow={maxRow}
                      selectedDate={selectedDate}
                      optionView={optionView}
                    />
                  ));
                })}
              </Box>
            </Box>
          </ScrollSyncPane>
          <ScrollSyncPane group="horizontal">
            <Box className={classes.boxFooter}>
              <Box className={classes.dayFooter}>
                {arrayColumns.map(columnData => {
                  let isShowHidden = !columnData.isHidden && columnData.emptyData;
                  let arrayRender = isShowHidden ? columnData.emptyData : [columnData];
                  return arrayRender.map(({ total, reservation }, index) => {
                    let displayReservation = reservation.filter(item =>
                      AppConstant.STT_RESERVATION_ACTIVE.includes(item.status),
                    );
                    let totalConsumer = { ...total, reservationCount: displayReservation.length };
                    let totalCons = 0;

                    displayReservation.map(item => (totalCons += item.totalConsumer));
                    totalConsumer.consumer.total = totalCons;
                    return (
                      <Box width={WIDTH_COLUMN} key={index} className={classes.columnDayFooter}>
                        <TotalComponent total={totalConsumer} consumerCapacity={consumerCapacity} />
                      </Box>
                    );
                  });
                })}
              </Box>
            </Box>
          </ScrollSyncPane>
        </Box>
      </ScrollSync>
    </Box>
  );
};

BookingByDay.protoType = {
  consumerCapacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionView: PropTypes.number,
};

BookingByDay.defaultProps = {};

export default memo(BookingByDay);
export const HEIGHT_DAY_HEADER = 42;

const MIN_COLUMN_NUMBER = 7;
const DEFAULT_MAX_ROW = 5;

const HEIGHT_DAY_FOOTER = 40;
const WIDTH_COLUMN = 175;

const onOptimizeData = (data, minColumnNumber) => {
  let primaryData = Object.keys(data).map(key => {
    let cloneItem = { ...data[key] };
    cloneItem.time = key;
    return cloneItem;
  });
  if (data.length <= minColumnNumber) {
    return data;
  }

  let result = [];
  let isAllEmpty = false;
  let startEmptyColumn = -1;
  // make group empty column
  primaryData.every((columnData, index) => {
    let lengthLatestColumn = primaryData.length - index;
    let lengthResult = result.length;
    let lengthRequire = minColumnNumber - lengthResult - 1;

    // Latest column
    if (1 === lengthLatestColumn) {
      if (startEmptyColumn >= 0) {
        // Create empty column
        let emptyColumn = primaryData[startEmptyColumn];
        emptyColumn.isHidden = true;
        emptyColumn.emptyData = primaryData.slice(startEmptyColumn, index - 1);
        result.push({ ...emptyColumn });
      }

      result.push({ ...columnData });

      // Break loop
      return false;
    }

    if (lengthRequire === lengthLatestColumn) {
      if (startEmptyColumn >= 0) {
        // Create empty column
        let emptyColumn = primaryData[startEmptyColumn];
        emptyColumn.isHidden = true;
        emptyColumn.emptyData = primaryData.slice(startEmptyColumn, index - 1);
        result.push({ ...emptyColumn });
      }
      let arrayLatestColumn = primaryData.slice(index, primaryData.length);
      // When all column is empty, restructure data again
      isAllEmpty = 0 === lengthResult && arrayLatestColumn.filter(column => column.reservation.length > 0).length === 0;

      if (!isAllEmpty) {
        result = result.concat(arrayLatestColumn);
      }

      // Break loop
      return false;
    }

    if (columnData.reservation.length > 0) {
      if (startEmptyColumn >= 0) {
        if (index > startEmptyColumn) {
          // Create empty column
          let emptyColumn = primaryData[startEmptyColumn];
          emptyColumn.isHidden = true;
          emptyColumn.emptyData = primaryData.slice(startEmptyColumn, index);
          result.push({ ...emptyColumn });
        }
        startEmptyColumn = -1;
      }
      result.push({ ...columnData });
    } else if (startEmptyColumn < 0) {
      // Store start index of empty column
      startEmptyColumn = index;
    }
    return true;
  });

  if (isAllEmpty) {
    result = onOptimizeEmptyData(primaryData, minColumnNumber);
  }

  return result;
};

const onOptimizeEmptyData = (data, minColumnNumber) => {
  if (!data) return [];

  let result = [];
  let length = data.length;
  let stepNumber = Math.floor((2 * length) / minColumnNumber);

  let endColumn = length > minColumnNumber ? minColumnNumber : length;
  for (let run = 0; run < endColumn - 2; run++) {
    if (run % 2 !== 0) {
      let selectedIndex = ((run + 1) * stepNumber) / 2;
      result.push({ ...data[selectedIndex] });
    } else {
      let startEmptyColumn = (run * stepNumber) / 2 + 1;
      let endEmptyColumn = (run * stepNumber) / 2 + stepNumber - 1;

      let emptyColumn = data[startEmptyColumn];
      emptyColumn.isHidden = true;
      emptyColumn.emptyData = data.slice(startEmptyColumn, endEmptyColumn);
      result.push({ ...emptyColumn });
    }
  }

  let startEmptyColumn = length - stepNumber - 2;
  let emptyColumn = { ...data[startEmptyColumn] };

  emptyColumn.isHidden = true;
  emptyColumn.emptyData = data.slice(startEmptyColumn, length - 2);
  result.push({ ...emptyColumn });
  result.push({ ...data[length - 1] });

  return result;
};

const useStyles = makeStyles(theme => ({
  overflowHidden: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  overflow: {
    overflow: "auto",
  },
  dayContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    width: "100%",
    backgroundColor: theme.palette.white,
    height: `calc(100vh - 200px)`,
  },
  boxHeader: {
    overflow: "hidden",
    height: HEIGHT_DAY_HEADER,
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  dayBodyWrap: {
    width: "fit-content",
    flexGrow: 1,
    display: "-webkit-box",
    borderLeft: "solid 1px #ebeced",
    height: `calc(100vh - 200px)`,
  },
  girdTime: {
    borderRightColor: theme.palette.grey[200],
  },
  reservationBox: {
    paddingRight: 5,
    paddingBottom: 5,
    borderBottomColor: theme.palette.grey[200],
  },
  dayFooter: {
    display: "inline-flex",
    height: "100%",
    backgroundColor: "#f1f3f6",
  },
  boxFooter: {
    overflow: "hidden",
    height: HEIGHT_DAY_FOOTER,
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    "&:hover": {
      overflow: "scroll",
    },
  },
  columnDayFooter: {
    borderRight: "1px solid " + theme.palette.grey[200],
  },
}));
