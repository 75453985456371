export default {
  TXT_CREATOR: "Người tạo",
  TXT_CONSUMER: "Khách hàng - C",
  TXT_SHOP: "Shop - S",
  TXT_RANGE_TIME_PICKER: "Thời gian hiển thị",
  TXT_CREATED_DAY: "Ngày tạo",
  TXT_REAL_START_TIME: "Thời gian bắt đầu",
  TXT_REAL_END_TIME: "Thời gian kết thúc",
  TXT_START_TIME: "Thời gian đặt lịch",
  TXT_USE_TIME: "Thời gian sử dụng",
  TXT_CODE: "Mã đặt chỗ",
  TXT_HISTORY_BOOKING_STATUS: "Trạng thái",
  TXT_BOOKING_PHONE_NUMBER: "Số điện thoại",
  TXT_BOOKED_PLACE: "Vị trí",
  TXT_CONSUMER_AMOUNT: "Số người",
  TXT_EXPORT_BILL: "Xuất hóa đơn",
  TXT_CASH_SHORT: "(TM)",
  TXT_BANKING_SHORT: "(CK)",

  FM_BOOKING_USE_TIME_FM_HH_MM: "{0} giờ {1} phút",
  FM_BOOKING_USE_TIME_FM_DD_HH: "{0} ngày {1} giờ",
  FM_BOOKING_USE_TIME_FM_DD: "{0} ngày",
  FM_BOOKING_USE_TIME_FM_MM_SS: "{0} phút {1} giây",
  FM_BOOKING_USE_TIME_FM_SS: "{0} giây",
  FM_BATCH_UPDATE_STATUS_DIALOG_TITLE: "Chuyển trạng thái của {0} đơn đặt chỗ sang “{1}”?",
  FM_BATCH_UPDATE_STATUS_DIALOG_CONTENT:
    "Bạn có chắc muốn chuyển trạng thái của {0} đơn đặt chỗ đã chọn sang “{1}” không?",

  FM_PLACE_WITH_OPACITY: "{0} ({1} người)",
};
