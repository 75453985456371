import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Grid } from "@material-ui/core";
import MnProductAction from "redux/mn-product.redux";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, removeVietnameseTones, convertPricePreOrder } from "utils";
import { DialogDelete } from "components/dialog";
import { ItemDescriptionBlock } from "components/mn-master-product";
import { DisplayModeBar, IconButtonMProductItem } from "components/mn-master-product";
import CreateProductCategory from "./CreateProductCategory";
import WatchAndEditProduct from "./WatchAndEditProduct";

const MainProductAll = ({ childProductModeId, childProductModeArr, onChangeMode }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const [isOpenCreateProduct, setIsOpenCreateProduct] = useState(false);
  const [dataItemUid, setDataItemUid] = useState(null);

  const listDataItem = useSelector(state => state.mnProductRedux.dataItem, shallowEqual);
  const isGetListItemSuccess = useSelector(state => state.mnProductRedux.isGetSuccessItem, shallowEqual);
  const isRemoveItemSuccess = useSelector(state => state.mnProductRedux.isDeleteSuccessItem, shallowEqual);
  const isPutItemSuccess = useSelector(state => state.mnProductRedux.isPutSuccessItem, shallowEqual);
  const isPostItemSuccess = useSelector(state => state.mnProductRedux.isPostSuccessItem, shallowEqual);
  const dataDetails = useSelector(state => state.mnProductRedux.dataDetails, shallowEqual);
  const dataCategory = useSelector(state => state.mnProductRedux.data, shallowEqual);
  const dataDetailsContent = useSelector(state => state.mnProductRedux.dataDetailsContent);

  const [isOpen, setIsOpen] = useState({
    createProduct: false,
    editProduct: false,
    deleteProduct: false,
    uid: "",
    name: "",
  });

  const onEditProductItem = uid => {
    var data = listDataItem?.data.find(data => data.uid === uid);
    var itemDetailsList = data.itemDetailsList.map(data => data.uid);
    var categoryList = data.categoryList.map(data => data.uid);
    setDataItemUid({ ...data, categoryUidList: categoryList, itemDetailsUidList: itemDetailsList });
    setIsOpen({ ...isOpen, editProduct: true, uid: uid });
  };
  const [listProductItem, setListProductItem] = useState([]);

  const onDeleteProductItem = uid => {
    let name = "";
    listProductItem.forEach(data => {
      if (data.uid === uid) {
        name = data.name;
      }
    });
    setIsOpen({ ...isOpen, deleteProduct: true, uid: uid, name: name });
  };

  const onClose = () => {
    setIsOpenCreateProduct(false);
    setIsOpen({
      ...isOpen,
      watchProduct: false,
      createProduct: false,
      editProduct: false,
      deleteProduct: false,
      uid: null,
      name: "",
    });
  };

  const onAddItem = () => {
    setIsOpenCreateProduct(true);
  };

  const onSearch = name => {
    let newListSearch = listDataItem.data.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListProductItem(newListSearch);
  };

  const onOpenProductItem = uid => {
    var data = listDataItem?.data.find(data => data.uid === uid);
    var itemDetailsList = data.itemDetailsList.map(data => data.uid);
    var categoryList = data.categoryList.map(data => data.uid);
    setDataItemUid({ ...data, categoryUidList: categoryList, itemDetailsUidList: itemDetailsList });
    setIsOpen({ ...isOpen, watchProduct: true, uid: uid });
  };

  const onConfirmRemoveProduct = (e, uid) => {
    setIsOpen({
      createProduct: false,
      editProduct: false,
      deleteProduct: false,
      uid: "",
      name: "",
    });
    dispatch(MnProductAction.resetMnProduct());
    dispatch(MnProductAction.removeProductItem({ uid: uid }));
  };

  useEffect(() => {
    dispatch(MnProductAction.getProductCategory());
    dispatch(MnProductAction.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    dispatch(MnProductAction.getProductItem());
    dispatch(MnProductAction.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
  }, []);

  useEffect(() => {
    if (listDataItem?.data && isGetListItemSuccess) {
      dispatch(MnProductAction.resetMnProduct());
      setListProductItem(listDataItem?.data);
    }
  }, [isGetListItemSuccess]);

  useEffect(() => {
    if (isRemoveItemSuccess) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItem());
      onClose();
    }
  }, [isRemoveItemSuccess]);

  useEffect(() => {
    if (isPutItemSuccess) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItem());
      dispatch(MnProductAction.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
      dispatch(MnProductAction.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
      onClose();
    }
  }, [isPutItemSuccess]);

  useEffect(() => {
    if (isPostItemSuccess) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItem());
      dispatch(MnProductAction.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
      dispatch(MnProductAction.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
      onClose();
    }
  }, [isPostItemSuccess]);

  return (
    <>
      <DisplayModeBar
        modeId={childProductModeId}
        modeArray={childProductModeArr}
        labelBtn={getLabel(LangConstant.TXT_CREATE_PRODUCT)}
        onChange={onChangeMode}
        onAddItem={onAddItem}
        onSearch={onSearch}
        showSearch={listProductItem.length > 0 ? true : false}
      />
      <>
        {listProductItem.length > 0 ? (
          <Grid container spacing={3} className={classes.listProduct}>
            {listProductItem.map(dataMap => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={dataMap.uid}>
                <ItemDescriptionBlock
                  data={dataMap}
                  amount={getLabel(getCommonKey(LangConstant.FM_ITEM_STOCK), { stock: dataMap.stock || 0 })}
                  price={convertPricePreOrder(dataMap)}
                  onOpenDetail={onOpenProductItem}
                  onOpenEdit={onEditProductItem}
                  onOpenDelete={onDeleteProductItem}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box className={classes.contentBox}>
            <IconButtonMProductItem
              label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
                LangConstant.TXT_PRODUCT_LOWER_CASE,
              )}`}
              onClick={onAddItem}
              buttonClasses={{ root: classes.addButton }}
            ></IconButtonMProductItem>
          </Box>
        )}
      </>
      {isOpenCreateProduct && (
        <CreateProductCategory
          dataDetailsView={dataDetails?.data}
          data={dataCategory?.data}
          isOpen={isOpenCreateProduct}
          onClose={onClose}
          dataDetailsContent={dataDetailsContent?.data}
        />
      )}
      {isOpen.uid && (isOpen.editProduct || isOpen.watchProduct) && dataItemUid && (
        <WatchAndEditProduct
          data={dataItemUid}
          dataCategory={dataCategory?.data}
          dataDetailsView={dataDetails?.data}
          isOpenWatch={isOpen.watchProduct}
          isOpenEdit={isOpen.editProduct}
          onClose={onClose}
          warning={true}
          dataDetailsContent={dataDetailsContent?.data}
        />
      )}
      {isOpen.deleteProduct && (
        <DialogDelete
          uid={isOpen.uid}
          isShow={isOpen.deleteProduct}
          onCancel={onClose}
          onConfirm={onConfirmRemoveProduct}
          title={getLabel(LangConstant.FN_DELETE_GOODS_ITEM_TITLE)(isOpen.name)}
          content={getLabel(LangConstant.FN_DELETE_GOODS_ITEM_CONTENT)(isOpen.name)}
        />
      )}
    </>
  );
};

export default memo(MainProductAll);

const useStyles = makeStyles(theme => ({
  contentBox: {
    width: "100%",
    minHeight: "720px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  container: {
    flexGrow: 1,
    margin: "0 10px",
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
  listProduct: {
    height: "fit-content",
    paddingLeft: 10,
    paddingRight: 10,
  },
  listProductCategorySub: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(304px, 1fr))",
  },
  boxCategorySubCategory: {
    height: "fit-content",
    gridTemplateColumns: "repeat(auto-fill,minmax(304px, 1fr))",
  },
}));
