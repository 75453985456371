import React, { useState } from "react";
import { makeStyles, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { LangConstant } from "const";
import BodyTableRow from "./BodyTableRow";
import PaymentHistoryDetail from "./PaymentHistoryDetail";

const TablePaymentHistory = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  const dataPaymentDetail = useSelector(({ paymentRedux }) => paymentRedux.paymentHistory.data, shallowEqual);

  const [objectOpenDetail, setObjectOpenDetail] = useState(OBJ_DETAIL_DEFAULT);

  const onOpenDetail = uid => {
    setObjectOpenDetail({ isOpen: true, uid: uid });
  };

  const onCloseDetail = () => {
    setObjectOpenDetail(OBJ_DETAIL_DEFAULT);
  };

  return (
    <TableContainer className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            {ARRAY_HEADER.map((item, index) => (
              <TableCell key={index} className={classes.headerCell}>
                {getLabel(item)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataPaymentDetail.map(item => (
            <BodyTableRow key={item.uid} data={item} onOpenDetail={() => onOpenDetail(item.uid)} />
          ))}
        </TableBody>
      </Table>
      {objectOpenDetail.isOpen && (
        <PaymentHistoryDetail isOpen={objectOpenDetail.isOpen} onClose={onCloseDetail} uid={objectOpenDetail.uid} />
      )}
    </TableContainer>
  );
};

const ARRAY_HEADER = [
  LangConstant.TXT_TB_PAYMENT_ID,
  LangConstant.TXT_TB_PAYMENT_DATE,
  LangConstant.TXT_TB_START_DATE,
  LangConstant.TXT_TB_END_DATE,
  LangConstant.TXT_TB_NAME,
  LangConstant.TXT_TB_PERIOD,
  LangConstant.TXT_TB_AMOUNT,
  LangConstant.TXT_TB_STATUS,
];

const OBJ_DETAIL_DEFAULT = {
  isOpen: false,
  uid: "",
};

export default TablePaymentHistory;

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  headerCell: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.69,
    color: "#3e4045",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));
