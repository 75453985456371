import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  makeStyles,
  withStyles,
  Popover,
  AppBar,
  Menu,
  MenuItem,
  Avatar,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import CustomButton from "./CustomButton";
import StatusDialog from "../StatusDialog";
import DialogBooking from "../DialogBooking";
import { shallowEqual, useDispatch } from "react-redux";
import ReservationAction from "redux/reservation.redux";
import WaitingConsumerAction from "redux/waiting-consumer.redux";
import { AppConstant, LangConstant } from "const/index";
import Cookie from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Processing } from "components";
import { convertTimeStampToDate, getCreatedTime, getEndOfDay, getStartOfDay, getTimestamp } from "utils/date";
import Alert from "components/dialog/Alert";
import { useTranslation } from "react-i18next";
import CommentIcon from "@material-ui/icons/Comment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { getCommonKey, getAttachmentUrl, getFeatureConditions } from "utils";
import { format as StringFormat } from "react-string-format";
import Warning from "./Warning";
import clsx from "clsx";
import * as MnReservation from "pages/MnReservation/index";
import * as Day from "../BookingByDay";
import * as Week from "../BookingByWeek";
import { checkExpiredBookingDate } from "utils/date";
import { DialogVerify } from "components/dialog";

const Reservation = ({ data, selectedDate, optionView }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const role = Cookie.get(AppConstant.ROLE);
  const { status, uid } = data;
  const reservationRef = useRef(null);
  const statusDialogRef = useRef(null);
  const internalNoteActionRef = useRef(null);
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const updateReservationSuccess = useSelector(state => state.reservationRedux.updateReservationSuccess);
  const settingData = useSelector(state => state.reservationRedux.displaySetting, shallowEqual);
  const isFetchingSetting = useSelector(state => state.reservationRedux.isFetching);
  const isFetching = useSelector(state => state.reservationRedux.isFetchingGetReservation);
  const reservationDay = useSelector(state => state.reservationRedux.reservationDay, shallowEqual);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [internalNoteActionAnchor, setInternalNoteActionAnchor] = useState(null);
  const [noteBoxAnchor, setNoteBoxAnchor] = useState(null);
  const [openNoteBox, setOpenNoteBox] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [displayNameFirstService, setDisplayNameFirstService] = useState("");
  const [isOpenConfirmPendingDialog, setIsOpenConfirmPendingDialog] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  const [dataDisplayState, setDataDisplayState] = useState({
    isShowService: {},
    isShowStaff: {},
    isShowPlace: {},
    isShowConsumerNumber: {},
  });
  const [isEditingInternalNote, setIsEditingInternalNote] = useState(false);
  const [isShowErr, setIsShowErr] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);
  const [internalNoteContent, setInternalNoteContent] = useState(
    data.internalNote && data.internalNote.content && data.internalNote.content,
  );

  let isPopup = Boolean(anchorEl);
  let isHasInternalNote = data.internalNote && data.internalNote.content;
  let selectedBooking = document.getElementById(SELECTED_BOOKING_ID);
  let scheduleViewPort = document.getElementById(MnReservation.HTML_CONTAINER_ID);
  let scheduleMainBox = document.getElementById(MnReservation.RESERVATION_BOX_ID);

  const onShowStatusPopup = event => {
    if (role !== AppConstant.SHOP_STAFF && checkExpiredBookingDate(convertTimeStampToDate(data.startTime))) {
      event.stopPropagation();
      setAnchorEl(statusDialogRef.current);
      setIsDetail(false);
    }
  };

  const onShowDetail = event => {
    event.preventDefault();
    dispatch(ReservationAction.getReservation({ uid: data.uid }));
    setAnchorEl(reservationRef.current);
    setIsDetail(true);
  };

  const onClose = () => {
    setAnchorEl(null);
    removeDisplayDetail();
  };

  const removeDisplayDetail = () => {
    dispatch(ReservationAction.resetBookingDetail());

    let originPath = window.location.pathname;

    history.push({
      pathname: originPath,
      search: "",
    });
  };

  const dataReservation = useSelector(state => state.reservationRedux);
  const { reservationSetting } = dataReservation;
  let isSettingTotal = reservationSetting && reservationSetting[4] && reservationSetting[4].publish === 1;
  let { isPremium } = getFeatureConditions(reservationSetting);

  const onChangeDetail = detail => {
    if (data.status !== detail.status) {
      dispatch(ReservationAction.putReservationDetails({ details: detail, uid: detail.uid }));
      onClose();
    } else {
      if (detail.date && detail.time && detail.startTime) {
        dispatch(
          ReservationAction.putReservationDetails({
            details: detail,
            uid: detail.uid,
            isSettingTotal: isSettingTotal,
          }),
        );
        onClose();
      } else {
        setIsShowErr(true);
      }
    }
  };

  const onConfirmMoveToPendingList = () => {
    dispatch(
      WaitingConsumerAction.postWaitingConsumer({
        name: data.name,
        phoneNumber: data.phoneNumber,
        total: data.consumer?.total,
        child: data.consumer?.child,
        man: data.consumer?.man,
        reservationUid: data.uid,
      }),
    );
    setIsOpenConfirmPendingDialog(false);
  };

  useEffect(() => {
    if (settingData) {
      let tmpArr = [];
      settingData.forEach(ele => {
        if (ele.isShow === true) {
          tmpArr.push(ele.displayId);
        }
      });
      setDataDisplayState({
        isShowService: tmpArr.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.service),
        isShowStaff: tmpArr.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.staff),
        isShowPlace: tmpArr.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.place),
        isShowConsumerNumber: tmpArr.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.consumerNumber),
      });
    }
  }, [settingData, isFetchingSetting]);

  useEffect(() => {
    if (selectedBooking && selectedBooking.offsetLeft && isDetail) {
      let pixelToScrollHorizontal =
        Math.floor(scheduleViewPort.offsetWidth / (MnReservation.WIDTH_COLUMN * 2)) * MnReservation.WIDTH_COLUMN;
      let pixelToScrollVertical =
        selectedBooking.offsetTop -
        (optionView !== AppConstant.ReservationViewBy.month
          ? optionView === AppConstant.ReservationViewBy.day
            ? Day.HEIGHT_DAY_HEADER
            : Week.HEIGHT_DAY_HEADER
          : 0);
      scheduleViewPort.scrollTo({
        left: selectedBooking.offsetLeft - pixelToScrollHorizontal,
        top: 0,
      });
      if (window.location.href.includes(AppConstant.KEY_UID)) {
        scheduleMainBox.scrollTo({
          left: 0,
          top: pixelToScrollVertical,
        });
      }
    }
  }, [selectedBooking]);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (
      searchParams &&
      searchParams.get(AppConstant.KEY_START_TIME) &&
      searchParams.get(AppConstant.KEY_UID) === uid &&
      !(isDetail && isPopup)
    ) {
      // TODO: Hot fix because this component is rendered twice when calling api list reservation
      setIsProcessing(true);
      let show = setTimeout(function () {
        if (role !== AppConstant.SHOP_STAFF) {
          setAnchorEl(reservationRef.current);
          setIsDetail(true);
          setIsProcessing(false);
        }
      }, 1200);
      return () => {
        clearTimeout(show);
      };
    }
    let timeWarning = getTimestamp(new Date()) - getTimestamp(convertTimeStampToDate(data.startTime));
    setIsShowWarning(
      Boolean(timeWarning > AppConstant.OVER_DATED_TIME && data.status < AppConstant.STT_RESERVATION.seated),
    );
  }, [window.location.href]);

  useEffect(() => {
    if (updateReservationSuccess && !isFetching && !reservationDay) {
      dispatch(
        ReservationAction.getReservationDay({
          start: getStartOfDay(new Date(selectedDate)),
          end: getEndOfDay(new Date(selectedDate)),
          status: null,
        }),
      );
    }
  }, [updateReservationSuccess, isFetching, reservationDay]);

  useEffect(() => {
    if (data?.orderDetailsList?.length > 0) {
      let checkNameDataService = data?.orderDetailsList?.find(
        dataFind => dataFind?.type === AppConstant.TYPE_CATEGORY.service,
      )?.name;
      if (checkNameDataService) {
        setDisplayNameFirstService(checkNameDataService);
      }
    }
    if (
      data.status > AppConstant.STT_RESERVATION.confirm &&
      data.status !== AppConstant.STT_RESERVATION.waitingPayment
    ) {
      setIsShowWarning(false);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, isPopup && classes.highlight)}
        ref={reservationRef}
        id={`${isPopup && SELECTED_BOOKING_ID}`}
        onClick={() => {
          if (!isHasInternalNote) {
            return onShowDetail;
          }
        }}
      >
        <Box className={classes.reservationBoxHeader}>
          <Header title={data.code && data.code} onClick={onShowDetail} />
          {isShowWarning && (
            <Warning
              onClick={() => {
                if (isPremium) {
                  setIsOpenConfirmPendingDialog(true);
                }
              }}
            />
          )}
          {isOpenConfirmPendingDialog && isPremium && (
            <DialogVerify
              isShow={true}
              title={getLabel(LangConstant.MSG_MOVE_TO_PENDING_CONSUMER_LIST)}
              content={StringFormat(
                getLabel(LangConstant.MSG_MOVE_TO_PENDING_CONSUMER_LIST_CONFIRM_MESSAGE),
                `${data.code}`,
              )}
              onCancel={() => setIsOpenConfirmPendingDialog(false)}
              onConfirm={onConfirmMoveToPendingList}
            />
          )}
          {Boolean(isHasInternalNote) && (
            <CommentIcon
              className={classes.addNoteIcon}
              onClick={() => {
                setNoteBoxAnchor(reservationRef.current);
                setOpenNoteBox(true);
              }}
            />
          )}
        </Box>

        <Content onClick={onShowDetail} ref={statusDialogRef}>
          <Typography variant="body1" classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}>
            {data?.name || ""}
          </Typography>

          {dataDisplayState?.isShowService && displayNameFirstService && (
            <Typography variant="body1" classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}>
              {displayNameFirstService}
            </Typography>
          )}

          {Boolean(dataDisplayState?.isShowStaff && data?.staff?.length > 0) &&
            data.staff.map((staff, index) => (
              <Typography
                key={index}
                variant="body1"
                classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}
              >
                {staff.staffName || ""}
              </Typography>
            ))}

          {Boolean(dataDisplayState?.isShowPlace && data?.assign?.length > 0) &&
            data.assign.map((assign, index) => (
              <Typography
                key={index}
                variant="body1"
                classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}
              >
                {assign.itemName || ""}
              </Typography>
            ))}
        </Content>

        <Actions disableSpacing onClick={onShowDetail}>
          <Box className={classes.iconBox}>
            {dataDisplayState?.isShowConsumerNumber && (
              <>
                <Person fontSize="inherit" color="inherit" />
                <Typography variant="body1" classes={{ body1: "semiBold-sm-txt" }} color="inherit">
                  {data.totalConsumer}
                </Typography>
              </>
            )}
          </Box>

          <CustomButton onClick={onShowStatusPopup} status={status} />
        </Actions>
      </Card>
      <Alert
        isShow={isShowErr}
        onClose={() => setIsShowErr(false)}
        type="error"
        message={getLabel(getCommonKey(LangConstant.ERR_INVALID_DAY_OR_TIME))}
      />
      {Boolean(isPopup && !isDetail) && (
        <StatusDialog
          anchorEl={anchorEl}
          onClose={onClose}
          detail={data}
          status={data.status}
          selectedId={SELECTED_BOOKING_ID}
        />
      )}
      {Boolean(isPopup && isDetail) && (
        <DialogBooking
          selectedDate={selectedDate}
          anchorEl={anchorEl}
          onClose={onClose}
          onSubmit={detail => onChangeDetail(detail)}
          data={data}
          selectedId={SELECTED_BOOKING_ID}
        />
      )}

      {data.internalNote && (
        <Popover
          open={openNoteBox}
          anchorEl={noteBoxAnchor}
          onClose={() => setOpenNoteBox(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.addInternalNotePaper }}
        >
          <Card className={classes.addNoteDialog}>
            <AppBar className={classes.addNoteDialogTitle}>
              <CardHeader
                avatar={
                  <Avatar className={classes.interNoteCreatorAvatar} src={getAttachmentUrl(data.internalNote.avatar)}>
                    {data.internalNote.createdBy && data.internalNote.createdBy}
                  </Avatar>
                }
                title={data.internalNote.createdBy && data.internalNote.createdBy}
                classes={{
                  root: classes.rootHeader,
                  action: classes.actionHeader,
                  content: classes.contentHeader,
                  title: `${classes.titleHeader} medium-md-txt`,
                }}
                action={
                  role !== AppConstant.SHOP_STAFF && (
                    <MoreHorizIcon
                      ref={internalNoteActionRef}
                      className={classes.menuIcon}
                      onClick={() => {
                        setInternalNoteActionAnchor(internalNoteActionRef.current);
                      }}
                    />
                  )
                }
              />
              {role !== AppConstant.SHOP_STAFF && (
                <Menu
                  anchorEl={internalNoteActionAnchor}
                  open={Boolean(internalNoteActionAnchor)}
                  onClose={() => {
                    setInternalNoteActionAnchor(null);
                  }}
                  classes={{ paper: classes.interNoteActionMenu }}
                >
                  <MenuItem
                    className={classes.interNoteAction}
                    onClick={() => {
                      setIsEditingInternalNote(true);
                      setInternalNoteActionAnchor(null);
                    }}
                  >
                    {getLabel(getCommonKey(LangConstant.TXT_EDIT))}
                  </MenuItem>
                  <MenuItem
                    className={classes.interNoteAction}
                    onClick={() => {
                      onChangeDetail({ ...data, internalNote: null });
                      setInternalNoteActionAnchor(null);
                    }}
                  >
                    {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
                  </MenuItem>
                </Menu>
              )}
            </AppBar>
            <CardContent className={classes.addNoteDialogContent}>
              {isEditingInternalNote ? (
                <OutlinedInput
                  classes={{ root: classes.internalNoteContent, input: classes.inputNoteTextField }}
                  multiline={true}
                  value={internalNoteContent}
                  onChange={event => {
                    setInternalNoteContent(event.target.value);
                  }}
                />
              ) : (
                <Typography className={classes.internalNoteContent}>
                  {data.internalNote.content && data.internalNote.content}
                </Typography>
              )}
            </CardContent>
            {isEditingInternalNote && (
              <Box className={classes.rootFooter}>
                <Button
                  onClick={() => {
                    setIsEditingInternalNote(false);
                  }}
                  classes={{ root: classes.buttonCancelFooter, label: classes.labelFooter }}
                >
                  {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
                </Button>
                <Button
                  onClick={() => {
                    onChangeDetail({ ...data, internalNote: { content: internalNoteContent } });
                    setInternalNoteActionAnchor(null);
                  }}
                  classes={{
                    root: classes.buttonRootFooter,
                    label: classes.labelFooter,
                  }}
                >
                  {getLabel(getCommonKey(LangConstant.TXT_SAVE_BUTTON))}
                </Button>
              </Box>
            )}
            <Box className={classes.internalNoteMilestone}>
              {StringFormat(
                getLabel(LangConstant.TXT_INTERNAL_NOTE_EDIT_MILESTONE),
                getCreatedTime(
                  data.internalNote.modified && new Date(data.internalNote.modified * 1000),
                  getLabel,
                ).toLowerCase(),
                data.internalNote.updatedBy && data.internalNote.updatedBy,
              )}
            </Box>
          </Card>
        </Popover>
      )}
      {isPopup && <Box className={classes.bgPopup} />}
      <Processing isShow={isProcessing} />
    </>
  );
};

Reservation.propTypes = { data: PropTypes.object, placeCategory: PropTypes.array };
Reservation.defaultProps = { data: { status: AppConstant.STT_RESERVATION.pending } };

export default memo(Reservation);

const SELECTED_BOOKING_ID = "selectedBookingDetail";

const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    width: "100%",
    height: "100%",
    position: "relative",
    background: "#f1f3f6",
    padding: "5px",
    borderRadius: "unset",
    boxShadow: "none",
    overflow: "auto",
    cursor: "zoom-in",
    gridTemplateRows: "min-content",
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    color: "#787878",
    "&>*:first-child": {
      marginRight: "8px",
    },
  },
  bgPopup: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.grey[800],
    opacity: 0.5,
    zIndex: theme.zIndex.drawer,
  },
  highlight: {
    zIndex: theme.zIndex.drawer + 10,
  },
  typographyRoot: {},
  addNoteIcon: {
    fontSize: 17,
    marginRight: 8,
    color: "#447aff",
    cursor: "pointer",
  },
  reservationBoxHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addNoteDialog: {
    margin: "auto",
    position: "relative",
    height: "auto",
    width: 375,
    minHeight: 125,
    "@media only screen and (max-width: 500px)": {
      width: 300,
    },
  },
  addNoteDialogTitle: {
    position: "relative",
    backgroundColor: theme.palette.white,
  },
  addNoteDialogContent: {
    display: "grid",
    padding: "15px 24px",
  },
  menuIcon: {
    padding: 0,
    cursor: "pointer",
    color: theme.palette.grey[500],
  },
  rootHeader: {
    position: "relative",
    padding: "20px 24px",
    color: theme.palette.white,
    height: 54,
    marginBottom: 1,
  },
  rootTabs: {
    minHeight: 38,
    height: 38,
  },
  contentHeader: {
    flex: "none",
  },
  actionHeader: {
    marginTop: 0,
    marginLeft: "auto",
    marginRight: 0,
  },
  titleHeader: {
    color: "#3e4045",
    "@media only screen and (max-width: 375px)": {
      fontSize: 16,
    },
  },
  internalNoteContent: {
    fontSize: 13,
    fontWeight: "normal",
  },
  internalNoteContentDisabled: {
    fontSize: 13,
    fontWeight: "normal",
    border: "none",
    padding: 0,
  },
  addInternalNotePaper: {
    borderRadius: "4px",
  },
  interNoteActionMenu: {
    marginTop: 30,
    marginLeft: -40,
  },
  interNoteAction: {
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.white,
    },
  },
  interNoteCreatorAvatar: {
    backgroundColor: theme.palette.black,
  },
  inputNoteTextField: {
    maxHeight: 70,
    overflow: "auto !important",
  },
  internalNoteMilestone: {
    padding: "15px 24px",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 10,
    color: "#d4d5d8",
    fontWeight: "normal",
  },
  buttonCancelFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.black,
  },
  labelFooter: {
    width: "100%",
    height: 24,
    marginTop: 3,
    marginBottom: 3,
  },
  buttonRootFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.8,
      color: "unset",
    },
  },
  rootFooter: {
    right: 10,
    textAlign: "right",
    width: "100%",
    padding: "0 24px",
    "@media only screen and (max-width: 375px)": {
      position: "fixed",
      bottom: 10,
      padding: "0px 24px",
    },
  },
}));

const Header = withStyles(theme => ({
  root: {
    padding: 5,
    width: "fill-available",
  },
  title: {
    fontSize: "13px",
    fontWeight: "600",
    color: theme.palette.grey[800],
    lineHeight: "normal",
  },
}))(CardHeader);

const Content = withStyles({
  root: {
    width: "100%",
    padding: "0px 5px 0px 5px",
    marginBottom: 1,
    "&>*": {
      lineHeight: "20px",
      color: "#787878",
    },
    "&:last-child": {
      paddingBottom: 0,
    },
  },
})(CardContent);

const Actions = withStyles({
  root: {
    padding: 0,
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  spacing: {},
})(CardActions);
