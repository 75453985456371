import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import clsx from "clsx";

const ConfirmDialog = ({
  onSelectConfirm,
  onSelectCancel,
  title,
  titleConfirm,
  isShow,
  confirmText,
  cancelText,
  classes,
}) => {
  const defaultClasses = useStyles();

  return (
    <Dialog open={isShow} classes={{ paper: clsx(classes?.dialogPaper, defaultClasses.dialogPaper) }}>
      <DialogTitle disableTypography className={defaultClasses.dialogTitle}>
        <Typography variant="subtitle2" className={clsx(classes?.title, defaultClasses.textTitle, "medium-xl-txt")}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={clsx(classes?.dialogContent, defaultClasses.dialogContent)}>
        <Typography variant="body2" className={clsx("medium-md-txt", defaultClasses.confirmMessage)}>
          {titleConfirm}
        </Typography>
      </DialogContent>
      <DialogActions className={clsx(classes?.dialogAction, defaultClasses.dialogAction)}>
        <Button
          variant="text"
          onClick={onSelectCancel}
          className={clsx(classes?.cancelButton, defaultClasses.button, defaultClasses.cancelButton, "semiBold-md-txt")}
        >
          {cancelText}
        </Button>
        <Button
          variant="text"
          onClick={onSelectConfirm}
          className={clsx(classes?.confirmButton, defaultClasses.button, "semiBold-md-txt")}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minWidth: 470,
    borderRadius: "unset",
    maxWidth: "fit-content",
    padding: 0,
    boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.0973011)",
  },

  dialogTitle: {
    alignItems: "center",
    color: theme.palette.primary,
    padding: 24,
  },

  textTitle: {
    marginLeft: "auto",
    whiteSpace: "pre-wrap",
  },

  dialogContent: {
    padding: 24,
    paddingTop: 0,
    color: theme.palette.text.secondary,
  },

  dialogAction: {
    padding: "16px 32px",
    borderTop: "1px solid " + theme.palette.grey[200],
  },

  button: {
    color: theme.palette.text.secondary,
  },

  cancelButton: {
    marginLeft: 30,
  },

  confirmMessage: {
    color: theme.palette.grey[500],
  },
}));

ConfirmDialog.propTypes = {
  onSelectConfirm: PropTypes.func,
  onSelectCancel: PropTypes.func,

  title: PropTypes.string.isRequired,
  titleConfirm: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,

  isShow: PropTypes.bool,

  classes: PropTypes.object,
};

ConfirmDialog.defaultProps = {
  title: "",
  titleConfirm: "",
  isShow: true,
};

export default memo(ConfirmDialog);
