import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Error } from "@material-ui/icons";
import { Box, makeStyles, OutlinedInput, Typography } from "@material-ui/core";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const UploadVideo = ({ defaultUrl, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const [videoUrl, setVideoUrl] = useState(defaultUrl);
  const [isErrorLoadVideo, setIsErrorLoadVideo] = useState(false);

  const onChangeVideoInput = event => {
    let value = event.target.value || "";
    setVideoUrl(value);
  };

  useEffect(() => {
    if (videoUrl) setIsErrorLoadVideo(!ReactPlayer.canPlay(videoUrl));
    else setIsErrorLoadVideo(false);
  }, [videoUrl]);

  useEffect(() => {
    if (isErrorLoadVideo || !Boolean(videoUrl)) onChange(videoUrl, true);
    else onChange(videoUrl, false);
  }, [isErrorLoadVideo, videoUrl]);

  let isHideVideoPreview = isErrorLoadVideo || !videoUrl;

  return (
    <>
      <OutlinedInput
        classes={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        onChange={onChangeVideoInput}
        value={videoUrl}
      />

      {isErrorLoadVideo && (
        <Typography className={classes.errorMessage}>
          <Error /> {getLabel(LangConstant.TXT_CAN_NOT_FIND_VIDEO)}
        </Typography>
      )}

      <Box className={isHideVideoPreview ? "hidden" : classes.videoPlayerContainer}>
        <ReactPlayer url={videoUrl} onError={() => setIsErrorLoadVideo(true)} width="100%" height="100%" pip={false} />
      </Box>
    </>
  );
};

UploadVideo.propTypes = {
  defaultUrl: PropTypes.string,

  onChange: PropTypes.func,
};

UploadVideo.defaultProps = {
  defaultUrl: "",

  onChange: () => {},
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    width: "100%",
    borderRadius: 3,
  },

  input: {
    height: 30,
    padding: "5px 20px",
  },

  videoPlayerContainer: {
    width: "100%",
    height: "100%",
    marginTop: 16,
    minHeight: 180,
    "&>div>div>iframe": {
      minHeight: 180,
    },
  },

  errorMessage: {
    width: "100%",
    justifyContent: "end",
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    "&>svg": {
      fontSize: 12,
    },
  },
}));

export default UploadVideo;
