import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, makeStyles, Typography, Box, IconButton, OutlinedInput } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import clsx from "clsx";
import { LangConstant } from "const";
import { deepCloneJsonObject, isObjectNotEqual } from "utils";

const GroupClassification = ({ onRemove, onChange, isTwoGroupClassification, data, isShowGroupClassifiedTwo }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const [groupClassification, setGroupClassification] = useState(data);

  const onAddItem = () => {
    let arrNewItem = deepCloneJsonObject(groupClassification.modelList);
    if (isTwoGroupClassification) {
      arrNewItem.forEach(dataItem => {
        dataItem.groupModel.modelList.push({ ...DATA_DEFAULT.modelList[0] });
      });
    } else {
      arrNewItem.push(isShowGroupClassifiedTwo ? { ...arrNewItem[0], name: "" } : { ...DATA_DEFAULT.modelList[0] });
    }

    setGroupClassification({ ...groupClassification, modelList: arrNewItem });
    onChange({ ...groupClassification, modelList: arrNewItem });
  };

  const onChangeNameGroup = event => {
    let nameNewGroup = event.target.value;
    let newItem = deepCloneJsonObject(groupClassification);
    if (isTwoGroupClassification) {
      newItem.modelList.forEach(data => {
        data.groupModel.name = nameNewGroup;
        data.groupModel.modelList.forEach(dataItem => {
          let nameNewItem = { ...dataItem.item };
          nameNewItem = dataItem.name + "," + nameNewGroup;
          dataItem.item.name = nameNewItem;
        });
      });
    } else {
      newItem = { ...newItem, name: nameNewGroup };
    }
    setGroupClassification(newItem);
    onChange(newItem);
  };

  const onChangeItem = (event, index) => {
    let newName = event.target.value;
    let arrNewItem = deepCloneJsonObject(groupClassification.modelList);
    if (isTwoGroupClassification) {
      arrNewItem.forEach(data => {
        let itemClone = { ...data.groupModel.modelList[index] };
        itemClone.name = newName;
        let nameNewItem = { ...itemClone.item };
        nameNewItem.name = data.name + "," + newName;
        itemClone.item = nameNewItem;
        data.groupModel.modelList[index] = itemClone;
      });
    } else {
      let itemClone = { ...arrNewItem[index] };
      itemClone.name = newName;
      arrNewItem[index] = itemClone;
      if (isTwoGroupClassification) {
        let newNameItem = { ...arrNewItem[index].item };
        newNameItem.name = newName;
        arrNewItem[index].item = newNameItem;
      }
    }

    setGroupClassification({ ...groupClassification, modelList: arrNewItem });
    onChange({ ...groupClassification, modelList: arrNewItem });
  };

  const onDeleteItem = index => {
    let arrNewItem = deepCloneJsonObject(groupClassification.modelList);
    if (isTwoGroupClassification) {
      arrNewItem.forEach(data => {
        data.groupModel.modelList = data.groupModel.modelList.filter((_, itemIndex) => itemIndex !== index);
      });
    } else {
      arrNewItem = arrNewItem.filter((_, itemIndex) => itemIndex !== index);
    }

    setGroupClassification(state => ({ ...state, modelList: arrNewItem }));
    onChange({ ...groupClassification, modelList: arrNewItem });
  };

  useEffect(() => {
    if (isObjectNotEqual(data, groupClassification)) {
      setGroupClassification(data);
    }
  }, [data]);

  let isRemoveBtn = isTwoGroupClassification
    ? groupClassification?.modelList[0]?.groupModel?.modelList.length > 1
    : groupClassification?.modelList?.length > 1;

  let nameClassification = isTwoGroupClassification
    ? groupClassification?.modelList[0]?.groupModel?.name || ""
    : groupClassification?.name || "";

  return (
    <Box paddingTop="15px">
      <Typography variant="subtitle2" className={classes.classificationGroup}>
        {getLabel(LangConstant.FM_CLASSIFICATION_GROUP, {
          name: isTwoGroupClassification ? NUMBER_CLASSIFICATION.two : NUMBER_CLASSIFICATION.one,
        })}
      </Typography>
      <Box className={classes.boxGroupClassification}>
        <Box className={classes.boxHeaderGroup}>
          <IconButton className={classes.closeButton} onClick={onRemove}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </Box>
        <Typography variant="subtitle2" className={classes.classificationGroup}>
          {getLabel(LangConstant.TXT_NAME_CLASSIFICATION_GROUP)}
        </Typography>
        <OutlinedInput
          classes={{ root: clsx(classes.outlinedInput, "regular-md-txt"), input: classes.backgroundInput }}
          placeholder={getLabel(LangConstant.P_FILL_CLASSIFICATION_GROUP)}
          onChange={onChangeNameGroup}
          value={nameClassification}
        />
        <Typography variant="subtitle2" className={classes.classificationGroup}>
          {getLabel(LangConstant.TXT_NAME_CLASSIFICATION_GROUP)}
        </Typography>
        {isTwoGroupClassification
          ? groupClassification.modelList[0]?.groupModel?.modelList?.map((item, index) => (
              <Box key={index}>
                <OutlinedInput
                  classes={{ root: clsx(classes.outlinedInput, "regular-md-txt"), input: classes.backgroundInput }}
                  placeholder={getLabel(LangConstant.P_FILL_CLASSIFICATION)}
                  onChange={event => onChangeItem(event, index)}
                  value={item.name}
                />
                {isRemoveBtn && (
                  <IconButton className={classes.closeButton} onClick={() => onDeleteItem(index)}>
                    <Close className={classes.closeIconOutlinedInput} />
                  </IconButton>
                )}
              </Box>
            ))
          : groupClassification?.modelList?.map((item, index) => (
              <Box key={index}>
                <OutlinedInput
                  classes={{ root: clsx(classes.outlinedInput, "regular-md-txt"), input: classes.backgroundInput }}
                  placeholder={getLabel(LangConstant.P_FILL_CLASSIFICATION)}
                  onChange={event => onChangeItem(event, index)}
                  value={item.name}
                />
                {isRemoveBtn && (
                  <IconButton className={classes.closeButton} onClick={() => onDeleteItem(index)}>
                    <Close className={classes.closeIconOutlinedInput} />
                  </IconButton>
                )}
              </Box>
            ))}
        <Button className={clsx(classes.buttonAdd, "regular-md-txt")} startIcon={<Add />} onClick={onAddItem}>
          {getLabel(LangConstant.TXT_ADD_CLASSIFICATION_SERVICE)}
        </Button>
      </Box>
    </Box>
  );
};

export const NUMBER_CLASSIFICATION = { one: 1, two: 2 };

const DATA_DEFAULT = {
  name: "",
  modelList: [
    {
      name: "",
      imageList: [],
      item: {
        name: "",
        usingTime: 0,
        usingTimeType: 1,
        price: "",
        priceTaxIn: "",
        tax: 0,
        taxInclusiveF: 0,
        unitType: 1,
        description: "",
      },
    },
  ],
};

GroupClassification.propTypes = {
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  isTwoGroupClassification: PropTypes.bool,
  isShowGroupClassifiedTwo: PropTypes.bool,
  data: PropTypes.object,
};

GroupClassification.defaultProps = {
  onRemove: () => {},
  onChange: () => {},
  isTwoGroupClassification: false,
  isShowGroupClassifiedTwo: false,
  data: {},
};

export default memo(GroupClassification);

const useStyles = makeStyles(theme => ({
  classificationGroup: {
    width: "100%",
    fontSize: 13,
    marginBottom: 10,
  },
  boxHeaderGroup: {
    width: "100%",
    textAlign: "end",
  },
  boxGroupClassification: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FAFAFB",
    padding: "10px 16px",
  },
  closeButton: {
    width: 16,
    height: 16,
    backgroundColor: "#FAFAFB",
    marginLeft: 15,
    "&:hover": {
      backgroundColor: "#FAFAFB",
    },
  },
  closeIcon: {
    color: "#B3B5BA",
    width: 20,
    height: 20,
  },
  outlinedInput: {
    height: 40,
    width: "90%",
    marginBottom: 10,
  },
  closeIconOutlinedInput: {
    width: 16,
    height: 16,
  },
  buttonAdd: {
    height: 40,
    width: "80%",
    textTransform: "none",
    marginTop: 15,
    marginLeft: "10%",
    color: "#447AFF",
    border: "1px solid #447AFF",
    borderRadius: 3,
  },
  backgroundInput: {
    backgroundColor: theme.palette.white,
  },
}));
