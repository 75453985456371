import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestGetConsumer: ["data"],
  getConsumerSuccess: ["data"],
  getConsumerFailure: ["data"],

  patchInvitationConsumer: ["data"],
  invitationConsumerSuccess: ["data"],
  invitationConsumerFailure: ["data"],

  requestGetEmailQuantity: ["data"],
  getEmailQuantitySuccess: ["data"],
  getEmailQuantityFailure: ["data"],

  requestGetInvitationFacebook: ["data"],
  getInvitationFacebookSuccess: ["data"],
  getInvitationFacebookFailure: ["data"],

  patchInvitationFacebook: ["data"],
  invitationFacebookSuccess: ["data"],
  invitationFacebookFailure: ["data"],

  getNumberBookingByPhoneNumber: ["data"],

  postConsumerList: ["data"],

  resetConsumer: [],
  resetNumberBooking: [],
  consumerSuccess: ["data"],
  consumerFailure: ["data"],
});

export const ConsumerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  consumers: null,
  isUploadConsumerSuccess: false,

  isFetchingInvitationConsumer: false,
  errorInvitationConsumer: null,
  isInvitationConsumerSuccess: false,
  invitationConsumer: null,

  isFetchingEmailQuantity: false,
  errorEmailQuantity: null,
  isEmailQuantitySuccess: false,
  emailQuantity: null,

  isFetchingGetInvitationFacebook: false,
  errorGetInvitationFacebook: null,
  isGetInvitationFacebookSuccess: false,
  getInvitationFacebook: null,

  isFetchingInvitationFacebook: false,
  errorInvitationFacebook: null,
  isInvitationFacebookSuccess: false,
  invitationFacebook: null,

  isNumberBookingSuccess: false,
  numberBooking: null,
};

/* ------------- Reducers ------------- */
export const requestGetConsumer = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  error: null,
  isSuccess: false,
});

export const getConsumerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    error: null,
    isSuccess: true,
    consumers: action.data,
  };
};

export const getConsumerFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
  isSuccess: false,
});

/* ------------- Reducers Patch Invitation Consumer ------------- */

export const patchInvitationConsumer = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingInvitationConsumer: true,
  errorInvitationConsumer: null,
  isInvitationConsumerSuccess: false,
});

export const invitationConsumerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingInvitationConsumer: false,
    errorInvitationConsumer: null,
    isInvitationConsumerSuccess: true,
    invitationConsumer: action.data,
  };
};

export const invitationConsumerFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingInvitationConsumer: false,
  errorInvitationConsumer: action.error,
  isInvitationConsumerSuccess: false,
});

/* ------------- Reducers Get Email Consumer Quantity ------------- */

export const requestGetEmailQuantity = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingEmailQuantity: true,
  errorEmailQuantity: null,
  isEmailQuantitySuccess: false,
});

export const getEmailQuantitySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingEmailQuantity: false,
    errorEmailQuantity: null,
    isEmailQuantitySuccess: true,
    emailQuantity: action.data,
  };
};

export const getEmailQuantityFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingEmailQuantity: false,
  errorEmailQuantity: action.error,
  isEmailQuantitySuccess: false,
});

/* ------------- Reducers Get Invitation Facebook ------------- */

export const requestGetInvitationFacebook = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingGetInvitationFacebook: true,
  errorGetInvitationFacebook: null,
  isGetInvitationFacebookSuccess: false,
});

export const getInvitationFacebookSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingGetInvitationFacebook: false,
    errorGetInvitationFacebook: null,
    isGetInvitationFacebookSuccess: true,
    getInvitationFacebook: action.data,
  };
};

export const getInvitationFacebookFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingGetInvitationFacebook: false,
  errorGetInvitationFacebook: action.error,
  isGetInvitationFacebookSuccess: false,
});

/* ------------- Reducers Patch Invitation Facebook ------------- */

export const patchInvitationFacebook = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingInvitationFacebook: true,
  errorInvitationFacebook: null,
  isInvitationFacebookSuccess: false,
});

export const invitationFacebookSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingInvitationFacebook: false,
    errorInvitationFacebook: null,
    isInvitationFacebookSuccess: true,
    invitationFacebook: action.data,
  };
};

export const invitationFacebookFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingInvitationFacebook: false,
  errorInvitationFacebook: action.error,
  isInvitationFacebookSuccess: false,
});
/* ------------- Reducers Patch NumberBooking By Phone Number ------------- */
export const getNumberBookingByPhoneNumber = (state = INITIAL_STATE) => ({
  ...state,
  isNumberBookingSuccess: false,
});

export const resetNumberBooking = (state = INITIAL_STATE) => ({
  ...state,
  isNumberBookingSuccess: false,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isSuccess: false,
  isInvitationConsumerSuccess: false,
  isEmailQuantitySuccess: false,
  isGetInvitationFacebookSuccess: false,
  isInvitationFacebookSuccess: false,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action?.data || {};
  return {
    ...state,
    isFetching: false,
    ...data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  isNumberBookingSuccess: false,
  error: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  /* ------------- get Consumer ------------- */
  [Types.REQUEST_GET_CONSUMER]: requestGetConsumer,
  [Types.GET_CONSUMER_SUCCESS]: getConsumerSuccess,
  [Types.GET_CONSUMER_FAILURE]: getConsumerFailure,
  /* ------------- patch Invitation Consumer ------------- */
  [Types.PATCH_INVITATION_CONSUMER]: patchInvitationConsumer,
  [Types.INVITATION_CONSUMER_SUCCESS]: invitationConsumerSuccess,
  [Types.INVITATION_CONSUMER_FAILURE]: invitationConsumerFailure,
  /* ------------- get Email Consumer Quantity ------------- */
  [Types.REQUEST_GET_EMAIL_QUANTITY]: requestGetEmailQuantity,
  [Types.GET_EMAIL_QUANTITY_SUCCESS]: getEmailQuantitySuccess,
  [Types.GET_EMAIL_QUANTITY_FAILURE]: getEmailQuantityFailure,
  /* ------------- get Invitation Facebook ------------- */
  [Types.REQUEST_GET_INVITATION_FACEBOOK]: requestGetInvitationFacebook,
  [Types.GET_INVITATION_FACEBOOK_SUCCESS]: getInvitationFacebookSuccess,
  [Types.GET_INVITATION_FACEBOOK_FAILURE]: getInvitationFacebookFailure,
  /* ------------- patch Invitation Consumer ------------- */
  [Types.PATCH_INVITATION_FACEBOOK]: patchInvitationFacebook,
  [Types.INVITATION_FACEBOOK_SUCCESS]: invitationFacebookSuccess,
  [Types.INVITATION_FACEBOOK_FAILURE]: invitationFacebookFailure,

  /* ------------- patch Number Booking By Phone Number ------------- */
  [Types.GET_NUMBER_BOOKING_BY_PHONE_NUMBER]: getNumberBookingByPhoneNumber,
  /* ------------- post File Consumer ------------- */
  [Types.POST_CONSUMER_LIST]: requestGetConsumer,

  /* ------------- reset ------------- */
  [Types.RESET_CONSUMER]: reset,
  [Types.RESET_NUMBER_BOOKING]: resetNumberBooking,
  [Types.CONSUMER_SUCCESS]: success,
  [Types.CONSUMER_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
