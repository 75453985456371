import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { EditOutlined, SmsOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { LangConstant } from "const";
import { InputShopInfo } from "components/mnbooking";
import { AntSwitchPrimary } from "./CustomizeSwitch";
import { DISABLE_NOTIFICATION_OPTION, OBJECT_TYPE } from "../index";
import { DialogSMS } from "./DialogNotificationSetting/index";
import { Alert } from "components";
import { getCommonKey, validatePhone } from "utils";

const NotificationSms = ({ data, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_NOTIFICATION_SETTING);

  const [dataSMSNotification, setDataSMSNotification] = useState(data.smsSetting || {});
  const [arrSMS, setArrSMS] = useState(data.emailSettingList || []);
  const [isShowAdd, setIsShowAdd] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [sms, setSMS] = useState("");
  const [indexSMS, setIndexSMS] = useState(null);
  const [isShowErrorSms, setIsShowErrorSms] = useState(false);

  const onAddSMS = () => {
    setIsShowAdd(true);
  };

  const onEditSMS = index => {
    setSMS(arrSMS[index]);
    setIndexSMS(index);
    setIsShowEdit(true);
  };

  const onClose = () => {
    setIsShowAdd(false);
    setIsShowEdit(false);
    setSMS("");
    setIndexSMS(null);
  };

  const onChangSMS = data => {
    setSMS(data);
  };

  const onSubmitSMS = () => {
    if (validatePhone(sms)) {
      let arr = [...arrSMS];
      if (isShowEdit) {
        arr[indexSMS] = sms;
      } else {
        arr.push(sms);
      }
      setArrSMS(arr);
      onChange(arr, OBJECT_TYPE.sms);
      onClose();
    } else {
      setIsShowErrorSms(true);
    }
  };

  const onDeleteSMS = () => {
    let arrNew = arrSMS.filter((_, index) => index != indexSMS);
    setArrSMS(arrNew);
    onChange(arrNew, OBJECT_TYPE.sms);
    onClose();
  };

  const onChangeSendingNotification = event => {
    let isEnableSending = event.currentTarget.checked;

    let data = {
      ...dataSMSNotification,
      disableAll: isEnableSending ? DISABLE_NOTIFICATION_OPTION.enable : DISABLE_NOTIFICATION_OPTION.disable,
    };
    setDataSMSNotification(data);
    onChange(data);
  };

  const onChecked = event => {
    let name = event.currentTarget.name;
    let isChecked = event.currentTarget.checked;

    let dataCheck = {
      ...dataSMSNotification,
      [name]: Number(isChecked),
    };
    setDataSMSNotification(dataCheck);
    onChange(dataCheck);
  };

  useEffect(() => {
    setDataSMSNotification(data.smsSetting || {});
    setArrSMS(data.smsSettingList || []);
  }, [data]);

  return (
    <Box>
      <Box className={classes.boxHeader}>
        <Grid item xs={12} md={6} container direction="row" justify="space-between">
          <Grid item className={classes.grid}>
            <SmsOutlined />
            <Typography className={clsx("semiBold-lg-txt", classes.typography)}>
              {getLabel(LangConstant.TXT_NOTIFICATION_PHONE_NUMBER)}
            </Typography>
          </Grid>
          <Grid item>
            <AntSwitchPrimary
              checked={!Boolean(dataSMSNotification.disableAll)}
              name={LangConstant.OBJECT_KEY_NOTIFICATION.disableAll}
              className={classes.toggleGg}
              onChange={onChangeSendingNotification}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={!Boolean(dataSMSNotification.disableAll) ? classes.bodyHeader : "hidden"}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} container direction="column">
            <Typography className={clsx("semiBold-sm-txt", classes.typographySMS)}>
              {getLabel(LangConstant.TXT_PHONE_NUMBER_TO_NOTIFICATION)}
            </Typography>
          </Grid>
        </Grid>
        {arrSMS.length > 0 &&
          arrSMS.map((item, index) => (
            <Box key={index}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} container direction="column">
                  <Typography className={"medium-sm-txt"}>
                    {item}
                    <IconButton onClick={() => onEditSMS(index)}>
                      <EditOutlined className={classes.editIcon} />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} container direction="column">
            <Button
              classes={{
                root: clsx("medium-sm-txt", classes.btnSMS),
                label: classes.labelSMS,
                text: classes.textSMS,
              }}
              onClick={onAddSMS}
            >
              {getLabel(LangConstant.TXT_ADD_PHONE_NUMBER)}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationNew}
              checkBox
              checked={Boolean(dataSMSNotification.reservationNew)}
              title={getLabel(LangConstant.TXT_NEW_BOOKING)}
              onCheck={onChecked}
            />
          </Grid>
          {/* TODO: Abandoned because more features than the original scope of the project will open in another pj */}
          {/* <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.packagePayment}
              checkBox
              checked={Boolean(dataSMSNotification.packagePayment)}
              title={getLabel(LangConstant.TXT_NOTICE_OF_PAYMENT_REQUEST)}
              onCheck={onChecked}
            />
          </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.remoteOrderNew}
              checkBox
              checked={Boolean(dataSMSNotification.remoteOrderNew)}
              title={getLabel(LangConstant.TXT_NEW_ORDER)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
      </Box>
      <DialogSMS
        isShowAdd={isShowAdd}
        isShowEdit={isShowEdit}
        onClose={onClose}
        onChange={onChangSMS}
        data={sms}
        onSubmit={onSubmitSMS}
        onDelete={onDeleteSMS}
      />
      <Alert
        isShow={isShowErrorSms}
        onClose={() => setIsShowErrorSms(false)}
        type="error"
        message={getLabel(getCommonKey(LangConstant.TXT_ADD_NEW_BOOKING_WARNING_TELEPHONE))}
      />
    </Box>
  );
};

NotificationSms.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};
NotificationSms.defaultProps = {
  data: {},
  onChange: () => {},
};

export default memo(NotificationSms);

const useStyles = makeStyles(theme => ({
  boxHeader: {
    padding: "15px 30px 10px 25px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 20px 10px 25px",
    },
  },
  bodyHeader: {
    padding: "0 57px 20px 57px",
  },
  typography: {
    paddingLeft: 10,
    paddingTop: 2,
  },
  toggleGg: {
    marginRight: "0.5vw",
    marginTop: "3px",
    color: "#3e4045",
    width: 30,
  },
  grid: {
    display: "flex",
  },
  editIcon: {
    height: 16,
    width: 16,
    padding: 0,
    color: "#7f838c",
  },
  btnSMS: {
    textTransform: "none",
    color: theme.palette.text.link,
    width: 150,
    height: 30,
  },
  labelSMS: {
    justifyContent: "flex-start",
  },
  textSMS: {
    padding: "6px 0",
  },
  typographySMS: {
    height: 25,
    paddingTop: 10,
  },
}));
