import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Dialog, Box, Typography, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LangConstant } from "const";
import { BodyInfo, TableDetail } from "./components";
import PaymentAction from "redux/payment.redux";

const PaymentHistoryDetail = ({ isOpen, uid, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  useEffect(() => {
    if (uid) {
      dispatch(PaymentAction.getPaymentDetails({ uid: uid }));
    }
  }, [uid]);

  return (
    <Dialog open={isOpen} fullScreen>
      <Box className={classes.rootHeader}>
        <Typography className={classes.nameHeaderTypo}>{getLabel(LangConstant.TXT_PAYMENT_DETAIL)}</Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButtonClose}>
            <Close className={classes.iconClose} />
          </IconButton>
        </Box>
      </Box>
      <BodyInfo />
      <TableDetail />
    </Dialog>
  );
};

PaymentHistoryDetail.propTypes = {
  isOpen: PropTypes.bool,
  uid: PropTypes.string,
  onClose: PropTypes.func,
};
PaymentHistoryDetail.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default PaymentHistoryDetail;

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButtonClose: {
    padding: 0,
  },
  iconClose: {
    width: 36,
    height: 36,
  },
}));
