import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Paper,
  Accordion,
  AccordionSummary,
  Typography,
  Box,
  Button,
  AccordionDetails,
} from "@material-ui/core";
import { LangConstant } from "../../../../../const";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@material-ui/icons";
import PlaceDialog from "./PlaceDialog";
import ServiceDialog, { HOUR_UNIT } from "./ServiceDialog";
import ConfirmDialog from "./ConfirmDialog";
import SubCategoryAccordion from "./SubCategoryAccordion";
import CategoryAccordionDetails from "./CategoryAccordionDetails";
import { uuid } from "utils";
import PlaceCategoryAction from "../../../../../redux/place-category.redux";
import ServiceCategoryAction from "../../../../../redux/service-category.redux";
import { useSelector, useDispatch } from "react-redux";

const CategoryAccordion = props => {
  const placeCategoryRedux = useSelector(state => state.placeCategoryRedux);
  const placeCategoryFetchData = placeCategoryRedux.data;
  const serviceCategoryRedux = useSelector(state => state.serviceCategoryRedux);
  const { createData: serviceDataCreate, removeData: serviceDataDelete } = serviceCategoryRedux;
  const { data, icon, headingText, name, style, disableSubAccordion } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [openPlace, setOpenPlace] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [category, setCategory] = useState(data);
  const [newPlaceCategory, setNewPlaceCategory] = useState({});
  const [apiType, setApiType] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setCategory(data);
  }, [data]);

  useEffect(() => {
    if (apiType === API_TYPES.API_CREATE_PLACE_CATEGORY && placeCategoryFetchData && placeCategoryFetchData != null) {
      setCategory(placeCategoryFetchData);
      setOpenPlace(false);
    } else if (
      apiType === API_TYPES.API_DELETE_PLACE_CATEGORY &&
      placeCategoryFetchData &&
      placeCategoryFetchData != null
    ) {
      setCategory(placeCategoryFetchData);
    }
  }, [placeCategoryFetchData]);

  useEffect(() => {
    if (apiType === API_TYPES.API_CREATE_SERVICE_CATEGORY && serviceDataCreate != null) {
      let serviceAdded = { ...category };
      serviceAdded[serviceDataCreate.uid] = serviceDataCreate;
      setCategory(serviceAdded);
      setOpenService(false);
    }
  }, [serviceDataCreate]);

  useEffect(() => {
    if (apiType === API_TYPES.API_DELETE_SERVICE_CATEGORY && serviceDataDelete != null) {
      let serviceDeleted = { ...category };
      delete serviceDeleted[serviceDataDelete.uid];
      setCategory(serviceDeleted);
    }
  }, [serviceDataDelete]);

  const onExpand = event => {
    event.preventDefault();
  };

  const onOpenDialog = event => {
    event.stopPropagation();
    switch (name) {
      case PLACE_CATEGORY:
        setOpenPlace(true);
        break;
      case SERVICE_CATEGORY:
        setOpenService(true);
        break;
      default:
        return;
    }
  };

  const onCloseDialog = event => {
    event.stopPropagation();
    switch (name) {
      case PLACE_CATEGORY:
        setOpenPlace(false);
        break;
      case SERVICE_CATEGORY:
        setOpenService(false);
        break;
      default:
        return;
    }
  };

  // Handle service data
  const onDeleteService = (event, serviceUid) => {
    event.stopPropagation();

    if (!serviceCategoryRedux.isRemoveFetching) {
      dispatch(ServiceCategoryAction.removeServiceCategory({ uid: serviceUid }));
      setApiType(API_TYPES.API_DELETE_SERVICE_CATEGORY);
    }
  };

  const onAddService = (event, newData, timeUnitIndex) => {
    event.stopPropagation();

    if (newData.name !== null && newData.name !== "" && newData.usingTime !== null && newData.usingTime !== "") {
      event.preventDefault();
      let usingTime = newData.usingTime;
      if (timeUnitIndex === HOUR_UNIT) {
        usingTime = usingTime * 60;
      }

      let categoryAdded = { ...category };
      setCategory(categoryAdded);

      if (!serviceCategoryRedux.isCreateFetching) {
        dispatch(
          ServiceCategoryAction.createServiceCategory({
            name: newData.name,
            description: newData.description,
            usingTime: usingTime,
          }),
        );
        setApiType(API_TYPES.API_CREATE_SERVICE_CATEGORY);
      }
    }
  };

  //Handle place data
  const onSubmitPlace = (event, newData) => {
    event.stopPropagation();
    if (
      newData.name !== null &&
      newData.name !== "" &&
      newData.totalAmount !== null &&
      newData.totalAmount !== "" &&
      newData.capacity !== null &&
      newData.capacity !== ""
    ) {
      event.preventDefault();
      setOpenPlace(false);
      setOpenConfirm(true);
      setNewPlaceCategory(newData);
    }
  };

  const onDeletePlace = (event, placeUid) => {
    event.stopPropagation();

    if (placeCategoryFetchData !== null && !placeCategoryRedux.isRemoveFetching) {
      dispatch(PlaceCategoryAction.removePlaceCategory({ uid: placeUid }));
      setApiType(API_TYPES.API_DELETE_PLACE_CATEGORY);
    }
  };

  const onConfirm = (event, isAgree) => {
    event.stopPropagation();
    if (isAgree) {
      autoCreatePlace();
    } else {
      simpleCreatePlace();
    }
    setOpenConfirm(false);
  };

  const simpleCreatePlace = () => {
    let newPlace = {};
    const placeUid = uuid();
    newPlace.uid = placeUid;
    newPlace.name = newPlaceCategory.name;
    newPlace.items = {};

    const placeAdded = { ...category };
    placeAdded[placeUid] = newPlace;
    // setCategory(placeAdded);
    if (placeCategoryFetchData !== null && !placeCategoryRedux.isCreateFetching) {
      dispatch(PlaceCategoryAction.createPlaceCategory({ name: newPlaceCategory.name }));
      setApiType(API_TYPES.API_CREATE_PLACE_CATEGORY);
    }
  };

  const autoCreatePlace = () => {
    if (newPlaceCategory.totalAmount > 0) {
      if (placeCategoryFetchData !== null && !placeCategoryRedux.isCreateFetching) {
        dispatch(
          PlaceCategoryAction.createPlaceCategoryItem({
            name: newPlaceCategory.name,
            totalAmount: newPlaceCategory.totalAmount,
            capacity: newPlaceCategory.capacity,
          }),
        );
        setApiType(API_TYPES.API_CREATE_PLACE_CATEGORY);
      }
    } else {
      simpleCreatePlace();
    }
  };

  return (
    <div className={`${classes.root}`} style={style}>
      <Accordion onClick={e => onExpand(e)} className={classes.accordionRoot}>
        <Paper className={classes.paper}>
          <AccordionSummary
            expandIcon={<ExpandMore className={classes.iconExpand} />}
            aria-controls="panel1c-content"
            id="panel1c-header"
          >
            <Box className={classes.summaryBox}>
              {icon}
              <Typography className={classes.textHeading}>{headingText}</Typography>
              <Button
                color="primary"
                variant={"contained"}
                className={classes.createButton}
                onClick={e => onOpenDialog(e)}
              >
                <Typography variant="body2" classes={{ body2: "medium-md-txt" }} color={"inherit"} size="small">
                  {getLabel(LangConstant.TXT_CREATE_BUTTON)}
                </Typography>
              </Button>
            </Box>
          </AccordionSummary>
        </Paper>
        {!disableSubAccordion && (
          <AccordionDetails className={classes.details}>
            {category &&
              Object.keys(category).map(key => (
                <SubCategoryAccordion key={key} data={category[key]} onDelete={onDeletePlace}></SubCategoryAccordion>
              ))}
          </AccordionDetails>
        )}
        {disableSubAccordion && (
          <Box className={classes.directDetailsBox}>
            {category &&
              Object.keys(category).map(key => (
                <CategoryAccordionDetails
                  key={key}
                  classRoot={classes.directDetails}
                  directCall={true}
                  data={category[key]}
                  onDelete={onDeleteService}
                  serviceCategoryRedux={serviceCategoryRedux}
                ></CategoryAccordionDetails>
              ))}
          </Box>
        )}
      </Accordion>
      {openPlace && <PlaceDialog onClose={onCloseDialog} onSubmit={onSubmitPlace}></PlaceDialog>}
      {openService && <ServiceDialog onClose={onCloseDialog} onSubmit={onAddService}></ServiceDialog>}
      {openConfirm && <ConfirmDialog onSelectConfirm={onConfirm} categoryName={newPlaceCategory.name}></ConfirmDialog>}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    flexGrow: 1,
    borderRadius: "0%",
  },
  accordionRoot: {
    borderRadius: "0%",
  },
  summaryBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  details: {
    padding: "0px 0px",
    display: "flex",
    flexDirection: "column",
    "&:first-child": {
      paddingTop: 6,
    },
    overflowX: "scroll",
    msOverflowStyle: `none`,
    scrollbarWidth: `none`,
    "&::-webkit-scrollbar": {
      display: `none`,
    },
  },

  //Service call detail dialog
  directDetailsBox: {
    width: "100%",
    overflowX: "scroll",
    msOverflowStyle: `none`,
    scrollbarWidth: `none`,
    "&::-webkit-scrollbar": {
      display: `none`,
    },
  },
  directDetails: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "1060px",
    },
    padding: "0px 0px",
    display: "flex",
    flexDirection: "column",
    "&:first-child": {
      paddingTop: 6,
    },
    "&:not(:last-child)": {
      borderBottom: `solid 0.5px ${theme.palette.grey[300]}`,
    },
  },
  textHeading: {
    marginLeft: 10,
  },
  createButton: {
    textTransform: "unset",
    minWidth: 82,
    minHeight: 30,
    marginRight: 25,
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconExpand: {
    color: theme.palette.grey[500],
  },
}));

CategoryAccordion.propTypes = {
  icon: PropTypes.node.isRequired,
  headingText: PropTypes.string.isRequired,
  name: PropTypes.string,
  style: PropTypes.object,
  disableSubAccordion: PropTypes.bool,
  data: PropTypes.object,
};

CategoryAccordion.defaultProps = { style: {}, disableSubAccordion: false };

const API_TYPES = {
  API_CREATE_PLACE_CATEGORY: 1,
  API_CREATE_PLACE_CATEGORY_ITEM: 2,
  API_DELETE_PLACE_CATEGORY: 3,
  API_CREATE_SERVICE_CATEGORY: 4,
  API_DELETE_SERVICE_CATEGORY: 5,
};

export const PLACE_CATEGORY = "Place";
export const SERVICE_CATEGORY = "Service";

export default memo(CategoryAccordion);
