export default {
  TXT_MN_REVIEW: "Quản lý đánh giá",
  TXT_CONSUMER_RATING_MANAGEMENT: "Quản lý đánh giá khách hàng",
  TXT_RATING_FILTER: "Bộ lọc đánh giá",
  TXT_SHOP_RATING: "Đánh giá shop",
  TXT_PRODUCT_RATING: "Đánh giá sản phẩm",
  TXT_REPORT: "Báo cáo",
  TXT_REPORTED: "Đã báo cáo",
  TXT_VIOLATION_REPORT: "Báo cáo vi phạm",
  TXT_HIDDEN_REPORT_CONTENT: "Đánh giá đã được ẩn",
  TXT_MASTER_PRODUCT: "Sản phẩm",
  TXT_CLEAR_FILTER: "Xóa bộ lọc",
  TXT_FILTER: "Lọc sản phẩm",
  TXT_APPLY: "Áp dụng",
  TXT_RATING_FROM_CONSUMER: "Đánh giá từ Khách hàng",
  TXT_SEE_ALL_RATING: "Xem tất cả đánh giá của shop",

  FM_RATING_SCORE: "{0} Sao",
  FM_TOTAL_RATING_AMOUNT: "{0} đánh giá",
  FM_TOTAL_REPORT: "{0} lượt báo cáo vi phạm",
};
