import { Switch, withStyles } from "@material-ui/core";

export const AntSwitchPrimary = withStyles(theme => ({
  root: {
    width: 40,
    height: 18,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  track: {
    border: "1px solid" + theme.palette.grey[500],
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export const AntSwitchSecondary = withStyles(theme => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#3b5998",
        borderColor: "#3b5998",
      },
    },
  },
  thumb: {
    width: 8,
    height: 8,
    boxShadow: "none",
  },
  track: {
    border: "1px solid" + theme.palette.grey[500],
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  disabled: {
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 0.3,
        backgroundColor: "#3b5998",
        borderColor: "#3b5998",
      },
    },
  },
}))(Switch);
