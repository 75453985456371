import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Typography, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, jumpToTargetBooking } from "utils";
import ReservationAction from "redux/reservation.redux";

const NewOrderConfirm = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MAIN_LAYOUT);
  const dispatch = useDispatch();
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);

  const onClick = event => {
    event.stopPropagation();
    const isPeriodic = Boolean(data.periodicCode && data.periodicCode !== "");
    if (isPeriodic) {
      setOpenDialog(true);
    } else {
      onConfirm([data.uid]);
    }
  };

  const onCloseDialog = event => {
    event.stopPropagation();
    setOpenDialog(false);
  };

  const onClickDialog = (event, index) => {
    event.stopPropagation();
    setOpenDialog(false);
    switch (index) {
      case AppConstant.READ_TYPE_NOTIFICATION.seenDetail:
        jumpToTargetBooking(history, data.startTime, data.uid);
        break;
      case AppConstant.READ_TYPE_NOTIFICATION.confirmAll:
        onConfirm(data.periodicReservationUid);
        break;
    }
  };

  const onConfirm = uids => {
    dispatch(
      ReservationAction.postReservationListStatus({
        status: AppConstant.STT_RESERVATION.confirm,
        uids: uids,
      }),
    );
  };

  return (
    <>
      <Button color="primary" size="small" className={classes.mainButton} onClick={onClick}>
        <Typography color="inherit" className={clsx(classes.mainButtonTypo, "medium-sm-txt")}>
          {getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
        </Typography>
      </Button>
      <ConfirmDialog open={openDialog} getLabel={getLabel} onClick={onClickDialog} onClose={onCloseDialog} />
    </>
  );
};

const ConfirmDialog = props => {
  const classes = useStyles();
  const { open, getLabel, onClick, onClose } = props;

  return (
    <Dialog aria-labelledby="new-booking-confirm-dialog" open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6">{getLabel(LangConstant.TXT_NEW_ORDER_DIALOG_TITLE)}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography color="inherit" variant="body2">
          {getLabel(LangConstant.TXT_NEW_ORDER_DIALOG_CONTENT)}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {LangConstant.ARR_NEW_BOOKING_DIALOG_BUTTON.map((value, index) => (
          <Button key={index} onClick={e => onClick(e, index)} className={classes.buttonDialogActions}>
            {getLabel(getCommonKey(value))}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  mainButton: {
    width: 70,
    minHeight: 20,
    backgroundColor: theme.palette.reservation.finished,
    color: theme.palette.white,
    textTransform: "unset",
    padding: "0 4px",
    marginTop: 8,
    "&:hover": {
      backgroundColor: theme.palette.reservation.finished,
    },
  },
  dialogTitle: {
    padding: 24,
  },
  dialogContent: {
    borderBottom: `solid 1px ${theme.palette.grey[100]}`,
    color: theme.palette.grey[500],
    paddingBottom: 24,
  },
  dialogActions: {
    display: "flex",
  },
  buttonDialogActions: {
    color: theme.palette.grey[400],
  },
}));

NewOrderConfirm.propTypes = {
  data: PropTypes.object,
};
NewOrderConfirm.defaultProps = {
  data: {},
};

export default memo(NewOrderConfirm);
