import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Chip, makeStyles, Select, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { chipsId, FilterCategoryMenu } from "components/mn-master-product/CategoryMenu";
import CategoryAllMenuItem from "./CategoryAllMenuItem";
import { sortDataByArray, textNormalize } from "utils";

const CategoryAllMenu = props => {
  const {
    label,
    modeViewAr,
    customClasses,
    data,
    allItemData,
    itemsKey,
    onChange,
    selectedArr,
    isDisable,
    ...otherProps
  } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(selectedArr);
  const [categoryData] = useState(data);
  const [itemData] = useState(allItemData ? allItemData : getUniqueItemList(data, itemsKey));
  const [showData, setShowData] = useState(categoryData);
  const [searchValue, setSearchValue] = useState("");
  const [modeView, setModeView] = useState(modeViewAr[0]?.id);
  const [scroll, setScroll] = useState(false);

  const isExistItems = data.some(element => element[itemsKey]);
  const isCategoryMode = modeView === modeViewAr[0]?.id;

  const onCheckBox = selectedId => {
    const newSelectedValue = [...selectedValue];
    if (!newSelectedValue.includes(selectedId)) {
      newSelectedValue.push(selectedId);
    } else {
      const deleteIndex = newSelectedValue.indexOf(selectedId);
      if (deleteIndex > -1) {
        newSelectedValue.splice(deleteIndex, 1);
      }
    }
    setSelectedValue(newSelectedValue);

    onChange({ [modeView]: newSelectedValue });
  };

  const onCategoryCheckBox = selectedCategoryId => {
    //Category
    const newSelectedCategory = [...selectedCategory];

    //Item
    const newSelectedValue = [...selectedValue];
    const targetCategory = categoryData.find(data => data.uid === selectedCategoryId);

    if (!newSelectedCategory.includes(selectedCategoryId)) {
      newSelectedCategory.push(selectedCategoryId);
      targetCategory &&
        targetCategory[itemsKey]?.map(item => {
          //Check item
          newSelectedValue.push(item.uid);
        });
    } else {
      const deleteCategoryIndex = newSelectedCategory.indexOf(selectedCategoryId);
      if (deleteCategoryIndex > -1) {
        newSelectedCategory.splice(deleteCategoryIndex, 1);
      }
      //Check item
      targetCategory &&
        targetCategory[itemsKey]?.map(item => {
          const deleteIndex = newSelectedValue.indexOf(item.uid);
          if (deleteIndex > -1) {
            newSelectedValue.splice(deleteIndex, 1);
          }
        });
    }
    setSelectedValue(newSelectedValue);
    setSelectedCategory(newSelectedCategory);

    onChange({ [modeView]: newSelectedCategory });
  };

  const onCheck = selectedId => {
    onCheckBox(selectedId);
    setScroll(!scroll);
  };

  const onCheckCategory = selectedCategoryId => {
    onCategoryCheckBox(selectedCategoryId);
    setScroll(!scroll);
  };

  const onFilter = search => {
    var searchValue = textNormalize(search);
    setSearchValue(searchValue);
    const targetData = isCategoryMode ? categoryData : itemData;
    const cloneData = JSON.parse(JSON.stringify(targetData));
    const filteredArray = cloneData.filter(data => getFilterArray(data, searchValue));

    setShowData(search === "" ? targetData : filteredArray);
  };

  const onClearFilter = () => {
    setSearchValue("");
  };

  const onChangeModeView = event => {
    const newMode = event.currentTarget.id;

    if (
      newMode !== modeView &&
      ((modeView == modeViewAr[0].id && selectedCategory.length == 0) ||
        (modeView == modeViewAr[1].id && selectedValue.length == 0))
    ) {
      setModeView(newMode);
      setSelectedCategory([]);
      setSelectedValue([]);
      setShowData(newMode === modeViewAr[0]?.id ? categoryData : itemData);
      setSearchValue("");
    }
  };

  const onMouseDown = event => {
    event.stopPropagation();
  };

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      onFilter(searchValue);
    }, 500);
    return () => clearTimeout(searchTimeout);
  }, [searchValue]);

  useEffect(() => {
    let chipBox = document.getElementById(chipsId);
    if (chipBox) {
      chipBox.scrollTo(0, chipBox.offsetHeight);
    }
  }, [scroll]);

  useEffect(() => {
    if (selectedArr.length > 0 && isExistItems) {
      const newSelectedCategory = [...selectedCategory];
      const newSelectedValue = [...selectedValue];
      categoryData.map(category => {
        if (newSelectedCategory.includes(category.uid)) {
          category[itemsKey]?.map(item => {
            newSelectedValue.push(item.uid);
          });
        }
      });
      setSelectedValue(newSelectedValue);
    }
  }, []);

  return (
    <Select
      disabled={isDisable}
      className={clsx(classes.root, customClasses?.root)}
      displayEmpty
      labelId="label"
      multiple
      value={selectedValue}
      renderValue={value => {
        if (value.length == 0) {
          return (
            <Typography color="inherit" className="regular-md-txt">
              {label}
            </Typography>
          );
        } else {
          return (
            <Box className={isDisable ? classes.disableChips : classes.chips} id={chipsId}>
              {sortDataByArray(getCheckedData(itemData, value), selectedValue).map(data => {
                return (
                  <Chip
                    key={data.uid}
                    label={data.name}
                    className={classes.chip}
                    classes={{ deleteIcon: classes.deleteChip }}
                    onMouseDown={onMouseDown}
                    size="small"
                    clickable
                  />
                );
              })}
            </Box>
          );
        }
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        style: { marginTop: 4, maxWidth: 250 },
      }}
      classes={{ root: clsx(classes.selectRoot, customClasses?.selectRoot), icon: classes.selectIcon }}
      {...otherProps}
    >
      <FilterCategoryMenu
        modeViewAr={modeViewAr}
        enableChangeViewMode={true}
        onFilter={onFilter}
        onChangeMode={onChangeModeView}
        onClearFilter={onClearFilter}
        selectedId={modeView}
      />
      {showData.length == 0 ? (
        <Box className={classes.noResultBox}>
          <Typography variant="body2" color="inherit" classes={{ body2: classes.noResult }}>
            {getLabel(LangConstant.TXT_RESULT_NOT_FOUND)}
          </Typography>
        </Box>
      ) : (
        <CategoryAllMenuItem
          data={showData}
          categoryMode={isCategoryMode}
          onCheck={isCategoryMode ? onCheckCategory : onCheck}
          selectedValue={isCategoryMode ? selectedCategory : selectedValue}
        />
      )}
    </Select>
  );
};

const getUniqueItemList = (categoryList, itemsKey) => {
  let validateList = new Set();
  let uniqueItemList = [];

  categoryList.map(category => {
    category[itemsKey]?.map(item => {
      if (validateList.size !== validateList.add(item.uid).size) {
        uniqueItemList.push(item);
      }
    });
  });
  return uniqueItemList;
};

const getFilterArray = (value, search) => {
  return textNormalize(value.name).includes(search);
};

const getCheckedData = (listData, checkedValue) => {
  const result = listData.filter(data => checkedValue.includes(data.uid));
  return result;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    border: `solid 1px #d4d5d8`,
    borderRadius: "3px",
    "&.MuiInput-underline": {
      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
  },
  selectRoot: {
    height: "max-content",
    wordWrap: "anywhere",
    width: "100%",
    color: "#d4d5d8",
    padding: "10px 16px 10px",
    "&.MuiSelect-select": {
      paddingRight: 0,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  label: { marginLeft: 16, marginRight: 30 },
  chips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
  },
  disableChips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
    "& > *": { cursor: "default" },
  },
  chip: {
    height: 18,
    color: theme.palette.white,
    background: theme.palette.text.link,
    margin: 4,
  },
  deleteChip: {
    color: theme.palette.white,
  },
  noResultBox: {
    color: "#7f838c",
    margin: "10px 16px 0",
  },
}));

CategoryAllMenu.propTypes = {
  customClasses: PropTypes.object,
  label: PropTypes.string,
  modeViewAr: PropTypes.array,
  data: PropTypes.array.isRequired,
  itemsKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selectedObj: PropTypes.object,
  isDisable: PropTypes.bool,
};

CategoryAllMenu.defaultProps = {
  customClasses: {},
  label: "",
  modeViewAr: [],
  data: [],
  itemsKey: "",
  onChange: () => {},
  selectedArr: [],
  isDisable: false,
};

export default memo(CategoryAllMenu);
