import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useMediaQuery, useTheme, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { Close, LocalMallOutlined } from "@material-ui/icons";
import { ListCategoryByMenu, ListItems, ListSelectedProduct, NotifyBadge, OptionView } from "components";
import { LangConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey, removeVietnameseTones } from "utils";
import { useDispatch, useSelector } from "react-redux";
import ComboItemAction from "redux/combo-item.redux";
import MnProductAction from "redux/mn-product.redux";
import ServiceItemAction from "redux/service-item.redux";
import MenuAction from "redux/menu.redux";

const DialogPreOrder = ({ isShow, isShowCategory, data, onSubmitFormData, onClose }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1240));
  const dispatch = useDispatch();

  const listMenuData = useSelector(state => state.menuRedux.data);
  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem);
  const listProductItem = useSelector(state => state.mnProductRedux.dataItem);
  const listServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem);

  const [arrayData, setArrayData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [value, setValue] = useState(0);
  const [viewOption, setViewOption] = useState(0);
  const [isShowSelectedProduct, setIsShowSelectedProduct] = useState(false);
  const [totalItem, setTotalItem] = useState(0);

  const defaultCategory =
    isShowCategory && ARRAY_CATEGORY.map(mapData => ({ ...mapData, name: getLabel(getCommonKey(mapData.name)) }));

  const onChangeTabs = (_, newValue) => {
    setValue(newValue);
  };

  const onChangeViewOption = (_, newValue) => {
    setViewOption(newValue);
  };

  const onSearchItem = name => {
    let convertName = removeVietnameseTones(name.toLowerCase());
    let newListSearch = [];
    arrayData.forEach(categorySearch => {
      if (removeVietnameseTones(categorySearch.name).toLowerCase().includes(convertName)) {
        newListSearch.push(categorySearch);
      } else {
        let newItemSearch = categorySearch.itemList.filter(itemSearch =>
          removeVietnameseTones(itemSearch.name).toLowerCase().includes(convertName),
        );
        if (newItemSearch.length > 0) {
          newListSearch.push({
            ...categorySearch,
            itemList: newItemSearch,
          });
        }
      }
    });
    setSearchData(newListSearch);
  };

  const onChooseItem = (parentIndex, childIndex) => {
    let isSame = false;
    let searchData = arrayData[parentIndex].itemList[childIndex];
    let newSelectedData = selectedData.map(dataMap => {
      if (dataMap.uid === searchData.uid && dataMap.toppingListIndex.length === 0) {
        isSame = true;
        return {
          ...dataMap,
          orderNumber: dataMap.orderNumber + 1,
        };
      }
      return dataMap;
    });
    if (isSame) {
      setSelectedData(newSelectedData);
    } else {
      setSelectedData([...newSelectedData, searchData]);
    }
  };

  const onRemoveItem = removedIndex => {
    let newSelectedData = [...selectedData];
    newSelectedData.splice(removedIndex, 1);
    setSelectedData(newSelectedData);
  };

  const onOpenListSelected = () => {
    setIsShowSelectedProduct(true);
  };

  const onComeBack = () => {
    setIsShowSelectedProduct(false);
  };

  const onChangeTotalItem = number => {
    setTotalItem(number);
  };

  useEffect(() => {
    if (isShowCategory && !(listComboItem && listProductItem && listServiceItem)) {
      dispatch(ComboItemAction.getListComboItem());
      dispatch(MnProductAction.getProductItem());
      dispatch(ServiceItemAction.getListServiceItem());
    } else if (listMenuData.length === 0) {
      dispatch(MenuAction.getMenu({ type: AppConstant.DATA_TYPE.booking }));
    }
  }, [isShowCategory]);

  useEffect(() => {
    if (!isShowCategory) {
      let newArrayData = listMenuData.map(dataMap => ({
        ...dataMap,
        itemList: dataMap.itemList.map(dataItem => ({
          ...dataItem,
          orderNumber: 1,
          toppingList: [],
          toppingListIndex: [],
        })),
      }));
      setArrayData(newArrayData);
      setSearchData(newArrayData);
    }
  }, [listMenuData, isShowCategory]);

  useEffect(() => {
    if (isShowCategory && listComboItem && listProductItem && listServiceItem) {
      let newArrayData = [...defaultCategory];

      // Format Data Service Item
      let newFormListServiceItem = [...listServiceItem?.data];
      newArrayData[0].itemList = formatFormDataPreOrder(newFormListServiceItem, AppConstant.TYPE_CATEGORY.service);

      // Format Data Product Item
      let newFormListProductItem = [...listProductItem?.data];
      newArrayData[1].itemList = formatFormDataPreOrder(newFormListProductItem, AppConstant.TYPE_CATEGORY.product);

      // Format Data Combo Item
      let newFormListComboItem = [...listComboItem?.data];
      newArrayData[2].itemList = formatFormDataPreOrder(newFormListComboItem, AppConstant.TYPE_CATEGORY.combo);

      setArrayData(newArrayData);
      setSearchData(newArrayData);
    }
  }, [listComboItem, listProductItem, listServiceItem, isShowCategory]);

  useEffect(() => {
    if (data.length > 0) {
      setSelectedData(data);
    }
  }, [data]);

  return (
    <Dialog open={isShow} fullScreen>
      <Box className={defaultClasses.root}>
        <Box className={defaultClasses.boxIconClose}>
          <IconButton onClick={onClose} className={defaultClasses.iconButtonClose}>
            <Close className={defaultClasses.iconClose} />
          </IconButton>
        </Box>
        <Box className={defaultClasses.boxHeader}>
          {!isDesktop && (
            <IconButton
              onClick={onOpenListSelected}
              classes={{ root: defaultClasses.buttonBadge, label: defaultClasses.buttonBadgeLabel }}
            >
              <NotifyBadge data={totalItem} badgeClass={defaultClasses.badgeClass}>
                <LocalMallOutlined className={defaultClasses.iconSelectedItem} />
              </NotifyBadge>
            </IconButton>
          )}
          <Box className={defaultClasses.boxTitle}>
            {isShowCategory ? (
              <Typography className={defaultClasses.titleText}>
                {getLabel(LangConstant.TXT_CATEGORY_PRODUCT)}
              </Typography>
            ) : (
              <Typography className={defaultClasses.titleText}>
                {getLabel(getCommonKey(LangConstant.TXT_MENU))}
              </Typography>
            )}
          </Box>
          {!isDesktop && <Box width="40px" height="40px" marginRight="16px" />}
        </Box>
        <Box className={defaultClasses.boxBody}>
          <Box className={defaultClasses.listCategoryBox}>
            <ListCategoryByMenu
              value={value}
              isShowCategory={isShowCategory}
              data={searchData}
              onChange={onChangeTabs}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="on"
            />
            <Box className={defaultClasses.bodyProduct}>
              <OptionView value={viewOption} onSearch={onSearchItem} onChangeView={onChangeViewOption} />
              <ListItems
                data={searchData}
                viewOption={viewOption}
                onChooseItem={onChooseItem}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            </Box>
          </Box>
          <ListSelectedProduct
            isShow={isShowSelectedProduct}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            haveData={Boolean(data.length > 0)}
            onChangeTotalItem={onChangeTotalItem}
            onRemoveItem={onRemoveItem}
            onSubmitFormData={onSubmitFormData}
            onCloseAll={onClose}
            onComeBack={onComeBack}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export const ARRAY_CATEGORY = [
  {
    name: LangConstant.TXT_SERVICE,
    itemList: [],
  },
  {
    name: LangConstant.TXT_PRODUCT,
    itemList: [],
  },
  {
    name: LangConstant.TXT_COMBO,
    itemList: [],
  },
];

export const formatFormDataPreOrder = (data, type) => {
  if (data) {
    let newFormDataPreOrder = [];
    if (type === AppConstant.TYPE_CATEGORY.combo) {
      data.forEach(dataMap => {
        newFormDataPreOrder.push({
          ...dataMap,
          itemDetailsContentList: dataMap.itemDetails?.itemDetailsContentList || [],
          type: AppConstant.TYPE_CATEGORY.combo,
          orderNumber: 1,
          toppingList: [],
          toppingListIndex: [],
        });
      });
    } else if (type === AppConstant.TYPE_CATEGORY.service || type === AppConstant.TYPE_CATEGORY.product) {
      data.forEach(dataMap => {
        let saveContent = [];
        let listDetailsData = dataMap.itemDetailsList;
        if (listDetailsData && Array.isArray(listDetailsData)) {
          listDetailsData.forEach(dataDetails => {
            let listContentData = dataDetails.itemDetailsContentList;
            if (listContentData && Array.isArray(listContentData) && listContentData.length > 0) {
              saveContent = [...saveContent, ...listContentData];
            }
          });
        }
        let newContentList = [];
        saveContent.forEach(objectData => {
          if (!newContentList.find(dataFind => dataFind.uid === objectData.uid)) {
            newContentList.push(objectData);
          }
        });
        newFormDataPreOrder.push({
          ...dataMap,
          itemDetailsContentList: newContentList,
          type:
            type === AppConstant.TYPE_CATEGORY.service
              ? AppConstant.TYPE_CATEGORY.service
              : AppConstant.TYPE_CATEGORY.product,
          orderNumber: 1,
          toppingList: [],
          toppingListIndex: [],
        });
      });
    }
    return newFormDataPreOrder;
  }
  return [];
};

DialogPreOrder.propTypes = {
  isShow: PropTypes.bool,
  isShowCategory: PropTypes.bool,
  data: PropTypes.array,
  onSubmitFormData: PropTypes.func,
  onClose: PropTypes.func,
};
DialogPreOrder.defaultProps = {
  isShow: false,
  isShowCategory: false,
  data: [],
  onSubmitFormData: () => {},
  onClose: () => {},
};

export default memo(DialogPreOrder);

const useStyles = makeStyles(theme => ({
  root: {
    padding: "28px 36px 0",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0 0",
    },
  },
  boxIconClose: {
    height: 36,
    margin: "8px 0",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      margin: "0 16px",
    },
  },
  iconButtonClose: {
    width: 36,
    height: 36,
    padding: 0,
  },
  iconClose: {
    width: "inherit",
    height: "inherit",
  },
  boxHeader: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 54,
    [theme.breakpoints.down(1240)]: {
      margin: "24px 24px 32px",
      height: 40,
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0 32px",
      height: 40,
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  buttonBadge: {
    height: 40,
    width: 40,
    padding: 0,
    marginLeft: 16,
  },
  buttonBadgeLabel: {
    height: 40,
    alignItems: "flex-end",
  },
  badgeClass: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    top: 2,
    left: 6,
  },
  iconSelectedItem: {
    height: 32,
  },
  headerButton: {
    padding: 0,
  },
  boxTitle: {
    textAlign: "center",
    height: 32,
  },
  titleText: {
    fontSize: 24,
    height: "inherit",
    color: theme.palette.black,
    lineHeight: 1.33,
  },
  boxBody: {
    width: "100%",
    height: "calc(100% - 138px)",
    padding: "0 40px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  listCategoryBox: {
    width: "66.6%",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  bodyProduct: {
    height: "calc(100vh - 258px)",
    minHeight: 700,
    padding: "24px 0",
    marginBottom: 36,
    border: "0.5px solid " + theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      padding: "24px 12px",
    },
  },
}));
