import React, { memo, useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  OutlinedInput,
  Select,
  MenuItem,
  useMediaQuery,
  withStyles,
  Button,
  Avatar,
  FormControl,
  makeStyles,
  FormHelperText,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputBase from "@material-ui/core/InputBase";
import { LangConstant, AppConstant, ApiConstant } from "const";
import { getSortName } from "utils";
import { useDispatch, useSelector } from "react-redux";
import Cookie from "js-cookie";
import UserAction from "redux/user.redux";
import { DialogConfirm } from "./dialog";
import { isExists } from "date-fns";
import { validatePhone } from "utils/index";
import Alert from "./dialog/Alert";
import { checkFuture } from "utils/date";
import CropImage from "./CropImage";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "0.5px solid #ced4da",
    fontSize: 16,
    padding: "15.5px 26px 15.5px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 3,
    },
  },
}))(InputBase);

const AccountInfoDetail = props => {
  const { data } = props;
  const role = Cookie.get(AppConstant.ROLE);
  function generate(size, start) {
    return [...Array(size)].map((_, index) => (
      <MenuItem key={index} value={index + start}>
        {index + start}
      </MenuItem>
    ));
  }
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputElementRef = useRef();
  const { t: getLabel } = useTranslation();
  const isUserUpdateSuccess = useSelector(state => state.userRedux.isUserUpdateSuccess);
  const typoMediumClass = "medium-md-txt inline";
  const matches = useMediaQuery(useTheme().breakpoints.up("md"));
  const [userData, setUserData] = useState(data != null ? data : {});
  const [isDisable, setDisable] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isShowNotice, setIsShowNotice] = useState({ state: false, title: "" });
  const [isShowErr, setIsShowErr] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [error, setError] = useState(0);
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const focus = () => {
    inputElementRef.current.click();
  };

  const onChangeImage = fileList => {
    let file;
    if (fileList.length && fileList.length > 0) {
      file = fileList[0];
    } else {
      file = fileList;
    }
    const appData = Cookie.get(AppConstant.KEY_GBOSS);
    var userId = null;

    if (appData && appData.length > 0 && file !== null) {
      userId = JSON.parse(appData).userId;
      var fileSize = file.size / 1024 / 1024;
      if (fileSize > 1) {
        setIsShowNotice({ state: true, title: "Vui lòng sử dụng hình ảnh < 1M" });
      } else {
        dispatch(
          UserAction.requestUploadUserImage({
            upload: { file: file, type: "image" },
            update: { ...userData, userId: userId },
          }),
        );
      }
    }
  };
  const onChange = event => {
    const name = event.target.name;
    setUserData({
      ...userData,
      [name]: event.target.value,
    });
    setIsEdited(true);
  };
  const showButtonDelete = event => {
    if (event) {
      setIsShowConfirm(true);
    }
  };
  const deleteImage = () => {
    const appData = Cookie.get(AppConstant.KEY_GBOSS);
    if (appData && appData.length > 0) {
      const userId = JSON.parse(appData).userId;
      let clone = Object.assign({}, userData);
      clone[AppConstant.KEY_USER_ID] = userId;
      clone[AppConstant.KEY_AVATAR] = "";
      dispatch(UserAction.requestUpdateUserInfo(clone));
    }
    setIsShowConfirm(false);
  };

  const rejectImage = () => {
    setIsShowConfirm(false);
  };
  const onSubmit = () => {
    if (!isDisable && validatePhone(userData.phoneNumber)) {
      setDisable(true);
      const appData = Cookie.get(AppConstant.KEY_GBOSS);
      if (appData && appData.length > 0) {
        const userId = JSON.parse(appData).userId;
        let clone = Object.assign({}, userData);
        clone[AppConstant.KEY_USER_ID] = userId;
        if (
          clone[AppConstant.KEY_DAY] &&
          clone[AppConstant.KEY_DAY] !== 0 &&
          clone[AppConstant.KEY_MONTH] &&
          clone[AppConstant.KEY_MONTH] !== 0 &&
          clone[AppConstant.KEY_YEAR] &&
          clone[AppConstant.KEY_YEAR] !== 0 &&
          isExists(clone[AppConstant.KEY_YEAR], clone[AppConstant.KEY_MONTH] - 1, clone[AppConstant.KEY_DAY]) &&
          !checkFuture(
            new Date(clone[AppConstant.KEY_YEAR], clone[AppConstant.KEY_MONTH] - 1, clone[AppConstant.KEY_DAY]),
          )
        ) {
          setIsWarning(false);
          setError(0);
          let date = new Date(
            Date.UTC(clone[AppConstant.KEY_YEAR], clone[AppConstant.KEY_MONTH] - 1, clone[AppConstant.KEY_DAY]),
          );
          clone[AppConstant.KEY_BIRTHDAY] = Math.floor(date.getTime() / 1000);
          dispatch(UserAction.requestUpdateUserInfo(clone));
        } else {
          setIsWarning(true);
          setError(AppConstant.ERROR_CODE.invalid_birthday);
        }
      }
      setDisable(false);
    } else {
      setIsShowErr(true);
    }
  };

  const onSelectImage = event => {
    let tmpArr = [];
    Array.from(event.target.files).forEach(file => {
      if (AppConstant.ACCEPT_IMAGE.includes(file.type)) {
        tmpArr.push(file);
      }
    });
    setSelectedFiles(tmpArr);
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setIsOpenCropDialog(true);
    }
  }, [selectedFiles]);

  useEffect(() => {
    setUserData(data);
  }, [data]);

  useEffect(() => {
    if (isUserUpdateSuccess) {
      setIsAlert(true);
      setIsEdited(false);
      dispatch(UserAction.resetUserUpdate());
    }
  }, [isUserUpdateSuccess]);

  return (
    <>
      <Grid className="account-info-detail">
        <Grid item container direction="column" alignItems="center" className="root">
          <Grid item className="titleBox">
            <Typography className="title" variant="h4">
              {getLabel(LangConstant.TXT_ACCOUNT_SETTING)}
            </Typography>
          </Grid>
          <Grid item container direction="column" className="main" spacing={4}>
            <Grid item container direction="column" className="head" spacing={4}>
              <Grid item container justify="flex-start" direction="row">
                <Grid>
                  <Avatar
                    className="iconLarge"
                    src={
                      userData[AppConstant.KEY_AVATAR]
                        ? `${ApiConstant.HOST_ATTACHMENT}${userData[AppConstant.KEY_AVATAR]}`
                        : ""
                    }
                  >
                    {userData[AppConstant.KEY_AVATAR] ? "" : getSortName(userData[AppConstant.KEY_NAME])}
                  </Avatar>
                </Grid>
                <Grid item xs container direction="column" wrap="nowrap" spacing={1}>
                  <Grid item xs container wrap="nowrap" direction="row" justify="flex-start" alignItems="center">
                    <Typography variant="subtitle1" className="positioncenter">
                      {userData[AppConstant.KEY_NAME] ? userData[AppConstant.KEY_NAME] : ""}
                    </Typography>
                    {matches && (
                      <Grid item xs zeroMinWidth>
                        <Typography noWrap variant="body2" className={`email`}>
                          ({getLabel(LangConstant.ROLE_LANG[role])})
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs container direction="row" alignItems="center">
                    <Grid item xs container direction="row">
                      <Button onClick={focus} className={`button ${typoMediumClass} addButton`} variant="outlined">
                        {getLabel(LangConstant.TXT_ADD_IMAGE)}
                      </Button>
                      <Button
                        className={
                          userData[AppConstant.KEY_AVATAR]
                            ? `button ${typoMediumClass} deleteButtonValue`
                            : `button ${typoMediumClass} deleteButton`
                        }
                        onClick={() => showButtonDelete(userData[AppConstant.KEY_AVATAR])}
                        variant="outlined"
                      >
                        {getLabel(LangConstant.TXT_DELETE_IMAGE)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid></Grid>
            <Alert
              isShow={isShowErr}
              onClose={() => setIsShowErr(false)}
              type="error"
              message={"Vui lòng kiểm tra lại thông tin"}
            />
            <DialogConfirm
              isShow={isShowConfirm}
              title={"Xác nhận"}
              message="Xóa ảnh đại diện?"
              classes={{ root: "dialog-small" }}
              onClose={deleteImage}
              onSubmit={deleteImage}
              onReject={rejectImage}
              action={getLabel(LangConstant.TXT_LOGIN)}
            />
            <Alert
              isShow={isShowNotice.state}
              onClose={() => setIsShowNotice({ ...isShowNotice, state: false })}
              type="error"
              message={isShowNotice.title}
            />
            <Grid item xs={12} container direction="column" className="info" spacing={4}>
              <Grid item xs={12} container direction="column" spacing={1}>
                <Grid item>
                  <Typography className="semiBold-md-txt">{getLabel(LangConstant.TXT_USER_NAME)}</Typography>
                </Grid>
                <Grid item>
                  <OutlinedInput
                    name={AppConstant.KEY_NAME}
                    onChange={onChange}
                    value={userData[AppConstant.KEY_NAME] ? userData[AppConstant.KEY_NAME] : ""}
                    className="input"
                  ></OutlinedInput>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="column" spacing={1}>
                <Grid item>
                  <Typography className="semiBold-md-txt">{getLabel(LangConstant.TXT_EMAIL)}</Typography>
                </Grid>
                <Grid item xs>
                  <OutlinedInput
                    onChange={onChange}
                    readOnly={true}
                    disabled
                    name={AppConstant.KEY_EMAIL}
                    value={userData[AppConstant.KEY_EMAIL] ? userData[AppConstant.KEY_EMAIL] : ""}
                    className="input"
                  ></OutlinedInput>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="column" spacing={1}>
                <Grid item>
                  <Typography className="semiBold-md-txt">{getLabel(LangConstant.TXT_PHONE_NUMBER)}</Typography>
                </Grid>
                <Grid item>
                  <OutlinedInput
                    onChange={onChange}
                    name={AppConstant.KEY_PHONE}
                    value={userData.phoneNumber ? userData.phoneNumber : ""}
                    className="input"
                  ></OutlinedInput>
                </Grid>
              </Grid>
              <Grid item xs={12} container direction="column" spacing={1}>
                <Grid item>
                  <Typography className="semiBold-md-txt">{getLabel(LangConstant.TXT_SEX)}</Typography>
                </Grid>
                <Grid item>
                  <Select
                    name={AppConstant.KEY_SEX}
                    value={userData[AppConstant.KEY_SEX] ? userData[AppConstant.KEY_SEX] : 0}
                    onChange={onChange}
                    className="select lagre"
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={0}>
                      <em>{getLabel(LangConstant.TXT_NONE)}</em>
                    </MenuItem>
                    <MenuItem value={1}>{getLabel(LangConstant.TXT_MAN)}</MenuItem>
                    <MenuItem value={2}>{getLabel(LangConstant.TXT_WOMAN)}</MenuItem>
                    <MenuItem value={3}>{getLabel(LangConstant.TXT_OTHER)}</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} style={{ paddingBottom: 8 }}>
                  <Typography className="semiBold-md-txt">{getLabel(LangConstant.TXT_BIRTH_DAY)}</Typography>
                </Grid>
                <Grid item xs={12} container direction="row" spacing={1}>
                  <Grid item xs={4}>
                    <Select
                      value={
                        userData[AppConstant.KEY_DAY] && typeof userData[AppConstant.KEY_DAY] == "number"
                          ? userData[AppConstant.KEY_DAY]
                          : 0
                      }
                      name={AppConstant.KEY_DAY}
                      onChange={onChange}
                      className="select small"
                      input={<BootstrapInput />}
                      MenuProps={{ paper: { width: "300px" } }}
                    >
                      <MenuItem value={0}>
                        <em>{getLabel(LangConstant.TXT_DAY)}</em>
                      </MenuItem>
                      {generate(31, 1)}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      value={
                        userData[AppConstant.KEY_MONTH] && typeof userData[AppConstant.KEY_MONTH] == "number"
                          ? userData[AppConstant.KEY_MONTH]
                          : 0
                      }
                      name={AppConstant.KEY_MONTH}
                      onChange={onChange}
                      className="select small"
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={0}>
                        <em>{getLabel(LangConstant.TXT_MONTH)}</em>
                      </MenuItem>
                      {generate(12, 1)}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      value={
                        userData[AppConstant.KEY_YEAR] && typeof userData[AppConstant.KEY_YEAR] == "number"
                          ? userData[AppConstant.KEY_YEAR]
                          : 0
                      }
                      name={AppConstant.KEY_YEAR}
                      onChange={onChange}
                      className="select small"
                      input={<BootstrapInput />}
                    >
                      <MenuItem value={0}>
                        <em>{getLabel(LangConstant.TXT_YEAR)}</em>
                      </MenuItem>
                      {generate(new Date().getFullYear() - 1949, 1950)}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <FormControl error className={classes.errorLabel}>
                {isWarning === true && error === AppConstant.ERROR_CODE.invalid_birthday && (
                  <FormHelperText>{getLabel(LangConstant.ERR_INVALID_DAY)}</FormHelperText>
                )}
              </FormControl>

              <Grid item xs={12} container direction="column" spacing={1}>
                <Grid item container direction="row" justify="center">
                  {isEdited && (
                    <Button
                      className="buttonUpdate"
                      variant="contained"
                      disabled={isDisable}
                      onClick={onSubmit}
                      color="primary"
                    >
                      <Typography variant="body1" classes={{ body1: `semiBold-md-txt  typoAccept` }}>
                        {getLabel(LangConstant.TXT_UPDATE)}
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <input
        ref={inputElementRef}
        onChange={onSelectImage}
        id="files"
        style={{ display: "none" }}
        type="file"
        accept={AppConstant.ACCEPT_IMAGE}
        multiple={false}
      />
      <CropImage
        isMultiple={false}
        isEnableCrop={true}
        inputArray={selectedFiles}
        aspect={1 / 1}
        onSubmit={file => {
          onChangeImage(file);
        }}
        isShow={isOpenCropDialog}
        onClose={() => setIsOpenCropDialog(false)}
        title={getLabel(LangConstant.TXT_EDIT_IMAGE)}
      />
      {isAlert && (
        <Alert
          isShow={true}
          onClose={() => setIsAlert(false)}
          type="success"
          message={getLabel(LangConstant.MSG_UPDATE_SUCCESS)}
        />
      )}
    </>
  );
};

AccountInfoDetail.propTypes = {
  data: PropTypes.object,
};
AccountInfoDetail.defaultProps = {
  data: {},
};

export default memo(AccountInfoDetail);

const useStyles = makeStyles(() => ({
  errorLabel: {
    alignItems: "center",
    marginTop: "4vh",
    marginBottom: "1vh",
  },
}));
