import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, IconButton, Typography, Button, Tooltip, CardMedia } from "@material-ui/core";
import { EditOutlined, DeleteOutlined, CheckCircle } from "@material-ui/icons";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import { getAttachmentUrl, getCommonKey, onLoadImageError } from "utils";

const CategoryItemListBody = ({
  uid,
  customClasses,
  onClickWatch,
  onClickAdd,
  onClickEdit,
  onClickDelete,
  image,
  categoryName,
  timeUsed,
  stock,
  typeStockProduct,
  price,
  isAdd,
  disabled,
  isNotClickEdit,
  isRemoveMode,
}) => {
  const classes = useStyles();

  const typeIcon = type => {
    switch (type) {
      case AppConstant.TYPE_PRODUCT_ICON.notMarked:
        return <CheckCircle className={classes.prefixIconType2} />;
      case AppConstant.TYPE_PRODUCT_ICON.marked:
        return <CheckCircle className={classes.prefixIconType1} />;
      default:
        return <></>;
    }
  };

  return (
    <Box className={clsx(classes.root, customClasses?.root)}>
      <Box className={classes.boxBodyCategoryName}>
        <Box className={classes.boxImage}>
          <CardMedia
            component="img"
            className={classes.image}
            src={getAttachmentUrl(image)}
            onError={onLoadImageError}
          />
        </Box>
        <Box className={classes.boxName} onClick={() => onClickWatch(uid)}>
          <Typography className={classes.categoryNameTypo}>{categoryName}</Typography>
        </Box>
      </Box>
      <Box className={timeUsed ? classes.boxBody : classes.boxBodyProduct}>
        <Typography className={classes.bodyTypo}>{timeUsed ? timeUsed : stock}</Typography>
        {typeStockProduct && <Box className={classes.boxIconTick}>{typeIcon(typeStockProduct)}</Box>}
      </Box>
      <Box className={classes.boxBody}>
        <Typography className={classes.bodyTypo}>{price}</Typography>
      </Box>
      {isAdd ? (
        <AddComponent uid={uid} onClickAdd={onClickAdd} disabled={disabled} />
      ) : (
        <EditAndDeleteComponent
          uid={uid}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          isNotClickEdit={isNotClickEdit}
          isRemoveMode={isRemoveMode}
        />
      )}
    </Box>
  );
};

const AddComponent = ({ uid, onClickAdd, disabled }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Box className={classes.boxOptionAdd}>
      <Button disabled={disabled} onClick={() => onClickAdd(uid)} className={classes.buttonAdd}>
        {getLabel(LangConstant.TXT_ADD)}
      </Button>
    </Box>
  );
};

const EditAndDeleteComponent = ({ uid, onClickEdit, onClickDelete, isNotClickEdit, isRemoveMode }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  return (
    <Box className={classes.boxOptionEditAndDelete}>
      <Tooltip
        title={
          isNotClickEdit
            ? getLabel(LangConstant.TXT_WARNING_EDIT_SERVICE)
            : getLabel(getCommonKey(LangConstant.TXT_SHORT_EDIT))
        }
        classes={{ tooltip: isNotClickEdit ? classes.tooltipNotEdit : classes.tooltip }}
      >
        <IconButton onClick={() => onClickEdit(uid)} className={classes.buttonIconEdit}>
          <EditOutlined className={classes.iconEdit} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={getLabel(getCommonKey(isRemoveMode ? LangConstant.TXT_REMOVED : LangConstant.TXT_DELETE))}
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton onClick={() => onClickDelete(uid)} className={classes.buttonIconDelete}>
          <DeleteOutlined className={classes.iconDelete} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

CategoryItemListBody.propTypes = {
  onClickWatch: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,

  customClasses: PropTypes.object,

  uid: PropTypes.string.isRequired,
  image: PropTypes.string,
  categoryName: PropTypes.string,
  timeUsed: PropTypes.string,
  price: PropTypes.string,

  stock: PropTypes.number,
  typeStockProduct: PropTypes.number,

  isAdd: PropTypes.bool,
  isNotClickEdit: PropTypes.bool,
  isRemoveMode: PropTypes.bool,
};

CategoryItemListBody.defaultProps = {
  image: "",
  isNotClickEdit: false,
  isRemoveMode: false,

  onClickWatch: () => {},
  onClickAdd: () => {},
  onClickEdit: () => {},
  onClickDelete: () => {},
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: 49,
    minWidth: 1000,
    display: "flex",
    borderBottom: "solid 1px #ebeced",
    borderLeft: "solid 1px #ebeced",
    borderRight: "solid 1px #ebeced",
    padding: "12px 15px 12px",
  },
  boxImage: {
    widht: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 24,
  },
  boxBodyCategoryName: {
    width: "35%",
    display: "flex",
  },
  boxName: {
    cursor: "pointer",
  },
  boxBody: {
    width: "25%",
    display: "flex",
  },
  boxBodyProduct: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
  },
  prefixIconType1: {
    width: 15,
    height: 15,
    color: "#65b39d",
  },
  prefixIconType2: {
    width: 15,
    height: 15,
    color: "#d4d5d8",
  },
  boxOptionAdd: {
    width: "15%",
    display: "flex",
    justifyContent: "flex-end",
  },
  boxOptionEditAndDelete: {
    margin: "2px 0",
    width: "15%",
    display: "flex",
    justifyContent: "flex-end",
  },
  categoryNameTypo: {
    margin: "1px 16px",
    lineHeight: "24px",
    fontSize: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  bodyTypo: {
    margin: "1px 0",
    lineHeight: "24px",
    fontSize: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  boxIconTick: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
  },
  buttonAdd: {
    height: 24,
    padding: 0,
    minWidth: 47,
    fontSize: 11,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
    "&.Mui-disabled": {
      opacity: 0.6,
      color: theme.palette.white,
    },
  },
  buttonIconEdit: {
    padding: 0,
    width: 24,
    height: 24,
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #feba40",
      borderRadius: 0,
    },
  },
  buttonIconDelete: {
    padding: 0,
    width: 24,
    height: 24,
    marginLeft: 16,
    marginRight: 6,
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #ef5845",
      borderRadius: 0,
    },
  },
  iconEdit: {
    width: 19,
    height: 19,
    color: "#6c7078",
    "&:hover": {
      color: "#feba40",
    },
  },
  iconDelete: {
    width: 19,
    height: 19,
    color: "#6c7078",
    "&:hover": {
      color: "#ef5845",
    },
  },
  tooltip: {
    marginLeft: 50,
    marginTop: 2,
    backgroundColor: "#7f838c",
    fontWeight: 500,
    fontSize: 12,
    height: 20,
    padding: "0 5px",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
  tooltipNotEdit: {
    marginLeft: 50,
    marginTop: 2,
    backgroundColor: "#447AFF",
    fontWeight: 500,
    fontSize: 12,
    height: 20,
    padding: "0 5px",
    lineHeight: 1.67,
    textAlign: "center",
    borderRadius: 0,
  },
}));

export default memo(CategoryItemListBody);
