import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Chip, makeStyles, Select, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import FilterCategoryMenu from "./FilterCategoryMenu";
import CategoryMenuItem from "./CategoryMenuItem";
import { Close } from "@material-ui/icons";
import { sortDataByArray, textNormalize } from "utils";

const CategoryMenu = props => {
  const {
    label,
    modeViewAr,
    customClasses,
    data,
    enableChangeViewMode,
    itemsKey,
    onChange,
    selectedArr,
    isDisable,
  } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState(enableChangeViewMode ? [] : selectedArr);
  const [selectedCategory, setSelectedCategory] = useState(enableChangeViewMode ? selectedArr : []);
  const [showData, setShowData] = useState(data);
  const [searchValue, setSearchValue] = useState("");
  const [modeView, setModeView] = useState(modeViewAr[0]?.id);
  const [scroll, setScroll] = useState(false);

  const isExistItems = showData.some(data => data[itemsKey]);

  const onCheckBox = selectedId => {
    const newSelectedValue = [...selectedValue];
    if (!newSelectedValue.includes(selectedId)) {
      newSelectedValue.push(selectedId);
    } else {
      const deleteIndex = newSelectedValue.indexOf(selectedId);
      if (deleteIndex > -1) {
        newSelectedValue.splice(deleteIndex, 1);
      }
    }
    setSelectedValue(newSelectedValue);

    //Check category
    if (isExistItems) {
      const targetCategory = showData.find(category => category[itemsKey].find(item => item.uid === selectedId));
      const allChecked =
        targetCategory &&
        targetCategory[itemsKey] &&
        targetCategory[itemsKey].every(item => newSelectedValue.includes(item.uid));
      const newSelectedCategory = [...selectedCategory];
      if (allChecked) {
        newSelectedCategory.push(targetCategory.uid);
      } else {
        const deleteCategoryIndex = newSelectedCategory.indexOf(targetCategory.uid);
        if (deleteCategoryIndex > -1) {
          newSelectedCategory.splice(deleteCategoryIndex, 1);
        }
      }
      setSelectedCategory(newSelectedCategory);
    }

    if (enableChangeViewMode) {
      onChange({ [modeView]: newSelectedValue });
    } else {
      onChange(newSelectedValue);
    }
  };

  const onCategoryCheckBox = selectedCategoryId => {
    //Category
    const newSelectedCategory = [...selectedCategory];

    //Item
    const newSelectedValue = [...selectedValue];
    const targetCategory = showData.find(data => data.uid === selectedCategoryId);

    if (!newSelectedCategory.includes(selectedCategoryId)) {
      newSelectedCategory.push(selectedCategoryId);
      targetCategory &&
        targetCategory[itemsKey].map(item => {
          //Check item
          if (!newSelectedValue.includes(item.uid)) {
            newSelectedValue.push(item.uid);
          }
        });
    } else {
      const deleteCategoryIndex = newSelectedCategory.indexOf(selectedCategoryId);
      if (deleteCategoryIndex > -1) {
        newSelectedCategory.splice(deleteCategoryIndex, 1);
      }
      //Check item
      targetCategory &&
        targetCategory[itemsKey].map(item => {
          const deleteIndex = newSelectedValue.indexOf(item.uid);
          if (deleteIndex > -1) {
            newSelectedValue.splice(deleteIndex, 1);
          }
        });
    }
    setSelectedValue(newSelectedValue);
    setSelectedCategory(newSelectedCategory);

    if (enableChangeViewMode) {
      onChange({ [modeView]: newSelectedCategory });
    } else {
      onChange(newSelectedValue);
    }
  };

  const onCheck = selectedId => {
    onCheckBox(selectedId);
    setScroll(!scroll);
  };

  const onCheckCategory = selectedCategoryId => {
    onCategoryCheckBox(selectedCategoryId);
    setScroll(!scroll);
  };

  const onFilter = search => {
    var searchValue = textNormalize(search);
    setSearchValue(searchValue);
    const cloneData = JSON.parse(JSON.stringify(data));
    const filteredArray = cloneData.filter(category => getFilterArray(category, searchValue, itemsKey));
    if (search === "") {
      setShowData(data);
    } else {
      setShowData(filteredArray);
    }
  };

  const onClearFilter = () => {
    setSearchValue("");
  };

  const onChangeModeView = event => {
    const newMode = event.currentTarget.id;

    if (newMode !== modeView) {
      if (
        (modeView == modeViewAr[0].id && selectedCategory.length == 0) ||
        (modeView == modeViewAr[1].id && selectedValue.length == 0)
      ) {
        setModeView(newMode);
        setSelectedCategory([]);
        setSelectedValue([]);
      }
    }
  };

  const onDeleteChip = (event, selectedId) => {
    event.stopPropagation();
    if (!isDisable) {
      onCheckBox(selectedId);
    }
  };

  const onMouseDown = event => {
    event.stopPropagation();
  };

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      onFilter(searchValue);
    }, 500);
    return () => clearTimeout(searchTimeout);
  }, [searchValue]);

  useEffect(() => {
    let chipBox = document.getElementById(chipsId);
    if (chipBox) {
      chipBox.scrollTo(0, chipBox.offsetHeight);
    }
  }, [scroll]);

  useEffect(() => {
    if (selectedArr.length > 0 && isExistItems) {
      if (enableChangeViewMode) {
        const newSelectedCategory = [...selectedCategory];
        const newSelectedValue = [...selectedValue];
        showData.map(category => {
          if (newSelectedCategory.includes(category.uid)) {
            category[itemsKey] &&
              category[itemsKey].map(item => {
                newSelectedValue.push(item.uid);
              });
          }
        });
        setSelectedValue(newSelectedValue);
      } else {
        const newSelectedValue = [...selectedValue];
        const newSelectedCategory = [...selectedCategory];
        newSelectedValue.map(selectedId => {
          const targetCategory = showData.find(category => category[itemsKey].find(item => item.uid === selectedId));
          const allChecked =
            targetCategory &&
            targetCategory[itemsKey] &&
            targetCategory[itemsKey].every(item => newSelectedValue.includes(item.uid));

          if (allChecked && !newSelectedCategory.includes(targetCategory.uid)) {
            newSelectedCategory.push(targetCategory.uid);
          }
          setSelectedCategory(newSelectedCategory);
        });
      }
    }
  }, []);

  return (
    <Select
      disabled={isDisable}
      className={clsx(classes.root, customClasses?.root)}
      displayEmpty
      labelId="label"
      multiple
      value={selectedValue}
      renderValue={value => {
        if (value.length == 0) {
          return (
            <Typography color="inherit" className="regular-md-txt">
              {label}
            </Typography>
          );
        } else {
          return (
            <Box className={isDisable ? classes.disableChips : classes.chips} id={chipsId}>
              {sortDataByArray(getCheckedData(showData, itemsKey, value), selectedValue).map(data => (
                <Chip
                  key={data.uid}
                  label={data.name}
                  className={classes.chip}
                  classes={{ deleteIcon: classes.deleteChip }}
                  deleteIcon={<Close />}
                  onDelete={isDisable ? null : event => onDeleteChip(event, data.uid)}
                  onMouseDown={onMouseDown}
                  size="small"
                  clickable
                />
              ))}
            </Box>
          );
        }
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        style: { marginTop: 4 },
      }}
      classes={{ root: clsx(classes.selectRoot, customClasses?.selectRoot), icon: classes.selectIcon }}
    >
      <FilterCategoryMenu
        modeViewAr={modeViewAr}
        enableChangeViewMode={enableChangeViewMode}
        onFilter={onFilter}
        onChangeMode={onChangeModeView}
        onClearFilter={onClearFilter}
        selectedId={modeView}
      />
      {showData.length == 0 ? (
        <Box className={classes.noResultBox}>
          <Typography variant="body2" color="inherit" classes={{ body2: classes.noResult }}>
            {getLabel(LangConstant.TXT_RESULT_NOT_FOUND)}
          </Typography>
        </Box>
      ) : (
        <CategoryMenuItem
          data={showData}
          multiLevel={isExistItems}
          defaultMode={!enableChangeViewMode}
          categoryMode={modeView === modeViewAr[0]?.id}
          onCheck={onCheck}
          onCheckCategory={onCheckCategory}
          selectedValue={selectedValue}
          selectedCategory={selectedCategory}
          itemsKey={itemsKey}
        />
      )}
    </Select>
  );
};

export const chipsId = "chips";

const getFilterArray = (value, search, itemsKey) => {
  let filterItems = [];
  if (value[itemsKey] && value[itemsKey].length > 0) {
    filterItems = value[itemsKey].filter(item => textNormalize(item.name).includes(search));
  }
  value[itemsKey] = filterItems;
  return textNormalize(value.name).includes(search) || filterItems.length > 0;
};

const getCheckedData = (listData, itemsKey, checkedValue) => {
  let result = [];
  listData.map(data => {
    if (data[itemsKey] && data[itemsKey].length > 0) {
      data[itemsKey].map(item => {
        if (checkedValue.includes(item.uid)) {
          result.push(item);
        }
      });
    } else {
      if (checkedValue.includes(data.uid)) {
        result.push(data);
      }
    }
  });
  return result;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    border: `solid 1px #d4d5d8`,
    borderRadius: "3px",
    "&.MuiInput-underline": {
      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
  },
  selectRoot: {
    height: "max-content",
    wordWrap: "anywhere",
    width: "100%",
    color: "#d4d5d8",
    padding: "10px 16px 10px",
    "&.MuiSelect-select": {
      paddingRight: 0,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  label: { marginLeft: 16, marginRight: 30 },
  chips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
  },
  disableChips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
    "& > *": { cursor: "default" },
  },
  chip: {
    height: 18,
    color: theme.palette.white,
    background: theme.palette.text.link,
    margin: 4,
  },
  deleteChip: {
    color: theme.palette.white,
  },
  noResultBox: {
    color: "#7f838c",
    margin: "10px 16px 0",
  },
}));

CategoryMenu.propTypes = {
  customClasses: PropTypes.object,
  label: PropTypes.string,
  modeViewAr: PropTypes.array,
  data: PropTypes.array.isRequired,
  enableChangeViewMode: PropTypes.bool,
  itemsKey: PropTypes.string,
  onChange: PropTypes.func,
  selectedObj: PropTypes.object,
  isDisable: PropTypes.bool,
};

CategoryMenu.defaultProps = {
  customClasses: {},
  label: "",
  modeViewAr: [],
  data: [],
  enableChangeViewMode: false,
  itemsKey: "",
  onChange: () => {},
  selectedArr: [],
  isDisable: false,
};

export default memo(CategoryMenu);
export { FilterCategoryMenu, CategoryMenuItem };
