import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getReportStaff: ["data"],
  getReportStaffSuccess: ["data"],
  getReportStaffFailure: ["data"],
});

export const ReportStaffTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  data: null,
};

/* ------------- Reducers ------------- */

/* ------------- Get ------------- */
export const getReportStaff = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getReportStaffSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    data: action.data,
  };
};

export const getReportStaffFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_REPORT_STAFF]: getReportStaff,
  [Types.GET_REPORT_STAFF_SUCCESS]: getReportStaffSuccess,
  [Types.GET_REPORT_STAFF_FAILURE]: getReportStaffFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
