import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ArrowForward, FiberManualRecord, Person } from "@material-ui/icons";
import clsx from "clsx";
import { LangConstant } from "const";
import { isArrayNotEqual } from "utils";
import { getTimestamp, getWaitingTime, removeSecond } from "utils/date";

const UsingPlacesBlock = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLACE_MANAGE);

  const [busyPlaceArray, timeRangeFiltering] = useSelector(
    ({ placeCategoryRedux }) => [placeCategoryRedux.placeDialog?.active, placeCategoryRedux.timeRangeFiltering],
    shallowEqual,
  );

  const [list, setList] = useState([]);

  useEffect(() => {
    if (isArrayNotEqual(busyPlaceArray, list)) {
      setList(busyPlaceArray);
    }
  }, [busyPlaceArray]);

  let currentTime = getTimestamp(removeSecond(new Date()));
  let currentStartTimeRangeFilter = timeRangeFiltering.start < currentTime ? currentTime : timeRangeFiltering.start;

  return (
    <Box className={classes.rightBox}>
      <Box className={classes.rightBoxHeader}>
        <Box className={classes.headerName}>
          <FiberManualRecord className={classes.inUseDot} />
          <Typography className={"medium-md-txt"}>
            {StringFormat(getLabel(LangConstant.FM_IS_USING), list.length)}
          </Typography>
        </Box>
        <Typography className={classes.headerNote}>
          (<ArrowForward className={classes.usingArrow} /> {getLabel(LangConstant.TXT_IS_BUSY)})
        </Typography>
      </Box>
      <Box className={classes.placeDisplay}>
        <Box className={classes.placeGridDisplay}>
          {list.map((place, index) => (
            <Box key={index} className={classes.placeBox}>
              <Box className={classes.placeCapacity}>
                <Person className={classes.personIconUsing} />
                <Typography className={clsx(classes.placeCapacityContentUsing, "semiBold-md-txt")}>
                  {place.capacity}
                </Typography>
              </Box>
              <Box className={classes.placeNameAndStatus}>
                <Typography className={clsx(classes.placeName, "ellipsis")}>{place.name}</Typography>
                <Typography className={classes.placeUsingTime}>
                  <ArrowForward className={classes.usingArrow} />
                  {currentStartTimeRangeFilter && getWaitingTime(place.startTime, currentStartTimeRangeFilter)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default memo(UsingPlacesBlock);

const useStyles = makeStyles(theme => ({
  rightBox: {
    backgroundColor: "#fafafb",
    padding: "16px 8px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 460,
    },
  },
  placeGridDisplay: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(181px, 189px))",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill,minmax(164px, 1fr))",
    },
  },
  rightBoxHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 14,
  },
  headerName: {
    display: "flex",
    alignItems: "center",
    "&>p": {
      height: "100%",
    },
  },
  headerNote: {
    fontSize: 13,
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    marginRight: 9,
    "&>svg": {
      marginRight: 0,
    },
  },
  inUseDot: {
    width: 18,
    height: 18,
    color: "#65b39d",
    marginLeft: 2,
    marginRight: 14,
  },
  usingArrow: {
    color: "#46cf70",
    width: 20,
    height: 20,
    marginRight: 10,
  },
  placeCapacityContentUsing: {
    color: "#65b39d",
  },
  placeNameAndStatus: {
    display: "flex",
    width: "calc(100% - 52px)",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  placeUsingTime: {
    fontSize: 13,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    color: "#46cf70",
  },
  personIconUsing: {
    width: 20,
    height: 20,
    color: "#65b39d",
  },
  placeBox: {
    maxWidth: 181,
    height: 60,
    border: `solid 1px #65b39d`,
    display: "flex",
    margin: "10px 4px",
    borderRadius: 4,
    backgroundColor: "rgba(101, 179, 157, 0.1)",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 2px",
    },
  },
  placeCapacity: {
    width: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  placeName: {
    color: "#7f838c",
    fontSize: 15,
    fontWeight: "bold",
  },
  placeDisplay: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 410,
      overflow: "auto",
    },
  },
}));
