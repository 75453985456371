import React from "react";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { CellBody } from "components";
import { AppConstant, LangConstant } from "const";
import { convertTimeStampToDate } from "utils/date";
import { formatCurrency, getCommonKey } from "utils";

const DialogStatusTable = ({ data }) => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);
  const headerArr = [
    LangConstant.TXT_BOOKING_DATE_ORDER,
    LangConstant.TXT_NAME_CONSUMER_ORDER,
    LangConstant.TXT_ADDRESS_ORDER,
    LangConstant.TXT_TOTAL_PRICE_ORDER,
  ];

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {headerArr.map((cell, index) => (
              <CellBody
                cellData={
                  <Box className={classes.firstTableCellHead}>
                    <Typography className={clsx(classes.tableHeadLabel, "semiBold-sm-txt")}>
                      {getLabel(cell)}
                    </Typography>
                  </Box>
                }
                className={clsx(classes.cellHeadRoot, "semiBold-sm-txt")}
                key={index}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.rootTableRow}>
            <CellBody
              className={classes.cellBodyRoot}
              cellData={convertTimeStampToDate(data.created || 0, AppConstant.FM_HH_MM_DD_MM_YYYY)}
            />
            <CellBody className={classes.cellBodyRoot} cellData={data.name || ""} />
            <CellBody className={classes.cellBodyRoot} cellData={data.address || ""} />
            <CellBody
              className={classes.cellBodyRoot}
              cellData={
                `${formatCurrency(data.total)} ${getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON))}` || ""
              }
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DialogStatusTable;

DialogStatusTable.propTypes = {};

const useStyles = makeStyles(theme => ({
  rootTableRow: {
    height: 40,
  },
  tableContainer: {
    "&>table": {
      overflowX: "auto",
      minWidth: 800,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cellBodyRoot: {
    color: theme.palette.grey[500],
    fontSize: 13,
    fontWeight: "normal",
    textAlign: "center",
    "&>div": {
      justifyContent: "center",
    },
  },
  firstTableCellHead: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  tableHeadLabel: {
    width: "100%",
    textAlign: "center",
  },
}));
