import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, useTheme } from "@material-ui/core";
import TotalComponent from "./TotalComponent";
import clsx from "clsx";

const DayInMonth = props => {
  const { detail, day, isDayInMonth, isSelectedDate, widthColumn } = props;
  const classes = useStyles({ widthColumn: widthColumn });
  const theme = useTheme();

  let detailTmp =
    detail && isDayInMonth
      ? {
          total: { ...detail },
        }
      : {
          total: {
            consumer: { total: 0, child: 0, man: 0 },
            reservationCount: 0,
          },
        };

  return (
    <Box className={classes.day}>
      <Box
        className={clsx(classes.valueDayInMonth, "medium-md-txt")}
        color={isSelectedDate ? theme.palette.white : isDayInMonth ? "#787878" : theme.palette.grey[300]}
        bgcolor={isSelectedDate ? theme.palette.reservation.active : theme.palette.grey[200]}
      >
        {day.getDate()}
      </Box>
      {isDayInMonth && (
        <Box display="flex" justifyContent="center" pt="32px">
          <TotalComponent total={Object.assign({}, detailTmp.total)} />
        </Box>
      )}
    </Box>
  );
};

DayInMonth.propTypes = {
  detail: PropTypes.object.isRequired,
  day: PropTypes.object.isRequired,
  isDayInMonth: PropTypes.bool.isRequired,
  isSelectedDate: PropTypes.bool,
};

DayInMonth.defaultProps = {
  detail: {
    total: {
      consumer: { total: 0, child: 0, man: 0 },
      reservationCount: 0,
    },
  },
  day: new Date(),
  isDayInMonth: true,
  isSelectedDate: false,
};

const HEIGHT_DAY = "111px";
const HEIGHT_DAY_SCREEN_XL = "150px";

const useStyles = makeStyles(theme => ({
  day: {
    height: HEIGHT_DAY,
    borderColor: theme.palette.grey[200],
    flexGrow: 1,
    py: 15,
    border: "1px solid",
    width: props => props.widthColumn,
    [theme.breakpoints.up("lg")]: {
      height: HEIGHT_DAY_SCREEN_XL,
      paddingTop: 10,
    },
  },
  valueDayInMonth: {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default memo(DayInMonth);
