import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getWaitingConsumer: [],
  postWaitingConsumer: ["data"],
  putWaitingConsumer: ["data"],
  deleteWaitingConsumer: ["data"],

  waitingConsumerSuccess: ["data"],
  waitingConsumerFailure: ["error"],
  resetWaitingConsumer: [],
});

export const WaitingConsumerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  data: null,
  isFetching: false,
  isSuccess: false,
  isPostSuccess: false,
  error: null,
};

/* ------------- Reducers Waiting Consumer ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  isPostSuccess: false,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_WAITING_CONSUMER]: request,
  [Types.POST_WAITING_CONSUMER]: request,
  [Types.PUT_WAITING_CONSUMER]: request,
  [Types.DELETE_WAITING_CONSUMER]: request,

  [Types.WAITING_CONSUMER_SUCCESS]: success,
  [Types.WAITING_CONSUMER_FAILURE]: failure,
  [Types.RESET_WAITING_CONSUMER]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
