import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Button, Typography, useTheme } from "@material-ui/core";

export default function DialogNotice({ isShow, children, message, onSubmit }) {
  const theme = useTheme();
  return (
    <Dialog
      classes={{ paper: "dialog-common dialog-notice-root" }}
      aria-labelledby="dialog-notice-title"
      open={isShow}
      maxWidth="sm"
    >
      <DialogContent classes={{ root: "dialog__main" }} dividers style={{ zIndex: theme.zIndex.appBar + 100 }}>
        <Typography variant="h3" component="p" className="highlight">
          Thông báo
        </Typography>
        <Typography variant="subtitle1" component="p" color="textSecondary">
          {message}
        </Typography>
        {children}
      </DialogContent>
      <DialogActions classes={{ root: "dialog__footer" }}>
        <Button autoFocus variant="contained" onClick={onSubmit} color="primary">
          XÁC NHẬN
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogNotice.propTypes = {
  isShow: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,

  onSubmit: PropTypes.func.isRequired,
};
DialogNotice.defaultProps = {};
