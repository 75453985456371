import { createReducer, createActions } from "reduxsauce";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getNews: ["data"],
  getNewsSuccess: ["data"],
  getNewsFailure: ["data"],

  createNews: ["data"],
  createNewsSuccess: ["data"],
  createNewsFailure: ["data"],

  updateNews: ["data"],
  updateNewsSuccess: ["data"],
  updateNewsFailure: ["data"],

  removeNews: ["data"],
  removeNewsSuccess: ["data"],
  removeNewsFailure: ["data"],

  requestUploadPostImage: ["data"],
  uploadPostImageSuccess: ["data"],
  uploadPostImageFailure: ["data"],

  mnNewsReset: ["data"],
});

export const MnNewsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  news: [],
  isFetching: false,
  error: null,
  isSuccess: false,
  //create
  isCreateFetching: false,
  errorCreate: null,
  isCreateSuccess: false,
  //update
  updateData: {},
  isUpdateFetching: false,
  errorUpdate: null,
  isUpdateSuccess: false,
  //remove
  isRemoveFetching: false,
  errorRemove: null,
  isRemoveSuccess: false,
  //upload attachment
  postImgUrl: [],
  ImageURL: [],
  isUpLoadFetching: false,
  isUploadSuccess: false,
  errorUploadImage: null,
};

/* ------------- Reducers ------------- */

export const getNews = (state = INITIAL_STATE) => ({
  ...state,
  news: [],
  isFetching: true,
  isSuccess: false,
  error: null,
});

export const getNewsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    error: null,
    news: action.data,
  };
};

export const getNewsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

export const createNews = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
  isCreateSuccess: false,
  errorCreate: null,
});

export const createNewsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isCreateSuccess: true,
  isCreateFetching: false,
  errorCreate: null,
});

export const createNewsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isCreateSuccess: false,
  errorCreate: action.error,
});

export const updateNews = (state = INITIAL_STATE) => ({
  ...state,
  updateData: {},
  isUpdateFetching: true,
  isUpdateSuccess: false,
  errorUpdate: null,
});

export const updateNewsSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  updateData: action.data,
  isUpdateSuccess: true,
  isUpdateFetching: false,
  errorUpdate: null,
});

export const updateNewsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  updateData: {},
  isUpdateFetching: false,
  isUpdateSuccess: false,
  errorUpdate: action.error,
});

export const removeNews = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetching: true,
  errorRemove: null,
  isRemoveSuccess: false,
});

export const removeNewsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetching: false,
  isRemoveSuccess: true,
  errorRemove: null,
});

export const removeNewsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetching: false,
  isRemoveSuccess: false,
  errorRemove: action.error,
});

export const requestUploadPostImage = (state = INITIAL_STATE) => {
  return {
    ...state,
    isUploadFetching: true,
  };
};

export const uploadPostImageSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpLoadFetching: false,
    isUploadSuccess: true,
    postImgUrl: action.data.uri,
  };
};

export const uploadPostImageFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUploadFetching: false,
  isUploadSuccess: false,
  errorUploadImage: action.error,
});

export const mnNewsReset = () => INITIAL_STATE;
/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_NEWS]: getNews,
  [Types.GET_NEWS_SUCCESS]: getNewsSuccess,
  [Types.GET_NEWS_FAILURE]: getNewsFailure,

  [Types.CREATE_NEWS]: createNews,
  [Types.CREATE_NEWS_SUCCESS]: createNewsSuccess,
  [Types.CREATE_NEWS_FAILURE]: createNewsFailure,

  [Types.UPDATE_NEWS]: updateNews,
  [Types.UPDATE_NEWS_SUCCESS]: updateNewsSuccess,
  [Types.UPDATE_NEWS_FAILURE]: updateNewsFailure,

  [Types.REMOVE_NEWS]: removeNews,
  [Types.REMOVE_NEWS_SUCCESS]: removeNewsSuccess,
  [Types.REMOVE_NEWS_FAILURE]: removeNewsFailure,

  [Types.REQUEST_UPLOAD_POST_IMAGE]: requestUploadPostImage,
  [Types.UPLOAD_POST_IMAGE_SUCCESS]: uploadPostImageSuccess,
  [Types.UPLOAD_POST_IMAGE_FAILURE]: uploadPostImageFailure,

  [Types.MN_NEWS_RESET]: mnNewsReset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
