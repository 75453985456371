import React, { memo, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  InputAdornment,
  OutlinedInput,
  IconButton,
  MenuItem,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import DropDown from "./DropDown";
import { ArrowDropDownOutlined } from "@material-ui/icons";

const InputIconDơwn = forwardRef((props, ref) => {
  const { icon, onChange, className, readOnly, data, iconSelect, title, ...otherProps } = props;
  const classes = useStyles();
  const onTyping = event => {
    if (readOnly) {
      return;
    }
    if (Boolean(onChange)) {
      onChange(event.target.value, event);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClick = event => {
    if (data.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  function generate(arr) {
    return arr.map((value, index) => (
      <MenuItem key={index} value={index} onClick={handlerChange}>
        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.inputSelect}>
          {typeof iconSelect != undefined && <IconButton> {iconSelect}</IconButton>}
          <Typography>{value}</Typography>
        </Grid>
      </MenuItem>
    ));
  }
  const [inputState, setInputState] = useState("");
  const handlerChange = event => {
    setInputState(event.currentTarget.value);
    onClose();
  };

  return (
    <Box>
      <OutlinedInput
        fullWidth
        type="text"
        onChange={onTyping}
        onClick={onClick}
        classes={{
          input: classes.input,
          root: `${classes.root} ${className}`,
        }}
        value={inputState}
        startAdornment={
          <InputAdornment position="start" className={classes.icon}>
            {icon}
          </InputAdornment>
        }
        endAdornment={
          <IconButton edge="end" className={classes.secondaryIcon}>
            <ArrowDropDownOutlined color="inherit" />
          </IconButton>
        }
        ref={ref}
        {...otherProps}
      ></OutlinedInput>
      <DropDown title={title} anchorEl={anchorEl} onClose={onClose}>
        {generate(data)}
      </DropDown>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: "8px",
    marginBottom: "8px",
  },
  input: { padding: "16px 8px", borderColor: "red" },
  inputSelect: {
    textAlign: "center !important",
  },
  icon: {
    minWidth: "50px",
    width: "50px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "50px",
    background: theme.palette.grey[200],
    justifyContent: "center",
    borderRight: "solid 0.5px #cecfd2",
    marginRight: 0,
  },
  secondaryIcon: {
    color: theme.palette.grey[400],
    padding: 4,
  },
}));

InputIconDơwn.propTypes = {
  icon: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  data: PropTypes.array,
  title: PropTypes.string,
};
InputIconDơwn.defaultProps = { placeholder: "", className: "", data: [], title: "" };

export default memo(InputIconDơwn);
