import React, { memo, useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { IconButton, makeStyles } from "@material-ui/core";
import Cookie from "js-cookie";
import ReservationNewAction from "redux/reservation-new.redux";
import { NotifyBadge } from "components";
import NewOrderNotifyContent from "./NewOrderNotifyContent";
import { OrderNotificationIcon } from "icons";
import { AppConstant } from "const";
import { HEADER_CONDITION_OBJ } from "layouts/MainLayout/components/CustomAppBar";

const NewOrderNotify = ({ onClick, isShowPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const role = Cookie.get(AppConstant.ROLE);
  let isShopOwnerOrManager = role === AppConstant.SHOP_OWNER || role === AppConstant.SHOP_MANAGER;

  const [orderNotification, isFetching] = useSelector(
    ({ reservationNewRedux }) => [reservationNewRedux.orderNotification, reservationNewRedux.isFetching],
    shallowEqual,
  );

  const isPostSuccessReservationListStatus = useSelector(
    state => state.reservationRedux.isPostSuccessReservationListStatus,
  );

  const { page, total, data: newReservationData } = orderNotification;

  const [anchorEl, setAnchorEl] = useState(null);
  const [newOrderIntervalId, setNewOrderIntervalId] = useState();

  const onControlDisplay = event => {
    event.stopPropagation();
    setAnchorEl(Boolean(anchorEl) ? null : event.currentTarget);
    onClick({ ...HEADER_CONDITION_OBJ, isOrder: !Boolean(anchorEl) });
  };

  useEffect(() => {
    dispatch(ReservationNewAction.getReservationNewOrder());
  }, []);

  useEffect(() => {
    if (isPostSuccessReservationListStatus && !isFetching && Object.keys(orderNotification.data).length > 0) {
      dispatch(
        ReservationNewAction.getReservationNewOrder({
          page: page,
        }),
      );
    }
  }, [isPostSuccessReservationListStatus]);

  useEffect(() => {
    let newOrderInterval = 0;
    if (newOrderIntervalId) {
      clearInterval(newOrderIntervalId);
      setNewOrderIntervalId(null);
    } else if (isShopOwnerOrManager) {
      var intervalId = setInterval(() => {
        dispatch(
          ReservationNewAction.getReservationNewOrder({
            page: 1,
          }),
        );
      }, AppConstant.NOTIFICATION_REQUEST_INTERVAL_TIME);
      setNewOrderIntervalId(intervalId);
      newOrderInterval = intervalId;
    }
    return () => {
      clearInterval(newOrderInterval);
    };
  }, [anchorEl]);

  useEffect(() => {
    if (!isShowPopup && Boolean(anchorEl)) {
      setAnchorEl(null);
    }
  }, [isShowPopup]);

  let isShow = isShowPopup && Boolean(anchorEl);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={onControlDisplay}
        className={anchorEl ? classes.iconBoxFocused : classes.iconBox}
        id={NEW_NOTIFY_BOOKING_ICON}
      >
        {newReservationData && total > 0 ? (
          <NotifyBadge data={total} badgeClass={classes.notifyBadge}>
            <OrderNotificationIcon className={anchorEl && classes.iconFocused} />
          </NotifyBadge>
        ) : (
          <OrderNotificationIcon className={anchorEl && classes.iconFocused} />
        )}
      </IconButton>
      {isShow && (
        <NewOrderNotifyContent
          open={isShow}
          anchorEl={anchorEl}
          onClose={onControlDisplay}
          classes={{
            popover: classes.newOrderNotifyPaper,
          }}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  iconBox: {
    marginRight: 15,
    padding: 5,
  },
  iconBoxFocused: {
    marginRight: 15,
    borderRadius: "50%",
    backgroundColor: theme.palette.white,
    padding: 5,
  },

  iconFocused: {
    color: "#ef4858",
  },
  notifyBadge: {
    backgroundColor: "#feba40",
  },

  newOrderNotifyPaper: {
    marginTop: 13,
    "&::before": {
      right: 194,
    },
    [theme.breakpoints.down("md")]: {
      "&::before": {
        right: 138,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&::before": {
        right: "43.5%",
      },
    },
  },
}));

NewOrderNotify.propTypes = {
  onClick: PropTypes.func,
  isShowPopup: PropTypes.bool,
};
NewOrderNotify.defaultProps = {
  onClick: () => {},
  isShowPopup: false,
};

const NEW_NOTIFY_BOOKING_ICON = "new-notify-booking-icon";

export default memo(NewOrderNotify);
