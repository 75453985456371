import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, OutlinedInput, Typography } from "@material-ui/core";
import clsx from "clsx";

const LabelInput = ({ label, value, onChange, isRequired, classes, ...otherInputProps }) => {
  const defaultClasses = useStyles();

  return (
    <Box className={clsx(defaultClasses.root, classes.root)}>
      <Typography className={clsx(defaultClasses.label, classes.label)}>
        {label} {isRequired && <span className={defaultClasses.requiredIcon}>&lowast;</span>}
      </Typography>
      <OutlinedInput
        classes={{
          root: clsx(defaultClasses.inputRoot, "regular-md-txt", classes.inputRoot),
          input: clsx(defaultClasses.infoInput, classes.input),
        }}
        value={value}
        onChange={onChange}
        {...otherInputProps}
      />
    </Box>
  );
};

LabelInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,

  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    inputRoot: PropTypes.string,
    input: PropTypes.string,
  }),

  onChange: PropTypes.func,
};

LabelInput.defaultProps = {
  onChange: () => {},

  classes: {
    root: "",
    label: "",
    inputRoot: "",
    input: "",
  },

  label: "",
  value: "",
  isRequired: false,
};

export default LabelInput;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  label: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "22px",
    color: "#565C6A",
    marginRight: 16,
  },

  inputRoot: {
    minHeight: "unset",
    padding: "4px 16px",
  },

  infoInput: {
    height: "100%",
    padding: "2px 0",
    lineHeight: "22px",
    "&::placeholder": {
      fontSize: 14,
    },
  },

  requiredIcon: {
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
}));
