import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey } from "utils";
import clsx from "clsx";

const CategoryItemListHeaderMenu = ({ isProduct, isCombo, onClickAddAll, disabled }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const [nameHeader, setNameHeader] = useState();
  const [nameHeaderTypo, setNameHeaderType] = useState();
  useEffect(() => {
    if (isCombo) {
      setNameHeader(getLabel(LangConstant.TXT_NAME_COMBO));
      setNameHeaderType(getLabel(getCommonKey(LangConstant.TXT_BOOK_USED_TIME)));
    } else if (isProduct) {
      setNameHeader(getLabel(getCommonKey(LangConstant.TXT_PRODUCT_NAME)));
      setNameHeaderType(getLabel(getCommonKey(LangConstant.TXT_STOCK)));
    } else if (!isCombo && !isProduct) {
      setNameHeader(getLabel(getCommonKey(LangConstant.TXT_SERVICE_NAME)));
      setNameHeaderType(getLabel(getCommonKey(LangConstant.TXT_BOOK_USED_TIME)));
    }
  }, [isCombo, isProduct]);

  return (
    <Box className={classes.root}>
      <Typography className={clsx("semiBold-md-txt", classes.headerServiceNameTypo)}>{nameHeader}</Typography>
      <Typography className={clsx("semiBold-md-txt", isProduct ? classes.headerTypoProduct : classes.headerTypo)}>
        {nameHeaderTypo}
      </Typography>
      <Typography className={clsx("semiBold-md-txt", classes.headerTypo)}>
        {getLabel(getCommonKey(LangConstant.TXT_PRICE))}
      </Typography>
      <Box className={classes.boxOptionAdd}>
        <Button onClick={onClickAddAll} className={classes.buttonAdd} disabled={disabled}>
          {getLabel(getCommonKey(LangConstant.TXT_ADD_ALL))}
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: 48,
    minWidth: 1000,
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    padding: "13px 16px",
    display: "flex",
  },
  headerServiceNameTypo: {
    lineHeight: "22px",
    width: "35%",
    textAlign: "left",
  },
  headerTypo: {
    lineHeight: "22px",
    width: "27%",
    textAlign: "left",
  },
  headerTypoProduct: {
    lineHeight: "22px",
    width: "28%",
    textAlign: "center",
  },
  boxOptionAdd: {
    width: "15%",
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonAdd: {
    height: 24,
    minWidth: 47,
    fontSize: 11,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: theme.palette.primary["main"],
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.grey["700"],
    },
    "&.Mui-disabled": {
      opacity: 0.6,
      color: theme.palette.white,
    },
  },
}));

export default memo(CategoryItemListHeaderMenu);
