import React, { memo, useEffect, useState } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Box, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { preZoomChart } from "../BookingByTime/BookingByTimeChart";

const BookingByPlaceChart = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [summaryData, viewBy, arraySetting] = useSelector(({ reportDashboard }) => [
    reportDashboard.summaryPlace.data,
    reportDashboard.summaryPlace.viewBy,
    reportDashboard.summaryPlace.setting.details,
  ]);

  const [chartData, setChartData] = useState();
  const [columnData, setColumnData] = useState([]);

  useEffect(() => {
    if (!summaryData) return;
    setChartData(summaryData);
  }, [summaryData]);

  useEffect(() => {
    if (!arraySetting) return;

    setColumnData(arraySetting);
  }, [arraySetting]);

  useEffect(() => {
    if (!chartData || !Array.isArray(chartData)) return;
    // Create chart instance
    let chart = window.am4core.create(chartHtmlId, am4charts.XYChart);
    chart.scrollbarX = new window.am4core.Scrollbar();
    chart.scrollbarX.marginBottom = 24;
    chart.scrollbarX.marginTop = -8;

    // Add data
    if (AppConstant.CHART_VIEW_BY.week === viewBy) {
      let arrDayOfWeekLang = LangConstant.ARR_DAY_OF_WEEK_SHORT.map(itemLang => getLabel(itemLang));

      chart.data = chartData.map(item => ({
        ...item,
        date: arrDayOfWeekLang[parseInt(item.date)],
      }));
    } else {
      chart.data = chartData;
    }

    // Create axes
    createCategoryAxis(chart, AppConstant.CHART_VIEW_BY.hour === viewBy && chartData.length);
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    columnData.forEach(item => createLineChart(chart, valueAxis, item));

    chart.cursor = new am4charts.XYCursor();
  }, [chartData, columnData]);

  return <Box id={chartHtmlId} className={classes.chart} />;
};

BookingByPlaceChart.propTypes = {};
export default memo(BookingByPlaceChart);

const chartHtmlId = "bookingByPlaceChart";

const useStyles = makeStyles({
  chart: {
    width: "100%",
    height: 450,
  },
});

export const createCategoryAxis = (chart, length) => {
  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "date";
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.renderer.grid.template.location = 0;
  preZoomChart(categoryAxis, length);

  return categoryAxis;
};

export const createLineChart = (chart, valueAxis, data) => {
  let series = chart.series.push(new am4charts.LineSeries());
  series.name = data.name;
  series.dataFields.valueY = data.display_id;
  series.dataFields.categoryX = "date";
  series.yAxis = valueAxis;
  series.stroke = window.am4core.color(data.color);
  series.tooltip.background.fill = series.stroke;
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = series.stroke;
  series.tooltipText = "[bold][#fff]{valueY}[/]";

  return series;
};
