import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, Box, IconButton } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import Cookie from "js-cookie";
import { Notification, NewBookingNotify, NotifyBadge } from "components";
import { AppConstant } from "const";
import WaitingListIcon from "./WaitingListIcon";
import ReservationAction from "redux/reservation-setting.redux";
import NewOrderNotify from "./NewOrderNotify";
import { HEADER_CONDITION_OBJ } from "../CustomAppBar/index";
import { getFeatureConditions } from "utils";

const GroupButtonWithBadge = ({ headerButtonState, setHeaderButtonState }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = Cookie.get(AppConstant.ROLE);
  var isShopOwnerOrManager = role === AppConstant.SHOP_OWNER || role === AppConstant.SHOP_MANAGER;

  const waitingConsumerArray = useSelector(state => state.waitingConsumerRedux.data, shallowEqual);

  const [dataReservation, newReservations, totalUnRead, isFetchingNotification] = useSelector(
    ({ reservationSettingRedux }) => [
      reservationSettingRedux.dataNotification,
      reservationSettingRedux.news,
      reservationSettingRedux.unreadTotal,
      reservationSettingRedux.isFetchingNotification,
    ],
    shallowEqual,
  );
  const totalNewReservation = useSelector(({ reservationNewRedux }) => reservationNewRedux.total, shallowEqual);
  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { reservationSettingIsValid, isPremium, isOnlyBooking, isOnlyOrdering } =
    getFeatureConditions(reservationSetting);

  const isDisplayBadge =
    (waitingConsumerArray && waitingConsumerArray.length > 0) || totalUnRead > 0 || totalNewReservation > 0;
  let rootBoxClasses = isDisplayBadge ? classes.groupButtonWithBadge : "";

  const [anchorElNotify, setAnchorElNotify] = useState(null);
  const [rung, setRung] = useState(false);
  const [ring, setRing] = useState(false);
  const [retry, setRetry] = useState({ countNotify: 1, countUser: 1 });

  const [changed, setChange] = useState(0);

  const [notificationIntervalId, setNotificationIntervalId] = useState();

  const [openNotification, setOpenNotification] = useState(Boolean(anchorElNotify));
  const idNotification = openNotification ? "notification-popover" : undefined;

  const onClickNotification = event => {
    setAnchorElNotify(openNotification ? null : event.currentTarget);
    setOpenNotification(!openNotification);
    setHeaderButtonState({ ...HEADER_CONDITION_OBJ, isNotification: !Boolean(openNotification) });
  };
  const onCloseNotification = () => {
    setAnchorElNotify(null);
    setHeaderButtonState(HEADER_CONDITION_OBJ);
    setOpenNotification(false);
  };

  const onCloseDialog = data => {
    setHeaderButtonState(data);
    setAnchorElNotify(null);
    setOpenNotification(false);
  };

  /* Notification */
  useEffect(() => {
    if (newReservations > changed) setChange(newReservations);
  }, [newReservations]);

  useEffect(() => {
    let isSubscribed = true;
    if (changed !== 0 && totalUnRead > 0 && isSubscribed) {
      setRung(true);
      const interval = setInterval(() => {
        setRung(false);
        setRing(false);
      }, 5000);
      return () => clearInterval(interval);
    }
    return () => (isSubscribed = false);
  }, [changed]);

  useEffect(() => {
    let isSubscribed = true;
    if (rung) {
      const interval = setInterval(() => {
        if (isSubscribed) setRing(!ring);
      }, 100);
      return () => clearInterval(interval);
    }
    return () => (isSubscribed = false);
  }, [rung, ring]);

  useEffect(() => {
    let isSubscribed = true;
    if (retry.countNotify <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
      setTimeout(() => {
        if (isSubscribed) setRetry({ ...retry, countNotify: ++retry.countNotify });
      }, AppConstant.VALUE_RETRY_REQUEST_TIME_BASE * retry.countNotify);
    }
    return () => (isSubscribed = false);
  }, [retry]);

  useEffect(() => {
    let notificationInterval = 0;
    if (notificationIntervalId) {
      clearInterval(notificationIntervalId);
      setNotificationIntervalId(null);
    } else {
      const interval = setInterval(() => {
        if (!isFetchingNotification) {
          dispatch(ReservationAction.requestGetReservationNotification(DEFAULT_NOTIFICATION_PARAMS));
        }
      }, AppConstant.NOTIFICATION_REQUEST_INTERVAL_TIME);
      notificationInterval = interval;
      setNotificationIntervalId(interval);
    }
    return () => clearInterval(notificationInterval);
  }, [openNotification]);

  useEffect(() => {
    if (!isFetchingNotification && retry.countNotify <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
      setRetry({ ...retry, countNotify: ++retry.countNotify });
      dispatch(ReservationAction.requestGetReservationNotification(DEFAULT_NOTIFICATION_PARAMS));
    }
  }, []);

  useEffect(() => {
    if (!headerButtonState.isNotification) {
      setAnchorElNotify(null);
      setOpenNotification(false);
    }
  }, [headerButtonState.isNotification]);

  let notifyIconClass = ring
    ? anchorElNotify
      ? classes.notifyRingFocused
      : classes.notifyRing
    : anchorElNotify
    ? classes.notifyIconFocused
    : classes.notifyIcon;

  /* Notification */
  let isShowNotification = Boolean(dataReservation != null && openNotification);

  let isNewOrderNotify = isShopOwnerOrManager && (isOnlyOrdering || isPremium);
  let isNewBookingNotify = isShopOwnerOrManager && (isOnlyBooking || isPremium);

  return (
    reservationSettingIsValid && (
      <Box className={rootBoxClasses}>
        {isPremium && <WaitingListIcon onClick={onCloseNotification} />}

        {isNewOrderNotify && <NewOrderNotify onClick={onCloseDialog} isShowPopup={headerButtonState.isOrder} />}
        {isNewBookingNotify && <NewBookingNotify onClick={onCloseDialog} isShowPopup={headerButtonState.isBooking} />}

        <IconButton
          color="inherit"
          onClick={onClickNotification}
          className={anchorElNotify ? classes.notifyIconBoxFocused : classes.notifyIconBox}
          id={NOTIFY_ICON_ID}
        >
          {dataReservation && totalUnRead > 0 ? (
            <NotifyBadge data={totalUnRead} badgeClass={classes.notifyBadge}>
              <Notifications className={notifyIconClass} />
            </NotifyBadge>
          ) : (
            <Notifications className={notifyIconClass} />
          )}
        </IconButton>

        {isShowNotification && (
          <Notification
            id={idNotification}
            openP={openNotification}
            anchorEl={anchorElNotify}
            onClose={onCloseNotification}
            data={dataReservation || {}}
          />
        )}
      </Box>
    )
  );
};

const DEFAULT_NOTIFICATION_PARAMS = {
  size: 20,
  page: 1,
};

GroupButtonWithBadge.propTypes = {
  setHeaderButtonState: PropTypes.func,
  headerButtonState: PropTypes.shape({
    isWarning: PropTypes.bool,
    isBooking: PropTypes.bool,
    isOrder: PropTypes.bool,
    isAccountInfo: PropTypes.bool,
    isNotification: PropTypes.bool,
  }),
};

GroupButtonWithBadge.defaultProps = { headerButtonState: {} };

const useStyles = makeStyles(theme => ({
  groupButtonWithBadge: { paddingTop: 5 },

  notifyIconBox: {
    marginRight: 20,
    padding: 1,
  },
  notifyIconBoxFocused: {
    marginRight: 20,
    borderRadius: "50%",
    backgroundColor: theme.palette.white,
    padding: 1,
  },
  notifyIcon: {
    fontSize: 25,
  },
  notifyIconFocused: {
    fontSize: 25,
    color: "#ef4858",
  },
  notifyRing: {
    transform: "rotate(30deg)",
    fontSize: 25,
  },
  notifyRingFocused: {
    fontSize: 25,
    transform: "rotate(30deg)",
    color: "#ef4858",
  },
  notifyBadge: {
    backgroundColor: theme.palette.text.link,
  },
}));

const NOTIFY_ICON_ID = "notify-icon-id";

export default memo(GroupButtonWithBadge);
