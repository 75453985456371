import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import PaymentAction from "redux/payment.redux";

const PaginationPaymentHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataUser = useSelector(({ userRedux }) => userRedux.dataUser);
  const { page, totalPage } = useSelector(({ paymentRedux }) => paymentRedux.paymentHistory);

  const onChangePage = (_, newPage) => {
    dispatch(PaymentAction.getPaymentHistory({ userId: dataUser.id, page: newPage }));
  };

  return (
    <Box className={classes.root}>
      {totalPage > 1 && (
        <Pagination
          page={page}
          onChange={onChangePage}
          count={totalPage}
          color="primary"
          renderItem={item => (
            <PaginationItem
              {...item}
              classes={{
                root: classes.pageItem,
                selected: classes.selectedPageItem,
                icon: classes.iconPageItem,
              }}
            />
          )}
        />
      )}
    </Box>
  );
};

PaginationPaymentHistory.propTypes = {};
PaginationPaymentHistory.defaultProps = {};

export default PaginationPaymentHistory;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "end",
    padding: "18px 0",
  },
  pageItem: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1.73,
    color: "#3e4045",
    "&:hover": {
      textDecoration: "underline",
      color: "#4d85ff",
      backgroundColor: "unset",
    },
  },
  selectedPageItem: {
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.white,
    },
  },
  iconPageItem: {
    height: 30,
    width: 30,
  },
}));
