import React, { memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Paper, useMediaQuery, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Warning } from "@material-ui/icons";
import { LangConstant, AppConstant, ApiConstant } from "const";
import InputShopInfo from "./InputShopInfo";
import MNBookingUploadImage from "./MNBookingUploadImage";

const BookingShopInfo = props => {
  const inputElementRef = useRef();
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { previewData, onUpdatePreviewData, onAccept } = props;
  const isNotMobile = useMediaQuery("(min-width:560px)");
  const [notify, setNotify] = useState(DEFAULT_NOTIFY);

  const onUploadCover = event => {
    const name = event.currentTarget.name;
    if (name === "cover") {
      onClickUpload();
    } else {
      onUpdatePreviewData(name, event.currentTarget.value);
    }
  };

  const onChangeImage = (file, error) => {
    if (!error) {
      onAccept(file);
    } else {
      setNotify({
        value: true,
        message: error,
        type: 1,
      });
    }
  };

  const onCloseNotify = () => {
    setNotify({
      value: false,
      message: "",
      type: 0,
    });
  };

  const onClickUpload = () => {
    inputElementRef.current.click();
  };

  return (
    <Paper className="shopInfo">
      <Grid container direction="column" justify="center" alignItems="center" className="main">
        <Grid item container direction="row" justify="flex-start">
          <Typography className="title semiBold-md-txt">{getLabel(LangConstant.TXT_SHOP_INFO_SIDEBAR)}</Typography>
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <InputShopInfo
              nameInput={AppConstant.KEY_SHOP_NAME}
              title={getLabel(LangConstant.TXT_SHOP_NAME)}
              placeHolder={getLabel(LangConstant.TXT_SHOP_NAME)}
              value={previewData.name || ""}
              disabled={true}
              onChangeInput={onUploadCover}
            />
          </Grid>
          <Grid item>
            <Typography className={`medium-md-txt typo`}>
              {getLabel(LangConstant.TXT_BOOKING_SETTING_IMAGE_COVER)}
            </Typography>
            <MNBookingUploadImage
              onChange={onChangeImage}
              action={() => {}}
              defaultUrl={`${ApiConstant.HOST_ATTACHMENT}${previewData[AppConstant.KEY_SHOP_AVATA]}`}
            />
            {notify.value && notify.type == 1 && (
              <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
            )}
          </Grid>
          <Grid item>
            <InputShopInfo
              nameInput={AppConstant.KEY_SHOP_WELCOME_SPEECH}
              title={getLabel(LangConstant.TXT_SHOP_WELCOME_SPEECH)}
              rowsMax={isNotMobile ? 4 : 3}
              placeHolder={getLabel(LangConstant.TXT_SHOP_WELCOME_SPEECH_HOLDER)}
              value={
                previewData[AppConstant.KEY_SHOP_WELCOME_SPEECH] ? previewData[AppConstant.KEY_SHOP_WELCOME_SPEECH] : ""
              }
              heightType={isNotMobile ? "medium" : "large"}
              onChangeInput={onUploadCover}
              classes={{
                root: classes.welcomeSpeech,
              }}
            />
          </Grid>
          <Grid item>
            <InputShopInfo
              nameInput={AppConstant.KEY_SHOP_ADDRESS}
              title={getLabel(LangConstant.TXT_SHOP_ADDRESS)}
              placeHolder={getLabel(LangConstant.TXT_SHOP_ADDRESS)}
              value={previewData[AppConstant.KEY_SHOP_ADDRESS] ? previewData[AppConstant.KEY_SHOP_ADDRESS] : ""}
              onChangeInput={onUploadCover}
            />
          </Grid>
          <Grid item>
            <InputShopInfo
              nameInput={AppConstant.KEY_SHOP_PHONE}
              title={getLabel(LangConstant.TXT_SHOP_PHONE)}
              placeHolder={getLabel(LangConstant.TXT_SHOP_PHONE)}
              value={previewData[AppConstant.KEY_SHOP_PHONE] ? previewData[AppConstant.KEY_SHOP_PHONE] : ""}
              onChangeInput={onUploadCover}
              typeInput="tel"
            />
          </Grid>
          <Grid item>
            <InputShopInfo
              nameInput={AppConstant.KEY_OTHER_INFORMATION}
              title={getLabel(LangConstant.TXT_BOOKING_SETTING_OTHER_INFORMATION)}
              rowsMax={isNotMobile ? 4 : 3}
              placeHolder={getLabel(LangConstant.TXT_BOOKING_SETTING_OTHER_INFORMATION)}
              value={
                previewData[AppConstant.KEY_OTHER_INFORMATION] ? previewData[AppConstant.KEY_OTHER_INFORMATION] : ""
              }
              heightType={isNotMobile ? "medium" : "large"}
              onChangeInput={onUploadCover}
              className="welcomeSpeech"
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

BookingShopInfo.propTypes = {
  data: PropTypes.object,
  previewData: PropTypes.object,
  onAccept: PropTypes.func,
};

BookingShopInfo.defaultProps = {
  data: {},
  previewData: {},
};

const useStyles = makeStyles(() => ({
  welcomeSpeech: {
    marginTop: 24,
  },
}));

export default memo(BookingShopInfo);

const DEFAULT_NOTIFY = { value: false, message: "", type: 0 };
