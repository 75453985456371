import React, { useState, useEffect } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";

const AddingProduct = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const ObjFmPackageUsingType = getLabel(LangConstant.OBJ_PACKAGE_USING_TYPE, { returnObjects: true });

  const [objProduct, setObjProduct] = useState();
  const [arrayKeyProduct, setArrayKeyProduct] = useState();

  useEffect(() => {
    if (data) {
      let objectData = {};
      data.forEach(child => {
        if (
          child.type !== AppConstant.PACKAGE_USING_TYPE.bookingFunction &&
          child.type !== AppConstant.PACKAGE_USING_TYPE.orderFunction
        ) {
          // Check type if not Booking or Ordering, i will add to object.
          // If type is exist in object add orderNumber to quantity
          if (objectData[child.type]) {
            objectData[child.type].quantity = objectData[child.type].quantity.replace(")", ` +${child.orderNumber})`);
          } else {
            objectData[child.type] = {
              title: StringFormat(getLabel(LangConstant.FM_ADDING_PRODUCT), ObjFmPackageUsingType[child.type]),
              quantity: StringFormat(FM_GUARD, StringFormat(FM_PLUS, child.orderNumber)),
            };
          }
        } else {
          if (child.default) {
            // Check if type are Booking or Ordering and default value, i will create data with type equals package
            objectData[AppConstant.PACKAGE_USING_TYPE.package] = {
              title: StringFormat(
                getLabel(LangConstant.FM_ADDING_PRODUCT),
                ObjFmPackageUsingType[AppConstant.PACKAGE_USING_TYPE.package],
              ),
              quantity: StringFormat(FM_GUARD, ObjFmPackageUsingType[child.type]),
            };
            // if value default is create, i will add type not default
            data.forEach(child => {
              if (
                (child.type == AppConstant.PACKAGE_USING_TYPE.bookingFunction ||
                  child.type == AppConstant.PACKAGE_USING_TYPE.orderFunction) &&
                !child.default
              ) {
                objectData[AppConstant.PACKAGE_USING_TYPE.package].quantity = objectData[
                  AppConstant.PACKAGE_USING_TYPE.package
                ].quantity.replace(")", ` + ${ObjFmPackageUsingType[child.type]})`);
                return;
              }
            });
          }
        }
      });

      setObjProduct(objectData);
      setArrayKeyProduct(Object.keys(objectData));
    }
  }, [data]);

  return (
    <Typography className="medium-md-txt">
      {objProduct &&
        arrayKeyProduct &&
        arrayKeyProduct.map((packageType, index) => (
          <Box component="span" key={index}>
            {objProduct[packageType].title}
            <Typography component="span" className={clsx("medium-md-txt", classes.otherNumber)}>
              {objProduct[packageType].quantity}
            </Typography>
            {arrayKeyProduct.length !== index + 1 && <>,&emsp;</>}
          </Box>
        ))}
    </Typography>
  );
};

const FM_GUARD = "({0})";
const FM_PLUS = "+{0}";

export default AddingProduct;

const useStyles = makeStyles(theme => ({
  root: {},
  otherNumber: {
    color: theme.palette.text.link,
    marginLeft: 4,
  },
}));
