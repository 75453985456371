import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const BookingHistoryIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(-40 -400) translate(0 70) translate(40 330)" />
        <g fill="currentColor">
          <path
            fillRule="nonzero"
            d="M14.81 7.88c-2.922 0-5.29 2.368-5.29 5.29H7.76L10 15.46v.08l2.38-2.37H10.7c0-2.27 1.84-4.11 4.11-4.11 2.27 0 4.11 1.84 4.11 4.11 0 2.27-1.84 4.11-4.11 4.11-1.09.002-2.135-.434-2.9-1.21l-.84.84c1.686 1.713 4.314 2.075 6.4.88 2.086-1.194 3.104-3.644 2.479-5.965-.625-2.321-2.736-3.929-5.139-3.915v-.03zm-.59 2.94v2.94l2.52 1.49.42-.71-2.06-1.22v-2.5h-.88z"
            transform="translate(-40 -400) translate(0 70) translate(40 330) translate(2 3)"
          />
          <path
            d="M7.07 13.5H1.88V6.75H15v.47c.634-.086 1.28-.014 1.88.21V3c-.12-.925-.951-1.589-1.88-1.5h-.94V0h-1.87v1.5h-7.5V0H2.81v1.5h-.93C.95 1.406.115 2.072 0 3v10.5c.115.928.95 1.594 1.88 1.5h6.57c-.494-.468-.955-.97-1.38-1.5zM1.88 3H15v2.25H1.88V3z"
            transform="translate(-40 -400) translate(0 70) translate(40 330) translate(2 3)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default memo(BookingHistoryIcon);
