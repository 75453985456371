export default {
  TXT_PAYMENT_SETUP: "Cài đặt thanh toán",
  TXT_SHOW_PAYMENT_INFORMATION: " Hiện thông tin thanh toán",
  P_PAYMENT_INFORMATION: "Cung cấp các phương thức thanh toán của bạn cho khách hàng",
  TXT_PAYMENT_REQUIRED_IN_ADVANCE: "Yêu cầu thanh toán trước",
  TXT_AMOUNT_PER_BOOKING: "Số tiền / 1 booking",
  TXT_PERCENTAGE_PER_BOOKING: "Số % / 1 booking",
  TXT_ORDER_DELIVERY: "Hiện thông tin vận chuyển",
  TXT_SHIP_AWAY: "Giao tận nơi",
  TXT_TAKE_AWAY: "Tự đến lấy",
  TXT_CASH: "Tiền mặt",
  TXT_INTERNET_BANKING: "Chuyển khoản",
  TXT_EDIT_BANKING_INFO: "Chỉnh sửa thông tin chuyển khoản",
  TXT_ADD_NEW_BANKING_INFO: "Thêm tài khoản mới",
  TXT_ADD_BANKING_INFO: "Thêm tài khoản",
  TXT_BANKING_SERVICE_INFO: "Dịch vụ thanh toán",
  TXT_ACCOUNT_NUMBER: "Số tài khoản",
  TXT_ACCOUNT_OWNER: "Chủ tài khoản",
  TXT_BANKING_CONTENT: "Nội dung chuyển khoản",
  TXT_DELETE_BANKING_INFO: "Xoá thông tin chuyển khoản?",
  TXT_FORM_CONTAIN_EMPTY_FIELD: "Vui lòng điền vào những trường bắt buộc",

  P_ORDER_PAYMENT: "COD (Thanh toán khi nhận hàng)",
  P_ORDER_DELIVERY: "Miễn phí (đồng giá 30K theo chính sách của shop)",

  FM_BANKING_INFO: "Thông tin chuyển khoản {{ index }}",
  FM_DELETE_MESSAGE: 'Bạn có chắc muốn xoá thông tin chuyển khoản "{{ name }}" không?',
};
