import React, { memo, useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, useMediaQuery, useTheme, Box, Typography, Button, Dialog, IconButton } from "@material-ui/core";
import { LocalMallOutlined, Close, ArrowBack } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import formatString from "string-format";
import { LangConstant, AppConstant } from "const";
import { convertHex2rgba, deepCloneJsonObject, formatCurrency, getCommonKey, isArrayNotEqual } from "utils";
import { SelectedItem } from "components/mnbooking";
import DialogListTopping from "./DialogListTopping";
import { formatClassificationItem } from "./ListItems";

const ListSelectedProduct = ({
  isShow,
  selectedData,
  setSelectedData,
  onChangeTotalItem,
  onRemoveItem,
  onComeBack,
  onSubmitFormData,
  onCloseAll,
  continueOrder,
}) => {
  const defaultClasses = useStyles();
  const theme = useTheme();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);
  const isDesktop = useMediaQuery(theme.breakpoints.up(1240));
  const listComboItem = useSelector(({ comboItemRedux }) => comboItemRedux.dataGetListComboItem, shallowEqual);
  const listProductItem = useSelector(({ mnProductRedux }) => mnProductRedux.dataItem, shallowEqual);
  const listServiceItem = useSelector(({ serviceItemRedux }) => serviceItemRedux.dataGetListServiceItem, shallowEqual);

  const [calculateTotal, setCalculateTotal] = useState(DEFAULT_DATA);
  const [toppingList, setToppingList] = useState(FORM_TOPPING_DEFAULT);
  const [classification, setClassification] = useState(null);
  const [itemDetail, setItemDetail] = useState(null);

  const onOpenTopping = selectedItemIndex => {
    let dataRowDetail = selectedData[selectedItemIndex];

    let tmpItemDetails = { ...dataRowDetail };
    let itemDataType = tmpItemDetails.type;

    let itemToppingDetail = {};
    let mainItemDetail = {};
    let classificationItem = null;

    if (dataRowDetail?.mainItemUid) {
      let allItemList = [
        ...deepCloneJsonObject(listProductItem.data),
        ...deepCloneJsonObject(listComboItem.data),
        ...deepCloneJsonObject(listServiceItem.data),
      ];

      mainItemDetail = allItemList.find(item => item.uid === dataRowDetail?.mainItemUid);
      classificationItem = mainItemDetail.classificationList.find(item => item.item.uid === dataRowDetail?.itemUid);

      tmpItemDetails = {
        ...deepCloneJsonObject(mainItemDetail),
        classification: classificationItem,
        type: dataRowDetail.type,
      };
    }

    switch (itemDataType) {
      case AppConstant.ITEM_TYPE.serviceItem:
        itemToppingDetail = getItemTopping(tmpItemDetails, listServiceItem);
        break;
      case AppConstant.ITEM_TYPE.productItem:
        itemToppingDetail = getItemTopping(tmpItemDetails, listProductItem);
        break;
      case AppConstant.ITEM_TYPE.comboItem:
        itemToppingDetail = getItemComboTopping(tmpItemDetails, listComboItem);
        break;
    }
    setItemDetail({ ...itemToppingDetail, selectedTopping: tmpItemDetails.orderDetailsContentList });

    setToppingList({
      index: selectedItemIndex,
      isShow: true,
      listToppingIndex: selectedData[selectedItemIndex]?.toppingListIndex || [],
    });
  };

  const onCloseTopping = () => {
    setToppingList(FORM_TOPPING_DEFAULT);
  };

  const onRemovedItem = (event, index) => {
    event.stopPropagation();
    onRemoveItem(index);
  };

  const onDecreaseItem = index => {
    let mapData = selectedData.map((dataMap, indexMap) =>
      index === indexMap ? { ...dataMap, orderNumber: dataMap.orderNumber - 1 } : dataMap,
    );
    let selectedItem = mapData[index];
    if (selectedItem.orderNumber === 0) {
      onRemoveItem(index);
      onCloseTopping();
    } else {
      setSelectedData(mapData);
    }
  };

  const onIncreaseItem = index => {
    setSelectedData(listDataDisplay =>
      listDataDisplay.map((dataMap, indexMap) =>
        index === indexMap ? { ...dataMap, orderNumber: dataMap.orderNumber + 1 } : dataMap,
      ),
    );
  };

  const onCheckedTopping = (event, index) => {
    let arrayToppingIndex = [...toppingList.listToppingIndex];
    if (event.target.checked) {
      arrayToppingIndex.push(index);
    } else {
      arrayToppingIndex = arrayToppingIndex.filter(dataMap => dataMap !== index);
    }
    setToppingList(dataMap => ({
      ...dataMap,
      listToppingIndex: arrayToppingIndex,
    }));
  };

  const onSaveTopping = orderNumber => {
    let haveSame = false;
    let indexSame = null;
    let mapArrayDisplay = selectedData.map((dataDisplay, indexDisplay) => {
      if (
        !isArrayNotEqual(toppingList.listToppingIndex?.sort(), dataDisplay.toppingListIndex?.sort()) &&
        indexDisplay !== toppingList.index &&
        selectedData[indexDisplay] === selectedData[toppingList.index]
      ) {
        haveSame = true;
        indexSame = indexDisplay;
      }
      if (indexDisplay === toppingList.index) {
        return {
          ...dataDisplay,
          toppingList: toppingList.listToppingIndex.map(dataMap => dataDisplay.itemDetailsContentList[dataMap]),
          toppingListIndex: toppingList.listToppingIndex,
          orderNumber: orderNumber,
          classification: classification,
          uid: null,
          ...formatClassificationItem(classification, deepCloneJsonObject(dataDisplay)),
        };
      }
      return dataDisplay;
    });
    if (haveSame) {
      mapArrayDisplay[indexSame].orderNumber += orderNumber;
      let newResult = mapArrayDisplay.filter((_, indexMap) => indexMap !== toppingList.index);
      setSelectedData(newResult);
    } else {
      setSelectedData(mapArrayDisplay);
    }

    setClassification(null);
    onCloseTopping();
  };

  useEffect(() => {
    if (selectedData.length > 0) {
      let newTotal = {
        day: 0,
        hour: 0,
        minute: 0,
        price: 0,
        total: 0,
      };
      selectedData.forEach(dataMap => {
        let orderNumber = dataMap.orderNumber || 0;
        let usingTime = dataMap.usingTime || 0;
        let usingTimeType = dataMap.usingTimeType || 0;
        let priceTaxIn = dataMap.priceTaxIn || 0;

        newTotal.total += orderNumber;
        newTotal.price += priceTaxIn * orderNumber;
        newTotal = autoCalculatorTime(newTotal, usingTime * orderNumber, true, usingTimeType);

        if (dataMap.type !== AppConstant.ITEM_TYPE.comboItem) {
          // calculate toppings price of item which isn't belong to Combo category
          let listTopping = dataMap.toppingList || dataMap.orderDetailsContentList || [];
          listTopping.forEach(item => {
            let usingTimeItem = item.usingTime || 0;
            let usingTimeTypeItem = item.usingTimeType || 0;
            let priceTaxInItem = item.priceTaxIn || 0;

            newTotal.price += priceTaxInItem * orderNumber;
            newTotal = autoCalculatorTime(newTotal, usingTimeItem * orderNumber, true, usingTimeTypeItem);
          });
        }
      });
      setCalculateTotal(newTotal);
      onChangeTotalItem(newTotal.total);
    } else {
      setCalculateTotal(DEFAULT_DATA);
      onChangeTotalItem(0);
    }
  }, [selectedData]);

  useEffect(() => {
    if (itemDetail) {
      let newItemDetails = deepCloneJsonObject(itemDetail);
      newItemDetails.classification = classification;
      setItemDetail(newItemDetails);
    }
  }, [classification]);

  return (
    <>
      {isDesktop ? (
        <Box className={defaultClasses.root}>
          <ContentSelectedProduct
            classes={defaultClasses}
            listDataDisplay={selectedData}
            calculateTotal={calculateTotal}
            onOpenTopping={onOpenTopping}
            onSubmitFormData={() => onSubmitFormData(selectedData, calculateTotal.price)}
            onDecreaseItem={onDecreaseItem}
            onIncreaseItem={onIncreaseItem}
            onRemovedItem={onRemovedItem}
            continueOrder={continueOrder}
          />
        </Box>
      ) : (
        !toppingList.isShow && (
          <Dialog open={isShow} fullScreen>
            <Box className={defaultClasses.dialogRoot}>
              <IconButton className={defaultClasses.iconButton} onClick={onComeBack}>
                <ArrowBack className={defaultClasses.icon} />
              </IconButton>
              <IconButton className={defaultClasses.iconButton} onClick={onCloseAll}>
                <Close className={defaultClasses.icon} />
              </IconButton>
            </Box>
            <Box className={defaultClasses.cartBox}>
              <Typography className={defaultClasses.cartText}>{getLabel(LangConstant.TXT_CART)}</Typography>
              <ContentSelectedProduct
                classes={defaultClasses}
                listDataDisplay={selectedData}
                calculateTotal={calculateTotal}
                onOpenTopping={onOpenTopping}
                onSubmitFormData={() => onSubmitFormData(selectedData, calculateTotal.price)}
                onDecreaseItem={onDecreaseItem}
                onIncreaseItem={onIncreaseItem}
                onRemovedItem={onRemovedItem}
                continueOrder={continueOrder}
              />
            </Box>
          </Dialog>
        )
      )}
      {toppingList.isShow && (
        <DialogListTopping
          isShow={toppingList.isShow}
          data={itemDetail}
          listDataChecked={toppingList.listToppingIndex}
          onClose={onCloseTopping}
          onCheckedTopping={onCheckedTopping}
          onSave={onSaveTopping}
          onChangeClassification={setClassification}
          isEditFromCart={true}
          classificationItem={classification || itemDetail?.classification?.item}
        />
      )}
    </>
  );
};

const FORM_TOPPING_DEFAULT = {
  isShow: false,
  index: null,
  listToppingIndex: [],
};

ListSelectedProduct.propTypes = {
  isShow: PropTypes.bool,
  selectedData: PropTypes.array,
  setSelectedData: PropTypes.any,
  onChangeTotalItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onComeBack: PropTypes.func,
  onSubmitFormData: PropTypes.func,
  onCloseAll: PropTypes.func,
};
ListSelectedProduct.defaultProps = {};

export default memo(ListSelectedProduct);

const ContentSelectedProduct = ({
  classes,
  listDataDisplay,
  calculateTotal,
  onOpenTopping,
  onSubmitFormData,
  onDecreaseItem,
  onIncreaseItem,
  onRemovedItem,
  continueOrder,
}) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  const [dataDisplay, setDataDisplay] = useState([]);
  const [time, setTime] = useState({
    day: 0,
    hour: 0,
    minute: 0,
  });
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setDataDisplay(listDataDisplay);
  }, [listDataDisplay]);

  useEffect(() => {
    setTime({
      day: calculateTotal.day,
      hour: calculateTotal.hour,
      minute: calculateTotal.minute,
    });
    setPrice(calculateTotal.price);
    setTotal(calculateTotal.total);
  }, [calculateTotal]);
  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.iconHeader}>
          <LocalMallOutlined />
        </Box>
        <Box className={classes.contentHeader}>
          <Typography className={classes.haveChooseTypo}>{getLabel(LangConstant.TXT_HAVE_CHOOSE)}</Typography>
          {dataDisplay?.length > 0 && (
            <Typography className={classes.numberProductTypo}>
              {formatString(getLabel(LangConstant.FM_NUMBER_PRODUCT), total)}
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.body}>
        {dataDisplay.map((dataMap, index) => (
          <SelectedItem
            key={index}
            data={dataMap}
            onOpenTopping={() => onOpenTopping(index)}
            onDecrease={() => onDecreaseItem(index)}
            onIncrease={() => onIncreaseItem(index)}
            onRemovedItem={event => onRemovedItem(event, index)}
          />
        ))}
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.totalSelectedBox}>
          <Box className={classes.itemBox}>
            <Typography className={classes.totalTypo}>{getLabel(LangConstant.TXT_TOTAL)}</Typography>
            <Typography className={classes.timeTypo}>{displayTimeTotal(time, getLabel)}</Typography>
          </Box>
          <Box className={classes.itemBox}>
            <Typography className={classes.includedTax}>{getLabel(LangConstant.TXT_INCLUDED_TAX)}</Typography>
            <Typography className={classes.totalPrice}>{`${formatCurrency(price.toString())} ${getLabel(
              getCommonKey(LangConstant.TXT_CURRENCY_COMMON),
            )}`}</Typography>
          </Box>
        </Box>
        <Button
          onClick={onSubmitFormData}
          disabled={dataDisplay.length === 0}
          classes={{ root: classes.buttonReserve, disabled: classes.disabledButton }}
        >
          {continueOrder || getLabel(LangConstant.TXT_BEFORE_BOOKING)}
        </Button>
      </Box>
    </>
  );
};

const displayTimeTotal = (totalData, getLabel) => {
  let result = "";
  if (totalData.day > 0) {
    result += `${totalData.day} ${getLabel(getCommonKey(LangConstant.TXT_DAY_LOWERCASE))}`;
  }
  if (result.length > 0) {
    result += " ";
  }
  if (totalData.hour > 0) {
    result += `${totalData.hour}${getLabel(getCommonKey(LangConstant.TXT_H))}`;
  }
  if (result.length > 0) {
    result += " ";
  }
  if (totalData.minute > 0) {
    result += `${totalData.minute}${getLabel(getCommonKey(LangConstant.TXT_MIN))}`;
  }
  return result;
};

const getItemTopping = (item, listItems) => {
  let toppingList = [];
  let selectedItemDetail = { ...item };
  listItems.data.forEach(dataMap => {
    if (dataMap.uid === selectedItemDetail.itemUid) {
      dataMap.itemDetailsList.map(itemDetail => (toppingList = itemDetail.itemDetailsContentList.sort()));
    }
  });
  selectedItemDetail.orderDetailsContentList = toppingList;
  return selectedItemDetail;
};

const getItemComboTopping = (item, listItems) => {
  let toppingList = [];
  let selectedItemDetail = { ...item };
  listItems.data.forEach(dataMap => {
    if (dataMap.uid === selectedItemDetail.itemUid) {
      toppingList = dataMap.itemDetails?.itemDetailsContentList.sort();
    }
  });
  selectedItemDetail.orderDetailsContentList = toppingList;
  return selectedItemDetail;
};

export const autoCalculatorTime = (calculateData, time, isPlus, type) => {
  let newTime = parseInt(time);
  let minute = parseInt((time - newTime) * 60);
  let newCalculateData = { ...calculateData };
  switch (type) {
    case AppConstant.TYPE_TIME_USED.day:
      if (isPlus) {
        newCalculateData.day += newTime;
      } else {
        newCalculateData.day -= newTime;
      }
      break;
    case AppConstant.TYPE_TIME_USED.hour:
      if (isPlus) {
        newCalculateData.minute += minute;
        if (newCalculateData.minute >= 60) {
          calculateTheAdditionOfMinute(newCalculateData);
        }
        newCalculateData.hour += newTime;
        if (newCalculateData.hour >= 24) {
          let numberDivider = parseInt(newCalculateData.hour / 24);
          newCalculateData.day = newCalculateData.day + numberDivider;
          newCalculateData.hour -= 24 * numberDivider;
        }
      } else {
        newCalculateData.minute -= minute;
        if (newCalculateData.minute < 0) {
          calculateTheSubtractionOfMinute(newCalculateData);
        }
        newCalculateData.hour -= newTime;
        if (newCalculateData.hour < 0) {
          let numberDivider = (parseInt(newCalculateData.hour / 24) - 1) * -1;
          newCalculateData.day = newCalculateData.day - numberDivider;
          newCalculateData.hour += 24 * numberDivider;
        }
      }
      break;
    case AppConstant.TYPE_TIME_USED.minute:
      if (isPlus) {
        newCalculateData.minute += newTime;
        if (newCalculateData.minute >= 60) {
          calculateTheAdditionOfMinute(newCalculateData);
        }
      } else {
        newCalculateData.minute -= newTime;
        if (newCalculateData.minute < 0) {
          calculateTheSubtractionOfMinute(newCalculateData);
        }
      }
      break;
    default:
      if (isPlus) {
        newCalculateData.minute += newTime;
        if (newCalculateData.minute >= 60) {
          calculateTheAdditionOfMinute(newCalculateData);
        }
      } else {
        newCalculateData.minute -= newTime;
        if (newCalculateData.minute < 0) {
          calculateTheSubtractionOfMinute(newCalculateData);
        }
      }
  }
  return newCalculateData;
};

const calculateTheAdditionOfMinute = data => {
  let newCalculateData = data;
  let numberDivider = parseInt(newCalculateData.minute / 60);
  newCalculateData.hour = newCalculateData.hour + numberDivider;
  newCalculateData.minute -= 60 * numberDivider;
  return newCalculateData;
};

const calculateTheSubtractionOfMinute = data => {
  let newCalculateData = data;
  let numberDivider = (parseInt(newCalculateData.minute / 60) - 1) * -1;
  newCalculateData.hour = newCalculateData.hour - numberDivider;
  newCalculateData.minute += 60 * numberDivider;
  return newCalculateData;
};

const DEFAULT_DATA = {
  day: 0,
  hour: 0,
  minute: 0,
  price: 0,
  total: 0,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "33.4%",
    height: "100%",
    paddingLeft: 24,
  },
  dialogRoot: {
    display: "flex",
    width: "100%",
    height: 75,
    justifyContent: "space-between",
  },
  iconButton: {
    width: 36,
    height: 36,
    margin: "16px 16px 23px",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      width: 24,
      height: 24,
    },
  },
  icon: {
    width: 36,
    height: 36,
    [theme.breakpoints.down("xs")]: {
      width: 24,
      height: 24,
    },
  },

  cartBox: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 92px",
    },
  },

  cartText: {
    fontSize: 24,
    paddingBottom: 36,
    alignSelf: "center",
    color: theme.palette.black,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: 54,
    },
  },

  header: {
    display: "flex",
    width: "100%",
    height: 48,
    backgroundColor: theme.palette.secondary.dark,
    padding: "6px 16px",
  },
  iconHeader: {
    width: 36,
    height: 36,
    color: "#6c7078",
    backgroundColor: "white",
    borderRadius: "50%",
    padding: 6,
    marginRight: 16,
  },
  contentHeader: {
    display: "flex",
    width: "100%",
    height: 24,
    margin: "6px 0",
  },
  haveChooseTypo: {
    color: "white",
    lineHeight: 1.5,
    fontWeight: 600,
  },
  numberProductTypo: {
    marginLeft: "auto",
    fontSize: 14,
    color: "white",
    lineHeight: 1.9,
  },
  body: {
    maxHeight: 397,
    overflow: "auto",
  },
  footer: {
    textAlign: "center",
  },
  totalSelectedBox: {
    height: 81,
    width: "100%",
    padding: 16,
    backgroundColor: "#fafafb",
  },
  itemBox: {
    display: "flex",
    height: 24,
  },
  totalTypo: {
    color: "#3e4045",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  timeTypo: {
    marginLeft: "auto",
    fontSize: 14,
    color: "#447aff",
  },
  includedTax: {
    marginTop: 8,
    fontSize: 12,
    color: "#7f838c",
    fontWeight: 400,
    lineHeight: 1.67,
  },
  totalPrice: {
    marginLeft: "auto",
    color: theme.palette.primary.main,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  buttonReserve: {
    width: 132,
    height: 40,
    marginTop: 16,
    borderRadius: 2,
    fontSize: 16,
    fontWeight: 600,
    textTransform: "inherit",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabledButton: {
    color: theme.palette.white,
    backgroundColor: convertHex2rgba(theme.palette.primary.main, 0.6),
    opacity: 0.6,
    cursor: "no-drop",
  },
}));
