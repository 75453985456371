import React, { memo, useEffect, useState } from "react";
import ServiceCategoryList from "./ServiceCategoryList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ServiceCategoryAction from "redux/service-category.redux";
import ServiceItemAction from "redux/service-item.redux";
import ServiceSubcategoryList from "./ServiceSubcategoryList";
import ServiceItemList from "./ServiceItemList";

const MainServiceCategory = ({ childServiceModeId, childServiceModeArr, onChangeMode }) => {
  const dispatch = useDispatch();

  const isCreateCategorySuccess = useSelector(state => state.serviceCategoryRedux.isCreateSuccess, shallowEqual);
  const isRemoveCategorySuccess = useSelector(state => state.serviceCategoryRedux.isRemoveSuccess, shallowEqual);
  const isUpdateCategorySuccess = useSelector(state => state.serviceCategoryRedux.isUpdateSuccess, shallowEqual);

  const listCategoryServiceItem = useSelector(state => state.serviceItemRedux.dataCategoryServiceItem);
  const [isOpen, setIsOpen] = useState({
    category: true,
    subcategory: false,
    item: false,
    categoryData: null,
    fromSubcategory: false,
    subcategoryData: null,
  });

  const [jumpToChild, setJumpToChild] = useState(false);

  const onJumpFromCategory = parentData => {
    dispatch(ServiceItemAction.resetServiceItem());
    dispatch(ServiceItemAction.getCategoryServiceItem({ categoryUid: parentData.uid }));
    setJumpToChild(true);
    setIsOpen({ ...isOpen, categoryData: parentData });
  };

  const onJumpFromSubcategory = (e, parentData) => {
    dispatch(ServiceItemAction.resetServiceItem());
    dispatch(ServiceItemAction.getCategoryServiceItem({ categoryUid: parentData.uid }));
    setJumpToChild(true);
    setIsOpen({
      ...isOpen,
      category: false,
      subcategory: false,
      item: true,
      subcategoryData: parentData,
      fromSubcategory: true,
    });
  };

  const onJumpToMain = () => {
    setIsOpen({
      ...isOpen,
      category: true,
      subcategory: false,
      item: false,
      categoryData: null,
      subcategoryData: null,
      fromSubcategory: false,
    });
    setJumpToChild(false);
  };

  const onBackToSubcategory = masterCategoryData => {
    setIsOpen({ ...isOpen, category: false, subcategory: true, item: false, categoryData: masterCategoryData });
  };

  useEffect(() => {
    if (jumpToChild) {
      setJumpToChild(false);
      if (isOpen.fromSubcategory) {
        setIsOpen({ ...isOpen, category: false, subcategory: false, item: true });
      } else {
        if (!listCategoryServiceItem || (listCategoryServiceItem && listCategoryServiceItem.length <= 0)) {
          setIsOpen({ ...isOpen, category: false, subcategory: true, item: false });
        } else {
          setIsOpen({ ...isOpen, category: false, subcategory: false, item: true, fromSubcategory: false });
        }
      }
    }
  }, [listCategoryServiceItem]);

  useEffect(() => {
    if (isCreateCategorySuccess || isUpdateCategorySuccess || isRemoveCategorySuccess) {
      dispatch(ServiceCategoryAction.resetServiceCategory());
      dispatch(ServiceCategoryAction.getServiceCategory({}));
    }
  }, [isCreateCategorySuccess, isUpdateCategorySuccess, isRemoveCategorySuccess]);

  return (
    <>
      {isOpen.category && (
        <ServiceCategoryList
          onJumpToChild={onJumpFromCategory}
          modeId={childServiceModeId}
          modeArray={childServiceModeArr}
          onChange={onChangeMode}
        />
      )}
      {isOpen.subcategory && (
        <ServiceSubcategoryList
          onJumpToCategoryItemList={onJumpFromCategory}
          onJumpToChild={onJumpFromSubcategory}
          parentData={isOpen.categoryData}
          modeId={childServiceModeId}
          modeArray={childServiceModeArr}
          onChange={onChangeMode}
          onJumpToMain={onJumpToMain}
        />
      )}
      {isOpen.item && (
        <ServiceItemList
          fromSubcategory={isOpen.fromSubcategory}
          masterData={isOpen.categoryData}
          parentData={isOpen.fromSubcategory ? isOpen.subcategoryData : isOpen.categoryData}
          modeId={childServiceModeId}
          modeArray={childServiceModeArr}
          onChange={onChangeMode}
          onBackToSubcategory={onBackToSubcategory}
          onJumpToMain={onJumpToMain}
        />
      )}
    </>
  );
};

export default memo(MainServiceCategory);
