import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import AuthLayout from "layouts/AuthLayout";
import { LoginForm } from "./components";

const Login = () => {
  const { t: getLabel } = useTranslation();

  return (
    <AuthLayout intro={getLabel(LangConstant.MSG_WELCOME)} intro2={getLabel(LangConstant.MSG_WELCOME_LINE2)}>
      <LoginForm />
    </AuthLayout>
  );
};

export default memo(Login);
