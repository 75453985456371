import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { ArticleIcon, CalendarIcon } from "icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const TabSelect = props => {
  const { onClick, value } = props;
  const classes = useStyles();

  const onChangeTab = e => {
    onClick(e);
  };
  return (
    <Box className={classes.barRoot}>
      <ToggleButtonGroup value={value}>
        <ToggleButton
          classes={{ root: classes.buttonClick, selected: classes.selectedButtonClick }}
          onClick={() => onChangeTab(TabOne)}
          value={TabOne}
        >
          <ArticleIcon className={classes.iconStyle} />
        </ToggleButton>
        <ToggleButton
          classes={{ root: classes.buttonClick, selected: classes.selectedButtonClick }}
          onClick={() => onChangeTab(TabTwo)}
          value={TabTwo}
        >
          <CalendarIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  buttonClick: {
    color: theme.palette.grey[600],
    borderRadius: "0%",
    "&:hover": {
      backgroundColor: "#d4d5d8",
    },
  },
  selectedButtonClick: {
    backgroundColor: "#d4d5d8",
  },
  barRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },
  iconStyle: {
    fontSize: 18,
    width: 24,
    height: 24,
  },
}));

TabSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
};
TabSelect.defaultProps = { onClick: () => {} };
export default TabSelect;
const TabOne = 0;
const TabTwo = 1;
