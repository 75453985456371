import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { Star, Check, Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey, formatCurrency } from "utils";

const PackageItem = ({ isSelected, item }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  return (
    <Box className={classes.root}>
      <Box className={clsx("center-root", classes.boxItemHeader, isSelected && classes.selectedHeader)}>
        <Box textAlign="center">
          <Box className={classes.boxPackageHeader}>
            <Typography className={clsx(classes.packageHeader, isSelected && classes.textSelectedHeader)}>
              {getLabel(item.package)}
            </Typography>
            {isSelected && <Star className={classes.starIcon} />}
          </Box>
          <Typography className={clsx(classes.packagePrice, isSelected && classes.textSelectedHeader)}>
            {Number.isInteger(item.price)
              ? StringFormat(
                  getLabel(LangConstant.FM_CURRENCY),
                  formatCurrency(item.price.toString()),
                  getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
                )
              : getLabel(LangConstant.TXT_CONTACT_US)}
          </Typography>
        </Box>
      </Box>
      <Box className={isSelected ? classes.selectedBody : ""}>
        <Box className={clsx("center-root", classes.boxItemPlanDescription)}>
          <Typography className={classes.titleDescription}>
            {Number.isInteger(item.include) ? item.include : getLabel(LangConstant.TXT_BOTH)}
          </Typography>
        </Box>
        <Box className={clsx("center-root", classes.boxItemPlanDescription)}>
          <Typography className={classes.titleDescription}>{item.createShop}</Typography>
        </Box>
        <Box className={clsx("center-root", classes.boxItemPlanDescription)}>
          {item.basicBooking ? <Check className={classes.checkIcon} /> : <Close className={classes.closeIcon} />}
        </Box>
        <Box className={clsx("center-root", classes.boxItemPlanDescription)}>
          {item.advanceBooking ? <Check className={classes.checkIcon} /> : <Close className={classes.closeIcon} />}
        </Box>
        <Box className={clsx("center-root", classes.boxItemPlanDescription)}>
          <Typography className={classes.titleDescription}>
            {Number.isInteger(item.limitRemote) ? item.limitRemote : getLabel(item.limitRemote)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

PackageItem.propTypes = {
  item: PropTypes.object,
  isSelected: PropTypes.bool,
};

PackageItem.defaultProps = {};

export default memo(PackageItem);

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 203,
  },
  boxItemPlanDescription: {
    minHeight: 80,
    padding: "16px 0",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  boxItemHeader: {
    height: 136,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  boxPackageHeader: {
    display: "flex",
    justifyContent: "center",
  },
  packageHeader: {
    fontSize: 15,
    lineHeight: 1.73,
    color: "#3e4045",
  },
  packagePrice: {
    fontSize: 17,
    fontWeight: 600,
    lineHeight: 1.53,
    color: "#3e4045",
  },
  selectedHeader: {
    backgroundColor: "#2a2c2f",
  },
  textSelectedHeader: {
    color: theme.palette.white,
  },
  selectedBody: {
    background: theme.palette.grey[200],
  },
  starIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
    color: "#feba40",
  },
  checkIcon: {
    width: 20,
    height: 20,
    color: "#46cf70",
  },
  closeIcon: {
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
  },
}));
