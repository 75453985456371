import React from "react";
import { makeStyles, Paper, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import PackageAndProductItem from "./PackageAndProductItem";

const AdditionalPackage = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const objAdditionalPackage = getLabel(LangConstant.OBJ_ADDITIONAL_PACKAGE, { returnObjects: true });

  return (
    <Paper className={classes.root}>
      <Typography className={classes.highlightTitle}>{getLabel(LangConstant.TXT_ADDITIONAL_PACKAGE)}</Typography>
      <Typography className={classes.titleDescription}>{getLabel(LangConstant.TXT_ADDITIONAL_DESCRIPTION)}</Typography>
      <Box className={classes.listItem}>
        {Object.keys(objAdditionalPackage).map((keyPackage, index) => (
          <PackageAndProductItem key={index} keyPackage={keyPackage} />
        ))}
      </Box>
    </Paper>
  );
};

export default AdditionalPackage;

const useStyles = makeStyles(theme => ({
  root: {
    padding: "100px 189px",
    backgroundColor: "#f1f3f6",
    [theme.breakpoints.down("xs")]: {
      padding: "24px",
    },
  },
  highlightTitle: {
    width: "100%",
    textAlign: "center",
    fontSize: 42,
    fontWeight: "bold",
    lineHeight: 1.19,
    color: "#2a2c2f",
    marginBottom: 16,
  },
  titleDescription: {
    width: "100%",
    textAlign: "center",
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 1.53,
    color: "#3e4045",
    marginBottom: 84,
  },
  listItem: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(346px, 1fr))",
    columnGap: 16,
    rowGap: 16,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(auto-fill,minmax(100%, 1fr))",
    },
  },
}));
