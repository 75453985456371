import React, { memo, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { Add, ArrowDropDown, ArrowDropUp, Close, Error } from "@material-ui/icons";
import {
  Box,
  useTheme,
  makeStyles,
  InputLabel,
  OutlinedInput,
  FormLabel,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  FormHelperText,
} from "@material-ui/core";
import clsx from "clsx";
import StringFormat from "string-format";
import ReservationAction from "redux/reservation.redux";
import PlaceItemAction from "redux/place-item.redux";
import StaffAction from "redux/staff.redux";
import ConsumerAction from "redux/consumer.redux";
import { LangConstant, AppConstant } from "const";
import { MenuStatus, MinuteHourTimePicker } from "components";
import ExtendPartAddBooking from "./ExtendPartAddBooking";
import { validatePhone, getLabelArray, getStatusColor, isBlank, getFeatureConditions } from "utils";
import { convertTimeStampToDate, getEndOfDay, getStartOfDay, getTimestamp, removeSecond } from "utils/date";
import MultiSelectMenu from "components/MultiSelectMenu";
import AddPreOrderInReservation from "./AddPreOrderInReservation";
import { DatePickerReservation } from "components/mnbooking";

const AddReservation = props => {
  const role = Cookie.get(AppConstant.ROLE);
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Box>
      {role !== AppConstant.SHOP_STAFF && (
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.buttonAdd, startIcon: classes.startIcon }}
          startIcon={<Add style={{ fontSize: 20 }} />}
          onClick={() => setOpen(true)}
        >
          <Typography variant="body2" classes={{ body2: "medium-md-txt" }} className={classes.textBtn}>
            {getLabel(LangConstant.TXT_ADD)}
          </Typography>
        </Button>
      )}
      {open && <AddingDialog onClose={() => setOpen(false)} {...props} />}
    </Box>
  );
};

AddReservation.prototype = {
  isSettingEndTime: PropTypes.bool,
  onSubmitNewReservation: PropTypes.func,
};

AddReservation.defaultProps = {
  isSettingEndTime: false,
};

const AddingDialog = ({ onClose, isSettingEndTime, onSubmitNewReservation }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting, shallowEqual);
  const staffBusyStatus = useSelector(state => state.staffRedux.staffBusyStatus, shallowEqual);
  const shopData = useSelector(state => state.shopInfoRedux.data, shallowEqual);
  const [dataBooking, isSuccessNumberBooking] = useSelector(
    ({ consumerRedux }) => [consumerRedux.numberBooking, consumerRedux.isNumberBookingSuccess],
    shallowEqual,
  );
  const placeBusyStatus = useSelector(state => state.placeItemRedux.placeBusyStatus, shallowEqual);

  // TODO hidden suggestion place because consumer request, to enable when improve logic suggestion
  // const [dataPlaceItemAutoFill, placeBusyStatus] = useSelector(
  //   ({ placeItemRedux }) => [placeItemRedux.dataPlaceItemAutoFill, placeItemRedux.placeBusyStatus],
  //   shallowEqual,
  // );

  let isSettingTotal = Boolean(
    reservationSetting &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.customer] &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.customer].publish === 1,
  );
  let isSettingDeposit = Boolean(
    reservationSetting &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit] &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit].publish === 1,
  );
  let isSettingPrepayment = Boolean(
    reservationSetting &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit] &&
      Boolean(reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit]?.details?.depositInformation?.publish),
  );

  let { isPremium, isOnlyBooking, isOnlyOrdering } = getFeatureConditions(reservationSetting);

  const [isAddingPeriodic, setIsAddingPeriodic] = useState(false);
  const [timeChangeControlState, setTimeChangeControlState] = useState(false);
  const [isBreakRule, setIsBreakRule] = useState(false);
  const [placeArrayWithBusyStatus, setPlaceArrayWithBusyStatus] = useState([]);
  const [staffArrayWithBusyStatus, setStaffArrayWithBusyStatus] = useState([]);
  const [timeHourArr, setTimeHourArr] = useState([]);
  const [timeMinuteArr, setTimeMinuteArr] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(AppConstant.STT_RESERVATION.confirm);
  const [isShowWarning, setIsShowWarning] = useState({ name: false, telephone: false });
  const inputRef = useRef([]);
  const [textLabelNumberBooking, setTextLabelNumberBooking] = useState("");
  const [isShowAllAddBooking, setIsShowAllAddBooking] = useState(false);
  const [arrayStatus, setArrayStatus] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [startTimeAndHour, setStartTimeAndHour] = useState({
    min: new Date().getMinutes(),
    hour: new Date().getHours(),
  });

  const [endDate, setEndDate] = useState(new Date());
  const [endTimeAndHour, setEndTimeAndHour] = useState({
    min: 0,
    hour: 0,
  });

  const [newReservation, setNewReservation] = useState(createDefaultReservation());

  const onCloseDialog = () => {
    // TODO hidden suggestion place because consumer request, to enable when improve logic suggestion
    // dispatch(PlaceItemAction.resetPlaceAutoFill());
    dispatch(ReservationAction.resetGetAvailableStatus());
    onClose();
  };

  const onSubmit = event => {
    let isValidPhone = validatePhone(newReservation.phoneNumber);
    let isValidName = !isBlank(newReservation.name);
    if (isValidPhone && isValidName) {
      onSubmitNewReservation(newReservation, isSettingTotal, event);
      // TODO hidden suggestion place because consumer request, to enable when improve logic suggestion
      // dispatch(PlaceItemAction.resetPlaceAutoFill());
      onClose();
      setIsShowWarning({ telephone: false, name: false });
    } else if (!isValidName) {
      setIsShowWarning({ name: true });
    } else if (!isValidPhone) {
      setIsShowWarning({ telephone: true });
    }
    setIsBreakRule(false);
    event.preventDefault();
  };

  const onChangeDetail = event => {
    let inputValue = event.target.value;
    setNewReservation({ ...newReservation, [event.target.name]: inputValue });
    if (event.target.name === "man") {
      let manPeople = inputValue > 0 ? parseInt(inputValue) : 0;
      setNewReservation({
        ...newReservation,
        man: manPeople,
        totalConsumer: newReservation.child + manPeople,
      });
    }
    if (event.target.name === "child") {
      let childPeople = inputValue > 0 ? parseInt(inputValue) : 0;
      setNewReservation({
        ...newReservation,
        child: inputValue > 0 ? parseInt(inputValue) : 0,
        totalConsumer: newReservation.man + childPeople,
      });
    }
  };

  const onChangeStartDate = date => {
    let dateOpt = createDateOpt(startTimeAndHour.hour, startTimeAndHour.min);
    let dateObj = new Date(date);
    let newDate = set(dateObj, dateOpt);
    setStartDate(newDate);
    setNewReservation({ ...newReservation, startTime: getTimestamp(newDate) });
    setTimeChangeControlState(!timeChangeControlState);
  };

  const onChangeStartHour = (hour, min) => {
    let timeInDay = calculateTimeInDay(newReservation, shopData);
    let tempCloseMinuteArr = [];
    tempCloseMinuteArr =
      hour === timeInDay.openHour
        ? MINUTE_ARR.filter(min => min >= timeInDay.minuteOpen)
        : hour === timeInDay.closeHour
        ? MINUTE_ARR.filter(min => min <= timeInDay.minuteClose)
        : MINUTE_ARR;
    setTimeMinuteArr(tempCloseMinuteArr);
    let dateOpt = createDateOpt(hour, min);
    let newDate = set(startDate, dateOpt);
    setStartTimeAndHour({
      hour: hour,
      min: min,
    });
    setStartDate(newDate);
    setNewReservation({ ...newReservation, startTime: getTimestamp(newDate) });
    setTimeChangeControlState(!timeChangeControlState);
  };

  const onChangeEndDate = date => {
    let dateOpt = createDateOpt(endTimeAndHour.hour, endTimeAndHour.min);
    let dateObj = new Date(date);
    let newDate = set(dateObj, dateOpt);
    setEndDate(newDate);
    setNewReservation({ ...newReservation, endTime: getTimestamp(newDate) });
    setTimeChangeControlState(!timeChangeControlState);
  };

  const onChangeEndHour = (hour, min) => {
    let dateOpt = createDateOpt(hour, min);
    let newDate = set(endDate, dateOpt);
    setEndTimeAndHour({
      hour: hour,
      min: min,
    });
    setEndDate(newDate);
    setNewReservation({ ...newReservation, endTime: getTimestamp(newDate) });
    setTimeChangeControlState(!timeChangeControlState);
  };

  const onChangeSelectedPlaces = selectedList => {
    let placeArraySelected = [];
    selectedList.forEach(element => {
      placeArrayWithBusyStatus.forEach(ele => {
        if (ele.items.length > 0) {
          ele.items.forEach(item => {
            if (item.uid === element) {
              placeArraySelected.push(restructurePlaceArray(item, true));
            }
          });
        } else if (ele.uid === element) {
          placeArraySelected.push(restructurePlaceArray(ele));
        }
      });
    });
    setNewReservation({ ...newReservation, assign: placeArraySelected });
  };

  const onChangeSelectedStaff = selectedList => {
    let staffArraySelected = [];
    selectedList.forEach(element => {
      staffArrayWithBusyStatus.forEach(ele => {
        if (ele.uid === element) {
          staffArraySelected.push(restructureStaffArray(ele));
        }
      });
    });
    setNewReservation({ ...newReservation, staff: staffArraySelected });
  };

  const onAddPreOrderToReservation = (orderList, price) => {
    let newPriceDisplay = 0;
    if (isSettingDeposit) {
      let settingDetails = reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit]?.details;
      if (settingDetails?.depositInformation?.type === AppConstant.DEPOSIT_TYPE.percent) {
        newPriceDisplay = parseInt((settingDetails?.depositInformation?.value / 100) * price);
      } else {
        newPriceDisplay = settingDetails?.depositInformation?.value || 0;
      }
    }
    setNewReservation({ ...newReservation, orderDetailsList: orderList, depositTotal: newPriceDisplay });
  };

  const onChangePriceDeposit = price => {
    setNewReservation({ ...newReservation, depositTotal: price });
  };

  const onChangeActivity = index => {
    let status = LangConstant.ARR_STATUS_FILTER.indexOf(arrayStatus[index]);
    setNewReservation({ ...newReservation, status: status });
    setSelectedStatus(status);
  };

  const onEnterInput = (event, index) => {
    if (event.keyCode === 13 || event.which === 13) {
      index === (isSettingTotal ? indexInput.internalNote : indexInput.internalNote - 1)
        ? inputRef.current[0].focus()
        : inputRef.current[index + 1].focus();
    }
  };

  const onCreateEventPusRef = (ref, position) => {
    if (inputRef.current[position]?.name === ref?.name) {
      inputRef.current[position] = ref;
    } else if (Boolean(ref)) {
      inputRef.current.push(ref);
    }
  };

  useEffect(() => {
    if (newReservation.startTime) {
      let selectedDate = convertTimeStampToDate(newReservation.startTime);
      dispatch(
        StaffAction.getStaffBusyStatus({
          start: getStartOfDay(selectedDate),
          end: getEndOfDay(selectedDate),
        }),
      );
      dispatch(
        PlaceItemAction.getPlaceBusyStatus({
          start: getStartOfDay(selectedDate),
          end: getEndOfDay(selectedDate),
        }),
      );
    }
  }, [newReservation.startTime]);

  useEffect(() => {
    if (staffBusyStatus.length > 0) {
      let staffBusy = staffBusyStatus[0];
      setStaffArrayWithBusyStatus(staffBusy.staff || []);
    }
  }, [staffBusyStatus]);

  useEffect(() => {
    if (placeBusyStatus.length > 0) {
      let placeBusy = placeBusyStatus[0];
      setPlaceArrayWithBusyStatus(placeBusy.placeCategory || []);
    }
  }, [placeBusyStatus]);

  useEffect(() => {
    if (newReservation.startTime) {
      let timeInDay = calculateTimeInDay(newReservation, shopData);
      let arrayTimeWorking = [];
      for (let timeHour = 0; timeHour <= timeInDay.closeHour - timeInDay.openHour; timeHour++) {
        arrayTimeWorking.push(timeHour + timeInDay.openHour);
      }
      setTimeHourArr(arrayTimeWorking);
    }
  }, [newReservation.startTime]);

  useEffect(() => {
    if (validatePhone(newReservation.phoneNumber)) {
      dispatch(
        ConsumerAction.getNumberBookingByPhoneNumber({
          phoneNumber: newReservation.phoneNumber,
          type: AppConstant.DATA_TYPE.booking,
        }),
      );
      setIsShowWarning({ ...isShowWarning, telephone: false });
    }
    if (!isBlank(newReservation.name)) {
      setIsShowWarning({ ...isShowWarning, name: false });
    }
    if (!isBlank(newReservation.phoneNumber)) {
      setTextLabelNumberBooking(StringFormat(getLabel(LangConstant.TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS), 0));
    }
  }, [newReservation.name, newReservation.phoneNumber]);

  useEffect(() => {
    if (isSuccessNumberBooking) {
      setTextLabelNumberBooking(
        StringFormat(getLabel(LangConstant.TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS), dataBooking.count),
      );
      dispatch(ConsumerAction.resetNumberBooking());
    }
  }, [isSuccessNumberBooking]);
  // TODO hidden suggestion place because consumer request, to enable when improve logic suggestion
  // useEffect(() => {
  //   if (validatePhone(newReservation.phoneNumber)) {
  //     dispatch(
  //       PlaceItemAction.getPlaceItemAutoFill({
  //         total: newReservation.totalConsumer,
  //         start: newReservation.startTime,
  //         end: getEndOfDay(convertTimeStampToDate(newReservation.startTime)),
  //       }),
  //     );
  //   }
  // }, [newReservation.startTime, newReservation.totalConsumer, newReservation.phoneNumber]);

  // useEffect(() => {
  //   if (dataPlaceItemAutoFill) {
  //     for (var index = 0; index < dataPlaceItemAutoFill.length; index++) {
  //       dataPlaceItemAutoFill[index] = {
  //         ...dataPlaceItemAutoFill[index],
  //         itemUid: dataPlaceItemAutoFill[index].uid,
  //         categoryUid: dataPlaceItemAutoFill[index].category_uid,
  //       };
  //     }
  //     setNewReservation({ ...newReservation, assign: dataPlaceItemAutoFill });
  //   }
  // }, [dataPlaceItemAutoFill]);

  useEffect(() => {
    let tmpArr = [...ARR_STATUS];
    if (isSettingPrepayment && ((isOnlyBooking && isOnlyOrdering) || isPremium)) {
      tmpArr.splice(2, 0, LangConstant.TXT_WAITING_PAYMENT);
    }
    setArrayStatus(tmpArr);
  }, [reservationSetting]);

  let isDisplayPreOrder = (isOnlyBooking && isOnlyOrdering) || isPremium;
  return (
    <Dialog
      onClose={onCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={true}
      classes={{ paperScrollPaper: isSettingEndTime ? classes.dialogContainerLarge : classes.dialogContainer }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onCloseDialog}>
        {getLabel(LangConstant.TXT_ADD_NEW_BOOKING)}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormLabel className={classes.formLabel}>{getLabel(LangConstant.TXT_CONSUMER_INFORMATION)}</FormLabel>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>
            {getLabel(LangConstant.P_BOOKING_NAME)} <Typography color="primary">&nbsp; &#42;</Typography>
          </InputLabel>
          <Box width="100%">
            <OutlinedInput
              error={isShowWarning.name}
              classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
              onChange={onChangeDetail}
              onKeyDown={event => onEnterInput(event, indexInput.name)}
              required
              inputProps={{
                name: "name",
                ref: ref => onCreateEventPusRef(ref, indexInput.name),
              }}
            />
            {isShowWarning.name && (
              <FormHelperText className={classes.boxError}>
                <Error className={classes.iconError} /> {getLabel(LangConstant.TXT_ADD_NEW_BOOKING_WARNING_NAME)}
              </FormHelperText>
            )}
          </Box>
        </Box>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.P_BOOKING_EMAIL)}</InputLabel>
          <OutlinedInput
            classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
            onChange={onChangeDetail}
            onKeyDown={event => onEnterInput(event, indexInput.email)}
            inputProps={{
              ref: ref => onCreateEventPusRef(ref, indexInput.email),
              name: "email",
              type: "email",
            }}
          />
        </Box>
        <Box className={classes.addFormInputTelephone}>
          <InputLabel className={classes.inputLabel}>
            {getLabel(LangConstant.P_BOOKING_PHONE)} <Typography color="primary">&nbsp; &#42;</Typography>
          </InputLabel>
          <Box>
            <OutlinedInput
              classes={{
                root: classes.contentLineBefore,
                input: classes.inputEdit,
                disabled: classes.disabled,
              }}
              onChange={onChangeDetail}
              onKeyDown={event => onEnterInput(event, indexInput.phoneNumber)}
              required
              error={isShowWarning.telephone}
              inputProps={{
                type: "tel",
                name: "phoneNumber",
                ref: ref => onCreateEventPusRef(ref, indexInput.phoneNumber),
              }}
            />
            {isShowWarning.telephone && (
              <FormHelperText className={classes.boxError}>
                <Error className={classes.iconError} /> {getLabel(LangConstant.TXT_ADD_NEW_BOOKING_WARNING_TELEPHONE)}
              </FormHelperText>
            )}
          </Box>
          <OutlinedInput
            classes={{
              root: classes.contentLineAfter,
              input: classes.inputEdit,
              disabled: classes.disabledNumberBooking,
            }}
            required
            disabled
            value={textLabelNumberBooking}
            placeholder={StringFormat(getLabel(LangConstant.TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS), 0)}
          />
        </Box>
        <FormLabel className={classes.formLabelDetail}>{getLabel(LangConstant.TXT_RESERVATION_DETAIL)}</FormLabel>
        {isSettingTotal && (
          <Box className={classes.addFormInput}>
            <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.P_BOOKING_PEOPLE_AND_CHILD)}</InputLabel>
            <OutlinedInput
              classes={{ root: classes.contentLineBefore, input: classes.inputEdit, disabled: classes.disabled }}
              inputProps={{
                name: "man",
                type: "number",
                min: 0,
                ref: ref => onCreateEventPusRef(ref, indexInput.man),
              }}
              onChange={onChangeDetail}
              onKeyDown={event => onEnterInput(event, indexInput.man)}
              required
              defaultValue={newReservation.man}
            />
            <OutlinedInput
              classes={{
                root: classes.contentLineAfter,
                input: classes.inputEdit,
                disabled: classes.disabled,
              }}
              inputProps={{
                name: "child",
                min: 0,
                type: "number",
                ref: ref => onCreateEventPusRef(ref, indexInput.child),
              }}
              onChange={onChangeDetail}
              onKeyDown={event => onEnterInput(event, indexInput.child)}
              defaultValue={newReservation.child}
            />
          </Box>
        )}
        {!isSettingTotal && (
          <Box className={classes.addFormInput}>
            <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.L_BOOKING_TOTAL)}</InputLabel>
            <OutlinedInput
              classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
              inputProps={{
                name: "totalConsumer",
                min: 1,
                type: "number",
                ref: ref => onCreateEventPusRef(ref, indexInput.totalConsumer),
              }}
              onChange={onChangeDetail}
              onKeyDown={event => onEnterInput(event, indexInput.totalConsumer)}
              required
              defaultValue={newReservation.totalConsumer}
            />
          </Box>
        )}
        <Box className={classes.timeFormInput}>
          <Box className={classes.nameTimeLabel}>
            <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.P_BOOKING_TIME)}</InputLabel>
          </Box>
          <Box className={classes.boxTimeParent}>
            <Box className={classes.boxTime}>
              <DatePickerReservation onChange={onChangeStartDate} />
              <MinuteHourTimePicker
                onChange={onChangeStartHour}
                classes={{ rootInputBox: classes.timePicker }}
                hourValue={startTimeAndHour.hour}
                minuteValue={startTimeAndHour.min}
                hourArray={timeHourArr}
                minuteArray={timeMinuteArr}
                required
              />
            </Box>
            {isSettingEndTime && (
              <Box className={classes.boxTime}>
                <DatePickerReservation onChange={onChangeEndDate} />
                <MinuteHourTimePicker
                  onChange={onChangeEndHour}
                  classes={{ rootInputBox: classes.timePicker }}
                  hourValue={endTimeAndHour.hour}
                  minuteValue={endTimeAndHour.min}
                  required
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.placeBox}>
          <Typography className={classes.titleLine}>{getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE)}</Typography>
          <MultiSelectMenu
            data={placeArrayWithBusyStatus}
            onChange={onChangeSelectedPlaces}
            // TODO hidden suggestion place because consumer request, to enable when improve logic suggestion
            // selectedArr={getUidArrayFromData(newReservation.assign)}
            customClasses={{
              root: clsx(classes.addReservationOverrideClass, classes.multiSelectMenuRoot),
            }}
          />
        </Box>
        <Box className={classes.placeBox}>
          <Typography className={classes.titleLine}>{getLabel(LangConstant.L_STAFF)}</Typography>
          <MultiSelectMenu
            data={staffArrayWithBusyStatus}
            onChange={onChangeSelectedStaff}
            customClasses={{
              root: clsx(classes.addReservationOverrideClass, classes.multiSelectMenuRoot),
            }}
          />
        </Box>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.TXT_CONSUMER_NOTE)}</InputLabel>
          <OutlinedInput
            classes={{
              root: classes.contentLineEditNote,
              input: classes.inputEditNote,
              disabled: classes.disabled,
            }}
            name="note"
            onChange={onChangeDetail}
            onKeyDown={event => onEnterInput(event, isSettingTotal ? indexInput.note : indexInput.note - 1)}
            required
            multiline={true}
            inputProps={{
              ref: ref => onCreateEventPusRef(ref, isSettingTotal ? indexInput.note : indexInput.note - 1),
            }}
          />
        </Box>
        <Box className={classes.addFormInput}>
          <InputLabel className={classes.inputLabel}>{getLabel(LangConstant.TXT_INTERNAL_NOTE)}</InputLabel>
          <OutlinedInput
            classes={{
              root: classes.contentLineEditNote,
              input: classes.inputEditNote,
              disabled: classes.disabled,
            }}
            name="internalNote"
            onChange={onChangeDetail}
            onKeyDown={event =>
              onEnterInput(event, isSettingTotal ? indexInput.internalNote : indexInput.internalNote - 1)
            }
            required
            multiline={true}
            inputProps={{
              ref: ref =>
                onCreateEventPusRef(ref, isSettingTotal ? indexInput.internalNote : indexInput.internalNote - 1),
            }}
          />
        </Box>
        <Box className={classes.addFormInput}>
          <FormLabel className={classes.formLabelDetail}>{getLabel(LangConstant.TXT_STATUS)}</FormLabel>
          <MenuStatus
            classes={{
              root: classes.boxStatus,
              label: classes.boxStatusLabel,
              paper: classes.boxStatusPaper,
              rootButton: classes.rootButtonMenu,
            }}
            style={{ backgroundColor: getStatusColor(selectedStatus) }}
            isShowIcon={false}
            onChange={onChangeActivity}
            categoryText={getLabel(LangConstant.TXT_CONFIRM)}
            arrayItemsText={getLabelArray([...new Set(arrayStatus)], getLabel, true)}
            rowNum={7}
          />
        </Box>
        <Button
          onClick={() => setIsShowAllAddBooking(!isShowAllAddBooking)}
          className={clsx(classes.optionOpenPeriodic, isShowAllAddBooking ? classes.hiddenBox : classes.periodicBox)}
        >
          <Typography className={clsx("semiBold-md-txt", classes.buttonOpenPeriodic)}>
            {getLabel(LangConstant.TXT_ADD_NEW_BOOKING_EXTEND)}
          </Typography>
          <ArrowDropDown className={classes.icon} />
        </Button>
        <Box className={isShowAllAddBooking ? classes.periodicBox : classes.hiddenBox}>
          {isDisplayPreOrder && (
            <AddPreOrderInReservation
              dataOrder={newReservation.orderDetailsList}
              depositTotal={newReservation?.depositTotal}
              onSubmit={onAddPreOrderToReservation}
              onChangePrice={onChangePriceDeposit}
            />
          )}
          <Box className={classes.addFormInput}>
            <FormLabel className={classes.formLabelDetail}>{getLabel(LangConstant.TXT_BOOK_PERIODIC)}</FormLabel>
          </Box>
          <ExtendPartAddBooking
            startTime={{
              date: startDate,
              ...startTimeAndHour,
            }}
            data={newReservation}
            timeChangeControlState={timeChangeControlState}
            setTimeChangeControlState={setTimeChangeControlState}
            isAddingPeriodic={isAddingPeriodic}
            setIsAddingPeriodic={setIsAddingPeriodic}
            setNewReservation={setNewReservation}
            isBreakRule={isBreakRule}
            setIsBreakRule={setIsBreakRule}
          />
          <Button onClick={() => setIsShowAllAddBooking(!isShowAllAddBooking)} className={classes.optionClosePeriodic}>
            <Typography className={clsx("semiBold-md-txt", classes.buttonOpenPeriodic)}>
              {getLabel(LangConstant.TXT_ADD_NEW_BOOKING_SHORT_CUT)}
            </Typography>
            <ArrowDropUp className={classes.icon} />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCloseDialog} className={classes.buttonCancel}>
          {getLabel(LangConstant.TXT_SHOP_CANCEL)}
        </Button>
        <Button type="submit" color="primary" variant="contained" onClick={onSubmit} className={classes.buttonSubmit}>
          {getLabel(LangConstant.TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddingDialog.prototype = {
  isSettingEndTime: PropTypes.bool,

  onSubmitNewReservation: PropTypes.func,
  onClose: PropTypes.func,
};

AddingDialog.defaultProps = {
  isSettingEndTime: false,
};

const DialogTitle = props => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography className="semiBold-lg-txt" classes={{ body1: classes.textDialogTitle }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close style={{ fontSize: 20, color: theme.palette.white }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const indexInput = { name: 0, email: 1, phoneNumber: 2, man: 3, child: 4, totalConsumer: 3, note: 5, internalNote: 6 };

const ARR_STATUS = [
  LangConstant.TXT_PENDING,
  LangConstant.TXT_CONFIRM,
  LangConstant.TXT_SEATED,
  LangConstant.TXT_ACTIVE,
  LangConstant.TXT_FINISHED,
];

const createDateOpt = (hours, minutes) => ({
  hours: hours,
  minutes: minutes,
  seconds: 0,
});
// TODO hidden suggestion place because consumer request, to enable when improve logic suggestion
// const getUidArrayFromData = dataPlace => {
//   let data = dataPlace.map(value => value.itemUid || value.categoryUid);
//   return data;
// };

const createDefaultReservation = () => {
  let currentDate = new Date();

  return {
    startTime: getTimestamp(removeSecond(currentDate)),
    endTime: null,
    totalConsumer: 1,
    child: 0,
    man: 1,
    note: null,
    name: null,
    email: null,
    phoneNumber: null,
    internalNote: null,
    isPeriodic: true,
    assign: [],
    service: {},
    staff: [],
    orderDetailsList: [],
    status: 2,
  };
};

const useStyles = makeStyles(theme => ({
  buttonAdd: {
    borderRadius: 2,
    minWidth: 91,
    padding: 0,
    minHeight: 30,
    backgroundColor: "#ef5845",
    border: "unset",
    color: theme.palette.white,
    textTransform: "none",
    marginLeft: 8,
    "@media only screen and (max-width: 900px)": {
      width: 54,
      height: 54,
      boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.3)",
      backgroundColor: "#f54e5e",
      borderRadius: "50%",
      position: "fixed",
      bottom: 50,
      right: 21,
      border: "none",
      minWidth: 50,
      zIndex: 1000,
      "&:hover": {
        backgroundColor: "#f54e5e",
      },
    },
  },
  startIcon: {
    marginLeft: "unset !important",
    marginRight: 4,
    color: theme.palette.white,
    "@media only screen and (max-width: 900px)": {
      marginRight: "unset !important",
      fontSize: 28,
    },
  },
  textBtn: {
    color: "inherit",
    "@media only screen and (max-width: 900px)": {
      display: "none",
    },
  },
  dialogContainer: {
    width: 580,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 0,
    "@media only screen and (max-width: 414px)": {
      width: "100",
      margin: 0,
      height: "100%",
      maxHeight: "none",
    },
  },
  dialogContainerLarge: {
    width: 580,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 0,
    "@media only screen and (max-width: 414px)": {
      width: "100",
      margin: 0,
      height: "100%",
      maxHeight: "none",
    },
  },
  dialogTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 30,
    background: "#65b39d",
    color: theme.palette.white,
    height: 40,
    padding: 0,
    zIndex: 100,
    "@media only screen and (max-width: 414px)": {
      width: "100",
    },
  },
  textDialogTitle: {
    color: theme.palette.white,
  },
  boxTimeParent: {
    display: "flex",
    width: "60%",
    flexFlow: "wrap",
  },
  boxTime: {
    display: "flex",
    width: "50%",
    [theme.breakpoints.down(550)]: {
      width: "100%",
    },
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
    color: theme.palette.grey[500],
    height: 20,
    width: 20,
    padding: 0,
  },
  buttonCancel: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: 0,
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
    backgroundColor: "#b3b5ba",
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: "#b3b5ba",
    },
  },
  buttonSubmit: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: "0 24px 0",
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
  dialogActions: {
    display: "block",
    textAlign: "right",
    padding: "15px 8px",
  },
  dialogContent: {
    maxHeight: "calc(100vh - 165px)",
    padding: "16px 30px 0",
    display: "flex",
    flexDirection: "column",
    "&:first-child": {
      "@media only screen and (max-width: 414px)": {
        padding: "26px 10px 20px",
        borderBottom: "none",
      },
    },
  },
  contentLineEdit: {
    width: "100%",
    fontSize: 13,
    padding: "4px 16px",
    height: 30,
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  contentLineBefore: {
    height: 30,
    fontSize: 13,
    padding: "4px 16px",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  contentLineAfter: {
    height: 30,
    marginLeft: 15,
    fontSize: 13,
    padding: "4px 16px",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  contentLineEditNote: {
    width: "60%",
    minHeight: 30,
    maxHeight: 62,
    fontSize: 13,
    padding: "4px 16px",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  boxStatus: {
    width: "60%",
    height: "30px !important",
    fontSize: 13,
    border: 1,
    borderRadius: 4,
  },
  boxStatusLabel: {
    color: theme.palette.white,
    justifyContent: "center !important",
  },
  boxStatusPaper: {
    width: 312,
    [theme.breakpoints.down("sm")]: {
      width: 230,
    },
  },
  menuItemStatus: {
    justifyContent: "center",
  },
  periodicBox: {
    width: "100%",
  },
  inputEditNote: {
    maxHeight: 60,
    overflow: "auto !important",
    margin: "2px 0",
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  disabledNumberBooking: {
    color: "#565c6a",
  },
  inputEdit: {
    padding: 0,
  },
  addFormInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 13,
    marginBottom: 5,
  },
  addFormInputTelephone: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 13,
    marginBottom: 5,
  },
  nameTimeLabel: {
    display: "flex",
    alignItems: "center",
    width: "40%",
    [theme.breakpoints.down(550)]: {
      alignItems: "start",
    },
  },
  timeFormInput: {
    display: "flex",
    margin: "3px 0 8px",
  },
  inputLabel: {
    display: "flex",
    minWidth: "40%",
    height: 22,
    fontSize: "inherit",
    lineHeight: 1.69,
    color: "#565c6a",
  },
  formLabel: {
    height: 24,
    marginBottom: 16,
    color: "#3e4045",
    fontSize: 14,
    lineHeight: 1.71,
  },
  formLabelDetail: {
    height: 24,
    marginBottom: 20,
    marginTop: 20,
    color: "#3e4045",
    fontSize: 14,
    lineHeight: 1.71,
  },
  datePicker: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    width: "60%",
    height: 30,
    fontSize: 13,
    padding: "4px 16px",
    display: "flex",
    alignItems: "center",
  },
  timePicker: {
    marginLeft: 8,
  },
  optionOpenPeriodic: {
    display: "flex",
    textAlign: "center",
    borderRadius: 3,
    border: "solid 1px #65b39d",
    height: 30,
  },
  optionClosePeriodic: {
    textAlign: "center",
    borderRadius: 3,
    border: "solid 1px #65b39d",
    width: "100%",
    marginTop: 15,
    height: 30,
  },
  buttonOpenPeriodic: {
    textTransform: "none",
    color: "#65b39d",
  },
  rootButtonMenu: {
    borderRadius: "4px !important",
  },
  icon: {
    width: 24,
    height: 24,
    alignItems: "center",
    color: "#65b39d",
  },
  boxError: {
    color: "#ef5845",
    display: "flex",
    paddingLeft: 10,
  },
  iconError: {
    width: 12,
    height: 12,
  },
  periodicCheckBox: {
    padding: 2,
    height: "fit-content",
  },
  hiddenBox: {
    display: "none",
  },
  checkBoxRow: {
    width: "61%",
  },
  addReservationOverrideClass: {
    width: "60%",
    margin: "0",
    marginRight: 0,
    justifyContent: "space-between",
  },
  multiSelectMenuRoot: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    width: "60%",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
  },
  titleLine: {
    minWidth: 82,
    fontSize: 13,
    display: "flex",
    alignItems: "center",
  },
  selectFormInfo: {
    display: "flex",
    width: "100%",
    height: 30,
    alignItems: "center",
    marginBottom: 5,
    justifyContent: "space-between",
  },
  placeBox: {
    display: "flex",
    textAlign: "left",
    width: "100%",
    marginBottom: 5,
    justifyContent: "space-between",
  },
}));

export default memo(AddReservation);

export const restructureStaffArray = data => ({
  staffUid: data.uid || "",
  staffName: data.name || "",
});

export const restructurePlaceArray = (data, isItem = false) => {
  if (isItem) {
    return {
      categoryUid: data.categoryUid || "",
      categoryName: data.categoryName || "",
      itemUid: data.uid || "",
      itemName: data.name || "",
    };
  } else {
    return {
      categoryUid: data.uid || "",
      categoryName: data.name || "",
    };
  }
};

const calculateTimeInDay = (newReservation, shopData) => {
  let dayInWeek = convertTimeStampToDate(newReservation.startTime).getDay();
  dayInWeek > 0 ? (dayInWeek = dayInWeek - 1) : (dayInWeek = SUN_DAY);
  let workingTime = shopData?.details.workingTime.filter(data => data.days.includes(dayInWeek))[0];
  let openHour = 0,
    closeHour = 0,
    minuteOpen = 0,
    minuteClose = 0;
  if (workingTime) {
    openHour = parseInt(workingTime.open / SECONDS_IN_HOUR);
    closeHour = parseInt(workingTime.close / SECONDS_IN_HOUR);
    minuteOpen = (workingTime.open - openHour * SECONDS_IN_HOUR) / SECONDS_IN_MINUTES;
    minuteClose = (workingTime.close - closeHour * SECONDS_IN_HOUR) / SECONDS_IN_MINUTES;
  }
  return { openHour: openHour, closeHour: closeHour, minuteOpen: minuteOpen, minuteClose: minuteClose };
};

const SECONDS_IN_MINUTES = 60;
const SECONDS_IN_HOUR = 3600;
const SUN_DAY = 6;
const MINUTE_ARR = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
