import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { ApiConstant, LangConstant } from "const";
import PlaceCategoryAction from "redux/place-category.redux";
import PlaceItemAction from "redux/place-item.redux";
import PlaceDialog from "../../MnShopInfo/components/AdvancedInfo/CategoryAccordion/PlaceDialog";
import BodyPlaceCategory from "./BodyPlaceCategory";
import { Alert } from "components";
import { getCommonKey, getError } from "utils";

const PlaceCategory = ({ data, style }) => {
  const placeCategoryRedux = useSelector(state => state.placeCategoryRedux);
  const placeCategoryFetchData = placeCategoryRedux.data;

  const placeItemStatus = useSelector(({ placeItemRedux }) => placeItemRedux.errorPatch);

  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [openPlace, setOpenPlace] = useState(false);
  const [category, setCategory] = useState(data);
  const [apiType, setApiType] = useState({});
  const [errorContent, setErrorContent] = useState();

  const dispatch = useDispatch();

  const onOpenDialog = event => {
    event.stopPropagation();
    setOpenPlace(true);
  };

  const onCloseDialog = event => {
    event.stopPropagation();
    setOpenPlace(false);
  };

  //Handle place data
  const onSubmitPlace = (event, newData) => {
    event.stopPropagation();
    if (
      newData.name !== null &&
      newData.name !== "" &&
      newData.totalAmount !== null &&
      newData.totalAmount !== "" &&
      newData.capacity !== null &&
      newData.capacity !== ""
    ) {
      event.preventDefault();
      if (placeCategoryFetchData && !placeCategoryRedux.isCreateFetching) {
        dispatch(
          PlaceCategoryAction.createPlaceCategoryItem({
            name: newData.name,
            totalAmount: newData.totalAmount,
            capacity: newData.capacity,
          }),
        );
        setApiType(API_TYPES.API_CREATE_PLACE_CATEGORY);
      }
      setOpenPlace(false);
    }
  };

  const onDeletePlace = (event, placeUid) => {
    event.stopPropagation();

    if (placeCategoryFetchData && !placeCategoryRedux.isRemoveFetching) {
      dispatch(PlaceCategoryAction.removePlaceCategory({ uid: placeUid }));
      setApiType(API_TYPES.API_DELETE_PLACE_CATEGORY);
    }
  };

  useEffect(() => {
    if (placeCategoryFetchData) {
      if (apiType === API_TYPES.API_CREATE_PLACE_CATEGORY) {
        setOpenPlace(false);
      }
      setCategory(placeCategoryFetchData);
    }
  }, [placeCategoryFetchData]);

  useEffect(() => {
    if (placeItemStatus) {
      setErrorContent(
        placeItemStatus === ApiConstant.STT_UN_PROCESSABLE_ENTITY
          ? getLabel(getCommonKey(LangConstant.ERR_LIMITED_FEATURE_NUMBER))
          : getError({ error: true, status: errorContent }),
      );

      dispatch(PlaceItemAction.resetPlaceItemStatus({ errorPatch: null }));
    }
  }, [placeItemStatus]);

  return (
    <Box className={classes.root} style={style}>
      <Paper className={classes.paper}>
        <Grid className={classes.summaryBox} container>
          <Grid item xs={2}>
            <Typography className={`medium-md-txt ${classes.placeTitle}`}>
              {getLabel(LangConstant.L_PLACE_CATEGORY)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={`medium-md-txt ${classes.amountPlace}`}>
              {getLabel(LangConstant.L_PLACE_CATEGORY_DIALOG_AMOUNT)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={`medium-md-txt ${classes.placeCapacity}`}>
              {getLabel(LangConstant.L_MAX_CLIENTS_PER_PLACE)}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.buttonContainer}>
            <Button
              color="primary"
              variant={"contained"}
              className={`medium-md-txt ${classes.createButton}`}
              onClick={e => onOpenDialog(e)}
              startIcon={<Add />}
            >
              <Typography variant="body2" classes={{ body2: "medium-md-txt" }} color={"inherit"} size="small">
                {getLabel(LangConstant.TXT_CREATE_BUTTON)}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box className={classes.details}>
        {category &&
          Object.values(category).map(key => (
            <BodyPlaceCategory key={key.uid} data={key} onDelete={onDeletePlace}></BodyPlaceCategory>
          ))}
      </Box>

      {openPlace && <PlaceDialog onClose={onCloseDialog} onSubmit={onSubmitPlace}></PlaceDialog>}
      <Alert isShow={Boolean(errorContent)} onClose={() => setErrorContent(null)} type="error" message={errorContent} />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "scroll",
    msOverflowStyle: `none`,
    scrollbarWidth: `none`,
    "&::-webkit-scrollbar": {
      display: `none`,
    },
  },
  paper: {
    width: "100%",
    flexGrow: 1,
    borderRadius: "0%",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "1060px",
    },
  },
  accordionRoot: {
    borderRadius: "0%",
  },
  summaryBox: {
    width: "100%",
    display: "flex",
    padding: "12px 16px",
    textAlign: "center",
    alignItems: "center",
  },
  details: {
    padding: "0px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "1060px",
    },
  },

  buttonContainer: {
    display: "flex",
  },

  createButton: {
    padding: 0,
    textTransform: "unset",
    minWidth: 77,
    minHeight: 30,
    marginRight: 25,
    marginLeft: "auto",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconExpand: {
    color: theme.palette.grey[500],
  },
}));

PlaceCategory.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  disableSubAccordion: PropTypes.bool,
  data: PropTypes.object,
};

PlaceCategory.defaultProps = { style: {}, disableSubAccordion: false };

const API_TYPES = {
  API_CREATE_PLACE_CATEGORY: 1,
  API_CREATE_PLACE_CATEGORY_ITEM: 2,
  API_DELETE_PLACE_CATEGORY: 3,
};

export const PLACE_CATEGORY = "Place";

export default memo(PlaceCategory);
