import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, Hidden, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { DownloadIcon } from "icons";
import { saveAs } from "file-saver";
import { BillService } from "services";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, getLabelArray } from "utils";
import { convertTimeStampToDate, getEndOfDay, getStartOfDay } from "utils/date";
import { CustomSearchBar, DatePickerWithLabelAndIcon, MenuStatus } from "components";

const BillManagementFilter = ({ filterObject, onChangeFilterObject }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);
  let statusTextArray = getLabelArray(LangConstant.VAT_MANAGEMENT_FILTER_STATUS_ARRAY, getLabel, false);

  const onChangeStartTime = date => {
    let newDate = getStartOfDay(date);
    onChangeFilterObject({ ...filterObject, startTime: newDate });
  };

  const onChangeEndTime = date => {
    let newDate = getEndOfDay(date);
    onChangeFilterObject({ ...filterObject, endTime: newDate });
  };

  const onChangeFilterByStatus = value => {
    let status = null;
    switch (LangConstant.VAT_MANAGEMENT_FILTER_STATUS_ARRAY[value]) {
      case LangConstant.TXT_WAITING_TO_PROCESS:
        status = AppConstant.STT_VAT_BILL.pending;
        break;

      case LangConstant.TXT_TRANSFERRED:
        status = AppConstant.STT_VAT_BILL.transferred;
        break;

      case LangConstant.TXT_BILL_EXPORTED:
        status = AppConstant.STT_VAT_BILL.billExported;
        break;

      default:
        status = null;
        break;
    }
    onChangeFilterObject({ ...filterObject, status: status });
  };

  const onExportFile = async () => {
    let data = {
      startTime: filterObject.startTime,
      endTime: filterObject.endTime,
    };
    let fileResponse = await BillService.exportBillList(data);
    try {
      saveAs(new Blob([fileResponse.data]), AppConstant.FILE_NAME_EXPORT_BILL);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.datePickerContainer}>
        <DatePickerWithLabelAndIcon
          label={getLabel(LangConstant.TXT_FROM)}
          selectedDateProp={convertTimeStampToDate(filterObject.startTime)}
          onChange={onChangeStartTime}
        />
        <DatePickerWithLabelAndIcon
          label={getLabel(LangConstant.TXT_TO)}
          selectedDateProp={convertTimeStampToDate(filterObject.endTime)}
          onChange={onChangeEndTime}
        />
      </Box>
      <Box className={classes.statusAndSearchFilter}>
        <Box className={classes.statusAndExportBox}>
          <MenuStatus
            categoryText={getLabel(getCommonKey(LangConstant.TXT_STATUS))}
            arrayItemsText={statusTextArray}
            includeDefault={true}
            titleDefault={getLabel(getCommonKey(LangConstant.TXT_STATUS))}
            value={filterObject.status}
            onChange={onChangeFilterByStatus}
            classes={{
              root: classes.menuStatusRoot,
              item: classes.menuItem,
              rootButton: classes.statusButtonRoot,
            }}
          />
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              className={clsx(classes.exportButton, "medium-md-txt")}
              startIcon={<DownloadIcon />}
            >
              {getLabel(getCommonKey(LangConstant.TXT_EXPORT_FILE))}
            </Button>
          </Hidden>
        </Box>
        <CustomSearchBar
          className={classes.customSearch}
          placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
          onChange={value => onChangeFilterObject({ ...filterObject, filter: value || "" })}
        />
      </Box>
      <Hidden xsDown>
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.exportButton, "medium-md-txt")}
          startIcon={<DownloadIcon />}
          onClick={onExportFile}
        >
          {getLabel(getCommonKey(LangConstant.TXT_EXPORT_FILE))}
        </Button>
      </Hidden>
    </Box>
  );
};

BillManagementFilter.propTypes = {
  filterObject: PropTypes.shape({
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    paging: PropTypes.number,
    page: PropTypes.number,

    filter: PropTypes.string,

    status: PropTypes.any,
  }),

  onChangeFilterObject: PropTypes.func,
};

BillManagementFilter.defaultProps = {
  filterObject: {
    startTime: Date.now(),
    endTime: Date.now(),
    paging: 1,
    page: 1,

    filter: "",

    status: null,
  },

  onChangeFilterObject: () => {},
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 60,
    width: "100%",
    backgroundColor: theme.palette.white,
    display: "flex",
    padding: 15,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.between(0, 375)]: {
      flexDirection: "column",
    },
  },

  datePickerContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "&>:first-child": {
        marginBottom: 10,
      },
      "&>div": {
        justifyContent: "space-between",
        width: "100%",
        "&>p": {
          width: "max-content",
        },
      },
    },
  },

  menuStatusRoot: {
    width: "100%",
    maxWidth: "fit-content",
    minWidth: 137,
    height: "100%",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: 30,
    },
    [theme.breakpoints.between(0, 375)]: {
      marginLeft: 0,
    },
  },

  statusButtonRoot: {
    borderRadius: 3,
    border: "1px solid #D4D5D8",
    width: "max-content",
    minWidth: "100%",
  },

  menuItem: {
    height: 30,
    minHeight: 30,
  },

  customSearch: {
    border: "1px solid #D4D5D8",
    borderRadius: 3,
    marginLeft: 24,
    marginRight: 24,
    width: "100%",
    height: 30,
    "&>input::placeholder": {
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      marginRight: 0,
      width: "-webkit-fill-available",
    },
    [theme.breakpoints.between(0, 375)]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },

  exportButton: {
    borderRadius: 2,
    height: 30,
    width: "100%",
    maxWidth: 105,
    minHeight: "unset",
    textTransform: "none",
    padding: 6,
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },

  statusAndSearchFilter: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
    [theme.breakpoints.between(0, 375)]: {
      marginTop: 10,
    },
  },

  statusAndExportBox: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    },
  },
}));

export default BillManagementFilter;
