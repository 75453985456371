import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import ItemDetailsContentAction from "redux/item-details-content.redux";
import { ItemDetailsContentService } from "services";
import { toCamel, toSnake } from "utils";

export function* postItemDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsContentService.postItemDetailsContent, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsContentAction.postItemDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsContentAction.postItemDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsContentAction.postItemDetailsContentFailure(error));
  }
}

export function* putItemDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsContentService.putItemDetailsContent, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsContentAction.putItemDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsContentAction.putItemDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsContentAction.putItemDetailsContentFailure(error));
  }
}

export function* getListItemDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsContentService.getListItemDetailsContent, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsContentAction.getListItemDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsContentAction.getListItemDetailsContentFailure(responseData));
    }
  } catch (error) {
    yield put(ItemDetailsContentAction.getListItemDetailsContentFailure(error));
  }
}

export function* getItemDetailsContentByUid(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsContentService.getItemDetailsContentByUid, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsContentAction.getItemDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsContentAction.getItemDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsContentAction.getItemDetailsContentFailure(error));
  }
}

export function* deleteItemDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsContentService.deleteItemDetailsContent, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsContentAction.deleteItemDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsContentAction.deleteItemDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsContentAction.deleteItemDetailsContentFailure(error));
  }
}
