import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { SearchInPeriod, PrimaryCustomDatePicker } from "components";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import ReportChartHeader from "./ReportChartHeader";
import { createQueryParamForReport } from "utils";
import { DEFAULT_QUERY_CHART } from "../index";

const ReportHeader = ({ title, onShow, onChangeQueryParams, defaultQueryParams }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);

  const [isShow, setIsShow] = useState(true);
  const [queryParams, setQueryParams] = useState(defaultQueryParams || DEFAULT_QUERY_CHART);
  const [selectedDate, setSelectedDate] = useState({ from: new Date(), to: new Date() });

  const onControlDisplay = () => {
    setIsShow(!isShow);
    onShow();
  };

  const onChangeViewBy = viewMode => {
    let newQuery = { ...queryParams };
    newQuery.view = viewMode + 1;
    setQueryParams(createQueryParamForReport(newQuery));
    onChangeQueryParams(newQuery);
  };

  const onChangeDate = data => {
    let newData = data || {};
    let newQuery = { ...queryParams };
    newQuery.start = newData.from;
    newQuery.end = newData.to;
    setSelectedDate(newData);
    setQueryParams(newQuery);
    onChangeQueryParams(newQuery);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography className={clsx("semiBold-md-txt", classes.title)}>{title}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} className={classes.actionRoot}>
          <SearchInPeriod onChange={onChangeViewBy} buttonTitles={LangConstant.ARR_REPORT_BUTTON_TITLE} />
          <PrimaryCustomDatePicker
            dateRange={selectedDate}
            onChange={onChangeDate}
            isPickRangeDate={true}
            onClickToday={onChangeDate}
          />
          <Button
            variant="outlined"
            size="small"
            startIcon={isShow ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            onClick={onControlDisplay}
            className={`${classes.actionVisible} medium-md-txt `}
          >
            {isShow ? getLabel(LangConstant.TXT_HIDDEN) : getLabel(LangConstant.TXT_SHOW)}
          </Button>
        </Grid>
      </Grid>
      {isShow && <ReportChartHeader startDate={queryParams.start} endDate={queryParams.end} />}
    </>
  );
};

ReportHeader.propTypes = {
  title: PropTypes.string,
  defaultQueryParams: PropTypes.object,

  onShow: PropTypes.func,
  onChangeQueryParams: PropTypes.func,
};
export default ReportHeader;

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.grey[800],
  },
  actionRoot: {
    justifyContent: "flex-end",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: { justifyContent: "flex-start", marginTop: 8 },
    "& > *": {
      height: 30,
      [theme.breakpoints.down("sm")]: { marginTop: 8 },
    },
    "& > *:not(:last-child)": {
      marginRight: 8,
    },
  },
  actionVisible: {
    minWidth: 70,
    borderRadius: 0,
    padding: 8,
    borderColor: theme.palette.grey[200],
    textTransform: "unset",
  },
}));
