import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import { enUS, vi } from "date-fns/locale";
import { endOfDay, format, isBefore, isSameDay, isWithinInterval, startOfDay } from "date-fns";
import clsx from "clsx";
import { Box, Button, IconButton, makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LangConstant, AppConstant } from "const";

const PrimaryCalendar = ({ dateRange, onChange, isPickRangeDate, ...otherProps }) => {
  const classes = useStyles();
  const { i18n, t: getLabel } = useTranslation();

  const [startDate, setStartDate] = useState(startOfDay(dateRange.from) || new Date());
  const [endDate, setEndDate] = useState(endOfDay(dateRange.to) || new Date());
  const [calendarValue, setCalendarValue] = useState(new Date());

  const onSubmit = () => {
    onChange({
      from: startDate,
      to: endDate || startDate,
    });
    setStartDate(null);
    setEndDate(null);
  };

  const sortDateObject = (date, valueDate) => {
    let result = {};
    if (isBefore(date, valueDate) || Boolean(startDate && endDate)) {
      result = {
        from: date,
        to: null,
      };
    } else if (!endDate) {
      result = {
        from: valueDate,
        to: isSameDay(date, valueDate) ? null : date,
      };
    }
    return result;
  };

  const onChangeDate = date => {
    if (isPickRangeDate) {
      let dateObject = sortDateObject(date, startDate);
      if (!isSameDay(startDate, date)) {
        setStartDate(dateObject.from);
        setEndDate(dateObject.to);
      }
    } else {
      setStartDate(date);
      setEndDate(null);
    }
    setCalendarValue(date);
  };

  const renderWrappedWeekDay = (date, _, dayInCurrentMonth) => {
    let dayIsBetween, wrapperClassName, dayClassName, isFirstDay, isLastDay, dayBetweenInterval;
    let isCurrent = isSameDay(date, new Date());

    if (startDate && endDate) {
      const start = startDate;
      const end = endDate;

      dayIsBetween = isWithinInterval(date, { start: start, end: end });
      isFirstDay = isSameDay(date, start);
      isLastDay = isSameDay(date, end);
      wrapperClassName = clsx({
        [classes.firstHighlight]: isFirstDay,
        [classes.endHighlight]: isLastDay,
      });
    }

    dayBetweenInterval = clsx(classes.dayBetweenInterval, {
      [isCurrent ? classes.currentInsideInterval : classes.highlight]: dayIsBetween,
      [classes.firstDay && (isSameDay(startDate, new Date()) || isSameDay(startDate, endDate))
        ? classes.backgroundNone
        : classes.backgroundDayBetweenRight]: isFirstDay,
      [classes.lastDay && classes.backgroundDayBetweenLeft]: isLastDay,
    });

    dayClassName = clsx(classes.day, "medium-md-txt", {
      [classes.isPicked]: isSameDay(date, startDate || endDate),
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [dayIsBetween ||
      isFirstDay ||
      isLastDay ||
      isSameDay(date, startDate && startDate) ||
      isSameDay(date, endDate && endDate)
        ? ""
        : classes.currentHighlight]: isCurrent,
    });

    return (
      <Box className={dayBetweenInterval}>
        <IconButton className={dayClassName} disabled={!dayInCurrentMonth}>
          <Box className={wrapperClassName}>
            <span> {format(date, "d")} </span>
          </Box>
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    if (startDate && endDate) {
      setCalendarValue(startDate);
    }
  }, [startDate]);

  return (
    <>
      <Box className="range-datepicker-root">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
          <DatePicker
            disableToolbar
            variant="static"
            format={AppConstant.FM_DD_MM_YYYY}
            value={calendarValue || null}
            renderDay={renderWrappedWeekDay}
            onChange={onChangeDate}
            {...otherProps}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box className={classes.submitTimeRangeBox}>
        <Button
          className={clsx(classes.submitSelectedTimeRangedBtn, "medium-md-txt")}
          variant="contained"
          onClick={onSubmit}
          color="primary"
        >
          {getLabel(LangConstant.TXT_SELECT_SHOP_CHOOSE)}
        </Button>
      </Box>
    </>
  );
};

PrimaryCalendar.propTypes = {
  dateRange: PropTypes.shape({
    from: PropTypes.objectOf(Date),
    to: PropTypes.objectOf(Date),
  }),
  onChange: PropTypes.func,
  isPickRangeDate: PropTypes.bool,
};

PrimaryCalendar.defaultProps = {
  dateRange: {
    from: new Date(),
    to: new Date(),
  },
  onChange: () => {},
  isPickRangeDate: false,
};

export default PrimaryCalendar;

const localeMap = {};

const months = [
  "Tháng 01 năm",
  "Tháng 02 năm",
  "Tháng 03 năm",
  "Tháng 04 năm",
  "Tháng 05 năm",
  "Tháng 06 năm",
  "Tháng 07 năm",
  "Tháng 08 năm",
  "Tháng 09 năm",
  "Tháng 10 năm",
  "Tháng 11 năm",
  "Tháng 12 năm",
];

const days = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];

const customVILocal = {
  localize: {
    day: (dirtyIndex, dirtyOptions) => {
      let strDay = days[dirtyIndex] || "";
      let options = dirtyOptions || {};
      if (options.width && "short" === options.width && strDay.length > 0) {
        return strDay.charAt(0) + strDay.charAt(strDay.length - 1);
      }
      return strDay;
    },
    month: n => months[n],
  },
};

localeMap[LangConstant.EN_LANG] = enUS;
localeMap[LangConstant.DEFAULT_LANG] = { ...vi, ...customVILocal };

const useStyles = makeStyles(theme => ({
  day: {
    width: 36,
    height: 36,
    color: "#6c7078",
    padding: "2px 0",
  },
  nonCurrentMonthDay: {
    "&:disabled": {
      color: "transparent",
    },
  },
  currentInsideInterval: {
    background: "#f1f3f6",
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&>span": {
      width: 24,
      height: 24,
      backgroundColor: "#65b39d",
      color: theme.palette.white,
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },
  highlight: {
    background: "#f1f3f6",
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 0px",
  },
  firstDay: {
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 0px",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  lastDay: {
    width: "inherit",
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "6px 0px",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  backgroundDayBetweenLeft: {
    background: "linear-gradient(to left , transparent 50%, #f1f3f6 50%)",
  },
  backgroundDayBetweenRight: {
    background: "linear-gradient(to right , transparent 50%, #f1f3f6 50%)",
  },
  backgroundNone: {
    background: "none",
  },
  firstHighlight: {
    "&>span": {
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
    },
  },
  endHighlight: {
    "&>span": {
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
    },
  },
  isPicked: {
    "&>span:first-child": {
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 24,
      height: 24,
    },
  },
  currentHighlight: {
    "&>span:first-child": {
      width: 24,
      height: 24,
      backgroundColor: "#65b39d",
      color: theme.palette.white,
      borderRadius: "50%",
    },
  },
  submitSelectedTimeRangedBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    width: 68,
    minHeight: 30,
    textTransform: "none",
    borderRadius: 2,
  },
  submitTimeRangeBox: {
    marginTop: 12,
    display: "flex",
    justifyContent: "center",
    marginBottom: 15,
  },
  dayBetweenInterval: {
    width: "100%",
    textAlign: "center",
  },
}));
