import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestReportConsumer: ["data"],
  reportConsumerSuccess: ["data"],
  reportConsumerFailure: ["data"],
});

export const ReportConsumerTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,

  isReportConsumer: false,
  data: null,
};

/* ------------- Reducers ------------- */
export const requestReportConsumer = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isReportConsumer: null,
});

export const reportConsumerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isReportConsumer: true,
    data: action.data,
  };
};

export const reportConsumerFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_REPORT_CONSUMER]: requestReportConsumer,
  [Types.REPORT_CONSUMER_SUCCESS]: reportConsumerSuccess,
  [Types.REPORT_CONSUMER_FAILURE]: reportConsumerFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
