import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";

const CustomStepButton = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);

  const remoteOrderStep = 1;

  const isDoneStep = buttonIndex => {
    return buttonIndex <= remoteOrderStep;
  };
  let mapArr = [...AppConstant.STEP_ARR_ORDER_PAGE];

  const goToStep = () => {};

  return (
    <Box className={classes.boxRoot}>
      <Box className={classes.gridContainer}>
        {mapArr.map((textKey, index) => (
          <Box
            key={index}
            className={clsx(
              classes.pointerBtn,
              "semiBold-sm-txt",
              isDoneStep(index) ? classes.pointerBtnDone : classes.pointerBtnPending,
              createClassWithIndex(index, classes),
              remoteOrderStep > index && classes.pointerBtnDoneCurrent,
            )}
            onClick={goToStep}
          >
            <Typography className={classes.arrowBtnLabel}>
              {index + 1}. {getLabel(LangConstant[textKey])}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default memo(CustomStepButton);

const useStyles = makeStyles(theme => ({
  boxRoot: {
    width: "100%",
    marginBottom: 25,
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(20px, 1fr))",
  },
  pointerBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
  },

  pointerBtnPending: {
    background: theme.palette.grey[200],
    "&:before": {
      borderLeftColor: `${theme.palette.grey[200]} !important`,
    },
  },
  pointerBtnDone: {
    cursor: "pointer",
    background: theme.palette.grey[600],
    "&:before": {
      borderLeftColor: `${theme.palette.grey[600]} !important`,
    },
  },
  pointerBtnDoneCurrent: {
    cursor: "pointer",
    background: theme.palette.grey[600],
    "&:before": {
      borderLeftColor: `${theme.palette.grey[600]} !important`,
    },
    "&:hover": {
      background: theme.palette.grey[800],
      "&:before": {
        borderLeftColor: `${theme.palette.grey[800]} !important`,
      },
    },
  },
  firstPointerBtn: {
    height: 40,
    position: "relative",
    zIndex: 5,
    marginRight: 5,
    "&::before": {
      content: '""',
      position: "absolute",
      right: "-25px",
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "25px solid",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "0px solid white",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
  },
  lastPointerBtn: {
    height: 40,
    position: "relative",
    zIndex: 3,
    paddingLeft: 20,
    "&::before": {
      content: '""',
      position: "absolute",
      right: "-25px",
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "0px solid",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "25px solid white",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
  },
  arrowBtnLabel: {
    color: theme.palette.common.white,
    fontSize: "inherit",
    fontWeight: "inherit",
  },
}));

const createClassWithIndex = (index, classes) => {
  let className;
  switch (index) {
    case 0:
      className = classes.firstPointerBtn;
      break;
    case 1:
      className = classes.lastPointerBtn;
      break;
    default:
      className = classes.middlePointerBtn;
      break;
  }
  return className;
};
