export default {
  TXT_LOGIN: "Đăng nhập",
  TXT_PENDING: "Chờ xử lý",
  TXT_CONFIRM: "Xác nhận",
  TXT_SEATED: "Đã đến",
  TXT_ACTIVE: "Đang dùng",
  TXT_FINISHED: "Đã xong",
  TXT_ACTIVE_ORDER: "Đang giao",
  TXT_FINISHED_ORDER: "Đã giao",
  TXT_CANCEL: "Khách huỷ",
  TXT_REJECT: "Từ chối",
  TXT_CHANGE_STT: "Chuyển trạng thái",
  TXT_SAVE: "Lưu",
  TXT_DETAIL_INFO: "Thông tin chi tiết",
  TXT_ADD: "Thêm",
  TXT_ADD_NEW: "Thêm mới",
  TXT_ADD_NEW_PAGE: "Thêm trang",
  TEXT_ADD_NEW: "Thêm mới",
  TXT_EXPORT_FILE: "Xuất file",
  TXT_ADMINISTRATIVE: "Hành chính",
  TXT_COPYRIGHT: "Copyright © bGlobal Jsc. All Rights Reserved",
  TXT_SAVE_INFO: "Lưu thông tin đăng nhập của bạn",
  TXT_FORGOT_PASSWORD: "Quên mật khẩu?",
  TXT_SEND: "Gửi",
  TXT_MON: "Thứ 2",
  TXT_TUE: "Thứ 3",
  TXT_WED: "Thứ 4",
  TXT_THU: "Thứ 5",
  TXT_FRI: "Thứ 6",
  TXT_SAT: "Thứ 7",
  TXT_SUN: "Chủ nhật",
  TXT_MON_SHORT: "T2",
  TXT_TUE_SHORT: "T3",
  TXT_WED_SHORT: "T4",
  TXT_THU_SHORT: "T5",
  TXT_FRI_SHORT: "T6",
  TXT_SAT_SHORT: "T7",
  TXT_SUN_SHORT: "CN",
  TXT_DATE: "Ngày",
  TXT_WEEK: "Tuần",
  TXT_MONTH: "Tháng",
  TXT_STATUS: "Trạng thái",
  TXT_TIME: "Thời gian",
  TXT_RESERVATION_COUNT: "Số lượt đặt",
  TXT_ACCOUNT_STATUS: "Tài khoản",
  TXT_CONSUMER_NUMBER: "Số khách",
  TXT_NAME: "Tên khách hàng",
  TXT_FULLNAME: "Họ và tên",
  TXT_PHONE_NUMBER: "Số điện thoại",
  TXT_ACCOUNT_SETTING: "Quản lý tài khoản",
  TXT_LOGOUT: "Đăng xuất",
  TXT_ADD_IMAGE: "Thêm ảnh",
  TXT_DELETE_IMAGE: "Xóa ảnh",
  TXT_USER_NAME: "Tên tài khoản",
  TXT_EMAIL: "Email",
  TXT_SEX: "Giới tính",
  TXT_BIRTH_DAY: "Ngày sinh",
  TXT_NONE: "None",
  TXT_MAN: "Nam",
  TXT_WOMAN: "Nữ",
  TXT_DAY: "Ngày",
  TXT_DAY_LOWERCASE: "ngày",
  TXT_H: "h",
  TXT_MIN: "min",
  TXT_YEAR: "Năm",
  TXT_ACCEPT: "Xác nhận",
  TXT_UPDATE: "Cập nhật",
  TXT_WARNING_PASS:
    "Sử dụng sự kết hợp giữa các chữ cái, số và ký tự đặc biệt để giúp cho mật khẩu của bạn được bảo mật hơn.",
  TXT_OLD_PASS: "Mật khẩu hiện tại",
  TXT_NEW_PASS: "Mật khẩu mới",
  TXT_CONFIRM_PASS: "Nhập lại mật khẩu mới",
  TXT_PASS_WORD: "Mật khẩu",
  TXT_SHOP_INFO: "THÔNG TIN CỬA HÀNG",
  TXT_SHOP_NAME: "Tên cửa hàng",
  TXT_SHOP_AVATA: "Ảnh đại diện",
  TXT_SHOP_AVATA_HOLDER: "Tải ảnh đại diện của bạn lên",
  TXT_SHOP_UPLOAD: "Tải",
  TXT_SHOP_WELCOME_SPEECH: "Giới thiệu",
  TXT_SHOP_WELCOME_SPEECH_HOLDER:
    "Một số thông tin thêm về nhà hàng như lời chào, một số quy định (giờ mở/đóng, phí mang đồ uống vào, chỗ để xe…)",
  TXT_SHOP_ADDRESS: "Địa chỉ ",
  TXT_SHOP_PHONE: "Số điện thoại",
  TXT_SHOP_SAVE: "Lưu",
  TXT_SHOP_CANCEL: "Hủy",
  TXT_BOOK_BASE: "THÔNG TIN ĐẶT CHỖ CƠ BẢN",
  TXT_BOOK_SETTING_PERSON: "Số người",
  TXT_BOOK_DAY: "Ngày đặt",
  TXT_BOOK_TIME: "Thời gian",
  TXT_BOOK_HELPER_NOTE: "Hướng dẫn ghi chú",
  TXT_BOOK_HELPER_NOTE_HOLDER:
    "Nếu bạn có bất kỳ yêu cầu đặc biệt nào, xin vui lòng cho chúng tôi biết bằng cách viết vào ô bên dưới đây, chúng tôi sẽ cố gắng để phục vụ bạn tốt nhất. Ví dụ : ngồi phòng điều hoà, cần chỗ để ô tô,...",
  TXT_BOOK_NOTE: "Ghi chú",
  TXT_BOOK_SETTING_PERSON_DETAIL: "Người lớn / trẻ em",
  TXT_BOOK_PERIODIC: "Đặt định kỳ",
  TXT_BOOK_POSTITION_TYPE: "Loại vị trí",
  TXT_BOOK_SERVICE_TYPE: "Loại dịch vụ",
  TXT_BOOK_USED_TIME: "Thời gian sử dụng",
  TXT_BOOK_ADVANCE: "Thông tin nâng cao",
  TXT_BOOK_PREVIEW: "Thông tin xem trước",
  TXT_BOOK_PREVIEW_HELPER: "Vui lòng nhập các thông tin về lịch hẹn của bạn",
  TXT_BOOK_PREVIEW_PERSON: "Số người",
  TXT_BOOK_PREVIEW_PARENT: "Số người lớn",
  TXT_BOOK_PREVIEW_CHILD: "Số trẻ em",
  TXT_BOOK_PREVIEW_SERVICE: "Dịch vụ",
  TXT_BOOK_PREVIEW_PLACE: "Vị trí",
  TXT_BOOK_PREVIEW_PERIODIC_NOTHING: "Không lặp lại",
  TXT_BOOK_PREVIEW_PERIODIC_DAY: "Lặp lại mỗi ngày",
  TXT_BOOK_PREVIEW_PERIODIC_WEEK: "Lặp lại mỗi tuần",
  TXT_BOOK_PREVIEW_PERIODIC_MONTH: "Lặp lại mỗi tháng",
  TXT_NOTIFICATION_TITLE: "Thông báo",
  TXT_NOTIFICATION_DETAIL_TITLE: "Thông tin đơn hàng",
  TXT_NOTIFICATION_DETAIL_CONS_NAME: "Tên người đặt:",
  TXT_NOTIFICATION_DETAIL_CODE: "Mã đặt hàng:",
  TXT_NOTIFICATION_DETAIL_PHONE: "Số điện thoại:",
  TXT_NOTIFICATION_DETAIL_PARENT: "Số người lớn:",
  TXT_NOTIFICATION_DETAIL_CHILD: "Số trẻ em:",
  TXT_NOTIFICATION_DETAIL_DATE: "Ngày đặt:",
  TXT_NOTIFICATION_DETAIL_TIME: "Thời gian:",
  TXT_NOTIFICATION_DETAIL_NOTE: "Ghi chú:",
  TXT_NOTIFICATION_SUBJECT: "Bạn có đơn đặt hàng mới từ ",
  TXT_NOTIFICATION_CONSUMER: "Khách hàng",
  TXT_REGISTERED: "Đã đăng ký",
  TXT_UNREGISTERED: "Chưa đăng ký",
  TXT_HAVE_ACCOUNT: "Có tài khoản",
  TXT_HAVE_NOT_ACCOUNT: "Chưa có tài khoản",
  TXT_SEE_MORE: "Xem thêm",
  TXT_DONT_HAVE_LIST_VIEW: "Không có danh sách hiển thị",
  TXT_SEEN_DETAIL: "Xem chi tiết",
  TXT_JOINED: "Đã tham gia",
  TXT_HAVE_NOT_EMAIL: "Chưa có email",
  TXT_OTHER: "Khác",
  TXT_COPY_RESERVATION: "Sao chép đơn đặt chỗ",
  TXT_HAPPENS_EVERY: "Lặp lại",
  TXT_ADD_REPEATS: "Số lần lặp",
  TXT_WAITING_PAYMENT: "Chờ thanh toán",
  TXT_TIMES: "Lần",
  TXT_BOOK_SETTING_TIME_DEFAULT: "Khung thời gian mặc định",
  TXT_BOOK_SETTING_TIME: "Cài đặt khung thời gian",
  TXT_BOOK_SETTING_BOOKING_INFO: "Thông tin Booking",
  TXT_BOOKING_SETTING_IMAGE_COVER: "Ảnh bìa",
  TXT_BOOKING_SETTING_OTHER_INFORMATION: "Thông tin khác",
  TXT_BOOKING_SETTING_SHOP_OPEN: "Thời gian mở cửa",
  TXT_TODAY: "Hôm nay",
  TXT_CLEAR_FILTER: "Bỏ lọc",
  TXT_ADD_NEW_BOOKING: "Thêm mới đơn đặt chỗ",
  TXT_ADD_NEW_BOOKING_WARNING_NAME: "Bạn chưa điền tên người đặt",
  TXT_ADD_NEW_BOOKING_WARNING_TELEPHONE: "Số điện thoại chưa đúng",
  TXT_ADD_NEW_BOOKING_NUMBER_OF_BOOKINGS: "Số lượt đặt : {0}",
  TXT_ADD_NEW_BOOKING_EXTEND: "Xem thêm",
  TXT_ADD_NEW_BOOKING_SHORT_CUT: "Rút gọn",
  TXT_TOPPING_PRODUCT_ACCOMPANIED: "Hàng hóa đi kèm",

  FM_SERVICE_TIME: "{{ time }}/lần",

  // Waiting list
  TXT_WAITING_LIST: "Khách chờ",
  MSG_MOVE_INTO_WAITING_LIST_TITLE: 'Chuyển đơn đặt chỗ "{0}" sang danh sách khách chờ không?',
  MSG_MOVE_INTO_WAITING_LIST_CONTENT: 'Bạn có chắc muốn chuyển đơn đặt chỗ "{0}" sang danh sách khách chờ không?',
  MSG_MOVE_INTO_WAITING_LIST_SUCCESS: 'Đơn đặt chỗ "{0}" đã được chuyển sang danh sách khách chờ',
  MSG_MOVE_INTO_WAITING_LIST_FAILURE: 'Đơn đặt chỗ "{0}" chưa chuyển thành công sang danh sách chờ',
  TXT_LATE: "Đến muộn",
  TXT_SOON: "Đến sớm",

  // Dialog setting
  TXT_NOTIFICATION_SETTING: "Cài đặt thông báo",
  TXT_BOOKING: "Booking",
  TXT_NEWS: "Tin tức",
  TXT_NOTIFICATION_RESEVERVATION_NEW: "Thông báo đơn đặt chỗ mới",
  TXT_NOTIFICATION_CHANGE_BOOKING: "Thông báo thay đổi trạng thái đơn đặt chỗ",
  TXT_NOTIFICATION_CHANGE_RESERVATION: " Thông báo thay đổi thông tin đơn đặt chỗ",
  TXT_NOTIFICATION_ADD_NEWS: "Thông báo thêm tin tức mới",
  TXT_SETTING: "Cài đặt",
  TXT_MARK_ALL_READ: "Đánh dấu tất cả là đã đọc",
  TXT_RESERVATION: "Đặt chỗ",
  TXT_PERIODIC: "Định kỳ",
  TXT_EXTEND: "Mở rộng",
  TXT_DONT_ASSIGN: "Chưa chỉ định",
  TXT_SHOP_INFO_SIDEBAR: "Thông tin cửa hàng",
  TXT_RESOURCES: "Tài nguyên",
  TXT_CALENDAR: "Quản lý đặt chỗ",
  TXT_MENU: "Menu",
  TXT_PAYMENT: "Thanh toán",
  TXT_PEOPLE: "người",
  TXT_CALENDER_SETUP: "Cài đặt trang quản lý đặt chỗ",
  TXT_TIME_FRAME: "Khung thời gian",
  TXT_MAXIMUM_PEOPLE: "Số khách tối đa",
  TXT_INFORMATION_DISPLAYED: "Thông tin hiển thị",
  TXT_PREVIEW_ON_DESKTOP: "PREVIEW ON DESKTOP",
  TXT_SETTING_BOOKING: " Cài đặt trang khách hàng",

  // Select shop
  TXT_SELECT_SHOP_MANAGER: "Quản lý",
  TXT_SELECT_SHOP_STAFF: "Nhân viên",
  TXT_SELECT_SHOP_CHOOSE: "Chọn",
  //Shop info
  TXT_SHOP_INFO_HEADER: "THÔNG TIN CỬA HÀNG",
  TXT_SHOP_BUSINESS_CODE: "Mã số doanh nghiệp",
  TXT_SHOP_BRANCH_NAME: "Tên thương hiệu",
  TXT_SHOP_PHONE_NUMBER: "Số điện thoại",
  TXT_SHOP_EMAIL: "Email",
  TXT_SHOP_WEBSITE: "Website",
  TXT_SHOP_FACEBOOK: "Facebook",
  TXT_SHOP_GMB: "GMB",
  TXT_LINK_SHOP_CONSUMER: "Đường dẫn trang đặt chỗ",
  TXT_SAVE_BUTTON: "Lưu",
  TXT_SETTING_BUTTON: "Cài đặt",
  TXT_CHANGING_SETTING_WORKING_TIME: "Thay đổi",
  //Social link
  TXT_LINK: "Liên kết",
  TXT_FACEBOOK_LINK: "Liên kết tài khoản Facebook",
  TXT_GBOSS: "Gboss",
  TXT_FACEBOOK: "Facebook",
  TXT_SHOP_FACEBOOK_DISMOUNT: "Chuyển đổi",
  TXT_SHOP_FACEBOOK_MOUNTSTATUS_MOUNTED: "Hủy liên kết",
  TXT_SHOP_FACEBOOK_MOUNTSTATUS_UNMOUNTED: "Liên kết",
  TXT_FACEBOOK_PAGE_LIST: "Chọn trang mà bạn sẽ liên kết",
  TXT_EDIT: "Chỉnh sửa",
  TXT_SHORT_EDIT: "Sửa",
  TXT_REMOVED: "Gỡ bỏ",
  TXT_DEL_POST: "Xóa bài viết",
  //Basic info
  TXT_BASIC_INFO_HEADER: "Thông tin cơ bản",
  TXT_MONDAY: "Thứ 2",
  TXT_TUESDAY: "Thứ 3",
  TXT_WEDNESDAY: "Thứ 4",
  TXT_THURSDAY: "Thứ 5",
  TXT_FRIDAY: "Thứ 6",
  TXT_SATURDAY: "Thứ 7",
  TXT_SUNDAY: "Chủ nhật",
  TXT_STARTDATE: "Ngày bắt đầu",
  TXT_WORKSHIFT: "Giờ làm việc",
  TXT_STAFFCODE: "Mã nhân viên",
  TXT_MANAGER: "Quản lý",
  TXT_GENDER: "Giới tính",
  TXT_STOPDATE: "Ngày kết thúc",
  TXT_STAFF_STATUS_ACTIVE: "Đang làm việc",
  TXT_STAFF_STATUS_INACTIVE: "Ngừng làm việc",
  TXT_CREATE_NEW_STAFF: "Tạo thêm nhân sự",
  TXT_STAFF_DETAIL: "Chi tiết nhân sự",
  TXT_STAFF_EDITING: "Chỉnh sửa nhân sự",
  TXT_PLACE_CODE: "Mã đặt chỗ",
  TXT_AVAILABLE: "Khả dụng",
  TXT_wORKING_TIME: "Thời gian làm việc",
  TXT_SETTING_WORKING_TIME: "Cài đặt thời gian làm việc",
  TXT_START_TIME_PICKER: "từ lúc",
  TXT_END_TIME_PICKER: "đến",
  TXT_ADD_CUSTOM_WORKING_TIME: "Thêm khung giờ",
  TXT_REMOVE_CUSTOM_WORKING_TIME: "Xoá khung giờ",
  TXT_WORKING_TIME_ILLUSTRATE: "Ngày làm việc",
  TXT_BREAK_TIME_ILLUSTRATE: "Ngày nghỉ",
  FM_INVALID_TIME: "Chọn giờ đóng cửa lớn hơn giờ mở cửa ở khối {0}",
  //Advanced info
  TXT_ADVANCED_INFO_HEADER: "Thông tin nâng cao",
  TXT_CREATE_BUTTON: "Tạo",
  TXT_AGREE: "Có",
  TXT_DISAGREE: "Không",
  TXT_MINUTES: "Phút",
  TXT_HOURS: "Giờ",
  //Staff-setting
  TXT_UPDATE_LOCATION: "Cập nhật nhiệm vụ",
  TXT_UPDATE_WORK_TIME: "Cập nhật ca làm việc ",
  TXT_DELETE_WORK_TIME: "Xóa ca làm việc?",
  TXT_DELETE_WORK_TIME_QUESTION: "Bạn có chắc muốn xóa ca làm việc này không?",
  TXT_WORK_TIME: "Ca làm việc",
  TXT_DELETE_WORK_POSITION: "Xóa chức vụ?",
  TXT_DELETE_WORK_POSITION_QUESTION: "Bạn có chắc muốn xóa chức vụ này không?",
  TXT_STAFF_SETTING: "Cài đặt nhân viên",
  TXT_POSITISON: "Chức vụ ",
  TXT_DELETE_STAFF_POPUP_TITLE: 'Xoá nhân viên "{0}"?',
  TXT_DELETE_STAFF_MSG: 'Bạn có chắc muốn xoá toàn bộ thông tin của nhân viên "{0}" không?',

  TXT_SEND_INVITE: "Mời thêm",
  TXT_FILTER_ACCOUNT: "Lọc tài khoản",
  TXT_INVITE_EMAIL: "Mời mọi người qua email",
  TXT_INVITE_FACEBOOK: "Mời bạn bè qua facebook",
  TXT_INVITE_LINK: "Sao chép đường link",
  TXT_LIST_INVITE_FACEBOOK: "Danh sách cần mời",
  TXT_TEXTED_FACEBOOK: "(Đã từng nhắn tin trao đổi với bạn qua fanpage)",
  TXT_INVITE_ALL: "Mời tất cả",
  TXT_ALL: "Tất cả",
  TXT_INVITE: "Mời",
  TXT_INVITED: "Đã mời",
  TXT_SEND_INVITATION: "Gửi lời mời",
  TXT_INVITE_CONTINUE: "Mời tiếp",
  TXT_COPIED: "Đã sao chép",
  TXT_COPY: "Sao chép",
  TXT_CONFIRM_INVITE_ALL: "Xác nhận mời tất cả?",
  TXT_STATUS_INVITE_ALL: "Bạn có chắc chắn muốn thêm tất cả những người này trở thành khách hàng của bạn?",
  TXT_CONFIRM_INVITE: "Xác nhận mời?",
  TXT_STATUS_INVITE: "Bạn có chắc chắn muốn mời người này trở thành khách hàng của bạn?",
  TXT_INVITE_TO_TEAM_STAFF: "Mời mọi người tham gia vào đội ngũ nhân viên",
  TXT_NEXT: "Tiếp",
  TXT_LINK_UPGRADE: "gia hạn hoặc nâng gói",

  //Staff
  TXT_BEING_ACTIVE: "Đang làm việc",
  TXT_STOP_WORKING: "Ngừng làm việc",
  TXT_ROLE: "Chức vụ",
  TXT_MANAGE: "Quản lý",
  TXT_STAFF: "Nhân viên",
  TXT_TIME_IN_WORKS: "Hành chính",
  TXT_MORNING: "Sáng",
  TXT_AFTERNOON: "Chiều",
  TXT_NIGHT: "Tối",
  TXT_BROKEN_CASE: "Ca gãy",
  TXT_POSITION: "Nhiệm vụ",
  TXT_CHEF: "Bếp trưởng",
  TXT_SOUS_CHEF: "Bếp phó",
  TXT_VICE_COOK: "Phụ bếp",
  TXT_WAITER: "Chạy bàn",
  TXT_FULL_NAME: "Họ và tên",
  TXT_STARTED_DATE: "Ngày bắt đầu",
  TXT_CODE_STAFF: "Mã nhân viên",
  TXT_ADJUST_DISPLAY_COLUMN: "Điều chỉnh cột hiển thị",
  TXT_RETURN_DEFAULT: "Quay về mặc định",
  TXT_CHOOSE_ALL: "Chọn tất cả",
  TXT_DELETE: "Xóa",
  TXT_WORKING_HOUR: "Số giờ làm việc",
  TXT_NOTIFICATION_MESSAGE: "Thay đổi của bạn sẽ không được lưu?",
  TXT_ASSIGN_TO_MYSELF: "Chỉ định cho tôi",

  //News Manage
  TXT_NEWS_POSTUPLOAD: "Thêm tin tức",
  TXT_NEWS_POSTUPLOAD_INSTRUCTION: "Tối đa 5 ảnh và có dung lượng dưới 2MB",
  TXT_NEWS_POSTUPLOAD_PLACEHOLDER: "Hãy nhập nội dung cho tin tức của bạn",
  TXT_NEWS_POSTLIST_POSTEDITBTN: "Chỉnh sửa",
  TXT_NEWS_POSTLIST_POSTDELETEBTN: "Xóa tin",
  TXT_NEWS_POSTUPLOADNAV_MOBILEBTN: "✛Thêm",
  TXT_POST_DEL_CONFIRM_DIALOG:
    "Bạn có chắc muốn xoá bài viết này không? (Bạn có thể chỉnh sửa tin nếu chỉ cần thay đổi gì đó)",
  TXT_FACEBOOK_SYNC: "Đồng bộ với Facebook?",
  TXT_SYNC_CONFIRM: "Đồng bộ",
  TXT_SYNC_CONFIRM_MESSAGE: "Bạn có chắc chắn muốn đồng bộ bài viết này lên trang Facebook {0}?",
  TXT_OFFSYNC_GBOSS: "Ẩn bài viết trên Gboss?",
  TXT_OFFSYNC_CONFIRM_MESSAGE: "Bạn có chắc chắn muốn ẩn bài viết này khỏi trang Gboss của mình không?",
  TXT_HIDE_BTN: "Ẩn",
  TXT_LINK_TO_FACEBOOK_CF: "Liên kết với facebook?",
  TXT_LINK_FB_CONFIRM_MESSAGE:
    "Bạn cần liên kết với facebook của bạn để sử dụng chức năng đồng bộ với facebook. Bạn có muốn liên kết facebook không?",
  TXT_CONFIRM_DELETE_DIALOG_TITLE: `Bạn có muốn xóa "{0}"?`,
  TXT_CONFIRM_DELETE_DIALOG_TITLE_CONFIRM: `Bạn có chắc chắn muốn xóa "{0}"?`,

  TXT_FIRST_LAST_NAME: "Họ và tên",
  TXT_STAFF_WORKING_TIME: "Giờ làm việc",
  TXT_STAFF_CODE: "Mã nhân viên",
  TXT_SERVING_TIMES: "Số lượt phục vụ",
  TXT_BOOKING_PAGE: "Trang khách hàng",
  TXT_LIST: "Danh sách",
  TXT_STATISTIC: "Thống kê",
  TXT_STAFF_KPI: "Chấm công",
  TXT_REPORT_SUMMARY: "Tổng quát",
  TXT_RANGE_TIME_PICKER: "Thời gian hiển thị",
  TXT_MANAGE_PACKAGE: "Quản lý gói",
  TXT_MANAGE_SUBSCRIPTION: "Đăng ký",
  TXT_MANAGE_UPGRADE_PLAN: "Nâng gói",
  TXT_MANAGE_PAYMENT_HISTORY: "Lịch sử thanh toán",

  TXT_ELLIPSIS: "...",
  TXT_CANCEL_ACTION: "Hủy",
  TXT_CONFIRM_ALL: "Xác nhận tất cả",

  TXT_EMPTY_NOTICE_MESSAGE: "Bạn chưa có thông báo mới",
  TXT_EMPTY_NEW_BOOKING_NOTICE_MESSAGE: "Bạn chưa có lịch đặt chỗ mới",

  TXT_SWITCH_TEAM: "Chuyển shop",

  //Manage product
  TXT_MASTER_PRODUCT: "Sản phẩm",
  TXT_SERVICE: "Dịch vụ",
  TXT_PRODUCT: "Hàng hóa",
  TXT_COMBO: "Combo",
  TXT_CATEGORY: "Danh mục",
  TXT_RESULT_NOT_FOUND: "Không có kết quả",
  TXT_UPLOAD_IMAGE: "Tải ảnh lên",
  TXT_UPLOAD_IMAGE_INSTRUCTION: "Tối đa 4 ảnh và dung lượng mỗi ảnh dưới 2MB",
  TXT_COPY_LINK_TO_PRODUCT: "Sao chép liên kết tới sản phẩm",

  //Menu
  TXT_MENU_SETUP: "Cài đặt Menu",
  TXT_HIDE_PRICE: "Hiển thị giá",
  TXT_HIDE_TAX: "Hiển thị thuế",
  TXT_HIDE_USED_TIME: "Hiển thị thời gian sử dụng",
  TXT_ADD_COMBO_MENU: "Thêm Combo vào menu",
  TXT_ADD_SERVICE_MENU: "Thêm dịch vụ vào menu",
  TXT_ADD_GOODS_MENU: "Thêm hàng hóa  vào menu",
  TXT_SELECT_PRODUCT_TYPE: "Chọn loại sản phẩm",
  TXT_SELECT_ADD_SERVICE: "Chọn kiểu thêm dịch vụ",
  TXT_SELECT_ADD_PRODUCT: "Chọn kiểu thêm hàng hóa",
  TXT_SELECT_ADD_ALL_SERVICE: "Thêm từ danh sách tất cả dịch vụ",
  TXT_SELECT_ADD_ALL_PRODUCT: "Thêm từ danh sách tất cả hàng hóa",
  TXT_SELECT_ADD_CATEGORY_SERVICE: "Thêm từ danh mục dịch vụ",
  TXT_SELECT_ADD_CATEGORY_PRODUCT: "Thêm từ danh mục hàng hóa",
  TXT_SELECT_CATEGORY: "Chọn danh mục",
  TXT_ADD_ALL: "  Thêm tất cả",
  TXT_NO_TEXT_NOTE: "không có ghi chú",

  L_BOOKING_NAME: "Tên người đặt:",
  L_BOOKING_CODE: "Mã đặt hàng:",
  L_BOOKING_PHONE: "Số điện thoại:",
  L_BOOKING_PEOPLE: "Số người lớn:",
  L_BOOKING_CHILDREN: "Số trẻ em:",
  L_BOOKING_TOTAL: "Số người",
  L_BOOKING_DATE: "Ngày đặt:",
  L_BOOKING_TIME: "Thời gian:",
  L_BOOKING_SERVICE: "Dịch vụ:",
  L_BOOKING_ASSIGN: "Vị trí:",
  L_BOOKING_STATUS: "Trạng thái:",
  L_BOOKING_NOTE: "Ghi chú:",
  L_INTERNAL_NOTE: " Ghi chú nội bộ:",
  L_BOOKING_EMAIL: "Email:",
  L_CAPACITY: "Số khách tối đa",
  L_WORKING_TIME: "Thời gian làm việc",
  L_OPEN_TIME: "Giờ mở cửa",
  L_CLOSE_TIME: "Giờ đóng cửa",
  L_PLACE_CATEGORY: "Loại vị trí",
  L_SERVICE_CATEGORY: "Loại dịch vụ",
  L_PLACE_AMOUNT: "Số lượng",
  L_PLACE_CAPACITY: "Số khách tối đa",
  L_PLACE_CATEGORY_DIALOG_NAME: "Tên loại vị trí",
  L_PLACE_CATEGORY_DIALOG_AMOUNT: "Số lượng",
  L_PLACE_CATEGORY_DIALOG_CAPACITY: "Số khách",
  L_SERVICE_CATEGORY_DIALOG_NAME: "Tên loại",
  L_SERVICE_CATEGORY_DIALOG_TIME: "Thời gian",
  L_SERVICE_CATEGORY_DIALOG_DESCRIPTION: "Mô tả",
  L_EDIT_DIALOG_NAME: "Mã vị trí",
  L_EDIT_DIALOG_CAPACITY: "Số khách",
  L_STAFF: "Nhân viên",
  L_MAX_CLIENTS_PER_PLACE: "Số khách tối đa",

  P_BOOKING_NAME: "Tên người đặt",
  P_BOOKING_CODE: "Mã đặt hàng",
  P_BOOKING_PHONE: "Số điện thoại",
  P_BOOKING_EMAIL: "Email",
  P_BOOKING_PEOPLE: "Số người lớn",
  P_BOOKING_CHILDREN: "Số trẻ em",
  P_BOOKING_PEOPLE_AND_CHILD: "Số người lớn / trẻ em",
  P_BOOKING_TIME: "Thời gian",
  P_BOOKING_NOTE: "Ghi chú",
  P_BOOKING_ARRIVAL_DATE: "Ngày đến",
  P_BOOKING_SEARCH: "Tìm kiếm",
  P_EMAIL: "Nhập email",
  P_USERNAME: "Nhập email",
  P_PASSWORD: "Nhập password",
  P_BOOK_PREVIEW_PERIODIC_SELECT: "Số lần",
  P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT: "lần",
  P_PLACE_CATEGORY_DIALOG_NAME: "Tên loại vị trí",
  P_PLACE_CATEGORY_DIALOG_AMOUNT: "Số lượng",
  P_PLACE_CATEGORY_DIALOG_CAPACITY: "Số khách",
  P_SERVICE_CATEGORY_DIALOG_NAME: "Tên loại dịch vụ",
  P_SERVICE_CATEGORY_DIALOG_TIME: "30",
  P_SERVICE_CATEGORY_DIALOG_DESCRIPTION: "Viết mô tả cho dịch vụ",
  P_EDIT_DIALOG_NAME: "VITRI01",
  P_EDIT_DIALOG_CAPACITY: "20",
  P_DAY: "dd/mm/yyyy",
  P_TIME: "hh:mm",
  P_UNIT: "Đơn vị",

  T_PLACE_CATEGORY_DIALOG: "Tạo loại vị trí",
  T_PLACE_EDIT_CATEGORY_DIALOG: "Chỉnh sửa vị trí",
  T_CONFIRM_DIALOG: "Tạo nhanh mã cho loại vị trí",
  T_SERVICE_CATEGORY_DIALOG: "Tạo loại dịch vụ",

  FN_PAGINATION: (from, to, count) => `Hiển thị kết quả từ ${from} - ${to} trên tổng ${count}`,
  FN_INVITE_CONSUMER: nameShop => `Mời trở thành khách hàng của ${nameShop} trên Gboss`,
  FN_LIMITED_INVITE: (count, limit) => `Bạn còn ${count}/${limit} lượt mời trong hôm nay`,
  FN_INVITE_SUCCESS: (count, limit) => `Bạn đã gửi thành công ${count}/${limit} lời mời`,
  FN_INVITE_STAFF: name => `Mời ${name} tham gia vào hệ thống nhân viên?`,
  FN_FAILURE_INVITATION: `{0} lời mời thất bại do đã mời hoặc vượt mức giới hạn mời hàng ngày`,
  FN_NOTI_INVITE_STAFF: number => `Mời thêm ${number} người vào đội ngũ nhân viên`,
  FN_CONFIRM_DIALOG: table => `Bạn có muốn tạo nhanh mã cho loại vị trí ${table}?`,
  FN_DELETE_CATEGORY_MENU_TITLE: `Xóa danh mục {0}?`,
  FN_DELETE_CATEGORY_MENU_CONTENT: `Bạn có chắc muốn xoá danh mục {0} khỏi danh sách danh mục Menu không?`,

  MSG_WELCOME: `Chào mừng bạn đến với GBoss`,
  MSG_WELCOME_LINE2: ` Đặt và quản lý lịch hẹn giờ đây thật dễ dàng!`,
  MSG_FORGOT_PASSWORD_REQUIRE: `Vui lòng cung cấp email đăng ký tài khoản của bạn.
  Chúng tôi sẽ gửi cho bạn một email, bạn hãy kiểm tra
  và làm theo hướng dẫn để lấy lại mật khẩu.`,
  MSG_BACK_LOGIN_PAGE: "Quay lại màn hình ",
  MSG_UPDATE_SUCCESS: "Cập nhật thông tin thành công",
  MSG_LIMIT_CREATE_ITEM: "Đã tạo đủ số lượng, không thể tạo thêm!",

  ERR_LOGIN: "Username/ password không chính xác",
  ERR_EMAIL: "Vui lòng kiểm tra email của bạn",
  ERR_PASSWORD: "Mật khẩu bao gồm ít nhất 8 ký tự",
  ERR_PASSWORD_DIFF: "Mật khẩu xác nhận không khớp",
  ERR_EXCEPTION: "Xuất hiện lỗi không mong muốn!",
  ERR_MISSING_REQUIRED_FIELD: "Trường bắt buộc không được để trống",
  ERR_INVALID_DAY: "Ngày không hợp lệ",
  ERR_INVALID_PHONE: "Số điện thoại không hợp lệ",
  ERR_INVALID_DAY_OR_TIME: "Ngày hoặc giờ không hợp lệ",
  ERR_BAD_REQUEST: "Vui lòng kiểm tra lại thông tin.",
  ERR_NOT_FOUND: "Không tìm thấy thông tin.",
  ERR_LIMITED_FEATURE_NUMBER: "Vượt quá số lượng của gói bạn đang sử dụng",

  //Create Position Staff Dialog
  T_CREATE_POSITION_STAFF_DIALOG: "Thêm nhiệm vụ",
  T_CREATE_POSITION_STAFF_ADD: "Thêm",
  L_CREATE_POSITION_STAFF_INPUT: "Tên nhiệm vụ",
  T_CREATE_POSITION_STAFF_TITLE: "Tên nhiệm vụ",

  //Create Working Shift Dialog
  T_CREATE_WORKING_SHIFT_STAFF_TITLE: "Thêm ca làm việc",
  L_CREATE_WORKING_SHIFT_STAFF_HOURS: "Giờ",
  L_CREATE_WORKING_SHIFT_STAFF_MINUTE: "Phút",
  T_CREATE_WORKING_SHIFT_STAFF_START: "Giờ đến",
  T_CREATE_WORKING_SHIFT_STAFF_END: "Giờ về",
  T_CREATE_WORKING_SHIFT_STAFF_NAME: "Tên gọi",
  L_CREATE_WORKING_SHIFT_STAFF_NAME: "Ca sáng (8:00 - 16:00)",

  FM_NOTIFICATION_SUBJECT: `<p>Bạn có đơn đặt hàng mới từ <span style="font-weight:bold;">{0}</span></p>`,
  FM_NOTIFICATION_SUBJECT_NEWS: `<p><span style="font-weight:bold;">{0}</span> đã thêm một tin mới trên Gboss</p>`,
  FM_NOTIFICATION_SUBJECT_RESERVATION_INFO_UPDATE: `<p><span style="font-weight:bold;">{0}</span> đã thay đổi thông tin của đơn đặt chỗ {1}</p>`,
  FM_NOTIFICATION_SUBJECT_RESERVATION_STATUS_UPDATE: `<p><span style="font-weight:bold;">{0}</span> đã chuyển trạng thái của đơn đặt chỗ <span style="font-weight:bold;">{1}</span> thành <span style="font-weight:bold;">{2}</span></p>`,
  FM_NOTIFICATION_SUBJECT_ROLE_UPDATE: `<p><span style="font-weight:bold;">{0}</span> đã thay đổi chức vụ của bạn thành <span style="font-weight:bold;">{3}</span></p>`,
  FM_NOTIFICATION_SUBJECT_RESERVATION_ASSIGN: `<p><span style="font-weight:bold;">{0}</span> đã chỉ định bạn vào đơn đặt chỗ <span style="font-weight:bold;">{1}</span></p>`,
  FM_NOTIFICATION_SUBJECT_RESERVATION_PAYMENT_FOR_SCHEDULING: `<p><span style="font-weight:bold;">{0}</span> đã thanh toán cho đặt lịch <span style="font-weight:bold;">{1}</span></p>`,
  FM_NEW_CREATED_BY_DAY: "{0} ngày trước",
  FM_NEW_CREATED_BY_HOUR: "{0} giờ trước",
  FM_NEW_CREATED_BY_MINUTE: "{0} phút trước",
  FM_EXTEND_RESERVATION: `Mở rộng đơn đặt chỗ "{0}"`,
  FM_RESERVATION_PERIODIC: `Đơn đặt chỗ định kỳ ({0})`,
  FM_RESERVATION_EXTEND: `Đơn đặt chỗ mở rộng ({0})`,
  FM_DAY_LABEL: `Ngày {0}`,
  FM_DATE_INPUT: `{0}, {1}/{2}/{3}`,
  FM_USE_TIME_IN_HOUR: `{0}h{1}`,
  FM_EXPIRED_PAYMENT_PACKAGE: "Gói cước của bạn sẽ hết hạn vào ngày {0}. Hãy",
  FM_EXPIRED_PAYMENT_FEATURES: "Kì thanh toán tính năng của bạn sẽ hết hạn vào ngày {0}. Hãy",
  FM_EXPIRED_PAYMENT_USING_FEATURES: " để tiếp tục sử dụng dịch vụ của chúng tôi.",

  TXT_NEW_CREATED_BY_SECOND: "Vừa xong",

  TXT_SHOP_OWNER: "Chủ sở hữu",
  TXT_SHOP_MANAGER: "Quản lý",
  TXT_SHOP_STAFF: "Nhân viên",
  TXT_SHOP_CONSUMER: "Khách hàng",

  MSG_SHOP_SELECT: `Chọn một thương hiệu để tiếp tục`,
  MSG_SHOP_SELECT_SMALL: `Bạn đang đăng nhập với tài khoản`,
  MSG_SHOP_SELECT_CONVERT: `Chuyển đổi`,
  MSG_LOGIN_WITH_SOCIAL: `Hoặc đăng nhập với:`,

  TXT_INVALID_BOOKING_TIME_ALERT: "Thời gian đặt bàn phải cách ít nhất 15' tính từ thời điểm hiện tại.",
  TXT_INVALID_EMAIL_ALERT: "Địa chỉ email không đúng định dạng.",
  TXT_INVALID_PHONE_ALERT: "Số điện thoại không đúng định dạng.",
  TXT_CONSUMER_NUMBER_REQUIRE: "Yêu cầu nhập đầy đủ thông tin về số người.",

  TXT_CONSUMER_INFORMATION: "Thông tin khách hàng",
  TXT_RESERVATION_DETAIL: "Thông tin đặt chỗ",
  TXT_CONSUMER_NOTE: "Ghi chú khách hàng",
  TXT_INTERNAL_NOTE: "Ghi chú nội bộ",
  TXT_BOOKING_HISTORY: "Lịch sử đặt chỗ",

  TXT_CHOOSE: "Chọn",
  TXT_ACTIVITY_HISTORY: "Lịch sử hành động",
  TXT_BGLOBAL: "bGlobal",

  //Crop image component
  TXT_EDIT_IMAGE: "Chỉnh sửa ảnh",
  TXT_SAVE_CROP: "Lưu chỉnh sửa",
  TXT_REQUIRE_SAVE_MESSAGE: "Chỉnh sửa ảnh chưa được lưu. Lưu ảnh đã cắt?",
  TXT_IMAGE: "Ảnh",
  TXT_CROP_IMAGE: "Cắt ảnh",

  TXT_ASSIGN_TO_ME: "Chỉ định cho bạn",

  TXT_REALITY_START_TIME: "Thời gian bắt đầu",
  TXT_REALITY_END_TIME: "Thời gian kết thúc",

  TXT_OK: "OK",
  TXT_BUSY_AT_THIS_TIME: "Đang bận",

  WARN_IMAGE_SIZE_SMALLER_THAN_X: "Vui lòng sử dụng kích thước ảnh < {0}MB",
  /* Category */
  TXT_ADD_CATEGORY: "Thêm danh mục mới",
  TXT_ADD_NEW_CATEGORY: "Thêm mới danh mục",
  TXT_EDIT_CATEGORY: "Chỉnh sửa danh mục ",
  TXT_CATEGORY_NAME: "Tên danh mục",
  TXT_SERVICE_NAME: "Tên dịch vụ",
  TXT_PRODUCT_NAME: "Tên hàng hóa",
  TXT_PRICE: "Giá bán",
  TXT_STOCK: "Kho hàng",

  TXT_EXCEEDED_CAPACITY_UPLOAD_IMAGE:
    "Ảnh của bạn có dung lượng vượt quá dung lượng cho phép là 2MB. Vui lòng giảm dung lượng ảnh bằng cách cắt ảnh hoặc chọn ảnh khác có dung lượng phù hợp.",
  TXT_CURRENCY_COMMON: "đ",
  TXT_TOTAL_MONEY: "Tổng tiền",
  TXT_PRE_PAYMENT: "Trả trước",
  TXT_SEND_REQUEST_PAYMENT: "Gửi yêu cầu thanh toán",

  FM_ITEM_STOCK: "Kho hàng: {{ stock }}",

  // Pre Order
  TXT_EDIT_FROM_MENU: "Chỉnh sửa từ Menu",
  TXT_EDIT_FROM_CATEGORY: "Chỉnh sửa từ Danh mục sản phẩm",
  TXT_CHOOSED: "Đã chọn",
  TXT_BOOK_SCHEDULE: "Đặt lịch",

  // Setting components start
  TXT_BOOKING_TAB: "Đặt chỗ",
  TXT_ORDER_TAB: "Đặt hàng",
  // Setting components end

  // Package payment start
  TXT_PACKAGE_PAYMENT_NOTICE: "Gói của bạn hiện tại chưa được thanh toán. Hãy",
  TXT_FEATURES_PAYMENT_NOTICE: "Các chức năng mới thêm của bạn chưa được thanh toán. Hãy",
  // Package payement end
};
