import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { onDoNothing } from "utils";

const StepButton = ({ currentStep }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);

  const isDoneStep = buttonIndex => {
    return buttonIndex <= currentStep - 1;
  };

  return (
    <Box className={classes.boxRoot}>
      <Box className={classes.gridContainer}>
        {STEP_ARR.map((textKey, index) => (
          <Box
            key={index}
            className={clsx(
              classes.pointerBtn,
              "semiBold-sm-txt",
              isDoneStep(index) ? classes.pointerBtnDone : classes.pointerBtnPending,
              createClassWithIndex(index, classes),
              currentStep > index + 1 && classes.pointerBtnDoneCurrent,
            )}
            onClick={onDoNothing}
          >
            <Typography className={classes.arrowBtnLabel}>
              {index + 1}. {getLabel(LangConstant[textKey])}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

StepButton.propTypes = {
  currentStep: PropTypes.number,
};

StepButton.defaultProps = {
  currentStep: 0,
};

const STEP_ARR = ["TXT_SCHEDULE", "TXT_CONTACT", "TXT_CONFIRM_PREVIEW"];

export default memo(StepButton);

const useStyles = makeStyles(theme => ({
  boxRoot: {
    width: "100%",
    marginBottom: 25,
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(20px, 1fr))",
  },
  pointerBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
  },

  pointerBtnPending: {
    background: theme.palette.grey[200],
    "&:before": {
      borderLeftColor: `${theme.palette.grey[200]} !important`,
    },
  },
  pointerBtnDone: {
    cursor: "pointer",
    background: theme.palette.grey[600],
    "&:before": {
      borderLeftColor: `${theme.palette.grey[600]} !important`,
    },
  },
  pointerBtnDoneCurrent: {
    cursor: "pointer",
    background: theme.palette.grey[600],
    "&:before": {
      borderLeftColor: `${theme.palette.grey[600]} !important`,
    },
    "&:hover": {
      background: theme.palette.grey[800],
      "&:before": {
        borderLeftColor: `${theme.palette.grey[800]} !important`,
      },
    },
  },
  firstPointerBtn: {
    height: 40,
    position: "relative",
    zIndex: 5,
    marginRight: 5,
    "&::before": {
      content: '""',
      position: "absolute",
      right: "-25px",
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "25px solid",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "0px solid white",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
  },
  middlePointerBtn: {
    height: 40,
    position: "relative",
    zIndex: 4,
    marginRight: "5px",
    paddingLeft: 20,
    "&::before": {
      content: '""',
      position: "absolute",
      right: "-25px",
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "25px solid",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "25px solid white",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
  },
  lastPointerBtn: {
    height: 40,
    position: "relative",
    zIndex: 3,
    paddingLeft: 20,
    "&::before": {
      content: '""',
      position: "absolute",
      right: "-25px",
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "0px solid",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      bottom: 0,
      width: 0,
      height: 0,
      borderLeft: "25px solid white",
      borderTop: "20px solid transparent",
      borderBottom: "20px solid transparent",
    },
  },
  arrowBtnLabel: {
    color: theme.palette.common.white,
    fontSize: "inherit",
    fontWeight: "inherit",
  },
}));

const createClassWithIndex = (index, classes) => {
  let className;
  switch (index) {
    case 0:
      className = classes.firstPointerBtn;
      break;
    case 1:
      className = classes.middlePointerBtn;
      break;
    case 2:
      className = classes.lastPointerBtn;
      break;
    default:
      className = classes.middlePointerBtn;
      break;
  }
  return className;
};
