import React, { useEffect, useState } from "react";
import { makeStyles, Box, TextareaAutosize, Button } from "@material-ui/core";
import InfiniteCarousel from "react-leaf-carousel";
import { ApiConstant, LangConstant } from "../../../const";
import { useTranslation } from "react-i18next";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import MnNewsAction from "../../../redux/mn-news.redux";

const PostContent = props => {
  const { postImage, uid, onEditHandler } = props;
  const { t: getLabel } = useTranslation();
  const [value, setValue] = useState(props.postContent);
  const [updatedData, setUpdateData] = useState({});
  const dispatch = useDispatch();
  const onEdit = e => {
    setValue(e.target.value);
    setUpdateData({ content: value });
  };
  useEffect(() => {
    setUpdateData(value);
  }, [value]);
  const classes = useStyles();
  const showFooterStatus = props.editing ? classes.hiddenContainer : classes.editingInputContainer;
  const updatePost = () => {
    setUpdateData(value);
    dispatch(
      MnNewsAction.updateNews({
        data: {
          content: updatedData,
        },
        uid: uid,
      }),
    );
  };
  return (
    <Box className={classes.contentContainerText}>
      <Box className={`${props.editing ? classes.staticContainer : classes.editingContainer}`}>
        <TextareaAutosize
          value={value}
          className={`${props.editing ? classes.postEditInput : classes.postEditInputEditing} regular-md-txt`}
          readOnly={props.editing}
          onChange={onEdit}
        />
        <Box className={`${showFooterStatus}`}>
          <Box className={classes.editButtonContainer}>
            <Button
              className={classes.saveEdit}
              onClick={v => {
                onEditHandler(v);
                updatePost();
              }}
            >
              {getLabel(LangConstant.TXT_SAVE)}
            </Button>

            <Button
              className={classes.cancelEdit}
              onClick={v => {
                setValue(props.postContent);
                onEditHandler(v);
              }}
            >
              {getLabel(LangConstant.TXT_SHOP_CANCEL)}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <InfiniteCarousel
          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
          ]}
          dots={true}
          showSides={false}
          sidesOpacity={0.5}
          sideSize={1}
          slidesToScroll={1}
          slidesToShow={1}
          scrollOnDevice={true}
          slidesSpacing={0}
          prevArrow={<NavigateBefore className={classes.carouselPrevIcon} />}
          nextArrow={<NavigateNext className={classes.carouselNextIcon} />}
        >
          {postImage.map(data => {
            return (
              <img
                key={data}
                src={data !== [] ? `${ApiConstant.HOST_ATTACHMENT}${data}` : ""}
                alt="postimage"
                className={classes.contentImage}
              />
            );
          })}
        </InfiniteCarousel>
      </Box>
    </Box>
  );
};
export default PostContent;

const useStyles = makeStyles(theme => ({
  contentContainerText: {
    textAlign: "left",
    maxWidth: "100%",
  },
  contentContainer: {
    textAlign: "left",
    maxWidth: "100%",
    backgroundColor: "#B3B5BA",
  },
  contentImage: {
    maxWidth: "100%",
  },
  postEditInput: {
    width: "100%",
    resize: "none",
    padding: "1rem",
    fontFamily: theme.typography.fontFamily,
    marginLeft: "-1vw",
    border: "unset",
    "&:focus": {
      border: "unset",
      outline: "unset",
    },
  },
  postEditInputEditing: {
    width: "100%",
    resize: "none",
    padding: "1rem",
    fontFamily: theme.typography.fontFamily,
    border: "unset",
    "&:focus": {
      border: "unset",
      outline: "unset",
    },
  },
  editingInputContainer: {
    display: "block",
    height: "fit-content",
  },
  hiddenContainer: {
    display: "none",
    height: "0px",
  },
  saveEdit: {
    border: "unset",
    minHeight: "35px",
    float: "right",
    backgroundColor: "#fafafb",
    color: theme.palette.reservation.finished,
    padding: "1vh",
    marginRight: "1vw",
    textTransform: "none",
    fontWeight: theme.typography.h5.fontWeight,
  },
  cancelEdit: {
    border: "unset",
    minHeight: "35px",
    backgroundColor: "#fafafb",
    color: "#7f838c",
    padding: "1vh",
    marginRight: "1vw",
    textTransform: "none",
    fontWeight: theme.typography.h5.fontWeight,
  },
  editingContainer: {
    border: " 1px solid #447aff",
    paddingTop: "1vh",
  },
  staticContainer: {
    height: "fit-content",
  },
  editButtonContainer: {
    background: "#fafafb",
    display: "flex",
    flexDirection: "row-reverse",
    margin: 0,
    border: "1px solid #ebeced",
  },
  carouselPrevIcon: {
    position: "absolute",
    top: "50%",
    left: "1.5vw",
    background: "#3e4045",
    borderRadius: "50%",
    zIndex: 1,
    height: 48,
    width: 48,
    color: "#ffffff",
    cursor: "pointer",
    padding: "6px",
    boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    opacity: 0.8,
    [theme.breakpoints.down("sm")]: {
      height: 30,
      width: 30,
    },
  },
  carouselNextIcon: {
    background: "#3e4045",
    position: "absolute",
    top: "50%",
    right: "1.5vw",
    borderRadius: "50%",
    zIndex: 1,
    height: 48,
    width: 48,
    color: "#ffffff",
    cursor: "pointer",
    padding: "6px",
    boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.16)",
    opacity: 0.8,
    [theme.breakpoints.down("sm")]: {
      height: 30,
      width: 30,
    },
  },
}));
