import { createApiRegistration } from "api";
import { ApiConstant } from "const";
import StringFormat from "string-format";

export const getRatingItem = data => {
  let formattedData = { ...data };
  formattedData = formatArrayToRequest(formattedData);
  return createApiRegistration().get(ApiConstant.GET_RATING_ITEM, formattedData);
};

export const getRatingShop = data => {
  let formattedData = { ...data };
  formattedData = formatArrayToRequest(formattedData);
  return createApiRegistration().get(ApiConstant.GET_RATING_SHOP, formattedData);
};

export const getRatingItemSummary = data => {
  let formattedData = { ...data };
  formattedData = formatArrayToRequest(formattedData);
  return createApiRegistration().get(ApiConstant.GET_RATING_ITEM_SUMMARY, formattedData);
};

export const getRatingShopSummary = data => {
  let formattedData = { ...data };
  formattedData = formatArrayToRequest(formattedData);
  return createApiRegistration().get(ApiConstant.GET_RATING_SHOP_SUMMARY, formattedData);
};

export const reportRating = uid => createApiRegistration().post(StringFormat(ApiConstant.REPORT_RATING, uid));

const formatArrayToRequest = data => {
  let result = { ...data };
  for (const [key, value] of Object.entries(result)) {
    if (Array.isArray(value)) {
      if (value.length > 0) result[key] = value.toString();
      else delete result[key];
    }
  }
  return result;
};
