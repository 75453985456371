import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, OutlinedInput } from "@material-ui/core";

const Input = forwardRef((props, ref) => {
  const { onChange, classes, ...otherProps } = props;
  const defaultClasses = useStyles();

  const onTyping = event => {
    if (Boolean(onChange)) {
      onChange(event.target.value, event);
    }
  };

  return (
    <OutlinedInput
      fullWidth
      type="text"
      onChange={onTyping}
      classes={{
        ...classes,
        root: `${defaultClasses.root} ${classes.root}`,
        input: `${defaultClasses.input} ${classes.input}`,
      }}
      ref={ref}
      {...otherProps}
    />
  );
});

Input.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.any,
  classes: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
};
Input.defaultProps = { classes: { root: "", input: "" } };

export default memo(Input);

const useStyles = makeStyles({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: "8px",
    marginBottom: "8px",
  },
  input: {
    padding: "16px 0 16px 8px",
    "&.Mui-disabled": {
      cursor: "no-drop",
    },
  },
});
