import React, { memo, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { LangConstant } from "../../../../const";
import { useTranslation } from "react-i18next";
import { MenuBody } from "../index";
import { useDispatch } from "react-redux";
import StaffTableAction from "../../../../redux/staff.redux";

const PopupSuccess = props => {
  const { open, setOpen, email, name, role, uid, onChangeInvite } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [index, setIndex] = useState(null);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = (email, role) => {
    setOpen(false);
    onChangeInvite(uid);
    dispatch(StaffTableAction.patchInvitationStaff({ staffs: [{ email: email, role: role }] }));
  };

  const onChange = index => {
    setIndex(index);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogTitle>{getLabel(LangConstant.FN_INVITE_STAFF)(name)}</DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Box className={classes.parentContent}>
            <Typography className={classes.typoContent}>{email}</Typography>
            <Box className={classes.childContent}>
              <MenuBody headData={getLabel(LangConstant.TXT_ROLE)} onChange={onChange} cellData={role} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.rootActions}>
          <Button onClick={onClose} className={classes.button}>
            {getLabel(LangConstant.TXT_SHOP_CANCEL)}
          </Button>
          <Button onClick={() => onSubmit(email, role + 2)} className={classes.button}>
            {getLabel(LangConstant.TXT_INVITE)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(() => ({
  paper: {
    margin: "0 20px",
    boxShadow: "none",
    borderRadius: 0,
    maxWidth: 500,
    minHeight: 200,
  },
  content: {
    padding: 24,
    borderTop: "none",
  },
  rootActions: {
    height: 60,
    padding: "8px 30px",
  },
  button: {
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      backgroundColor: "transparent",
    },
  },
  parentContent: {
    display: "flex",
  },
  typoContent: {
    fontSize: 13,
    color: "#787878",
  },
  childContent: {
    marginLeft: "auto",
  },
}));
export default memo(PopupSuccess);
