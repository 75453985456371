import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";

const IconButtonMProductItem = props => {
  const { buttonClasses, icon, label, onClick, size } = props;
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(classes.buttonRoot, buttonClasses && buttonClasses.root)}
      startIcon={icon ? icon : <Add />}
      onClick={onClick}
      size={size ? size : "medium"}
    >
      <Typography
        color="inherit"
        className={buttonClasses && buttonClasses.typo ? buttonClasses.typo : "medium-md-txt"}
      >
        {label}
      </Typography>
    </Button>
  );
};

const useStyles = makeStyles({
  buttonRoot: {
    minWidth: 180,
    height: "min-content",
    borderRadius: "2px",
    textTransform: "none",
  },
});

IconButtonMProductItem.propTypes = {
  buttonClasses: PropTypes.object,
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

IconButtonMProductItem.defaultProps = {
  buttonClasses: {},
  label: "",
  onClick: () => {},
};

export default memo(IconButtonMProductItem);
