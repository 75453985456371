import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography, withStyles } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import InputPrice from "./InputPrice";
import Radio from "@material-ui/core/Radio";
import { LangConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils";

const InputPriceChecked = ({
  placeholder,
  currencyUnit,
  onChange,
  classPriceChecked,
  onClick,
  taxPrice,
  taxInclusiveF,
  isDisabled,
}) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(
    taxInclusiveF ? taxInclusiveF : AppConstant.CHECKBOX_INPUT_PRICE.before,
  );
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const [taxBefore, setTaxBefore] = useState("");
  const [taxAfter, setTaxAfter] = useState("");

  const onChangeCheckBox = event => {
    onClick(event.target.value);
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (taxPrice) {
      let taxPriceBefore = taxPrice.taxBefore;
      let taxPriceAfter = taxPrice.taxAfter;
      setTaxBefore(formatCurrency(taxPriceBefore));
      setTaxAfter(formatCurrency(taxPriceAfter));
    }
    if (taxPrice.taxBefore == taxPrice.taxAfter) {
      setSelectedValue(AppConstant.CHECKBOX_INPUT_PRICE.before);
    }
  }, [taxPrice.taxBefore, taxPrice.taxAfter]);

  return (
    <Box className={classes.root}>
      <Box>
        <InputPrice
          valueInput={taxBefore}
          checkDisable={true}
          placeholder={placeholder}
          onChange={onChange}
          currencyUnit={currencyUnit}
          classPrice={classPriceChecked}
        />
        <Box className={classes.checkBox}>
          <FormControlLabel
            className={classes.rootFormControl}
            control={
              <GreenRadio
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={selectedValue == AppConstant.CHECKBOX_INPUT_PRICE.before}
                onChange={onChangeCheckBox}
                disabled={isDisabled}
                value={AppConstant.CHECKBOX_INPUT_PRICE.before}
                name="pretax-price"
              />
            }
            label={<Typography variant="body2">{getLabel(LangConstant.TXT_PRETAX_PRICE)}</Typography>}
          />
        </Box>
      </Box>
      <Box className={classes.title}>
        <InputPrice
          valueInput={taxAfter}
          checkDisable={true}
          placeholder={placeholder}
          onChange={onChange}
          currencyUnit={currencyUnit}
          classPrice={classPriceChecked}
        />
        <Box className={classes.checkBox}>
          <FormControlLabel
            className={classes.rootFormControl}
            control={
              <GreenRadio
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={selectedValue == AppConstant.CHECKBOX_INPUT_PRICE.after}
                onChange={onChangeCheckBox}
                value={AppConstant.CHECKBOX_INPUT_PRICE.after}
                disabled={taxBefore == taxAfter || isDisabled}
                name="price-after-tax"
              />
            }
            label={<Typography variant="body2">{getLabel(LangConstant.TXT_PRICE_AFTER_TAX)}</Typography>}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(InputPriceChecked);

const useStyles = makeStyles({
  root: {
    maxHeight: 40,
    display: "flex",
  },
  inputPrice: {
    display: "block !important",
  },
  checkBox: {
    textAlign: "left",
  },
  title: {
    paddingLeft: 24,
  },
  rootFormControl: {
    "&.Mui-disabled": {
      cursor: "no-drop",
    },
  },
});

const GreenRadio = withStyles({
  root: {
    color: "#d4d5d8",
    "&$checked": {
      color: "#65b39d",
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

InputPriceChecked.propTypes = {
  onChange: PropTypes.func,
  currencyUnit: PropTypes.string,
  classPriceChecked: PropTypes.string,
};

InputPriceChecked.defaultProps = { currencyUnit: "" };
