import { call, put, select } from "redux-saga/effects";
import { ApiConstant, AppConstant } from "const";
import ReservationAction from "redux/reservation.redux";
import ReservationSettingAction from "redux/reservation-setting.redux";
import { ReservationService } from "services";
import Cookie from "js-cookie";
import { toCamel, toSnake } from "utils";
import { format, getTime, parse } from "date-fns";

export function* getReservationDay(action) {
  try {
    const data = action.data || {};
    const {
      reservationRedux: { advancedFilter },
    } = yield select();
    let params = { ...advancedFilter, ...data };
    let response = yield call(ReservationService.getReservationDay, toSnake(params));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      Object.keys(responseData).map(
        key =>
          (responseData[key].reservation = responseData[key].reservation.map(value =>
            setReservationFromResponse(value),
          )),
      );
      yield put(ReservationAction.getReservationDaySuccess(responseData));
      yield put(ReservationAction.reservationSuccess({ advancedFilter: params }));
    } else {
      yield put(ReservationAction.getReservationDayFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationDayFailure(error));
  }
}

export function* getReservationWeek(action) {
  try {
    const { data } = action;
    const {
      reservationRedux: { advancedFilter },
    } = yield select();
    let params = { ...advancedFilter, ...data };

    let response = yield call(ReservationService.getReservationWeek, toSnake(params));

    if (response.status === ApiConstant.STT_OK) {
      let responseData = toCamel(response.data.data);
      Object.keys(responseData).map(
        key =>
          (responseData[key].reservation = responseData[key].reservation.map(value =>
            setReservationFromResponse(value),
          )),
      );
      yield put(ReservationAction.getReservationWeekSuccess(responseData));
      yield put(ReservationAction.reservationSuccess({ advancedFilter: params }));
    } else {
      yield put(ReservationAction.getReservationWeekFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationWeekFailure(error));
  }
}

export function* getReservationMonth(action) {
  try {
    const { data } = action;
    const {
      reservationRedux: { advancedFilter },
    } = yield select();
    let params = { ...advancedFilter, ...data };
    let response = yield call(ReservationService.getReservationMonth, params);

    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ReservationAction.getReservationMonthSuccess(toCamel(responseData)));
      yield put(ReservationAction.reservationSuccess({ advancedFilter: params }));
    } else {
      yield put(ReservationAction.getReservationMonthFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationMonthFailure(error));
  }
}

export function* postReservation(action) {
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  try {
    const { data: postData } = action;
    const data = postData ? postData.newReservation : null;
    const countManChild = postData ? postData.isSettingTotal : false;
    let consumer = countManChild
      ? { total: Number(data.child) + Number(data.man), child: Number(data.child), man: Number(data.man) }
      : data.totalConsumer && data.totalConsumer > 0
      ? { total: data.totalConsumer }
      : { total: 1 };
    let dataPeriodic = data.copyReservationUid
      ? []
      : toSnake(data && data.periodic && data.isPeriodic ? data.periodic : []);
    dataPeriodic && dataPeriodic.unshift({ start_time: data.startTime, end_time: null });
    let snakeOrderDetailsList = toSnake(data.orderDetailsList || []);
    let orderDetailsForm = convertFormSubmitPreOrder(snakeOrderDetailsList);
    let arrayPlaceData = data.assign.map(dataMap => ({
      type: AppConstant.TYPE_RESERVATION_DETAILS.place,
      category_uid: dataMap.categoryUid,
      item_uid: dataMap.itemUid,
    }));
    let arrayStaffData = data.staff.map(dataMap => ({
      type: AppConstant.TYPE_RESERVATION_DETAILS.staff,
      staff_uid: dataMap.staffUid,
    }));
    let objectDetails = {
      item_from:
        snakeOrderDetailsList.length > 0 && snakeOrderDetailsList[0]?.item_uid
          ? AppConstant.TYPE_DETAILS_PRE_ORDER.menu
          : AppConstant.TYPE_DETAILS_PRE_ORDER.category,
    };
    let reservation = {
      consumer: consumer,
      note: data.note,
      details: objectDetails,
      internal_note: data.internalNote && data.internalNote.content ? data.internalNote.content : data.internalNote,
      reservation_details: [...arrayStaffData, ...arrayPlaceData],
      order_details_list: orderDetailsForm,
      name: data.name,
      email: data.email,
      start_time: data.startTime,
      phone_number: data.phoneNumber,
      periodic: dataPeriodic,
      shop_id: JSON.parse(appData).shopId,
      copy_reservation_uid: data.copyReservationUid ? data.copyReservationUid : null,
      deposit_total: data.depositTotal || 0,
      waiting_consumer_uid: data.waitingConsumerUid ? data.waitingConsumerUid : null,
      status: data.status || AppConstant.STT_RESERVATION.confirm,
      type: data.type || 1,
    };
    let response = yield call(ReservationService.postReservation, reservation);
    if (response.status === ApiConstant.STT_OK) {
      let newReservation = response.data.data;
      yield put(
        ReservationAction.postReservationSuccess({
          ...action.data,
          copyReservation: {
            uid: newReservation.uid,
            startTime: newReservation.start_time,
          },
          isDetailSuccess: Boolean(reservation.copy_reservation_uid),
        }),
      );
      yield put(
        ReservationSettingAction.requestGetReservationNotification({ paging: 0, order_by: "created", asc: 0, page: 1 }),
      );
    } else {
      yield put(ReservationAction.postReservationFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.postReservationFailure(error));
  }
}

export function* createRemoteOrder(action) {
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  try {
    const { data: orderData } = action;

    const data = orderData.newReservation || null;

    let snakeOrderDetailsList = toSnake(data.orderDetailsList || []);
    let orderDetailsForm = convertFormSubmitPreOrder(snakeOrderDetailsList);
    let itemsDetail = {
      item_from:
        snakeOrderDetailsList.length > 0 && snakeOrderDetailsList[0]?.item_uid
          ? AppConstant.TYPE_DETAILS_PRE_ORDER.menu
          : AppConstant.TYPE_DETAILS_PRE_ORDER.category,
    };
    let reservation = createReservation(data, itemsDetail, orderDetailsForm, appData);

    let response = yield call(ReservationService.postReservation, reservation);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.postReservationSuccess(reservation));
    } else {
      yield put(ReservationAction.postReservationFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.postReservationFailure(error));
  }
}

export function* putRemoteOrderDetails(action) {
  try {
    let { data } = action;

    const {
      reservationRedux: { dataOrder: newDataOrder },
    } = yield select();

    data.details.internalNote = data.details.internalNote?.content || null;
    let newData = refactorUpdateOrder(data.details.orderDetailsList);

    let dataForm = {
      details: { ...data.details, orderDetailsList: newData },
      uid: data.details.uid,
    };
    // Update data into redux store
    let orderUpdatedIndex = 0;
    orderUpdatedIndex = newDataOrder.findIndex(item => item.uid === dataForm.uid);
    newDataOrder[orderUpdatedIndex] = data.details;

    let response = yield call(ReservationService.putReservationDetails, toSnake(dataForm));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.reservationSuccess({ dataOrder: newDataOrder, updateReservationSuccess: true }));
    } else {
      yield put(ReservationAction.reservationFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.putReservationDetailsFailure(error));
  }
}

export function* getReservationSettingOption(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationService.getReservationSetting, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      let reservationSetting = {};
      responseData.map(value => (reservationSetting[value.type] = value));
      yield put(ReservationAction.getReservationSettingOptionSuccess(toCamel(reservationSetting)));
    } else {
      yield put(ReservationAction.getReservationSettingOptionFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationSettingOptionFailure(error));
  }
}

export function* putReservationStatus(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationService.putReservationStatus, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.putReservationStatusSuccess(data));
      yield put(
        ReservationSettingAction.requestGetReservationNotification({ paging: 0, order_by: "created", asc: 0, page: 1 }),
      );
    } else {
      yield put(ReservationAction.putReservationStatusFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.putReservationStatusFailure(error));
  }
}

export function* putReservationDetails(action) {
  try {
    let { data } = action;
    let details = setReservationBodyFromActionData(toSnake(data.details));
    if (data.isSettingTotal === false) {
      // nếu không có child thì chỉ lấy total =totalConsumer  và man = totalConsumer còn child = null
      details.consumer.total = data.details.totalConsumer;
      details.consumer.man = data.details.totalConsumer;
      details.consumer.child = null;
    }
    let dataForm = {
      ...data,
      details: details,
      uid: data.details?.uid,
    };
    let response = yield call(ReservationService.putReservationDetails, dataForm);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.putReservationDetailsSuccess(action.data));
      yield put(
        ReservationSettingAction.requestGetReservationNotification({ paging: 0, order_by: "created", asc: 0, page: 1 }),
      );
    } else {
      yield put(ReservationAction.putReservationDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.putReservationDetailsFailure(error));
  }
}

export function* getReservationPeriodic(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationService.getReservationPeriodic, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ReservationAction.getReservationPeriodicSuccess(toCamel(responseData)));
    } else {
      yield put(ReservationAction.getReservationPeriodicFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationPeriodicFailure(error));
  }
}

export function* getReservation(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationService.getReservation, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = setReservationFromResponse(toCamel(response.data.data));
      yield put(ReservationAction.getReservationSuccess(responseData));
    } else {
      yield put(ReservationAction.getReservationFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getReservationFailure(error));
  }
}

export function* postReservationExtend(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationService.postReservationExtend, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.postReservationExtendSuccess(data));
      yield put(ReservationAction.getReservation({ uid: data.reservation_uid }));
      yield put(ReservationAction.getReservationPeriodic({ uid: data.reservation_uid }));
    } else {
      yield put(ReservationAction.postReservationExtendFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.postReservationExtendFailure(error));
  }
}

export function* postReservationListStatus(action) {
  try {
    const { data } = action;
    let response = yield call(ReservationService.postReservationListStatus, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ReservationAction.postReservationListStatusSuccess(responseData));
    } else {
      yield put(ReservationAction.postReservationListStatusFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.postReservationListStatusFailure(error));
  }
}

export function* getAvailableStatus(action) {
  try {
    const data = action.data;
    let response = yield call(ReservationService.getAvailableStatus, data);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.getAvailableStatusSuccess(responseData));
    } else {
      yield put(ReservationAction.getAvailableStatusFailure(responseData));
    }
  } catch (error) {
    yield put(ReservationAction.getAvailableStatusFailure(error));
  }
}

function setReservationFromResponse(response) {
  let reservation = response ? { ...response } : {};
  if (response) {
    reservation.uid = response.uid ? response.uid : null;
    reservation.code = response.code ? response.code : null;
    reservation.shopId = response.shopId ? response.shopId : null;
    reservation.startTime = response.startTime ? response.startTime : null;
    reservation.endTime = response.endTime ? response.endTime : null;
    reservation.realityStartTime = response.realityStartTime || null;
    reservation.realityEndTime = response.realityEndTime || null;
    reservation.note = response.note ? response.note : null;
    reservation.details = response?.details || { item_from: AppConstant.TYPE_DETAILS_PRE_ORDER.category };
    reservation.name = response.name ? response.name : null;
    reservation.email = response.email ? response.email : null;
    reservation.phoneNumber = response.phoneNumber ? response.phoneNumber : null;
    reservation.status = response.status ? response.status : null;
    reservation.totalConsumer = response.consumer ? (response.consumer.total ? response.consumer.total : null) : null;
    reservation.man = response.consumer
      ? response.consumer.man
        ? response.consumer.man
        : response.consumer.total
      : null;
    reservation.child = response.consumer ? (response.consumer.child ? response.consumer.child : null) : null;
    reservation.date = response.startTime
      ? format(new Date(response.startTime * 1000), AppConstant.FM_DD_MM_YYYY)
      : null;
    let startHour = response.startTime ? format(new Date(response.startTime * 1000), AppConstant.FM_HH_MM) : null;

    if (response?.endTime) {
      reservation.dateEnd = format(new Date(response.endTime * 1000), AppConstant.FM_DD_MM_YYYY);
      let endHour = format(new Date(response.endTime * 1000), AppConstant.FM_HH_MM);
      reservation.timeEnd = endHour;
    }

    reservation.time = startHour;
    reservation.startTime = response.startTime;

    let reservationDetails = response.reservationDetails;
    let staffArray = [];
    let assignArray = [];

    reservationDetails.forEach(dataMap => {
      if (dataMap.type === AppConstant.TYPE_RESERVATION_DETAILS.staff) {
        staffArray.push({
          staffUid: dataMap?.staffUid,
          staffName: dataMap?.staffName,
        });
      } else if (dataMap.type === AppConstant.TYPE_RESERVATION_DETAILS.place) {
        assignArray.push({
          categoryUid: dataMap?.categoryUid,
          categoryName: dataMap?.categoryName,
          itemUid: dataMap?.itemUid,
          itemName: dataMap?.itemName,
        });
      }
    });
    reservation.assign = assignArray;
    reservation.staff = staffArray;
    reservation.depositTotal = response.depositTotal ? response.depositTotal : 0;
    reservation.orderDetailsList =
      reservation?.orderDetailsList?.map(dataMap => ({
        ...dataMap,
        isTransmitData: true,
        orderDetailsContentList: dataMap.orderDetailsContentList.map(contentData => ({
          ...contentData,
          isTransmitData: true,
        })),
      })) || [];
  }
  return reservation;
}

function setReservationBodyFromActionData(object) {
  let reservation = {
    start_time: null,
    end_time: null,
    internal_note: null,
    consumer: { total: null, child: null, man: null },
    note: null,
    reservation_details: [],
    order_details_list: [],
    name: null,
    email: null,
    phone_number: null,
    status: null,
    reality_end_time: null,
    reality_start_time: null,
    deposit_total: 0,
    details: null,
    waiting_consumer_uid: null,
  };
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  if (object) {
    let consumer = object.totalConsumer
      ? { total: object.totalConsumer }
      : { total: Number(object.child) + Number(object.man), child: object.child, man: object.man };
    let start_time = Math.floor(
      getTime(parse(object.time + " " + object.date, AppConstant.FM_HH_MM_DD_MM_YYYY, new Date())) / 1000,
    );
    let end_time =
      object?.dateEnd && object?.timeEnd
        ? Math.floor(
            getTime(parse(object.dateEnd + " " + object.timeEnd, AppConstant.FM_HH_MM_DD_MM_YYYY, new Date())) / 1000,
          )
        : null;

    let snakeOrderDetailsList = object.order_details_list;
    let orderDetailsForm = convertFormUpdatePreOrder(snakeOrderDetailsList);

    let arrayPlaceData = object.assign.map(dataMap => ({
      type: AppConstant.TYPE_RESERVATION_DETAILS.place,
      category_uid: dataMap.category_uid,
      item_uid: dataMap.item_uid,
    }));

    let arrayStaffData = object.staff.map(dataMap => ({
      type: AppConstant.TYPE_RESERVATION_DETAILS.staff,
      staff_uid: dataMap.staff_uid,
    }));

    reservation = {
      shop_id: JSON.parse(appData).shopId,
      start_time: start_time,
      end_time: end_time,
      consumer: consumer,
      note: object.note,
      internal_note: object.internal_note ? object.internal_note.content : null,
      reservation_details: [...arrayStaffData, ...arrayPlaceData],
      order_details_list: orderDetailsForm,
      name: object.name,
      email: object.email || "",
      phone_number: object.phone_number,
      status: object.status,
      reality_start_time: object.reality_start_time,
      reality_end_time: object.reality_end_time,
      details: object?.details || { item_from: AppConstant.TYPE_DETAILS_PRE_ORDER.category },
      deposit_total: object?.deposit_total || 0,
      waiting_consumer_uid: object.waiting_consumer_uid,
    };
  }
  return reservation;
}

export function* getDisplaySetting(action) {
  try {
    const data = action.data;
    let response = yield call(ReservationService.getDisplaySetting, data);
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.getDisplaySettingSuccess(responseToBookingDisplaySetting(responseData?.details)));
    } else {
      yield put(ReservationAction.getDisplaySettingFailure(response.data));
    }
  } catch (error) {
    yield put(ReservationAction.getDisplaySettingFailure(error));
  }
}

export function* updateDisplaySetting(action) {
  try {
    const data = dataToRequestBodyBookingDisplaySetting(action.data);
    let response = yield call(ReservationService.updateDisplaySetting, { display_ids: data });
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReservationAction.updateDisplaySettingSuccess(action.data));
    } else {
      yield put(ReservationAction.updateDisplaySettingFailure(responseData));
    }
  } catch (error) {
    yield put(ReservationAction.updateDisplaySettingFailure(error));
  }
}

export function* getReservationType(action) {
  try {
    const { data } = action;
    const {
      reservationRedux: { filter },
    } = yield select();
    let params = { ...filter, ...data };
    let response = yield call(ReservationService.getReservationType, toSnake(params));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        ReservationAction.reservationSuccess({
          dataOrder: toCamel(responseData),
          filter: { ...params, total: response.data.total, totalPage: response.data.total_page },
        }),
      );
    } else {
      yield put(ReservationAction.reservationFailure(responseData));
    }
  } catch (error) {
    yield put(ReservationAction.reservationFailure(error));
  }
}

const responseToBookingDisplaySetting = settingDetail => {
  let tmpIdArray = [];

  settingDetail.forEach(element => {
    tmpIdArray.push(parseInt(element.display_id));
  });

  let displaySettingArray = [
    {
      displayId: AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.service,
      isShow: tmpIdArray.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.service),
    },
    {
      displayId: AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.place,
      isShow: tmpIdArray.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.place),
    },
    {
      displayId: AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.staff,
      isShow: tmpIdArray.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.staff),
    },
    {
      displayId: AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.consumerNumber,
      isShow: tmpIdArray.includes(AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.consumerNumber),
    },
  ];
  return displaySettingArray;
};

const dataToRequestBodyBookingDisplaySetting = settingDetail => {
  let tmpUpdateSettingArray = [];
  settingDetail.forEach(element => {
    if (element.isShow) {
      tmpUpdateSettingArray.push(element.displayId);
    }
  });
  return tmpUpdateSettingArray;
};

const convertFormSubmitPreOrder = data => {
  let newForm = [];
  data.forEach(dataMap => {
    let objectData = {
      item_uid: dataMap?.item_uid ? dataMap.item_uid : dataMap.uid,
      type: dataMap.type,
      order_number: dataMap.order_number,
      order_details_content_list: dataMap.topping_list.map(dataContent => ({
        item_details_content_uid: dataContent.uid,
      })),
    };
    newForm.push(objectData);
  });
  return newForm;
};

const convertFormUpdatePreOrder = data => {
  let newForm = [];
  data.forEach(dataMap => {
    let objectData = {
      uid: dataMap?.is_transmit_data ? dataMap.uid : null,
      item_uid: dataMap?.item_uid ? dataMap.item_uid : dataMap.uid,
      type: dataMap.type,
      order_number: dataMap.order_number,
      order_details_content_list: dataMap?.topping_list
        ? dataMap.topping_list.map(dataContent => {
            if (dataContent?.is_transmit_data) {
              return {
                uid: dataContent.uid,
                item_details_content_uid: dataContent.item_uid,
              };
            }
            return {
              item_details_content_uid: dataContent.uid,
            };
          })
        : dataMap.order_details_content_list.map(dataContent => ({
            uid: dataContent.uid,
            item_details_content_uid: dataContent.item_uid,
          })),
    };
    newForm.push(objectData);
  });
  return newForm;
};

const DEFAULT_RESERVATION = {
  type: AppConstant.DATA_TYPE.booking,
  status: AppConstant.STT_RESERVATION.confirm,
  consumer: { total: 1 },
  address: "",
  name: "",
  email: "",
  phone_number: "",
  details: {},
  reservation_details: [],
  deposit_total: 0,
  order_details_list: [],
  periodic: [],
  shop_id: 0,
  copy_reservation_uid: "",
  waiting_consumer_uid: "",
  note: "",
  internal_note: "",
};

const createReservation = (data, itemsDetail, orderDetailsForm, appData) => {
  let newData = {};
  if (!data) newData = DEFAULT_RESERVATION;
  else {
    newData = {
      ...DEFAULT_RESERVATION,
      address: data.address,
      note: data.note,
      details: itemsDetail,
      internal_note: data.internalNote && data.internalNote.content ? data.internalNote.content : data.internalNote,
      order_details_list: orderDetailsForm,
      name: data.name,
      email: data.email,
      phone_number: data.phoneNumber,
      shop_id: JSON.parse(appData).shopId,
      type: data.type,
    };
  }
  return newData;
};

const getOrderDetailsContentList = data => {
  let orderDetailContentList = [];

  let newOrder = data.toppingList ? data.toppingList : data.orderDetailsContentList;
  newOrder.map(dataContent => {
    if (dataContent.isSelectedItem) {
      orderDetailContentList.push({
        uid: dataContent.uid,
        itemDetailsContentUid: dataContent.itemUid,
      });
    } else {
      orderDetailContentList.push({
        itemDetailsContentUid: dataContent.uid,
      });
    }
  });
  return orderDetailContentList;
};

const refactorUpdateOrder = data => {
  let result = [];
  data.forEach(dataMap => {
    let orderDetailContentList = getOrderDetailsContentList(dataMap);

    let updatedOrderData = {
      uid: dataMap.isSelectedItem ? dataMap.uid : null,
      itemUid: dataMap?.itemUid ? dataMap.itemUid : dataMap.uid,
      type: dataMap.type,
      orderNumber: dataMap.orderNumber,
      orderDetailsContentList: orderDetailContentList,
    };
    result.push(updatedOrderData);
  });
  return result;
};
