import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Typography,
  makeStyles,
  Box,
  Popover,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { ArrowDropDownOutlined, NavigateBefore, AccessTimeOutlined, Close, NavigateNext } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import StringFormat from "string-format";
import { LangConstant } from "const";
import { onDoNothing } from "utils";

const DialogTimePicker = ({ placeholder, timeArray, isEnableClick, classes }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const defaultClasses = useStyles();
  const timePickerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [timeFullYear, setTimeFullYear] = useState("");

  let selectedDate = new Date();
  const onClickTime = () => {
    onClose();
  };

  const onClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  useEffect(() => {
    setTimeFullYear(
      StringFormat(
        getLabel(LangConstant.FM_FULL_DATE_TEXT),
        selectedDate.getDate(),
        selectedDate.getMonth() + 1,
        selectedDate.getFullYear(),
      ),
    );
  }, [selectedDate]);

  return (
    <>
      <OutlinedInput
        ref={timePickerRef}
        onClick={() => {
          if (isEnableClick) {
            setIsOpen(true);
            setAnchorEl(timePickerRef.current);
          }
        }}
        fullWidth
        type="text"
        value={""}
        placeholder={placeholder || getLabel(LangConstant.P_SELECT_TIME)}
        classes={{
          input: defaultClasses.input,
          root: clsx(defaultClasses.root, classes.inputRoot),
        }}
        startAdornment={
          <InputAdornment position="start" className={defaultClasses.icon}>
            <AccessTimeOutlined />
          </InputAdornment>
        }
        endAdornment={
          <IconButton edge="end" className={defaultClasses.secondaryIcon}>
            <ArrowDropDownOutlined color="inherit" />
          </IconButton>
        }
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: defaultClasses.dialogTimePickerPaper }}
      >
        <Box className={defaultClasses.dropdownContent}>
          <Box className={defaultClasses.dialogTitle}>
            <Grid className={defaultClasses.gridHeader}>
              <IconButton onClick={onDoNothing}>
                <NavigateBefore className={defaultClasses.colorHeader} />
              </IconButton>
              <Typography className={defaultClasses.colorHeaderTime}>{timeFullYear}</Typography>
              <IconButton onClick={onDoNothing}>
                <NavigateNext className={defaultClasses.colorHeader} />
              </IconButton>
            </Grid>
            <Grid className={defaultClasses.girdHeaderClose}>
              <IconButton onClick={onClose}>
                <Close className={defaultClasses.colorHeaderClose} />
              </IconButton>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box className={defaultClasses.gridContainer}>
            {timeArray &&
              timeArray.length > 0 &&
              timeArray.map((item, index) => (
                <Button key={index} className={defaultClasses.boxButtonTop} onClick={() => onClickTime(item)}>
                  <Typography>{item}</Typography>
                </Button>
              ))}
          </Box>
        </Box>
        <Box className={defaultClasses.bottomTitle}>
          <Box className={defaultClasses.timeBottomTitle}>
            <Box className={defaultClasses.timeBottomTitleAvailability} />
            <Typography className={defaultClasses.timeBottomTitleFontsize}>
              {getLabel(LangConstant.TXT_AVAILABLE_TIME)}
            </Typography>
          </Box>
          <Box className={defaultClasses.timeBottomTitle}>
            <Box className={defaultClasses.timeBottomTitlePrivate} />
            <Typography className={defaultClasses.timeBottomTitleFontsize}>
              {getLabel(LangConstant.TXT_UNAVAILABLE_TIME)}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

DialogTimePicker.propTypes = {
  selectedDate: PropTypes.any,
  available: PropTypes.any,

  setSelectedTime: PropTypes.func,
  setSelectedDate: PropTypes.func,

  timeArray: PropTypes.array,
  placeholder: PropTypes.string,

  classes: PropTypes.object,

  disablePast: PropTypes.bool,
  isEnableClick: PropTypes.bool,

  selectedTime: PropTypes.number,
};
DialogTimePicker.defaultProps = {
  classes: {
    inputRoot: {},
  },
};

export default memo(DialogTimePicker);

const useStyles = makeStyles(theme => ({
  secondaryIcon: {
    color: theme.palette.grey[400],
    padding: 4,
  },
  gridHeader: {
    width: "94%",
    display: "flex",
    justifyContent: "center",
  },
  girdHeaderClose: {
    placeSelf: "center",
  },
  dialogTitle: {
    display: "flex",
    fontSize: 16,
    justifyContent: "center",
    color: theme.palette.white,
    width: "100%",
  },
  colorHeader: {
    color: theme.palette.white,
  },
  colorHeaderTime: {
    placeSelf: "center",
    color: theme.palette.white,
  },
  colorHeaderClose: {
    color: theme.palette.white,
    width: 24,
    height: 24,
  },
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: 8,
    marginBottom: 8,
  },
  input: {
    padding: "16px 0 16px 8px",
    "&::placeholder": {
      color: theme.palette.black,
      opacity: 0.3,
    },
  },
  icon: {
    minWidth: 50,
    width: 50,
    height: "100%",
    maxHeight: "100%",
    minHeight: 50,
    background: "#ebeced",
    justifyContent: "center",
    borderRight: "solid 0.5px #cecfd2",
    marginRight: 0,
  },
  dropdownContent: {
    height: "max-content",
    width: "100%",
    maxHeight: 375,
    backgroundColor: theme.palette.reservation["finished"],
    padding: "12px 14px 10.4px 0px",
  },
  bottomTitle: {
    width: "100%",
    display: "flex",
    marginTop: 24,
    marginBottom: 24,
  },
  timeBottomTitle: {
    width: "50%",
    justifyContent: "center",
    display: "flex",
  },
  timeBottomTitleAvailability: {
    width: 16,
    height: 16,
    backgroundColor: theme.palette.reservation["finished"],
    opacity: 0.15,
  },
  timeBottomTitlePrivate: {
    width: 16,
    height: 16,
    backgroundColor: "#000000",
    opacity: 0.1,
  },
  timeBottomTitleFontsize: {
    paddingLeft: "5%",
    fontSize: 12,
    borderRadius: 4,
  },
  boxButtonTop: {
    width: 74,
    height: 36,
    backgroundColor: "rgb(239, 88, 69, 0.15)",
    overflow: "auto",
    maxHeight: 450,
    "&:hover": {
      backgroundColor: "rgb(239, 88, 69, 0.4)",
    },
  },
  boxButtonTopSelected: {
    width: 74,
    height: 36,
    backgroundColor: "rgb(239, 88, 69, 0.4)",
    overflow: "auto",
    maxHeight: 450,
    "&:hover": {
      backgroundColor: "rgb(239, 88, 69, 0.4)",
    },
  },
  boxButtonTopNotAvailable: {
    width: 74,
    height: 36,
    backgroundColor: "rgb(0, 0, 0, 0.08)",
    overflow: "auto",
    maxHeight: 450,
    "&:disabled>span>p": {
      color: "#979aa1",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    gridGap: 20,
    padding: "36px 24px 12px 24px",
    [theme.breakpoints.down("sm")]: {
      gridGap: 5,
      padding: "18px 24px 12px 12px",
    },
  },
  dialogTimePickerPaper: {
    minWidth: 465,
    borderRadius: 6,
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      maxWidth: "90%",
    },
  },
}));
