import React, { memo, useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { EventAvailable } from "@material-ui/icons";
import { IconButton, makeStyles } from "@material-ui/core";
import Cookie from "js-cookie";
import NewBookingNotifyContent from "./NewBookingNotifyContent";
import ReservationNewAction from "redux/reservation-new.redux";
import { NotifyBadge } from "components";
import { AppConstant } from "const";
import { HEADER_CONDITION_OBJ } from "layouts/MainLayout/components/CustomAppBar";

const NewBookingNotify = ({ onClick, isShowPopup }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const role = Cookie.get(AppConstant.ROLE);
  let isShopOwnerOrManager = role === AppConstant.SHOP_OWNER || role === AppConstant.SHOP_MANAGER;

  const [bookingNotification, isFetching] = useSelector(
    ({ reservationNewRedux }) => [reservationNewRedux.bookingNotification, reservationNewRedux.isFetching],
    shallowEqual,
  );

  const isPostSuccessReservationListStatus = useSelector(
    state => state.reservationRedux.isPostSuccessReservationListStatus,
  );

  const { page, total, data: newReservationData } = bookingNotification;

  const [anchorEl, setAnchorEl] = useState(null);
  const [newBookingIntervalId, setNewBookingIntervalId] = useState();

  const onControlDisplay = event => {
    event.stopPropagation();
    let newAnchor = Boolean(anchorEl) ? null : event.currentTarget;
    setAnchorEl(newAnchor);
    onClick({ ...HEADER_CONDITION_OBJ, isBooking: Boolean(newAnchor) });
  };

  useEffect(() => {
    dispatch(ReservationNewAction.getReservationNewBooking());
  }, []);

  useEffect(() => {
    if (isPostSuccessReservationListStatus && !isFetching && Object.keys(bookingNotification.data).length > 0) {
      dispatch(
        ReservationNewAction.getReservationNewBooking({
          page: page,
        }),
      );
    }
  }, [isPostSuccessReservationListStatus]);

  useEffect(() => {
    let newOrderInterval = 0;
    if (newBookingIntervalId) {
      clearInterval(newBookingIntervalId);
      setNewBookingIntervalId(null);
    } else if (isShopOwnerOrManager) {
      var intervalId = setInterval(() => {
        dispatch(
          ReservationNewAction.getReservationNewBooking({
            page: 1,
          }),
        );
      }, AppConstant.NOTIFICATION_REQUEST_INTERVAL_TIME);
      setNewBookingIntervalId(intervalId);
      newOrderInterval = intervalId;
    }
    return () => {
      clearInterval(newOrderInterval);
    };
  }, [anchorEl]);

  useEffect(() => {
    if (Boolean(anchorEl) && !isShowPopup) {
      setAnchorEl(null);
    }
  }, [isShowPopup]);

  let isShow = isShowPopup && Boolean(anchorEl);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={onControlDisplay}
        className={anchorEl ? classes.iconBoxFocused : classes.iconBox}
        id={NEW_NOTIFY_BOOKING_ICON}
      >
        {newReservationData && total > 0 ? (
          <NotifyBadge data={total} badgeClass={classes.notifyBadge}>
            <EventAvailable className={anchorEl && classes.iconFocused} />
          </NotifyBadge>
        ) : (
          <EventAvailable className={anchorEl && classes.iconFocused} />
        )}
      </IconButton>
      {isShow && (
        <NewBookingNotifyContent
          open={isShow}
          anchorEl={anchorEl}
          onClose={onControlDisplay}
          classes={{
            popover: classes.newBookingPopover,
          }}
        />
      )}
    </>
  );
};

NewBookingNotify.propTypes = {
  onClick: PropTypes.func,
  isShowPopup: PropTypes.bool,
};
NewBookingNotify.defaultProps = {
  onClick: () => {},
  isShowPopup: false,
};
export default memo(NewBookingNotify);

const NEW_NOTIFY_BOOKING_ICON = "new-notify-booking-icon";

const useStyles = makeStyles(theme => ({
  iconBox: {
    marginRight: 15,
    padding: "5px 5px",
  },
  iconBoxFocused: {
    marginRight: 15,
    borderRadius: "50%",
    backgroundColor: theme.palette.white,
    padding: "5px 5px",
  },

  iconFocused: {
    color: "#ef4858",
  },
  notifyBadge: {
    backgroundColor: "#feba40",
  },
  newBookingPopover: {
    marginTop: 13,
    "&::before": {
      right: 147,
    },
    [theme.breakpoints.down("md")]: {
      "&::before": {
        right: 89,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&::before": {
        right: "43.5%",
      },
    },
  },
}));
