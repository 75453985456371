import React, { forwardRef, memo, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import { LangConstant, ApiConstant, AppConstant } from "const";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { openSocialWindow } from "utils";
import { useDispatch } from "react-redux";
import ShopAction from "redux/shop.redux";

const LinkFacebookPage = forwardRef((props, ref) => {
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    openSocialLink() {
      let fbUrl = ApiConstant.LOGIN_BY_FACEBOOK;
      if (alias) {
        fbUrl += `&${AppConstant.KEY_SHOP_NAME}=` + alias;
        fbUrl += `&${AppConstant.KEY_ACTION}=`;
      }
      openSocialWindow(fbUrl, onHandling);
    },
  }));
  const alias = JSON.parse(Cookie.get(AppConstant.KEY_GBOSS))[AppConstant.KEY_TOKEN];
  const onHandling = () => {
    dispatch(ShopAction.requestGetFacebookPage({ isLogged: true }));
    props?.action && props.action();
  };

  return (
    <>
      <Typography variant="body2">{getLabel(LangConstant.MSG_REGISTER_WITH_SOCIAL)}</Typography>
      <Box component="span">{props?.linkButton}</Box>
    </>
  );
});
LinkFacebookPage.propTypes = {
  action: PropTypes.func,
  linkButton: PropTypes.node,
};
LinkFacebookPage.defaultProps = {};

export default memo(LinkFacebookPage);
