import React, { memo, useEffect, useState } from "react";
import { makeStyles, Paper, Container, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { getCommonKey, getLabelArray } from "utils";
import { SearchBar, MenuStatus, BglobalJSC, PrimaryCustomDatePicker } from "components";
import { ActivityHistoryTable } from "./components";
import { TablePagination } from "components/table";
import ReservationActivityAction from "redux/reservation-activity.redux";
import { convertTimeStampToDate, getEndOfDay, getStartOfDay } from "utils/date";

const MnActivityHistory = () => {
  const reservationActivityRedux = useSelector(state => state.reservationActivityRedux);
  const { data: reservationActivityData } = reservationActivityRedux;
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_ACTIVITY_HISTORY);
  const classes = useStyles();
  const headData = LangConstant.ARR_ACTIVITY_HEADER;

  const [showData, setShowData] = useState([]);
  const [order, setOrder] = useState(0);
  const [action, setAction] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [paging] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchString, setSearchString] = useState("");
  const [timeRange, setTimeRange] = useState({
    from: getStartOfDay(new Date()),
    to: getEndOfDay(new Date()),
  });

  const onFilterTimeRange = rangeDate => {
    setTimeRange({
      from: getStartOfDay(rangeDate.from),
      to: getEndOfDay(rangeDate.to),
    });
  };

  const onChangeActivity = act => {
    setPage(1);
    setAction(act);
  };

  const onSort = () => {
    let ord = !order;
    setOrder(ord ? 1 : 0);
  };

  const onChangePage = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    if (reservationActivityData) {
      setShowData(reservationActivityData.data);
      setTotal(reservationActivityData.total);
      setTotalPage(reservationActivityData.totalPage);
      setPage(reservationActivityData.page);
      setRowsPerPage(reservationActivityData.size);
    }
  }, [reservationActivityData]);

  useEffect(() => {
    var callingApiTimeout = setTimeout(() => {
      dispatch(
        ReservationActivityAction.getReservationActivity({
          page: page,
          paging: paging,
          size: rowsPerPage,
          orderBy: SORT_VALUE[0],
          asc: order,
          start: timeRange.from || getStartOfDay(new Date()),
          end: timeRange.to || getEndOfDay(new Date()),
          action: action > 0 ? action : null,
          action_details: searchString,
        }),
      );
    }, 600);

    return () => clearTimeout(callingApiTimeout);
  }, [searchString, timeRange, action, order, page]);

  return (
    <Box className="hidden-scroll">
      <Paper elevation={1} square className={classes.paperRoot}>
        <Container className={classes.containerRoot}>
          <Box className={classes.mainRoot}>
            <Box className={classes.optionBar}>
              <PrimaryCustomDatePicker
                dateRange={{
                  from: convertTimeStampToDate(timeRange.from),
                  to: convertTimeStampToDate(timeRange.to),
                }}
                onChange={onFilterTimeRange}
                onClickToday={onFilterTimeRange}
                isPickRangeDate={true}
              />
              <MenuStatus
                classes={{ root: classes.menuStatus, item: classes.menuItemStatus }}
                onChange={onChangeActivity}
                categoryText={getLabel(LangConstant.TXT_ACTIVITY_TYPE)}
                arrayItemsText={getLabelArray(LangConstant.ARR_ACTIVITY_TYPE, getLabel)}
              />
              <Box className={classes.searchBox}>
                <SearchBar
                  placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
                  className={classes.searchBarRoot}
                  onKeyUp={setSearchString}
                />
              </Box>
            </Box>
            <ActivityHistoryTable showData={showData} headData={headData} onSort={onSort}></ActivityHistoryTable>
            <TablePagination
              total={total}
              totalPage={totalPage}
              page={page}
              rowsPerPage={rowsPerPage}
              onChange={onChangePage}
            />
          </Box>
        </Container>
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};

const SORT_VALUE = ["created"];

const useStyles = makeStyles(() => ({
  containerRoot: {
    maxWidth: "100%",
  },
  mainRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    padding: "20px 0 20px",
  },
  menuStatus: {
    height: 30,
    minWidth: 180,
    marginLeft: 10,
    marginTop: 0,
    "@media only screen and (max-width: 600px)": {
      marginLeft: 0,
      marginTop: 8,
    },
  },
  menuItemStatus: {
    minWidth: 180,
  },
  optionBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 20,
    height: 30,
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      height: 110,
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 20,
    },
  },
  searchBox: {
    marginLeft: 10,
    marginTop: 0,
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      marginLeft: 0,
      marginTop: 8,
    },
  },
  searchBarRoot: {
    width: "100%",
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));

export default memo(MnActivityHistory);
