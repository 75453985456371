import React, { memo, useEffect, useState } from "react";
import { Box, makeStyles, Button, Typography, Avatar, TextareaAutosize } from "@material-ui/core";
import { AntSwitchSecondary } from "./CustomizeSwitch";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import MultipleImageUploadComponent from "./MulFileUpload";
import { useDispatch, useSelector } from "react-redux";
import MnNewsAction from "redux/mn-news.redux";
import { AppConstant, ApiConstant } from "const";
import { getSortName } from "utils";
import ShopAction from "redux/shop.redux";
import Cookie from "js-cookie";

const PostUpload = () => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [postContent, setPostContent] = useState("");
  const [uploadButtonStatus, setUploadButtonStatus] = useState(false);
  const imageURI = useSelector(state => state.newsRedux.postImgUrl);
  const isSuccessCreate = useSelector(state => state.newsRedux.isCreateSuccess);
  const dataShopRedux = useSelector(state => state.shopRedux.dataShop);
  const [showData, setShowData] = useState({ cover: "", name: "" });
  const [fileArr, setFileArr] = useState([]);

  const dispatch = useDispatch();
  const onChecked = () => {
    setChecked(!checked);
  };
  const onWriting = event => {
    setPostContent(event.target.value);
  };
  const onActiveUploadButton = e => {
    setUploadButtonStatus(e);
  };
  useEffect(() => {
    const appData = Cookie.get(AppConstant.KEY_GBOSS);
    if (appData && appData.length > 0) {
      let shopId = JSON.parse(appData).shopId;
      dispatch(ShopAction.requestGetShopInfo({ data: { shopId: shopId } }));
    }
  }, []);
  useEffect(() => {
    setShowData(dataShopRedux ? dataShopRedux : { cover: "", name: "" });
  }, [dataShopRedux]);
  useEffect(() => {
    if (isSuccessCreate) {
      dispatch(MnNewsAction.getNews());
      window.location.reload();
    }
  }, [isSuccessCreate]);

  const uploadData = { image: imageURI, content: postContent, post_to_facebook: checked ? 1 : 0 };
  return (
    <Box className={classes.postUploadMainBox}>
      <Box className={classes.postUploadContainer}>
        <Box className={classes.shopNameUpload}>
          <Avatar
            src={
              !showData[AppConstant.KEY_SHOP_AVATA]
                ? ""
                : `${ApiConstant.HOST_ATTACHMENT}${showData[AppConstant.KEY_SHOP_AVATA]}`
            }
            className={classes.avtImage}
          >
            {showData[AppConstant.KEY_SHOP_AVATA] ? "" : getSortName(showData[AppConstant.KEY_SHOP_NAME])}
          </Avatar>
          <Typography component="p" className={classes.titleStyle} variant="subtitle2">
            {showData ? showData.name : ""}
          </Typography>
        </Box>
        <Box className={classes.postUploadContent}>
          <TextareaAutosize
            placeholder={getLabel(LangConstant.TXT_NEWS_POSTUPLOAD_PLACEHOLDER)}
            className={`${classes.textInputUpload} regular-md-txt`}
            rows={1}
            value={postContent}
            onChange={onWriting}
          />
          <MultipleImageUploadComponent
            onActiveUploadButton={onActiveUploadButton}
            fileArr={fileArr}
            setFileArr={setFileArr}
          />
          <Box className={classes.uploadFooter}>
            <Typography component="p" className={`${classes.uploadInstruction} light-sm-txt`}>
              {getLabel(LangConstant.TXT_NEWS_POSTUPLOAD_INSTRUCTION)}
            </Typography>
            <Box className={`${classes.instrucContainer} light-sm-txt`}>
              <AntSwitchSecondary checked={checked} onChange={onChecked} name="fbSwitch" className={classes.fbSwitch} />
              <Typography component="span" className={`${classes.fbLabel} regular-sm-txt`}>
                {getLabel(LangConstant.TXT_FACEBOOK)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button
          disabled={!uploadButtonStatus && !postContent}
          classes={{
            root: `${classes.onUploadPostButton} semiBold-lg-txt`,
            disabled: `${classes.disabledButton} semiBold-lg-txt`,
          }}
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch(MnNewsAction.createNews(uploadData));
          }}
        >
          {getLabel(LangConstant.TXT_NEWS_POSTUPLOAD)}
        </Button>
      </Box>
    </Box>
  );
};
export default memo(PostUpload);

const useStyles = makeStyles(theme => ({
  avtImage: {
    borderRadius: "50%",
    width: "48px",
    height: "48px",
    marginRight: "1rem",
  },
  shopNameUpload: {
    marginTop: "1.5vh",
    display: "flex",
    alignItems: "center",
    marginBottom: "2vh",
    "@media only screen and (max-width: 414px)": {
      paddingTop: "2vh",
      backgroundColor: "#ffffff",
    },
  },
  titleStyle: {
    lineHeight: 1.5,
  },
  textInputUpload: {
    justifyItems: "center",
    minHeight: 20,
    resize: "none",
    fontFamily: theme.typography.fontFamily,
    minWidth: "100%",
    border: "unset",
    "&:focus": {
      border: "unset",
      outline: "unset",
    },
    "&::placeholder": {
      opacity: 0.3,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
    },
    marginTop: "10px",
    marginBottom: "15px",
    "@media only screen and (max-width: 414px)": {
      textAlign: "center",
    },
    width: "-webkit-fill-available",
    position: "relative",
    color: "#3e4045",
  },
  postUploadContent: {
    border: "solid 1px #d4d5d8",
    width: "-webkit-fill-available",
    height: "fit-content",
    textAlign: "left",
    paddingLeft: "2vw",
    paddingRight: "2vw",
    padding: "1vw",
    position: "relative",
    marginBottom: "10vh",
  },
  uploadFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "fill-available",
    position: "relative",
    height: "20%",
    marginTop: "13px",
    marginBottom: "3vh",
  },
  fbLabel: {
    marginLeft: "10px",
    color: "#3e4045",
  },
  onUploadPostButton: {
    textTransform: "none",
    marginTop: "-4vh",
    borderRadius: "24px",
    height: "50px",
    marginBottom: "2vh",
    "&$disabledButton": {
      backgroundColor: theme.palette.reservation.finished,
      opacity: 0.6,
      color: "#ffffff",
      borderRadius: "24px",
      height: "50px",
      width: "max-content",
      marginTop: "-4vh",
      marginBottom: "2vh",
    },
  },
  disabledButton: {},
  uploadInstruction: {
    color: "#7f838c",
  },
  postUploadContainer: {
    "@media only screen and (min-width: 414px)": {
      display: "block",
      paddingTop: "3vh",
      minHeight: "97vh",
      height: "fit-content",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.white,
      paddingLeft: "2vw",
      paddingRight: "2vw",
      textAlign: "center",
    },
    "@media only screen and (max-width: 414px)": {
      width: "95vw",
      minHeight: "87vh",
      height: "fit-content",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.white,
      textAlign: "-webkit-center",
      padding: "10px",
    },
  },
  postUploadMainBox: {
    marginTop: "-3vh",
  },
  instrucContainer: {
    display: "flex",
    position: "relative",
  },
}));
