import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Dialog, Box, IconButton, Typography, Button, Hidden } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import StringFormat from "string-format";
import { AppConstant, LangConstant } from "const";
import clsx from "clsx";
import { getCommonKey, getStatusColor } from "utils";
import { convertTimeStampToDate } from "utils/date";
import ShippingInfo from "./ShippingInfo";
import OrderDetailTable from "./OrderDetailTable";
import StatusDialogOrder from "../DialogStatus/index";
import { formatCode } from "../BookingOrderTableRow";
import ReservationAction from "redux/reservation.redux";
import ComboItemAction from "redux/combo-item.redux";
import MnProductAction from "redux/mn-product.redux";
import ServiceItemAction from "redux/service-item.redux";
import MenuAction from "redux/menu.redux";

const DialogOrderDetail = ({ orderData, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);

  const [isOpenStatusDialog, setIsOpenStatusDialog] = useState(false);
  const [detailData, setDetailData] = useState(refactorSelectedData(orderData) || {});

  const onClickStatus = () => setIsOpenStatusDialog(true);

  const onCloseStatus = () => setIsOpenStatusDialog(false);

  const onSubmit = status => {
    dispatch(ReservationAction.putReservationStatus({ status: status, uid: detailData.uid }));
    setIsOpenStatusDialog(false);
  };

  useEffect(() => {
    setDetailData(refactorSelectedData(orderData));
  }, [orderData]);

  useEffect(() => {
    dispatch(ComboItemAction.getListComboItem());
    dispatch(MnProductAction.getProductItem());
    dispatch(ServiceItemAction.getListServiceItem());
    dispatch(MenuAction.getMenu({ type: AppConstant.DATA_TYPE.order }));
  }, []);

  return (
    <Dialog open={true} fullScreen classes={{ paper: classes.root }}>
      <IconButton onClick={onClose} className={classes.iconButtonClose}>
        <Close className={classes.iconClose} />
      </IconButton>
      <Typography color="inherit" className={classes.titleText}>
        {getLabel(LangConstant.TXT_ORDER_DETAIL)}
      </Typography>
      <Box className={classes.boxInfo}>
        <Box className={classes.textInfo}>
          <Typography color="inherit" className={classes.id}>
            {StringFormat(getLabel(LangConstant.FM_REMOTE_ORDER), formatCode(detailData))}
          </Typography>
          <Typography color="inherit" className={classes.time}>
            {StringFormat(
              getLabel(LangConstant.FM_ORDER_TIME),
              convertTimeStampToDate(detailData.created || "", AppConstant.FM_HH_MM_DD_MM_YYYY),
            )}
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: getStatusColor(detailData.status || 0),
          }}
          className={clsx("medium-md-txt", classes.orderStatus)}
          onClick={onClickStatus}
        >
          {getLabel(getCommonKey(LangConstant.OBJECT_STATUS_ORDER[detailData.status]))}
        </Button>
      </Box>
      <Box className={classes.boxBody}>
        <Box className={classes.listProductRoot}>
          <Hidden mdUp>
            <ShippingInfo data={detailData} />
          </Hidden>
          <OrderDetailTable data={detailData} />
        </Box>
        <Hidden smDown>
          <ShippingInfo data={detailData} />
        </Hidden>
      </Box>
      {isOpenStatusDialog && (
        <StatusDialogOrder
          isShow={isOpenStatusDialog}
          dataShow={detailData}
          onClose={onCloseStatus}
          onSubmit={onSubmit}
        />
      )}
    </Dialog>
  );
};

DialogOrderDetail.propTypes = {
  orderData: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};
DialogOrderDetail.defaultProps = {
  orderData: {},
  onClose: () => {},
};

const refactorSelectedData = data => {
  let selectedData = { ...data };
  let arrSelectedItems =
    (data &&
      data.orderDetailsList?.length > 0 &&
      data.orderDetailsList.map(dataMap => ({
        ...dataMap,
        isSelectedItem: true,
        orderDetailsContentList: dataMap.orderDetailsContentList?.map(contentData => ({
          ...contentData,
          isSelectedItem: true,
        })),
      }))) ||
    [];
  selectedData.orderDetailsList = arrSelectedItems;
  return selectedData;
};

export default memo(DialogOrderDetail);

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    padding: "40px 36px 0",
    color: "#08080a",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0 0",
    },
  },
  iconButtonClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    padding: 0,
  },
  iconClose: {
    width: "inherit",
    height: "inherit",
  },

  titleText: {
    marginBottom: 54,
    fontSize: 24,
    lineHeight: 1.33,
    textAlign: "center",
    [theme.breakpoints.down(1240)]: {
      margin: "24px 24px 32px",
      height: 40,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0 32px",
      height: 40,
    },
  },

  boxInfo: {
    display: "flex",
    padding: "0 0 16px 40px",
  },
  textInfo: {
    marginRight: 24,
    color: "#08080a",
  },
  id: {
    marginBottom: 10,
    fontSize: 18,
    fontWeight: 500,
  },
  time: {
    fontSize: 13,
  },
  orderStatus: {
    minWidth: 100,
    height: 3,
    borderRadius: 4,
    color: theme.palette.white,
    textAlign: "center",
    textTransform: "capitalize",
  },

  boxBody: {
    width: "100%",
    height: "calc(100% - 198px)",
    padding: "0 40px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  listProductRoot: {
    width: "75%",
    height: "calc(100vh - 198px)",
    minHeight: 700,
    marginBottom: 36,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 12px",
    },
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
}));
