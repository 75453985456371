import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const ReportIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g transform="translate(-40 -418) translate(40 418) translate(2 2)">
        <path
          fill="#4B4D53"
          d="M5.5 10L8.5 10 8.5 20 5.5 20zM11 12L14 12 14 20 11 20 11 12zM16.5 5L19.5 5 19.5 20 16.5 20 16.5 5zM0 14L3 14 3 20 0 20z"
        />
        <path stroke="#4B4D53" strokeWidth=".5" d="M2.35 8.95L7.65 5.65M7.15 6.35L11.5 7.5M12.95 7.95L17.5 1.5" />
        <path d="M5.5 10L8.5 10 8.5 20 5.5 20zM11 12L14 12 14 20 11 20 11 12zM16.5 5L19.5 5 19.5 20 16.5 20 16.5 5zM0 14L3 14 3 20 0 20z" />
        <path fill="currentColor" fillRule="nonzero" d="M2.35 8.95L7.65 5.65M7.15 6.35L11.5 7.5M12.95 7.95L17.5 1.5" />
        <circle cx="1.5" cy="10" r="1" fillRule="nonzero" />
        <circle cx="7" cy="6" r="1" fillRule="nonzero" />
        <circle cx="12.5" cy="8" r="1" fillRule="nonzero" />
        <circle cx="18" cy="1" r="1" fillRule="nonzero" />
      </g>
    </SvgIcon>
  );
};

ReportIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
ReportIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(ReportIcon);
