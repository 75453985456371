import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import MnNewsAction from "redux/mn-news.redux";
import { AppConstant, LangConstant } from "const";
import { getAttachmentUrl, getCommonKey, isArrayNotEqual, removeUndefinedValueInArray, validateFileSize } from "utils";
import { FileUploadIcon } from "icons";
import UploadImageCell from "./UploadImageCell";
import CropImage from "components/CropImage";

const UploadImage = ({ onChange, isDisabled, imageList }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const imageURI = useSelector(state => state.newsRedux.postImgUrl, shallowEqual);

  const [fileArray, setFileArray] = useState([]);
  const [urlArray, setUrlArray] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [clickBoxAvailableStatus, setClickBoxAvailableStatus] = useState(true);
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);
  const [inputImageCrop, setInputImageCrop] = useState([]);

  const onRemoveItemImage = index => {
    let newFileArray = [...fileArray];
    let newUrlArray = [...urlArray];
    newFileArray.splice(index, 1);
    newUrlArray.splice(index, 1);
    setFileArray(newFileArray);
    setUrlArray(newUrlArray);
    setInputImageCrop(newFileArray);
    onChange(newUrlArray, null);
  };

  const onUploadMultipleFiles = e => {
    let uploadFile = e.target.files;
    let arrFiles = [...fileArray];
    if (fileArray.length < MAX_FILE_AMOUNT) {
      let availableSpace = MAX_FILE_AMOUNT - fileArray.length;
      let availableAmount = uploadFile.length < availableSpace ? uploadFile.length : availableSpace;
      let containInvalid = false;

      for (let i = 0; i < availableAmount; i++) {
        if (!validateFileSize(uploadFile[i].size)) containInvalid = true;
        arrFiles.push(uploadFile[i]);
      }

      if (containInvalid) onChange(null, getLabel(getCommonKey(LangConstant.TXT_EXCEEDED_CAPACITY_UPLOAD_IMAGE)));

      dispatch(MnNewsAction.requestUploadPostImage({ file: arrFiles }));

      setFileArray(arrFiles);
      setInputImageCrop(arrFiles);
    }
    e.target.value = null;
  };

  const onOpenCropDialog = index => {
    setIsOpenCropDialog(true);
    setSelectedIndex(index);
  };

  const onUploadFileCrop = files => {
    let newCutFiles = [];
    files.forEach(file => {
      if (validateFileSize(file.size)) {
        newCutFiles.push(file);
      }
    });

    if (newCutFiles.length > 0) {
      dispatch(MnNewsAction.mnNewsReset());
      dispatch(MnNewsAction.requestUploadPostImage({ file: newCutFiles }));
    } else {
      onChange(null, getLabel(getCommonKey(LangConstant.TXT_EXCEEDED_CAPACITY_UPLOAD_IMAGE)));
    }
  };

  useEffect(() => {
    setClickBoxAvailableStatus(fileArray.length < MAX_FILE_AMOUNT);
  }, [fileArray]);

  useEffect(() => {
    if (imageURI.length > 0 && isArrayNotEqual(imageURI, urlArray)) {
      setUrlArray(imageURI);
      onChange(imageURI, null);
    }
  }, [imageURI]);

  let isInputNotNull = Boolean(fileArray.length > 0);
  let cleanedImageList = removeUndefinedValueInArray(imageList);

  return (
    <>
      <form className={classes.uploadFormGroup}>
        <Box className={isInputNotNull ? classes.previewBoxNotNull : classes.previewBoxIsNull}>
          <Box className={classes.uploadContainer}>
            {fileArray.length === 0 && cleanedImageList.length > 0 ? (
              <Grid container className={classes.previewBox}>
                {cleanedImageList.map((url, index) => (
                  <Grid className={classes.gridPreviewItem} item key={index} xs={12 / cleanedImageList.length}>
                    <UploadImageCell url={getAttachmentUrl(url)} isDisabled={true} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              fileArray.length > 0 && (
                <Grid container className={classes.previewBox}>
                  {urlArray.map((url, index) => (
                    <Grid className={classes.gridPreviewItem} item key={index} xs={12 / fileArray.length}>
                      <UploadImageCell
                        url={getAttachmentUrl(url)}
                        onOpenCrop={() => onOpenCropDialog(index)}
                        onRemoveImage={() => onRemoveItemImage(index)}
                      />
                    </Grid>
                  ))}
                </Grid>
              )
            )}

            {fileArray.length < MAX_FILE_AMOUNT && (
              <label
                htmlFor="uploadInput"
                className={clsx(
                  isInputNotNull ? classes.inputClickBoxPickedImg : classes.inputClickBoxInitial,
                  clickBoxAvailableStatus ? classes.inputClickBoxNotFull : classes.inputClickBoxFull,
                )}
              >
                <Typography className={clsx(classes.uploadFileButton, "semiBold-lg-txt")}>
                  <FileUploadIcon /> {getLabel(LangConstant.TXT_UPLOAD_IMAGE)}
                </Typography>
              </label>
            )}
          </Box>
          <Typography className={clsx(classes.uploadFileDescription, "regular-sm-txt")}>
            {getLabel(LangConstant.TXT_UPLOAD_IMAGE_INSTRUCTION)}
          </Typography>
        </Box>

        {!isDisabled && (
          <input
            type="file"
            onChange={onUploadMultipleFiles}
            className={classes.fileUploadInput}
            id="uploadInput"
            disabled={isDisabled}
            accept={AppConstant.ACCEPT_IMAGE.join(",")}
            multiple={true}
          />
        )}
      </form>

      <CropImage
        isMultiple={true}
        isEnableCrop={true}
        inputArray={inputImageCrop}
        aspect={4 / 3}
        onSubmit={file => {
          onUploadFileCrop(file);
        }}
        isShow={isOpenCropDialog}
        selectedImage={selectedIndex}
        title={getLabel(LangConstant.TXT_EDIT_IMAGE)}
        onClose={() => {
          setIsOpenCropDialog(false);
          setSelectedIndex(null);
        }}
      />
    </>
  );
};

UploadImage.propTypes = {
  isDisabled: PropTypes.bool,
  imageList: PropTypes.array,

  onChange: PropTypes.func,
};

UploadImage.defaultProps = {
  isDisabled: false,
  imageList: [],

  onChange: () => {},
};

const MAX_FILE_AMOUNT = 4;

const useStyles = makeStyles(theme => ({
  inputClickBoxInitial: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minHeight: 150,
    width: "-webkit-fill-available",
    cursor: "pointer",
  },
  inputClickBoxPickedImg: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
  },
  inputClickBoxFull: {
    minWidth: 430,
    minHeight: 130,
    display: "none",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 200,
    },
  },
  inputClickBoxNotFull: {
    display: "flex",
    marginTop: 24,
  },

  previewBoxNotNull: {
    color: "#d4d5d8",
    border: "solid 1px #d4d5d8",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "140px",
    },
  },

  previewBoxIsNull: {
    borderRadius: 3,
    border: "solid 1px #d4d5d8",
  },

  uploadFormGroup: {
    width: 430,
    height: "fit-content",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      minHeight: 70,
      minWidth: 200,
    },
  },
  addIconInitial: {
    position: "relative",
    width: "50%",
    height: 114,
    color: "#d4d5d8",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 200,
    },
  },
  addIconInitialDefault: {
    position: "relative",
    width: 80,
    height: 80,
    color: "#d4d5d8",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 90,
    },
  },

  addIconPickedInput: {
    position: "relative",
    width: 28,
    height: 28,
    color: "#d4d5d8",
  },

  fileUploadInput: {
    display: "none",
  },

  uploadFileButton: {
    textTransform: "none",
    background: theme.palette.white,
    boxShadow: "1px 0px 4px rgba(0, 0, 0, 0.16)",
    height: 30,
    minHeight: "unset",
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  previewBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "24px 36px",
    paddingBottom: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "24px 5px",
    },
  },

  uploadContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  uploadFileDescription: {
    lineHeight: "20px",
    margin: "8px 0",
  },

  gridPreviewItem: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default UploadImage;
