import React, { memo } from "react";
import { makeStyles, Box, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import PackageItem from "./PackageItem";

const UpgradePlanTable = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  const selectedTab = 1;

  return (
    <Paper elevation={1} square>
      <Typography className={classes.titleText}>{getLabel(LangConstant.TXT_UPGRADE_PLAN)}</Typography>
      <Box className={classes.contentBox}>
        <Box className={classes.planDescription}>
          {PLAN_DESCRIPTION.map((item, index) => (
            <Box key={index} className={classes.boxItemPlanDescription}>
              <Typography className={classes.titleDescription}>{getLabel(item.title)}</Typography>
              <Typography className={classes.contentDescription}>{getLabel(item.content)}</Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.boxPackageParent}>
          {PACKAGE.map((item, index) => (
            <PackageItem key={index} isSelected={selectedTab === index} item={item} />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

const PLAN_DESCRIPTION = [
  {
    title: LangConstant.TXT_INCLUDE_BOOKING_ORDER,
    content: LangConstant.TXT_ONLINE_APPOINTMENT,
  },
  {
    title: LangConstant.TXT_CREATE_SHOP,
    content: LangConstant.TXT_ONLINE_APPOINTMENT,
  },
  {
    title: LangConstant.TXT_BASIC_MN_BOOKING,
    content: LangConstant.TXT_BASIC_BOOKING_DESCRIPTION,
  },
  {
    title: LangConstant.TXT_ADVANCE_MN_BOOKING,
    content: LangConstant.TXT_ADVANCE_BOOKING_DESCRIPTION,
  },
  {
    title: LangConstant.TXT_TIME_DISPLAY_REMOTE,
    content: LangConstant.TXT_ONLINE_APPOINTMENT,
  },
];

const PACKAGE = [
  {
    package: LangConstant.TXT_PACKAGE_STANDARD,
    price: 250000,
    include: 1,
    createShop: 1,
    basicBooking: true,
    advanceBooking: false,
    limitRemote: 3,
  },
  {
    package: LangConstant.TXT_PACKAGE_PREMIUM,
    price: 2000000,
    include: 1,
    createShop: 5,
    basicBooking: true,
    advanceBooking: true,
    limitRemote: LangConstant.TXT_UNLIMITED,
  },
  {
    package: LangConstant.TXT_PACKAGE_ENTERPRISE,
    price: LangConstant.TXT_CONTACT_US,
    include: LangConstant.TXT_BOTH,
    createShop: 10,
    basicBooking: true,
    advanceBooking: true,
    limitRemote: LangConstant.TXT_UNLIMITED,
  },
];

export default memo(UpgradePlanTable);

const useStyles = makeStyles(theme => ({
  titleText: {
    width: "100%",
    padding: "48px 100px",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.33,
    color: theme.palette.grey[800],
    [theme.breakpoints.down("xs")]: {
      padding: 24,
    },
  },
  contentBox: {
    display: "flex",
    width: "100%",
    padding: "36px 100px 121px",
    [theme.breakpoints.down(1250)]: {
      flexDirection: "column",
      padding: "0 50px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "0 24px",
    },
  },
  boxStatus: {
    height: 30,
  },
  planDescription: {
    width: "100%",
    marginTop: 135,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down("xs")]: {
      margin: "40px 0",
    },
  },
  boxItemPlanDescription: {
    minHeight: 80,
    padding: "16px 0",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  titleDescription: {
    fontSize: 17,
    lineHeight: 1.53,
    color: "#3e4045",
  },
  contentDescription: {
    fontSize: 13,
    lineHeight: 1.69,
    color: theme.palette.grey[500],
  },
  boxPackageParent: {
    display: "flex",
    width: 609,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));
