import React from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import BookingHistoryTable from "./components/BookingHistoryTable";
import FilterMenu from "./components/FilterMenu";
import { BglobalJSC } from "components";
import BookingHistoryPagination from "./components/BookingHistoryPagination";

const BookingHistory = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Paper className={classes.bookingHistoryPaper}>
        <FilterMenu />
        <BookingHistoryTable />
        <BookingHistoryPagination />
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};
export default BookingHistory;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  bookingHistoryPaper: {
    width: "100%",
    padding: 16,
    height: "fit-content",
  },
  boxJSC: {
    marginTop: "auto",
    marginBottom: 24,
  },
}));
