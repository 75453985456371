import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const Warning = ({ onClick }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const onPopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const onPopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <WarningIcon
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
        onMouseEnter={onPopoverOpen}
        onMouseLeave={onPopoverClose}
        className={classes.warningIcon}
        onClick={onClick}
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
        disableRestoreFocus
      >
        <Grid className={classes.textWarningGrid}>
          <Typography className={classes.textWarning}>{getLabel(LangConstant.TXT_OUT_OF_DATE)}</Typography>
        </Grid>
      </Popover>
    </>
  );
};

Warning.propTypes = {
  onClick: PropTypes.func,
};
Warning.defaultProps = {
  onClick: () => {},
};

export default memo(Warning);

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: "none",
  },
  textWarningGrid: {
    width: 60,
    height: 16,
    backgroundColor: "#FFAF0A",
    paddingTop: "5%",
  },
  textWarning: {
    objectFit: "contain",
    fontSize: 8,
    fontWeight: 500,
    color: theme.palette.white,
    textAlign: "center",
  },
  warningIcon: {
    fontSize: 17,
    color: "#feba40",
    cursor: "pointer",
  },
}));
