import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, OutlinedInput } from "@material-ui/core";
import { Search } from "@material-ui/icons";
const SearchBar = ({ placeholder, value, onChange, onKeyUp, className, ...otherProps }) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(value);
  const onTyping = event => {
    if (Boolean(onChange)) {
      onChange(event.target.value);
    }
    setInputValue(event.target.value);
  };
  const onKeyUpInput = event => {
    if (Boolean(onKeyUp) && (event.key === "Enter" || event.keyCode === 13)) {
      onKeyUp(event.target.value);
    }
  };
  return (
    <OutlinedInput
      placeholder={placeholder}
      type="text"
      value={inputValue}
      onChange={onTyping}
      classes={{
        input: `${classes.input} medium-md-txt`,
        root: `${classes.root} ${className}`,
        notchedOutline: classes.notchedOutline,
      }}
      startAdornment={<Search className={classes.rootIcon} />}
      onKeyUp={onKeyUpInput}
      {...otherProps}
    />
  );
};
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginRight: 8,
    paddingRight: 0,
    borderRadius: "unset",
    border: `1px solid ${theme.palette.grey["200"]}`,
  },
  rootIcon: {
    fontSize: 24,
    color: theme.palette.grey["700"],
  },
  notchedOutline: {
    border: "none",
  },
  input: {
    height: "unset",
    padding: "0 20px",
    color: theme.palette.text.primary,
    "&::placeholder": {
      color: theme.palette.text.primary,
      opacity: 1,
    },
    "@media only screen and (max-width: 600px)": {
      padding: "0 10px",
    },
  },
  icon: {
    minWidth: 40,
    width: 40,
    height: "100%",
    maxHeight: "100%",
    minHeight: 30,
    justifyContent: "center",
    borderRight: `1px solid ${theme.palette.grey["200"]}`,
    marginRight: 0,
  },
}));
SearchBar.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
};
SearchBar.defaultProps = { placeholder: "", value: "", className: "", type: "" };
export default memo(SearchBar);
