import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Select, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { LangConstant } from "const";
import DropDownCategoryItem from "./DropDownCategoryItem";

const SelectCategory = props => {
  const { value, classes, data, itemsKey, onChange } = props;
  const widthMenuPaper = document.getElementById("select-category")?.offsetWidth;
  const { t: getLabel } = useTranslation();
  const defaultClasses = useStyles({ widthMenuPaper: widthMenuPaper });
  let isValidData = data && data.length > 0;
  const [selectedValue, setSelectedValue] = useState(getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE));
  const [staffMenuAnchorEl, setStaffMenuAnchorEl] = useState(null);

  const onSelectCategoryItem = selectedItem => {
    setSelectedValue(selectedItem?.name);
    onChange(selectedItem);
    setStaffMenuAnchorEl(null);
  };

  const onSelectCategory = selectedCategory => {
    if (Boolean(selectedCategory)) {
      setSelectedValue(selectedCategory?.name);
      onChange(selectedCategory);
    } else {
      setSelectedValue(getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE));
      onChange();
    }
    setStaffMenuAnchorEl(null);
  };

  const onCloseStaffMenu = () => setStaffMenuAnchorEl(null);

  const onOpenStaffMenu = () => setStaffMenuAnchorEl(true);

  const onRenderValue = () => {
    return (
      <Typography color="textPrimary" className={clsx(defaultClasses.menuItems, "medium-md-txt")}>
        {selectedValue}
      </Typography>
    );
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Select
      open={Boolean(staffMenuAnchorEl)}
      onClose={onCloseStaffMenu}
      onOpen={onOpenStaffMenu}
      className={clsx(defaultClasses.root, "no-border", classes?.root)}
      displayEmpty
      id="select-category"
      value={selectedValue}
      renderValue={onRenderValue}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        className: defaultClasses.selectProps,
      }}
      classes={{
        root: clsx(defaultClasses.selectRoot, classes?.selectRoot),
        icon: defaultClasses.selectIcon,
        select: defaultClasses.select,
      }}
    >
      {isValidData ? (
        data.map((category, index) => (
          <DropDownCategoryItem
            onSelectCategoryItem={onSelectCategoryItem}
            onSelectCategory={onSelectCategory}
            category={category}
            items={category[itemsKey] || []}
            key={index}
          />
        ))
      ) : (
        <Box className={defaultClasses.noResultBox}>
          <Typography variant="body2" color="inherit" classes={{ body2: defaultClasses.noResult }}>
            {getLabel(LangConstant.TXT_RESULT_NOT_FOUND)}
          </Typography>
        </Box>
      )}
    </Select>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    border: `solid 1px ${theme.palette.grey[200]}`,
    borderRadius: 3,
    "&.no-border": {
      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
  },
  selectRoot: {
    height: "max-content",
    width: "100%",
    color: "#d4d5d8",
    padding: "10px 16px 10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  select: { paddingRight: 0 },
  selectIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  label: { marginLeft: 16, marginRight: 30 },
  menuItems: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
    paddingLeft: 8,
  },
  noResultBox: {
    color: "#7f838c",
    margin: "10px 16px 0",
  },
  selectProps: {
    marginTop: 4,
    maxHeight: "calc(100vh - 190px)",
    width: props => props.widthMenuPaper,
  },
}));

SelectCategory.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string,
  data: PropTypes.array.isRequired,
  itemsKey: PropTypes.string,
  onChange: PropTypes.func,
};

SelectCategory.defaultProps = {
  classes: {},
  value: "",
  data: [],
  itemsKey: "items",
  onChange: () => {},
};

export default memo(SelectCategory);
