import React, { memo, useEffect, useState } from "react";
import { makeStyles, Box, Button } from "@material-ui/core";
import CustomSearchBar from "./CustomSearchBar";
import { useTranslation } from "react-i18next";
import IndividualPost from "./IndividualPost";
import MnNewsAction from "../../../redux/mn-news.redux";
import { useDispatch, useSelector } from "react-redux";
import Cookie from "js-cookie";
import { AppConstant, LangConstant } from "../../../const/index";
import { getCreatedTime } from "../../../utils/date";

const Post = ({ uid, avt, alt, title, time, image, postContent, publish, fbID, showData }) => (
  <Box className="post">
    <IndividualPost
      showData={showData}
      avt={avt}
      alt={alt}
      time={time}
      title={title}
      postImage={image}
      postContent={postContent}
      publish={publish}
      uid={uid}
      fbID={fbID}
    />
  </Box>
);
const PostList = props => {
  const { changeTabs } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const role = Cookie.get(AppConstant.ROLE);
  const dispatch = useDispatch();
  const dataShopRedux = useSelector(state => state.shopRedux.dataShop);
  const isDeleteSuccess = useSelector(state => state.newsRedux.isRemoveSuccess);
  const news = useSelector(state => state.newsRedux.news);

  const [data, setData] = useState(news);
  const [showData, setShowData] = useState({ cover: "", name: "" });
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setShowData(dataShopRedux ? dataShopRedux : { cover: "", name: "" });
  }, [dataShopRedux]);

  useEffect(() => {
    dispatch(
      MnNewsAction.getNews({
        orderBy: "created",
        asc: 0,
        content: searchValue,
      }),
    );
    setData(news);
  }, [searchValue]);
  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(MnNewsAction.getNews());
      dispatch(MnNewsAction.mnNewsReset());
    }
  }, [isDeleteSuccess]);
  useEffect(() => {
    setData(news);
  }, [news]);

  const onSearching = e => {
    if (e.keyCode === 13) {
      setSearchValue(e.target.value);
    }
  };
  const changing = e => {
    if (!e.target.value) dispatch(MnNewsAction.getNews({ orderBy: "created", asc: 0, content: searchValue }));
  };

  return (
    <Box className={props.className}>
      <Box className={classes.searchBarContainer}>
        <CustomSearchBar
          className={classes.SearchBarNews}
          placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)}
          onKeyDown={onSearching}
          onChange={changing}
        />
        {!(AppConstant.SHOP_STAFF === role) && (
          <Button className={`${classes.onUploadPostButton} medium-md-txt`} onClick={changeTabs}>
            {getLabel(LangConstant.TXT_NEWS_POSTUPLOADNAV_MOBILEBTN)}
          </Button>
        )}
      </Box>
      <Box className={classes.listField}>
        {data &&
          data.map((post, index) => {
            return (
              <Post
                key={index}
                postContent={post.content}
                image={post.image}
                publish={post.publish}
                uid={post.uid}
                time={getCreatedTime(post.created, getLabel)}
                fbID={post.facebook_post_id}
                showData={showData}
              />
            );
          })}
      </Box>
    </Box>
  );
};
export default memo(PostList);

const useStyles = makeStyles(theme => ({
  onUploadPostButton: {
    display: "none",
    "@media only screen and (max-width: 414px)": {
      display: "inline-flex",
      textTransform: "none",
      width: "23vw",
      color: theme.palette.white,
      backgroundColor: theme.palette.reservation.finished,
      borderRadius: "21.5px",
      marginLeft: "10px",
    },
  },
  searchBarContainer: {
    backgroundColor: "#ffffff",
    padding: "1vh 10px 1vh 10px",
    position: "sticky",
    width: "100%",
    zIndex: 2,
    "@media only screen and (max-width: 414px)": {
      width: "100%",
      height: "6vh",
      display: "inline-flex",
    },
    top: 0,
    right: 0,
    left: 0,
  },
  listField: {
    overflow: "auto",
  },
}));
