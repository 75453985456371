// Common
export const HOST_REGISTRATION = "https://api.gboss.io/registration";
export const HOST_RESERVATION = "https://api.gboss.io/reservation";
export const HOST_ATTACHMENT = "https://api.gboss.io";
export const HOST_CONSUMER = "https://cons.gboss.io";
export const HOST_SHOP = "https://shop.gboss.io";
export const HOST_OAUTH = "https://api.gboss.io";

export const HEADER_DEFAULT = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const HEADER_DEFAULT_UPLOAD_FILE = {
  ...HEADER_DEFAULT,
  "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
};
export const HEADER_FORM = {
  "Content-Type": "multipart/form-data",
};
export const TIMEOUT = 15000;
export const RESPONSE_TYPE_DOWNLOAD_EXCEL = "blob";

//Fixed value
export const SHOP_URL = HOST_CONSUMER + "/shops/{0}";

// HTTP Status
export const STT_OK = 200;
export const STT_BAD_REQUEST = 400;
export const STT_UNAUTHORIZED = 401;
export const STT_FORBIDDEN = 403;
export const STT_NOT_FOUND = 404;
export const STT_UN_PROCESSABLE_ENTITY = 422;
export const STT_INTERNAL_SERVER = 500;

// Api
export const POST_LOGIN = "/auth/shop/login/multi-role";
export const POST_RESET_PASSWORD = "/user/reset-password";

// Api report
export const REPORT_BOOKING_DAY = "/adjustment/reservation/day";
export const REPORT_BOOKING_WEEK = "/adjustment/reservation/week";
export const REPORT_BOOKING_MONTH = "/adjustment/reservation/month";
export const REPORT_CONSUMER = "/adjustment/reservation/consumer";
export const REPORT_STAFF = "/adjustment/reservation/staff";
export const GET_REPORT_SUMMARY_RESERVATION = "/adjustment/overview/reservation";
export const GET_REPORT_SUMMARY_CUSTOMER = "/adjustment/overview/consumer";
export const GET_REPORT_SUMMARY_CATEGORY = "/adjustment/overview/category";

export const GET_SHOP_INFO = "/shop/{0}";
export const UPDATE_SHOP_INFO = "/shop/{0}";
export const GET_USER_INFO = "/user/{0}";
export const GET_USER_SHOP_INFO = "/user/shop-information";
export const UPDATE_USER_INFO = "/user/{0}";
export const UPDATE_USER_PASSWORD = "/user/change-password";
export const UPDATE_RESERVATION_SETTING = "/reservation-setting";
export const UPDATE_RESERVATION_STATUS = "/reservation/{0}/status";
export const PATCH_SEEN_NOTIFICATION = "/notification/mark-as-read";
export const GET_RESERVATION_PENDING = "/notification";

export const UPLOAD_ATTACHMENT = "/attachment/upload";

// Api shop info
export const GET_PLACE_CATEGORY = "/place-category";
export const POST_PLACE_CATEGORY = "/place-category";
export const DELETE_PLACE_CATEGORY = "/place-category/{0}";
export const PUT_PLACE_CATEGORY = "/place-category/{0}";

export const SHOP_LINK_FACEBOOK = "facebook-page";
export const SHOP_LINK_UPDATE = "facebook-page/{0}";

export const GET_PLACE_ITEM = "/place-item/{0}";
export const POST_PLACE_ITEM = "/place-item";
export const PATCH_PLACE_ITEM = "/place-item";
export const PUT_PLACE_ITEM = "/place-item/{0}";
export const DELETE_PLACE_ITEM = "/place-item/{0}";
export const GET_PLACE_BUSY_STATUS = "/place-item/check";
export const GET_PLACE_ITEM_AUTO_FILL = "/place-item/auto-fill";

export const GET_SERVICE_CATEGORY = "/service-category";
export const POST_SERVICE_CATEGORY = "/service-category";
export const PUT_SERVICE_CATEGORY = "/service-category/{0}";
export const DELETE_SERVICE_CATEGORY = "/service-category/{0}";

// Api reservation
export const GET_RESERVATION_DAY = "/reservation/day";
export const GET_RESERVATION_WEEK = "/reservation/week";
export const GET_RESERVATION_MONTH = "/reservation/month";
export const POST_RESERVATION = "/reservation";
export const PUT_RESERVATION_STATUS = "/reservation/{uid}/status";
export const PUT_RESERVATION_DETAILS = "/reservation/{uid}/details";
export const GET_RESERVATION_SETTING = "/reservation-setting";
export const GET_RESERVATION_EXPORT = "/reservation/export";
export const GET_RESERVATION = "/reservation/{uid}";
export const GET_RESERVATION_PERIODIC = "/reservation/{uid}/periodic";
export const POST_RESERVATION_EXTEND = "/reservation/extend";
export const POST_RESERVATION_LIST_STATUS = "/reservation/status";
export const GET_AVAILABLE_STATUS = "/reservation/available?time={0}&total_consumer={1}";
export const GET_RESERVATION_ACTIVITY = "/reservation/activities";
export const GET_BOOKING_HISTORY = "/reservation/history";
export const GET_RESERVATION_NEW = "/reservation/new";
export const GET_RESERVATION_COUNT = "/reservation/count";
export const GET_RESERVATION_TYPE = "/reservation";

// Api staff
export const GET_STAFF_TABLE = "/staff";
export const GET_WORKING_SHIFT = "/working-shift";
export const GET_STAFF_POSITION = "/staff-position";
export const PATCH_INVITATION_STAFF = "/invitation/staff";
export const STAFF = "/staff";
export const GET_STAFF_BUSY_STATUS = "/staff/check";
export const WORKING_SHIFT = "/working-shift";
export const STAFF_POSITION = "/staff-position";
export const UPDATE_STAFF = "/staff/{0}";
export const POST_WORKING_SHIFT = "/working-shift";
export const POST_POSITION = "/staff-position";
export const GET_POSITION = "/staff-position";
export const DELETE_WORKING_SHIFT = "/working-shift/{0}";
export const DELETE_GET_POSITION = "/staff-position/{0}";
export const PUSH_GET_POSITION = "/staff-position/{0}";
export const PUSH_WORKING_SHIFT = "/working-shift/{0}";

//Api product good
export const GET_PRODUCT_CATEGORY = "/product-category";
export const POST_PRODUCT_CATEGORY = "/product-category";
export const PUSH_PRODUCT_CATEGORY = "/product-category/{0}";
export const DELETE_PRODUCT_CATEGORY = "/product-category/{0}";
export const GET_PRODUCT_ITEM_UID_CATEGORY = "/product-item?category_uid={0}";
export const GET_PRODUCT_ITEM = "/product-item";
export const POST_PRODUCT_ITEM = "/product-item";
export const PUSH_PRODUCT_ITEM = "/product-item/{0}";
export const DELETE_PRODUCT_ITEM = "/product-item/{0}";

// Api details
export const GET_DETAILS = "/item-details";
export const GET_DETAILS_UID = "/item-details/{0}";
export const POST_DETAILS = "/item-details";
export const PUSH_DETAILS = "/item-details/{0}";
export const PUSH_DETAILS_EDIT_CONTENT = "/item-details/edit-content/{0}";
export const DELETE_DETAILS = "/item-details/{0}";
export const GET_DETAILS_CONTENT = "/item-details-content";
export const GET_DETAILS_CONTENT_UID = "/item-details-content/{0}";
export const POST_DETAILS_CONTENT = "/item-details-content";
export const PUSH_DETAILS_CONTENT = "/item-details-content/{0}";
export const DELETE_DETAILS_CONTENT = "/item-details-content/{0}";

// Api consumer
export const GET_CONSUMER = "/consumer";
export const PATCH_INVITATION_CONSUMER = "/invitation/consumer";
export const GET_EMAIL_CONSUMER_QUANTITY = "/invitation/consumer/email/quantity";
export const GET_INVITATION_FACEBOOK = "/invitation/facebook/potential-consumer";
export const PATCH_INVITATION_FACEBOOK = "/invitation/consumer/facebook";
export const POST_CONSUMER_LIST = "/consumer/import";

// invitation
export const PATCH_INVITATION_ACCEPT = "/invitation/accept";

// Api news
export const MN_NEWS = "/news";
export const MN_NEWS_UPDATE = "/news/{0}";
export const UPLOAD_ATTACHMENT_NEWS = "/attachment/upload";

// Api user
export const USER_CHART_DISPLAY_SETTING = "/user/display-setting";

export const OAUTH2_REDIRECT_URI = HOST_SHOP + "/auth-social";
export const REGISTER_BY_GOOGLE =
  HOST_OAUTH + "/oauth2/authorize/cons/signup/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const REGISTER_BY_FACEBOOK =
  HOST_OAUTH + "/oauth2/authorize/cons/signup/facebook?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const LOGIN_BY_GOOGLE = HOST_OAUTH + "/oauth2/authorize/shop/login/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const LOGIN_BY_FACEBOOK =
  HOST_OAUTH + "/oauth2/authorize/shop/login/facebook?redirect_uri=" + OAUTH2_REDIRECT_URI;

// Api Service Item
export const SERVICE_ITEM = "/service-item";
export const SERVICE_ITEM_UID = "/service-item/{0}";
export const SERVICE_ITEM_CATEGORY_UID = "/service-item?category_uid={0}";

// Api Item Details
export const ITEM_DETAILS = "/item-details";
export const ITEM_DETAILS_UID = "/item-details/{0}";
export const EDIT_CONTENT = "/item-details/edit-content/{0}";

// Api Item Details Content
export const ITEM_DETAILS_CONTENT = "/item-details-content";
export const ITEM_DETAILS_CONTENT_UID = "/item-details-content/{0}";

// Api Combo
export const COMBO_ITEM = "/combo-item";
export const COMBO_ITEM_UID = "combo-item/{0}";

// API Menu
export const MENU = "/menu";

// Api waiting consumer
export const WAITING_CONSUMER = "/waiting-consumer";
export const WAITING_CONSUMER_BY_UID = "/waiting-consumer/{0}";

// API place
export const GET_PLACE_STATUS = "/place-item/activity";

// API check payment
export const GET_CHECK_PAYMENT = "/package/payment/check";
export const GET_PACKAGE_CURRENT = "/package/current";
export const GET_PACKAGE = "/package";
export const GET_PAYMENT_HISTORY = "/package-payment/history";
export const GET_PAYMENT_DETAILS = "/package-payment/details/{0}";

// API rating
export const GET_RATING_ITEM = "/rating/item";
export const GET_RATING_SHOP = "/rating/shop";
export const GET_RATING_ITEM_SUMMARY = "/rating/item/summary";
export const GET_RATING_SHOP_SUMMARY = "/rating/shop/summary";
export const REPORT_RATING = "/rating/{0}/report";

// API VAT Bill
export const BILL_INFORMATION = "/invoice-information";
export const UPDATE_BILL_INFORMATION = "/invoice-information/{0}";
export const EXPORT_BILL_INFORMATION = "/invoice-information/export";
export const GET_RESERVATION_BILL_DETAIL = "/invoice-information/reservation/{0}";

// API group admin
export const GET_GROUP_ADMIN_BY_ALIAS = "/group-admin/alias/{0}";
export const JOIN_GROUP_ADMIN = "/group-admin/{0}/shop/request";
