import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close, Dehaze } from "@material-ui/icons";

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
const DialogSelectProduct = forwardRef((props, ref) => {
  const classes = useStyles();
  const { isShow, onAddService, onAddProduct, onAddCombo, action, inputProps, titleName } = props;

  return (
    <Dialog
      classes={{ paper: classes.dialogBackground }}
      fullScreen
      open={isShow}
      onClose={() => action(false)}
      ref={ref}
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => action(false)}
        aria-label="close"
        className={classes.closeDialogBtn}
      >
        <Close className={classes.closeIcon} />
      </IconButton>
      <Box>
        <Typography className={classes.header}>{titleName.header}</Typography>
      </Box>
      <Box className={classes.addItem} onClick={onAddService}>
        <Box className={classes.boxHeader}>
          <Box className={classes.icon}>
            <Dehaze className={classes.colorIcon} />
          </Box>
          <Box className={classes.textProduct}>
            <Typography {...inputProps}>{titleName.service}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.createItem} onClick={onAddProduct}>
        <Box className={classes.boxHeader}>
          <Box className={classes.icon}>
            <Dehaze className={classes.colorIcon} />
          </Box>
          <Box className={classes.textProduct}>
            <Typography {...inputProps}>{titleName.product}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.createItem} onClick={onAddCombo}>
        <Box className={classes.boxHeader}>
          <Box className={classes.icon}>
            <Dehaze className={classes.colorIcon} />
          </Box>
          <Box className={classes.textProduct}>
            <Typography {...inputProps}>{titleName.combo}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
});

export default DialogSelectProduct;
const useStyles = makeStyles(theme => ({
  dialogBackground: {
    background: "#f2f2f7",
  },
  closeDialogBtn: {
    alignSelf: "flex-end",
    width: "36px",
    height: "36px",
    marginTop: "21px",
    marginRight: "21px",
  },
  closeIcon: {
    width: 36,
    height: 36,
  },
  header: {
    textAlign: "center",
    color: theme.palette.grey[800],
    fontSize: 24,
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  addItem: {
    width: "70%",
    minHeight: 80,
    marginTop: 100,
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    marginLeft: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  createItem: {
    width: "70%",
    minHeight: 80,
    marginTop: 30,
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    marginLeft: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  icon: {
    marginLeft: "10%",
    backgroundColor: "#ebf7fe",
    marginTop: 28,
    paddingTop: 5,
    width: 36,
    height: 36,
    textAlign: "center",
  },
  textProduct: {
    marginTop: 35,
    marginBottom: 28,
    marginLeft: 10,
    width: "85%",
    height: "100%",
    fontSize: 16,
    textAlign: "left",
  },
  boxHeader: {
    display: "flex",
    cursor: "pointer",
  },
  colorIcon: {
    color: "#7f838c",
  },
}));

DialogSelectProduct.propTypes = {
  isShow: PropTypes.bool.isRequired,
  action: PropTypes.func,
  inputProps: PropTypes.object,
  titleName: PropTypes.object.isRequired,
};

DialogSelectProduct.defaultProps = {};
