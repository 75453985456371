import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import AuthLayout from "layouts/AuthLayout";
import StringFormat from "string-format";
import { FormJoinGroup } from "./components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GroupAdminAction from "redux/group-admin.redux";
import { useParams } from "react-router-dom";

const JoinGroup = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_GROUP_ADMIN);
  const dispatch = useDispatch();
  const { groupAlias } = useParams();
  const dataGroupAdmin = useSelector(({ groupAdminRedux }) => groupAdminRedux.dataGroup, shallowEqual);

  useEffect(() => {
    if (groupAlias) dispatch(GroupAdminAction.getGroupAdmin(groupAlias));
  }, [groupAlias]);

  return (
    <AuthLayout intro={StringFormat(getLabel(LangConstant.MSG_WELCOME_GROUP_ADMIN), dataGroupAdmin?.name)}>
      <FormJoinGroup dataGroupAdmin={dataGroupAdmin} />
    </AuthLayout>
  );
};

export default memo(JoinGroup);
