import React, { memo, useState } from "react";
import { makeStyles, Button, Box, Avatar } from "@material-ui/core";
import { LangConstant } from "../../../const";
import { DialogConfirm } from "../components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ConsumerAction from "../../../redux/consumer.redux";

const ListFacebook = props => {
  const { user, setUser, next, shopData } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const onLoadmore = () => {
    if (user !== null && next !== null) {
      dispatch(ConsumerAction.requestGetInvitationFacebook({ next: next }));
    }
  };

  const onInvite = (e, index) => {
    setOpen(true);
    setIndex(index);
  };

  const onSubmitData = (e, id) => {
    setOpen(false);
    let data = user.find(x => x.id === id);
    dispatch(ConsumerAction.patchInvitationFacebook({ senders: [data], invitation_link: shopData }));
    let newData = user.filter(x => x.id !== id);
    setUser(newData);
  };

  return (
    <ul id="list">
      {user &&
        user.map((data, index) => (
          <Box className={classes.boxInviteParent} key={index}>
            <Box>
              <Avatar />
            </Box>
            <Box className={classes.boxName}>{data.name}</Box>

            <Box className={classes.boxInvite}>
              <Button disableRipple onClick={e => onInvite(e, data.id)} className={classes.inviteButton}>
                {getLabel(LangConstant.TXT_INVITE)}
              </Button>
            </Box>
          </Box>
        ))}
      <Box className={next ? classes.boxSeeMore : classes.noneSeeMore}>
        <Button disableRipple onClick={onLoadmore} className={classes.seeMore}>
          {getLabel(LangConstant.TXT_SEE_MORE)}
        </Button>
      </Box>
      <DialogConfirm open={open} setOpen={setOpen} index={index} onSubmit={onSubmitData} />
    </ul>
  );
};

const useStyles = makeStyles(() => ({
  boxInviteParent: {
    display: "flex",
    marginBottom: 20,
    marginTop: 20,
  },
  boxName: {
    width: "100%",
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  boxInvite: {
    width: 30,
    marginTop: 10,
    marginBottom: 10,
  },
  inviteButton: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    color: "#447aff",
    minWidth: 30,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  boxSeeMore: {
    width: "100%",
    textAlign: "center",
  },
  noneSeeMore: {
    display: "none",
  },
  seeMore: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    color: "#447aff",
    minWidth: 30,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
}));

export default memo(ListFacebook);
