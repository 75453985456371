import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
  Button,
  DialogContent,
  TableCell,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Alert, CellBody, CellHead } from "components";
import { AppConstant, LangConstant } from "const";
import { Close } from "@material-ui/icons";
import { createArrayWithLabel, getCommonKey } from "utils";
import { validatePhone, validateEmail, validBirthday } from "utils/index";
import StringFormat from "string-format";
import clsx from "clsx";
import { DropdownMenu } from "components/mn-master-product";
import ConsumerAction from "redux/consumer.redux";
import { getTimestamp } from "utils/date";
import { parse as dateParse } from "date-fns";

const MnConsumerTableDialog = ({ data, open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_CONSUMER);

  let headData = LangConstant.ARR_CONSUMER_TABLE_DATA;
  let isSuccess = useSelector(({ consumerRedux }) => consumerRedux.isUploadConsumerSuccess);

  const [isEdit, setIsEdit] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isNoticeAlert, setIsNoticeAlert] = useState(false);
  const [isCheckedInvitation, setIsCheckInvitation] = useState(false);
  const [arrConsumer, setArrConsumer] = useState(data || []);
  const [arrErrorIndex, setArrErrorIndex] = useState([]); // array contains index of rows which have wrong format information
  const [arrIndexSelected, setArrIndexSelected] = useState([]); // array contains index of rows which selected by users
  const [contentNotice, setContentNotice] = useState("");
  const [invitationMethod, setInvitationMethod] = useState();

  const onSelectAll = event => {
    let isCheckedAll = Boolean(event.target.checked);

    let arrIndexSelected = isCheckedAll ? [...Array(arrConsumer.length).keys()] : [];
    let dataShow = arrConsumer.map(item => ({
      ...item,
      checked: isCheckedAll,
    }));

    setArrConsumer(dataShow);
    setArrIndexSelected(arrIndexSelected);
    setIsCheckAll(isCheckedAll);
  };

  const onSelect = (event, index) => {
    let isChecked = event.target.checked;

    let dataShow = [...arrConsumer];
    dataShow[index].checked = isChecked;

    let arrIndex = [...arrIndexSelected];
    let isExistedIndex = arrIndex.includes(index);
    if (isExistedIndex) arrIndex = arrIndex.filter(item => item !== index);
    else arrIndex.push(index);

    setArrIndexSelected(arrIndex);
    setArrConsumer(dataShow);
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  const onContinue = () => {
    if (isEdit) {
      // Incase we just done editing but still having error information remain then set enable edit button and get error item again
      let arrError = getArrErrorItemIndex(arrConsumer);
      setArrErrorIndex(arrError);

      setIsEdit(false);
    } else {
      let modifiedData = arrConsumer.filter((_, index) => !arrErrorIndex.includes(index));
      setArrConsumer(modifiedData);
      setArrErrorIndex([]);
    }
  };

  const onCancel = () => {
    let data = [...arrConsumer];
    let dataShow = data.map(item => {
      if (item.checked) delete item["checked"];
      return {
        ...item,
      };
    });

    setArrConsumer(dataShow);
    setArrIndexSelected([]);
    setIsCheckAll(false);
  };

  const onDelete = () => {
    setContentNotice(StringFormat(getLabel(LangConstant.FN_DELETE_SUCCESS_NOTICE), arrIndexSelected.length));
    let data = [...arrConsumer];

    let modifiedData = data.filter((_, index) => !arrIndexSelected.includes(index));

    setIsNoticeAlert(true);
    setArrConsumer(modifiedData);
    setArrIndexSelected([]);
    setArrErrorIndex(getArrErrorItemIndex(modifiedData));
  };

  const onInvite = () => {
    setIsCheckInvitation(!isCheckedInvitation);
  };

  const onChange = method => {
    setInvitationMethod(method);
  };

  const onConfirm = () => {
    // Convert birthday to timestamp before calling API
    let data = arrConsumer.map(item => ({
      ...item,
      birthday: getTimestamp(dateParse(item.birthday || "", AppConstant.FM_DD_MM_YYYY, new Date())),
    }));

    // Call API post consumer data list import from excel
    dispatch(ConsumerAction.postConsumerList({ data: data, invitationMethod: [invitationMethod] }));
  };

  const onChangeData = (event, index) => {
    let data = [...arrConsumer];
    let value = event.target.value;
    let name = event.target.name;

    let newData = { ...data[index], [name]: value };
    data[index] = newData;

    setArrConsumer(data);
  };

  useEffect(() => {
    if (data) {
      let arrData = [...data];
      let arrError = getArrErrorItemIndex(arrData);

      let newData = arrData.map(item => ({
        ...item,
        checked: false,
      }));

      if (arrError.length > 0) setArrErrorIndex(arrError);
      else setIsEdit(false);
      setArrConsumer(newData);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      dispatch(ConsumerAction.consumerSuccess({ isUploadConsumerSuccess: false }));
    }
  }, [isSuccess]);

  let isValidData = arrErrorIndex.length === 0;
  let isEditing = arrIndexSelected.length > 0;

  return (
    <Dialog fullWidth open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.titleRoot}>
        <Typography color="inherit" variant="h6">
          {isValidData
            ? StringFormat(getLabel(LangConstant.FN_UPLOADED_CONSUMER_LIST), arrConsumer.length)
            : getLabel(LangConstant.TXT_CONSUMER_LIST_INCLUDE_ERROR)}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow padding="checkbox" className={classes.headerCell}>
                {!isValidData && (
                  <TableCell>
                    <Checkbox checked={isCheckAll} onChange={onSelectAll} color="primary" />
                  </TableCell>
                )}
                {headData.map((cell, index) => (
                  <CellHead cellData={getLabel(getCommonKey(cell))} key={index} align={getTextAlign(cell)} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrConsumer.length > 0 &&
                arrConsumer.map((dataShow, index) => (
                  <TableRow key={index} className={classes.rootTableRow}>
                    {!isValidData && (
                      <TableCell>
                        <Checkbox
                          checked={dataShow.checked}
                          onChange={event => onSelect(event, index)}
                          color="primary"
                        />
                      </TableCell>
                    )}
                    <CellBody
                      headData={getLabel(LangConstant.TXT_NAME)}
                      cellData={dataShow.name || ""}
                      textAlign="left"
                      className={classes.normalText}
                    />
                    <CellBody
                      headData={getLabel(LangConstant.TXT_PHONE_NUMBER)}
                      cellData={dataShow.phoneNumber || ""}
                      textAlign="left"
                      isEdit={isEdit}
                      isError={!validatePhone(dataShow.phoneNumber)}
                      type="tel"
                      valueName="phoneNumber"
                      onChange={event => onChangeData(event, index)}
                      className={validatePhone(dataShow.phoneNumber) ? classes.normalText : classes.errorText}
                    />
                    <CellBody
                      headData={getLabel(LangConstant.TXT_EMAIL)}
                      cellData={dataShow.email || ""}
                      textAlign="left"
                      isEdit={isEdit}
                      isError={!validateEmail(dataShow.email)}
                      valueName="email"
                      type="email"
                      className={validateEmail(dataShow.email) ? classes.normalText : classes.errorText}
                      onChange={event => onChangeData(event, index)}
                    />
                    <CellBody
                      headData={getLabel(LangConstant.TXT_BIRTH_DAY)}
                      cellData={dataShow.birthday}
                      placeholder={AppConstant.FM_DD_MM_YYYY}
                      textAlign="center"
                      isEdit={isEdit}
                      isError={!validBirthday(dataShow.birthday)}
                      valueName="birthday"
                      className={validBirthday(dataShow.birthday) ? classes.normalText : classes.errorText}
                      onChange={event => onChangeData(event, index)}
                    />
                    <CellBody
                      headData={getLabel(LangConstant.TXT_SEX)}
                      cellData={
                        Object.values(AppConstant.GENDER_PICKER).includes(dataShow.gender)
                          ? getLabel(getCommonKey(LangConstant.OBJ_GENDER[dataShow.gender]))
                          : ""
                      }
                      textAlign="center"
                      className={classes.normalText}
                    />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      {isEditing ? (
        <DialogActions classes={{ root: classes.rootActions, spacing: classes.actionSpacing }}>
          <Typography className={clsx(classes.noticeText, "medium-md-txt")}>
            {StringFormat(getLabel(LangConstant.FN_ERROR_CONSUMER_NOTICE), arrErrorIndex.length, arrConsumer.length)}
          </Typography>

          <Button
            onClick={onCancel}
            classes={{
              root: classes.editBtn,
              disabled: classes.disabled,
            }}
            color="default"
            size="large"
            variant="contained"
          >
            {getLabel(LangConstant.TXT_BTN_CANCEL)}
          </Button>

          <Button
            onClick={onDelete}
            classes={{
              root: classes.button,
              disabled: classes.disabled,
            }}
            color="primary"
            size="large"
            variant="contained"
          >
            {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions classes={{ root: classes.rootActions, spacing: classes.actionSpacing }}>
          {isValidData ? (
            <Box className={classes.invitationBox}>
              <Checkbox checked={isCheckedInvitation} onChange={onInvite} color="primary" />
              <Typography className="medium-md-txt">{getLabel(LangConstant.TXT_INVITE_CONSUMER)}</Typography>
              <DropdownMenu
                placeholder={getLabel(LangConstant.P_INVITATION_METHOD)}
                dataChange={createArrayWithLabel(
                  AppConstant.INVITATION_METHOD,
                  LangConstant.ARR_INVITATION_METHOD,
                  getLabel,
                )}
                dropdownClass={classes.dropdownClass}
                onChange={onChange}
                isDisabled={!isCheckedInvitation}
              />
            </Box>
          ) : (
            <Typography className={clsx(classes.noticeText, "medium-md-txt")}>
              {StringFormat(getLabel(LangConstant.FN_ERROR_CONSUMER_NOTICE), arrErrorIndex.length, arrConsumer.length)}
            </Typography>
          )}
          {!isValidData && (
            <Button
              onClick={onEdit}
              classes={{
                root: isEdit ? classes.disabledBtn : classes.editBtn,
                disabled: classes.disabled,
              }}
              color="primary"
              size="large"
              variant="contained"
            >
              {getLabel(getCommonKey(LangConstant.TXT_SHORT_EDIT))}
            </Button>
          )}
          <Button
            onClick={isValidData ? onConfirm : onContinue}
            classes={{
              root: classes.button,
              disabled: classes.disabled,
            }}
            color="primary"
            size="large"
            variant="contained"
          >
            {isValidData ? getLabel(getCommonKey(LangConstant.TXT_CONFIRM)) : getLabel(LangConstant.TXT_BTN_CONTINUE)}
          </Button>
        </DialogActions>
      )}
      <Alert isShow={isNoticeAlert} onClose={() => setIsNoticeAlert(false)} type="warning" message={contentNotice} />
    </Dialog>
  );
};

export default memo(MnConsumerTableDialog);

const getArrErrorItemIndex = data => {
  let arrData = [...data];
  let arrError = [];

  arrData.forEach((item, index) => {
    let isNotExistInvitationMethod = !Boolean(item.phoneNumber) && !Boolean(item.email);
    let validData = validatePhone(item.phoneNumber) && validateEmail(item.email) && validBirthday(item.birthday);

    let inValidData = isNotExistInvitationMethod || !validData;
    if (inValidData) {
      arrError.push(index);
    }
  });

  return arrError;
};

const getTextAlign = data => {
  if (!data) return;
  if (data === LangConstant.TXT_BIRTH_DAY || data === LangConstant.TXT_SEX) {
    return "center";
  } else return "left";
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    display: "flex",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    margin: 0,
    borderRadius: 0,
    alignItems: "center",
  },

  titleRoot: {
    marginTop: 40,
    marginBottom: 20,

    "&>*": {
      textAlign: "center",
    },
  },

  closeButton: {
    position: "absolute",
    top: 50,
    right: 70,
  },
  closeIcon: {
    width: 35,
    height: 35,
  },

  content: {
    width: "85%",
    padding: 0,
    border: "none",
    borderRadius: "8px 8px 0px 0px",
    lineHeight: "22px",
  },

  headerCell: {
    "&>*": {
      height: 75,
      backgroundColor: "#fafafb",
    },
  },

  rootTableRow: {
    width: "100%",
    height: 75,
    "&>*": {
      borderBottom: "1px solid" + theme.palette.grey[200],
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },

  rootActions: {
    width: "85%",
    position: "relative",
    margin: "30px 0px 80px 0px",
    padding: "0px 37px",
    justifyContent: "center",
  },
  noticeText: {
    position: "absolute",
    width: "30%",
    left: 0,
  },
  invitationBox: {
    position: "absolute",
    display: "flex",
    width: "50%",
    left: 0,
    alignItems: "center",
  },
  button: {
    width: 137,
    padding: 0,
    borderRadius: 4,
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      opacity: 1,
    },
  },
  editBtn: {
    width: 137,
    padding: 0,
    marginRight: 36,
    borderRadius: 4,
    color: theme.palette.white,
    textTransform: "capitalize",
    "&:hover": {
      opacity: 1,
    },
  },
  disabledBtn: {
    display: "none",
  },
  d: {
    backgroundColor: "#EF5845",
    opacity: 0.6,
    color: theme.palette.white,
  },

  normalText: {
    color: "#3E4045",
  },

  errorText: {
    color: theme.palette.primary.main,
  },
  dropdownClass: {
    minWidth: 220,
    marginLeft: 6,
  },
}));
