import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Grid, Hidden, makeStyles } from "@material-ui/core";
import BookingPage from "./BookingPage";
import ShopDetail from "./components/ShopDetail";
import ShopInfo from "./components/ShopInfo";
import OrderPage from "./OrderPage";

const BookingPagePreviewComponent = ({ displayCondition, displayData }) => {
  const classes = useStyles();

  const [isNewsTab, setIsNewsTab] = useState(false);
  const [shopDetailHeader, setShopDetailHeader] = useState({});
  const [isOrderTab, setIsOrderTab] = useState(false);

  const onChangeToOrderTab = isOrder => {
    setIsOrderTab(isOrder);
  };

  useEffect(() => {
    if (displayData)
      setShopDetailHeader({
        name: displayData.name || "",
        cover: displayData.cover || "",
        description: displayData.description || "",
      });
  }, [displayData]);

  return (
    <>
      <ShopDetail
        shopData={shopDetailHeader}
        isOrderTab={isOrderTab}
        setIsNewsTab={setIsNewsTab}
        onChangeToOrderTab={onChangeToOrderTab}
      />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Hidden xsDown>
            <Grid item sm={4}>
              <ShopInfo shopData={displayData} isNewsTab={isNewsTab} setIsNewsTab={setIsNewsTab} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={8}>
            {isOrderTab ? <OrderPage /> : <BookingPage data={displayCondition || {}} displayData={displayData} />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

BookingPagePreviewComponent.propTypes = {
  data: PropTypes.object,
};

export default BookingPagePreviewComponent;

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      background: "white",
    },
  },
  container: {
    padding: "0 120px",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  bookRoot: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    borderRadius: "unset",
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16,
      paddingRight: 0,
    },
  },
}));
