import "date-fns";
import React, { memo, useEffect, useRef, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { fetchDateToText, getDateLang } from "utils/date";
import { Popover, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { AppConstant } from "const";

const CustomDatePickerStaff = ({ onChange, selectedDateProp, isUseEffect, isEditing, ...otherProps }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(selectedDateProp);
  const [isOpen, setIsOpen] = useState(false);

  const { t: getLabel } = useTranslation();
  const calendarRef = useRef();
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  useEffect(() => {
    if (isUseEffect) setSelectedDate(selectedDateProp);
  }, [selectedDateProp]);

  const onDateChange = date => {
    setSelectedDate(date);
    onChange(date);
    setIsOpen(false);
  };

  const onClick = event => {
    event.preventDefault();
    setIsOpen(!isOpen);
    setCalendarAnchor(calendarRef.current);
  };

  return (
    <>
      <Box
        className={isEditing ? classes.boxRoot : classes.readOnlyTimePicker}
        onClick={e => onClick(e)}
        ref={calendarRef}
      >
        <Typography className={`${classes.inputRoot} medium-md-txt`} onClick={e => onClick(e)}>
          {fetchDateToText(selectedDate, getLabel)}
        </Typography>
        <ExpandMore className={isEditing ? classes.iconRoot : classes.hiddenArrow} />
      </Box>
      <Popover
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={{ paper: classes.datepickerPaper }}
      >
        <Box className="datepicker-root">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateLang(i18n.language)}>
            <DatePicker
              disableToolbar
              variant="static"
              format={AppConstant.FM_DD_MM_YYYY}
              value={selectedDate}
              onChange={onDateChange}
              className={`${classes.datePickerRoot} datepicker-root`}
              {...otherProps}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    color: theme.palette.grey[600],
  },
  datepickerPaper: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  datePickerRoot: {
    pointerEvents: `none`,
  },
  iconRoot: {
    pointerEvents: `none`,
  },
  boxRoot: {
    justifyContent: "space-between",
    color: theme.palette.grey[500],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minWidth: 155,
    height: "100%",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "0%",
    },
    padding: 8,
    cursor: `pointer`,
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
    "@media only screen and (max-width: 600px)": {
      padding: "4px 10px",
      marginBottom: 5,
      marginRight: 5,
    },
  },
  hiddenArrow: {
    display: "none",
  },
  readOnlyTimePicker: {
    color: theme.palette.grey[500],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "0px",
    height: "100%",
    borderBottom: "1px solid #d4d5d8",
  },
}));

CustomDatePickerStaff.propTypes = {
  onChange: PropTypes.func,
  isUseEffect: PropTypes.bool,
  selectedDateProp: PropTypes.objectOf(Date),
};

CustomDatePickerStaff.defaultProps = {
  isUseEffect: false,
  selectedDateProp: new Date(),
};

export default memo(CustomDatePickerStaff);
