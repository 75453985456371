import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { makeStyles, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close, ArrowBack } from "@material-ui/icons";
import MenuAction from "redux/menu.redux";
import { LangConstant } from "const";
import { getCommonKey, formatCurrency, removeVietnameseTones } from "utils";
import { SearchBar } from "components";
import { CategoryItemListBody } from "components/mn-master-product";
import { CategoryItemListHeaderMenu } from ".";

const DialogAddItemMenu = ({
  isOpen,
  onClose,
  onBack,
  nameTable,
  data,
  nameParent,
  nameChild,
  lineData,
  isProduct,
  isCombo,
  type,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const [dataDefault, dataDisplay, dataOrderDefault, dataOrderDisplay, isTabBooking] = useSelector(
    ({ menuRedux }) => [
      menuRedux.dataDefault,
      menuRedux.dataDisplay,
      menuRedux.dataOrderDefault,
      menuRedux.dataOrderDisplay,
      menuRedux.isTabBooking,
    ],
    shallowEqual,
  );

  const [dataItemList, setDataItemList] = useState(data);
  const [disabledAddAll, setDisabledAddAll] = useState(false);
  const [dataMenuDisplay, setDataMenuDisplay] = useState(isTabBooking ? dataDisplay : dataOrderDisplay);
  const [dataMenuDefault, setDataMenuDefault] = useState(isTabBooking ? dataDefault : dataOrderDefault);

  const onSearch = name => {
    let newListSearch = data.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setDataItemList(newListSearch);
  };

  const onClickAddAll = () => {
    setDisabledAddAll(true);
    let dataItem = [],
      dataPost = [],
      dataItemListDisplay = [],
      dataListPost = [];
    dataItem = [...dataMenuDisplay.menuList[lineData].itemList];
    dataPost = [...dataMenuDefault.menuList[lineData].itemList];
    dataItemList.forEach(data => {
      let isNotFound =
        dataMenuDisplay.menuList[lineData].itemList.findIndex(dataIndex => dataIndex.itemUid === data.uid) < 0;
      if (isNotFound) {
        dataItem.push({ ...data, itemUid: data.uid, type: type });
        dataPost.push({ itemUid: data.uid, type: type });
      }
    });
    dataItemListDisplay = [...dataMenuDisplay.menuList];
    dataListPost = [...dataMenuDefault.menuList];
    dataItemListDisplay[lineData] = { ...dataItemListDisplay[lineData], itemList: dataItem };
    dataListPost[lineData] = { ...dataListPost[lineData], itemList: dataPost };
    if (isTabBooking) {
      dispatch(
        MenuAction.menuSuccess({
          dataDefault: { ...dataMenuDisplay, menuList: dataListPost },
          dataDisplay: { ...dataMenuDisplay, menuList: dataItemListDisplay },
        }),
      );
    } else {
      dispatch(
        MenuAction.menuSuccess({
          dataOrderDisplay: { ...dataMenuDisplay, menuList: dataItemListDisplay },
          dataOrderDefault: { ...dataMenuDefault, menuList: dataListPost },
        }),
      );
    }
  };

  const onAddProductMainToAccompaniedProduct = uid => {
    let dataItem = [],
      dataPost = [],
      dataItemList = [],
      dataListPost = [];
    dataItem = [...dataMenuDisplay.menuList[lineData].itemList];
    dataPost = [...dataMenuDefault.menuList[lineData].itemList];
    let productItem = data.find(itemSearch => itemSearch.uid === uid) || {};
    productItem = { ...productItem, itemUid: productItem.uid, type: type };
    let itemPost = { itemUid: productItem.uid, type: type };
    dataPost.push(itemPost);
    dataItem.push(productItem);
    dataItemList = [...dataMenuDisplay.menuList];
    dataListPost = [...dataMenuDefault.menuList];
    dataItemList[lineData] = { ...dataItemList[lineData], itemList: dataItem };
    dataListPost[lineData] = { ...dataListPost[lineData], itemList: dataPost };
    if (isTabBooking) {
      dispatch(
        MenuAction.menuSuccess({
          dataDefault: { ...dataMenuDefault, menuList: dataListPost },
          dataDisplay: { ...dataMenuDisplay, menuList: dataItemList },
        }),
      );
    } else {
      dispatch(
        MenuAction.menuSuccess({
          dataOrderDefault: { ...dataMenuDefault, menuList: dataListPost },
          dataOrderDisplay: { ...dataMenuDisplay, menuList: dataItemList },
        }),
      );
    }
  };

  useEffect(() => {
    let dataItem = [];
    dataItemList.forEach(data => {
      let isNotFound =
        dataMenuDisplay.menuList[lineData].itemList.findIndex(dataIndex => dataIndex.itemUid === data.uid) < 0;
      if (isNotFound) {
        dataItem.push(data);
      }
    });
    if (dataItem.length === 0) {
      setDisabledAddAll(true);
    }
    if (isTabBooking) {
      setDataMenuDisplay(dataDisplay);
      setDataMenuDefault(dataDefault);
    } else {
      setDataMenuDisplay(dataOrderDisplay);
      setDataMenuDefault(dataOrderDefault);
    }
  }, []);

  useEffect(() => {
    if (isTabBooking) {
      setDataMenuDisplay(dataDisplay);
      setDataMenuDefault(dataDefault);
    } else {
      setDataMenuDisplay(dataOrderDisplay);
      setDataMenuDefault(dataOrderDefault);
    }
  }, [dataDisplay, dataOrderDisplay, dataMenuDisplay, isTabBooking]);

  return (
    <Dialog open={isOpen} fullScreen>
      <Box className={classes.boxBody}>
        <Box className={classes.rootHeader}>
          <Box className={classes.boxIconBack}>
            <IconButton onClick={onBack} className={classes.iconButton}>
              <ArrowBack className={classes.icon} />
            </IconButton>
          </Box>
          <Typography className={classes.nameHeaderTypo}>{nameTable}</Typography>
          <Box className={classes.boxIconClose}>
            <IconButton onClick={onClose} className={classes.iconButton}>
              <Close className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.boxHeader}>
          <Box className={classes.listProductCategorySub}>
            <Box className={classes.boxTypography}>
              {nameParent ? (
                <>
                  <Typography className={classes.categorySourceBox}>{nameParent} &nbsp; / &nbsp;</Typography>
                  <Typography className={classes.categoryName}>{nameChild}</Typography>
                </>
              ) : (
                <Typography className={classes.categoryName}>{nameChild}</Typography>
              )}
            </Box>
            <Box className={classes.boxSearchBar}>
              <SearchBar
                className={classes.searchBar}
                onKeyUp={onSearch}
                placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
              />
            </Box>
          </Box>
          <Box className={classes.rootBody}>
            <CategoryItemListHeaderMenu
              isProduct={!isProduct}
              isCombo={isCombo}
              onClickAddAll={onClickAddAll}
              disabled={disabledAddAll}
            />
            <Box className={classes.bodyTable}>
              {dataItemList?.map(dataProductItem => (
                <CategoryItemListBody
                  key={dataProductItem.uid}
                  uid={dataProductItem.uid}
                  onClickAdd={onAddProductMainToAccompaniedProduct}
                  categoryName={dataProductItem.name}
                  isAdd={true}
                  stock={dataProductItem?.stock || 0}
                  image={dataProductItem.image}
                  price={dataProductItem.price ? formatCurrency(dataProductItem.price) : ""}
                  disabled={Boolean(
                    dataMenuDisplay.menuList[lineData].itemList.find(data => data.itemUid === dataProductItem.uid),
                  )}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
export default memo(DialogAddItemMenu);

const useStyles = makeStyles(theme => ({
  boxBody: {
    height: "calc(100% - 138px)",
  },
  boxHeader: {
    textAlign: "center",
    width: "100%",
    padding: "0 100px",
    [theme.breakpoints.down("md")]: {
      padding: "0 25px",
    },
  },
  boxTypography: {
    display: "flex",
    marginBottom: 18,
  },
  boxSearchBar: {
    textAlign: "center",
  },
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down("md")]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down("md")]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: "100%",
    height: 30,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rootBody: {
    marginTop: 48,
    marginBottom: 48,
    width: "100%",
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  categorySourceBox: {
    color: "#47494e",
    cursor: "pointer",
    "&:hover": { color: "#4d85ff" },
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },
  categoryName: {
    color: "#5790ff",
    textTransform: "none",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },
  listProductCategorySub: {},
  bodyTable: {
    height: "calc(100vh - 350px)",
    marginBottom: 36,
  },
}));
