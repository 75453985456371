import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const SwitchMProductItem = props => {
  const { selectedId, customClasses, itemInfos, onTabClick } = props;
  const classes = useStyles();

  return (
    <Box className={clsx(classes.switchItemContainer, customClasses?.container)}>
      {itemInfos.map(item => (
        <Button
          key={item.id}
          id={item.id}
          className={
            selectedId === item.id
              ? clsx(classes.active, customClasses?.active)
              : clsx(classes.inactive, customClasses?.inactive)
          }
          onClick={onTabClick}
        >
          <Typography color={"inherit"} className={customClasses?.label ? customClasses.label : "medium-md-txt"}>
            {item.name}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  switchItemContainer: { background: "#4b4d53", borderBottom: "solid 1px #ebeced" },
  active: {
    height: "fit-content",
    minHeight: 50,
    width: "fit-content",
    minWidth: "150px",
    color: theme.palette.white,
    background: theme.palette.primary.main,
    textTransform: "none",
    borderRadius: "1px",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
    padding: "0 30px",
  },
  inactive: {
    height: "fit-content",
    minHeight: 50,
    width: "fit-content",
    minWidth: "150px",
    color: theme.palette.white,
    background: "#4b4d53",
    textTransform: "none",
    borderRadius: "1px",
    "&:hover": {
      background: "#4b4d53",
    },
    padding: "0 30px",
  },
}));

SwitchMProductItem.propTypes = {
  itemInfos: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func,
  selectedId: PropTypes.string.isRequired,
};

SwitchMProductItem.defaultProps = { itemInfos: [], onItemSelected: () => {} };

export default memo(SwitchMProductItem);
