import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stepper, Step, makeStyles } from "@material-ui/core";
import { AppConstant } from "const";
import StepperOder from "./StepperOder";

const CustomStepperOrder = ({ status, selectedStatus, onChange }) => {
  const classes = useStyles();

  let statusArray = [...Object.values(AppConstant.STT_VAT_BILL)];

  let currentSelected = AppConstant.STT_RESERVATION.pending;
  let currentStatusIndex = AppConstant.STT_RESERVATION.pending;

  statusArray.forEach(entry => {
    if (entry === selectedStatus) {
      currentSelected = entry;
    }
    if (entry === status) {
      currentStatusIndex = entry;
    }
  });

  let stepProps = { alternativeLabel: true, orientation: "horizontal" };

  return (
    <Stepper
      classes={{
        root: classes.rootStepper,
      }}
      activeStep={status - 1}
      {...stepProps}
      alternativeLabel={true}
    >
      {statusArray.map((entry, statusIndex) => {
        return (
          <Step className={classes.rootStep} key={statusIndex}>
            <StepperOder
              entry={entry}
              statusIndex={statusIndex}
              onChange={onChange}
              currentStatus={status}
              selectedStatus={selectedStatus}
              currentSelected={currentSelected}
              currentStatusIndex={currentStatusIndex}
            />
          </Step>
        );
      })}
    </Stepper>
  );
};

CustomStepperOrder.propTypes = {
  status: PropTypes.number,
  onChange: PropTypes.func,
};
CustomStepperOrder.defaultProps = { status: AppConstant.STT_RESERVATION.pending };

const useStyles = makeStyles({
  rootStepper: {
    padding: "36px 0px",
  },

  rootStep: {
    padding: 0,
  },
});

export default memo(CustomStepperOrder);
