import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography, Button, Checkbox, Hidden } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { convertTimePreOrder, convertPricePreOrder, getAttachmentUrl, onLoadImageError } from "utils";
import clsx from "clsx";

const ProductItemInList = ({ isChoose, data, onChoose, onChecked, classes, ...otherProps }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();

  const [displayData, setDisplayData] = useState();

  useEffect(() => {
    setDisplayData(data);
  }, [data]);

  return (
    <Box className={clsx(defaultClasses.root, classes?.rootItemInList)}>
      <Box className={defaultClasses.boxImage}>
        <img className={defaultClasses.image} onError={onLoadImageError} src={getAttachmentUrl(displayData?.image)} />
      </Box>
      <Typography className={defaultClasses.textTypoName}>{displayData?.name}</Typography>
      <Hidden xsDown>
        <Typography className={defaultClasses.textTypo}>{convertTimePreOrder(data)}</Typography>
        <Typography className={defaultClasses.textTypo}>{convertPricePreOrder(data)}</Typography>
      </Hidden>
      {isChoose ? (
        <Box className={defaultClasses.boxOptionAdd}>
          <Button onClick={onChoose} className={defaultClasses.buttonAdd}>
            {getLabel(LangConstant.TXT_CHOOSE)}
          </Button>
        </Box>
      ) : (
        <Box className={defaultClasses.boxOptionAdd}>
          <Checkbox
            color="primary"
            onChange={onChecked}
            className={classes.customCheckbox}
            checked={otherProps.valueChecked}
            disabled={otherProps.disabled}
          />
        </Box>
      )}
    </Box>
  );
};

ProductItemInList.propTypes = {
  isChoose: PropTypes.bool,
  data: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    time: PropTypes.number,
  }),
  onChecked: PropTypes.func,
  onChoose: PropTypes.func,
  classes: PropTypes.object,
};
ProductItemInList.defaultProps = {};

export default memo(ProductItemInList);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: 40,
    padding: "8px 16px",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
  },
  boxImage: {
    minWidth: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxWidth: 30,
    maxHeight: 24,
  },
  textTypoName: {
    width: "100%",
    minWidth: 200,
    padding: "0 16px",
    lineHeight: 1.57,
    fontSize: 14,
    height: 24,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  textTypo: {
    width: "100%",
    padding: "0 16px",
    lineHeight: 1.57,
    fontSize: 14,
  },
  boxOptionAdd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonAdd: {
    height: 24,
    padding: 0,
    minWidth: 47,
    fontSize: 11,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
}));
