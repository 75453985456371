import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey, textNormalize } from "utils";
import { SearchBar } from "components";
import { getFilterArray } from "..";

const FilterByProduct = ({ filterByProduct, onSelectProduct }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_RATING_MANAGEMENT);

  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem?.data, shallowEqual);
  const listProductItem = useSelector(state => state.mnProductRedux.dataItem?.data, shallowEqual);
  const listServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem?.data, shallowEqual);

  const [searchValue, setSearchValue] = useState(null);
  const [productList, setProductList] = useState([]);

  const onFilter = search => {
    let searchValue = textNormalize(search);
    let productList = [...(listComboItem || []), ...(listProductItem || []), ...(listServiceItem || [])];
    const filteredArray = getFilterArray(productList, searchValue);
    if (searchValue) setProductList(filteredArray);
    else setProductList(productList);

    setSearchValue(searchValue);
  };

  useEffect(() => {
    if (
      listComboItem &&
      listProductItem &&
      listServiceItem &&
      (listComboItem.length > 0 || listProductItem.length > 0 || listServiceItem.length > 0)
    ) {
      let productList = [...(listComboItem || []), ...(listProductItem || []), ...(listServiceItem || [])];
      setProductList(productList);
    }
  }, [listComboItem, listProductItem, listServiceItem]);

  return (
    <Box className={classes.filterByProduct}>
      <Typography className="bold-md-txt">{getLabel(LangConstant.TXT_MASTER_PRODUCT)}</Typography>
      <SearchBar
        className={classes.productSearch}
        placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
        customClasses={{
          input: clsx(classes.searchBarInput, "regular-sm-txt"),
          icon: classes.searchIcon,
          closeIcon: classes.clearFilter,
        }}
        onChange={onFilter}
        enableClear={Boolean(searchValue)}
        onClear={() => onFilter(null)}
      />
      {productList.map(item => (
        <FormControlLabel
          key={item.uid}
          control={
            <Checkbox
              disableRipple={true}
              disableTouchRipple={true}
              checked={filterByProduct.includes(item.uid)}
              onChange={() => onSelectProduct(item.uid)}
              color="primary"
              className={classes.scoreFilterCheckbox}
            />
          }
          label={
            <Typography variant="body2" className="ellipsis">
              {item.name}
            </Typography>
          }
          classes={{
            root: classes.filterByReport,
            label: classes.filterByReportLabel,
          }}
        />
      ))}
    </Box>
  );
};

FilterByProduct.propTypes = {
  filterByProduct: PropTypes.array,

  onSelectProduct: PropTypes.func,
};

const useStyles = makeStyles(() => ({
  scoreFilterCheckbox: {
    padding: 0,
  },

  filterByProduct: {
    borderTop: "1px solid #CECFD2",
    width: "100%",
    paddingTop: 10,
    marginTop: 10,
  },

  filterByReport: {
    width: "100%",
    margin: 0,
    marginTop: 10,
  },

  filterByReportLabel: {
    marginLeft: 10,
    userSelect: "none",
  },

  productSearch: {
    marginTop: 10,
    borderRadius: 4,
    backgroundColor: "#F6F5F9",
  },

  searchBarInput: {
    height: 23,
    "&::placeholder": {
      color: "#D4D5D8",
    },
  },

  searchIcon: {
    "&>svg": {
      fontSize: 20,
    },
  },

  clearFilter: {
    fontSize: 14,
  },
}));

export default FilterByProduct;
