import React, { memo, useState, useEffect } from "react";
import { ShopInfo, BasicInfo } from "./components/";
import { Box, makeStyles } from "@material-ui/core";
import ShopInfoAction from "redux/shop-info.redux";
import { useSelector, useDispatch } from "react-redux";
import { toSnake } from "utils";
import { BglobalJSC } from "components";

const MnShopInfo = () => {
  const shopInfoRedux = useSelector(state => state.shopInfoRedux);
  const { data: shopInfoData } = shopInfoRedux;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [shopData, setShopData] = useState(shopInfoData);

  const updateShopData = newData => {
    let updatedData = { ...shopData };

    updatedData.id = newData.id;
    updatedData.username = newData.username;
    updatedData.code = newData.code;
    updatedData.state = newData.state;
    updatedData.businessId = newData.businessId;
    updatedData.name = newData.name;
    updatedData.alias = newData.alias;
    updatedData.address = newData.address;
    if (updatedData.details && updatedData.details !== {}) {
      updatedData.social.website = newData.socialWebsite;
      updatedData.social.facebook = newData.socialFacebook;
      updatedData.social.google = newData.socialGoogle;
    }
    updatedData.ownerName = newData.ownerName;
    updatedData.phoneNumber = newData.phoneNumber;
    updatedData.email = newData.email;
    updatedData.description = newData.description;

    dispatch(ShopInfoAction.updateShopInfoData(toSnake(updatedData)));
  };

  const updateBasicData = newData => {
    let updatedData = { ...shopData };

    if (updatedData.details && updatedData.details !== {}) {
      updatedData.details.consumerCapacity = newData.consumerCapacity;

      if (updatedData.details.workingTime && updatedData.details.workingTime !== {}) {
        updatedData.details.workingTime.days = newData.workingDay;
        updatedData.details.workingTime.open = newData.openTime;
        updatedData.details.workingTime.close = newData.closeTime;
      }
    }

    dispatch(ShopInfoAction.updateShopInfoData(toSnake(updatedData)));
  };

  const onUpdateShopData = newData => {
    updateShopData(newData);
  };

  const onUpdateBasicData = newData => {
    updateBasicData(newData);
  };

  useEffect(() => {
    if (!shopInfoRedux.isFetching) {
      dispatch(ShopInfoAction.getShopInfoData());
    }
  }, []);

  useEffect(() => {
    if (shopInfoData != null && !shopInfoRedux.isFetching) {
      setShopData(shopInfoData);
    }
  }, [shopInfoData]);

  return (
    <>
      <ShopInfo shopData={shopInfoData} onSubmit={onUpdateShopData} />
      <BasicInfo basicData={shopInfoData} onSubmit={onUpdateBasicData} />
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({}));

export default memo(MnShopInfo);
