import { call, put, select } from "redux-saga/effects";
import { ApiConstant } from "const";
import PlaceItemAction from "redux/place-item.redux";
import PaymentAction from "redux/payment.redux";
import { PlaceItemService } from "services";
import { toCamel, toSnake } from "utils";
import PlaceCategoryAction from "redux/place-category.redux";

export function* getPlaceItem() {
  try {
    let response = yield call(PlaceItemService.get);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validatePlaceDataItem(responseData);
      yield put(PlaceItemAction.getPlaceItemSuccess(placeItemArrayToJson(validatedResponseData)));
    } else {
      yield put(PlaceItemAction.getPlaceItemFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceItemAction.getPlaceItemFailure(error));
  }
}

export function* createPlaceItem(action) {
  try {
    const { data } = action;
    // Get current place category data
    const {
      placeCategoryRedux: { data: currentCategoryData },
    } = yield select();
    let response = yield call(PlaceItemService.create, toSnake(data));

    // Update place category redux data
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      let newData = updatePlaceCategoryData(currentCategoryData, {
        ...toCamel(responseData),
        categoryUid: data.categoryUid,
      });
      const validatedResponseData = validatePlaceDataItem(responseData);
      yield put(PlaceItemAction.createPlaceItemSuccess(toCamel(validatedResponseData)));
      yield put(PlaceCategoryAction.getPlaceCategorySuccess({ jsonData: toCamel(newData) }));
    } else if (response.status === ApiConstant.STT_UN_PROCESSABLE_ENTITY) {
      yield put(PaymentAction.getUnProcessableEntity());
    } else {
      yield put(PlaceItemAction.createPlaceItemFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceItemAction.createPlaceItemFailure(error));
  }
}

export function* patchPlaceItem(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceItemService.patch, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validateResponseArray(responseData);
      yield put(PlaceItemAction.patchPlaceItemSuccess(placeItemArrayToJson(validatedResponseData)));
      yield put(PlaceCategoryAction.createPlaceCategoryItemSuccess(placeItemArrayToJson(validatedResponseData)));
    } else {
      yield put(PlaceItemAction.patchPlaceItemFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceItemAction.patchPlaceItemFailure(error));
  }
}

export function* updatePlaceItem(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceItemService.update, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validatePlaceDataItem(responseData);
      yield put(PlaceItemAction.updatePlaceItemSuccess(toCamel(validatedResponseData)));
    } else {
      yield put(PlaceItemAction.updatePlaceItemFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceItemAction.updatePlaceItemFailure(error));
  }
}

export function* removePlaceItem(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceItemService.remove, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(PlaceItemAction.removePlaceItemSuccess(data));
    } else {
      yield put(PlaceItemAction.removePlaceItemFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceItemAction.removePlaceItemFailure(error));
  }
}

export function* getPlaceBusyStatus(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceItemService.getPlaceBusyStatus, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(PlaceItemAction.getPlaceBusyStatusSuccess(restructureBusyStatusArray(response.data.data)));
    } else {
      yield put(PlaceItemAction.getPlaceBusyStatusFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceItemAction.getPlaceBusyStatusFailure(error));
  }
}

export function* getPlaceItemAutoFill(action) {
  try {
    const { data } = action;
    let response = yield call(PlaceItemService.getPlaceItemAutoFill, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        PlaceItemAction.placeItemSuccess({ dataPlaceItemAutoFill: response.data.data, isGetPlaceItemAutoFill: false }),
      );
    } else {
      yield put(PlaceItemAction.placeItemFailure(response.data));
    }
  } catch (error) {
    yield put(PlaceItemAction.placeItemFailure(error));
  }
}

const placeItemArrayToJson = array => {
  let jsonData = {};
  var categoryUid = "";
  for (var i = 0; i < array.length; i++) {
    // array[i]
    //Create data json object
    jsonData[array[i].uid] = toCamel(array[i]);
    categoryUid = array[i].category_uid;
  }
  return { items: jsonData, categoryUid: categoryUid };
};

const validateResponseArray = responseArray => {
  const validatedArray = responseArray
    ? responseArray.map(value => {
        return validatePlaceDataItem(value);
      })
    : [];
  return validatedArray;
};

const validatePlaceDataItem = item => {
  const validatedItem = {
    uid: item.uid ? item.uid : "",
    state: item.state ? item.state : "",
    name: item.name ? item.name : "",
    code: item.code ? item.code : "",
    sort_order: item.sort_order ? item.sort_order : 0,
    description: item.description ? item.description : "",
    category_uid: item.category_uid ? item.category_uid : "",
    capacity: item.capacity ? item.capacity : 0,
  };
  return validatedItem;
};

const restructureBusyStatusArray = object => {
  return toCamel(
    Object.entries(object).map(entry => {
      return { time: entry[0], place_category: entry[1].place_category };
    }),
  );
};

const updatePlaceCategoryData = (currentCategoryData, newItemData) => {
  let placeCategoryData = { ...currentCategoryData };
  let categoryUpdated = Object.values(placeCategoryData).find(obj => {
    if (obj.uid === newItemData.categoryUid) return obj;
  });

  let updatedCategoryUid = newItemData.categoryUid;
  let updatedItemUId = newItemData.uid;

  let newListItems = { ...categoryUpdated.items };
  newListItems = { ...newListItems, [updatedItemUId]: newItemData };

  let newCategory = { ...categoryUpdated, items: newListItems };

  return { ...placeCategoryData, [updatedCategoryUid]: newCategory };
};
