import React, { memo } from "react";
import { makeStyles, TableCell } from "@material-ui/core";
import { LangConstant } from "../../../const";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import StatusInvite from "./StatusInvite";

const CellBody = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { headData, cellData, onSelect, checked, checkAll, data, index, open, setOpen, setSelected } = props;

  return (
    <>
      {headData === getLabel(LangConstant.TXT_SEX) ? (
        <TableCell
          align="center"
          classes={{
            body: classes.bodyMiddle,
          }}
        >
          {cellData}
        </TableCell>
      ) : headData === getLabel(LangConstant.TXT_ACCOUNT_STATUS) ? (
        <>
          <TableCell
            align="center"
            classes={{
              body: classes.bodyMiddle,
            }}
          >
            <StatusInvite
              accountStatus={cellData}
              onSelect={onSelect}
              checked={checked}
              checkAll={checkAll}
              data={data}
              index={index}
              setSelected={setSelected}
              open={open}
              setOpen={setOpen}
            />
          </TableCell>
        </>
      ) : (
        <TableCell
          align="center"
          classes={{
            body: classes.bodyLeft,
          }}
        >
          {cellData}
        </TableCell>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  bodyLeft: {
    color: "#787878",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: 6,
    paddingBottom: 6,
    minWidth: 180,
    textAlign: "left",
  },
  bodyMiddle: {
    color: "#787878",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: 6,
    paddingBottom: 6,
    minWidth: 130,
  },
  iconStatus: {
    width: "16px",
    height: "16px",
    color: "#65b39d",
  },
  checkBoxStatus: {
    padding: "0 0 0",
    width: 16,
    height: 17,
  },
}));

CellBody.propTypes = {
  cellData: PropTypes.node,
  firstCol: PropTypes.bool,
  style: PropTypes.object,
  isStatus: PropTypes.bool,
};

CellBody.defaultProps = {
  style: { isStatus: false },
};

export default memo(CellBody);
