import { ApiConstant } from "const";
import { call, put, select } from "redux-saga/effects";
import BillAction from "redux/bill.redux";
import { BillService } from "services";
import { toCamel, toSnake } from "utils";

export function* createBill(action) {
  try {
    const { data } = action;
    let response = yield call(BillService.createBill, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        BillAction.billSuccess({
          isSuccess: true,
        }),
      );
    } else {
      yield put(BillAction.billFailure(responseData));
    }
  } catch (error) {
    yield put(BillAction.billFailure(error));
  }
}

export function* updateBill(action) {
  try {
    const {
      billRedux: { billList },
    } = yield select();
    const { data } = action;
    let response = yield call(BillService.updateBill, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      let updatedList = billList.map(item => {
        if (item.uid !== data.uid) return item;
        else return toCamel(responseData.data);
      });

      yield put(
        BillAction.billSuccess({
          billList: updatedList,
          isUpdateSuccess: true,
        }),
      );
    } else {
      yield put(BillAction.billFailure(responseData));
    }
  } catch (error) {
    yield put(BillAction.billFailure(error));
  }
}

export function* deleteBill(action) {
  try {
    const { data } = action;
    const {
      billRedux: { billList },
    } = yield select();
    let response = yield call(BillService.deleteBill, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      let newBillList = billList.filter(bill => bill.uid !== data.uid);
      yield put(
        BillAction.billSuccess({
          billList: newBillList,
          isDeleteSuccess: true,
        }),
      );
    } else {
      yield put(BillAction.billFailure(responseData));
    }
  } catch (error) {
    yield put(BillAction.billFailure(error));
  }
}

export function* patchUpdateBillStatus(action) {
  try {
    const { data } = action;
    let response = yield call(BillService.patchUpdateBillStatus, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        BillAction.billSuccess({
          isPatchUpdateSuccess: true,
        }),
      );
    } else {
      yield put(BillAction.billFailure(responseData));
    }
  } catch (error) {
    yield put(BillAction.billFailure(error));
  }
}

export function* getBillList(action) {
  try {
    const { data } = action;
    let response = yield call(BillService.getBillList, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        BillAction.billSuccess({
          billList: toCamel(responseData.data),
          paging: {
            page: responseData.page,
            size: responseData.size,
            total: responseData.total,
            totalPage: responseData.total_page,
          },
        }),
      );
    } else {
      yield put(BillAction.billFailure(responseData));
    }
  } catch (error) {
    yield put(BillAction.billFailure(error));
  }
}

export function* getReservationBillDetail(action) {
  try {
    const { data } = action;
    let response = yield call(BillService.getReservationBillDetail, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        BillAction.billSuccess({
          billDetailReservation: toCamel(responseData.data),
        }),
      );
    } else {
      yield put(BillAction.billFailure(responseData));
    }
  } catch (error) {
    yield put(BillAction.billFailure(error));
  }
}
