import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { isObjectNotEqual } from "utils";
import { FilterByScore, FilterByType, FilterByProduct } from "../components";

const Filter = ({
  filterByScore,
  filterBy,
  setFilterBy,
  filterByReport,
  setFilterByReport,
  filterByProduct,
  setFilterByProduct,
  setFilterByScore,
}) => {
  const { ratingSummary } = useSelector(state => state.ratingRedux, shallowEqual);

  const [ratingData, setRatingData] = useState(ratingSummary);

  const onSelectFilterByItem = uid => {
    let newFilter = [...filterByProduct];
    if (newFilter.includes(uid)) {
      newFilter = newFilter.filter(item => item !== uid);
    } else {
      newFilter.push(uid);
    }
    setFilterByProduct(newFilter);
  };

  const onChangeScoreFilter = score => {
    let newScoreFilter = [...filterByScore];
    if (newScoreFilter.includes(score)) {
      newScoreFilter = newScoreFilter.filter(item => item !== score);
    } else {
      newScoreFilter.push(score);
    }
    setFilterByScore(newScoreFilter);
  };

  useEffect(() => {
    if (isObjectNotEqual(ratingSummary, ratingData)) setRatingData(ratingSummary);
  }, [ratingSummary]);

  return (
    <>
      <FilterByScore filterByScore={filterByScore} onSelectScore={onChangeScoreFilter} ratingData={ratingData} />

      <FilterByType
        filterBy={filterBy}
        onChangeFilterByType={setFilterBy}
        filterByReport={filterByReport}
        onChangeFilterByReport={setFilterByReport}
      />

      {filterBy === FILTER_BY.product && (
        <FilterByProduct filterByProduct={filterByProduct} onSelectProduct={onSelectFilterByItem} />
      )}
    </>
  );
};

const FILTER_BY = {
  shop: 1,
  product: 2,
};

Filter.propTypes = {
  filterBy: PropTypes.number,

  filterByReport: PropTypes.bool,

  filterByScore: PropTypes.array,
  filterByProduct: PropTypes.array,

  setFilterBy: PropTypes.func,
  setFilterByScore: PropTypes.func,
  setFilterByReport: PropTypes.func,
  setFilterByProduct: PropTypes.func,
};

Filter.defaultProps = {
  filterBy: FILTER_BY.shop,

  filterByReport: false,

  filterByScore: [],
  filterByProduct: [],

  setFilterBy: () => {},
  setFilterByScore: () => {},
  setFilterByReport: () => {},
  setFilterByProduct: () => {},
};

export default Filter;
