import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Grid, makeStyles, Paper, Typography, Container, Button } from "@material-ui/core";
import { LangConstant } from "const";
import { getAttachmentUrl, getCommonKey } from "utils";
import clsx from "clsx";
import ShopDetailHeader from "./ShopDetailHeader";

const ShopDetail = props => {
  const { shopData, onChangeToOrderTab, isOrderTab } = props;

  let contentLineEl = document.getElementById(DESCRIPTION_BOX_ID);
  let primaryCover = getAttachmentUrl(shopData.cover);

  const { t: getLabel } = useTranslation();
  const classes = useStyles({ cover: primaryCover });

  const [shop, setShop] = useState({});
  const [isOverLengthDescription, setIsOverLengthDescription] = useState(false);
  const [isReadingMore, setIsReadingMore] = useState(false);

  useEffect(() => {
    if (contentLineEl && contentLineEl.offsetHeight > LIMIT_LENGTH_CONTENT) {
      setIsOverLengthDescription(true);
    }
  }, [contentLineEl]);

  useEffect(() => {
    if (shopData && shopData != shop) {
      setShop(shopData);
    }
  }, [shopData]);

  return (
    <Paper className={classes.root}>
      <Box className={classes.shopCoverBox}>
        <Avatar variant="square" src={primaryCover} className={classes.shopCoverBackground}>
          {shop.name}
        </Avatar>
        <Container className={classes.coverContainer} maxWidth="lg">
          <Avatar
            variant="square"
            src={primaryCover}
            classes={{
              root: classes.cover,
              img: classes.coverImage,
            }}
          >
            {shop.name}
          </Avatar>
        </Container>
      </Box>
      <Container maxWidth="lg" className={classes.shopInfoContent}>
        <ShopDetailHeader />
        <Box className={classes.container}>
          <Grid container>
            <Grid item xs={12} sm={12} className={classes.descriptionBox}>
              <Typography
                variant="body1"
                id={DESCRIPTION_BOX_ID}
                className={clsx(classes.content, isOverLengthDescription && !isReadingMore && "eclipse")}
              >
                {shop.description}
              </Typography>
              {isOverLengthDescription && (
                <Typography
                  className={clsx(classes.readMoreBtn, "semiBold-md-txt")}
                  onClick={() => setIsReadingMore(!isReadingMore)}
                >
                  {isReadingMore ? getLabel(LangConstant.L_HIDE) : getLabel(LangConstant.L_SEE_MORE)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Button
          className={clsx(isOrderTab ? classes.changeTabBtn : classes.changeTabBtnSelected, "semiBold-lg-txt")}
          onClick={() => onChangeToOrderTab(false)}
        >
          {getLabel(getCommonKey(LangConstant.TXT_BOOK_SCHEDULE))}
        </Button>
        <Button
          className={clsx(isOrderTab ? classes.changeTabBtnSelected : classes.changeTabBtn, "semiBold-lg-txt")}
          onClick={() => onChangeToOrderTab(true)}
        >
          {getLabel(getCommonKey(LangConstant.TXT_ORDER_TAB))}
        </Button>
        <Button className={clsx(classes.changeTabBtn, "semiBold-lg-txt")}>
          {getLabel(getCommonKey(LangConstant.TXT_NEWS))}
        </Button>
      </Container>
    </Paper>
  );
};

ShopDetail.propTypes = {
  shopData: PropTypes.object.isRequired,
  selectedTab: PropTypes.number,

  isHavingBookingFunc: PropTypes.bool,
  isHavingOrderFunc: PropTypes.bool,
};

ShopDetail.defaultProps = {
  selectedTab: 0,
  isHavingBookingFunc: false,
  isHavingOrderFunc: false,
};

const DESCRIPTION_BOX_ID = "descriptionBox";
const LIMIT_LENGTH_CONTENT = 115;

export default memo(ShopDetail);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginBottom: 24,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
  },
  container: {
    "& p": {
      padding: "3px 0",
    },
  },
  shopCoverBox: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  shopCoverBackground: {
    opacity: 0.5,
    width: "100%",
    height: 520,
    "-webkit-mask-image": "-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,1)))",
    "mask-image": "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))",
    "&>img": {
      objectFit: "cover",
    },
    [theme.breakpoints.down("xs")]: {
      height: 195,
    },
  },
  coverContainer: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: 0,
  },
  cover: {
    width: "100%",
    height: "100%",
    padding: "0 63px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 50px",
    },
  },
  shopInfoContent: {
    background: "none",
    margin: "auto",
    padding: "0 63px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 10px",
    },
  },
  coverImage: {
    width: "100%",
    objectFit: "cover",
    height: "100%",
    minWidth: 375,
  },
  content: {
    paddingTop: 4,
    WebkitLineClamp: 4,
    lineHeight: 1.5,
  },
  readMoreBtn: {
    marginTop: 5,
    color: theme.palette.primary.main,
    marginBottom: 24,
    cursor: "pointer",
  },
  btnSubmit: {
    margin: 36,
  },
  descriptionBox: {
    borderBottom: "1px solid rgba(151, 154, 161, 0.7)",
    marginBottom: 10,
  },
  changeTabBtn: {
    textTransform: "none",
    color: theme.palette.grey[800],
    padding: "12px 20px",
    borderRadius: "unset",
    minWidth: 107,
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "12px 6px",
    },
  },
  flexContainer: {
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill ,minmax(110px, 1fr))",
      display: "grid",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(auto-fill ,minmax(80px, 1fr))",
      display: "grid",
    },
  },
  changeTabBtnSelected: {
    textTransform: "none",
    padding: "12px 20px",
    borderRadius: "unset",
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
}));
