import React, { memo } from "react";
import PropTypes from "prop-types";

import {
  Typography,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import formatString from "string-format";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PersonIcon from "@material-ui/icons/Person";
import { AppConstant, LangConstant, PathConstant } from "const";
import { useTranslation } from "react-i18next";
import Icon from "@material-ui/core/Icon";
import { jumpToTargetBooking, jumpToTargetOrder } from "utils";

const NotificationItem = ({ data, uidKey, updateSeen, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const history = useHistory();
  const onItemClick = (e, uidKey) => {
    e.stopPropagation();
    switch (data[uidKey].type) {
      case AppConstant.NOTIFICATION_TYPE.reservation_info_updated:
      case AppConstant.NOTIFICATION_TYPE.reservation_new:
      case AppConstant.NOTIFICATION_TYPE.reservation_assign:
      case AppConstant.NOTIFICATION_TYPE.payment:
      case AppConstant.NOTIFICATION_TYPE.reservation_status_updated: {
        //refer to reservation books page
        onClose();
        if (data[uidKey].content?.type === AppConstant.DATA_TYPE.order) {
          jumpToTargetOrder(history, data[uidKey].content.reservation.startTime, data[uidKey].content.reservation.uid);
        } else {
          jumpToTargetBooking(
            history,
            data[uidKey].content.reservation.startTime,
            data[uidKey].content.reservation.uid,
          );
        }

        break;
      }
      case AppConstant.NOTIFICATION_TYPE.news: {
        history.push(PathConstant.MANAGE_NEWS);
        //refer to new page
        break;
      }
      case AppConstant.NOTIFICATION_TYPE.role_updated: {
        history.push(PathConstant.MANAGE_STAFF);
        //refer to staff page
        break;
      }
      default:
        return;
    }
    updateSeen([uidKey]);
  };

  const getSubject = key => {
    let dataFocus = data[key];
    let isOrder = Boolean(dataFocus.content?.type === AppConstant.DATA_TYPE.order);
    let subject = "";
    if (!dataFocus) return subject;
    let fromName = dataFocus.content.fromName
      ? dataFocus.content.fromName
      : getLabel(LangConstant.TXT_NOTIFICATION_CONSUMER);
    let code = dataFocus.content.code ? dataFocus.content.code : "";
    let status = dataFocus.content.status;
    let role = dataFocus.content.role;
    return getFormat(LangConstant.NOTIFICATION_TYPE_FORMAT[dataFocus.type], fromName, code, status, role, isOrder);
  };

  const getFormat = (fm, fromName, code, status, role, isOrder) => {
    return formatString(
      getLabel(fm),
      fromName,
      code,
      isOrder
        ? getLabel(LangConstant.OBJECT_STATUS_ORDER[status])
        : getLabel(LangConstant.ARR_STATUS_RESERVATION[status - 1]),
      getLabel(LangConstant.ROLE_LANG[role]),
    );
  };

  function getIcon(type) {
    switch (type) {
      case AppConstant.NOTIFICATION_TYPE.news: {
        return (
          <Icon color="inherit">
            <img className={classes.imageIcon} src="/notice_new.svg" alt={"notice_news"} />
          </Icon>
        );
      }
      case AppConstant.NOTIFICATION_TYPE.role_updated: {
        return <PersonIcon color="inherit" />;
      }
      default:
        return <DateRangeIcon color="inherit" />;
    }
  }

  return (
    <ListItem
      key={uidKey}
      id={uidKey}
      button={true}
      className={classes.notification}
      selected={data[uidKey].status === 1}
      classes={{ selected: classes.activeSelected, root: classes.item }}
      onClick={event => onItemClick(event, uidKey)}
    >
      <Grid item container direction="row" justify="flex-start">
        <Grid item xs container direction="row" alignItems="center" className={classes.noticeIconForm}>
          <Grid item>
            <ListItemIcon>
              <div className={classes.bgItem}>{getIcon(data[uidKey].type)}</div>
            </ListItemIcon>
          </Grid>
        </Grid>
        <Grid item xs={8} container>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                className={`medium-md-txt ${classes.typoNotice}`}
                dangerouslySetInnerHTML={{
                  __html: getSubject(uidKey),
                }}
              ></Typography>
            }
          />
        </Grid>
        <Grid item xs container className={classes.noticeIconForm}>
          <ListItemSecondaryAction>
            <Typography variant="body1" classes={{ body1: "light-sm-txt" }}>
              {data[uidKey].createTime}
            </Typography>
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  notification: {
    minHeight: "60px",
    backgroundColor: "#f1f3f6",
    paddingTop: "8px",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
  },
  notice: {
    "@media only screen and (min-width: 960px)": {
      maxWidth: "480px !important",
      minWidth: "480px",
    },
    height: "100%",
    maxHeight: "600px",
    width: "100%",
    minWidth: "200px",
    maxWidth: "456px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    paddingRight: "2px",
  },
  bgItem: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "#ffaa6e",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  typoNotice: {
    lineHeight: "1.57",
    fontWeight: "normal",
    fontStretch: "normal",
    color: "#3b404c",
  },
  activeSelected: {
    backgroundColor: "white !important",
    color: "white",
  },
  divider: {
    height: "710px",
    width: "1px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
  },
  item: {
    marginBottom: "8px",
  },
  noticeIconForm: {
    minWidth: "48px",
    maxWidth: "48px",
  },
}));

NotificationItem.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  key: PropTypes.string,
};
NotificationItem.defaultProps = {
  data: {},
  key: "",
};

export default memo(NotificationItem);
