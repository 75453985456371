export default {
  TXT_WAITING_TO_PROCESS: "Chờ xử lý",
  TXT_TRANSFERRED: "Đã chuyển",
  TXT_BILL_EXPORTED: "Đã gửi hóa đơn",
  TXT_SENT: "Đã gửi",

  TXT_FROM: "Từ",
  TXT_TO: "Đến",

  TXT_BILL_CODE: "Mã đơn hàng",
  TXT_COMPANY_NAME: "Tên công ty",
  TXT_COMPANY_TAX_NUMBER: "Mã số thuế",
  TXT_COMPANY_ADDRESS: "Địa chỉ công ty",
  TXT_RECEIVER_NAME: "Tên người nhận",
  TXT_VAT_BILL_EDIT: "Sửa thông tin hóa đơn VAT",
  TXT_DELETE_BILL: "Xóa hóa đơn",
  TXT_ADD_BILL_TITLE: "Nhập thông tin hóa đơn VAT",
  TXT_VAT_BILL: "Hóa đơn VAT",
  TXT_DELETE_BILL_SUCCESS: "Xóa hóa đơn thành công",

  FM_BATCH_UPDATE_STATUS_DIALOG_TITLE: "Chuyển trạng thái của {0} hóa đơn sang “{1}”?",
  FM_BATCH_UPDATE_STATUS_DIALOG_CONTENT: "Bạn có chắc muốn chuyển trạng thái của {0} hóa đơn đã chọn sang “{1}” không?",
  FM_CHANGE_VAT_BILL_STATUS: `Chuyển trạng thái mã hóa đơn "{0}"`,
  FM_DELETE_BILL: "Bạn có chắc muốn Xóa hóa đơn {0} không?",

  PH_COMPANY_NAME: "Tên công ty, Doanh nghiệp",
  PH_TAX_CODE: "Tax Code",
  PH_COMPANY_ADDRESS: "Địa chỉ cụ thể",
  PH_EMAIL: "Địa chỉ Email",
};
