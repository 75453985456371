import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import {
  DisplayModeBar,
  IconButtonMProductItem,
  CategorySubcategory,
  CategoryItemListBody,
  CategoryItemListHeader,
  DialogAddCategoryItem,
} from "components/mn-master-product";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { formatCurrency } from "utils";
import { useSelector, useDispatch } from "react-redux";
import MnProductRedux from "redux/mn-product.redux";
import { DialogDelete, DialogAddCategory } from "components/dialog";
import DialogAddAccompaniedProductDetails from "./DialogAddAccompaniedProductDetails";
import DialogAddTableAccompaniedProductDetails from "./DialogAddTableAccompaniedProductDetails";

const AccompaniedProductCategory = ({ childProductModeId, childProductModeArr, onChangeMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const [isOpenDialogAddCategory, setIsOpenDialogAddCategory] = useState(false);
  const [checkAdd, setCheckAdd] = useState(DETAILS);
  const [dataDialogAddCategory, setDataDialogAddCategory] = useState(null);
  const [onChangeNameDialogAddCategory, setOnChangeNameDialogAddCategory] = useState("");
  const [openAddAccompaniedProductAll, setOpenAddAccompaniedProductAll] = useState(false);
  const [isOnSearch, setIsOnSearch] = useState(false);
  const [isCategory, setIsCategory] = useState(true);
  const [isOpen, setIsOpen] = useState({
    editProduct: false,
    deleteProduct: false,
    viewAccompanied: false,
    editAccompanied: false,
    addFromMain: false,
    uid: "",
    name: "",
  });

  const [isOpenByUid, setIsOpenByUid] = useState({
    uid: "",
    name: "",
    isEdit: false,
    isWatch: false,
    isDelete: false,
    createAccompanied: true,
    itemUid: "",
  });
  const [checkProduct, setCheckProduct] = useState({ isCheckDetailsContent: false, uid: "" });
  const [dataDetailsUid, setDataDetailsUid] = useState(null);
  const [isDuplicate, seIsDuplicate] = useState(false);

  const dataDetails = useSelector(state => state.mnProductRedux.dataDetails);
  const isDetails = useSelector(state => state.mnProductRedux.isGetDetailsSuccess);
  const isRemoveDetails = useSelector(state => state.mnProductRedux.isDeleteDetailsSuccess);
  const isPutDetails = useSelector(state => state.mnProductRedux.isPutDetailsSuccess);
  const isPostDetails = useSelector(state => state.mnProductRedux.isPostDetailsSuccess);
  const isPostDetailsContent = useSelector(state => state.mnProductRedux.isPostDetailsContentSuccess);
  const isGetDetailsContent = useSelector(state => state.mnProductRedux.isGetDetailsContentSuccess);
  const isPutDetailsContent = useSelector(state => state.mnProductRedux.isPutDetailsContentSuccess);
  const isDeleteDetailsContent = useSelector(state => state.mnProductRedux.isDeleteDetailsContentSuccess);
  const isPutDetailsEditContent = useSelector(state => state.mnProductRedux.isPutDetailsEditContentSuccess);
  const errorUpdateDetails = useSelector(state => state.mnProductRedux.errorUpdateDetails);
  const errorCreateDetails = useSelector(state => state.mnProductRedux.errorCreateDetails);

  const onAddItem = () => {
    if (checkAdd === DETAILS) {
      setIsOpenDialogAddCategory(true);
    } else {
      setOpenAddAccompaniedProductAll(true);
    }
  };

  const onSearch = () => {};

  const onClickBackCategory = () => {
    setCheckAdd(DETAILS);
    setCheckProduct({ ...checkProduct, uid: "", isCheckDetailsContent: false });
  };

  const onCloseAll = () => {
    setDataDialogAddCategory(null);
    seIsDuplicate(false);
    setIsOpenByUid({ uid: "", name: "", isEdit: false, isWatch: false, isDelete: false, itemUid: "" });
    setIsOpen({
      viewAccompanied: false,
      editAccompanied: false,
      editProduct: false,
      deleteProduct: false,
      addFromMain: false,
      createAccompanied: false,
      uid: "",
      name: "",
    });
    setIsOpenDialogAddCategory(false);
    setOpenAddAccompaniedProductAll(false);
  };

  const onChangeDialogAddCategory = value => {
    setOnChangeNameDialogAddCategory(value);
  };

  const onSubmitCategory = () => {
    if (dataDialogAddCategory != null) {
      dispatch(MnProductRedux.updateDetails({ ...dataDialogAddCategory, name: onChangeNameDialogAddCategory }));
    } else {
      dispatch(MnProductRedux.createDetails({ name: onChangeNameDialogAddCategory, type: 3 }));
    }
    setDataDialogAddCategory(null);
  };

  const onClickCategorySubcategory = uid => {
    setCheckProduct({ ...checkProduct, uid: uid, isCheckDetailsContent: true });
    setDataDetailsUid(dataDetails.data.filter(data => data.uid === uid)[0]);
    setDataDialogAddCategory(null);
    setCheckAdd(DETAILS_CONTENT);
  };

  const onAddFromMainProduct = () => {
    setIsOpen({ ...isOpen, addFromMain: true });
  };

  const onCreateAccompaniedProduct = () => {
    setIsOpen({ ...isOpen, createAccompanied: true });
  };

  const onContextMenuCategorySubcategory = (uid, key) => {
    if (key === EDIT) {
      dataDetails.data.forEach(data => {
        if (data.uid === uid) {
          setDataDialogAddCategory({ name: data.name, uid: data.uid });
        }
      });
      setIsOpenDialogAddCategory(true);
    } else {
      let name = "";
      dataDetails.data.forEach(data => {
        if (data.uid === uid) {
          name = data.name;
        }
      });
      setDataDialogAddCategory({ name: name, uid: uid });
      setIsOpen({ ...isOpen, deleteProduct: true, uid: uid, name: name });
    }
  };

  const onConfirmRemoveProduct = (e, uid) => {
    if (dataDialogAddCategory != null) {
      onCloseAll();
      dispatch(MnProductRedux.removeDetails({ uid: uid }));
    } else {
      var itemDetailsContentList = dataDetailsUid.itemDetailsContentList.filter(data => data.uid !== uid);
      var detailsContentObjectList = [];
      for (var i = 0; i < itemDetailsContentList.length; i++) {
        detailsContentObjectList.push({ uid: itemDetailsContentList[i].uid, amount: 0 });
      }
      onCloseAll();
      dispatch(MnProductRedux.updateDetailsEditContent({ data: detailsContentObjectList, uid: dataDetailsUid.uid }));
    }
  };

  const onComeBack = () => {
    setIsOpen({
      addFromMain: false,
      createAccompanied: false,
      viewAccompanied: false,
      editAccompanied: false,
    });
  };

  const onClickEditBody = uid => {
    var data = dataDetailsUid.itemDetailsContentList.filter(dataMap => dataMap.uid === uid)[0];
    if (data.itemUid) {
      setIsOpenByUid({ ...isOpenByUid, isEdit: true, uid: uid });
    } else {
      setIsOpen({ ...isOpen, editAccompanied: true, uid: uid });
    }
  };

  const onClickDeleteBody = uid => {
    let name = "";
    dataDetailsUid.itemDetailsContentList.forEach(data => {
      if (data.uid === uid) {
        name = data.name;
      }
    });
    setIsOpen({ ...isOpen, deleteProduct: true, uid: uid, name: name });
    setIsOpenByUid({ ...isOpenByUid, isDelete: true, uid: uid, name: name });
  };

  const onClickWatchBody = uid => {
    var data = dataDetailsUid.itemDetailsContentList.filter(dataMap => dataMap.uid === uid)[0];
    if (data.itemUid) {
      setIsOpenByUid({ ...isOpenByUid, isWatch: true, uid: uid });
    } else {
      setIsOpen({ ...isOpen, viewAccompanied: true, uid: uid });
    }
  };

  useEffect(() => {
    dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
  }, []);

  useEffect(() => {
    if (isPutDetailsEditContent) {
      if (!isOpen.addFromMain) {
        onCloseAll();
      }
      dispatch(MnProductRedux.resetMnProduct());
      dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isPutDetailsEditContent]);

  useEffect(() => {
    if (isDetails) {
      if (!isOpen.addFromMain) {
        onCloseAll();
      }
      dispatch(MnProductRedux.resetMnProduct());
      setDataDetailsUid(dataDetails.data.filter(data => data.uid === checkProduct.uid)[0]);
    }
  }, [isDetails]);

  useEffect(() => {
    if (isRemoveDetails) {
      onCloseAll();
      dispatch(MnProductRedux.resetMnProduct());
      dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isRemoveDetails]);

  useEffect(() => {
    if (isPutDetails) {
      if (!isOpen.addFromMain) {
        onCloseAll();
      }
      dispatch(MnProductRedux.resetMnProduct());
      dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isPutDetails]);

  useEffect(() => {
    if (isPostDetails) {
      onCloseAll();
      dispatch(MnProductRedux.resetMnProduct());
      dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isPostDetails]);

  useEffect(() => {
    if (isDeleteDetailsContent) {
      onCloseAll();
      dispatch(MnProductRedux.resetMnProduct());
      dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isDeleteDetailsContent]);

  useEffect(() => {
    if (isPutDetailsContent) {
      onCloseAll();
      dispatch(MnProductRedux.resetMnProduct());
      dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isPutDetailsContent]);

  useEffect(() => {
    if (isGetDetailsContent) {
      if (!isOpen.addFromMain) {
        onCloseAll();
      }
      dispatch(MnProductRedux.resetMnProduct());
      setDataDetailsUid(dataDetails.data.filter(data => data.uid === checkProduct.uid)[0]);
    }
  }, [isGetDetailsContent]);

  useEffect(() => {
    if (isPostDetailsContent) {
      if (!isOpen.addFromMain) {
        onCloseAll();
      }
      dispatch(MnProductRedux.resetMnProduct());
      dispatch(MnProductRedux.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
      dispatch(MnProductRedux.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isPostDetailsContent]);

  useEffect(() => {
    if (dataDetails && dataDetails?.data.length > 0) {
      setIsOnSearch(true);
      setIsCategory(true);
    } else if (dataDetails && dataDetails?.data.length === 0) {
      setIsOnSearch(false);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (dataDetailsUid && dataDetailsUid?.itemDetailsContentList.length > 0) {
      setIsOnSearch(true);
      setIsCategory(false);
    } else if (dataDetailsUid && dataDetailsUid?.itemDetailsContentList.length === 0) {
      setIsOnSearch(false);
    }
  }, [dataDetailsUid]);

  useEffect(() => {
    if (errorUpdateDetails) {
      if (errorUpdateDetails?.name[0] !== getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsOpenDialogAddCategory(false);
      } else {
        seIsDuplicate(true);
        dispatch(MnProductRedux.resetErrorMnProduct());
      }
    }
    if (errorCreateDetails) {
      if (errorCreateDetails?.name[0] !== getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsOpenDialogAddCategory(false);
      } else {
        seIsDuplicate(true);
        dispatch(MnProductRedux.resetErrorMnProduct());
      }
    }
  }, [errorUpdateDetails, errorCreateDetails]);

  return (
    <>
      <DisplayModeBar
        modeId={childProductModeId}
        modeArray={childProductModeArr}
        labelBtn={
          checkAdd === DETAILS_CONTENT
            ? getLabel(LangConstant.TXT_CREATE_PRODUCT)
            : getLabel(LangConstant.TXT_ADD_CATEGORY_PRODUCT)
        }
        onChange={onChangeMode}
        onAddItem={onAddItem}
        onSearch={onSearch}
        showSearch={isOnSearch}
      />
      <Box className={classes.boxCategorySubCategory}>
        {dataDetails && dataDetails?.data.length > 0 ? (
          <>
            <Box>
              {checkProduct.isCheckDetailsContent ? (
                <>
                  {dataDetailsUid.itemDetailsContentList.length > 0 ? (
                    <Box className={classes.rootBody}>
                      <Box display="flex" margin="24px 0" paddingLeft="24px">
                        <Typography onClick={onClickBackCategory} className={classes.categorySourceBox}>
                          {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)} &nbsp; / &nbsp;
                        </Typography>
                        <Typography onClick={onClickBackCategory} className={classes.categoryName}>
                          {dataDetailsUid.name}
                        </Typography>
                      </Box>
                      <CategoryItemListHeader isProduct={true} />
                      {dataDetailsUid.itemDetailsContentList.map(dataProductItem => (
                        <CategoryItemListBody
                          key={dataProductItem.uid}
                          uid={dataProductItem.uid}
                          onClickEdit={onClickEditBody}
                          onClickDelete={onClickDeleteBody}
                          onClickWatch={onClickWatchBody}
                          categoryName={dataProductItem.name}
                          isAdd={false}
                          stock={dataProductItem.stock ? dataProductItem.stock : 0}
                          image={dataProductItem.image}
                          price={dataProductItem.price ? formatCurrency(`${dataProductItem.price}`) : ""}
                          disabled={Boolean(dataProductItem.itemDetailsContentUid)}
                          isNotClickEdit={Boolean(isOpenByUid.isEdit && dataProductItem.itemUid)}
                        />
                      ))}
                    </Box>
                  ) : (
                    <>
                      <Box display="flex" margin="24px 0" paddingLeft="24px">
                        <Typography onClick={onClickBackCategory} className={classes.categorySourceBox}>
                          {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)} &nbsp; / &nbsp;
                        </Typography>
                        <Typography onClick={onClickBackCategory} className={classes.categoryName}>
                          {dataDetailsUid.name}
                        </Typography>
                      </Box>
                      <Box className={classes.contentBox}>
                        <IconButtonMProductItem
                          label={getLabel(LangConstant.TXT_CREATE_PRODUCT)}
                          onClick={onAddItem}
                          buttonClasses={{ root: classes.addButton }}
                        ></IconButtonMProductItem>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <Box className={classes.listProductCategorySub}>
                  {dataDetails?.data.map(dataMap => (
                    <Box margin="12px" display="grid" key={dataMap.uid}>
                      <CategorySubcategory
                        uid={dataMap.uid}
                        text={dataMap.name}
                        onClick={onClickCategorySubcategory}
                        onContextMenu={onContextMenuCategorySubcategory}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box className={classes.contentBox}>
            <IconButtonMProductItem
              label={
                isCategory || checkAdd === DETAILS_CONTENT
                  ? getLabel(LangConstant.TXT_ADD_CATEGORY_PRODUCT)
                  : getLabel(LangConstant.TXT_CREATE_PRODUCT)
              }
              onClick={onAddItem}
              buttonClasses={{ root: classes.addButton }}
            />
          </Box>
        )}
      </Box>
      {openAddAccompaniedProductAll && (
        <DialogAddCategoryItem
          isShow={openAddAccompaniedProductAll}
          titleName={{
            header: getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_HEADER),
            add: getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_ADD),
            created: getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE),
          }}
          action={onCloseAll}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddFromMainService={onAddFromMainProduct}
          onCreateAccompaniedService={onCreateAccompaniedProduct}
        />
      )}
      {isOpen.addFromMain && (
        <DialogAddTableAccompaniedProductDetails
          isOpen={isOpen.addFromMain}
          onClose={onCloseAll}
          onBack={onComeBack}
          nameHeader={getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_ADD_DIALOG)}
          dataChild={dataDetailsUid}
        />
      )}
      {isOpenDialogAddCategory && (
        <DialogAddCategory
          isShow={isOpenDialogAddCategory}
          onChange={onChangeDialogAddCategory}
          onClose={onCloseAll}
          onSubmit={onSubmitCategory}
          data={dataDialogAddCategory}
          isWarning={isDuplicate}
          nameWaring={isDuplicate ? getLabel(LangConstant.TXT_WARNING_CATEGORY) : ""}
        />
      )}
      {(isOpen.editAccompanied || isOpen.viewAccompanied || isOpen.createAccompanied) && (
        <DialogAddAccompaniedProductDetails
          isOpenWatch={isOpen.viewAccompanied}
          isOpenEdit={isOpen.editAccompanied}
          isOpenCreate={isOpen.createAccompanied}
          onClose={onCloseAll}
          onBack={onComeBack}
          dataDetailsUid={dataDetailsUid}
          data={dataDetailsUid.itemDetailsContentList.filter(dataMap => dataMap.uid === isOpen.uid)[0]}
        />
      )}
      {isOpen.deleteProduct && (
        <DialogDelete
          uid={isOpen.uid}
          isShow={isOpen.deleteProduct}
          onCancel={onCloseAll}
          onConfirm={onConfirmRemoveProduct}
          title={
            dataDialogAddCategory != null
              ? getLabel(LangConstant.FN_DELETE_GOODS_CATEGORY_TITLE)(isOpen.name)
              : getLabel(LangConstant.FN_DELETE_GOODS_ITEM_TITLE)(isOpen.name)
          }
          content={
            dataDialogAddCategory != null
              ? getLabel(LangConstant.FN_DELETE_GOODS_CATEGORY_CONTENT)(isOpen.name)
              : getLabel(LangConstant.FN_DELETE_GOODS_SUB_ITEM_CONTENT)(isOpen.name)
          }
        />
      )}
    </>
  );
};

export default memo(AccompaniedProductCategory);

const useStyles = makeStyles(theme => ({
  categorySourceBox: { color: "#47494e", cursor: "pointer", "&:hover": { color: "#4d85ff" } },
  contentBox: {
    width: "100%",
    minHeight: "720px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  category: {
    textTransform: "none",
    marginLeft: 15,
  },
  categoryName: {
    color: "#5790ff",
    textTransform: "none",
  },
  headerBoxName: {
    width: "100%",
    height: 30,
    fontSize: 14,
    marginTop: 15,
    marginBottom: 30,
  },
  rootBody: {
    marginBottom: 62,
    width: "94%",
    marginLeft: "3%",
    [theme.breakpoints.down(600)]: {
      overflowX: "auto",
    },
  },
  listProductCategorySub: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(304px, 1fr))",
  },
  boxCategorySubCategory: {
    height: "fit-content",
    gridTemplateColumns: "repeat(auto-fill,minmax(304px, 1fr))",
  },
}));

const DETAILS = "details";
const DETAILS_CONTENT = "details_content";
const EDIT = "edit";
