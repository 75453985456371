import React, { memo, useState } from "react";
import { makeStyles, Popover, Button, Box, Typography, TextField, Chip, Paper } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { LangConstant } from "../../../../const";
import { EmailIcon } from "../../../../theme/images";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import PopupSendInvite from "./PopupSendInvite";
import { validateEmail, removeUnnecessarySpaces } from "../../../../utils";
const PopupInvite = ({ open, setOpen, ...props }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [email, setEmail] = useState([]);
  const [inputEmail, setInputEmail] = useState("");

  const onClose = () => {
    setOpen(false);
  };

  const onNext = () => {
    let displayEmails = [...email];
    let error = [];
    let emails = [];
    if (inputEmail && inputEmail.length > 0) {
      displayEmails.push(inputEmail);
    }
    // create list correct emails/ error validation email
    displayEmails.forEach(email => (validateEmail(email) ? emails.push({ email: email, role: 3 }) : error.push(email)));
    if (emails.length > 0) {
      setData(emails);
      setEmail([]);
      setOpenSuccess(true);
    }
  };

  const onInputChangeEmail = (event, newInputValue) => {
    let trimInput = newInputValue.trim();
    let options = removeUnnecessarySpaces(newInputValue).split(" ");
    const fieldValue = email.concat(options);
    if (options.length > 1) {
      onChangeEmail(event, fieldValue);
      return;
    }
    let space = newInputValue.indexOf(" ") >= 0;
    if (space && trimInput.length > 0) {
      setInputEmail("");
      setEmail([...email, trimInput]);
    } else {
      setInputEmail(trimInput);
    }
  };

  const onChangeEmail = (event, emails) => {
    setEmail(emails);
    event.persist();
  };

  const validateButton = (inputEmail, email) => {
    let emails = [];
    if (email.length > 0) {
      email.forEach(e => (validateEmail(e) ? emails.push(e) : null));
    }
    if ((inputEmail.length > 0 && validateEmail(inputEmail)) || emails.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <Popover
      open={open}
      anchorEl={document.body}
      anchorPosition={{ left: 0, top: 0 }}
      anchorReference="anchorPosition"
      marginThreshold={0}
      classes={{ paper: classes.PaperProps }}
    >
      <Paper elevation={1} square classes={{ root: classes.rootPaper, elevation1: classes.elevation1 }}>
        <Box className={classes.boxCloseParent}>
          <Box className={classes.boxCloseChildren}>
            <Button
              onClick={() => onClose()}
              classes={{
                root: classes.rootButton,
                label: classes.label,
                startIcon: classes.startIcon,
                iconSizeMedium: classes.iconSizeMedium,
              }}
              startIcon={<Close className={classes.rootIcon} />}
            />
          </Box>
        </Box>
        <Box className={classes.boxBodyParent}>
          <Box className={classes.boxBodyChildren}>
            <Box className={classes.boxTitle}>
              <Typography variant="h5">{getLabel(LangConstant.TXT_INVITE_TO_TEAM_STAFF)}</Typography>
            </Box>
            <Box className={classes.boxContentParent}>
              <Box className={classes.boxContentChildren}>
                <img src={EmailIcon} alt="email" />
                <Box className={classes.optionChoose}>{getLabel(LangConstant.TXT_INVITE_EMAIL)}</Box>
              </Box>
              <Autocomplete
                classes={{ root: classes.rootAutoComplete, inputFocused: classes.rootInputField }}
                multiple
                disableClearable={true}
                options={[]}
                freeSolo
                renderTags={(emails, getTagProps) =>
                  emails.map((email, index) => (
                    <Chip
                      key={index}
                      classes={{ outlinedPrimary: classes.outlinedChip }}
                      variant="outlined"
                      label={email}
                      color={validateEmail(email) ? "default" : "primary"}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                value={email}
                inputValue={inputEmail}
                onChange={onChangeEmail}
                onInputChange={onInputChangeEmail}
                renderInput={params => (
                  <>
                    <TextField className={classes.fieldRoot} {...params} variant="outlined" {...props} />

                    <Box className={classes.boxBtn}>
                      <Button
                        classes={{
                          root: classes.rootBtnEmail,
                          contained: classes.contained,
                          disabled: classes.disabled,
                        }}
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={onNext}
                        disabled={validateButton(inputEmail, email)}
                      >
                        {getLabel(LangConstant.TXT_NEXT)}
                      </Button>
                    </Box>
                  </>
                )}
              />
            </Box>
          </Box>
        </Box>
        <PopupSendInvite open={openSuccess} setOpen={setOpenSuccess} data={data} />
      </Paper>
    </Popover>
  );
};
const useStyles = makeStyles(() => ({
  PaperProps: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
  },
  rootPaper: {
    height: "70%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
  },
  elevation1: {
    boxShadow: "none",
  },
  boxCloseParent: {
    marginRight: "0",
    height: "50px",
    marginTop: "20px",
    maxWidth: "100%",
  },
  boxCloseChildren: {
    height: "100%",
    width: "20%",
    marginLeft: "80%",
  },
  boxBodyParent: {
    width: "100%",
    height: "100%",
  },
  boxBodyChildren: {
    height: "100%",
    width: "90%",
    margin: "auto",
  },
  boxTitle: {
    textAlign: "center",
    marginTop: "90px",
    "@media only screen and (max-width: 1000px)": {
      marginTop: "50px",
    },
  },
  boxContentParent: {
    marginTop: "50px",
    "@media only screen and (max-width: 1000px)": {
      marginTop: "38px",
    },
  },
  boxContentChildren: {
    display: "flex",
    justifyContent: "left",
    width: "230px",
    margin: "auto",
    "@media only screen and (max-width: 1000px)": {
      marginLeft: "0",
      justifyContent: "center",
      width: "auto",
      paddingLeft: "0",
    },
  },
  outlinedChip: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  rootBtnEmail: {
    marginTop: "50px",
    padding: "0 16px 0",
    minHeight: "46px",
    fontWeight: "inherit",
    borderRadius: "25px",
    minWidth: "107px",
    textTransform: "capitalize",
    color: "#ffffff",
    "@media only screen and (max-width: 600px)": {
      maxWidth: "160px",
      minHeight: "40px",
      borderRadius: "20px",
    },
  },
  contained: {
    "&$disabled": {
      backgroundColor: "#EF5845",
      opacity: 0.6,
      color: "#ffffff",
    },
  },
  disabled: {
    backgroundColor: "#EF5845",
    opacity: 0.6,
  },
  rootButton: {
    width: "50px",
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "20px",
  },
  rootAutoComplete: {
    marginLeft: "25%",
    marginRight: "25%",
    "@media only screen and (max-width: 600px)": {
      marginLeft: "0",
      marginRight: "0",
    },
  },
  label: {
    height: "100%",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },

  iconSizeMedium: {
    fontSize: "30px",
  },
  optionChoose: {
    marginLeft: "20px",
  },
  fieldRoot: {
    marginTop: "23px",
  },
  boxBtn: {
    textAlign: "center",
  },
}));
export default memo(PopupInvite);
