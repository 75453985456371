import React, { memo, useEffect } from "react";
import { makeStyles, Box, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { LangConstant } from "const";
import { BglobalJSC } from "components";
import { TablePaymentHistory, PaginationPaymentHistory } from "./components";
import PaymentAction from "redux/payment.redux";

const PaymentHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const dataUser = useSelector(({ userRedux }) => userRedux.dataUser);

  useEffect(() => {
    if (dataUser?.id) {
      dispatch(PaymentAction.getPaymentHistory({ userId: dataUser.id }));
    }
  }, [dataUser?.id]);

  return (
    <>
      <Paper elevation={1} square>
        <Box className={classes.titleBox}>
          <Typography className={classes.titleText}>{getLabel(LangConstant.TXT_PAYMENT_HISTORY)}</Typography>
        </Box>
        <Box className={classes.contentBox}>
          <TablePaymentHistory />
          <PaginationPaymentHistory />
        </Box>
      </Paper>
      <BglobalJSC className={classes.boxJSC} />
    </>
  );
};

export default memo(PaymentHistory);

const useStyles = makeStyles(theme => ({
  titleBox: {
    width: "100%",
    padding: "48px 100px",
    [theme.breakpoints.down("xs")]: {
      padding: "24px",
    },
  },
  titleText: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.33,
    color: theme.palette.grey[800],
  },
  contentBox: {
    width: "100%",
    padding: "0 100px 48px",
    [theme.breakpoints.down(1250)]: {
      flexDirection: "column",
      padding: "0 50px 48px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: "0 24px 24px",
    },
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));
