import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { ControlCamera, KeyboardArrowDown, KeyboardArrowUp, Place } from "@material-ui/icons";
import { Box, makeStyles, Paper, OutlinedInput, Typography, Grid, Tooltip, Button, TextField } from "@material-ui/core";
import { getCommonKey, isArrayNotEqual, uuid } from "utils";
import { AppConstant, LangConstant } from "const";
import AddressItem from "./AddressItem";
import RemoteOrderPreviewDetail from "./RemoteOrderPreviewDetail";
import { Autocomplete } from "@material-ui/lab";
import Map from "./Map";

const RemoteOrderInfo = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);

  const [remoteOrderForm, setRemoteOrderForm] = useState(DEFAULT_REMOTE_ORDER_FORM);
  const [validateStatus, setValidateStatus] = useState();

  return (
    <>
      <Box className={classes.preOrderInfoTitle}>
        <Typography className={classes.infoTitle}>{getLabel(LangConstant.TXT_REMOTE_ORDER_DETAIL)}</Typography>
        <Typography className={classes.infoMessage} variant="body2">
          {getLabel(LangConstant.TXT_INSTRUCTION_REMOTE_ORDER)}
        </Typography>
        <Box className={classes.titleBottomDivider} />
      </Box>
      <AddressPaper
        setRemoteOrderForm={newForm => {
          setRemoteOrderForm(newForm);
          setValidateStatus();
        }}
        remoteOrderForm={remoteOrderForm}
        validateStatus={validateStatus}
      />
      <PaymentInfo />
      <OrderDetailPreview />
    </>
  );
};

const DEFAULT_REMOTE_ORDER_FORM = {
  consumerName: "",
  phoneNumber: "",
  address: "",
};

const AddressPaper = ({ remoteOrderForm, setRemoteOrderForm, onOpenAddAddressDialog }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);

  const addressListRedux = useSelector(({ userRedux }) => userRedux.addressList, shallowEqual);

  const [isExpandingAddressList, setIsExpandingAddressList] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const onSelectAddress = addressItem => {
    setSelectedAddress(addressItem);
  };

  const onCreateNewAddress = () => {
    onOpenAddAddressDialog(null);
  };

  const onEditAddressDetail = addressItem => {
    onOpenAddAddressDialog(addressItem);
  };

  const onOpenAddressList = () => {
    setIsExpandingAddressList(!isExpandingAddressList);
  };

  const onChangeForm = event => {
    let newFormValue = { ...remoteOrderForm };
    let name = event.target.name;
    let value = event.target.value;
    newFormValue[name] = value;
    setRemoteOrderForm(newFormValue);
  };

  const onSelectAddressSearchItem = () => {};

  useEffect(() => {
    if (selectedAddress) {
      let newFormValue = { ...remoteOrderForm };
      let shippingDetails = {
        longitude: selectedAddress.longitude || 0,
        latitude: selectedAddress.latitude || 0,
      };

      newFormValue.address = selectedAddress.address;
      newFormValue.details = { ...shippingDetails };
      setRemoteOrderForm(newFormValue);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (isArrayNotEqual(addressListRedux, addressList)) setAddressList(addressListRedux);
  }, [addressListRedux]);

  return (
    <Paper className={classes.preOrderPaper}>
      <Box className={clsx(classes.customerInformationForm, classes.mapContainerPaper)}>
        <Typography className={classes.customerInfoTitle} variant="subtitle1">
          <ControlCamera className={classes.lineHeaderIcon} />
          {getLabel(LangConstant.TXT_PICKUP_ADDRESS)}
        </Typography>
        <Box className={classes.formContainer}>
          <Grid container className={classes.inputLabelBox}>
            <Grid item sm={3}>
              <Typography className={clsx(classes.inputLabel, "regular-lg-txt")}>
                {getLabel(LangConstant.TXT_RECEIVER_NAME)}
              </Typography>
            </Grid>
            <Grid item sm={9}>
              <OutlinedInput
                placeholder={getLabel(LangConstant.TXT_PLACEHOLDER_NAME)}
                classes={{
                  root: classes.inputFieldRoot,
                  input: clsx(classes.input, "medium-lg-txt"),
                  notchedOutline: classes.notchedOutlineBorderless,
                }}
                name="consumerName"
                value={remoteOrderForm.consumerName || ""}
                onChange={onChangeForm}
              />
            </Grid>
          </Grid>
          <Box className={classes.inputLabelBox}>
            <Grid container className={classes.inputLabelBox}>
              <Grid item sm={3}>
                <Typography className={clsx(classes.inputLabel, "regular-lg-txt")}>
                  {getLabel(LangConstant.TXT_RECEIVER_PHONE)}
                </Typography>
              </Grid>
              <Grid item sm={9}>
                <OutlinedInput
                  placeholder={getLabel(LangConstant.TXT_PLACEHOLDER_PHONE_NUMBER)}
                  classes={{
                    root: classes.inputFieldRoot,
                    input: clsx(classes.input, "medium-lg-txt"),
                    notchedOutline: classes.notchedOutlineBorderless,
                  }}
                  name="phoneNumber"
                  value={remoteOrderForm.phoneNumber || ""}
                  onChange={onChangeForm}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.inputLabelBox}>
            <Grid container className={classes.inputLabelBox}>
              <Grid item sm={3}>
                <Typography className={clsx(classes.inputLabel, "regular-lg-txt")}>
                  {getLabel(LangConstant.TXT_RESERVATION_ADDRESS)}
                </Typography>
              </Grid>
              <Grid item sm={9} className={classes.addressInputContainer}>
                <Autocomplete
                  value={remoteOrderForm.address || ""}
                  options={[]}
                  disableClearable={true}
                  forcePopupIcon={false}
                  disablePortal={true}
                  getOptionSelected={() => true}
                  onChange={(_, newValue) => {
                    onSelectAddressSearchItem(newValue);
                  }}
                  classes={{
                    listbox: classes.autocompleteListBox,
                    paper: "hidden",
                    option: clsx("regular-lg-txt", classes.autocompleteItem),
                  }}
                  renderInput={params => {
                    return (
                      <TextField
                        onChange={onChangeForm}
                        name="address"
                        {...params}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: getLabel(LangConstant.TXT_PLACEHOLDER_ADDRESS),
                          className: classes.input,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            root: clsx(classes.inputFieldRoot),
                            input: clsx(classes.input, "medium-lg-txt"),
                            notchedOutline: classes.notchedOutlineBorderless,
                          },
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.mapContainer}>
          <Map />
        </Box>
      </Box>

      {isExpandingAddressList && (
        <AddressList
          onCreateNewAddress={onCreateNewAddress}
          addressList={addressList}
          selectedAddress={selectedAddress}
          onSelectAddress={onSelectAddress}
          onEditAddressDetail={onEditAddressDetail}
        />
      )}

      <Box className={classes.extendAddressButton} onClick={onOpenAddressList}>
        <Typography className={clsx(classes.pickingUpAddress, "medium-md-txt")}>
          {getLabel(LangConstant.TXT_PICKUP_ADDRESS)}
        </Typography>
        {isExpandingAddressList ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Box>
    </Paper>
  );
};

AddressPaper.propTypes = {
  remoteOrderForm: PropTypes.object,
  validateStatus: PropTypes.object,
  userCurrentCoordinate: PropTypes.object,
  selectedAddress: PropTypes.object,

  setRemoteOrderForm: PropTypes.func,
  onOpenAddAddressDialog: PropTypes.func,
  setSelectedAddress: PropTypes.func,
};

AddressPaper.defaultProps = {
  setRemoteOrderForm: () => {},
  onOpenAddAddressDialog: () => {},
  setSelectedAddress: () => {},
};

const PaymentInfo = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const shopData = useSelector(state => state.reservationRedux.reservationSetting);

  let shippingInfo =
    shopData &&
    Object.values(shopData).length > 0 &&
    Object.values(shopData).find(item => item.type === AppConstant.RESERVATION_SETTING_OPTION.order);

  return (
    <Paper className={classes.shippingDetailPaper}>
      <Box className={classes.customerInformationForm}>
        <Typography className={clsx(classes.customerInfoTitle, classes.shippingInfoTitle)} variant="subtitle1">
          <ControlCamera className={classes.lineHeaderIcon} />
          {getLabel(LangConstant.TXT_DEPOSIT_PAYMENT_INFO)}
        </Typography>
        <Grid container className={classes.otherInfoContainer}>
          <Grid xs={12} item className={classes.otherInfoRow}>
            <Grid item xs={3} className={classes.infoRow}>
              <Typography variant="body2">{getLabel(LangConstant.TXT_PAYMENT_METHOD)}</Typography>
            </Grid>
            <Grid item xs={8} className={classes.infoRow}>
              <Typography className="semiBold-md-txt">{shippingInfo?.details.depositInformation.content}</Typography>
            </Grid>
          </Grid>
          <Grid xs={12} item className={classes.otherInfoRow}>
            <Grid item xs={3} className={classes.infoRow}>
              <Typography variant="body2">{getLabel(LangConstant.TXT_SHIPPING_COST)}</Typography>
            </Grid>
            <Grid item xs={8} className={classes.infoRow}>
              <Typography className="semiBold-md-txt">{shippingInfo?.details.shippingInformation.content}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

PaymentInfo.propTypes = {
  options: PropTypes.array,
};

const OrderDetailPreview = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING);

  return (
    <Paper className={classes.shippingDetailPaper}>
      <Box className={classes.customerInformationForm}>
        <Typography className={classes.customerInfoTitle} variant="subtitle1">
          <ControlCamera className={classes.lineHeaderIcon} />
          {getLabel(getCommonKey(LangConstant.TXT_MASTER_PRODUCT))}
        </Typography>
        <Box className={classes.remoteOrderDetailPreview}>
          <RemoteOrderPreviewDetail />
        </Box>
      </Box>
    </Paper>
  );
};

const SuccessAction = ({ message, onClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.confirmActionContainer}>
      <Typography variant="h6" className={classes.confirmAction} onClick={onClick}>
        {message}
      </Typography>
    </Box>
  );
};

const AddressList = ({ onCreateNewAddress, onSelectAddress, onEditAddressDetail }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  return (
    <>
      <Box className={classes.addressBoxTitle}>
        <Typography variant="subtitle2" className={classes.addressTitle}>
          {getLabel(LangConstant.TXT_ORDER_PICKUP_DETAIL)}
        </Typography>
        <Button
          variant="text"
          className={clsx(classes.addAddress, "semiBold-md-txt")}
          color="primary"
          onClick={onCreateNewAddress}
        >
          {getLabel(LangConstant.TXT_ADD_ADDRESS)}
        </Button>
      </Box>
      <Box className={classes.addressListContainer}>
        <AddressItem
          addressDetail={DEMO_ADDRESS_DETAIL}
          isChecked={true}
          onEdit={onEditAddressDetail}
          onSelect={onSelectAddress}
        />
      </Box>
    </>
  );
};

const DEMO_ADDRESS_DETAIL = {
  uid: uuid(),
  phoneNumber: "012345678",
  title: "Địa chỉ người nhận",
  contactName: "Tên người nhận hàng",
  address: "Số 2, tòa CIC, phố Nguyễn Thị Duệ, Trung Kính, Cầu Giấy, Hà Nội",
  defaultF: true,
};

AddressList.propTypes = {
  addressList: PropTypes.array,

  selectedAddress: PropTypes.object,

  onCreateNewAddress: PropTypes.func,
  onSelectAddress: PropTypes.func,
  onEditAddressDetail: PropTypes.func,
};

const Marker = props => {
  const { text } = props;
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      classes={{
        tooltipArrow: classes.tooltipArrow,
      }}
      placement="top"
      PopperProps={{
        disablePortal: true,
      }}
      open={true}
      title={text}
    >
      <Place color="primary" fontSize="large" />
    </Tooltip>
  );
};
Marker.propTypes = { text: PropTypes.string };

SuccessAction.propTypes = {
  message: PropTypes.string,

  onClick: PropTypes.func.isRequired,
};

export default memo(RemoteOrderInfo);

const useStyles = makeStyles(theme => ({
  preOrderPaper: {
    width: "100%",
  },

  shippingDetailPaper: {
    marginTop: 24,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
    },
  },

  preOrderInfoTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 24,
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
  },

  titleBottomDivider: {
    height: 1,
    backgroundColor: "#9D9FA5",
    marginTop: 16,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  infoTitle: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.25,
    marginBottom: 16,
  },

  notchedOutlineBorderless: {
    border: "none",
  },

  input: {
    height: "100% !important",
    padding: "0 14px",
    "&::placeholder": {
      color: theme.palette.grey[600],
    },
  },

  inputFieldRoot: {
    border: "0.5px solid #9D9FA5",
    borderRadius: "unset",
    width: "100%",
    height: 45,
    marginBottom: 4,
    padding: 0 + "!important",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 218,
    },
  },

  inputLabel: {
    lineHeight: 1.69,
  },

  customerInfoTitle: {
    marginBottom: 26,
    display: "flex",
    alignItems: "center",
    "&>svg": {
      marginRight: 8,
    },
  },

  customerInformationForm: {
    padding: "26px 121px 48px 121px",
    [theme.breakpoints.down("sm")]: {
      padding: "26px 50px 48px 50px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "16px 10px",
    },
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  infoRow: {
    display: "flex",
    alignItems: "flex-end",
  },

  otherInfoContainer: {
    display: "flex",
  },

  otherInfoRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "13px 0",
  },

  lineHeaderIcon: {
    width: 32,
    height: 32,
  },

  inputLabelBox: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  errorContent: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.reservation.finished,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 20,
    "&>svg": {
      width: 12,
      height: 12,
    },
  },

  confirmActionContainer: {},

  confirmAction: {
    textTransform: "uppercase",
    color: theme.palette.white,
  },

  shippingInfoTitle: {
    marginBottom: 40,
  },

  extendAddressButton: {
    width: "100%",
    height: 50,
    backgroundColor: theme.palette.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "1px 0px 4px rgba(0, 0, 0, 0.16)",
    cursor: "pointer",
    "&>svg": {
      width: 24,
      height: 24,
      marginTop: 2,
      color: "#9D9FA5",
    },
  },

  pickingUpAddress: {
    color: "#9D9FA5",
    userSelect: "none",
  },

  addressListContainer: {
    width: "100%",
    height: "auto",
    padding: "0 121px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 50px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  addressBoxTitle: {
    padding: "0 121px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "0 50px",
    },
  },

  addAddress: {
    textTransform: "none",
    padding: 0,
  },

  infoMessage: {
    textAlign: "center",
  },

  mapContainer: {
    width: "100%",
    height: 150,
    marginTop: 16,
  },

  addressInputContainer: {
    position: "relative",
  },

  autocompleteListBox: {
    padding: 0,
  },

  autocompletePaper: {
    margin: 0,
    maxHeight: 250,
    "&>::-webkit-scrollbar": {
      display: "none",
    },
  },

  autocompleteItem: {
    height: 34,
    color: "#3B404C",
    padding: "5px 10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    backgroundColor: theme.palette.white + "!important",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: "#3B404C !important",
    },
  },
}));
