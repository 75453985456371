import { ApiConstant, AppConstant, ImageConstant, LangConstant, PathConstant } from "const";
import Cookie from "js-cookie";
import AppColors from "../theme/material/palette";
import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { isString } from "@amcharts/amcharts4/.internal/core/utils/Type";
import { getLabel } from "language";
import StringFormat from "string-format";
import { convertTimeStampToDate, getStartOfDay, getTimestamp } from "./date";
import { parse as dateParse } from "date-fns";

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .replace(/-/g, "");
};

export const validateEmail = content => {
  /* eslint-disable no-useless-escape */
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return content && regex.test(String(content).replace(/\s+/g, "").toLowerCase());
};

export const validatePhone = content => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/;
  const isValid = content && content.length > 8 && content.length <= 16;
  return isValid && regex.test(String(content).replace(/\s+/g, "").toLowerCase());
};

export const validBirthday = dateString => {
  if (!dateString) return true;

  let TIMESTAMP_1920 = -1577948400;

  const regex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

  let timestamp = getTimestamp(dateParse(dateString || "", AppConstant.FM_DD_MM_YYYY, new Date()));

  let isInPast = Boolean(Date.now() / 1000 - timestamp > 0);
  let startDateLimit = Boolean(timestamp - TIMESTAMP_1920 > 0);
  let isValidDate = regex.test(String(dateString).replace(/\s+/g, "").toLowerCase());

  return Boolean(isInPast && isValidDate && startDateLimit);
};

export const validatePassword = content => {
  return content && content.length >= 8;
};

export const snakeToCamelCase = str =>
  str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const camelToSnakeCase = str =>
  (str.charAt(0).toLowerCase() + str.slice(1) || str).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const toCamel = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = snakeToCamelCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toCamel(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const toSnake = obj => {
  var newObj, origKey, newKey, value;
  if (obj instanceof Array) {
    return obj.map(function (value) {
      if (typeof value === "object") {
        value = toSnake(value);
      }
      return value;
    });
  } else {
    newObj = {};
    for (origKey in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, origKey)) {
        newKey = camelToSnakeCase(origKey);
        value = obj[origKey];
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toSnake(value);
        }
        newObj[newKey] = value;
      }
    }
  }
  return newObj;
};

export const getSortName = input => {
  var name = input ? (input.length > 0 ? input : "G") : "G";
  var initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  return initials;
};

export const getError = response => {
  let errorContent = "";
  if (response) {
    const { errors, status } = response;
    if (errors) {
      errors.forEach(item => (errorContent += item.details ? item.details : item.errKey));
    } else if (parseInt(status) === ApiConstant.STT_BAD_REQUEST) {
      errorContent = getLabel(LangConstant.ERR_BAD_REQUEST);
    } else if (parseInt(status) === ApiConstant.STT_NOT_FOUND) {
      errorContent = getLabel(LangConstant.ERR_NOT_FOUND);
    } else {
      errorContent = getLabel(LangConstant.ERR_EXCEPTION);
    }
  }

  return errorContent;
};

export const isSttException = status =>
  ApiConstant.STT_INTERNAL_SERVER === status || ApiConstant.STT_NO_TOKEN === status;

export const openSocialWindow = (url, callback) => {
  if (Boolean(window.popup) && !window.popup.closed) {
    window.popup.close();
  }
  var popup = (window.popup = window.open(url, "popup", "width=600,height=600"));
  var popupTick = setInterval(function () {
    if (popup.closed) {
      clearInterval(popupTick);
      callback();
    }
  }, 500);
};

export const hasLogged = () => Boolean(Cookie.get(AppConstant.FACEBOOK_LINKED));

export const createStyleStatus = (colors, status) => {
  let styles = {};

  switch (status) {
    case AppConstant.STT_RESERVATION.pending:
      styles.color = colors.pending;
      break;
    case AppConstant.STT_RESERVATION.confirm:
      styles.color = colors.confirm;
      break;
    case AppConstant.STT_RESERVATION.seated:
      styles.color = colors.seated;
      break;
    case AppConstant.STT_RESERVATION.active:
      styles.color = colors.active;
      break;
    case AppConstant.STT_RESERVATION.finished:
      styles.color = colors.finished;
      break;
    case AppConstant.STT_RESERVATION.cancel:
      styles.color = colors.cancel;
      break;
    case AppConstant.STT_RESERVATION.reject:
      styles.color = colors.reject;
      break;
    case AppConstant.STT_RESERVATION.waitingPayment:
      styles.color = colors.waitingPayment;
      break;
    default:
      break;
  }
  return styles;
};

export const openConsumerWindow = (url, callback) => {
  if (Boolean(window.popup) && !window.popup.closed) {
    window.popup.close();
  }
  window.popup = window.open(url, "popup", "width=600,height=600");
  window.popup.onbeforeunload = callback;
};

export const removeAllCookie = Cookies => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    Cookies.remove(cookieName);
  });
};

export const openFacebookWindow = (url, callback) => {
  if (Boolean(window.popup) && !window.popup.closed) {
    window.popup.close();
  }
  var data = null;
  window.addEventListener(
    "message",
    event => {
      if (event.origin !== ApiConstant.HOST_CONSUMER) return;
      data = event.data;
    },
    false,
  );
  var popup = (window.popup = window.open(url, "popup", "width=600,height=600"));
  var popupTick = setInterval(function () {
    if (popup.closed) {
      clearInterval(popupTick);
      callback(data);
    }
  }, 500);
};

export const removeUnnecessarySpaces = string => {
  if (string) {
    let removeSpaces = string.replace(/\s\s+/g, " ");
    return removeSpaces.trim();
  }

  return string;
};

export const removeDuplicatesWithKey = (array, key) => {
  let lookup = new Set();
  return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
};
export const removeDuplicateInArray = arr => {
  let tmpArray = new Set(arr);
  arr = [...tmpArray];
  return arr;
};

export const getCommonKey = key => `${LangConstant.NS_COMMON}:${key}`;

export const getNSKey = (namespace, key) => `${namespace}:${key}`;

export const removeUndefinedValueInArray = arr => {
  const filtered = arr.filter(function (el) {
    return el !== null || el !== undefined;
  });
  return filtered;
};

export const convertSmallNumber = number => {
  if (Number.isInteger(number)) {
    return number < 10 ? "0" + number : number;
  }
  return number;
};

export const getStatusColor = statusCode => {
  let intStatusCode = statusCode;
  if (isString(intStatusCode)) {
    intStatusCode = parseInt(intStatusCode);
  }

  switch (intStatusCode) {
    case AppConstant.STT_RESERVATION.pending:
      return AppColors.reservation.pending;

    case AppConstant.STT_RESERVATION.waitingPayment:
      return AppColors.reservation.waitingPayment;

    case AppConstant.STT_RESERVATION.confirm:
      return AppColors.reservation.confirm;

    case AppConstant.STT_RESERVATION.seated:
      return AppColors.reservation.seated;

    case AppConstant.STT_RESERVATION.active:
      return AppColors.reservation.active;

    case AppConstant.STT_RESERVATION.finished:
      return AppColors.reservation.finished;

    case AppConstant.STT_RESERVATION.cancel:
      return AppColors.reservation.cancel;

    case AppConstant.STT_RESERVATION.reject:
      return AppColors.reservation.reject;

    default:
      return "";
  }
};

export const getBillStatusColor = statusCode => {
  let intStatusCode = statusCode;
  if (isString(intStatusCode)) {
    intStatusCode = parseInt(intStatusCode);
  }

  switch (intStatusCode) {
    case AppConstant.STT_VAT_BILL.pending:
      return AppColors.reservation.pending;

    case AppConstant.STT_VAT_BILL.transferred:
      return AppColors.reservation.active;

    case AppConstant.STT_VAT_BILL.billExported:
      return AppColors.reservation.finished;

    default:
      return "";
  }
};

export const createQueryParamForReport = queryParams => {
  let currentDate = new Date();

  switch (queryParams.view) {
    case AppConstant.CHART_VIEW_BY.hour:
      queryParams.start = queryParams.start || currentDate;
      queryParams.end = queryParams.end || currentDate;
      break;
    case AppConstant.CHART_VIEW_BY.day:
      queryParams.start = queryParams.start || startOfMonth(currentDate);
      queryParams.end = queryParams.end || endOfMonth(currentDate);
      break;
    case AppConstant.CHART_VIEW_BY.week:
      queryParams.start = queryParams.start || startOfWeek(currentDate);
      queryParams.end = queryParams.end || endOfWeek(currentDate);
      break;
    case AppConstant.CHART_VIEW_BY.month:
      queryParams.start = queryParams.start || startOfYear(currentDate);
      queryParams.end = queryParams.end || endOfYear(currentDate);
      break;

    default:
      break;
  }
  return queryParams;
};

export const getLabelArray = (array, getLabel, isCommonKey = false) => {
  let translatedArray = [];
  array.map(item => {
    if (!isCommonKey) {
      translatedArray.push(getLabel(item));
    } else {
      translatedArray.push(getLabel(getCommonKey(item)));
    }
  });
  return translatedArray;
};

export const createArrayWithLabel = (valueObject, nameObject, getLabel, isCommonKey = false) => {
  let resultArray = [];
  Object.keys(valueObject).map(key => {
    if (!isCommonKey) {
      resultArray.push({ value: valueObject[key], name: getLabel(nameObject[key]) });
    } else {
      resultArray.push({ value: valueObject[key], name: getLabel(getCommonKey(nameObject[key])) });
    }
  });
  return resultArray;
};

export const getJsonArray = arrayData => {
  let jsonData = {};
  for (var i = 0; i < arrayData.length; i++) {
    //Create data json object
    jsonData[arrayData[i].uid] = arrayData[i];
  }
  return jsonData;
};

export const convertArrayToObjectWithKey = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const jumpToTargetBooking = (history, startTime, uid, isReplace = true) => {
  let configRouter = StringFormat(
    PathConstant.FM_URL_RESERVATION_BY_UID,
    getStartOfDay(convertTimeStampToDate(startTime)),
    uid,
  );
  isReplace ? history.replace(configRouter) : history.push(configRouter);
};

export const jumpToTargetOrder = (history, startTime, uid, isReplace = true) => {
  let configRouter = StringFormat(
    PathConstant.FM_URL_RESERVATION_ORDER_BY_UID,
    getStartOfDay(convertTimeStampToDate(startTime)),
    uid,
  );
  isReplace ? history.replace(configRouter) : history.push(configRouter);
};

export const getAttachmentUrl = path => (path ? ApiConstant.HOST_ATTACHMENT + path : "");

export const sortArrayObject = (array, field) =>
  array.sort((first, second) => (first[field] > second[field] ? 1 : second[field] > first[field] ? -1 : 0));

export const roundNumber = (number, decimal = 2) => {
  let decimalNumber = decimal * 10;
  return Math.round(number * decimalNumber) / decimalNumber;
};
export const removeVietnameseTones = str => {
  if (!str) return "";
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
};

export const textNormalize = str => {
  return removeVietnameseTones(str).toLowerCase();
};

export const isArrayNotEqual = (firstArray, secondArray) =>
  firstArray && Array.isArray(firstArray) && JSON.stringify(firstArray) !== JSON.stringify(secondArray);

export const isArrayEqual = (firstArray, secondArray) =>
  firstArray === secondArray || JSON.stringify(firstArray) === JSON.stringify(secondArray);

export const checkInteger = str => {
  const checkInteger = /^[0-9\b]+$/;
  return checkInteger.test(str);
};

export const checkFloat = str => {
  const checkFloat = /^([0-9]+([.][0-9]*)?|[.][0-9])$/;
  return checkFloat.test(str);
};

export const formatCurrency = (str, unit) => {
  let strCurrency = str + ""; // Ensure that variable is String
  if (!unit) {
    return strCurrency.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return strCurrency.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " " + unit;
  }
};

export const formatFormItemCombo = listData => {
  let result = "";
  listData.forEach((data, index) => {
    result += `${data.amount} ${data.name}`;
    if (index != listData.length - 1) {
      result += " + ";
    }
  });
  return result;
};

export const convertTimeItemDescription = (time, type) => {
  if (!type || !time) return "";

  let result = "";
  switch (type) {
    case AppConstant.TYPE_TIME_USED.minute:
      result += `${time}${getLabel(LangConstant.TXT_MIN)}`;
      break;

    case AppConstant.TYPE_TIME_USED.hour:
      var sign = time >= 0 ? 1 : -1;

      time = time * sign;

      var hour = Math.floor(time);
      var decpart = time - hour;

      var min = 1 / 60;

      decpart = min * Math.round(decpart / min);

      var minute = Math.floor(decpart * 60) + "";

      if (minute.length < 2) {
        minute = "0" + minute;
      }

      sign = sign == 1 ? "" : "-";

      result =
        sign +
        hour +
        `${getLabel(LangConstant.TXT_H)} ` +
        (minute != 0 ? minute + `${getLabel(LangConstant.TXT_MIN)}` : "");
      break;

    case AppConstant.TYPE_TIME_USED.day:
      result += `${time} ${getLabel(LangConstant.TXT_DAY_LOWERCASE)}`;
      break;

    default:
      result += `${time || 0}${getLabel(LangConstant.TXT_MIN)}`;
      break;
  }
  return result;
};

export const sortDataByArray = (arrayData, sortArray) => {
  return arrayData.sort((a, b) => {
    const indexA = sortArray.indexOf(a.uid);
    const indexB = sortArray.indexOf(b.uid);
    if (indexA > indexB) {
      return 1;
    } else {
      return -1;
    }
  });
};

export const removeFromArray = (array, elem) => {
  var index = array.indexOf(elem);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export const getUidList = array => {
  return array && array.map(dataMap => dataMap.uid);
};

export const convertTimePreOrder = data => {
  if (data?.type === AppConstant.TYPE_CATEGORY.product) {
    let stock = data?.stock || "";
    if (!stock) return "";
    return stock.toString();
  }
  return convertTimeItemDescription(data.usingTime, data.usingTimeType);
};

export const convertPricePreOrder = data => {
  if (!(data && (data.priceTaxIn || data.price || data.priceTaxInRange || data.priceRange)))
    return formatVietnameseCurrency();

  let itemPrice = data.priceTaxIn || data.price || 0;
  let itemPriceRange = data.priceTaxInRange || data.priceRange;
  let formattedItemPrice = itemPriceRange ? getPriceRange(itemPriceRange) : formatVietnameseCurrency(itemPrice);
  return formattedItemPrice;
};

export const formatUsingTime = (data, getLabel) => {
  if (!data) return "";

  let timeConvert = convertTimeItemDescription(data.usingTime, data.usingTimeType);
  let formattedResult = "";

  if (data?.unitType && data.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.times) {
    formattedResult = timeConvert
      ? getLabel(getCommonKey(LangConstant.FM_SERVICE_TIME), { time: timeConvert })
      : getLabel(getCommonKey(LangConstant.TXT_TIMES));
  } else {
    formattedResult = timeConvert;
  }

  return formattedResult;
};

export const onDoNothing = () => {};

export const onCloseBrowser = event => {
  // handling did mounted component
  event.preventDefault();
  return (event.returnValue = "");
};

export const isNumber = str => str && str.match(/^[0-9]+$/) != null;

export const isEmpty = variable => {
  switch (variable) {
    case "":
    case 0:
    case "0":
    case null:
    case false:
    case typeof variable == "undefined":
      return true;
    default:
      return false;
  }
};

export const isBlank = str => !str || /^\s*$/.test(str);

export const convertHex2rgba = (hex, alpha = 1) => {
  try {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  } catch (error) {
    return "";
  }
};

export const parseParams = querystring => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const getObjByItsProperty = (object, key, value) => {
  let result = Object.values(object).find(obj => obj[key] === value);
  return result || {};
};

export const authenticate = (history, isLogin) => {
  let result = false;
  if (!history) return result;

  try {
    let currentPath = history.location.pathname;
    let isAuthPage = PathConstant.AUTHENTICATION_PATHS.includes(currentPath);
    const appData = Cookie.get(AppConstant.KEY_GBOSS_LOGIN);

    let configRoute = { search: window.location.search };
    let hasLoginToken = Boolean(appData && JSON.parse(appData).responseData);
    let hasShopToken = Boolean(getShopToken());

    if (isAuthPage) {
      if (hasLoginToken && hasShopToken) {
        configRoute.pathname = PathConstant.MANAGE_BOOKS;
      } else if (isLogin || hasLoginToken) {
        configRoute.pathname = PathConstant.SELECT_SHOP;
      }
    } else {
      if (!hasLoginToken) {
        configRoute.pathname = PathConstant.LOGIN;
      } else if (!hasShopToken) {
        configRoute.pathname = PathConstant.SELECT_SHOP;
      }
    }

    history.replace(configRoute);
    result = true;
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const getShopToken = () => {
  let shopToken;
  try {
    const shopData = Cookie.get(AppConstant.KEY_GBOSS);
    shopToken = shopData && JSON.parse(shopData).token;
  } catch (error) {
    console.log(error);
  }
  return shopToken;
};

export const addUrlParam = (basePath, key, val) => {
  let newParam = key + "=" + val;
  let queryParams = "?" + newParam;

  // If the "search" string exists, then build params from it
  if (basePath && basePath.includes("?")) {
    // Try to replace an exist instance
    queryParams = basePath.replace(new RegExp("([?&])" + key + "[^&]*"), "$1" + newParam);
    // If nothing was replaced, then add the new param to the end
    if (queryParams === basePath) {
      queryParams += "&" + newParam;
    }
  }

  return basePath + queryParams;
};

export const getFeatureConditions = reservationSettingObj => {
  let formCheck = {
    reservationSettingIsValid: false,
    isStandard: false,
    isPremium: false,
    isOnlyBooking: false,
    isOnlyOrdering: false,
  };
  const arrayFeatures = [
    AppConstant.RESERVATION_SETTING_OPTION.useBooking,
    AppConstant.RESERVATION_SETTING_OPTION.useOrdering,
  ];

  if (reservationSettingObj) {
    let arrayReservationSettingValues = Object.values(reservationSettingObj);

    const currentPackageSetting = arrayReservationSettingValues.find(
      item => item.type === AppConstant.RESERVATION_SETTING_OPTION.currentPackage,
    );

    // Check shop setting using booking/order feature
    let featureIndex = arrayReservationSettingValues.findIndex(setting => arrayFeatures.includes(setting.type));
    formCheck.reservationSettingIsValid = featureIndex >= 0;

    formCheck.isStandard = [AppConstant.SHOP_PACKAGE.standard, AppConstant.SHOP_PACKAGE.freeTrial].includes(
      currentPackageSetting?.details?.plan,
    );

    formCheck.isPremium = currentPackageSetting?.details?.plan === AppConstant.SHOP_PACKAGE.premium;

    formCheck.isBusiness = currentPackageSetting?.details?.plan === AppConstant.SHOP_PACKAGE.business;

    arrayReservationSettingValues.forEach(settingItem => {
      if (settingItem.type === AppConstant.RESERVATION_SETTING_OPTION.useBooking) {
        formCheck.isOnlyBooking = Boolean(settingItem.publish);
      }
      if (settingItem.type === AppConstant.RESERVATION_SETTING_OPTION.useOrdering) {
        formCheck.isOnlyOrdering = Boolean(settingItem.publish);
      }
    });
  }

  return formCheck;
};

export const getCookiesDataShopSelect = () => {
  const appData = Cookie.get(AppConstant.KEY_GBOSS_LOGIN);
  let dataShops = [];
  try {
    if (appData && appData.length > 0) {
      dataShops = JSON.parse(appData).responseData;
    }
  } catch (error) {
    console.log(error);
  }
  return dataShops;
};

export const isObjectNotEqual = (firstObject, secondObject) =>
  firstObject && secondObject && JSON.stringify(firstObject) !== JSON.stringify(secondObject);

export const onLoadImageError = event => {
  event.target.src = ImageConstant.EmptyImage;
};

export const validateFileSize = (fileSize, limit = 2) => {
  let limitSizeMb = limit * 1024 * 1024;
  return fileSize <= limitSizeMb;
};

export const getItemUrl = (shopName, uid, itemName) => {
  if (!shopName || !uid) return ApiConstant.HOST_CONSUMER + PathConstant.NOT_FOUND;
  let formattedName = removeVietnameseTones(itemName).replaceAll(" ", "-");
  let linkToShop = StringFormat(ApiConstant.SHOP_URL, shopName);
  let itemPath = StringFormat(AppConstant.ITEM_DETAIL_URL, formattedName || "", uid);
  return linkToShop.trim() + itemPath.trim();
};

export const deepCloneJsonObject = json => {
  if (!json) return null;
  return JSON.parse(JSON.stringify(json));
};

export const formatVietnameseCurrency = (cashAmount = 0) => {
  let formattedCurrencyString = new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(
    cashAmount,
  );
  return formattedCurrencyString.replace(" ₫", "đ");
};

export const getPriceRange = range => {
  if (!(range && Array.isArray(range))) return formatVietnameseCurrency(0);
  return formatVietnameseCurrency(range[0]) + " - " + formatVietnameseCurrency(range[1]);
};

export const formatItemPrice = item => {
  if (!item || !(item.priceTaxInRange || item.priceRange || item.priceTaxIn || item.itemPrice))
    return formatVietnameseCurrency();

  let result = "";
  let itemPrice = item.priceTaxIn || item.price;
  let itemPriceRange = item.priceTaxInRange || item.priceRange;

  result = itemPriceRange ? getPriceRange(itemPriceRange) : formatVietnameseCurrency(itemPrice);
  return result;
};

export const calculatePriceTaxIn = (price, tax) => {
  let priceTaxIn = Math.round(price * (1 + tax));
  return priceTaxIn;
};

export const removeCurrencyFormat = string => {
  if (!string) return "";

  let priceString = string.toString().replace(/đ/g, "");
  return priceString.split(".").join("");
};

export const lettersInString = string => {
  if (!string) return 0;
  return string.replace(/\s+/g, "").length;
};

export const putCursorBeforeTheLastCharacter = inputElement => {
  if (!inputElement) return;
  let stringLength = lettersInString(inputElement.value);
  inputElement.setSelectionRange(stringLength - 1, stringLength - 1);
};
