import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CropDialogTitle from "components/CropImage/CropImageDialogTitle";
import { AppConstant, LangConstant } from "const";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCommonKey } from "utils";
import ReservationAction from "redux/reservation.redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

const DisplayBookingDetailSettingMenu = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const [reservationRedux, settingData] = useSelector(
    state => [state.reservationRedux, state.reservationRedux.displaySetting],
    shallowEqual,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const [dataDisplayState, setDataDisplayState] = useState([]);

  const getLabelFromId = id => {
    let label;
    switch (id) {
      case AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.service:
        label = getLabel(getCommonKey(LangConstant.TXT_BOOK_PREVIEW_SERVICE));
        break;
      case AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.place:
        label = getLabel(getCommonKey(LangConstant.TXT_BOOK_PREVIEW_PLACE));
        break;
      case AppConstant.DISPLAY_SETTING_BOOKING_DETAIL_IDS.staff:
        label = getLabel(LangConstant.TXT_ASSIGNED_STAFF);
        break;
      default:
        label = getLabel(LangConstant.TXT_CUSTOMER_NUMBER);
        break;
    }
    return label;
  };
  const onChangeSetting = (event, index) => {
    let tmpArr = [...dataDisplayState];
    tmpArr[index] = { ...tmpArr[index], isShow: Boolean(event.target.checked) };
    setDataDisplayState(tmpArr);
  };

  const onSave = () => {
    dispatch(ReservationAction.updateDisplaySetting(dataDisplayState));
    onCloseDialog();
  };

  const onCloseDialog = () => {
    dispatch(ReservationAction.getDisplaySetting());
    setIsOpen(false);
  };

  useEffect(() => {
    if (!reservationRedux.isFetching && reservationRedux.isUpdateSettingSuccess) {
      dispatch(ReservationAction.getDisplaySetting());
      dispatch(ReservationAction.resetPostSetting());
      setIsOpen(false);
    }
  }, [reservationRedux.isFetching, reservationRedux.isUpdateSettingSuccess]);

  useEffect(() => {
    setDataDisplayState(settingData);
  }, [settingData]);

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.settingDialogPaper,
      }}
      fullScreen={isMobile}
      onClose={onCloseDialog}
    >
      <CropDialogTitle
        classes={{
          root: classes.dialogTitleRoot,
        }}
        onClose={onCloseDialog}
      >
        <Typography className={`${classes.dialogTitleContent} medium-xl-txt`}>
          {getLabel(LangConstant.TXT_SETTING_DIALOG_TITLE)}
        </Typography>
      </CropDialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box className={classes.sectionContainerFirst}>
          <Typography className={`${classes.sectionTitle} medium-md-txt`}>
            {getLabel(LangConstant.TXT_BASIC_DISPLAY_INFO_SETTING)}
          </Typography>
          <Typography className={classes.sectionDescription}>
            {getLabel(LangConstant.TXT_BASIC_DISPLAY_INFO_SETTING_DESCRIPTION)}
          </Typography>
        </Box>
        <CheckBoxRow title={getLabel(getCommonKey(LangConstant.TXT_PLACE_CODE))} />
        <CheckBoxRow title={getLabel(getCommonKey(LangConstant.TXT_NAME))} />
        <CheckBoxRow title={getLabel(LangConstant.TXT_BOOKING_STATUS)} />
        <Box className={classes.sectionContainer}>
          <Typography className={`${classes.sectionTitle} medium-md-txt`}>
            {getLabel(LangConstant.TXT_OPTIONAL_INFO_SETTING)}
          </Typography>
          <Typography className={classes.sectionDescription}>
            {getLabel(LangConstant.TXT_OPTIONAL_INFO_SETTING_DESCRIPTION)}
          </Typography>
        </Box>

        {dataDisplayState &&
          dataDisplayState.map((element, index) => (
            <CheckBoxRow
              key={index}
              title={getLabelFromId(element.display_id)}
              iniState={element.isShow}
              onChange={event => {
                onChangeSetting(event, index);
              }}
              isNotDefault={true}
            />
          ))}
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button className={`${classes.cancelButton} medium-md-txt`} onClick={onCloseDialog} variant="contained">
          {getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
        </Button>
        <Button className={`${classes.saveButton} medium-md-txt`} variant="contained" color="primary" onClick={onSave}>
          {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(DisplayBookingDetailSettingMenu);

DisplayBookingDetailSettingMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

DisplayBookingDetailSettingMenu.defaultProps = {};

const CheckBoxRow = ({ iniState, onChange, title, isNotDefault }) => {
  const classes = useStyles();

  return (
    <Box className={classes.checkBoxRowContainer}>
      <Typography className={classes.checkBoxLabel}>{title}</Typography>
      <Checkbox
        color="primary"
        checked={isNotDefault ? iniState : true}
        onChange={isNotDefault && onChange}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  settingDialogPaper: {
    minHeight: "60%",
    width: 450,
    borderRadius: 5,
    padding: "0 0 20px 0",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  dialogTitleRoot: {
    backgroundColor: "#65b39d",
  },
  dialogTitleContent: {
    color: "#fff",
  },
  checkBoxRowContainer: {
    backgroundColor: "#fafafb",
    width: "90%",
    display: "flex",
    alignSelf: "center",
    margin: "4px 0",
    justifyContent: "space-between",
    borderRadius: 2,
    height: 30,
    alignItems: "center",
    padding: "0 14px",
  },
  checkBoxLabel: {
    color: "#565c6a",
    fontSize: 13,
  },
  dialogContent: {
    width: "100%",
    height: "100%",
    padding: "20px 0 0 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sectionContainer: {
    width: "90%",
    marginTop: 24,
    marginBottom: 16,
  },
  sectionContainerFirst: {
    width: "90%",
    marginBottom: 16,
  },
  sectionTitle: {
    lineHeight: 1.71,
    color: "#3e4045",
    marginBottom: 5,
  },
  sectionDescription: {
    fontSize: 13,
    color: "#565c6a",
  },
  dialogActionContainer: {
    padding: 0,
    width: "90%",
    display: "flex",
    alignSelf: "center",
    placeContent: "space-between",
    justifyContent: "flex-end",
    paddingTop: 10,
  },
  cancelButton: {
    borderRadius: 4,
    minHeight: "unset",
    height: 30,
    padding: 0,
    textTransform: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  saveButton: {
    minHeight: "unset",
    height: 30,
    borderRadius: 4,
    padding: 0,
    textTransform: "none",
  },
}));
