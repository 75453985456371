import React, { memo } from "react";
import { makeStyles, TableCell, Checkbox, TableSortLabel } from "@material-ui/core";
import { SwapVert } from "@material-ui/icons";
import { LangConstant } from "../../../const";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CellHead = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { cellData, onSelect, checked, onFilter, listDisabled } = props;
  const showFilter = [
    getLabel(LangConstant.TXT_NAME),
    getLabel(LangConstant.TXT_BIRTH_DAY),
    getLabel(LangConstant.TXT_SEX),
  ];

  const tableSortLabel = (Cell, Classes) => (
    <TableCell align="center" className={Classes}>
      {Cell}
      <TableSortLabel onClick={onFilter} classes={{ icon: classes.iconSort }}>
        <SwapVert className={classes.iconSwag} />
      </TableSortLabel>
    </TableCell>
  );

  return (
    <>
      {showFilter.includes(cellData) ? (
        tableSortLabel(cellData, cellData === getLabel(LangConstant.TXT_SEX) ? classes.rootMiddle : classes.rootLeft)
      ) : cellData === getLabel(LangConstant.TXT_ACCOUNT_STATUS) ? (
        <TableCell align="center" className={classes.rootMiddle}>
          {cellData}
          <Checkbox
            color="primary"
            disabled={!Boolean(listDisabled.length > 0)}
            checked={checked}
            onChange={onSelect}
            className={classes.checkBoxStatus}
          />
        </TableCell>
      ) : (
        <TableCell align="center" className={classes.rootLeft}>
          {cellData}
        </TableCell>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  rootLeft: {
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: "#ffffff",
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 180,
    textAlign: "left",
  },
  rootMiddle: {
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: "#ffffff",
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 130,
  },
  checkBoxStatus: {
    padding: "0 0 0",
    width: 16,
    height: 16,
    marginLeft: 10,
  },
  iconSort: {
    display: "none",
  },
  iconSwag: {
    marginLeft: 10,
    opacity: 1,
    fill: "#4b4d53",
  },
}));

CellHead.propTypes = {
  cellData: PropTypes.string,
  style: PropTypes.object,
};

export default memo(CellHead);
