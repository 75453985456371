import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  withStyles,
  CardHeader,
  IconButton,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey } from "utils";
import CustomStepperOrder from "./CustomStepperOrder";
import DialogStatusTable from "./DialogStatusTable";

const StatusDialogOrder = ({ isShow, dataShow, onSubmit, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);
  const [selectedStatus, setSelectedStatus] = useState(dataShow.status);

  return (
    <Dialog open={isShow} maxWidth="md" classes={{ paper: classes.dialogPaper }}>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Header
          title={StringFormat(getLabel(LangConstant.FM_CHANGE_STATUS_ORDER), dataShow.code)}
          action={
            <IconButton aria-label="close" onClick={onClose} color="inherit">
              <Close color="inherit" />
            </IconButton>
          }
        />
        <DialogStatusTable data={dataShow} />
        <CustomStepperOrder
          status={dataShow.status}
          selectedStatus={selectedStatus}
          onChange={setSelectedStatus}
          classes={classes}
        />
        <DialogActions>
          <Box marginTop="30px">
            <Button className={clsx(classes.button, classes.buttonCancel)} onClick={onClose}>
              {getLabel(LangConstant.TXT_CANCEL_ORDER)}
            </Button>
            <Button className={clsx(classes.button, classes.buttonAccept)} onClick={() => onSubmit(selectedStatus)}>
              {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 0,
    position: "relative",
    borderRadius: "unset",
    boxShadow: "none",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: 800,
    overflowY: "unset",
    [theme.breakpoints.down("sm")]: {
      height: 365,
      minWidth: 882,
    },
    "&:first-child": {
      paddingTop: 0,
    },
  },
  button: {
    height: 30,
    textTransform: "none",
    minHeight: 20,
    width: 60,
    marginRight: 10,
    marginBottom: 20,
  },
  buttonCancel: {
    color: "#ffffff",
    backgroundColor: "#b3b5ba",
    "&:hover": {
      backgroundColor: "#b3b5ba",
    },
  },
  buttonAccept: {
    color: "#ffffff",
    backgroundColor: theme.palette.reservation.finished,
    "&:hover": {
      backgroundColor: theme.palette.reservation.finished,
    },
  },
  typoAccept: {
    color: theme.palette.white,
  },
  dialogPaper: {
    marginLeft: 10,
    marginRight: 0,
  },
}));
StatusDialogOrder.propTypes = {
  isShow: PropTypes.bool,
  dataShow: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
StatusDialogOrder.defaultProps = {};

export default memo(StatusDialogOrder);

const Header = withStyles(theme => ({
  root: {
    position: "relative",
    padding: 8,
    background: "#65b39d",
    color: "white",
    minHeight: 50,
  },
  action: {
    flex: "none",
    position: "absolute",
    right: 10,
  },
  title: {
    ...theme.typography.subtitle2,
    color: "white",
  },
}))(CardHeader);
