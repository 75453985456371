import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import formatString from "string-format";
import { format as formatDate } from "date-fns";
import { LangConstant, AppConstant } from "const";
import { jumpToTargetBooking } from "utils";
import NewBookingConfirm from "./NewBookingConfirm";
import { ARR_NEW_BOOKING_REQUEST_CONTENT } from "const/lang.const";

const NewBookingNotifyItem = ({ data, onClose, ...otherProps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const screenBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const history = useHistory();
  const { t: getLabel } = useTranslation(LangConstant.NS_MAIN_LAYOUT);

  const onItemClick = event => {
    event.preventDefault();
    const startTime = data.startTime;
    const uid = data.uid;
    onClose(event);
    jumpToTargetBooking(history, startTime, uid);
  };

  const getContent = () => {
    const name = data.name;
    const isPeriodic = Boolean(data.periodicCode && data.periodicCode !== "");
    let content = "";
    if (!isPeriodic) {
      content = formatString(getLabel(ARR_NEW_BOOKING_REQUEST_CONTENT[0]), `${name}`);
    } else {
      content = formatString(getLabel(ARR_NEW_BOOKING_REQUEST_CONTENT[1]), `${name}`);
    }
    return content;
  };

  return (
    <ListItem
      key={data.uid}
      id={data.uid}
      button={true}
      className={classes.notification}
      selected={data.status === 1}
      classes={{ selected: classes.activeSelected, root: classes.item }}
      onClick={onItemClick}
    >
      <Grid item container direction="row" justify="flex-start">
        <Grid item xs container direction="row" alignItems="center" className={classes.noticeIconForm}>
          <Grid item>
            <ListItemIcon>
              <div className={classes.bgItem}>
                <DateRange color="inherit" />
              </div>
            </ListItemIcon>
          </Grid>
        </Grid>
        <Grid item xs={screenBreakpoint ? 8 : 7} container>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                className={`medium-md-txt ${classes.typoNotice}`}
                dangerouslySetInnerHTML={{
                  __html: getContent(),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs container className={classes.noticeIconForm}>
          <ListItemSecondaryAction>
            <Box>
              <Typography variant="body1" className="medium-md-txt">
                {formatDate(data.created * 1000, AppConstant.FM_HH_MM)}
              </Typography>
              <NewBookingConfirm data={data} {...otherProps} />
            </Box>
          </ListItemSecondaryAction>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  notice: {
    "@media only screen and (min-width: 960px)": {
      maxWidth: "480px !important",
      minWidth: "480px",
    },
    height: "100%",
    maxHeight: "600px",
    width: "100%",
    minWidth: "200px",
    maxWidth: "456px",
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    paddingRight: "2px",
  },
  bgItem: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: "#ffaa6e",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activeSelected: {
    backgroundColor: "white !important",
    color: "white",
  },
  item: {
    marginBottom: "8px",
  },
  typoNotice: {
    lineHeight: "1.57",
    fontWeight: "normal",
    fontStretch: "normal",
    color: "#3b404c",
  },
  noticeIconForm: {
    minWidth: "48px",
    maxWidth: "48px",
  },
}));

NewBookingNotifyItem.propTypes = {
  data: PropTypes.object,
};
NewBookingNotifyItem.defaultProps = {
  data: {},
};

export default memo(NewBookingNotifyItem);
