import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { WebAsset } from "@material-ui/icons";
import clsx from "clsx";
import { InputShopInfo } from "components/mnbooking";
import { LangConstant } from "const";
import { AntSwitchPrimary } from "./CustomizeSwitch";

const NotificationWebsite = ({ data, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_NOTIFICATION_SETTING);

  const [dataWebsiteNotification, setDataWebsiteNotification] = useState(data || {});

  const onChecked = event => {
    let name = event.currentTarget.name;
    let checked = event.currentTarget.checked;
    let dataCheck = {
      ...dataWebsiteNotification,
      [name]: Number(checked),
    };
    setDataWebsiteNotification(dataCheck);
    onChange(dataCheck);
  };

  useEffect(() => {
    setDataWebsiteNotification(data || {});
  }, [data]);

  return (
    <Box>
      <Box className={classes.boxHeader}>
        <Grid item xs={12} md={6} container direction="row" justify="space-between">
          <Grid item className={classes.grid}>
            <WebAsset />
            <Typography className={clsx("semiBold-lg-txt", classes.typography)}>
              {getLabel(LangConstant.TXT_NOTIFICATION_WEBSITE)}
            </Typography>
          </Grid>
          <Grid item>
            <AntSwitchPrimary
              checked={Boolean(dataWebsiteNotification.disableAll)}
              name={LangConstant.OBJECT_KEY_NOTIFICATION.disableAll}
              className={classes.toggleGg}
              onChange={onChecked}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={Boolean(dataWebsiteNotification.disableAll) ? classes.bodyHeader : "hidden"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.news}
              checkBox
              checked={Boolean(dataWebsiteNotification.news)}
              title={getLabel(LangConstant.TXT_NOTIFICATION_NEW)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationInfoUpdated}
              checkBox
              checked={Boolean(dataWebsiteNotification.reservationInfoUpdated)}
              title={getLabel(LangConstant.TXT_INFORMATION_BOOKING_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationNew}
              checkBox
              checked={Boolean(dataWebsiteNotification.reservationNew)}
              title={getLabel(LangConstant.TXT_NEW_BOOKING)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationStatusUpdated}
              checkBox
              checked={Boolean(dataWebsiteNotification.reservationStatusUpdated)}
              title={getLabel(LangConstant.TXT_APPOINTMENT_BOOKING_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.remoteOrderNew}
              checkBox
              checked={Boolean(dataWebsiteNotification.remoteOrderNew)}
              title={getLabel(LangConstant.TXT_NEW_ORDER)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.reservationAssign}
              checkBox
              checked={Boolean(dataWebsiteNotification.reservationAssign)}
              title={getLabel(LangConstant.TXT_DESIGNATED_STAFF)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.remoteOrderUpdated}
              checkBox
              checked={Boolean(dataWebsiteNotification.remoteOrderUpdated)}
              title={getLabel(LangConstant.TXT_INFORMATION_ORDER_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
          <Grid item xs={12} md={6} container direction="column">
            <InputShopInfo
              name={LangConstant.OBJECT_KEY_NOTIFICATION.remoteOrderStatusUpdated}
              checkBox
              checked={Boolean(dataWebsiteNotification.remoteOrderStatusUpdated)}
              title={getLabel(LangConstant.TXT_APPOINTMENT_ORDER_EDIT)}
              onCheck={onChecked}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

NotificationWebsite.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};
NotificationWebsite.defaultProps = {
  data: {},
  onChange: () => {},
};

export default memo(NotificationWebsite);

const useStyles = makeStyles(theme => ({
  boxHeader: {
    padding: "15px 30px 10px 25px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 60px 10px 25px",
    },
  },
  bodyHeader: {
    padding: "0 57px 20px 57px",
  },
  typography: {
    paddingLeft: 10,
    paddingTop: 2,
  },
  toggleGg: {
    marginRight: "0.5vw",
    marginTop: "3px",
    color: "#3e4045",
    width: 30,
  },
  grid: {
    display: "flex",
  },
}));
