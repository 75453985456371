import React, { useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import PostHeader from "./PostHeader";
import PostContent from "./PostContent";
import PostFooter from "./PostFooter";
import { AppConstant } from "../../../const/index";
import Cookie from "js-cookie";

const IndividualPost = props => {
  const [editing, setEditing] = useState(true);
  const { avt, alt, title, time, uid, showData, postContent, postImage, publish, fbID } = props;
  const classes = useStyles();
  const onEditHandler = () => {
    setEditing(v => !v);
  };
  const role = Cookie.get(AppConstant.ROLE);
  return (
    <>
      <Box className={classes.indiPost}>
        <PostHeader
          avt={avt}
          alt={alt}
          title={title}
          time={time}
          onEditHandler={onEditHandler}
          uid={uid}
          showData={showData}
        />
        <PostContent
          postImage={postImage}
          postContent={postContent}
          uid={uid}
          editing={editing}
          onEditHandler={onEditHandler}
        />
        {AppConstant.SHOP_STAFF !== role && <PostFooter publish={publish} fbID={fbID} uid={uid} />}
      </Box>
    </>
  );
};

export default IndividualPost;

const useStyles = makeStyles(theme => ({
  indiPost: {
    padding: "30px",
    textAlign: "center",
    minHeight: "30%",
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
    backgroundColor: "#ffffff",
  },
}));
