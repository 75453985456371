import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import StringFormat from "string-format";
import clsx from "clsx";

const TextPackageDetails = ({ data, type }) => {
  const classes = useStyles();

  const [displayText, setDisplayText] = useState({
    default: "",
    notDefault: "",
  });

  useEffect(() => {
    if (data) {
      let defaultText = "";
      let notDefaultText = "";
      data.forEach(item => {
        if (item.type === type) {
          if (item.default) {
            defaultText = item.orderNumber;
          } else {
            notDefaultText += StringFormat(FM_PLUS, item.orderNumber);
          }
        }
      });
      setDisplayText({
        default: defaultText,
        notDefault: notDefaultText.length > 2 ? StringFormat(FM_GUARD, notDefaultText) : "",
      });
    }
  }, [data]);

  return (
    <Box className="center-root">
      <Typography className="medium-md-txt">{displayText.default}</Typography>
      <Typography className={clsx("medium-md-txt", classes.otherNumber)}>{displayText.notDefault}</Typography>
    </Box>
  );
};

const FM_GUARD = "({0})";
const FM_PLUS = "+{0}";

TextPackageDetails.propTypes = {
  data: PropTypes.array,
  type: PropTypes.number,
};
TextPackageDetails.defaultProps = {};

export default TextPackageDetails;

const useStyles = makeStyles(theme => ({
  otherNumber: {
    color: theme.palette.text.link,
    marginLeft: 4,
  },
}));
