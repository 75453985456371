import React, { memo } from "react";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Grid, Typography, OutlinedInput, Button, InputAdornment, Checkbox, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const InputShopInfo = props => {
  const {
    nameInput,
    name,
    typeInput,
    title,
    value,
    heightType,
    disableInput,
    placeHolder,
    rowsMax,
    buttonEnd,
    buttonValue,
    checkBox,
    checked,
    includeInput,
    onCheck,
    onChangeInput,
    multiline,
    onClickInput,
    onUploadImage,
    disabled,
    valueTime,
    onChangeInputTime,
    classes,
  } = props;
  const { t: getLabel } = useTranslation();
  const defaultClasses = useStyles();
  const classHeight =
    heightType === "extra"
      ? `medium-md-txt extra`
      : heightType === "large"
      ? `medium-md-txt lagre`
      : heightType === "medium"
      ? `medium-md-txt medium`
      : `medium-md-txt small`;
  const RedCheckbox = withStyles({
    root: {
      color: "#9d9fa5",
      "&$checked": {
        color: "#ef5845",
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  return (
    <Grid item container direction="column" justify="flex-start" className={clsx("inputBooking", classes?.root)}>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
        className={`labelForm  ${checkBox && !includeInput ? "labelLarge" : "label"}`}
      >
        <Grid item>
          <Typography className={`medium-md-txt typo`}>{title}</Typography>
        </Grid>
        {valueTime && (
          <OutlinedInput
            classes={{ root: defaultClasses.rootInput, input: defaultClasses.InputOutline }}
            placeholder="30"
            type={typeInput}
            value={value}
            name={nameInput}
            onChange={onChangeInputTime}
            endAdornment={
              <InputAdornment position="end" className={defaultClasses.title}>
                <Typography variant="body1">{getLabel(LangConstant.TXT_MINUTES)}</Typography>
              </InputAdornment>
            }
          />
        )}
        {checkBox && (
          <Grid item>
            <RedCheckbox
              className="checkbox"
              name={name}
              checked={checked}
              onChange={onCheck}
              id={`checkbox-${name}`}
            />
          </Grid>
        )}
      </Grid>
      {(includeInput || !checkBox) && (
        <Grid item container direction="row" wrap="nowrap" className="inputFrom" alignItems="baseline">
          <OutlinedInput
            onChange={onChangeInput}
            onClick={onClickInput}
            name={nameInput}
            type={typeInput}
            readOnly={disableInput}
            multiline={multiline}
            fullWidth={true}
            placeholder={placeHolder}
            value={value}
            rowsMax={rowsMax}
            className={`${classHeight} input`}
            disabled={disabled}
          ></OutlinedInput>
          <InputAdornment variant="outlined">
            {buttonEnd && (
              <Button variant="contained" className={`button ${classHeight}`} onClick={onUploadImage}>
                {buttonValue}
              </Button>
            )}
          </InputAdornment>
        </Grid>
      )}
    </Grid>
  );
};

InputShopInfo.propTypes = {
  typeInput: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  nameInput: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heightType: PropTypes.string,
  disableInput: PropTypes.bool,
  placeHolder: PropTypes.string,
  rowsMax: PropTypes.number,
  buttonEnd: PropTypes.bool,
  buttonValue: PropTypes.string,
  checkBox: PropTypes.bool,
  includeInput: PropTypes.bool,
  onCheck: PropTypes.func,
  onChangeInput: PropTypes.func,
  multiline: PropTypes.bool,
  valueTime: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

InputShopInfo.defaultProps = {
  typeInput: "text",
  title: "",
  value: "",
  heightType: "small",
  disableInput: false,
  placeHolder: "",
  rowsMax: 1,
  buttonEnd: false,
  buttonValue: "",
  checkBox: false,
  checked: true,
  includeInput: false,
  multiline: true,
  valueTime: false,
};

const useStyles = makeStyles({
  root: {
    width: 120,
  },
  rootInput: {
    height: 40,
  },
  InputOutline: {
    width: 80,
  },
  title: {
    justifyContent: "center",
    borderLeft: "solid 0.5px #cecfd2",
    marginLeft: 0,
    width: 80,
    maxHeight: "100%",
    height: 40,
  },
});

export default memo(InputShopInfo);
