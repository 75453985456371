import PropTypes from "prop-types";
import { Box, Dialog, IconButton, makeStyles, Slide, Typography } from "@material-ui/core";
import React, { forwardRef } from "react";
import { Close, Dehaze, Add } from "@material-ui/icons";
import { DATA_TYPE } from "const/app.const";

// eslint-disable-next-line no-unused-vars
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} />;
});
// eslint-disable-next-line no-unused-vars
const DialogAddCategoryItem = forwardRef((props, ref) => {
  const classes = useStyles();
  const { isShow, onAddFromMainService, onCreateAccompaniedService, action, inputProps, titleName, dataType } = props;

  let isBooking = dataType === DATA_TYPE.booking;

  return (
    <Dialog fullScreen open={isShow} onClose={() => action(false)} TransitionComponent={Transition}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="close"
        onClick={() => action(false)}
        className={classes.closeDialogBtn}
      >
        <Close className={classes.closeIcon} />
      </IconButton>
      <Box>
        <Typography className={classes.header}>{titleName.header}</Typography>
      </Box>
      {onAddFromMainService && (
        <Box className={classes.addItem} onClick={onAddFromMainService}>
          <Box className={classes.boxHeader}>
            <Box className={classes.icon}>
              <Dehaze />
            </Box>
            <Box className={classes.textProduct}>
              <Typography {...inputProps}>{titleName.add}</Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.createItem} onClick={onCreateAccompaniedService}>
        <Box className={classes.boxHeader}>
          <Box className={classes.icon}>{isBooking ? <Add /> : <Dehaze />}</Box>
          <Box className={classes.textProduct}>
            <Typography {...inputProps}>{titleName.created}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
});

export default DialogAddCategoryItem;
const useStyles = makeStyles(theme => ({
  closeDialogBtn: {
    alignSelf: "flex-end",
    width: "36px",
    height: "36px",
    marginTop: 36,
    marginRight: 36,
    marginBottom: 28,
  },
  closeIcon: {
    width: 36,
    height: 36,
  },
  header: {
    textAlign: "center",
    color: theme.palette.grey[800],
    fontSize: 24,
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  addItem: {
    width: "70%",
    minHeight: 80,
    margin: "68px 148px 41px 0",
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    marginLeft: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  createItem: {
    width: "70%",
    minHeight: 80,
    marginLeft: "15%",
    boxShadow: "0 0.5px 4px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
  },
  icon: {
    width: 36,
    height: 36,
    marginLeft: "5%",
    marginTop: 28,
    paddingTop: 5,
    textAlign: "center",
  },
  textProduct: {
    width: "85%",
    height: "100%",
    marginTop: 35,
    marginBottom: 28,
    marginLeft: 10,
    fontSize: 16,
    textAlign: "left",
  },
  boxHeader: {
    display: "flex",
    cursor: "pointer",
  },
}));

DialogAddCategoryItem.propTypes = {
  isShow: PropTypes.bool.isRequired,
  action: PropTypes.func,
  inputProps: PropTypes.object,
  titleName: PropTypes.object.isRequired,
};

DialogAddCategoryItem.defaultProps = {};
