import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const OrderNotificationIcon = ({ className }) => {
  return (
    <SvgIcon className={className}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 0L24 0 24 24 0 24z"
          transform="translate(-1200.000000, -90.000000) translate(0.000000, 70.000000) translate(1200.000000, 20.000000)"
        />
        <path
          fill="currentColor"
          d="M19 4h-4.18C14.4 2.84 13.3 2 12 2c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14H5v-2h5v2zm4.82 0L12 15.16l1.41-1.41 1.41 1.42L17.99 12l1.42 1.42L14.82 18zM12 14H5v-2h7v2zm7-4H5V8h14v2z"
          transform="translate(-1200.000000, -90.000000) translate(0.000000, 70.000000) translate(1200.000000, 20.000000)"
        />
      </g>
    </SvgIcon>
  );
};

export default memo(OrderNotificationIcon);
