import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  IconButton,
  OutlinedInput,
  Typography,
  CardMedia,
} from "@material-ui/core";
import { Close, Add, Remove } from "@material-ui/icons";
import { ProductItemInList } from "components/mnbooking";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, formatItemPrice, onLoadImageError, formatVietnameseCurrency } from "utils";
import { ItemClassification } from ".";

const DialogListTopping = ({
  isShow,
  isEditItem,
  isLastItem,
  data,
  listDataChecked,
  onClose,
  onCheckedTopping,
  onSave,
  onChangeClassification,
  isEditFromCart,
  classificationItem,
}) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  const [showDataChecked, setShowDataChecked] = useState([]);
  const [orderNumber, setOrderNumber] = useState(data?.orderNumber || 1);

  let isHavingTopping = data && (data.orderDetailsContentList?.length > 0 || data.itemDetailsContentList?.length > 0);
  let isHaveClassificationDetail = data?.groupModel && data.groupModel?.modelList?.length > 0;

  const onChangeOrderNumber = e => {
    let newOrderNumber = parseInt(e.target.value);
    if (newOrderNumber > 0 && newOrderNumber < NUMBER_LIMIT) {
      setOrderNumber(newOrderNumber);
    }
  };

  const onIncrease = () => {
    if (orderNumber < NUMBER_LIMIT - 1) {
      setOrderNumber(orderNumber + 1);
    }
  };

  const onDecrease = () => {
    let minNumberItem = !isEditItem || isLastItem ? 1 : 0;

    if (orderNumber > minNumberItem) {
      setOrderNumber(orderNumber - 1);
    }
  };

  const onSaveOrder = () => {
    if (Boolean(classificationItem) || !isHaveClassificationDetail) onSave(orderNumber);
  };

  useEffect(() => {
    setShowDataChecked(listDataChecked);
  }, [listDataChecked]);

  useEffect(() => {
    if (data?.orderNumber) setOrderNumber(data.orderNumber);
  }, [data?.orderNumber]);

  return (
    <Dialog
      open={isShow}
      maxWidth="md"
      classes={{ paper: defaultClasses.paper, paperScrollPaper: defaultClasses.paperScroll }}
    >
      <DialogTitle className={defaultClasses.rootTitle}>
        <Box className={defaultClasses.boxTitle}>
          <Box className={defaultClasses.boxTitleItem}>
            <CardMedia
              component="img"
              onError={onLoadImageError}
              className={defaultClasses.image}
              src={data?.image || ""}
            />
            <Box className={defaultClasses.textTitleBox}>
              <Typography className={defaultClasses.textTitle}>{data?.name}</Typography>
              <Typography className={defaultClasses.textPrice}>{formatItemPrice(data)}</Typography>
            </Box>
          </Box>
          <IconButton onClick={onClose} className={defaultClasses.iconButtonClose}>
            <Close className={defaultClasses.iconClose} />
          </IconButton>
        </Box>
      </DialogTitle>
      {isHaveClassificationDetail && (
        <ItemClassification
          onChange={onChangeClassification}
          selectedClassification={data?.classification}
          data={data.groupModel}
          isService={data.type === AppConstant.TYPE_CATEGORY.service}
        />
      )}
      <DialogContent className={defaultClasses.rootContent}>
        {isHavingTopping && (
          <>
            {isEditItem
              ? data?.orderDetailsContentList?.length > 0 && (
                  <Typography className={defaultClasses.toppingText}>
                    {getLabel(getCommonKey(LangConstant.TXT_TOPPING_PRODUCT_ACCOMPANIED))}
                  </Typography>
                )
              : data?.itemDetailsContentList?.length > 0 && (
                  <Typography className={defaultClasses.toppingText}>{getLabel(LangConstant.TXT_TOPPING)}</Typography>
                )}
            {isEditItem
              ? data?.orderDetailsContentList &&
                data.orderDetailsContentList.map((dataMap, index) => (
                  <ProductItemInList
                    key={index}
                    data={dataMap}
                    onChecked={e => onCheckedTopping(e, index, dataMap)}
                    valueChecked={showDataChecked.includes(index)}
                    disabled={data?.type === AppConstant.TYPE_CATEGORY.combo}
                    classes={{ rootItemInList: defaultClasses.rootItemInList, customCheckbox: defaultClasses.checkBox }}
                  />
                ))
              : data?.itemDetailsContentList &&
                data.itemDetailsContentList.map((dataMap, index) => (
                  <ProductItemInList
                    key={index}
                    data={dataMap}
                    onChecked={e => onCheckedTopping(e, index)}
                    valueChecked={showDataChecked.includes(index)}
                    disabled={data?.type === AppConstant.TYPE_CATEGORY.combo}
                    classes={{ rootItemInList: defaultClasses.rootItemInList, customCheckbox: defaultClasses.checkBox }}
                  />
                ))}
          </>
        )}
      </DialogContent>
      <DialogActions className={defaultClasses.rootActions}>
        <Box className={defaultClasses.boxActionsCount}>
          <Box className={defaultClasses.quantityItemBox}>
            <IconButton className={defaultClasses.creaseButton} onClick={onDecrease}>
              <Remove className={defaultClasses.decreaseIcon} />
            </IconButton>
            <OutlinedInput
              classes={{ input: defaultClasses.inputNumberItem }}
              onChange={onChangeOrderNumber}
              value={orderNumber}
            />
            <IconButton className={defaultClasses.creaseButton} onClick={onIncrease}>
              <Add className={defaultClasses.increaseIcon} />
            </IconButton>
          </Box>
          <Typography className={defaultClasses.totalPrice}>
            {formatVietnameseCurrency(
              Boolean(classificationItem || data?.classification) || !isHaveClassificationDetail || isEditFromCart
                ? getTotalPrice(classificationItem || data?.classification || data, orderNumber)
                : 0,
            )}
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={onClose}
            classes={{ root: defaultClasses.buttonCancelActions, label: defaultClasses.labelButton }}
          >
            {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
          </Button>
          <Button
            classes={{
              root: defaultClasses.buttonSaveActions,
              label: defaultClasses.labelButton,
            }}
            onClick={onSaveOrder}
          >
            {getLabel(getCommonKey(LangConstant.TXT_SAVE_BUTTON))}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

DialogListTopping.propTypes = {
  isShow: PropTypes.bool,
  isEditItem: PropTypes.bool,
  isLastItem: PropTypes.bool,
  isEditFromCart: PropTypes.bool,
  data: PropTypes.object,
  listDataChecked: PropTypes.array,
  onClose: PropTypes.func,
  onCheckedTopping: PropTypes.func,
  onSave: PropTypes.func,
  onChangeClassification: PropTypes.func,
};
DialogListTopping.defaultProps = {
  isEditItem: false,
  isLastItem: false,
  isEditFromCart: false,
};

export default memo(DialogListTopping);

const NUMBER_LIMIT = 1000;

const getTotalPrice = (data, orderNumber) => {
  if (data) {
    let itemPrice = data.priceTaxIn || data.item?.priceTaxIn || 0;
    let totalItemPrice = itemPrice * orderNumber;

    if (data.type !== AppConstant.ITEM_TYPE.comboItem) {
      // Calculate toppings price of item which isn't belong to Combo category
      let toppingList = data.selectedTopping || data.toppingList || [];
      if (toppingList.length > 0) {
        toppingList.forEach(topping => {
          let priceTaxInItem = topping.priceTaxIn || 0;
          totalItemPrice += orderNumber * priceTaxInItem;
        });
      }
    }

    return totalItemPrice;
  }
};

const useStyles = makeStyles(theme => ({
  paper: {
    margin: 10,
  },
  paperScroll: {
    maxHeight: "calc(100% - 34px)",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rootTitle: {
    width: 654,
    height: 100,
    padding: "18px 24px",
    borderBottom: "0.5px solid " + theme.palette.grey[200],
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  boxTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  boxTitleItem: {
    height: 64,
    display: "flex",
  },
  image: {
    width: 96,
    maxWidth: 96,
  },
  textTitleBox: {
    maxWidth: 454,
    padding: "8px 16px 4px",
    [theme.breakpoints.down("md")]: {
      padding: "0 8px",
    },
  },
  textTitle: {
    height: 28,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.56,
    color: "#3e4045",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      WebkitLineClamp: 2,
    },
  },
  textPrice: {
    height: 22,
    fontSize: 14,
    lineHeight: 1.57,
    color: "#3e4045",
  },
  iconButtonClose: {
    width: 24,
    height: 24,
    padding: 0,
    marginTop: 8,
  },
  iconClose: {
    width: "inherit",
    height: "inherit",
  },
  rootContent: {
    width: 654,
    maxHeight: 432,
    minHeight: 200,
    padding: 24,
    borderBottom: "0.5px solid " + theme.palette.grey[200],
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  toppingText: {
    height: 22,
    marginBottom: 8,
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 600,
    color: "#3e4045",
  },
  rootItemInList: {
    display: "flex",
    justifyContent: "space-between",
    height: 40,
    padding: "8px 0",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
  },
  checkBox: {
    padding: 0,
  },
  rootActions: {
    width: 654,
    height: 78,
    padding: 24,
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 106,
    },
  },
  boxActionsCount: {
    maxWidth: 400,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  quantityItemBox: {
    display: "flex",
    minWidth: 80,
    padding: 0,
    justifyContent: "space-between",
  },
  creaseButton: {
    width: 28,
    height: 28,
    padding: 0,
    margin: 1,
  },
  decreaseIcon: {
    width: 23,
    height: 23,
    backgroundColor: "#b3b5ba",
    borderRadius: "50%",
    color: theme.palette.white,
  },
  inputNumberItem: {
    width: 35,
    height: 22,
    padding: "0 5px",
    textAlign: "center",
    fontSize: 13,
    lineHeight: 1.69,
    color: "#565c6a",
  },
  increaseIcon: {
    width: 23,
    height: 23,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    color: theme.palette.white,
  },
  totalPrice: {
    marginLeft: 16,
    color: theme.palette.primary.main,
    fontWeight: 600,
    lineHeight: 1.56,
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  buttonSaveActions: {
    padding: "0 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.8,
      color: "unset",
    },
  },
  buttonCancelActions: {
    padding: "0 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.black,
  },
  labelButton: {
    width: "100%",
    height: 24,
    marginTop: 3,
    marginBottom: 3,
  },
}));
