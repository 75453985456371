import React, { memo, useEffect, useState } from "react";
import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UserAction from "redux/user.redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey, onCloseBrowser } from "utils";
import { NotificationSms } from "./components";
import { BglobalJSC } from "components";

const NotificationSetting = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataUser, isUserUpdateSuccess] = useSelector(
    ({ userRedux }) => [userRedux.dataUser, userRedux.isUserUpdateSuccess],
    shallowEqual,
  );

  const { t: getLabel } = useTranslation(LangConstant.NS_NOTIFICATION_SETTING);
  const [dataNotification, setDataNotification] = useState((dataUser && dataUser.details) || {});
  const [isEnableSave, setIsEnableSave] = useState(false);

  // TODO: Abandoned because more features than the original scope of the project will open in another pj
  // const onchangeEmail = (data, type) => {
  //   setIsEnableSave(true);
  //   if (type === OBJECT_TYPE.email) {
  //     setDataNotification({ ...dataNotification, emailSettingList: data });
  //   } else {
  //     setDataNotification({ ...dataNotification, emailSetting: data });
  //   }
  // };

  // const onchangeWebsite = data => {
  //   setIsEnableSave(true);
  //   setDataNotification({ ...dataNotification, notificationSetting: data });
  // };

  const onchangeSMS = (data, type) => {
    setIsEnableSave(true);
    if (type === OBJECT_TYPE.sms) {
      setDataNotification({ ...dataNotification, smsSettingList: data });
    } else {
      setDataNotification({ ...dataNotification, smsSetting: data });
    }
  };

  const onSubmitDataAll = () => {
    let data = { ...dataNotification };
    // TODO: Abandoned because more features than the original scope of the project will open in another pj
    // if (!Boolean(data?.notificationSetting?.disableAll)) {
    //   data = { ...dataNotification, notificationSetting: OBJECT_DEFAULT };
    // }
    // if (!Boolean(data?.emailSetting?.disableAll)) {
    //   let dataEmail = { ...OBJECT_DEFAULT, packagePayment: 0 };
    //   data = { ...dataNotification, emailSetting: dataEmail };
    // }
    if (Boolean(data?.smsSetting?.disableAll)) {
      let dataSMS = { ...dataNotification.smsSetting, disableAll: DISABLE_NOTIFICATION_OPTION.disable };
      data = { ...dataNotification, smsSetting: dataSMS };
    }

    let user = { ...dataUser, details: data };

    dispatch(UserAction.requestUpdateUserInfo(user));
    setIsEnableSave(false);
  };

  useEffect(() => {
    if (isEnableSave) {
      window.addEventListener("beforeunload", onCloseBrowser);

      return () => {
        // handling will unmounted component
        window.removeEventListener("beforeunload", onCloseBrowser);
      };
    }
  }, [isEnableSave]);

  useEffect(() => {
    if (isUserUpdateSuccess) {
      dispatch(UserAction.resetUserUpdate());
    }
  }, [isUserUpdateSuccess]);

  return (
    <Box>
      <Paper>
        <Box className={classes.header}>
          <Typography className={classes.textHeader}>
            {getLabel(getCommonKey(LangConstant.TXT_NOTIFICATION_SETTING))}
          </Typography>
          <Box display="flex">
            <Button
              onClick={onSubmitDataAll}
              classes={{ root: clsx("semiBold-lg-txt", classes.buttonSave), disabled: classes.disabledSaveButton }}
              disabled={!isEnableSave}
            >
              <Typography className={classes.typography}>{getLabel(getCommonKey(LangConstant.TXT_SAVE))}</Typography>
            </Button>
          </Box>
        </Box>
        <Box>
          {/* TODO: Abandoned because more features than the original scope of the project will open in another pj */}
          {/* <NotificationWebsite data={dataNotification.notificationSetting} onChange={onchangeWebsite} />
          <NotificationEmailEmail data={dataNotification} onChange={onchangeEmail} /> */}
          <NotificationSms data={dataNotification} onChange={onchangeSMS} />
        </Box>
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};

export const OBJECT_TYPE = {
  email: "email",
  sms: "sms",
};

export const DISABLE_NOTIFICATION_OPTION = {
  enable: 0,
  disable: 1,
};

// TODO: Abandoned because more features than the original scope of the project will open in another pj
// export const OBJECT_DEFAULT = {
//   reservationNew: 0,
//   reservationInfoUpdated: 0,
//   reservationStatusUpdated: 0,
//   reservationAssign: 0,
//   news: 0,
//   remoteOrderNew: 0,
//   remoteOrderUpdated: 0,
//   remoteOrderStatusUpdated: 0,
//   disableAll: 0,
// };

export default memo(NotificationSetting);

const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    padding: "15px 30px",
    display: "flex",
    borderBottom: "1px solid #ebeced",
    backgroundColor: "#fafafb",
    minHeight: 71,
  },
  textHeader: {
    textAlign: "left",
    width: "95%",
    paddingTop: 6,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      width: "85%",
    },
  },
  buttonSave: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    textAlign: "right",
    height: 40,
    width: 78,
    [theme.breakpoints.down(600)]: {
      width: "15%",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary["main"],
    },
  },
  disabledSaveButton: {
    opacity: 0.6,
  },
  typography: {
    color: theme.palette.white,
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));
