import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Cookie from "js-cookie";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Box, Button, IconButton, makeStyles, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import { getLabelArray, getFeatureConditions } from "utils";
import { AppConstant, LangConstant } from "const";
import { MenuStatus, SelectCategory } from "components";

const FilterMenuMnBooking = ({
  onChangeFilter,
  placeCategoryArray,
  className,
  menuItemClass,
  onChangeFilterByStaff,
  onChangeFilterByPlace,
}) => {
  const staffData = useSelector(state => state.staffRedux.staff);
  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { isPremium } = getFeatureConditions(reservationSetting);

  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const staffMenuRef = useRef();
  const [staffNameArray, setStaffNameArray] = useState([]);
  const [isShowStaffMenu, setIsShowStaffMenu] = useState(false);
  const [staffMenuAnchorEl, setStaffMenuAnchorEl] = useState(null);
  const [filterByStaff, setFilterByStaff] = useState({
    name: null,
    uid: null,
  });
  const [filterByPlace, setFilterByPlace] = useState({
    placeItemUid: null,
    placeCategoryUid: null,
  });
  const [labelDefault, setLabelDefault] = useState(getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE));
  const [isShowButtonFilter, setIsShowButtonFilter] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(0);
  const widthMenuPaper = document.getElementById("box-menu-staff")?.offsetWidth;

  const onSelectCategory = data => {
    if (data) {
      setFilterByPlace(data);
      setLabelDefault(data.name);
    } else {
      setFilterByPlace();
      setLabelDefault(getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE));
    }
  };

  const onResetSelect = () => {
    setFilterByStaff({ name: null, uid: null });
    setFilterByStatus(0);
    setFilterByPlace({ placeItemUid: null, placeCategoryUid: null });
    setLabelDefault(getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE));
  };

  useEffect(() => {
    if (placeCategoryArray && placeCategoryArray.length > 0) {
      placeCategoryArray.unshift({
        name: getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE),
      });
    }
  }, [placeCategoryArray]);

  useEffect(() => {
    if (staffData && staffData.length > 0) {
      let tmpArr = [];
      staffData.forEach(element => {
        tmpArr.push(element);
      });
      setStaffNameArray(tmpArr);
    }
  }, [staffData]);

  useEffect(() => {
    onChangeFilterByStaff(filterByStaff);
  }, [filterByStaff]);

  useEffect(() => {
    if (filterByStatus) {
      let status = LangConstant.ARR_STATUS_FILTER.indexOf(tmpArr[filterByStatus]);
      onChangeFilter(status);
    } else {
      onChangeFilter(null);
    }
  }, [filterByStatus]);

  useEffect(() => {
    if (filterByPlace) {
      onChangeFilterByPlace(filterByPlace);
    }
  }, [filterByPlace]);

  useEffect(() => {
    let isShow = Boolean(filterByStaff.name || filterByStaff.uid || filterByPlace.uid || filterByStatus);
    setIsShowButtonFilter(isShow);
  }, [filterByPlace, filterByStaff, filterByStatus]);

  let tmpArr = [...new Set(LangConstant.ARR_STATUS_FILTER)];
  tmpArr.splice(tmpArr.length - 4, 3);
  tmpArr.splice(2, 0, [...LangConstant.ARR_STATUS_FILTER][8]);

  return (
    <Paper classes={{ root: classes.filterContainer }}>
      <Box marginRight={`${isPremium ? 0 : "10px"}`}>
        <MenuStatus
          rowNum={LangConstant.ARR_STATUS_FILTER.length}
          onChange={index => {
            setFilterByStatus(index);
          }}
          status={filterByStatus}
          classes={{ root: classes.filterByStatus, item: "menu-item-status", paper: classes.menuStatusPaper }}
          categoryText={getLabel(LangConstant.TXT_STATUS)}
          arrayItemsText={getLabelArray([...new Set(tmpArr)], getLabel, true)}
        />
      </Box>
      {isPremium && (
        <Box id="box-menu-staff" className={`${classes.staffMenuBtn} ${className}`}>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={staffMenuRef}
            onClick={() => {
              setIsShowStaffMenu(!isShowStaffMenu);
              setStaffMenuAnchorEl(staffMenuRef.current);
            }}
            classes={{ root: classes.btnRoot }}
          >
            <Typography className={clsx("ellipsis", "medium-md-txt", classes.btnLabel)}>
              {filterByStaff.name || getLabel(LangConstant.TXT_STAFF)}
            </Typography>

            {isShowStaffMenu ? <ArrowDropUp className={classes.icon} /> : <ArrowDropDown className={classes.icon} />}
          </IconButton>
        </Box>
      )}
      {isPremium && staffNameArray.length > 0 && (
        <StaffMenu
          staffMenuAnchorEl={staffMenuAnchorEl}
          setIsShowStaffMenu={setIsShowStaffMenu}
          setStaffMenuAnchorEl={setStaffMenuAnchorEl}
          staffNameArray={staffNameArray}
          menuItemClass={menuItemClass}
          onChange={staff => {
            setFilterByStaff({ name: staff.name, uid: staff.uid });
          }}
          widthMenuPaper={widthMenuPaper}
        />
      )}
      <SelectCategory
        data={placeCategoryArray}
        value={labelDefault}
        classes={{ root: classes.categoryMenu }}
        onChange={onSelectCategory}
      />
      {isShowButtonFilter && (
        <Button className={classes.clearFilter} onClick={onResetSelect} color="primary">
          {getLabel(LangConstant.TXT_CLEAR_FILTER)}
        </Button>
      )}
    </Paper>
  );
};
export default memo(FilterMenuMnBooking);

FilterMenuMnBooking.propTypes = {
  onChangeFilter: PropTypes.func,
  onChangeFilterByStaff: PropTypes.func,
  onChangeFilterByPlace: PropTypes.func,
  className: PropTypes.object,
  menuItemClass: PropTypes.object,
  placeCategoryArray: PropTypes.arrayOf(Object),
};
FilterMenuMnBooking.defaultProps = {
  placeCategoryArray: [],
};

const StaffMenu = ({
  staffMenuAnchorEl,
  setIsShowStaffMenu,
  setStaffMenuAnchorEl,
  staffNameArray,
  menuItemClass,
  onChange,
  widthMenuPaper,
}) => {
  const classes = useStyles({ widthMenuPaper: widthMenuPaper });
  const { t: getLabel } = useTranslation();
  const user = useSelector(state => state.userRedux.dataUser);
  const role = Cookie.get(AppConstant.ROLE);
  const [staffMapArray, setStaffMapArray] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const onCloseStaffMenu = () => {
    setIsShowStaffMenu(false);
    setStaffMenuAnchorEl(null);
  };

  const onSelectStaff = staff => {
    onChange(staff);
    onCloseStaffMenu();
  };

  useEffect(() => {
    if (staffNameArray.length > 0 && role) {
      let tmpMapArr = [...staffNameArray];
      staffNameArray.map(staff => {
        if (role && staff?.userId === user?.id && staff?.role === parseInt(role)) {
          setCurrentUser(staff);
          tmpMapArr = tmpMapArr.filter(tmpStaff => tmpStaff !== staff);
        }
      });
      setStaffMapArray(tmpMapArr);
    }
  }, [staffNameArray]);

  return (
    <>
      <Menu
        keepMounted
        open={Boolean(staffMenuAnchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={staffMenuAnchorEl}
        getContentAnchorEl={null}
        onClose={onCloseStaffMenu}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        <MenuItem
          onClick={() => onSelectStaff({ name: null, uid: null })}
          className={clsx("semiBold-md-txt ellipse", classes.menuItemRoot)}
        >
          {getLabel(LangConstant.TXT_STAFF)}
        </MenuItem>
        <MenuItem
          onClick={() => onSelectStaff(currentUser)}
          className={clsx("medium-txt ellipse", classes.currentUserItem, classes.menuItemRoot)}
        >
          {getLabel(LangConstant.TXT_ASSIGN_TO_MYSELF)}
        </MenuItem>
        {staffMapArray.map((element, index) => (
          <MenuItem
            key={index}
            className={clsx(classes.menuItemRoot, menuItemClass)}
            onClick={() => onSelectStaff(element)}
          >
            <Typography color="inherit" className="medium-md-txt ellipsis">
              {element?.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  staffMenuBtn: {
    width: "100%",
    height: 35,
    minWidth: 190,
    maxWidth: 270,
    marginRight: 10,
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      height: 30,
      maxWidth: 240,
    },
    [theme.breakpoints.down(900)]: {
      marginBottom: 10,
    },
  },
  icon: {
    right: 15,
    [theme.breakpoints.down("sm")]: {
      right: 8,
    },
    color: theme.palette.grey[500],
  },
  btnRoot: {
    width: "100%",
    color: "#3b404c",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[200]}`,
    height: "100%",
  },
  btnLabel: {
    justifyContent: "space-between",
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 8,
    },
  },
  menuPaper: {
    marginTop: "2px",
    borderRadius: "0%",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: "calc(100vh - 230px)",
    width: props => props.widthMenuPaper,
    overflow: "auto",
  },
  menuList: {
    paddingTop: `0px`,
    paddingBottom: `0px`,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    minHeight: 40,
    display: "flex",
    justifyContent: "space-between",
    whiteSpace: "normal",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  placeMenuInput: {
    marginLeft: 0,
    height: 35,
    borderColor: "#ebeced",
    borderRadius: "unset",
    "&>fieldset": {
      borderColor: "inherit",
    },
    "&>input::placeholder": {
      opacity: 1,
    },
  },
  filterContainer: {
    display: "flex",
    margin: "2px 0",
    boxShadow: "unset",
    padding: "0 0 12px",
    position: "relative",
    backgroundColor: "#fafafb",
    [theme.breakpoints.down(900)]: {
      display: "grid",
    },
  },
  placeMenu: {
    width: 240,
    height: 35,
  },
  filterByStatus: {
    height: 35,
    minWidth: 170,
    marginLeft: "0 !important",
    [theme.breakpoints.down(900)]: {
      width: 240,
      marginLeft: "10px !important",
      marginBottom: "10px !important",
      marginRight: "0 !important",
    },
  },
  resetFilterButton: {
    position: "absolute",
    right: 0,
  },
  assignForMe: {
    width: 16,
    height: 16,
  },
  placeItemButton: {
    width: "240px !important",
  },
  categoryMenu: {
    height: 35,
    minWidth: 190,
    maxWidth: 270,
    wordWrap: "anywhere",
    border: `solid 1px ${theme.palette.grey[200]}`,
    color: "#d4d5d8",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 240,
      marginLeft: 10,
      height: 30,
    },
  },
  menuStatusPaper: {
    width: 170,
  },
  currentUserItem: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white,
  },
  clearFilter: {
    textTransform: "none",
    fontSize: 13,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 240,
    },
  },
}));
