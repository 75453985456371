import React, { memo, useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  DialogTitle,
  IconButton,
  OutlinedInput,
  Box,
} from "@material-ui/core";
import { Close, Error } from "@material-ui/icons";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const DialogAddCategory = ({ isShow, onClose, onChange, onSubmit, data, isWarning, nameWaring }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [value, setValue] = useState(data ? data.name : "");

  const onChangeCategory = event => {
    onChange(event.target.value);
    setValue(event.target.value);
  };

  useEffect(() => {
    setValue(data ? data.name : "");
  }, [data]);

  return (
    <Dialog open={isShow} classes={{ paperScrollPaper: classes.dialogContainer }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography classes={{ body1: classes.textDialogTitle }}>
          {data ? getLabel(LangConstant.TXT_EDIT_CATEGORY) : getLabel(LangConstant.TXT_ADD_NEW_CATEGORY)}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.typographyContent}>{getLabel(LangConstant.TXT_CATEGORY_NAME)}</Typography>
        <OutlinedInput
          classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
          required
          onChange={onChangeCategory}
          inputProps={{
            name: "categoryName",
          }}
          value={value}
        />
        {isWarning && (
          <Box className={classes.warningName}>
            <Error color="primary" className={classes.iconError} />
            <Typography color="primary" className={classes.textWarning}>
              {nameWaring}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.buttonCancel} onClick={onClose}>
          {getLabel(LangConstant.TXT_SHOP_CANCEL)}
        </Button>
        <Button type="submit" color="primary" variant="contained" onClick={onSubmit} className={classes.buttonSubmit}>
          {getLabel(LangConstant.TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 448,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    "@media only screen and (max-width: 515px)": {
      width: "100%",
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      height: 236,
      marginBottom: 0,
      maxHeight: "none",
    },
  },
  dialogTitle: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: "fixed",
    width: 448,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 60,
    padding: "0 24px",
    zIndex: 100,
    "@media only screen and (max-width: 515px)": {
      width: "100%",
    },
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 20,
  },
  closeButton: {
    position: "absolute",
    right: 14,
    top: 18,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    marginTop: 34,
    padding: "26px 24px 17px",
    display: "flex",
    flexDirection: "column",
    "&:first-child": {
      paddingTop: 26,
      "@media only screen and (max-width: 515px)": {
        padding: "26px 10px 20px",
        borderBottom: "none",
      },
    },
  },
  typographyContent: {
    margin: "24px 0 8px",
    fontSize: 13,
    fontWeight: 600,
    color: "#3e4045",
    lineHeight: "22px",
  },
  contentLineEdit: {
    width: "100%",
    height: 40,
    fontSize: 13,
    padding: "9px 16px",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  inputEdit: {
    padding: 0,
  },
  dialogActions: {
    display: "block",
    textAlign: "right",
    padding: "19px 16px",
  },
  buttonCancel: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: 0,
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
  buttonSubmit: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: "0 24px 0",
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
}));
DialogAddCategory.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  isWarning: PropTypes.bool,
};
DialogAddCategory.defaultProps = { isWarning: false };

export default memo(DialogAddCategory);
