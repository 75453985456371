import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { LocalMallOutlined } from "@material-ui/icons";
import { LangConstant } from "const";
import { convertHex2rgba, formatCurrency } from "utils";

const RemoteOrderPreviewDetail = () => {
  const defaultClasses = useStyles();

  const onOpenDetail = () => {};

  return (
    <Box className={defaultClasses.root}>
      <ContentSelectedProduct classes={defaultClasses} onOpenDetail={onOpenDetail} />
    </Box>
  );
};

RemoteOrderPreviewDetail.propTypes = {};
RemoteOrderPreviewDetail.defaultProps = {};

export default memo(RemoteOrderPreviewDetail);

const ContentSelectedProduct = ({ classes }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.iconHeader}>
          <LocalMallOutlined />
        </Box>
        <Box className={classes.contentHeader}>
          <Typography variant="subtitle1" className={classes.haveChooseTypo}>
            {getLabel(LangConstant.TXT_MY_ORDER)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.totalSelectedBox}>
        <Box className={classes.itemBox}>
          <Typography variant="body1" className={classes.priceType}>
            {getLabel(LangConstant.TXT_TOTAL_TAX_FREE)}
          </Typography>
          <Typography variant="body1" className={classes.priceValue}>
            {formatCurrency(0)}
          </Typography>
        </Box>
        <Box className={classes.itemBox}>
          <Typography variant="body1" className={classes.priceType}>
            {getLabel(LangConstant.TXT_TAX_PRICE_VALUE)}
          </Typography>
          <Typography variant="body1" className={classes.priceValue}>
            {formatCurrency(0)}
          </Typography>
        </Box>
        <Box className={classes.divider} />
        <Box className={classes.totalBox}>
          <Typography variant="subtitle1" className={classes.totalTypo}>
            {getLabel(LangConstant.TXT_TOTAL_TAX_INCLUDED)}
          </Typography>
          <Typography variant="subtitle1" className={classes.totalValue}>
            {formatCurrency(0)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

ContentSelectedProduct.propTypes = {
  selectedData: PropTypes.array,
  listDataDisplay: PropTypes.array,

  classes: PropTypes.object,
  calculateTotal: PropTypes.object,

  onRemoveItem: PropTypes.func,
  onOpenDetail: PropTypes.func,
  onClose: PropTypes.func,
};

ContentSelectedProduct.defaultProps = {};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dialogRoot: {
    display: "flex",
    width: "100%",
    height: 75,
    justifyContent: "space-between",
  },
  iconButton: {
    width: 36,
    height: 36,
    margin: "16px 16px 23px",
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  header: {
    display: "flex",
    width: "100%",
    height: 48,
    backgroundColor: "rgba(62, 64, 69, 0.1)",
    padding: "6px 16px",
    boxShadow: "1px 0 4px 0 rgba(0, 0, 0, 0.16)",
  },
  iconHeader: {
    width: 36,
    height: 36,
    color: "#6c7078",
    backgroundColor: "white",
    borderRadius: "50%",
    padding: 6,
    marginRight: 16,
  },
  contentHeader: {
    display: "flex",
    width: "100%",
    height: 24,
    margin: "6px 0",
  },
  haveChooseTypo: {
    color: "#3e4045",
    lineHeight: 1.5,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  numberProductTypo: {
    color: theme.palette.grey[600],
    lineHeight: 1.9,
  },
  body: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    minHeight: 200,
    position: "relative",
    "&>div": {
      maxWidth: "unset",
    },
  },
  bodyEmpty: {
    alignItems: "center",
    justifyContent: "center",
  },
  totalSelectedBox: {
    width: "100%",
    background: convertHex2rgba("#EF5845", 0.1),
    marginTop: 10,
    boxShadow: "1px 0 4px 0 rgba(0, 0, 0, 0.16)",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 12,
  },
  itemBox: {
    display: "flex",
    height: "100%",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
  },
  priceType: {
    lineHeight: 1.5,
  },
  timeTypo: {
    marginLeft: "auto",
    fontSize: 14,
    color: "#447aff",
  },
  includedTax: {
    marginTop: 8,
    fontSize: 12,
    color: "#7f838c",
    fontWeight: 400,
    lineHeight: 1.67,
  },
  priceValue: {
    marginLeft: "auto",
    lineHeight: 1.5,
  },
  buttonReserve: {
    alignSelf: "center",
    width: 132,
    height: 40,
    marginTop: 16,
    borderRadius: 2,
    fontSize: 16,
    fontWeight: 600,
    textTransform: "inherit",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  emptyCartCover: {
    width: 160,
    height: 160,
    position: "absolute",
  },
  divider: {
    width: "auto",
    height: 1,
    backgroundColor: theme.palette.divider,
  },
  totalValue: {
    color: theme.palette.primary.main,
    marginLeft: "auto",
  },
  totalBox: {
    padding: "26px 12px",
    display: "flex",
  },
}));
