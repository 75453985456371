import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const ActivityHistoryIcon = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-40 -453) translate(0 70) translate(40 384)" />
              <g fill="currentColor" fillRule="nonzero">
                <path
                  d="M15.745 10.384c-1.545 0-3.027.612-4.118 1.7-1.092 1.09-1.704 2.566-1.7 4.104H7.99l2.52 2.508v.088l2.65-2.596h-1.936c-.004-1.827 1.098-3.476 2.793-4.177 1.694-.702 3.646-.316 4.944.976 1.297 1.292 1.685 3.235.98 4.922-.704 1.687-2.36 2.785-4.196 2.78-1.199.003-2.348-.474-3.19-1.324l-.913.92c1.084 1.09 2.563 1.701 4.103 1.697 3.214 0 5.82-2.594 5.82-5.794 0-3.2-2.606-5.793-5.82-5.793v-.011zm-.649 3.22v3.22l2.761 1.632.473-.778-2.266-1.336v-2.738h-.968z"
                  transform="translate(-40 -453) translate(0 70) translate(40 384) translate(1 .5)"
                />
                <path
                  stroke="#FFF"
                  strokeWidth=".5"
                  d="M11.18 10.91c.37-.342.776-.643 1.21-.899.5-.246 1.049-.38 1.606-.394l4.235-6.571c.523.092 1.056-.1 1.397-.504.523-.597.504-1.491-.044-2.065-.548-.574-1.444-.638-2.069-.148-.624.49-.772 1.373-.34 2.037l-4.18 6.419c-.406-.12-.843-.068-1.21.142L8.65 5.006c.38-.694.185-1.56-.456-2.027-.64-.467-1.53-.389-2.078.182-.55.57-.59 1.457-.095 2.075l-4.4 6.571c-.64-.069-1.25.287-1.503.876-.253.59-.09 1.275.403 1.687.297.258.685.387 1.077.358.393-.029.758-.213 1.013-.511.426-.495.467-1.213.1-1.752l4.421-6.66c.172.028.346.028.517 0l3.388 4.261c.01.146.01.292 0 .438.013.145.063.284.143.405z"
                  transform="translate(-40 -453) translate(0 70) translate(40 384) translate(1 .5)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

ActivityHistoryIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
ActivityHistoryIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default memo(ActivityHistoryIcon);
