import { ApiConstant } from "../const";
import { createApiRegistration, createApiAttachmentUpload } from "../api";
import { format } from "react-string-format";

export const get = data => createApiRegistration().get(format(ApiConstant.GET_SHOP_INFO, data.shop_id), {});

export const update = data => createApiRegistration().put(format(ApiConstant.UPDATE_SHOP_INFO, data.shop_id), data);

export const upload = data => createApiAttachmentUpload().post(ApiConstant.UPLOAD_ATTACHMENT, data);

export const getFbPage = data =>
  createApiRegistration().get(format(ApiConstant.GET_SHOP_INFO, ApiConstant.SHOP_LINK_FACEBOOK), data);

export const putFbLink = data =>
  createApiRegistration().put(format(ApiConstant.GET_SHOP_INFO, format(ApiConstant.SHOP_LINK_UPDATE, data.uid)), data);
