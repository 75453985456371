import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Chip, makeStyles, Select, Typography } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { chipsId, FilterCategoryMenu } from "components/mn-master-product/CategoryMenu";
import { Close } from "@material-ui/icons";
import { sortDataByArray, textNormalize } from "utils";
import ComboCategoryMenuItem from "./ComboCategoryMenuItem";

const ComboCategoryMenu = props => {
  const {
    label,
    modeViewAr,
    customClasses,
    serviceData,
    productData,
    enableChangeViewMode,
    onChange,
    selectedArr,
    isDisable,
  } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState(selectedArr);
  const [dataService, setDataService] = useState(serviceData);
  const [dataProduct, setDataProduct] = useState(productData);
  const [selectData] = useState([...dataService, ...dataProduct]);
  const [searchValue, setSearchValue] = useState("");
  const [modeView, setModeView] = useState(modeViewAr[0]?.id);
  const firstTabSelected = modeView === modeViewAr[0]?.id;
  const [showData, setShowData] = useState(firstTabSelected ? dataService : dataProduct);
  const [scroll, setScroll] = useState(false);

  const onCheckBox = (dataItem, dataItemSelect) => {
    let uid = dataItem.uid;
    const newSelectedValue = [...selectedValue];
    if (!newSelectedValue.includes(uid)) {
      newSelectedValue.push(uid);
    } else {
      const deleteIndex = newSelectedValue.indexOf(uid);
      if (deleteIndex > -1) {
        newSelectedValue.splice(deleteIndex, 1);
      }
    }
    setSelectedValue(newSelectedValue);
    const totalData = [...dataService, ...dataProduct];
    const sendData = totalData
      .filter(data => newSelectedValue.includes(data.uid))
      .map(data => {
        if (1 > data.amount) {
          data.amount = 1;
        }
        if (Boolean(data.groupModel) && Boolean(dataItemSelect)) {
          data.name = dataItemSelect.name;
          data.itemUid = dataItemSelect.uid;
        }
        return data;
      });
    onChange(sendData);
  };

  const onCheck = (dataItem, dataItemSelect) => {
    onCheckBox(dataItem, dataItemSelect);
    setScroll(!scroll);
  };

  const onChangeAmount = (uid, amount) => {
    const targetData = firstTabSelected ? dataService : dataProduct;
    targetData.filter(data => data.uid == uid).map(data => (data.amount = amount));

    let totalData = [];
    if (firstTabSelected) {
      setDataService(targetData);
      setShowData(targetData);
      totalData = [...targetData, ...dataProduct];
    } else {
      setDataProduct(targetData);
      setShowData(targetData);
      totalData = [...targetData, ...dataService];
    }

    const sendData = totalData.filter(data => selectedValue.includes(data.uid));
    onChange(sendData);
  };

  const onFilter = search => {
    var data = firstTabSelected ? dataService : dataProduct;
    var searchValue = textNormalize(search);
    setSearchValue(searchValue);
    const cloneData = JSON.parse(JSON.stringify(data));
    const filteredArray = cloneData.filter(data => getFilterArray(data, searchValue));
    if (search === "") {
      setShowData(data);
    } else {
      setShowData(filteredArray);
    }
  };

  const onClearFilter = () => {
    setSearchValue("");
  };

  const onChangeModeView = event => {
    const newMode = event.currentTarget.id;
    if (newMode !== modeView) {
      setModeView(newMode);
      if (newMode === modeViewAr[0]?.id) {
        setShowData(dataService);
      } else {
        setShowData(dataProduct);
      }
      setSearchValue("");
    }
  };

  const onDeleteChip = (event, selectedId) => {
    event.stopPropagation();
    if (!isDisable) {
      onCheckBox(selectedId);
    }
  };

  const onMouseDown = event => {
    event.stopPropagation();
  };

  useEffect(() => {
    let searchTimeout = setTimeout(() => {
      onFilter(searchValue);
    }, 500);
    return () => clearTimeout(searchTimeout);
  }, [searchValue]);

  useEffect(() => {
    let chipBox = document.getElementById(chipsId);
    if (chipBox) {
      chipBox.scrollTo(0, chipBox.offsetHeight);
    }
  }, [scroll]);

  return (
    <Select
      disabled={isDisable}
      className={clsx(classes.root, customClasses?.root)}
      displayEmpty
      labelId="label"
      multiple
      value={selectedValue}
      renderValue={value => {
        if (value.length == 0) {
          return (
            <Typography color="inherit" className="regular-md-txt">
              {label}
            </Typography>
          );
        } else {
          return (
            <Box className={isDisable ? classes.disableChips : classes.chips} id={chipsId}>
              {sortDataByArray(
                selectData.filter(data => getCheckData(data, value)),
                selectedValue,
              ).map(data => (
                <Chip
                  key={data.uid}
                  label={`${data.name} (${data.amount})`}
                  className={`${classes.chip} ellipsis`}
                  classes={{ deleteIcon: classes.deleteChip }}
                  deleteIcon={<Close />}
                  onDelete={isDisable ? null : event => onDeleteChip(event, data.uid)}
                  onMouseDown={onMouseDown}
                  size="small"
                  clickable
                />
              ))}
            </Box>
          );
        }
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        style: { marginTop: 4, maxWidth: 250 },
      }}
      classes={{ root: clsx(classes.selectRoot, customClasses?.selectRoot), icon: classes.selectIcon }}
    >
      <FilterCategoryMenu
        modeViewAr={modeViewAr}
        enableChangeViewMode={enableChangeViewMode}
        onFilter={onFilter}
        onChangeMode={onChangeModeView}
        onClearFilter={onClearFilter}
        selectedId={modeView}
      />
      {showData.length == 0 ? (
        <Box className={classes.noResultBox}>
          <Typography variant="body2" color="inherit" classes={{ body2: classes.noResult }}>
            {getLabel(LangConstant.TXT_RESULT_NOT_FOUND)}
          </Typography>
        </Box>
      ) : (
        <ComboCategoryMenuItem
          title={firstTabSelected ? modeViewAr[0]?.title : modeViewAr[1]?.title}
          data={showData}
          onCheck={onCheck}
          selectedValue={selectedValue}
          onSubmitAmount={onChangeAmount}
        />
      )}
    </Select>
  );
};

const getFilterArray = (value, search) => {
  return textNormalize(value.name).includes(search);
};

const getCheckData = (data, checkedValue) => {
  return checkedValue.includes(data.uid);
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    border: `solid 1px #d4d5d8`,
    borderRadius: "3px",
    "&.MuiInput-underline": {
      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&::before": {
          borderBottom: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
  },
  selectRoot: {
    height: "max-content",
    wordWrap: "anywhere",
    width: "100%",
    color: "#d4d5d8",
    padding: "10px 16px 10px",
    "&.MuiSelect-select": {
      paddingRight: 0,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  label: { marginLeft: 16, marginRight: 30 },
  chips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
  },
  disableChips: {
    maxHeight: 150,
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    paddingRight: 40,
    "& > *": { cursor: "default" },
  },
  chip: {
    height: 18,
    color: theme.palette.white,
    background: theme.palette.text.link,
    margin: 4,
  },
  deleteChip: {
    color: theme.palette.white,
  },
  noResultBox: {
    color: "#7f838c",
    margin: "10px 16px 0",
  },
}));

ComboCategoryMenu.propTypes = {
  customClasses: PropTypes.object,
  label: PropTypes.string,
  modeViewAr: PropTypes.array,
  serviceData: PropTypes.array.isRequired,
  productData: PropTypes.array.isRequired,
  enableChangeViewMode: PropTypes.bool,
  onChange: PropTypes.func,
  selectedArr: PropTypes.array,
  isDisable: PropTypes.bool,
};

ComboCategoryMenu.defaultProps = {
  customClasses: {},
  label: "",
  modeViewAr: [],
  serviceData: [],
  productData: [],
  enableChangeViewMode: true,
  onChange: () => {},
  selectedArr: [],
  isDisable: false,
};

export default memo(ComboCategoryMenu);
