import React, { memo } from "react";
import PropTypes from "prop-types";
import { AccountInfoDetail, ChangePassWord } from "components/";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

const AccountInfo = props => {
  const isAccountInfo = props.location.pathname === "/account";
  const dataUser = useSelector(state => state.userRedux.dataUser);
  return <Box>{isAccountInfo ? <AccountInfoDetail data={dataUser ? dataUser : {}} /> : <ChangePassWord />}</Box>;
};

AccountInfo.propTypes = {
  props: PropTypes.any,
};

AccountInfo.defaultProps = {};

export default memo(AccountInfo);
