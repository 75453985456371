import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import clsx from "clsx";
import MenuStatus from "components/MenuStatus";
import { LangConstant } from "const";
import { getCommonKey, getLabelArray } from "utils";
import { MultiLayerMenu } from "components";
import StaffAction from "redux/staff.redux";
import PlaceCategoryAction from "redux/place-category.redux";
import { STT_RESERVATION_WITH_INDEX } from "pages/MnReservation/components/StatusDialog/CustomStepper";

const SelectGroupFilterMenu = ({ onChangeFilter }) => {
  const placeArray = useSelector(state => state.placeCategoryRedux.placeArray, shallowEqual);
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);
  const classes = useStyles();
  const filterByCreatorRef = useRef(null);
  const staffMenuRef = useRef(null);

  const creatorFilterArray = [getLabel(LangConstant.TXT_CONSUMER), getLabel(LangConstant.TXT_SHOP)];
  const mappingStatusArray = [
    LangConstant.TXT_STATUS,
    ...STT_RESERVATION_WITH_INDEX.map(object => LangConstant.ARR_STATUS_FILTER[object.value]),
  ];

  const [creatorFilterMenu, setCreatorFilterMenu] = useState(null);
  const [creatorFilter, setCreatorFilter] = useState(getLabel(LangConstant.TXT_CREATOR));
  const [staffMenuAnchorEl, setStaffMenuAnchorEl] = useState(null);
  const [filterByStaff, setFilterByStaff] = useState({
    name: null,
    uid: null,
  });
  const [filterByPlace, setFilterByPlace] = useState({
    placeItemUid: null,
    placeCategoryUid: null,
  });

  const onChangeFilterByStatus = status => {
    if (status > 0) onChangeFilter({ status: STT_RESERVATION_WITH_INDEX[status - 1].value });
    else onChangeFilter({ status: status });
  };

  const onChangeFilterByStaff = staff => {
    setFilterByStaff({
      name: staff?.name || "",
      uid: staff?.uid || "",
    });
    onChangeFilter({
      staffUid: staff?.uid || null,
    });
  };

  const onChangeFilterByPlace = event => {
    if (event) {
      setFilterByPlace(event);
      if (event.items) {
        onChangeFilter({
          placeCategoryUid: event.uid,
          placeItemUid: null,
        });
      } else {
        onChangeFilter({
          placeCategoryUid: event.categoryUid,
          placeItemUid: event.uid,
        });
      }
    } else {
      onChangeFilter({
        placeCategoryUid: null,
        placeItemUid: null,
      });
      setFilterByPlace({
        placeItemUid: null,
        placeCategoryUid: null,
      });
    }
  };

  const onChangeFilterByCreator = event => {
    let valueToUpdate = event.target.value;
    if (valueToUpdate) {
      onChangeFilter({ isStaff: valueToUpdate - 1 });
      setCreatorFilter(creatorFilterArray[valueToUpdate - 1]);
    } else {
      onChangeFilter({ isStaff: null });
      setCreatorFilter(getLabel(LangConstant.TXT_CREATOR));
    }
    setCreatorFilterMenu(null);
  };

  useEffect(() => {
    dispatch(PlaceCategoryAction.getPlaceCategory({}));
    dispatch(StaffAction.requestStaff({ page: 0 }));
  }, []);

  return (
    <>
      <Box className={classes.selectGroup}>
        <Box className={classes.firstSelectGroup}>
          <MenuStatus
            classes={{ root: classes.menuStatus, item: classes.menuItemStatus }}
            categoryText={getLabel(getCommonKey(LangConstant.TXT_STATUS))}
            arrayItemsText={getLabelArray(mappingStatusArray, getLabel, true)}
            onChange={event => {
              onChangeFilterByStatus(event > 0 ? event : null);
            }}
            rowNum={mappingStatusArray.length - 1}
          />
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={filterByCreatorRef}
            onClick={() => setCreatorFilterMenu(filterByCreatorRef.current)}
            classes={{
              root: clsx(classes.filterByCreatorButton, classes.btnRoot),
              label: "medium-md-txt",
            }}
          >
            <Typography className={clsx("ellipsis", "medium-md-txt", classes.btnLabel)}>{creatorFilter} </Typography>
            <ArrowDropDown className={classes.icon} />
          </IconButton>
        </Box>
        <Box className={classes.secondSelectGroup}>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            ref={staffMenuRef}
            onClick={() => {
              setStaffMenuAnchorEl(staffMenuRef.current);
            }}
            classes={{
              root: clsx(classes.staffMenuBtn, classes.btnRoot),
              label: "medium-md-txt",
            }}
          >
            <Typography className={clsx("ellipsis", "medium-md-txt", classes.btnLabel)}>
              {filterByStaff.name || getLabel(getCommonKey(LangConstant.TXT_STAFF))}
            </Typography>
            <ArrowDropDown className={classes.icon} />
          </IconButton>
          <MultiLayerMenu
            listItemButton={classes.placeItemButton}
            itemWidth={273}
            array={placeArray}
            labelText={getLabel(getCommonKey(LangConstant.TXT_BOOK_PREVIEW_PLACE))}
            onChange={onChangeFilterByPlace}
            className={classes.placeMenu}
            value={filterByPlace}
            includeDefault={true}
          />
        </Box>
      </Box>
      <StaffMenu
        staffMenuAnchorEl={staffMenuAnchorEl}
        setStaffMenuAnchorEl={setStaffMenuAnchorEl}
        onChange={staff => onChangeFilterByStaff(staff)}
      />
      <Menu
        open={Boolean(creatorFilterMenu)}
        keepMounted
        anchorEl={creatorFilterMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setCreatorFilterMenu(null)}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        <MenuItem
          className={clsx("medium-md-txt", classes.menuItemRoot)}
          value={null}
          onClick={onChangeFilterByCreator}
        >
          {getLabel(LangConstant.TXT_CREATOR)}
        </MenuItem>
        <MenuItem className={clsx("medium-md-txt", classes.menuItemRoot)} value={1} onClick={onChangeFilterByCreator}>
          {creatorFilterArray[0]}
        </MenuItem>
        <MenuItem className={clsx("medium-md-txt", classes.menuItemRoot)} value={2} onClick={onChangeFilterByCreator}>
          {creatorFilterArray[1]}
        </MenuItem>
      </Menu>
    </>
  );
};

SelectGroupFilterMenu.propTypes = {
  onChangeFilter: PropTypes.func,
};

SelectGroupFilterMenu.defaultProps = {
  onChangeFilter: () => {},
};

export default SelectGroupFilterMenu;

const StaffMenu = ({ staffMenuAnchorEl, setStaffMenuAnchorEl, menuItemClass, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const user = useSelector(state => state.userRedux.dataUser);
  const staffNameArray = useSelector(state => state.staffRedux.staff);
  const DEFAULT_STAFF_FILTER = {
    name: null,
    uid: null,
  };

  const [staffMapArray, setStaffMapArray] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const onCloseStaffMenu = () => {
    setStaffMenuAnchorEl(null);
  };

  const onSelectStaff = staff => {
    onChange(staff);
    onCloseStaffMenu();
  };

  useEffect(() => {
    if (staffNameArray.length > 0) {
      let tmpMapArr = [...staffNameArray];
      staffNameArray.map(staff => {
        if (user?.id && staff?.userId === user?.id) {
          setCurrentUser(staff);
          tmpMapArr = tmpMapArr.filter(tmpStaff => tmpStaff !== staff);
        }
      });
      setStaffMapArray(tmpMapArr);
    }
  }, [staffNameArray]);

  return (
    <>
      <Menu
        keepMounted
        open={Boolean(staffMenuAnchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={staffMenuAnchorEl}
        getContentAnchorEl={null}
        onClose={onCloseStaffMenu}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        <MenuItem
          onClick={() => onSelectStaff(DEFAULT_STAFF_FILTER)}
          className={clsx("medium-md-txt", classes.menuItemRoot)}
        >
          {getLabel(LangConstant.TXT_STAFF)}
        </MenuItem>
        <MenuItem
          onClick={() => onSelectStaff(currentUser)}
          className={clsx("medium-md-txt", classes.currentUserItem, classes.menuItemRoot)}
        >
          {getLabel(LangConstant.TXT_ASSIGN_TO_MYSELF)}
        </MenuItem>
        {staffMapArray.map((element, index) => (
          <MenuItem
            key={index}
            className={clsx("medium-md-txt ellipsis", classes.menuItemRoot, menuItemClass)}
            onClick={() => onSelectStaff(element)}
          >
            {element?.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  filterMenuItem: {
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemStatus: {
    minWidth: 180,
  },
  menuStatus: {
    height: 30,
    width: 146,
    marginTop: 0,
    "&>button": {
      paddingRight: 0,
      "&>span": {
        marginLeft: 0,
      },
    },
  },

  filterByCreatorButton: {
    width: 146,
    "&>span": {
      justifyContent: "space-between",
    },
  },

  btnRoot: {
    display: "flex",
    justifyContent: "flex-start",
    minWidth: 130,
    color: theme.palette.grey[600],
    flexDirection: "row",
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[200]}`,
    height: 30,
  },
  btnLabel: {
    textAlign: "left",
  },
  icon: {
    right: 15,
    [theme.breakpoints.down("sm")]: {
      right: 8,
    },
    color: theme.palette.grey[500],
  },
  menuPaper: {
    marginTop: "2px",
    borderRadius: "0%",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: 160,
  },
  menuList: {
    paddingTop: `0px`,
    paddingBottom: `0px`,
    paddingRight: `0px !important`,
  },
  selectGroup: {
    display: "flex",
    margin: "0 36px",
    [theme.breakpoints.down(660)]: {
      marginLeft: 0,
      display: "grid",
      gridGap: 10,
    },
  },
  placeMenu: {
    height: 30,
    width: 273,
    "&>button>span": {
      marginLeft: 0,
    },
  },
  staffMenuBtn: {
    width: 273,
    "&>span": {
      justifyContent: "space-between",
    },
  },
  firstSelectGroup: {
    marginRight: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down(660)]: {
      marginTop: 10,
      marginLeft: 0,
      display: "grid",
      gridGap: 10,
    },
  },
  secondSelectGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down(550)]: {
      marginLeft: 0,
      display: "grid",
      gridGap: 10,
    },
  },
  currentUserItem: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white,
  },
  menuItemRoot: {
    minHeight: 40,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
}));
