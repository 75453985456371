import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const DialogLayout = props => {
  const { titleName, children, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="subtitle2" className={classes.textTitle} color={"inherit"}>
          {titleName}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} color={"inherit"}>
          <Close />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: "fit-content",
    borderRadius: "0%",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.white,
    padding: "0px 0px 0px 24px",
    backgroundColor: theme.palette.secondary.main,
  },
  textTitle: {
    marginLeft: "auto",
  },
  closeButton: {
    marginLeft: "auto",
  },
}));

DialogLayout.propTypes = {
  titleName: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

DialogLayout.defaultProps = {};

export default memo(DialogLayout);
