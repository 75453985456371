import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const get = () => createApiReservation().get(ApiConstant.GET_PLACE_CATEGORY, {});

export const create = data => createApiReservation().post(ApiConstant.POST_PLACE_CATEGORY, data);

export const update = data => createApiReservation().put(format(ApiConstant.PUT_PLACE_CATEGORY, data.uid), data);

export const remove = data => createApiReservation().delete(format(ApiConstant.DELETE_PLACE_CATEGORY, data.uid), {});

export const getPlaceDialogData = data => createApiReservation().get(ApiConstant.GET_PLACE_STATUS, data);
