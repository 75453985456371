import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import theme from "./theme/material";
import "react-image-crop/dist/ReactCrop.css";

import "./language";
import "./theme/styles";
import store from "./redux";
import { PathConstant } from "const";
import { AuthSocial, ForgotPassword, JoinGroup, NotFound, SelectShop, Login } from "pages";
import NotificationJoinGroup from "pages/JoinGroup/components/NotificationJoinGroup";
import { AuthenticationRoute } from "router";
import MainLayout from "layouts/MainLayout";

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route exact path={PathConstant.AUTH_SOCIAL} component={AuthSocial} />
              <Route exact path={PathConstant.LOGIN_GROUP_ADMIN} component={JoinGroup} />
              <Route component={NotificationJoinGroup} exact path={PathConstant.JOIN_GROUP_ADMIN} />
              <Route exact path={PathConstant.LOGIN} component={Login} />
              <Route exact path={PathConstant.FORGOT_PASSWORD} component={ForgotPassword} />
              <Route exact path={PathConstant.NOT_FOUND} component={NotFound} />
              <AuthenticationRoute component={SelectShop} exact path={PathConstant.SELECT_SHOP} />
              <Route path={PathConstant.ROOT} component={MainLayout} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}
