import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Paper } from "@material-ui/core";
import ReportHeader from "./ReportHeader";
import clsx from "clsx";

const ReportLayout = ({ children, ...otherProps }) => {
  const classes = useStyles();

  const [isHidden, setIsHidden] = useState(false);

  return (
    <Paper className={classes.root}>
      <ReportHeader onShow={() => setIsHidden(!isHidden)} {...otherProps} />
      <Box className={clsx(isHidden && classes.contentInvisible)}>{children}</Box>
    </Paper>
  );
};

ReportLayout.propTypes = {
  title: PropTypes.string,
  defaultQueryParams: PropTypes.object,

  onChangeQueryParams: PropTypes.func,
};
export default ReportLayout;

const useStyles = makeStyles({
  root: {
    position: "relative",
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 32,
  },
  contentInvisible: {
    display: "none",
  },
});
