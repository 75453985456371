import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, useTheme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";

const WeekHeader = props => {
  const { t: getLabel } = useTranslation();
  const weekDays = [
    getLabel(LangConstant.TXT_MON),
    getLabel(LangConstant.TXT_TUE),
    getLabel(LangConstant.TXT_WED),
    getLabel(LangConstant.TXT_THU),
    getLabel(LangConstant.TXT_FRI),
    getLabel(LangConstant.TXT_SAT),
    getLabel(LangConstant.TXT_SUN),
  ];

  const { selectedDate } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [isTodayInWeek, setIsTodayInWeek] = useState(false);
  let selectedWeekDay = selectedDate ? (selectedDate.getDay() === 0 ? 6 : selectedDate.getDay() - 1) : null;
  const sizeScreen = screen.width;
  const [widthColumn, setWidthColumn] = useState(0);

  useEffect(() => {
    if (selectedDate) {
      let today = new Date();
      setIsTodayInWeek(today.getDate() === selectedDate.getDate());
    }
    if (sizeScreen) {
      if (sizeScreen >= AppConstant.SIZE_SCREEN_XL) {
        setWidthColumn(parseInt((sizeScreen - 240) / DEFAULT_MAX_ROW));
      } else {
        setWidthColumn(WIDTH_COLUMN);
      }
    }
  }, [selectedDate]);

  return (
    <Box classes={{ root: classes.weekHeader }} borderBottom={1}>
      {weekDays.map((value, index) => (
        <Box
          key={value}
          width={widthColumn}
          textAlign="center"
          py="10px"
          className="medium-md-txt"
          color={index >= 5 ? theme.palette.primary.main : theme.palette.text.primary}
          classes={{
            root: selectedWeekDay === index && isTodayInWeek && classes.selectedWeekDay,
          }}
        >
          {value}
        </Box>
      ))}
    </Box>
  );
};

WeekHeader.propTypes = {
  selectedDate: PropTypes.object,
};

WeekHeader.defaultProps = {
  selectedDate: null,
};

const HEIGHT_DAY_HEADER = 42;
const WIDTH_COLUMN = 175;
const DEFAULT_MAX_ROW = 7;

const useStyles = makeStyles(theme => ({
  weekHeader: {
    height: HEIGHT_DAY_HEADER,
    display: "inline-flex",
    backgroundColor: theme.palette.white,
    borderBottomColor: theme.palette.grey[200],
  },
  selectedWeekDay: {
    textDecoration: "underline",
    color: theme.palette.reservation.active,
  },
}));

export default memo(WeekHeader);
