import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Grid, Typography, Button } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import { format as formatDate, set as setTimeToDate, isToday } from "date-fns";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { Line, TimeAndDatePicker } from "../componentDialog";
import {
  convertTimeStampToDate,
  fromDateAndHourToDateObject,
  getEndOfDay,
  getStartOfDay,
  getTimestamp,
  removeSecond,
} from "utils/date";
import { DialogVerify, Alert, MultiSelectMenu } from "components";
import { restructurePlaceArray, restructureStaffArray } from "../../AddReservation";
import { STT_RESERVATION_WITH_INDEX } from "../../StatusDialog/CustomStepper";
import WaitingConsumerAction from "redux/waiting-consumer.redux";
import StaffAction from "redux/staff.redux";
import PlaceItemAction from "redux/place-item.redux";

const TabReservation = props => {
  const { value, index, detail, setDetail, isEditing, isCopying, setIsEdited, originStatus, ...otherProps } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();

  let isShow = value === index;

  const dataReservation = useSelector(state => state.reservationRedux, shallowEqual);
  const placeArray = useSelector(state => state.placeCategoryRedux.placeArray, shallowEqual);
  const staffData = useSelector(state => state.staffRedux.staff);
  const { placeBusyStatus } = useSelector(state => state.placeItemRedux, shallowEqual);
  const staffBusyStatus = useSelector(state => state.staffRedux.staffBusyStatus, shallowEqual);
  const { isPostSuccess } = useSelector(state => state.waitingConsumerRedux, shallowEqual);

  const { reservationSetting } = dataReservation;
  let isSettingTotal = reservationSetting && reservationSetting[4] && reservationSetting[4].publish === 1;
  let isBookingDate = isToday(convertTimeStampToDate(detail.startTime)) && detail.startTime > getTimestamp(new Date());
  let isEnableWaitingBtn = isBookingDate && ENABLE_WAITING_BTN.includes(detail.status);

  const [placeArrayWithBusyStatus, setPlaceArrayWithBusyStatus] = useState(placeArray ? placeArray : []);
  const [staffArrayWithBusyStatus, setStaffArrayWithBusyStatus] = useState(staffData ? staffData : []);
  const [isConfirmMovingIntoWaitingList, setIsConfirmMovingIntoWaitingList] = useState(false);
  const [isDisableWaitingButton, setIsDisableWaitingButton] = useState(false);
  const [isShowSuccessAlert, setIsShowSuccessAlert] = useState(false);

  const onMoveIntoWaitingList = () => {
    dispatch(
      WaitingConsumerAction.postWaitingConsumer({
        reservationUid: detail.uid,
        name: detail.name,
        phoneNumber: detail.phoneNumber,
        total: detail.consumer?.total,
        child: detail.consumer?.child,
        man: detail.consumer?.man,
      }),
    );
    setIsConfirmMovingIntoWaitingList(false);
  };

  const onChangeDetail = event => {
    setIsEdited(true);
    setDetail({ ...detail, [event.target.name]: event.target.value });
  };

  const onChangeInternalNote = event => {
    setIsEdited(true);
    setDetail({ ...detail, internalNote: { [event.target.name]: event.target.value } });
  };

  const onChangeDropdown = status => {
    let presentTime = getTimestamp(removeSecond(new Date()));
    let currentStatusStep = STT_RESERVATION_WITH_INDEX.findIndex(entry => entry.value === originStatus);
    let incomingStep = STT_RESERVATION_WITH_INDEX.findIndex(entry => entry.value === status);
    if (incomingStep >= currentStatusStep) {
      setIsEdited(true);
      let dataDetail = { ...detail, status: status };
      setDetail(dataDetail);
      if (status === AppConstant.STT_RESERVATION.seated || status === AppConstant.STT_RESERVATION.active) {
        setDetail({
          ...dataDetail,
          realityStartTime: dataDetail.realityStartTime || presentTime,
        });
      } else if (status === AppConstant.STT_RESERVATION.finished) {
        setDetail({
          ...dataDetail,
          realityEndTime: dataDetail.realityEndTime || presentTime,
        });
      }
    }
  };

  const onChangeSelectedPlaces = selectedList => {
    let placeArraySelected = [];
    selectedList.forEach(element => {
      placeArrayWithBusyStatus.forEach(ele => {
        if (ele.items.length > 0) {
          ele.items.forEach(item => {
            if (item.uid === element) {
              placeArraySelected.push(restructurePlaceArray(item, true));
            }
          });
        } else if (ele.uid === element) {
          placeArraySelected.push(restructurePlaceArray(ele));
        }
      });
    });
    setIsEdited(true);
    setDetail({ ...detail, assign: placeArraySelected });
  };

  const onChangeSelectedStaff = selectedList => {
    let staffArraySelected = [];
    selectedList.forEach(element => {
      staffArrayWithBusyStatus.forEach(ele => {
        if (ele.uid === element) {
          staffArraySelected.push(restructureStaffArray(ele));
        }
      });
    });
    setIsEdited(true);
    setDetail({ ...detail, staff: staffArraySelected });
  };

  const onChangeStartDate = date => {
    let dateChoose = formatDate(date, AppConstant.FM_DD_MM_YYYY);
    let startTime = getTimestamp(fromDateAndHourToDateObject(date, detail.time));
    setIsEdited(true);
    setDetail({ ...detail, date: dateChoose, startTime: startTime });
  };

  const onChangeStartTime = (hour, min) => {
    let timeObject = createTimeOpt(hour, min);
    let newDate = setTimeToDate(new Date(detail.startTime * 1000), timeObject);
    let timeStampDate = getTimestamp(newDate);
    let newTime = formatDate(new Date(timeStampDate * 1000), AppConstant.FM_HH_MM);
    setDetail({ ...detail, time: newTime, startTime: timeStampDate });
    setIsEdited(true);
  };

  const onChangeEndDate = date => {
    let dateChoose = formatDate(date, AppConstant.FM_DD_MM_YYYY);
    let endTime = getTimestamp(fromDateAndHourToDateObject(endTime, detail.timeEnd));
    setIsEdited(true);
    setDetail({ ...detail, dateEnd: dateChoose, endTime: endTime });
  };

  const onChangeEndTime = (hour, min) => {
    let timeObject = createTimeOpt(hour, min);
    let newDate = setTimeToDate(new Date(detail.endTime * 1000), timeObject);
    let timeStampDate = getTimestamp(newDate);
    let newTime = formatDate(new Date(timeStampDate * 1000), AppConstant.FM_HH_MM);
    setDetail({ ...detail, timeEnd: newTime, endTime: timeStampDate });
    setIsEdited(true);
  };

  useEffect(() => {
    if (detail.startTime) {
      let selectedDate = convertTimeStampToDate(detail.startTime);
      dispatch(
        StaffAction.getStaffBusyStatus({
          start: getStartOfDay(selectedDate),
          end: getEndOfDay(selectedDate),
        }),
      );
      dispatch(
        PlaceItemAction.getPlaceBusyStatus({
          start: getStartOfDay(selectedDate),
          end: getEndOfDay(selectedDate),
        }),
      );
    }
  }, [detail.startTime]);

  useEffect(() => {
    if (detail.isWaitingConsumer) {
      setIsDisableWaitingButton(detail.isWaitingConsumer === isExitInWaitingList);
    }
  }, [detail.isWaitingConsumer]);

  useEffect(() => {
    if (staffBusyStatus.length > 0) {
      let staffBusy = staffBusyStatus[0];
      setStaffArrayWithBusyStatus(staffBusy.staff || []);
    }
  }, [staffBusyStatus]);

  useEffect(() => {
    if (placeBusyStatus.length > 0) {
      let placeBusy = placeBusyStatus[0];
      setPlaceArrayWithBusyStatus(placeBusy.placeCategory || []);
    }
  }, [placeBusyStatus]);

  useEffect(() => {
    if (isPostSuccess) {
      setIsDisableWaitingButton(true);
      setIsShowSuccessAlert(true);
      dispatch(WaitingConsumerAction.resetWaitingConsumer());
    }
  }, [isPostSuccess]);

  let isContainPreOrder = detail?.orderDetailsList.length > 0;

  return (
    <Box className={isShow ? classes.root : classes.rootHidden} {...otherProps}>
      {isShow && (
        <Grid container spacing={1} className={classes.root}>
          {isEnableWaitingBtn && !isEditing && !isCopying && (
            <Box className={classes.waitingCustomerBox}>
              <Button
                classes={{
                  root: classes.waitingCustomerButton,
                  startIcon: classes.iconArrowForward,
                }}
                onClick={() => setIsConfirmMovingIntoWaitingList(true)}
                disabled={isDisableWaitingButton}
                startIcon={<ArrowForward fontSize="small" />}
              >
                <Typography className={clsx(classes.waitingListText, "semiBold-sm-txt")}>
                  {getLabel(LangConstant.TXT_WAITING_LIST)}
                </Typography>
              </Button>
            </Box>
          )}
          {isConfirmMovingIntoWaitingList && (
            <DialogVerify
              isShow={true}
              title={StringFormat(getLabel(LangConstant.MSG_MOVE_INTO_WAITING_LIST_TITLE), detail.code)}
              content={StringFormat(getLabel(LangConstant.MSG_MOVE_INTO_WAITING_LIST_CONTENT), detail.code)}
              onCancel={() => setIsConfirmMovingIntoWaitingList(false)}
              onConfirm={onMoveIntoWaitingList}
            />
          )}
          <Alert
            isShow={isShowSuccessAlert}
            onClose={() => setIsShowSuccessAlert(false)}
            type="success"
            message={StringFormat(getLabel(LangConstant.MSG_MOVE_INTO_WAITING_LIST_SUCCESS), detail.code)}
          />
          <Line
            title={getLabel(LangConstant.L_BOOKING_CODE)}
            disable={isEditing || isCopying}
            content={!isCopying ? detail && detail.code && detail.code : ""}
            isEditing={isEditing}
            isCopying={isCopying}
            onChange={onChangeDetail}
            name="code"
          />
          {!isSettingTotal && (
            <Line
              title={`${getLabel(LangConstant.L_BOOKING_TOTAL)}:`}
              content={detail.totalConsumer < 0 ? 1 : detail.totalConsumer}
              isEditing={isEditing}
              isCopying={isCopying}
              onChange={onChangeDetail}
              name="totalConsumer"
            />
          )}
          {isSettingTotal && (
            <>
              <Line
                title={getLabel(LangConstant.L_BOOKING_PEOPLE)}
                content={detail.man || ""}
                isEditing={isEditing}
                isCopying={isCopying}
                onChange={onChangeDetail}
                name="man"
              />
              <Line
                title={getLabel(LangConstant.L_BOOKING_CHILDREN)}
                content={detail.child || ""}
                isEditing={isEditing}
                isCopying={isCopying}
                onChange={onChangeDetail}
                name="child"
              />
            </>
          )}
          <TimeAndDatePicker
            title={`${getLabel(LangConstant.TXT_REALITY_START_TIME)}:`}
            isEditing={isEditing || isCopying}
            onChangeDatePicker={onChangeStartDate}
            onChangeTimePicker={onChangeStartTime}
            data={{
              timestamp: detail.startTime,
              date: detail.date || "",
              time: detail.time || "",
            }}
          />
          {detail?.endTime && (
            <TimeAndDatePicker
              title={`${getLabel(LangConstant.TXT_REALITY_END_TIME)}:`}
              isEditing={isEditing || isCopying}
              onChangeDatePicker={onChangeEndDate}
              onChangeTimePicker={onChangeEndTime}
              data={{
                timestamp: detail.startTime,
                date: detail.date || "",
                time: detail.time || "",
              }}
            />
          )}
          <Box className={classes.multiSelectBox}>
            <Typography className={classes.titleLine}>{getLabel(LangConstant.L_BOOKING_ASSIGN)}</Typography>
            {isEditing || isCopying ? (
              <MultiSelectMenu
                selectedArr={detail.assign.map(value => value.itemUid || value.categoryUid)}
                data={placeArrayWithBusyStatus}
                onChange={onChangeSelectedPlaces}
                itemsKey={ITEM_ARRAY_KEY}
                customClasses={{ root: classes.multiSelectMenuRoot }}
              />
            ) : (
              <Box>
                {detail?.assign.map((value, index) => (
                  <Typography className={classes.contentLine} key={index}>
                    {value.itemName || value.categoryName}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          <Box className={classes.multiSelectBox}>
            <Typography className={classes.titleLine}>{`${getLabel(LangConstant.L_STAFF)}:`}</Typography>
            {isEditing || isCopying ? (
              <MultiSelectMenu
                selectedArr={detail.staff.map(value => value.staffUid)}
                data={staffArrayWithBusyStatus}
                onChange={onChangeSelectedStaff}
                itemsKey={ITEM_ARRAY_KEY}
                customClasses={{ root: classes.multiSelectMenuRoot }}
              />
            ) : (
              <Box className={classes.singlePlaceLine}>
                {detail?.staff.map((value, index) => (
                  <Typography className={classes.contentLine} key={index}>
                    {value.staffName}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          <Line
            title={getLabel(LangConstant.L_BOOKING_STATUS)}
            content={detail.status}
            isStatus={true}
            isEditing={isEditing}
            isCopying={isCopying}
            isHasPreOrder={isContainPreOrder}
            onChange={onChangeDropdown}
          />
          <Line
            title={getLabel(LangConstant.L_BOOKING_NOTE)}
            content={detail.note || getLabel(LangConstant.TXT_NO_TEXT_NOTE)}
            isEditing={isEditing}
            isCopying={isCopying}
            onChange={onChangeDetail}
            name="note"
          />
          {detail.internalNote && detail.internalNote.content && (
            <Line
              title={getLabel(LangConstant.L_INTERNAL_NOTE)}
              content={detail.internalNote.content || ""}
              isEditing={isEditing}
              isCopying={isCopying}
              onChange={onChangeInternalNote}
              name="content"
            />
          )}
        </Grid>
      )}
    </Box>
  );
};

const ENABLE_WAITING_BTN = [AppConstant.STT_RESERVATION.confirm, AppConstant.STT_RESERVATION.seated];

const createTimeOpt = (hours, minutes) => ({
  hours: hours,
  minutes: minutes,
  seconds: 0,
});

export const isExitInWaitingList = 1;
export const ITEM_ARRAY_KEY = "items";

export default memo(TabReservation);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    display: "block",
    maxWidth: 550,
  },
  rootHidden: {
    display: "none",
  },
  multiSelectBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 5,
  },
  titleLine: {
    minWidth: "40%",
    height: 22,
    fontSize: 13,
    marginTop: 4,
  },
  multiSelectMenuRoot: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    width: "60%",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
  },
  contentLine: {
    padding: "5px 0",
    marginRight: "auto",
    fontSize: 13,
  },
  waitingCustomerBox: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    top: 106,
    right: 24,
  },
  waitingCustomerButton: {
    minHeight: 28,
    width: 106,
    padding: "4px 12px 4px 8px",
    borderRadius: 2,
    backgroundColor: "#b8afff",
    "&:hover": {
      backgroundColor: "#9f92fe",
    },
    "&:disabled": {
      opacity: 0.6,
      color: theme.palette.white,
    },
  },
  waitingListText: {
    textTransform: "none",
    color: theme.palette.white,
  },
  iconArrowForward: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.white,
  },
}));
