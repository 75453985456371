import { call, put } from "redux-saga/effects";
import { ApiConstant } from "../const";
import MnNewsAction from "../redux/mn-news.redux";
import { MnNewsService } from "../services";

export function* requestGetNews(action) {
  try {
    const { data } = action;
    let response = yield call(MnNewsService.get, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = validateResponseArray(response.data.data);
      yield put(MnNewsAction.getNewsSuccess(responseData));
    } else {
      yield put(MnNewsAction.getNewsFailure(response.data));
    }
  } catch (error) {
    yield put(MnNewsAction.getNewsFailure(error));
  }
}

export function* requestCreateNews(action) {
  try {
    const { data } = action;
    let response = yield call(MnNewsService.create, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(MnNewsAction.createNewsSuccess(responseData));
    } else {
      yield put(MnNewsAction.createNewsFailure(response.data));
    }
  } catch (error) {
    yield put(MnNewsAction.createNewsFailure(error));
  }
}

export function* requestUpdateNews(action) {
  try {
    const { data } = action;
    let response = yield call(MnNewsService.update, data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(MnNewsAction.updateNewsSuccess(responseData));
    } else {
      yield put(MnNewsAction.updateNewsFailure(response.data));
    }
  } catch (error) {
    yield put(MnNewsAction.updateNewsFailure(error));
  }
}

export function* requestRemoveNews(action) {
  try {
    const { data } = action;
    let response = yield call(MnNewsService.remove, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnNewsAction.removeNewsSuccess(data));
    } else {
      yield put(MnNewsAction.removeNewsFailure(response.data));
    }
  } catch (error) {
    yield put(MnNewsAction.removeNewsFailure(error));
  }
}

export function* requestUploadPostImage(action) {
  try {
    const { data } = action;
    let formData = new FormData();
    for (var i = 0; i < data.file.length; i++) {
      formData.append("file", data.file[i]);
    }
    formData.append("type", "image");
    let response = yield call(MnNewsService.upload, formData);
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnNewsAction.uploadPostImageSuccess(responseData));
    } else {
      yield put(MnNewsAction.uploadPostImageFailure(response.data));
    }
  } catch (error) {
    yield put(MnNewsAction.uploadPostImageFailure(error));
  }
}

const validateNews = item => {
  const validatedNews = {
    content: item.content ? item.content : "",
    uid: item.uid ? item.uid : "",
    publish: item.publish ? item.publish : 0,
    image: item.image ? item.image : [],
    created: item.created ? new Date(item.created * 1000) : 0,
    facebook_post_id: item.facebook_post_id ? item.facebook_post_id : "",
  };
  return validatedNews;
};
const validateResponseArray = responseArray => {
  const validatedArray = responseArray
    ? responseArray.map(value => {
        return validateNews(value);
      })
    : [];
  return validatedArray;
};
