import { ApiConstant } from "../const";
import { createApiReservation, createApiRegistration } from "../api";

export const getConsumer = data => createApiReservation().get(ApiConstant.GET_CONSUMER, data);

export const patchInvitationConsumer = data =>
  createApiRegistration().patch(ApiConstant.PATCH_INVITATION_CONSUMER, data);

export const getEmailConsumerQuantity = () => createApiRegistration().get(ApiConstant.GET_EMAIL_CONSUMER_QUANTITY, {});

export const getInvitationFacebook = data => createApiRegistration().get(ApiConstant.GET_INVITATION_FACEBOOK, data);

export const patchInvitationFacebook = data =>
  createApiRegistration().patch(ApiConstant.PATCH_INVITATION_FACEBOOK, data);

export const getNumberBookingByPhoneNumber = data => {
  return createApiRegistration().get(ApiConstant.GET_RESERVATION_COUNT, data);
};

export const postConsumerList = data => createApiReservation().post(ApiConstant.POST_CONSUMER_LIST, data);
