import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  DialogTitle,
  IconButton,
  OutlinedInput,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { getCommonKey } from "utils";
import { DropdownMenu } from "components/mn-master-product";

const DialogAddAmount = ({ isShow, onClose, onChange, onSubmit, data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const [dataDropdownOne, setDataDropdownOne] = useState([]);
  const [dataDropdownTwo, setDataDropdownTwo] = useState([]);
  const [indexOneClassification, setIndexOneClassification] = useState(0);
  const [dataDialog, setDataDialog] = useState({ uid: "", name: "", amount: "" });
  const [indexTwoClassification, setIndexTwoClassification] = useState("");

  const onChangeDropdownOne = index => {
    if (Boolean(data.groupModel.modelList[0].item)) {
      let item = data.groupModel.modelList[index].item;
      let newData = {
        ...dataDialog,
        uid: item.uid,
        name: item.name,
      };
      setDataDialog(newData);
      onChange(newData);
    } else {
      setIndexOneClassification(index);
    }
  };

  const onChangeDropdownTwo = index => {
    let item = data.groupModel.modelList[indexOneClassification].groupModel.modelList[index].item;
    let newData = {
      ...dataDialog,
      uid: item.uid,
      name: item.name,
    };
    setIndexTwoClassification(index);
    setDataDialog(newData);
    onChange(newData);
  };

  const onChangeAmount = value => {
    let newData = { ...dataDialog };
    newData = {
      ...newData,
      amount: value < AppConstant.AMOUNT_THRESHOLD ? value : "",
    };
    setDataDialog(newData);
    onChange(newData);
  };

  useEffect(() => {
    if (Boolean(data.groupModel)) {
      let newDataOneClassification = [];
      data.groupModel.modelList.map((dataList, index) => {
        newDataOneClassification.push({ value: index, name: dataList.name });
      });
      setDataDropdownOne(newDataOneClassification);
      if (!Boolean(data.groupModel.modelList[0].item)) {
        let newDataTwoClassification = [];
        data.groupModel.modelList[0].groupModel.modelList.map((dataItem, index) => {
          newDataTwoClassification.push({ value: index, name: dataItem.name });
        });
        setDataDropdownTwo(newDataTwoClassification);
      }
    }
  }, [data]);

  useEffect(() => {
    if (Boolean(indexTwoClassification)) {
      let item = data.groupModel.modelList[indexOneClassification].groupModel.modelList[indexTwoClassification].item;
      let newData = {
        ...dataDialog,
        uid: item.uid,
        name: item.name,
      };
      setDataDialog(newData);
      onChange(newData);
    }
  }, [indexOneClassification]);

  return (
    <Dialog open={isShow} classes={{ paperScrollPaper: classes.dialogContainer }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.textDialogTitle}>{data.name} </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {Boolean(data.groupModel) && (
          <>
            <Box className={classes.boxGroup}>
              <Box className={classes.boxTypography}>
                <Typography className={classes.typographyContent}>{data.groupModel.name}</Typography>
              </Box>
              <DropdownMenu
                onChange={onChangeDropdownOne}
                dataChange={dataDropdownOne}
                dropdownClass={classes.dropdownClass}
                placeholder={getLabel(LangConstant.FM_ADD_NAME, { name: data.groupModel.name })}
              />
            </Box>
            {!Boolean(data.groupModel.modelList[0].item) && (
              <Box className={classes.boxGroup}>
                <Box className={classes.boxTypography}>
                  <Typography className={classes.typographyContent}>
                    {data.groupModel.modelList[0].groupModel.name}
                  </Typography>
                </Box>
                <DropdownMenu
                  onChange={onChangeDropdownTwo}
                  dataChange={dataDropdownTwo}
                  dropdownClass={classes.dropdownClass}
                  placeholder={getLabel(LangConstant.FM_ADD_NAME, {
                    name: data.groupModel.modelList[0].groupModel.name,
                  })}
                />
              </Box>
            )}
          </>
        )}
        <Box className={classes.boxGroup}>
          <Box className={classes.boxTypography}>
            <Typography className={classes.typographyContent}>{getLabel(LangConstant.TXT_ADD_AMOUNT)}</Typography>
          </Box>
          <OutlinedInput
            value={dataDialog.amount}
            classes={{ root: classes.contentLineEdit, input: clsx(classes.inputEdit, "semiBold-md-txt") }}
            required
            placeholder={getLabel(getCommonKey(LangConstant.L_PLACE_AMOUNT))}
            onChange={event => {
              let value = parseInt(event.target.value);
              onChangeAmount(value);
            }}
            inputProps={{
              name: "amount",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button type="submit" color="primary" variant="contained" onClick={onSubmit} className={classes.buttonSubmit}>
          {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 330,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 0,
    "@media only screen and (max-width: 515px)": {
      width: "100%",
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      height: "100%",
      marginBottom: 0,
      maxHeight: "none",
    },
  },
  dialogTitle: {
    position: "fixed",
    width: 330,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 60,
    padding: "0 24px",
    zIndex: 100,
    "@media only screen and (max-width: 515px)": {
      width: "100%",
    },
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 20,
  },
  closeButton: {
    position: "absolute",
    right: 14,
    top: 18,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    marginTop: 34,
    padding: "26px 24px 17px",
    "&:first-child": {
      paddingTop: 26,
      "@media only screen and (max-width: 515px)": {
        padding: "26px 10px 20px",
        borderBottom: "none",
      },
    },
  },
  typographyContent: {
    width: 175,
    fontSize: 14,
    fontWeight: 600,
    paddingRight: 20,
    color: "#3e4045",
    height: 17,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  contentLineEdit: {
    width: 151,
    height: 41,
    fontSize: 13,
    padding: "9px 16px",
    marginRight: 0,
  },
  inputEdit: {
    padding: 0,
  },
  dialogActions: {
    display: "block",
    textAlign: "center",
    padding: "16px",
  },
  buttonSubmit: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: "0 24px 0",
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
  dropdownClass: {
    minWidth: 151,
  },
  boxGroup: { marginTop: 18, marginBottom: 16, width: "100%", display: "flex" },
  boxTypography: {
    height: 30,
    maxWidth: 125,
    display: "flex",
    alignItems: "center",
  },
}));

DialogAddAmount.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default memo(DialogAddAmount);
