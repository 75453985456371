import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getGroupAdmin: ["data"],
  joinGroupAdmin: ["data"],

  groupAdminSuccess: ["data"],
  groupAdminFailure: ["data"],
  groupAdminSet: ["data"],
});

export const GroupAdminTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  isSuccess: false,
  isJoinGroupSuccess: false,
  isJoinGroupAdmin: false,

  error: null,
  dataGroup: {},
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, error: action.error };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_GROUP_ADMIN]: request,
  [Types.JOIN_GROUP_ADMIN]: request,
  [Types.GROUP_ADMIN_SET]: success,

  [Types.GROUP_ADMIN_SUCCESS]: success,
  [Types.GROUP_ADMIN_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
