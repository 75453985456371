import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment, OutlinedInput } from "@material-ui/core";
const FormInput = ({ icon, placeholder, value, onChange, className, ...otherProps }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const onTyping = event => {
    if (Boolean(onChange)) {
      onChange(event);
    }
    setInputValue(event.target.value);
  };
  return (
    <OutlinedInput
      fullWidth
      placeholder={placeholder}
      type="text"
      value={inputValue}
      onChange={onTyping}
      classes={{
        input: classes.input,
        root: `${classes.root} ${className}`,
      }}
      className="regular-md-txt"
      startAdornment={
        <InputAdornment position="start" className={classes.icon}>
          {icon}
        </InputAdornment>
      }
      {...otherProps}
    />
  );
};
const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.grey[400],
  },
  input: {
    height: "unset",
    padding: "0 20px",
    color: theme.palette.text.primary,
    "&::placeholder": {
      opacity: 1,
      color: theme.palette.grey[400],
    },
  },
  icon: {
    minWidth: 40,
    width: 40,
    height: "100%",
    maxHeight: "100%",
    minHeight: 40,
    background: "#EBECED",
    justifyContent: "center",
    borderRight: "solid 0.5px #CECFD2",
    marginRight: 0,
    color: theme.palette.grey[600],
  },
}));
FormInput.propTypes = {
  icon: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
};
FormInput.defaultProps = { placeholder: "", value: "", className: "", type: "" };
export default memo(FormInput);
