export const PAYMENT_METHOD = {
  cash: 1,
  internetBanking: 2,
};

export const SHIPPING_METHOD = {
  shipping: 3,
  takeAway: 4,
};

export const TAX_INCLUSIVE_F = {
  noTax: 0,
  tax: 1,
};
