import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import PackageInfo from "./PackageInfo";
import QuantityInfo from "./quantity-info";
import AccountInfo from "./AccountInfo";

const Package = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{getLabel(LangConstant.TXT_MY_PLAN_PACKAGE)}</Typography>
      <Box className={classes.planPackageBox}>
        <PackageInfo />
        <QuantityInfo />
      </Box>
      <Typography className={classes.title}>{getLabel(LangConstant.TXT_MY_ACCOUNT)}</Typography>
      <AccountInfo />
    </Box>
  );
};

export default memo(Package);

const useStyles = makeStyles(theme => ({
  root: {
    flexBasis: "50%",
    [theme.breakpoints.down(1250)]: {
      flexBasis: "100%",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    color: "#3e4045",
    marginBottom: 24,
  },
  planPackageBox: {
    display: "flex",
    marginBottom: 48,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));
