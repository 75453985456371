import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Button, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { convertHex2rgba } from "utils";

const StatusButton = ({ color, isActive, isNext, disabled, children, onClick, className }) => {
  let StatusColor = convertHex2rgba(color);
  let StatusBackground = convertHex2rgba(color, 0.3);

  let statusStyle = createColorStyle(StatusColor, isActive, disabled, StatusBackground);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let nextStatus = {
    borderWidth: 3,
  };
  if (isNext) {
    statusStyle = { ...statusStyle, ...nextStatus };
  }

  const classes = useStyles({
    statusStyle,
    StatusColor,
    isMobile: isMobile,
  });

  return (
    <Box className={classes.root}>
      <Button
        size="small"
        disabled={disabled}
        onClick={onClick}
        classes={{ root: clsx(classes.btnRoot, className), disabled: classes.disabled }}
      >
        {children}
      </Button>
    </Box>
  );
};

StatusButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.string,
  isActive: PropTypes.bool,
  isNext: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.object,
};
StatusButton.defaultProps = { disabled: false };

export default memo(StatusButton);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    position: "unset",
    marginTop: 0,
  },
  btnRoot: props => ({
    borderRadius: 2,
    fontWeight: "bold",
    borderWidth: "1px solid",
    padding: "0 10px",
    minWidth: 109,
    minHeight: 35,
    fontSize: 10,
    ...props.statusStyle,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 22,
      marginRight: 16,
    },
  }),
  disabled: props => ({ opacity: 0.3, color: `${props.StatusColor} !important` }),
}));

const createColorStyle = (color, isActive, isDisabled, StatusBackground) => {
  let activeStyle = {
    background: isActive ? color : StatusBackground,
    color: "white",
    border: `solid 1px ${color}`,
  };
  let jsonStyle = {
    color: color,
    border: `solid 1px ${color}`,
    background: StatusBackground,
    "&:hover": activeStyle,
  };
  if (isActive || isDisabled) {
    jsonStyle = { ...jsonStyle, ...activeStyle };
  }
  return jsonStyle;
};
