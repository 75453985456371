import React, { memo, useEffect, useState } from "react";
import { makeStyles, Box, Button, Menu, MenuItem } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { MenuStatus, SearchBar, PopupInvite } from "../index";
import { LangConstant } from "../../../../const";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StaffTableAction from "../../../../redux/staff.redux";
import CreateStaff from "../StaffDetail/CreateStaff";
const OptionBar = props => {
  const { showTime, showPosition, setPage, searchForm, setSearchForm } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const isCreateSuccess = useSelector(state => state.staffRedux.isCreateSuccess);

  const onMenuRole = status => {
    let condition = status !== null ? status + 2 : status;
    let newSearchForm = { ...searchForm, role: condition, page: 0 };
    setSearchForm(newSearchForm);
    setPage(0);
    dispatch(StaffTableAction.requestStaffTable(newSearchForm));
  };

  const onMenuTime = status => {
    let condition = status !== null ? showTime[status].uid : status;
    let newSearchForm = { ...searchForm, workingShift: condition, page: 0 };
    setSearchForm(newSearchForm);
    setPage(0);
    dispatch(StaffTableAction.requestStaffTable(newSearchForm));
  };

  const onMenuPosition = status => {
    let condition = status !== null ? showPosition[status].uid : status;
    let newSearchForm = { ...searchForm, staffPosition: condition, page: 0 };
    setSearchForm(newSearchForm);
    setPage(0);
    dispatch(StaffTableAction.requestStaffTable(newSearchForm));
  };

  const onMenuStatus = status => {
    let condition = status !== null ? status + 1 : status;
    let newSearchForm = { ...searchForm, state: condition, page: 0 };
    setSearchForm(newSearchForm);
    setPage(0);
    dispatch(StaffTableAction.requestStaffTable(newSearchForm));
  };

  const onMenuAccount = status => {
    let condition = status !== null ? Number(!Boolean(status)) : status;
    let newSearchForm = { ...searchForm, isUser: condition, page: 0 };
    setSearchForm(newSearchForm);
    setPage(0);
    dispatch(StaffTableAction.requestStaffTable(newSearchForm));
  };

  const onSearch = name => {
    let newSearchForm = { ...searchForm, filter: name, page: 0 };
    setSearchForm(newSearchForm);
    setPage(0);
    dispatch(StaffTableAction.requestStaffTable(newSearchForm));
  };

  const onClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onInvite = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const onAdd = () => {
    setAnchorEl(null);
    setOpenAdd(true);
  };
  const setOpenAddForm = () => {
    setOpenAdd(false);
  };
  useEffect(() => {
    if (isCreateSuccess) setOpenAdd(false);
  }, [isCreateSuccess]);
  return (
    <Box className={classes.boxOptionBar}>
      <Box className={classes.roleTime}>
        <MenuStatus
          className={classes.menuRole}
          classMore={classes.moreRole}
          onChange={onMenuRole}
          includeDefault={true}
          titleDefault={LangConstant.TXT_ROLE}
          listTitle={LangConstant.ARR_ROLE}
        />
        <MenuStatus
          className={classes.menuTime}
          classMore={classes.moreTime}
          onChange={onMenuTime}
          includeDefault={true}
          titleDefault={LangConstant.TXT_WORK_TIME}
          listTitle={showTime}
        />
      </Box>
      <Box className={classes.positionStatus}>
        <MenuStatus
          className={classes.menuPosition}
          classMore={classes.morePosition}
          onChange={onMenuPosition}
          includeDefault={true}
          titleDefault={LangConstant.TXT_POSITION}
          listTitle={showPosition}
        />
        <MenuStatus
          className={classes.menuStatus}
          classMore={classes.moreStatus}
          onChange={onMenuStatus}
          includeDefault={true}
          titleDefault={LangConstant.TXT_STATUS}
          listTitle={LangConstant.ARR_WORKING_STATUS}
        />
      </Box>
      <Box className={classes.account}>
        <MenuStatus
          className={classes.menuAccount}
          classMore={classes.moreAccount}
          onChange={onMenuAccount}
          includeDefault={true}
          titleDefault={LangConstant.TXT_ACCOUNT_STATUS}
          listTitle={LangConstant.ARR_ACCOUNT}
        />
      </Box>
      <Box className={classes.searchBtn}>
        <SearchBar placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)} onKeyUp={onSearch} />
        <Button
          className={classes.buttonSendInvite}
          color="primary"
          size="medium"
          variant="contained"
          onClick={onClick}
          startIcon={<Add fontSize="small" />}
        >
          {getLabel(LangConstant.TXT_ADD)}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        <MenuItem onClick={onInvite} className={`medium-md-txt ${classes.menuItemRoot}`}>
          {getLabel(LangConstant.TXT_INVITE)}
        </MenuItem>
        <MenuItem onClick={() => onAdd()} className={`medium-md-txt ${classes.menuItemRoot}`}>
          {getLabel(LangConstant.TXT_CREATE_BUTTON)}
        </MenuItem>
      </Menu>
      <PopupInvite open={open} setOpen={setOpen} />
      <CreateStaff isShow={openAdd} action={setOpenAddForm} showTime={showTime} showPosition={showPosition} />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxOptionBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: 30,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      display: "unset",
      flexDirection: "column",
      height: "auto",
      marginBottom: 0,
    },
  },
  roleTime: {
    display: "flex",
    width: 331,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      marginBottom: 10,
      height: 30,
    },
  },
  positionStatus: {
    display: "flex",
    width: 333,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      marginBottom: 10,
      height: 30,
    },
  },
  account: {
    display: "flex",
    width: 158,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      marginBottom: 10,
      height: 30,
    },
  },
  searchBtn: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      height: 30,
      marginBottom: 10,
    },
  },
  menuRole: {
    marginRight: 8,
    width: 150,
  },
  menuTime: {
    marginRight: 8,
    width: 165,
  },
  menuPosition: {
    marginRight: 8,
    width: 150,
  },
  menuStatus: {
    marginRight: 8,
    width: 165,
  },
  menuAccount: {
    marginRight: 8,
    width: 150,
  },
  moreRole: {
    width: 135,
  },
  moreTime: {
    width: 140,
  },
  morePosition: {
    width: 133,
  },
  moreStatus: {
    width: 165,
  },
  moreAccount: {
    width: 150,
  },
  buttonSendInvite: {
    padding: "0 16px 0",
    minHeight: 30,
    fontWeight: "inherit",
    height: 30,
    minWidth: 95,
    borderRadius: 2,
    textTransform: "capitalize",
    color: "#ffffff",
  },
  menuPaper: {
    marginTop: 50,
    borderRadius: "0%",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: 160,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    minHeight: 40,
    width: 95,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
  },
}));

export default memo(OptionBar);
