import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, Popover as MuiPopover, Box, IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

const Dropdown = props => {
  const { anchorEl, children, classes, title, isIconClose, onClose } = props;
  const defaultClasses = useStyles();
  const modifyClasses = { ...DEFAULT_CLASSES, ...classes };
  const Popover = withStyles({
    paper: {
      width: anchorEl ? anchorEl.clientWidth : "max-content",
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
  })(MuiPopover);

  return (
    <Popover
      id="booking-dropdown"
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      className={modifyClasses.root}
    >
      <Box className={`${defaultClasses.title} ${modifyClasses.title}`}>
        {title}
        {isIconClose && (
          <IconButton className={defaultClasses.closeIcon} onClick={onClose}>
            <CloseOutlined color="inherit" />
          </IconButton>
        )}
      </Box>
      <Box className={`${defaultClasses.content} ${modifyClasses.content}`}>{children}</Box>
    </Popover>
  );
};
const DEFAULT_CLASSES = { root: "", title: "", content: "" };

Dropdown.propTypes = {
  anchorEl: PropTypes.any,
  title: PropTypes.string,
  classes: PropTypes.object,
  isIconClose: PropTypes.bool,
  onClose: PropTypes.func,
};
Dropdown.defaultProps = { classes: DEFAULT_CLASSES, title: "", isIconClose: true };

export default memo(Dropdown);

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    color: "white",
    background: theme.palette.primary.main,
  },
  content: {
    width: "100%",
    maxHeight: "500px",
    overflowY: "auto",
    cursor: "pointer",
    "& > *:hover": {
      background: theme.palette.grey[200],
    },
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    color: "white",
  },
}));
