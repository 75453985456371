import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment, OutlinedInput } from "@material-ui/core";

const InputIcon = forwardRef((props, ref) => {
  const { icon, onChange, classes, readOnly, ...otherProps } = props;
  const defaultClasses = useStyles();

  const onTyping = event => {
    if (readOnly) {
      return;
    }
    if (Boolean(onChange)) {
      onChange(event.target.value, event);
    }
  };

  return (
    <OutlinedInput
      fullWidth
      onChange={onTyping}
      classes={{
        input: defaultClasses.input,
        root: `${defaultClasses.root} ${classes.root}`,
        disabled: `${classes.disabled}`,
      }}
      startAdornment={
        <InputAdornment position="start" className={defaultClasses.icon}>
          {icon}
        </InputAdornment>
      }
      ref={ref}
      {...otherProps}
    />
  );
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: 8,
    marginBottom: 8,
  },
  input: {
    padding: "16px 0 16px 8px",
    "&::placeholder": {
      fontSize: 14,
      fontWeight: "normal",
    },
  },
  icon: {
    minWidth: 50,
    width: 50,
    height: "100%",
    maxHeight: "100%",
    minHeight: 50,
    background: theme.palette.grey[200],
    justifyContent: "center",
    borderRight: "solid 0.5px #cecfd2",
    marginRight: 0,
  },
}));

InputIcon.propTypes = {
  icon: PropTypes.node.isRequired,

  placeholder: PropTypes.string,
  type: PropTypes.string,

  value: PropTypes.any,
  defaultValue: PropTypes.any,

  classes: PropTypes.object,

  readOnly: PropTypes.bool,

  onChange: PropTypes.func,
};
InputIcon.defaultProps = {
  placeholder: "",
  classes: {
    root: "",
    disabled: "",
  },
};
InputIcon.displayName = "InputIcon";

export default memo(InputIcon);
