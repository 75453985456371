import React, { memo, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import PropTypes from "prop-types";

const DropdownMenu = ({ placeholder, valueTax, dataChange, onChange, dropdownClass, isDisabled }) => {
  const [data, setData] = useState(valueTax || valueTax == 0 ? valueTax : "");
  const classes = useStyles();

  const onChangeData = event => {
    setData(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Select
      className={dropdownClass}
      classes={{ icon: classes.icon }}
      value={data}
      displayEmpty
      disabled={isDisabled}
      input={<BootstrapInput />}
      onChange={onChangeData}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
      renderValue={data !== "" ? undefined : () => <Placeholder>{placeholder}</Placeholder>}
    >
      {dataChange.map((item, index) => (
        <MenuItem key={index} className={classes.selectDefault} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default memo(DropdownMenu);

const useStyles = makeStyles(theme => ({
  icon: {
    "&.MuiSelect-icon": {
      marginRight: 12,
    },
  },
  colorTex: {
    color: "#3e4045",
  },
  selectDefault: {
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#4b4d53",
      color: theme.palette.white,
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#4b4d53",
    },
  },
  placeholder: {
    fontSize: 14,
    color: "#aaa",
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    minWidth: 60,
    borderRadius: 4,
    textAlign: "left",
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    "&.Mui-disabled": {
      cursor: "no-drop",
      color: "#3e4045",
    },
  },
}))(InputBase);

const Placeholder = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.placeholder}>{children}</Box>;
};

DropdownMenu.propTypes = {
  placeholder: PropTypes.string.isRequired,
  dataChange: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  dropdownClass: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
};

DropdownMenu.defaultProps = { placeholder: "" };
