import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { Pagination, PaginationItem } from "@material-ui/lab";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Container,
  Typography,
  TablePagination,
  makeStyles,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import { endOfMonth, endOfWeek, format as DateFormat, startOfMonth, startOfWeek } from "date-fns";
import { LangConstant, AppConstant } from "const";
import { CellHead, CellBody, Processing, PrimaryCustomDatePicker, SearchInPeriod, SearchBar } from "components";
import MenuStatusReport from "./StatusBarReport";
import ReportStaffAction from "redux/report-staff.redux";
import StaffAction from "redux/staff.redux";

const MnReportStaff = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const headTitle = LangConstant.ARR_REPORT_STAFF_HEADER;

  const [data, isFetching] = useSelector(
    ({ reportStaffRedux }) => [reportStaffRedux.data, reportStaffRedux.isFetching],
    shallowEqual,
  );
  const [showTime, showPosition] = useSelector(
    ({ staffRedux }) => [staffRedux.dataWork, staffRedux.dataWorkKingShift],
    shallowEqual,
  );

  const [page, setPage] = useState(0);
  const [paging] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(null);
  const [showData, setShowData] = useState(data);
  const [day, setDay] = useState(DateFormat(new Date(Date.now()), AppConstant.FM_YYYY_MM_DD));
  const [optionView, setOptionView] = useState(DAY_FILTER);
  const [selectedDate, setSelectedDate] = useState({ from: new Date(), to: new Date() });
  const [dateObject, setDateObject] = useState({ from: new Date(), to: new Date() });

  const onCalculateDiffTime = (time, current) => {
    let result =
      Math.floor(new Date(time).getTime() / 1000) -
      current.getHours() * 60 * 60 -
      current.getMinutes() * 60 -
      current.getSeconds();
    return result;
  };

  const getTime = (selectedDay, selectedPeriod) => {
    let selectedDate = new Date(selectedDay);
    let start = null;
    let end = null;
    let firstDayOfWeek = 0;
    switch (selectedPeriod) {
      case WEEK_FILTER:
        firstDayOfWeek = selectedDate.getDate() - (selectedDate.getDay() === 0 ? 7 : selectedDate.getDay()) + 1;
        start = onCalculateDiffTime(selectedDate.setDate(firstDayOfWeek), selectedDate);
        end = start + 7 * 86400 - 1;
        break;
      case MONTH_FILTER:
        start = onCalculateDiffTime(selectedDate.setDate(1), selectedDate);
        end = onCalculateDiffTime(selectedDate.setMonth(selectedDate.getMonth() + 1), selectedDate) - 1;
        break;
      default:
        start = onCalculateDiffTime(selectedDate, selectedDate);
        end = start + 86399;
    }
    return [start, end];
  };

  const onChangeDate = (selectedDate, option) => {
    const selectedDay = new Date(selectedDate.from);
    const formatSelectedDay = DateFormat(selectedDay, AppConstant.FM_YYYY_MM_DD);
    const arrayTime = getTime(selectedDate.from, optionView);
    const startTime = arrayTime[0];
    const endTime = arrayTime[1];
    setSelectedDate(selectedDate);
    setOptionView(option || optionView);
    onChangeOption(selectedDate, option || optionView);
    if (day !== formatSelectedDay) {
      onGetReportStaff({
        start: startTime,
        end: endTime,
        page: 1,
      });
      setPage(0);
      setDay(formatSelectedDay);
    }
  };

  const onClickTodayBtn = toDayObj => onChangeDate(toDayObj, AppConstant.ReservationViewBy.day);

  const getPeriodSearch = selectedPeriod => {
    if (optionView !== selectedPeriod) {
      const arrayTime = getTime(day, selectedPeriod);
      const startTime = arrayTime[0];
      const endTime = arrayTime[1];
      onGetReportStaff({
        start: startTime,
        end: endTime,
        page: page + 1,
      });
      setPage(0);
      setOptionView(selectedPeriod);
      onChangeOption(selectedDate, selectedPeriod);
    }
  };

  const onSearch = stringSearch => {
    const arrayTime = getTime(day, optionView);
    const startTime = arrayTime[0];
    const endTime = arrayTime[1];
    onGetReportStaff({
      start: startTime,
      end: endTime,
      page: 1,
      filter: stringSearch,
    });
    setFilter(stringSearch);
  };

  const onChangePage = (event, newPage) => {
    const arrayTime = getTime(day, optionView);
    const startTime = arrayTime[0];
    const endTime = arrayTime[1];

    if (filter == null || filter === "") {
      onGetReportStaff({
        start: startTime,
        end: endTime,
        page: newPage,
      });
    } else if (filter) {
      onGetReportStaff({
        start: startTime,
        end: endTime,
        page: page + 1,
        filter: filter,
      });
    }
    setPage(newPage - 1);
  };

  const onChangeMenuRole = status => {
    const arrayTime = getTime(day, optionView);
    const startTime = arrayTime[0];
    const endTime = arrayTime[1];
    let condition = status !== null ? status + 2 : status;
    setPage(0);
    onGetReportStaff({
      role: condition,
      start: startTime,
      end: endTime,
      page: page + 1,
      filter: filter,
    });
  };

  const onChangeMenuTime = status => {
    const arrayTime = getTime(day, optionView);
    const startTime = arrayTime[0];
    const endTime = arrayTime[1];
    let condition = status !== null ? showTime[status].uid : status;
    setPage(0);
    onGetReportStaff({ workingShiftUid: condition, start: startTime, end: endTime, page: page + 1, filter: filter });
  };

  const onChangeMenuPosition = status => {
    const arrayTime = getTime(day, optionView);
    const startTime = arrayTime[0];
    const endTime = arrayTime[1];
    let condition = status !== null ? showPosition[status].uid : status;
    setPage(0);

    onGetReportStaff({ staffPosition: condition, start: startTime, end: endTime, page: page + 1, filter: filter });
  };

  const onGetReportStaff = queryParams => {
    dispatch(
      ReportStaffAction.getReportStaff({
        paging: paging,
        size: rowsPerPage,
        ...queryParams,
      }),
    );
  };

  const onChangeOption = (newSelectedDate, option) => {
    let startDate = newSelectedDate.from,
      endDate = newSelectedDate.to;
    if (option === AppConstant.ReservationViewBy.week) {
      startDate = startOfWeek(newSelectedDate.from, { weekStartsOn: 1 });
      endDate = endOfWeek(newSelectedDate.to, { weekStartsOn: 1 });
    } else if (option === AppConstant.ReservationViewBy.month) {
      startDate = startOfMonth(newSelectedDate.from);
      endDate = endOfMonth(newSelectedDate.to);
    }
    setDateObject({ from: startDate, to: endDate });
  };

  useEffect(() => {
    const arrayTime = getTime(day, optionView);
    const startTime = arrayTime[0];
    const endTime = arrayTime[1];
    onGetReportStaff({
      start: startTime,
      end: endTime,
      page: page + 1,
      filter: filter,
    });
  }, []);

  useEffect(() => {
    if (data != null) {
      setShowData(data);
      setRowsPerPage(data.size);
    }
  }, [data]);

  useEffect(() => {
    if (!showTime) {
      dispatch(StaffAction.getWorking());
    }
  }, [showTime]);

  useEffect(() => {
    if (!showPosition) {
      dispatch(StaffAction.getWorkingPotision());
    }
  }, [showPosition]);

  return (
    <Box className="mn-report-staff-root">
      <Paper elevation={1} square className={"container"}>
        <Container>
          <Box aria-label="total" className={"options-bar"}>
            <Box aria-label="date" className={"item-options-bar"}>
              <PrimaryCustomDatePicker
                onChange={onChangeDate}
                dateRange={dateObject}
                optionView={optionView}
                onClickToday={onClickTodayBtn}
              />
            </Box>
            <SearchInPeriod onChange={getPeriodSearch} optionViewSelected={optionView} className={"period-options"} />
            <Box className={classes.roleTime}>
              <MenuStatusReport
                className={classes.menuRole}
                classMore={classes.moreRole}
                onChange={onChangeMenuRole}
                includeDefault={true}
                titleDefault={LangConstant.TXT_ROLE}
                listTitle={LangConstant.ARR_ROLE}
              />
              <MenuStatusReport
                className={classes.menuTime}
                classMore={classes.moreTime}
                onChange={onChangeMenuTime}
                includeDefault={true}
                titleDefault={LangConstant.TXT_WORK_TIME}
                listTitle={showTime}
              />
            </Box>
            <Box className={classes.positionStatus}>
              <MenuStatusReport
                className={classes.menuPosition}
                classMore={classes.morePosition}
                onChange={onChangeMenuPosition}
                includeDefault={true}
                titleDefault={LangConstant.TXT_POSITION}
                listTitle={showPosition}
              />
            </Box>
            <SearchBar
              className="search-bar-root"
              onKeyUp={onSearch}
              placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)}
            ></SearchBar>
          </Box>
          <TableContainer className={"table-container"} mx="60px" my="40px">
            <Table aria-label="report table" className={"table-root"}>
              <TableHead>
                <TableRow>
                  {headTitle.map((cell, index) => (
                    <CellHead
                      className={classes.cellHeadRoot}
                      cellData={getLabel(cell)}
                      key={index}
                      style={{ backgroundColor: "transparent" }}
                      textAlign={index < 5 ? "left" : "center"}
                    ></CellHead>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {showData != null &&
                  Object.keys(showData.staffData).map((key, index) => (
                    <TableRow key={index}>
                      <CellBody
                        className={classes.cellBodyRoot}
                        cellData={showData.staffData[key].name}
                        style={{ width: "283px" }}
                        textAlign="left"
                      />
                      <CellBody
                        className={clsx(classes.cellBodyRoot, classes.regularTableCell)}
                        cellData={getLabel(AppConstant.ROLE_TYPE[showData.staffData[key]?.role])}
                      />
                      <CellBody
                        className={clsx(classes.cellBodyRoot, classes.regularTableCell)}
                        cellData={showData.staffData[key].workingShift?.name}
                      />
                      <CellBody
                        className={clsx(classes.cellBodyRoot, classes.regularTableCell)}
                        cellData={showData.staffData[key].code}
                      />
                      <CellBody
                        className={classes.cellBodyRoot}
                        cellData={showData.staffData[key].staffPosition}
                        style={{ width: "130px" }}
                        textAlign="left"
                      />
                      <CellBody
                        className={classes.cellBodyRoot}
                        cellData={showData.staffData[key].totalWorkingTime}
                        style={{ width: "130px" }}
                      />
                      <CellBody
                        className={classes.cellBodyRoot}
                        cellData={showData.staffData[key].totalServe}
                        style={{ width: "130px" }}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {showData != null && (
            <Box className={"pagination-parent"}>
              <TablePagination
                classes={{
                  root: "table-pagination",
                  selectRoot: "select-root",
                  toolbar: "toolbar",
                  caption: "caption",
                  actions: "actions",
                }}
                component="div"
                onChangePage={() => null}
                count={showData.total}
                page={page - 1 >= 0 ? page - 1 : 0}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={``}
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography
                    variant="body2"
                    classes={{ root: "pagination-typo", body2: "regular-sm-txt" }}
                    component="span"
                  >
                    {getLabel(LangConstant.FN_PAGINATION)(from, to, count)}
                  </Typography>
                )}
              />
              <Pagination
                className={"table-pagination"}
                count={parseInt((showData.total - 1) / rowsPerPage) + 1}
                onChange={onChangePage}
                page={page + 1}
                size="small"
                renderItem={item => (
                  <PaginationItem
                    classes={{
                      page: "pagination-item",
                      selected: "selected",
                    }}
                    {...item}
                  />
                )}
              />
            </Box>
          )}
        </Container>
      </Paper>
      {isFetching && <Processing isShow={true} />}
    </Box>
  );
};

const DAY_FILTER = 0;
const WEEK_FILTER = 1;
const MONTH_FILTER = 2;

const useStyles = makeStyles(theme => ({
  cellHeadRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  cellBodyRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  roleTime: {
    display: "flex",
    width: 331,
    height: 30,
    marginLeft: 8,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      marginBottom: 10,
      height: 30,
    },
  },
  menuRole: {
    marginRight: 8,
    width: 150,
  },
  moreRole: {
    width: 135,
  },
  menuTime: {
    marginRight: 8,
    width: 165,
  },
  moreTime: {
    width: 140,
  },
  positionStatus: {
    display: "flex",
    width: 333,
    height: 30,
    [theme.breakpoints.down("md")]: {
      display: "inline-flex",
      marginBottom: 10,
      height: 30,
    },
  },
  menuPosition: {
    marginRight: 8,
    width: 150,
  },
  morePosition: {
    width: 133,
  },
  regularTableCell: {
    width: 245,
    textAlign: "left",
  },
}));

export default memo(MnReportStaff);
