import React, { memo } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Avatar, Box, Button, makeStyles, Typography } from "@material-ui/core";
import Cookie from "js-cookie";
import { AppConstant, LangConstant, PathConstant } from "const";
import clsx from "clsx";
import { getAttachmentUrl } from "utils";
import { TO_BACK } from "pages/SelectShop";
import { Autorenew } from "@material-ui/icons";

const SwitchAccount = ({ isFullContent }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t: getLabel } = useTranslation();
  const multiShopData = Cookie.get(AppConstant.KEY_GBOSS_LOGIN);
  const selectedShopData = Cookie.get(AppConstant.KEY_GBOSS);
  const dataShop = useSelector(state => state.shopInfoRedux.data, shallowEqual);

  let shopData = [];
  if (multiShopData && multiShopData.length > 0) {
    shopData = JSON.parse(multiShopData).responseData;
  }

  let selectedShopId;
  if (selectedShopData && selectedShopData.length > 0) {
    selectedShopId = JSON.parse(selectedShopData).shopId;
  }

  let showData = shopData.filter(element => element.shop_id === selectedShopId)[0];

  const onSelectShop = event => {
    event.stopPropagation();
    history.push({ pathname: PathConstant.SELECT_SHOP, search: `?${TO_BACK}=yes` });
  };

  return (
    <Box
      className={clsx(
        classes.switchAccountRoot,
        isFullContent ? classes.switchAccountOpen : classes.switchAccountClose,
      )}
    >
      {showData && (
        <Box className={classes.accountInfo}>
          <Avatar
            variant="rounded"
            className={classes.ava}
            src={getAttachmentUrl(dataShop?.avatar || shopData.avatar)}
            onClick={onSelectShop}
          />
          <Box className={classes.accountNameRole}>
            <Typography className={`${classes.accountName} medium-lg-txt`}>{showData.name}</Typography>
            <Typography className={`${classes.accountRole} regular-sm-txt`}>
              {getLabel(AppConstant.ROLE_TYPE[showData.role])}
            </Typography>
          </Box>
        </Box>
      )}
      {showData &&
        (isFullContent ? (
          <Button
            variant="outlined"
            classes={{ root: classes.switchBtn, label: `semiBold-sm-txt ${classes.switchBtnLabel}` }}
            onClick={onSelectShop}
          >
            {getLabel(LangConstant.TXT_SWITCH_TEAM)}
          </Button>
        ) : (
          <Button variant="outlined" className={classes.switchIcon} onClick={onSelectShop}>
            <Autorenew />
          </Button>
        ))}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  switchAccountRoot: {
    width: "100%",
    minHeight: "120px",
    maxHeight: "max-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "auto",
    boxShadow: "1px 0 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fafafb",
  },
  switchAccountOpen: {
    padding: "35px 16px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 70,
    },
  },
  switchAccountClose: {
    padding: "35px 16px 45px",
  },
  accountInfo: {
    minHeight: "32px",
    width: "100%",
    display: "flex",
  },
  ava: {
    height: "32px",
    width: "32px",
    cursor: "pointer",
  },
  accountNameRole: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  },
  accountName: { color: "#3e4045" },
  accountRole: { color: "#7f838c", marginTop: "auto" },
  switchBtn: {
    minHeight: "27px",
    maxWidth: "130px",
    marginTop: "18px",
    color: "#3e4045",
    textTransform: "unset",
    borderRadius: "13.5px",
    border: "solid 2px #979797",
  },
  switchBtnLabel: {
    color: "#3e4045",
  },
  switchIcon: {
    minWidth: 28,
    minHeight: 28,
    marginTop: 24,
    padding: 2,
    borderRadius: "13.5px",
    border: "solid 2px #979797",
  },
}));

SwitchAccount.propTypes = { isFullContent: PropTypes.bool };
SwitchAccount.defaultProps = { isFullContent: false };

export default memo(SwitchAccount);
