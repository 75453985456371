import React, { memo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { AppConstant } from "const";

const StatusIcon = ({ children, ...otherProps }) => {
  const classes = useStyles(otherProps);
  return <Box className={clsx(classes.root, "medium-md-txt")}>{children}</Box>;
};

StatusIcon.propTypes = {};
StatusIcon.defaultProps = {};

export default memo(StatusIcon);

const useStyles = makeStyles(theme => ({
  root: {
    background: props => props.color,
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.white,
    opacity: props => (props.isActive ? 1 : 0.3),
    marginBottom: props =>
      props.isMobile &&
      props.currentStatus > AppConstant.STT_RESERVATION.confirm &&
      (props.sttValue === AppConstant.STT_RESERVATION.seated ? 45 : 0),
  },
}));
