import { Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { forwardRef, memo } from "react";
import Cookie from "js-cookie";
import { AppConstant } from "const";
import { DownloadIcon } from "icons";

const ExportFile = forwardRef((props, ref) => {
  const { onExportFile, extraClass } = props;
  const classes = useStyles();
  const role = Cookie.get(AppConstant.ROLE);
  return (
    AppConstant.SHOP_STAFF !== role && (
      <Button
        onClick={onExportFile}
        ref={ref}
        startIcon={<DownloadIcon className={classes.downloadIcon} />}
        classes={{ root: `${classes.exportBtn} ${extraClass}`, startIcon: classes.startIcon }}
      />
    )
  );
});

ExportFile.prototype = {
  onExportFile: PropTypes.func.isRequired,
  extraClass: PropTypes.any,
};

ExportFile.defaultProps = {};

const useStyles = makeStyles(theme => ({
  exportBtn: {
    borderRadius: 0,
    width: 30,
    height: 30,
    backgroundColor: theme.palette.white,
    margin: 0,
    border: "solid 1px " + theme.palette.grey["200"],
    textTransform: "none",
    padding: 0,
    minWidth: "unset",
  },
  startIcon: {
    margin: "0 !important",
    color: "#4b4d53",
    height: 22,
    width: 22,
    justifyContent: "center",
  },
  downloadIcon: {
    fontSize: "24px !important",
  },
}));

export default memo(ExportFile);
