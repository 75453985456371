import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Alert, CellBody } from "components";
import { LangConstant } from "const";
import { getCommonKey, isArrayNotEqual } from "utils";
import BookingDialogHistory from "./BookingDialogHistory";
import PlaceCategoryAction from "redux/place-category.redux";
import ReservationAction from "redux/reservation.redux";
import ReservationHistoryAction from "redux/reservation-history.redux";
import ServiceCategoryAction from "redux/service-category.redux";
import StatusBatchUpdateMenu from "./StatusBatchUpdateMenu";
import { checkIsNotPast, isValidDate, isValidDateTime } from "utils/date";
import BookingHistoryTableRow from "./BookingHistoryTableRow";
import { getQueryData } from "./FilterMenu";

const BookingHistoryTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = getQueryData(history.location.search, history);
  const reservationRedux = useSelector(state => state.reservationRedux);
  const [isSuccess, bookingHistory] = useSelector(state => [
    state.reservationHistoryRedux.isSuccess,
    state.reservationHistoryRedux.bookingHistory,
  ]);
  const { reservationSetting, updateReservationSuccess } = reservationRedux;
  const headerArr = LangConstant.ARR_BOOKING_HISTORY_TABLE_HEADER;
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);

  const [open, setOpen] = useState(Boolean(queryParams.uid));
  const [isShowErr, setIsShowErr] = useState(false);
  const [checkedBookingList, setCheckedBookingList] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [showData, setShowData] = useState([]);

  let isSettingTotal = reservationSetting && reservationSetting[4] && reservationSetting[4].publish === 1;

  const onClose = () => {
    setOpen(false);
    dispatch(ReservationAction.resetBookingDetail());
  };

  const onResetMenu = () => {
    setCheckedBookingList([]);
    setIsCheckedAll(false);
  };

  const onChangeDetail = detail => {
    if (reservationRedux.reservation && reservationRedux.reservation.status !== detail.status) {
      dispatch(ReservationAction.putReservationDetails({ details: detail, uid: detail.uid }));
      onClose();
    } else {
      if (isValidDate(detail.date) && isValidDateTime(detail.time)) {
        let detailTimeObject = detail.date.split("/");
        let startTime = Math.floor(
          new Date(`${detailTimeObject[2]}-${detailTimeObject[1]}-${detailTimeObject[0]}  ${detail.time}`).getTime() /
            1000,
        );
        if (checkIsNotPast(startTime + 3600)) {
          dispatch(
            ReservationAction.putReservationDetails({
              details: detail,
              uid: detail.uid,
              isSettingTotal: isSettingTotal,
            }),
          );
          onClose();
        } else {
          setIsShowErr(true);
        }
      } else {
        setIsShowErr(true);
      }
    }
  };

  const onCheckAll = () => {
    let tmpCheckedArr = [];
    setIsCheckedAll(!isCheckedAll);
    if (!isCheckedAll) {
      tmpCheckedArr = showData.map(booking => booking.uid);
    }
    setCheckedBookingList(tmpCheckedArr);
  };

  useEffect(() => {
    if (queryParams.uid) dispatch(ReservationAction.getReservation({ uid: queryParams.uid }));
    dispatch(PlaceCategoryAction.getPlaceCategory({}));
    dispatch(ServiceCategoryAction.getServiceCategory({}));
    dispatch(ReservationAction.getReservationSettingOption());
  }, []);

  useEffect(() => {
    if (updateReservationSuccess) {
      dispatch(ReservationHistoryAction.getBookingHistory());
    }
  }, [updateReservationSuccess]);

  useEffect(() => {
    if (isSuccess) {
      onResetMenu();
      dispatch(ReservationHistoryAction.resetSuccess());
    }
  }, [isSuccess]);

  useEffect(() => {
    let newData = bookingHistory?.data;
    if (isArrayNotEqual(newData, showData)) setShowData(newData);
  }, [bookingHistory]);

  let isShowStatusSelect = checkedBookingList.length > 0;

  return (
    <>
      {isShowStatusSelect && <StatusBatchUpdateMenu selectedBookingList={checkedBookingList} />}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headerArr.map((cell, index) => (
                <CellBody
                  cellData={
                    <Box className={classes.firstTableCellHead}>
                      {index === 0 && (
                        <Checkbox
                          checked={isCheckedAll}
                          onChange={onCheckAll}
                          color="default"
                          classes={{
                            root: clsx(classes.tableHeadCheckBox, classes.checkboxBasis),
                            checked: clsx(classes.checkboxChecked, classes.checkboxBasis),
                          }}
                        />
                      )}
                      <Typography className={clsx(classes.tableHeadLabel, "semiBold-sm-txt")}>
                        {getLabel(cell)}
                      </Typography>
                    </Box>
                  }
                  className={clsx(classes.cellHeadRoot, "semiBold-sm-txt")}
                  key={index}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showData.map((dataShow, index) => (
              <BookingHistoryTableRow
                key={index}
                checkedBookingList={checkedBookingList}
                dataShow={dataShow}
                showDataLength={showData.length}
                setCheckedBookingList={setCheckedBookingList}
                setIsCheckedAll={setIsCheckedAll}
                setOpen={setOpen}
              />
            ))}
          </TableBody>
        </Table>
        <Alert
          isShow={isShowErr}
          onClose={() => setIsShowErr(false)}
          type="error"
          message={getLabel(getCommonKey(LangConstant.ERR_INVALID_DAY_OR_TIME))}
        />
      </TableContainer>

      {open && <BookingDialogHistory open={open} onClose={onClose} onSubmit={onChangeDetail} />}
    </>
  );
};

export default BookingHistoryTable;

BookingHistoryTable.propTypes = {};

const useStyles = makeStyles(theme => ({
  rootTableRow: {
    height: 40,
  },

  tableContainer: {
    border: "1px solid #ebeced",
    "&>table": {
      overflowX: "auto",
      minWidth: 800,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cellHeadRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  cellBodyRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[500],
    fontSize: 13,
    fontWeight: "normal",
    textAlign: "center",
    "&>div": {
      justifyContent: "center",
    },
  },
  bookingCode: {
    color: theme.palette.text.link,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: 13,
  },
  creatorCell: {
    display: "flex",
  },
  creatorTag: {
    minWidth: "fit-content",
    fontSize: 13,
    fontWeight: 500,
    color: "#65b39d",
  },
  statusText: {
    width: "100%",
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    padding: "2px 5px",
    fontWeight: 600,
    lineHeight: 1.69,
    objectFit: "contain",
  },
  creatorName: {
    color: theme.palette.grey[500],
    fontSize: 13,
    fontWeight: "normal",
  },
  firstTableCellHead: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  codeCell: {
    display: "flex",
    alignItems: "center",
  },
  checkboxBasis: {
    width: 15,
    height: 15,
    marginRight: 25,
    " &>span>svg": {
      width: 20,
      height: 20,
    },
  },
  tableHeadCheckBox: {
    color: "#4b4d53",
  },
  checkboxChecked: {
    color: "#447aff",
  },
  tableHeadLabel: {
    width: "100%",
    textAlign: "center",
  },
}));
