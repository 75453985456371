import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const NotificationJoinGroup = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_GROUP_ADMIN);

  return (
    <Box className={classes.root}>
      <Typography variant="h4">{getLabel(LangConstant.TXT_NOTIFICATION_JOIN_GROUP)}</Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    paddingTop: "20%",
  },
});

export default NotificationJoinGroup;
