import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles, StepLabel, useMediaQuery, useTheme } from "@material-ui/core";
import StatusIcon from "./StatusIcon";
import StatusButton from "./StatusButton";
import { LangConstant } from "const";
import { createStyleStatus } from "utils";
import { STT_RESERVATION_WITH_INDEX } from "./CustomStepperOrder";

const StepperOder = ({ entry, index, onChange, currentStatus, currentStatusStep, currentSelected }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t: getLabel } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let statusArray = STT_RESERVATION_WITH_INDEX;
  let sttValue = entry;

  let sttStyle = createStyleStatus(theme.palette.reservation, sttValue);

  let isNext = statusArray.indexOf(currentStatusStep) + 1 === index;
  let isActive = index === statusArray.indexOf(currentSelected) || index === statusArray.indexOf(currentStatusStep);
  let isPassed = index < statusArray.indexOf(currentStatusStep);

  return (
    <StepLabel
      classes={{ root: classes.root, iconContainer: classes.stepLabel }}
      StepIconComponent={() => (
        <StatusIcon
          isActive={isActive}
          color={sttStyle.color}
          sttValue={entry}
          currentStatus={currentStatus}
          isMobile={isMobile}
        >
          {index + 1}
        </StatusIcon>
      )}
    >
      <StatusButton
        disabled={isPassed}
        isNext={isNext}
        isActive={isActive}
        color={sttStyle.color}
        onClick={() => onChange(entry)}
      >
        {getLabel(LangConstant.ARR_STATUS_ORDER[index])}
      </StatusButton>
    </StepLabel>
  );
};

StepperOder.propTypes = {
  status: PropTypes.number,
  onChange: PropTypes.func,
};

const useStyles = makeStyles({
  stepLabel: {
    marginLeft: 55,
    marginBottom: 20,
  },
  root: {
    display: "block",
  },
});
export default memo(StepperOder);
