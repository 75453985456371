import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import {
  DisplayModeBar,
  IconButtonMProductItem,
  DialogAddCategoryItem,
  CategoryItemListBody,
  CategoryItemListHeader,
} from "components/mn-master-product";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { formatCurrency, removeVietnameseTones } from "utils";
import DialogAddAccompaniedProductAll from "./DialogAddAccompaniedProductAll";
import DialogAddTableAccompaniedProductAll from "./DialogAddTableAccompaniedProductAll";
import MnProductAction from "redux/mn-product.redux";
import { useSelector, useDispatch } from "react-redux";
import { DialogDelete } from "components/dialog";

const AccompaniedProductAll = ({ childProductModeId, childProductModeArr, onChangeMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const [isOpen, setIsOpen] = useState({
    addFromMain: false,
    createAccompanied: false,
    uid: "",
    deleteProduct: false,
  });
  const [isOpenByUid, setIsOpenByUid] = useState({
    uid: "",
    name: "",
    isEdit: false,
    isWatch: false,
    isDelete: false,
    itemUid: "",
  });

  const [openAddAccompaniedProductAll, setOpenAddAccompaniedProductAll] = useState(false);
  const [listProductItem, setListProductItem] = useState([]);

  const dataDetailsContent = useSelector(state => state.mnProductRedux.dataDetailsContent);
  const isDetailsContentSuccess = useSelector(state => state.mnProductRedux.isGetDetailsContentSuccess);

  const isRemoveDetailsContent = useSelector(state => state.mnProductRedux.isDeleteDetailsContentSuccess);
  const isPutDetailsContent = useSelector(state => state.mnProductRedux.isPutDetailsContentSuccess);
  const isPostDetailsContent = useSelector(state => state.mnProductRedux.isPostDetailsContentSuccess);

  const onOpenAddAccompaniedService = () => {
    setOpenAddAccompaniedProductAll(open => !open);
  };

  const onAddFromMainProduct = () => {
    setIsOpen({ ...isOpen, addFromMain: true });
  };

  const onCreateAccompaniedProduct = () => {
    setIsOpen({ ...isOpen, createAccompanied: true });
  };

  const onCloseAccompaniedProduct = value => {
    setOpenAddAccompaniedProductAll(value);
  };

  const onSearch = name => {
    let newListSearch = dataDetailsContent?.data.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListProductItem(newListSearch);
  };

  const onCloseAll = () => {
    onCloseByUid();
    setOpenAddAccompaniedProductAll(false);
    setIsOpen({
      addFromMain: false,
      createAccompanied: false,
      viewAccompanied: false,
      editAccompanied: false,
      uid: "",
      deleteProduct: false,
    });
  };

  const onComeBack = () => {
    setIsOpen({
      addFromMain: false,
      createAccompanied: false,
      viewAccompanied: false,
      editAccompanied: false,
      deleteProduct: false,
    });
  };

  const onClickEditBody = uid => {
    var data = dataDetailsContent?.data.filter(dataMap => dataMap.uid === uid)[0];
    if (data.itemUid) {
      setIsOpenByUid({ ...isOpenByUid, isEdit: true, uid: uid });
    } else {
      setIsOpen({ ...isOpen, editAccompanied: true, uid: uid });
    }
  };
  const onClickDeleteBody = uid => {
    let name = "";
    dataDetailsContent?.data.forEach(data => {
      if (data.uid === uid) {
        name = data.name;
      }
    });
    setIsOpen({ ...isOpen, deleteProduct: true, uid: uid, name: name });
  };

  const onClickWatchBody = uid => {
    var data = dataDetailsContent?.data.filter(dataMap => dataMap.uid === uid)[0];
    if (data.itemUid) {
      setIsOpenByUid({ ...isOpenByUid, isWatch: true, uid: uid });
    } else {
      setIsOpen({ ...isOpen, viewAccompanied: true, uid: uid });
    }
  };

  const onCloseByUid = () => {
    setIsOpenByUid({
      uid: "",
      name: "",
      isEdit: false,
      isWatch: false,
      isDelete: false,
      itemUid: "",
    });
  };

  const onConfirmRemoveProduct = (e, uid) => {
    onCloseAll();
    dispatch(MnProductAction.removeDetailsContent({ uid: uid }));
  };

  useEffect(() => {
    dispatch(MnProductAction.getProductCategory());
    dispatch(MnProductAction.getDetails({ type: AppConstant.TYPE_CATEGORY.product }));
    dispatch(MnProductAction.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
  }, []);

  useEffect(() => {
    if (dataDetailsContent && isDetailsContentSuccess) {
      setListProductItem(dataDetailsContent.data);
    }
  }, [isDetailsContentSuccess]);

  useEffect(() => {
    if (dataDetailsContent?.data && isDetailsContentSuccess) {
      dispatch(MnProductAction.resetMnProduct());
      setListProductItem(dataDetailsContent.data);
    }
  }, [isDetailsContentSuccess]);

  useEffect(() => {
    if (dataDetailsContent && dataDetailsContent?.data && isDetailsContentSuccess) {
      setListProductItem(dataDetailsContent.data);
    }
  }, [isDetailsContentSuccess]);

  useEffect(() => {
    if (isRemoveDetailsContent) {
      onCloseAll();
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isRemoveDetailsContent]);

  useEffect(() => {
    if (isPutDetailsContent) {
      onCloseAll();
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isPutDetailsContent]);

  useEffect(() => {
    if (isPostDetailsContent) {
      if (!isOpen.addFromMain) {
        onCloseAll();
      }
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getDetailsContent({ type: AppConstant.TYPE_CATEGORY.product }));
    }
  }, [isPostDetailsContent]);

  return (
    <>
      <DisplayModeBar
        modeId={childProductModeId}
        modeArray={childProductModeArr}
        labelBtn={getLabel(LangConstant.TXT_CREATE_PRODUCT)}
        onChange={onChangeMode}
        onAddItem={onOpenAddAccompaniedService}
        onSearch={onSearch}
        showSearch={dataDetailsContent && dataDetailsContent?.data.length > 0 ? true : false}
      />
      <Box className={classes.contentBox}>
        {Boolean(dataDetailsContent?.data.length > 0) ? (
          <Box className={classes.rootBody}>
            <CategoryItemListHeader isProduct={true} />
            {listProductItem?.map(dataProductItem => (
              <CategoryItemListBody
                key={dataProductItem.uid}
                uid={dataProductItem.uid}
                onClickEdit={onClickEditBody}
                onClickDelete={onClickDeleteBody}
                onClickWatch={onClickWatchBody}
                categoryName={dataProductItem.name}
                isAdd={false}
                stock={dataProductItem.stock ? dataProductItem.stock : 0}
                image={dataProductItem.image}
                price={dataProductItem.price ? formatCurrency(`${dataProductItem.price}`) : ""}
                disabled={Boolean(dataProductItem.itemDetailsContentUid)}
                isNotClickEdit={Boolean(isOpenByUid.isEdit && dataProductItem.itemUid)}
              />
            ))}
          </Box>
        ) : (
          <IconButtonMProductItem
            label={getLabel(LangConstant.TXT_CREATE_PRODUCT)}
            onClick={onOpenAddAccompaniedService}
            buttonClasses={{ root: classes.addButton }}
          />
        )}
        {openAddAccompaniedProductAll && (
          <DialogAddCategoryItem
            isShow={openAddAccompaniedProductAll}
            titleName={{
              header: getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_HEADER),
              add: getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_ADD),
              created: getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE),
            }}
            action={onCloseAccompaniedProduct}
            inputProps={{ className: classes.dialogAddTypo }}
            onAddFromMainService={onAddFromMainProduct}
            onCreateAccompaniedService={onCreateAccompaniedProduct}
          />
        )}
        {isOpen.addFromMain && (
          <DialogAddTableAccompaniedProductAll
            isOpen={isOpen.addFromMain}
            onClose={onCloseAll}
            onBack={onComeBack}
            nameHeader={getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_ADD_DIALOG)}
            dataChild={dataDetailsContent?.data}
          />
        )}
        {(isOpen.createAccompanied || isOpen.editAccompanied || isOpen.viewAccompanied) && (
          <DialogAddAccompaniedProductAll
            isOpenWatch={isOpen.viewAccompanied}
            isOpenEdit={isOpen.editAccompanied}
            isOpenCreate={isOpen.createAccompanied}
            onClose={onCloseAll}
            onBack={onComeBack}
            data={dataDetailsContent?.data.filter(dataMap => dataMap.uid === isOpen.uid)[0]}
          />
        )}
        <DialogDelete
          uid={isOpen.uid}
          isShow={isOpen.deleteProduct}
          onCancel={onCloseAll}
          onConfirm={onConfirmRemoveProduct}
          title={getLabel(LangConstant.FN_DELETE_GOODS_ITEM_TITLE)(isOpen.name)}
          content={getLabel(LangConstant.FN_DELETE_GOODS_SUB_ITEM_CONTENT)(isOpen.name)}
        />
      </Box>
    </>
  );
};

export default memo(AccompaniedProductAll);

const useStyles = makeStyles(theme => ({
  contentBox: {
    width: "100%",
    minHeight: "720px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  rootBody: {
    marginTop: 62,
    marginBottom: 62,
    width: "94%",
    marginLeft: "3%",
    [theme.breakpoints.down(600)]: {
      overflowX: "auto",
    },
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  dialogAddTypo: {
    color: "#3e4045",
    lineHeight: 1.5,
    fontWeight: "bold",
  },
}));
