import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";
import { convertTimeStampToDate } from "utils/date";
import { getCommonKey } from "utils";

const StatusDialogBillDetail = ({ data }) => {
  const classes = useStyles();

  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);

  return (
    <Box className={classes.statusDialogContent}>
      <Box className={classes.contentContainer}>
        <Typography variant="subtitle2" className={clsx(classes.tableHeadLabel, "semiBold-sm-txt")}>
          {getLabel(getCommonKey(LangConstant.P_BOOKING_TIME))}
        </Typography>
        <Typography className={clsx(classes.contentDetail, "regular-md-txt")}>
          {convertTimeStampToDate(data.created || 0, AppConstant.FM_DD_MM_YYYY)}
        </Typography>
      </Box>
      <Box className={classes.contentContainer}>
        <Typography variant="subtitle2" className={clsx(classes.tableHeadLabel, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_COMPANY_NAME)}
        </Typography>
        <Typography className={clsx(classes.contentDetail, "regular-md-txt")}>{data.companyName}</Typography>
      </Box>
      <Box className={classes.contentContainer}>
        <Typography variant="subtitle2" className={clsx(classes.tableHeadLabel, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_COMPANY_TAX_NUMBER)}
        </Typography>
        <Typography className={clsx(classes.contentDetail, "regular-md-txt")}>{data.taxCode}</Typography>
      </Box>
    </Box>
  );
};

export default StatusDialogBillDetail;

StatusDialogBillDetail.propTypes = {
  uid: PropTypes.string,
  companyName: PropTypes.string,
  taxCode: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  contactName: PropTypes.string,

  status: PropTypes.number,
  created: PropTypes.number,

  reservation: PropTypes.shape({
    uid: PropTypes.string,
    code: PropTypes.string,
  }),
};

const useStyles = makeStyles(() => ({
  statusDialogContent: {
    display: "flex",
    padding: "36px 24px",
    paddingBottom: 12,
  },

  tableHeadLabel: {
    width: "100%",
    fontSize: 13,
  },

  contentDetail: {
    marginTop: 8,
  },

  contentContainer: {
    marginRight: 36,
  },
}));
