import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getMenu: ["data"],
  getMenuSuccess: ["data"],
  getMenuFailure: ["data"],

  postMenu: ["data"],
  postMenuSuccess: ["data"],
  postMenuFailure: ["data"],

  menuSuccess: ["data"],

  resetMenu: [],
  setMenu: ["data"],
});

export const MenuTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  data: [],
  //data default
  dataDefault: [],
  dataDisplay: [],
  dataOrderDefault: [],
  dataOrderDisplay: [],
  isTabBooking: true,
  isPostSuccess: false,
};

/* ------------- Reducers Get Shop info data ------------- */

export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    ...data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

export const postMenu = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const postMenuSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isPostSuccess: true,
    data: action.data,
  };
};

export const postMenuFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isPostSuccess: false,
  error: action.error,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isSuccess: false,
  isPostSuccess: false,
  isPostOrderSuccess: false,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_MENU]: request,
  [Types.GET_MENU_SUCCESS]: success,
  [Types.GET_MENU_FAILURE]: failure,

  [Types.POST_MENU]: postMenu,
  [Types.POST_MENU_SUCCESS]: postMenuSuccess,
  [Types.POST_MENU_FAILURE]: postMenuFailure,
  [Types.MENU_SUCCESS]: success,

  [Types.RESET_MENU]: reset,
  [Types.SET_MENU]: success,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
