import React from "react";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import ReportDashboardAction from "redux/report-dashboard.redux";
import { useDispatch, useSelector } from "react-redux";
import BookingByTimeChart from "./BookingByTimeChart";
import ReportLayout from "../ReportLayout";
import ReportChartStatus from "../ReportChartStatus";

const BookingByTime = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);
  const dispatch = useDispatch();
  const summaryCustomerSetting = useSelector(({ reportDashboard }) => reportDashboard.summaryReservation.setting);

  const onChangeQueryParams = queryParams =>
    dispatch(ReportDashboardAction.requestReportSummaryReservation(queryParams));

  const onChangeDisplaySetting = displaySettings => {
    if (bookingByReservationTimeout) {
      clearTimeout(bookingByReservationTimeout);
    }

    bookingByReservationTimeout = setTimeout(() => {
      dispatch(
        ReportDashboardAction.createReportSummaryReservationSetting({ ...DISPLAY_SETTING_QUERY, ...displaySettings }),
      );
    }, 300);
  };

  return (
    <ReportLayout title={getLabel(LangConstant.TXT_CHART_TITLE_FIRST)} onChangeQueryParams={onChangeQueryParams}>
      <BookingByTimeChart />
      <ReportChartStatus displaySetting={summaryCustomerSetting} onChange={onChangeDisplaySetting} />
    </ReportLayout>
  );
};

BookingByTime.propTypes = {};
export default BookingByTime;

const DISPLAY_SETTING_QUERY = { type: AppConstant.DISPLAY_CHART_SETTING_TYPE.reportByReservation };
var bookingByReservationTimeout;
