import React, { memo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, Box, Button, Typography, CardMedia, IconButton } from "@material-ui/core";
import { AccessTime, CheckCircle, Star } from "@material-ui/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import StringFormat from "string-format";
import { CopyIcon } from "icons";
import { getAttachmentUrl, getItemUrl, getNSKey, onLoadImageError } from "utils";
import { LangConstant, AppConstant } from "const";
import { CustomTooltip } from "components";
import { ItemRatingDetailDialog } from "components/dialog";

const ItemDescriptionBlock = ({
  data,
  onChoose,
  onOpenDetail,
  onOpenEdit,
  onOpenDelete,
  typeButton,
  amount,
  price,
  time,
  typeProductIcon,
  classes,
  ...otherProps
}) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();

  const shopInfo = useSelector(state => state.shopInfoRedux.data, shallowEqual);

  const [isShowRatingDetail, setIsShowRatingDetail] = useState(false);

  const typeButtonFooter = type => {
    switch (type) {
      case AppConstant.TYPE_BUTTON_CATEGORY.deleted:
        return getLabel(LangConstant.TXT_DELETE);
      case AppConstant.TYPE_BUTTON_CATEGORY.removed:
        return getLabel(LangConstant.TXT_REMOVED);
      default:
        return getLabel(LangConstant.TXT_DELETE);
    }
  };

  const onOpenRatingDetail = () => {
    setIsShowRatingDetail(true);
  };

  let isSingleButton = Boolean(
    typeButton !== AppConstant.TYPE_BUTTON_CATEGORY.choose &&
      typeButton !== AppConstant.TYPE_BUTTON_CATEGORY.removedSingle,
  );
  let isRemovedSingle = Boolean(typeButton === AppConstant.TYPE_BUTTON_CATEGORY.removedSingle);

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <CopyToClipboard text={getItemUrl(shopInfo?.alias, data.itemUid ? data.itemUid : data.uid, data.name)}>
        <CustomTooltip
          size="small"
          arrow={true}
          placement="top"
          title={
            <Typography className={clsx(defaultClasses.popoverCopyIcon, "medium-sm-txt")}>
              {getLabel(LangConstant.TXT_COPY_LINK_TO_PRODUCT)}
            </Typography>
          }
        >
          <IconButton size="small" className={defaultClasses.copyButton}>
            <CopyIcon className={defaultClasses.copyIcon} />
          </IconButton>
        </CustomTooltip>
      </CopyToClipboard>

      <Box
        onClick={() => {
          onOpenDetail(data.uid);
          !isRemovedSingle && onChoose();
        }}
        className={defaultClasses.boxHeader}
      >
        <CardMedia
          component="img"
          className={defaultClasses.image}
          src={getAttachmentUrl(data.image)}
          onError={onLoadImageError}
        />
      </Box>
      <Box className={defaultClasses.boxBody}>
        <Box onClick={() => onOpenDetail(data.uid)} width="100%">
          <Typography className={defaultClasses.contentHeader}>{data.name}</Typography>
        </Box>
        <Box className={defaultClasses.itemRatingDetail}>
          <Typography className={clsx(defaultClasses.ratingScore, "regular-sm-txt")}>
            <Star />
            {data.rating?.averageScore || 0}&nbsp;|&nbsp;
            <Button
              variant="text"
              className={clsx(defaultClasses.ratingDetailButton, "regular-sm-txt")}
              onClick={onOpenRatingDetail}
            >
              {StringFormat(
                getLabel(getNSKey(LangConstant.NS_RATING_MANAGEMENT, LangConstant.FM_TOTAL_RATING_AMOUNT)),
                data.rating?.totalRating || 0,
              )}
            </Button>
          </Typography>

          <Box className={clsx(defaultClasses.prefixContentFooter, classes?.prefixContentCustom)}>
            {time ? (
              <>
                <AccessTime className={defaultClasses.prefixAccessTime} />
                <Typography
                  className={clsx(defaultClasses.prefixTypoService, classes?.prefixTypoCustom, "regular-sm-txt")}
                >
                  {time}
                </Typography>
              </>
            ) : (
              <Product amount={amount} typeProductIcon={typeProductIcon} />
            )}
          </Box>
        </Box>
        <Typography className={defaultClasses.contentBody}>{data.description}</Typography>
        <Box className={defaultClasses.contentFooter}>
          <Service time={time} price={price} />
        </Box>
      </Box>
      <Box className={defaultClasses.boxFooter}>
        {isSingleButton ? (
          <>
            <Button onClick={() => onOpenDelete(data.uid)} className={defaultClasses.buttonDelete}>
              {typeButtonFooter(typeButton)}
            </Button>
            <Button onClick={() => onOpenEdit(data.uid)} className={defaultClasses.buttonEdit}>
              {getLabel(LangConstant.TXT_SHORT_EDIT)}
            </Button>
          </>
        ) : (
          <Button disabled={otherProps.disabled} onClick={onChoose} className={classes?.chooseItem || ""}>
            {isRemovedSingle ? getLabel(LangConstant.TXT_REMOVED) : getLabel(LangConstant.TXT_CHOOSE)}
          </Button>
        )}
      </Box>
      <ItemRatingDetailDialog isShow={isShowRatingDetail} onClose={() => setIsShowRatingDetail(false)} data={data} />
    </Box>
  );
};

const Service = ({ price }) => {
  const defaultClasses = useStyles();

  return <Typography className={defaultClasses.suffixesContentFooter}>{price}</Typography>;
};

const Product = ({ amount, typeProductIcon }) => {
  const defaultClasses = useStyles();

  const typeIcon = type => {
    switch (type) {
      case AppConstant.TYPE_PRODUCT_ICON.notMarked:
        return <CheckCircle className={defaultClasses.prefixIconType2} />;
      case AppConstant.TYPE_PRODUCT_ICON.marked:
        return <CheckCircle className={defaultClasses.prefixIconType1} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Box className={defaultClasses.prefixContentFooter}>
        <Typography className={defaultClasses.prefixTypoProduct}>{amount}</Typography>
        {typeIcon(typeProductIcon)}
      </Box>
    </>
  );
};

ItemDescriptionBlock.propTypes = {
  data: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),

  onOpenDetail: PropTypes.func,
  onOpenDelete: PropTypes.func,
  onOpenEdit: PropTypes.func,
  onChoose: PropTypes.func,

  typeButton: PropTypes.number,
  typeProductIcon: PropTypes.number,

  time: PropTypes.string,
  amount: PropTypes.string,
  price: PropTypes.string,

  classes: PropTypes.object,
};

ItemDescriptionBlock.defaultProps = {
  data: {
    image: "",
    uid: "",
    title: "",
    description: "",
  },

  time: "",

  classes: {},

  onOpenDetail: () => {},
  onOpenDelete: () => {},
  onOpenEdit: () => {},
  onChoose: () => {},
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    minHeight: 307,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    "&:hover": {
      "& $copyButton": {
        display: "block",
      },
    },
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.103529)",
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
    },
  },

  boxHeader: {
    textAlign: "center",
    cursor: "pointer",
  },

  image: {
    height: 127,
    minHeight: 127,
    maxWidth: "100%",
    objectFit: "contain",
    backgroundColor: "#B3B5BA",
  },

  boxBody: {
    height: "-webkit-fill-available",
    display: "flex",
    flexDirection: "column",
    justifyContent: " space-between",
  },

  contentHeader: {
    fontSize: 14,
    lineHeight: "22px",
    width: "100%",
    fontWeight: 600,
    cursor: "pointer",
    color: "#3e4045",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    padding: "16px 16px 0px",
  },

  contentBody: {
    marginTop: 8,
    fontSize: 12,
    lineHeight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    padding: "0 16px",
  },

  contentFooter: {
    padding: "16px 16px 2px",
    display: "flex",
    justifyContent: "space-between",
    height: 38,
  },

  prefixContentFooter: {
    display: "flex",
    alignItems: "flex-start",
  },

  prefixAccessTime: {
    width: 15,
    height: 15,
    color: theme.palette.grey[500],
  },

  prefixTypoService: {
    marginLeft: 7,
    fontSize: 12,
    color: theme.palette.grey[500],
  },

  prefixTypoProduct: {
    fontSize: 12,
    marginRight: 10,
  },

  prefixIconType1: {
    width: 15,
    height: 15,
    color: "#65b39d",
  },

  prefixIconType2: {
    width: 15,
    height: 15,
    color: "#d4d5d8",
  },

  suffixesContentFooter: {
    fontSize: 14,
  },

  boxFooter: {
    height: 56,
    padding: 16,
    textAlign: "center",
  },

  buttonDelete: {
    height: 24,
    marginRight: 8,
    padding: 0,
    minWidth: 53,
    fontSize: 11,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },

  buttonEdit: {
    height: 24,
    marginLeft: 8,
    padding: 0,
    minWidth: 53,
    fontSize: 11,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#feba40",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#da962e",
    },
  },

  itemRatingDetail: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 4,
    borderBottom: "1px solid" + theme.palette.grey[200],
    padding: "0 16px 8px 16px",
  },

  ratingScore: {
    display: "flex",
    alignItems: "center",
    lineHeight: "20px",
    "&>svg": {
      height: "100%",
      color: "#FEBA40",
      fontSize: 15,
      marginRight: 3,
    },
  },

  ratingDetailButton: {
    color: theme.palette.text.link,
    padding: 0,
    textTransform: "none",
    lineHeight: "20px",
  },

  copyButton: {
    display: "none",
    width: 30,
    height: 30,
    position: "absolute",
    top: 2,
    right: 2,
    textTransform: "none",
    padding: "4px 8px",
    borderRadius: 4,
    minHeight: "unset",
    backgroundColor: theme.palette.white,
    border: "1.5px solid " + theme.palette.grey[200],
    "&:hover": {
      backgroundColor: "#c2c2c2",
    },
  },

  copyIcon: {
    fontSize: 18,
    color: "#4a4a4a",
  },

  popoverCopyIcon: {
    width: "fit-content",
    height: "fit-content",
    lineHeight: "22px",
    color: theme.palette.white,
  },
}));

export default memo(ItemDescriptionBlock);
