import React, { memo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import PropTypes from "prop-types";

const CustomDayOfWeek = ({ selectedButton, onSelect, isDisableDays }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={selectedButton}
      classes={{ root: classes.rootGroup, groupedHorizontal: classes.groupedHorizontalButton }}
      onChange={(event, data) => onSelect(data)}
      exclusive
    >
      {LangConstant.ARR_DAY_OF_WEEK.map((day, index) => (
        <ToggleButton
          value={index}
          aria-label="day"
          classes={{ root: classes.rootButton, selected: classes.selectedDay, disabled: classes.disableButton }}
          key={index}
          disabled={isDisableDays && isDisableDays.includes(index)}
          disableRipple={true}
        >
          <Typography variant="body2" classes={{ body2: "medium-lg-txt" }} color="inherit">
            {getLabel(day)}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles(theme => ({
  rootGroup: {
    height: "100%",
    flexWrap: "wrap",
  },
  groupedHorizontalButton: {
    marginLeft: "0px !important",
    border: "none",
  },
  rootButton: {
    height: 40,
    minWidth: 110,
    padding: 0,
    marginRight: 4,
    color: "#3E4045",
    backgroundColor: theme.palette.grey[100],
    textTransform: "none",
    borderRadius: "0%",
    border: "none",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.text.link,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 15,
    },
  },
  selectedDay: {
    color: `${theme.palette.white} !important`,
    backgroundColor: "#729fff !important",
  },
  disableButton: {
    backgroundColor: "rgba(114, 159, 255, 0.6)",
    color: `${theme.palette.white} !important`,
  },
}));

CustomDayOfWeek.propTypes = {
  className: PropTypes.string,
  inputProps: PropTypes.object,
  selectedArr: PropTypes.array,
};

CustomDayOfWeek.defaultProps = {
  className: "",
  selectedArr: [],
};

export default memo(CustomDayOfWeek);
