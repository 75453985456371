import React, { memo } from "react";
import { Avatar, Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { ApiConstant, LangConstant, PathConstant } from "../../const";
import { useTranslation } from "react-i18next";
import { openSocialWindow } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";
import * as AppConstant from "../../const/app.const";

const SocialLogin = () => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  var invitation = "";
  if (
    searchParams &&
    searchParams.get(AppConstant.KEY_SHOP_INVITATION) &&
    searchParams.get(AppConstant.KEY_SHOP_INVITATION) !== ""
  ) {
    invitation = searchParams.get(AppConstant.KEY_SHOP_INVITATION);
  }

  const onHandling = () => {
    history.push(PathConstant.SELECT_SHOP);
  };

  const onLoginFB = () => {
    let url = ApiConstant.LOGIN_BY_FACEBOOK + `&${AppConstant.KEY_SHOP_INVITATION}=` + invitation;
    openSocialWindow(url, onHandling);
  };

  const onLoginGoogle = () => {
    let url = ApiConstant.LOGIN_BY_GOOGLE + `&${AppConstant.KEY_SHOP_INVITATION}=` + invitation;
    openSocialWindow(url, onHandling);
  };

  return (
    <Box className="center-root">
      <Typography variant="body2">{getLabel(LangConstant.MSG_LOGIN_WITH_SOCIAL)}</Typography>
      <IconButton className={classes.iconButton} onClick={onLoginFB}>
        <Avatar src="/images/ic-facebook.png" alt="Login Facebook" className={classes.icon} />
      </IconButton>
      <IconButton className={classes.iconButton} onClick={onLoginGoogle}>
        <Avatar src="/images/ic-google.png" alt="Login Google" className={classes.icon} />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles({
  icon: {
    width: "24px",
    height: "24px",
  },
  iconButton: { padding: "4px" },
});

SocialLogin.propTypes = {};
SocialLogin.defaultProps = {};

export default memo(SocialLogin);
