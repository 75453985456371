import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Box, IconButton, AccordionDetails, Grid } from "@material-ui/core";
import { CreateOutlined, DeleteOutlined } from "@material-ui/icons";
import EditDialog from "./SubCategoryAccordion/EditDialog";
import ServiceDialog, { HOUR_UNIT } from "./ServiceDialog";
import PlaceItemAction from "../../../../../redux/place-item.redux";
import ServiceCategoryAction from "../../../../../redux/service-category.redux";
import { useDispatch } from "react-redux";
import { LangConstant } from "../../../../../const";
import { useTranslation } from "react-i18next";

const CategoryAccordionDetails = props => {
  const {
    data,
    placeItemRedux,
    serviceCategoryRedux,
    classRoot,
    style,
    directCall: isService,
    titleName,
    onDelete,
  } = props;
  const { updateData: serviceDataUpdate } = serviceCategoryRedux;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [editPlaceItemDialog, setEditPlaceItemDialog] = useState(false);
  const [editServiceDialog, setEditServiceDialog] = useState(false);
  const [item, setItem] = useState(data);
  const [apiType, setApiType] = useState();
  const [timeUnit, setTimeUnit] = useState();
  const [clickable, setClickable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      apiType === API_UPDATE_PLACE_ITEM &&
      !isService &&
      placeItemRedux.data != null &&
      !placeItemRedux.isUpdateFetching &&
      item.uid === placeItemRedux.data.uid
    ) {
      //Edit place item
      const tmpItem = { ...item };
      const editedItem = Object.assign(tmpItem, placeItemRedux.data);

      setItem(editedItem);
      setApiType(null);
      setEditPlaceItemDialog(false);
    }
  }, [placeItemRedux.data]);

  useEffect(() => {
    if (
      apiType === API_UPDATE_SERVICE_CATEGORY &&
      isService &&
      serviceDataUpdate != null &&
      !serviceCategoryRedux.isUpdateFetching &&
      item.uid === serviceDataUpdate.uid
    ) {
      //Edit service category
      let usingTime = serviceDataUpdate.usingTime;
      const tmpItem = { ...item };
      const editedItem = Object.assign(tmpItem, serviceDataUpdate);

      if (timeUnit === HOUR_UNIT) {
        usingTime = usingTime * 60;
      }
      editedItem.usingTime = usingTime;
      setItem(editedItem);
      setApiType(null);
      setEditServiceDialog(false);
    }
  }, [serviceDataUpdate]);

  const onEditItem = (event, newData, timeUnitIndex) => {
    event.stopPropagation();
    //Edit place
    if (timeUnitIndex == null) {
      if (newData.name !== null && newData.name !== "" && newData.capacity !== null && newData.capacity !== "") {
        event.preventDefault();
        dispatch(PlaceItemAction.updatePlaceItem({ uid: item.uid, name: newData.name, capacity: newData.capacity }));
        setApiType(API_UPDATE_PLACE_ITEM);
      }
    }
    //Edit service
    else {
      setTimeUnit(timeUnitIndex);
      if (newData.name !== null && newData.name !== "" && newData.usingTime !== null && newData.usingTime !== "") {
        event.preventDefault();
        dispatch(
          ServiceCategoryAction.updateServiceCategory({
            uid: item.uid,
            name: newData.name,
            description: newData.description,
            usingTime: newData.usingTime,
          }),
        );
        setApiType(API_UPDATE_SERVICE_CATEGORY);
      }
    }
  };

  const onSelectDelete = (event, itemUid) => {
    setClickable(true);
    onDelete(event, itemUid);
    setTimeout(() => {
      setClickable(false);
    }, 1000);
  };

  const onOpenEditDialog = event => {
    event.stopPropagation();
    setEditPlaceItemDialog(true);
  };

  const onCloseEditDialog = event => {
    event.stopPropagation();
    setEditPlaceItemDialog(false);
  };

  const onEditServiceDialog = event => {
    event.stopPropagation();
    setEditServiceDialog(true);
  };

  const onCloseEditServiceDialog = event => {
    event.stopPropagation();
    setEditServiceDialog(false);
  };

  return (
    <Box className={`${classes.boxRoot} ${classRoot}`} style={style}>
      <AccordionDetails className={classes.accordionRoot}>
        <Grid container className={classes.detailsBox}>
          <Grid item xs={2} lg={1} style={{ paddingLeft: 50 }}>
            <Typography variant="subtitle2" classes={{ subtitle2: "medium-md-txt" }} className={classes.textHeading}>
              {isService && item.name}
            </Typography>
          </Grid>
          <Grid item className={classes.itemBox} xs={3}>
            <Typography
              variant="subtitle2"
              classes={{ subtitle2: "medium-md-txt" }}
              className={classes.textHeading}
              style={{ paddingRight: 20 }}
            >
              {!isService && item.name}
              {isService && `${item.usingTime} ${getLabel(LangConstant.TXT_MINUTES)}`}
            </Typography>
          </Grid>
          <Grid item className={classes.itemBox} xs={4}>
            <Typography
              variant="subtitle2"
              classes={{ subtitle2: "medium-md-txt" }}
              className={isService ? classes.descriptionHeading : classes.textHeading}
              style={{ paddingRight: 44 }}
            >
              {!isService && item.capacity}
              {isService && item.description}
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "right" }} xs={3} lg={4}>
            <IconButton
              onClick={e => (isService ? onEditServiceDialog(e) : onOpenEditDialog(e))}
              className={classes.iconButton}
            >
              <CreateOutlined className={classes.icon} />
            </IconButton>
            <IconButton onClick={e => onSelectDelete(e, item.uid)} disabled={clickable}>
              <DeleteOutlined className={classes.icon}> </DeleteOutlined>
            </IconButton>
          </Grid>
        </Grid>
      </AccordionDetails>
      {editPlaceItemDialog && (
        <EditDialog onClose={onCloseEditDialog} titleName={titleName} onSubmit={onEditItem} data={item}></EditDialog>
      )}
      {editServiceDialog && (
        <ServiceDialog
          onClose={onCloseEditServiceDialog}
          onSubmit={onEditItem}
          isAdd={false}
          data={item}
          titleName={item.name}
        ></ServiceDialog>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    width: "100%",
    justifyContent: "space-between",
  },
  accordionRoot: {
    height: "100%",
    backgroundColor: "#fafafb",
    [theme.breakpoints.down("sm")]: {
      width: "1060px",
    },
    padding: "0px 0px",
  },
  detailsBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 4,
    paddingTop: 10,
    color: theme.palette.grey[600],
    "&:last-child": {
      paddingBottom: 10,
    },
  },
  itemBox: {
    textAlign: "center",
  },
  textHeading: {
    color: "theme.palette.grey[600]",
  },
  descriptionHeading: {
    color: "theme.palette.grey[600]",
    overflow: "hidden",
    overflowWrap: "break-word",
    textAlign: "justify",
  },
  iconExpand: {
    color: theme.palette.grey[500],
    border: "none",
  },
  iconButton: {
    marginRight: 19,
  },
  icon: {
    color: theme.palette.grey[500],
    width: 21,
    height: 21,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const API_UPDATE_PLACE_ITEM = 1;
const API_UPDATE_SERVICE_CATEGORY = 2;

CategoryAccordionDetails.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.object,
  //Arccordion details is called from Service Category directly
  directCall: PropTypes.bool,
  titleName: PropTypes.string,
  onDelete: PropTypes.func,
  classRoot: PropTypes.string,
  placeItemRedux: PropTypes.object,
  serviceCategoryRedux: PropTypes.object,
};

CategoryAccordionDetails.defaultProps = { style: {}, directCall: false, placeItemRedux: {}, serviceCategoryRedux: {} };

export default memo(CategoryAccordionDetails);
