import { all, call, put } from "redux-saga/effects";
import { ApiConstant, AppConstant } from "../const";
import ReportDashboardAction from "../redux/report-dashboard.redux";
import { PlaceCategoryService, ReportDashboardService, ServiceCategoryService, UserService } from "../services";

export function* requestReportSummaryReservation(action) {
  try {
    const { data } = action;
    const [response, responseSetting] = yield all([
      call(ReportDashboardService.reportSummaryReservation, data),
      call(UserService.getChartDisplaySetting, DISPLAY_SETTING_BY_RESERVATION),
    ]);
    let isSummarySuccess = response.status === ApiConstant.STT_OK;
    let isSettingSuccess = responseSetting.status === ApiConstant.STT_OK;

    if (isSummarySuccess && isSettingSuccess) {
      let result = {
        data: createCharByReservation(response.data.data),
        setting: responseSetting.data.data,
        viewBy: data.view,
      };
      yield put(ReportDashboardAction.reportDashboardSuccess({ summaryReservation: result }));
    } else {
      let errors = !isSummarySuccess ? response.data : responseSetting.data;
      yield put(ReportDashboardAction.reportDashboardFailure(errors));
    }
  } catch (error) {
    yield put(ReportDashboardAction.reportDashboardFailure(error));
  }
}

export function* requestReportSummaryCustomer(action) {
  try {
    const { data } = action;
    const [response, responseSetting] = yield all([
      call(ReportDashboardService.reportSummaryCustomer, data),
      call(UserService.getChartDisplaySetting, DISPLAY_SETTING_BY_CUSTOMER),
    ]);
    let isSummarySuccess = response.status === ApiConstant.STT_OK;
    let isSettingSuccess = responseSetting.status === ApiConstant.STT_OK;

    if (isSummarySuccess && isSettingSuccess) {
      let result = {
        data: createCharByReservation(response.data.data),
        setting: responseSetting.data.data,
        viewBy: data.view,
      };
      yield put(ReportDashboardAction.reportDashboardSuccess({ summaryCustomer: result }));
    } else {
      let errors = !isSummarySuccess ? response.data : responseSetting.data;
      yield put(ReportDashboardAction.reportDashboardFailure(errors));
    }
  } catch (error) {
    yield put(ReportDashboardAction.reportDashboardFailure(error));
  }
}

export function* requestReportSummaryPlace(action) {
  try {
    const { data } = action;
    const [response, responseSetting, responseList] = yield all([
      call(ReportDashboardService.reportSummaryCategory, data),
      call(UserService.getChartDisplaySetting, DISPLAY_SETTING_BY_PLACE),
      call(PlaceCategoryService.get),
    ]);
    let isSummarySuccess = response.status === ApiConstant.STT_OK;
    let isSettingSuccess = responseSetting.status === ApiConstant.STT_OK;

    if (isSummarySuccess && isSettingSuccess) {
      yield put(
        ReportDashboardAction.reportDashboardSuccess({
          summaryPlace: createChartByCategory(response, responseSetting, responseList, data.view),
        }),
      );
    } else {
      let errors = !isSummarySuccess ? response.data : responseSetting.data;
      yield put(ReportDashboardAction.reportDashboardFailure(errors));
    }
  } catch (error) {
    yield put(ReportDashboardAction.reportDashboardFailure(error));
  }
}

export function* requestReportSummaryService(action) {
  try {
    const { data } = action;
    const [response, responseSetting, responseList] = yield all([
      call(ReportDashboardService.reportSummaryCategory, data),
      call(UserService.getChartDisplaySetting, DISPLAY_SETTING_BY_SERVICE),
      call(ServiceCategoryService.get),
    ]);
    let isSummarySuccess = response.status === ApiConstant.STT_OK;
    let isSettingSuccess = responseSetting.status === ApiConstant.STT_OK;

    if (isSummarySuccess && isSettingSuccess) {
      yield put(
        ReportDashboardAction.reportDashboardSuccess({
          summaryService: createChartByCategory(response, responseSetting, responseList, data.view),
        }),
      );
    } else {
      let errors = !isSummarySuccess ? response.data : responseSetting.data;
      yield put(ReportDashboardAction.reportDashboardFailure(errors));
    }
  } catch (error) {
    yield put(ReportDashboardAction.reportDashboardFailure(error));
  }
}

export function* requestCreateDisplaySetting(action) {
  try {
    const { data } = action;
    let response = yield call(UserService.createChartDisplaySetting, data);

    if (response.status === ApiConstant.STT_OK) {
      yield put(ReportDashboardAction.reportDashboardSuccess());
    } else {
      yield put(ReportDashboardAction.reportDashboardFailure(response.data));
    }
  } catch (error) {
    yield put(ReportDashboardAction.reportDashboardFailure(error));
  }
}

const createCharByReservation = data => data.map(itemParent => ({ ...itemParent, ...itemParent.details }));
const createChartByCategory = (response, responseSetting, responseList, viewBy) => {
  let responseData = response.data.data;
  let responseSettingData = responseSetting.data.data;
  let responseListData = responseList.data.data.map(item => ({ display_id: item.uid, name: item.name }));

  let isDisplaySetting = responseListData.length > AppConstant.MINIMUM_NUMBER_DISPLAY_SETTINGS;
  let displaySetting = isDisplaySetting ? responseSettingData.details : responseListData;

  responseSettingData.details = displaySetting.map((item, index) => ({
    ...item,
    color: AppConstant.CHART_COLORS[index],
  }));

  return {
    data: createCharByReservation(responseData),
    setting: responseSettingData,
    list: responseListData,
    viewBy: viewBy,
  };
};

const DISPLAY_SETTING_BY_RESERVATION = { type: AppConstant.DISPLAY_CHART_SETTING_TYPE.reportByReservation };
const DISPLAY_SETTING_BY_CUSTOMER = { type: AppConstant.DISPLAY_CHART_SETTING_TYPE.reportByCustomer };
const DISPLAY_SETTING_BY_PLACE = { type: AppConstant.DISPLAY_CHART_SETTING_TYPE.reportByPlace };
const DISPLAY_SETTING_BY_SERVICE = { type: AppConstant.DISPLAY_CHART_SETTING_TYPE.reportByService };
