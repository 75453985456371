import { call, put } from "redux-saga/effects";
import { ApiConstant, AppConstant } from "const";
import AuthAction from "redux/auth.redux";
import GroupAdminAction from "redux/group-admin.redux";
import { AuthService } from "services";
import Cookie from "js-cookie";

export function* requestLogin(action) {
  try {
    const { data } = action;
    let response = yield call(AuthService.login, data);
    const { username, invitation, isGroup } = data;
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      Cookie.set(AppConstant.KEY_GBOSS_LOGIN, JSON.stringify({ responseData: responseData }), {
        expires: AppConstant.EXPIRES_TOKEN,
      });
      Cookie.set(AppConstant.NAME_LOGIN, username, {
        expires: AppConstant.EXPIRES_TOKEN,
      });
      let { cons_token } = response.data;
      if (invitation && cons_token) {
        let sendData = { ...data, cons_token: cons_token };
        yield requestInvitationAccept({ data: { uids: { uids: [invitation] }, cons_token: cons_token } });
        delete sendData.invitation;
        delete sendData.cons_token;
        action.data = sendData;
        yield requestLogin(action);
        return;
      }
      yield put(AuthAction.authSuccess({ isLogin: true }));
      if (isGroup) yield put(GroupAdminAction.groupAdminSet({ isJoinGroupAdmin: true }));
    } else {
      yield put(AuthAction.authFailure(response.data));
    }
  } catch (error) {
    yield put(AuthAction.authFailure(error));
  }
}

export function* requestPassword(action) {
  try {
    const { data } = action;
    let response = yield call(AuthService.forgotPassword, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(AuthAction.authSuccess({ isPassword: true }));
    } else {
      yield put(AuthAction.authFailure(response.data));
    }
  } catch (error) {
    yield put(AuthAction.authFailure(error));
  }
}

export function* requestInvitationAccept(action) {
  try {
    const { data } = action;
    let response = yield call(AuthService.patchInvitationAccept, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(AuthAction.invitationAcceptSuccess());
    } else {
      yield put(AuthAction.invitationAcceptFailure());
    }
  } catch (error) {
    yield put(AuthAction.invitationAcceptFailure(error));
  }
}
