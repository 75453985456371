import { createReducer, createActions } from "reduxsauce";
import { getError } from "utils";
import Cookie from "js-cookie";
import { AppConstant } from "const";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestLogin: ["data"],
  requestLogout: ["data"],
  requestSelectShop: ["data"],
  requestPassword: ["data"],
  requestInvitationAccept: ["data"],
  invitationAcceptSuccess: ["data"],
  invitationAcceptFailure: ["data"],
  authSuccess: ["data"],
  authFailure: ["data"],
  authReset: [],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  status: null,
  isLoginSelectShop: false,
  isLogin: false,
  isLoginFb: false,
  isPassword: false,
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => {
  const data = action.data ? action.data : {};
  let error = getError(data);
  return { ...state, isFetching: false, error: error, status: data.status };
};

export const reset = () => INITIAL_STATE;

export const requestSelectShop = (state = INITIAL_STATE, action) => {
  const { data } = action;
  if (data) {
    const { access_token, shop_id, user_id, role } = data;
    Cookie.set(AppConstant.KEY_GBOSS, JSON.stringify({ token: access_token, shopId: shop_id, userId: user_id }), {
      expires: AppConstant.EXPIRES_TOKEN,
    });
    Cookie.set(AppConstant.ROLE, role, { expires: AppConstant.EXPIRES_TOKEN });
  }
  return {
    ...state,
    ...action.data,
  };
};

export const requestInvitationAccept = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_LOGIN]: request,
  [Types.REQUEST_LOGOUT]: reset,
  [Types.REQUEST_SELECT_SHOP]: requestSelectShop,
  [Types.REQUEST_INVITATION_ACCEPT]: requestInvitationAccept,
  [Types.REQUEST_PASSWORD]: request,

  [Types.AUTH_SUCCESS]: success,
  [Types.AUTH_FAILURE]: failure,
  [Types.AUTH_RESET]: reset,
  [Types.INVITATION_ACCEPT_SUCCESS]: success,
  [Types.INVITATION_ACCEPT_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
