import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  postItemDetails: ["data"],
  postItemDetailsSuccess: ["data"],
  postItemDetailsFailure: ["data"],

  putItemDetails: ["data"],
  putItemDetailsSuccess: ["data"],
  putItemDetailsFailure: ["data"],

  getItemDetails: ["data"],
  getItemDetailsSuccess: ["data"],
  getItemDetailsFailure: ["data"],

  getListItemDetails: ["data"],
  getListItemDetailsSuccess: ["data"],
  getListItemDetailsFailure: ["data"],

  deleteItemDetails: ["data"],
  deleteItemDetailsSuccess: ["data"],
  deleteItemDetailsFailure: ["data"],

  putEditContent: ["data"],
  putEditContentSuccess: ["data"],
  putEditContentFailure: ["data"],

  resetItemDetails: ["data"],
  resetErrorItemDetails: ["data"],
});

export const ItemDetailsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  // post
  isPostItemDetailsFetching: false,
  errorPostItemDetails: null,
  isPostItemDetailsSuccess: false,
  dataPostItemDetails: null,

  // put
  isPutItemDetailsFetching: false,
  errorPutItemDetails: null,
  isPutItemDetailsSuccess: false,
  dataPutItemDetails: null,

  // get by Uid
  isGetItemDetailsFetching: false,
  errorGetItemDetails: null,
  isGetItemDetailsSuccess: false,
  dataGetItemDetails: null,

  // get list
  isGetListItemDetailsFetching: false,
  errorGetListItemDetails: null,
  isGetListItemDetailsSuccess: false,
  dataGetListItemDetails: null,

  // delete
  isDeleteItemDetailsFetching: false,
  errorDeleteItemDetails: null,
  isDeleteItemDetailsSuccess: false,
  dataDeleteItemDetails: null,

  // put
  isPutEditContentFetching: false,
  errorPutEditContent: null,
  isPutEditContentSuccess: false,
  dataPutEditContent: null,
};

/* ------------- Reducers ------------- */
/* ------------- Reducers Post Item Details ------------- */
export const postItemDetails = (state = INITIAL_STATE) => ({
  ...state,
  isPostItemDetailsFetching: true,
});

export const postItemDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPostItemDetailsFetching: false,
    isPostItemDetailsSuccess: true,
    dataPostItemDetails: action.data,
  };
};

export const postItemDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPostItemDetailsFetching: false,
  isPostItemDetailsSuccess: false,
  errorPostItemDetails: action?.data?.message,
});

/* ------------- Reducers Put Item Details ------------- */
export const putItemDetails = (state = INITIAL_STATE) => ({
  ...state,
  isPutItemDetailsFetching: true,
});

export const putItemDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPutItemDetailsFetching: false,
    isPutItemDetailsSuccess: true,
    dataPutItemDetails: action.data,
  };
};

export const putItemDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPutItemDetailsFetching: false,
  isPutItemDetailsSuccess: false,
  errorPutItemDetails: action?.data?.message,
});

/* ------------- Reducers Get By Uid Item Details ------------- */
export const getItemDetails = (state = INITIAL_STATE) => ({
  ...state,
  isGetItemDetailsFetching: true,
});

export const getItemDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetItemDetailsFetching: false,
    isGetItemDetailsSuccess: true,
    dataGetItemDetails: action.data,
  };
};

export const getItemDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetItemDetailsFetching: false,
  isGetItemDetailsSuccess: false,
  errorGetItemDetails: action.error,
});

/* ------------- Reducers Get List Item Details ------------- */
export const getListItemDetails = (state = INITIAL_STATE) => ({
  ...state,
  isGetListItemDetailsFetching: true,
});

export const getListItemDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetListItemDetailsFetching: false,
    isGetListItemDetailsSuccess: true,
    dataGetListItemDetails: action.data,
  };
};

export const getListItemDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetListItemDetailsFetching: false,
  isGetListItemDetailsSuccess: false,
  errorGetListItemDetails: action.error,
});

/* ------------- Reducers Delete Item Details ------------- */
export const deleteItemDetails = (state = INITIAL_STATE) => ({
  ...state,
  isDeleteItemDetailsFetching: true,
});

export const deleteItemDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isDeleteItemDetailsFetching: false,
    isDeleteItemDetailsSuccess: true,
    dataDeleteItemDetails: action.data,
  };
};

export const deleteItemDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isDeleteItemDetailsFetching: false,
  isDeleteItemDetailsSuccess: false,
  errorDeleteItemDetails: action.error,
});

/* ------------- Reducers Put Edit Content ------------- */
export const putEditContent = (state = INITIAL_STATE) => ({
  ...state,
  isPutEditContentFetching: true,
});

export const putEditContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPutEditContentFetching: false,
    isPutEditContentSuccess: true,
    dataPutEditContent: action.data,
  };
};

export const putEditContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPutEditContentFetching: false,
  isPutEditContentSuccess: false,
  errorPutEditContent: action.error,
});

/* ------------- Reducers Reset ------------- */
export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isPostItemDetailsSuccess: false,
  isPutItemDetailsSuccess: false,
  isGetItemDetailsSuccess: false,
  isGetListItemDetailsSuccess: false,
  isDeleteItemDetailsSuccess: false,
  isPutEditContentSuccess: false,
});

export const resetError = (state = INITIAL_STATE) => ({
  ...state,
  errorPostItemDetails: null,
  errorPutItemDetails: null,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  // Map Post Item Details
  [Types.POST_ITEM_DETAILS]: postItemDetails,
  [Types.POST_ITEM_DETAILS_SUCCESS]: postItemDetailsSuccess,
  [Types.POST_ITEM_DETAILS_FAILURE]: postItemDetailsFailure,

  // Map Put Item Details
  [Types.PUT_ITEM_DETAILS]: putItemDetails,
  [Types.PUT_ITEM_DETAILS_SUCCESS]: putItemDetailsSuccess,
  [Types.PUT_ITEM_DETAILS_FAILURE]: putItemDetailsFailure,

  // Map Get By Uid Item Details
  [Types.GET_ITEM_DETAILS]: getItemDetails,
  [Types.GET_ITEM_DETAILS_SUCCESS]: getItemDetailsSuccess,
  [Types.GET_ITEM_DETAILS_FAILURE]: getItemDetailsFailure,

  // Map Get List Item Details
  [Types.GET_LIST_ITEM_DETAILS]: getListItemDetails,
  [Types.GET_LIST_ITEM_DETAILS_SUCCESS]: getListItemDetailsSuccess,
  [Types.GET_LIST_ITEM_DETAILS_FAILURE]: getListItemDetailsFailure,

  // Map Delete Item Details
  [Types.DELETE_ITEM_DETAILS]: deleteItemDetails,
  [Types.DELETE_ITEM_DETAILS_SUCCESS]: deleteItemDetailsSuccess,
  [Types.DELETE_ITEM_DETAILS_FAILURE]: deleteItemDetailsFailure,

  // Map Put Edit Content
  [Types.PUT_EDIT_CONTENT]: putEditContent,
  [Types.PUT_EDIT_CONTENT_SUCCESS]: putEditContentSuccess,
  [Types.PUT_EDIT_CONTENT_FAILURE]: putEditContentFailure,

  // Reset Item Details
  [Types.RESET_ITEM_DETAILS]: reset,
  [Types.RESET_ERROR_ITEM_DETAILS]: resetError,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
