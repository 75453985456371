import React, { memo, useState } from "react";
import { makeStyles, Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { LangConstant } from "../../../../const";
import { useTranslation } from "react-i18next";

const MenuBodyInvite = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { cellData, onChange, indexEmail } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(cellData - 2);

  const listRole = LangConstant.ARR_ROLE;

  const onClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChange(index + 2, indexEmail);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.boxRoot}>
      <IconButton
        onClick={onClickListItem}
        classes={{ root: classes.btnRoot, label: `medium-md-txt ${classes.btnLabel}` }}
      >
        {getLabel(listRole[selectedIndex])}
        {<ArrowDropDown className={classes.icon} />}
      </IconButton>
      {selectedIndex !== 0 && (
        <Menu
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ list: classes.menuList, paper: classes.menuPaper }}
        >
          {listRole.map(
            (option, index) =>
              index !== selectedIndex &&
              index !== 0 && (
                <MenuItem
                  onClick={event => onMenuItemClick(event, index)}
                  className={`medium-md-txt ${classes.menuItemRoot}`}
                  key={index}
                >
                  {getLabel(option)}
                </MenuItem>
              ),
          )}
        </Menu>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    height: "100%",
  },
  btnRoot: {
    color: "#787878",
    flexDirection: "row",
    borderRadius: 0,
    height: "100%",
    width: 120,
    paddingRight: 0,
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  btnLabel: {
    justifyContent: "unset",
  },
  menuPaper: {
    marginTop: 2,
    borderRadius: 0,
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: 300,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    minHeight: 30,
    width: 120,
    color: "#9d9fa5",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
  },
  icon: {
    right: 8,
    position: "absolute",
    color: theme.palette.grey[500],
  },
}));

export default memo(MenuBodyInvite);
