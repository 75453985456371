import React, { memo, useState } from "react";
import {
  makeStyles,
  IconButton,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Check, DeleteOutlined, CreateOutlined } from "@material-ui/icons";
import { LangConstant } from "../../../../const";
import { InviteStaffIcon, ArticleIcon } from "../../../../icons";
import { useTranslation } from "react-i18next";
import { DialogConfirm } from "../index";
import StaffDetail from "../StaffDetail/StaffDetail";
import { useDispatch } from "react-redux";
import StaffAction from "../../../../redux/staff.redux";
import { format } from "react-string-format";

const StatusInvite = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cellData, email, name, role, showData, uid, isInvited, onChangeInvite, showTime, showPosition } = props;
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState(null);
  const [delAlert, openDelAlert] = useState(false);

  const onDeleteStaff = () => {
    dispatch(StaffAction.deleteStaff({ uid: uid }));
    openDelAlert(false);
    dispatch(StaffAction.requestStaffTable({ page: 1, paging: 1, size: 30 }));
  };

  const primaryButton = index => {
    if (index) {
      return (
        <Tooltip title={getLabel(LangConstant.TXT_JOINED)} classes={{ tooltip: classes.tooltip }}>
          <Box className={classes.checkedIcon}>
            <Check className={classes.iconStatus} />
          </Box>
        </Tooltip>
      );
    } else if (isInvited === 1 && email) {
      return (
        <Tooltip title={getLabel(LangConstant.TXT_INVITED)} classes={{ tooltip: classes.tooltip }}>
          <IconButton disableRipple className={classes.inviteButtonDisabled}>
            <InviteStaffIcon className={classes.inviteIcon} />
          </IconButton>
        </Tooltip>
      );
    } else if (isInvited === 0 && email) {
      return (
        <Tooltip title={getLabel(LangConstant.TXT_INVITE)} classes={{ tooltip: classes.tooltip }}>
          <IconButton
            disableRipple
            onClick={() => {
              setOpen(true);
            }}
            className={classes.inviteButton}
          >
            {<InviteStaffIcon className={classes.inviteIcon} />}
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={getLabel(LangConstant.TXT_HAVE_NOT_EMAIL)} classes={{ tooltip: classes.tooltip }}>
          <Box className={classes.borderNull} />
        </Tooltip>
      );
    }
  };

  const onOpenProfile = uid => {
    let user = showData.find(x => x.uid === uid);
    setOpenProfile(true);
    setUser(user);
  };

  const onOpenEditStaff = uid => {
    let user = showData.find(x => x.uid === uid);
    setOpenProfile(true);
    setIsEditing(true);
    setUser(user);
  };

  return (
    <>
      <Box className={classes.boxParent}>
        {primaryButton(cellData)}
        <Tooltip title={getLabel(LangConstant.TXT_SEEN_DETAIL)} classes={{ tooltip: classes.tooltip }}>
          <IconButton disableRipple onClick={() => onOpenProfile(uid)} className={classes.borderIconButton}>
            {<ArticleIcon className={classes.iconStyle} />}
          </IconButton>
        </Tooltip>
        <Tooltip title={getLabel(LangConstant.TXT_EDIT)} classes={{ tooltip: classes.tooltip }}>
          <Box component="span">
            <IconButton disableRipple onClick={() => onOpenEditStaff(uid)} className={classes.borderIconEdit}>
              {<CreateOutlined className={classes.iconStyle} />}
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip title={getLabel(LangConstant.TXT_DELETE)} classes={{ tooltip: classes.tooltip }}>
          <IconButton disableRipple className={classes.borderIconDelete} onClick={() => openDelAlert(true)}>
            {<DeleteOutlined className={classes.iconStyle} />}
          </IconButton>
        </Tooltip>
      </Box>
      <StaffDetail
        isShow={openProfile}
        setIsShow={setOpenProfile}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        user={user}
        showTime={showTime}
        showPosition={showPosition}
      />
      <DialogConfirm
        onChangeInvite={onChangeInvite}
        uid={uid}
        email={email}
        name={name}
        role={role}
        open={open}
        setOpen={setOpen}
      />
      <Dialog
        open={delAlert}
        onClose={() => {
          openDelAlert(false);
        }}
        aria-labelledby="alert-title"
        aria-describedby="alert-description"
        classes={{ paper: classes.DelDialog }}
      >
        <DialogTitle id="alert-title">{format(getLabel(LangConstant.TXT_DELETE_STAFF_POPUP_TITLE), name)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-description" className={classes.DialogTitle}>
            {format(getLabel(LangConstant.TXT_DELETE_STAFF_MSG), name)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <Button
            onClick={() => {
              openDelAlert(false);
            }}
            className={classes.ActionBtns}
          >
            {getLabel(LangConstant.TXT_SHOP_CANCEL)}
          </Button>
          <Button onClick={() => onDeleteStaff()} autoFocus className={classes.ActionBtns}>
            {getLabel(LangConstant.TXT_CONFIRM)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  iconStatus: {
    width: 18,
    height: 18,
    marginTop: 9,
    marginBottom: 9,
    color: "#46cf70",
  },
  boxParent: {
    display: "flex",
    justifyContent: "center",
    height: 40,
  },
  borderNull: {
    minWidth: 36,
    height: 36,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 2,
    padding: 0,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  borderIconButton: {
    minWidth: 36,
    height: 36,
    margin: 2,
    padding: 0,
    color: "#6c7078",
    border: "1px solid #fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#447aff",
      border: "1px solid #447aff",
    },
  },
  borderIconEdit: {
    minWidth: 36,
    height: 36,
    margin: 2,
    padding: 0,
    color: "#6c7078",
    border: "1px solid #fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#feba40",
      border: "1px solid #feba40",
    },
  },
  borderIconDelete: {
    minWidth: 36,
    height: 36,
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 2,
    padding: 0,
    color: "#6c7078",
    borderRadius: 0,
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#ef5845",
      border: "1px solid #ef5845",
    },
  },
  inviteIcon: {
    marginLeft: 5,
    fontSize: 29.75,
    color: "#6c7078",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#447aff",
    },
  },
  checkedIcon: {
    width: 36,
    height: 36,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 2,
    borderRadius: 0,
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #46cf70",
    },
  },
  iconStyle: {
    fontSize: 18,
  },
  inviteButton: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    color: "#447aff",
    minWidth: 36,
    height: 36,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 2,
    opacity: 1,
    borderRadius: 0,
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#447aff",
      border: "1px solid #447aff",
    },
  },
  inviteButtonDisabled: {
    fontWeight: "inherit",
    textTransform: "none",
    padding: "0 0 0",
    color: "#447aff",
    minWidth: 36,
    height: 36,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 2,
    opacity: 0.3,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#447aff",
      border: "1px solid #447aff",
    },
  },
  tooltip: {
    marginLeft: 50,
    marginTop: 2,
    backgroundColor: "#7f838c",
    fontWeight: 500,
    fontSize: 12,
  },
  DialogActions: {
    borderTop: "1px solid #ebeced",
    paddingRight: "2vw",
  },
  ActionBtns: {
    color: "#9d9fa5",
  },
  DialogTitle: {
    color: "#6c7078",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
  },
  DelDialog: {
    minHeight: "188px",
    width: "30%",
    "@media only screen and (max-width: 660px)": {
      width: "auto",
    },
  },
}));

export default memo(StatusInvite);
