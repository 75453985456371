import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, debounce, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { parse, format as DateFormat, compareAsc } from "date-fns";
import { useHistory } from "react-router-dom";
import { AppConstant, LangConstant } from "const";
import CustomSearchBar from "pages/MnNews/components/CustomSearchBar";
import { getCommonKey, parseParams } from "utils";
import { PrimaryCustomDatePicker } from "components";
import SelectGroupFilterMenu from "./SelectGroupFilterMenu";
import ReservationHistoryAction from "redux/reservation-history.redux";
import { getEndOfDay, getStartOfDay } from "utils/date";

const FilterMenu = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = getQueryData(history.location.search, history);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedDates, setSelectedDates] = useState(queryParams);

  const onFilterTimeRange = rangeDate => {
    if (rangeDate.from && rangeDate.to) {
      setSelectedDates(rangeDate);
      onCallApi({
        start: getStartOfDay(rangeDate.from),
        end: getEndOfDay(rangeDate.to),
      });
      updateQueryParams(rangeDate, history);
    }
  };

  const onCallApi = debounce(
    params => dispatch(ReservationHistoryAction.getBookingHistory(params)),
    AppConstant.DEBOUNCE_TIMEOUT,
  );

  useEffect(() => {
    dispatch(
      ReservationHistoryAction.getBookingHistory({
        start: getStartOfDay(queryParams.from),
        end: getEndOfDay(queryParams.to),
      }),
    );
  }, []);

  return (
    <Box className={classes.bookingHistoryMenu}>
      <Box className={classes.dateAndSearchBox}>
        <PrimaryCustomDatePicker
          dateRange={selectedDates}
          onChange={onFilterTimeRange}
          isPickRangeDate={true}
          onClickToday={onFilterTimeRange}
        />
        {isMobile && (
          <CustomSearchBar
            placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
            className={classes.bookingHistorySearch}
            onChange={event => onCallApi({ filter: event.target.value })}
          />
        )}
      </Box>
      <SelectGroupFilterMenu onChangeFilter={onCallApi} />
      {!isMobile && (
        <CustomSearchBar
          placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
          className={classes.bookingHistorySearch}
          onChange={event => onCallApi({ filter: event.target.value })}
        />
      )}
    </Box>
  );
};

export default FilterMenu;

export const getQueryData = (queryString, history) => {
  let queryObject = parseParams(queryString);
  let today = new Date();
  let result = { from: today, to: today };
  try {
    if (queryObject[AppConstant.KEY_START_TIME]) {
      let dateObj = parse(queryObject[AppConstant.KEY_START_TIME], AppConstant.FM_DD_MM_YYYY_SECOND, today);
      result.from = dateObj;
    }
    if (queryObject[AppConstant.KEY_BOOK_END_TIME]) {
      let dateObj = parse(queryObject[AppConstant.KEY_BOOK_END_TIME], AppConstant.FM_DD_MM_YYYY_SECOND, today);
      result.to = dateObj;
    }

    if (queryObject[AppConstant.KEY_UID]) {
      result.uid = queryObject[AppConstant.KEY_UID];
    }

    let isNotValidStartDate = !result.from.isValid();
    if (isNotValidStartDate) result.from = today;

    let isNotValidEndDate = !result.to.isValid();
    if (isNotValidEndDate) result.to = today;

    let isEndDateBeforeStartDate = compareAsc(result.from, result.to) > 0;
    if (isEndDateBeforeStartDate) {
      result.to = result.from;
    }

    if (isNotValidStartDate || isNotValidEndDate || isEndDateBeforeStartDate) updateQueryParams(result, history);
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const updateQueryParams = (data, history, pathname) => {
  let queryParamsObj = {};
  if (data.from) queryParamsObj[AppConstant.KEY_START_TIME] = DateFormat(data.from, AppConstant.FM_DD_MM_YYYY_SECOND);

  if (data.to) queryParamsObj[AppConstant.KEY_BOOK_END_TIME] = DateFormat(data.to, AppConstant.FM_DD_MM_YYYY_SECOND);

  if (data.uid) queryParamsObj[AppConstant.KEY_UID] = data.uid;

  var strQueryParams = new URLSearchParams(queryParamsObj).toString();
  let configRouter = { search: strQueryParams };
  if (pathname) configRouter.pathname = pathname;
  history.push(configRouter);
};

const useStyles = makeStyles(theme => ({
  bookingHistoryMenu: {
    height: 108,
    display: "flex",
    marginBottom: 20,
    padding: "16px 24px",
    [theme.breakpoints.down(660)]: {
      height: "auto",
      flexDirection: "column",
    },
  },
  bookingHistorySearch: {
    height: 30,
    [theme.breakpoints.down(660)]: {
      marginTop: 10,
    },
  },

  dateAndSearchBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));
