import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Clear, CropFree } from "@material-ui/icons";
import { Box, Typography, makeStyles, CardMedia } from "@material-ui/core";
import MnNewsAction from "redux/mn-news.redux";
import clsx from "clsx";
import { onLoadImageError, validateFileSize, getCommonKey } from "utils";
import { AppConstant, LangConstant } from "const";
import CropImage from "components/CropImage";

const UploadImage = ({ onChange, isDisabled, defaultUrl }) => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [fileArray, setFileArray] = useState([]);
  const [isInputNotNull, setInputNotNull] = useState(false);
  const [clickBoxAvailableStatus, setClickBoxAvailableStatus] = useState(true);
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);
  const [inputImageCrop, setInputImageCrop] = useState([]);
  const imageURI = useSelector(state => state.newsRedux.postImgUrl);

  const onRemoveItemImage = () => {
    setFileArray([]);
    setInputImageCrop([]);
    document.getElementById("uploadInput").value = "";
    dispatch(MnNewsAction.mnNewsReset());
    onChange([], null);
  };

  const onUploadMultipleFiles = async e => {
    let uploadFile = e.target.files;
    var _URL = window.URL || window.webkitURL;
    var img = new Image();
    img.src = _URL.createObjectURL(uploadFile[0]);
    let newImg = URL.createObjectURL(uploadFile[0]);
    setInputImageCrop([uploadFile[0]]);
    setFileArray([newImg]);
    if (validateFileSize(uploadFile[0].size)) {
      dispatch(MnNewsAction.requestUploadPostImage({ file: [uploadFile[0]] }));
    } else {
      onOpenCropDialog();
    }
  };

  const onOpenCropDialog = () => {
    setIsOpenCropDialog(true);
  };

  const onUploadFileCrop = file => {
    if (validateFileSize(file.size)) {
      let newImg = URL.createObjectURL(file);
      setInputImageCrop([file]);
      setFileArray([newImg]);
      dispatch(MnNewsAction.requestUploadPostImage({ file: [file] }));
    } else {
      onChange(null, getLabel(getCommonKey(LangConstant.TXT_EXCEEDED_CAPACITY_UPLOAD_IMAGE)));
    }
  };

  useEffect(() => {
    let isEmptyInput = fileArray.length < 1;
    let isInputNotEmpty = fileArray.length > 0;
    setClickBoxAvailableStatus(isEmptyInput);
    setInputNotNull(isInputNotEmpty);
  }, [fileArray]);

  useEffect(() => {
    if (imageURI.length > 0) {
      onChange(imageURI, null);
      dispatch(MnNewsAction.mnNewsReset());
    }
  }, [imageURI]);

  return (
    <>
      <form className={classes.uploadFormGroup}>
        <Box className={isInputNotNull ? classes.previewBoxNotNull : classes.previewBoxIsNull}>
          <Box>
            {fileArray.length == 0 ? (
              <label
                htmlFor="uploadInput"
                className={clsx(
                  isInputNotNull ? classes.inputClickBoxPickedImg : classes.inputClickBoxInitial,
                  clickBoxAvailableStatus ? classes.inputClickBoxNotFull : classes.inputClickBoxFull,
                )}
              >
                <CardMedia
                  component="img"
                  variant="square"
                  src={defaultUrl}
                  onError={onLoadImageError}
                  className={defaultUrl ? classes.addIconInitial : classes.addIconInitialDefault}
                />
              </label>
            ) : (
              <UploadImageCell url={fileArray[0]} onOpenCrop={onOpenCropDialog} onRemoveImage={onRemoveItemImage} />
            )}
          </Box>
        </Box>
        {!isDisabled && (
          <input
            type="file"
            onChange={onUploadMultipleFiles}
            className={classes.fileUploadInput}
            id="uploadInput"
            disabled={isDisabled}
            accept={AppConstant.ACCEPT_IMAGE.join(",")}
          />
        )}
      </form>
      <CropImage
        isMultiple={false}
        isEnableCrop={true}
        inputArray={inputImageCrop}
        aspect={4 / 3}
        onSubmit={file => {
          onUploadFileCrop(file);
        }}
        isShow={isOpenCropDialog}
        title={getLabel(LangConstant.TXT_EDIT_IMAGE)}
        onClose={() => setIsOpenCropDialog(false)}
      />
    </>
  );
};

UploadImage.propTypes = {
  onChange: PropTypes.func,

  isDisabled: PropTypes.bool,

  defaultUrl: PropTypes.string,
};

const UploadImageCell = props => {
  const { url, onOpenCrop, onRemoveImage } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const [isHover, setIsHover] = useState();

  return (
    <Box
      className={classes.singleImageCell}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <CardMedia component="img" src={url} className={classes.imgPreviewThumbnail} />
      {isHover && (
        <Box className={classes.hoverBox}>
          <Box className={classes.cropButton} onClick={onOpenCrop}>
            <CropFree className={classes.cropIcon} />
            <Typography className="medium-sm-txt">{getLabel(LangConstant.TXT_EDIT)}</Typography>
          </Box>
          <Clear onClick={onRemoveImage} className={classes.removeIcon} />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  inputClickBoxInitial: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minHeight: 150,
    width: "-webkit-fill-available",
    cursor: "pointer",
  },

  inputClickBoxPickedImg: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
  },

  inputClickBoxFull: {
    minWidth: 430,
    minHeight: 130,
    display: "none",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 200,
    },
  },

  inputClickBoxNotFull: {
    minWidth: 430,
    minHeight: 130,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 200,
    },
  },

  previewBoxNotNull: {
    border: "none",
    color: "#d4d5d8",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    "&>*": {
      width: "100%",
      height: "100%",
    },
  },

  previewBoxIsNull: {
    borderRadius: 3,
    border: "solid 1px #d4d5d8",
  },

  uploadFormGroup: {
    with: 430,
    height: 130,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 200,
    },
  },

  addIconInitial: {
    position: "relative",
    width: "50%",
    height: 114,
    color: "#d4d5d8",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 200,
    },
  },

  addIconInitialDefault: {
    position: "relative",
    width: 80,
    height: 80,
    color: "#d4d5d8",
    [theme.breakpoints.down("xs")]: {
      minHeight: 70,
      minWidth: 90,
    },
  },

  addIconPickedInput: {
    position: "relative",
    width: "28px",
    height: "28px",
    color: "#d4d5d8",
  },

  fileUploadInput: {
    display: "none",
  },

  singleImageCell: {
    width: "100%",
    height: "100%",
    borderRadius: 3,
    border: "solid 1px #d4d5d8",
    position: "relative",
  },

  imgPreviewThumbnail: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  hoverBox: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "rgb(0, 0, 0, 0.3)",
  },

  cropButton: {
    display: "flex",
    height: 30,
    position: "absolute",
    width: "fit-content",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&>*": {
      color: theme.palette.white,
    },
  },

  cropIcon: {
    width: 14,
    height: 14,
    marginRight: 5,
  },

  removeIcon: {
    position: "absolute",
    right: "4%",
    top: "10%",
    cursor: "pointer",
    width: 15,
    height: 15,
    color: theme.palette.white,
    backgroundColor: theme.palette.grey[700],
    display: "unset",
  },
}));
export default UploadImage;
