import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";
import InputBase from "@material-ui/core/InputBase";
import CustomDatePickerStaff from "./CustomDatePickerStaff";
import { format, isExists } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import StaffAction from "redux/staff.redux";
import { DialogCreatePositionStaff, DialogStaffWorkingShift } from "components/dialog";
import { checkFuture, checkIsNotPast, getTimestamp } from "utils/date";

const StaffDetailForm = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isEditing, user, showTime, showPosition, setIsEditing } = props;

  const uid = user.uid;
  const initialBirthday = user.birthday ? new Date(user.birthday * 1000) : null;

  const [formControlState, setFormControlState] = useState(user);

  const [error, setError] = useState(0);
  const [listPosition, setListPosition] = useState(showPosition);
  const [listTime, setListTime] = useState(showTime);
  const [isShowWorkingShiftDialog, setIsShowWorkShiftDialog] = useState(false);
  const [isShowPositionDialog, setIsShowPositionDialog] = useState(false);
  const newWorkingShift = useSelector(state => state.staffRedux.newWorkShift);
  const newPosition = useSelector(state => state.staffRedux.newPosition);
  const createWorkingShiftSuccess = useSelector(state => state.staffRedux.isSuccess);
  const createPositionSuccess = useSelector(state => state.staffRedux.isSuccessPosition);

  const staffEditInfo = {
    uid: uid,
    name: formControlState.name,
    role: formControlState.role,
    phoneNumber: formControlState.phone,
    email: formControlState.email || null,
    birthday: formControlState.birthday ? formControlState.birthday : null,
    gender: formControlState.gender,
    startTime: checkIsNotPast(formControlState.startDay),
    endTime: checkIsNotPast(formControlState.stopDay),
    code: formControlState.staffCode,
    workingShiftUid: formControlState.workShift,
    staffPositionUid: formControlState.position,
    state: formControlState.status,
  };

  const [birthDay, setBirthDay] = useState({
    dayOfBirth: initialBirthday ? initialBirthday.getDate() : null,
    monthOfBirth: initialBirthday ? initialBirthday.getMonth() + 1 : null,
    yearOfBirth: initialBirthday ? initialBirthday.getFullYear() : null,
  });
  const onChangeInput = e => {
    let name = e.target.name;
    let value = e.target.value;
    setFormControlState({ ...formControlState, [name]: value });
  };

  const onChangeBirthDay = e => {
    let name = e.target.name;
    let value = e.target.value;
    setBirthDay({
      ...birthDay,
      [name]: value,
    });
    setFormControlState({
      ...formControlState,
      birthday: getTimestamp(
        new Date(
          name !== "yearOfBirth" ? birthDay.yearOfBirth : value,
          name !== "monthOfBirth" ? birthDay.monthOfBirth - 1 : value - 1,
          name !== "dayOfBirth" ? birthDay.dayOfBirth : value,
        ),
      ),
    });
  };
  const getDateCustom = date => {
    let current = new Date(date);
    let formatDate = format(current, AppConstant.FM_YYYY_MM_DD);
    if (formControlState.startDay !== formatDate) {
      setFormControlState({ ...formControlState, startDay: formatDate });
    }
  };
  const getStopDate = date => {
    let current = new Date(date);
    let formatDate = format(current, AppConstant.FM_YYYY_MM_DD);
    if (formControlState.stopDay !== formatDate) {
      setFormControlState({ ...formControlState, stopDay: formatDate });
    }
  };
  function generate(size, start) {
    return [...Array(size)].map((_, index) => (
      <MenuItem key={index} value={index + start} className={classes.menuOption}>
        {index + start}
      </MenuItem>
    ));
  }

  useEffect(() => {
    setFormControlState(convertResponseToFormData(user));
  }, [user]);

  useEffect(() => {
    if (Object.keys(newWorkingShift).length > 0) {
      setListTime([...listTime, newWorkingShift]);
      setFormControlState({ ...formControlState, workShift: newWorkingShift.uid });
      dispatch(StaffAction.resetStaff());
    }
  }, [newWorkingShift]);

  useEffect(() => {
    if (Object.keys(newPosition).length > 0) {
      setListPosition([...listPosition, newPosition]);
      setFormControlState({ ...formControlState, position: newPosition.uid });
      dispatch(StaffAction.resetStaff());
    }
  }, [newPosition]);

  useEffect(() => {
    if (createWorkingShiftSuccess && createPositionSuccess) {
      dispatch(StaffAction.resetStaff());
    }
  }, [createPositionSuccess, createWorkingShiftSuccess]);

  const updateStaff = () => {
    if (
      formControlState.role &&
      (!formControlState.phone || formControlState.phone.match(/^\d{10}$/)) &&
      formControlState.position &&
      formControlState.status &&
      formControlState.name &&
      formControlState.name.replace(/^\s+/, "").replace(/\s+$/, "") !== "" &&
      formControlState.workShift
    ) {
      if (
        isExists(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth) &&
        !checkFuture(new Date(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth))
      ) {
        dispatch(StaffAction.requestEditStaff(staffEditInfo));
        dispatch(StaffAction.requestWorkingShifts());
        dispatch(StaffAction.requestPosition());
        setFormControlState({ ...formControlState, isWarning: false });
      } else if (formControlState.birthday === null) {
        setFormControlState({ ...formControlState, isWarning: false });
        dispatch(StaffAction.requestEditStaff(staffEditInfo));
        dispatch(StaffAction.requestWorkingShifts());
        dispatch(StaffAction.requestPosition());
        setFormControlState({ ...formControlState, isWarning: false });
      }
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    if (
      formControlState.role &&
      (!formControlState.phone || formControlState.phone.match(/^\d{10}$/)) &&
      formControlState.position &&
      formControlState.status &&
      formControlState.name &&
      formControlState.name.replace(/^\s+/, "").replace(/\s+$/, "") !== "" &&
      formControlState.workShift
    ) {
      if (birthDay.dayOfBirth || birthDay.monthOfBirth || birthDay.yearOfBirth) {
        if (!isExists(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth)) {
          setFormControlState({ ...formControlState, isWarning: true });
          setError(AppConstant.ERROR_CODE.invalid_birthday);
        }
        if (checkFuture(new Date(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth))) {
          setFormControlState({ ...formControlState, isWarning: true });
          setError(1);
        }
      } else
        setFormControlState({
          ...formControlState,
          birthday: null,
        });
    } else if (formControlState.phone && !formControlState.phone.match(/^\d{10}$/)) {
      setFormControlState({ ...formControlState, isWarning: true });
      setError(AppConstant.ERROR_CODE.invalid_phone);
    } else {
      setFormControlState({ ...formControlState, isWarning: true });
      setError(AppConstant.ERROR_CODE.empty_field);
    }
  };

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        {!isEditing ? getLabel(LangConstant.TXT_STAFF_DETAIL) : getLabel(LangConstant.TXT_STAFF_EDITING)}
      </Typography>
      <form onSubmit={onSubmit} className={classes.validateForm}>
        <Box className={classes.formContainer}>
          <Box component="div" className={classes.leftColumn}>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_FULLNAME)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <TextField
                required
                variant={!isEditing ? "standard" : "outlined"}
                disabled={!isEditing || Boolean(formControlState.userId)}
                size="small"
                name="name"
                value={formControlState.name || ""}
                className={classes.inputRow}
                placeholder={getLabel(LangConstant.TXT_FULLNAME)}
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                InputProps={{ disableUnderline: !isEditing }}
                onChange={onChangeInput}
              />
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_PHONE_NUMBER)}
                </Typography>
                <Typography color="primary">&nbsp;</Typography>
              </Box>
              <TextField
                variant={!isEditing ? "standard" : "outlined"}
                disabled={!isEditing}
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                InputProps={{ disableUnderline: !isEditing }}
                type="number"
                size="small"
                name="phone"
                value={formControlState.phone || ""}
                className={classes.inputRow}
                placeholder={getLabel(LangConstant.TXT_PHONE_NUMBER)}
                onChange={onChangeInput}
              />
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_BIRTH_DAY)}
              </Typography>
              {isEditing ? (
                <Box component="div" className={classes.birthdayDateContainer}>
                  <Box className={classes.customDatePickerInput}>
                    <Select
                      value={birthDay.dayOfBirth || ""}
                      inputProps={{
                        className: `${classes.detailStaffInput} regular-md-txt`,
                      }}
                      disabled={!isEditing}
                      name="dayOfBirth"
                      onChange={onChangeBirthDay}
                      input={<BootstrapInput />}
                      classes={{
                        icon: `${!isEditing ? classes.hiddenIcon : ""}`,
                      }}
                    >
                      {generate(31, 1)}
                    </Select>
                  </Box>

                  <Box className={classes.customDatePickerInput}>
                    <Select
                      variant={!isEditing ? "standard" : "outlined"}
                      inputProps={{
                        className: `${classes.detailStaffInput} regular-md-txt`,
                      }}
                      disabled={!isEditing}
                      value={birthDay.monthOfBirth || ""}
                      name="monthOfBirth"
                      onChange={onChangeBirthDay}
                      className="select small"
                      input={<BootstrapInput />}
                    >
                      {generate(12, 1)}
                    </Select>
                  </Box>
                  <Box className={classes.customDatePickerInput}>
                    <Select
                      variant={!isEditing ? "standard" : "outlined"}
                      inputProps={{
                        className: `${classes.detailStaffInput} regular-md-txt`,
                      }}
                      disabled={!isEditing}
                      value={birthDay.yearOfBirth || ""}
                      name="yearOfBirth"
                      onChange={onChangeBirthDay}
                      className="select small"
                      input={<BootstrapInput />}
                    >
                      {generate(new Date().getFullYear() - 1949, 1950)}
                    </Select>
                  </Box>
                </Box>
              ) : (
                <TextField
                  disabled={true}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    className: `${classes.detailStaffInput} regular-md-txt`,
                  }}
                  className={classes.inputRow}
                  value={
                    initialBirthday
                      ? birthDay.dayOfBirth + "/" + birthDay.monthOfBirth + "/" + birthDay.yearOfBirth
                      : ""
                  }
                />
              )}
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_STARTDATE)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Box className={isEditing ? classes.datePickerContainer : classes.datePickerContainerStatic}>
                <CustomDatePickerStaff
                  onChange={getDateCustom}
                  selectedDateProp={
                    formControlState.startDay
                      ? format(new Date(formControlState.startDay), AppConstant.FM_YYYY_MM_DD)
                      : new Date()
                  }
                  className={classes.datePicker}
                  isEditing={isEditing}
                  isUseEffect={true}
                />
              </Box>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_WORK_TIME)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Select
                required
                name="workShift"
                value={formControlState.workShift || ""}
                disabled={!isEditing}
                disableUnderline
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                classes={{
                  icon: `${!isEditing ? classes.hiddenIcon : ""}`,
                  root: isEditing ? classes.selectRoot : classes.selectRootStatic,
                }}
                variant={!isEditing ? "standard" : "outlined"}
                onChange={onChangeInput}
                className={classes.inputRow}
              >
                <MenuItem className={classes.menuOption} onClick={() => setIsShowWorkShiftDialog(true)}>
                  {getLabel(LangConstant.TXT_ADD_NEW)}
                </MenuItem>
                {isEditing ? (
                  listTime.map((data, index) => (
                    <MenuItem key={index + 2} value={data.uid} className={classes.menuOption}>
                      {data.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={formControlState.workShift}>{user?.workingShift?.name}</MenuItem>
                )}
              </Select>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_STAFFCODE)}
              </Typography>
              <TextField
                variant={!isEditing ? "standard" : "outlined"}
                disabled={!isEditing}
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                size="small"
                name="staffCode"
                value={formControlState.staffCode || ""}
                className={classes.inputRow}
                onChange={onChangeInput}
              />
            </Box>
          </Box>
          <Box component="div" className={classes.rightColumn}>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_ROLE)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Select
                required
                variant={!isEditing ? "standard" : "outlined"}
                disabled={!isEditing || formControlState.role === 2}
                name="role"
                value={formControlState.role || ""}
                disableUnderline
                onChange={onChangeInput}
                className={classes.inputRow}
                classes={{
                  icon: `${!isEditing ? classes.hiddenIcon : ""}`,
                  root: isEditing ? classes.selectRoot : classes.selectRootStatic,
                }}
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
              >
                {(!isEditing || formControlState.role === 2) && (
                  <MenuItem value={2} className={classes.menuOption}>
                    {getLabel(LangConstant.TXT_SHOP_OWNER)}
                  </MenuItem>
                )}
                <MenuItem value={3} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_MANAGER)}
                </MenuItem>
                <MenuItem value={4} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_STAFF)}
                </MenuItem>
              </Select>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_EMAIL)}
              </Typography>
              <TextField
                variant={!isEditing ? "standard" : "outlined"}
                disabled={!isEditing || Boolean(formControlState.userId)}
                InputProps={{ disableUnderline: true }}
                size="small"
                name="email"
                value={formControlState.email || ""}
                className={classes.inputRow}
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                onChange={onChangeInput}
              />
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_GENDER)}
              </Typography>
              <Select
                variant={!isEditing ? "standard" : "outlined"}
                disabled={!isEditing}
                name="gender"
                value={formControlState.gender || ""}
                disableUnderline
                onChange={onChangeInput}
                className={classes.inputRow}
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                classes={{
                  icon: `${!isEditing ? classes.hiddenIcon : ""}`,
                  root: isEditing ? classes.selectRoot : classes.selectRootStatic,
                }}
              >
                <MenuItem value={1} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_MAN)}
                </MenuItem>
                <MenuItem value={2} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_WOMAN)}
                </MenuItem>
                <MenuItem value={3} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_OTHER)}
                </MenuItem>
              </Select>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_STOPDATE)}
              </Typography>
              <Box className={isEditing ? classes.datePickerContainer : classes.datePickerContainerStatic}>
                <CustomDatePickerStaff
                  onChange={getStopDate}
                  selectedDateProp={
                    formControlState.stopDay
                      ? format(new Date(formControlState.stopDay), AppConstant.FM_YYYY_MM_DD)
                      : new Date()
                  }
                  className={classes.datePicker}
                  isEditing={isEditing}
                  isUseEffect={true}
                />
              </Box>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_POSITION)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Select
                required
                name="position"
                value={formControlState.position || ""}
                disabled={!isEditing}
                disableUnderline
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                classes={{
                  icon: `${!isEditing ? classes.hiddenIcon : ""}`,
                  root: isEditing ? classes.selectRoot : classes.selectRootStatic,
                }}
                variant={!isEditing ? "standard" : "outlined"}
                onChange={onChangeInput}
                className={classes.inputRow}
              >
                <MenuItem value={1} className={classes.menuOption} onClick={() => setIsShowPositionDialog(true)}>
                  {getLabel(LangConstant.TXT_ADD_NEW)}
                </MenuItem>
                {isEditing ? (
                  listPosition.map((data, index) => (
                    <MenuItem key={index} value={data.uid} className={classes.menuOption}>
                      {data.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={formControlState.position}>{user.staffPosition?.name || ""}</MenuItem>
                )}
              </Select>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_STATUS)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Select
                required
                variant={!isEditing ? "standard" : "outlined"}
                disabled={!isEditing}
                disableUnderline
                name="status"
                value={formControlState.status || ""}
                onChange={onChangeInput}
                className={classes.inputRow}
                inputProps={{
                  className: `${classes.detailStaffInput} regular-md-txt`,
                }}
                classes={{
                  icon: !isEditing ? classes.hiddenIcon : "",
                  root: isEditing ? classes.selectRoot : classes.selectRootStatic,
                }}
              >
                <MenuItem value={1} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_STAFF_STATUS_ACTIVE)}
                </MenuItem>
                <MenuItem value={2} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_STAFF_STATUS_INACTIVE)}
                </MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>

        <FormControl error className={classes.errorLabel}>
          {formControlState.isWarning === true && error === AppConstant.ERROR_CODE.invalid_birthday ? (
            <FormHelperText>{getLabel(LangConstant.ERR_INVALID_DAY)}</FormHelperText>
          ) : (
            <></>
          )}
          {formControlState.isWarning === true && error === AppConstant.ERROR_CODE.empty_field ? (
            <FormHelperText>{getLabel(LangConstant.ERR_MISSING_REQUIRED_FIELD)}</FormHelperText>
          ) : (
            <></>
          )}
          {formControlState.isWarning === true && error === AppConstant.ERROR_CODE.invalid_phone ? (
            <FormHelperText>{getLabel(LangConstant.ERR_INVALID_PHONE)}</FormHelperText>
          ) : (
            <></>
          )}
        </FormControl>

        <Button
          type={isEditing ? "submit" : "button"}
          className={classes.submitStaffDetailBtn}
          color="primary"
          variant="contained"
          onClick={() => {
            if (isEditing) {
              updateStaff();
            } else {
              setIsEditing(!isEditing);
            }
          }}
        >
          {isEditing ? (
            <Typography variant="subtitle2" className={classes.submitBtnText}>
              {getLabel(LangConstant.TXT_SAVE)}
            </Typography>
          ) : (
            <Typography variant="subtitle2" className={classes.editBtn}>
              {getLabel(LangConstant.TXT_EDIT)}
            </Typography>
          )}
        </Button>
      </form>

      <DialogCreatePositionStaff
        isShow={isShowPositionDialog}
        onClose={() => setIsShowPositionDialog(false)}
        className={classes.positionDialog}
        onSubmit={() => {
          setIsShowPositionDialog(false);
        }}
      />
      <DialogStaffWorkingShift
        isShow={isShowWorkingShiftDialog}
        onClose={() => setIsShowWorkShiftDialog(false)}
        className={classes.workShiftDialog}
        onSubmit={() => {
          setIsShowWorkShiftDialog(false);
        }}
      />
    </>
  );
};

export default StaffDetailForm;

const convertResponseToFormData = response => {
  let defaultFormData = {};
  if (response) {
    defaultFormData = {
      workShift: response.workingShift?.uid,
      gender: response.gender === 0 ? "" : response.gender,
      position: response.staffPosition?.uid,
      status: response.state,
      role: response.role,
      phone: response.phoneNumber,
      name: response.name,
      email: response.email,
      birthday: response.birthday ? response.birthday : null,
      staffCode: response.code,
      isWarning: false,
      startDay: format(
        response.startTime !== 0 ? new Date(response.startTime * 1000) : new Date(),
        AppConstant.FM_YYYY_MM_DD,
      ),
      stopDay: format(
        response.endTime !== 0 ? new Date(response.endTime * 1000) : new Date(),
        AppConstant.FM_YYYY_MM_DD,
      ),
      userId: response.userId,
    };
  }
  return defaultFormData;
};

const useStyles = makeStyles(theme => ({
  positionDialog: {
    zIndex: 1,
  },
  workShiftDialog: {
    zIndex: 1,
  },
  formContainer: {
    display: "inline-flex",
    width: "90%",
    justifyContent: "space-evenly",
    minWidth: "550px",
    [theme.breakpoints.down("xs")]: {
      display: "flow-root",
      minWidth: "0px",
      marginRight: "3%",
      marginLeft: "3%",
      marginTop: "10%",
    },
    justifySelf: "center",
  },
  leftColumn: {
    minWidth: "25%",
  },
  rightColumn: {
    minWidth: "25%",
  },
  detailStaffInput: {
    "&::placeholder": {
      color: "#3e4045",
    },
    "&.MuiInputBase-input.Mui-disabled": {
      borderBottom: "1px solid #d4d5d8",
      color: "#3e4045",
      opacity: 0.6,
      cursor: "no-drop",
    },
    "&.MuiInputBase-inputMarginDense": {
      paddingTop: "10px",
    },
  },
  selectRoot: {
    padding: " 10px 14px",
  },
  selectRootStatic: {
    padding: "7px 0px",
  },
  inputRow: {
    maxHeight: "40px",
    minWidth: "100%",
  },
  singleStaffInfoRow: {
    textAlign: "left",
    minWidth: "250px",
    width: "100%",
    marginTop: "4vh",
  },
  placeHolder: {
    color: "#d4d5d8",
    fontStyle: "unset",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: "unset",
    opacity: 0.4,
  },
  datePicker: {
    marginRight: "auto",
  },
  datePickerContainer: {
    borderRadius: "5px",
    border: "1px solid #c4c4c4",
  },
  datePickerContainerStatic: {
    border: "unset",
  },
  menuOption: {
    "&:hover": {
      backgroundColor: "#3b404c",
      color: "#ffffff",
    },
  },
  birthdayDateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  customDatePickerInput: {},
  staffFormLabel: {
    marginBottom: "10px",
    color: "#3b404c",
  },
  formTitle: {
    marginBottom: "4vh",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      marginLeft: "3%",
    },
  },
  hiddenIcon: {
    display: "none",
  },
  validateForm: {
    display: "grid",
  },
  submitStaffDetailBtn: {
    marginTop: "3vh",
    width: "1vw",
    textTransform: "none",
    minWidth: "120px",
    fontWeight: theme.typography.subtitle2.fontWeight,
    [theme.breakpoints.down("xs")]: {
      marginTop: "16%",
      marginBottom: "6%",
      minHeight: "46px",
      borderRadius: "24px",
      minWidth: "102px",
    },
    justifySelf: "center",
  },
  editBtn: {
    width: "1vw",
    textTransform: "none",
    alignSelf: "center",
    minWidth: "120px",
    fontWeight: theme.typography.subtitle2.fontWeight,
    color: "#ffffff",
  },
  submitBtnText: {
    color: "#ffffff",
  },
  errorLabel: {
    alignItems: "center",
    marginTop: "4vh",
    marginBottom: "1vh",
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "24vw",
    },
  },
  input: {
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "0.5px solid #ced4da",
    fontSize: 16,
    padding: "9px 0px 9px 10px",
    height: "20px",
    minWidth: "4vw",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 3,
    },
  },
}))(InputBase);
