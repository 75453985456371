import React, { memo } from "react";
import { makeStyles, Box, IconButton } from "@material-ui/core";
import { LangConstant } from "../../../../const";
import { useTranslation } from "react-i18next";

// TODO Comment use for drop down

const MenuBody = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { headData, cellData, dataAPI } = props;
  // const [anchorEl, setAnchorEl] = useState(null);
  let selectedIndex = cellData;

  const listRole = LangConstant.ARR_ROLE;

  // const onClickListItem = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const onMenuItemClick = (event, index) => {
  //   setSelectedIndex(index);
  //   setAnchorEl(null);
  //   console.log(index);
  //   onChange(index);
  // };

  // const onClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <Box className={classes.boxRoot}>
      <IconButton
        // onClick={onClickListItem}
        classes={{ root: classes.btnRoot, label: `medium-md-txt ${classes.btnLabel}` }}
      >
        {getLabel(LangConstant.TXT_ROLE) === headData
          ? selectedIndex != null
            ? getLabel(listRole[selectedIndex])
            : getLabel(listRole[cellData])
          : dataAPI !== null && cellData !== null && dataAPI.find(x => x.uid === selectedIndex)
          ? dataAPI.find(x => x.uid === selectedIndex).name
          : null}
        {/* {selectedIndex !== 0 && <ArrowDropDown className={classes.icon} />} */}
      </IconButton>
      {/* {selectedIndex !== 0 && (
        <Menu
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ list: classes.menuList, paper: classes.menuPaper }}
        >
          {getLabel(LangConstant.TXT_ROLE) === headData
            ? listRole.map(
                (option, index) =>
                  selectedIndex !== 0 &&
                  index !== 0 &&
                  index !== selectedIndex && (
                    <MenuItem
                      onClick={event => onMenuItemClick(event, index)}
                      className={`medium-md-txt ${classes.menuItemRoot}`}
                      key={uuid()}
                    >
                      {getLabel(option)}
                    </MenuItem>
                  ),
              )
            : dataAPI &&
              dataAPI.map(
                (option, index) =>
                  selectedIndex !== 0 &&
                  index !== 0 &&
                  index !== selectedIndex && (
                    <MenuItem
                      onClick={event => onMenuItemClick(event, option.uid)}
                      className={`medium-md-txt ${classes.menuItemRoot}`}
                      key={uuid()}
                    >
                      {option.name}
                    </MenuItem>
                  ),
              )}
        </Menu>
      )} */}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    height: "100%",
  },
  btnRoot: {
    color: "#787878",
    flexDirection: "row",
    borderRadius: 0,
    height: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  btnLabel: {
    justifyContent: "unset",
    textAlign: "left",
  },
  menuPaper: {
    marginTop: 2,
    borderRadius: 0,
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: 300,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    minHeight: 30,
    width: 120,
    color: "#9d9fa5",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
  },
  icon: {
    right: 8,
    position: "absolute",
    color: theme.palette.grey[500],
  },
}));

export default memo(MenuBody);
