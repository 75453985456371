import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  Popper,
  ListItemText,
  List,
  Box,
  makeStyles,
  ClickAwayListener,
} from "@material-ui/core";
import { LangConstant, AppConstant, ApiConstant, PathConstant } from "const";
import { useTranslation } from "react-i18next";
import { removeAllCookie } from "utils";
import Cookie from "js-cookie";
import { useHistory } from "react-router-dom";
import AuthAction from "redux/auth.redux";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import clsx from "clsx";
import UserAction from "redux/user.redux";
import { HEADER_CONDITION_OBJ } from "layouts/MainLayout/components/CustomAppBar";

const AccountInfo = ({ isShowPopup, setHeaderButtonState }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();
  const history = useHistory();
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  const role = Cookie.get(AppConstant.ROLE);
  const [dataUser, isFirstSuccess, isFetchingUser] = useSelector(
    ({ userRedux }) => [userRedux.dataUser, userRedux.isFirstSuccess, userRedux.isFetchingUser],
    shallowEqual,
  );

  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [retry, setRetry] = useState({ countUser: 1 });
  const [userData, setUserData] = useState(dataUser || {});

  const onClickAccount = event => {
    setAnchorElAccount(anchorElAccount ? null : event.currentTarget);
    setHeaderButtonState({ ...HEADER_CONDITION_OBJ, isAccountInfo: !Boolean(anchorElAccount) });
  };

  const onClickManageAccount = () => {
    history.push(PathConstant.ACCOUNT);
    onClose();
  };

  const onClickLogOut = () => {
    dispatch(AuthAction.requestLogout());
    removeAllCookie(Cookie);
    history.replace(PathConstant.LOGIN);
    onClose();
  };

  const onClose = () => {
    setAnchorElAccount(null);
    setHeaderButtonState(HEADER_CONDITION_OBJ);
  };

  useEffect(() => {
    if (!isFetchingUser && retry.countUser <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
      if (appData && appData.length > 0) {
        setRetry({ ...retry, countUser: ++retry.countUser });
        let userId = JSON.parse(appData).userId;
        dispatch(UserAction.requestGetUserInfo({ data: { userId: userId } }));
      }
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (dataUser == null && retry.countUser <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
      setTimeout(() => {
        if (isSubscribed) setRetry({ ...retry, countUser: ++retry.countUser });
      }, AppConstant.VALUE_RETRY_REQUEST_TIME_BASE * retry.countUser);
    }
    if (!isFirstSuccess && retry.countNotify <= AppConstant.VALUE_THREAD_HOLD_RETRY_REQUEST) {
      setTimeout(() => {
        if (isSubscribed) setRetry({ ...retry, countNotify: ++retry.countNotify });
      }, AppConstant.VALUE_RETRY_REQUEST_TIME_BASE * retry.countNotify);
    }
    return () => (isSubscribed = false);
  }, [retry]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      let newData = dataUser ? dataUser : {};
      let newUserData = {};
      newUserData.avatar = newData[AppConstant.KEY_AVATAR]
        ? `${ApiConstant.HOST_ATTACHMENT}${newData[AppConstant.KEY_AVATAR]}`
        : "";
      newUserData.name = newData[AppConstant.KEY_NAME] || "G";
      setUserData(newUserData);
    }
    return () => (isSubscribed = false);
  }, [dataUser]);

  useEffect(() => {
    if (!isShowPopup) {
      setAnchorElAccount(null);
    }
  }, [isShowPopup]);

  return (
    <>
      <Avatar alt="avatar" className={classes.avatar} onClick={onClickAccount} src={userData.avatar}>
        {userData.name}
      </Avatar>
      {isShowPopup && (
        <ClickAwayListener onClickAway={onClose}>
          <Popper open={Boolean(anchorElAccount)} anchorEl={anchorElAccount} className={classes.accountInfo}>
            <Box className={classes.container}>
              <Box className={clsx("arrow", classes.arrow)} />
              <List component="nav" className={classes.root}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="avatar" src={userData.avatar}>
                      {userData.name}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className="medium-md-txt" color="textPrimary">
                        {userData.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2" className={`medium-sm-txt email title`}>
                        {getLabel(LangConstant.ROLE_LANG[role])}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem button alignItems="flex-start" name="account" onClick={onClickManageAccount}>
                  <ListItemText
                    primary={
                      <Typography component="span" className="medium-md-txt" color="textPrimary">
                        {getLabel(LangConstant.TXT_ACCOUNT_SETTING)}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem button alignItems="flex-start" name="logout" onClick={onClickLogOut}>
                  <ListItemText
                    primary={
                      <Typography component="span" className="medium-md-txt" color="textPrimary">
                        {getLabel(LangConstant.TXT_LOGOUT)}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

AccountInfo.propTypes = {
  isShowPopup: PropTypes.bool,
  setHeaderButtonState: PropTypes.func,
};
AccountInfo.defaultProps = {
  isShowPopup: false,
};

export default memo(AccountInfo);

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 30,
    height: 30,
    fontSize: 14,
    cursor: "pointer",
  },
  accountInfo: {
    zIndex: theme.zIndex.drawer,
    "&.paper": {
      background: "transparent",
      boxShadow: "none",
      [theme.breakpoints.down("md")]: {
        right: 0,
        left: "unset !important",
      },
    },
  },
  container: {
    position: "relative",
    paddingTop: 12,
  },
  arrow: {
    [theme.breakpoints.up("lg")]: {
      right: 56,
    },
  },
  root: { width: 291, maxWidth: 291, minWidth: 291, background: "white", boxShadow: " 0 1px 6px 0 rgba(0, 0, 0, 0.1)" },
  email: { width: 200, height: 20, color: "#9d9fa5" },
}));
