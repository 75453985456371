import { ApiConstant, AppConstant } from "const";
import { createApiReservation, createApiDownloadExcel, createApiRegistration } from "api";
import { format } from "react-string-format";

export const getReservationDay = data => {
  return createApiReservation().get(ApiConstant.GET_RESERVATION_DAY, data);
};

export const getReservationWeek = data => {
  return createApiReservation().get(ApiConstant.GET_RESERVATION_WEEK, data);
};

export const getReservationMonth = data => {
  return createApiReservation().get(ApiConstant.GET_RESERVATION_MONTH, data);
};

export const postReservation = data => {
  return createApiReservation().post(ApiConstant.POST_RESERVATION, data);
};

export const getReservationSetting = () => {
  return createApiReservation().get(ApiConstant.GET_RESERVATION_SETTING);
};

export const putReservationStatus = data => {
  let putReservationStatusApi = ApiConstant.PUT_RESERVATION_STATUS.replace("{uid}", data.uid);
  return createApiReservation().put(putReservationStatusApi, { status: data.status });
};

export const putReservationDetails = data => {
  let putReservationDetailsApi = ApiConstant.PUT_RESERVATION_DETAILS.replace("{uid}", data.uid);
  return createApiReservation().put(putReservationDetailsApi, data.details);
};

export const getReservationExport = data => {
  return createApiDownloadExcel().get(ApiConstant.GET_RESERVATION_EXPORT, {
    start: data.start,
    end: data.end,
    status: data.status,
  });
};

export const getReservationPeriodic = data => {
  let getReservationPeriodicApi = ApiConstant.GET_RESERVATION_PERIODIC.replace("{uid}", data.uid);
  return createApiReservation().get(getReservationPeriodicApi, {});
};

export const getReservation = data => {
  let getReservationApi = ApiConstant.GET_RESERVATION.replace("{uid}", data.uid);
  return createApiReservation().get(getReservationApi, {});
};

export const postReservationExtend = data => {
  return createApiReservation().post(ApiConstant.POST_RESERVATION_EXTEND, data);
};

export const postReservationListStatus = data => {
  return createApiReservation().post(ApiConstant.POST_RESERVATION_LIST_STATUS, data);
};

export const getAvailableStatus = data => {
  let requestString = format(
    ApiConstant.GET_AVAILABLE_STATUS,
    data && data.time && data.time.toString(),
    data.total_consumer,
  );
  return createApiReservation().get(requestString);
};

export const getDisplaySetting = data => {
  return createApiRegistration().get(ApiConstant.USER_CHART_DISPLAY_SETTING, {
    ...data,
    type: AppConstant.DISPLAY_SETTING_BOOKING_MANAGEMENT_TYPE,
  });
};

export const updateDisplaySetting = data => {
  return createApiRegistration().post(ApiConstant.USER_CHART_DISPLAY_SETTING, {
    ...data,
    type: AppConstant.DISPLAY_SETTING_BOOKING_MANAGEMENT_TYPE,
  });
};

export const getReservationType = data => {
  return createApiRegistration().get(ApiConstant.GET_RESERVATION_TYPE, data);
};
