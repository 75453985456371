import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const postServiceItem = data => createApiReservation().post(ApiConstant.SERVICE_ITEM, data);

export const putServiceItem = data => createApiReservation().put(format(ApiConstant.SERVICE_ITEM_UID, data.uid), data);

export const getListServiceItem = data => createApiReservation().get(ApiConstant.SERVICE_ITEM, data);

export const getServiceItemByUid = data =>
  createApiReservation().get(format(ApiConstant.SERVICE_ITEM_UID, data.uid), {});

export const deleteServiceItem = data =>
  createApiReservation().delete(format(ApiConstant.SERVICE_ITEM_UID, data.uid), {});

export const getServiceItemByCategoryUid = data =>
  createApiReservation().get(format(ApiConstant.SERVICE_ITEM_CATEGORY_UID, data.category_uid));
