import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import * as ErrorRedux from "./error.redux";
import * as ReportBookingRedux from "./report-booking.redux";
import * as ReportConsumerRedux from "./report-consumer.redux";
import * as ReportStaffRedux from "./report-staff.redux";
import * as ShopInfoRedux from "./shop-info.redux";
import * as PlaceCategoryRedux from "./place-category.redux";
import * as PlaceItemRedux from "./place-item.redux";
import * as ServiceCategoryRedux from "./service-category.redux";
import * as StaffRedux from "./staff.redux";
import * as ConsumerRedux from "./consumer.redux";
// import * as ReportConsumerRedux from "./reportConsumer.redux";
import * as AuthRedux from "./auth.redux";
import * as ShopRedux from "./shop.redux";
import * as UserRedux from "./user.redux";
import * as ReservationSettingRedux from "./reservation-setting.redux";
import * as ReservationRedux from "./reservation.redux";
import * as NewsRedux from "./mn-news.redux";
import * as ReportDashboard from "./report-dashboard.redux";
import * as ReservationActivityRedux from "./reservation-activity.redux";
import * as ReservationNewRedux from "./reservation-new.redux";
import * as ComboItemRedux from "./combo-item.redux";
import * as ServiceItemRedux from "./service-item.redux";
import * as ItemDetailsRedux from "./item-details.redux";
import * as ItemDetailsContentRedux from "./item-details-content.redux";
import * as MnProductRedux from "./mn-product.redux";
import * as MenuRedux from "./menu.redux";
import * as WaitingConsumerRedux from "./waiting-consumer.redux";
import * as ReservationHistoryRedux from "./reservation-history.redux";
import * as PaymentRedux from "./payment.redux";
import * as RatingRedux from "./rating.redux";
import * as BillRedux from "./bill.redux";
import * as GroupAdmin from "./group-admin.redux";
import * as UploadFile from "./upload-file.redux";

/* ------------- Assemble The Reducers ------------- */
export const appReducer = combineReducers({
  errorRedux: ErrorRedux.reducer,
  authRedux: AuthRedux.reducer,
  reportBookingRedux: ReportBookingRedux.reducer,
  reportConsumerRedux: ReportConsumerRedux.reducer,
  shopRedux: ShopRedux.reducer,
  userRedux: UserRedux.reducer,
  reservationSettingRedux: ReservationSettingRedux.reducer,
  shopInfoRedux: ShopInfoRedux.reducer,
  placeCategoryRedux: PlaceCategoryRedux.reducer,
  placeItemRedux: PlaceItemRedux.reducer,
  reservationRedux: ReservationRedux.reducer,
  serviceCategoryRedux: ServiceCategoryRedux.reducer,
  staffRedux: StaffRedux.reducer,
  consumerRedux: ConsumerRedux.reducer,
  newsRedux: NewsRedux.reducer,
  reportStaffRedux: ReportStaffRedux.reducer,
  reportDashboard: ReportDashboard.reducer,
  reservationActivityRedux: ReservationActivityRedux.reducer,
  reservationNewRedux: ReservationNewRedux.reducer,
  comboItemRedux: ComboItemRedux.reducer,
  serviceItemRedux: ServiceItemRedux.reducer,
  itemDetailsRedux: ItemDetailsRedux.reducer,
  itemDetailsContentRedux: ItemDetailsContentRedux.reducer,
  mnProductRedux: MnProductRedux.reducer,
  menuRedux: MenuRedux.reducer,
  waitingConsumerRedux: WaitingConsumerRedux.reducer,
  reservationHistoryRedux: ReservationHistoryRedux.reducer,
  paymentRedux: PaymentRedux.reducer,
  ratingRedux: RatingRedux.reducer,
  billRedux: BillRedux.reducer,
  groupAdminRedux: GroupAdmin.reducer,
  uploadFile: UploadFile.reducer,
});

export const rootReducer = (state, action) => {
  // Action logout
  if (action.type === "REQUEST_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

/* ------------- Redux Configuration ------------- */

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();

// Create store
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// kick off root saga
sagaMiddleware.run(rootSaga);

export default store;
