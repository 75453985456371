import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey, getStatusColor } from "utils";

const ReportChartStatus = ({ onChange, displaySetting }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);
  let hasData = displaySetting && displaySetting.view_mode > 0;

  const [currentArraySelected, setCurrentArraySelected] = useState();
  const [arrayStatus, setArrayStatus] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [viewMode, setViewMode] = useState(AppConstant.CHART_VIEW_MODE.overview);

  const onCheckAll = () => {
    let isNewCheckedAll = !isCheckedAll;
    let newArrayStatus = Object.keys(AppConstant.STT_RESERVATION).map(itemKey => ({
      display_id: String(AppConstant.STT_RESERVATION[itemKey]),
      isChecked: isNewCheckedAll,
    }));

    setIsCheckedAll(isNewCheckedAll);
    setArrayStatus(newArrayStatus);
    onChangeDisplaySetting(viewMode, newArrayStatus);
  };

  const onCheckItem = selectedIndex => {
    let newArrayStatus = [...arrayStatus];
    newArrayStatus[selectedIndex].isChecked = !newArrayStatus[selectedIndex].isChecked;
    setIsCheckedAll(newArrayStatus.filter(item => !item.isChecked).length === 0);
    setArrayStatus(newArrayStatus);
    onChangeDisplaySetting(viewMode, newArrayStatus);
  };

  const onChangeViewMode = newViewMode => {
    setViewMode(newViewMode);
    onChangeDisplaySetting(newViewMode, arrayStatus);
  };

  const onChangeDisplaySetting = (view, array) => {
    if (!(view >= 0 && array.length > 0)) return;

    let selectedStatus = array.filter(item => item.isChecked);
    onChange({
      view_mode: view,
      display_ids: selectedStatus.map(item => item.display_id),
      details: selectedStatus,
    });
  };

  useEffect(() => {
    if (!hasData || currentArraySelected) return;

    const [selectedStatus, viewMode] = [displaySetting.details, displaySetting.view_mode];

    let newArrayStatus = Object.keys(AppConstant.STT_RESERVATION).map(itemKey => {
      let result = { display_id: AppConstant.STT_RESERVATION[itemKey], isChecked: false };
      let tmpItem = selectedStatus.filter(item => result.display_id == item.display_id)[0];
      return tmpItem ? { ...result, ...tmpItem, isChecked: true } : result;
    });

    setViewMode(viewMode);
    setArrayStatus(newArrayStatus);
    setIsCheckedAll(newArrayStatus.filter(item => !item.isChecked).length === 0);
    setCurrentArraySelected(displaySetting);
  }, [displaySetting]);

  return (
    <Box className={classes.root}>
      <Box className={classes.viewModeSetting}>
        <Box paddingTop={1}>{getLabel(LangConstant.TXT_VIEW_MODE)}</Box>
        <RadioGroup
          className={classes.radioGroup}
          value={viewMode}
          onChange={event => onChangeViewMode(parseInt(event.target.value))}
        >
          <FormControlLabel
            className={classes.formControlRoot}
            value={AppConstant.CHART_VIEW_MODE.overview}
            control={<Radio color="primary" className={classes.radioItem} />}
            label={getLabel(LangConstant.TXT_SUMMARY)}
          />
          <FormControlLabel
            className={classes.formControlRoot}
            value={AppConstant.CHART_VIEW_MODE.detail}
            control={<Radio color="primary" className={classes.radioItem} />}
            label={getLabel(LangConstant.TXT_DETAIL)}
          />
        </RadioGroup>
      </Box>
      {viewMode === AppConstant.CHART_VIEW_MODE.detail && (
        <Box className={classes.statusContainer}>
          <FormControlLabel
            classes={{ root: classes.formControlRoot, label: classes.formControlLabel }}
            control={
              <Checkbox
                checked={isCheckedAll}
                onChange={onCheckAll}
                name="checkAll"
                color="primary"
                className={classes.checkAll}
                size="small"
              />
            }
            label={getLabel(getCommonKey(LangConstant.TXT_CHOOSE_ALL))}
          />
          {arrayStatus.map((item, index) => (
            <CheckboxStatus key={index} data={item} onChange={() => onCheckItem(index)} isChecked={item.isChecked} />
          ))}
        </Box>
      )}
    </Box>
  );
};

ReportChartStatus.propTypes = {
  arraySelected: PropTypes.array,

  onChange: PropTypes.func,
};
ReportChartStatus.defaultProps = {};

export default memo(ReportChartStatus);

const CheckboxStatus = props => {
  const { data, onChange, isChecked } = props;
  const statusCode = parseInt(data.display_id) || 1;
  const classes = useStyles({ sttColor: getStatusColor(parseInt(statusCode)) });
  const { t: getLabel } = useTranslation();
  const primaryLabel =
    statusCode && LangConstant.ARR_STATUS_RESERVATION[statusCode - 1]
      ? getLabel(LangConstant.ARR_STATUS_RESERVATION[statusCode - 1])
      : "";

  const onCheckItem = () => {
    if (onChange) onChange(data);
  };

  return (
    <FormControlLabel
      classes={{ root: classes.formControlRoot, label: classes.formControlLabel }}
      control={
        <Checkbox
          checked={isChecked}
          onChange={onCheckItem}
          name={data.name}
          className={classes.checkboxRoot}
          color="default"
          size="small"
        />
      }
      label={primaryLabel}
    />
  );
};
CheckboxStatus.propTypes = {
  data: PropTypes.object,
  displaySetting: PropTypes.object,
  onChange: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    paddingTop: 16,
    paddingLeft: 70,
  },
  viewModeSetting: {
    display: "flex",
    paddingRight: "10%",

    "&, *": {
      fontSize: 13,
    },
  },
  radioGroup: {
    paddingLeft: 16,
    "& label": {
      marginLeft: 0,
    },
  },
  radioItem: {
    "&, *": {
      fontSize: 15,
    },
  },
  statusContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    flexGrow: 1,
  },
  formControlRoot: { height: 30 },
  formControlLabel: {
    fontSize: 13,
    fontWeight: 400,
  },
  checkboxRoot: {
    color: props => props.sttColor || theme.palette.primary.main,
  },
  checkAll: {
    color: "#447AFF !important",
  },
}));
