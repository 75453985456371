import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import UploadFileAction from "redux/upload-file.redux";
import { FileUploadIcon } from "icons";
import { AppConstant, LangConstant } from "const";
import CropImage from "components/CropImage";
import UploadImageCell from "./UploadImageCell";
import { deepCloneJsonObject, getAttachmentUrl, getCommonKey, isObjectNotEqual, validateFileSize } from "utils";
import { NUMBER_CLASSIFICATION } from "./GroupClassification";

const UploadImageClassified = ({ data, onChange }) => {
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const classes = useStyles();
  const imageURI = useSelector(state => state.newsRedux.postImgUrl, shallowEqual);

  const [dataClassified, setDataClassified] = useState(data);
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);
  const [indexImage, setIndexImage] = useState(0);
  const [fileArray, setFileArray] = useState([]);
  const [isUpload, setIsUpload] = useState(false);

  const onSelectImage = (event, index) => {
    setIndexImage(index);
    setIsUpload(true);
    let uploadFile = event.target.files;
    let arrFiles = [...fileArray];
    let containInvalid = false;
    if (!validateFileSize(uploadFile[0].size)) {
      containInvalid = true;
      arrFiles.push(uploadFile[0]);
    }
    if (containInvalid) {
      onChange(null, getLabel(getCommonKey(LangConstant.TXT_EXCEEDED_CAPACITY_UPLOAD_IMAGE)));
    } else {
      dispatch(UploadFileAction.requestUploadImage({ file: uploadFile }));
    }
    setFileArray(arrFiles);
  };

  const onChangeImage = file => {
    let newCutFiles = [];
    newCutFiles.push(file);
    setIsUpload(true);
    dispatch(UploadFileAction.requestUploadImage({ file: newCutFiles }));
  };

  const onRemoveImage = index => {
    let dataNew = deepCloneJsonObject(dataClassified);
    dataNew.modelList[index].imageList = [];
    onChange(dataNew);
    setDataClassified(dataNew);
  };

  const onOpenCrop = index => {
    setIsOpenCropDialog(true);
    setIndexImage(index);
  };

  useEffect(() => {
    if (imageURI.length > 0 && isUpload) {
      let dataNew = deepCloneJsonObject(dataClassified);
      dataNew.modelList[indexImage].imageList = imageURI;
      setDataClassified(dataNew);
      setIsUpload(false);
      onChange(dataNew);
      dispatch(UploadFileAction.resetUploadFile());
    }
  }, [imageURI]);

  useEffect(() => {
    if (isObjectNotEqual(data, dataClassified)) {
      setDataClassified(data);
    }
  }, [data]);

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle2" className={clsx(classes.typographyHeader, "semiBold-sm-txt")}>
        {dataClassified.name || getLabel(LangConstant.FM_CLASSIFICATION_GROUP, { name: NUMBER_CLASSIFICATION.one })}
      </Typography>
      {dataClassified.modelList.map((data, index) => (
        <Box key={index} paddingLeft="20px">
          <Box className={clsx(classes.buttonRoot, data.imageList.length === 0 && classes.paddingTop)}>
            {data.imageList.length > 0 ? (
              <UploadImageCell
                url={getAttachmentUrl(data.imageList[0])}
                onOpenCrop={() => onOpenCrop(index)}
                onRemoveImage={() => onRemoveImage(index)}
              />
            ) : (
              <label htmlFor={"uploadFiles" + index}>
                <Box className={classes.input}>
                  <FileUploadIcon />
                </Box>
              </label>
            )}
          </Box>
          <Typography variant="body2" className={classes.typographyBottom}>
            {data.name}
          </Typography>

          <input
            onChange={event => onSelectImage(event, index)}
            id={"uploadFiles" + index}
            style={{ display: "none" }}
            type="file"
            accept={AppConstant.ACCEPT_IMAGE.join(",")}
            multiple={false}
          />
        </Box>
      ))}
      <CropImage
        isMultiple={false}
        isEnableCrop={true}
        inputArray={fileArray}
        selectedImage={indexImage}
        aspect={1 / 1}
        onSubmit={onChangeImage}
        isShow={isOpenCropDialog}
        onClose={() => setIsOpenCropDialog(false)}
        title={getLabel(getCommonKey(LangConstant.TXT_EDIT_IMAGE))}
      />
    </Box>
  );
};

UploadImageClassified.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

UploadImageClassified.defaultProps = {
  data: {},
  onChange: () => {},
};

export default memo(UploadImageClassified);

const useStyles = makeStyles({
  root: { display: "flex", paddingTop: 20 },
  buttonRoot: {
    width: 80,
    height: 80,
    border: "1px solid #D4D5D8",
    borderRadius: 3,
    textAlign: "-webkit-center",
  },
  paddingTop: {
    paddingTop: 24,
  },
  input: {
    width: 28,
    height: 28,
    boxShadow: " 1px 0px 4px rgba(0, 0, 0, 0.16)",
  },
  typographyHeader: {
    marginRight: 30,
    paddingTop: 30,
    maxWidth: 120,
  },
  typographyBottom: { fontSize: 13, textAlign: "center", marginTop: 10, maxWidth: 80 },
});
