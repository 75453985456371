import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import {
  DisplayModeBar,
  IconButtonMProductItem,
  DialogAddCategoryItem,
  CategoryItemListHeader,
  CategoryItemListBody,
} from "components/mn-master-product";
import {
  DialogAddFromMain,
  DialogCreateAccompaniedService,
  DialogEditAndWatchAccompaniedService,
} from "./accompanied-service";
import { DialogEditAndWatchService } from "./main-service";
import { DialogDelete } from "components/dialog";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, removeVietnameseTones, convertTimeItemDescription, formatCurrency } from "utils";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ItemDetailsContentAction from "redux/item-details-content.redux";
import ServiceItemAction from "redux/service-item.redux";
import ItemDetailsAction from "redux/item-details.redux";

const AccompaniedServiceAll = ({ childServiceModeId, childServiceModeArr, onChangeMode }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const getListServiceContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isGetListItemDetailsContentSuccess,
    shallowEqual,
  );
  const postServiceContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isPostItemDetailsContentSuccess,
    shallowEqual,
  );
  const putServiceContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isPutItemDetailsContentSuccess,
    shallowEqual,
  );
  const deleteServiceContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isDeleteItemDetailsContentSuccess,
    shallowEqual,
  );

  const dataItemDetailsContent = useSelector(
    state => state.itemDetailsContentRedux.dataGetListItemDetailsContent,
    shallowEqual,
  );

  const [listServiceContent, setListServiceContent] = useState([]);
  const [saveListServiceContent, setSaveListServiceContent] = useState([]);
  const [openAddAccompaniedServiceAll, setOpenAddAccompaniedServiceAll] = useState(false);
  const [isOpen, setIsOpen] = useState({
    addFromMain: false,
    createAccompanied: false,
  });
  const [isOpenByUid, setIsOpenByUid] = useState({
    isEdit: false,
    isWatch: false,
    isDelete: false,
    data: null,
  });

  const typeService = AppConstant.TYPE_CATEGORY.service;

  const onOpenAddAccompaniedService = () => {
    setOpenAddAccompaniedServiceAll(open => !open);
  };

  const onAddFromMainService = () => {
    setIsOpen(open => ({ ...open, addFromMain: !open.addFromMain }));
  };

  const onCreateAccompaniedService = () => {
    setIsOpen(open => ({ ...open, createAccompanied: !open.createAccompanied }));
  };

  const onCloseAll = () => {
    setOpenAddAccompaniedServiceAll(false);
    setIsOpen({
      addFromMain: false,
      createAccompanied: false,
    });
  };

  const onComeBack = () => {
    setIsOpen({
      addFromMain: false,
      createAccompanied: false,
    });
  };

  const onSearch = name => {
    let newListSearch = saveListServiceContent.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListServiceContent(newListSearch);
  };

  const onOpenDeleteAccompaniedServiceItem = uid => {
    let newServiceContent = listServiceContent.find(data => data.uid === uid);
    setIsOpenByUid({ ...isOpenByUid, isDelete: true, data: newServiceContent });
  };

  const onSubmitDeleteAccompaniedService = (e, uid) => {
    dispatch(ItemDetailsContentAction.deleteItemDetailsContent({ uid: uid }));
  };

  const onOpenEditAccompaniedService = uid => {
    let newServiceContent = listServiceContent.find(data => data.uid === uid);
    setIsOpenByUid({ ...isOpenByUid, isEdit: true, data: newServiceContent });
  };

  const onOpenWatchAccompaniedService = () => {
    // todo pj9 later can be used
    // let newServiceContent = listServiceContent.find(data => data.uid === uid);
    // setIsOpenByUid({ ...isOpenByUid, isWatch: true, data: newServiceContent });
  };

  const onCloseByUid = () => {
    setIsOpenByUid({
      isEdit: false,
      isWatch: false,
      isDelete: false,
      data: null,
    });
  };

  useEffect(() => {
    if (!dataItemDetailsContent) {
      dispatch(ItemDetailsContentAction.getListItemDetailsContent({ type: typeService }));
    } else {
      if (dataItemDetailsContent?.data) {
        setListServiceContent(dataItemDetailsContent.data);
        setSaveListServiceContent(dataItemDetailsContent.data);
      }
    }
  }, []);

  useEffect(() => {
    if (dataItemDetailsContent?.data && getListServiceContentSuccess) {
      dispatch(ItemDetailsContentAction.resetItemDetailsContent());
      setListServiceContent(dataItemDetailsContent.data);
      setSaveListServiceContent(dataItemDetailsContent.data);
    }
  }, [getListServiceContentSuccess]);

  useEffect(() => {
    if (postServiceContentSuccess) {
      dispatch(ItemDetailsContentAction.resetItemDetailsContent());
      dispatch(ItemDetailsContentAction.getListItemDetailsContent({ type: typeService }));
      if (isOpen.createAccompanied) {
        onCloseAll();
      }
    }
  }, [postServiceContentSuccess]);

  useEffect(() => {
    if (putServiceContentSuccess) {
      dispatch(ItemDetailsContentAction.resetItemDetailsContent());
      dispatch(ItemDetailsContentAction.getListItemDetailsContent({ type: typeService }));
      dispatch(ItemDetailsAction.getListItemDetails({ type: typeService }));
      onCloseByUid();
    }
  }, [putServiceContentSuccess]);

  useEffect(() => {
    if (deleteServiceContentSuccess) {
      dispatch(ItemDetailsContentAction.resetItemDetailsContent());
      dispatch(ItemDetailsContentAction.getListItemDetailsContent({ type: typeService }));
      dispatch(ServiceItemAction.getListServiceItem({ type: typeService }));
      dispatch(ItemDetailsAction.getListItemDetails({ type: typeService }));
      onCloseByUid();
    }
  }, [deleteServiceContentSuccess]);

  return (
    <>
      <DisplayModeBar
        modeId={childServiceModeId}
        modeArray={childServiceModeArr}
        labelBtn={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
          LangConstant.TXT_SERVICE_LOWER_CASE,
        )}`}
        onChange={onChangeMode}
        onAddItem={onOpenAddAccompaniedService}
        onSearch={onSearch}
        showSearch={Boolean(saveListServiceContent.length > 0)}
      />
      <Box className={classes.contentBox}>
        {Boolean(saveListServiceContent.length > 0) ? (
          <Box className={classes.rootBody}>
            <CategoryItemListHeader isProduct={false} />
            {listServiceContent.map(dataServiceItem => (
              <CategoryItemListBody
                key={dataServiceItem.uid}
                uid={dataServiceItem.uid}
                onClickDelete={onOpenDeleteAccompaniedServiceItem}
                onClickEdit={onOpenEditAccompaniedService}
                onClickWatch={onOpenWatchAccompaniedService}
                categoryName={dataServiceItem.name}
                isAdd={false}
                timeUsed={
                  dataServiceItem.usingTime && dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.times
                    ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                    : ""
                }
                image={dataServiceItem.image}
                price={
                  dataServiceItem.price
                    ? `${formatCurrency(`${dataServiceItem.price}`)} ${getLabel(LangConstant.TXT_CURRENCY)}/${
                        dataServiceItem.usingTime &&
                        dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.time
                          ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                          : getLabel(getCommonKey(LangConstant.P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT))
                      }`
                    : ""
                }
                disabled={Boolean(dataServiceItem.itemDetailsContentUid)}
                isNotClickEdit={Boolean(isOpenByUid.isEdit && dataServiceItem.itemUid)}
              />
            ))}
          </Box>
        ) : (
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
              LangConstant.TXT_SERVICE_LOWER_CASE,
            )}`}
            onClick={onOpenAddAccompaniedService}
            buttonClasses={{ root: classes.addButton }}
          />
        )}
        {openAddAccompaniedServiceAll && (
          <DialogAddCategoryItem
            isShow={openAddAccompaniedServiceAll}
            titleName={{
              header: getLabel(LangConstant.TXT_CHOOSE_TYPE_CREATE_ACCOMPANIED),
              add: getLabel(LangConstant.TXT_ADD_ACCOMPANIED_FROM_MAIN),
              created: getLabel(LangConstant.TXT_CREATE_SERVICE_ACCOMPANIED),
            }}
            action={onCloseAll}
            inputProps={{ className: classes.dialogAddTypo }}
            onAddFromMainService={onAddFromMainService}
            onCreateAccompaniedService={onCreateAccompaniedService}
          />
        )}
        {isOpen.addFromMain && (
          <DialogAddFromMain isOpen={isOpen.addFromMain} onClose={onCloseAll} onBack={onComeBack} />
        )}
        {isOpen.createAccompanied && (
          <DialogCreateAccompaniedService isOpen={isOpen.createAccompanied} onClose={onCloseAll} onBack={onComeBack} />
        )}
        {isOpenByUid.data?.uid && isOpenByUid.isDelete && (
          <DialogDelete
            uid={isOpenByUid.data?.uid}
            isShow={isOpenByUid.isDelete}
            onCancel={onCloseByUid}
            onConfirm={onSubmitDeleteAccompaniedService}
            title={getLabel(LangConstant.FN_DELETE_SERVICE_ITEM_TITLE)(isOpenByUid.data?.name)}
            content={getLabel(LangConstant.FN_DELETE_ACCOMPANIED_SERVICE_ITEM_CONTENT)(isOpenByUid.data?.name)}
          />
        )}
        {isOpenByUid.data?.uid && (isOpenByUid.isEdit || isOpenByUid.isWatch) && !isOpenByUid.data?.itemUid && (
          <DialogEditAndWatchAccompaniedService
            data={isOpenByUid.data}
            onClose={onCloseByUid}
            isEdit={isOpenByUid.isEdit}
            isWatch={isOpenByUid.isWatch}
          />
        )}
        {isOpenByUid.isWatch && isOpenByUid.data?.itemUid && (
          <DialogEditAndWatchService
            uid={isOpenByUid.data?.itemUid}
            isWatch={isOpenByUid.isWatch}
            onClose={onCloseByUid}
          />
        )}
      </Box>
    </>
  );
};

export default memo(AccompaniedServiceAll);

const useStyles = makeStyles(theme => ({
  contentBox: {
    width: "100%",
    minHeight: "720px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  rootBody: {
    marginTop: 62,
    marginBottom: 62,
    width: "94%",
    marginLeft: "3%",
    [theme.breakpoints.down(600)]: {
      overflowX: "auto",
    },
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  dialogAddTypo: {
    color: "#3e4045",
    lineHeight: 1.5,
    fontWeight: "bold",
  },
}));
