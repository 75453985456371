import React, { memo } from "react";
import PropTypes from "prop-types";

import { Menu, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey } from "utils";

const NotificationSettingMenu = ({ onSelect, isShopOwner }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuItemClick = (event, index) => {
    onSelect(event, index);
    onClose();
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography variant="h4" component="p" className={classes.settingEllipsis} onClick={onMenuClick}>
        {getLabel(getCommonKey(LangConstant.TXT_ELLIPSIS))}
      </Typography>
      <Menu
        id="notification-setting-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        {LangConstant.ARR_NOTIFICATION_SETTING.map((value, index) => {
          return (
            (index === MARK_ALL || (index === SHOW_SETTING && isShopOwner)) && (
              <MenuItem
                onClick={e => onMenuItemClick(e, index)}
                key={index}
                className={` ${classes.menuItemRoot} regular-md-txt`}
              >
                {getLabel(getCommonKey(value))}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

export const MARK_ALL = 0;
export const SHOW_SETTING = 1;

const useStyles = makeStyles(theme => ({
  settingEllipsis: {
    color: theme.palette.white,
    paddingBottom: 10,
    cursor: "pointer",
  },
  menuPaper: {
    marginTop: "2px",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: 160,
    borderRadius: 4,
    "&::before": {
      position: "absolute",
      top: "-9px",
      right: "4%",
      borderBottom: `10px solid ${theme.palette.white}`,
      borderRight: "20px solid transparent",
      borderLeft: "20px solid transparent",
      content: `''`,
      [theme.breakpoints.down("md")]: {
        right: "5%",
      },
      [theme.breakpoints.down("sm")]: {
        right: "-3%",
      },
    },
    "&.MuiPopover-paper": {
      overflow: "visible",
    },
  },
  menuList: {
    paddingTop: `0px`,
    paddingBottom: `0px`,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    minHeight: 40,
    color: "#3e4045",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

NotificationSettingMenu.propTypes = {
  onSelect: PropTypes.func,
  isShopOwner: PropTypes.bool,
};
NotificationSettingMenu.defaultProps = {
  onSelect: () => {},
  isShopOwner: false,
};

export default memo(NotificationSettingMenu);
