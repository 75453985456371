import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Button, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCommonKey, getFeatureConditions } from "utils";
import { LangConstant } from "const";
import clsx from "clsx";

const SettingComponentHeader = ({ title, onSubmit, isDisable, ...otherProps }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const tabCells = createTabCells(getLabel);

  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);

  let { isPremium, isOnlyOrdering, isOnlyBooking } = getFeatureConditions(reservationSetting);

  let isBookingTab = isOnlyBooking || isPremium;
  let isOrderTab = isOnlyOrdering || isPremium;
  return (
    <Box className={classes.headerBox}>
      <Box className={classes.header}>
        <Typography className={classes.textHeader}>{title}</Typography>
        <Button
          onClick={onSubmit}
          classes={{ root: clsx("semiBold-lg-txt", classes.buttonSave), disabled: classes.disabledSaveButton }}
          disabled={isDisable}
        >
          <Typography className={classes.buttonText}>{getLabel(getCommonKey(LangConstant.TXT_SAVE))}</Typography>
        </Button>
      </Box>
      <Box className={classes.tabBox}>
        <Tabs className={classes.tabsRoot} indicatorColor="primary" textColor="primary" {...otherProps}>
          {isBookingTab && (
            <Tab
              value={cellIndexTab.booking}
              label={
                <Typography variant="h6" color="inherit" className="medium-md-txt">
                  {tabCells[cellIndexTab.booking]}
                </Typography>
              }
              wrapped
              classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
            />
          )}
          {isOrderTab && (
            <Tab
              value={cellIndexTab.ordering}
              label={
                <Typography variant="h6" color="inherit" className="medium-md-txt">
                  {tabCells[cellIndexTab.ordering]}
                </Typography>
              }
              wrapped
              classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
            />
          )}
        </Tabs>
      </Box>
    </Box>
  );
};

SettingComponentHeader.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  isDisable: PropTypes.bool,
};

export default memo(SettingComponentHeader);

const cellIndexTab = { booking: 0, ordering: 1 };

export const createTabCells = getLabel => {
  return [getLabel(LangConstant.TXT_BOOKING_TAB), getLabel(LangConstant.TXT_ORDER_TAB)];
};

const useStyles = makeStyles(theme => ({
  headerBox: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "solid 1px #ebeced",
    backgroundColor: "#fafafb",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "16px 54px 10px 54px",
    borderBottom: "1px solid #ebeced",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 24px 10px 24px",
    },
  },
  textHeader: {
    alignSelf: "center",
    fontSize: 18,
    fontWeight: 600,
  },
  buttonSave: {
    width: "auto",
    height: 40,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    color: theme.palette.white,
    "&.MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  disabledSaveButton: {
    opacity: 0.6,
  },
  buttonText: {
    color: theme.palette.white,
  },
  tabBox: {
    paddingLeft: 30,
  },
  tabsRoot: {
    width: "100%",
  },
  tabRoot: {
    minWidth: "max-content",
    padding: "9px 24px",
    textTransform: "none",
    color: "#3e4045",
    opacity: 1,
  },
  selectedTab: {
    color: theme.palette.primary.main,
  },
}));
