import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { addDays, format as DateFormat, isSameDay, subDays } from "date-fns";
import { Box, Button, makeStyles, Popover } from "@material-ui/core";
import { ArrowLeftTwoTone, ArrowRightTwoTone } from "@material-ui/icons";
import RangeDatePicker from "./RangeDatePicker";
import { AppConstant, LangConstant } from "const";
import { fetchDateToText, getEndOfDay, getStartOfDay, getTimestamp } from "utils/date";
import { getCommonKey } from "utils";

const SelectedRangeDate = ({ onChange, dateRange }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const datePickerDialogRef = useRef();

  const [isToday, setIsToday] = useState(true);
  const [isDateBetween, setIsDateBetween] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [datePickerAnchor, setDatePickerAnchor] = useState();
  const [dataSelectedDate, setDataSelectedDate] = useState();
  const [selectedDate, setSelectedDate] = useState(getLabel(LangConstant.TXT_RANGE_TIME_PICKER));

  const onSelected = data => {
    setIsShow(false);
    if (data.from && data.to) {
      onChange(data);
      setDataSelectedDate(data);
    }
  };

  const onClickToday = () => {
    setIsToday(true);
    onSelected(TODAY);
  };

  const onNextCalendar = () => {
    onSelected({
      from: addDays(dataSelectedDate.from || TODAY.from, 1),
      to: addDays(dataSelectedDate.to || TODAY.to, 1),
    });
  };

  const onPreviousCalendar = () => {
    onSelected({
      from: subDays(dataSelectedDate.from || TODAY.from, 1),
      to: subDays(dataSelectedDate.to || TODAY.to, 1),
    });
  };

  useEffect(() => {
    if (dateRange && dateRange.from && dateRange.to) setDataSelectedDate(dateRange);
  }, [dateRange]);

  useEffect(() => {
    if (dataSelectedDate && dataSelectedDate.from && dataSelectedDate.to) {
      setIsToday(isTimeRangeToday(dataSelectedDate));

      let intervalToCompare =
        dataSelectedDate.from && dataSelectedDate.to
          ? {
              start: getStartOfDay(dataSelectedDate.from),
              end: getEndOfDay(dataSelectedDate.to),
            }
          : {
              start: getStartOfDay(TODAY.from),
              end: getEndOfDay(TODAY.to),
            };
      setIsDateBetween(
        getTimestamp(new Date()) >= intervalToCompare.start && getTimestamp(new Date()) <= intervalToCompare.end,
      );
      if (isSameDay(dataSelectedDate.from, dataSelectedDate.to)) {
        setSelectedDate(fetchDateToText(dataSelectedDate.from, getLabel));
      } else {
        setSelectedDate(
          DateFormat(dataSelectedDate.from, AppConstant.FM_DD_MM_YYYY) +
            "-" +
            DateFormat(dataSelectedDate.to, AppConstant.FM_DD_MM_YYYY),
        );
      }
    }
  }, [dataSelectedDate]);

  return (
    <Box className={classes.root}>
      <Button
        classes={{
          root: clsx(
            "medium-md-txt",
            isToday
              ? classes.todayButtonSelected
              : isDateBetween
              ? classes.todayButtonWithinInterval
              : classes.todayButton,
          ),
        }}
        onClick={onClickToday}
      >
        {getLabel(getCommonKey(LangConstant.TXT_TODAY))}
      </Button>
      <Button
        classes={{
          root: classes.buttonCalendarLeft,
        }}
        onClick={onPreviousCalendar}
      >
        <ArrowLeftTwoTone className={classes.iconButtonCalendar} />
      </Button>
      <Button
        variant="outlined"
        size="small"
        classes={{ root: classes.btnRoot, label: `medium-md-txt` }}
        ref={datePickerDialogRef}
        onClick={() => {
          setDatePickerAnchor(datePickerDialogRef.current);
          setIsShow(true);
        }}
      >
        {selectedDate}
      </Button>
      <Button
        classes={{
          root: clsx(classes.buttonCalendarLeft, classes.buttonCalendarRight),
        }}
        onClick={onNextCalendar}
      >
        <ArrowRightTwoTone className={classes.iconButtonCalendar} />
      </Button>
      <Popover
        open={isShow}
        anchorEl={datePickerAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          root: classes.datePickerContainer,
          paper: classes.datePickerPaper,
        }}
        onClose={() => setIsShow(false)}
      >
        <RangeDatePicker onChange={onSelected} dateRange={dateRange} />
      </Popover>
    </Box>
  );
};
SelectedRangeDate.propTypes = {
  onChange: PropTypes.func,
  dateRange: PropTypes.shape({
    from: PropTypes.objectOf(Date),
    to: PropTypes.objectOf(Date),
  }),
};

SelectedRangeDate.defaultProps = {
  dateRange: {
    from: new Date(),
    to: new Date(),
  },
};

const TODAY = {
  from: new Date(),
  to: new Date(),
};

const isTimeRangeToday = timeRange => {
  if (!(timeRange && (timeRange.from || timeRange.to))) return false;

  let today = new Date();
  if (!timeRange.from) return isSameDay(today, timeRange.to);
  if (!timeRange.to) return isSameDay(today, timeRange.from);
  return Boolean(isSameDay(timeRange.from, timeRange.to) && isSameDay(timeRange.from, today));
};

export default memo(SelectedRangeDate);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: 30,
  },
  btnRoot: {
    textTransform: "none",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "unset",
    display: "flex",
    cursor: "pointer",
    minWidth: 200,
    paddingLeft: 8,
    paddingRight: 8,
    letterSpacing: "unset",
  },
  datePickerContainer: {
    display: "flex",
    textTransform: "none",
  },
  datePickerPaper: {
    display: "block",
    borderRadius: 4,
  },
  dateRangeIcon: {
    width: 18,
    height: 18,
    marginRight: 6,
  },
  buttonCalendarLeft: {
    minWidth: 28,
    height: 30,
    padding: "3px 2px",
    borderTop: "solid 1px #ebeced",
    borderBottom: "solid 1px #ebeced",
    borderRadius: 2,
  },
  buttonCalendarRight: {
    borderRight: "solid 1px #ebeced",
  },
  iconButtonCalendar: {
    width: 24,
    height: 24,
  },
  todayButton: {
    minWidth: 81,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    border: "solid 1px #ebeced",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
  todayButtonSelected: {
    minWidth: 80,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    padding: "4px 8px",
    backgroundColor: "#3e4045",
    color: theme.palette.white,
    "&>span>p": {
      color: theme.palette.white,
    },
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
  todayButtonWithinInterval: {
    background: "#f1f3f6",
    minWidth: 81,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    border: "solid 1px #ebeced",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
}));
