import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography } from "@material-ui/core";
import { CategoryItemListHeader, CategoryItemListBody } from "components/mn-master-product";
import { SearchBar } from "components";
import { Close, ArrowBack } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { getCommonKey, formatCurrency, removeVietnameseTones } from "utils";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import MnProductAction from "redux/mn-product.redux";

const DialogAddTable = ({ isOpen, onClose, onBack, nameTable, uidCategory }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const listDataItem = useSelector(state => state.mnProductRedux.dataItem, shallowEqual);
  const isListItemSuccess = useSelector(state => state.mnProductRedux.isGetSuccessItem, shallowEqual);
  const isPutItem = useSelector(state => state.mnProductRedux.isPutSuccessItem, shallowEqual);

  const [listProductItem, setListProductItem] = useState([]);
  const [saveListProductItem, setListSaveProductItem] = useState([]);

  const onSearch = name => {
    let newListSearch = saveListProductItem.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListProductItem(newListSearch);
  };

  useEffect(() => {
    dispatch(MnProductAction.getProductItem());
  }, []);

  useEffect(() => {
    if (listDataItem?.data && isListItemSuccess) {
      dispatch(MnProductAction.resetMnProduct());
      setListProductItem(listDataItem.data);
      setListSaveProductItem(listDataItem.data);
    }
  }, [isListItemSuccess]);

  useEffect(() => {
    if (listDataItem && listDataItem.data && isListItemSuccess) {
      setListProductItem(listDataItem.data);
      setListSaveProductItem(listDataItem.data);
    }
  }, [isListItemSuccess]);

  useEffect(() => {
    if (isPutItem) {
      dispatch(MnProductAction.resetMnProduct());
      dispatch(MnProductAction.getProductItem());
      dispatch(MnProductAction.getProductItemUidCategory({ category_uid: uidCategory }));
    }
  }, [isPutItem]);

  const onAddProductMainToAccompaniedProduct = uid => {
    let productItem = saveListProductItem.filter(itemSearch => itemSearch.uid === uid)[0];
    var categoryUidList = [];
    categoryUidList = productItem.categoryList.map(data => data.uid);
    categoryUidList.push(uidCategory);
    var dataProductItem = {
      ...productItem,
      categoryUidList: categoryUidList,
      itemDetails: null,
      itemDetailsUidList: [],
    };
    dispatch(MnProductAction.updateProductItem({ data: dataProductItem }));
  };

  return (
    <Dialog open={isOpen} fullScreen>
      <Box className={classes.rootHeader}>
        <Box className={classes.boxIconBack}>
          <IconButton onClick={onBack} className={classes.iconButton}>
            <ArrowBack className={classes.icon} />
          </IconButton>
        </Box>
        <Typography className={classes.nameHeaderTypo}>
          {`${getLabel(LangConstant.TXT_ADD_PRODUCT_CATEGORY_FROM_MAIN_GOOD)}
          ${nameTable.name}`}
        </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
      <Box textAlign="center">
        <SearchBar
          className={classes.searchBar}
          onKeyUp={onSearch}
          placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
        />
        <Box className={classes.rootBody}>
          <CategoryItemListHeader isProduct={true} />
          {listProductItem.map(dataProductItem => (
            <CategoryItemListBody
              key={dataProductItem.uid}
              uid={dataProductItem.uid}
              onClickAdd={onAddProductMainToAccompaniedProduct}
              categoryName={dataProductItem.name}
              isAdd={true}
              stock={dataProductItem.stock ? dataProductItem.stock : 0}
              image={dataProductItem.image}
              price={dataProductItem.price ? formatCurrency(`${dataProductItem.price}`) : ""}
              disabled={Boolean(dataProductItem.categoryList.find(data => data.uid === uidCategory))}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
export default memo(DialogAddTable);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: 400,
    height: 30,
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  rootBody: {
    marginTop: 48,
    marginBottom: 48,
    width: "80%",
    marginLeft: "10%",
    [theme.breakpoints.down(600)]: {
      width: "90%",
      marginLeft: "5%",
      overflowX: "auto",
    },
  },
}));
