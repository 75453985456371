import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { SearchBar } from "components";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { IconButtonMProductItem } from ".";

const DisplayModeBar = props => {
  const { modeId, modeArray, labelBtn, onChange, onAddItem, onSearch, showSearch } = props;
  const classes = useStyles();
  const mode = { all: 0, category: 1 };
  const { t: getLabel } = useTranslation();

  const [value, setValue] = useState(modeId ? mode[modeId] : 0);

  const onChangeTab = e => {
    if (e.currentTarget.id !== value) {
      setValue(mode[e.currentTarget.id]);
      onChange(e);
    }
  };

  const onAdd = e => {
    onAddItem(e);
  };

  return (
    <Box className={classes.barRoot}>
      <Tabs
        className={classes.tabsRoot}
        value={value}
        onChange={onChangeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        {modeArray.map(item => (
          <Tab
            key={item.id}
            label={
              <Typography color="inherit" className="medium-md-txt">
                {item.name}
              </Typography>
            }
            className={classes.tabRoot}
            id={item.id}
          />
        ))}
      </Tabs>
      {showSearch && (
        <>
          <SearchBar
            placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)}
            className={classes.searchBar}
            onKeyUp={onSearch}
          />
          <IconButtonMProductItem label={labelBtn} onClick={onAdd} buttonClasses={{ root: classes.button }} />
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  barRoot: {
    display: "flex",
    borderBottom: "solid 1px #ebeced",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },
  tabsRoot: {
    width: "100%",
  },
  tabRoot: {
    minWidth: "150px",
    textTransform: "none",
    color: "#3e4045",
  },
  searchBar: {
    width: 400,
    float: "left",
    [theme.breakpoints.down("sm")]: {
      margin: "8px 8px",
      width: "calc(100% - 16px)",
    },
  },
  button: {
    minWidth: 152,
    minHeight: 32,
    marginLeft: 8,
    marginRight: 24,
    paddingLeft: 12,
    paddingRight: 24,
    [theme.breakpoints.down("xs")]: {
      margin: "0 8px 8px",
    },
  },
}));

DisplayModeBar.propTypes = {
  modeArray: PropTypes.array,
  onChange: PropTypes.func,
  onAddItem: PropTypes.func,
  onSearch: PropTypes.func,
  labelBtn: PropTypes.string,
};

DisplayModeBar.defaultProps = {
  modeArray: [],
  onChange: () => {},
  onAddItem: () => {},
  onSearch: () => {},
  labelBtn: "",
};

export default memo(DisplayModeBar);
