import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import { getCommonKey } from "utils";
import ConsumerAction from "redux/consumer.redux";

const InviteDialog = ({ open, setOpen, listCustomers, shopAlias }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_CONSUMER);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isSelectEmail, setIsSelectEmail] = useState(false);
  const [isSelectPhone, setIsSelectPhone] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmitInvitation = () => {
    let emails = null;
    let phoneNumberList = null;
    if (shopAlias && listCustomers) {
      if (isSelectEmail) emails = listCustomers.map(item => item.email);
      if (isSelectPhone) phoneNumberList = listCustomers.map(item => item.phoneNumber);
      dispatch(ConsumerAction.patchInvitationConsumer({ emails: emails, phoneNumberList: phoneNumberList }));
      setOpen(false);
    }
  };

  useEffect(() => {
    setIsSelectEmail(false);
    setIsSelectPhone(false);
  }, [open]);

  let isMultiSelect = Boolean(listCustomers.length > 1);

  let labelEmail = getLabel(isMultiSelect ? LangConstant.TXT_INVITE_ALL_BY_EMAIL : LangConstant.TXT_INVITE_BY_EMAIL);
  let LabelPhoneNumber = getLabel(
    isMultiSelect ? LangConstant.TXT_INVITE_ALL_BY_PHONE_NUMBER : LangConstant.TXT_INVITE_BY_PHONE_NUMBER,
  );
  let LabelOptionDescription = getLabel(
    isMultiSelect ? LangConstant.TXT_INVITE_ALL_OPTION_DESCRIPTION : LangConstant.TXT_INVITE_OPTION_DESCRIPTION,
  );

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>{getLabel(LangConstant.TXT_INVITE_TITLE)}</DialogTitle>
      <DialogContent dividers className={classes.content}>
        {LabelOptionDescription}
        <Box className={classes.selectionBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelectEmail}
                onChange={event => setIsSelectEmail(event.target.checked)}
                name="email"
                color="primary"
              />
            }
            label={labelEmail}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelectPhone}
                onChange={event => setIsSelectPhone(event.target.checked)}
                name="tel"
                color="primary"
              />
            }
            label={LabelPhoneNumber}
          />
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.rootActions, spacing: classes.actionSpacing }}>
        <Button
          onClick={onClose}
          classes={{
            root: classes.button,
            contained: classes.contained,
          }}
          size="small"
          variant="contained"
        >
          {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
        </Button>
        <Button
          onClick={onSubmitInvitation}
          classes={{
            root: classes.button,
            contained: classes.contained,
            disabled: classes.disabled,
          }}
          color="primary"
          size="small"
          variant="contained"
          disabled={!isSelectEmail && !isSelectPhone}
        >
          {getLabel(getCommonKey(LangConstant.TXT_CONFIRM))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const useStyles = makeStyles(() => ({
  paper: {
    margin: "0 20px",
    boxShadow: "none",
    borderRadius: 0,
    maxWidth: 500,
    minHeight: 200,
  },
  content: {
    padding: "0px 24px 24px",
    borderTop: "none",
    lineHeight: "22px",
  },
  rootActions: {
    height: 60,
    padding: "8px 30px",
  },
  actionSpacing: {
    "&>*:not(:first-child)": {
      marginLeft: 16,
    },
  },
  selectionBox: {
    marginTop: 16,
    display: "grid",
  },
  button: {
    minWidth: 90,
    borderRadius: 0,
    color: "white",
    "&:hover": {
      opacity: 1,
    },
  },
  contained: {
    "&$disabled": {
      backgroundColor: "#EF5845",
      opacity: 0.6,
      color: "#ffffff",
    },
  },
  disabled: {
    backgroundColor: "#EF5845",
    opacity: 0.6,
  },
}));
export default memo(InviteDialog);
