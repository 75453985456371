import React, { memo, useEffect, useState } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Box, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { getStatusColor } from "utils";
import {
  createCategoryAxis,
  createColumnChartTotal,
  createColumnLayoutChart,
} from "../BookingByTime/BookingByTimeChart";

const BookingByConsumerChart = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [summaryData, viewBy, arraySetting, viewMode] = useSelector(({ reportDashboard }) => [
    reportDashboard.summaryCustomer.data,
    reportDashboard.summaryCustomer.viewBy,
    reportDashboard.summaryCustomer.setting.details,
    reportDashboard.summaryCustomer.setting.view_mode,
  ]);

  const [chartData, setChartData] = useState();
  const [columnLayout, setColumnLayout] = useState([]);

  useEffect(() => {
    if (!arraySetting) return;

    setColumnLayout(
      arraySetting.map(item => ({
        ...item,
        filed: item.display_id,
        color: getStatusColor(item.display_id),
      })),
    );
  }, [arraySetting]);

  useEffect(() => {
    if (!summaryData) return;
    setChartData(summaryData);
  }, [summaryData]);

  useEffect(() => {
    if (!chartData || !Array.isArray(chartData)) return;
    // Create chart instance
    let chart = window.am4core.create(chartHtmlId, am4charts.XYChart);
    chart.scrollbarX = new window.am4core.Scrollbar();
    chart.scrollbarX.marginBottom = 24;
    chart.scrollbarX.marginTop = -8;

    // Add data
    if (AppConstant.CHART_VIEW_BY.week === viewBy) {
      let arrDayOfWeekLang = LangConstant.ARR_DAY_OF_WEEK_SHORT.map(itemLang => getLabel(itemLang));

      chart.data = chartData.map(item => ({
        ...item,
        date: arrDayOfWeekLang[parseInt(item.date)],
      }));
    } else {
      chart.data = chartData;
    }

    // Create axes
    createCategoryAxis(chart, AppConstant.CHART_VIEW_BY.hour === viewBy && chartData.length);
    chart.yAxes.push(new am4charts.ValueAxis());

    if (AppConstant.CHART_VIEW_MODE.overview == viewMode) {
      createColumnChartTotal(chart);
    } else if (AppConstant.CHART_VIEW_MODE.detail == viewMode) {
      columnLayout.forEach(column => createColumnLayoutChart(chart, column));
    }

    chart.cursor = new am4charts.XYCursor();
  }, [chartData, columnLayout, viewMode]);

  return <Box id={chartHtmlId} className={classes.chart} />;
};

BookingByConsumerChart.propTypes = {};
export default memo(BookingByConsumerChart);

const chartHtmlId = "bookingByCustomerChart";

const useStyles = makeStyles({
  chart: {
    width: "100%",
    height: 450,
  },
});
