import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import { Box, Button, Chip, Hidden, makeStyles, Paper, Typography } from "@material-ui/core";
import { Clear, Star, StarOutline } from "@material-ui/icons";
import StringFormat from "string-format";
import clsx from "clsx";
import RatingAction from "redux/rating.redux";
import ComboItemAction from "redux/combo-item.redux";
import MnProductAction from "redux/mn-product.redux";
import ServiceItemAction from "redux/service-item.redux";
import { LangConstant } from "const";
import { isArrayNotEqual, isObjectNotEqual, textNormalize } from "utils";
import { TablePagination } from "components/table";
import { Filter, MobileFilter, RatingItem } from "./components";

const MnReview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_RATING_MANAGEMENT);

  const shopInfo = useSelector(state => state.shopInfoRedux.data, shallowEqual);
  const {
    ratingSummary,
    ratingList: ratingListRedux,
    pagination,
  } = useSelector(state => state.ratingRedux, shallowEqual);
  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem?.data, shallowEqual);
  const listProductItem = useSelector(state => state.mnProductRedux.dataItem?.data, shallowEqual);
  const listServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem?.data, shallowEqual);

  const [filterByScore, setFilterByScore] = useState([]);
  const [filterBy, setFilterBy] = useState(FILTER_BY.shop);
  const [filterByReport, setFilterByReport] = useState(false);
  const [filterByProduct, setFilterByProduct] = useState([]);
  const [ratingData, setRatingData] = useState(ratingSummary);
  const [productReduxList, setProductReduxList] = useState([]);
  const [ratingList, setRatingList] = useState(ratingListRedux);
  const [page, setPage] = useState(pagination.page || 1);
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const onClearFilter = () => {
    setFilterByScore([]);
    setFilterByReport(false);
    setFilterByProduct([]);
  };

  const onRemoveReportFilter = () => {
    setFilterByReport(false);
  };

  const onRemoveProductFilter = uid => {
    let newProductFilterList = [...filterByProduct];
    newProductFilterList = newProductFilterList.filter(itemUid => itemUid !== uid);
    setFilterByProduct(newProductFilterList);
  };

  const onRemoveScoreFilter = score => {
    let newScoreFilterList = [...filterByScore];
    newScoreFilterList = newScoreFilterList.filter(scoreRating => scoreRating !== score);
    setFilterByScore(newScoreFilterList);
  };

  const onChangePage = nextPage => {
    setPage(nextPage);
  };

  useEffect(() => {
    dispatch(ComboItemAction.getListComboItem());
    dispatch(MnProductAction.getProductItem());
    dispatch(ServiceItemAction.getListServiceItem());
  }, []);

  useEffect(() => {
    if (shopInfo && shopInfo.id) {
      let shopId = shopInfo.id;
      if (filterBy === FILTER_BY.shop) {
        dispatch(
          RatingAction.getRatingShopSummary({
            shopId: shopId,
          }),
        );
      }
    }
  }, [filterBy]);

  useEffect(() => {
    if (shopInfo && shopInfo.id) {
      let shopId = shopInfo.id;
      if (filterBy === FILTER_BY.product) {
        dispatch(
          RatingAction.getRatingItemSummary({
            shopId: shopId,
            itemUid: filterByProduct,
            isReported: Number(filterByReport),
          }),
        );
      }
    }
  }, [filterBy, filterByProduct, filterByReport]);

  useEffect(() => {
    if (
      listComboItem &&
      listProductItem &&
      listServiceItem &&
      (listComboItem.length > 0 || listProductItem.length > 0 || listServiceItem.length > 0)
    ) {
      let productList = [...(listComboItem || []), ...(listProductItem || []), ...(listServiceItem || [])];
      setProductReduxList(productList);
    }
  }, [listComboItem, listProductItem, listServiceItem]);

  useEffect(() => {
    if (isArrayNotEqual(ratingListRedux, ratingList)) setRatingList(ratingListRedux);
  }, [ratingListRedux]);

  useEffect(() => {
    if (isObjectNotEqual(ratingSummary, ratingData)) setRatingData(ratingSummary);
  }, [ratingSummary]);

  useEffect(() => {
    if (shopInfo && shopInfo.id) {
      let shopId = shopInfo.id;
      if (filterBy === FILTER_BY.shop) {
        dispatch(
          RatingAction.getRatingShop({
            shopId: shopId,
            score: filterByScore,
            isReported: Number(filterByReport),
            page: page,
          }),
        );
      } else if (filterBy === FILTER_BY.product) {
        dispatch(
          RatingAction.getRatingItem({
            itemUid: filterByProduct,
            shopId: shopId,
            score: filterByScore,
            isReported: Number(filterByReport),
            page: page,
          }),
        );
      }
    }
  }, [shopInfo, filterBy, filterByScore, filterByReport, filterByProduct, page]);

  let isShowClearButton = filterByReport || filterByProduct.length > 0 || filterByScore.length > 0;

  return (
    <Paper className={classes.reviewPaper}>
      <Box className={classes.reviewHeader}>
        <Typography variant="h3" className={classes.reviewTitle}>
          {getLabel(LangConstant.TXT_CONSUMER_RATING_MANAGEMENT)}
        </Typography>
      </Box>
      <Box className={classes.reviewContainer}>
        <Box className={classes.reviewFilter}>
          <Typography variant="h2" className={classes.totalRatingPoint}>
            {ratingData.averageScore || 0}
          </Typography>

          <Box className={classes.starAndTotalRate}>
            <Rating
              emptySymbol={<StarOutline className={classes.emptyStar} />}
              fullSymbol={<Star className={classes.fullStar} />}
              readonly={true}
              initialRating={ratingData.averageScore}
              className={classes.ratingScore}
            />

            <Typography className={clsx(classes.totalRatingPoint, "medium-md-txt")}>
              {StringFormat(getLabel(LangConstant.FM_TOTAL_RATING_AMOUNT), ratingData.totalRating || 0)}
            </Typography>
          </Box>

          <Hidden smDown>
            <Filter
              filterBy={filterBy}
              filterByProduct={filterByProduct}
              filterByReport={filterByReport}
              filterByScore={filterByScore}
              setFilterBy={setFilterBy}
              setFilterByProduct={setFilterByProduct}
              setFilterByReport={setFilterByReport}
              setFilterByScore={setFilterByScore}
            />
          </Hidden>
        </Box>
        <Box className={classes.reviewList}>
          <Box className={classes.selectedFilterContainer}>
            <Button className={classes.mobileFilterButton} onClick={() => setIsShowDrawer(true)}>
              {getLabel(LangConstant.TXT_FILTER)}
            </Button>

            <Box className={classes.chipFilter}>
              {filterByReport && (
                <Chip
                  className={classes.filterChip}
                  size="small"
                  onDelete={onRemoveReportFilter}
                  label={getLabel(LangConstant.TXT_VIOLATION_REPORT)}
                  deleteIcon={<Clear className={classes.clearIcon} />}
                />
              )}
              {filterByProduct.length > 0 &&
                filterByProduct.map((uid, index) => (
                  <Chip
                    key={index}
                    size="small"
                    className={classes.filterChip}
                    onDelete={() => onRemoveProductFilter(uid)}
                    label={getChipTag(productReduxList, uid)}
                    deleteIcon={<Clear className={classes.clearIcon} />}
                  />
                ))}
              {filterByScore.length > 0 &&
                filterByScore.map((score, index) => (
                  <Chip
                    className={classes.filterChip}
                    key={`${index} - ${score}`}
                    size="small"
                    onDelete={() => onRemoveScoreFilter(score)}
                    label={StringFormat(getLabel(LangConstant.FM_RATING_SCORE), score)}
                    deleteIcon={<Clear className={classes.clearIcon} />}
                  />
                ))}
            </Box>
            {isShowClearButton && (
              <Button
                variant="text"
                className={clsx(classes.clearFilterButton, "regular-md-txt")}
                onClick={onClearFilter}
              >
                {getLabel(LangConstant.TXT_CLEAR_FILTER)}
              </Button>
            )}
          </Box>
          {ratingList.length > 0 && (
            <>
              {ratingList.map(item => (
                <RatingItem key={item.uid} data={item} />
              ))}
              <TablePagination
                page={pagination.page}
                rowsPerPage={pagination.size}
                total={pagination.total}
                onChange={onChangePage}
                className={classes.tablePagination}
              />
            </>
          )}
        </Box>
      </Box>
      <Hidden mdUp>
        <MobileFilter
          filterByInitiate={filterBy}
          filterByProductInitiate={filterByProduct}
          filterByReportInitiate={filterByReport}
          filterByScoreInitiate={filterByScore}
          onChangeFilterBy={setFilterBy}
          onChangeFilterByProduct={setFilterByProduct}
          onChangeFilterByReport={setFilterByReport}
          onChangeFilterByScore={setFilterByScore}
          onClose={() => setIsShowDrawer(false)}
          open={isShowDrawer}
        />
      </Hidden>
    </Paper>
  );
};

MnReview.propTypes = {};

const getChipTag = (dataList, uid) => {
  let tagName = dataList.find(item => item.uid === uid)?.name || "";
  if (tagName.length > 40) {
    tagName = tagName.slice(0, 41);
    tagName = tagName + "...";
  }
  return tagName;
};

export const getRatingPercentage = (ratingData, scoreValue) => {
  if (!ratingData || !scoreValue || (ratingData && (!Object.keys(ratingData) || !ratingData.totalRating))) return 0;
  return (ratingData.ratingCount[scoreValue] / ratingData.totalRating) * 100;
};

export const getFilterArray = (array, search) => {
  let filterItems = [];
  if (array && array.length > 0) {
    filterItems = array.filter(item => textNormalize(item.name).includes(search));
  }
  return filterItems;
};

const FILTER_BY = {
  shop: 1,
  product: 2,
};

const useStyles = makeStyles(theme => ({
  reviewPaper: {
    marginTop: 6,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.103529)",
  },

  reviewHeader: {
    padding: "16px 30px",
    borderBottom: "1px solid #F1F3F6",
  },

  reviewTitle: {
    marginTop: 6,
    fontSize: 18,
  },

  reviewContainer: {
    display: "flex",
    padding: "28px 30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  totalRatingPoint: {
    textAlign: "center",
    height: "fit-content",
  },

  reviewFilter: {
    display: "flex",
    width: "20%",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "baseline",
      flexDirection: "row",
    },
  },

  reviewList: {
    width: "75%",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 16,
    },
  },

  emptyStar: {
    color: "#FEBA40",
  },

  fullStar: {
    color: "#FEBA40",
  },

  ratingScore: {
    minWidth: "max-content",
    margin: "0 16px",
    [theme.breakpoints.up("md")]: {
      margin: "7px 0 0",
    },
  },

  starAndTotalRate: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },

  selectedFilterContainer: {
    height: "fit-content",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  clearFilterButton: {
    textTransform: "none",
    height: "fit-content",
    minWidth: "max-content",
    color: theme.palette.text.link,
  },

  filterChip: {
    backgroundColor: theme.palette.white,
    border: "1px solid #CECFD2",
    marginRight: 16,
  },

  clearIcon: {
    color: theme.palette.grey[500],
  },

  chipFilter: {
    lineHeight: "32px",
    width: "100%",
  },

  mobileFilterButton: {
    height: 30,
    width: 109,
    minWidth: 109,
    marginRight: 16,
    border: "1px solid #D4D5D8",
    textTransform: "none",
    fontSize: 13,
    fontWeight: 500,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  tablePagination: {
    marginTop: "auto",
  },
}));

export default MnReview;
