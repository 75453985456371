import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography, Button } from "@material-ui/core";
import { UploadImage, Warning } from "components/mn-master-product";
import { Input, Alert } from "components";
import { Close, Error, ArrowBack } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getCommonKey } from "utils";
import MnProductAction from "redux/mn-product.redux";
import { useSelector, useDispatch } from "react-redux";

const DialogAddAccompaniedProductAll = ({ isOpenWatch, isOpenEdit, isOpenCreate, onClose, onBack, data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();
  const [isDuplicate, setIsDuplicate] = useState(false);

  const errorUpdateDetailsContent = useSelector(state => state.mnProductRedux.errorUpdateDetailsContent);
  const errorCreateDetailsContent = useSelector(state => state.mnProductRedux.errorCreateDetailsContent);

  const [formInput, setFormInput] = useState(
    data
      ? data
      : {
          name: "",
          image: "",
          type: AppConstant.TYPE_CATEGORY.product_info,
          description: "",
          itemUid: null,
          detailsUidList: [],
        },
  );
  const [notify, setNotify] = useState({
    value: false,
    message: "",
    type: 0,
  });

  const onTypingName = value => {
    setFormInput({
      ...formInput,
      name: value,
    });
  };

  const onUploadFile = (value, error) => {
    if (!error) {
      setFormInput({
        ...formInput,
        image: value[0],
      });
    } else {
      setNotify({
        value: true,
        message: error,
        type: 1,
      });
    }
  };

  const onEditImage = () => {};

  const onSubmitForm = () => {
    if (formInput.name) {
      if (formInput.uid != null) {
        dispatch(MnProductAction.updateDetailsContent(formInput));
      } else {
        dispatch(MnProductAction.createDetailsContent(formInput));
      }
    } else {
      setNotify({
        value: true,
        message: getLabel(LangConstant.FN_NOTIFY_INPUT_NAME)(getLabel(LangConstant.L_ATTACHED_SERVICE)),
        type: 2,
      });
    }
  };

  const onCloseNotify = () => {
    setNotify({
      value: false,
      message: "",
      type: 0,
    });
  };

  useEffect(() => {
    if (errorUpdateDetailsContent) {
      if (errorUpdateDetailsContent?.name?.[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dispatch(MnProductAction.resetErrorMnProduct());
      }
    }
    if (errorCreateDetailsContent) {
      if (errorCreateDetailsContent?.name?.[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dispatch(MnProductAction.resetErrorMnProduct());
      }
    }
  }, [errorUpdateDetailsContent, errorCreateDetailsContent]);

  return (
    <Dialog open={isOpenWatch || isOpenEdit || isOpenCreate} fullScreen>
      <Box className={classes.rootHeader}>
        <Box className={classes.boxIconBack}>
          <IconButton onClick={onBack} className={classes.iconButton}>
            <ArrowBack className={classes.icon} />
          </IconButton>
        </Box>
        <Typography className={classes.nameHeaderTypo}>
          {isOpenWatch
            ? `${getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_VIEW_DIALOG)}`
            : isOpenEdit
            ? `${getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_EDIT_DIALOG)}`
            : `${getLabel(LangConstant.TXT_CREATE_ACCOMPANIED_PRODUCT_CREATE_DIALOG)}`}
        </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.icon} />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.infoBox}>
        <Box padding="8px 0 16px">
          <Box className={classes.infoBasic}>
            <Typography className={classes.nameTypo}>{getLabel(LangConstant.TXT_NAME_PRODUCT)}</Typography>
          </Box>
          <Input
            placeholder={getLabel(LangConstant.TXT_NAME_PRODUCT)}
            classes={{ root: classes.rootName, input: classes.inputName }}
            required
            onChange={onTypingName}
            value={formInput.name}
            disabled={isOpenWatch}
          />
          {isDuplicate && (
            <Box className={classes.warningName}>
              <Error color="primary" className={classes.iconError} />
              <Typography color="primary" className={classes.textWarning}>
                {getLabel(LangConstant.TXT_WARNING_NAME_GOOD)}
              </Typography>
            </Box>
          )}
          {notify.value && notify.type == 2 && (
            <Alert isShow={notify.value} onClose={onCloseNotify} type="error" message={notify.message} />
          )}
        </Box>
        <Box padding="8px 0 16px">
          <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_ILLUSTRATION)}</Typography>
          <Box marginTop="8px">
            <UploadImage
              onChange={onUploadFile}
              action={onEditImage}
              imageList={Boolean(formInput.image) ? [formInput.image] : []}
              isDisabled={isOpenWatch}
            />
            {notify.value && notify.type == 1 && (
              <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
            )}
          </Box>
          {(isOpenEdit || isOpenCreate) && (
            <Box className={classes.boxButtonSave}>
              <Button className={classes.buttonSave} onClick={onSubmitForm}>
                {isOpenCreate
                  ? `${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))}`
                  : `${getLabel(getCommonKey(LangConstant.TXT_SAVE))}`}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
export default memo(DialogAddAccompaniedProductAll);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: "25%",
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down(600)]: {
      height: 136,
    },
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    width: 36,
    height: 36,
  },
  infoBox: {
    width: 430,
    marginTop: 46,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  infoBasic: {
    display: "flex",
    alignItems: "center",
    height: 22,
    marginBottom: 8,
  },
  nameTypo: {
    height: 22,
    lineHeight: 1.57,
    fontSize: 13,
    fontWeight: 600,
  },
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  rootName: {
    margin: 0,
    borderRadius: 3,
    "&.Mui-disabled": {
      color: "#3e4045",
    },
  },
  inputName: {
    height: 22,
    padding: "9px 16px",
    fontSize: 14,
    lineHeight: 1.57,
  },
  boxButtonSave: {
    display: "flex",
    justifyContent: "center",
    margin: "50px 0 54px",
  },
  buttonSave: {
    height: 46,
    padding: 0,
    minWidth: 102,
    fontSize: 16,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
}));
