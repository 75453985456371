import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  postComboItem: ["data"],
  postComboItemSuccess: ["data"],
  postComboItemFailure: ["data"],

  putComboItem: ["data"],
  putComboItemSuccess: ["data"],
  putComboItemFailure: ["data"],

  getComboItem: ["data"],
  getComboItemSuccess: ["data"],
  getComboItemFailure: ["data"],

  getListComboItem: ["data"],
  getListComboItemSuccess: ["data"],
  getListComboItemFailure: ["data"],

  deleteComboItem: ["data"],
  deleteComboItemSuccess: ["data"],
  deleteComboItemFailure: ["data"],

  resetComboItem: ["data"],
  resetErrorComboItem: ["data"],
});

export const ComboItemTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  // post
  isPostComboItemFetching: false,
  errorPostComboItem: null,
  isPostComboItemSuccess: false,
  dataPostComboItem: null,

  // put
  isPutComboItemFetching: false,
  errorPutComboItem: null,
  isPutComboItemSuccess: false,
  dataPutComboItem: null,

  // get by Uid
  isGetComboItemFetching: false,
  errorGetComboItem: null,
  isGetComboItemSuccess: false,
  dataGetComboItem: null,

  // get list
  isGetListComboItemFetching: false,
  errorGetListComboItem: null,
  isGetListComboItemSuccess: false,
  dataGetListComboItem: null,

  // delete
  isDeleteComboItemFetching: false,
  errorDeleteComboItem: null,
  isDeleteComboItemSuccess: false,
  dataDeleteComboItem: null,
};

/* ------------- Reducers ------------- */
/* ------------- Reducers Post Combo Item ------------- */
export const postComboItem = (state = INITIAL_STATE) => ({
  ...state,
  isPostComboItemFetching: true,
  errorPostComboItem: null,
});

export const postComboItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPostComboItemFetching: false,
    isPostComboItemSuccess: true,
    dataPostComboItem: action.data,
  };
};

export const postComboItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPostComboItemFetching: false,
  isPostComboItemSuccess: false,
  errorPostComboItem: action?.data?.message,
});

/* ------------- Reducers Put Combo Item ------------- */
export const putComboItem = (state = INITIAL_STATE) => ({
  ...state,
  isPutComboItemFetching: true,
  errorPutComboItem: null,
});

export const putComboItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPutComboItemFetching: false,
    isPutComboItemSuccess: true,
    dataPutComboItem: action.data,
  };
};

export const putComboItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPutComboItemFetching: false,
  isPutComboItemSuccess: false,
  errorPutComboItem: action?.data?.message,
});

/* ------------- Reducers Get By Uid Combo Item ------------- */
export const getComboItem = (state = INITIAL_STATE) => ({
  ...state,
  isGetComboItemFetching: true,
});

export const getComboItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetComboItemFetching: false,
    isGetComboItemSuccess: true,
    dataGetComboItem: action.data,
  };
};

export const getComboItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetComboItemFetching: false,
  isGetComboItemSuccess: false,
  errorGetComboItem: action.error,
});

/* ------------- Reducers Get List Combo Item ------------- */
export const getListComboItem = (state = INITIAL_STATE) => ({
  ...state,
  isGetListComboItemFetching: true,
});

export const getListComboItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetListComboItemFetching: false,
    isGetListComboItemSuccess: true,
    dataGetListComboItem: action.data,
  };
};

export const getListComboItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetListComboItemFetching: false,
  isGetListComboItemSuccess: false,
  errorGetListComboItem: action.error,
});

/* ------------- Reducers Delete Combo Item ------------- */
export const deleteComboItem = (state = INITIAL_STATE) => ({
  ...state,
  isDeleteComboItemFetching: true,
});

export const deleteComboItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isDeleteComboItemFetching: false,
    isDeleteComboItemSuccess: true,
    dataDeleteComboItem: action.data,
  };
};

export const deleteComboItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isDeleteComboItemFetching: false,
  isDeleteComboItemSuccess: false,
  errorDeleteComboItem: action.error,
});

/* ------------- Reducers Reset ------------- */
export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isPostComboItemSuccess: false,
  isPutComboItemSuccess: false,
  isGetComboItemSuccess: false,
  isGetListComboItemSuccess: false,
  isDeleteComboItemSuccess: false,
});

export const resetError = (state = INITIAL_STATE) => ({
  ...state,
  errorPutComboItem: null,
  errorPostComboItem: null,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  // Map Post Combo Item
  [Types.POST_COMBO_ITEM]: postComboItem,
  [Types.POST_COMBO_ITEM_SUCCESS]: postComboItemSuccess,
  [Types.POST_COMBO_ITEM_FAILURE]: postComboItemFailure,

  // Map Put Combo Item
  [Types.PUT_COMBO_ITEM]: putComboItem,
  [Types.PUT_COMBO_ITEM_SUCCESS]: putComboItemSuccess,
  [Types.PUT_COMBO_ITEM_FAILURE]: putComboItemFailure,

  // Map Get By Uid Combo Item
  [Types.GET_COMBO_ITEM]: getComboItem,
  [Types.GET_COMBO_ITEM_SUCCESS]: getComboItemSuccess,
  [Types.GET_COMBO_ITEM_FAILURE]: getComboItemFailure,

  // Map Get List Combo Item
  [Types.GET_LIST_COMBO_ITEM]: getListComboItem,
  [Types.GET_LIST_COMBO_ITEM_SUCCESS]: getListComboItemSuccess,
  [Types.GET_LIST_COMBO_ITEM_FAILURE]: getListComboItemFailure,

  // Map Delete Combo Item
  [Types.DELETE_COMBO_ITEM]: deleteComboItem,
  [Types.DELETE_COMBO_ITEM_SUCCESS]: deleteComboItemSuccess,
  [Types.DELETE_COMBO_ITEM_FAILURE]: deleteComboItemFailure,

  // Reset Combo Item
  [Types.RESET_COMBO_ITEM]: reset,
  [Types.RESET_ERROR_COMBO_ITEM]: resetError,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
