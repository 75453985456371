import React, { memo } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant } from "../const";
import PropTypes from "prop-types";

const BGlobalJSC = ({ className }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${className}`}>
      <Typography variant="body1" classes={{ body1: "regular-sm-txt" }} color="inherit">
        {getLabel(LangConstant.TXT_COPYRIGHT)}
      </Typography>
    </Box>
  );
};

BGlobalJSC.propTypes = {
  className: PropTypes.string,
};

BGlobalJSC.defaultProps = {};

export default memo(BGlobalJSC);

const useStyles = makeStyles({
  root: {
    width: "100%",
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
