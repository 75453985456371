import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { LangConstant, AppConstant } from "const";
import { formatCurrency, convertArrayToObjectWithKey } from "utils";
import DialogPreOrder, { ARRAY_CATEGORY, formatFormDataPreOrder } from "pages/MnReservation/components/DialogPreOrder";
import { PRE_ORDER_ACTION } from "pages/MnReservation/components/AddPreOrderInReservation";
import { InputPrice } from "components/mn-master-product";

const TabPreOrder = ({ isEditing, isReadOnly, detail, onSubmitFormData, onSendRequestPayment, onChangePrice }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const dataReservation = useSelector(state => state.reservationRedux);
  const listMenuData = useSelector(state => state.menuRedux.data);
  const listComboItem = useSelector(state => state.comboItemRedux.dataGetListComboItem);
  const listProductItem = useSelector(state => state.mnProductRedux.dataItem);
  const listServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem);

  const { reservationSetting } = dataReservation;
  let isSettingDeposit = Boolean(
    reservationSetting &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit] &&
      reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit].publish === 1,
  );

  const [arrayPreOrderService, setArrayPreOrderService] = useState([]);
  const [arrayPreOrderProduct, setArrayPreOrderProduct] = useState([]);
  const [arrayPreOrderCombo, setArrayPreOrderCombo] = useState([]);
  const [totalMoney, setTotalMoney] = useState(0);
  const [dataItemDetailDisplay, setDataItemDetailDisplay] = useState();
  const [preOrderOpen, setPreOrderOpen] = useState(PRE_ORDER_ACTION.close);
  const [depositSetting, setDepositSetting] = useState();

  let checkDetails =
    detail.details?.itemFrom === AppConstant.TYPE_DETAILS_PRE_ORDER.category
      ? AppConstant.TYPE_DETAILS_PRE_ORDER.category
      : AppConstant.TYPE_DETAILS_PRE_ORDER.menu;

  const onCloseDialogPreOrder = () => {
    setPreOrderOpen(PRE_ORDER_ACTION.close);
  };

  const onOpenDialogPreOrder = preOrderOpen => {
    setPreOrderOpen(preOrderOpen);
  };

  const onSubmitFormDataPreOrder = (data, price) => {
    let newPrice = detail.depositTotal;
    if (detail.status === AppConstant.STT_RESERVATION.pending) {
      if (depositSetting?.type === AppConstant.DEPOSIT_TYPE.percent) {
        newPrice = price * (depositSetting.value / 100);
      } else {
        newPrice = depositSetting?.value || 0;
      }
    }
    onSubmitFormData(data, newPrice);
    onCloseDialogPreOrder();
  };

  useEffect(() => {
    let orderList = detail?.orderDetailsList ? [...detail.orderDetailsList] : [];

    let listService = [];
    let listProduct = [];
    let listCombo = [];
    let sumMoney = 0;

    orderList.forEach(dataMap => {
      sumMoney += (dataMap.orderNumber || 0) * (dataMap.priceTaxIn || 0);
      dataMap.orderDetailsContentList?.forEach(dataChild => {
        sumMoney += (dataChild.orderNumber || 0) * (dataChild.priceTaxIn || 0);
      });
      switch (dataMap.type) {
        case AppConstant.TYPE_CATEGORY.service:
          listService.push(dataMap);
          break;
        case AppConstant.TYPE_CATEGORY.product:
          listProduct.push(dataMap);
          break;
        case AppConstant.TYPE_CATEGORY.combo:
          listCombo.push(dataMap);
          break;
      }
    });

    setArrayPreOrderService(listService);
    setArrayPreOrderProduct(listProduct);
    setArrayPreOrderCombo(listCombo);
    setTotalMoney(sumMoney);
  }, [detail?.orderDetailsList]);

  useEffect(() => {
    if (listMenuData || (listServiceItem && listProductItem && listComboItem)) {
      let orderList = detail?.orderDetailsList ? [...detail.orderDetailsList] : [];

      let handleDataArray = [];
      if (checkDetails === AppConstant.TYPE_DETAILS_PRE_ORDER.category) {
        handleDataArray = convertFormDataCategory(orderList, listServiceItem, listProductItem, listComboItem);
      } else {
        handleDataArray = convertFormDataMenu(orderList, listMenuData);
      }
      setDataItemDetailDisplay(handleDataArray);
    }
  }, [listMenuData, listServiceItem, listProductItem, listComboItem]);

  useEffect(() => {
    if (isSettingDeposit) {
      setDepositSetting(
        reservationSetting[AppConstant.RESERVATION_SETTING_OPTION.deposit]?.details?.depositInformation,
      );
    }
  }, [reservationSetting]);

  let checkOrderDetailsList = Boolean(detail?.orderDetailsList?.length > 0);

  return (
    <>
      <Box className={classes.productBox}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={`semiBold-sm-txt ${classes.productTitle}`}>
            {getLabel(LangConstant.TXT_MASTER_PRODUCT)}
          </Typography>
          {isEditing && !isReadOnly && (
            <Box className={classes.boxButton}>
              <Button
                onClick={() => onOpenDialogPreOrder(PRE_ORDER_ACTION.detailsProduct)}
                disableRipple
                className={`medium-sm-txt ${classes.buttonSendRequestPayment}`}
              >
                {`${getLabel(LangConstant.TXT_EDIT_FROM_CATEGORY)}${
                  checkDetails === AppConstant.TYPE_DETAILS_PRE_ORDER.category
                    ? ` (${getLabel(LangConstant.TXT_CHOOSED)})`
                    : ""
                }`}
              </Button>
            </Box>
          )}
        </Box>
        {!isEditing && (
          <>
            {arrayPreOrderService.length > 0 && (
              <Box paddingBottom="6px">
                <LayoutPreOrder
                  title={<Typography className={classes.typoTitle}>{getLabel(LangConstant.TXT_SERVICE)}</Typography>}
                >
                  {arrayPreOrderService.map((dataParent, indexParent) => (
                    <Box key={indexParent} paddingBottom="6px">
                      <Typography className={classes.typoTitle}>{dataParent.name}</Typography>
                      <Box display="flex" flexWrap="wrap">
                        {dataParent.orderDetailsContentList?.length > 0 &&
                          dataParent.orderDetailsContentList.map((dataChild, indexChild) => (
                            <Box key={indexChild} marginRight="18px">
                              <Typography className={classes.typoContent}>{dataChild.name}</Typography>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ))}
                </LayoutPreOrder>
              </Box>
            )}

            {arrayPreOrderProduct.length > 0 && (
              <Box paddingBottom="6px">
                <LayoutPreOrder
                  title={<Typography className={classes.typoTitle}>{getLabel(LangConstant.TXT_PRODUCT)}</Typography>}
                >
                  {arrayPreOrderProduct.map((dataParent, indexParent) => (
                    <Box key={indexParent} paddingBottom="6px">
                      <Typography className={classes.typoTitle}>{dataParent.name}</Typography>
                      {dataParent.orderDetailsContentList?.length > 0 && (
                        <Typography className={classes.typoContent}>
                          {convertProduct(dataParent.orderDetailsContentList)}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </LayoutPreOrder>
              </Box>
            )}

            {arrayPreOrderCombo.length > 0 && (
              <Box paddingBottom="6px">
                <LayoutPreOrder
                  title={<Typography className={classes.typoTitle}>{getLabel(LangConstant.TXT_COMBO)}</Typography>}
                >
                  {arrayPreOrderCombo.map((dataParent, indexParent) => (
                    <Box key={indexParent} paddingBottom="6px">
                      <Typography className={classes.typoTitle}>{dataParent.name}</Typography>
                      {dataParent.orderDetailsContentList?.length > 0 && (
                        <Typography className={classes.typoContent}>
                          {convertProduct(dataParent.orderDetailsContentList)}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </LayoutPreOrder>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box className={classes.paymentBox}>
        <Typography className={`semiBold-md-txt ${classes.paymentTitle}`}>
          {getLabel(LangConstant.TXT_PAYMENT)}
        </Typography>
        <LayoutPreOrder
          title={
            <Box paddingBottom="12px">
              <Typography className={classes.typoTitle}>{getLabel(LangConstant.TXT_TOTAL_MONEY)}</Typography>
            </Box>
          }
        >
          <Typography className={classes.typoTitle}>{`${formatCurrency(totalMoney)} ${getLabel(
            LangConstant.TXT_CURRENCY_COMMON,
          )}`}</Typography>
        </LayoutPreOrder>
        {isSettingDeposit && checkOrderDetailsList && (
          <LayoutPreOrder
            title={
              <Box display="flex" height="100%" alignItems="center">
                <Typography className={classes.typoTitle}>{getLabel(LangConstant.TXT_PRE_PAYMENT)}</Typography>
              </Box>
            }
          >
            {isEditing && !isReadOnly && detail.status === AppConstant.STT_RESERVATION.pending ? (
              <InputPrice
                onChange={onChangePrice}
                currencyUnit={getLabel(LangConstant.TXT_CURRENCY_COMMON)}
                valueInput={detail.depositTotal ? detail.depositTotal.toString() : ""}
                classPrice={classes.rootOverridePrice}
                classes={{
                  inputOverridePrice: classes.inputOverridePrice,
                  inputOverride: classes.inputOverride,
                }}
              />
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.typoTitle}>{`${formatCurrency(detail.depositTotal)} ${getLabel(
                  LangConstant.TXT_CURRENCY_COMMON,
                )}${
                  depositSetting?.type === AppConstant.DEPOSIT_TYPE.percent ? ` (${depositSetting?.value}%)` : ""
                }`}</Typography>
                {detail.status === AppConstant.STT_RESERVATION.pending && (
                  <Button
                    onClick={onSendRequestPayment}
                    disableRipple
                    className={`medium-sm-txt ${classes.buttonSendRequestPayment}`}
                  >
                    {getLabel(LangConstant.TXT_SEND_REQUEST_PAYMENT)}
                  </Button>
                )}
              </Box>
            )}
          </LayoutPreOrder>
        )}
      </Box>
      {isEditing && !isReadOnly && (
        <DialogPreOrder
          data={dataItemDetailDisplay || []}
          isShow={Boolean(preOrderOpen !== PRE_ORDER_ACTION.close && isEditing)}
          isShowCategory={
            dataItemDetailDisplay
              ? preOrderOpen === PRE_ORDER_ACTION.detailsProduct
              : checkDetails === AppConstant.TYPE_DETAILS_PRE_ORDER.category
          }
          onSubmitFormData={onSubmitFormDataPreOrder}
          onClose={onCloseDialogPreOrder}
        />
      )}
    </>
  );
};

const convertProduct = dataProduct => {
  let stringProduct = "";
  let lastIndexProduct = dataProduct.length - 1;
  dataProduct.forEach((dataMap, index) => {
    stringProduct += dataMap.name;
    if (lastIndexProduct !== index) {
      stringProduct += " - ";
    }
  });
  return stringProduct;
};

const convertFormDataMenu = (dataDisplay, dataMenu) => {
  let arrayDataMenu = [];
  let resultData = [];
  if (dataDisplay?.length > 0) {
    dataMenu?.forEach(dataCategory => {
      arrayDataMenu.push({
        ...dataCategory,
        itemList: convertArrayToObjectWithKey(dataCategory.itemList, "itemUid"),
      });
    });
  }
  dataDisplay?.forEach(mapData => {
    arrayDataMenu.find(dataCategory => {
      if (dataCategory.itemList[mapData.mainItemUid || mapData.itemUid]) {
        let toppingList = mapData.orderDetailsContentList?.map(orderData => ({
          ...orderData,
        }));
        let contentList = dataCategory.itemList[mapData.mainItemUid || mapData.itemUid]?.itemDetailsContentList;
        let toppingListIndex = addToppingIndex(toppingList, contentList);
        resultData.push({
          ...mapData,
          toppingList: toppingList,
          toppingListIndex: toppingListIndex,
          itemDetailsContentList: contentList,
        });
        return true;
      }
    });
  });
  return resultData;
};

const convertFormDataCategory = (dataDisplay, serviceData, productData, comboData) => {
  let newArrayData = [...ARRAY_CATEGORY];
  // Format Data Service Item
  let newFormListServiceItem = serviceData?.data || [];
  newArrayData[0].itemList = formatFormDataPreOrder(newFormListServiceItem, AppConstant.TYPE_CATEGORY.service);

  // Format Data Product Item
  let newFormListProductItem = productData?.data || [];
  newArrayData[1].itemList = formatFormDataPreOrder(newFormListProductItem, AppConstant.TYPE_CATEGORY.product);

  // Format Data Combo Item
  let newFormListComboItem = comboData?.data || [];
  newArrayData[2].itemList = formatFormDataPreOrder(newFormListComboItem, AppConstant.TYPE_CATEGORY.combo);

  let arrayDataCategory = [];
  let resultData = [];
  if (dataDisplay?.length > 0) {
    newArrayData.forEach(dataCategory => {
      arrayDataCategory.push({
        ...dataCategory,
        itemList: convertArrayToObjectWithKey(dataCategory.itemList, "uid"),
      });
    });
  }
  dataDisplay?.forEach(mapData => {
    arrayDataCategory.find(dataCategory => {
      if (dataCategory.itemList[mapData.itemUid]) {
        let toppingList = mapData.orderDetailsContentList?.map(orderData => ({
          ...orderData,
        }));
        let contentList = dataCategory.itemList[mapData.itemUid]?.itemDetailsContentList;
        let toppingListIndex = addToppingIndex(toppingList, contentList);
        resultData.push({
          ...mapData,
          toppingList: toppingList,
          toppingListIndex: toppingListIndex,
          itemDetailsContentList: contentList,
        });
        return true;
      }
    });
  });
  return resultData;
};

const addToppingIndex = (toppingList, contentList) => {
  let result = [];
  contentList?.forEach((contentMap, contentIndex) => {
    if (toppingList.find(toppingMap => toppingMap.itemUid === contentMap.uid)) {
      result.push(contentIndex);
    }
  });
  return result;
};

TabPreOrder.propTypes = {
  isEditing: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  detail: PropTypes.object,
  onSubmitFormData: PropTypes.func,
  onSendRequestPayment: PropTypes.func,
  onChangePrice: PropTypes.func,
};
TabPreOrder.defaultProps = {
  isEditing: false,
  isReadOnly: false,
  onSubmitFormData: () => {},
};

export default memo(TabPreOrder);

const LayoutPreOrder = ({ title, children }) => {
  return (
    <Box display="flex">
      <Box width="35%">{title}</Box>
      <Box width="65%">{children}</Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  productBox: {
    paddingBottom: 6,
    maxHeight: 400,
    overflow: "auto",
  },
  productTitle: {
    height: 20,
    marginBottom: 20,
    lineHeight: 1.67,
    color: "#3e4045",
  },
  boxButton: {
    width: "60%",
    display: "flex",
    flexFlow: "column",
    alignItems: "start",
  },
  typoTitle: {
    fontSize: 13,
    lineHeight: 1.69,
    color: "#565c6a",
  },
  typoContent: {
    fontSize: 12,
    lineHeight: 1.5,
    color: "#979aa1",
  },
  paymentBox: {
    paddingTop: 12,
  },
  paymentTitle: {
    height: 24,
    marginBottom: 16,
    lineHeight: 1.67,
    color: "#3e4045",
  },
  buttonSendRequestPayment: {
    padding: 0,
    textTransform: "none",
    color: theme.palette.text.link,
    minHeight: 22,
    textAlign: "left",
    "&:hover": {
      backgroundColor: theme.palette.white,
      textDecoration: "underline",
    },
  },
  rootOverridePrice: {
    height: 30,
    maxWidth: 159,
  },
  inputOverridePrice: {
    fontSize: 13,
    color: "#565c6a",
  },
  inputOverride: {
    justifyContent: "center",
    borderLeft: "solid 0.5px #cecfd2",
    marginLeft: 0,
    minWidth: 40,
    width: 40,
    height: "100%",
    maxHeight: "100%",
    minHeight: 30,
  },
}));
