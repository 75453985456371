import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon, withStyles } from "@material-ui/core";

const RangeDateIcon = ({ width, height, color, ...otherProps }) => {
  const SvgIconStyled = withStyles({
    root: { width: width, height: height },
  })(props => <SvgIcon {...props} {...otherProps} />);

  const defaultColor = color || "#3B404C";

  return (
    <SvgIconStyled width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0 0L24 0 24 24 0 24z" transform="translate(-1265 -152) translate(274 152) translate(991)" />
          <path
            fill={defaultColor}
            fillRule="nonzero"
            d="M10 17L15 12 10 7z"
            transform="translate(-1265 -152) translate(274 152) translate(991)"
          />
        </g>
        <g>
          <path
            d="M0 0L24 0 24 24 0 24z"
            transform="translate(-1265 -152) translate(274 152) translate(991) translate(9)"
          />
          <path
            fill={defaultColor}
            fillRule="nonzero"
            d="M10 17L15 12 10 7z"
            transform="translate(-1265 -152) translate(274 152) translate(991) translate(9)"
          />
        </g>
        <g>
          <path
            d="M0 0L24 0 24 24 0 24z"
            transform="translate(-1265 -152) translate(274 152) translate(991) translate(18)"
          />
          <path
            fill={defaultColor}
            fillRule="nonzero"
            d="M10 17L15 12 10 7z"
            transform="translate(-1265 -152) translate(274 152) translate(991) translate(18)"
          />
        </g>
      </g>
    </SvgIconStyled>
  );
};

RangeDateIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
RangeDateIcon.defaultProps = {
  width: 42,
  height: 24,
};

export default memo(RangeDateIcon);
