import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Typography, Grid } from "@material-ui/core";
import ServiceItemAction from "redux/service-item.redux";
import { AppConstant, LangConstant } from "const";
import { getCommonKey, textNormalize, removeFromArray, getUidList, convertPricePreOrder, formatUsingTime } from "utils";
import {
  DialogAddCategoryItem,
  DisplayModeBar,
  IconButtonMProductItem,
  ItemDescriptionBlock,
} from "components/mn-master-product";
import { DialogDelete } from "components/dialog";
import { DialogAddToCategory } from "pages/MnService/components/main-service";
import { DialogCreateMainService } from "../main-service";
import DialogViewEditMainService from "../main-service/DialogViewEditMainService";

const ServiceItemList = ({
  fromSubcategory,
  masterData,
  parentData,
  onJumpToMain,
  onBackToSubcategory,
  ...otherProps
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const dataServiceItem = useSelector(state => state.serviceItemRedux.dataCategoryServiceItem);

  const isPostServiceItemSuccess = useSelector(state => state.serviceItemRedux.isPostServiceItemSuccess, shallowEqual);
  const isPutServiceItemSuccess = useSelector(state => state.serviceItemRedux.isPutServiceItemSuccess, shallowEqual);
  const isDeleteServiceItemSuccess = useSelector(
    state => state.serviceItemRedux.isDeleteServiceItemSuccess,
    shallowEqual,
  );

  const [serviceItemList, setServiceItemList] = useState(dataServiceItem);
  const [isOpen, setIsOpen] = useState({
    addServiceType: false,
    addService: false,
    createService: false,
    viewService: false,
    editService: false,
    removeService: false,
    targetUid: null,
    targetName: "",
  });

  const onOpenAddServiceType = () => {
    setIsOpen({ ...isOpen, addServiceType: true });
  };

  const onBackAddServiceType = () => {
    setIsOpen({ ...isOpen, addServiceType: true, addService: false });
  };

  const onOpenAddService = () => {
    setIsOpen({ ...isOpen, addService: true });
  };

  const onOpenCreateService = () => {
    setIsOpen({ ...isOpen, createService: true });
  };

  const onOpenViewService = uid => {
    setIsOpen({ ...isOpen, viewService: true, targetUid: uid });
  };

  const onOpenRemoveService = (e, uid, name) => {
    setIsOpen({ ...isOpen, removeService: true, targetUid: uid, targetName: name });
  };

  const onRemoveService = (e, uid) => {
    const serviceItem = serviceItemList.find(data => data.uid === uid);
    if (serviceItem) {
      let newCategoryUidList = getUidList(serviceItem?.categoryList);
      newCategoryUidList = removeFromArray(newCategoryUidList, parentData?.uid);
      const placeUidList = getUidList(serviceItem?.placeList);
      const staffList = getUidList(serviceItem?.staffList);
      const itemDetailsUidList = getUidList(serviceItem?.itemDetailsUidList);
      dispatch(
        ServiceItemAction.putServiceItem({
          ...serviceItem,
          categoryList: newCategoryUidList,
          placeList: placeUidList,
          staffList: staffList,
          itemDetailsUidList: itemDetailsUidList,
        }),
      );
    }
  };

  const onOpenEditService = uid => {
    setIsOpen({ ...isOpen, editService: true, targetUid: uid });
  };

  const onClose = () => {
    setIsOpen({
      addServiceType: false,
      addService: false,
      createService: false,
      viewService: false,
      editService: false,
      removeService: false,
      targetUid: null,
      targetName: "",
    });
  };

  const onSearch = searchValue => {
    if (searchValue == "" || !searchValue) {
      setServiceItemList(dataServiceItem);
    } else {
      const cloneData = JSON.parse(JSON.stringify(dataServiceItem));
      const filterList = cloneData.filter(data => textNormalize(data?.name).includes(textNormalize(searchValue)));
      setServiceItemList(filterList);
    }
  };

  useEffect(() => {
    setServiceItemList(dataServiceItem);
    if (isOpen.editService || isOpen.createService || isOpen.removeService) {
      onClose();
    }
    if (!fromSubcategory && dataServiceItem && dataServiceItem?.length <= 0) {
      onBackToSubcategory(masterData);
    }
  }, [dataServiceItem]);

  useEffect(() => {
    if (isPostServiceItemSuccess || isPutServiceItemSuccess || isDeleteServiceItemSuccess) {
      dispatch(ServiceItemAction.resetServiceItem());
      dispatch(ServiceItemAction.getCategoryServiceItem({ categoryUid: parentData?.uid }));
      dispatch(ServiceItemAction.getListServiceItem({}));
    }
  }, [isPostServiceItemSuccess, isPutServiceItemSuccess, isDeleteServiceItemSuccess]);

  return (
    <>
      <DisplayModeBar
        labelBtn={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
          LangConstant.TXT_SERVICE_LOWER_CASE,
        )}`}
        onAddItem={onOpenAddServiceType}
        onSearch={onSearch}
        showSearch={serviceItemList && serviceItemList?.length > 0}
        {...otherProps}
      />
      {fromSubcategory ? (
        <Box display="flex" margin="24px 0" paddingLeft="24px">
          <Box className={classes.categorySourceBox} onClick={e => onJumpToMain(e)}>
            <Typography color="inherit" className="medium-md-txt">
              {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)}
            </Typography>
          </Box>
          &nbsp; / &nbsp;
          <Box className={classes.categorySourceBox} onClick={() => onBackToSubcategory(masterData)}>
            <Typography color="inherit" className="medium-md-txt">
              {masterData?.name}
            </Typography>
          </Box>
          &nbsp; / &nbsp;
          <Box className={classes.currentSourceBox}>
            <Typography color="inherit" className="medium-md-txt">
              {parentData?.name}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box display="flex" margin="24px 0" paddingLeft="24px">
          <Box className={classes.categorySourceBox} onClick={e => onJumpToMain(e)}>
            <Typography color="inherit" className="medium-md-txt">
              {getLabel(LangConstant.TXT_ADD_CATEGORY_GOOD)}
            </Typography>
          </Box>
          &nbsp; / &nbsp;
          <Box className={classes.currentSourceBox}>
            <Typography color="inherit" className="medium-md-txt">
              {parentData?.name}
            </Typography>
          </Box>
        </Box>
      )}

      {serviceItemList && serviceItemList?.length > 0 ? (
        <Grid container spacing={3} className={classes.listItem}>
          {serviceItemList &&
            serviceItemList.map(dataMap => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={dataMap.uid}>
                <ItemDescriptionBlock
                  data={dataMap}
                  typeButton={AppConstant.TYPE_BUTTON_CATEGORY.removed}
                  time={formatUsingTime(dataMap, getLabel)}
                  price={convertPricePreOrder(dataMap)}
                  onOpenDetail={onOpenViewService}
                  onOpenEdit={onOpenEditService}
                  onOpenDelete={e => {
                    onOpenRemoveService(e, dataMap?.uid, dataMap?.name);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      ) : (
        <Box className={classes.contentBox}>
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_ADD))} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`}
            onClick={onOpenAddServiceType}
            buttonClasses={{ root: classes.addButton }}
          />
        </Box>
      )}
      {isOpen.addServiceType && (
        <DialogAddCategoryItem
          isShow={isOpen.addServiceType}
          titleName={{
            header: getLabel(LangConstant.TXT_CHOOSE_TYPE_ADD_SERVICE),
            add: getLabel(LangConstant.TXT_ADD_FROM_ALL_CATEGORY_SERVICE),
            created: `${getLabel(LangConstant.TXT_CREATE_NEW)} ${getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)}`,
          }}
          action={onClose}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddFromMainService={onOpenAddService}
          onCreateAccompaniedService={onOpenCreateService}
        />
      )}
      {isOpen.createService && (
        <DialogCreateMainService isOpen={isOpen.createService} onClose={onClose} categoryUid={[parentData?.uid]} />
      )}
      {(isOpen.viewService || isOpen.editService) && (
        <DialogViewEditMainService
          data={serviceItemList ? serviceItemList.find(data => data.uid === isOpen.targetUid) : {}}
          isOpen={isOpen.viewService || isOpen.editService}
          isEdit={isOpen.editService}
          onClose={onClose}
        />
      )}
      {isOpen.addService && (
        <DialogAddToCategory
          categoryData={parentData}
          isOpen={isOpen.addService}
          onClose={onClose}
          onBack={onBackAddServiceType}
        />
      )}
      {isOpen.removeService && (
        <DialogDelete
          uid={isOpen.targetUid}
          isShow={isOpen.removeService}
          onCancel={onClose}
          onConfirm={onRemoveService}
          title={getLabel(LangConstant.FN_REMOVE)(getLabel(LangConstant.TXT_SERVICE_LOWER_CASE), isOpen.targetName)}
          content={getLabel(LangConstant.FN_REMOVE_FROM_CATEGORY)(
            getLabel(LangConstant.TXT_SERVICE_LOWER_CASE),
            isOpen.targetName,
            parentData?.name,
          )}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  categorySourceBox: { color: "#47494e", cursor: "pointer", "&:hover": { color: "#4d85ff" } },
  currentSourceBox: { color: "#4d85ff" },
  contentBox: {
    width: "100%",
    minHeight: "720px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  addButton: {
    minWidth: 162,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
  listItem: {
    minHeight: "calc(100% - 363px)",
    paddingLeft: 10,
    paddingRight: 10,
  },
  dialogAddTypo: {
    color: "#3e4045",
    lineHeight: 1.5,
    fontWeight: "bold",
  },
}));

ServiceItemList.propTypes = {
  fromSubcategory: PropTypes.bool,
  masterData: PropTypes.object,
  parentData: PropTypes.object,
  onJumpToMain: PropTypes.func,
  onBackToSubcategory: PropTypes.func,
};

ServiceItemList.defaultProps = {
  fromSubcategory: false,
  masterData: {},
  parentData: {},
  onJumpToMain: () => {},
  onBackToSubcategory: () => {},
};

export default memo(ServiceItemList);
