import React, { memo, useState } from "react";
import { makeStyles, Menu, Box, MenuItem, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import { LangConstant } from "../../../const";

const MenuStatus = props => {
  const { onChange, rowNum, className, includeDefault, titleDefault } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState();

  const onClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChange(index);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={`${classes.boxRoot} ${className}`}>
      <IconButton
        onClick={onClickListItem}
        classes={{ root: classes.btnRoot, label: `medium-md-txt ${classes.btnLabel}` }}
      >
        {selectedIndex != null
          ? getLabel(LangConstant.ARR_ACCOUNT[selectedIndex])
          : getLabel(LangConstant.TXT_FILTER_ACCOUNT)}
        <ExpandMoreIcon className={classes.icon} />
      </IconButton>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        {includeDefault && (
          <MenuItem onClick={event => onMenuItemClick(event, null)} className={`medium-md-txt ${classes.menuItemRoot}`}>
            {titleDefault}
          </MenuItem>
        )}
        {LangConstant.ARR_ACCOUNT.map(
          (option, index) =>
            index <= rowNum && (
              <MenuItem
                onClick={event => onMenuItemClick(event, index)}
                className={`medium-md-txt ${classes.menuItemRoot}`}
                key={index}
              >
                {getLabel(option)}
              </MenuItem>
            ),
        )}
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    height: "100%",
  },
  btnRoot: {
    color: theme.palette.grey[600],
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[200]}`,
    height: "100%",
    width: 160,
  },
  btnLabel: {
    justifyContent: "unset",
    fontFamily: theme.typography.fontFamily,
  },
  menuPaper: {
    marginTop: 2,
    borderRadius: 0,
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: 300,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    minHeight: 30,
    width: 160,
    color: "#9d9fa5",
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
  },
  icon: {
    right: 15,
    position: "absolute",
    color: theme.palette.grey[500],
  },
}));

MenuStatus.propTypes = {
  rowNum: PropTypes.number,
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  includeDefault: PropTypes.bool,
  titleDefault: PropTypes.string,
};

MenuStatus.defaultProps = {
  style: {},
  rowNum: 6,
  className: "",
  includeDefault: false,
  titleDefault: "",
};

export default memo(MenuStatus);
