import React, { memo, useEffect } from "react";
import PostManageLayout from "./components/PostManageLayout";
import { Box, makeStyles } from "@material-ui/core";
import { BglobalJSC } from "../../components";
import { useDispatch } from "react-redux";
import ShopAction from "../../redux/shop.redux";
import Cookie from "js-cookie";
import { AppConstant } from "../../const";

const MnNews = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = Cookie.get(AppConstant.ROLE);

  useEffect(() => {
    if (role !== AppConstant.SHOP_STAFF) dispatch(ShopAction.requestGetFacebookPage());
    if (role === AppConstant.SHOP_STAFF) {
      const appData = Cookie.get(AppConstant.KEY_GBOSS);
      if (appData && appData.length > 0) {
        let shopId = JSON.parse(appData).shopId;
        dispatch(ShopAction.requestGetShopInfo({ data: { shopId: shopId } }));
      }
    }
  }, []);
  return (
    <Box className={classes.postContainer}>
      <PostManageLayout />
      <BglobalJSC className={classes.copyRight} />
    </Box>
  );
};

export default memo(MnNews);

const useStyles = makeStyles(() => ({
  postContainer: {
    overflow: "auto",
    "@media only screen and (max-width: 414px)": {
      paddingBottom: 0,
      maxHeight: "90vh",
    },
  },
  copyRight: {
    marginBottom: "2vh",
  },
}));
