import React, { memo, useState } from "react";
import { makeStyles, TableCell } from "@material-ui/core";
import { LangConstant } from "../../../../const";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { StatusInvite, MenuBody } from "../index";

const CellBody = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const {
    headData,
    cellData,
    displayColumn,
    email,
    name,
    role,
    dataAPI,
    showData,
    uid,
    isInvited,
    onChangeInvite,
    showTime,
    showPosition,
  } = props;
  const Status = [getLabel(LangConstant.TXT_ACCOUNT_STATUS)];
  // eslint-disable-next-line no-unused-vars
  const [index, setIndex] = useState(null);
  const Menu = [
    getLabel(LangConstant.TXT_ROLE),
    getLabel(LangConstant.TXT_WORK_TIME),
    getLabel(LangConstant.TXT_POSITION),
  ];

  const Align = [getLabel(LangConstant.TXT_ACCOUNT_STATUS), getLabel(LangConstant.TXT_SEX)];

  const Body = Align.includes(headData)
    ? `${classes.bodyMiddle} ${displayColumn.includes(headData) ? null : classes.dontShow}`
    : `${classes.bodyLeft} ${displayColumn.includes(headData) ? null : classes.dontShow}`;

  const onChange = index => {
    setIndex(index);
  };

  return (
    <>
      {Status.includes(headData) ? (
        <TableCell
          align="center"
          classes={{
            body: Body,
          }}
        >
          <StatusInvite
            cellData={cellData}
            showData={showData}
            uid={uid}
            email={email}
            name={name}
            role={role}
            isInvited={isInvited}
            onChangeInvite={onChangeInvite}
            showTime={showTime}
            showPosition={showPosition}
          />
        </TableCell>
      ) : Menu.includes(headData) ? (
        <TableCell
          align="center"
          classes={{
            body: `${classes.bodyLeft} ${displayColumn.includes(headData) ? null : classes.dontShow}`,
          }}
        >
          <MenuBody onChange={onChange} cellData={cellData} headData={headData} dataAPI={dataAPI} />
        </TableCell>
      ) : (
        <TableCell
          align="center"
          classes={{
            body: Body,
          }}
        >
          {Align.includes(headData) ? getLabel(LangConstant.ARR_GENDER[cellData]) : cellData}
        </TableCell>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  bodyLeft: {
    color: "#787878",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    minWidth: 160,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
  },
  bodyMiddle: {
    color: "#787878",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    minWidth: 100,
    padding: 0,
  },
  dontShow: {
    display: "none",
  },
}));

CellBody.propTypes = {
  cellData: PropTypes.node,
  firstCol: PropTypes.bool,
  style: PropTypes.object,
  isStatus: PropTypes.bool,
};

export default memo(CellBody);
