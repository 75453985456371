import React, { Fragment, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Rating from "react-rating";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Hidden, IconButton, makeStyles, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import { ArrowDropDownSharp, Info, MoreVert, Star, StarOutline, Warning } from "@material-ui/icons";
import clsx from "clsx";
import StringFormat from "string-format";
import { format as DateFormat } from "date-fns";
import RatingAction from "redux/rating.redux";
import { AppConstant, LangConstant } from "const";
import { getAttachmentUrl, getCommonKey, isObjectNotEqual } from "utils";
import { convertTimeStampToDate } from "utils/date";

const RatingItem = ({ data }) => {
  const classes = useStyles();

  const [itemData, setItemData] = useState(data);

  useEffect(() => {
    if (isObjectNotEqual(data, itemData)) {
      setItemData(data);
    }
  }, [data]);

  let isHaveData = Object.keys(itemData).length > 0;

  return (
    isHaveData && (
      <Paper className={classes.ratingItemRoot}>
        <UserInfo itemData={itemData} />
        <Hidden smDown>
          <RatingInfo itemData={itemData} />
        </Hidden>
      </Paper>
    )
  );
};

RatingItem.propTypes = {
  data: PropTypes.object,
};

RatingItem.defaultProps = {
  data: {
    user: {
      id: 0,
      name: "",
      email: "",
      phoneNumber: "",
      avatar: "",
    },
    created: Date.now(),
    content: "",
    score: 0,
    isReported: 0,
    isHideContent: 0,
    reportCount: 0,
  },
};

const UserInfo = ({ itemData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_RATING_MANAGEMENT);
  const shopInfo = useSelector(state => state.shopInfoRedux.data, shallowEqual);

  const [userInFoAnchor, setUserInfoAnchor] = useState(null);
  const [reportMenuAnchor, setReportMenuAnchor] = useState(null);

  const onReport = () => {
    if (shopInfo && shopInfo.id) {
      let shopId = shopInfo.id;
      onCloseReportMenu();
      dispatch(
        RatingAction.reportRating({
          uid: itemData.uid,
          shopId: shopId,
        }),
      );
    }
  };

  const onOpenReportMenu = event => {
    setReportMenuAnchor(event.currentTarget);
  };

  const onCloseReportMenu = () => {
    setReportMenuAnchor(null);
  };

  const onOpenUserInfoMenu = event => {
    setUserInfoAnchor(event.currentTarget);
  };

  let isHaveReport = itemData && itemData.reportCount > 0;

  return (
    <Box className={classes.userAvatarAndName}>
      <Avatar src={getAttachmentUrl(itemData.user.avatar) || ""} className={classes.customerAvatar} />
      <Hidden mdUp>
        <Box className={classes.mobileRatingDetail}>
          <Box className={classes.mobileUserDetail}>
            <Fragment>
              <Typography className={clsx(classes.customerDetail, "semiBold-sm-txt")}>
                {itemData.user.name || ""}
              </Typography>
              <IconButton className={classes.userInfoMobile} onClick={onOpenUserInfoMenu}>
                <ArrowDropDownSharp />
              </IconButton>
            </Fragment>
            <Box className={classes.menuAndReport}>
              {isHaveReport &&
                (itemData.isHideContent ? (
                  <Typography className={clsx(classes.reportContentHidden, "regular-sm-txt")}>
                    <Info /> {getLabel(LangConstant.TXT_HIDDEN_REPORT_CONTENT)}
                  </Typography>
                ) : (
                  <Typography className={clsx(classes.reportCount, "regular-sm-txt")}>
                    <Warning /> {StringFormat(getLabel(LangConstant.FM_TOTAL_REPORT), itemData.reportCount || 0)}
                  </Typography>
                ))}
              <IconButton className={classes.menuButton} onClick={onOpenReportMenu}>
                <MoreVert />
              </IconButton>
              <Menu
                open={Boolean(reportMenuAnchor)}
                onBackdropClick={onCloseReportMenu}
                anchorEl={reportMenuAnchor}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                getContentAnchorEl={null}
                classes={{ list: classes.reportMenu }}
              >
                <MenuItem
                  disabled={Boolean(itemData.isReported)}
                  onClick={onReport}
                  classes={{
                    root: clsx(classes.menuItemRoot, "regular-sm-txt"),
                  }}
                >
                  {getLabel(Boolean(itemData.isReported) ? LangConstant.TXT_REPORTED : LangConstant.TXT_REPORT)}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <RatingInfo itemData={itemData} />
        </Box>
      </Hidden>

      <Hidden smDown>
        <Box className={classes.customerDetailBox}>
          <Typography className={clsx(classes.customerDetail, "semiBold-sm-txt")}>
            {itemData.user.name || ""}
          </Typography>
          <Typography className={clsx(classes.customerDetail, "regular-sm-txt")}>
            {itemData.user.email || ""}
          </Typography>
          <Typography className={clsx(classes.customerDetail, "regular-sm-txt")}>
            {itemData.user.phoneNumber}
          </Typography>
        </Box>
      </Hidden>

      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(userInFoAnchor)}
        anchorEl={userInFoAnchor}
        onClose={() => setUserInfoAnchor(null)}
        classes={{
          list: classes.userInfoList,
          paper: classes.userInfoPaper,
        }}
      >
        <Box className={classes.mobileUserTitle}>
          <Typography className={clsx(classes.customerTitle, "regular-sm-txt")}>
            {getLabel(getCommonKey(LangConstant.L_BOOKING_EMAIL))}
          </Typography>
          <Typography className={clsx(classes.customerTitle, "regular-sm-txt")}>
            {getLabel(getCommonKey(LangConstant.L_BOOKING_PHONE))}
          </Typography>
        </Box>

        <Box className={classes.mobileUserDetail}>
          <Typography className={clsx(classes.customerDetailMobile, "regular-sm-txt")}>
            {itemData.user.email || ""}
          </Typography>
          <Typography className={clsx(classes.customerDetailMobile, "regular-sm-txt")}>
            {itemData.user.phoneNumber}
          </Typography>
        </Box>
      </Menu>
    </Box>
  );
};

const RatingInfo = ({ itemData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_RATING_MANAGEMENT);
  const shopInfo = useSelector(state => state.shopInfoRedux.data, shallowEqual);

  const [reportMenuAnchor, setReportMenuAnchor] = useState(null);

  const onReport = () => {
    if (shopInfo && shopInfo.id) {
      let shopId = shopInfo.id;
      onCloseReportMenu();
      dispatch(
        RatingAction.reportRating({
          uid: itemData.uid,
          shopId: shopId,
        }),
      );
    }
  };

  const onOpenReportMenu = event => {
    setReportMenuAnchor(event.currentTarget);
  };

  const onCloseReportMenu = () => {
    setReportMenuAnchor(null);
  };

  let isHaveReport = itemData && itemData.reportCount > 0;
  let isProduct = itemData.item;
  return (
    <Box className={classes.ratingDetail}>
      <Box className={classes.scoreAndMenu}>
        <Box className={classes.scoreAndDate}>
          <Rating
            emptySymbol={<StarOutline className={classes.emptyStar} />}
            fullSymbol={<Star className={classes.fullStar} />}
            readonly={true}
            initialRating={itemData.score || 0}
            className={classes.ratingScore}
          />
          <Typography className={clsx(classes.ratingTime, "regular-sm-txt")}>
            {DateFormat(convertTimeStampToDate(itemData?.created), AppConstant.FM_HH_MM_DD_MM_YYYY)}
          </Typography>
        </Box>
        <Hidden smDown>
          <Box className={classes.menuAndReport}>
            {isHaveReport &&
              (itemData.isHideContent ? (
                <Typography className={clsx(classes.reportContentHidden, "regular-sm-txt")}>
                  <Info /> {getLabel(LangConstant.TXT_HIDDEN_REPORT_CONTENT)}
                </Typography>
              ) : (
                <Typography className={clsx(classes.reportCount, "regular-sm-txt")}>
                  <Warning /> {StringFormat(getLabel(LangConstant.FM_TOTAL_REPORT), itemData.reportCount || 0)}
                </Typography>
              ))}
            <IconButton className={classes.menuButton} onClick={onOpenReportMenu}>
              <MoreVert />
            </IconButton>
            <Menu
              open={Boolean(reportMenuAnchor)}
              onBackdropClick={onCloseReportMenu}
              anchorEl={reportMenuAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              getContentAnchorEl={null}
              classes={{ list: classes.reportMenu }}
            >
              <MenuItem
                disabled={Boolean(itemData.isReported)}
                onClick={onReport}
                classes={{
                  root: clsx(classes.menuItemRoot, "regular-sm-txt"),
                }}
              >
                {getLabel(Boolean(itemData.isReported) ? LangConstant.TXT_REPORTED : LangConstant.TXT_REPORT)}
              </MenuItem>
            </Menu>
          </Box>
        </Hidden>
      </Box>
      {isProduct && (
        <Typography className={clsx(classes.serviceName, "medium-sm-txt")}>{itemData.item.name || ""}</Typography>
      )}
      <Typography className={clsx(classes.reportContent, "regular-sm-txt")}>{itemData.content || ""}</Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  ratingItemRoot: {
    display: "flex",
    width: "100%",
    padding: 16,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)",
    margin: "16px 0",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      alignItems: "center",
      width: "100%",
    },
  },

  mobileRatingDetail: {
    width: "100%",
  },

  userAvatarAndName: {
    width: "fit-content",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  mobileUserDetail: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  customerDetailBox: {
    marginLeft: 16,
    marginRight: 60,
    minWidth: "max-content",
  },

  emptyStar: {
    color: "#FEBA40",
    fontSize: 20,
  },

  fullStar: {
    color: "#FEBA40",
    fontSize: 20,
  },

  ratingDetail: {
    width: "100%",
  },

  scoreAndMenu: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  scoreAndDate: {
    display: "flex",
    alignItems: "center",
    height: "fit-content",
  },

  ratingTime: {
    display: "flex",
    alignItems: "center",
    marginLeft: 14,
  },

  menuButton: {
    padding: 0,
    marginLeft: 6,
  },

  serviceName: {
    marginTop: "3px 0",
    lineHeight: "20px",
  },

  reportMenu: {
    padding: 0,
  },

  menuItemRoot: {
    "&:hover": {
      backgroundColor: "#4B4D53",
      color: theme.palette.white,
    },
  },

  customerDetail: {
    lineHeight: "20px",
    height: "fit-content",
  },

  customerDetailMobile: {
    lineHeight: "22px",
    height: "fit-content",
  },

  customerTitle: {
    color: theme.palette.grey[400],
    lineHeight: "22px",
    height: "fit-content",
  },

  customerAvatar: {
    width: 48,
    height: 48,
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
  },

  menuAndReport: {
    display: "flex",
    marginLeft: "auto",
    height: "fit-content",
  },

  reportCount: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
    border: "0.5px solid #FEBA40",
    backgroundColor: "#FEF6D8",
    borderRadius: 4,
    "&>svg": {
      color: "#FEBA40",
      fontSize: 15,
      marginRight: 8,
    },
  },

  reportContentHidden: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
    border: "0.5px solid #447AFF",
    backgroundColor: "#D9E8FF",
    borderRadius: 4,
    "&>svg": {
      color: "#447AFF",
      fontSize: 15,
      marginRight: 8,
    },
  },

  reportContent: {
    marginTop: 4,
  },

  userInfoMobile: {
    padding: 0,
    height: "fit-content",
  },

  mobileUserTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: 10,
  },

  userInfoList: {
    display: "flex",
    padding: 0,
  },

  userInfoPaper: {
    padding: 10,
  },
}));

export default RatingItem;
