import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import { getCommonKey } from "utils";
import { Close } from "@material-ui/icons";
import clsx from "clsx";

const ReportDisplaySettingDialog = props => {
  const { arraySelected, data, isShow, onClose, onSubmit } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [arrayLegendIds, setArrayLegendIds] = useState([]);
  const [list, setList] = useState([]);

  const onSelected = selectedId => {
    let newArrayLegend = [];
    let isExisted = arrayLegendIds.includes(selectedId);
    if (isExisted) {
      newArrayLegend = arrayLegendIds.filter(id => selectedId != id);
    } else {
      newArrayLegend = [...arrayLegendIds, selectedId];
    }

    if (newArrayLegend.length > 6) return; // Maximum is 6 item
    setArrayLegendIds(newArrayLegend);
  };

  const onSubmitDialog = () => {
    let arraySelectedObject = list
      .filter(item => arrayLegendIds.includes(item.display_id))
      .map((item, index) => ({ ...item, color: AppConstant.CHART_COLORS[index] }));
    onSubmit({
      display_ids: arrayLegendIds,
      details: arraySelectedObject,
    });
  };

  useEffect(() => {
    if (arraySelected && arraySelected != arrayLegendIds) setArrayLegendIds(arraySelected.map(item => item.display_id));
  }, [arraySelected]);

  useEffect(() => {
    if (data && data != list) setList(data);
  }, [data]);

  return (
    <Dialog open={isShow} onClose={onClose} aria-labelledby="display-setting-dialog" fullScreen={isMobile}>
      <DialogTitle className={clsx("center-root", classes.titleRoot)} disableTypography>
        <Typography color="inherit">{getLabel(LangConstant.TXT_DISPLAY_SETTING_TITLE)}</Typography>
        <IconButton aria-label="close" className={classes.btnCloseTitle} onClick={onClose} color="inherit">
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.mainRoot}>
        {list.map((item, index) => (
          <CheckboxSetting
            data={item}
            onChange={() => onSelected(item.display_id)}
            isChecked={arrayLegendIds.includes(item.display_id)}
            key={item.display_id || index}
          />
        ))}
      </DialogContent>
      <DialogActions className={classes.actionRoot}>
        <Typography className="medium-sm-txt">{getLabel(LangConstant.TXT_DISPLAY_SETTING_LIMIT)}</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onSubmitDialog}
          disabled={arrayLegendIds.length == 0}
        >
          {getLabel(getCommonKey(LangConstant.TXT_SAVE_BUTTON))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReportDisplaySettingDialog.propTypes = {
  arraySelected: PropTypes.array,
  list: PropTypes.array,
  isShow: PropTypes.bool,

  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
export default memo(ReportDisplaySettingDialog);

const CheckboxSetting = props => {
  const { data, onChange, isChecked } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ label: "medium-md-txt" }}
      control={
        <Checkbox
          checked={isChecked}
          onChange={onChange}
          name={data.name}
          className={classes.checkboxRoot}
          color="primary"
          size="small"
        />
      }
      label={data.name}
    />
  );
};
CheckboxSetting.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 375,
    padding: 0,
  },
  titleRoot: {
    padding: "10px 24px",
    background: theme.palette.secondary.main,
    color: "white",
  },
  btnCloseTitle: {
    position: "absolute",
    right: 0,
  },
  mainRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 32px",
    borderBottom: "solid 1px #b2b8c2",
  },
  actionRoot: {
    padding: "0 32px",
    flexDirection: "column",
    "& p": {
      marginTop: 8,
      color: "#7f838c",
    },
    "& button": {
      marginBottom: 16,
      marginTop: 24,
    },
  },
  checkboxRoot: {
    "& svg": { fontSize: 26 },
  },
}));
