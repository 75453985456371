import React, { memo } from "react";
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { LangConstant } from "../../../const";
import { useTranslation } from "react-i18next";

const PopupSuccess = ({ open, setOpen, onSubmit, index }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
  };

  const dialog = (Title, Content) => (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>{getLabel(Title)}</DialogTitle>
      <DialogContent dividers className={classes.content}>
        {getLabel(Content)}
      </DialogContent>
      <DialogActions className={classes.rootActions}>
        <Button onClick={onClose} className={classes.button}>
          {getLabel(LangConstant.TXT_SHOP_CANCEL)}
        </Button>
        <Button onClick={e => onSubmit(e, index !== null ? index : null)} className={classes.button}>
          {getLabel(LangConstant.TXT_CONFIRM)}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {dialog(
        index ? LangConstant.TXT_CONFIRM_INVITE : LangConstant.TXT_CONFIRM_INVITE_ALL,
        index ? LangConstant.TXT_STATUS_INVITE : LangConstant.TXT_STATUS_INVITE_ALL,
      )}
    </>
  );
};
const useStyles = makeStyles(() => ({
  paper: {
    margin: "0 20px",
    boxShadow: "none",
    borderRadius: 0,
    maxWidth: 500,
    minHeight: 200,
  },
  content: {
    padding: 24,
    borderTop: "none",
  },
  rootActions: {
    height: 60,
    padding: "8px 30px",
  },
  button: {
    opacity: 0.6,
    "&:hover": {
      opacity: 1,
      backgroundColor: "transparent",
    },
  },
}));
export default memo(PopupSuccess);
