export default {
  TXT_SHOW: "Hiện",
  TXT_HIDDEN: "Ẩn",
  TXT_CHART_TITLE_FIRST: "Tỷ trọng đặt chỗ theo thời gian",
  TXT_CHART_TITLE_SECOND: "Tỷ trọng khách hàng theo thời gian",
  TXT_CHART_TITLE_THIRD: "So sánh hiệu quả loại vị trí",
  TXT_SUM_NUMBER_RESERVATION: "Tổng số đặt chỗ",
  TXT_DISPLAY_SETTING: "Cài đặt hiển thị",
  TXT_DISPLAY_SETTING_LIMIT: "Bạn chỉ được chọn tối đa 6 thông tin để hiển thị",
  TXT_DISPLAY_SETTING_TITLE: "Lựa chọn thông tin hiển thị",
  TXT_VIEW_MODE: "Chế độ xem",
  TXT_SUMMARY: "Tổng quát",
  TXT_DETAIL: "Chi tiết",
};
