import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Button,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Chip,
  FormHelperText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { LangConstant } from "const";
import { PopupSuccess } from "../components";
import { validateEmail, removeUnnecessarySpaces } from "utils";
import ConsumerAction from "redux/consumer.redux";
import { EmailIcon } from "theme/images";

const InviteByEmail = () => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const emailQuantity = useSelector(state => state.consumerRedux.emailQuantity);
  const listInvite = useSelector(state => state.consumerRedux.invitationConsumer?.invitedList);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [email, setEmail] = useState([]);
  const [save, setSave] = useState([]);
  const [inputEmail, setInputEmail] = useState("");

  const [touched, setTouched] = useState([]);
  const [quantity, setQuantity] = useState({
    used: 0,
    total: 0,
  });

  const onInvite = () => {
    let displayEmails = [...email];
    let error = [];
    let emails = [];
    if (inputEmail && inputEmail.length > 0) {
      displayEmails.push(inputEmail);
    }

    // create list correct emails/ error validation email
    displayEmails.forEach(email => (validateEmail(email) ? emails.push(email) : error.push(email)));

    if (emails.length > 0) {
      dispatch(ConsumerAction.patchInvitationConsumer({ emails: emails }));
      setSave(emails);
      setEmail([]);
      setOpenSuccess(true);
    }
  };

  const onChangeEmail = (event, emails) => {
    setTouched({ ...touched });
    setEmail(emails);
    event.persist();
  };

  const onInputChangeEmail = (event, newInputValue) => {
    let trimInput = newInputValue.trim();
    let options = removeUnnecessarySpaces(newInputValue).split(" ");
    const fieldValue = email.concat(options);
    if (options.length > 1) {
      onChangeEmail(event, fieldValue);
      return;
    }
    let space = newInputValue.indexOf(" ") >= 0;
    if (space && trimInput.length > 0) {
      setInputEmail("");
      setEmail([...email, trimInput]);
    } else {
      setInputEmail(trimInput);
    }
  };

  const validateButton = (inputEmail, email) => {
    let emails = [];
    if (email.length > 0) {
      email.forEach(e => (validateEmail(e) ? emails.push(e) : null));
    }
    if ((inputEmail.length > 0 && validateEmail(inputEmail)) || emails.length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (emailQuantity) {
      dispatch(ConsumerAction.requestGetEmailQuantity());
    }
  }, []);

  useEffect(() => {
    if (emailQuantity === null) {
      dispatch(ConsumerAction.requestGetEmailQuantity());
    } else if (emailQuantity) {
      setQuantity(emailQuantity);
    }
  }, [emailQuantity]);

  return (
    <Box className={classes.boxContentParent}>
      <Accordion defaultExpanded={false} className={classes.rootAccordion}>
        <Box className={classes.boxContentChildren}>
          <AccordionSummary className={classes.rootAccordionSummary}>
            <img src={EmailIcon} alt="email" />
          </AccordionSummary>
          <AccordionSummary className={classes.rootAccordionSummary}>
            <Box className={classes.optionChoose}>{getLabel(LangConstant.TXT_INVITE_EMAIL)}</Box>
          </AccordionSummary>
        </Box>
        <AccordionDetails className={classes.rootAccordionDetail}>
          <Autocomplete
            classes={{ root: classes.rootAutoComplete, inputFocused: classes.rootInputField }}
            multiple
            disableClearable={true}
            options={[]}
            freeSolo
            renderTags={(emails, getTagProps) =>
              emails.map((email, index) => (
                <Chip
                  key={index}
                  classes={{ outlinedPrimary: classes.outlinedChip }}
                  variant="outlined"
                  label={email}
                  color={validateEmail(email) ? "default" : "primary"}
                  {...getTagProps({ index })}
                />
              ))
            }
            value={email}
            inputValue={inputEmail}
            onChange={onChangeEmail}
            onInputChange={onInputChangeEmail}
            renderInput={params => (
              <>
                <TextField {...params} variant="outlined" />
                <FormHelperText className={classes.formHelper}>
                  {quantity ? getLabel(LangConstant.FN_LIMITED_INVITE)(quantity.used, quantity.total) : null}
                </FormHelperText>
                <Button
                  classes={{
                    root: classes.rootBtnEmail,
                    contained: classes.contained,
                    disabled: classes.disabled,
                  }}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={onInvite}
                  disabled={validateButton(inputEmail, email)}
                >
                  {getLabel(LangConstant.TXT_SEND_INVITATION)}
                </Button>
              </>
            )}
          />
        </AccordionDetails>
      </Accordion>
      <PopupSuccess
        open={openSuccess}
        setOpen={setOpenSuccess}
        data={save}
        lengthSuccess={(listInvite && listInvite.length) || null}
      />
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  boxBodyParent: {
    width: "100%",
    height: "100%",
  },
  rootAccordion: {
    boxShadow: "none",
  },
  rootAccordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    height: 24,
    minHeight: 24,
    backgroundColor: "#ffffff",
  },
  rootAccordionDetail: {
    display: "block",
    textAlign: "center",
    "@media only screen and (max-width: 1000px)": {
      padding: "0 0 0",
    },
  },
  boxBodyChildren: {
    height: "100%",
    width: "90%",
    margin: "auto",
  },
  boxTitle: {
    textAlign: "center",
    marginTop: 90,
    "@media only screen and (max-width: 1000px)": {
      marginTop: 50,
    },
  },
  boxContentParent: {
    marginTop: 50,
    "@media only screen and (max-width: 1000px)": {
      marginTop: 38,
    },
  },
  boxContentChildren: {
    display: "flex",
    justifyContent: "left",
    width: 290,
    margin: "auto",
    "@media only screen and (max-width: 1000px)": {
      marginLeft: 0,
      justifyContent: "center",
      width: "auto",
      paddingLeft: 0,
    },
  },
  boxContentChildrenLink: {
    display: "flex",
    justifyContent: "left",
    width: 290,
    margin: "auto",
    "@media only screen and (max-width: 1000px)": {
      marginLeft: 0,
      justifyContent: "center",
      width: "auto",
      paddingLeft: 0,
    },
  },
  outlinedChip: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  rootBtnEmail: {
    marginTop: 50,
    padding: "0 16px 0",
    minHeight: 50,
    fontWeight: "inherit",
    Height: 50,
    borderRadius: 25,
    minWidth: 160,
    textTransform: "capitalize",
    color: "#ffffff",
    "@media only screen and (max-width: 600px)": {
      minWidth: 160,
      maxWidth: 160,
      height: 40,
      minHeight: 40,
      borderRadius: 20,
    },
  },
  contained: {
    "&$disabled": {
      backgroundColor: "#EF5845",
      opacity: 0.6,
      color: "#ffffff",
    },
  },
  disabled: {
    backgroundColor: "#EF5845",
    opacity: 0.6,
  },
  rootButton: {
    width: 50,
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: 20,
  },
  rootAutoComplete: {
    marginLeft: "25%",
    marginRight: "25%",
    "@media only screen and (max-width: 600px)": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },
  label: {
    height: "100%",
  },
  iconSizeMedium: {
    fontSize: 30,
  },
  optionChoose: {
    width: "100%",
    marginLeft: 20,
    "&:hover": {
      color: "#447aff",
      textDecoration: "underline",
    },
  },

  formHelper: {
    fontSize: 12,
    color: "#447aff",
  },
}));
export default memo(InviteByEmail);
