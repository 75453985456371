import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getPlaceItem: ["data"],
  getPlaceItemSuccess: ["data"],
  getPlaceItemFailure: ["data"],

  createPlaceItem: ["data"],
  createPlaceItemSuccess: ["data"],
  createPlaceItemFailure: ["data"],

  patchPlaceItem: ["data"],
  patchPlaceItemSuccess: ["data"],
  patchPlaceItemFailure: ["data"],

  updatePlaceItem: ["data"],
  updatePlaceItemSuccess: ["data"],
  updatePlaceItemFailure: ["data"],

  removePlaceItem: ["data"],
  removePlaceItemSuccess: ["data"],
  removePlaceItemFailure: ["data"],

  getPlaceBusyStatus: ["data"],
  getPlaceBusyStatusSuccess: ["data"],
  getPlaceBusyStatusFailure: ["data"],

  getPlaceItemAutoFill: ["data"],
  placeItemSuccess: ["data"],
  placeItemFailure: ["data"],

  resetPlaceItemStatus: ["data"],
  resetPlaceAutoFill: [],
});

export const PlaceItemTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  data: null,
  //create
  isCreateFetching: false,
  errorCreate: null,
  isCreateSuccess: false,
  //patch
  isPatchFetching: false,
  errorPatch: null,
  isPatchSuccess: false,
  //update
  isUpdateFetching: false,
  errorUpdate: null,
  isUpdateSuccess: false,
  //remove
  isRemoveFetching: false,
  errorRemove: null,
  isRemoveSuccess: false,

  isGetBusyStatusFetching: false,
  errorGetBusyStatus: null,
  isGetBusyStatusSuccess: false,

  isGetPlaceItemAutoFill: false,
  dataPlaceItemAutoFill: [],

  placeBusyStatus: [],
};

/* ------------- Reducers Get Place item ------------- */
export const getPlaceItem = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getPlaceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    data: action.data,
  };
};

export const getPlaceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

/* ------------- Reducers Create Place item ------------- */
export const createPlaceItem = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
});

export const createPlaceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreateFetching: false,
    isCreateSuccess: true,
    data: action.data,
  };
};

export const createPlaceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isCreateSuccess: false,
  errorCreate: action.error,
});

/* ------------- Reducers Patch Place item ------------- */
export const patchPlaceItem = (state = INITIAL_STATE) => ({
  ...state,
  isPatchFetching: true,
});

export const patchPlaceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPatchFetching: false,
    isPatchSuccess: true,
    data: action.data,
  };
};

export const patchPlaceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPatchFetching: false,
  isPatchSuccess: false,
  errorPatch: action.data.status,
});

/* ------------- Reducers Update Place item ------------- */
export const updatePlaceItem = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updatePlaceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isUpdateSuccess: true,
    data: action.data,
  };
};

export const updatePlaceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isUpdateSuccess: false,
  errorUpdate: action.error,
});

/* ------------- Reducers Remove Place item ------------- */
export const removePlaceItem = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetching: true,
});

export const removePlaceItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isRemoveFetching: false,
    isRemoveSuccess: true,
    data: action.data,
  };
};

export const removePlaceItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetching: false,
  isRemoveSuccess: false,
  errorRemove: action.error,
});

export const getPlaceBusyStatus = (state = INITIAL_STATE) => ({
  ...state,
  isGetBusyStatusFetching: true,
});
export const getPlaceBusyStatusSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetBusyStatusFetching: false,
    placeBusyStatus: action.data,
  };
};

export const getPlaceBusyStatusFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetBusyStatusFetching: false,
  isGetBusyStatusSuccess: false,
  errorGetBusyStatus: action.error,
});

export const getPlaceItemAutoFill = (state = INITIAL_STATE) => ({
  ...state,
  isGetPlaceItemAutoFill: true,
  dataPlaceItemAutoFill: [],
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action?.data || {};
  return {
    ...state,
    isFetching: false,
    ...data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  isNumberBookingSuccess: false,
  error: action.error,
});

export const resetPlaceAutoFill = (state = INITIAL_STATE) => {
  return {
    ...state,
    dataPlaceItemAutoFill: [],
  };
};

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_PLACE_ITEM]: getPlaceItem,
  [Types.GET_PLACE_ITEM_SUCCESS]: getPlaceItemSuccess,
  [Types.GET_PLACE_ITEM_FAILURE]: getPlaceItemFailure,

  [Types.CREATE_PLACE_ITEM]: createPlaceItem,
  [Types.CREATE_PLACE_ITEM_SUCCESS]: createPlaceItemSuccess,
  [Types.CREATE_PLACE_ITEM_FAILURE]: createPlaceItemFailure,

  [Types.PATCH_PLACE_ITEM]: patchPlaceItem,
  [Types.PATCH_PLACE_ITEM_SUCCESS]: patchPlaceItemSuccess,
  [Types.PATCH_PLACE_ITEM_FAILURE]: patchPlaceItemFailure,

  [Types.UPDATE_PLACE_ITEM]: updatePlaceItem,
  [Types.UPDATE_PLACE_ITEM_SUCCESS]: updatePlaceItemSuccess,
  [Types.UPDATE_PLACE_ITEM_FAILURE]: updatePlaceItemFailure,

  [Types.REMOVE_PLACE_ITEM]: removePlaceItem,
  [Types.REMOVE_PLACE_ITEM_SUCCESS]: removePlaceItemSuccess,
  [Types.REMOVE_PLACE_ITEM_FAILURE]: removePlaceItemFailure,

  [Types.GET_PLACE_BUSY_STATUS]: getPlaceBusyStatus,
  [Types.GET_PLACE_BUSY_STATUS_SUCCESS]: getPlaceBusyStatusSuccess,
  [Types.GET_PLACE_BUSY_STATUS_FAILURE]: getPlaceBusyStatusFailure,

  [Types.GET_PLACE_ITEM_AUTO_FILL]: getPlaceItemAutoFill,
  [Types.PLACE_ITEM_SUCCESS]: success,
  [Types.PLACE_ITEM_FAILURE]: failure,
  [Types.RESET_PLACE_ITEM_STATUS]: success,
  [Types.RESET_PLACE_AUTO_FILL]: resetPlaceAutoFill,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
