import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Checkbox } from "@material-ui/core";

const CheckBoxMenu = props => {
  const { name, title, onClick, isNotDefault } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const onChangeCheckBox = e => {
    onClick(e.target.checked, name);
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (Boolean(isNotDefault)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [isNotDefault]);

  return (
    <Box className={classes.CheckBoxMenuRowContainer}>
      <Typography className={classes.CheckBoxMenuLabel}>{title}</Typography>
      <Checkbox color="primary" checked={checked} onChange={onChangeCheckBox} />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  CheckBoxMenuRowContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.up("sm")]: {
      marginRight: 100,
    },
  },
  CheckBoxMenuLabel: {
    [theme.breakpoints.down(600)]: {
      minWidth: 200,
    },
  },
}));

export default CheckBoxMenu;
