import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import StatusButton from "./StatusButton";
import StatusIcon from "./StatusIcon";

const StatusGroup = ({ color, status, onClick, isActive }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <StatusIcon color={color} isActive={isActive}>
        {status.value}
      </StatusIcon>
      <StatusButton color={color} onClick={() => onClick(status.value)} isActive={isActive} className={classes.button}>
        {status.text}
      </StatusButton>
    </Box>
  );
};

StatusGroup.propTypes = {
  color: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
StatusGroup.defaultProps = {};

export default memo(StatusGroup);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: 0,
    },
  },
  button: {
    minWidth: 110,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginLeft: 30,
    },
  },
}));
