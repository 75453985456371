import React from "react";
import { AppConstant, LangConstant } from "const";
import { useTranslation } from "react-i18next";
import ReportDashboardAction from "redux/report-dashboard.redux";
import { useDispatch, useSelector } from "react-redux";
import BookingByConsumerChart from "./BookingByCustomerChart";
import ReportLayout from "../ReportLayout";
import ReportChartStatus from "../ReportChartStatus";

const BookingByCustomer = () => {
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);
  const dispatch = useDispatch();
  const summaryCustomerSetting = useSelector(({ reportDashboard }) => reportDashboard.summaryCustomer.setting);

  const onChangeQueryParams = queryParams => dispatch(ReportDashboardAction.requestReportSummaryCustomer(queryParams));

  const onChangeDisplaySetting = displaySettings => {
    if (bookingByCustomerTimeout) {
      clearTimeout(bookingByCustomerTimeout);
    }

    bookingByCustomerTimeout = setTimeout(() => {
      dispatch(
        ReportDashboardAction.createReportSummaryCustomerSetting({ ...DISPLAY_SETTING_QUERY, ...displaySettings }),
      );
    }, 300);
  };

  return (
    <ReportLayout title={getLabel(LangConstant.TXT_CHART_TITLE_SECOND)} onChangeQueryParams={onChangeQueryParams}>
      <BookingByConsumerChart />
      <ReportChartStatus displaySetting={summaryCustomerSetting} onChange={onChangeDisplaySetting} />
    </ReportLayout>
  );
};

BookingByCustomer.propTypes = {};
export default BookingByCustomer;

const DISPLAY_SETTING_QUERY = { type: AppConstant.DISPLAY_CHART_SETTING_TYPE.reportByCustomer };
var bookingByCustomerTimeout;
