import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Card, makeStyles, Typography } from "@material-ui/core";
import StringFormat from "string-format";
import BillAction from "redux/bill.redux";
import { AppConstant, LangConstant } from "const";
import { convertHex2rgba, getBillStatusColor, getCommonKey } from "utils";
import { DialogVerify } from "components";

const BillStatusBatchUpdate = ({ selectedBookingList }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);
  let mappingArray = VAT_STATUS_MENU_ARRAY;
  const isUpdateSuccess = useSelector(state => state.billRedux.isPatchUpdateSuccess);

  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [targetStatus, setTargetStatus] = useState(null);

  const onClick = status => {
    setTargetStatus(status);
    setIsShowConfirm(true);
  };

  const onCancelConfirm = () => {
    setIsShowConfirm(false);
    setTargetStatus(null);
  };

  const onConfirmBatchUpdate = () => {
    let uidList = selectedBookingList.map(item => item.uid);
    dispatch(
      BillAction.patchUpdateBillStatus({
        uidList: uidList,
        status: targetStatus,
      }),
    );
  };

  const onSetConfirmDialogContent = isTitle => {
    let result;
    if (isTitle) {
      result = StringFormat(
        getLabel(LangConstant.FM_BATCH_UPDATE_STATUS_DIALOG_TITLE),
        selectedBookingList.length || 0,
        getLabel(getButtonStatusContent(targetStatus)),
      );
    } else {
      result = StringFormat(
        getLabel(LangConstant.FM_BATCH_UPDATE_STATUS_DIALOG_CONTENT),
        selectedBookingList.length || 0,
        getLabel(getButtonStatusContent(targetStatus)),
      );
    }
    return result;
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsShowConfirm(false);
      dispatch(
        BillAction.billSuccess({
          isPatchUpdateSuccess: false,
        }),
      );
    }
  }, [isUpdateSuccess]);

  return (
    <Card className={classes.statusMenuFilterRoot}>
      {mappingArray.map((valueStatus, index) => (
        <Button
          className={classes.filterByStatusBtn}
          style={{
            ...getButtonStatusStyle(valueStatus.value, targetStatus),
          }}
          key={index}
          onClick={() => onClick(valueStatus.value)}
        >
          <Typography className="semiBold-sm-txt" color="inherit">
            {getLabel(getButtonStatusContent(valueStatus.value))}
          </Typography>
        </Button>
      ))}

      {isShowConfirm && (
        <DialogVerify
          classes={{
            title: classes.confirmDialogTitle,
            content: classes.confirmDialogContent,
            confirmButton: classes.confirmDialogConfirmButton,
          }}
          isShow={isShowConfirm}
          title={onSetConfirmDialogContent(true)}
          content={onSetConfirmDialogContent()}
          onCancel={onCancelConfirm}
          onConfirm={onConfirmBatchUpdate}
        />
      )}
    </Card>
  );
};

BillStatusBatchUpdate.propTypes = {
  selectedBookingList: PropTypes.array,
};

BillStatusBatchUpdate.defaultProps = {
  selectedBookingList: [],
};

const VAT_STATUS_MENU_ARRAY = [
  { value: AppConstant.STT_VAT_BILL.pending },
  { value: AppConstant.STT_VAT_BILL.transferred },
  { value: AppConstant.STT_VAT_BILL.billExported },
];

const getButtonStatusContent = status => {
  let content =
    status === AppConstant.STT_VAT_BILL.pending
      ? getCommonKey(LangConstant.TXT_PENDING)
      : status === AppConstant.STT_VAT_BILL.transferred
      ? LangConstant.TXT_TRANSFERRED
      : LangConstant.TXT_SENT;
  return content;
};

export default BillStatusBatchUpdate;

const getButtonStatusStyle = (status, selectedStatus) => {
  let style = {
    color: status == selectedStatus ? "#fff" : getBillStatusColor(status),
    border: `1px solid ${getBillStatusColor(status)}`,
    backgroundColor: convertHex2rgba(getBillStatusColor(status), status == selectedStatus ? 1 : 0.3),
  };
  return style;
};

const useStyle = makeStyles(theme => ({
  filterByStatusBtn: {
    textTransform: "none",
    borderRadius: 2,
    minWidth: 109,
    height: 24,
    marginRight: 10,
    "&:hover": {
      backgroundColor: "currentColor !important",
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },

  statusMenuFilterRoot: {
    height: 55,
    display: "flex",
    alignItems: "center",
    padding: "0 68px",
    overflowX: "auto",
    [theme.breakpoints.down(550)]: {
      padding: 0,
      paddingLeft: 5,
    },
  },

  confirmDialogContent: {
    fontSize: 13,
    fontWeight: "normal",
  },

  confirmDialogConfirmButton: {
    marginLeft: 26,
  },
}));
