import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Box, Button, Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import { LangConstant } from "const";

const FilterByType = ({ filterBy, onChangeFilterByType, filterByReport, onChangeFilterByReport }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_RATING_MANAGEMENT);
  const classes = useStyles();

  return (
    <>
      <Box className={classes.filterByBox}>
        <Typography className="bold-md-txt">{getLabel(LangConstant.TXT_RATING_FILTER)}</Typography>

        <Button
          variant="text"
          className={clsx(filterBy === FILTER_BY.shop && classes.selectedFilterBy, classes.filterBy, "regular-md-txt")}
          onClick={() => onChangeFilterByType(FILTER_BY.shop)}
        >
          {getLabel(LangConstant.TXT_SHOP_RATING)}
        </Button>

        <Button
          variant="text"
          className={clsx(
            filterBy === FILTER_BY.product && classes.selectedFilterBy,
            classes.filterBy,
            "regular-md-txt",
          )}
          onClick={() => onChangeFilterByType(FILTER_BY.product)}
        >
          {getLabel(LangConstant.TXT_PRODUCT_RATING)}
        </Button>
      </Box>

      <Box className={classes.reportBox}>
        <Typography className="bold-md-txt">{getLabel(LangConstant.TXT_REPORT)}</Typography>
        <FormControlLabel
          control={
            <Checkbox
              disableRipple={true}
              disableTouchRipple={true}
              checked={filterByReport}
              onChange={() => onChangeFilterByReport(!filterByReport)}
              color="primary"
              className={classes.scoreFilterCheckbox}
            />
          }
          label={<Typography variant="body2">{getLabel(LangConstant.TXT_VIOLATION_REPORT)}</Typography>}
          classes={{
            root: classes.filterByReport,
            label: classes.filterByReportLabel,
          }}
        />
      </Box>
    </>
  );
};

FilterByType.propTypes = {
  filterBy: PropTypes.number,
  onChangeFilterByType: PropTypes.func,
};

const FILTER_BY = {
  shop: 1,
  product: 2,
};

const useStyles = makeStyles(theme => ({
  scoreFilterCheckbox: {
    padding: 0,
  },

  filterByBox: {
    width: "100%",
    padding: "10px 0",
    borderBottom: "1px solid #CECFD2",
    display: "flex",
    flexDirection: "column",
  },

  filterBy: {
    cursor: "pointer",
    userSelect: "none",
    marginTop: 10,
    width: "fit-content",
    lineHeight: "22px",
    textTransform: "none",
    padding: 0,
  },

  selectedFilterBy: {
    color: theme.palette.primary.main,
  },

  reportBox: {
    width: "100%",
    paddingTop: 10,
  },

  filterByReport: {
    width: "100%",
    margin: 0,
    marginTop: 10,
  },

  filterByReportLabel: {
    marginLeft: 10,
    userSelect: "none",
  },
}));

export default FilterByType;
