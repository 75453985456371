import { Box, makeStyles } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import PlaceCategoryAction from "redux/place-category.redux";
import PlaceCategory from "./PlaceCategory";
import { BglobalJSC } from "components";

const MnPlace = () => {
  const placeCategoryRedux = useSelector(state => state.placeCategoryRedux);
  const placeCategoryData = placeCategoryRedux?.data;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [placeData, setPlaceData] = useState(placeCategoryData);

  useEffect(() => {
    if (!placeCategoryRedux.isFetching) {
      dispatch(PlaceCategoryAction.getPlaceCategory({}));
    }
  }, []);

  useEffect(() => {
    if (placeCategoryData != null && !placeCategoryRedux.isFetching) {
      setPlaceData(placeCategoryData);
    }
  }, [placeCategoryData]);

  return (
    <>
      <Box className={classes.root}>
        <PlaceCategory data={placeData} />
      </Box>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 180px)",
  },
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));

MnPlace.propTypes = {
  placeDate: PropTypes.object,
};

MnPlace.defaultProps = {};
export default memo(MnPlace);
