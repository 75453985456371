import React from "react";
import PropTypes from "prop-types";
import { makeStyles, TableRow, TableCell } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import StringFormat from "string-format";
import { AppConstant, LangConstant } from "const";
import { convertTimeStampToDate } from "utils/date";
import { getCommonKey, formatCurrency } from "utils";

const PaymentTableRow = ({ data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);
  const objPackageUsing = getLabel(LangConstant.OBJ_PACKAGE_USING_TYPE, { returnObjects: true });

  return (
    <TableRow>
      <TableCell align="left" className={classes.contentCell}>
        {data?.type ? StringFormat(getLabel(LangConstant.FM_ADDING_PRODUCT), objPackageUsing[data.type]) : ""}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data?.type ? StringFormat(getLabel(LangConstant.FM_ADDING_PRODUCT), objPackageUsing[data.type]) : ""}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data.orderNumber}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data?.period ? StringFormat(getLabel(LangConstant.FM_TIME_MONTH), data.period) : ""}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data?.startDate ? convertTimeStampToDate(data.startDate, AppConstant.FM_DD_MM_YYYY) : ""}
      </TableCell>
      <TableCell align="left" className={classes.contentCell}>
        {data?.expiryDate ? convertTimeStampToDate(data.expiryDate, AppConstant.FM_DD_MM_YYYY) : ""}
      </TableCell>
      <TableCell align="right" className={classes.contentCell}>
        {data?.price
          ? StringFormat(
              getLabel(LangConstant.FM_CURRENCY),
              formatCurrency(data.price.toString()),
              getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
            )
          : ""}
      </TableCell>
      <TableCell align="right" className={classes.contentCell}>
        {data?.total
          ? StringFormat(
              getLabel(LangConstant.FM_CURRENCY),
              formatCurrency(data.total.toString()),
              getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON)),
            )
          : ""}
      </TableCell>
    </TableRow>
  );
};

PaymentTableRow.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    orderNumber: PropTypes.number,
    period: PropTypes.number,
    startDate: PropTypes.number,
    expiryDate: PropTypes.number,
    price: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
};
PaymentTableRow.defaultProps = {};

export default PaymentTableRow;

const useStyles = makeStyles(theme => ({
  contentCell: {
    fontWeight: 500,
    lineHeight: 1.57,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));
