import React, { memo } from "react";
import PropTypes from "prop-types";
import { DialogTitle as MuiDialogTitle, IconButton, makeStyles } from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

const DialogTitle = props => {
  const { children, classes, onClose, ...other } = props;
  const defaultClasses = useStyles();

  return (
    <MuiDialogTitle disableTypography className={clsx(defaultClasses.root, classes.root)} {...other}>
      {children}
      {onClose && (
        <IconButton aria-label="close" className={clsx(defaultClasses.closeButton, classes.icon)} onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
  }),

  onClose: PropTypes.func.isRequired,
};

DialogTitle.defaultProps = {
  classes: {
    root: "",
    icon: "",
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },

  closeButton: {
    color: theme.palette.grey[700],
    fontSize: 24,
  },
}));

export default memo(DialogTitle);
