import React, { memo, useState, useEffect } from "react";
import { makeStyles, Box, Button, Typography } from "@material-ui/core";
import {
  DisplayModeBar,
  IconButtonMProductItem,
  CategorySubcategory,
  CategoryItemListHeader,
  CategoryItemListBody,
  DialogAddCategoryItem,
} from "components/mn-master-product";
import { DialogAddCategory, DialogDelete } from "components/dialog";
import {
  DialogAddServiceFromCategory,
  DialogCreateAccompaniedService,
  DialogEditAndWatchAccompaniedService,
} from "./accompanied-service";
import { DialogEditAndWatchService } from "./main-service";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant } from "const";
import { getCommonKey, removeVietnameseTones, convertTimeItemDescription, formatCurrency } from "utils";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ItemDetailsAction from "redux/item-details.redux";
import ItemDetailsContentAction from "redux/item-details-content.redux";

const AccompaniedServiceCategory = ({ childServiceModeId, childServiceModeArr, onChangeMode }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();
  const searchForm = {
    type: AppConstant.TYPE_CATEGORY.service,
  };

  const getListItemDetailsSuccess = useSelector(
    state => state.itemDetailsRedux.isGetListItemDetailsSuccess,
    shallowEqual,
  );
  const postItemDetailsSuccess = useSelector(state => state.itemDetailsRedux.isPostItemDetailsSuccess, shallowEqual);
  const putItemDetailsSuccess = useSelector(state => state.itemDetailsRedux.isPutItemDetailsSuccess, shallowEqual);
  const putEditContentSuccess = useSelector(state => state.itemDetailsRedux.isPutEditContentSuccess, shallowEqual);
  const deleteItemDetailsSuccess = useSelector(
    state => state.itemDetailsRedux.isDeleteItemDetailsSuccess,
    shallowEqual,
  );
  const errorPostItemDetails = useSelector(state => state.itemDetailsRedux.errorPostItemDetails);
  const errorPutItemDetails = useSelector(state => state.itemDetailsRedux.errorPutItemDetails);
  const dataItemDetails = useSelector(state => state.itemDetailsRedux.dataGetListItemDetails, shallowEqual);

  const postServiceContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isPostItemDetailsContentSuccess,
    shallowEqual,
  );
  const putServiceContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isPutItemDetailsContentSuccess,
    shallowEqual,
  );
  const deleteServiceContentSuccess = useSelector(
    state => state.itemDetailsContentRedux.isDeleteItemDetailsContentSuccess,
    shallowEqual,
  );

  const [listItemDetails, setListItemDetails] = useState([]);
  const [saveListItemDetails, setSaveListItemDetails] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [formCreateCategory, setFormCreateCategory] = useState({
    name: "",
    type: AppConstant.TYPE_CATEGORY.service,
    description: "",
    itemDetailsContent: [],
  });
  const [isOpenDialogCreateCategory, setIsOpenDialogCreateCategory] = useState(false);
  const [isOpenDialogAddService, setIsOpenDialogAddService] = useState({
    selectAddType: false,
    addFromMain: false,
    addNew: false,
    uid: null,
  });
  const [isOpenDeleteAndEditCategory, setIsOpenDeleteAndEditCategory] = useState({
    edit: false,
    delete: false,
    dataForm: {
      uid: "",
      name: "",
      type: AppConstant.TYPE_CATEGORY.service,
      description: "",
    },
  });
  const [formDataChild, setFormDataChild] = useState({
    isOpen: false,
    uid: "",
    nameParent: "",
    itemDetailsContent: [],
    save: [],
  });
  const [isOpenChild, setIsOpenChild] = useState({
    edit: false,
    watch: false,
    delete: false,
    dataForm: null,
  });

  const onOpenDialogCreate = () => {
    setIsOpenDialogCreateCategory(true);
  };

  const onOpenDialogAddService = () => {
    setIsOpenDialogAddService({ ...isOpenDialogAddService, selectAddType: true });
  };

  const onAddFromMainService = () => {
    setIsOpenDialogAddService(open => ({ ...open, addFromMain: !open.addFromMain }));
  };

  const onCreateAccompaniedService = () => {
    setIsOpenDialogAddService(open => ({ ...open, addNew: !open.addNew }));
  };

  const onCloseDialogAddService = () => {
    setIsOpenDialogAddService({
      ...isOpenDialogAddService,
      selectAddType: false,
      addFromMain: false,
      addNew: false,
      uid: null,
    });
  };

  const onBackDialogAddService = () => {
    setIsOpenDialogAddService({
      ...isOpenDialogAddService,
      selectAddType: true,
      addFromMain: false,
      addNew: false,
    });
  };

  const onChangeNameCategory = name => {
    if (isOpenDialogCreateCategory) {
      setFormCreateCategory(dataChange => ({
        ...dataChange,
        name: name,
      }));
    } else if (isOpenDeleteAndEditCategory.edit) {
      setIsOpenDeleteAndEditCategory(dataChange => ({
        ...dataChange,
        dataForm: {
          ...dataChange.dataForm,
          name: name,
        },
      }));
    }
  };

  const onSubmitDialogCreateAndEditCategory = () => {
    if (isOpenDialogCreateCategory) {
      dispatch(ItemDetailsAction.postItemDetails(formCreateCategory));
    } else if (isOpenDeleteAndEditCategory.edit) {
      dispatch(ItemDetailsAction.putItemDetails(isOpenDeleteAndEditCategory.dataForm));
    }
  };

  const onCloseDialogCategory = () => {
    setIsDuplicate(false);
    setIsOpenDialogCreateCategory(false);
    setIsOpenDeleteAndEditCategory({
      edit: false,
      delete: false,
      dataForm: {
        uid: "",
        name: "",
        type: AppConstant.TYPE_CATEGORY.service,
        description: "",
      },
    });
  };

  const onComeBack = () => {
    setFormDataChild({
      isOpen: false,
      uid: "",
      nameParent: "",
      itemDetailsContent: [],
    });
  };

  const onSearch = name => {
    let newListSearch = saveListItemDetails.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setListItemDetails(newListSearch);
  };

  const onSearchItemInChild = name => {
    let newListSearchChild = formDataChild.save.filter(itemSearch =>
      removeVietnameseTones(itemSearch.name).toLowerCase().includes(removeVietnameseTones(name.toLowerCase())),
    );
    setFormDataChild(dataChange => ({ ...dataChange, itemDetailsContent: newListSearchChild }));
  };

  const onOpenCategorySubCategory = uid => {
    let itemDetails = listItemDetails.find(data => data.uid === uid);
    setFormDataChild({
      isOpen: true,
      uid: itemDetails.uid,
      nameParent: itemDetails.name,
      itemDetailsContent: itemDetails.itemDetailsContentList,
      save: itemDetails.itemDetailsContentList,
    });
  };

  const onContextMenuCategorySubcategory = (uid, key) => {
    let itemDetails = listItemDetails.find(data => data.uid === uid);
    setIsOpenDeleteAndEditCategory(dataChange => ({
      delete: !Boolean(key === "edit"),
      edit: Boolean(key === "edit"),
      dataForm: {
        ...dataChange.dataForm,
        uid: itemDetails.uid,
        name: itemDetails.name,
      },
    }));
  };

  const onSubmitDeleteCategory = (e, uid) => {
    dispatch(ItemDetailsAction.deleteItemDetails({ uid: uid }));
  };

  const onOpenDialogDeleteChild = uid => {
    let newForm = formDataChild.save.find(data => data.uid === uid);
    setIsOpenChild(dataChange => ({
      ...dataChange,
      delete: true,
      dataForm: newForm,
    }));
  };

  const onOpenDialogWatchChild = () => {
    //TODO pj9 later can be used
    // let newForm = formDataChild.save.find(data => data.uid === uid);
    // setIsOpenChild(dataChange => ({
    //   ...dataChange,
    //   watch: true,
    //   dataForm: newForm,
    // }));
  };

  const onOpenDialogEditChild = uid => {
    let newForm = formDataChild.save.find(data => data.uid === uid);
    setIsOpenChild(dataChange => ({
      ...dataChange,
      edit: true,
      dataForm: newForm,
    }));
  };

  const onCloseDialogChild = () => {
    setIsOpenChild({
      edit: false,
      watch: false,
      delete: false,
      dataForm: null,
    });
  };

  const onSubmitDeleteChild = (e, uid) => {
    let formData = formDataChild.itemDetailsContent.filter(dataChange => dataChange.uid !== uid);
    dispatch(ItemDetailsAction.putEditContent({ uid: formDataChild.uid, itemDetailsContentList: formData }));
  };

  useEffect(() => {
    if (!dataItemDetails) {
      dispatch(ItemDetailsAction.getListItemDetails(searchForm));
    } else {
      if (dataItemDetails?.data) {
        setListItemDetails(dataItemDetails.data);
        setSaveListItemDetails(dataItemDetails.data);
      }
    }
  }, []);

  useEffect(() => {
    if (dataItemDetails?.data && getListItemDetailsSuccess) {
      dispatch(ItemDetailsAction.resetItemDetails());
      setListItemDetails(dataItemDetails.data);
      setSaveListItemDetails(dataItemDetails.data);
      if (formDataChild.isOpen) {
        let newForm = dataItemDetails.data.find(data => data.uid === formDataChild.uid);
        setFormDataChild(dataChange => ({
          ...dataChange,
          itemDetailsContent: newForm.itemDetailsContentList,
          save: newForm.itemDetailsContentList,
        }));
      }
    }
  }, [getListItemDetailsSuccess]);

  useEffect(() => {
    if (postItemDetailsSuccess) {
      dispatch(ItemDetailsAction.resetItemDetails());
      dispatch(ItemDetailsAction.getListItemDetails(searchForm));
      onCloseDialogCategory();
    }
  }, [postItemDetailsSuccess]);

  useEffect(() => {
    if (putItemDetailsSuccess) {
      dispatch(ItemDetailsAction.resetItemDetails());
      dispatch(ItemDetailsAction.getListItemDetails(searchForm));
      onCloseDialogCategory();
    }
  }, [putItemDetailsSuccess]);

  useEffect(() => {
    if (deleteItemDetailsSuccess) {
      dispatch(ItemDetailsAction.resetItemDetails());
      dispatch(ItemDetailsAction.getListItemDetails(searchForm));
      onCloseDialogCategory();
    }
  }, [deleteItemDetailsSuccess]);

  useEffect(() => {
    if (putEditContentSuccess) {
      dispatch(ItemDetailsAction.resetItemDetails());
      dispatch(ItemDetailsAction.getListItemDetails(searchForm));
      onCloseDialogChild();
    }
  }, [putEditContentSuccess]);

  useEffect(() => {
    if (putServiceContentSuccess || postServiceContentSuccess) {
      dispatch(ItemDetailsAction.resetItemDetails());
      dispatch(ItemDetailsAction.getListItemDetails(searchForm));
      onCloseDialogChild();
      if (isOpenDialogAddService.addNew || isOpenChild.edit || isOpenChild.delete) {
        dispatch(ItemDetailsContentAction.getListItemDetailsContent(searchForm));
        onCloseDialogAddService();
      }
    }
  }, [putServiceContentSuccess, postServiceContentSuccess, deleteServiceContentSuccess]);

  useEffect(() => {
    if (errorPostItemDetails) {
      if (errorPostItemDetails?.name[0] !== getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsOpenDialogCreateCategory(false);
      } else {
        setIsDuplicate(true);
        dispatch(ItemDetailsAction.resetErrorItemDetails());
      }
    }
    if (errorPutItemDetails) {
      if (errorPutItemDetails?.name[0] !== getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsOpenDialogCreateCategory(false);
      } else {
        setIsDuplicate(true);
        dispatch(ItemDetailsAction.resetErrorItemDetails());
      }
    }
  }, [errorPostItemDetails, errorPutItemDetails]);

  return (
    <>
      <DisplayModeBar
        modeId={childServiceModeId}
        modeArray={childServiceModeArr}
        labelBtn={`${
          formDataChild.isOpen
            ? getLabel(getCommonKey(LangConstant.TXT_ADD))
            : getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))
        } ${
          formDataChild.itemDetailsContent.length > 0
            ? getLabel(LangConstant.TXT_SERVICE_LOWER_CASE)
            : getLabel(LangConstant.TXT_CATEGORY_LOWER_CASE)
        }`}
        onChange={onChangeMode}
        onAddItem={formDataChild.isOpen > 0 ? onOpenDialogAddService : onOpenDialogCreate}
        onSearch={formDataChild.isOpen > 0 ? onSearchItemInChild : onSearch}
        showSearch={
          (Boolean(saveListItemDetails.length > 0) && !formDataChild.isOpen) ||
          Boolean(formDataChild.isOpen && formDataChild.itemDetailsContent.length > 0)
        }
      />
      {formDataChild.isOpen ? (
        formDataChild.itemDetailsContent.length > 0 ? (
          <Box className={classes.contentBox} padding="16px 0">
            <Box display="flex" marginBottom="24px" paddingLeft="24px">
              <Button className={classes.rootChooseButton} onClick={onComeBack}>
                {getLabel(getCommonKey(LangConstant.TXT_CATEGORY))}
              </Button>
              <Typography className={classes.typoSlash}>/</Typography>
              <Button classes={{ root: classes.rootChooseButton, label: classes.labelChooseButton }}>
                {formDataChild.nameParent}
              </Button>
            </Box>
            <Box margin="0 24px">
              <CategoryItemListHeader isProduct={false} />
              {formDataChild.itemDetailsContent.map(dataServiceItem => (
                <CategoryItemListBody
                  key={dataServiceItem.uid}
                  uid={dataServiceItem.uid}
                  categoryName={dataServiceItem.name}
                  onClickWatch={onOpenDialogWatchChild}
                  onClickEdit={onOpenDialogEditChild}
                  onClickDelete={onOpenDialogDeleteChild}
                  isAdd={false}
                  timeUsed={
                    dataServiceItem.usingTime &&
                    dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.times
                      ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                      : ""
                  }
                  image={dataServiceItem.image}
                  price={
                    dataServiceItem.price
                      ? `${formatCurrency(`${dataServiceItem.price}`)} ${getLabel(LangConstant.TXT_CURRENCY)}/${
                          dataServiceItem.usingTime &&
                          dataServiceItem.unitType === AppConstant.TYPE_UNIT_PRICE_CALCULATE.time
                            ? convertTimeItemDescription(dataServiceItem.usingTime, dataServiceItem.usingTimeType)
                            : getLabel(getCommonKey(LangConstant.P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT))
                        }`
                      : ""
                  }
                  isNotClickEdit={Boolean(isOpenChild.edit && dataServiceItem.itemUid)}
                  isRemoveMode={true}
                />
              ))}
            </Box>
          </Box>
        ) : (
          <Box className={classes.contentBox} padding="16px 0">
            <Box display="flex" marginBottom="24px" paddingLeft="24px">
              <Button className={classes.rootChooseButton} onClick={onComeBack}>
                {getLabel(getCommonKey(LangConstant.TXT_CATEGORY))}
              </Button>
              <Typography className={classes.typoSlash}>/</Typography>
              <Button classes={{ root: classes.rootChooseButton, label: classes.labelChooseButton }}>
                {formDataChild.nameParent}
              </Button>
            </Box>
            <Box display="flex" height="563px">
              <IconButtonMProductItem
                label={`${getLabel(getCommonKey(LangConstant.TXT_ADD))} ${getLabel(
                  LangConstant.TXT_SERVICE_LOWER_CASE,
                )}`}
                onClick={onOpenDialogAddService}
                buttonClasses={{ root: classes.addButton }}
              />
            </Box>
          </Box>
        )
      ) : Boolean(saveListItemDetails.length > 0) ? (
        <Box className={classes.contentBox} padding="50px 0">
          <Box className={classes.listItemDetails}>
            {listItemDetails.map(dataMap => (
              <Box margin="12px" display="grid" key={dataMap.uid}>
                <CategorySubcategory
                  uid={dataMap.uid}
                  text={dataMap.name}
                  onClick={onOpenCategorySubCategory}
                  onContextMenu={onContextMenuCategorySubcategory}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box className={classes.contentBox} display="flex">
          <IconButtonMProductItem
            label={`${getLabel(getCommonKey(LangConstant.TXT_CREATE_BUTTON))} ${getLabel(
              LangConstant.TXT_CATEGORY_LOWER_CASE,
            )}`}
            onClick={onOpenDialogCreate}
            buttonClasses={{ root: classes.addButton }}
          />
        </Box>
      )}
      {(isOpenDialogCreateCategory || isOpenDeleteAndEditCategory.edit) && (
        <DialogAddCategory
          isShow={isOpenDialogCreateCategory || isOpenDeleteAndEditCategory.edit}
          onClose={onCloseDialogCategory}
          onSubmit={onSubmitDialogCreateAndEditCategory}
          onChange={onChangeNameCategory}
          data={isOpenDeleteAndEditCategory.dataForm.uid ? isOpenDeleteAndEditCategory.dataForm : null}
          isWarning={isDuplicate}
          nameWaring={isDuplicate ? getLabel(LangConstant.TXT_WARNING_CATEGORY) : ""}
        />
      )}
      {isOpenDeleteAndEditCategory.delete && (
        <DialogDelete
          uid={isOpenDeleteAndEditCategory.dataForm.uid}
          isShow={isOpenDeleteAndEditCategory.delete}
          onCancel={onCloseDialogCategory}
          onConfirm={onSubmitDeleteCategory}
          title={getLabel(LangConstant.FN_DELETE_GOODS_CATEGORY_TITLE)(isOpenDeleteAndEditCategory.dataForm.name)}
          content={getLabel(LangConstant.FN_DELETE_SERVICE_CATEGORY_CONTENT)(isOpenDeleteAndEditCategory.dataForm.name)}
        />
      )}
      {isOpenChild.delete && (
        <DialogDelete
          uid={isOpenChild.dataForm?.uid}
          isShow={isOpenChild.delete}
          onCancel={onCloseDialogChild}
          onConfirm={onSubmitDeleteChild}
          title={getLabel(LangConstant.FN_DELETE_SERVICE_ITEM_TITLE)(isOpenChild.dataForm?.name)}
          content={getLabel(LangConstant.FN_DELETE_ACCOMPANIED_SERVICE_ITEM_CONTENT)(isOpenChild.dataForm?.name)}
        />
      )}
      {isOpenChild.edit && !isOpenChild.dataForm?.itemUid && (
        <DialogEditAndWatchAccompaniedService
          data={isOpenChild.dataForm}
          onClose={onCloseDialogChild}
          isEdit={isOpenChild.edit}
        />
      )}
      {isOpenChild.watch && !isOpenChild.dataForm?.itemUid && (
        <DialogEditAndWatchAccompaniedService
          data={isOpenChild.dataForm}
          onClose={onCloseDialogChild}
          isWatch={isOpenChild.watch}
        />
      )}
      {isOpenChild.watch && isOpenChild.dataForm?.itemUid && (
        <DialogEditAndWatchService
          uid={isOpenChild.dataForm.itemUid}
          onClose={onCloseDialogChild}
          isWatch={isOpenChild.watch}
        />
      )}

      {isOpenDialogAddService.selectAddType && (
        <DialogAddCategoryItem
          isShow={isOpenDialogAddService.selectAddType}
          titleName={{
            header: getLabel(LangConstant.TXT_CHOOSE_TYPE_CREATE_ACCOMPANIED),
            add: getLabel(LangConstant.TXT_ADD_ACCOMPANIED_FROM_ALL_ATTACHED),
            created: getLabel(LangConstant.TXT_CREATE_SERVICE_ACCOMPANIED),
          }}
          action={onCloseDialogAddService}
          inputProps={{ className: classes.dialogAddTypo }}
          onAddFromMainService={onAddFromMainService}
          onCreateAccompaniedService={onCreateAccompaniedService}
        />
      )}
      {isOpenDialogAddService.addFromMain && (
        <DialogAddServiceFromCategory
          uidParent={formDataChild.uid}
          nameParent={formDataChild.nameParent}
          dataChild={formDataChild.itemDetailsContent}
          isOpen={isOpenDialogAddService.addFromMain}
          onClose={onCloseDialogAddService}
        />
      )}
      {isOpenDialogAddService.addNew && (
        <DialogCreateAccompaniedService
          isOpen={isOpenDialogAddService.addNew}
          onClose={onCloseDialogAddService}
          onBack={onBackDialogAddService}
          categoryUid={formDataChild.uid}
        />
      )}
    </>
  );
};

export default memo(AccompaniedServiceCategory);

const useStyles = makeStyles(theme => ({
  contentBox: {
    width: "100%",
    minHeight: "720px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
      overflowX: "auto",
    },
  },
  listItemDetails: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(296px, 1fr))",
  },
  rootChooseButton: {
    padding: 0,
    textTransform: "unset",
    fontWeight: 500,
    minWidth: "unset",
  },
  labelChooseButton: {
    color: "#447aff",
  },
  typoSlash: {
    margin: "0 8px",
    fontSize: 14,
  },
  addButton: {
    minWidth: 180,
    minHeight: 40,
    margin: "auto auto",
    paddingLeft: 20,
    paddingRight: 24,
  },
}));
