import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  requestReportBookingDay: ["data"],
  reportBookingSuccessDay: ["data"],
  reportBookingFailureDay: ["data"],
  requestReportBookingWeek: ["data"],
  reportBookingSuccessWeek: ["data"],
  reportBookingFailureWeek: ["data"],
  requestReportBookingMonth: ["data"],
  reportBookingSuccessMonth: ["data"],
  reportBookingFailureMonth: ["data"],
});

export const ReportBookingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,

  isReportBooking: false,
  dataDay: null,
  dataWeek: null,
  dataMonth: null,
};

/* ------------- Reducers ------------- */

/* ------------- Day ------------- */
export const requestReportBookingDay = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isReportBooking: null,
});

export const reportBookingSuccessDay = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isReportBooking: true,
    dataDay: action.data,
  };
};

export const reportBookingFailureDay = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

/* ------------- Week ------------- */

export const requestReportBookingWeek = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isReportBooking: null,
});

export const reportBookingSuccessWeek = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isReportBooking: true,
    dataWeek: action.data,
  };
};

export const reportBookingFailureWeek = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

/* ------------- Month ------------- */

export const requestReportBookingMonth = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
  isReportBooking: null,
});

export const reportBookingSuccessMonth = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isReportBooking: true,
    dataMonth: action.data,
  };
};

export const reportBookingFailureMonth = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  error: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.REQUEST_REPORT_BOOKING_DAY]: requestReportBookingDay,
  [Types.REPORT_BOOKING_SUCCESS_DAY]: reportBookingSuccessDay,
  [Types.REPORT_BOOKING_FAILURE_DAY]: reportBookingFailureDay,
  [Types.REQUEST_REPORT_BOOKING_WEEK]: requestReportBookingWeek,
  [Types.REPORT_BOOKING_SUCCESS_WEEK]: reportBookingSuccessWeek,
  [Types.REPORT_BOOKING_FAILURE_WEEK]: reportBookingFailureWeek,
  [Types.REQUEST_REPORT_BOOKING_MONTH]: requestReportBookingMonth,
  [Types.REPORT_BOOKING_SUCCESS_MONTH]: reportBookingSuccessMonth,
  [Types.REPORT_BOOKING_FAILURE_MONTH]: reportBookingFailureMonth,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
