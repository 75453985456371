import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment, OutlinedInput } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import clsx from "clsx";
import { AppConstant } from "const";

const CustomSearchBar = ({ placeholder, className, onChange, ...otherProps }) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");

  const onCallBack = params => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      onChange(params);
    }, AppConstant.DEBOUNCE_TIMEOUT);
  };

  const onChangeSearchValue = event => {
    let value = event.target.value;
    setSearchValue(value);
    onCallBack(value);
  };

  return (
    <OutlinedInput
      placeholder={placeholder}
      type="text"
      className={className}
      value={searchValue}
      onChange={onChangeSearchValue}
      classes={{
        input: clsx(classes.input, "medium-md-txt"),
        root: classes.root,
        notchedOutline: classes.notchedOutline,
      }}
      startAdornment={
        <InputAdornment position="start" className={classes.icon}>
          <Search className={classes.customSearchIcon} />
        </InputAdornment>
      }
      {...otherProps}
    />
  );
};

CustomSearchBar.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
CustomSearchBar.defaultProps = {
  placeholder: "",
  className: "",
};

let searchTimeout;

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    width: "100%",
    border: `1px solid ${theme.palette.grey["200"]}`,
  },

  notchedOutline: {
    border: "none",
  },

  input: {
    height: "unset",
    padding: "0",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
  },

  icon: {
    minWidth: 60,
    width: 60,
    height: "100%",
    maxHeight: "100%",
    minHeight: 30,
    justifyContent: "center",
    marginRight: 0,
    [theme.breakpoints.down("xs")]: {
      minWidth: 40,
      width: 40,
    },
  },

  customSearchIcon: {
    fontSize: 24,
    color: theme.palette.grey["700"],
  },
}));

export default memo(CustomSearchBar);
