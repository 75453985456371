import "date-fns";
import React, { memo, useEffect, useRef, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import viLocale from "date-fns/locale/vi";
import enLocale from "date-fns/locale/en-US";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";
import PropTypes from "prop-types";
import { Popover, Typography } from "@material-ui/core";
import { fetchDateToText } from "utils/date";
import clsx from "clsx";

const CustomDatePicker = ({
  onChange,
  selectedDateProp,
  isUseEffect,
  includeIcon,
  displayDate,
  inputBox,
  iconClass,
  datePickerDialog,
  overrideInputClass,
  ...otherProps
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { t: getLabel } = useTranslation();
  const calendarRef = useRef();
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  const [selectedDate, setSelectedDate] = useState(selectedDateProp);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isUseEffect) setSelectedDate(selectedDateProp);
  }, [selectedDateProp]);

  const onDateChange = date => {
    setSelectedDate(date);
    onChange(date);
    setIsOpen(false);
  };

  const onClick = event => {
    event.preventDefault();
    setIsOpen(!isOpen);
    setCalendarAnchor(calendarRef.current);
  };

  return (
    <>
      <Box
        className={clsx(classes.boxRoot, inputBox, includeIcon ? classes.boxRootSpaceBetween : classes.boxRootCenter)}
        onClick={onClick}
        ref={calendarRef}
      >
        <Typography className={overrideInputClass || `${classes.inputRoot} medium-md-txt`} onClick={onClick}>
          {displayDate || fetchDateToText(selectedDate, getLabel)}
        </Typography>
        {includeIcon && <ExpandMoreIcon className={clsx(classes.iconRoot, iconClass)} />}
      </Box>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
        <Popover
          anchorEl={calendarAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          classes={{ paper: clsx(classes.datepickerPaper, datePickerDialog) }}
        >
          <Box className="datepicker-root">
            <DatePicker
              disableToolbar
              variant="static"
              format={AppConstant.FM_DD_MM_YYYY}
              value={selectedDate}
              onChange={onDateChange}
              className={`${classes.datePickerRoot} datepicker-root`}
              {...otherProps}
            />
          </Box>
        </Popover>
      </MuiPickersUtilsProvider>
    </>
  );
};

CustomDatePicker.propTypes = {
  onChange: PropTypes.func,
  selectedDateProp: PropTypes.object,
};

CustomDatePicker.defaultProps = {
  selectedDateProp: new Date(),
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    color: theme.palette.grey[600],
  },
  datepickerPaper: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  datePickerRoot: {
    pointerEvents: `none`,
  },
  iconRoot: {
    pointerEvents: `none`,
  },
  boxRoot: {
    color: theme.palette.grey[500],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `solid 1px ${theme.palette.grey[200]}`,
    minWidth: 180,
    height: "100%",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    padding: 8,
    cursor: `pointer`,
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
    "@media only screen and (max-width: 600px)": {
      padding: "4px 10px",
      marginBottom: 5,
    },
  },
  boxRootSpaceBetween: {
    justifyContent: "space-between",
  },
  boxRootCenter: {
    justifyContent: "center",
  },
}));

CustomDatePicker.propTypes = {
  onChange: PropTypes.func,
  isUseEffect: PropTypes.bool,
  includeIcon: PropTypes.bool,
  displayDate: PropTypes.any,
  inputBox: PropTypes.any,
  iconClass: PropTypes.any,
  datePickerDialog: PropTypes.any,
};

CustomDatePicker.defaultProps = {
  isUseEffect: false,
  includeIcon: true,
};

const localeMap = {};

const months = [
  "Tháng 01",
  "Tháng 02",
  "Tháng 03",
  "Tháng 04",
  "Tháng 05",
  "Tháng 06",
  "Tháng 07",
  "Tháng 08",
  "Tháng 09",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];

const days = ["CN", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"];

const customVILocal = {
  localize: {
    day: (dirtyIndex, dirtyOptions) => {
      let strDay = days[dirtyIndex] || "";
      let options = dirtyOptions || {};
      if (options.width && "short" === options.width && strDay.length > 0) {
        return strDay.charAt(0) + strDay.charAt(strDay.length - 1);
      }
      return strDay;
    },
    month: n => months[n],
  },
};

localeMap[LangConstant.EN_LANG] = enLocale;
localeMap[LangConstant.DEFAULT_LANG] = { ...viLocale, ...customVILocal };

export default memo(CustomDatePicker);
