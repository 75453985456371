import React, { memo, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CropFree, ChevronRight, ChevronLeft, FullscreenExit, Add } from "@material-ui/icons";
import { Box, Button, useTheme, Paper, Container, useMediaQuery, makeStyles, Typography } from "@material-ui/core";
import { Day, ExportFile } from "./preview-components";
import { CustomDatePicker, SearchInPeriod, Processing } from "components";
import { LangConstant } from "const";
import CustomSearchBar from "pages/MnNews/components/CustomSearchBar";
import { getCommonKey, onDoNothing } from "utils";
import { FilterIcon } from "icons";
import clsx from "clsx";

const ReservationPreview = ({ data, dataSetting, consumerCapacity }) => {
  const shopInfoRedux = useSelector(state => state.shopInfoRedux);
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const classes = useStyles();

  const exportBtnRef = useRef();
  const fullScreenBtnRef = useRef();

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const selectedDate = new Date();

  return (
    <Paper elevation={1} square className="mn-reservation-root">
      <Container maxWidth="lg" className={clsx("container", classes.container)}>
        <Box classes={{ root: "header" }}>
          <Box classes={{ root: "header-left" }}>
            <CustomDatePicker onChange={onDoNothing} selectedDateProp={selectedDate} isUseEffect={true} />
            <SearchInPeriod onChange={onDoNothing} className={classes.optionViewDesktop} />
            <CustomSearchBar
              placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
              className={classes.searchBarDesktop}
              onChange={onDoNothing}
            />
            <Button
              classes={{
                root: classes.filterButton,
                label: classes.filterBtnLabel,
              }}
              variant="outlined"
              onClick={onDoNothing}
            >
              <FilterIcon className={classes.filterIcon} />
              <Typography className={`${classes.filterText} semiBold-md-txt`}>
                {getLabel(LangConstant.TXT_FILTER)}
              </Typography>
            </Button>
          </Box>
          <Box className={classes.searchBarContainerMediaSmall}>
            <SearchInPeriod onChange={onDoNothing} className={classes.optionViewMobile} />
            <CustomSearchBar
              placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
              className={classes.searchBarMobileSmall}
              onChange={onDoNothing}
            />
          </Box>
          <Box className={classes.additionalFunctionContainer}>
            <Box ref={exportBtnRef} onMouseEnter={onDoNothing} onMouseLeave={onDoNothing}>
              <ExportFile onExportFile={onDoNothing} extraClass={classes.exportButton} />
            </Box>
            {isNotMobile && (
              <Button
                className={classes.fullScreenBtn}
                onClick={onDoNothing}
                ref={fullScreenBtnRef}
                onMouseEnter={onDoNothing}
                onMouseLeave={onDoNothing}
              >
                {!document.fullscreenElement ? <CropFree /> : <FullscreenExit />}
              </Button>
            )}

            {isNotMobile && (
              <Button
                variant="contained"
                color="primary"
                classes={{ root: classes.buttonAdd, startIcon: classes.startIcon }}
                startIcon={<Add style={{ fontSize: 20 }} />}
              >
                <Typography
                  variant="body2"
                  classes={{ body2: "medium-md-txt" }}
                  style={{ color: theme.palette.white }}
                  className={classes.textBtn}
                >
                  {getLabel(getCommonKey(LangConstant.TXT_ADD))}
                </Typography>
              </Button>
            )}
          </Box>
        </Box>

        <Box position="relative">
          <Button
            variant="contained"
            classes={{ root: "button-left", startIcon: "start-icon" }}
            startIcon={<ChevronLeft style={{ color: theme.palette.white, fontSize: 40 }} />}
            onClick={onDoNothing}
          />
          <Button
            variant="contained"
            classes={{ root: "button-right", startIcon: "start-icon" }}
            startIcon={<ChevronRight style={{ color: theme.palette.white, fontSize: 40 }} />}
            onClick={onDoNothing}
          />
          <Box classes={{ root: "box-wrap-calendar" }}>
            <Day
              dataSetting={dataSetting}
              selectedDate={selectedDate}
              data={data}
              htmlId={HTML_CONTAINER_ID}
              reservationBoxId={RESERVATION_BOX_ID}
              consumerCapacity={consumerCapacity}
            />
          </Box>
        </Box>
      </Container>
      {shopInfoRedux.isFetching && <Processing isShow={true} />}
    </Paper>
  );
};

ReservationPreview.propTypes = {
  consumerCapacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ReservationPreview.defaultProps = { consumerCapacity: 100 };

export default memo(ReservationPreview);

export const HTML_CONTAINER_ID = "reserveRoot";
export const RESERVATION_BOX_ID = "reservationBox";
export const WIDTH_COLUMN = 175;

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: 20,
  },
  searchBarDesktop: {
    margin: "0 9px",
    width: "auto",
    height: 30,
    "@media only screen and (max-width: 700px)": {
      display: "none",
    },
  },
  searchBarMobileMedium: {
    margin: "0 9px",
    width: "auto",
    height: 30,
    display: "none",
    "@media only screen and (max-width: 700px)": {
      display: "flex",
      height: 30,
      marginLeft: 0,
    },
    "@media only screen and (max-width: 550px)": {
      display: "none",
      height: 30,
    },
  },
  searchBarMobileSmall: {
    display: "none",
    height: 30,
    "@media only screen and (max-width: 549px)": {
      display: "flex",
      height: 30,
      marginLeft: 9,
    },
  },
  fullScreenBtn: {
    height: 30,
    width: 30,
    border: "solid 1px #ebeced",
    color: theme.palette.grey[600],
    minWidth: "unset",
  },
  additionalFunctionContainer: {
    display: "flex",
    height: "fit-content",
    "@media only screen and (max-width: 700px)": {
      display: "flex",
      margin: "5px 0",
    },
  },
  exportButton: {
    height: 30,
    "@media only screen and (max-width: 600px)": {
      marginLeft: 0,
    },
  },
  optionViewDesktop: {
    height: 30,
    "@media only screen and (max-width: 550px)": {
      display: "none",
    },
  },
  optionViewMobile: {
    display: "none",
    "@media only screen and (max-width: 550px)": {
      display: "flex",
      height: 30,
    },
  },
  searchBarContainerMediaSmall: {
    display: "none",
    "@media only screen and (max-width: 700px)": {
      display: "flex",
      marginBottom: 5,
    },
  },
  settingButton: {
    borderRadius: 0,
    width: 30,
    height: 30,
    backgroundColor: theme.palette.white,
    margin: 0,
    border: "solid 1px " + theme.palette.grey["200"],
    textTransform: "none",
    padding: 0,
    minWidth: "unset",
    "&>*>svg": {
      width: "75%",
      height: "75%",
      border: "2px solid #343434",
    },
  },
  filterButton: {
    width: 65,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    padding: 0,
    "&:hover": {
      backgroundColor: "#3e4045",
      color: "#fff",
      "&>span>p": {
        color: "#fff",
      },
    },
  },
  filterButtonSelected: {
    width: 65,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    padding: 0,
    backgroundColor: "#3e4045",
    color: "#fff",
    "&>span>p": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#fff",
      color: "#3e4045",
      "&>span>p": {
        color: "#3e4045",
      },
    },
  },
  filterText: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  filterIcon: {
    width: 20,
    height: 20,
  },
  filterBtnLabel: {
    height: "100%",
  },
  buttonAdd: {
    borderRadius: 2,
    minWidth: 91,
    padding: 0,
    minHeight: 30,
    backgroundColor: "#ef5845",
    border: "unset",
    color: theme.palette.white,
    textTransform: "none",
    marginLeft: 8,
    "@media only screen and (max-width: 900px)": {
      width: 54,
      height: 54,
      boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.3)",
      backgroundColor: "#f54e5e",
      borderRadius: "50%",
      position: "fixed",
      bottom: 50,
      right: 21,
      border: "none",
      minWidth: 50,
      zIndex: 1000,
      "&:hover": {
        backgroundColor: "#f54e5e",
      },
    },
  },
  startIcon: {
    marginLeft: "unset !important",
    marginRight: 4,
    color: theme.palette.white,
    "@media only screen and (max-width: 900px)": {
      marginRight: "unset !important",
      fontSize: 28,
    },
  },
  textBtn: {
    "@media only screen and (max-width: 900px)": {
      display: "none",
    },
  },
}));
