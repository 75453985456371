import React, { isValidElement, memo, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  OutlinedInput,
  useMediaQuery,
  Link,
  Tooltip,
} from "@material-ui/core";
import { CreateOutlined } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { CopyIcon } from "icons";
import { LangConstant } from "const";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getLabel } from "language";

const CustomInfo = ({
  label,
  value,
  name,
  isCanEdit,
  inputProps,
  labelClass,
  enableEdit: isEdit,
  onEnableEdit,
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isShortLink = Boolean(inputProps?.href);
  const eventProps =
    isCanEdit && isDesktop
      ? {
          onMouseEnter: () => setIsShowIcon(true),
          onMouseLeave: () => setIsShowIcon(false),
        }
      : {};

  const [isShowIcon, setIsShowIcon] = useState(false);

  return (
    <>
      <Grid item xs={12} sm={4} className={`${classes.inline} ${labelClass}`}>
        <Typography
          variant="body1"
          classes={{ body1: isLargeScreen ? "medium-md-txt" : "bold-md-txt" }}
          color="inherit"
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} className={classes.inline} {...eventProps}>
        {isEdit ? (
          <InfoEdit text={value} inputProps={inputProps} name={name} {...otherProps} />
        ) : isShortLink ? (
          <InfoLink
            text={value}
            isShowIcon={isShowIcon}
            onEdit={onEnableEdit}
            inputProps={inputProps}
            {...otherProps}
          />
        ) : isValidElement(value) ? (
          value
        ) : (
          <InfoText text={value} isShowIcon={isShowIcon} onEdit={onEnableEdit} {...otherProps} />
        )}
      </Grid>
    </>
  );
};
CustomInfo.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired, PropTypes.node]),
  isCanEdit: PropTypes.bool,
  inputProps: PropTypes.object,
  labelClass: PropTypes.string,
};
CustomInfo.defaultProps = {
  isCanEdit: true,
  labelClass: "",
};
export default memo(CustomInfo);

export const InfoText = props => {
  const { isShowIcon, text, onEdit } = props;
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1" classes={{ body1: `medium-md-txt ${classes.infoText}` }} color="inherit">
        {text}
      </Typography>
      {isShowIcon && (
        <IconButton aria-label="edit" color="inherit" size="small" onClick={onEdit}>
          <CreateOutlined color="inherit" className={classes.icon} />
        </IconButton>
      )}
    </>
  );
};

const InfoEdit = props => {
  const { text, name, inputProps } = props;
  const classes = useStyles();
  return (
    <OutlinedInput
      // fullWidth
      type="text"
      value={text}
      name={name}
      classes={{
        input: classes.input,
        root: classes.inputRoot,
        multiline: classes.multiline,
        inputMultiline: classes.inputMultiline,
      }}
      {...inputProps}
    />
  );
};

export const InfoLink = props => {
  const { isShowIcon, text, onEdit, inputProps, shortenedSocialLink } = props;
  const classes = useStyles();
  const hasContent = Boolean(text);

  return (
    <>
      <Link href={inputProps?.href} className={`${classes.linkBooking} medium-md-txt`}>
        {shortenedSocialLink}
      </Link>
      {hasContent && (
        <CopyToClipboard text={inputProps?.href}>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title={
              <Typography className={`${classes.popoverCopyIcon} medium-sm-txt`}>
                {getLabel(LangConstant.TXT_COPY)}
              </Typography>
            }
          >
            <IconButton variant="outlined" size="small">
              <CopyIcon className={classes.copyIcon} />
            </IconButton>
          </Tooltip>
        </CopyToClipboard>
      )}

      {isShowIcon && (
        <IconButton aria-label="edit" color="inherit" size="small" onClick={onEdit} className={classes.editButton}>
          <CreateOutlined color="inherit" className={classes.icon} />
        </IconButton>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  tooltip: {
    width: 70,
    height: 16,
    flexGrow: 0,
    margin: "2px 0",
    borderRadius: 1,
    backgroundColor: "#7f838c",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 50,
  },
  inline: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: "38px",
    },
  },
  icon: {
    fontSize: theme.typography.body1.fontSize,
    opacity: "0.5",
    color: theme.palette.grey[400],
  },
  inputRoot: {
    width: 630,
    paddingLeft: 0,
    borderRadius: "unset",
    marginBottom: "0",
  },
  input: { padding: "5px 10px", height: 20 },
  multiline: { padding: "inherit" },
  inputMultiline: { height: "auto" },
  infoText: { whiteSpace: "pre-line" },
  editButton: {
    minWidth: 17.7,
  },
  linkBooking: {
    objectFit: "contain",
    color: "#447aff",
  },
  copyIcon: {
    width: 15.8,
    height: 18.3,
  },
  popoverCopyIcon: {
    width: 54,
    height: 20,
    flexGrow: 0,
    lineHeight: 1.67,
    color: "#ffffff",
  },
}));
