import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { AppConstant, LangConstant } from "const";

const Status = ({ status }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  const STATUS_OBJECT = {
    [AppConstant.STATUS_PAYMENT.paid]: {
      classes: classes.paid,
      name: getLabel(LangConstant.TXT_STATUS_PAID),
    },
    [AppConstant.STATUS_PAYMENT.unPaid]: {
      classes: classes.unPaid,
      name: getLabel(LangConstant.TXT_STATUS_UN_PAID),
    },
    [AppConstant.STATUS_PAYMENT.canceled]: {
      classes: classes.canceled,
      name: getLabel(LangConstant.TXT_STATUS_CANCELED),
    },
  };

  return (
    <Box className={clsx("center-root", classes.root, STATUS_OBJECT[status].classes)}>
      <Typography className={clsx("semiBold-sm-txt", classes.textStatus)}>{STATUS_OBJECT[status].name}</Typography>
    </Box>
  );
};

Status.propTypes = {
  status: PropTypes.number,
};
Status.defaultProps = {
  status: AppConstant.STATUS_PAYMENT.unPaid,
};

export default Status;

const useStyles = makeStyles(theme => ({
  root: {
    width: 105,
    height: 30,
    borderRadius: 4,
  },
  paid: {
    backgroundColor: "#46cf70",
  },
  unPaid: {
    backgroundColor: theme.palette.primary.main,
  },
  canceled: {
    backgroundColor: "#3e4045",
  },
  textStatus: {
    color: theme.palette.white,
    lineHeight: 1.67,
  },
}));
