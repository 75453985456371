import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Box,
  Paper,
  MenuItem,
  Popper,
  MenuList,
  ClickAwayListener,
  Grow,
  IconButton,
  Typography,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { LangConstant } from "../../../const";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MnNewsAction from "../../../redux/mn-news.redux";
import { AppConstant, ApiConstant } from "../../../const";
import { getSortName } from "../../../utils";
import Cookie from "js-cookie";

const PostHeader = props => {
  const classes = useStyles();
  const { time, uid, showData, onEditHandler } = props;
  const [open, setOpen] = useState(false);
  const [delAlert, openDelAlert] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  const role = Cookie.get(AppConstant.ROLE);
  const onHandleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const onHandleCloseMenu = () => {
    setOpen(false);
    openDelAlert(true);
  };
  const onDelPost = () => {
    dispatch(MnNewsAction.removeNews({ uid: uid }));
    openDelAlert(false);
  };
  const handleClose = () => {
    openDelAlert(false);
  };
  function onHandleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Box className={classes.postHeader}>
      <Box className={classes.headerWrap}>
        <Avatar
          src={
            !showData[AppConstant.KEY_SHOP_AVATA]
              ? ""
              : `${ApiConstant.HOST_ATTACHMENT}${showData[AppConstant.KEY_SHOP_AVATA]}`
          }
          className={classes.avtImage}
        >
          {showData[AppConstant.KEY_SHOP_AVATA] ? "" : getSortName(showData[AppConstant.KEY_SHOP_NAME])}
        </Avatar>
        <Box className={classes.titleTime}>
          <Typography component="p" className={classes.titleStyle}>
            {showData.name ? showData.name : ""}
          </Typography>
          <Typography component="p" className={classes.postTime}>
            {time}
          </Typography>
        </Box>
      </Box>
      {AppConstant.SHOP_STAFF !== role && (
        <IconButton
          aria-label="settings"
          className={classes.postSettingIcon}
          ref={anchorRef}
          aria-controls={open && "menu-list-grow"}
          aria-haspopup="true"
          onClick={onHandleToggle}
        >
          <MoreHoriz className={classes.settingIcon} />
        </IconButton>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal={true}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={onHandleListKeyDown}>
                  <MenuItem
                    onClick={v => {
                      onEditHandler(v);
                      setOpen(false);
                    }}
                    className={classes.menuOption}
                  >
                    {getLabel(LangConstant.TXT_NEWS_POSTLIST_POSTEDITBTN)}
                  </MenuItem>
                  <MenuItem className={classes.menuOption} onClick={() => onHandleCloseMenu()}>
                    {getLabel(LangConstant.TXT_NEWS_POSTLIST_POSTDELETEBTN)}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        open={delAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.DelDialog }}
      >
        <DialogTitle id="alert-dialog-title">{getLabel(LangConstant.TXT_DEL_POST)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.DialogTitle}>
            {getLabel(LangConstant.TXT_POST_DEL_CONFIRM_DIALOG)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <Button onClick={handleClose} className={classes.ActionBtns}>
            {getLabel(LangConstant.TXT_SHOP_CANCEL)}
          </Button>
          <Button
            onClick={() => {
              onDelPost();
            }}
            autoFocus
            className={classes.ActionBtns}
          >
            {getLabel(LangConstant.TXT_CONFIRM)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PostHeader;

const useStyles = makeStyles(theme => ({
  postHeader: {
    display: "flex",
    marginBottom: "1rem",
    justifyContent: "space-between",
  },
  postTime: {
    color: "#7f838c",
    lineHeight: "1.67",
    fontSize: "12px",
  },
  titleStyle: {
    fontWeight: theme.typography.subtitle2.fontWeight,
    color: "#3e4045",
    lineHeight: "1.5",
    fontSize: theme.typography.subtitle2.fontSize,
  },
  avtImage: {
    borderRadius: "50%",
    width: "48px",
    height: "48px",
    marginRight: "1rem",
  },
  titleTime: {
    textAlign: "left",
  },
  postSettingIcon: {
    float: "top",
    height: "40px",
    textAlign: "right",
    marginTop: "-1.5%",
  },
  headerWrap: {
    display: "flex",
  },
  menuOption: {
    "&:hover": {
      backgroundColor: "#3b404c",
      color: "white",
    },
  },
  DialogActions: {
    borderTop: "1px solid #ebeced",
    paddingRight: "2vw",
  },
  ActionBtns: {
    color: "#9d9fa5",
  },
  DialogTitle: {
    color: "#6c7078",
    fontSize: "13px",
    height: "80px",
    display: "flex",
    alignItems: "center",
  },
  DelDialog: {
    minHeight: "188px",
    width: "30%",
    "@media only screen and (max-width: 660px)": {
      width: "auto",
    },
  },
}));
