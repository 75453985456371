import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";
import PropTypes from "prop-types";

const SearchInPeriod = props => {
  const { onChange, style, className, optionViewSelected, buttonTitles, btnClass } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [alignment, setAlignment] = useState(0);
  const [arrayButtonTiles, setArrayButtonTiles] = useState([]);

  const onPeriodChange = (event, newPeriod) => {
    if (newPeriod !== null) {
      setAlignment(newPeriod);
      onChange(newPeriod);
    }
  };

  useEffect(() => {
    setAlignment(optionViewSelected);
  }, [optionViewSelected]);

  useEffect(() => {
    let newArrayTitles = buttonTitles || LangConstant.ARR_PERIOD_SEARCH_RESERVATION;
    if (Array.isArray(newArrayTitles) && newArrayTitles !== arrayButtonTiles) {
      setArrayButtonTiles(newArrayTitles);
    }
  }, [buttonTitles]);

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={onPeriodChange}
      aria-label="Period"
      className={`${classes.rootGroup} ${btnClass}`}
      style={style}
    >
      {arrayButtonTiles.map((period, index) => (
        <ToggleButton
          value={index}
          aria-label="left aligned"
          className={`medium-md-txt ${className}`}
          classes={{ root: classes.rootButton }}
          key={index}
          size="small"
        >
          {getLabel(period)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles(theme => ({
  rootGroup: {
    height: "fit-content",
  },
  rootButton: {
    width: 59,
    textTransform: "none",
    color: theme.palette.grey[600],
    marginRight: 2,
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[200]} !important`,
    padding: theme.spacing(0.5, 1),
    "&.Mui-selected": {
      color: theme.palette.white,
      backgroundColor: `${theme.palette.grey[700]} !important`,
    },
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

SearchInPeriod.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  optionViewSelected: PropTypes.number,
  buttonTitles: PropTypes.array,
};

SearchInPeriod.defaultProps = {
  style: {},
  className: "",
  optionViewSelected: 0,
};

export default memo(SearchInPeriod);
