import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import ReservationTimeItem from "./ReservationTimeItem";
import { onDoNothing } from "utils";

const ReservationTime = ({ usingTimeSetting }) => {
  return (
    <Grid container spacing={2}>
      <ReservationTimeItem data={usingTimeSetting} setIsChangingDateState={onDoNothing} />
    </Grid>
  );
};

ReservationTime.propTypes = {
  isEnableScheduleAction: PropTypes.bool,

  onClosePeriodicForm: PropTypes.func,
};

ReservationTime.defaultProps = {};

export default memo(ReservationTime);
