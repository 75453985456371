import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getPlaceCategory: ["data"],
  getPlaceCategorySuccess: ["data"],
  getPlaceCategoryFailure: ["data"],

  createPlaceCategory: ["data"],
  createPlaceCategorySuccess: ["data"],
  createPlaceCategoryFailure: ["data"],

  createPlaceCategoryItem: ["data"],
  createPlaceCategoryItemSuccess: ["data"],
  createPlaceCategoryItemFailure: ["data"],

  removePlaceCategory: ["data"],
  removePlaceCategorySuccess: ["data"],
  removePlaceCategoryFailure: ["data"],

  updatePlaceCategory: ["data"],
  updatePlaceCategorySuccess: ["data"],
  updatePlaceCategoryFailure: ["data"],

  getPlaceDialogData: ["data"],

  placeCategorySuccess: ["data"],
  placeCategoryFailure: ["data"],
});

export const PlaceCategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  data: null,
  placeArray: [],
  //create
  isCreateFetching: false,
  errorCreate: null,
  isCreateSuccess: false,
  //remove
  isRemoveFetching: false,
  errorRemove: null,
  isRemoveSuccess: false,
  //update
  isUpdateFetching: false,
  errorUpdate: null,
  isUpdateSuccess: false,

  // Data on place dialog
  placeDialog: {
    empty: [],
    goingToUse: [],
    active: [],
  },

  timeRangeFiltering: {
    start: 0,
    end: 0,
  },
};

/* ------------- Reducers Get Place category ------------- */
export const getPlaceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getPlaceCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    data: action.data.jsonData,
    placeArray: action.data.arrayData,
  };
};

export const getPlaceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

/* ------------- Reducers Create Place category ------------- */
export const createPlaceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
});

export const createPlaceCategorySuccess = (state = INITIAL_STATE, action) => {
  const newData = Object.keys(action.data).map(key => action.data[key]);
  const placeAdded = { ...state.data };
  placeAdded[newData[0].uid] = newData[0];
  return {
    ...state,
    isCreateFetching: false,
    isCreateSuccess: true,
    data: placeAdded,
  };
};

export const createPlaceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isCreateSuccess: false,
  errorUpdate: action.error,
});

/* ------------- Reducers Create Place category item ------------- */
export const createPlaceCategoryItem = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
});

export const createPlaceCategoryItemSuccess = (state = INITIAL_STATE, action) => {
  const items = action.data.items;
  const uid = action.data.categoryUid;
  let stateData = state.data;
  stateData[uid].items = items;

  return {
    ...state,
    isCreateFetching: false,
    isCreateSuccess: true,
    data: stateData,
  };
};

export const createPlaceCategoryItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isCreateSuccess: false,
  errorUpdate: action.error,
});

/* ------------- Reducers Remove Place category ------------- */
export const removePlaceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetching: true,
});

export const removePlaceCategorySuccess = (state = INITIAL_STATE, action) => {
  let placeDeleted = { ...state.data };
  delete placeDeleted[action.data.uid];
  return {
    ...state,
    isRemoveFetching: false,
    isRemoveSuccess: true,
    data: placeDeleted,
  };
};

export const removePlaceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetching: false,
  isRemoveSuccess: false,
  errorRemove: action.error,
});

export const updatePlaceCategory = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updatePlaceCategorySuccess = (state = INITIAL_STATE, action) => {
  let targetUpdate = { ...state.data };
  const updateData = action.data;
  targetUpdate[updateData.uid].name = updateData.name;
  return {
    ...state,
    isUpdateFetching: false,
    isUpdateSuccess: true,
    dataUpdatePlaceCategory: targetUpdate,
  };
};

export const updatePlaceCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isUpdateSuccess: false,
  errorUpdate: action.error,
});

export const fetching = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: true,
  ...action.data,
});

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  errorUpdate: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_PLACE_CATEGORY]: getPlaceCategory,
  [Types.GET_PLACE_CATEGORY_SUCCESS]: getPlaceCategorySuccess,
  [Types.GET_PLACE_CATEGORY_FAILURE]: getPlaceCategoryFailure,

  [Types.CREATE_PLACE_CATEGORY]: createPlaceCategory,
  [Types.CREATE_PLACE_CATEGORY_SUCCESS]: createPlaceCategorySuccess,
  [Types.CREATE_PLACE_CATEGORY_FAILURE]: createPlaceCategoryFailure,

  [Types.CREATE_PLACE_CATEGORY_ITEM]: createPlaceCategoryItem,
  [Types.CREATE_PLACE_CATEGORY_ITEM_SUCCESS]: createPlaceCategoryItemSuccess,
  [Types.CREATE_PLACE_CATEGORY_ITEM_FAILURE]: createPlaceCategoryItemFailure,

  [Types.REMOVE_PLACE_CATEGORY]: removePlaceCategory,
  [Types.REMOVE_PLACE_CATEGORY_SUCCESS]: removePlaceCategorySuccess,
  [Types.REMOVE_PLACE_CATEGORY_FAILURE]: removePlaceCategoryFailure,

  [Types.UPDATE_PLACE_CATEGORY]: updatePlaceCategory,
  [Types.UPDATE_PLACE_CATEGORY_SUCCESS]: updatePlaceCategorySuccess,
  [Types.UPDATE_PLACE_CATEGORY_FAILURE]: updatePlaceCategoryFailure,

  [Types.GET_PLACE_DIALOG_DATA]: fetching,
  [Types.PLACE_CATEGORY_SUCCESS]: success,
  [Types.PLACE_CATEGORY_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
