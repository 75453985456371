import { call, put } from "redux-saga/effects";
import { ApiConstant } from "const";
import ItemDetailsAction from "redux/item-details.redux";
import { ItemDetailsService } from "services";
import { toCamel, toSnake } from "utils";

export function* postItemDetails(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsService.postItemDetails, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsAction.postItemDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsAction.postItemDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsAction.postItemDetailsFailure(error));
  }
}

export function* putItemDetails(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsService.putItemDetails, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsAction.putItemDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsAction.putItemDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsAction.putItemDetailsFailure(error));
  }
}

export function* getListItemDetails(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsService.getListItemDetails, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsAction.getListItemDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsAction.getListItemDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(ItemDetailsAction.getListItemDetailsFailure(error));
  }
}

export function* getItemDetailsByUid(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsService.getItemDetailsByUid, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsAction.getItemDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsAction.getItemDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsAction.getItemDetailsFailure(error));
  }
}

export function* deleteItemDetails(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsService.deleteItemDetails, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsAction.deleteItemDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsAction.deleteItemDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsAction.deleteItemDetailsFailure(error));
  }
}

export function* putEditContent(action) {
  try {
    const { data } = action;
    let response = yield call(ItemDetailsService.putEditContent, toSnake(data));
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ItemDetailsAction.putEditContentSuccess(toCamel(responseData)));
    } else {
      yield put(ItemDetailsAction.putEditContentFailure(response.data));
    }
  } catch (error) {
    yield put(ItemDetailsAction.putEditContentFailure(error));
  }
}
