import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Button, Typography, makeStyles, DialogTitle } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant } from "const";

const DialogVerify = ({ isShow, title, content, onCancel, onConfirm, classes }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Dialog open={isShow} classes={{ paper: defaultClasses.paperDialog }} onBackdropClick={onCancel}>
      <DialogTitle className={clsx(defaultClasses.dialogTitle, classes?.title)}>
        <Typography classes={{ body1: defaultClasses.textDialogTitle }}>{title}</Typography>
      </DialogTitle>
      <DialogContent className={clsx(defaultClasses.dialogContent, classes?.content)}>
        <Typography className={defaultClasses.textDialogContent}>{content}</Typography>
      </DialogContent>
      <DialogActions className={defaultClasses.dialogActions}>
        <Button className={clsx(defaultClasses.button, classes?.cancelButton)} onClick={onCancel}>
          {getLabel(LangConstant.TXT_SHOP_CANCEL)}
        </Button>
        <Button className={clsx(defaultClasses.button, classes?.confirmButton)} onClick={onConfirm}>
          {getLabel(LangConstant.TXT_CONFIRM)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogVerify.propTypes = {
  isShow: PropTypes.bool,

  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,

  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,

  classes: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    cancelButton: PropTypes.string,
    confirmButton: PropTypes.string,
  }),
};

export default memo(DialogVerify);

const useStyles = makeStyles(theme => ({
  paperDialog: {
    width: 470,
    minHeight: 188,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 0,
  },
  dialogTitle: {
    padding: "24px 24px 16px",
  },
  textDialogTitle: {
    fontSize: 20,
    color: "#3b404c",
  },
  dialogContent: {
    padding: "0 24px 24px",
    display: "flex",
    flexDirection: "column",
  },
  textDialogContent: {
    fontSize: 13,
    color: "#6c7078",
  },
  dialogActions: {
    height: 53,
    borderTop: "1px solid #ebeced",
    padding: "15px 35px",
  },
  button: {
    color: "#9d9fa5",
    fontSize: 14,
    fontWeight: 600,
  },
}));
