import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  Box,
} from "@material-ui/core";
import { AppConstant, LangConstant } from "../../../../const";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";

const DialogAdjust = props => {
  const { titleName, open, setOpen, headData, displayColumn, setDisplayColumn, showDefault } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const [map, setMap] = useState(displayColumn);
  const [checkedDefault, setCheckedDefault] = useState(true);
  const [checkedAll, setCheckedAll] = useState(false);

  const onSelect = (e, index) => {
    let checked = e.target.checked;
    if (checked) {
      let newArray = [...map, getLabel(headData[index])];
      setMap(newArray);
      if (checkedDefault === true && newArray !== showDefault) {
        setCheckedDefault(false);
      }
      if (checkedAll === true && newArray !== showDefault) {
        setCheckedAll(false);
      }
      return;
    }
    let newArray = map.filter(value => value !== getLabel(headData[index]));
    setMap(newArray);
    if (checkedDefault === true && newArray !== showDefault) {
      setCheckedDefault(false);
    }
    if (checkedAll === true && newArray !== showDefault) {
      setCheckedAll(false);
    }
  };

  const onSubmit = () => {
    setDisplayColumn(map);
    localStorage.setItem(AppConstant.KEY_DISPLAY_COLUMN, JSON.stringify(map));
    onClose();
  };

  const onSelectAll = e => {
    let checked = e.target.checked;
    if (checked) {
      let newArray = headData.map(value => (value = getLabel(value)));
      setMap(newArray);
      setCheckedAll(true);
      setCheckedDefault(false);
      return;
    }
    setMap([]);
    setCheckedAll(false);
  };

  const onSelectDefault = e => {
    let checked = e.target.checked;
    if (checked) {
      setMap(showDefault);
      setCheckedDefault(true);
      setCheckedAll(false);
      return;
    }
    setMap([]);
    setCheckedDefault(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullScreen classes={{ paper: classes.dialogPaper, paperFullScreen: classes.paperFullScreen }}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="body1" className={classes.textTitle}>
          {titleName}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.contentBodyHeader}>
          <Checkbox
            color="primary"
            onChange={onSelectDefault}
            checked={checkedDefault}
            className={classes.checkBoxStatus}
          />
          <Typography className={classes.contentTextHeader}>{getLabel(LangConstant.TXT_RETURN_DEFAULT)}</Typography>
        </Box>
        <Box className={classes.contentBody}>
          <Checkbox color="primary" onChange={onSelectAll} checked={checkedAll} className={classes.checkBoxStatus} />
          <Typography className={classes.contentTextHeader}>{getLabel(LangConstant.TXT_CHOOSE_ALL)}</Typography>
        </Box>
        {headData.map((data, index) => (
          <Box className={classes.contentBody} key={index}>
            <Checkbox
              onChange={e => onSelect(e, index)}
              checked={map.includes(getLabel(data))}
              color="primary"
              className={classes.checkBoxStatus}
            />
            <Typography className={classes.contentText}>{getLabel(data)}</Typography>
          </Box>
        ))}
      </DialogContent>
      <DialogActions className={classes.rootActions}>
        <Button className={classes.btnRoot} onClick={onSubmit} color="primary" size="medium" variant="contained">
          {getLabel(LangConstant.TXT_SAVE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: 375,
    borderRadius: 0,
    "@media only screen and (max-width: 400px)": {
      margin: 0,
      width: "100%",
    },
  },
  paperFullScreen: {
    height: "auto",
    "@media only screen and (max-width: 400px)": {
      height: "100%",
    },
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.white,
    padding: "0px 0px 0px 24px",
    backgroundColor: theme.palette.secondary.main,
  },
  textTitle: {
    marginLeft: "auto",
    color: "inherit",
  },
  contentBody: {
    display: "flex",
    marginLeft: 14,
    marginBottom: 28,
  },
  contentBodyHeader: {
    display: "flex",
    marginLeft: 14,
    marginBottom: 28,
    marginTop: 36,
  },
  contentText: {
    marginLeft: 26,
    fontSize: 14,
  },
  contentTextHeader: {
    marginLeft: 26,
    fontSize: 14,
    color: "#447aff",
  },
  rootActions: {
    justifyContent: "center",
    height: 77,
    borderTop: "solid 0.5px #b2b8c2",
    "@media only screen and (max-width: 400px)": {
      height: 100,
    },
  },
  closeButton: {
    marginLeft: "auto",
    color: "inherit",
  },
  btnRoot: {
    padding: "0 16px 0",
    minHeight: 30,
    fontWeight: "inherit",
    height: 30,
    minWidth: 74,
    borderRadius: 20,
    textTransform: "capitalize",
    color: "#ffffff",
    alignContent: "center",
  },
  checkBoxStatus: {
    padding: "0 0 0",
    width: 16,
    height: 16,
  },
}));

DialogAdjust.propTypes = {
  titleName: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

DialogAdjust.defaultProps = {};

export default memo(DialogAdjust);
