import { ApiConstant } from "../const";
import { createApiReservation } from "../api";
import { format } from "react-string-format";

export const get = data => createApiReservation().get(format(ApiConstant.GET_PLACE_ITEM, data.uid), {});

export const create = data => createApiReservation().post(ApiConstant.POST_PLACE_ITEM, data);

export const patch = data => createApiReservation().patch(ApiConstant.PATCH_PLACE_ITEM, data);

export const update = data => createApiReservation().put(format(ApiConstant.PUT_PLACE_ITEM, data.uid), data);

export const remove = data => createApiReservation().delete(format(ApiConstant.DELETE_PLACE_ITEM, data.uid), {});

export const getPlaceItemAutoFill = data => createApiReservation().get(ApiConstant.GET_PLACE_ITEM_AUTO_FILL, data);

export const getPlaceBusyStatus = data =>
  createApiReservation().get(ApiConstant.GET_PLACE_BUSY_STATUS, {
    start: data.start,
    end: data.end,
  });
