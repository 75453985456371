import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { AntSwitchPrimary, AntSwitchSecondary } from "./CustomizeSwitch";
import { useTranslation } from "react-i18next";
import { LangConstant, PathConstant } from "../../../const";
import { useDispatch, useSelector } from "react-redux";
import MnNewsAction from "../../../redux/mn-news.redux";
import { useHistory } from "react-router-dom";
import { format } from "react-string-format";

const PostFooter = props => {
  const classes = useStyles();
  const history = useHistory();
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  const pages = useSelector(state => state.shopRedux.facebookPage);
  const [stateGgSwitch, setGgSwitchState] = useState(props.publish === 1 ? true : false);
  const [stateFbSwitch, setFbSwitchState] = useState(props.fbID ? true : false);
  const [publishAlert, openSyncFbAlert] = useState(false);
  const [syncGboss, openSyncGbossAlert] = useState(false);
  const [openLinkFb, openLinkFbAlert] = useState(false);
  const [isLinked, setIsLinked] = useState(false);
  const [linkedPage, setLinkedPage] = useState();

  const onPublishToFb = () => {
    dispatch(
      MnNewsAction.updateNews({
        data: { post_to_facebook: !stateFbSwitch ? 1 : 0 },
        uid: props.uid,
      }),
    );
    openSyncFbAlert(false);
    setFbSwitchState(true);
  };
  const syncToGboss = () => {
    dispatch(
      MnNewsAction.updateNews({
        data: { publish: !stateGgSwitch ? 1 : 0 },
        uid: props.uid,
      }),
    );
    openSyncGbossAlert(false);
    setGgSwitchState(true);
  };
  useEffect(() => {
    if (pages) {
      for (let i = 0; i < pages.length; i++) {
        if (pages[i].state === 1) {
          setLinkedPage(pages[i].name);
          setIsLinked(true);
        }
      }
    } else {
      setLinkedPage("");
      setIsLinked(false);
    }
  }, [pages]);

  return (
    <Box className={classes.postFooterContainer}>
      <Box className={classes.postAction}>
        <Box className={classes.switches}>
          <AntSwitchPrimary
            checked={stateGgSwitch}
            onChange={() => {
              stateGgSwitch ? openSyncGbossAlert(true) : syncToGboss();
            }}
            name="ggSwitch"
            className={classes.toggleGg}
          />
          <Typography component="span" className={`${classes.toggleGg} regular-sm-txt`}>
            {getLabel(LangConstant.TXT_GBOSS)}
          </Typography>
          <AntSwitchSecondary
            checked={stateFbSwitch}
            disabled={stateFbSwitch}
            onChange={() => {
              isLinked ? openSyncFbAlert(true) : openLinkFbAlert(true);
            }}
            name="fbSwitch"
            className={classes.toggleFb}
          />
          <Typography component="span" className={`${classes.toggleFb} regular-sm-txt`}>
            {getLabel(LangConstant.TXT_FACEBOOK)}
          </Typography>
        </Box>
      </Box>

      <Dialog
        open={publishAlert}
        onClose={() => openSyncFbAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.SyncDialog }}
      >
        <DialogTitle id="alert-dialog-title">{getLabel(LangConstant.TXT_FACEBOOK_SYNC)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.SyncDialogTitle}>
            {format(getLabel(LangConstant.TXT_SYNC_CONFIRM_MESSAGE), linkedPage)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <Button onClick={() => openSyncFbAlert(false)} className={classes.SyncActionBtns}>
            {getLabel(LangConstant.TXT_SHOP_CANCEL)}
          </Button>
          <Button
            onClick={() => {
              onPublishToFb();
            }}
            autoFocus
            className={classes.SyncActionBtns}
          >
            {getLabel(LangConstant.TXT_SYNC_CONFIRM)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={syncGboss}
        onClose={() => openSyncGbossAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.SyncDialog }}
      >
        <DialogTitle id="alert-dialog-title">{getLabel(LangConstant.TXT_OFFSYNC_GBOSS)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.SyncDialogTitle}>
            {getLabel(LangConstant.TXT_OFFSYNC_CONFIRM_MESSAGE)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <Button onClick={() => openSyncGbossAlert(false)} className={classes.SyncActionBtns}>
            {getLabel(LangConstant.TXT_SHOP_CANCEL)}
          </Button>
          <Button
            onClick={() => {
              syncToGboss();
              setGgSwitchState(false);
            }}
            autoFocus
            className={classes.SyncActionBtns}
          >
            {getLabel(LangConstant.TXT_HIDE_BTN)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLinkFb}
        onClose={() => openLinkFbAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.SyncDialog }}
      >
        <DialogTitle id="alert-dialog-title">{getLabel(LangConstant.TXT_LINK_TO_FACEBOOK_CF)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.SyncDialogTitle}>
            {getLabel(LangConstant.TXT_LINK_FB_CONFIRM_MESSAGE)}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <Button onClick={() => openLinkFbAlert(false)} className={classes.SyncActionBtns}>
            {getLabel(LangConstant.TXT_SHOP_CANCEL)}
          </Button>
          <Button
            autoFocus
            className={classes.SyncActionBtns}
            onClick={() => history.push(PathConstant.MANAGE_SHOP_INFO)}
          >
            {getLabel(LangConstant.TXT_LINK)}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PostFooter;

const useStyles = makeStyles(() => ({
  postAction: {
    display: "flex",
    justifyContent: "flex-end",
  },
  switches: {
    display: "flex",
  },
  postFooterContainer: {
    marginTop: "1.5vh",
    textAlign: "left",
    justifyContent: "center",
  },
  toggleFb: {
    marginLeft: "1vh",
    marginTop: "3px",
    color: "#3e4045",
  },
  toggleGg: {
    marginRight: "0.5vw",
    marginTop: "3px",
    color: "#3e4045",
  },
  DialogActions: {
    borderTop: "1px solid #ebeced",
    paddingRight: "2vw",
  },
  SyncActionBtns: {
    color: "#9d9fa5",
  },
  SyncDialogTitle: {
    color: "#6c7078",
    fontSize: "13px",
    height: "80px",
    display: "flex",
    alignItems: "center",
  },
  SyncDialog: {
    minHeight: "188px",
    width: "30%",
    "@media only screen and (max-width: 660px)": {
      width: "auto",
    },
  },
}));
