import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  postItemDetailsContent: ["data"],
  postItemDetailsContentSuccess: ["data"],
  postItemDetailsContentFailure: ["data"],

  putItemDetailsContent: ["data"],
  putItemDetailsContentSuccess: ["data"],
  putItemDetailsContentFailure: ["data"],

  getItemDetailsContent: ["data"],
  getItemDetailsContentSuccess: ["data"],
  getItemDetailsContentFailure: ["data"],

  getListItemDetailsContent: ["data"],
  getListItemDetailsContentSuccess: ["data"],
  getListItemDetailsContentFailure: ["data"],

  deleteItemDetailsContent: ["data"],
  deleteItemDetailsContentSuccess: ["data"],
  deleteItemDetailsContentFailure: ["data"],

  resetItemDetailsContent: ["data"],
  resetErrorItemDetailsContent: ["data"],
});

export const ItemDetailsContentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  // post
  isPostItemDetailsContentFetching: false,
  errorPostItemDetailsContent: null,
  isPostItemDetailsContentSuccess: false,
  dataPostItemDetailsContent: null,

  // put
  isPutItemDetailsContentFetching: false,
  errorPutItemDetailsContent: null,
  isPutItemDetailsContentSuccess: false,
  dataPutItemDetailsContent: null,

  // get by Uid
  isGetItemDetailsContentFetching: false,
  errorGetItemDetailsContent: null,
  isGetItemDetailsContentSuccess: false,
  dataGetItemDetailsContent: null,

  // get list
  isGetListItemDetailsContentFetching: false,
  errorGetListItemDetailsContent: null,
  isGetListItemDetailsContentSuccess: false,
  dataGetListItemDetailsContent: null,

  // delete
  isDeleteItemDetailsContentFetching: false,
  errorDeleteItemDetailsContent: null,
  isDeleteItemDetailsContentSuccess: false,
  dataDeleteItemDetailsContent: null,
};

/* ------------- Reducers ------------- */
/* ------------- Reducers Post Item Details Content ------------- */
export const postItemDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isPostItemDetailsContentFetching: true,
});

export const postItemDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPostItemDetailsContentFetching: false,
    isPostItemDetailsContentSuccess: true,
    dataPostItemDetailsContent: action.data,
  };
};

export const postItemDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPostItemDetailsContentFetching: false,
  isPostItemDetailsContentSuccess: false,
  errorPostItemDetailsContent: action?.data?.message,
});

/* ------------- Reducers Put Item Details Content ------------- */
export const putItemDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isPutItemDetailsContentFetching: true,
});

export const putItemDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isPutItemDetailsContentFetching: false,
    isPutItemDetailsContentSuccess: true,
    dataPutItemDetailsContent: action.data,
  };
};

export const putItemDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isPutItemDetailsContentFetching: false,
  isPutItemDetailsContentSuccess: false,
  errorPutItemDetailsContent: action?.data?.message,
});

/* ------------- Reducers Get By Uid Item Details Content ------------- */
export const getItemDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isGetItemDetailsContentFetching: true,
});

export const getItemDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetItemDetailsContentFetching: false,
    isGetItemDetailsContentSuccess: true,
    dataGetItemDetailsContent: action.data,
  };
};

export const getItemDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetItemDetailsContentFetching: false,
  isGetItemDetailsContentSuccess: false,
  errorGetItemDetailsContent: action.error,
});

/* ------------- Reducers Get List Item Details Content ------------- */
export const getListItemDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isGetListItemDetailsContentFetching: true,
});

export const getListItemDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGetListItemDetailsContentFetching: false,
    isGetListItemDetailsContentSuccess: true,
    dataGetListItemDetailsContent: action.data,
  };
};

export const getListItemDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isGetListItemDetailsContentFetching: false,
  isGetListItemDetailsContentSuccess: false,
  errorGetListItemDetailsContent: action.error,
});

/* ------------- Reducers Delete Item Details Content ------------- */
export const deleteItemDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isDeleteItemDetailsContentFetching: true,
});

export const deleteItemDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isDeleteItemDetailsContentFetching: false,
    isDeleteItemDetailsContentSuccess: true,
    dataDeleteItemDetailsContent: action.data,
  };
};

export const deleteItemDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isDeleteItemDetailsContentFetching: false,
  isDeleteItemDetailsContentSuccess: false,
  errorDeleteItemDetailsContent: action.error,
});

/* ------------- Reducers Reset ------------- */
export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isPostItemDetailsContentSuccess: false,
  isPutItemDetailsContentSuccess: false,
  isGetItemDetailsContentSuccess: false,
  isGetListItemDetailsContentSuccess: false,
  isDeleteItemDetailsContentSuccess: false,
});

export const resetError = (state = INITIAL_STATE) => ({
  ...state,
  errorPutItemDetailsContent: null,
  errorPostItemDetailsContent: null,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  // Map Post Item Details Content
  [Types.POST_ITEM_DETAILS_CONTENT]: postItemDetailsContent,
  [Types.POST_ITEM_DETAILS_CONTENT_SUCCESS]: postItemDetailsContentSuccess,
  [Types.POST_ITEM_DETAILS_CONTENT_FAILURE]: postItemDetailsContentFailure,

  // Map Put Item Details Content
  [Types.PUT_ITEM_DETAILS_CONTENT]: putItemDetailsContent,
  [Types.PUT_ITEM_DETAILS_CONTENT_SUCCESS]: putItemDetailsContentSuccess,
  [Types.PUT_ITEM_DETAILS_CONTENT_FAILURE]: putItemDetailsContentFailure,

  // Map Get By Uid Item Details Content
  [Types.GET_ITEM_DETAILS_CONTENT]: getItemDetailsContent,
  [Types.GET_ITEM_DETAILS_CONTENT_SUCCESS]: getItemDetailsContentSuccess,
  [Types.GET_ITEM_DETAILS_CONTENT_FAILURE]: getItemDetailsContentFailure,

  // Map Get List Item Details Content
  [Types.GET_LIST_ITEM_DETAILS_CONTENT]: getListItemDetailsContent,
  [Types.GET_LIST_ITEM_DETAILS_CONTENT_SUCCESS]: getListItemDetailsContentSuccess,
  [Types.GET_LIST_ITEM_DETAILS_CONTENT_FAILURE]: getListItemDetailsContentFailure,

  // Map Delete Item Details Content
  [Types.DELETE_ITEM_DETAILS_CONTENT]: deleteItemDetailsContent,
  [Types.DELETE_ITEM_DETAILS_CONTENT_SUCCESS]: deleteItemDetailsContentSuccess,
  [Types.DELETE_ITEM_DETAILS_CONTENT_FAILURE]: deleteItemDetailsContentFailure,

  // Reset Item Details Content
  [Types.RESET_ITEM_DETAILS_CONTENT]: reset,
  [Types.RESET_ERROR_ITEM_DETAILS_CONTENT]: resetError,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
