import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Popover, Button, Box, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import StringFormat from "string-format";
import { LangConstant } from "const";

const PopupSuccess = props => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const { open, setOpen, data, lengthSuccess } = props;
  const onClose = () => {
    setOpen(false);
  };

  let errorInvitationNumber = lengthSuccess ? data.length - lengthSuccess : 0;

  return (
    <Popover open={open} anchorEl={document.body} marginThreshold={0} classes={{ paper: classes.paperProps }}>
      <Box className={classes.boxCloseParent}>
        <Box className={classes.boxCloseChildren}>
          <Button
            onClick={() => onClose()}
            classes={{
              root: classes.rootButton,
              label: classes.label,
              startIcon: classes.startIcon,
              iconSizeMedium: classes.iconSizeMedium,
            }}
            startIcon={<Close className={classes.rootIcon} />}
          />
        </Box>
      </Box>
      <Box className={classes.bodyRoot}>
        <Box className={classes.elementRoot}>
          <Typography variant="h4">
            {getLabel(LangConstant.FN_INVITE_SUCCESS)(lengthSuccess !== null ? lengthSuccess : 0, data.length)}
          </Typography>
        </Box>
        {errorInvitationNumber > 0 && (
          <Box className={classes.elementRoot}>
            <Typography variant="body2">
              {StringFormat(getLabel(LangConstant.FN_FAILURE_INVITATION), errorInvitationNumber)}
            </Typography>
          </Box>
        )}
        <Box className={classes.elementRoot}>
          <Button className={classes.rootBtnEmail} color="primary" size="medium" variant="contained" onClick={onClose}>
            {getLabel(LangConstant.TXT_INVITE_CONTINUE)}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
const useStyles = makeStyles(() => ({
  paperProps: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
  },
  boxCloseParent: {
    marginRight: 0,
    height: 50,
    marginTop: 20,
    maxWidth: "100%",
  },
  boxCloseChildren: {
    height: "100%",
    width: "20%",
    marginLeft: "80%",
  },
  rootButton: {
    width: 50,
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: 20,
  },
  rootIcon: {
    width: "100%",
    height: "100%",
  },
  label: {
    height: "100%",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  iconSizeMedium: {
    fontSize: 30,
  },
  rootBtnEmail: {
    marginTop: 50,
    padding: "0 16px 0",
    fontSize: 16,
    fontWeight: "inherit",
    height: 46,
    borderRadius: 25,
    minWidth: 136,
    textTransform: "capitalize",
    color: "#ffffff",
  },
  bodyRoot: {
    width: "60%",
    margin: "auto",
    paddingTop: "10%",
    "@media only screen and (max-width: 600px)": {
      width: "90%",
    },
  },
  elementRoot: {
    textAlign: "center",
    marginTop: 20,
  },
}));
export default memo(PopupSuccess);
