import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getRatingItem: ["data"],
  getRatingShop: ["data"],
  getRatingItemSummary: ["data"],
  getRatingShopSummary: ["data"],
  reportRating: ["data"],

  ratingSuccess: ["data"],
  ratingFailure: ["data"],
});

export const RatingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  isReportSuccess: false,
  ratingSummary: {},
  ratingList: [],
  pagination: {
    totalPage: 0,
    size: 10,
    total: 0,
    page: 1,
  },
};

/* ------------- Reducers ------------- */
export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    ...action.data,
  };
};

export const failure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, error: action.error };
};

export const reset = () => INITIAL_STATE;

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_RATING_ITEM]: request,
  [Types.GET_RATING_SHOP]: request,
  [Types.GET_RATING_ITEM_SUMMARY]: request,
  [Types.GET_RATING_SHOP_SUMMARY]: request,
  [Types.REPORT_RATING]: request,

  [Types.RATING_SUCCESS]: success,
  [Types.RATING_FAILURE]: failure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
