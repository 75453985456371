import { Box, Dialog, IconButton, makeStyles, Slide } from "@material-ui/core";
import React, { forwardRef, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import StaffDetailForm from "./StaffDetailForm";
import { useDispatch, useSelector } from "react-redux";
import StaffTableAction from "redux/staff.redux";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// eslint-disable-next-line no-unused-vars
const StaffDetail = forwardRef((props, ref) => {
  const classes = useStyles();
  const { responseData, isShow, setIsShow, user, showTime, showPosition, isEditing, setIsEditing } = props;
  const updateSuccess = useSelector(state => state.staffRedux.isSuccessEditStaff);
  const isSuccess = useSelector(state => state.staffRedux.isSuccess);
  const dispatch = useDispatch();
  const newWS = useSelector(state => state.staffRedux.newWorkShift);

  const onCloseDetailStaff = () => {
    setIsShow(false);
    setIsEditing(false);
  };
  useEffect(() => {
    if (updateSuccess) {
      setIsEditing(false);
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (isSuccess && newWS === {}) dispatch(StaffTableAction.requestWorkingShift({}));
  }, [isSuccess]);
  return (
    <>
      <Dialog fullScreen open={isShow} onClose={onCloseDetailStaff} TransitionComponent={Transition}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onCloseDetailStaff}
          aria-label="close"
          className={classes.closeDialogBtn}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <Box className={classes.staffDetailForm}>
          <StaffDetailForm
            responseData={responseData}
            isEditing={isEditing}
            user={user}
            showTime={showTime}
            showPosition={showPosition}
            setIsEditing={setIsEditing}
          />
        </Box>
      </Dialog>
    </>
  );
});
export default StaffDetail;
const useStyles = makeStyles(theme => ({
  closeDialogBtn: {
    alignSelf: "flex-end",
    width: "36px",
    height: "36px",
    marginTop: "3vh",
    marginRight: "3vw",
  },
  closeIcon: {},
  staffDetailForm: {
    textAlign: "center",
    minHeight: "80%",
    [theme.breakpoints.down("xs")]: {
      minHeight: "unset",
      textAlign: "center",
    },
  },
}));
