import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment, OutlinedInput, IconButton } from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import clsx from "clsx";

const SearchBar = ({
  placeholder,
  value,
  onChange,
  onKeyUp,
  onClear,
  enableClear,
  className,
  customClasses,
  width,
  ...otherProps
}) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(value);

  const onTyping = event => {
    if (Boolean(onChange)) {
      onChange(event.target.value);
    }
    setInputValue(event.target.value);
  };
  const onKeyUpInput = event => {
    if (Boolean(onKeyUp) && (event.key === "Enter" || event.keyCode === 13)) {
      onKeyUp(event.target.value);
    }
  };

  const onClearSearch = () => {
    setInputValue("");
    onClear();
  };

  return (
    <OutlinedInput
      style={{ width: width }}
      placeholder={placeholder}
      type="text"
      value={inputValue}
      onChange={onTyping}
      classes={{
        root: clsx(classes.root, className, customClasses?.backgroundColor),
        input: clsx(classes.input, "medium-md-txt", customClasses?.input),
        notchedOutline: classes.notchedOutline,
      }}
      startAdornment={
        <InputAdornment position="start" className={clsx(classes.icon, customClasses?.icon)}>
          <Search className={clsx(classes.searchIcon, customClasses?.searchIcon)} />
        </InputAdornment>
      }
      endAdornment={
        enableClear && (
          <InputAdornment position="end" className={classes.icon}>
            <IconButton onClick={onClearSearch}>
              <Close className={clsx(classes.closeIcon, customClasses?.closeIcon)} />
            </IconButton>
          </InputAdornment>
        )
      }
      onKeyUp={onKeyUpInput}
      {...otherProps}
    />
  );
};
const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    borderRadius: "unset",
    border: `1px solid ${theme.palette.grey["200"]}`,
  },
  notchedOutline: {
    border: "none",
  },
  input: {
    height: "unset",
    padding: "0 20px 0 0",
    color: theme.palette.text.primary,
    "&::placeholder": {
      color: theme.palette.grey["400"],
      opacity: 1,
    },
    "@media only screen and (max-width: 375px)": {
      padding: "0 10px",
    },
  },
  icon: {
    minWidth: "40px",
    width: "40px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "30px",
    justifyContent: "center",
    marginRight: 0,
    "@media only screen and (max-width: 375px)": {
      minWidth: "40px",
      width: "40px",
    },
  },
  searchIcon: {
    fontSize: 24,
    color: theme.palette.grey["400"],
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.grey["500"],
  },
}));
SearchBar.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClear: PropTypes.func,
  customClasses: PropTypes.object,
  enableClear: PropTypes.bool,
};
SearchBar.defaultProps = {
  placeholder: "",
  value: "",
  className: "",
  type: "",
  customClasses: {},
  onClear: () => {},
  enableClear: false,
};
export default memo(SearchBar);
