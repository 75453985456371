import { call, put } from "redux-saga/effects";
import { ApiConstant } from "../const";
import ServiceCategoryAction from "../redux/service-category.redux";
import { ServiceCategoryService } from "../services";
import { toCamel, toSnake } from "../utils";

export function* getServiceCategory(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceCategoryService.get, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validateResponseArray(toCamel(responseData));
      yield put(ServiceCategoryAction.getServiceCategorySuccess(serviceArrayToJson(validatedResponseData)));
    } else {
      yield put(ServiceCategoryAction.getServiceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceCategoryAction.getServiceCategoryFailure(error));
  }
}

export function* createServiceCategory(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceCategoryService.create, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validateServiceData(toCamel(responseData));
      yield put(ServiceCategoryAction.createServiceCategorySuccess(validatedResponseData));
    } else {
      yield put(ServiceCategoryAction.createServiceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceCategoryAction.createServiceCategoryFailure(error));
  }
}

export function* updateServiceCategory(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceCategoryService.update, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      const validatedResponseData = validateServiceData(toCamel(responseData));
      yield put(ServiceCategoryAction.updateServiceCategorySuccess(validatedResponseData));
    } else {
      yield put(ServiceCategoryAction.updateServiceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceCategoryAction.updateServiceCategoryFailure(error));
  }
}

export function* removeServiceCategory(action) {
  try {
    const { data } = action;
    let response = yield call(ServiceCategoryService.remove, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ServiceCategoryAction.removeServiceCategorySuccess(toCamel(data)));
    } else {
      yield put(ServiceCategoryAction.removeServiceCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(ServiceCategoryAction.removeServiceCategoryFailure(error));
  }
}

const serviceArrayToJson = array => {
  let jsonData = {};
  for (var i = 0; i < array.length; i++) {
    jsonData[array[i].uid] = array[i];
  }
  return jsonData;
};

const validateResponseArray = responseArray => {
  const validatedArray = responseArray
    ? responseArray.map(value => {
        return validateServiceData(value);
      })
    : [];
  return validatedArray;
};

const validateServiceData = serviceData => {
  const validatedServiceData = {
    name: serviceData.name ? serviceData.name : "",
    uid: serviceData.uid ? serviceData.uid : "",
    description: serviceData.description ? serviceData.description : "",
    usingTime: serviceData.usingTime ? serviceData.usingTime : 0,
    child: serviceData.child ? serviceData.child : [],
  };

  return validatedServiceData;
};
