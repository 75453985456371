import React, { memo, useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  DialogTitle,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { parse as dateParse, format as DateFormat } from "date-fns";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Line } from "pages/MnReservation/components/DialogBooking/componentDialog";
import { LangConstant, AppConstant } from "const";
import { getCommonKey } from "utils";
import { convertTimeStampToDate, getEndOfDay, getStartOfDay, getTimestamp, removeSecond } from "utils/date";
import { MinuteHourTimePicker } from "components";
import MultiSelectMenu from "components/MultiSelectMenu";
import PlaceItemAction from "redux/place-item.redux";
import StaffAction from "redux/staff.redux";
import ReservationAction from "redux/reservation.redux";
import { ITEM_ARRAY_KEY } from "pages/MnReservation/components/DialogBooking/Tab/TabReservation";
import { restructurePlaceArray } from "pages/MnReservation/components/AddReservation";

const DialogArrangeSeat = ({ isShow, data, onSubmit, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting, shallowEqual);
  const placeBusyStatus = useSelector(state => state.placeItemRedux.placeBusyStatus, shallowEqual);
  const staffBusyStatus = useSelector(state => state.staffRedux.staffBusyStatus, shallowEqual);
  const reservationDetails = useSelector(state => state.reservationRedux.reservation, shallowEqual);

  let isSettingTotal = reservationSetting && reservationSetting[4] && reservationSetting[4].publish === 1;

  const [formInputData, setFormInputData] = useState(FORMAT_DEFAULT_WAITING_CONSUMER);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);
  const [bookingHourAndMin, setBookingHourAndMin] = useState({
    min: 0,
    hour: 0,
  });
  const [placeArrayWithBusyStatus, setPlaceArrayWithBusyStatus] = useState([]);
  const [selectedPlaceList, setSelectedPlaceList] = useState([]);
  const [staffArrayWithBusyStatus, setStaffArrayWithBusyStatus] = useState([]);

  const onChangeFormInvite = event => {
    let newFormInputData = { ...formInputData, [event.target.name]: event.target.value };
    setFormInputData(newFormInputData);
    if (newFormInputData.date) {
      setIsDisabledSubmit(false);
    } else {
      setIsDisabledSubmit(true);
    }
  };

  const onChangeDropdown = selectedList => {
    let staffArraySelected = [];
    selectedList.forEach(element => {
      staffArrayWithBusyStatus.forEach(ele => {
        if (ele.uid === element) {
          staffArraySelected.push(restructureStaffArray(ele));
        }
      });
    });
    setFormInputData({ ...formInputData, staff: staffArraySelected });
  };

  const onChangeMinAndHour = (hour, min) => {
    setBookingHourAndMin({
      min: min,
      hour: hour,
    });
    setFormInputData({ ...formInputData, [KEY_NAME.time]: `${hour}:${min}` });
  };

  const onSubmitForm = () => {
    onSubmit(
      {
        ...formInputData,
        realityStartTime: formInputData.realityStartTime || getTimestamp(removeSecond(new Date())),
        status: AppConstant.STT_RESERVATION.active,
      },
      isSettingTotal,
    );
  };
  const onChangeLocation = arrUid => {
    setSelectedPlaceList(arrUid);
    setFormInputData({ ...formInputData, assignUids: arrUid });
  };

  useEffect(() => {
    // Create startTime = Time now if data.startTime is not exist
    let startTime = reservationDetails?.startTime || Math.floor(Date.now() / 1000);
    if (data?.uid) {
      // Format date and time from startTime before set to formInputData
      let dateFormatBefore = DateFormat(startTime * 1000, AppConstant.FM_DD_MM_YYYY).toString();
      let timeFormatBefore = DateFormat(startTime * 1000, AppConstant.FM_HH_MM).toString();
      let changingData = Boolean(data.reservationUid && reservationDetails)
        ? { ...reservationDetails, reservationUid: data.reservationUid }
        : { ...data };
      let newFormData = {
        ...changingData,
        startTime: startTime,
        date: dateFormatBefore,
        time: timeFormatBefore,
        assignUids: [],
      };

      if (newFormData.assign && Array.isArray(newFormData.assign)) {
        newFormData.assignUids = newFormData.assign.map(value => value.uid || value.itemUid || value.categoryUid);
      }

      setFormInputData({ ...formInputData, ...newFormData });
      // Save Minutes and Hour on state
      let minBefore = parseInt(timeFormatBefore.split(":")[1]);
      let hourBefore = parseInt(timeFormatBefore.split(":")[0]);
      setBookingHourAndMin({
        min: minBefore,
        hour: hourBefore,
      });
    }
  }, [reservationDetails, data]);

  useEffect(() => {
    if (data.reservationUid) {
      dispatch(ReservationAction.getReservation({ uid: data.reservationUid }));
    }
  }, []);

  useEffect(() => {
    if ((formInputData.time, formInputData.date)) {
      // Parse Date with format dd/mm/yyyy
      let parseDateBefore = dateParse(formInputData.date, AppConstant.FM_DD_MM_YYYY, new Date());
      // Set Hour and minutes for time after parse date
      let timeDetails = parseDateBefore.setHours(bookingHourAndMin.hour, bookingHourAndMin.min, 0);
      // Get Timestamp from time details
      let startTimeCurrent = getTimestamp(new Date(timeDetails));
      // Set startTimeCurrent to formInputData
      setFormInputData({
        ...formInputData,
        [KEY_NAME.startTime]: startTimeCurrent,
      });
      // Check disbaled button save
      if (Number.isInteger(startTimeCurrent) && startTimeCurrent > 0) {
        setIsDisabledSubmit(false);
      } else {
        setIsDisabledSubmit(true);
      }
    }
  }, [bookingHourAndMin, formInputData.date]);

  useEffect(() => {
    if (formInputData.startTime) {
      let selectedDate = convertTimeStampToDate(formInputData.startTime);
      dispatch(
        StaffAction.getStaffBusyStatus({
          start: getStartOfDay(selectedDate),
          end: getEndOfDay(selectedDate),
        }),
      );
      dispatch(
        PlaceItemAction.getPlaceBusyStatus({
          start: getStartOfDay(selectedDate),
          end: getEndOfDay(selectedDate),
        }),
      );
    }
  }, [formInputData.startTime]);

  useEffect(() => {
    if (placeBusyStatus.length > 0) {
      let placeBusy = placeBusyStatus[0];
      setPlaceArrayWithBusyStatus(placeBusy.placeCategory || []);
    }
  }, [placeBusyStatus]);

  useEffect(() => {
    if (staffBusyStatus.length > 0) {
      let staffBusy = staffBusyStatus[0];
      setStaffArrayWithBusyStatus(staffBusy.staff || []);
    }
  }, [staffBusyStatus]);

  useEffect(() => {
    if (selectedPlaceList.length > 0) {
      let placeArraySelected = [];
      // The first loop is for user's selected elements
      selectedPlaceList.forEach(element => {
        placeArrayWithBusyStatus.forEach(ele => {
          // Second loop is for the server's response array of place
          if (ele.items.length > 0) {
            // If a category has items, then loop through the items array
            ele.items.forEach(item => {
              if (item.uid === element) {
                //If selected element is item, then push to another array to push into form data
                placeArraySelected.push(restructurePlaceArray(item, true));
              }
            });
          } else {
            // If a category has no child, then check its uid
            if (ele.uid === element) {
              // If selected element is category, then push into form data array
              placeArraySelected.push(restructurePlaceArray(ele));
            }
          }
        });
      });
      setFormInputData({
        ...formInputData,
        assign: placeArraySelected,
        assignUids: placeArraySelected.map(value => value.uid || value.itemUid || value.categoryUid),
      });
    }
  }, [selectedPlaceList]);

  useEffect(() => {
    let isAssignLocation = Boolean(formInputData.assignUids.length > 0);
    setIsDisabledSubmit(!isAssignLocation);
  }, [formInputData.assignUids]);

  return (
    <Dialog open={isShow} classes={{ paperScrollPaper: classes.dialogContainer }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography classes={{ body1: classes.textDialogTitle }}>
          {getLabel(LangConstant.TXT_CREATE_BOOKING_FROM_WAITING_CONSUMER)}
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box padding="24px 20px 0">
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_NAME))}
            onChange={onChangeFormInvite}
            isEditing={false}
            content={formInputData.name}
            name={KEY_NAME.name}
          />
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_PHONE))}
            onChange={onChangeFormInvite}
            isEditing={false}
            content={formInputData.phoneNumber}
            name={KEY_NAME.phoneNumber}
          />
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_PEOPLE))}
            onChange={onChangeFormInvite}
            isEditing={false}
            content={formInputData?.consumer?.man || FORMAT_DEFAULT_WAITING_CONSUMER.man}
            name={KEY_NAME.man}
          />
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_CHILDREN))}
            onChange={onChangeFormInvite}
            isEditing={false}
            content={formInputData?.consumer?.child || FORMAT_DEFAULT_WAITING_CONSUMER.child}
            name={KEY_NAME.child}
          />
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_DATE))}
            content={formInputData.date || ""}
            isEditing={true}
            onChange={onChangeFormInvite}
            placeholder={getLabel(getCommonKey(LangConstant.P_DAY))}
            name={KEY_NAME.date}
          />
          <Box className={classes.rootLine}>
            <Typography className={classes.titleLine}>{getLabel(getCommonKey(LangConstant.L_BOOKING_TIME))}</Typography>
            <MinuteHourTimePicker
              hourValue={bookingHourAndMin.hour}
              minuteValue={bookingHourAndMin.min}
              onChange={onChangeMinAndHour}
              classes={{ rootInputBox: classes.contentLineEdit }}
              required
            />
          </Box>
          {placeArrayWithBusyStatus.length > 0 && (
            <Box className={classes.placeBox}>
              <Typography className={classes.titleLinePlaceBox}>
                {getLabel(getCommonKey(LangConstant.L_BOOKING_ASSIGN))}
              </Typography>
              <MultiSelectMenu
                selectedArr={formInputData?.assignUids}
                data={placeArrayWithBusyStatus}
                onChange={onChangeLocation}
                itemsKey={ITEM_ARRAY_KEY}
                customClasses={{ root: clsx(classes.addReservationOverrideClass, classes.multiSelectMenuRoot) }}
              />
            </Box>
          )}
          {staffArrayWithBusyStatus.length > 0 && (
            <Box className={classes.placeBox}>
              <Typography className={classes.titleLinePlaceBox}>
                {getLabel(getCommonKey(LangConstant.L_STAFF))}
              </Typography>
              <MultiSelectMenu
                data={staffArrayWithBusyStatus}
                selectedArr={getUidArrayFromData(formInputData?.staff || [])}
                onChange={onChangeDropdown}
                customClasses={{
                  root: clsx(classes.addReservationOverrideClass, classes.multiSelectMenuRoot),
                }}
              />
            </Box>
          )}
          <Line
            title={getLabel(getCommonKey(LangConstant.L_BOOKING_NOTE))}
            content={formInputData?.note || ""}
            isEditing={true}
            onChange={onChangeFormInvite}
            name={KEY_NAME.note}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.buttonCancel} onClick={onClose}>
          {getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonInvite}
          onClick={onSubmitForm}
          disabled={isDisabledSubmit}
        >
          <Typography className={classes.textButtonInvite}>{getLabel(getCommonKey(LangConstant.TXT_SAVE))}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getUidArrayFromData = dataPlace => {
  let data = [];
  if (dataPlace.length > 0) {
    data = dataPlace.map(value => value.staffUid);
  }
  return data;
};

const restructureStaffArray = data => ({
  staffUid: data.uid || "",
  staffName: data.name || "",
});

const FORMAT_DEFAULT_WAITING_CONSUMER = {
  uid: "",
  name: "",
  phoneNumber: "",
  man: "",
  child: "",
  startTime: 0,
  service: {},
  staff: [],
  assign: [],
  assignUids: [],
  note: "",
};

DialogArrangeSeat.propTypes = {
  isShow: PropTypes.bool,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

const KEY_NAME = {
  name: "name",
  phoneNumber: "phoneNumber",
  man: "man",
  child: "child",
  startTime: "startTime",
  date: "date",
  time: "time",
  service: "service",
  staff: "staff",
  note: "note",
};

export default memo(DialogArrangeSeat);

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 531,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    "@media only screen and (max-width: 550px)": {
      width: "100%",
      margin: 0,
      height: "100%",
      maxHeight: "none",
    },
  },
  dialogTitle: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: "fixed",
    width: 531,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 60,
    padding: "0 24px",
    zIndex: 100,
    "@media only screen and (max-width: 550px)": {
      width: "100%",
    },
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 20,
  },
  closeButton: {
    position: "absolute",
    right: 24,
    top: 18,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    marginTop: 34,
    padding: "26px 0 0",
    display: "flex",
    flexDirection: "column",
    border: "none",
    "&:first-child": {
      paddingTop: 26,
      "@media only screen and (max-width: 550px)": {
        padding: "26px 0 20px",
      },
    },
  },
  rootLine: {
    width: "100%",
    minHeight: 30,
    display: "flex",
    marginBottom: 4,
  },
  titleLine: {
    minWidth: "40%",
    height: 22,
    fontSize: 13,
    marginTop: 4,
  },
  contentLineEdit: {
    height: 30,
    fontSize: 13,
    padding: "4px 16px",
    width: "60%",
    paddingLeft: 0,
  },
  dialogActions: {
    display: "block",
    textAlign: "right",
    padding: "35px 24px 27px",
  },
  buttonCancel: {
    borderRadius: 2,
    minWidth: 58,
    padding: 0,
    minHeight: 30,
    textTransform: "none",
    fontWeight: 500,
  },
  buttonInvite: {
    borderRadius: 2,
    minWidth: 58,
    padding: 0,
    minHeight: 30,
    marginLeft: 8,
    backgroundColor: "#ef5845",
    border: "unset",
    color: theme.palette.white,
    textTransform: "none",
  },
  textButtonInvite: {
    fontSize: 14,
    color: theme.palette.white,
  },
  addReservationOverrideClass: {
    width: "60%",
    margin: "0",
    marginRight: 0,
    justifyContent: "space-between",
  },
  multiSelectMenuRoot: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    width: "100%",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
  },
  placeBox: {
    display: "flex",
    textAlign: "left",
    width: "100%",
    marginBottom: 5,
    justifyContent: "space-between",
    minHeight: 39,
  },
  titleLinePlaceBox: {
    minWidth: "40%",
    height: 22,
    fontSize: 13,
    marginTop: 4,
    display: "flex",
    alignItems: "center",
  },
  inputOverride: {
    marginLeft: 0,
  },
}));
