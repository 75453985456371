import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const SMSInvite = ({ width, height }) => {
  return (
    <SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M23 0.5H3C1.625 0.5 0.5 1.625 0.5 3V25.5L5.5 20.5H23C24.375 20.5 25.5 19.375 25.5 18V3C25.5 1.625 24.375 0.5 23 0.5ZM23 18H5.5L3 20.5V3H23V18ZM6.75 9.25H9.25V11.75H6.75V9.25ZM11.75 9.25H14.25V11.75H11.75V9.25ZM16.75 9.25H19.25V11.75H16.75V9.25Z"
          fill="#65B39D"
        />
      </g>
    </SvgIcon>
  );
};

SMSInvite.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
SMSInvite.defaultProps = {
  width: 26,
  height: 26,
};

export default memo(SMSInvite);
