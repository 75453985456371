import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { MoreHorizRounded } from "@material-ui/icons";
import clsx from "clsx";

const DayHeader = props => {
  const { data: arrColumns, onControlHiddenColumns } = props;
  const classes = useStyles();

  // No render when empty data
  if (arrColumns.length === 0) {
    return null;
  }

  return (
    <Box classes={{ root: classes.dayHeader }} borderBottom={1}>
      {arrColumns.map((column, index) => {
        let isShowHidden = Boolean(!column.isHidden && column.emptyData);
        let isFirst = 0 === index;
        let isLatest = arrColumns.length - 1 === index;
        let arrayRender = isShowHidden ? column.emptyData : [column];

        return arrayRender.map((renderData, emptyIndex) => (
          <DayHeadItem
            isEnableHover={isShowHidden}
            data={renderData}
            onShowHidden={() => onControlHiddenColumns(index)}
            isFirst={isFirst && emptyIndex === 0}
            isLatest={isLatest}
            key={emptyIndex}
          />
        ));
      })}
    </Box>
  );
};

DayHeader.protoType = {
  data: PropTypes.object,
};

DayHeader.defaultProps = {
  data: [],
};

const HEIGHT_DAY_HEADER = 42;
const WIDTH_COLUMN = 175;

const DayHeadItem = props => {
  const { data, isFirst, isLatest, isEnableHover, onShowHidden } = props;
  const classes = useStyles();
  const { time, isHidden } = data;

  const [isHover, setIsHover] = useState(isEnableHover);

  let lastHourEnd = time
    ? time.split(":")[1] === "30"
      ? Number(time.split(":")[0]) + 1 + ":00"
      : Number(time.split(":")[0]) + ":30"
    : "00:00";

  let cellClass = clsx(["medium-md-txt", !isFirst && !isLatest && classes.hourHeader]);
  let isShowDotBtn = Boolean(isHidden || isHover);

  let hoverEvent = isEnableHover
    ? {
        onMouseEnter: () => setIsHover(true),
        onMouseLeave: () => setIsHover(false),
      }
    : {};

  useEffect(() => {
    setIsHover(false);
  }, [data, isEnableHover]);

  return (
    <Box className={classes.cellHeader} {...hoverEvent}>
      <Typography className={cellClass}>{time}</Typography>
      {isShowDotBtn && (
        <Box className={clsx("center-root", classes.btnHidden)}>
          <IconButton className={classes.icon} aria-label="hidden column" component="span" onClick={onShowHidden}>
            <MoreHorizRounded />
          </IconButton>
        </Box>
      )}
      {isLatest && <Typography className={clsx(cellClass, classes.lastHour)}>{lastHourEnd}</Typography>}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  dayHeader: {
    height: HEIGHT_DAY_HEADER,
    display: "inline-flex",
    backgroundColor: theme.palette.white,
    borderBottomColor: theme.palette.grey[200],
  },
  cellHeader: {
    paddingTop: 10,
    paddingLeft: 10,
    position: "relative",
    width: WIDTH_COLUMN,
    display: "flex",
  },
  hourHeader: {
    position: "absolute",
    left: "-19px",
    top: 10,
  },
  lastHour: {
    textAlign: "right",
    flexGrow: 1,
  },
  btnHidden: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    "& > *": {
      padding: 4,
    },
  },
  icon: {
    color: theme.palette.white,
    "&:hover": {
      color: theme.palette.black,
    },
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.black,
    },
  },
}));

export default memo(DayHeader);
