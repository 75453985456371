import React, { useLayoutEffect } from "react";
import AuthLayout from "../layouts/AuthLayout";
import * as AppConstant from "../const/app.const";
import { useLocation } from "react-router-dom";
import Cookie from "js-cookie";

const AuthSocial = () => {
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Get multi role token and email from url
  const token = searchParams.get(AppConstant.KEY_MULTI_TOKEN);
  const email = searchParams.get(AppConstant.KEY_EMAIL);

  useLayoutEffect(() => {
    if (token) {
      Cookie.set(AppConstant.KEY_GBOSS_LOGIN, JSON.stringify({ responseData: JSON.parse(decodeURI(token)).data }), {
        expires: AppConstant.EXPIRES_TOKEN,
      });
      Cookie.set(AppConstant.NAME_LOGIN, email, {
        expires: AppConstant.EXPIRES_TOKEN,
      });
    }
    window.popup ? window.popup.close() : window.close();
  }, []);

  return(
    <AuthLayout/>
    )
};
export default AuthSocial;

