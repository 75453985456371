import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stepper, Step, makeStyles } from "@material-ui/core";
import { AppConstant } from "const";
import StepperOder from "./StepperOder";

const CustomStepperOrder = ({ status, selectedStatus, onChange }) => {
  const classes = useStyles();

  let statusArray = [...STT_RESERVATION_WITH_INDEX];

  let currentSelected = AppConstant.STT_RESERVATION.pending;
  let currentStatusStep = AppConstant.STT_RESERVATION.pending;

  statusArray.forEach(entry => {
    if (entry === selectedStatus) {
      currentSelected = entry;
    }
    if (entry === status) {
      currentStatusStep = entry;
    }
  });

  let stepProps = { alternativeLabel: true, orientation: "horizontal" };

  return (
    <Stepper className={classes.rootStepper} activeStep={status - 1} {...stepProps}>
      {statusArray.map((entry, index) => {
        return (
          <Step className={classes.rootStep} key={entry}>
            <StepperOder
              key={index}
              entry={entry}
              index={index}
              onChange={onChange}
              currentStatus={status}
              selectedStatus={selectedStatus}
              currentSelected={currentSelected}
              currentStatusStep={currentStatusStep}
            />
          </Step>
        );
      })}
    </Stepper>
  );
};

CustomStepperOrder.propTypes = {
  status: PropTypes.number,
  onChange: PropTypes.func,
};
CustomStepperOrder.defaultProps = { status: AppConstant.STT_RESERVATION.pending };

export const STT_RESERVATION_WITH_INDEX = [
  AppConstant.STT_RESERVATION.pending,
  AppConstant.STT_RESERVATION.confirm,
  AppConstant.STT_RESERVATION.active,
  AppConstant.STT_RESERVATION.finished,
  AppConstant.STT_RESERVATION.cancel,
  AppConstant.STT_RESERVATION.reject,
];

const useStyles = makeStyles({
  rootStepper: {
    padding: "10px 0",
  },
  rootStep: {
    padding: 0,
  },
});

export default memo(CustomStepperOrder);
