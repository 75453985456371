import React, { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Dialog,
  Box,
  IconButton,
  Button,
  Typography,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Close, Error, Link } from "@material-ui/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import clsx from "clsx";
import ComboItemAction from "redux/combo-item.redux";
import { Input, TextArea, Alert } from "components";
import {
  UploadImage,
  InputUsedTime,
  InputPrice,
  ComboCategoryMenu,
  Warning,
  UploadVideo,
  ValueTax,
} from "components/mn-master-product";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { getCommonKey, createArrayWithLabel, getItemUrl } from "utils";

const WatchAndEditCombo = ({ data, isOpenEdit, isOpenWatch, onClose, isShowEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);

  const dataListServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem, shallowEqual);
  const dataListProductItem = useSelector(state => state.mnProductRedux.dataItem, shallowEqual);
  const errorPutComboItem = useSelector(state => state.comboItemRedux.errorPutComboItem);
  const shopInfo = useSelector(state => state.shopInfoRedux.data, shallowEqual);

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [dataServiceItem, setDataServiceItem] = useState([]);
  const [dataProductItem, setDataProductItem] = useState([]);
  const [arraySelectedUid, setArraySelectedUid] = useState([]);
  const [formInputCombo, setFormInputCombo] = useState(data);
  const [isWarning, setIsWarning] = useState(isOpenEdit);
  const [isEditable, setIsEditable] = useState(isOpenEdit);
  const [isCheckService, setIsCheckService] = useState(false);
  const dialogRef = useRef(null);

  const [notify, setNotify] = useState({
    value: false,
    message: "",
    type: TYPES_NOTIFICATION.not_warning,
  });
  const [taxTypeValue, setTaxTypeValue] = useState(
    data?.details?.defaultId
      ? AppConstant.TAX_TYPE_VALUE[data?.details?.defaultId]
      : AppConstant.TAX_TYPE_VALUE[AppConstant.TYPE_TAX.noTax],
  );

  const onTypingName = value => {
    setFormInputCombo({
      ...formInputCombo,
      name: value,
      itemDetails: {
        ...formInputCombo.itemDetails,
        name: value,
      },
    });
  };

  const onChangeCategoryMenu = array => {
    var checkService = array.some(data => data.type === AppConstant.TYPE_CATEGORY.service);
    setIsCheckService(checkService);
    setFormInputCombo({
      ...formInputCombo,
      itemDetails: {
        ...formInputCombo.itemDetails,
        itemDetailsContentList: array,
      },
    });
  };

  const onUploadFile = (value, error) => {
    if (!error) {
      setFormInputCombo({
        ...formInputCombo,
        imageList: value,
      });
    } else {
      setNotify({
        value: true,
        message: error,
        type: TYPES_NOTIFICATION.warning_image,
      });
    }
  };

  const onUploadVideo = (value, isError) => {
    let newFormValue = {
      ...formInputCombo,
    };
    if (isError) delete newFormValue.video;
    else newFormValue.video = value;

    setFormInputCombo(newFormValue);
  };

  const onTypingDescription = event => {
    let descriptionInput = event.target.value;
    setFormInputCombo({
      ...formInputCombo,
      description: descriptionInput,
      itemDetails: {
        ...formInputCombo.itemDetails,
        description: descriptionInput,
      },
    });
  };

  const onChangeTime = value => {
    setFormInputCombo({
      ...formInputCombo,
      usingTime: value,
    });
  };

  const onClickTimeUsed = value => {
    setFormInputCombo({
      ...formInputCombo,
      usingTimeType: value,
    });
  };

  const onInputPrice = value => {
    const newPrice = value;
    const newPriceTaxIn = Math.round(newPrice * (1 + taxTypeValue));
    const tax = newPriceTaxIn - newPrice;
    setFormInputCombo({
      ...formInputCombo,
      price: newPrice,
      priceTaxIn: newPriceTaxIn,
      tax: tax,
    });
  };

  const onChangeDropdownTax = taxTypeId => {
    const newTaxTypeValue = AppConstant.TAX_TYPE_VALUE[taxTypeId];
    setTaxTypeValue(newTaxTypeValue);

    const newPriceTaxIn = Math.round(formInputCombo.price * (1 + newTaxTypeValue));
    const tax = newPriceTaxIn - formInputCombo.price;
    setFormInputCombo({
      ...formInputCombo,
      tax: tax,
      priceTaxIn: newPriceTaxIn,
      taxInclusiveF: Boolean(taxTypeId) ? SystemConstant.TAX_INCLUSIVE_F.tax : SystemConstant.TAX_INCLUSIVE_F.noTax,
      details: { ...formInputCombo?.details, defaultId: taxTypeId },
      itemDetails: {
        ...formInputCombo?.itemDetails,
        details: { ...formInputCombo?.itemDetails?.details, defaultId: taxTypeId },
      },
    });
  };

  const onSubmitFormCombo = () => {
    if (formInputCombo.name) {
      let newArray = formInputCombo?.itemDetails?.itemDetailsContentList.map(dataChange => ({
        ...dataChange,
        uid: null,
      }));
      let newFormData = {
        ...formInputCombo,
        itemDetails: { ...formInputCombo.itemDetails, itemDetailsContentList: newArray },
      };
      dispatch(ComboItemAction.putComboItem(newFormData));
    } else {
      setNotify({
        value: true,
        message: getLabel(LangConstant.TXT_NOTIFY_INPUT_NAME_COMBO),
        type: TYPES_NOTIFICATION.incomplete_attribute,
      });
    }
  };

  const onCloseNotify = () => {
    setIsWarning(false);
    setNotify({
      value: false,
      message: "",
      type: TYPES_NOTIFICATION.not_warning,
    });
  };

  const onEnableEdit = () => {
    setIsEditable(true);
    setIsWarning(true);
  };

  useEffect(() => {
    let formDataService = [];
    let arrayUid = [];
    let arrayContentMap = data?.itemDetails?.itemDetailsContentList || data?.itemDetailsContentList;
    let dataService = dataListServiceItem.data;
    let dataConvertService = convertDataCombo(dataService, arrayContentMap);
    formDataService = dataConvertService.data;
    arrayUid = dataConvertService.arrayUid;
    setDataServiceItem(formDataService);

    let formDataProduct = [];
    let dataProduct = dataListProductItem.data;
    let dataConvertProduct = convertDataCombo(dataProduct, arrayContentMap, arrayUid);
    formDataProduct = dataConvertProduct.data;
    arrayUid = dataConvertProduct.arrayUid;
    setDataProductItem(formDataProduct);
    setArraySelectedUid(arrayUid);
  }, []);

  useEffect(() => {
    if (errorPutComboItem) {
      if (errorPutComboItem?.name?.[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dialogRef.current.querySelector(".MuiDialog-paperScrollPaper").scrollTop = 0;
        dispatch(ComboItemAction.resetErrorComboItem());
      }
    }
  }, [errorPutComboItem]);

  useEffect(() => {
    if (arraySelectedUid && (isOpenEdit || isEditable)) {
      let serviceSelected = dataServiceItem.filter(data => arraySelectedUid.includes(data.uid));
      serviceSelected?.length > 0 ? setIsCheckService(true) : setIsCheckService(false);
    }
  }, [arraySelectedUid, isEditable]);

  return (
    <Dialog open={isOpenEdit || isOpenWatch} fullScreen ref={dialogRef}>
      {isEditable && (
        <Warning isShow={isWarning} onClose={onCloseNotify} message={getLabel(LangConstant.TXT_NOTIFY_WARNING_COMBO)} />
      )}
      <Box className={classes.rootHeader}>
        <Typography className={classes.nameHeaderTypo}>
          {isEditable ? getLabel(LangConstant.TXT_EDIT_COMBO) : getLabel(LangConstant.TXT_DETAIL_COMBO)}
        </Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButtonClose}>
            <Close className={classes.iconClose} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <Box className={classes.infoBox}>
          <Box marginBottom="30px">
            <Box padding="8px 0 16px">
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_INFORMATION_BASIC)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.FN_NOTICE_FILL)(getLabel(LangConstant.L_MAIN_COMBO))}
              </Typography>
            </Box>
            <Box padding="8px 0 16px">
              <Box className={classes.infoBasic}>
                <Typography className={classes.nameComboTypo}>{getLabel(LangConstant.TXT_NAME_COMBO)}</Typography>
                <Typography color="primary" className={classes.isRequire}>
                  &nbsp;&#42;
                </Typography>
              </Box>
              <Input
                placeholder={getLabel(LangConstant.TXT_NAME_COMBO)}
                classes={{ root: classes.rootNameCombo, input: classes.inputNameCombo }}
                required
                onChange={onTypingName}
                value={formInputCombo.name}
                disabled={!isEditable}
              />
              {isDuplicate && (
                <Box className={classes.warningName}>
                  <Error color="primary" className={classes.iconError} />
                  <Typography color="primary" className={classes.textWarning}>
                    {getLabel(LangConstant.TXT_WARNING_NAME_COMBO)}
                  </Typography>
                </Box>
              )}
              {isEditable && notify.value && notify.type === TYPES_NOTIFICATION.incomplete_attribute && (
                <Alert isShow={notify.value} onClose={onCloseNotify} type="error" message={notify.message} />
              )}

              <Box className={classes.copyContainer}>
                <CopyToClipboard text={getItemUrl(shopInfo?.alias, formInputCombo.uid, formInputCombo.name)}>
                  <Button variant="text" className={classes.copyButton} startIcon={<Link />}>
                    {getLabel(LangConstant.TXT_COPY_ITEM_LINK)}
                  </Button>
                </CopyToClipboard>
              </Box>
            </Box>

            <Box padding="8px 0 16px">
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_CHOOSE_PRODUCT)}</Typography>
              <ComboCategoryMenu
                modeViewAr={[
                  {
                    id: "1",
                    name: getLabel(getCommonKey(LangConstant.TXT_SERVICE)),
                    title: getLabel(LangConstant.TXT_NAME_SERVICE),
                  },
                  {
                    id: "2",
                    name: getLabel(getCommonKey(LangConstant.TXT_PRODUCT)),
                    title: getLabel(getCommonKey(LangConstant.TXT_PRODUCT_NAME)),
                  },
                ]}
                label={getLabel(LangConstant.TXT_CHOOSE_PRODUCT)}
                serviceData={dataServiceItem}
                productData={dataProductItem}
                selectedArr={arraySelectedUid}
                onChange={onChangeCategoryMenu}
                isDisable={!isEditable}
              />
            </Box>
            <Box padding="8px 0 16px">
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_ILLUSTRATION)}</Typography>
              <Box marginTop="8px">
                <UploadImage
                  onChange={onUploadFile}
                  isDisabled={!isEditable}
                  imageList={
                    formInputCombo?.imageList?.length > 0
                      ? formInputCombo.imageList
                      : Boolean(formInputCombo?.image)
                      ? [formInputCombo.image]
                      : []
                  }
                />
                {isEditable && notify.value && notify.type === TYPES_NOTIFICATION.warning_image && (
                  <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
                )}
              </Box>
            </Box>
            <Box padding="8px 0 16px">
              <Typography className={classes.inputTitleTypo}>
                {getLabel(LangConstant.TXT_ILLUSTRATION_VIDEO)}
              </Typography>
              <Box marginTop="8px">
                <UploadVideo defaultUrl={formInputCombo?.video || ""} onChange={onUploadVideo} />
              </Box>
            </Box>
            <Box padding="8px 0 16px">
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_COMBO_DESCRIPTION)}</Typography>
              <TextArea
                placeholder={getLabel(LangConstant.TXT_ADD_COMBO_DESCRIPTION)}
                className={classes.comboDesTextArea}
                rowMax={4}
                onChange={onTypingDescription}
                value={formInputCombo.description}
                disabled={!isEditable}
              />
            </Box>
          </Box>
          <Box>
            <Box padding="8px 0 16px">
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_INFORMATION_SALES)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.TXT_FILL_IN_INFORMATION_SALES_COMBO)}
              </Typography>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography variant="subtitle2" className={classes.classificationProduct}>
                {getLabel(LangConstant.TXT_TAX_APPLY)}
              </Typography>
              <ValueTax onChange={onChangeDropdownTax} valueTax={formInputCombo?.details?.defaultId} />
            </Box>
            <Box padding="8px 0 46px" display="flex">
              <Box minWidth="158px">
                <Typography className={classes.inputTitleTypo}>
                  {getLabel(LangConstant.TXT_PRICE_ACCORDING_TO)}
                </Typography>
                <Input
                  defaultValue={getLabel(LangConstant.L_MAIN_COMBO)}
                  disabled
                  classes={{ root: classes.rootNameCombo, input: classes.inputNameCombo }}
                />
              </Box>
              <Box minWidth="158px" margin="0 90px">
                <Typography className={classes.inputTitleTypo}>
                  {getLabel(getCommonKey(LangConstant.TXT_BOOK_USED_TIME))}
                </Typography>
                <InputUsedTime
                  valueTime={formInputCombo?.usingTime ? formInputCombo.usingTime : ""}
                  valueTimeType={formInputCombo?.usingTimeType ? formInputCombo.usingTimeType : ""}
                  dataChange={createArrayWithLabel(
                    AppConstant.TYPE_TIME_USED,
                    LangConstant.ARR_TYPE_TIME_USED,
                    getLabel,
                    true,
                  )}
                  isService={!isCheckService}
                  onChange={onChangeTime}
                  onClick={onClickTimeUsed}
                  isDisabled={!isCheckService}
                  className={classes.inputUsedTime}
                  placeholderTime={getLabel(getCommonKey(LangConstant.TXT_MINUTES))}
                  placeholderValue={"30"}
                />
              </Box>
            </Box>
            <Box padding="8px 0 46px" display="flex">
              <Box minWidth="158px">
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_UNIT_PRICE)}</Typography>
                <InputPrice
                  valueInput={formInputCombo?.price ? formInputCombo.price.toString() : ""}
                  onChange={onInputPrice}
                  checkDisable={!isEditable}
                  currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                />
              </Box>
              <Box minWidth="158px" margin="0 90px">
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_PRICE_AFTER_TAX)}</Typography>
                <InputPrice
                  valueInput={formInputCombo?.priceTaxIn ? formInputCombo.priceTaxIn.toString() : ""}
                  onChange={onInputPrice}
                  checkDisable={true}
                  currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Box className={classes.boxButtonSave}>
          {isShowEdit && (
            <>
              <Button className={clsx("semiBold-lg-txt", classes.buttonCancel)} onClick={onClose}>
                {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
              </Button>
              {isEditable ? (
                <Button className={classes.buttonSave} onClick={onSubmitFormCombo}>
                  {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
                </Button>
              ) : (
                <Button className={classes.buttonEdit} onClick={onEnableEdit}>
                  {getLabel(getCommonKey(LangConstant.TXT_EDIT))}
                </Button>
              )}
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const convertDataCombo = (data, arrayContentMap, arrayUid) => {
  // TODO optimal code
  let newFormData = [],
    newArrayUid = arrayUid || [];
  data.map((dataItem, index) => {
    newFormData.push({
      uid: dataItem.uid,
      type: AppConstant.TYPE_CATEGORY.service,
      name: dataItem.name,
      amount: 0,
      image: dataItem.image,
      description: dataItem.description,
      itemUid: dataItem.uid,
      groupModel: dataItem.groupModel,
    });
    arrayContentMap.forEach(dataMap => {
      if (dataItem.uid === dataMap.itemUid) {
        newFormData[index].amount = dataMap.amount;
        newArrayUid.push(dataMap.itemUid);
      }
    });
    if (Boolean(dataItem.classificationList)) {
      dataItem.classificationList.forEach(dataClassification => {
        arrayContentMap.forEach(dataItemMap => {
          if (dataClassification.item.uid === dataItemMap.itemUid) {
            newFormData[index].amount = dataItemMap.amount;
            newFormData[index].name = dataItemMap.name;
            newArrayUid.push(dataItem.uid);
          }
        });
      });
    }
  });
  return { data: newFormData, arrayUid: newArrayUid };
};

const TYPES_NOTIFICATION = {
  not_warning: 0,
  warning_image: 1,
  incomplete_attribute: 2,
};

WatchAndEditCombo.propTypes = {
  data: PropTypes.object,
  isShowEdit: PropTypes.bool,
  isOpenEdit: PropTypes.bool,
  isOpenWatch: PropTypes.bool,
  onClose: PropTypes.func,
};
WatchAndEditCombo.defaultProps = { isShowEdit: true };

export default memo(WatchAndEditCombo);

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButtonClose: {
    padding: 0,
  },
  iconClose: {
    width: 36,
    height: 36,
  },
  infoBox: {
    width: 430,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  infoBasic: {
    display: "flex",
    alignItems: "center",
    height: 22,
    marginBottom: 8,
  },
  infoBasicTypo: {
    fontSize: 18,
    fontWeight: 600,
    height: 28,
    color: "#3e4045",
    lineHeight: 1.56,
  },
  nameComboTypo: {
    height: 22,
    lineHeight: 1.57,
    fontSize: 13,
    fontWeight: 600,
  },
  isRequire: {
    height: 22,
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  noticeFillTypo: {
    fontSize: 14,
    minHeight: 22,
    lineHeight: 1.57,
    marginTop: 8,
    color: "#3e4045",
  },
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  rootNameCombo: {
    margin: 0,
    borderRadius: 3,
    "&.Mui-disabled": {
      color: "#3e4045",
    },
  },
  inputNameCombo: {
    height: 22,
    padding: "9px 16px",
    fontSize: 14,
    lineHeight: 1.57,
  },
  comboDesTextArea: {
    height: 80,
    margin: 0,
    padding: "8px 16px",
    borderRadius: 3,
  },
  inputUsedTime: {
    width: 158,
  },
  dropdownClass: {
    minWidth: 113,
  },
  buttonSave: {
    height: 46,
    marginLeft: 10,
    minWidth: 102,
    fontSize: 16,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
  buttonEdit: {
    height: 46,
    marginLeft: 10,
    minWidth: 152,
    fontSize: 16,
    borderRadius: 3,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },

  copyButton: {
    minHeight: "unset",
    borderRadius: 4,
    textTransform: "none",
    color: theme.palette.text.link,
    padding: "4px 8px",
    fontSize: 13,
  },

  copyContainer: {
    display: "flex",
    width: "100%",
    marginTop: 5,
  },
  buttonCancel: {
    height: 46,
    padding: 0,
    minWidth: 102,
    borderRadius: 3,
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    textTransform: "none",
    border: "1px solid #D4D5D8",
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  dialogAction: {
    justifyContent: "center",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.103529)",
    padding: "30px 0",
  },
}));
