import StringFormat from "string-format";
import { ApiConstant } from "const";
import { createApiRegistration } from "api";

export const getCheckPayment = () => createApiRegistration().get(ApiConstant.GET_CHECK_PAYMENT);

export const getPackageCurrent = data => createApiRegistration().get(ApiConstant.GET_PACKAGE_CURRENT, data);

export const getPackage = data => createApiRegistration().get(ApiConstant.GET_PACKAGE, data);

export const getPaymentHistory = data => createApiRegistration().get(ApiConstant.GET_PAYMENT_HISTORY, data);

export const getPaymentDetails = data =>
  createApiRegistration().get(StringFormat(ApiConstant.GET_PAYMENT_DETAILS, data.uid));
