import React from "react";
import { Close } from "@material-ui/icons";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, makeStyles } from "@material-ui/core";

const CropDialogTitle = props => {
  const { children, onClose, ...other } = props;
  const classes = useStyle();
  return (
    <>
      <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
        {children}
        {onClose && (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </MuiDialogTitle>
    </>
  );
};

export default CropDialogTitle;

const useStyle = makeStyles(theme => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    paddingLeft: 30,
    backgroundColor: "#65b39d",
    "&>p": {
      color: "#fff",
    },
  },
  closeButton: {
    position: "absolute",
    right: "1.5%",
    top: theme.spacing(1),
    color: "#fff",
  },
}));
