import React, { forwardRef, memo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography, Checkbox, Box } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { LangConstant } from "const";
import DialogAddAmount from "./DialogAddAmount";

const ComboCategoryMenuItem = forwardRef((props, ref) => {
  const { data, onCheck, selectedValue, onSubmitAmount, title } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [dataDialogAdd, setDataDialogAdd] = useState({});
  const [dataItemSelect, setDataItemSelect] = useState({ uid: "", name: "", amount: "" });

  const onValueCheck = (event, value) => {
    event.stopPropagation();
    if (Boolean(value.groupModel) && !selectedValue.includes(value.uid)) {
      setShowAddDialog(true);
    } else {
      onCheck(value);
    }
    setDataDialogAdd(value);
  };

  const onShowAddDialog = (event, value) => {
    event.preventDefault();
    setShowAddDialog(true);
    setDataDialogAdd(value);
  };

  const onCloseAddDialog = event => {
    event.preventDefault();
    setShowAddDialog(false);
  };

  const onChangeAddDialog = data => {
    setDataItemSelect(data);
  };

  const onSubmit = event => {
    event.preventDefault();
    if (dataItemSelect.amount >= DEFAULT_AMOUNT) {
      onSubmitAmount(dataDialogAdd.uid, dataItemSelect.amount);
    } else {
      onSubmitAmount(dataDialogAdd.uid, DEFAULT_AMOUNT);
    }
    onCheck(dataDialogAdd, dataItemSelect);
    setDataItemSelect({ uid: "", name: "", amount: "" });
    setShowAddDialog(false);
  };

  return (
    <Box className={classes.menuItemsRoot} ref={ref}>
      <Box className={classes.titleContainer}>
        <Box className={classes.contentTitleBox}>
          <Typography className="semiBold-sm-txt">{title}</Typography>
        </Box>
        <Box className={classes.amountTitleBox}>
          <Typography className="semiBold-sm-txt">{getLabel(LangConstant.L_PLACE_AMOUNT)}</Typography>
        </Box>
      </Box>
      {data.map(value => (
        <Box
          className={classes.menuItemContainer}
          id={value.uid}
          key={value.uid}
          onClick={event => onShowAddDialog(event, value)}
        >
          <Box className={classes.contentBox}>
            <Checkbox
              classes={{ root: classes.itemCheckbox, checked: classes.checked }}
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              checked={selectedValue.includes(value.uid)}
              onClick={event => onValueCheck(event, value)}
            />
            <Typography color="inherit" className="regular-md-txt ellipsis">
              {value.name}
            </Typography>
          </Box>
          <Box className={classes.amountBox}>
            {selectedValue.includes(value.uid) && (
              <Typography color="inherit" className="regular-md-txt">
                {value.amount >= DEFAULT_AMOUNT ? value.amount : DEFAULT_AMOUNT}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
      {showAddDialog && (
        <DialogAddAmount
          isShow={true}
          data={dataDialogAdd}
          onChange={onChangeAddDialog}
          onSubmit={onSubmit}
          onClose={onCloseAddDialog}
        />
      )}
    </Box>
  );
});

const DEFAULT_AMOUNT = 1;

const useStyles = makeStyles(theme => ({
  menuItemsRoot: {
    maxHeight: 150,
    maxWidth: 475,
  },
  titleContainer: { display: "flex", padding: "8px 0 8px 24px" },
  menuItemContainer: {
    display: "flex",
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  contentTitleBox: { width: "80%", paddingLeft: 12, color: theme.palette.grey[600] },
  amountTitleBox: { width: "20%", textAlign: "center" },
  contentBox: {
    width: "80%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
    "&:hover > *": {
      color: theme.palette.white,
    },
  },
  amountBox: {
    width: "20%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    color: "#3e4045",
    justifyContent: "center",
  },
  itemCheckbox: {
    color: "#979aa1",
  },
  checked: {
    color: `${theme.palette.text.link} !important`,
  },
}));

ComboCategoryMenuItem.propTypes = {
  data: PropTypes.array,
  selectedValue: PropTypes.array,
  title: PropTypes.string,

  onCheck: PropTypes.func,
  onSubmitAmount: PropTypes.func,
};

ComboCategoryMenuItem.defaultProps = {
  data: [],
  title: "",
  selectedValue: [],

  onSubmitAmount: () => {},
  onCheck: () => {},
};

export default memo(ComboCategoryMenuItem);
