import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment, OutlinedInput } from "@material-ui/core";

const InputIcon = forwardRef((props, ref) => {
  const { icon, onChange, className, readOnly, type, value, ...otherProps } = props;
  const classes = useStyles();

  const onTyping = event => {
    if (readOnly) {
      return;
    }
    if (Boolean(onChange)) {
      if (type === "number") {
        if (!isNaN(parseInt(event.target.value))) {
          onChange(event.target.value, event);
        } else {
          onChange(value, event);
        }
      } else {
        onChange(event.target.value, event);
      }
    }
  };

  return (
    <OutlinedInput
      fullWidth
      type={type}
      onChange={onTyping}
      classes={{
        input: classes.input,
        root: `${classes.root} ${className}`,
      }}
      startAdornment={
        <InputAdornment position="start" className={classes.icon}>
          {icon}
        </InputAdornment>
      }
      ref={ref}
      {...otherProps}
    />
  );
});

const useStyles = makeStyles(theme => ({
  root: {
    borderColor: "white",
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: "8px",
    marginBottom: "8px",
  },
  input: { padding: "16px 8px" },
  icon: {
    minWidth: "50px",
    width: "50px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "50px",
    background: theme.palette.grey[200],
    justifyContent: "center",
    borderRight: "solid 0.5px #cecfd2",
    marginRight: 0,
  },
}));

InputIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};
InputIcon.defaultProps = { placeholder: "", className: "", type: "text" };

export default memo(InputIcon);
