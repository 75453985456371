import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, Drawer, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { LangConstant } from "const";
import { isObjectNotEqual } from "utils";
import { FilterByScore, FilterByProduct, FilterByType } from "../components";

const MobileFilter = ({
  open,
  onClose,
  filterByScoreInitiate,
  filterByInitiate,
  filterByProductInitiate,
  filterByReportInitiate,
  onChangeFilterByScore,
  onChangeFilterBy,
  onChangeFilterByReport,
  onChangeFilterByProduct,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_RATING_MANAGEMENT);

  const { ratingSummary } = useSelector(state => state.ratingRedux, shallowEqual);

  const [ratingData, setRatingData] = useState(ratingSummary);
  const [filterByScore, setFilterByScore] = useState(filterByScoreInitiate || []);
  const [filterBy, setFilterBy] = useState(filterByInitiate || FILTER_BY.shop);
  const [filterByReport, setFilterByReport] = useState(filterByReportInitiate || false);
  const [filterByProduct, setFilterByProduct] = useState(filterByProductInitiate || []);

  const onSelectFilterByItem = uid => {
    let newFilter = [...filterByProduct];
    if (newFilter.includes(uid)) {
      newFilter = newFilter.filter(item => item !== uid);
    } else {
      newFilter.push(uid);
    }
    setFilterByProduct(newFilter);
  };

  const onChangeScoreFilter = score => {
    let newScoreFilter = [...filterByScore];
    if (newScoreFilter.includes(score)) {
      newScoreFilter = newScoreFilter.filter(item => item !== score);
    } else {
      newScoreFilter.push(score);
    }
    setFilterByScore(newScoreFilter);
  };

  const onApplyFilter = () => {
    onChangeFilterByScore(filterByScore);
    onChangeFilterBy(filterBy);
    onChangeFilterByReport(filterByReport);
    onChangeFilterByProduct(filterByProduct);
    onClose();
  };

  useEffect(() => {
    if (filterByScoreInitiate !== filterByScore) setFilterByScore(filterByScoreInitiate);
  }, [filterByScoreInitiate]);

  useEffect(() => {
    if (filterByInitiate !== filterBy) setFilterBy(filterByInitiate);
  }, [filterByInitiate]);

  useEffect(() => {
    if (filterByProductInitiate !== filterByProduct) setFilterByProduct(filterByProductInitiate);
  }, [filterByProductInitiate]);

  useEffect(() => {
    if (filterByReportInitiate !== filterByReport) setFilterByReport(filterByReportInitiate);
  }, [filterByReportInitiate]);

  useEffect(() => {
    if (isObjectNotEqual(ratingSummary, ratingData)) setRatingData(ratingSummary);
  }, [ratingSummary]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Typography variant="h6" className={classes.drawerHeader}>
        {getLabel(LangConstant.TXT_FILTER)}
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <Close />
        </IconButton>
      </Typography>
      <Box className={classes.reviewFilter}>
        <FilterByScore filterByScore={filterByScore} ratingData={ratingData} onSelectScore={onChangeScoreFilter} />
        <FilterByType
          filterBy={filterBy}
          onChangeFilterByType={setFilterBy}
          filterByReport={filterByReport}
          onChangeFilterByReport={setFilterByReport}
        />
        {filterBy === FILTER_BY.product && (
          <FilterByProduct filterByProduct={filterByProduct} onSelectProduct={onSelectFilterByItem} />
        )}
      </Box>

      <Button
        variant="contained"
        color="primary"
        className={clsx(classes.applyButton, "semiBold-md-txt")}
        onClick={onApplyFilter}
      >
        {getLabel(LangConstant.TXT_APPLY)}
      </Button>
    </Drawer>
  );
};

MobileFilter.propTypes = {
  open: PropTypes.bool,
  filterByReportInitiate: PropTypes.bool,

  filterByInitiate: PropTypes.number,

  filterByScoreInitiate: PropTypes.array,
  filterByProductInitiate: PropTypes.array,

  onClose: PropTypes.func,
  onChangeFilterBy: PropTypes.func,
  onChangeFilterByScore: PropTypes.func,
  onChangeFilterByReport: PropTypes.func,
  onChangeFilterByProduct: PropTypes.func,
};

const FILTER_BY = {
  shop: 1,
  product: 2,
};

const useStyles = makeStyles(theme => ({
  reviewFilter: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "43px 18px",
  },

  drawerPaper: {
    width: 250,
    paddingBottom: 46,
  },

  drawerHeader: {
    backgroundColor: theme.palette.secondary.dark,
    textAlign: "center",
    padding: "48px 0",
    color: theme.palette.white,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  closeIcon: {
    position: "absolute",
    right: 20,
    color: theme.palette.white,
  },

  applyButton: {
    borderRadius: 2,
    width: 90,
    height: 30,
    minHeight: 30,
    textTransform: "none",
    alignSelf: "center",
    padding: 0,
  },
}));

export default MobileFilter;
