import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey } from "utils";

const BankingInfoItem = ({ onEdit, onDelete, data, label }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PAYMENT_SETUP);

  return (
    <Box className={classes.itemRoot}>
      <Box className={classes.labelBox}>
        <Typography className={clsx(classes.bankingInfoLabel, "semiBold-sm-txt")}>{label} </Typography>
        <Box>
          <Button
            variant="text"
            className={clsx(classes.bankingItemAction, "semiBold-sm-txt")}
            color="primary"
            onClick={onEdit}
          >
            {getLabel(getCommonKey(LangConstant.TXT_SHORT_EDIT))}
          </Button>
          <Button
            variant="text"
            className={clsx(classes.bankingItemAction, classes.deleteBtn, "semiBold-sm-txt")}
            color="primary"
            onClick={onDelete}
          >
            {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
          </Button>
        </Box>
      </Box>
      <Grid container className={classes.bankingInfoContainer}>
        <Grid item xs={4}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>
            {getLabel(LangConstant.TXT_BANKING_SERVICE_INFO)}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>{data.serviceName}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.bankingInfoContainer}>
        <Grid item xs={4}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>
            {getLabel(LangConstant.TXT_ACCOUNT_NUMBER)}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>{data.accountNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.bankingInfoContainer}>
        <Grid item xs={4}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>
            {getLabel(LangConstant.TXT_ACCOUNT_OWNER)}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>{data.accountOwner}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.bankingInfoContainer}>
        <Grid item xs={4}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>
            {getLabel(LangConstant.TXT_BANKING_CONTENT)}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography className={clsx(classes.bankingDetail, "regular-md-txt")}>{data.bankingContent}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

BankingInfoItem.propTypes = {
  data: PropTypes.shape({
    serviceName: PropTypes.string,
    accountNumber: PropTypes.string,
    accountOwner: PropTypes.string,
    bankingContent: PropTypes.string,
  }),

  label: PropTypes.string,

  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

BankingInfoItem.defaultProps = {
  data: {
    serviceName: "",
    accountNumber: "",
    accountOwner: "",
    bankingContent: "",
  },

  label: "",

  onEdit: () => {},
  onDelete: () => {},
};

export default BankingInfoItem;

const useStyles = makeStyles(() => ({
  itemRoot: {
    marginTop: 24,
    width: "100%",
  },

  bankingItemAction: {
    textTransform: "none",
    textDecoration: "underline",
    padding: 0,
    minWidth: "unset",
  },

  deleteBtn: {
    marginLeft: 24,
  },

  bankingInfoContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },

  labelBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
  },

  bankingInfoLabel: {
    lineHeight: "20px",
  },

  bankingDetail: {
    lineHeight: "22px",
  },
}));
