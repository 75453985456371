import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  Box,
  makeStyles,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Close, CropFree, NavigateBefore, NavigateNext } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { getCommonKey } from "utils";
import { AppConstant, LangConstant } from "const";
import CropDialogTitle from "./CropImageDialogTitle";
import CropImageInterface from "./CropImageInterface";

const CropImage = props => {
  const {
    title,
    onSubmit,
    onClose,
    aspect,
    isMultiple,
    isShow,
    inputArray,
    isEnableCrop,
    selectedImage,
    onRemoveIndexedImage,
  } = props;

  const cropInterfaceRef = useRef();
  const inputElementRef = useRef();
  const theme = useTheme();
  const classes = useStyles({ isMultiple: isMultiple });
  const { t: getLabel } = useTranslation();
  const isMediumMobile = useMediaQuery(theme.breakpoints.down(1125));
  const isMobile = useMediaQuery(theme.breakpoints.down(750));

  const [fileArray, setFileArray] = useState([]);
  const [croppedImage, setCroppedImage] = useState({
    croppedImageFile: null,
    croppedImageUrl: null,
  });
  const [openCrop, setOpenCrop] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [previewURLsArray, setPreviewURLsArray] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [onConfirmSaveCrop, setOnConfirmSaveCrop] = useState(false);
  const [pendingIndex, setPendingIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState();
  const [isClosingDialog, setIsClosingDialog] = useState(false);

  const onSelectImage = e => {
    if (e.target.files && e.target.files.length > 0 && !inputArray) {
      let tmpArr = [];
      Array.from(e.target.files).forEach(file => {
        if (AppConstant.ACCEPT_IMAGE.includes(file.type)) {
          tmpArr.push(file);
        }
      });
      setFileArray(tmpArr);
    }
  };

  const onToggleCropping = () => {
    setIsCropping(!isCropping);
  };

  const onCloseCropDialog = () => {
    setOpenCrop(false);
    onClose();
    setFileArray([]);
    setPreviewURLsArray([]);
    setIsCropping(false);
    setCroppedImage({
      croppedImageFile: null,
      croppedImageUrl: null,
    });
    setSelectedImageIndex(0);
    cropInterfaceRef.current.onReset();
  };

  const onChangeSelectedImage = index => {
    if (index !== selectedImageIndex) {
      if (isCropping) {
        setPendingIndex(index);
        setOnConfirmSaveCrop(true);
      } else {
        setSelectedImageIndex(index);
      }
    }
  };

  const saveCrop = confirmState => {
    if (confirmState) {
      onCropComplete();
    }
    if (isClosingDialog) {
      onCloseCropDialog();
    }
    setIsCropping(false);
    setSelectedImageIndex(pendingIndex);
    setOnConfirmSaveCrop(false);
  };

  const onCropComplete = () => {
    if (isCropping) {
      if (isMultiple) {
        let tmpArr = fileArray;
        tmpArr[selectedImageIndex] = croppedImage.croppedImageFile;
        setFileArray(tmpArr);
        setCroppedImage({
          croppedImageFile: null,
          croppedImageUrl: null,
        });
        setIsCropping(false);
        cropInterfaceRef.current.onReset();
        onSubmit(tmpArr);
      } else {
        onSubmit(croppedImage.croppedImageFile);
        onCloseCropDialog();
      }
    } else {
      onSubmit(isMultiple ? fileArray : fileArray[selectedImageIndex]);
      onCloseCropDialog();
    }
  };

  const onRemoveImage = index => {
    if (fileArray.length >= 1) {
      if (onRemoveIndexedImage) {
        onRemoveIndexedImage(index);
      } else {
        let tmpArr = fileArray;
        tmpArr.splice(index, 1);
        setFileArray(tmpArr);
      }
      let tmpURLArr = previewURLsArray;
      tmpURLArr.splice(index, 1);
      setPreviewURLsArray(tmpURLArr);
    } else {
      onCloseCropDialog();
    }
  };

  useEffect(() => {
    if (isShow) {
      if (inputArray) {
        setFileArray(inputArray);
        if (selectedImage !== 0) {
          setSelectedImageIndex(selectedImage);
        }
      } else {
        inputElementRef.current.click();
        onClose();
      }
    }
  }, [isShow]);

  useEffect(() => {
    if (fileArray.length > 0) {
      setOpenCrop(true);
      let tmpArrURL = [];
      fileArray.forEach(file => {
        tmpArrURL.push(URL.createObjectURL(file));
        setPreviewURLsArray(tmpArrURL);
      });
    }
  }, [fileArray, fileArray[selectedImageIndex]]);

  useEffect(() => {
    if ((fileArray && fileArray.length === 0) || (inputArray && inputArray.length === 0)) {
      onClose();
    }
  }, [fileArray, inputArray]);

  return (
    <>
      {fileArray.length > 0 && (
        <>
          <Dialog
            classes={{ paper: classes.mainDialogContainer }}
            aria-labelledby="upload-image-dialog-title"
            open={openCrop}
            maxWidth="xl"
            fullScreen={isMediumMobile}
          >
            <CropDialogTitle
              id="upload-image-dialog-title"
              onClose={() => {
                if (isCropping) {
                  setIsClosingDialog(true);
                  setOnConfirmSaveCrop(true);
                } else {
                  onCloseCropDialog();
                }
              }}
            >
              <Typography variant="h5" component="p" className="medium-xl-txt">
                {title}
              </Typography>
            </CropDialogTitle>
            <Box className={classes.cropContainer}>
              <Box className={classes.leftSideBox}>
                {isMultiple && !isMobile && (
                  <IconButton
                    onClick={() => {
                      if (selectedImageIndex > 0) {
                        onChangeSelectedImage(selectedImageIndex - 1);
                      }
                    }}
                    classes={{ root: classes.navigateButton }}
                  >
                    <NavigateBefore className={classes.carouselArrows} />
                  </IconButton>
                )}
                <Box className={classes.leftSideBoxImageArea}>
                  <Box className={classes.leftSideBoxTittle}>
                    {isMultiple && (
                      <Typography className={`${classes.imageIndex} medium-sm-txt`}>
                        {getLabel(LangConstant.TXT_IMAGE)} {selectedImageIndex + 1}/{fileArray && fileArray.length}
                      </Typography>
                    )}
                    <Button
                      className={`${
                        isCropping ? classes.toggleCropFunctionActive : classes.toggleCropFunction
                      }  medium-sm-txt`}
                      onClick={onToggleCropping}
                    >
                      <CropFree className={classes.cropIcon} />
                      &nbsp;{getLabel(LangConstant.TXT_CROP_IMAGE)}
                    </Button>
                    {isMultiple && isMobile && (
                      <Box display="flex" justifyContent="flex-end">
                        <IconButton
                          onClick={() => {
                            if (selectedImageIndex > 0) {
                              onChangeSelectedImage(selectedImageIndex - 1);
                            }
                          }}
                          classes={{ root: classes.navigateButton }}
                        >
                          <NavigateBefore className={classes.carouselArrows} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (selectedImageIndex < fileArray.length - 1) {
                              onChangeSelectedImage(selectedImageIndex + 1);
                            }
                          }}
                          classes={{ root: classes.navigateButton }}
                        >
                          <NavigateNext className={classes.carouselArrows} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                  <DialogContent classes={{ root: isCropping ? classes.cropContentCropping : classes.cropContent }}>
                    {fileArray.length > 0 && (
                      <CropImageInterface
                        isEnableCrop={isEnableCrop}
                        isCropping={isCropping}
                        setIsCropping={setIsCropping}
                        ref={cropInterfaceRef}
                        onSubmit={setCroppedImage}
                        file={fileArray[selectedImageIndex]}
                        aspect={aspect}
                      />
                    )}
                  </DialogContent>
                </Box>
                {isMultiple && !isMobile && (
                  <IconButton
                    onClick={() => {
                      if (selectedImageIndex < fileArray.length - 1) {
                        onChangeSelectedImage(selectedImageIndex + 1);
                      } else {
                        onChangeSelectedImage(0);
                      }
                    }}
                    classes={{ root: classes.navigateButton }}
                  >
                    <NavigateNext className={classes.carouselArrows} />
                  </IconButton>
                )}
              </Box>

              <Box className={classes.imagePreviewList}>
                {!isMobile && (
                  <Box className={classes.previewList}>
                    {previewURLsArray.length > 0 &&
                      previewURLsArray.map((url, index) => (
                        <Box
                          key={index}
                          className={index === selectedImageIndex ? classes.selectedPreviewField : classes.previewField}
                          onMouseEnter={() => {
                            setHoveredIndex(index);
                          }}
                          onMouseLeave={() => {
                            setHoveredIndex(null);
                          }}
                        >
                          <Box className={classes.imageCell}>
                            <img className={classes.imagePreview} src={url} />
                            {index === hoveredIndex && (
                              <Box className={classes.hoverBackgroundBox}>
                                <Box
                                  height="100%"
                                  width="100%"
                                  position="absolute"
                                  onClick={() => {
                                    onChangeSelectedImage(index);
                                  }}
                                />
                                <IconButton
                                  className={classes.removeImageIcon}
                                  onClick={() => {
                                    onRemoveImage(index);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      ))}
                  </Box>
                )}

                <Box className={classes.cropDialogAction} component="div">
                  <Button
                    className={`${classes.cancelButton} medium-md-txt`}
                    onClick={() => {
                      if (isCropping) {
                        setIsClosingDialog(true);
                        setOnConfirmSaveCrop(true);
                      } else {
                        onCloseCropDialog();
                      }
                    }}
                    variant="contained"
                  >
                    {getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
                  </Button>
                  <Button
                    className={`${classes.saveButton} medium-md-txt`}
                    variant="contained"
                    color="primary"
                    onClick={onCropComplete}
                  >
                    {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Dialog>

          <Dialog classes={{ paper: classes.saveConfirmDialog }} open={onConfirmSaveCrop}>
            <Box className={classes.saveConfirmContent}>
              <Typography className={`${classes.saveDialogTitle} medium-xl-txt`}>
                {getLabel(LangConstant.TXT_SAVE_CROP)}
              </Typography>
              <Typography className={classes.saveDialogText}>
                {getLabel(LangConstant.TXT_REQUIRE_SAVE_MESSAGE)}
              </Typography>
            </Box>
            <Box className={classes.saveDialogAction} component="div">
              <Button
                className={`${classes.cancelButton} medium-md-txt`}
                onClick={() => {
                  saveCrop(false);
                }}
                variant="contained"
              >
                {getLabel(getCommonKey(LangConstant.TXT_CANCEL_ACTION))}
              </Button>
              <Button
                className={`${classes.saveButton} medium-md-txt`}
                variant="contained"
                color="primary"
                onClick={() => {
                  saveCrop(true);
                  setIsClosingDialog(false);
                }}
              >
                {getLabel(getCommonKey(LangConstant.TXT_SAVE))}
              </Button>
            </Box>
          </Dialog>
        </>
      )}
      <input
        ref={inputElementRef}
        onChange={onSelectImage}
        id="files"
        style={{ display: "none" }}
        type="file"
        accept={AppConstant.ACCEPT_IMAGE}
        multiple={isMultiple}
      />
    </>
  );
};

export default memo(CropImage);

const useStyles = makeStyles(theme => ({
  cropContainer: {
    display: "flex",
    height: 650,
    minHeight: 350,
    [theme.breakpoints.down(1125)]: {
      height: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.down(750)]: {
      height: "100%",
    },
  },
  imagePreviewList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 16,
    justifyContent: "space-between",
  },
  imagePreview: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  selectedPreviewField: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 150,
    width: 200,
    padding: 8,
    backgroundColor: "rgb(239,88,69,0.4)",
    marginBottom: 8,
  },
  previewField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 150,
    width: 200,
    padding: 8,
    backgroundColor: "rgb(179,181,186,0.4)",
    marginBottom: 8,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(239,88,69,0.4)",
    },
  },
  cropContent: {
    padding: 0,
    minWidth: 550,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 250,
    width: "100%",
    [theme.breakpoints.down(750)]: {
      minWidth: "unset",
      padding: "8px 12px",
    },
  },
  cropContentCropping: {
    padding: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(750)]: {
      minWidth: "unset",
      padding: "8px 12px",
      placeContent: "space-between",
      position: "relative",
      overflow: "hidden",
    },
  },
  previewList: {
    maxHeight: "100%",
    overflow: "auto",
    width: "100%",
    padding: "10px 25px",
    [theme.breakpoints.down(1125)]: {
      display: "flex",
    },
  },
  cropDialogAction: {
    display: "flex",
    placeContent: "space-between",
    boxShadow: "0 -1px 2px 0 rgba(0, 0, 0, 0.15)",
    padding: "11px 25px 0 25px",
    width: "100%",
    justifyContent: "flex-end",
  },
  leftSideBox: {
    display: "flex",
    backgroundColor: "#343434",
    paddingBottom: 16,
    width: 800,
    position: "relative",
    justifyContent: props => (props.isMultiple ? "space-between" : "center"),
    [theme.breakpoints.down(1125)]: {
      width: "auto",
      height: "70%",
    },
    [theme.breakpoints.down(750)]: {
      paddingBottom: 0,
      height: "90%",
    },
  },
  leftSideBoxImageArea: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "85%",
  },
  leftSideBoxTittle: {
    display: "flex",
    position: "relative",
    minHeight: "fit-content",
    placeContent: "center",
    alignItems: "center",
    margin: "10px 0px",
    gridTemplateColumns: props => (props.isMultiple ? "repeat(3, minmax(20px, 1fr))" : "unset"),
    [theme.breakpoints.down(750)]: {
      margin: "10px 12px",
      display: "grid",
      gridAutoFlow: "column",
    },
  },
  imageIndex: {
    color: "#fff",
    position: "absolute",
    left: 0,
    [theme.breakpoints.down(750)]: {
      position: "unset",
      textAlign: "left",
    },
  },
  toggleCropFunction: {
    backgroundColor: "#4b4d53",
    color: "#fff",
    textTransform: "none",
    borderRadius: 4,
    height: 24,
    border: "1px solid #4b4d53",
  },
  toggleCropFunctionActive: {
    backgroundColor: "#447aff",
    color: "#fff",
    textTransform: "none",
    borderRadius: 4,
    height: 24,
    border: "1px solid #4b4d53",
  },
  carouselArrows: {
    color: "#fff",
    cursor: "pointer",
  },
  navigateButton: {
    padding: 0,
    display: "flex",
    alignSelf: "center",
    width: 40,
    height: 40,
    backgroundColor: "#3e4045",
    borderRadius: "unset",
    margin: 5,
    "&:hover": {
      backgroundColor: "#979aa1",
    },
    [theme.breakpoints.down(750)]: {
      margin: 0,
    },
  },
  cancelButton: {
    borderRadius: 4,
    minHeight: "unset",
    height: 30,
    padding: 0,
    textTransform: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  saveButton: {
    minHeight: "unset",
    height: 30,
    borderRadius: 4,
    padding: 0,
    textTransform: "none",
  },
  cropIcon: {
    fontSize: "1rem",
  },
  saveConfirmContent: {
    padding: 24,
  },
  saveDialogAction: {
    padding: "16px 24px",
    display: "flex",
    justifyContent: "flex-end",
    boxShadow: "0 -1px 2px 0 rgba(0, 0, 0, 0.15)",
  },
  saveDialogText: {
    color: "#6c7078",
    fontSize: 13,
    paddingTop: 16,
  },
  saveConfirmDialog: {
    width: 450,
  },
  removeImageIcon: {
    color: "#fff",
    position: "absolute",
    top: "4%",
    right: "4%",
    padding: 0,
    zIndex: 1000,
    opacity: 1,
  },
  hoverBackgroundBox: {
    backgroundColor: "rgb(62,64,69, 0.5)",
    minWidth: "100%",
    minHeight: "100%",
    position: "absolute",
  },
  imageCell: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
}));

CropImage.propTypes = {
  aspect: PropTypes.number,
  isMultiple: PropTypes.bool,
  title: PropTypes.string,
  isShow: PropTypes.bool,
  inputArray: PropTypes.array,
  isEnableCrop: PropTypes.bool,
  selectedImage: PropTypes.number,

  onRemoveIndexedImage: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
CropImage.defaultProps = {
  selectedImage: 0,
  isEnableCrop: false,
  isMultiple: false,
  aspect: 1 / 1,
};
