import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import StringFormat from "string-format";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Button,
  Box,
  Dialog,
} from "@material-ui/core";
import { Close, Comment } from "@material-ui/icons";
import { getTime, parse } from "date-fns";
import {
  TabReservation,
  TabConsumer,
  TabPeriodic,
  TabExtend,
  TabPreOrder,
} from "pages/MnReservation/components/DialogBooking/Tab";
import ReservationAction from "redux/reservation.redux";
import { AppConstant, LangConstant } from "const";
import { getCommonKey } from "utils";
import StaffAction from "redux/staff.redux";
import AddNoteDialog from "./AddNoteDialog";
import ViewModeDialogActions from "./ViewModeDialogActions";
import EditModeDialogActions from "./EditModeDialogActions";

const BookingDialogHistory = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);
  const currentRef = useRef(null);
  const dispatch = useDispatch();
  const staffs = useSelector(state => state.staffRedux.staff);
  const [reservation, isFetching, periodicData, isDetailSuccess, reservationSetting] = useSelector(
    state => [
      state.reservationRedux.reservation,
      state.reservationRedux.isFetching,
      state.reservationRedux.reservationPeriodic,
      state.reservationRedux.isDetailSuccess,
      state.reservationRedux.reservationSetting,
    ],
    shallowEqual,
  );
  let isSettingTotal = reservationSetting && reservationSetting[4] && reservationSetting[4].publish === 1;

  const [valueTabs, setValueTabs] = useState(AppConstant.TAB_BOOKING_DETAIL.reservation);
  const [detail, setDetail] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isExtending, setIsExtending] = useState(false);
  const [dataPeriodic, setDataPeriodic] = useState({});
  const [dataExtend, setDataExtend] = useState([]);
  const [isContainPast, setIsContainPast] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [internalNote, setInternalNote] = useState();
  const [dialogAnchor, setDialogAnchor] = useState(null);
  const [isEdited, setIsEdited] = useState(false);

  const onOpenAddingNoteModal = () => {
    setDialogAnchor(currentRef.current);
    setIsAddingNote(true);
  };
  const onCloseAddingNoteModal = () => {
    setIsAddingNote(false);
  };

  const onChangeValueTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const onOpenEditing = () => {
    setIsEditing(true);
  };

  const onOpenCopying = () => {
    setIsCopying(true);
    if (valueTabs === AppConstant.TAB_BOOKING_DETAIL.periodic) {
      setValueTabs(AppConstant.TAB_BOOKING_DETAIL.reservation);
    }
    if (detail.startTime < Date.now() / 1000) {
      setDetail({ ...detail, date: "", time: "" });
    }
  };

  const onOpenExtending = () => {
    setIsExtending(true);
    setDataExtend([]);
  };

  const onCancel = () => {
    setDetail(reservation);
    if (valueTabs === AppConstant.TAB_BOOKING_DETAIL.periodic) {
      dispatch(ReservationAction.getReservationPeriodic({ uid: reservation.uid }));
    }
    setIsEdited(false);
    setIsEditing(false);
    setIsExtending(false);
    setIsCopying(false);
  };

  const onSave = () => {
    if (isEditing) {
      if (isSettingTotal)
        onSubmit({ ...detail, man: detail.man > 0 ? detail.man : 1, child: detail.child ? detail.child : 0 });
      else {
        onSubmit({ ...detail, totalConsumer: detail.totalConsumer > 0 ? detail.totalConsumer : 1 });
      }
      setIsEditing(false);
    } else if (isCopying) {
      dispatch(
        ReservationAction.postReservation({
          newReservation: {
            ...detail,
            startTime: Math.floor(
              getTime(parse(detail.date + " " + detail.time, AppConstant.FM_HH_MM_DD_MM_YYYY, new Date())) / 1000,
            ),
            endTime: detail.endTime,
            totalConsumer: detail.totalConsumer,
            child: detail.child,
            man: detail.man,
            note: detail.note,
            name: detail.name,
            email: detail.email,
            phoneNumber: detail.phoneNumber,
            copyReservationUid: reservation.uid,
          },
          isSettingTotal: isSettingTotal,
        }),
      );
      dispatch(ReservationAction.getReservation({ uid: reservation.uid }));
    } else if (isExtending) {
      dispatch(ReservationAction.postReservationExtend({ periodic: dataExtend, reservation_uid: detail.uid }));
      setIsExtending(false);
    }
    setIsEdited(false);
  };

  const onAddInternalNote = () => {
    if (internalNote) {
      onSubmit({ ...detail, internalNote: internalNote });
      setIsAddingNote(false);
    }
  };

  useEffect(() => {
    dispatch(StaffAction.requestStaff());
  }, []);

  useEffect(() => {
    if (periodicData && periodicData.periodic) {
      setDataPeriodic(periodicData);
    } else {
      setDataPeriodic({
        periodic: [
          {
            code: detail.code,
            status: detail.status,
            startTime: detail.startTime,
            available: AppConstant.STT_AVAILABLE.available,
          },
        ],
      });
    }
  }, [periodicData]);

  useEffect(() => {
    if (reservation && !isFetching) {
      setDetail(reservation);
    }
  }, [reservation, isFetching]);

  useEffect(() => {
    if (reservation?.uid) dispatch(ReservationAction.getReservationPeriodic({ uid: reservation.uid }));
  }, [reservation]);

  useEffect(() => {
    if (isDetailSuccess) {
      dispatch(ReservationAction.resetReservation());
      dispatch(
        ReservationAction.getReservationDay({
          start: Math.floor(getTime(new Date().setHours(0, 0, 0)) / 1000),
          end: Math.floor(getTime(new Date().setHours(23, 59, 59)) / 1000),
          status: null,
        }),
      );
    }
  }, [isDetailSuccess]);

  let isViewMode = !(isEditing || isCopying || isExtending);
  let isIncludeInternal = detail.internalNote && detail.internalNote.content;
  let isDisableSaveButton = (isContainPast || !isEdited) && !isCopying && !isExtending;

  return (
    <>
      {Object.keys(detail).length > 0 && (
        <Dialog open={open} onClose={onClose}>
          <Card>
            {!isExtending ? (
              <AppBar className={classes.rootAppBar}>
                <CardHeader
                  title={
                    isCopying
                      ? getLabel(getCommonKey(LangConstant.TXT_COPY_RESERVATION))
                      : getLabel(getCommonKey(LangConstant.TXT_DETAIL_INFO))
                  }
                  classes={{
                    root: classes.rootHeader,
                    action: classes.actionHeader,
                    content: classes.contentHeader,
                    title: classes.titleHeader,
                  }}
                  action={
                    <IconButton
                      className={classes.iconClose}
                      onClick={() => {
                        onClose();
                        setIsEdited(false);
                        setIsEditing(false);
                        setIsExtending(false);
                        setIsCopying(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  }
                />
                <Tabs
                  classes={{
                    root: classes.rootTabs,
                    flexContainer: classes.flexContainer,
                    indicator: classes.indicatorTabs,
                  }}
                  onChange={onChangeValueTabs}
                  value={valueTabs}
                >
                  <Tab
                    label={getLabel(getCommonKey(LangConstant.TXT_RESERVATION))}
                    className={classes.rootReservation}
                  />
                  <Tab label={getLabel(LangConstant.TXT_BEFORE_BOOKING)} className={classes.rootReservation} />
                  <Tab
                    label={getLabel(getCommonKey(LangConstant.TXT_SHOP_CONSUMER))}
                    className={classes.rootConsumer}
                  />
                  {detail.periodicCode && !isCopying && (
                    <Tab label={getLabel(getCommonKey(LangConstant.TXT_PERIODIC))} className={classes.rootPeriodic} />
                  )}
                </Tabs>
              </AppBar>
            ) : (
              <AppBar className={classes.rootAppBarExtend}>
                <CardHeader
                  title={StringFormat(getLabel(getCommonKey(LangConstant.FM_EXTEND_RESERVATION)), detail.code)}
                  classes={{
                    root: classes.rootHeader,
                    action: classes.actionHeader,
                    content: classes.contentHeader,
                    title: classes.titleHeader,
                  }}
                  action={
                    <IconButton
                      className={classes.iconClose}
                      onClick={() => {
                        onClose();
                        setIsEdited(false);
                        setIsEditing(false);
                        setIsExtending(false);
                        setIsCopying(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  }
                />
              </AppBar>
            )}
            <CardContent
              className={
                !(isExtending || valueTabs === AppConstant.TAB_BOOKING_DETAIL.periodic)
                  ? classes.rootContent
                  : classes.rootExtend
              }
            >
              {!isExtending ? (
                <>
                  <TabReservation
                    value={valueTabs}
                    index={AppConstant.TAB_BOOKING_DETAIL.reservation}
                    detail={detail}
                    setDetail={setDetail}
                    isEditing={isEditing}
                    isCopying={isCopying}
                    staffs={staffs}
                    setIsEdited={setIsEdited}
                  />
                  {valueTabs === AppConstant.TAB_BOOKING_DETAIL.preOrder && (
                    <TabPreOrder isEditing={isEditing || isCopying} detail={detail} isReadOnly={true} />
                  )}
                  <TabConsumer
                    value={valueTabs}
                    index={AppConstant.TAB_BOOKING_DETAIL.shopConsumer}
                    detail={detail}
                    setDetail={setDetail}
                    isEditing={isEditing && !detail.userId}
                    isCopying={isCopying}
                    setIsEdited={setIsEdited}
                  />
                  {detail.periodicCode && !isCopying && (
                    <TabPeriodic
                      selectedDate={reservation.startTime}
                      value={valueTabs}
                      setValueTabs={setValueTabs}
                      index={AppConstant.TAB_BOOKING_DETAIL.periodic}
                      isEditing={isEditing}
                      dataParent={dataPeriodic}
                      setIsEdited={setIsEdited}
                    />
                  )}
                </>
              ) : (
                reservation && (
                  <TabExtend
                    detail={reservation}
                    dataPeriodic={dataPeriodic.periodic ? dataPeriodic.periodic : []}
                    lastCode={dataPeriodic.periodic ? dataPeriodic.periodic.length : 2}
                    dataExtend={dataExtend}
                    isContainPast={isContainPast}
                    setIsContainPast={setIsContainPast}
                    setDataExtend={setDataExtend}
                    setIsEdited={setIsEdited}
                  />
                )
              )}
              {onSubmit && (
                <Box className={classes.formActionContainer}>
                  {!isIncludeInternal && (
                    <Button className={classes.addInternalNoteBtn} onClick={onOpenAddingNoteModal} ref={currentRef}>
                      <Comment className={classes.addNoteIcon} />
                      {getLabel(LangConstant.TXT_ADD_INTERNAL_NOTE)}
                    </Button>
                  )}
                  {isViewMode ? (
                    <ViewModeDialogActions
                      onOpenCopying={onOpenCopying}
                      onOpenEditing={onOpenEditing}
                      onOpenExtending={onOpenExtending}
                      setIsEdited={setIsEdited}
                    />
                  ) : (
                    <Box className={!isExtending ? classes.rootFooter : classes.rootFooterExtending}>
                      <EditModeDialogActions
                        isDisableSaveButton={isDisableSaveButton}
                        onCancel={onCancel}
                        onSave={onSave}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>

          <AddNoteDialog
            dialogAnchor={dialogAnchor}
            isAddingNote={isAddingNote}
            onAddInternalNote={onAddInternalNote}
            onCloseAddingNoteModal={onCloseAddingNoteModal}
            setInternalNote={setInternalNote}
          />
        </Dialog>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  rootAppBar: {
    position: "relative",
    background: "#65b39d",
    [theme.breakpoints.up("sm")]: {
      minWidth: 531,
      height: 93,
    },
  },

  rootAppBarExtend: {
    position: "relative",
    background: "#65b39d",
    [theme.breakpoints.up("sm")]: {
      minWidth: 531,
      height: 60,
    },
  },

  rootHeader: {
    position: "relative",
    padding: "15px 24px",
    color: theme.palette.white,
    height: 54,
    marginBottom: 1,
  },

  rootTabs: {
    minHeight: 38,
    height: 38,
  },

  contentHeader: {
    flex: "none",
  },

  actionHeader: {
    marginTop: 0,
    marginLeft: "auto",
    marginRight: 0,
  },

  titleHeader: {
    ...theme.typography.subtitle2,
    color: theme.palette.white,
    fontSize: 20,
    fontWeight: 500,
    "@media only screen and (max-width: 375px)": {
      fontSize: 16,
    },
  },

  iconClose: {
    color: theme.palette.white,
    padding: 0,
  },

  flexContainer: {
    height: 38,
    minHeight: 38,
  },

  rootContent: {
    width: "100%",
    minHeight: "max-content",
    padding: "12px 24px",
    flexGrow: 1,
    "&>*": {
      color: theme.palette.grey[600],
    },
    "@media only screen and (max-width: 375px)": {
      maxHeight: "none",
      padding: "30px 20px 20px",
    },
    "&:last-child": {
      paddingBottom: 16,
    },
  },

  rootExtend: {
    width: "100%",
    maxHeight: "70vh",
    overflow: "auto",
    padding: 12,
    flexGrow: 1,
    "&>*": {
      color: theme.palette.grey[600],
    },
    "@media only screen and (max-width: 375px)": {
      maxHeight: "none",
      width: 531,
      height: 500,
      overflowX: "scroll",
    },
    "&:last-child": {
      paddingBottom: 16,
    },
  },

  indicatorTabs: {
    minWidth: 83,
    borderBottom: "2px solid #feba40",
  },

  rootReservation: {
    minWidth: 85,
    height: 38,
    minHeight: 38,
    textTransform: "none",
    fontWeight: "inherit",
    fontSize: 13,
    opacity: 1,
  },

  rootConsumer: {
    minWidth: 110,
    height: 38,
    minHeight: 38,
    textTransform: "none",
    fontWeight: "inherit",
    fontSize: 13,
    opacity: 1,
  },

  rootPeriodic: {
    minWidth: 83,
    height: 38,
    minHeight: 38,
    textTransform: "none",
    fontWeight: "inherit",
    fontSize: 13,
    opacity: 1,
  },

  rootFooter: {
    right: 10,
    textAlign: "right",
    width: "100%",
    padding: 0,
    "@media only screen and (max-width: 375px)": {
      position: "fixed",
      bottom: 10,
      padding: "0px 12px",
    },
  },

  rootFooterExtending: {
    right: 10,
    marginTop: 20,
    textAlign: "right",
    width: "100%",
    padding: "0px 24px",
    "@media only screen and (max-width: 375px)": {
      position: "fixed",
      bottom: 10,
      padding: "0px 12px",
    },
  },

  addInternalNoteBtn: {
    fontSize: 13,
    height: "fit-content",
    textTransform: "none",
    minWidth: "max-content",
    display: "flex",
    alignItems: "center",
    color: "#447aff",
    marginLeft: "-8px",
    fontWeight: "normal",
  },

  formActionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  addNoteIcon: {
    fontSize: 13,
    marginRight: 8,
  },
}));

export default BookingDialogHistory;
