import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Box, makeStyles, withStyles, OutlinedInput, InputAdornment } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { checkInteger, checkFloat } from "utils";
import { AppConstant } from "const";

const InputUsedTime = ({
  valueTime,
  valueTimeType,
  placeholderTime,
  dataChange,
  onChange,
  placeholderValue,
  onClick,
  isDisabled,
  className,
  unitPriceCalculateType,
  isService,
}) => {
  const [data, setData] = useState(valueTimeType ? valueTimeType.toString() : AppConstant.TYPE_TIME_USED.minute);
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(valueTime ? valueTime.toString() : "");

  const onChangeInput = event => {
    let inputValue = event.target.value;
    if (data == AppConstant.TYPE_TIME_USED.minute || data == AppConstant.TYPE_TIME_USED.day) {
      if ((inputValue === "" || checkInteger(inputValue)) && inputValue.length < 6) {
        if (Boolean(onChange)) {
          onChange(parseInt(inputValue));
        }
        setInputValue(inputValue);
      }
    } else if (data == AppConstant.TYPE_TIME_USED.hour) {
      if ((inputValue === "" || checkFloat(inputValue)) && inputValue.length < 6) {
        if (Boolean(onChange)) {
          onChange(parseFloat(inputValue));
        }
        setInputValue(inputValue);
      }
    }
  };

  const onChangeTimeInput = event => {
    setData(event.target.value);
    if (Boolean(onClick)) {
      onClick(event.target.value);
    }
  };

  useEffect(() => {
    if (isService) {
      setInputValue("");
      onChange("");
    }
  }, [isService]);

  useEffect(() => {
    if (inputValue === "" && unitPriceCalculateType == AppConstant.TYPE_UNIT_PRICE_CALCULATE.time) {
      setInputValue(30);
    }
  }, [unitPriceCalculateType]);

  return (
    <Box className={classes.root}>
      <OutlinedInput
        classes={{ root: className, input: classes.inputOutlined }}
        placeholder={placeholderValue}
        value={inputValue}
        disabled={isDisabled}
        onChange={onChangeInput}
        endAdornment={
          <InputAdornment position="end" className={classes.title}>
            <Select
              value={data}
              disabled={isDisabled}
              displayEmpty
              input={<BootstrapInput />}
              onChange={onChangeTimeInput}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={data !== "" ? undefined : () => <Placeholder>{placeholderTime}</Placeholder>}
            >
              {dataChange.map((item, index) => (
                <MenuItem key={index} className={classes.selectDefault} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        }
      />
    </Box>
  );
};

InputUsedTime.propTypes = {
  dataChange: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholderTime: PropTypes.string.isRequired,
  placeholderValue: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  unitPriceCalculateType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InputUsedTime.defaultProps = { placeholderTime: "", placeholderValue: "" };

export default memo(InputUsedTime);

const useStyles = makeStyles({
  root: {
    display: "flex",
    maxHeight: 40,
  },
  selectDefault: {
    "&:hover": {
      backgroundColor: "#4b4d53",
      color: "white",
    },
    "&:active": {
      backgroundColor: "#4b4d53",
      color: "white",
    },
    "&.MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "#4b4d53",
      color: "white",
    },
  },
  placeholder: {
    color: "#aaa",
  },
  title: {
    justifyContent: "center",
    borderLeft: "solid 0.5px #cecfd2",
    marginLeft: 0,
    height: 40,
    maxHeight: "100%",
  },
  inputOutlined: {
    fontSize: 14,
    "&.Mui-disabled": {
      cursor: "no-drop",
      color: "#3e4045",
    },
  },
});

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    textAlign: "left",
    minWidth: 50,
    position: "relative",
    fontSize: 14,
    border: 0,
    paddingLeft: "10%",
    "&.Mui-disabled": {
      cursor: "no-drop",
      color: "#3e4045",
    },
  },
}))(InputBase);

const Placeholder = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.placeholder}>{children}</div>;
};
