import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Tabs, Tab } from "@material-ui/core";
import { List } from "@material-ui/icons";
import { SearchBar } from "components";
import { GridViewIcon } from "icons";
import { useTranslation } from "react-i18next";
import { LangConstant } from "const";

const OptionView = ({ value, onChangeView, onSearch }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Box className={defaultClasses.root}>
      <SearchBar
        onKeyUp={onSearch}
        className={defaultClasses.searchBar}
        placeholder={getLabel(LangConstant.P_BOOKING_SEARCH)}
        customClasses={{ input: defaultClasses.inputPlaceholder, backgroundColor: defaultClasses.searchBarCustom }}
      />
      <Tabs
        value={value}
        onChange={onChangeView}
        classes={{ root: defaultClasses.tabRoot, indicator: defaultClasses.tabIndicator }}
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab
          classes={{ root: defaultClasses.tabItem, textColorInherit: defaultClasses.tabTextColor }}
          icon={<List />}
        />
        <Tab
          classes={{ root: defaultClasses.tabItem, textColorInherit: defaultClasses.tabTextColor }}
          icon={<GridViewIcon />}
        />
      </Tabs>
    </Box>
  );
};

OptionView.propTypes = {
  value: PropTypes.number,
  onChangeView: PropTypes.func,
  onSearch: PropTypes.func,
};
OptionView.defaultProps = {};

export default memo(OptionView);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  searchBar: {
    height: 30,
    width: "100%",
  },
  tabRoot: {
    minHeight: 30,
    width: 80,
    marginLeft: 16,
    overflow: "unset",
  },
  tabIndicator: {
    backgroundColor: "unset",
  },
  tabItem: {
    minWidth: 40,
    minHeight: 30,
    padding: 0,
    textTransform: "unset",
    border: "1px solid #d4d5d8",
    color: "#b3b5ba",
    "&.Mui-selected": {
      color: "#3e4045",
    },
  },
  tabTextColor: {
    opacity: 1,
    "&.Mui-selected": {
      backgroundColor: "#d4d5d8",
    },
  },
  searchBarCustom: {
    backgroundColor: "#f6f5f9",
  },
  inputPlaceholder: {
    "&::placeholder": {
      color: "#d4d5d8",
    },
  },
}));
