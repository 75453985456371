import { call, put } from "redux-saga/effects";
import { toCamel, toSnake } from "utils";
import { ApiConstant } from "const";
import ShopAction from "redux/shop.redux";
import { ShopService } from "services";

export function* requestGetInfo(action) {
  try {
    const { data } = action.data;
    let response = yield call(ShopService.get, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;

      yield put(ShopAction.getShopInfoSuccess(toCamel(responseData)));
    } else {
      yield put(ShopAction.getShopInfoFailure(response.data));
    }
  } catch (error) {
    yield put(ShopAction.getShopInfoFailure(error));
  }
}

export function* requestUpdateInfo(action) {
  try {
    const { data } = action;
    let response = yield call(ShopService.update, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ShopAction.updateShopInfoSuccess(toCamel(data)));
    } else {
      yield put(ShopAction.updateShopInfoFailure(response.data));
    }
  } catch (error) {
    yield put(ShopAction.updateShopInfoFailure(error));
  }
}

export function* requestUploadInfo(action) {
  try {
    const { data } = action;
    const formData = new FormData();
    formData.append("file", data.upload.file);
    formData.append("type", "image");
    let response = yield call(ShopService.upload, formData);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(ShopAction.uploadShopImageSuccess(toCamel(responseData)));
      yield put(ShopAction.requestUpdateShopInfo({ ...data.update, cover: responseData.uri }));
    } else {
      yield put(ShopAction.uploadShopImageFailure(response.data));
    }
  } catch (error) {
    yield put(ShopAction.uploadShopImageFailure(error));
  }
}

export function* requestFbPage(action) {
  try {
    const { data } = action;
    let response = yield call(ShopService.getFbPage, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      let responseData = { data: validateResponseArray(response.data.data) };
      if (data && data.isLogged) responseData = { ...responseData, isLogged: data.isLogged };
      yield put(ShopAction.getFacebookPageSuccess(responseData));
    } else {
      yield put(ShopAction.getFacebookPageFailure(response.data));
    }
  } catch (error) {
    yield put(ShopAction.getFacebookPageFailure(action.error));
  }
}

export function* requestPutFbPage(action) {
  try {
    const { data } = action;
    let response = yield call(ShopService.putFbLink, data);
    if (response.status === ApiConstant.STT_OK) {
      yield put(ShopAction.putFacebookLinkSuccess(data));
    } else {
      yield put(ShopAction.putFacebookLinkFailure(response));
    }
  } catch (error) {
    yield put(ShopAction.putFacebookLinkFailure(action.error));
  }
}

const validatePages = item => {
  const validatedPage = {
    name: item.name ? item.name : "",
    uid: item.uid ? item.uid : "",
    state: item.state ? item.state : 0,
    avatar: item.avatar ? item.avatar : "",
  };
  return validatedPage;
};
const validateResponseArray = responseArray => {
  const validatedArray = responseArray
    ? responseArray.map(value => {
        return validatePages(value);
      })
    : [];
  return validatedArray;
};
