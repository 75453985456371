import { call, put } from "redux-saga/effects";
import { ApiConstant, AppConstant } from "const";
import ShopInfoAction from "redux/shop-info.redux";
import { ShopInfoService } from "services";
import { toCamel, toSnake } from "utils";
import StringFormat from "string-format";

export function* getShopInfoData() {
  try {
    let response = yield call(ShopInfoService.get);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;

      yield put(ShopInfoAction.getShopInfoDataSuccess(parseShopInfoData(responseData)));
    } else {
      yield put(ShopInfoAction.getShopInfoDataFailure(response.data));
    }
  } catch (error) {
    yield put(ShopInfoAction.getShopInfoDataFailure(error));
  }
}

export function* updateShopInfoData(action) {
  try {
    const { data } = action;
    let response = yield call(ShopInfoService.update, toSnake(data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(ShopInfoAction.updateShopInfoDataSuccess(parseShopInfoData(response.data.data)));
    } else {
      yield put(ShopInfoAction.updateShopInfoDataFailure(response.data));
    }
  } catch (error) {
    yield put(ShopInfoAction.updateShopInfoDataFailure(error));
  }
}

const parseShopInfoData = responseData => {
  var data = toCamel(responseData);

  //shop info social
  const dataSocial = data.social ? data.social : {};
  const socialWebsite = data.social ? data.social.website : "";
  const socialFacebook = data.social ? data.social.facebook : "";
  const socialGoogle = data.social ? data.social.google : "";

  dataSocial.website = socialWebsite;
  dataSocial.facebook = socialFacebook;
  dataSocial.google = socialGoogle;
  data.social = dataSocial;

  //shop info details
  const dataDetails = data.details ? data.details : {};

  const workingTime = data.details ? data.details.workingTime : [{ days: [], open: 0, close: 0 }];
  const consumerCapacity = data.details ? data.details.consumerCapacity : 0;

  dataDetails.workingTime = workingTime;
  dataDetails.consumerCapacity = consumerCapacity;
  data.details = dataDetails;
  const newData = {
    ...data,
    id: data.id ? data.id : "",
    username: data.username ? data.username : "",
    code: data.code ? data.code : "",
    state: data.state ? data.state : "",
    businessId: data.businessId ? data.businessId : "",
    name: data.name ? data.name : "",
    alias: data.alias ? data.alias : "",
    address: data.address ? data.address : "",
    ownerName: data.ownerName ? data.ownerName : "",
    phoneNumber: data.phoneNumber ? data.phoneNumber : "",
    email: data.email ? data.email : "",
    description: data.description ? data.description : "",
    socialWebsite: socialWebsite,
    socialFacebook: socialFacebook,
    socialGoogle: socialGoogle,
    workingDay: workingTime.days,
    openTime: workingTime.open,
    closeTime: workingTime.close,
    consumerCapacity: consumerCapacity,
    password: data.password ? data.password : "",
    avatar: data.avatar ? data.avatar : "",
    cover: data.cover ? data.cover : "",
  };

  // Create short link
  newData.facebookShortLink = StringFormat(AppConstant.FM_SHOP_FB_SHORT_LINK, newData?.alias);
  newData.googleShortLink = StringFormat(AppConstant.FM_SHOP_GMB_SHORT_LINK, newData?.alias);
  newData.gbossBookingLink = {
    link: StringFormat(ApiConstant.SHOP_URL, newData?.alias),
    shortenedSocialLink: StringFormat(AppConstant.FM_BOOKING_SHORT_LINK, newData?.alias),
  };
  newData.gbossShopLink = {
    link: StringFormat(ApiConstant.SHOP_URL, newData?.alias),
    shortenedSocialLink: StringFormat(AppConstant.FM_SHOP_SHORT_LINK, newData?.alias),
  };

  return newData;
};
