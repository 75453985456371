import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { DatePickerReservation } from "components/mnbooking";
import { MinuteHourTimePicker } from "components";
import clsx from "clsx";

const TimeAndDatePicker = ({ isEditing, title, data, onChangeDatePicker, onChangeTimePicker, classes }) => {
  const defaultClasses = useStyles();

  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [timestamp, setTimestamp] = useState();

  useEffect(() => {
    if (data.date) {
      setDate(data.date);
    }
    if (data.time) {
      let arrSplitCurrentTime = data.time.split(":");
      let currentHour = parseInt(arrSplitCurrentTime[0]);
      let currentMinute = parseInt(arrSplitCurrentTime[1]);
      setTime({
        hour: currentHour,
        minute: currentMinute,
      });
    }
    if (data.timestamp) {
      setTimestamp(data.timestamp);
    }
  }, [data]);

  return (
    <Box className={clsx(defaultClasses.root, classes?.root)}>
      <Typography className={defaultClasses.titleName}>{title}</Typography>
      <Box className={defaultClasses.boxContent}>
        {isEditing ? (
          <Box className={defaultClasses.boxTime}>
            {date && timestamp && (
              <DatePickerReservation
                onChange={onChangeDatePicker}
                selectedDateProp={new Date(timestamp * 1000)}
                isUseEffect={true}
              />
            )}
            {time && (
              <MinuteHourTimePicker
                onChange={onChangeTimePicker}
                classes={{ rootInputBox: defaultClasses.timePicker }}
                hourValue={time?.hour}
                minuteValue={time?.minute}
                required
              />
            )}
          </Box>
        ) : (
          <Box className={defaultClasses.boxWatchData}>
            <Typography className={defaultClasses.timeWatch}>{data?.date || ""}</Typography>
            <Typography className={defaultClasses.timeWatch}>{data?.time || ""}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

TimeAndDatePicker.propTypes = {
  isEditing: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
  }),
  onChangeDatePicker: PropTypes.func,
  onChangeTimePicker: PropTypes.func,
  classes: PropTypes.object,
};
TimeAndDatePicker.defaultProps = {
  isEditing: false,
  onChangeDatePicker: () => {},
  onChangeTimePicker: () => {},
};

export default memo(TimeAndDatePicker);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    minHeight: 30,
    display: "flex",
    marginBottom: 4,
  },
  titleName: {
    minWidth: "40%",
    height: 22,
    fontSize: 13,
    lineHeight: 1.69,
    marginTop: 4,
  },
  boxContent: {
    display: "flex",
    width: "60%",
    height: "inherit",
  },
  boxTime: {
    display: "flex",
    flexFlow: "wrap",
  },
  timePicker: {
    marginTop: 4,
  },
  boxWatchData: {
    minWidth: "40%",
    height: 22,
    display: "flex",
    marginTop: 4,
  },
  timeWatch: {
    fontSize: 13,
    lineHeight: 1.69,
    marginRight: 8,
  },
}));
