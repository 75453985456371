import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import InputDate from "../InputDate";
import DialogTimePicker from "../DialogTimePicker";
import { convertSmallNumber, onDoNothing } from "utils";

const ReservationTimeItem = ({ data: usingTimeSetting }) => {
  const defaultClasses = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const [timeArray, setTimeArray] = useState([]);

  useEffect(() => {
    if (usingTimeSetting) {
      setTimeArray(getTimeArrayInHourAndMinute(usingTimeSetting.details?.timeFrame));
    }
  }, [usingTimeSetting]);

  return (
    <>
      <Grid item xs={6} className={clsx(defaultClasses.gridItem, isMobile && defaultClasses.datePickerItem)}>
        <InputDate
          disablePast
          selectedDate={null}
          setSelectedDate={onDoNothing}
          available={true}
          classes={{ inputRoot: clsx(defaultClasses.dateInputRoot, "semiBold-md-txt") }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        className={clsx(defaultClasses.item, defaultClasses.gridItem, isMobile && defaultClasses.timePickerItem)}
      >
        <DialogTimePicker
          classes={{ inputRoot: clsx(defaultClasses.dateInputRoot, "semiBold-md-txt") }}
          timeArray={timeArray}
          available={true}
          isEnableClick={true}
        />
      </Grid>
    </>
  );
};
ReservationTimeItem.propTypes = {
  data: PropTypes.any,
  date: PropTypes.any,
  loop: PropTypes.any,

  index: PropTypes.number,

  isHover: PropTypes.bool,
  isEnableScheduleAction: PropTypes.bool,

  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onSelectLoopNumber: PropTypes.func,
  onSelectLoop: PropTypes.func,
  setData: PropTypes.func,
  setIsChangingDateState: PropTypes.func,
};
ReservationTimeItem.defaultProps = {};
export default memo(ReservationTimeItem);

const useStyles = makeStyles(() => ({
  item: {
    position: "relative",
  },
  gridItem: {
    paddingBottom: 0,
    height: "100%",
  },
  dateInputRoot: {
    marginBottom: 0,
    "&>input": {
      "&::placeholder": {
        fontSize: 14,
        fontWeight: "normal",
      },
    },
  },
  datePickerItem: {
    paddingRight: "0 !important",
  },
  timePickerItem: {
    paddingLeft: "0 !important",
  },
}));

const getTimeArrayInHourAndMinute = timeFrame => {
  if (!timeFrame) return [];
  let resultArr = [];
  let open = 21600;
  let close = 82800;
  let timeStep = Math.floor((close - open) / timeFrame);
  for (let index = 0; index < timeStep; index++) {
    if (open + timeFrame * index <= close) resultArr.push(returnFormattedTimeStep(open + timeFrame * index));
  }
  resultArr.push(returnFormattedTimeStep(close));

  return resultArr;
};

const returnFormattedTimeStep = seconds => {
  if (!seconds) return "";
  let hourValue = Math.floor(seconds / 3600);
  let minuteValue = Math.floor((seconds - hourValue * 3600) / 60);
  return `${convertSmallNumber(hourValue)}: ${convertSmallNumber(minuteValue)}`;
};
