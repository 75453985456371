import React, { memo, useState, useEffect } from "react";
import { makeStyles, Dialog, Box, IconButton, Typography, Button, useMediaQuery, useTheme } from "@material-ui/core";
import { Close, ArrowBack, Dehaze } from "@material-ui/icons";
import { Fragment } from "react";

const DialogAddCategorySelectMenu = ({ isOpen, onClose, onBack, onClick, nameHeader, data, isChildDefault }) => {
  const classes = useStyles();
  const [coefficient, setCoefficient] = useState(column.four);
  const [isChild, setIsChild] = useState(isChildDefault);
  const [lineIndex, setLineIndex] = useState(column.three);
  const [dataChildReplication, setDataChildReplication] = useState([]);
  const [indexArrayLine, setIndexArrayLine] = useState([]);
  const [dataParent] = useState(data);
  const [dataChild, setDataChild] = useState(null);
  const [dataParentIsChild, setDataParentIsChild] = useState(null);

  const theme = useTheme();
  const fourColumn = useMediaQuery(theme.breakpoints.up("lg"));
  const towColumn = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const threeColumn = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const oneColumn = useMediaQuery(theme.breakpoints.down("xs"));

  const onClickChild = data => {
    onClick(data, dataParentIsChild);
  };

  const createChildArrayIndex = (index, elementNumber) => {
    var indexCeil = 0;
    if (index % elementNumber === 0) {
      indexCeil = index + 1;
    } else {
      indexCeil = index;
    }
    var indexNumber = Math.ceil(indexCeil / elementNumber);
    var indexChild = 0;
    indexChild = indexNumber * elementNumber - 1;
    return indexChild;
  };

  const onClickCategoryMenu = (data, index) => {
    var lineChildPushReplication = data.child.length % coefficient;
    var indexChild;
    if (data.child.length > 0) {
      setDataParentIsChild(data);
      setDataChild(data.child);
      if (coefficient === column.four) {
        indexChild = createChildArrayIndex(index, column.four);
        setLineIndex(indexChild);
        if (indexArrayLine.includes(index)) {
          if (lineChildPushReplication === column.three) {
            setDataChildReplication([0]);
          } else if (lineChildPushReplication === column.two) {
            setDataChildReplication([0, 1]);
          } else if (lineChildPushReplication === column.one) {
            setDataChildReplication([0, 1, 2]);
          } else if (lineChildPushReplication === 0) {
            setDataChildReplication([]);
          }
        }
      } else if (coefficient === column.three) {
        indexChild = createChildArrayIndex(index, column.three);
        setLineIndex(indexChild);
        if (indexArrayLine.includes(index)) {
          if (lineChildPushReplication === column.two) {
            setDataChildReplication([0]);
          } else if (lineChildPushReplication === column.one) {
            setDataChildReplication([0, 1]);
          } else if (lineChildPushReplication === 0) {
            setDataChildReplication([]);
          }
        }
      } else if (coefficient === column.two) {
        indexChild = createChildArrayIndex(index, column.two);
        setLineIndex(indexChild);
        if (indexArrayLine.includes(index)) {
          if (lineChildPushReplication === column.one) {
            setDataChildReplication([0]);
          } else if (lineChildPushReplication === 0) {
            setDataChildReplication([]);
          }
        }
      }
      if (coefficient === column.one) {
        setLineIndex(index);
      }
      setIsChild(true);
    } else {
      onClick(data);
      setIsChild(false);
    }
  };

  useEffect(() => {
    var indexArray = [];
    var lineParentPushReplication;
    if (fourColumn) {
      setCoefficient(column.four);
      indexArray = createArray(data.length, column.four);
      setIndexArrayLine(indexArray);
      lineParentPushReplication = data.length % column.four;
      if (lineParentPushReplication === column.three) {
        dataParent.push("0");
      } else if (lineParentPushReplication === column.two) {
        dataParent.push("0", "1");
      } else if (lineParentPushReplication === column.one) {
        dataParent.push("0", "1", "2");
      }
    } else if (threeColumn) {
      setCoefficient(column.three);
      indexArray = createArray(data.length, column.three);
      setIndexArrayLine(indexArray);
      lineParentPushReplication = data.length % column.three;
      if (lineParentPushReplication === column.two) {
        dataParent.push("0");
      } else if (lineParentPushReplication === column.one) {
        dataParent.push("0", "1");
      }
    } else if (towColumn) {
      setCoefficient(column.two);
      indexArray = createArray(data.length, column.two);
      setIndexArrayLine(indexArray);
      lineParentPushReplication = data.length % column.two;
      if (lineParentPushReplication === column.one) {
        dataParent.push("0");
      }
    } else if (oneColumn) {
      setCoefficient(column.one);
    }
  }, [fourColumn, threeColumn, towColumn, oneColumn]);

  return (
    <Dialog open={isOpen} fullScreen>
      <Box className={classes.rootHeader}>
        <Box className={classes.boxIconBack}>
          <IconButton onClick={onBack} className={classes.iconButton}>
            <ArrowBack className={classes.iconClose} />
          </IconButton>
        </Box>
        <Typography className={classes.nameHeaderTypo}>{nameHeader}</Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButton}>
            <Close className={classes.iconClose} />
          </IconButton>
        </Box>
      </Box>
      <Box textAlign="center">
        <Box className={classes.listProductCategorySub}>
          {dataParent.map((data, index) => (
            <Fragment key={index}>
              {data?.name ? (
                <Box className={classes.boxMargin}>
                  <ButtonCategorySubCategory data={data} onClick={() => onClickCategoryMenu(data, index)} />
                </Box>
              ) : (
                <Box className={classes.buttonParentReplication} />
              )}
              {isChild &&
                index === lineIndex &&
                dataChild &&
                dataChild.map((data, indexChild) => (
                  <Box key={indexChild} className={classes.buttonChild}>
                    <Box margin="12px">
                      <ButtonCategorySubCategoryChild data={data} onClick={() => onClickChild(data)} />
                    </Box>
                  </Box>
                ))}
              {isChild &&
                index === lineIndex &&
                dataChildReplication.map((data, indexChild) => (
                  <Box key={indexChild} className={classes.buttonChild} />
                ))}
            </Fragment>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

const ButtonCategorySubCategory = ({ onClick, data }) => {
  const classes = useStyles();

  return (
    <Button onClick={onClick} classes={{ root: `semiBold-lg-txt ${classes.root}`, label: classes.labelButton }}>
      <Dehaze className={classes.icon} />
      {data.name}
    </Button>
  );
};

const ButtonCategorySubCategoryChild = ({ onClick, data }) => {
  const classes = useStyles();

  return (
    <Button classes={{ root: `semiBold-lg-txt ${classes.rootChild}`, label: classes.labelButton }} onClick={onClick}>
      <Dehaze className={classes.icon} />
      {data.name}
    </Button>
  );
};

export default memo(DialogAddCategorySelectMenu);

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    width: 360,
    height: 48,
    textAlign: "left",
    borderRadius: 4,
    border: "solid 1px #f2f2f2",
    textTransform: "none",
    [theme.breakpoints.down("lg")]: {
      width: 272,
    },
    "&:hover": {
      backgroundColor: "#447aff",
      color: theme.palette.white,
      "& >*": {
        "& >*": {
          color: theme.palette.white,
        },
      },
    },
    "&:focus": {
      backgroundColor: "#447aff",
      color: theme.palette.white,
      "& >*": {
        "& >*": {
          color: theme.palette.white,
        },
      },
    },
  },
  rootChild: {
    paddingTop: 10,
    width: 300,
    height: 43,
    textAlign: "left",
    borderRadius: 4,
    border: "solid 1px #f2f2f2",
    textTransform: "none",
    marginLeft: 20,
    [theme.breakpoints.down("lg")]: {
      width: 272,
    },
    "&:hover": {
      backgroundColor: "#4b4d53",
      color: theme.palette.white,
      "& >*": {
        "& >*": {
          color: theme.palette.white,
        },
      },
    },
  },
  labelButton: {
    alignItems: "center",
    justifyContent: "left",
    height: "100%",
  },
  icon: {
    marginLeft: 16,
    marginRight: 16,
    fontSize: 20,
    color: "#7f838c",
    height: "100%",
    "&:focus": {
      color: theme.palette.white,
    },
  },
  typography: {
    height: 48,
    padding: "12px 16px 12px 0",
    lineHeight: 1.5,
    width: "100%",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingLeft: 10,
    "&:focus": {
      color: theme.palette.white,
    },
  },
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      marginTop: 20,
    },
  },
  boxIconBack: {
    position: "absolute",
    top: 36,
    left: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      left: 10,
    },
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButton: {
    padding: 0,
  },
  iconClose: {
    width: 36,
    height: 36,
  },
  searchBar: {
    width: 400,
    height: 30,
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
  },
  listProductCategorySub: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(360px, 1fr))",
    marginLeft: "10%",
    marginRight: "10%",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(auto-fill,minmax(272px, 1fr))",
    },
  },
  buttonChild: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(360px, 1fr))",
    backgroundColor: "#fafafb",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(auto-fill,minmax(272px, 1fr))",
    },
  },
  buttonParentReplication: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill,minmax(360px, 1fr))",
    paddingLeft: "10%",
    paddingRight: "10%",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(auto-fill,minmax(272px, 1fr))",
    },
  },
  boxMargin: {
    margin: "12px 12px 12px 0px",
  },
}));

const createArray = (maxLength, unit) => {
  var arrayNew = [];
  for (var i = 0; i < maxLength; i++) {
    if (i * unit > maxLength) {
      break;
    }
    arrayNew.push(i);
  }
  return arrayNew;
};

const column = { one: 1, two: 2, three: 3, four: 4 };
