import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Comment, MoreHoriz } from "@material-ui/icons";
import { Box, Checkbox, makeStyles, TableRow, Typography, Button, Menu, MenuItem, debounce } from "@material-ui/core";
import clsx from "clsx";
import BillAction from "redux/bill.redux";
import ReservationAction from "redux/reservation.redux";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { getCommonKey, getStatusColor, isBlank, formatVietnameseCurrency } from "utils";
import { convertTimeStampToDate } from "utils/date";
import { CellBody } from "components";
import BillDetailDialog from "pages/BillManagement/components/BillDetailDialog";
import StatusDialogOrder from "./DialogStatus";
import DialogOrderDetail from "./DialogOrderDetail";
import DialogAddNewOrder from "./DialogAddNewOrder";

const BookingOrderTableRow = props => {
  const { dataShow, onClick } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_HISTORY);
  const billDetailReservation = useSelector(state => state.billRedux.billDetailReservation, shallowEqual);
  const [isUpdateSuccess, isSuccess] = useSelector(({ billRedux }) => [billRedux.isUpdateSuccess, billRedux.isSuccess]);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowNote, setIsShowNote] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODE.default);

  const onShowDialog = () => {
    setViewMode(VIEW_MODE.view);
  };

  const onClickStatus = () => {
    setIsOpenDialog(true);
  };

  const onSubmit = status => {
    dispatch(ReservationAction.putReservationStatus({ status: status, uid: dataShow.uid }));
    setIsOpenDialog(false);
  };

  const onClose = () => {
    setIsOpenDialog(false);
    setViewMode(VIEW_MODE.default);
    let originPath = window.location.pathname;

    history.push({
      pathname: originPath,
      search: "",
    });
  };

  const onSelectButton = event => {
    setAnchorEl(event.currentTarget);
  };

  const onChangeButton = event => {
    setViewMode(event.currentTarget.value);
    setAnchorEl(null);
  };

  const onShowDialogOrder = debounce(() => {
    let searchParams = new URLSearchParams(location.search);
    if (
      searchParams &&
      searchParams.get(AppConstant.KEY_START_TIME) &&
      searchParams.get(AppConstant.KEY_UID) === dataShow.uid
    ) {
      setViewMode(VIEW_MODE.view);
    }
  }, AppConstant.DEBOUNCE_TIMEOUT);

  const onAddBillDetail = detail => {
    dispatch(
      BillAction.createBill({
        ...detail,
        reservationUid: dataShow.uid,
      }),
    );
  };

  useEffect(() => {
    if (isUpdateSuccess || isSuccess) {
      setViewMode(VIEW_MODE.default);
      dispatch(
        BillAction.billSuccess({
          isUpdateSuccess: false,
        }),
      );
    }
  }, [isUpdateSuccess, isSuccess]);

  useEffect(() => {
    if (viewMode === VIEW_MODE.addBill) {
      dispatch(BillAction.billReset());
      dispatch(BillAction.getReservationBillDetail({ uid: dataShow.uid }));
    }
  }, [viewMode]);

  useEffect(() => {
    let isNote = !isBlank(dataShow?.note) || !isBlank(dataShow?.internalNote?.content);
    setIsShowNote(isNote);
  }, [dataShow]);

  useEffect(() => {
    onShowDialogOrder();
  }, [location]);

  let isHaveBillDetail = billDetailReservation && Boolean(billDetailReservation.uid);
  let paymentContent = dataShow?.details
    ? dataShow.details.depositType === SystemConstant.PAYMENT_METHOD.cash
      ? getLabel(LangConstant.TXT_CASH_SHORT)
      : getLabel(LangConstant.TXT_BANKING_SHORT)
    : "";

  return (
    <TableRow className={classes.rootTableRow}>
      <CellBody
        className={classes.firstTableCellHead}
        cellData={
          <Box className={classes.codeCell}>
            <Checkbox
              checked={dataShow.isCheck}
              onChange={() => onClick(dataShow)}
              color="default"
              classes={{
                root: classes.checkboxBasis,
                checked: clsx(classes.checkboxChecked, classes.checkboxBasis),
              }}
            />
            <Typography className={classes.bookingCode} onClick={onShowDialog}>
              {formatCode(dataShow)}
            </Typography>
          </Box>
        }
      />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={convertTimeStampToDate(dataShow.created || "", AppConstant.FM_HH_MM_DD_MM_YYYY)}
      />
      <CellBody className={classes.cellBodyRoot} cellData={dataShow.name || ""} />
      <CellBody className={classes.cellBodyRoot} cellData={dataShow.address || ""} />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={
          <Typography className={clsx(classes.totalCheckout, "regular-md-txt")}>
            {formatVietnameseCurrency(dataShow.total)} <span className={classes.paymentContent}>{paymentContent}</span>
          </Typography>
        }
      />
      <CellBody
        className={classes.cellBodyRoot}
        cellData={
          <Button
            style={{
              backgroundColor: getStatusColor(dataShow.status || 0),
            }}
            className={clsx("semiBold-sm-txt", classes.tableHeadLabel)}
            onClick={onClickStatus}
          >
            {getLabel(getCommonKey(LangConstant.OBJECT_STATUS_ORDER[dataShow.status]))}
          </Button>
        }
      />
      <CellBody
        className={classes.tableHeadLabelButton}
        cellData={
          <Box>
            {isShowNote && <Button onClick={onShowDialog} className={classes.icon} startIcon={<Comment />} />}
            <Button className={classes.iconThreeDot} onClick={onSelectButton}>
              <MoreHoriz />
            </Button>
            <Menu
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
            >
              <MenuItem className={classes.selectButton} value={VIEW_MODE.view} onClick={onChangeButton}>
                {getLabel(getCommonKey(LangConstant.TXT_SEEN_DETAIL))}
              </MenuItem>
              <MenuItem className={classes.selectButton} value={VIEW_MODE.edit} onClick={onChangeButton}>
                {getLabel(getCommonKey(LangConstant.TXT_EDIT))}
              </MenuItem>
              <MenuItem className={classes.selectButton} value={VIEW_MODE.addBill} onClick={onChangeButton}>
                {getLabel(LangConstant.TXT_EXPORT_BILL)}
              </MenuItem>
            </Menu>
          </Box>
        }
      />
      {isOpenDialog && (
        <StatusDialogOrder isShow={isOpenDialog} dataShow={dataShow} onClose={onClose} onSubmit={onSubmit} />
      )}

      {viewMode === VIEW_MODE.edit && (
        <DialogAddNewOrder
          open={viewMode === VIEW_MODE.edit}
          onClose={onClose}
          selectedItems={refactorSelectedData(dataShow)}
          isUpdatedOrder={viewMode === VIEW_MODE.edit}
        />
      )}

      {viewMode === VIEW_MODE.view && <DialogOrderDetail onClose={onClose} orderData={dataShow} />}

      {viewMode === VIEW_MODE.addBill && (
        <BillDetailDialog
          isEditing={isHaveBillDetail}
          onClose={onClose}
          data={billDetailReservation.uid ? billDetailReservation : DEFAULT_BILL_FORM}
          onSubmitAddBill={onAddBillDetail}
        />
      )}
    </TableRow>
  );
};

const DEFAULT_BILL_FORM = {
  status: 0,
  uid: "",
  reservation: {
    uid: "",
  },
  created: 0,
  companyName: "",
  taxCode: "",
  email: "",
  address: "",
  contactName: "",
};

const VIEW_MODE = {
  default: 0,
  view: 1,
  edit: 2,
  addBill: 3,
};

export const formatCode = data => {
  let code = "";
  if (data.code.indexOf("-") > 0) {
    code = `#${data.code.substring(data.code.indexOf("-") + 1)}`;
  }
  return code;
};

const refactorSelectedData = data => {
  let selectedData = { ...data };
  let arrSelectedItems =
    (data &&
      data.orderDetailsList?.length > 0 &&
      data.orderDetailsList.map(dataMap => ({
        ...dataMap,
        isSelectedItem: true,
        orderDetailsContentList: dataMap.orderDetailsContentList?.map(contentData => ({
          ...contentData,
          isSelectedItem: true,
        })),
      }))) ||
    [];
  selectedData.orderDetailsList = arrSelectedItems;
  return selectedData;
};

BookingOrderTableRow.propTypes = {
  dataShow: PropTypes.object.isRequired,
};
BookingOrderTableRow.defaultProps = {};

export default BookingOrderTableRow;

const useStyles = makeStyles(theme => ({
  rootTableRow: {
    height: 40,
    "&:hover": {
      background: "#f2f5f7",
    },
  },

  tableContainer: {
    border: `1px solid ${theme.palette.grey[200]}`,
    "&>table": {
      overflowX: "auto",
      minWidth: 800,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  cellBodyRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.grey[500],
    fontSize: 13,
    fontWeight: "normal",
    textAlign: "center",
    "&>div": {
      justifyContent: "center",
    },
  },
  bookingCode: {
    width: "100%",
    color: theme.palette.text.link,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: 13,
  },
  firstTableCellHead: {
    alignItems: "center",
    textAlign: "center",
  },
  codeCell: {
    display: "flex",
    alignItems: "center",
  },
  checkboxBasis: {
    width: 15,
    height: 15,
    marginRight: 25,
    " &>span>svg": {
      width: 20,
      height: 20,
    },
  },
  checkboxChecked: {
    color: "#447aff",
  },
  tableHeadLabel: {
    width: "100%",
    maxWidth: 82,
    textAlign: "center",
    color: theme.palette.white,
    textTransform: "none",
  },
  tableHeadLabelButton: {
    width: 100,
    textAlign: "center",
  },
  icon: {
    color: "#447aff",
    minWidth: 18,
    width: 18,
    maxHeight: 18,
    marginBottom: 18,
  },
  iconThreeDot: {
    width: 24,
    height: 24,
    minWidth: "unset",
    padding: 0,
  },
  selectButton: {
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
    },
  },
  totalCheckout: {},
  paymentContent: {
    color: theme.palette.secondary.main,
  },
}));
