import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import BuildCircle from "icons/BuildCircle";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import ReportDisplaySettingDialog from "./ReportDisplaySettingDialog";

const ReportDisplaySetting = props => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_REPORT_DASHBOARD);

  const [isShowSetting, setIsShowSetting] = useState(false);

  const onCloseDialog = () => {
    setIsShowSetting(false);
  };

  const onSubmitDialog = selectedDisplaySetting => {
    onCloseDialog(false);
    setTimeout(() => {
      props.onChange(selectedDisplaySetting);
    }, 300);
  };

  return (
    <Box className={classes.root}>
      <Button
        classes={{ root: classes.btnDisplaySetting, iconSizeMedium: classes.btnDisplaySettingIcon }}
        variant="text"
        startIcon={<BuildCircle />}
        onClick={() => setIsShowSetting(!isShowSetting)}
      >
        <Typography className="medium-sm-txt">{getLabel(LangConstant.TXT_DISPLAY_SETTING)}</Typography>
      </Button>
      <ReportDisplaySettingDialog {...props} isShow={isShowSetting} onClose={onCloseDialog} onSubmit={onSubmitDialog} />
    </Box>
  );
};

ReportDisplaySetting.propTypes = {
  arraySelected: PropTypes.array,
  data: PropTypes.array,

  onChange: PropTypes.func,
};
export default memo(ReportDisplaySetting);

const useStyles = makeStyles({
  root: {
    paddingTop: 32,
    paddingLeft: 67,
    paddingRight: 70,
  },
  btnDisplaySetting: {
    textTransform: "none",
    "& *": { color: "#447AFF" },
  },
  btnDisplaySettingIcon: { "& > *:first-child": { fontSize: 15 } },
});
