import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Box, IconButton, AccordionDetails, Grid } from "@material-ui/core";
import { CreateOutlined, DeleteOutlined } from "@material-ui/icons";
import PlaceItemAction from "redux/place-item.redux";
import { useDispatch, useSelector } from "react-redux";
import EditDialog from "pages/MnShopInfo/components/AdvancedInfo/CategoryAccordion/SubCategoryAccordion/EditDialog";

const SubPlaceCategory = props => {
  const { data, placeItemData, classRoot, style, directCall: isService, titleName, onDelete } = props;
  const isUpdateFetching = useSelector(state => state.placeItemRedux.isUpdateFetching);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editPlaceItemDialog, setEditPlaceItemDialog] = useState(false);
  const [item, setItem] = useState(data);
  const [apiType, setApiType] = useState();
  const [clickable, setClickable] = useState(false);

  const onEditItem = (event, newData, timeUnitIndex) => {
    event.stopPropagation();
    //Edit place
    if (timeUnitIndex == null) {
      if (newData.name !== null && newData.name !== "" && newData.capacity !== null && newData.capacity !== "") {
        event.preventDefault();
        dispatch(PlaceItemAction.updatePlaceItem({ uid: item.uid, name: newData.name, capacity: newData.capacity }));
        setApiType(API_UPDATE_PLACE_ITEM);
      }
    }
  };

  const onSelectDelete = (event, itemUid) => {
    setClickable(true);
    onDelete(event, itemUid);
    setTimeout(() => {
      setClickable(false);
    }, 1000);
  };

  const onOpenEditDialog = event => {
    event.stopPropagation();
    setEditPlaceItemDialog(true);
  };
  const onEditServiceDialog = event => {
    event.stopPropagation();
  };

  const onCloseEditDialog = event => {
    event.stopPropagation();
    setEditPlaceItemDialog(false);
  };

  useEffect(() => {
    let updatePlaceItem = apiType === API_UPDATE_PLACE_ITEM;
    let isExistItem = item.uid === placeItemData?.uid;

    if (updatePlaceItem && !isService && isExistItem && !isUpdateFetching) {
      //Edit place item
      const tmpItem = { ...item };
      const editedItem = Object.assign(tmpItem, placeItemData);

      setItem(editedItem);
      setApiType(null);
      setEditPlaceItemDialog(false);
    }
  }, [placeItemData]);

  return (
    <Box className={`${classes.boxRoot} ${classRoot}`} style={style}>
      <AccordionDetails className={classes.accordionRoot}>
        <Grid container className={classes.detailsBox}>
          <Grid item xs={2} style={{ paddingLeft: 50 }}>
            <Typography variant="subtitle2" classes={{ subtitle2: "medium-md-txt" }} className={classes.textHeading}>
              {isService && item.name}
            </Typography>
          </Grid>
          <Grid item className={classes.itemBox} xs={4}>
            <Typography variant="subtitle2" classes={{ subtitle2: "medium-md-txt" }} className={classes.textHeading}>
              {!isService && item.name}
            </Typography>
          </Grid>
          <Grid item className={classes.itemBox} xs={3}>
            <Typography
              variant="subtitle2"
              classes={{ subtitle2: "medium-md-txt" }}
              className={isService ? classes.descriptionHeading : classes.textHeading}
            >
              {!isService && item.capacity}
              {isService && item.description}
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "right" }} xs={3}>
            <IconButton
              onClick={e => (isService ? onEditServiceDialog(e) : onOpenEditDialog(e))}
              className={classes.iconButton}
            >
              <CreateOutlined className={classes.icon} />
            </IconButton>
            <IconButton onClick={e => onSelectDelete(e, item.uid)} disabled={clickable} className={classes.iconButton}>
              <DeleteOutlined className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionDetails>
      {editPlaceItemDialog && (
        <EditDialog onClose={onCloseEditDialog} titleName={titleName} onSubmit={onEditItem} data={item} />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    width: "100%",
    justifyContent: "space-between",
  },
  accordionRoot: {
    height: "100%",
    backgroundColor: "#fafafb",
    [theme.breakpoints.down("sm")]: {
      width: "1060px",
    },
    padding: "0px 0px",
  },
  detailsBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 10,
    color: theme.palette.grey[600],
    "&:last-child": {
      paddingBottom: 10,
    },
  },
  itemBox: {
    textAlign: "center",
  },
  textHeading: {
    color: "theme.palette.grey[600]",
  },
  descriptionHeading: {
    color: "theme.palette.grey[600]",
    overflow: "hidden",
    overflowWrap: "break-word",
    textAlign: "justify",
  },

  iconExpand: {
    color: theme.palette.grey[500],
    border: "none",
  },
  iconButton: {
    marginRight: 19,
  },
  icon: {
    color: theme.palette.grey[500],
    width: 21,
    height: 21,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const API_UPDATE_PLACE_ITEM = 1;

SubPlaceCategory.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.object,
  //Arccordion details is called from Service Category directly
  directCall: PropTypes.bool,
  titleName: PropTypes.string,
  onDelete: PropTypes.func,
  classRoot: PropTypes.string,
  placeItemRedux: PropTypes.object,
};

SubPlaceCategory.defaultProps = { style: {}, directCall: false, placeItemRedux: {}, serviceCategoryRedux: {} };

export default memo(SubPlaceCategory);
