import { call, put } from "redux-saga/effects";
import { toCamel, toSnake } from "utils";
import { ApiConstant } from "../const";
import ReportConsumerAction from "../redux/report-consumer.redux";
import { ReportConsumerService } from "../services";

export function* reportConsumer(action) {
  try {
    const { data } = action;
    let response = yield call(ReportConsumerService.reportConsumer, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(ReportConsumerAction.reportConsumerSuccess(toCamel(responseData)));
    } else {
      yield put(ReportConsumerAction.reportConsumerFailure(response.data));
    }
  } catch (error) {
    yield put(ReportConsumerAction.reportConsumerFailure(error));
  }
}
