import React, { memo, useState } from "react";
import { Grid, Typography, Divider, Paper, OutlinedInput, Button, useMediaQuery, Avatar, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LangConstant, AppConstant, ApiConstant } from "../../const";
import PropTypes from "prop-types";
import { InputIcon, InputDate, InputTime, InputDropDown, InputDropDownNeked } from "./form";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import ChildCareOutlinedIcon from "@material-ui/icons/ChildCareOutlined";
import SpaOutlinedIcon from "@material-ui/icons/SpaOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ControlCameraOutlinedIcon from "@material-ui/icons/ControlCameraOutlined";
import PersonPinCircleOutlinedIcon from "@material-ui/icons/PersonPinCircleOutlined";

const BookingPreviewShopInfo = props => {
  const { data, onClick, dataShop } = props;
  const { t: getLabel } = useTranslation();
  const [preiodicState, setPreiodicState] = useState("0");
  const matches = useMediaQuery("(min-width:769px)");
  const onSelect = value => {
    setPreiodicState(value);
  };
  const handlerClick = () => {
    if (!matches) onClick();
  };
  function generate(size, start, unit, first) {
    return [...Array(size)].map((u, i) => first + (start + i) + unit);
  }
  const [inputValue, setInputValue] = useState({
    customer: {
      person: 0,
      parent: 0,
      child: 0,
    },
  });
  const onChange = (name, value) => {
    setInputValue({
      ...inputValue,
      [AppConstant.KEY_BOOK_CUSTOMER]: {
        [name]: value,
      },
    });
  };
  return (
    <Paper className="previewBookingInfo">
      <Grid container direction="column" justify="flex-start" className="main">
        <Grid item container direction="row" justify="flex-start" alignItems="center">
          <Grid item container justify="center">
            <Grid item>
              <Typography className="shopNameTitle">
                {dataShop[AppConstant.KEY_NAME] ? dataShop[AppConstant.KEY_NAME] : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" className="previewInfo">
          <Grid item container>
            <Avatar
              className="shopAvatar"
              variant="square"
              src={
                dataShop[AppConstant.KEY_SHOP_AVATA]
                  ? `${ApiConstant.HOST_ATTACHMENT}${dataShop[AppConstant.KEY_SHOP_AVATA]}`
                  : ""
              }
            ></Avatar>
          </Grid>
          <Grid item container direction="column" className="previewContent">
            <Typography className="typoShopPreview shopWelcomePre" variant="body2">
              {dataShop[AppConstant.KEY_SHOP_WELCOME_SPEECH] ? dataShop[AppConstant.KEY_SHOP_WELCOME_SPEECH] : ""}
            </Typography>

            <Divider></Divider>

            <Box className="shopAddressPre">
              <Typography className="typoShopPreview" variant="body2">
                {getLabel(LangConstant.TXT_SHOP_ADDRESS) + ": "}
              </Typography>

              <Typography className="typoShopPreview" variant="body2">
                {dataShop[AppConstant.KEY_SHOP_ADDRESS] ? dataShop[AppConstant.KEY_SHOP_ADDRESS] : ""}
              </Typography>
            </Box>

            <Typography className="typoShopPreview shopPhonePre" variant="body2">
              {getLabel(LangConstant.TXT_SHOP_PHONE) +
                ": " +
                (dataShop[AppConstant.KEY_SHOP_PHONE] ? dataShop[AppConstant.KEY_SHOP_PHONE] : "")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container className="titleForm">
          <Grid item>
            <ControlCameraOutlinedIcon />
          </Grid>
          <Typography className="title" variant="subtitle1">
            {getLabel(LangConstant.TXT_BOOK_PREVIEW)}
          </Typography>
        </Grid>
        <Grid item container direction="column" className="value previewInfo">
          <Grid item container>
            <Typography variant="body2"> {getLabel(LangConstant.TXT_BOOK_PREVIEW_HELPER)}</Typography>
          </Grid>
          <Grid item>
            <InputIcon
              onChange={onChange}
              icon={<SentimentSatisfiedOutlinedIcon />}
              name={data[AppConstant.KEY_BOOK_CUSTOMER].publish === 1 ? "parent" : "person"}
              placeholder={
                data[AppConstant.KEY_BOOK_CUSTOMER].publish === 1
                  ? getLabel(LangConstant.TXT_BOOK_PREVIEW_PARENT)
                  : getLabel(LangConstant.TXT_BOOK_PREVIEW_PERSON)
              }
              type={"number"}
            />
          </Grid>
          {data[AppConstant.KEY_BOOK_CUSTOMER].publish === 1 && (
            <Grid item>
              <InputIcon
                name={"child"}
                type={"number"}
                icon={<ChildCareOutlinedIcon />}
                placeholder={getLabel(LangConstant.TXT_BOOK_PREVIEW_CHILD)}
                value={inputValue[AppConstant.KEY_BOOK_CUSTOMER].child}
              />
            </Grid>
          )}
          {data[AppConstant.KEY_BOOK_SERVICE].publish === 1 && (
            <Grid item>
              <InputDropDown
                title={"Chọn dịch vụ bạn muốn sử dụng"}
                data={generate(5, 1, "", getLabel(LangConstant.TXT_BOOK_PREVIEW_SERVICE) + " ")}
                icon={<SpaOutlinedIcon />}
                placeholder={getLabel(LangConstant.TXT_BOOK_PREVIEW_SERVICE)}
              />
            </Grid>
          )}
          {data[AppConstant.KEY_BOOK_PLACE].publish === 1 && (
            <Grid item>
              <InputDropDown
                title={getLabel(LangConstant.TXT_BOOK_POSTITION_TYPE)}
                data={generate(5, 1, "", getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE) + " ")}
                icon={<PersonPinCircleOutlinedIcon />}
                placeholder={getLabel(LangConstant.TXT_BOOK_PREVIEW_PLACE)}
              />
            </Grid>
          )}
          <Grid item container direction={matches ? "row" : "column"} justify="flex-start" spacing={matches ? 2 : 0}>
            <Grid item xs>
              <InputDate icon={<DateRangeOutlinedIcon />} placeholder={getLabel(LangConstant.TXT_BOOK_DAY)} />
            </Grid>

            <Grid item xs>
              <InputTime icon={<AccessTimeOutlinedIcon />} placeholder={getLabel(LangConstant.TXT_BOOK_TIME)} />
            </Grid>
          </Grid>
          {data[AppConstant.KEY_BOOK_PERIODIC].publish === 1 && (
            <Grid item container direction={matches ? "row" : "column"} spacing={matches ? 2 : 0}>
              <Grid item xs>
                <InputDropDownNeked
                  title={getLabel(LangConstant.TXT_BOOK_PERIODIC)}
                  placeholder={getLabel(LangConstant.TXT_BOOK_PERIODIC)}
                  onSelect={onSelect}
                  data={LangConstant.ARR_PERIODIC_RESERVATION.map(value => getLabel(value))}
                  icon={<FiberManualRecordIcon style={{ width: "15px", height: "15px" }} />}
                />
              </Grid>
              <Grid item xs>
                <InputDropDownNeked
                  disabled={preiodicState === "0"}
                  data={
                    preiodicState === "0"
                      ? []
                      : generate(12, 1, ` ${getLabel(LangConstant.P_BOOK_PREVIEW_PERIODIC_SELECT_UNIT)}`, "")
                  }
                  icon={<FiberManualRecordIcon style={{ width: "15px", height: "15px" }} />}
                  placeholder={getLabel(LangConstant.P_BOOK_PREVIEW_PERIODIC_SELECT)}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs>
            <Typography component="p" className="helperNote" variant="body2">
              {data[AppConstant.KEY_BOOK_NOTE].details[AppConstant.KEY_BOOK_HELPER_NOTE_HOLDER].content
                ? data[AppConstant.KEY_BOOK_NOTE].details[AppConstant.KEY_BOOK_HELPER_NOTE_HOLDER].content
                : ""}
            </Typography>
          </Grid>
          <Grid item xs>
            <OutlinedInput
              placeholder={getLabel(LangConstant.TXT_BOOK_NOTE)}
              fullWidth
              rows={2}
              multiline
              className="note"
              variant="body2"
            >
              {getLabel(LangConstant.TXT_BOOK_NOTE)}
            </OutlinedInput>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ paddingTop: "50px", paddingBottom: "200px" }}
          >
            <Grid item>
              <Button variant="outlined" className="buttonSwitch" onClick={handlerClick}>
                {"Đặt chỗ"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

BookingPreviewShopInfo.propTypes = {
  data: PropTypes.object,
  dataShop: PropTypes.object,
};

BookingPreviewShopInfo.defaultProps = {
  data: {},
  dataShop: {},
};

export default memo(BookingPreviewShopInfo);
