import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getShopInfoData: ["data"],
  getShopInfoDataSuccess: ["data"],
  getShopInfoDataFailure: ["data"],
  updateShopInfoData: ["data"],
  updateShopInfoDataSuccess: ["data"],
  updateShopInfoDataFailure: ["data"],
});

export const ShopInfoTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  data: null,
  //update
  isUpdateFetching: false,
  errorUpdate: null,
  isUpdateSuccess: false,
};

/* ------------- Reducers Get Shop info data ------------- */
export const getShopInfoData = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getShopInfoDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    data: action.data,
  };
};

export const getShopInfoDataFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

/* ------------- Reducers Update Shop Info daa ------------- */
export const updateShopInfoData = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updateShopInfoDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isUpdateSuccess: true,
    data: action.data,
  };
};

export const updateShopInfoDataFailure = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: false,
  isUpdateSuccess: false,
  // errorUpdate: action.error,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_SHOP_INFO_DATA]: getShopInfoData,
  [Types.GET_SHOP_INFO_DATA_SUCCESS]: getShopInfoDataSuccess,
  [Types.GET_SHOP_INFO_DATA_FAILURE]: getShopInfoDataFailure,
  [Types.UPDATE_SHOP_INFO_DATA]: updateShopInfoData,
  [Types.UPDATE_SHOP_INFO_DATA_SUCCESS]: updateShopInfoDataSuccess,
  [Types.UPDATE_SHOP_INFO_DATA_FAILURE]: updateShopInfoDataFailure,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
