import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, InputBase, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { Add, Clear, EventAvailable, EventBusy } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { addDays, addMonths, addWeeks } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant, LangConstant } from "const";
import { convertSmallNumber, sortArrayObject } from "utils";
import { CustomDatePicker, CustomTimePicker } from "./DialogBooking/componentDialog";
import ReservationAction from "redux/reservation.redux";
import { convertTimeStampToDate, getTimestamp, getTimeStampFromMiliSec } from "utils/date";

const ExtendPartAddBooking = ({
  data,
  startTime,
  isAddingPeriodic,
  setIsAddingPeriodic,
  setNewReservation,
  timeChangeControlState,
  setTimeChangeControlState,
  isBreakRule,
  setIsBreakRule,
}) => {
  const START_CODE = 2;
  const dispatch = useDispatch();
  const availableStatus = useSelector(state => state.reservationRedux.availableStatus);
  const getStatusSuccess = useSelector(state => state.reservationRedux.getAvailableStatusSuccess);
  const { t: getLabel } = useTranslation();

  // happens is the type of repeat option : day-week-month
  const happens = LangConstant.ARR_HAPPENS_EVERY;
  const repeat = [0, ...AppConstant.ARR_REPEAT_TIME];

  const [valueHappen, setValueHappen] = useState(happens[0]);
  const [valueRepeat, setValueRepeat] = useState(0);
  const [codeArray, setCodeArray] = useState([]);
  const [baseCode, setBaseCode] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [timeArray, setTimeArray] = useState([]);
  const [availableArray, setAvailableArray] = useState([]);

  const classes = useStyles();

  const onChangeHappen = event => {
    setValueHappen(event.target.value);
  };

  const onChangeRepeat = event => {
    setValueRepeat(event.target.value);
    setIsAddingPeriodic(true);
  };

  const onRemoveExtend = (isLast, code) => {
    if (Boolean(isLast) && !isBreakRule) {
      let newData = storeData.slice(-1, 1);
      setValueRepeat(valueRepeat - 1);
      setStoreData(newData);
    } else {
      let newData = storeData.filter(dataMap => dataMap.code != code);
      let thisIndexRemove = storeData.map(e => e.code).indexOf(code);
      let newTimeArray = timeArray.filter((timeMap, i) => i != thisIndexRemove);
      if (Boolean(isLast)) {
        newData[newData.length - 1].isLast = true;
      } else {
        newData.map((d, i) => (d.code = `${baseCode}${convertSmallNumber(i + START_CODE)}`));
      }
      setStoreData(newData);
      setTimeArray(newTimeArray);
      setIsBreakRule(true);
    }
  };

  const onAddNewExtend = () => {
    if (!isBreakRule) {
      setValueRepeat(valueRepeat + 1);
    } else {
      let sizeArray = storeData.length;
      let newStoreData = [...storeData];
      newStoreData[sizeArray - 1].isLast = false;
      let newCode = `${baseCode}${convertSmallNumber(sizeArray + START_CODE)}`;
      let newData = {
        code: newCode,
        isLast: true,
        isPast: false,
        startTime: newStoreData[sizeArray - 1].startTime + 86400,
        endTime: null,
      };
      newStoreData.push(newData);
      setTimeArray([...timeArray, { startTime: newStoreData[sizeArray - 1].startTime + 86400, endTime: null }]);
      setStoreData(newStoreData);
    }
  };

  const onChangeSelectedTime = (_, changeTime, inputIndex) => {
    let tmpArr = [];
    for (let index = 0; index < timeArray.length; index++) {
      tmpArr = timeArray;
      if (index === inputIndex) {
        let startTime = convertTimeStampToDate(tmpArr[inputIndex].startTime);
        let startTimeHours = changeTime.substr(0, 2);
        let startTimeMinuts = changeTime.substr(3, 2);
        startTime = startTime.setHours(startTimeHours, startTimeMinuts);
        tmpArr.splice(inputIndex, 1, {
          ...timeArray[inputIndex],
          startTime: getTimeStampFromMiliSec(startTime),
        });
      }
    }
    sortArrayObject(tmpArr, "startTime");
    let newStore = [...storeData];
    newStore.map((dataMap, indexMap) => (dataMap.startTime = tmpArr[indexMap].startTime));
    setTimeArray(tmpArr);
    setTimeChangeControlState(!timeChangeControlState);
    setIsBreakRule(true);
  };

  const onChangeSelectedDate = (date, inputIndex) => {
    let timeMap = [...timeArray];
    for (let index = 0; index < timeArray.length; index++) {
      if (index === inputIndex) {
        timeMap.splice(inputIndex, 1, {
          startTime: getTimestamp(date),
          endTime: null,
        });
      }
    }
    sortArrayObject(timeMap, "startTime");
    let newStore = [...storeData];
    newStore.map((dataMap, indexMap) => (dataMap.startTime = timeMap[indexMap].startTime));
    setTimeArray(timeMap);
    setStoreData(newStore);
    setTimeChangeControlState(!timeChangeControlState);
    setIsBreakRule(true);
  };

  useEffect(() => {
    if (isAddingPeriodic) {
      dispatch(ReservationAction.resetGetAvailableStatus());
    }
  }, [isAddingPeriodic]);

  //Create an time array
  useEffect(() => {
    if (data.startTime && isAddingPeriodic && !isBreakRule) {
      setTimeArray([]);
      let tmpTimeArr = [];
      let tmpCompare = getTimeStampFromMiliSec(startTime.date.setHours(startTime.hour, startTime.min));

      switch (valueHappen) {
        case happens[0]:
          for (let index = 0; index < valueRepeat; index++) {
            let addTime = 0;
            addTime = getTimestamp(addDays(convertTimeStampToDate(tmpCompare), index + 1));
            tmpTimeArr.push({ startTime: addTime, endTime: null });
          }
          setTimeArray(tmpTimeArr);
          break;
        case happens[1]:
          for (let index = 0; index < valueRepeat; index++) {
            let addTime = 0;
            addTime = getTimestamp(addWeeks(convertTimeStampToDate(tmpCompare), index + 1));
            tmpTimeArr.push({ startTime: addTime, endTime: null });
          }
          setTimeArray(tmpTimeArr);
          break;
        case happens[2]:
          for (let index = 0; index < valueRepeat; index++) {
            let addTime = 0;
            addTime = getTimestamp(addMonths(convertTimeStampToDate(tmpCompare), index + 1));
            tmpTimeArr.push({ startTime: addTime, endTime: null });
          }
          setTimeArray(tmpTimeArr);
          break;
      }
    }
  }, [isAddingPeriodic, valueRepeat, valueHappen, timeChangeControlState]);

  // Check available status
  useEffect(() => {
    if (timeArray.length > 0 && (data.man > 0 || data.totalConsumer > 0)) {
      let tmpArr = [];
      for (let index = 0; index < timeArray.length; index++) {
        tmpArr.push(timeArray[index].startTime);
      }
      if (tmpArr.length > 0) {
        dispatch(
          ReservationAction.getAvailableStatus({
            time: tmpArr,
            total_consumer: data.man ? data.man + data.child : data.totalConsumer,
          }),
        );
      }
    }
  }, [timeArray, data.man, data.child, data.totalConsumer]);

  //Create an base for the code
  useEffect(() => {
    let tmpCode = [];
    if (data.phoneNumber) {
      for (let index = 4; index > 0; index--) {
        tmpCode.push(data.phoneNumber[data.phoneNumber.length - index]);
      }
      setBaseCode(tmpCode.join("").toString() + "-" + "00" + "-");
    } else setBaseCode(null);
  }, [data.phoneNumber]);

  //Create an code array
  useEffect(() => {
    let tmpCodeArr = [];
    if (baseCode && isAddingPeriodic) {
      for (let index = 0; index < valueRepeat; index++) {
        let newCode = "";

        newCode = `${baseCode}${convertSmallNumber(index + START_CODE)}`;
        tmpCodeArr.push(newCode);
      }
    }
    setCodeArray(tmpCodeArr);
  }, [isAddingPeriodic, valueRepeat, valueHappen, data.phoneNumber, baseCode]);

  // Combine together for an temporary data array
  useEffect(() => {
    let tmpArr = [];
    if (data && Boolean(timeArray.length) && isAddingPeriodic && Boolean(codeArray.length) && !isBreakRule) {
      for (let index = 0; index < valueRepeat; index++) {
        if (codeArray[index]) {
          let tmpVar = {
            code: codeArray[index] && codeArray[index],
            isLast: index === valueRepeat - 1 ? true : false,
            isPast: timeArray[index] && timeArray[index].startTime < getTimeStampFromMiliSec(Date.now()) ? true : false,
            startTime: timeArray[index] && timeArray[index] && timeArray[index].startTime,
            endTime: null,
          };
          tmpArr.push(tmpVar);
        }
      }
    }
    setStoreData(tmpArr);
  }, [isAddingPeriodic, codeArray, timeArray, timeChangeControlState]);

  // Set new time if original timestamps changed
  useEffect(() => {
    let tmpArr = [];
    for (let index = 0; index < timeArray.length; index++) {
      let startTimeByDate = convertTimeStampToDate(timeArray[index].startTime);
      let startTimeByMiliSec = startTimeByDate.setHours(startTime.hour, startTime.min);
      tmpArr.push({
        startTime: getTimeStampFromMiliSec(startTimeByMiliSec),
        endTime: null,
      });
    }
    setTimeArray(tmpArr);
  }, [data.startTime]);

  // Map available status
  useEffect(() => {
    let tmpArr = [];
    if (getStatusSuccess && timeArray.length > 0 && codeArray.length > 0) {
      if (availableStatus.data) {
        const { data } = availableStatus;
        tmpArr = [];
        Object.keys(data).forEach(function (key) {
          tmpArr.push(data[key]);
        });
      }
    }
    setAvailableArray(tmpArr);
  }, [isAddingPeriodic, availableStatus, getStatusSuccess, timeArray]);

  // Set to the data form to call API
  useEffect(() => {
    if (timeArray && isAddingPeriodic) {
      setNewReservation({ ...data, periodic: timeArray });
    }
  }, [isAddingPeriodic, timeArray]);

  return (
    <Box className={classes.extendContainer}>
      <Box className={classes.optionRepeat}>
        <Box>
          <Typography className={classes.typoRepeat}>{getLabel(LangConstant.TXT_HAPPENS_EVERY)}</Typography>
        </Box>
        <Box className={classes.selectContainer}>
          <Select
            disabled={isBreakRule}
            value={valueHappen}
            classes={{ root: classes.selectRepeat, select: classes.selectedRepeat }}
            onChange={onChangeHappen}
            input={<BootstrapInput />}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
          >
            {happens.map((data, index) => (
              <MenuItem className={classes.menuRepeat} value={data} key={index}>
                {data}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box className={classes.optionRepeat}>
        <Box>
          <Typography className={classes.typoRepeat}>{getLabel(LangConstant.TXT_ADD_REPEATS)}</Typography>
        </Box>
        <Box className={classes.selectContainer}>
          <Select
            disabled={isBreakRule}
            value={valueRepeat}
            classes={{ root: classes.selectRepeat, select: classes.selectedRepeat }}
            onChange={onChangeRepeat}
            input={<BootstrapInput />}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
          >
            {repeat.map((data, index) => (
              <MenuItem className={classes.menuRepeat} value={data} key={index}>
                {data}
              </MenuItem>
            ))}
          </Select>
          <Box height="30px" marginLeft="16px">
            <Typography className={classes.typoRepeat}>{getLabel(LangConstant.TXT_TIMES)}</Typography>
          </Box>
        </Box>
      </Box>
      <ExtendData
        storeData={storeData}
        onChangeSelectedDate={onChangeSelectedDate}
        onChangeSelectedTime={onChangeSelectedTime}
        data={data}
        available={availableArray}
        onRemoveExtend={onRemoveExtend}
        onAddNewExtend={onAddNewExtend}
      />
    </Box>
  );
};

ExtendPartAddBooking.prototype = {
  timeChangeControlState: PropTypes.bool,
  isAddingPeriodic: PropTypes.bool,
  isBreakRule: PropTypes.bool,

  setNewReservation: PropTypes.func,
  setTimeChangeControlState: PropTypes.func,
  setIsBreakRule: PropTypes.func,

  data: PropTypes.object,
  startTime: PropTypes.object,
};

ExtendPartAddBooking.defaultProps = {
  startTime: {
    date: new Date(),
    hour: 0,
    min: 0,
  },
};

export default ExtendPartAddBooking;

const ExtendData = props => {
  const { storeData, onChangeSelectedDate, onChangeSelectedTime, available, onRemoveExtend, onAddNewExtend } = props;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <>
      <Box className={classes.extendTitle}>
        <Box minWidth="40%">
          <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_PLACE_CODE)}</Typography>
          {storeData &&
            storeData.map((data, index) => (
              <Typography className={data.isPast ? classes.typoCodePast : classes.typoCode} key={index}>
                {data.code}
              </Typography>
            ))}
        </Box>
        <Box minWidth="35%">
          <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_TIME)}</Typography>
          {storeData &&
            storeData.map((data, key) => (
              <Box display="flex" key={key}>
                <CustomDatePicker
                  className={classes.datePicker}
                  onChange={onChangeSelectedDate}
                  selectedDateProp={data.startTime * 1000}
                  index={key}
                />
                <CustomTimePicker
                  className={classes.timePicker}
                  onChange={onChangeSelectedTime}
                  selectedTimeProp={data.startTime * 1000}
                  index={key}
                />
              </Box>
            ))}
        </Box>
        <Box textAlign="center" minWidth="15%">
          <Typography className={classes.rootTypoHeader}>{getLabel(LangConstant.TXT_AVAILABLE)}</Typography>
          {available &&
            available.map((data, index) => (
              <Box key={index} className={classes.availableBox}>
                {data ? (
                  <EventAvailable className={classes.eventAvailable} />
                ) : (
                  <EventBusy className={classes.eventBusy} />
                )}
              </Box>
            ))}
        </Box>
        <Box className={classes.actionBoxContainer}>
          {storeData &&
            storeData.map((data, index) => (
              <Box className={classes.extendActions} key={index}>
                <IconButton onClick={() => onAddNewExtend()} className={classes.iconButtonExtend}>
                  <Add className={classes.iconExtend} />
                </IconButton>
                {storeData.length > 1 ? (
                  <IconButton
                    onClick={() => onRemoveExtend(data.isLast, data.code)}
                    className={classes.iconButtonExtend}
                  >
                    <Clear className={classes.iconExtend} />
                  </IconButton>
                ) : (
                  <Box className={classes.iconButtonExtend} />
                )}
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};

const BootstrapInput = props => {
  const classes = useStyles();
  return <InputBase {...props} classes={{ root: classes.rootBase, input: classes.InputBase }} />;
};

const useStyles = makeStyles(theme => ({
  extendContainer: {
    height: "100%",
  },
  typoRepeat: {
    marginTop: 4,
    fontSize: 13,
    height: 22,
    paddingTop: 2,
  },
  selectRepeat: {
    width: 180,
    height: 30,
  },
  selectedRepeat: {
    "&:focus": {
      borderRadius: 3,
      backgroundColor: theme.palette.white,
    },
  },
  menuRepeat: {
    height: 30,
    fontSize: 12,
    padding: "4px 16px 4px",
    boxSizing: "inherit",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
    },
  },
  rootBase: {
    width: "60%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  InputBase: {
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 12,
    height: 20,
    padding: "4px 16px 4px 16px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  optionRepeat: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  selectContainer: {
    display: "flex",
    width: "60%",
  },
  eventAvailable: {
    fontSize: 18,
    marginTop: 1,
    color: theme.palette.reservation.active,
  },
  eventBusy: {
    fontSize: 18,
    marginTop: 1,
    color: "#feba40",
  },
  iconButtonExtend: {
    padding: 0,
    width: 18,
    height: 18,
    marginLeft: 5,
  },
  iconExtend: {
    width: 16,
    height: 16,
  },
  extendActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 30,
  },
  actionBoxContainer: {
    marginTop: 20,
    minWidth: "10%",
  },
  extendTitle: {
    display: "flex",
    marginTop: 10,
  },
  rootTypoHeader: {
    fontSize: 12,
    color: "#3e4045",
    fontWeight: 600,
    height: 20,
    paddingTop: 2,
    paddingBottom: 15,
  },
  datePicker: {
    maxWidth: 90,
    placeContent: "center",
    height: 30,
  },
  timePicker: {
    maxWidth: 40,
    placeContent: "center",
    height: 30,
  },
  typoCode: {
    display: "flex",
    height: 30,
    fontSize: 13,
    fontWeight: 500,
    alignItems: "center",
  },
  typoCodePast: {
    display: "flex",
    height: 30,
    fontSize: 13,
    fontWeight: 500,
    opacity: 0.3,
    alignItems: "center",
  },
  availableBox: {
    height: 30,
    display: "flex",
    placeItems: "center",
    justifyContent: "center ",
  },
}));
