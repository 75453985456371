import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  OutlinedInput,
  Box,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { LangConstant } from "../../../../../const";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@material-ui/icons";
import DialogLayout from "./DialogLayout";

const ServiceDialog = props => {
  const { onClose, onSubmit, isAdd, data, titleName } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const initData = isAdd ? { name: "", usingTime: "", description: "" } : data;
  const [inputData, setInputData] = useState(initData);
  const [timeUnitIndex, setTimeUnitIndex] = useState(MINUTE_UNIT);

  const onChangeInputData = event => {
    if (event.target.name === "usingTime") {
      if (!isNaN(parseInt(event.target.value))) {
        setInputData({ ...inputData, [event.target.name]: parseInt(event.target.value) });
      } else {
        setInputData({ ...inputData, [event.target.name]: 0 });
      }
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
    }
  };

  const getTimeUnit = index => {
    setTimeUnitIndex(index);
  };

  return (
    <DialogLayout onClose={onClose} titleName={isAdd ? getLabel(LangConstant.T_SERVICE_CATEGORY_DIALOG) : titleName}>
      <form>
        <DialogContent className={classes.dialogContent}>
          <ContentBlock
            text={inputData.name}
            label={getLabel(LangConstant.L_SERVICE_CATEGORY_DIALOG_NAME)}
            placeholder={getLabel(LangConstant.P_SERVICE_CATEGORY_DIALOG_NAME)}
            enableMenu={false}
            outlinedInputStyle={{ width: 150 }}
            inputProps={{
              name: "name",
              onChange: onChangeInputData,
            }}
            required
          />
          <ContentBlock
            text={inputData.usingTime}
            label={getLabel(LangConstant.L_SERVICE_CATEGORY_DIALOG_TIME)}
            placeholder={getLabel(LangConstant.P_SERVICE_CATEGORY_DIALOG_TIME)}
            enableMenu={true}
            outlinedInputStyle={{ width: 60 }}
            inputProps={{
              name: "usingTime",
              onChange: onChangeInputData,
            }}
            required
            getTimeUnit={getTimeUnit}
          />
          <ContentBlock
            text={inputData.description}
            label={getLabel(LangConstant.L_SERVICE_CATEGORY_DIALOG_DESCRIPTION)}
            placeholder={getLabel(LangConstant.P_SERVICE_CATEGORY_DIALOG_DESCRIPTION)}
            enableMenu={false}
            outlinedInputStyle={{ width: 420 }}
            inputProps={{
              name: "description",
              onChange: onChangeInputData,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={e => onSubmit(e, inputData, timeUnitIndex)}
          >
            <Typography variant="body2" classes={{ body2: "medium-md-txt" }} style={{ color: theme.palette.white }}>
              {isAdd ? "Tạo" : getLabel(LangConstant.TXT_SAVE_BUTTON)}
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};

ServiceDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isAdd: PropTypes.bool,
  data: PropTypes.object,
  titleName: PropTypes.string,
};

ServiceDialog.defaultProps = { isAdd: true };

export const MINUTE_UNIT = 0;
export const HOUR_UNIT = 1;

const ContentBlock = props => {
  const { text, label, placeholder, enableMenu, outlinedInputStyle, inputProps, getTimeUnit, ...otherProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box className={classes.contentItem}>
      <Typography variant="body2" classes={{ body2: "medium-md-txt" }} style={{ color: theme.palette.grey[600] }}>
        {label}
      </Typography>
      {!enableMenu && (
        <OutlinedInput
          value={text}
          placeholder={placeholder}
          className={classes.outlineInput}
          inputProps={{ classes: { input: `${classes.input} regular-md-txt` }, style: { textAlign: "center" } }}
          style={outlinedInputStyle}
          {...inputProps}
          {...otherProps}
        ></OutlinedInput>
      )}
      {enableMenu && (
        <Box className={classes.boxInputMenu}>
          <OutlinedInput
            value={text}
            placeholder={placeholder}
            className={classes.outlineInputMenu}
            inputProps={{
              classes: { input: `${classes.input} regular-md-txt` },
              style: { textAlign: "center" },
            }}
            style={outlinedInputStyle}
            {...inputProps}
            {...otherProps}
          ></OutlinedInput>
          <TimeUnit onChange={getTimeUnit}></TimeUnit>
        </Box>
      )}
    </Box>
  );
};

const TimeUnit = props => {
  const { onChange } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChange(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.boxMenuRoot}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickListItem}
        classes={{ root: classes.btnMenuRoot, label: `medium-md-txt ${classes.btnPlaceHolder}` }}
      >
        {getLabel(LangConstant.ARR_TIME_UNIT[selectedIndex])}
        <ExpandMore className={classes.icon}></ExpandMore>
      </IconButton>
      <Menu
        id="lock-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
      >
        {LangConstant.ARR_TIME_UNIT.map((option, index) => (
          <MenuItem
            onClick={event => handleMenuItemClick(event, index)}
            className={`medium-md-txt ${classes.menuItemRoot}`}
            key={index}
          >
            {getLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  //Dialog
  dialogContent: {
    display: "flex",
    alignItems: "center",
    borderBottom: `solid 0.5px ${theme.palette.grey[300]}`,
    padding: "30px 30px",
  },
  dialogAction: {
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
  },
  button: {
    width: 73,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    minHeight: "unset",
    padding: 0,
  },
  textButton: {},
  contentItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  outlineInput: {
    borderRadius: "0%",
    marginTop: 21,
    height: 40,
    marginLeft: 5,
    color: theme.palette.grey[600],
  },
  outlineInputMenu: {
    borderRadius: "0%",
    height: 40,
    color: theme.palette.grey[600],
  },
  outlineInputInput: {
    textAlign: "center",
    "&::placeholder": {
      opacity: 1,
    },
    padding: "0px 0px",
    color: theme.palette.grey[400],
  },
  boxInputMenu: {
    marginTop: 21,
    marginLeft: 5,
    display: "flex",
    alignItems: "center",
  },

  //Time unit menu
  boxMenuRoot: {
    height: 40,
  },
  btnMenuRoot: {
    color: theme.palette.grey[400],
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderLeft: "none",
    height: "100%",
    width: 100,
    paddingRight: 0,
  },
  btnPlaceHolder: {
    color: theme.palette.grey[600],
    justifyContent: "unset",
    fontFamily: theme.typography.fontFamily,
    marginLeft: 4,
  },
  menuPaper: {
    marginTop: "2px",
    borderRadius: "0%",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    msOverflowStyle: `none`,
    scrollbarWidth: `none`,
    "&::-webkit-scrollbar": {
      display: `none`,
    },
  },
  menuList: {
    paddingTop: `0px`,
    paddingBottom: `0px`,
    paddingRight: `0px !important`,
  },
  menuItemRoot: {
    paddingRight: 0,
    width: 100,
    height: 40,
    color: theme.palette.grey[600],
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
  },
  icon: {
    right: 15,
    position: "absolute",
    color: theme.palette.grey[400],
  },
}));

export default memo(ServiceDialog);
