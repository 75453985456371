import { call, put } from "redux-saga/effects";
import { ApiConstant } from "../const";
import UserAction from "../redux/user.redux";
import { UserService } from "../services";
import ReservationSettingAction from "../redux/reservation-setting.redux";
import { toCamel, toSnake } from "utils";

export function* requestGetUserInfo(action) {
  try {
    const { data } = action;
    let response = yield call(UserService.getInfo, data.data);
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(UserAction.getUserInfoSuccess(toCamel(responseData)));
    } else {
      yield put(UserAction.getUserInfoFailure(response.data));
    }
  } catch (error) {
    yield put(UserAction.getUserInfoFailure(error));
  }
}

export function* requestGetUserShopInfo() {
  try {
    let response = yield call(UserService.getShopInfo, {});
    if (response.status === ApiConstant.STT_OK) {
      let responseData = response.data.data;
      yield put(UserAction.getUserShopInfoSuccess(responseData));
    } else {
      yield put(UserAction.getUserShopInfoFailure(response.data));
    }
  } catch (error) {
    yield put(UserAction.getUserShopInfoFailure(error));
  }
}

export function* requestUpdateUserInfo(action) {
  try {
    let response = yield call(UserService.updateInfo, toSnake(action.data));
    if (response.status === ApiConstant.STT_OK) {
      yield put(UserAction.updateUserInfoSuccess(action.data));
      if (action.data.checked === true) {
        yield put(
          ReservationSettingAction.requestGetReservationNotification({
            paging: 0,
            orderBy: "created",
            asc: 0,
            page: 1,
          }),
        );
      }
    } else {
      yield put(UserAction.updateUserInfoFailure(response.data));
    }
  } catch (error) {
    yield put(UserAction.updateUserInfoFailure(error));
  }
}

export function* requestUpdateUserPassword(action) {
  try {
    let params = toSnake(action?.data || {});
    let response = yield call(UserService.updatePassword, params);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(UserAction.updateUserPasswordSuccess(responseData));
    } else {
      yield put(UserAction.updateUserPasswordFailure(response.data));
    }
  } catch (error) {
    yield put(UserAction.updateUserPasswordFailure(error));
  }
}

export function* requestUploadInfo(action) {
  try {
    const { data } = action;
    let formData = new FormData();
    formData.append("file", action.data.upload.file);
    formData.append("type", "image");
    let response = yield call(UserService.upload, formData);
    let responseData = response.data.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(UserAction.uploadUserImageSuccess(responseData));
      yield put(UserAction.requestUpdateUserInfo({ ...data.update, avatar: responseData.uri }));
    } else {
      yield put(UserAction.uploadUserImageFailure(response.data));
    }
  } catch (error) {
    yield put(UserAction.uploadUserImageFailure(error));
  }
}
