import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, makeStyles, Paper } from "@material-ui/core";
import ReservationPreview from "./ReservationPreview";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import { LangConstant } from "const";

const CalendarPreview = ({ data, dataSetting, consumerCapacity }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  return (
    <Paper>
      <Box className={classes.Preview}>
        <DesktopMacIcon className={classes.icon} />
        <Typography className={classes.TextPreview}>{getLabel(LangConstant.TXT_PREVIEW_ON_DESKTOP)}</Typography>
      </Box>
      <Box>
        <ReservationPreview
          data={data}
          isOnSidebar={true}
          dataSetting={dataSetting}
          consumerCapacity={consumerCapacity}
        />
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  Preview: {
    backgroundColor: "#65B39D",
    height: 40,
    display: "flex",
    paddingLeft: 50,
    paddingTop: 10,
  },
  icon: {
    color: "white",
    marginRight: 15,
  },
  TextPreview: {
    color: "white",
    paddingTop: -1,
  },
}));
export default CalendarPreview;
