import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles, Accordion, AccordionSummary, Typography, Box, IconButton, Grid } from "@material-ui/core";
import { ExpandMore, AddCircle, DeleteOutlined, CreateOutlined } from "@material-ui/icons";
import CategoryAccordionDetails from "../CategoryAccordionDetails";
import EditDialog from "./EditDialog";
import { LangConstant } from "const";
import ConfirmDialog from "../../CategoryAccordion/ConfirmDialog";
import PlaceDialog from "../../CategoryAccordion/PlaceDialog";
import PlaceItemAction from "redux/place-item.redux";
import PlaceCategoryAction from "redux/place-category.redux";

const SubCategoryAccordion = props => {
  const placeItemRedux = useSelector(state => state.placeItemRedux);
  const placeItemFetchData = placeItemRedux.data;
  const { data, className, style, onDelete: onDeletePlaceCategory } = props;
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [addPlaceItemDialog, setAddPlaceItemDialog] = useState(false);
  const [placeCategory, setPlaceCategory] = useState(data);
  const [apiType, setApiType] = useState();
  const [clickable, setClickable] = useState(false);
  const [openPlace, setOpenPlace] = useState(false);
  const [dataState, setDataState] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [newPlaceCategory, setNewPlaceCategory] = useState({});

  const onExpand = event => {
    event.preventDefault();
  };

  const onOpenAddDialog = event => {
    event.stopPropagation();
    setAddPlaceItemDialog(true);
  };

  const onOpenEditDialog = (event, data) => {
    setDataState(data);
    event.stopPropagation();
    setOpenPlace(true);
  };

  const onSubmitPlaceCategory = (event, newData) => {
    if (newData.updateAll === 0 && newData.name !== "") {
      if (newData.totalAmount === 0) {
        dispatch(PlaceCategoryAction.updatePlaceCategory(newData));
        setOpenPlace(false);
      } else {
        setNewPlaceCategory(newData);
        setOpenPlace(false);
        setOpenConfirm(true);
      }
    } else if (newData.name !== "") {
      dispatch(PlaceCategoryAction.updatePlaceCategory(newData));
      setOpenPlace(false);
    }
  };

  const onConfirmCategory = (event, isAgree) => {
    if (isAgree) {
      dispatch(PlaceCategoryAction.updatePlaceCategory({ ...newPlaceCategory }));
      setOpenConfirm(false);
    } else {
      setOpenConfirm(false);
    }
  };

  const onCloseDialog = () => {
    setOpenPlace(false);
  };

  const onAddItem = (event, newData) => {
    event.stopPropagation();

    if (newData.name !== null && newData.name !== "" && newData.capacity !== null && newData.capacity !== "") {
      event.preventDefault();
      dispatch(
        PlaceItemAction.createPlaceItem({
          categoryUid: placeCategory.uid,
          name: newData.name,
          capacity: newData.capacity,
        }),
      );
      setApiType(TYPE_API_PLACE.API_CREATE_PLACE_ITEM);
    }
  };

  const onDeleteCategory = (event, placeCategoryUid) => {
    event.stopPropagation();
    setClickable(true);
    onDeletePlaceCategory(event, placeCategoryUid);
    setTimeout(() => {
      setClickable(false);
    }, 1000);
  };

  const onDeletePlaceItem = (event, itemUid) => {
    event.stopPropagation();

    dispatch(PlaceItemAction.removePlaceItem({ uid: itemUid }));
    setApiType(TYPE_API_PLACE.API_DELETE_PLACE_ITEM);
  };

  const onCloseAddDialog = event => {
    event.stopPropagation();
    setAddPlaceItemDialog(false);
  };

  useEffect(() => {
    if (
      apiType === TYPE_API_PLACE.API_CREATE_PLACE_ITEM &&
      placeItemFetchData != null &&
      !placeItemRedux.isCreateFetching
    ) {
      //Add new item
      let listPlaceItem = placeCategory.items;
      listPlaceItem[placeItemFetchData.uid] = placeItemFetchData;

      setPlaceCategory({ ...placeCategory, items: listPlaceItem });
      setApiType(null);
      setAddPlaceItemDialog(false);
    } else if (
      apiType === TYPE_API_PLACE.API_DELETE_PLACE_ITEM &&
      placeItemFetchData != null &&
      !placeItemRedux.isRemoveFetching
    ) {
      let editedCategory = { ...placeCategory };
      delete editedCategory.items[placeItemFetchData.uid];

      setApiType(null);
      setPlaceCategory(editedCategory);
    }
  }, [placeItemFetchData]);

  return (
    <Box className={`${classes.boxRoot} ${className}`} style={style}>
      <Accordion square={true} onClick={e => onExpand(e)} classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          expandIcon={<ExpandMore className={classes.iconExpand} />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          className={classes.summaryRoot}
          classes={{ content: classes.summaryContent, expanded: classes.summaryExpand }}
        >
          <Grid container className={classes.summaryBox}>
            <Grid item xs={2} lg={1} style={{ paddingLeft: 50 }}>
              <Typography
                variant="subtitle2"
                classes={{ subtitle2: "medium-md-txt" }}
                className={classes.textHeading}
                color="inherit"
              >
                {placeCategory.name}
              </Typography>
            </Grid>
            <Grid item className={classes.itemBox} xs={3}>
              {placeCategory.items && (
                <Typography
                  variant="subtitle2"
                  classes={{ subtitle2: "medium-md-txt" }}
                  className={classes.textHeading}
                >
                  {`${getLabel(LangConstant.L_PLACE_AMOUNT)} (${Object.keys(placeCategory.items).length})`}
                </Typography>
              )}
            </Grid>
            <Grid item className={classes.itemBox} xs={4}>
              <Typography variant="subtitle2" classes={{ subtitle2: "medium-md-txt" }} className={classes.textHeading}>
                {getLabel(LangConstant.L_PLACE_CAPACITY)}
              </Typography>
            </Grid>
            <Grid item style={{ textAlign: "right" }} xs={3} lg={4}>
              <IconButton onClick={e => onOpenEditDialog(e, placeCategory)} className={classes.iconButton}>
                <CreateOutlined className={classes.icon} />
              </IconButton>
              <IconButton onClick={e => onOpenAddDialog(e)} className={classes.iconButton}>
                <AddCircle className={classes.icon} />
              </IconButton>
              <IconButton
                onClick={e => onDeleteCategory(e, placeCategory.uid)}
                className={classes.iconButton}
                disabled={clickable}
              >
                <DeleteOutlined className={classes.icon}> </DeleteOutlined>
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        {Object.keys(placeCategory.items).map(key => (
          <CategoryAccordionDetails
            key={key}
            data={placeCategory.items[key]}
            placeItemRedux={placeItemRedux}
            titleName={placeCategory.name}
            onDelete={onDeletePlaceItem}
          ></CategoryAccordionDetails>
        ))}
      </Accordion>
      {addPlaceItemDialog && (
        <EditDialog
          onClose={onCloseAddDialog}
          titleName={placeCategory.name}
          onSubmit={onAddItem}
          isAdd={true}
        ></EditDialog>
      )}
      {openPlace && (
        <PlaceDialog onClose={onCloseDialog} onSubmit={onSubmitPlaceCategory} data={dataState}></PlaceDialog>
      )}
      {openConfirm && (
        <ConfirmDialog onSelectConfirm={onConfirmCategory} categoryName={newPlaceCategory.name}></ConfirmDialog>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  boxRoot: {
    width: "100%",
    justifyContent: "space-between",
  },
  accordionRoot: {
    height: "100%",
    backgroundColor: "#fafafb",
    [theme.breakpoints.down("sm")]: {
      width: "1060px",
    },
  },
  summaryRoot: {},
  summaryExpand: {
    minHeight: "50px !important",
    height: 50,

    "&.MuiIconButton-root": {
      border: "unset !important",
      textAlign: "right",
    },
    "&:first-child": {
      borderBottom: `solid 0.5px ${theme.palette.grey[300]}`,
    },
  },
  summaryContent: {
    margin: "0px 0px",
  },
  summaryBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  itemBox: {
    textAlign: "center",
  },
  textHeading: {
    color: "theme.palette.grey[600]",
  },
  iconExpand: {
    color: theme.palette.grey[500],
  },
  iconButton: {
    marginRight: 19,
  },
  icon: {
    color: theme.palette.grey[500],
    width: 21,
    height: 21,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const TYPE_API_PLACE = {
  API_CREATE_PLACE_ITEM: 1,
  API_UPDATE: 2,
  API_DELETE_PLACE_ITEM: 3,
};

SubCategoryAccordion.propTypes = {
  style: PropTypes.object,
  data: PropTypes.object,
  className: PropTypes.string,
};

SubCategoryAccordion.defaultProps = { style: {} };

export default memo(SubCategoryAccordion);
