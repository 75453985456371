import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { AntSwitchPrimary } from "../../pages/MnNews/components/CustomizeSwitch";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "../../const";
import { useSelector, useDispatch } from "react-redux";
import ShopAction from "../../redux/shop.redux";
import Cookie from "js-cookie";
import UserAction from "../../redux/user.redux";

const NotificationSetting = ({ isShow, onClose }) => {
  const { t: getLabel } = useTranslation();

  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={isShow}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      fullScreen
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h5" className={classes.textTitle} color={"inherit"}>
          {getLabel(LangConstant.TXT_NOTIFICATION_SETTING)}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} color={"inherit"}>
          <Close fontSize="large" />
        </IconButton>
      </DialogTitle>
      <Content onClose={onClose}></Content>
    </Dialog>
  );
};

NotificationSetting.propTypes = {
  onClose: PropTypes.func,
};
NotificationSetting.defaultProps = {};
const Content = ({ onClose }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const appData = Cookie.get(AppConstant.KEY_GBOSS);
  var shopId = null;
  const { isFetching } = useSelector(state => state.shopRedux);
  const dispatch = useDispatch();

  useEffect(() => {
    if (appData && appData.length > 0) {
      shopId = JSON.parse(appData).shopId;
      if (!isFetching) {
        dispatch(ShopAction.requestGetShopInfo({ data: { shopId: shopId } }));
      }
    }
  }, []);

  const shopInfoRedux = useSelector(state => state.userRedux.dataUser);
  const isUpdateSuccess = useSelector(state => state.userRedux.isUserUpdateSuccess);
  const [state, setState] = useState({});
  useEffect(() => {
    if (shopInfoRedux) setState(shopInfoRedux ? shopInfoRedux.details.notificationSetting : {});
  }, [shopInfoRedux]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(UserAction.resetUserUpdate());
      onClose();
    }
  }, [isUpdateSuccess]);

  const onSwitch = event => {
    setState({ ...state, [event.target.name]: event.target.checked ? 1 : 0 });
  };

  const onSave = () => {
    let clone = Object.assign({}, shopInfoRedux);
    clone.userId = clone.id;
    if (state !== shopInfoRedux.details.notificationSetting) {
      clone.checked = true;
      clone.details.notification_setting = state;
      dispatch(UserAction.requestUpdateUserInfo(clone));
    } else {
      onClose();
    }
  };

  return (
    <DialogContent className={classes.dialogContent}>
      <Typography variant="subtitle2" color={"inherit"} className={classes.firstTitle}>
        {getLabel(LangConstant.TXT_BOOKING)}
      </Typography>
      <Box className={classes.rowBox}>
        <Typography variant="body2" classes={{ body2: "medium-md-txt" }} color={"inherit"}>
          {getLabel(LangConstant.TXT_NOTIFICATION_CHANGE_BOOKING)}
        </Typography>
        <Box marginLeft="auto">
          <AntSwitchPrimary
            checked={state.reservation_status_updated === 1}
            onChange={onSwitch}
            name="reservation_status_updated"
          />
        </Box>
      </Box>
      <Box className={classes.rowBox}>
        <Typography variant="body2" classes={{ body2: "medium-md-txt" }} color={"inherit"}>
          {getLabel(LangConstant.TXT_NOTIFICATION_CHANGE_RESERVATION)}
        </Typography>
        <Box marginLeft="auto">
          <AntSwitchPrimary
            checked={state.reservation_info_updated === 1}
            onChange={onSwitch}
            name="reservation_info_updated"
          />
        </Box>
      </Box>
      <Typography variant="subtitle2" color={"inherit"} className={classes.secondTitle}>
        {getLabel(LangConstant.TXT_NEWS)}
      </Typography>
      <Box className={classes.rowBox}>
        <Typography variant="body2" classes={{ body2: "medium-md-txt" }} color={"inherit"}>
          {getLabel(LangConstant.TXT_NOTIFICATION_ADD_NEWS)}
        </Typography>
        <Box marginLeft="auto">
          <AntSwitchPrimary checked={state.news === 1} onChange={onSwitch} name="news" />
        </Box>
      </Box>
      <Button color="primary" variant="contained" size="large" className={classes.saveButton} onClick={onSave}>
        {getLabel(LangConstant.TXT_SAVE)}
      </Button>
    </DialogContent>
  );
};

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    borderRadius: "0%",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[800],
    padding: "0px 0px 0px 24px",
  },
  textTitle: {
    marginLeft: "auto",
    paddingTop: "5%",
  },
  closeButton: {
    marginLeft: "auto",
  },
  dialogContent: {
    marginTop: 45,
    textAlign: "center",
    width: 430,
    margin: "auto",
  },
  rowBox: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    borderBottom: "solid 1px #d4d5d8",
    paddingBottom: 24,
  },
  firstTitle: {
    textAlign: "left",
  },
  secondTitle: {
    textAlign: "left",
    marginTop: 24,
  },
  saveButton: {
    marginTop: 180,
    // margin: "auto",
    width: 152,
    height: 46,
  },
}));

export default memo(NotificationSetting);
