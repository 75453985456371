import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { BorderColorOutlined } from "@material-ui/icons";
import { Box, Hidden, IconButton, makeStyles, Radio, Typography, FormControlLabel } from "@material-ui/core";
import { LangConstant } from "const";

const AddressItem = ({ isSetting, isChecked, addressDetail, onSelect, onEdit }) => {
  const classes = useStyle();
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);

  return (
    <Box className={classes.addressItemRoot}>
      <FormControlLabel
        onClick={() => onSelect(addressDetail)}
        classes={{
          root: classes.addressItemLabel,
        }}
        control={
          <Radio
            className={isSetting ? "hidden" : classes.radioButton}
            checked={isChecked}
            value={addressDetail.uid}
            color="primary"
          />
        }
        label={
          <>
            <Box className={classes.addressNameContainer}>
              <Typography variant="subtitle2" className={classes.addressName}>
                {addressDetail.title}
              </Typography>
              {addressDetail.defaultF && (
                <Typography variant="body2" className={classes.defaultBanner}>
                  {getLabel(LangConstant.TXT_DEFAULT)}
                </Typography>
              )}
            </Box>
            <Typography variant="body2" className={classes.receiverName}>
              {addressDetail.contactName}
            </Typography>
            <Typography variant="body2" className={classes.address}>
              {addressDetail.address}
            </Typography>
            <Typography variant="body2" className={classes.phone}>
              {addressDetail.phoneNumber}
            </Typography>
          </>
        }
      />

      <Hidden xsDown>
        <IconButton
          className={classes.editAddressButton}
          onClick={() => {
            onEdit(addressDetail);
          }}
        >
          <BorderColorOutlined className={classes.editAddressIcon} />
        </IconButton>
      </Hidden>
    </Box>
  );
};

AddressItem.propTypes = {
  isChecked: PropTypes.bool,
  isSetting: PropTypes.bool,

  onSelect: PropTypes.func,
  onEdit: PropTypes.func,

  addressDetail: PropTypes.shape({
    uid: PropTypes.string,
    title: PropTypes.string,
    contactName: PropTypes.string,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,

    defaultF: PropTypes.bool,
  }),
};

AddressItem.defaultProps = {
  isChecked: false,
  isSetting: false,

  onSelect: () => {},
  onEdit: () => {},

  addressDetail: {
    uid: "",
    phoneNumber: "",
    title: "",
    contactName: "",
    address: "",

    defaultF: false,
  },
};

const useStyle = makeStyles(theme => ({
  addressItemContainer: {
    marginBottom: 24,
  },

  radioButton: {
    display: "flex",
    justifyContent: "center",
    marginRight: 16,
    padding: 0,
    alignSelf: "self-start",
    "&>span": {
      height: 20,
      width: 20,
    },
  },

  addressDetailContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    maxWidth: "100%",
    "& p": {
      lineHeight: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: 0,
      marginLeft: "auto",
    },
  },

  editAddressIcon: {
    color: "#9D9FA5",
    cursor: "pointer",
  },

  addressName: {
    wordBreak: "break-word",
  },

  defaultBanner: {
    color: theme.palette.white,
    backgroundColor: theme.palette.divider,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 11,
    lineHeight: 1.8,
    padding: "0 10px",
    marginLeft: 10,
    minWidth: "max-content",
    height: "fit-content",
  },

  addressNameContainer: {
    display: "flex",
    marginBottom: 6,
  },

  receiverName: {
    color: theme.palette.black,
  },

  addressItemLabel: {
    margin: 0,
  },

  addressItemRoot: {
    display: "flex",
    marginBottom: 16,
    justifyContent: "space-between",
  },

  editAddressButton: {
    height: 32,
    width: 32,
    marginLeft: 16,
    padding: 0,
  },
}));

export default AddressItem;
