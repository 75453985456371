import React, { memo, useEffect, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  OutlinedInput,
  IconButton,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ArrowBackIos, Close } from "@material-ui/icons";
import StringFormat from "string-format";
import { getCommonKey, isNumber } from "utils";
import { ConfirmDialog } from "components/dialog";

const DialogSMS = ({ isShowAdd, isShowEdit, onClose, onChange, onSubmit, onDelete, data }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_NOTIFICATION_SETTING);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [value, setValue] = useState(data || "");
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const onChangePhoneNumber = event => {
    let inputValue = event.target.value;
    if (isNumber(inputValue) || inputValue === "") {
      onChange(event.target.value);
      setValue(event.target.value);
    }
  };

  const onConfirm = (event, isAgree) => {
    event.stopPropagation();
    if (isAgree) {
      onDelete();
    }
    setIsOpenConfirm(false);
  };

  useEffect(() => {
    setValue(data || "");
  }, [data]);

  return (
    <Dialog
      open={isShowAdd || isShowEdit}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialogPaper,
      }}
      BackdropProps={{
        open: !isMobile,
        invisible: isMobile,
      }}
      maxWidth="lg"
      disableBackdropClick={true}
      fullScreen={isMobile}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Hidden smUp>
          <IconButton className={classes.BackBtn} onClick={onClose}>
            <ArrowBackIos className={classes.backIcon} />
          </IconButton>
        </Hidden>
        <Typography classes={{ body1: classes.textDialogTitle }}>
          {getLabel(isShowEdit ? LangConstant.TXT_EDIT_SMS : LangConstant.TXT_ADD_PHONE_NUMBER)}
        </Typography>
        <Hidden xsDown>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </Hidden>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.boxContent}>
          <Typography className={classes.typographyContent}>
            {getLabel(getCommonKey(LangConstant.TXT_PHONE_NUMBER))}
          </Typography>
          <OutlinedInput
            classes={{ root: classes.contentLineEdit, input: classes.inputEdit, disabled: classes.disabled }}
            required
            onChange={onChangePhoneNumber}
            inputProps={{
              type: "tel",
            }}
            value={value}
          />
        </Box>
        <Typography className={classes.typographyEmail}>
          {getLabel(LangConstant.TXT_NOTIFICATION_CONFIRMATION_SMS)}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {isShowEdit && (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonSubmit}
            onClick={() => setIsOpenConfirm(true)}
          >
            {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
          </Button>
        )}
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          className={isShowEdit ? classes.buttonSubmit : classes.buttonConfirm}
        >
          {getLabel(getCommonKey(isShowEdit ? LangConstant.TXT_SAVE : LangConstant.TXT_CONFIRM))}
        </Button>
      </DialogActions>
      {isOpenConfirm && (
        <ConfirmDialog
          onSelectConfirm={onConfirm}
          title={StringFormat(getLabel(LangConstant.FM_CONFIRMATION_HEADER_SMS), data)}
          titleConfirm={getLabel(LangConstant.TXT_CONFIRMATION_BODY_SMS)}
        />
      )}
    </Dialog>
  );
};

DialogSMS.propTypes = {
  isShowAdd: PropTypes.bool,
  isShowEdit: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.string,
};
DialogSMS.defaultProps = {
  isShowAdd: false,
  isShowEdit: false,
};

export default memo(DialogSMS);

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 60px)",
      marginTop: 60,
    },
  },
  dialogPaper: {
    width: 700,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: 0,
      "&>:first-child": {
        padding: "30px 12px",
        "&>button": {
          display: "none",
        },
      },
    },
  },
  dialogTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    background: "#65b39d",
    color: theme.palette.white,
    height: 60,
    padding: "0 24px",
    zIndex: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      background: theme.palette.white,
      justifyContent: "center",
    },
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  textDialogTitle: {
    color: theme.palette.white,
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      color: theme.palette.black,
    },
  },
  closeButton: {
    position: "absolute",
    right: 14,
    top: 18,
    color: theme.palette.grey[500],
    height: 24,
    width: 24,
    padding: 0,
  },
  BackBtn: {
    position: "absolute",
    left: 14,
    top: 18,
    height: 24,
    width: 24,
    padding: 0,
  },
  backIcon: {
    fontSize: 24,
    color: theme.palette.black,
  },
  closeIcon: {
    fontSize: 24,
    color: theme.palette.white,
  },
  dialogContent: {
    padding: "8px 24px 17px",
    display: "flex",
    flex: "none ",
    flexDirection: "column",
    "&:first-child": {
      paddingTop: 26,
      [theme.breakpoints.down("xs")]: {
        padding: "26px 0 20px",
      },
    },
  },
  boxContent: {
    display: "flex",
    paddingTop: 30,
  },
  typographyEmail: {
    margin: "8px 0",
    fontSize: 13,
    fontWeight: 600,
    color: "#3e4045",
    lineHeight: "22px",
    paddingTop: 10,
  },
  typographyContent: {
    margin: "8px 0",
    fontSize: 13,
    fontWeight: 600,
    color: "#3e4045",
    lineHeight: "22px",
    width: 150,
  },
  contentLineEdit: {
    width: "100%",
    height: 40,
    fontSize: 13,
    padding: "9px 16px",
    "&$disabled": {
      backgroundColor: "#d4d5d8",
      opacity: 0.3,
      color: "#565c6a",
      border: "none",
      cursor: "no-drop",
    },
  },
  disabled: {
    backgroundColor: "#d4d5d8",
    opacity: 0.3,
    border: "none",
    cursor: "no-drop",
  },
  inputEdit: {
    padding: 0,
  },
  dialogActions: {
    display: "block",
    textAlign: "right",
    padding: "19px 16px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  buttonCancel: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: 0,
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
  buttonSubmit: {
    textTransform: "none",
    width: 58,
    height: 30,
    margin: "0 24px 0",
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
  buttonConfirm: {
    textTransform: "none",
    width: 150,
    height: 30,
    margin: "0 24px 0",
    padding: "3px 16px",
    borderRadius: 2,
    fontWeight: 500,
    minHeight: "unset",
  },
}));
