import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SentimentSatisfiedAltOutlined, ChildCareOutlined } from "@material-ui/icons";
import { LangConstant } from "const";
import { useState } from "react";
import InputIcon from "./InputIcon";
import { onDoNothing } from "utils";

const InputPeople = ({ className, consumerSetting }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);

  const [isShowConsumerInput, setIsShowConsumerInput] = useState(false);
  const [isChild, setIsChild] = useState(false);

  useEffect(() => {
    if (consumerSetting) {
      setIsShowConsumerInput(Boolean(consumerSetting.publish));
      if (consumerSetting.details?.child) {
        setIsChild(Boolean(consumerSetting.details?.child?.publish));
      }
    }
  }, [consumerSetting]);

  return (
    isShowConsumerInput && (
      <>
        <InputIcon
          value={""}
          icon={<SentimentSatisfiedAltOutlined />}
          placeholder={isChild ? getLabel(LangConstant.P_PEOPLE) : getLabel(LangConstant.P_MAN)}
          onChange={onDoNothing}
          required
          name={isChild ? STR_MAN : STR_TOTAL}
          classes={{
            root: className,
            disabled: className,
          }}
        />
        {isChild && (
          <InputIcon
            value={""}
            icon={<ChildCareOutlined />}
            placeholder={getLabel(LangConstant.P_CHILDREN)}
            onChange={onDoNothing}
            name={STR_CHILD}
            classes={{
              root: className,
              disabled: className,
            }}
          />
        )}
      </>
    )
  );
};

InputPeople.propTypes = {
  className: PropTypes.any,
};

InputPeople.defaultProps = {};

export default memo(InputPeople);

const STR_CHILD = "child";
const STR_TOTAL = "total";
const STR_MAN = "man";
