import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles, StepLabel, useMediaQuery, useTheme } from "@material-ui/core";
import StatusIcon from "./StatusIcon";
import StatusButton from "./StatusButton";
import { AppConstant, LangConstant } from "const";
import { getBillStatusColor } from "utils";

const StepperOder = ({ entry, statusIndex, onChange, currentStatus, currentStatusIndex, currentSelected }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t: getLabel } = useTranslation(LangConstant.NS_VAT);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let statusArray = [...Object.values(AppConstant.STT_VAT_BILL)];
  let sttValue = entry;

  let sttStyle = getBillStatusColor(sttValue);

  let isNext = statusArray.indexOf(currentStatusIndex) + 1 === statusIndex;
  let isActive =
    statusIndex === statusArray.indexOf(currentSelected) || statusIndex === statusArray.indexOf(currentStatusIndex);
  let isPassed = statusIndex < statusArray.indexOf(currentStatusIndex);

  return (
    <StepLabel
      classes={{
        root: classes.root,
        iconContainer: classes.stepLabel,
      }}
      StepIconComponent={() => (
        <StatusIcon
          isActive={isActive}
          color={sttStyle}
          sttValue={entry}
          currentStatus={currentStatus}
          isMobile={isMobile}
        >
          {statusIndex + 1}
        </StatusIcon>
      )}
    >
      <StatusButton
        disabled={isPassed}
        isNext={isNext}
        isActive={isActive}
        color={sttStyle}
        onClick={() => onChange(entry)}
      >
        {getLabel(LangConstant.VAT_MANAGEMENT_FILTER_STATUS_ARRAY[statusIndex])}
      </StatusButton>
    </StepLabel>
  );
};

StepperOder.propTypes = {
  status: PropTypes.number,
  onChange: PropTypes.func,
};

const useStyles = makeStyles({
  stepLabel: {
    padding: 0,
    marginBottom: 16,
  },

  root: {
    flexDirection: "column",
  },
});
export default memo(StepperOder);
