import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, OutlinedInput, Typography, InputAdornment } from "@material-ui/core";
import { checkInteger, formatCurrency } from "utils";
import clsx from "clsx";

const InputPrice = props => {
  const { currencyUnit, onChange, classPrice, checkDisable, valueInput, ...otherProps } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(valueInput != null ? valueInput : "");
  const onTyping = event => {
    var maxLength = event.target.value;
    maxLength = maxLength.split(".").join("");
    if (maxLength === "" || checkInteger(maxLength)) {
      if (maxLength.length < 10) {
        var valueInput = formatCurrency(maxLength);
        if (Boolean(onChange)) {
          const inputPrice = parseInt(event.target.value.split(".").join(""));
          if (!isNaN(inputPrice)) {
            onChange(inputPrice);
          } else {
            onChange(0);
          }
        }
        setInputValue(valueInput);
      }
    }
  };
  useEffect(() => {
    if (valueInput != null) {
      if (valueInput.indexOf(".") !== -1) {
        setInputValue(valueInput);
      } else {
        setInputValue(formatCurrency(valueInput));
      }
    }
  }, [valueInput]);

  useEffect(() => {
    if (valueInput) {
      let maxLength = valueInput;
      maxLength = maxLength.split(".").join("");
      if (maxLength === "" || checkInteger(maxLength)) {
        if (maxLength.length < 10) {
          let valueInput = formatCurrency(maxLength);
          setInputValue(valueInput);
        }
      }
    } else {
      setInputValue("");
    }
  }, [valueInput]);

  useEffect(() => {
    if (valueInput) {
      let maxLength = valueInput;
      maxLength = maxLength.split(".").join("");
      if (maxLength === "" || checkInteger(maxLength)) {
        if (maxLength.length < 10) {
          let valueInput = formatCurrency(maxLength);
          setInputValue(valueInput);
        }
      }
    } else {
      setInputValue("");
    }
  }, [valueInput]);

  return (
    <Box className={classes.root}>
      <OutlinedInput
        classes={{
          root: clsx(classes.colorText, classPrice && classPrice),
          input: clsx(classes.resize, otherProps.classes?.inputOverridePrice),
        }}
        disabled={checkDisable ? true : false}
        placeholder={"0.00"}
        value={inputValue}
        onChange={onTyping}
        endAdornment={
          <InputAdornment position="end" className={clsx(classes.title, otherProps.classes?.inputOverride)}>
            <Typography variant="body2">{currencyUnit}</Typography>
          </InputAdornment>
        }
      />
    </Box>
  );
};

InputPrice.propTypes = {
  onChange: PropTypes.func,
  currencyUnit: PropTypes.string.isRequired,
  classPrice: PropTypes.string,
};

InputPrice.defaultProps = { currencyUnit: "" };

export default memo(InputPrice);

const useStyles = makeStyles({
  root: {
    display: "flex",
    maxHeight: 40,
  },
  resize: {
    fontSize: 14,
    "&.Mui-disabled": {
      cursor: "no-drop",
      color: "#3e4045",
    },
  },
  colorText: {
    "&.Mui-disabled": {
      color: "#3e4045",
    },
    "&.MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  title: {
    justifyContent: "center",
    borderLeft: "solid 0.5px #cecfd2",
    marginLeft: 0,
    minWidth: 40,
    width: 40,
    height: "100%",
    maxHeight: "100%",
    minHeight: 40,
  },
});
