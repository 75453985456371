import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { Slider, IconButton, makeStyles, Box } from "@material-ui/core";
import { Remove, Add } from "@material-ui/icons";
import { AppConstant } from "const";
import ReactCrop from "react-image-crop";

const CropImageInterface = forwardRef((props, ref) => {
  const classes = useStyles();

  const { aspect, onSubmit, file, isCropping, setIsCropping, isEnableCrop } = props;

  const iniCropImageState = {
    src: null,
    unCroppedFiles: [],
    croppedFile: null,
    imagePercentage: 100,
  };
  const cropIniState = {
    unit: "%",
    aspect: aspect,
  };

  const [cropImageState, setCropImageState] = useState(iniCropImageState);
  const [cropSize, setCropSize] = useState(cropIniState);
  const [imageRef, setImageRef] = useState();
  const [originSize, setOriginSize] = useState({
    width: 0,
    height: 0,
  });

  useImperativeHandle(ref, () => ({
    onReset() {
      setCropSize(cropIniState);
      setCropImageState(iniCropImageState);
      setIsCropping(false);
      document.getElementById("files").value = "";
    },
  }));

  const makeClientCrop = async crop => {
    if (imageRef && crop.width && crop.height) {
      let tmpName = "";
      try {
        tmpName = `${cropImageState.src
          .split(";")[1]
          .split(",")[1]
          .slice(0, 6)
          .replace(/[^a-zA-Z ]/g, "")}.${cropImageState.src.split(";")[0].split(":")[1].split("/")[1]}`;
      } catch {
        tmpName = "tempName.png";
      }
      const croppedUrl = await getCroppedImg(imageRef, crop);
      dataURLtoFile(croppedUrl, `${tmpName}`);
    }
  };

  const onImageLoaded = image => {
    setImageRef(image);
  };

  const onCropComplete = crop => {
    makeClientCrop(crop);
  };

  const dataURLtoFile = (dataUrl, filename) => {
    fetch(dataUrl)
      .then(res => res.blob())
      .then(blob => {
        onSubmit({
          croppedImageUrl: dataUrl,
          croppedImageFile: new File([blob], `${filename}`, {
            type: `image/${filename.split(".")[1]}` || AppConstant.ACCEPT_IMAGE[2],
          }),
        });
      });
  };
  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        resolve(canvas.toDataURL(AppConstant.ACCEPT_IMAGE));
      }, AppConstant.QUALITY_IMAGE);
    });
  };

  const onCropChange = crop => {
    setCropSize(crop);
  };

  const onChangeCropSizeBySlider = newValue => {
    if (isCropping) {
      setCropImageState({ ...cropImageState, imagePercentage: newValue });
    }
  };
  const onDecreaseSize = () => {
    if (cropImageState.imagePercentage > 20) {
      let preState = cropImageState;
      setCropImageState({ ...cropImageState, imagePercentage: preState.imagePercentage - 10 });
    }
  };
  const onIncreaseSize = () => {
    if (cropImageState.imagePercentage < 200) {
      let preState = cropImageState;
      setCropImageState({ ...cropImageState, imagePercentage: preState.imagePercentage + 10 });
    }
  };

  let container = document.getElementById(IMAGE_CROP_AREA_ID);

  useEffect(() => {
    setCropImageState({ ...cropImageState, imagePercentage: 100 });
  }, [isCropping]);

  useEffect(() => {
    if (isEnableCrop) {
      setIsCropping(true);
    }
  }, [isEnableCrop]);

  useEffect(() => {
    if (isCropping) {
      setCropSize({ ...cropSize, unit: "%", width: aspect <= 1 ? 95 : 0, height: aspect <= 1 ? 0 : 95 });
    }
  }, [isCropping, cropImageState.imagePercentage, originSize.height, originSize.width, container]);

  useEffect(() => {
    if (cropSize) {
      makeClientCrop(cropSize);
    }
  }, [cropSize]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setCropImageState({ ...cropImageState, src: reader.result });
      };
    }
  }, [file]);

  useEffect(() => {
    if (isCropping && container) {
      let containerWidth = container.offsetWidth;
      let containerHeight = container.offsetHeight;
      if (
        document.getElementsByClassName(IMAGE_CROP_COMPONENT_CLASS_NAME) &&
        document.getElementsByClassName(IMAGE_CROP_COMPONENT_CLASS_NAME)[0]
      ) {
        document.getElementsByClassName(IMAGE_CROP_COMPONENT_CLASS_NAME)[0].style.width = `${containerWidth}px`;
      }
      setOriginSize({
        width: containerWidth,
        height: containerHeight,
      });
    }
  }, [container, isCropping]);

  useEffect(() => {
    if (
      isCropping &&
      document.getElementsByClassName(IMAGE_CROP_COMPONENT_CLASS_NAME) &&
      document.getElementsByClassName(IMAGE_CROP_COMPONENT_CLASS_NAME)[0]
    ) {
      let newWidth = (originSize.width * cropImageState.imagePercentage) / 100;
      document.getElementsByClassName(IMAGE_CROP_COMPONENT_CLASS_NAME)[0].style.width = `${newWidth}px`;
    }
  }, [cropImageState.imagePercentage]);

  return (
    <>
      {cropImageState.src && (
        <>
          {isCropping ? (
            <Box
              className={`${isCropping ? classes.imageAreaCropping : classes.imageArea} crop-root`}
              id={IMAGE_CROP_AREA_ID}
            >
              <ReactCrop
                src={cropImageState.src}
                crop={cropSize}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
                className={classes.cropZone}
                imageStyle={{
                  maxHeight: "100%",
                  maxWidth: "unset",
                  width: "100%",
                }}
              />
            </Box>
          ) : (
            <img src={cropImageState.src} className={classes.imageArea} />
          )}

          {isCropping && (
            <Box className={classes.sliderContainer}>
              <IconButton onClick={onDecreaseSize} color="inherit" className={classes.changeSizeBtn}>
                <Remove className={classes.changeSizeIcon} />
              </IconButton>

              <Slider
                value={cropImageState.imagePercentage}
                step={10}
                min={20}
                max={200}
                onChange={(event, newValue) => {
                  onChangeCropSizeBySlider(newValue);
                }}
                classes={{
                  root: classes.slideBarMain,
                  thumb: classes.slideThumb,
                  rail: classes.slideLine,
                  track: classes.slideTrack,
                }}
              />

              <IconButton onClick={onIncreaseSize} color="inherit" className={classes.changeSizeBtn}>
                <Add className={classes.changeSizeIcon} />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </>
  );
});

export default CropImageInterface;
const useStyles = makeStyles(theme => ({
  imageArea: {
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    "&>:first-child": {
      maxHeight: "100%",
      overflow: "auto",
    },
    [theme.breakpoints.down(1125)]: {
      height: "unset",
    },
  },
  imageAreaCropping: {
    maxHeight: "97%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#343434",
    marginTop: "-6%",
    width: "95%",
    justifyContent: "center",
    "&>:first-child": {
      maxHeight: "100%",
      overflow: "auto",
      [theme.breakpoints.down(750)]: {
        maxHeight: "90%",
      },
    },
    [theme.breakpoints.down(750)]: {
      width: "100%",
      minHeight: "90%",
      justifyContent: "center",
    },
  },
  imageBox: {
    alignSelf: "center",
    minWidth: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imagePercentage: {
    width: 550,
  },
  cropZone: {
    height: "fit-content",
  },
  changeSizeBtn: {
    color: "#fff",
    borderRadius: "50%",
    width: 20,
    height: 20,
    border: "2px solid #fff",
  },
  changeSizeIcon: {
    fontSize: 18,
  },
  sliderContainer: {
    display: "flex",
    marginTop: 8,
    width: "60%",
    alignItems: "center",
    position: "absolute",
    bottom: 20,
    [theme.breakpoints.down(750)]: {
      marginTop: 0,
      position: "absolute",
      bottom: 8,
      padding: "0 12px",
      width: "100%",
    },
  },
  slideBarMain: {
    padding: 0,
    margin: "0px 10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: 20,
  },
  slideThumb: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: "#fff",
    width: 16,
    height: 16,
    margin: 0,
  },
  slideTrack: {
    borderBottomLeftRadius: 6.5,
    borderTopLeftRadius: 6.5,
    height: 4,
  },
  slideLine: {
    borderRadius: 6.5,
    height: 4,
    backgroundColor: "#fafafb",
    opacity: 1,
  },
}));

CropImageInterface.propTypes = {
  aspect: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  isCropping: PropTypes.bool,
  setIsCropping: PropTypes.func,
  file: PropTypes.any,
  isEnableCrop: PropTypes.bool,
};
CropImageInterface.defaultProps = {
  isCropping: false,
  isEnableCrop: false,
};

const IMAGE_CROP_AREA_ID = "image-area";
const IMAGE_CROP_COMPONENT_CLASS_NAME = "ReactCrop__image";
