import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { AppConstant, LangConstant } from "const";
import InputBase from "@material-ui/core/InputBase";
import CustomDatePickerStaff from "./CustomDatePickerStaff";
import { format, isExists } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import StaffAction from "redux/staff.redux";
import { DialogCreatePositionStaff, DialogStaffWorkingShift } from "components/dialog";
import { checkFuture } from "utils/date";

// eslint-disable-next-line no-unused-vars
const CreateStaffForm = forwardRef((props, ref) => {
  let isEditing = true;
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const dispatch = useDispatch();
  const { showTime, showPosition, action } = props;

  const createStaffStatus = useSelector(state => state.staffRedux.isSuccessCreateStaff);
  const newWS = useSelector(state => state.staffRedux.newWorkShift);
  const newPS = useSelector(state => state.staffRedux.newPosition);
  const createWsSuccess = useSelector(state => state.staffRedux.isSuccess);
  const createPsSuccess = useSelector(state => state.staffRedux.isSuccessPosition);

  const [error, setError] = useState(0);
  const [listTime, setListTime] = useState(showTime);
  const [listPosition, setListPosition] = useState(showPosition);
  const [isShowWorkingShiftDialog, setIsShowWorkShiftDialog] = useState(false);
  const [isShowPositionDialog, setIsShowPositionDialog] = useState(false);

  const [formControlState, setFormControlState] = useState({
    workShift: "",
    gender: "",
    position: "",
    status: "",
    role: "",
    phone: "",
    name: "",
    email: null,
    birthday: null,
    staffCode: "",
    isWarning: false,
    startDay: format(new Date(), AppConstant.FM_YYYY_MM_DD),
    stopDay: format(new Date(), AppConstant.FM_YYYY_MM_DD),
  });

  const [birthDay, setBirthDay] = useState({
    dayOfBirth: null,
    monthOfBirth: null,
    yearOfBirth: null,
  });

  const onChangeBirthDay = e => {
    let name = e.target.name;
    let value = e.target.value;
    setBirthDay({
      ...birthDay,
      [name]: value,
    });
    setFormControlState({
      ...formControlState,
      birthday:
        new Date(
          name !== "yearOfBirth" ? birthDay.yearOfBirth : value,
          name !== "monthOfBirth" ? birthDay.monthOfBirth - 1 : value - 1,
          name !== "dayOfBirth" ? birthDay.dayOfBirth : value,
        ).getTime() / 1000,
    });
  };

  const onChangeInput = e => {
    let name = e.target.name;
    let value = e.target.value;
    setFormControlState({ ...formControlState, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(newWS).length > 0) {
      setListTime([...listTime, newWS]);
      setFormControlState({ ...formControlState, workShift: newWS.uid });
      dispatch(StaffAction.resetStaff());
    }
  }, [newWS]);

  useEffect(() => {
    if (Object.keys(newPS).length > 0) {
      setListPosition([...listPosition, newPS]);
      setFormControlState({ ...formControlState, position: newPS.uid });
      dispatch(StaffAction.resetStaff());
    }
  }, [newPS]);

  useEffect(
    e => {
      if (createStaffStatus) {
        action(e);
      }
    },
    [action, createStaffStatus],
  );

  useEffect(() => {
    if (createWsSuccess) {
      dispatch(StaffAction.requestWorkingShift({}));
      dispatch(StaffAction.resetStaff());
    }
    if (createPsSuccess) {
      dispatch(StaffAction.requestStaffPosition({}));
      dispatch(StaffAction.resetStaff());
    }
  }, [createPsSuccess, createWsSuccess]);

  const getDateCustom = date => {
    let current = new Date(date);
    let formatDate = format(current, AppConstant.FM_YYYY_MM_DD);
    if (formControlState.startDay !== formatDate) {
      setFormControlState({ ...formControlState, startDay: formatDate });
    }
  };
  const getStopDate = date => {
    let current = new Date(date);
    let formatDate = format(current, AppConstant.FM_YYYY_MM_DD);
    if (formControlState.stopDay !== formatDate) {
      setFormControlState({ ...formControlState, stopDay: formatDate });
    }
  };

  function generate(size, start) {
    return [...Array(size)].map((_, index) => (
      <MenuItem key={index} value={index + start} className={classes.menuOption}>
        {index + start}
      </MenuItem>
    ));
  }

  const onSubmitDialog = () => {
    setIsShowPositionDialog(false);
  };

  const onSubmitDialogWS = () => {
    setIsShowWorkShiftDialog(false);
  };
  const staffData = {
    name: formControlState.name,
    role: formControlState.role,
    phoneNumber: formControlState.phone,
    email: formControlState.email || null,
    birthday: formControlState.birthday,
    gender: formControlState.gender,
    startTime: Math.floor(new Date(formControlState.startDay).getTime() / 1000),
    endTime: Math.floor(new Date(formControlState.stopDay).getTime() / 1000),
    code: formControlState.staffCode,
    workingShiftUid: formControlState.workShift,
    staffPositionUid: formControlState.position,
    state: formControlState.status,
  };

  const createStaff = () => {
    if (
      formControlState.role &&
      formControlState.phone &&
      formControlState.phone.match(/^\d{10}$/) &&
      formControlState.position &&
      formControlState.status &&
      formControlState.name &&
      formControlState.name.replace(/^\s+/, "").replace(/\s+$/, "") !== "" &&
      formControlState.workShift
    ) {
      if (
        isExists(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth) &&
        !checkFuture(new Date(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth))
      ) {
        let tmpSubmitData = { ...staffData };
        if (!tmpSubmitData.email) {
          delete tmpSubmitData["email"];
          dispatch(StaffAction.requestCreateStaff(tmpSubmitData));
        } else {
          dispatch(StaffAction.requestCreateStaff(staffData));
        }
        setFormControlState({ ...formControlState, isWarning: false });
      } else if (formControlState.birthday === null) {
        let tmpSubmitData = { ...staffData };
        if (!tmpSubmitData.email) {
          delete tmpSubmitData["email"];
          dispatch(StaffAction.requestCreateStaff(tmpSubmitData));
        } else {
          dispatch(StaffAction.requestCreateStaff(staffData));
        }
        setFormControlState({ ...formControlState, isWarning: false });
      }
    }
  };

  const onSubmit = event => {
    event.preventDefault();
    if (
      formControlState.role &&
      formControlState.phone &&
      formControlState.phone.match(/^\d{10}$/) &&
      formControlState.position &&
      formControlState.status &&
      formControlState.name &&
      formControlState.name.replace(/^\s+/, "").replace(/\s+$/, "") !== "" &&
      formControlState.workShift
    ) {
      if (birthDay.dayOfBirth || birthDay.monthOfBirth || birthDay.yearOfBirth) {
        if (!isExists(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth)) {
          setFormControlState({ ...formControlState, isWarning: true });
          setError(AppConstant.ERROR_CODE.invalid_birthday);
        }
        if (checkFuture(new Date(birthDay.yearOfBirth, birthDay.monthOfBirth - 1, birthDay.dayOfBirth))) {
          setFormControlState({ ...formControlState, isWarning: true });
          setError(AppConstant.ERROR_CODE.invalid_birthday);
        }
      } else
        setFormControlState({
          ...formControlState,
          birthday: null,
        });
    } else if (formControlState.phone && !formControlState.phone.match(/^\d{10}$/)) {
      setFormControlState({ ...formControlState, isWarning: true });
      setError(AppConstant.ERROR_CODE.invalid_phone);
    } else {
      setFormControlState({ ...formControlState, isWarning: true });
      setError(AppConstant.ERROR_CODE.empty_field);
    }
    createStaff();
  };

  return (
    <>
      <Typography variant="h5" className={classes.formTitle}>
        {getLabel(LangConstant.TXT_CREATE_NEW_STAFF)}
      </Typography>
      <form onSubmit={onSubmit} className={classes.validateForm}>
        <Box className={classes.formContainer}>
          <Box component="div" className={classes.leftColumn}>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_FULLNAME)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <TextField
                required={true}
                variant="outlined"
                size="small"
                name="name"
                value={formControlState.name}
                onChange={onChangeInput}
                className={classes.inputRow}
                placeholder={getLabel(LangConstant.TXT_FULLNAME)}
                inputProps={{
                  className: classes.createStaffInput,
                }}
              />
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_PHONE_NUMBER)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <TextField
                type="number"
                required={true}
                variant="outlined"
                size="small"
                name="phone"
                value={formControlState.phone}
                onChange={onChangeInput}
                className={classes.inputRow}
                placeholder={getLabel(LangConstant.TXT_PHONE_NUMBER)}
                inputProps={{
                  className: classes.createStaffInput,
                }}
              />
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_BIRTH_DAY)}
              </Typography>
              <Box component="div" className={classes.startDateContainer}>
                <Box className={classes.customDatePickerInput}>
                  <Select
                    value={birthDay.dayOfBirth ? birthDay.dayOfBirth : ""}
                    name="dayOfBirth"
                    onChange={onChangeBirthDay}
                    input={<BootstrapInput />}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "420px" },
                      },
                    }}
                  >
                    <MenuItem>
                      <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_DAY)}</em>
                    </MenuItem>
                    {generate(31, 1)}
                  </Select>
                </Box>
                <Box className={classes.customDatePickerInput}>
                  <Select
                    value={birthDay.monthOfBirth ? birthDay.monthOfBirth : ""}
                    name="monthOfBirth"
                    onChange={onChangeBirthDay}
                    className="select small"
                    input={<BootstrapInput />}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "420px" },
                      },
                    }}
                  >
                    <MenuItem>
                      <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_MONTH)}</em>
                    </MenuItem>
                    {generate(12, 1)}
                  </Select>
                </Box>
                <Box className={classes.customDatePickerInput}>
                  <Select
                    value={birthDay.yearOfBirth ? birthDay.yearOfBirth : ""}
                    name="yearOfBirth"
                    onChange={onChangeBirthDay}
                    className="select small"
                    input={<BootstrapInput />}
                    MenuProps={{
                      PaperProps: {
                        style: { maxHeight: "420px" },
                      },
                    }}
                  >
                    <MenuItem>
                      <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_YEAR)}</em>
                    </MenuItem>
                    {generate(new Date().getFullYear() - 1949, 1950)}
                  </Select>
                </Box>
              </Box>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_STARTDATE)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Box className={classes.datePickerContainer}>
                <CustomDatePickerStaff
                  onChange={getDateCustom}
                  selectedDateProp={
                    formControlState.startDay
                      ? format(new Date(formControlState.startDay), AppConstant.FM_YYYY_MM_DD)
                      : new Date()
                  }
                  className={classes.datePicker}
                  isEditing={isEditing}
                />
              </Box>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_WORK_TIME)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <TextField
                select
                required
                variant="outlined"
                name="workShift"
                value={formControlState.workShift}
                onChange={onChangeInput}
                className={classes.inputRow}
              >
                <MenuItem>
                  <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_WORK_TIME)}</em>
                </MenuItem>
                <MenuItem className={classes.menuOption} onClick={() => setIsShowWorkShiftDialog(true)}>
                  {getLabel(LangConstant.TXT_ADD_NEW)}
                </MenuItem>
                {listTime.map((data, index) => (
                  <MenuItem key={index + 2} value={data.uid} className={classes.menuOption}>
                    {data.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_STAFFCODE)}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                name="staffCode"
                value={formControlState.staffCode}
                onChange={onChangeInput}
                className={classes.inputRow}
                placeholder={getLabel(LangConstant.TXT_STAFFCODE)}
                inputProps={{
                  className: classes.createStaffInput,
                }}
              />
            </Box>
          </Box>
          <Box component="div" className={classes.rightColumn}>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_ROLE)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <TextField
                select
                required
                variant="outlined"
                name="role"
                value={formControlState.role}
                onChange={onChangeInput}
                className={classes.inputRow}
              >
                <MenuItem>
                  <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_ROLE)}</em>
                </MenuItem>
                <MenuItem value={3} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_MANAGER)}
                </MenuItem>
                <MenuItem value={4} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_STAFF)}
                </MenuItem>
              </TextField>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_EMAIL)}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                name="email"
                value={formControlState.email}
                onChange={onChangeInput}
                className={classes.inputRow}
                placeholder={getLabel(LangConstant.TXT_EMAIL)}
                inputProps={{
                  className: classes.createStaffInput,
                }}
              />
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_GENDER)}
              </Typography>
              <Select
                variant="outlined"
                name="gender"
                value={formControlState.gender}
                onChange={onChangeInput}
                className={classes.inputRow}
                classes={{
                  root: classes.selectRoot,
                }}
              >
                <MenuItem value="">
                  <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_GENDER)}</em>
                </MenuItem>
                <MenuItem value={AppConstant.GENDER_PICKER.male} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_MAN)}
                </MenuItem>
                <MenuItem value={AppConstant.GENDER_PICKER.female} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_WOMAN)}
                </MenuItem>
                <MenuItem value={AppConstant.GENDER_PICKER.other} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_OTHER)}
                </MenuItem>
              </Select>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                {getLabel(LangConstant.TXT_STOPDATE)}
              </Typography>
              <Box className={classes.datePickerContainer}>
                <CustomDatePickerStaff
                  onChange={getStopDate}
                  selectedDateProp={
                    formControlState.stopDay
                      ? format(new Date(formControlState.stopDay), AppConstant.FM_YYYY_MM_DD)
                      : new Date()
                  }
                  className={classes.datePicker}
                  isEditing={isEditing}
                />
              </Box>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_POSITION)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Select
                required
                variant="outlined"
                name="position"
                value={formControlState.position === null ? 0 : formControlState.position}
                onChange={onChangeInput}
                className={classes.inputRow}
                classes={{
                  root: classes.selectRoot,
                }}
              >
                <MenuItem>
                  <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_POSITION)}</em>
                </MenuItem>
                <MenuItem
                  className={classes.menuOption}
                  onClick={() => {
                    setIsShowPositionDialog(true);
                  }}
                >
                  {getLabel(LangConstant.TXT_ADD_NEW)}
                </MenuItem>
                {listPosition.map((data, index) => (
                  <MenuItem key={index} value={data.uid}>
                    {data.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box component="div" className={classes.singleStaffInfoRow}>
              <Box display="flex">
                <Typography className={`${classes.staffFormLabel} semiBold-sm-txt`}>
                  {getLabel(LangConstant.TXT_STATUS)}
                </Typography>
                <Typography color="primary">&nbsp; &#42;</Typography>
              </Box>
              <Select
                required
                variant="outlined"
                name="status"
                value={formControlState.status}
                onChange={onChangeInput}
                className={classes.inputRow}
                classes={{
                  root: classes.selectRoot,
                }}
              >
                <MenuItem>
                  <em className={classes.placeHolder}>{getLabel(LangConstant.TXT_STATUS)}</em>
                </MenuItem>
                <MenuItem value={1} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_STAFF_STATUS_ACTIVE)}
                </MenuItem>
                <MenuItem value={2} className={classes.menuOption}>
                  {getLabel(LangConstant.TXT_STAFF_STATUS_INACTIVE)}
                </MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>

        <FormControl error className={classes.errorLabel}>
          {formControlState.isWarning === true && error === AppConstant.ERROR_CODE.invalid_birthday ? (
            <FormHelperText>{getLabel(LangConstant.ERR_INVALID_DAY)}</FormHelperText>
          ) : (
            <></>
          )}
          {formControlState.isWarning === true && error === AppConstant.ERROR_CODE.empty_field ? (
            <FormHelperText>{getLabel(LangConstant.ERR_MISSING_REQUIRED_FIELD)}</FormHelperText>
          ) : (
            <></>
          )}
          {formControlState.isWarning === true && error === AppConstant.ERROR_CODE.invalid_phone ? (
            <FormHelperText>{getLabel(LangConstant.ERR_INVALID_PHONE)}</FormHelperText>
          ) : (
            <></>
          )}
        </FormControl>

        <Button type="submit" className={classes.submitCreateStaffBtn} color="primary" variant="contained">
          <Typography variant="subtitle2" className={classes.submitBtnText}>
            {getLabel(LangConstant.TXT_ADD)}
          </Typography>
        </Button>
      </form>
      <DialogCreatePositionStaff
        isShow={isShowPositionDialog}
        onClose={onSubmitDialog}
        className={classes.postionDialog}
        onSubmit={onSubmitDialog}
      />
      <DialogStaffWorkingShift
        isShow={isShowWorkingShiftDialog}
        onClose={onSubmitDialogWS}
        className={classes.workShiftDialog}
        onSubmit={onSubmitDialogWS}
      />
    </>
  );
});

export default CreateStaffForm;
const useStyles = makeStyles(theme => ({
  formContainer: {
    display: "inline-flex",
    width: "90%",
    justifyContent: "space-evenly",
    minWidth: "550px",
    [theme.breakpoints.down("xs")]: {
      display: "flow-root",
      minWidth: "0px",
      marginRight: "3%",
      marginLeft: "3%",
      marginTop: "10%",
    },
    justifySelf: "center",
  },
  leftColumn: {
    minWidth: "25%",
  },
  rightColumn: {
    minWidth: "25%",
  },
  createStaffInput: {
    fontSize: theme.typography.body2.fontSize,
    "&::placeholder": {
      color: "#d4d5d8",
    },
  },
  inputRow: {
    maxHeight: "40px",
    minWidth: "100%",
  },
  singleStaffInfoRow: {
    textAlign: "left",
    minWidth: "250px",
    width: "100%",
    marginTop: "4vh",
    "& .MuiSelect-root": {
      padding: " 10.5px 14px",
    },
  },
  dateSelectContainer: {
    justifyContent: "space-between",
  },
  placeHolder: {
    color: "#d4d5d8",
    fontStyle: "unset",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: "unset",
    opacity: 0.4,
  },
  datePicker: {
    marginRight: "auto",
  },
  datePickerContainer: {
    borderRadius: "5px",
    border: "1px solid #c4c4c4",
  },
  selectRoot: {
    padding: " 10px 14px",
  },
  menuOption: {
    "&:hover": {
      backgroundColor: "#3b404c",
      color: "#ffffff",
    },
  },
  startDateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  customDatePickerInput: {},
  staffFormLabel: {
    marginBottom: "10px",
    color: "#3b404c",
  },
  formTitle: {
    marginBottom: "4vh",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      marginLeft: "3%",
    },
  },
  validateForm: {
    display: "grid",
  },
  submitCreateStaffBtn: {
    marginTop: "3vh",
    width: "1vw",
    textTransform: "none",
    minWidth: "120px",
    fontWeight: theme.typography.subtitle2.fontWeight,
    [theme.breakpoints.down("xs")]: {
      marginTop: "16%",
      marginBottom: "6%",
      minHeight: "46px",
      borderRadius: "24px",
      minWidth: "102px",
    },
    justifySelf: "center",
  },
  submitBtnText: {
    color: "#ffffff",
  },
  errorLabel: {
    alignItems: "center",
    marginTop: "4vh",
    marginBottom: "1vh",
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "24vw",
    },
  },
  input: {
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "0.5px solid #ced4da",
    fontSize: 16,
    padding: "9px 0px 9px 10px",
    height: "20px",
    minWidth: "4vw",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 3,
    },
  },
}))(InputBase);
