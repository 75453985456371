import React, { memo } from "react";
import { withStyles, Popover as MuiPopover, useTheme, useMediaQuery, Dialog } from "@material-ui/core";

const DialogContainer = ({ children, selectedId, ...otherProps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const selectedBox = document.getElementById(selectedId);
  let anchorOrigin =
    selectedBox &&
    getPlaceToSetPositionOnScreen(getElementOffset(selectedBox).xPosition, getElementOffset(selectedBox).yPosition)
      .anchorOrigin;
  let transformOrigin =
    selectedBox &&
    getPlaceToSetPositionOnScreen(getElementOffset(selectedBox).xPosition, getElementOffset(selectedBox).yPosition)
      .transformOrigin;
  let marginStyle = selectedBox && getPlaceToSetPositionOnScreen(getElementOffset(selectedBox).xPosition, 0).margin;

  return isMobile ? (
    <Dialog fullScreen open={true}>
      {children}
    </Dialog>
  ) : (
    anchorOrigin && transformOrigin && (
      <Popover
        open={true}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        style={marginStyle}
        {...otherProps}
      >
        {children}
      </Popover>
    )
  );
};

export default memo(DialogContainer);

const Popover = withStyles({
  paper: {
    overflow: "auto",
    borderRadius: 5,
  },
})(MuiPopover);

const getPlaceToSetPositionOnScreen = (leftSpace, topSpace) => {
  let quarter1, quarter2, quarter3, quarter4, maxQuarter, positionObject;
  const screenWidth = document.body.offsetWidth;
  const screenHeight = document.body.offsetHeight;
  let rightSpace = screenWidth - leftSpace;
  let bottomSpace = screenHeight - topSpace;
  quarter1 = leftSpace * topSpace;
  quarter2 = rightSpace * topSpace;
  quarter3 = rightSpace * bottomSpace;
  quarter4 = leftSpace * bottomSpace;
  maxQuarter = Math.max(quarter1, quarter2, quarter3, quarter4);

  if (quarter1 === maxQuarter) {
    positionObject = {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      margin: {
        marginLeft: -8,
      },
    };
  } else if (quarter2 === maxQuarter) {
    positionObject = {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      margin: {
        marginLeft: 8,
      },
    };
  } else if (quarter3 === maxQuarter) {
    positionObject = {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      margin: {
        marginLeft: 8,
      },
    };
  } else if (quarter4 === maxQuarter) {
    positionObject = {
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      margin: {
        marginLeft: -8,
      },
    };
  }
  return positionObject;
};

const getElementOffset = element => {
  var rect = element.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { yPosition: rect.top + scrollTop, xPosition: rect.left + scrollLeft };
};
