import React from "react";
import { HeaderCalendar } from "./components";
import { Box, Paper, makeStyles } from "@material-ui/core";
import { BglobalJSC } from "components";

const CalendarSetup = () => {
  const classes = useStyles();
  return (
    <Box>
      <Paper>
        <HeaderCalendar />
      </Paper>
      <Box className={classes.boxJSC}>
        <BglobalJSC />
      </Box>
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  boxJSC: {
    marginTop: 20,
    marginBottom: 24,
  },
}));

export default CalendarSetup;
