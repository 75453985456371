import React, { memo, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  makeStyles,
  Dialog,
  Box,
  IconButton,
  Button,
  Typography,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { Close, Error } from "@material-ui/icons";
import clsx from "clsx";
import ComboItemAction from "redux/combo-item.redux";
import { AppConstant, LangConstant, SystemConstant } from "const";
import { Input, TextArea, Alert } from "components";
import {
  UploadImage,
  InputUsedTime,
  InputPrice,
  ComboCategoryMenu,
  Warning,
  UploadVideo,
  ValueTax,
} from "components/mn-master-product";
import { getCommonKey, createArrayWithLabel } from "utils";

const CreateComBo = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_M_PRODUCT);
  const dispatch = useDispatch();

  const dataListServiceItem = useSelector(state => state.serviceItemRedux.dataGetListServiceItem, shallowEqual);
  const dataListProductItem = useSelector(state => state.mnProductRedux.dataItem, shallowEqual);
  const errorPostComboItem = useSelector(state => state.comboItemRedux.errorPostComboItem);

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [dataServiceItem, setDataServiceItem] = useState([]);
  const [dataProductItem, setDataProductItem] = useState([]);
  const [isCheckService, setIsCheckService] = useState(false);
  const dialogRef = useRef(null);
  const [formInputCombo, setFormInputCombo] = useState({
    name: "",
    image: "",
    usingTime: 0,
    usingTimeType: 0,
    price: null,
    priceTaxIn: null,
    costPrice: null,
    tax: 0,
    taxInclusiveF: 0,
    description: "",
    video: "",
    itemDetails: {
      name: "",
      type: AppConstant.TYPE_CATEGORY.combo,
      description: "",
      itemDetailsContentList: [],
      details: { defaultId: AppConstant.TYPE_TAX.noTax },
    },
  });
  const [taxTypeValue, setTaxTypeValue] = useState(AppConstant.TAX_TYPE_VALUE[AppConstant.TYPE_TAX.noTax]);

  const [notify, setNotify] = useState({
    value: false,
    message: "",
    type: TYPES_NOTIFICATION.not_warning,
  });

  const onTypingName = value => {
    setFormInputCombo({
      ...formInputCombo,
      name: value,
      itemDetails: {
        ...formInputCombo.itemDetails,
        name: value,
      },
    });
  };

  const onChangeCategoryMenu = array => {
    var checkService = array.some(data => data.type === AppConstant.TYPE_CATEGORY.service);
    setIsCheckService(checkService);
    setFormInputCombo({
      ...formInputCombo,
      itemDetails: {
        ...formInputCombo.itemDetails,
        itemDetailsContentList: array,
      },
    });
  };

  const onUploadFile = (value, error) => {
    if (!error) {
      setFormInputCombo({
        ...formInputCombo,
        imageList: value,
      });
    } else {
      setNotify({
        value: true,
        message: error,
        type: TYPES_NOTIFICATION.warning_image,
      });
    }
  };

  const onUploadVideo = (value, isError) => {
    let newFormValue = {
      ...formInputCombo,
    };
    if (isError) delete newFormValue.video;
    else newFormValue.video = value;

    setFormInputCombo(newFormValue);
  };

  const onTypingDescription = event => {
    let descriptionInput = event.target.value;
    setFormInputCombo({
      ...formInputCombo,
      description: descriptionInput,
      itemDetails: {
        ...formInputCombo.itemDetails,
        description: descriptionInput,
      },
    });
  };

  const onChangeTime = value => {
    setFormInputCombo({
      ...formInputCombo,
      usingTime: value,
    });
  };

  const onClickTimeUsed = value => {
    setFormInputCombo({
      ...formInputCombo,
      usingTimeType: value,
    });
  };

  const onInputPrice = value => {
    const newPrice = value;
    const newPriceTaxIn = Math.round(newPrice * (1 + taxTypeValue));
    const tax = newPriceTaxIn - newPrice;
    setFormInputCombo({
      ...formInputCombo,
      price: newPrice,
      priceTaxIn: newPriceTaxIn,
      tax: tax,
    });
  };

  const onChangeDropdownTax = taxTypeId => {
    const newTaxTypeValue = AppConstant.TAX_TYPE_VALUE[taxTypeId];
    setTaxTypeValue(newTaxTypeValue);

    const newPriceTaxIn = Math.round(formInputCombo.price * (1 + newTaxTypeValue));
    const tax = newPriceTaxIn - formInputCombo.price;
    setFormInputCombo({
      ...formInputCombo,
      tax: tax,
      priceTaxIn: newPriceTaxIn,
      taxInclusiveF: Boolean(taxTypeId) ? SystemConstant.TAX_INCLUSIVE_F.tax : SystemConstant.TAX_INCLUSIVE_F.noTax,
      details: { ...formInputCombo?.details, defaultId: taxTypeId },
      itemDetails: {
        ...formInputCombo?.itemDetails,
        details: { ...formInputCombo?.itemDetails?.details, defaultId: taxTypeId },
      },
    });
  };

  const onSubmitFormCombo = () => {
    if (formInputCombo.name) {
      dispatch(ComboItemAction.postComboItem(formInputCombo));
    } else {
      setNotify({
        value: true,
        message: getLabel(LangConstant.TXT_NOTIFY_INPUT_NAME_COMBO),
        type: TYPES_NOTIFICATION.incomplete_attribute,
      });
    }
  };

  const onCloseNotify = () => {
    setNotify({
      value: false,
      message: "",
      type: TYPES_NOTIFICATION.not_warning,
    });
  };

  useEffect(() => {
    let formDataService = [];
    dataListServiceItem?.data.forEach(data =>
      formDataService.push({
        uid: data.uid,
        type: AppConstant.TYPE_CATEGORY.service,
        name: data.name,
        amount: 0,
        image: data.image,
        description: data.description,
        itemUid: data.uid,
        groupModel: data.groupModel,
      }),
    );
    setDataServiceItem(formDataService);
    let formDataProduct = [];
    dataListProductItem?.data.forEach(data =>
      formDataProduct.push({
        uid: data.uid,
        type: AppConstant.TYPE_CATEGORY.product,
        name: data.name,
        amount: 0,
        image: data.image,
        description: data.description,
        itemUid: data.uid,
        groupModel: data.groupModel,
      }),
    );
    setDataProductItem(formDataProduct);
  }, []);

  useEffect(() => {
    if (errorPostComboItem) {
      if (errorPostComboItem?.name[0] === getLabel(LangConstant.TXT_DUPLICATE_NAME)) {
        setIsDuplicate(true);
        dialogRef.current.querySelector(".MuiDialog-paperScrollPaper").scrollTop = 0;
        dispatch(ComboItemAction.resetErrorComboItem());
      }
    }
  }, [errorPostComboItem]);

  return (
    <Dialog open={isOpen} fullScreen ref={dialogRef}>
      <Box className={classes.rootHeader}>
        <Typography className={classes.nameHeaderTypo}>{getLabel(LangConstant.TXT_CREATE_COMBO)}</Typography>
        <Box className={classes.boxIconClose}>
          <IconButton onClick={onClose} className={classes.iconButtonClose}>
            <Close className={classes.iconClose} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <Box className={classes.infoBox}>
          <Box marginBottom="30px">
            <Box className={classes.paddingBox}>
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_INFORMATION_BASIC)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.FN_NOTICE_FILL)(getLabel(LangConstant.L_MAIN_COMBO))}
              </Typography>
            </Box>
            <Box className={classes.paddingBox}>
              <Box className={classes.infoBasic}>
                <Typography className={classes.nameComboTypo}>{getLabel(LangConstant.TXT_NAME_COMBO)}</Typography>
                <Typography color="primary" className={classes.isRequire}>
                  &nbsp;&#42;
                </Typography>
              </Box>
              <Input
                placeholder={getLabel(LangConstant.TXT_NAME_COMBO)}
                classes={{ root: classes.rootNameCombo, input: classes.inputNameCombo }}
                required
                onChange={onTypingName}
              />
              {isDuplicate && (
                <Box className={classes.warningName}>
                  <Error color="primary" className={classes.iconError} />
                  <Typography color="primary" className={classes.textWarning}>
                    {getLabel(LangConstant.TXT_WARNING_NAME_COMBO)}
                  </Typography>
                </Box>
              )}
              {notify.value && notify.type === TYPES_NOTIFICATION.incomplete_attribute && (
                <Alert isShow={notify.value} onClose={onCloseNotify} type="error" message={notify.message} />
              )}
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_CHOOSE_PRODUCT)}</Typography>
              <ComboCategoryMenu
                modeViewAr={[
                  {
                    id: "1",
                    name: getLabel(getCommonKey(LangConstant.TXT_SERVICE)),
                    title: getLabel(LangConstant.TXT_NAME_SERVICE),
                  },
                  {
                    id: "2",
                    name: getLabel(getCommonKey(LangConstant.TXT_PRODUCT)),
                    title: getLabel(getCommonKey(LangConstant.TXT_PRODUCT_NAME)),
                  },
                ]}
                label={getLabel(LangConstant.TXT_CHOOSE_PRODUCT)}
                serviceData={dataServiceItem}
                productData={dataProductItem}
                onChange={onChangeCategoryMenu}
              />
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_ILLUSTRATION)}</Typography>
              <Box marginTop="8px">
                <UploadImage onChange={onUploadFile} />
                {notify.value && notify.type === TYPES_NOTIFICATION.warning_image && (
                  <Warning isShow={notify.value} onClose={onCloseNotify} message={notify.message} />
                )}
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>
                {getLabel(LangConstant.TXT_ILLUSTRATION_VIDEO)}
              </Typography>
              <Box marginTop="8px">
                <UploadVideo defaultUrl={formInputCombo.video || ""} onChange={onUploadVideo} />
              </Box>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_COMBO_DESCRIPTION)}</Typography>
              <TextArea
                placeholder={getLabel(LangConstant.TXT_ADD_COMBO_DESCRIPTION)}
                className={classes.comboDesTextArea}
                rowMax={4}
                onChange={onTypingDescription}
              />
            </Box>
          </Box>
          <Box>
            <Box className={classes.paddingBox}>
              <Typography className={classes.infoBasicTypo}>{getLabel(LangConstant.TXT_INFORMATION_SALES)}</Typography>
              <Typography className={classes.noticeFillTypo}>
                {getLabel(LangConstant.TXT_FILL_IN_INFORMATION_SALES_COMBO)}
              </Typography>
            </Box>
            <Box className={classes.paddingBox}>
              <Typography variant="subtitle2" className={classes.classificationProduct}>
                {getLabel(LangConstant.TXT_TAX_APPLY)}
              </Typography>
              <ValueTax onChange={onChangeDropdownTax} />
            </Box>
            <Box className={classes.boxInput}>
              <Box className={classes.widthBox}>
                <Typography className={classes.inputTitleTypo}>
                  {getLabel(LangConstant.TXT_PRICE_ACCORDING_TO)}
                </Typography>
                <Input
                  defaultValue={getLabel(LangConstant.L_MAIN_COMBO)}
                  disabled
                  classes={{ root: classes.rootNameCombo, input: classes.inputNameCombo }}
                />
              </Box>
              <Box className={classes.widthBox} margin="0 90px">
                <Typography className={classes.inputTitleTypo}>
                  {getLabel(getCommonKey(LangConstant.TXT_BOOK_USED_TIME))}
                </Typography>
                <InputUsedTime
                  dataChange={createArrayWithLabel(
                    AppConstant.TYPE_TIME_USED,
                    LangConstant.ARR_TYPE_TIME_USED,
                    getLabel,
                    true,
                  )}
                  isService={!isCheckService}
                  onChange={onChangeTime}
                  onClick={onClickTimeUsed}
                  className={classes.inputUsedTime}
                  placeholderTime={getLabel(getCommonKey(LangConstant.TXT_MINUTES))}
                  placeholderValue="30"
                  isDisabled={!isCheckService}
                />
              </Box>
            </Box>
            <Box className={classes.boxInput}>
              <Box className={classes.widthBox}>
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_UNIT_PRICE)}</Typography>
                <InputPrice onChange={onInputPrice} currencyUnit={getLabel(LangConstant.TXT_CURRENCY)} />
              </Box>
              <Box className={classes.widthBox} margin="0 90px">
                <Typography className={classes.inputTitleTypo}>{getLabel(LangConstant.TXT_PRICE_AFTER_TAX)}</Typography>
                <InputPrice
                  onChange={onInputPrice}
                  currencyUnit={getLabel(LangConstant.TXT_CURRENCY)}
                  valueInput={formInputCombo.priceTaxIn ? formInputCombo.priceTaxIn.toString() : ""}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button className={clsx("semiBold-lg-txt", classes.buttonCancel)} onClick={onClose}>
          {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
        </Button>
        <Button className={classes.buttonSave} onClick={onSubmitFormCombo}>
          {getLabel(LangConstant.TXT_ADD_MASTER_PRODUCT)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default memo(CreateComBo);

const TYPES_NOTIFICATION = {
  not_warning: 0,
  warning_image: 1,
  incomplete_attribute: 2,
};

const useStyles = makeStyles(theme => ({
  rootHeader: {
    height: 136,
    minHeight: 136,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameHeaderTypo: {
    fontSize: 24,
    lineHeight: 1.33,
    color: "#08080a",
  },
  boxIconClose: {
    position: "absolute",
    top: 36,
    right: 36,
    width: 36,
    height: 36,
    [theme.breakpoints.down(600)]: {
      top: 10,
      right: 10,
    },
  },
  iconButtonClose: {
    padding: 0,
  },
  iconClose: {
    width: 36,
    height: 36,
  },
  infoBox: {
    width: 430,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  warningName: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: 10,
  },
  textWarning: {
    fontSize: 10,
    marginRight: 3,
  },
  iconError: {
    width: 14,
    height: 14,
  },
  infoBasic: {
    display: "flex",
    alignItems: "center",
    height: 22,
    marginBottom: 8,
  },
  infoBasicTypo: {
    fontSize: 18,
    fontWeight: 600,
    height: 28,
    color: "#3e4045",
    lineHeight: 1.56,
  },
  nameComboTypo: {
    height: 22,
    lineHeight: 1.57,
    fontSize: 13,
    fontWeight: 600,
  },
  isRequire: {
    height: 22,
  },
  noticeFillTypo: {
    fontSize: 14,
    minHeight: 22,
    lineHeight: 1.57,
    marginTop: 8,
    color: "#3e4045",
  },
  inputTitleTypo: {
    height: 20,
    lineHeight: 1.67,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  rootNameCombo: {
    margin: 0,
    borderRadius: 3,
    "&.Mui-disabled": {
      color: "#3e4045",
    },
  },
  inputNameCombo: {
    height: 22,
    padding: "9px 16px",
    fontSize: 14,
    lineHeight: 1.57,
  },
  comboDesTextArea: {
    height: 80,
    margin: 0,
    padding: "8px 16px",
    borderRadius: 3,
  },
  inputUsedTime: {
    width: 158,
  },
  dropdownClass: {
    minWidth: 151,
  },
  buttonSave: {
    height: 46,
    padding: 0,
    minWidth: 102,
    fontSize: 16,
    borderRadius: 2,
    fontWeight: 600,
    backgroundColor: "#ef5845",
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#9a1d04",
    },
  },
  buttonCancel: {
    height: 46,
    padding: 0,
    minWidth: 102,
    borderRadius: 3,
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    textTransform: "none",
    border: "1px solid #D4D5D8",
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
  dialogAction: {
    justifyContent: "center",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.103529)",
    padding: "30px 0",
  },
  paddingBox: {
    padding: "8px 0 16px",
  },
  boxInput: {
    padding: "8px 0 46px",
    display: "flex",
  },
  widthBox: {
    minWidth: 158,
  },
}));
