import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Button,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Chip,
  FormHelperText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { LangConstant } from "const";
import { removeUnnecessarySpaces, getNSKey, validatePhone } from "utils";
import ConsumerAction from "redux/consumer.redux";
import { SMSInviteIcon } from "icons";
import { PopupSuccess } from "pages/MnConsumer/components";

const InviteBySMS = ({ open, ...props }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const emailQuantity = useSelector(state => state.consumerRedux.emailQuantity);
  const isSuccess = useSelector(state => state.consumerRedux.isInvitationConsumerSuccess);
  const listInvite = useSelector(state => state.consumerRedux.invitationConsumer?.invitedList);

  const [arrTel, setArrTel] = useState([]);
  const [inputTel, setInputTel] = useState("");
  const [quantity, setQuantity] = useState({
    used: 0,
    total: 0,
  });
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [save, setSave] = useState([]);

  const onInviteByPhoneNumber = () => {
    let displayTel = [...arrTel];
    let error = [];
    let phoneNumber = [];
    if (inputTel && inputTel.length > 0) {
      displayTel.push(inputTel);
    }

    // Create list correct tel/ error validation tel
    displayTel.forEach(tel => (validatePhone(tel) ? phoneNumber.push(tel) : error.push(tel)));

    if (phoneNumber.length > 0) {
      dispatch(ConsumerAction.patchInvitationConsumer({ phoneNumberList: phoneNumber }));
      setSave(phoneNumber);
      setArrTel([]);
      setIsOpenSuccess(true);
    }
  };

  const onChangeTel = (event, tel) => {
    setArrTel(tel);
    event.stopPropagation();
  };

  const onInputChangeTel = (event, newInputValue) => {
    let arrPhoneNumber = [...arrTel];
    let trimInput = newInputValue.trim();
    let neatInput = removeUnnecessarySpaces(newInputValue).split(" ");

    const fieldValue = arrPhoneNumber.concat(neatInput);
    if (neatInput.length > 1) {
      onChangeTel(event, fieldValue);
      return;
    }
    let space = newInputValue.indexOf(" ") >= 0;
    if (space && trimInput.length > 0) {
      setInputTel("");
      setArrTel([...arrPhoneNumber, trimInput]);
    } else {
      setInputTel(trimInput);
    }
  };

  const validatePhoneNumber = (inputTel, tels) => {
    let arrTel = [];
    if (tels.length > 0) {
      tels.forEach(e => (validatePhone(e) ? arrTel.push(e) : null));
    }
    if ((inputTel.length > 0 && validatePhone(inputTel)) || arrTel.length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(ConsumerAction.resetConsumer());
      dispatch(ConsumerAction.requestGetEmailQuantity());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (emailQuantity === null) {
      dispatch(ConsumerAction.requestGetEmailQuantity());
    } else if (emailQuantity) {
      setQuantity(emailQuantity);
    }
  }, [emailQuantity]);

  useEffect(() => {
    if (open && emailQuantity) {
      dispatch(ConsumerAction.requestGetEmailQuantity());
    }
  }, [open]);

  return (
    <Box className={classes.boxInviteSMS}>
      <Accordion defaultExpanded={false} className={classes.rootAccordion}>
        <Box className={classes.boxContentChildren}>
          <AccordionSummary className={classes.rootAccordionSummary}>
            <SMSInviteIcon />
          </AccordionSummary>
          <AccordionSummary className={classes.rootAccordionSummary}>
            <Box className={classes.txtChooseSMS}>
              {getLabel(getNSKey(LangConstant.NS_CONSUMER, LangConstant.TXT_INVITE_BY_PHONE_NUMBER))}
            </Box>
          </AccordionSummary>
        </Box>
        <AccordionDetails className={classes.rootAccordionDetail}>
          <Autocomplete
            classes={{ root: classes.rootAutoComplete, inputFocused: classes.rootInputField }}
            disableClearable={true}
            multiple
            options={[]}
            freeSolo
            renderTags={(tels, getTagProps) =>
              tels.map((tel, index) => (
                <Chip
                  key={index}
                  classes={{ outlinedPrimary: classes.outlinedChip }}
                  variant="outlined"
                  label={tel}
                  color={validatePhone(tel) ? "default" : "primary"}
                  {...getTagProps({ index })}
                />
              ))
            }
            value={arrTel}
            inputValue={inputTel}
            onChange={onChangeTel}
            onInputChange={onInputChangeTel}
            renderInput={params => (
              <>
                <TextField {...params} variant="outlined" {...props} />
                <FormHelperText className={classes.formHelper}>
                  {quantity ? getLabel(LangConstant.FN_LIMITED_INVITE)(quantity.used, quantity.total) : null}
                </FormHelperText>
                <Button
                  classes={{
                    root: classes.rootBtnSMS,
                    contained: classes.contained,
                    disabled: classes.disabled,
                  }}
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={onInviteByPhoneNumber}
                  disabled={validatePhoneNumber(inputTel, arrTel)}
                >
                  {getLabel(LangConstant.TXT_SEND_INVITATION)}
                </Button>
              </>
            )}
          />
        </AccordionDetails>
      </Accordion>
      <PopupSuccess
        open={isOpenSuccess}
        setOpen={setIsOpenSuccess}
        data={save}
        lengthSuccess={(listInvite && listInvite.length) || null}
      />
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  boxBodyParent: {
    width: "100%",
    height: "100%",
  },
  rootAccordion: {
    boxShadow: "none",
  },
  rootAccordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    height: 24,
    minHeight: 24,
    backgroundColor: "#ffffff",
  },
  rootAccordionDetail: {
    display: "block",
    textAlign: "center",
    "@media only screen and (max-width: 1000px)": {
      padding: "0 0 0",
    },
  },
  boxBodyChildren: {
    height: "100%",
    width: "90%",
    margin: "auto",
  },
  boxTitle: {
    textAlign: "center",
    marginTop: 90,
    "@media only screen and (max-width: 1000px)": {
      marginTop: 50,
    },
  },
  boxInviteSMS: {
    marginTop: 50,
    "@media only screen and (max-width: 1000px)": {
      marginTop: 38,
    },
  },
  boxContentChildren: {
    display: "flex",
    justifyContent: "left",
    width: 290,
    margin: "auto",
    "@media only screen and (max-width: 1000px)": {
      marginLeft: 0,
      justifyContent: "center",
      width: "auto",
      paddingLeft: 0,
    },
  },
  boxContentChildrenLink: {
    display: "flex",
    justifyContent: "left",
    width: 290,
    margin: "auto",
    "@media only screen and (max-width: 1000px)": {
      marginLeft: 0,
      justifyContent: "center",
      width: "auto",
      paddingLeft: 0,
    },
  },
  outlinedChip: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  rootBtnSMS: {
    marginTop: 50,
    padding: "0 16px 0",
    minHeight: 50,
    fontWeight: "inherit",
    Height: 50,
    borderRadius: 25,
    minWidth: 160,
    textTransform: "capitalize",
    color: "#ffffff",
    "@media only screen and (max-width: 600px)": {
      minWidth: 160,
      maxWidth: 160,
      height: 40,
      minHeight: 40,
      borderRadius: 20,
    },
  },
  contained: {
    "&$disabled": {
      backgroundColor: "#EF5845",
      opacity: 0.6,
      color: "#ffffff",
    },
  },
  disabled: {
    backgroundColor: "#EF5845",
    opacity: 0.6,
  },
  rootButton: {
    width: 50,
    height: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: 20,
  },
  rootAutoComplete: {
    marginLeft: "25%",
    marginRight: "25%",
    "@media only screen and (max-width: 600px)": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  label: {
    height: "100%",
  },
  startIcon: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "block",
  },
  iconSizeMedium: {
    fontSize: 30,
  },
  txtChooseSMS: {
    width: "100%",
    marginLeft: 20,
    "&:hover": {
      color: "#447aff",
      textDecoration: "underline",
    },
  },
  formHelper: {
    fontSize: 12,
    color: "#447aff",
  },
}));
export default memo(InviteBySMS);
