import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, makeStyles } from "@material-ui/core";
import { getCommonKey, checkInteger, formatCurrency, getObjByItsProperty } from "utils";
import { AppConstant, LangConstant } from "const";
import { TabPanel } from "components";
import InputPayment from "./InputPayment";
import Loading from "./Loading";

const BookingPaymentTab = ({ selectedTab, tabIndex, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PAYMENT_SETUP);

  const reservationSetting = useSelector(state => state.reservationRedux.reservationSetting);
  const isFetchingData = useSelector(state => state.reservationRedux.isFetchingSetting);

  const [checkedCurrency, setCheckedCurrency] = useState(false);
  const [checkedPercent, setCheckedPercent] = useState(false);
  const [valuePercent, setValuePercent] = useState();
  const [valueCurrency, setValueCurrency] = useState();
  const [previewData, setPreviewData] = useState();
  const [isGettingData, setIsGettingData] = useState(true);

  const onChecked = (name, value) => {
    let isEnableSaveButton = true;

    let isPublish = value ? AppConstant.STT_PUBLISH.publish : AppConstant.STT_PUBLISH.private;
    let previewObj = { ...previewData };

    switch (name) {
      case AppConstant.KEY_BOOK_DEPOSIT:
        previewObj = { ...previewObj, publish: isPublish };
        break;
      case DEPOSIT_INFORMATION:
        previewObj.details = {
          ...previewObj.details,
          publish: isPublish,
          depositInformation: {
            ...previewObj.details.depositInformation,
            publish: isPublish,
            value: "",
            type: isPublish,
          },
        };
        break;
      case UNIT.currency:
        setValuePercent("");
        previewObj.details = {
          ...previewObj.details,
          depositInformation: {
            ...previewObj.details.depositInformation,
            type: AppConstant.TYPE_DEPOSIT.currency,
            value: "",
          },
        };
        break;
      case UNIT.percent:
        setValueCurrency("");
        previewObj.details = {
          ...previewObj.details,
          depositInformation: {
            ...previewObj.details.depositInformation,
            type: AppConstant.TYPE_DEPOSIT.percent,
            value: "",
          },
        };
        break;
    }
    onChange(previewObj, isEnableSaveButton);
    setPreviewData(previewObj);
  };

  const onChangeInput = event => {
    let [name, value] = [event.currentTarget.name, event.currentTarget.value];
    let isEnableSaveButton = true;
    let previewObj = { ...previewData };

    if (name === AppConstant.KEY_BOOK_DEPOSIT) {
      previewObj.details = {
        ...previewObj.details,
        content: value,
      };
    } else {
      var maxLength = value;
      maxLength = maxLength.split(".").join("");
      if (maxLength === "" || checkInteger(maxLength)) {
        if (name === UNIT.currency && maxLength.length < 10) {
          setValuePercent("");
          setValueCurrency(maxLength);
          previewObj.details = {
            ...previewObj.details,
            depositInformation: {
              ...previewObj.details.depositInformation,
              value: maxLength,
            },
          };
        } else if (name === UNIT.percent && maxLength.length < 4 && maxLength < 101) {
          setValuePercent(maxLength);
          setValueCurrency("");
          previewObj.details = {
            ...previewObj.details,
            depositInformation: {
              ...previewObj.details.depositInformation,
              value: maxLength,
            },
          };
        }
      }
    }
    setPreviewData(previewObj);
    onChange(previewObj, isEnableSaveButton);
  };

  useEffect(() => {
    if (!isFetchingData && isGettingData) {
      setTimeout(() => {
        setIsGettingData(isFetchingData);
      }, 500);
    }
  }, [isFetchingData]);

  useEffect(() => {
    if (previewData) {
      let isPublish = Boolean(previewData.details?.depositInformation?.publish);
      let isCheckedCurrency =
        isPublish && previewData.details?.depositInformation?.type === AppConstant.TYPE_DEPOSIT.currency;
      let isCheckedPercent =
        isPublish && previewData.details?.depositInformation?.type === AppConstant.TYPE_DEPOSIT.percent;
      setCheckedCurrency(isCheckedCurrency);
      setCheckedPercent(isCheckedPercent);
    }
  }, [previewData]);

  useEffect(() => {
    if (reservationSetting) {
      let bookingPaymentData = getObjByItsProperty(
        reservationSetting,
        "type",
        AppConstant.RESERVATION_SETTING_OPTION.deposit,
      );

      bookingPaymentData.details = {
        ...bookingPaymentData.details,
        depositInformation: {
          ...(bookingPaymentData.details?.depositInformation || {
            publish: DEPOSIT_INFORMATION_DEFAULT.publish,
            type: DEPOSIT_INFORMATION_DEFAULT.type,
            value: DEPOSIT_INFORMATION_DEFAULT.value,
          }),
        },
      };
      setPreviewData(bookingPaymentData);

      let depositInfo = bookingPaymentData.details.depositInformation || {};
      if (depositInfo.publish) {
        if (bookingPaymentData.details.depositInformation.type === AppConstant.TYPE_DEPOSIT.currency) {
          setValueCurrency(bookingPaymentData.details.depositInformation.value);
        } else {
          setValuePercent(bookingPaymentData.details.depositInformation.value);
        }
      }
    }
  }, [reservationSetting]);

  return (
    <>
      <TabPanel value={selectedTab} index={tabIndex}>
        {isGettingData ? (
          <Loading />
        ) : (
          Boolean(previewData) && (
            <Grid item xs={12} sm={6} container className={classes.boxBody} spacing={1}>
              <Grid item>
                <InputPayment
                  name={AppConstant.KEY_BOOK_DEPOSIT}
                  checkBox={true}
                  checked={Boolean(previewData.publish)}
                  includeInput={true}
                  onCheck={onChecked}
                  title={getLabel(LangConstant.TXT_SHOW_PAYMENT_INFORMATION)}
                  classes={{
                    gridItem: classes.depositPaymentInfo,
                  }}
                  inputProps={{
                    value: previewData.details.content || "",
                    placeholder: getLabel(LangConstant.P_PAYMENT_INFORMATION),
                    rowsMax: 20,
                    name: AppConstant.KEY_BOOK_DEPOSIT,
                    disabled: !Boolean(previewData.publish),
                    onChange: onChangeInput,
                    fullWidth: true,
                    multiline: true,
                  }}
                />
              </Grid>
              <Grid className={classes.boxInput} item>
                <InputPayment
                  name={DEPOSIT_INFORMATION}
                  checkBox={true}
                  checked={Boolean(previewData.details.depositInformation.publish)}
                  onCheck={onChecked}
                  title={getLabel(LangConstant.TXT_PAYMENT_REQUIRED_IN_ADVANCE)}
                  disabledCheckBox={!Boolean(previewData.publish)}
                />
              </Grid>
              <Grid className={classes.boxInputValue} item>
                <InputPayment
                  name={UNIT.currency}
                  checkBox={true}
                  checked={checkedCurrency}
                  onCheck={onChecked}
                  valueTime={true}
                  title={getLabel(LangConstant.TXT_AMOUNT_PER_BOOKING)}
                  currencyUnit={getLabel(getCommonKey(LangConstant.TXT_CURRENCY_COMMON))}
                  disabledCheckBox={!Boolean(previewData.publish)}
                  classInput={classes.outlineInput}
                  inputProps={{
                    value: formatCurrency(valueCurrency || ""),
                    placeholder: "0",
                    onChange: onChangeInput,
                    name: UNIT.currency,
                    disabled: Boolean(previewData.details.depositInformation.type !== 1),
                    type: "number",
                  }}
                />
              </Grid>
              <Grid className={classes.boxInputValue} item>
                <InputPayment
                  name={UNIT.percent}
                  checkBox={true}
                  checked={checkedPercent}
                  onCheck={onChecked}
                  valueTime={true}
                  title={getLabel(LangConstant.TXT_PERCENTAGE_PER_BOOKING)}
                  currencyUnit={"%"}
                  classInput={classes.outlineInput}
                  disabledCheckBox={!Boolean(previewData.publish)}
                  inputProps={{
                    value: formatCurrency(valuePercent || ""),
                    placeholder: "0",
                    onChange: onChangeInput,
                    name: UNIT.percent,
                    disabled: Boolean(previewData.details.depositInformation.type !== 2),
                    type: "number",
                  }}
                />
              </Grid>
            </Grid>
          )
        )}
      </TabPanel>
    </>
  );
};

BookingPaymentTab.propTypes = {
  onChange: PropTypes.func,
  selectedTab: PropTypes.number,
  tabIndex: PropTypes.number,
  isEnableSaveButton: PropTypes.bool,
};

BookingPaymentTab.defaultProps = { onChange: () => {} };

export default memo(BookingPaymentTab);

const DEPOSIT_INFORMATION = "depositInformation";
const UNIT = { percent: "percent", currency: "currency" };
const DEPOSIT_INFORMATION_DEFAULT = {
  publish: 0,
  type: 0,
  value: "",
};

const useStyles = makeStyles(theme => ({
  boxBody: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 250px)",
    paddingLeft: 54,
    paddingTop: 37,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 24,
    },
  },
  outlineInput: {
    paddingLeft: 15,
    [theme.breakpoints.down(600)]: {
      paddingLeft: 0,
    },
  },
  boxInput: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  boxInputValue: {
    paddingLeft: 15,
  },
  depositPaymentInfo: {
    maxWidth: "calc(100% - 12px)",
  },
}));
