import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DialogAddCategoryItem } from "components";
import { LangConstant } from "const";
import DialogSelectItems from "./DialogSelectItems";

const DialogAddNewOrder = ({ open, onClose, selectedItems, isUpdatedOrder }) => {
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_ORDER);

  const [openDialog, setOpenDialog] = useState(open);
  const [selectItemsOpt, setSelectItemsOpt] = useState(DEFAULT_SELECT_ITEMS);

  const onAddItemsFromCategory = () => {
    setSelectItemsOpt({ isOpen: true, isShowCategory: true });
  };

  const onCloseDialog = () => {
    setSelectItemsOpt({ ...selectItemsOpt, isOpen: false, isShowCategory: false });
    onClose();
  };

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  return (
    <>
      <DialogAddCategoryItem
        isShow={openDialog}
        titleName={{
          header:
            Object.keys(selectedItems).length > 0
              ? getLabel(LangConstant.TXT_UPDATE_ITEM_OPTION)
              : getLabel(LangConstant.TXT_SELECT_ITEM_OPTION),
          created: getLabel(LangConstant.TXT_ADD_FROM_CATEGORY),
        }}
        action={onClose}
        onCreateAccompaniedService={onAddItemsFromCategory}
        inputProps={{
          className: "bold-lg-txt",
        }}
      />
      {selectItemsOpt.isOpen && (
        <DialogSelectItems
          onClose={onCloseDialog}
          isShowCategory={selectItemsOpt.isShowCategory}
          selectedItems={selectedItems}
          isUpdatedOrder={isUpdatedOrder}
        />
      )}
    </>
  );
};

DialogAddNewOrder.propTypes = {
  open: PropTypes.bool,
  isUpdatedOrder: PropTypes.bool,
  selectedItems: PropTypes.object,
  onClose: PropTypes.func,
};

DialogAddNewOrder.defaultProps = {
  open: false,
  isUpdatedOrder: false,
  selectedItems: {},
  onClose: () => {},
};

export default memo(DialogAddNewOrder);

const DEFAULT_SELECT_ITEMS = {
  isOpen: false,
  isShowCategory: false,
};
