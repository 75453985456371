import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Typography, Grid, Select, MenuItem, InputBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { addDays, addMonths, addWeeks } from "date-fns";
import { format } from "react-string-format";
import { removeDuplicatesWithKey } from "utils";
import { LangConstant, AppConstant } from "const";
import LineTitle from "./components/LineTitle";
import LineBodyPeriodic from "./components/LineBodyPeriodic";
import ReservationAction from "redux/reservation.redux";

const TabExtend = ({ detail, dataPeriodic, lastCode, dataExtend, isContainPast, setDataExtend, setIsContainPast }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation();
  const happens = LangConstant.ARR_HAPPENS_EVERY;
  const repeat = AppConstant.ARR_REPEAT_TIME;
  const [valueHappen, setValueHappen] = useState(happens[0]);
  const [valueRepeat, setValueRepeat] = useState(repeat[0]);
  const [breakRule, setBreakRule] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [storeData, setStoreData] = useState(dataExtend);
  const availableStatus = useSelector(state => state.reservationRedux.availableStatus);
  const getStatusSuccess = useSelector(state => state.reservationRedux.getAvailableStatusSuccess);

  const onChangeHappen = event => {
    setValueHappen(event.target.value);
  };

  const onChangeRepeat = event => {
    setValueRepeat(event.target.value);
  };

  const onRemoveRepeat = index => {
    let newData = storeData.filter((d, i) => i !== index);
    setValueRepeat(valueRepeat - 1);
    newData.map(
      (d, i) =>
        (d.code =
          d.code.substring(0, d.code.length - 3) +
          `${i + lastCode < 9 ? `${"-0" + (i + 1 + lastCode)}` : `${"-" + (i + 1 + lastCode)}`}`),
    );
    setIsRemoving(true);
    setStoreData(newData);
  };

  const onAddRepeat = () => {
    if (!breakRule) {
      setValueRepeat(valueRepeat + 1);
    } else {
      setValueRepeat(valueRepeat + 1);
      let mapArray = [...storeData];
      let lastData = mapArray[storeData.length - 1];
      let lastCode = parseInt(lastData.code.substring(lastData.code.length - 2)) + 1;
      let newExtend = {
        code:
          lastData.code.substring(0, lastData.code.length - 2) +
          `${lastCode < 10 ? `${"0" + lastCode}` : `${lastCode}`}`,
        status: AppConstant.STT_RESERVATION.pending,
        startTime: lastData.startTime + 86400,
        available: availableStatus && availableStatus.data && Object.values(availableStatus.data)[0] === 1,
      };
      setStoreData([...storeData, newExtend]);
    }
    setIsRemoving(false);
  };

  const onChangeSelectedDate = (date, index) => {
    let selectedTime = new Date(date).getTime() / 1000;
    let arrayTime = [];
    storeData.forEach((d, i) => (i !== index ? arrayTime.push(d.startTime) : arrayTime.push(selectedTime)));
    arrayTime.sort();
    let newExtend = storeData.map((d, i) => {
      d.startTime = arrayTime[i];
      return d;
    });
    setStoreData(newExtend);
    setBreakRule(true);
  };

  const onChangeSelectedTime = (currentTime, lastTime, index) => {
    let currentTimestamp = parseInt(currentTime.substring(0, 2)) * 60 * 60 + parseInt(currentTime.substring(3)) * 60;
    let lastTimestamp = parseInt(lastTime.substring(0, 2)) * 60 * 60 + parseInt(lastTime.substring(3)) * 60;
    let minusTime = lastTimestamp - currentTimestamp;
    let newTime = storeData[index].startTime + minusTime;
    let arrayTime = [];
    storeData.forEach((d, i) => (i !== index ? arrayTime.push(d.startTime) : arrayTime.push(newTime)));
    arrayTime.sort();
    let newExtend = storeData.map((d, i) => {
      d.startTime = arrayTime[i];
      return d;
    });
    setStoreData(newExtend);
    setBreakRule(true);
  };

  useEffect(() => {
    if (dataPeriodic && !breakRule && !isRemoving) {
      let tepPeriodic = [...dataPeriodic];
      let lastPeriodic = tepPeriodic[dataPeriodic.length - 1];
      let maxPeriodicTime = lastPeriodic.startTime;
      let addTime = 0;
      let addCode = lastPeriodic.code;

      let tempData = [];
      for (let i = 1; i <= valueRepeat; i++) {
        let lastNumber = i + lastCode;
        addTime =
          happens[0] === valueHappen
            ? addDays(new Date(maxPeriodicTime * 1000), i).getTime() / 1000
            : happens[1] === valueHappen
            ? addWeeks(new Date(maxPeriodicTime * 1000), i).getTime() / 1000
            : addMonths(new Date(maxPeriodicTime * 1000), i).getTime() / 1000;
        if (addCode.length > 7) {
          addCode = addCode.substring(0, addCode.length - 3);
          addCode += `${lastNumber < 10 ? `${"-0" + lastNumber}` : `${"-" + lastNumber}`}`;
        } else if (addCode.length > 4) {
          addCode += `${lastNumber < 10 ? `${"-0" + lastNumber}` : `${"-" + lastNumber}`}`;
        } else {
          addCode += `${lastNumber < 10 ? `${"-00-0" + lastNumber}` : `${"-00-" + lastNumber}`}`;
        }
        tempData.push({
          code: addCode,
          status: AppConstant.STT_RESERVATION.pending,
          startTime: addTime,
          endTime: "",
          available: true,
        });
      }
      setStoreData(removeDuplicatesWithKey(tempData, "code"));
    }
  }, [dataPeriodic, valueRepeat, valueHappen]);

  useEffect(() => {
    if (storeData.length === valueRepeat) {
      let timeArray = [];
      for (let i = 0; i < storeData.length; i++) {
        timeArray.push(storeData[i].startTime);
      }
      dispatch(
        ReservationAction.getAvailableStatus({
          time: timeArray,
          total_consumer: detail && detail.consumer.total,
        }),
      );
    }
  }, [valueHappen, valueRepeat, storeData.length]);

  useEffect(() => {
    let tempArray = [];
    if (availableStatus && availableStatus.data) {
      for (let i = 0; i < storeData.length; i++) {
        tempArray.push({
          code: storeData[i].code,
          status: storeData[i].status,
          startTime: storeData[i].startTime,
          endTime: storeData[i].endTime,
          available: Object.values(availableStatus.data)[i],
        });
        if (i === storeData.length - 1) {
          setStoreData(tempArray);
        }
      }
    }
  }, [availableStatus, getStatusSuccess]);

  useEffect(() => {
    if (storeData) setDataExtend(storeData);
  }, [storeData]);

  useEffect(() => {
    if (dataExtend) {
      setIsContainPast(false);
      for (let i = 0; i < dataExtend.length; i++) {
        if (dataExtend[i].startTime < Math.floor(Date.now() / 1000)) setIsContainPast(true);
      }
    }
  }, [dataExtend]);

  return (
    <Box>
      <Grid className={classes.root} container spacing={1}>
        <Box display="flex" marginLeft="24px">
          <Box height="30px" width="161px">
            <Typography className={classes.typoRepeat}>{getLabel(LangConstant.TXT_HAPPENS_EVERY)}</Typography>
          </Box>
          <Box>
            <Select
              value={valueHappen}
              classes={{ root: classes.selectRepeat, select: classes.selectedRepeat }}
              onChange={onChangeHappen}
              disabled={valueRepeat !== dataExtend.length}
              input={<BootstrapInput />}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {happens.map((data, index) => (
                <MenuItem className={classes.menuRepeat} value={data} key={index}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display="flex" marginLeft="24px" marginTop="4px">
          <Box height="30px" width="161px">
            <Typography className={classes.typoRepeat}>{getLabel(LangConstant.TXT_ADD_REPEATS)}</Typography>
          </Box>
          <Box>
            <Select
              value={valueRepeat}
              classes={{ root: classes.selectRepeat, select: classes.selectedRepeat }}
              onChange={onChangeRepeat}
              disabled={valueRepeat !== dataExtend.length}
              input={<BootstrapInput />}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {repeat.map((data, index) => (
                <MenuItem className={classes.menuRepeat} value={data} key={index}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box height="30px" marginLeft="16px">
            <Typography className={classes.typoRepeat}>{getLabel(LangConstant.TXT_TIMES)}</Typography>
          </Box>
        </Box>
        <Box padding="36px 24px 16px">
          <Typography>{format(getLabel(LangConstant.FM_RESERVATION_PERIODIC), dataPeriodic.length)}</Typography>
        </Box>
        <LineTitle />
        {dataPeriodic &&
          dataPeriodic.map((dataShow, index) => (
            <LineBodyPeriodic isCoincident={Boolean(dataShow.uid === detail.uid)} data={dataShow} key={index} />
          ))}
        <Box padding="20px 24px 16px">
          <Typography>{format(getLabel(LangConstant.FM_RESERVATION_EXTEND), dataExtend.length)}</Typography>
        </Box>
        <LineTitle isExtend />
        {dataExtend &&
          dataExtend.map((extend, index) => (
            <LineBodyPeriodic
              isExtend
              isCoincident={Boolean(dataPeriodic.find(data => data.startTime === extend.startTime))}
              isLast={Boolean(dataExtend[dataExtend.length - 1] === extend)}
              data={extend}
              index={index}
              isContainPast={isContainPast}
              setIsContainPast={setIsContainPast}
              onRemoveRepeat={onRemoveRepeat}
              onAddRepeat={onAddRepeat}
              onChangeSelectedDate={onChangeSelectedDate}
              onChangeSelectedTime={onChangeSelectedTime}
              key={index}
            />
          ))}
      </Grid>
    </Box>
  );
};

const BootstrapInput = props => {
  const classes = useStyles();
  return <InputBase {...props} classes={{ root: classes.rootBase, input: classes.InputBase }} />;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    display: "block",
    "@media only screen and (max-width: 375px)": {
      width: 660,
      overflowX: "scroll",
    },
  },
  typoRepeat: {
    marginTop: 4,
    fontSize: 13,
    height: 22,
    paddingTop: 2,
  },
  selectRepeat: {
    width: 180,
    height: 30,
  },
  selectedRepeat: {
    "&:focus": {
      borderRadius: 3,
      backgroundColor: theme.palette.white,
    },
  },
  menuRepeat: {
    height: 30,
    fontSize: 12,
    padding: "4px 16px 4px",
    boxSizing: "inherit",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.white,
    },
  },
  disabled: {
    opacity: 0.3,
    color: theme.palette.text.link,
  },
  rootBase: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  InputBase: {
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 12,
    height: 20,
    padding: "4px 16px 4px 16px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}));

export default memo(TabExtend);
