import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  addDays,
  format as DateFormat,
  isSameDay,
  subDays,
  addWeeks,
  addMonths,
  subMonths,
  subWeeks,
  isEqual,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import { Box, Button, makeStyles, Popover } from "@material-ui/core";
import { ArrowLeftTwoTone, ArrowRightTwoTone } from "@material-ui/icons";
import { AppConstant, LangConstant } from "const";
import { fetchDateToText } from "utils/date";
import { getCommonKey } from "utils";
import PrimaryCalendar from "./PrimaryCalendar";

const PrimaryCustomDatePicker = ({ onChange, dateRange, optionView, onClickToday, isPickRangeDate }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();

  const [popoverAnchor, setPopoverAnchor] = useState();
  const [selectedDateObj, setSelectedDateObj] = useState({ ...TODAY_OBJECT });
  const [labelSelectedDate, setLabelSelectedDate] = useState(getLabel(LangConstant.TXT_RANGE_TIME_PICKER));

  const onSelectDate = data => {
    setPopoverAnchor(null);
    if (data.from && data.to) {
      let dateObject = data;
      if (!isPickRangeDate) {
        if (AppConstant.ReservationViewBy.day === optionView) {
          dateObject = {
            from: startOfDay(data.from),
            to: endOfDay(data.to),
          };
        } else if (AppConstant.ReservationViewBy.week === optionView) {
          dateObject = {
            from: startOfWeek(data.from, { weekStartsOn: 1 }),
            to: endOfWeek(data.to, { weekStartsOn: 1 }),
          };
        } else if (AppConstant.ReservationViewBy.month === optionView) {
          dateObject = {
            from: startOfMonth(data.from),
            to: endOfMonth(data.to),
          };
        }
      }
      onChange(dateObject);
      setSelectedDateObj(dateObject);
    }
  };

  const onClickTodayBtn = () => {
    const toDayObj = {
      from: startOfDay(TODAY_OBJECT.from),
      to: endOfDay(TODAY_OBJECT.to),
    };
    onClickToday(toDayObj);
  };

  const onShowCalendar = event => {
    setPopoverAnchor(event.currentTarget);
  };

  const onNextCalendar = () => {
    if (isPickRangeDate) {
      onSelectDate(
        {
          from: addDays(selectedDateObj.from || TODAY_OBJECT.from, 1),
          to: addDays(selectedDateObj.to || TODAY_OBJECT.to, 1),
        },
        optionView,
      );
    } else {
      switch (optionView) {
        case AppConstant.ReservationViewBy.day:
          return onSelectDate(
            {
              from: addDays(selectedDateObj.from || TODAY_OBJECT.from, 1),
              to: addDays(selectedDateObj.to || TODAY_OBJECT.to, 1),
            },
            optionView,
          );
        case AppConstant.ReservationViewBy.week:
          return onSelectDate(
            {
              from: addWeeks(selectedDateObj.from || TODAY_OBJECT.from, 1),
              to: addWeeks(selectedDateObj.to || TODAY_OBJECT.to, 1),
            },
            optionView,
          );
        case AppConstant.ReservationViewBy.month:
          return onSelectDate(
            {
              from: addMonths(selectedDateObj.from || TODAY_OBJECT.from, 1),
              to: addMonths(selectedDateObj.to || TODAY_OBJECT.to, 1),
            },
            optionView,
          );
      }
    }
  };

  const onPreviousCalendar = () => {
    if (isPickRangeDate) {
      onSelectDate(
        {
          from: subDays(selectedDateObj.from || TODAY_OBJECT.from, 1),
          to: subDays(selectedDateObj.to || TODAY_OBJECT.to, 1),
        },
        optionView,
      );
    } else {
      switch (optionView) {
        case AppConstant.ReservationViewBy.day:
          return onSelectDate(
            {
              from: subDays(selectedDateObj.from || TODAY_OBJECT.from, 1),
              to: subDays(selectedDateObj.to || TODAY_OBJECT.to, 1),
            },
            optionView,
          );
        case AppConstant.ReservationViewBy.week:
          return onSelectDate(
            {
              from: subWeeks(selectedDateObj.from || TODAY_OBJECT.from, 1),
              to: subWeeks(selectedDateObj.to || TODAY_OBJECT.to, 1),
            },
            optionView,
          );
        case AppConstant.ReservationViewBy.month:
          return onSelectDate(
            {
              from: subMonths(selectedDateObj.from || TODAY_OBJECT.from, 1),
              to: subMonths(selectedDateObj.to || TODAY_OBJECT.to, 1),
            },
            optionView,
          );
      }
    }
  };

  useEffect(() => {
    if (dateRange && dateRange.from && dateRange.to) setSelectedDateObj(dateRange);
  }, [dateRange]);

  useEffect(() => {
    if (selectedDateObj && selectedDateObj.from && selectedDateObj.to) {
      if (isSameDay(selectedDateObj.from, selectedDateObj.to)) {
        setLabelSelectedDate(fetchDateToText(selectedDateObj.from, getLabel));
      } else {
        setLabelSelectedDate(dateFormat(selectedDateObj));
      }
    }
  }, [selectedDateObj]);

  const todayObj = new Date();
  let isTodaySelected = isCheckTimeToday(selectedDateObj);
  let isDateBetween =
    isPickRangeDate && isEqual(todayObj, selectedDateObj.from) >= 0 && isEqual(selectedDateObj.to, todayObj) >= 0;

  let classBtn = isTodaySelected
    ? classes.todayButtonSelected
    : isDateBetween
    ? classes.todayButtonWithinInterval
    : classes.todayButton;

  return (
    <Box className={classes.root}>
      <Button className={clsx("medium-md-txt", classBtn)} onClick={onClickTodayBtn}>
        {getLabel(getCommonKey(LangConstant.TXT_TODAY))}
      </Button>
      <Button
        classes={{
          root: classes.buttonCalendarLeft,
        }}
        onClick={onPreviousCalendar}
      >
        <ArrowLeftTwoTone className={classes.iconButtonCalendar} />
      </Button>
      <Button
        variant="outlined"
        size="small"
        classes={{ root: classes.btnRoot, label: `medium-md-txt` }}
        onClick={onShowCalendar}
      >
        {labelSelectedDate}
      </Button>
      <Button className={clsx(classes.buttonCalendarLeft, classes.buttonCalendarRight)} onClick={onNextCalendar}>
        <ArrowRightTwoTone className={classes.iconButtonCalendar} />
      </Button>
      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{
          root: classes.datePickerContainer,
          paper: classes.datePickerPaper,
        }}
        onClose={() => setPopoverAnchor(null)}
      >
        <PrimaryCalendar onChange={onSelectDate} dateRange={dateRange} isPickRangeDate={isPickRangeDate} />
      </Popover>
    </Box>
  );
};

const TODAY_OBJECT = {
  from: new Date(),
  to: new Date(),
};

PrimaryCustomDatePicker.propTypes = {
  dateRange: PropTypes.shape({
    from: PropTypes.objectOf(Date),
    to: PropTypes.objectOf(Date),
  }),
  isPickRangeDate: PropTypes.bool,

  onChange: PropTypes.func,
  onClickToday: PropTypes.func,
};

PrimaryCustomDatePicker.defaultProps = {
  dateRange: TODAY_OBJECT,
  isPickRangeDate: false,
};

const isCheckTimeToday = timeRange => {
  let today = new Date();
  return isSameDay(today, timeRange.to) && isSameDay(today, timeRange.from);
};

const dateFormat = date => {
  let dateFormat =
    DateFormat(date.from, AppConstant.FM_DD_MM_YYYY) + "-" + DateFormat(date.to, AppConstant.FM_DD_MM_YYYY);
  return dateFormat;
};

export default memo(PrimaryCustomDatePicker);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: 30,
  },
  btnRoot: {
    textTransform: "none",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "unset",
    display: "flex",
    cursor: "pointer",
    minWidth: 200,
    paddingLeft: 8,
    paddingRight: 8,
    letterSpacing: "unset",
  },
  datePickerContainer: {
    display: "flex",
    textTransform: "none",
  },
  datePickerPaper: {
    display: "block",
    borderRadius: 4,
  },
  dateRangeIcon: {
    width: 18,
    height: 18,
    marginRight: 6,
  },
  buttonCalendarLeft: {
    minWidth: 28,
    height: 30,
    padding: "3px 2px",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 2,
  },
  buttonCalendarRight: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  iconButtonCalendar: {
    width: 24,
    height: 24,
  },
  todayButton: {
    minWidth: 81,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
  todayButtonSelected: {
    minWidth: 80,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    padding: "4px 8px",
    backgroundColor: "#3e4045",
    color: theme.palette.white,
    "&>span>p": {
      color: theme.palette.white,
    },
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
  todayButtonWithinInterval: {
    background: "#f1f3f6",
    minWidth: 81,
    height: 30,
    minHeight: "unset",
    textTransform: "none",
    borderRadius: "unset",
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#3e4045",
      color: theme.palette.white,
      "&>span>p": {
        color: theme.palette.white,
      },
    },
  },
}));
