import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogActions, Typography, makeStyles, IconButton } from "@material-ui/core";
import { memo } from "react";
import { CloseOutlined, CheckCircleOutline } from "@material-ui/icons";

const DialogSuccess = props => {
  const { isShow, title, message, onClose, action, classes } = props;
  const defaultClasses = useStyles();

  return (
    <Dialog open={isShow} maxWidth="md" classes={{ paper: `${defaultClasses.root} ${classes.root}` }}>
      <DialogContent className={`${defaultClasses.contentRoot} `}>
        <IconButton className={defaultClasses.btnClose} onClick={onClose}>
          <CloseOutlined className={defaultClasses.iconClose} />
        </IconButton>
        <CheckCircleOutline className={defaultClasses.iconChecked} />
        <Typography component="p" classes={{ body1: defaultClasses.title }}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={defaultClasses.message}
          classes={{ body1: "regular-lg-txt" }}
        >
          {message}
        </Typography>
      </DialogContent>
      {action && <DialogActions className={defaultClasses.actionRoot}>{action}</DialogActions>}
    </Dialog>
  );
};

DialogSuccess.propTypes = {
  isShow: PropTypes.bool,
  message: PropTypes.string,
  classes: PropTypes.object,
  icon: PropTypes.node,
  action: PropTypes.node,

  onClose: PropTypes.func.isRequired,
};

DialogSuccess.defaultProps = { classes: { root: "" } };
export default memo(DialogSuccess);

const useStyles = makeStyles(theme => ({
  root: { borderRadius: 10 },
  contentRoot: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  title: {
    fontSize: 18,
    fontWeight: 800,
    margin: 15,
  },
  message: {
    color: theme.palette.grey[500],
    textAlign: "center",
  },
  btnClose: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  iconClose: {
    fontSize: 16,
    color: theme.palette.grey[400],
  },
  iconChecked: {
    fontSize: 55,
    color: theme.palette.primary.main,
    margin: 10,
  },
  actionRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.primary.main,
    minHeight: 78,
    color: "white",
  },
}));
