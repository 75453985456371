import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Checkbox, Box } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

const CategoryMenuItem = forwardRef((props, ref) => {
  const {
    data,
    multiLevel,
    defaultMode,
    categoryMode,
    onCheck,
    selectedValue,
    onCheckCategory,
    selectedCategory,
    itemsKey,
  } = props;
  const classes = useStyles({ defaultMode });

  const onValueCheck = event => {
    onCheck(event.currentTarget.id);
  };

  const onCategoryCheck = event => {
    onCheckCategory(event.currentTarget.id);
  };

  return (
    <Box className={classes.menuItemsRoot}>
      {multiLevel &&
        data.map(category => (
          <Box className={classes.menuItemContainer} key={category.uid} ref={ref}>
            {(categoryMode || defaultMode) && (
              <Box className={classes.categoryContentBox} onClick={onCategoryCheck} id={category.uid}>
                <Checkbox
                  classes={{ root: classes.categoryCheckbox, checked: classes.checked }}
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  value={category.uid}
                  checked={selectedCategory.includes(category.uid)}
                />
                <Typography color="inherit" className="semiBold-md-txt">
                  {category.name}
                </Typography>
              </Box>
            )}
            {(!categoryMode || defaultMode) &&
              category[itemsKey].map(valueItem => (
                <Box key={valueItem.uid} className={classes.itemContentBox} onClick={onValueCheck} id={valueItem.uid}>
                  <Checkbox
                    classes={{ root: classes.itemCheckbox, checked: classes.checked }}
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    checked={selectedValue.includes(valueItem.uid)}
                  />
                  <Typography color="inherit" className="regular-md-txt">
                    {valueItem.name}
                  </Typography>
                </Box>
              ))}
          </Box>
        ))}
      {!multiLevel &&
        data.map(value => (
          <Box className={classes.menuItemContainer} key={value.uid} ref={ref}>
            <Box key={value.uid} className={classes.itemContentBox} ref={ref} onClick={onValueCheck} id={value.uid}>
              <Checkbox
                classes={{ root: classes.itemCheckbox, checked: classes.checked }}
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                checked={selectedValue.includes(value.uid)}
              />
              <Typography color="inherit" className="regular-md-txt">
                {value.name}
              </Typography>
            </Box>
          </Box>
        ))}
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  menuItemsRoot: { maxHeight: 150 },
  menuItemContainer: {
    paddingLeft: 4,
    "&:not(:last-child)": {
      borderBottom: props => props.defaultMode && `solid 1px ${theme.palette.grey[200]}`,
    },
  },
  categoryContentBox: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  itemContentBox: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#3e4045",
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  categoryCheckbox: {
    color: "#4b4d53",
  },
  itemCheckbox: {
    color: "#979aa1",
  },
  checked: {
    color: `${theme.palette.text.link} !important`,
  },
}));

CategoryMenuItem.propTypes = {
  data: PropTypes.array,
  multiLevel: PropTypes.bool,
  onCheck: PropTypes.func,
  onCheckCategory: PropTypes.func,
  selectedValue: PropTypes.array,
  selectedCategory: PropTypes.array,
  categoryMode: PropTypes.bool,
  defaultMode: PropTypes.bool,
  itemsKey: PropTypes.string,
};

CategoryMenuItem.defaultProps = {
  data: [],
  multiLevel: false,
  onCheck: () => {},
  onCheckCategory: () => {},
  selectedValue: [],
  selectedCategory: [],
  categoryMode: false,
  defaultMode: false,
  itemsKey: "items",
};

export default memo(CategoryMenuItem);
