import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Container,
  makeStyles,
  Box,
} from "@material-ui/core";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, startOfDay, endOfDay } from "date-fns";
import { getLabelArray } from "utils";
import { LangConstant, AppConstant } from "const";
import { PrimaryCustomDatePicker, MenuStatus, SearchInPeriod, MultiLayerMenu, CellHead, CellBody } from "components";
import ReportBookingAction from "redux/report-booking.redux";
import PlaceCategoryAction from "redux/place-category.redux";
import ServiceCategoryAction from "redux/service-category.redux";
import { getEndOfDay, getStartOfDay } from "utils/date";

const MnReportBooking = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const dispatch = useDispatch();
  const placeCategoryRedux = useSelector(state => state.placeCategoryRedux);
  const { data: placeCategoryData } = placeCategoryRedux;
  const [dataDay, dataWeek, dataMonth] = useSelector(
    ({ reportBookingRedux }) => [reportBookingRedux.dataDay, reportBookingRedux.dataWeek, reportBookingRedux.dataMonth],
    shallowEqual,
  );

  const [showData, setShowData] = useState(dataDay != null ? dataDay : null);
  const [headData, setHeadData] = useState([
    null,
    dataDay?.total?.reservationCount || 0,
    dataDay?.total?.consumer?.total || 0,
  ]);
  const [selectedDate, setSelectedDate] = useState({ from: startOfDay(new Date()), to: endOfDay(new Date()) });
  const [dateObject, setDateObject] = useState({ from: startOfDay(new Date()), to: endOfDay(new Date()) });
  const [filterData, setFilterData] = useState({
    status: null,
    placeCategoryUid: null,
  });
  const [optionView, setOptionView] = useState(AppConstant.ReservationViewBy.day);
  const [placeCategoryArray] = useState([]);

  const headTitle = LangConstant.ARR_REPORT_HEADER;

  const onChangeDate = (date, option) => {
    onChangeOption(date, option || optionView);
    setSelectedDate({ from: date.from, to: date.to });
  };

  const onClickTodayBtn = toDayObj => onChangeDate(toDayObj, AppConstant.ReservationViewBy.day);

  const onChangeOption = (newSelectedDate, option) => {
    let startDate = newSelectedDate.from,
      endDate = newSelectedDate.to;
    if (option === AppConstant.ReservationViewBy.week) {
      startDate = startOfWeek(newSelectedDate.from, { weekStartsOn: 1 });
      endDate = endOfWeek(newSelectedDate.to, { weekStartsOn: 1 });
    } else if (option === AppConstant.ReservationViewBy.month) {
      startDate = startOfMonth(newSelectedDate.from);
      endDate = endOfMonth(newSelectedDate.to);
    }
    setOptionView(option);
    setDateObject({ from: startDate, to: endDate });
  };

  const onChangeFilterData = data => {
    setFilterData({
      ...filterData,
      [data.name]: data.value,
    });
  };

  const getPeriodSearch = period => {
    if (period === AppConstant.ReservationViewBy.day && dataDay) {
      setHeadData([null, dataDay.total.reservationCount, dataDay.total.consumer.total]);
      setShowData(dataDay);
      setOptionView(period);
      onChangeDate(selectedDate, AppConstant.ReservationViewBy.day);
    } else if (period === AppConstant.ReservationViewBy.week && dataWeek) {
      setHeadData([null, dataWeek.total.reservationCount, dataWeek.total.consumer.total]);
      setShowData(dataWeek);
      setOptionView(period);
      onChangeDate(selectedDate, AppConstant.ReservationViewBy.week);
    } else if (period === AppConstant.ReservationViewBy.month && dataMonth) {
      setHeadData([null, dataMonth.total.reservationCount, dataMonth.total.consumer.total]);
      setShowData(dataMonth);
      setOptionView(period);
      onChangeDate(selectedDate, AppConstant.ReservationViewBy.month);
    }
  };

  const onChangeFilterByPlace = event => {
    let tmpData = {
      placeCategoryUid: null,
      placeItemUid: null,
    };
    if (event) {
      if (event.categoryUid) {
        tmpData = {
          placeCategoryUid: event.categoryUid,
          placeItemUid: event.uid,
        };
      } else {
        tmpData = {
          placeCategoryUid: event.uid,
        };
      }
    }
    setFilterData({
      ...filterData,
      ...tmpData,
    });
  };

  useEffect(() => {
    dispatch(PlaceCategoryAction.getPlaceCategory({}));
    dispatch(ServiceCategoryAction.getServiceCategory({}));
  }, []);

  useEffect(() => {
    if (placeCategoryData) {
      let placeCategoryArr = Object.values(placeCategoryData);
      for (let i = 0; i < placeCategoryArr.length; i++) {
        placeCategoryArray.push({
          name: placeCategoryArr[i].name,
          uid: placeCategoryArr[i].uid,
          items: Object.values(placeCategoryArr[i].items),
        });
      }
    }
  }, [placeCategoryData]);

  useEffect(() => {
    if (dataDay && optionView === AppConstant.ReservationViewBy.day) {
      setHeadData([null, dataDay.total.reservationCount, dataDay.total.consumer.total]);
      setShowData(dataDay);
    }
  }, [dataDay, optionView]);

  useEffect(() => {
    if (dataWeek && optionView === AppConstant.ReservationViewBy.week) {
      setHeadData([null, dataWeek.total.reservationCount, dataWeek.total.consumer.total]);
      setShowData(dataWeek);
    }
  }, [dataWeek, optionView]);

  useEffect(() => {
    if (dataMonth && optionView === AppConstant.ReservationViewBy.month) {
      setHeadData([null, dataMonth.total.reservationCount, dataMonth.total.consumer.total]);
      setShowData(dataMonth);
    }
  }, [dataMonth, optionView]);

  useEffect(() => {
    let startWeek = startOfWeek(selectedDate.from, { weekStartsOn: 1 });
    let endWeek = endOfWeek(selectedDate.to, { weekStartsOn: 1 });
    let startMonth = startOfMonth(selectedDate.from);
    let endMonth = endOfMonth(selectedDate.to);

    dispatch(
      ReportBookingAction.requestReportBookingDay({
        start: getStartOfDay(selectedDate.from),
        end: getEndOfDay(selectedDate.to),
        ...filterData,
      }),
    );

    dispatch(
      ReportBookingAction.requestReportBookingWeek({
        start: getStartOfDay(startWeek),
        end: getEndOfDay(endWeek),
        ...filterData,
      }),
    );

    dispatch(
      ReportBookingAction.requestReportBookingMonth({
        start: getStartOfDay(startMonth),
        end: getEndOfDay(endMonth),
        ...filterData,
      }),
    );
  }, [filterData, selectedDate]);

  return (
    <Box className="mn-report-booking-root">
      <Paper elevation={1} square className={"container"}>
        <Container>
          <Box aria-label="total" className={"options-bar"}>
            <Box aria-label="date_status" className={classes.dateAndStatusFilter}>
              <PrimaryCustomDatePicker
                onChange={onChangeDate}
                dateRange={dateObject}
                optionView={optionView}
                onClickToday={onClickTodayBtn}
              />
              <MenuStatus
                name="status"
                onChange={event => {
                  onChangeFilterData({ name: "status", value: event });
                }}
                classes={{ root: "menu-status" }}
                categoryText={getLabel(LangConstant.TXT_STATUS)}
                arrayItemsText={getLabelArray(LangConstant.ARR_STATUS_FILTER, getLabel, true)}
              />
              <Box aria-label="period_options" className={"item-options-bar"}>
                <SearchInPeriod
                  onChange={getPeriodSearch}
                  optionViewSelected={optionView}
                  className={"period-options"}
                  btnClass={classes.searchInPeriodBtn}
                />
              </Box>
              <MultiLayerMenu
                className={classes.multiLayerButton}
                labelText={getLabel(LangConstant.L_PLACE_CATEGORY)}
                array={placeCategoryArray}
                includeDefault={true}
                onChange={onChangeFilterByPlace}
              />
            </Box>
            <TableContainer className={"table-container"} mx="60px" my="40px">
              <Table aria-label="report table" className={"table-root"}>
                <TableHead>
                  <TableRow>
                    {headTitle.map((cell, index) => (
                      <CellHead
                        className={classes.cellHeadRoot}
                        cellData={index === 0 ? getLabel(cell) : `${getLabel(cell)} (${headData[index]})`}
                        key={index}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {showData != null &&
                    Object.keys(showData.details).map((key, index) => (
                      <TableRow key={index}>
                        <CellBody
                          className={classes.cellBodyRoot}
                          cellData={Object.keys(showData.details).length === 7 ? LangConstant.DAY_OF_WEEK[key] : key}
                          style={{ width: "20%" }}
                        />
                        <CellBody
                          className={classes.cellBodyRoot}
                          cellData={showData.details[key].reservationCount}
                          style={{ width: "40%" }}
                        />
                        <CellBody
                          className={classes.cellBodyRoot}
                          cellData={showData.details[key].consumer.total}
                          style={{ width: "40%" }}
                        />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  cellHeadRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  cellBodyRoot: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  dateAndStatusFilter: {
    height: "30px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      display: "contents",
    },
  },
  overrideItemClass: {
    width: "auto",
    minWidth: 137,
    minHeight: 40,
    [theme.breakpoints.down("sm")]: {
      minWidth: 125,
    },
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
  },
  boxRoot: {
    height: "100%",
    [theme.breakpoints.down(660)]: {
      marginBottom: 0,
      marginTop: "0px !important",
    },
    [theme.breakpoints.down(970)]: {
      height: 30,
      marginTop: 5,
      marginLeft: "0px !important",
    },
  },
  btnRoot: {
    width: "max-content",
    minWidth: 125,
    color: theme.palette.grey[600],
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "0%",
    border: `solid 1px ${theme.palette.grey[200]}`,
    height: "100%",
  },
  btnLabel: {
    justifyContent: "unset",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 8,
    },
  },
  menuPaper: {
    marginTop: "2px",
    borderRadius: "0%",
    boxShadow: `0 1px 6px 0 rgba(0, 0, 0, 0.1)`,
    maxHeight: 160,
  },
  menuList: {
    paddingTop: `0px`,
    paddingBottom: `0px`,
    paddingRight: `0px !important`,
  },
  icon: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      right: 8,
    },
    color: theme.palette.grey[500],
  },
  menuItemRoot: {
    minHeight: 40,
    minWidth: 137,
    [theme.breakpoints.down("sm")]: {
      minWidth: 125,
    },
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
    borderBottom: `solid 0.5px ${theme.palette.grey[200]}`,
  },
  multiLayerButton: {
    width: "max-content !important",
    minWidth: 137,
    [theme.breakpoints.down(660)]: {
      marginBottom: 0,
      marginTop: "0px !important",
    },
    [theme.breakpoints.down(970)]: {
      minWidth: 125,
      height: 30,
      marginTop: 0,
      marginLeft: "0px !important",
      marginRight: 5,
    },
  },
  searchInPeriodBtn: {
    height: 30,
    [theme.breakpoints.down(960)]: {
      marginBottom: 5,
      marginLeft: 0,
      marginTop: 5,
    },
    [theme.breakpoints.down(645)]: {
      marginBottom: 0,
      marginTop: 5,
      marginLeft: "-10px",
    },
    [theme.breakpoints.down(595)]: {
      marginLeft: 0,
      marginTop: "-5px",
    },
  },
}));

export default memo(MnReportBooking);
