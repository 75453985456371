import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, makeStyles, Menu, MenuItem, Slider, Typography } from "@material-ui/core";
import { ArrowDropDown, PlaceOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { SearchBar } from "components";
import { LangConstant } from "const";
import { convertSmallNumber, getCommonKey } from "utils";
import ValueLabelComponent from "./SelectedTimeTooltip";
import PlaceCategoryAction from "redux/place-category.redux";
import { getStartOfDay } from "utils/date";

const PlaceDialogFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLACE_MANAGE);

  const [shopWorkingTime, timeFrame] = useSelector(
    ({ shopInfoRedux }) => [shopInfoRedux.data?.details?.workingTime, shopInfoRedux.data?.details?.timeFrame],
    shallowEqual,
  );

  const placeArray = useSelector(({ placeCategoryRedux }) => placeCategoryRedux.placeArray, shallowEqual);
  const defaultTimeFrame = timeFrame || DEFAULT_TIME_FRAME;
  const sliderStep = getMinuteFromSecs(defaultTimeFrame);

  const [timeRange, setTimeRange] = useState([0, 0]);
  const [selectedTimeRange, setSelectedTimeRange] = useState([0, 0]);
  const [placeCategory, setPlaceCategory] = useState([]);

  const [filterParams, setFilterParams] = useState({
    placeFilter: {},
    start: 0,
    end: 0,
    filter: "",
  });

  const onChangeTimeRange = timeValue => {
    setSelectedTimeRange(timeValue);
  };

  const onTypeSearch = keyword => {
    setFilterParams({ ...filterParams, filter: keyword });
  };

  const onChangeFilterByPlace = place => {
    setFilterParams({ ...filterParams, placeFilter: place });
  };

  useEffect(() => {
    if (placeArray.length > 0) {
      setPlaceCategory(placeArray);
    }
  }, [placeArray]);

  useEffect(() => {
    if (shopWorkingTime) {
      let tmpShopWorkingTime = getTodayWorkingTime(shopWorkingTime);
      if (tmpShopWorkingTime && defaultTimeFrame) {
        let timeFrameMinute = getMinuteFromSecs(defaultTimeFrame);
        let { timeRange, selectTimeRange } = getTimeRange(timeFrameMinute, tmpShopWorkingTime);
        if (timeRange && selectTimeRange) {
          setTimeRange(timeRange);
          setSelectedTimeRange(selectTimeRange);
          let timeRangeToCallAPI = {
            start: getStartOfDay(new Date()) + fromMinuteToSec(selectTimeRange[0]),
            end: getStartOfDay(new Date()) + fromMinuteToSec(selectTimeRange[1]),
          };
          setFilterParams({ ...filterParams, ...timeRangeToCallAPI });
        }
      }
    }
  }, [shopWorkingTime, defaultTimeFrame]);

  useEffect(() => {
    let timeRangeTimeout = setTimeout(() => {
      if (selectedTimeRange[1] >= selectedTimeRange[0]) {
        let tmpParam = { ...filterParams };
        if (!filterParams.filter) delete tmpParam.filter;
        dispatch(
          PlaceCategoryAction.getPlaceDialogData({
            ...tmpParam,
            start: getStartOfDay(new Date()) + fromMinuteToSec(selectedTimeRange[0]),
            end: getStartOfDay(new Date()) + fromMinuteToSec(selectedTimeRange[1]),
          }),
        );
      }
    }, DELAY_TIME);
    return () => clearTimeout(timeRangeTimeout);
  }, [selectedTimeRange, filterParams]);

  return (
    <>
      <Box className={classes.contentHeader}>
        <Box className={classes.leftSideFilter}>
          <Box className={classes.placeSelectMenu}>
            <PlaceOutlined className={classes.placeIcon} />
            {placeCategory && (
              <PlaceMenu
                arrayItemsText={placeCategory}
                onChange={onChangeFilterByPlace}
                selected={filterParams.placeFilter}
              />
            )}
          </Box>
          <SearchBar
            className={classes.searchInput}
            customClasses={{
              searchIcon: classes.searchIcon,
              input: classes.searchInputBar,
            }}
            placeholder={getLabel(getCommonKey(LangConstant.P_BOOKING_SEARCH))}
            onChange={onTypeSearch}
          />
        </Box>
        <Box className={classes.rightSideFilter}>
          <Typography className={classes.timeRange}>{convertMinuteToString(Math.min(...timeRange))}</Typography>
          <Slider
            value={selectedTimeRange}
            onChange={(_, newValue) => onChangeTimeRange(newValue)}
            valueLabelDisplay="auto"
            ValueLabelComponent={ValueLabelComponent}
            valueLabelFormat={value => {
              return (
                <Typography className={clsx("regular-sm-txt", classes.sliderLabel)}>
                  {convertMinuteToString(value)}
                </Typography>
              );
            }}
            marks
            step={sliderStep}
            min={Math.min(...timeRange)}
            max={Math.max(...timeRange)}
            classes={{
              root: classes.slideBarMain,
              thumb: classes.slideThumb,
              rail: classes.slideLine,
              track: classes.slideTrack,
              valueLabel: classes.slideHoverLabel,
              mark: classes.slideMark,
            }}
          />
          <Typography className={classes.timeRange}>{convertMinuteToString(Math.max(...timeRange))}</Typography>
        </Box>
      </Box>
    </>
  );
};

const PlaceMenu = ({ arrayItemsText, selected, onChange }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLACE_MANAGE);

  const [anchorEl, setAnchorEl] = useState(null);

  const onClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = value => {
    setAnchorEl(null);
    onChange(value);
  };

  let placeFilterButton = document.getElementById(PLACE_FILTER_BUTTON_ID);
  let placeFilterMenuList = document.getElementById(PLACE_FILTER_MENU_LIST_ID);

  useEffect(() => {
    if (placeFilterButton && placeFilterMenuList) {
      placeFilterMenuList.style.width = placeFilterButton.offsetWidth + "px";
    }
  }, [placeFilterButton, placeFilterMenuList]);

  return (
    <Box className={classes.boxRoot}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onClickListItem}
        classes={{ root: classes.btnRoot }}
        id={PLACE_FILTER_BUTTON_ID}
      >
        <Typography className={"ellipsis medium-md-txt"}>
          {selected?.name || getLabel(getCommonKey(LangConstant.TXT_BOOK_PREVIEW_PLACE))}
        </Typography>
        <ArrowDropDown className={classes.icon} />
      </IconButton>
      <Menu
        id="lock-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        classes={{ list: classes.menuList, paper: classes.menuPaper }}
        MenuListProps={{
          id: PLACE_FILTER_MENU_LIST_ID,
        }}
      >
        {arrayItemsText.map((value, index) => (
          <MenuItem
            onClick={() => onMenuItemClick(value)}
            className={clsx("semiBold-md-txt", classes.menuItemRoot, "ellipsis")}
            key={index}
          >
            <Typography className={clsx("semiBold-md-txt", classes.menuItemLabel, "ellipsis")}>{value.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const DEFAULT_TIME_FRAME = 1800; // Default is 30mins per step
const PLACE_FILTER_BUTTON_ID = "place-filter-button";
const PLACE_FILTER_MENU_LIST_ID = "place-filter-menu-list";

const DEFAULT_INITIAL_TIME = 120; // Default is two hour from now
const DELAY_TIME = 1000;

const getTodayWorkingTime = shopWorkingTime => {
  let result;
  let today = new Date();
  shopWorkingTime.map(block => {
    if (block.days.includes(today.getDay())) {
      result = {
        open: getMinuteFromSecs(block.open),
        close: getMinuteFromSecs(block.close),
      };
    }
  });
  return result;
};

const getTimeRange = (timeFrameInMinute = 30, shopWorkingTime) => {
  if (!shopWorkingTime) return {};
  let today = new Date();
  let openTime = shopWorkingTime?.open;
  let endTime = shopWorkingTime?.close;
  let startTime = 0;
  let thisHour = today.getHours();
  let thisMin = today.getMinutes();
  let tmpResult = 0;
  for (let index = 0; index <= Math.floor(60 / timeFrameInMinute); index++) {
    if (timeFrameInMinute * index <= thisMin) {
      tmpResult = timeFrameInMinute * index;
    }
  }
  startTime = thisHour * 60 + tmpResult;
  if (startTime < openTime) {
    startTime = openTime;
  }
  return {
    timeRange: [startTime, endTime],
    selectTimeRange: [startTime, startTime + DEFAULT_INITIAL_TIME],
  };
};

const getMinuteFromSecs = secs => {
  if (!secs) return 0;
  return Math.floor(secs / 60);
};

const convertMinuteToString = minute => {
  if (!minute) return "";
  let hourValue = getMinuteFromSecs(minute);
  let minuteValue = minute - hourValue * 60;
  return `${convertSmallNumber(hourValue)}:${convertSmallNumber(minuteValue)}`;
};

const fromMinuteToSec = min => {
  if (!min) return 0;
  let result = min * 60;
  return result;
};

export default memo(PlaceDialogFilter);

const useStyles = makeStyles(theme => ({
  boxRoot: {
    height: 30,
    minWidth: 217,
    margin: "0 auto 0 14px",
    width: "100%",
  },

  btnRoot: {
    padding: "0 5px",
    width: "100%",
    height: "100%",
    color: "#3b404c",
    flexDirection: "row",
    alignItems: "center",
    border: `solid 1px ${theme.palette.grey[200]}`,
    textAlign: "left",
    justifyContent: "space-between",
    borderRadius: 2,
  },

  slideBarMain: {
    padding: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: 27,
    marginLeft: 13,
  },

  slideThumb: {
    border: `2px solid`,
    color: "#fff",
    width: 14,
    height: 10,
    borderRadius: 6.5,
    backgroundColor: "#ffffff",
    margin: 0,
  },

  slideTrack: {
    borderBottomLeftRadius: 6.5,
    borderTopLeftRadius: 6.5,
    height: 8,
    backgroundColor: "#65b39d",
    marginLeft: 7,
  },

  slideLine: {
    borderRadius: 6.5,
    height: 8,
    backgroundColor: "#d4d5d8",
    opacity: 1,
    width: "101%",
    left: 5,
  },

  slideHoverLabel: {
    "&>span": {
      backgroundColor: "red",
    },
  },

  slideMark: {
    backgroundColor: "transparent",
  },

  contentHeader: {
    display: "grid",
    gridAutoFlow: "column",
    width: "100%",
    backgroundColor: "#fafafb",
    height: 60,
    gridTemplateColumns: "calc(50% - 10px) calc(50% - 10px)",
    justifyContent: "space-between",
    [theme.breakpoints.down(1000)]: {
      padding: 10,
      height: "auto",
      display: "flex",
      flexDirection: "column",
    },
  },

  leftSideFilter: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    gridAutoFlow: "column",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  rightSideFilter: {
    marginRight: 13,
    marginLeft: 13,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(1000)]: {
      marginTop: 25,
    },
  },

  searchInput: {
    borderRadius: 4,
    height: 30,
    backgroundColor: "#f6f5f9",
    width: "-webkit-fill-available",
    marginLeft: 14,
  },

  timeRange: {
    fontSize: 13,
    fontWeight: 600,
    minWidth: "fit-content",
    color: "#65b39d",
    textAlign: "center",
  },

  searchIcon: {
    width: 20,
    height: 20,
    color: "#7f838c",
  },

  sliderLabel: {
    color: theme.palette.white,
    minWidth: "fit-content",
    wordBreak: "initial",
  },

  menuPaper: {
    maxWidth: 225,
    borderRadius: 3,
    marginTop: 2,
  },
  menuItemRootDefault: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  menuItemRoot: {
    minHeight: 40,
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: 125,
    },
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.grey[700],
    },
  },
  menuItemLabel: {
    color: "inherit",
    whiteSpace: "break-spaces",
  },
  icon: {
    color: "#6c7078",
  },
  menuList: {
    padding: 0,
  },
  searchInputBar: {
    "&::placeholder": {
      color: "#d4d5d8",
      opacity: 1,
    },
  },
  placeSelectMenu: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
    },
  },
  placeIcon: {
    marginLeft: 10,
  },
}));
