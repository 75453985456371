import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography, Box, MenuItem } from "@material-ui/core";

const DropDownCategoryItem = forwardRef((props, ref) => {
  const { onSelectCategoryItem, onSelectCategory, items, category } = props;
  const classes = useStyles();

  const onCreateEventSelectedItem = item => () => onSelectCategoryItem(item);

  const onClickCategory = () => onSelectCategory(category);

  return (
    <Box className={classes.menuItemContainer} key={category.uid} ref={ref}>
      <MenuItem className={classes.categoryContentBox} onClick={onClickCategory} id={category.uid}>
        <Typography color="inherit" className="semiBold-md-txt">
          {category.name}
        </Typography>
      </MenuItem>
      {(items || []).map(valueItem => (
        <MenuItem
          key={valueItem.uid}
          className={classes.itemContentBox}
          onClick={onCreateEventSelectedItem(valueItem)}
          id={valueItem.uid}
        >
          <Typography color="inherit" className="regular-md-txt ellipsis">
            {valueItem.name}
          </Typography>
        </MenuItem>
      ))}
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  menuItemContainer: {
    paddingLeft: 4,
    "&:not(:last-child)": {
      borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    },
  },
  categoryContentBox: {
    height: 38,
    cursor: "pointer",
    display: "flex",
    color: "#3e4045",
    alignItems: "center",
    paddingLeft: 16,
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  itemContentBox: {
    cursor: "pointer",
    display: "flex",
    paddingLeft: 16,
    alignItems: "center",
    color: "#3e4045",
    height: 38,
    whiteSpace: "normal",
    "&:hover": {
      background: "#4b4d53",
      color: theme.palette.white,
      "& > *": {
        color: theme.palette.white,
      },
    },
  },
  typography: {
    height: 22,
  },
}));

DropDownCategoryItem.propTypes = {
  data: PropTypes.array,
  onSelectCategoryItem: PropTypes.func,
  onSelectCategory: PropTypes.func,
};

DropDownCategoryItem.defaultProps = {
  data: [],
  onSelectCategoryItem: () => {},
  onSelectCategory: () => {},
};

export default memo(DropDownCategoryItem);
