import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { LangConstant } from "const";
import { getCommonKey, isObjectNotEqual } from "utils";
import LabelInput from "components/form/LabelInput";
import PrimaryDialog from "components/PrimaryDialog";

const DialogAddBankingInfo = ({ onChange, isShow, onClose, formData, isEditing }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PAYMENT_SETUP);

  const [bankingInfoForm, setBankingInfoForm] = useState(formData);
  const [isContainInvalidField, setIsContainInvalidField] = useState(false);

  const onChangeBankingInfoForm = (name, value) => {
    setIsContainInvalidField(false);
    let newForm = { ...bankingInfoForm };
    newForm[name] = value;
    setBankingInfoForm(newForm);
  };

  const onSave = () => {
    if (formatBankingInfo(bankingInfoForm)) {
      onChange(bankingInfoForm);
      onCloseDialog();
    } else {
      setIsContainInvalidField(true);
    }
  };

  const onCloseDialog = () => {
    setBankingInfoForm(DEFAULT_INPUT_VALUE);
    setIsContainInvalidField(false);
    onClose();
  };

  useEffect(() => {
    if (isObjectNotEqual(formData, bankingInfoForm)) setBankingInfoForm(formData);
  }, [formData]);

  return (
    <PrimaryDialog
      isShow={isShow}
      title={
        <Typography className={classes.dialogTitle}>
          {getLabel(isEditing ? LangConstant.TXT_EDIT_BANKING_INFO : LangConstant.TXT_ADD_NEW_BANKING_INFO)}
        </Typography>
      }
      classes={{
        paper: classes.dialogRoot,
        title: {
          titleRoot: classes.title,
          titleIcon: classes.closeIcon,
        },
        content: classes.dialogContent,
      }}
      onClose={onCloseDialog}
    >
      <LabelInput
        label={getLabel(LangConstant.TXT_BANKING_SERVICE_INFO)}
        isRequired={true}
        value={bankingInfoForm.serviceName}
        onChange={event => {
          let value = event.target.value;
          onChangeBankingInfoForm(INPUT_NAME_OBJ.serviceName, value);
        }}
        classes={{
          root: classes.inputRoot,
        }}
      />
      <LabelInput
        label={getLabel(LangConstant.TXT_ACCOUNT_NUMBER)}
        isRequired={true}
        value={bankingInfoForm.accountNumber}
        onChange={event => {
          let value = event.target.value;
          onChangeBankingInfoForm(INPUT_NAME_OBJ.accountNumber, value);
        }}
        classes={{
          root: classes.inputRoot,
        }}
      />
      <LabelInput
        label={getLabel(LangConstant.TXT_ACCOUNT_OWNER)}
        isRequired={true}
        value={bankingInfoForm.accountOwner}
        onChange={event => {
          let value = event.target.value;
          onChangeBankingInfoForm(INPUT_NAME_OBJ.accountOwner, value);
        }}
        classes={{
          root: classes.inputRoot,
        }}
      />
      <LabelInput
        label={getLabel(LangConstant.TXT_BANKING_CONTENT)}
        isRequired={false}
        value={bankingInfoForm.bankingContent}
        onChange={event => {
          let value = event.target.value;
          onChangeBankingInfoForm(INPUT_NAME_OBJ.bankingContent, value);
        }}
        classes={{
          root: classes.inputRoot,
        }}
      />

      {isContainInvalidField && (
        <Typography className={clsx(classes.error, "semiBold-sm-txt")}>
          {getLabel(LangConstant.TXT_FORM_CONTAIN_EMPTY_FIELD)}
        </Typography>
      )}

      <Box className={classes.dialogAction}>
        <Button
          className={clsx(classes.actionButton, classes.cancelButton, "medium-md-txt")}
          variant="contained"
          onClick={onCloseDialog}
        >
          {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
        </Button>
        <Button
          className={clsx(classes.actionButton, classes.saveButton, "medium-md-txt")}
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          {getLabel(getCommonKey(LangConstant.TXT_SHOP_SAVE))}
        </Button>
      </Box>
    </PrimaryDialog>
  );
};

const formatBankingInfo = form => {
  return form && form.serviceName && form.accountNumber && form.accountOwner;
};

DialogAddBankingInfo.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,

  formData: PropTypes.shape({
    serviceName: PropTypes.string,
    accountNumber: PropTypes.string,
    accountOwner: PropTypes.string,
    bankingContent: PropTypes.string,
  }),

  isShow: PropTypes.bool,
  isEditing: PropTypes.bool,
};

DialogAddBankingInfo.defaultProps = {
  onChange: () => {},
  onClose: () => {},

  formData: {
    serviceName: "",
    accountNumber: "",
    accountOwner: "",
    bankingContent: "",
  },

  isShow: false,
  isEditing: false,
};

const DEFAULT_INPUT_VALUE = {
  serviceName: "",
  accountNumber: "",
  accountOwner: "",
  bankingContent: "",
};

const INPUT_NAME_OBJ = {
  serviceName: "serviceName",
  accountNumber: "accountNumber",
  accountOwner: "accountOwner",
  bankingContent: "bankingContent",
};

export default DialogAddBankingInfo;

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    maxWidth: "unset",
    borderRadius: 5,
  },

  title: {
    backgroundColor: theme.palette.secondary.dark,
    padding: "18px 24px",
    paddingRight: 10,
    color: theme.palette.white,
    display: "flex",
    alignItems: "center",
  },

  closeIcon: {
    fontSize: 26,
    color: theme.palette.white,
    padding: 2,
  },

  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "26px",
    marginRight: 5,
    color: theme.palette.white,
  },

  dialogContent: {
    padding: "36px 24px",
    paddingBottom: 0,
  },

  inputRoot: {
    marginBottom: 16,
  },

  dialogAction: {
    padding: "20px 0",
    display: "flex",
    justifyContent: "flex-end",
  },

  actionButton: {
    height: 30,
    minHeight: "unset",
    color: theme.palette.white,
    borderRadius: 4,
    textTransform: "none",
    padding: "3px 16px",
  },

  cancelButton: {
    backgroundColor: "#B3B5BA",
    marginRight: 8,
  },

  error: {
    color: theme.palette.primary.main,
    margin: "8px 0",
    width: "100%",
    textAlign: "right",
  },
}));
