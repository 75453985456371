import React, { memo } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, makeStyles, DialogTitle } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const DialogDelete = ({ uid, isShow, title, content, onCancel, onConfirm }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const onConfirmDelete = event => {
    onConfirm(event, uid);
  };

  return (
    <Dialog open={isShow} classes={{ paper: classes.paperDialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography classes={{ body1: classes.textDialogTitle }}>{title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.textDialogContent}>{content}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.button} onClick={onCancel}>
          {getLabel(LangConstant.TXT_SHOP_CANCEL)}
        </Button>
        <Button className={classes.button} onClick={onConfirmDelete}>
          {getLabel(LangConstant.TXT_CONFIRM)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  paperDialog: {
    width: 470,
    minHeight: 188,
    objectFit: "contain",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.white,
    borderRadius: 0,
  },
  dialogTitle: {
    padding: "24px 24px 16px",
  },
  textDialogTitle: {
    fontSize: 20,
    color: "#3b404c",
  },
  dialogContent: {
    padding: "0 24px 24px",
    display: "flex",
    flexDirection: "column",
  },
  textDialogContent: {
    fontSize: 13,
    color: "#6c7078",
  },
  dialogActions: {
    height: 53,
    borderTop: "1px solid #ebeced",
    padding: "15px 35px",
  },
  button: {
    color: "#9d9fa5",
    fontSize: 14,
    fontWeight: 600,
  },
}));
DialogDelete.propTypes = {
  uid: PropTypes.string,
  isShow: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default memo(DialogDelete);
