import { call, put } from "redux-saga/effects";
import { deepCloneJsonObject, toCamel, toSnake } from "utils";
import { ApiConstant } from "const";
import MnProductRedux from "redux/mn-product.redux";
import PaymentAction from "redux/payment.redux";
import { MnProduct, MnDetails } from "../services";

//product category
export function* getProductCategory(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.getProductCategory, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.getProductCategorySuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.getProductCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.getProductCategoryFailure(error));
  }
}

export function* createProductCategory(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.createProductCategory, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.createProductCategorySuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.createProductCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.createProductCategoryFailure(error));
  }
}

export function* updateProductCategory(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.updateProductCategory, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.updateProductCategorySuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.updateProductCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.updateProductCategoryFailure(error));
  }
}

export function* removeProductCategory(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.removeProductCategory, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.removeProductCategorySuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.removeProductCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.removeProductCategoryFailure(error));
  }
}

//Product item

export function* getProductItem(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.getProductItem, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.getProductItemSuccess(formatGroupModelProduct(toCamel(responseData))));
    } else {
      yield put(MnProductRedux.getProductItemFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.getProductItemFailure(error));
  }
}

export function* getProductItemUidCategory(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.getProductItemUidCategory, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.getProductItemUidCategorySuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.getProductItemUidCategoryFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.getProductItemUidCategoryFailure(error));
  }
}

export function* createProductItem(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.createProductItem, toSnake(data));
    let responseData = response;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.createProductItemSuccess(toCamel(responseData)));
    } else if (response.status === ApiConstant.STT_UN_PROCESSABLE_ENTITY) {
      yield put(PaymentAction.getUnProcessableEntity());
    } else {
      yield put(MnProductRedux.createProductItemFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.createProductItemFailure(error));
  }
}

export function* updateProductItem(action) {
  try {
    const { data } = action.data;
    let response = yield call(MnProduct.updateProductItem, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.updateProductItemSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.updateProductItemFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.updateProductItemFailure(error));
  }
}

export function* removeProductItem(action) {
  try {
    const { data } = action;
    let response = yield call(MnProduct.removeProductItem, data);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.removeProductItemSuccess(responseData));
    } else {
      yield put(MnProductRedux.removeProductItemFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.removeProductItemFailure(error));
  }
}

// details
export function* getDetails(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.getDetails, data);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.getDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.getDetailsFailure(responseData));
    }
  } catch (error) {
    yield put(MnProductRedux.getDetailsFailure(error));
  }
}

export function* getDetailsUid(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.getDetailsUid, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.getDetailsUidSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.getDetailsUidFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.getDetailsUidFailure(error));
  }
}

export function* createDetails(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.createDetails, toSnake(data));
    let responseData = response;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.createDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.createDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.createDetailsFailure(error));
  }
}

export function* updateDetails(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.updateDetails, toSnake(data));
    let responseData = response;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.updateDetailsSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.updateDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.updateDetailsFailure(error));
  }
}

export function* updateDetailsEditContent(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.updateDetailsEditContent, toSnake(data));
    let responseData = response;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.updateDetailsEditContentSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.updateDetailsEditContentFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.updateDetailsEditContentFailure(error));
  }
}

export function* removeDetails(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.removeDetails, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.removeDetailsSuccess(responseData));
    } else {
      yield put(MnProductRedux.removeDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.removeDetailsFailure(error));
  }
}

// details content

export function* getDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.getDetailsContent, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.getDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.getDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.getDetailsContentFailure(error));
  }
}

export function* getDetailsContentUid(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.getDetailsContentUid, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.getDetailsContentUidSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.getDetailsContentUidFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.getDetailsContentUidFailure(error));
  }
}

export function* createDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.createDetailsContent, toSnake(data));
    let responseData = response;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.createDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.createDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.createDetailsContentFailure(error));
  }
}

export function* updateDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.updateDetailsContent, toSnake(data));
    let responseData = response;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.updateDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.updateDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.updateDetailsContentFailure(error));
  }
}

export function* removeDetailsContent(action) {
  try {
    const { data } = action;
    let response = yield call(MnDetails.removeDetailsContent, toSnake(data));
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(MnProductRedux.removeDetailsContentSuccess(toCamel(responseData)));
    } else {
      yield put(MnProductRedux.removeDetailsContentFailure(response.data));
    }
  } catch (error) {
    yield put(MnProductRedux.removeDetailsContentFailure(error));
  }
}

const formatGroupModelProduct = data => {
  // TODO optimal logic
  let newData = deepCloneJsonObject(data);
  newData.data.forEach(dataProduct => {
    if (Boolean(dataProduct.groupModel)) {
      if (Boolean(dataProduct.groupModel.modelList[0].item)) {
        dataProduct.classificationList = dataProduct.groupModel.modelList;
      } else {
        let arrData = [];
        dataProduct.groupModel.modelList.forEach(dataModel => {
          dataModel.groupModel.modelList.forEach(dataItem => {
            arrData.push(dataItem);
          });
        });
        dataProduct.classificationList = arrData;
      }
    }
    return dataProduct;
  });

  return newData;
};
