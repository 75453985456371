import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  makeStyles,
  withStyles,
  Popover,
  AppBar,
  Menu,
  MenuItem,
  Avatar,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import clsx from "clsx";
import { format as StringFormat } from "react-string-format";
import { Comment, MoreHoriz, Person } from "@material-ui/icons";
import { AppConstant, LangConstant } from "const/index";
import { Processing } from "components";
import { getCreatedTime } from "utils/date";
import Alert from "components/dialog/Alert";
import { getCommonKey, getAttachmentUrl, onDoNothing } from "utils";
import CustomButton from "pages/MnReservation/components/Reservation/CustomButton";

const Reservation = ({ data, dataSetting }) => {
  const classes = useStyles();
  const role = Cookie.get(AppConstant.ROLE);
  const { status } = data;
  const reservationRef = useRef(null);
  const statusDialogRef = useRef(null);
  const internalNoteActionRef = useRef(null);
  const { t: getLabel } = useTranslation(LangConstant.NS_MANAGE_BOOKING);

  const [anchorEl] = useState(null);
  const [internalNoteActionAnchor] = useState(null);
  const [noteBoxAnchor] = useState(null);
  const [openNoteBox] = useState(false);

  const [isProcessing] = useState(false);

  const [dataDisplayState, setDataDisplayState] = useState({
    isShowService: {},
    isShowStaff: {},
    isShowPlace: {},
    isShowConsumerNumber: {},
  });
  const [isEditingInternalNote] = useState(false);
  const [isShowErr, setIsShowErr] = useState(false);
  const [internalNoteContent, setInternalNoteContent] = useState(
    data.internalNote && data.internalNote.content && data.internalNote.content,
  );

  let isPopup = Boolean(anchorEl);
  let isHasInternalNote = data.internalNote && data.internalNote.content;

  useEffect(() => {
    if (dataSetting?.length > 0) {
      setDataDisplayState({
        isShowService: dataSetting[0].isShow,
        isShowStaff: dataSetting[2].isShow,
        isShowPlace: dataSetting[1].isShow,
        isShowConsumerNumber: dataSetting[3].isShow,
      });
    }
  }, [dataSetting]);

  return (
    <>
      <Card
        className={clsx(classes.root, isPopup && classes.highlight)}
        ref={reservationRef}
        id={`${isPopup && SELECTED_BOOKING_ID}`}
        onClick={onDoNothing}
      >
        <Box className={classes.reservationBoxHeader}>
          <Header title={data.code && data.code} onClick={onDoNothing} />

          {Boolean(isHasInternalNote) && <Comment className={classes.addNoteIcon} onClick={onDoNothing} />}
        </Box>

        <Content onClick={onDoNothing} ref={statusDialogRef}>
          <Typography variant="body1" classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}>
            {data?.name || ""}
          </Typography>

          {dataDisplayState?.isShowService && (
            <Typography variant="body1" classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}>
              {data?.service?.name || ""}
            </Typography>
          )}

          {dataDisplayState?.isShowStaff && (
            <Typography variant="body1" classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}>
              {data?.staff?.name || ""}
            </Typography>
          )}

          {dataDisplayState?.isShowPlace && (
            <Typography variant="body1" classes={{ body1: "medium-sm-txt ellipsis", root: classes.typographyRoot }}>
              {data?.assign?.name || ""}
            </Typography>
          )}
        </Content>

        <Actions disableSpacing onClick={onDoNothing}>
          <Box className={classes.iconBox}>
            {dataDisplayState?.isShowConsumerNumber && (
              <>
                <Person fontSize="inherit" color="inherit" />
                <Typography variant="body1" classes={{ body1: "semiBold-sm-txt" }} color="inherit">
                  {data.totalConsumer}
                </Typography>
              </>
            )}
          </Box>
          <CustomButton onClick={onDoNothing} status={status} />
        </Actions>
      </Card>
      <Alert
        isShow={isShowErr}
        onClose={() => setIsShowErr(false)}
        type="error"
        message={getLabel(getCommonKey(LangConstant.ERR_INVALID_DAY_OR_TIME))}
      />

      {data.internalNote && (
        <Popover
          open={openNoteBox}
          anchorEl={noteBoxAnchor}
          onClose={onDoNothing}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.addInternalNotePaper }}
        >
          <Card className={classes.addNoteDialog}>
            <AppBar className={classes.addNoteDialogTitle}>
              <CardHeader
                avatar={
                  <Avatar className={classes.interNoteCreatorAvatar} src={getAttachmentUrl(data.internalNote.avatar)}>
                    {data.internalNote.createdBy && data.internalNote.createdBy}
                  </Avatar>
                }
                title={data.internalNote.createdBy && data.internalNote.createdBy}
                classes={{
                  root: classes.rootHeader,
                  action: classes.actionHeader,
                  content: classes.contentHeader,
                  title: `${classes.titleHeader} medium-md-txt`,
                }}
                action={
                  role !== AppConstant.SHOP_STAFF && (
                    <MoreHoriz ref={internalNoteActionRef} className={classes.menuIcon} onClick={onDoNothing} />
                  )
                }
              />
              {role !== AppConstant.SHOP_STAFF && (
                <Menu
                  anchorEl={internalNoteActionAnchor}
                  open={Boolean(internalNoteActionAnchor)}
                  onClose={onDoNothing}
                  classes={{ paper: classes.interNoteActionMenu }}
                >
                  <MenuItem className={classes.interNoteAction} onClick={onDoNothing}>
                    {getLabel(getCommonKey(LangConstant.TXT_EDIT))}
                  </MenuItem>
                  <MenuItem className={classes.interNoteAction} onClick={onDoNothing}>
                    {getLabel(getCommonKey(LangConstant.TXT_DELETE))}
                  </MenuItem>
                </Menu>
              )}
            </AppBar>
            <CardContent className={classes.addNoteDialogContent}>
              {isEditingInternalNote ? (
                <OutlinedInput
                  classes={{ root: classes.internalNoteContent, input: classes.inputNoteTextField }}
                  multiline={true}
                  value={internalNoteContent}
                  onChange={event => {
                    setInternalNoteContent(event.target.value);
                  }}
                />
              ) : (
                <Typography className={classes.internalNoteContent}>
                  {data.internalNote.content && data.internalNote.content}
                </Typography>
              )}
            </CardContent>
            {isEditingInternalNote && (
              <Box className={classes.rootFooter}>
                <Button
                  onClick={onDoNothing}
                  classes={{ root: classes.buttonCancelFooter, label: classes.labelFooter }}
                >
                  {getLabel(getCommonKey(LangConstant.TXT_SHOP_CANCEL))}
                </Button>
                <Button
                  onClick={onDoNothing}
                  classes={{
                    root: classes.buttonRootFooter,
                    label: classes.labelFooter,
                  }}
                >
                  {getLabel(getCommonKey(LangConstant.TXT_SAVE_BUTTON))}
                </Button>
              </Box>
            )}
            <Box className={classes.internalNoteMilestone}>
              {StringFormat(
                getLabel(LangConstant.TXT_INTERNAL_NOTE_EDIT_MILESTONE),
                getCreatedTime(
                  data.internalNote.modified && new Date(data.internalNote.modified * 1000),
                  getLabel,
                ).toLowerCase(),
                data.internalNote.updatedBy && data.internalNote.updatedBy,
              )}
            </Box>
          </Card>
        </Popover>
      )}
      {isPopup && <Box className={classes.bgPopup} />}
      <Processing isShow={isProcessing} />
    </>
  );
};

Reservation.propTypes = { data: PropTypes.object, placeCategory: PropTypes.array, dataSetting: PropTypes.array };
Reservation.defaultProps = { data: { status: AppConstant.STT_RESERVATION.pending }, dataSetting: null };

export default memo(Reservation);

const SELECTED_BOOKING_ID = "selectedBookingDetail";

const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    width: "100%",
    height: "100%",
    position: "relative",
    maxWidth: "170px",
    background: "#f1f3f6",
    padding: "5px",
    borderRadius: "unset",
    boxShadow: "none",
    overflow: "auto",
    cursor: "zoom-in",
    gridTemplateRows: "min-content",
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    color: "#787878",
    "&>*:first-child": {
      marginRight: "8px",
    },
  },
  bgPopup: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.grey[800],
    opacity: 0.5,
    zIndex: theme.zIndex.drawer,
  },
  highlight: {
    zIndex: theme.zIndex.drawer + 10,
  },
  typographyRoot: {},
  addNoteIcon: {
    fontSize: 17,
    marginRight: 8,
    color: "#447aff",
    cursor: "pointer",
  },
  reservationBoxHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addNoteDialog: {
    margin: "auto",
    position: "relative",
    height: "auto",
    width: 375,
    minHeight: 125,
    "@media only screen and (max-width: 500px)": {
      width: 300,
    },
  },
  addNoteDialogTitle: {
    position: "relative",
    backgroundColor: theme.palette.white,
  },
  addNoteDialogContent: {
    display: "grid",
    padding: "15px 24px",
  },
  menuIcon: {
    padding: 0,
    cursor: "pointer",
    color: theme.palette.grey[500],
  },
  rootHeader: {
    position: "relative",
    padding: "20px 24px",
    color: theme.palette.white,
    height: 54,
    marginBottom: 1,
  },
  rootTabs: {
    minHeight: 38,
    height: 38,
  },
  contentHeader: {
    flex: "none",
  },
  actionHeader: {
    marginTop: 0,
    marginLeft: "auto",
    marginRight: 0,
  },
  titleHeader: {
    color: "#3e4045",
    "@media only screen and (max-width: 375px)": {
      fontSize: 16,
    },
  },
  internalNoteContent: {
    fontSize: 13,
    fontWeight: "normal",
  },
  internalNoteContentDisabled: {
    fontSize: 13,
    fontWeight: "normal",
    border: "none",
    padding: 0,
  },
  addInternalNotePaper: {
    borderRadius: "4px",
  },
  interNoteActionMenu: {
    marginTop: 30,
    marginLeft: -40,
  },
  interNoteAction: {
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.white,
    },
  },
  interNoteCreatorAvatar: {
    backgroundColor: theme.palette.black,
  },
  inputNoteTextField: {
    maxHeight: 70,
    overflow: "auto !important",
  },
  internalNoteMilestone: {
    padding: "15px 24px",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 10,
    color: "#d4d5d8",
    fontWeight: "normal",
  },
  buttonCancelFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.black,
  },
  labelFooter: {
    width: "100%",
    height: 24,
    marginTop: 3,
    marginBottom: 3,
  },
  buttonRootFooter: {
    padding: "3px 16px",
    minHeight: 30,
    fontWeight: "inherit",
    marginLeft: 8,
    height: 30,
    borderRadius: 2,
    textTransform: "none",
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      opacity: 0.8,
      color: "unset",
    },
  },
  rootFooter: {
    right: 10,
    textAlign: "right",
    width: "100%",
    padding: "0 24px",
    "@media only screen and (max-width: 375px)": {
      position: "fixed",
      bottom: 10,
      padding: "0px 24px",
    },
  },
}));

const Header = withStyles(theme => ({
  root: {
    padding: 5,
    width: "fill-available",
  },
  title: {
    fontSize: "13px",
    fontWeight: "600",
    color: theme.palette.grey[800],
    lineHeight: "normal",
  },
}))(CardHeader);

const Content = withStyles({
  root: {
    width: "100%",
    padding: "0px 5px 0px 5px",
    marginBottom: 1,
    "&>*": {
      lineHeight: "20px",
      color: "#787878",
    },
    "&:last-child": {
      paddingBottom: 0,
    },
  },
})(CardContent);

const Actions = withStyles({
  root: {
    padding: 0,
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  spacing: {},
})(CardActions);
