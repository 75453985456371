import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { InputAdornment, makeStyles, IconButton } from "@material-ui/core";
import { EventAvailableOutlined, ArrowDropDownOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { LangConstant } from "../../../const";
import { getDateLang } from "../../../utils/date";

const InputDate = ({ onChange, placeholder, date }) => {
  const classes = useStyles();
  const { t: getLabel, i18n } = useTranslation();
  const dateLang = getDateLang(i18n.language);

  const [selectedDate, setSelectedDate] = useState(date || new Date());

  const onChangeDate = date => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date);
    }
  };

  useEffect(() => {
    if (date) setSelectedDate(date);
  }, [date]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLang}>
      <DatePicker
        autoOk
        disablePast
        variant="inline"
        format={DISPLAY_FORMAT_DATE}
        value={selectedDate}
        onChange={onChangeDate}
        inputVariant="outlined"
        InputProps={{
          placeholder: placeholder || getLabel(LangConstant.P_SELECT_DATE),
          startAdornment: (
            <InputAdornment position="start" className={classes.firstIcon}>
              <EventAvailableOutlined />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton edge="end" className={classes.secondaryIcon}>
              <ArrowDropDownOutlined color="inherit" />
            </IconButton>
          ),
          classes: {
            input: classes.input,
            root: classes.inputRoot,
          },
        }}
        className={classes.root}
      />
    </MuiPickersUtilsProvider>
  );
};

InputDate.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  date: PropTypes.any,
};

export default memo(InputDate);

const DISPLAY_FORMAT_DATE = "eeee dd/MM";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  inputRoot: {
    width: "100%",
    paddingLeft: 0,
    borderRadius: "unset",
    marginTop: "8px",
    marginBottom: "8px",
  },
  input: { padding: "16px 8px", cursor: "pointer" },
  firstIcon: {
    minWidth: "50px",
    width: "50px",
    height: "100%",
    maxHeight: "100%",
    minHeight: "50px",
    background: theme.palette.grey[200],
    justifyContent: "center",
    borderRight: "solid 0.5px #cecfd2",
    marginRight: 0,
  },
  secondaryIcon: {
    color: theme.palette.grey[400],
    padding: 4,
  },
}));
