import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Paper, Typography, Button, Box, Grid, IconButton, Dialog, Slide } from "@material-ui/core";
import { LangConstant } from "const";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import ListPagesFacebook from "./ListPagesFacebook";
import ShopAction from "redux/shop.redux";
import LinkFacebookPage from "components/social/LinkFacebookPage";
import WorkingTimeDialog from "./WorkingTimeDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BasicInfo = ({ basicData }) => {
  const pages = useSelector(state => state.shopRedux.facebookPage);
  const isPutFacebookLinkSuccess = useSelector(state => state.shopRedux.isPutFacebookLinkSuccess);
  const isSuccess = useSelector(state => state.shopRedux.isGetFacebookSuccess);
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const socialRef = useRef();

  const isExistWorkingTimeSetting = Boolean(basicData && basicData.details.workingTime?.length > 0);

  const [isLinked, setIsLinked] = useState();
  const [open, setOpen] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [linkedPage, setLinkedPage] = useState();
  const [openSettingTime, setOpenSettingTime] = useState();

  const onClickOpenDialog = () => {
    setOpen(!open);
  };

  const onClickCloseDialog = () => {
    setOpen(!open);
  };

  const onOpenSettingWorkingTime = () => {
    setOpenSettingTime(true);
  };

  useEffect(() => {
    dispatch(ShopAction.requestGetFacebookPage());
  }, []);

  useEffect(() => {
    if (isPutFacebookLinkSuccess) dispatch(ShopAction.requestGetFacebookPage());
  }, [isPutFacebookLinkSuccess]);

  useEffect(() => {
    if (isSuccess && pages) {
      if (pages.length !== 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    }
  }, [pages]);

  useEffect(() => {
    for (let indexSelected = 0; indexSelected < pages.length; indexSelected++) {
      if (pages[indexSelected].state === 1) {
        setIsLinked(!isLinked);
        setLinkedPage(pages[indexSelected]);
      }
    }
  }, [pages]);

  useEffect(() => {
    for (let indexSelected = 0; indexSelected < pages.length; indexSelected++) {
      if (pages[indexSelected].state === 1) {
        setIsLinked(!isLinked);
        setLinkedPage(pages[indexSelected]);
      }
    }
  }, [isSuccess, pages]);

  return (
    <Paper elevation={1} square className={classes.paper}>
      <Typography className="bold-lg-txt" classes={{ root: classes.header }}>
        {getLabel(LangConstant.TXT_ADVANCED_INFO_HEADER)}
      </Typography>
      <Box className={classes.box}>
        <Grid container>
          <Grid item xs={12} sm={4} classes={{ root: classes.gridItemLabel }}>
            <Typography className="medium-md-txt">{getLabel(LangConstant.TXT_wORKING_TIME)}</Typography>
          </Grid>
          <Grid item xs={12} sm={8} classes={{ root: classes.gridItemContent }}>
            <Button className={classes.buttonForm} onClick={onOpenSettingWorkingTime}>
              {isExistWorkingTimeSetting
                ? getLabel(LangConstant.TXT_CHANGING_SETTING_WORKING_TIME)
                : getLabel(LangConstant.TXT_SETTING_BUTTON)}
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} classes={{ root: classes.gridItemLabel }}>
            <Typography className="medium-md-txt">{getLabel(LangConstant.TXT_FACEBOOK_LINK)}</Typography>
          </Grid>
          <Grid item xs={12} sm={8} classes={{ root: classes.gridItemContent }}>
            {isLinked ? (
              <>
                <Typography component="p">
                  {linkedPage.name} &nbsp;&nbsp;
                  <Typography component="span">
                    <Typography component="a" onClick={onClickOpenDialog} className={classes.dismountFacebook}>
                      {getLabel(LangConstant.TXT_SHOP_FACEBOOK_DISMOUNT)}
                    </Typography>
                  </Typography>
                </Typography>
              </>
            ) : (
              <>
                <LinkFacebookPage
                  action={onClickOpenDialog}
                  ref={socialRef}
                  linkButton={
                    <Button
                      className={classes.buttonForm}
                      onClick={() => (isEmpty ? socialRef.current.openSocialLink() : setOpen(!open))}
                    >
                      {getLabel(LangConstant.TXT_LINK)}
                    </Button>
                  }
                />
              </>
            )}
          </Grid>
        </Grid>

        {openSettingTime && <WorkingTimeDialog open={openSettingTime} close={setOpenSettingTime} />}

        <Dialog fullScreen open={open} onClose={onClickCloseDialog} TransitionComponent={Transition}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClickCloseDialog}
            aria-label="close"
            className={classes.closeDialogBtn}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <ListPagesFacebook pages={pages} />
        </Dialog>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  box: {
    padding: "25px 60px 15px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 10px 0px",
    },
  },
  paper: {
    marginTop: 10,
    width: "100%",
    flexGrow: 1,
  },
  gridItemLabel: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  gridItemContent: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 30,
    },
  },
  header: {
    color: theme.palette.grey[800],
    padding: "20px 60px",
    borderBottom: "solid 1px #f1f3f6",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 10px 20px ",
    },
  },
  button: {
    textTransform: "unset",
    minWidth: 83,
    minHeight: 30,
    borderRadius: 20,
    margin: "60px auto 0",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonForm: {
    width: 112,
    backgroundColor: theme.palette.text.link,
    color: theme.palette.white,
    textTransform: "none",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    borderRadius: 2,
    "&:hover": {
      color: theme.palette.black,
    },
  },
  dismountFacebook: {
    color: theme.palette.text.link,
    textTransform: "none",
  },
  closeDialogBtn: {
    alignSelf: "flex-end",
    borderRadius: "50%",
    marginRight: "3.5vw",
    marginTop: "3.5vw",
  },
  closeIcon: {
    fontSize: "2.5rem",
  },
}));

BasicInfo.propTypes = {};

BasicInfo.defaultProps = {};

export default memo(BasicInfo);
