import { ApiConstant } from "const";
import { call, put } from "redux-saga/effects";
import GroupAdminAction from "redux/group-admin.redux";
import { GroupAdmin } from "services";
import { toCamel } from "utils";

export function* getGroupAdmin(action) {
  try {
    const { data } = action;
    let response = yield call(GroupAdmin.getGroupAdmin, data);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        GroupAdminAction.groupAdminSuccess({
          isSuccess: true,
          dataGroup: toCamel(responseData.data),
        }),
      );
    } else {
      yield put(GroupAdminAction.groupAdminFailure(responseData));
    }
  } catch (error) {
    yield put(GroupAdminAction.groupAdminFailure(error));
  }
}

export function* joinGroupAdmin(action) {
  try {
    const { data } = action;
    let response = yield call(GroupAdmin.joinGroupAdmin, data);
    let responseData = response.data;
    if (response.status === ApiConstant.STT_OK) {
      yield put(
        GroupAdminAction.groupAdminSuccess({
          isJoinGroupSuccess: true,
        }),
      );
    } else {
      yield put(GroupAdminAction.groupAdminFailure(responseData));
    }
  } catch (error) {
    yield put(GroupAdminAction.groupAdminFailure(error));
  }
}
