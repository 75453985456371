import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Paper,
  Typography,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  withStyles,
  Link,
  Box,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import { Email, Facebook, Phone, Place, Timelapse, Web } from "@material-ui/icons";
import { getShopWorkingTime } from "utils/date";
import { AppConstant, LangConstant } from "const";
import { getCommonKey } from "utils";

const ShopInfo = props => {
  const { shopData, containerId } = props;
  const { t: getLabel } = useTranslation(LangConstant.NS_BOOKING_SETUP_PREVIEW);
  const classes = useStyles();

  const [workingDays, setWorkingDays] = useState([]);
  const [location, setLocation] = useState();

  let boxRootEl = document.getElementById(SIDEBAR_BOX_ID);
  let scrollEl = document.getElementById(containerId);

  const scrollControl = () => {
    let pixelUntilStickySideBar = scrollEl.clientHeight - boxRootEl.offsetHeight;
    boxRootEl.style.setProperty("top", pixelUntilStickySideBar < 0 ? `${pixelUntilStickySideBar}px` : "10px");
  };

  useEffect(() => {
    if (shopData && Boolean(shopData.id)) {
      if (shopData.details && shopData.details.location) {
        let newLocation = convertLocation(shopData.details.location);
        if (newLocation) setLocation(newLocation);
      }
      let workingDaysListByText = [];
      let workingDaysByIndex = [];
      let notWorkingList = [...LangConstant.ARR_DAY_OF_WEEK];
      if (shopData.details && shopData.details.workingTime) {
        shopData.details.workingTime.forEach(workingTimeElement => {
          workingTimeElement.days.forEach(workingDayIndex => {
            let selectedDay = LangConstant.ARR_DAY_OF_WEEK[workingDayIndex];
            workingDaysListByText.push(selectedDay);
            workingDaysByIndex.push({
              day: workingDayIndex,
              open: workingTimeElement.open,
              close: workingTimeElement.close,
              name: LangConstant.ARR_DAY_OF_WEEK[workingDayIndex],
            });

            let notWorkingDayIndex = notWorkingList.indexOf(selectedDay);

            if (notWorkingDayIndex >= 0) {
              notWorkingList.splice(notWorkingDayIndex, 1);
            }
          });
        });
      }
      setWorkingDays(workingDaysByIndex);
    }
  }, [shopData]);

  useEffect(() => {
    if (boxRootEl) {
      let numberExecuting = 1;
      const addEventScroll = () => {
        if (scrollInterval) {
          scrollEl = document.getElementById(containerId);

          if (scrollEl) {
            // Add event scrolling
            scrollEl.addEventListener("scroll", scrollControl);
            clearInterval(scrollInterval);
          }
          if (numberExecuting > 6) {
            clearInterval(scrollInterval);
          }
          numberExecuting++;
        }
      };

      var scrollInterval = setInterval(addEventScroll, 1500);

      // When close this screen, reset function
      return () => {
        if (scrollEl) scrollEl.removeEventListener("scroll", scrollControl);
        clearInterval(scrollInterval);
      };
    }
  }, [containerId, boxRootEl]);

  const primaryInfo = [
    {
      icon: <Timelapse color="inherit" />,
      text: (
        <>
          {LangConstant.ARR_DAY_OF_WEEK.map(day =>
            workingDays.find(workDay => workDay.name === day) ? (
              <Typography variant="body2" component="p" className={classes.txtDate} key={day}>
                {getLabel(getCommonKey(day))} : {getShopWorkingTime(workingDays.find(workDay => workDay.name === day))}
              </Typography>
            ) : (
              <Typography variant="body2" component="p" className={classes.closedDay} key={day}>
                {getLabel(getCommonKey(day))} : {getLabel(getCommonKey(LangConstant.TXT_CLOSED))}
              </Typography>
            ),
          )}
        </>
      ),
    },

    {
      icon: <Phone color="inherit" />,
      text: (
        <Typography variant="body2" component="p">
          {shopData.phoneNumber}
        </Typography>
      ),
    },

    {
      icon: <Place color="inherit" />,
      text: (
        <Typography variant="body2" component="p">
          {shopData.address}
        </Typography>
      ),
    },

    {
      icon: <Web color="inherit" />,
      text: (
        <Link href={shopData.website} target="_blank" rel="noopener noreferrer">
          <Typography variant="body2" component="p" color="inherit" className="eclipse">
            {shopData.website}
          </Typography>
        </Link>
      ),
    },

    {
      icon: <Facebook color="inherit" />,
      text: (
        <Link href={shopData.facebook} target="_blank" rel="noopener noreferrer">
          <Typography variant="body2" component="p" color="inherit" className="eclipse">
            {shopData.facebook}
          </Typography>
        </Link>
      ),
    },

    {
      icon: <Email color="inherit" />,
      text: (
        <Typography variant="body2" component="p">
          {shopData.email}
        </Typography>
      ),
    },
  ];

  return (
    <Box className={classes.root} id={SIDEBAR_BOX_ID}>
      <Paper className={classes.paperRoot}>
        <Typography variant="h6" component="p">
          {getLabel(LangConstant.TXT_INFORMATION)}
        </Typography>
        {primaryInfo.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon color="inherit">{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        {location && (
          <Box className={classes.map}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: AppConstant.GOOGLE_API_KEY }}
              defaultCenter={location}
              defaultZoom={15}
            >
              <Marker lat={location.lat} lng={location.lng} text={shopData.name} />
            </GoogleMapReact>
          </Box>
        )}
      </Paper>
      {shopData.otherInformation && (
        <Paper className={classes.paperRoot}>
          <Typography variant="h6" component="p">
            {getLabel(LangConstant.TXT_OTHER_INFORMATION)}
          </Typography>
          <Typography className={classes.otherInformation} variant="body2" component="p" color="inherit">
            {shopData.otherInformation}
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

ShopInfo.propTypes = {
  setIsNewsTab: PropTypes.func,

  shopData: PropTypes.object.isRequired,
  containerId: PropTypes.string,
  isNewsTab: PropTypes.bool,
};

const SIDEBAR_BOX_ID = "sidebarInfoBox";

export const SIDEBAR_MARGIN = 24;
export const SIDEBAR_MARGIN_MB = 16;

export default memo(ShopInfo);

const useStyles = makeStyles(theme => ({
  root: {
    position: "sticky",
    display: "flex",
    flexDirection: "column",
  },
  paperRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: SIDEBAR_MARGIN,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
    "&>*:first-child": {
      width: "100%",
      marginBottom: SIDEBAR_MARGIN_MB,
    },
    marginBottom: SIDEBAR_MARGIN_MB,
  },
  map: {
    width: "100%",
    height: 210,
    marginTop: SIDEBAR_MARGIN,
    marginBottom: SIDEBAR_MARGIN,
  },
  txtDate: {
    marginBottom: 7,
    color: theme.palette.grey[800],
  },
  closedDay: {
    marginBottom: 7,
    color: "#feba40",
  },
  bookingBtn: {
    textTransform: "none",
    borderRadius: 4,
    letterSpacing: 0.1,
    lineHeight: 1.75,
  },
  otherInformation: {
    width: "100%",
  },
}));

const ListItem = withStyles({
  gutters: {
    alignItems: "start",
    padding: 0,
    minHeight: 43,
  },
})(MuiListItem);

const ListItemIcon = withStyles(theme => ({
  root: {
    minWidth: 30,
    color: theme.palette.grey[600],
  },
}))(MuiListItemIcon);

const Marker = props => {
  const { text } = props;
  return (
    <Tooltip
      arrow
      placement="top"
      PopperProps={{
        disablePortal: true,
      }}
      open={true}
      title={text}
    >
      <Place color="primary" fontSize="large" />
    </Tooltip>
  );
};
Marker.propTypes = { text: PropTypes.string };

const convertLocation = jsonLocation => {
  const { lat, lng } = jsonLocation;
  let result;
  if (lat && lng) {
    result = {};
    result.lat = parseFloat(lat);
    result.lng = parseFloat(lng);
  }
  return result;
};
