import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getCheckPayment: [],
  getUnProcessableEntity: [],
  getPackageCurrent: ["data"],
  getPackage: ["data"],
  getPaymentHistory: ["data"],
  getPaymentDetails: ["data"],

  getPaymentSuccess: ["data"],
  getPaymentFailure: ["data"],
  resetPayment: [],
});

export const PaymentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isSuccess: false,
  isLimitItem: false,

  checkPayment: {
    paymentUid: "",
    expiryDate: null,
    type: null,
  },

  packageCurrent: null,
  package: [],
  paymentHistory: {
    data: [],
    page: 1,
    size: 10,
    paging: 1,
  },
  paymentDetails: null,
};

/* ------------- Reducers Get Shop info data ------------- */

export const request = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const success = (state = INITIAL_STATE, action) => {
  let data = action.data || {};
  return {
    ...state,
    isFetching: false,
    isSuccess: true,
    ...data,
  };
};

export const failure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isSuccess: false,
  error: action.error,
});

export const limit = (state = INITIAL_STATE) => ({
  ...state,
  isLimitItem: true,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isSuccess: false,
  isLimitItem: false,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_CHECK_PAYMENT]: request,
  [Types.GET_PACKAGE_CURRENT]: request,
  [Types.GET_PACKAGE]: request,
  [Types.GET_PAYMENT_HISTORY]: request,
  [Types.GET_PAYMENT_DETAILS]: request,

  [Types.GET_PAYMENT_SUCCESS]: success,
  [Types.GET_PAYMENT_FAILURE]: failure,
  [Types.GET_UN_PROCESSABLE_ENTITY]: limit,
  [Types.RESET_PAYMENT]: reset,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
