import "date-fns";
import React, { memo, useEffect, useRef, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, OutlinedInput, Popover, IconButton, Box } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getDateLang } from "utils/date";
import { AppConstant } from "const";
import { format as formatDate } from "date-fns";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

const DatePickerReservation = ({ onChange, selectedDateProp, isUseEffect, ...otherProps }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(selectedDateProp);
  const [isOpen, setIsOpen] = useState(false);

  const calendarRef = useRef();
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  useEffect(() => {
    if (isUseEffect) setSelectedDate(selectedDateProp);
  }, [selectedDateProp]);

  const onDateChange = date => {
    setSelectedDate(date);
    onChange(date);
    setIsOpen(false);
  };

  const onClick = event => {
    event.preventDefault();
    setIsOpen(!isOpen);
    setCalendarAnchor(calendarRef.current);
  };

  return (
    <>
      <Box className={classes.boxRoot} ref={calendarRef}>
        <OutlinedInput
          classes={{ root: classes.inputRoot, adornedEnd: classes.adornedEnd }}
          value={formatDate(selectedDate, AppConstant.FM_DD_MM_YYYY)}
          endAdornment={
            <IconButton onClick={e => onClick(e)} className={classes.arrowButton} id={ADD_BOOKING_CALENDAR_OPEN_BUTTON}>
              {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          }
        />
      </Box>
      <Popover
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classes={{ paper: classes.datepickerPaper }}
      >
        <Box className="datepicker-root">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateLang(i18n.language)}>
            <DatePicker
              disableToolbar
              variant="static"
              format={AppConstant.FM_DD_MM_YYYY}
              value={selectedDate}
              onChange={onDateChange}
              className={`${classes.datePickerRoot} datepicker-root`}
              {...otherProps}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  inputRoot: {
    lineHeight: "22px",
    fontSize: 13,
    fontWeight: 400,
    width: 125,
    height: 30,
    color: theme.palette.text.link,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  datepickerPaper: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  datePickerRoot: {
    pointerEvents: `none`,
  },
  iconRoot: {
    pointerEvents: `none`,
  },
  boxRoot: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  hiddenArrow: {
    display: "none",
  },
  readOnlyTimePicker: {
    color: theme.palette.grey[500],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "0px",
    height: "100%",
    borderBottom: "1px solid #d4d5d8",
  },
  arrowButton: {
    padding: 0,
    width: 30,
    height: "100%",
    borderLeft: "1px solid #d4d5d8",
    borderRadius: "unset",
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.white,
  },
  adornedEnd: {
    paddingRight: 0,
  },
}));

DatePickerReservation.propTypes = {
  onChange: PropTypes.func,
  isUseEffect: PropTypes.bool,
  selectedDateProp: PropTypes.objectOf(Date),
};

DatePickerReservation.defaultProps = {
  isUseEffect: false,
  selectedDateProp: new Date(),
};
const ADD_BOOKING_CALENDAR_OPEN_BUTTON = "add-booking-calendar-open-button";

export default memo(DatePickerReservation);
