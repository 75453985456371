import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import StringFormat from "string-format";
import clsx from "clsx";
import { LangConstant, AppConstant } from "const";
import { convertTimeStampToDate } from "utils/date";
import { Status } from "pages/PaymentHistory/components";

const MyPayment = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation(LangConstant.NS_PLAN_PAYMENT);

  const paymentDetails = useSelector(({ paymentRedux }) => paymentRedux.paymentDetails, shallowEqual);

  return (
    <Box className={classes.cellItem}>
      <Typography className={clsx("semiBold-lg-txt", classes.titleCell)}>
        {getLabel(LangConstant.TXT_MY_PAYMENT)}
      </Typography>
      <Box className={classes.boxLine}>
        <Typography className={clsx("medium-md-txt", classes.titleCell)}>
          {`${getLabel(LangConstant.TXT_TB_PAYMENT_ID)}:`}
        </Typography>
        <Box marginLeft="4px">
          <Typography className={clsx("medium-md-txt", classes.shopColor)}>{paymentDetails?.code || ""}</Typography>
        </Box>
      </Box>
      <Typography className={clsx("medium-md-txt", classes.contentCell)}>
        {StringFormat(
          FM_CONTENT,
          `${getLabel(LangConstant.TXT_TB_PAYMENT_DATE)}:`,
          paymentDetails?.paymentDate
            ? convertTimeStampToDate(paymentDetails.paymentDate, AppConstant.FM_DD_MM_YYYY)
            : "",
        )}
      </Typography>
      <Box className={clsx(classes.centerStatus, classes.boxLine)}>
        <Typography className={clsx("medium-md-txt", classes.contentCell, classes.titleStatus)}>
          {getLabel(LangConstant.TXT_PLAN_STATUS)}
        </Typography>
        <Box marginLeft="4px">{paymentDetails?.status && <Status status={paymentDetails.status} />}</Box>
      </Box>
    </Box>
  );
};

const FM_CONTENT = "{0} {1}";

export default MyPayment;

const useStyles = makeStyles(theme => ({
  cellItem: {
    width: `calc(${100 / 3}%)`,
    minWidth: 300,
    paddingBottom: 30,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  titleCell: {
    lineHeight: 1.5,
    color: "#3e4045",
    marginBottom: 24,
  },
  contentCell: {
    lineHeight: 1.57,
    color: "#3e4045",
    marginBottom: 22,
  },
  boxLine: {
    display: "flex",
    alignItems: "start",
    minHeight: 30,
  },
  shopColor: {
    lineHeight: 1.57,
    color: theme.palette.text.link,
  },
  titleStatus: {
    marginBottom: 0,
  },
  centerStatus: {
    display: "flex",
    alignItems: "center",
  },
}));
