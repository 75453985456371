import React, { memo } from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const PaymentsIcon = ({ width, height, color }) => {
  return (
    <SvgIcon width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M19.1663 6.66634V14.9997C19.1663 15.9163 18.4163 16.6663 17.4997 16.6663H4.16634C3.70801 16.6663 3.33301 16.2913 3.33301 15.833C3.33301 15.3747 3.70801 14.9997 4.16634 14.9997H17.4997V6.66634C17.4997 6.20801 17.8747 5.83301 18.333 5.83301C18.7913 5.83301 19.1663 6.20801 19.1663 6.66634ZM3.33301 13.333C1.94967 13.333 0.833008 12.2163 0.833008 10.833V5.83301C0.833008 4.44967 1.94967 3.33301 3.33301 3.33301H13.333C14.7163 3.33301 15.833 4.44967 15.833 5.83301V11.6663C15.833 12.583 15.083 13.333 14.1663 13.333H3.33301ZM5.83301 8.33301C5.83301 9.71634 6.94967 10.833 8.33301 10.833C9.71634 10.833 10.833 9.71634 10.833 8.33301C10.833 6.94967 9.71634 5.83301 8.33301 5.83301C6.94967 5.83301 5.83301 6.94967 5.83301 8.33301Z"
        fill={color ? color : "#65B39D"}
      />
    </SvgIcon>
  );
};

PaymentsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
PaymentsIcon.defaultProps = {
  width: 20,
  height: 20,
};

export default memo(PaymentsIcon);
