import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Box, Typography, IconButton } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { AppConstant, LangConstant } from "const";
import { convertTimePreOrder, formatCurrency } from "utils";
import clsx from "clsx";

const SelectedITem = ({ data, onDecrease, onIncrease, onOpenTopping, onRemovedItem, classes }) => {
  const defaultClasses = useStyles();
  const { t: getLabel } = useTranslation();

  const [hoverMessageAnchor, setHoverMessageAnchor] = useState();
  const [isHover, setIsHover] = useState();

  const onMouseOver = () => {
    setIsHover(true);
    setHoverMessageAnchor(getLabel(LangConstant.TXT_DELETE));
  };

  const onMouseLeft = () => {
    setIsHover(false);
    setHoverMessageAnchor(null);
  };
  return (
    <Box className={clsx(defaultClasses.root, classes?.customSelectedItem)}>
      <Box className={defaultClasses.quantityItemBox}>
        <IconButton onClick={onDecrease} className={defaultClasses.creaseButton}>
          <Remove className={defaultClasses.decreaseIcon} />
        </IconButton>
        <Typography className={defaultClasses.numberProductTypo}>{data?.orderNumber}</Typography>
        <IconButton onClick={onIncrease} className={defaultClasses.creaseButton}>
          <Add className={defaultClasses.increaseIcon} />
        </IconButton>
      </Box>
      <Box onClick={onOpenTopping} className={defaultClasses.toppingBox}>
        <Box className={defaultClasses.contentBox}>
          <Typography className={defaultClasses.contentNameTypo}>{data?.name}</Typography>
          <Typography className={defaultClasses.contentDetailTypo}>
            {formatToppingList(data?.toppingList) || formatToppingList(data?.orderDetailsContentList)}
          </Typography>
        </Box>
        <Box className={defaultClasses.priceAndTimeBox}>
          <Box
            onMouseEnter={() => {
              onMouseOver();
            }}
            onMouseLeave={() => {
              onMouseLeft();
            }}
            onClick={event => {
              onRemovedItem(event);
            }}
          >
            <Typography
              className={
                isHover
                  ? clsx(defaultClasses.removeItem, defaultClasses.priceTypo)
                  : clsx("ellipsis", defaultClasses.priceTypo)
              }
            >
              {!isHover
                ? formatCurrency(getTotalPrice(data), getLabel(LangConstant.TXT_CURRENCY_COMMON))
                : hoverMessageAnchor}
            </Typography>
          </Box>

          <Typography className={defaultClasses.timeTypo}>
            {Boolean(data?.usingTime) ? convertTimePreOrder(data) : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

SelectedITem.propTypes = {
  data: PropTypes.object,
  onDecrease: PropTypes.func,
  onIncrease: PropTypes.func,
  onOpenTopping: PropTypes.func,
  onRemoveItem: PropTypes.func,
};
SelectedITem.defaultProps = {};

const formatToppingList = data => {
  let stringData = "";
  if (!data || data?.length === 0) {
    return "";
  } else if (data?.length === 1) {
    return `${data[0].name}`;
  }

  data.forEach((mapData, index) => {
    if (index === 0) {
      stringData += `${mapData.name}`;
    } else if (index === data.length - 1) {
      stringData += `, ${mapData.name}`;
    } else {
      stringData += `, ${mapData.name}`;
    }
  });
  return stringData;
};

const getTotalPrice = data => {
  if (data) {
    let orderNumber = data.orderNumber || 0;
    let itemPrice = data.priceTaxIn || 0;
    let totalItemPrice = itemPrice * orderNumber;

    if (data.type !== AppConstant.ITEM_TYPE.comboItem) {
      // calculate toppings price of item which isn't belong to Combo category
      let toppingList = data.isSelectedItem ? data.orderDetailsContentList : data.toppingList || [];
      toppingList.forEach(topping => {
        let priceTaxInItem = topping.priceTaxIn || 0;
        totalItemPrice += orderNumber * priceTaxInItem;
      });
    }

    return totalItemPrice;
  }
};

export default memo(SelectedITem);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: 66,
    width: "100%",
    border: "solid " + theme.palette.grey[200],
    borderWidth: "0.5px 0.5px 0",
  },
  quantityItemBox: {
    display: "flex",
    minWidth: 80,
    padding: "12px 9px 0",
    justifyContent: "space-between",
  },
  creaseButton: {
    width: 20,
    height: 20,
    padding: 0,
  },
  decreaseIcon: {
    width: 17,
    height: 17,
    backgroundColor: "#b3b5ba",
    borderRadius: "50%",
    color: theme.palette.white,
  },
  numberProductTypo: {
    minWidth: 26,
    textAlign: "center",
    fontSize: 13,
    lineHeight: 1.69,
    color: "#3e4045",
  },
  increaseIcon: {
    width: 17,
    height: 17,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    color: theme.palette.white,
  },
  toppingBox: {
    display: "flex",
    cursor: "pointer",
    width: "100%",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "9px 0",
  },
  contentNameTypo: {
    display: "inline",
    fontSize: 14,
    paddingRight: 3,
    lineHeight: 1.57,
  },
  contentDetailTypo: {
    display: "inline",
    paddingTop: 4,
    fontSize: 12,
    lineHeight: 1.67,
    color: "#7f838c",
  },
  priceAndTimeBox: {
    minWidth: 106,
    padding: "9px 16px 9px 0",
  },
  priceTypo: {
    height: 22,
    fontSize: 14,
    color: "#3e4045",
    lineHeight: 1.57,
    textAlign: "right",
  },
  timeTypo: {
    height: 20,
    fontSize: 12,
    color: theme.palette.text.link,
    lineHeight: 1.67,
    textAlign: "right",
  },
  removeItem: {
    color: theme.palette.primary.main,
  },
}));
