import { createReducer, createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getProductCategory: ["data"],
  getProductCategorySuccess: ["data"],
  getProductCategoryFailure: ["data"],

  createProductCategory: ["data"],
  createProductCategorySuccess: ["data"],
  createProductCategoryFailure: ["data"],

  updateProductCategory: ["data"],
  updateProductCategorySuccess: ["data"],
  updateProductCategoryFailure: ["data"],

  removeProductCategory: ["data"],
  removeProductCategorySuccess: ["data"],
  removeProductCategoryFailure: ["data"],

  getProductItem: ["data"],
  getProductItemSuccess: ["data"],
  getProductItemFailure: ["data"],

  getProductItemUidCategory: ["data"],
  getProductItemUidCategorySuccess: ["data"],
  getProductItemUidCategoryFailure: ["data"],

  createProductItem: ["data"],
  createProductItemSuccess: ["data"],
  createProductItemFailure: ["data"],

  updateProductItem: ["data"],
  updateProductItemSuccess: ["data"],
  updateProductItemFailure: ["data"],

  removeProductItem: ["data"],
  removeProductItemSuccess: ["data"],
  removeProductItemFailure: ["data"],

  //Details
  getDetails: ["data"],
  getDetailsSuccess: ["data"],
  getDetailsFailure: ["data"],

  getDetailsUid: ["data"],
  getDetailsUidSuccess: ["data"],
  getDetailsUidFailure: ["data"],

  createDetails: ["data"],
  createDetailsSuccess: ["data"],
  createDetailsFailure: ["data"],

  updateDetails: ["data"],
  updateDetailsSuccess: ["data"],
  updateDetailsFailure: ["data"],

  updateDetailsEditContent: ["data"],
  updateDetailsEditContentSuccess: ["data"],
  updateDetailsEditContentFailure: ["data"],

  removeDetails: ["data"],
  removeDetailsSuccess: ["data"],
  removeDetailsFailure: ["data"],

  getDetailsContent: ["data"],
  getDetailsContentSuccess: ["data"],
  getDetailsContentFailure: ["data"],

  getDetailsContentUid: ["data"],
  getDetailsContentUidSuccess: ["data"],
  getDetailsContentUidFailure: ["data"],

  createDetailsContent: ["data"],
  createDetailsContentSuccess: ["data"],
  createDetailsContentFailure: ["data"],

  updateDetailsContent: ["data"],
  updateDetailsContentSuccess: ["data"],
  updateDetailsContentFailure: ["data"],

  removeDetailsContent: ["data"],
  removeDetailsContentSuccess: ["data"],
  removeDetailsContentFailure: ["data"],

  resetMnProduct: ["data"],
  resetErrorMnProduct: ["data"],
});

export const ProductCategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  isGetSuccess: false,
  isDeleteSuccess: false,
  errorCreateCategory: null,
  errorUpdateCategory: null,
  isPutSuccess: false,
  isPostSuccess: false,
  data: null,
  createData: null,
  updateData: null,
  removeData: null,

  isFetchingItem: false,
  errorItem: null,
  errorCreateItem: null,
  errorUpdateItem: null,
  isGetSuccessItem: false,
  isGetUidSuccessItem: false,
  isPostSuccessItem: false,
  isDeleteSuccessItem: false,
  isPutSuccessItem: false,
  dataItem: null,
  createDataItem: null,
  updateDataItem: null,
  removeDataItem: null,

  isFetchingItemUidCategory: false,
  errorItemUidCategory: null,
  isGetSuccessItemUidCategory: false,
  dataItemUidCategory: null,
  createDataItemUidCategory: null,
  updateDataItemUidCategory: null,
  removeDataItemUidCategory: null,

  //Details
  isFetchingDetails: false,
  errorDetails: null,
  errorUpdateDetails: null,
  errorCreateDetails: null,
  isGetDetailsSuccess: false,
  isPostDetailsSuccess: false,
  isPutDetailsSuccess: false,
  isPutDetailsEditContentSuccess: false,
  isDeleteDetailsSuccess: false,
  dataDetails: null,
  createDataDetails: null,
  updateDataDetails: null,
  removeDataDetails: null,

  isFetchingDetailsUid: false,
  dataDetailsUid: null,

  isFetchingDetailsContent: false,
  errorDetailsContent: null,
  errorUpdateDetailsContent: null,
  errorCreateDetailsContent: null,
  isGetDetailsContentSuccess: false,
  isPostDetailsContentSuccess: false,
  isPutDetailsContentSuccess: false,
  isDeleteDetailsContentSuccess: false,
  dataDetailsContent: null,
  createDataDetailsContent: null,
  updateDataDetailsContent: null,
  removeDataDetailsContent: null,

  isFetchingDetailsContentUid: false,
  isSuccessDetailsContentUid: false,
  dataDetailsContentUid: null,
};

/* ------------- Reducers Get Product category ------------- */
export const getProductCategory = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

export const getProductCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isGetSuccess: true,
    data: action.data,
  };
};

export const getProductCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: false,
  isGetSuccess: false,
  error: action.error,
});

/* ------------- Reducers Create Service category ------------- */
export const createProductCategory = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
});

export const createProductCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreateFetching: false,
    isPostSuccess: true,
    createData: action.data,
  };
};

export const createProductCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isPostSuccess: false,
  errorCreateCategory: action?.data?.message,
});

/* ------------- Reducers Update Service category ------------- */
export const updateProductCategory = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updateProductCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isPutSuccess: true,
    updateData: action.data,
  };
};

export const updateProductCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isPutSuccess: false,
  errorUpdateCategory: action?.data?.message,
});

/* ------------- Reducers Remove Service category ------------- */
export const removeProductCategory = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetching: true,
});

export const removeProductCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isRemoveFetching: false,
    isDeleteSuccess: true,
    removeData: action.data,
  };
};

export const removeProductCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetching: false,
  isDeleteSuccess: false,
  errorRemove: action.error,
});

// product item
export const getProductItem = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingItem: true,
});

export const getProductItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingItem: false,
    isGetSuccessItem: true,
    dataItem: action.data,
  };
};

export const getProductItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingItem: false,
  isGetSuccessItem: false,
  error: action.error,
});

export const getProductItemUidCategory = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingItemUidCategory: true,
});

export const getProductItemUidCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingItemUidCategory: false,
    isGetSuccessItemUidCategory: true,
    dataItemUidCategory: action.data,
  };
};

export const getProductItemUidCategoryFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingItemUidCategory: false,
  isGetSuccessItemUidCategory: false,
  error: action.error,
});

/* ------------- Reducers Create Product Item ------------- */
export const createProductItem = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
  errorCreateItem: null,
});

export const createProductItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreateFetching: false,
    isPostSuccessItem: true,
    createData: action.data,
  };
};

export const createProductItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isPostSuccessItem: false,
  errorCreateItem: action?.data?.message,
});

/* ------------- Reducers Update Product Item ------------- */
export const updateProductItem = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
  errorUpdateItem: null,
});

export const updateProductItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isPutSuccessItem: true,
    updateData: action.data,
  };
};

export const updateProductItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isPutSuccessItem: false,
  errorUpdateItem: action?.data?.message,
});

/* ------------- Reducers Remove Product Item ------------- */
export const removeProductItem = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetchingItem: true,
});

export const removeProductItemSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isRemoveFetchingItem: false,
    isDeleteSuccessItem: true,
    removeData: action.data,
  };
};

export const removeProductItemFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetchingItem: false,
  isDeleteSuccessItem: false,
  errorRemove: action.error,
});

// details
export const getDetails = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingDetails: true,
});

export const getDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingDetails: false,
    isGetDetailsSuccess: true,
    dataDetails: action.data,
  };
};

export const getDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingDetails: false,
  isGetDetailsSuccess: false,
  error: action.error,
});

export const getDetailsUid = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingDetailsUid: true,
});

export const getDetailsUidSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingDetailsUid: false,
    isSuccessDetailsUid: true,
    dataDetailsUid: action.data,
  };
};

export const getDetailsUidFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingDetailsUid: false,
  isSuccessDetailsUid: false,
  error: action.error,
});

/* ------------- Reducers Create Details ------------- */
export const createDetails = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
});

export const createDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreateFetching: false,
    isPostDetailsSuccess: true,
    createData: action.data,
  };
};

export const createDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isPostDetailsSuccess: false,
  errorCreateDetails: action?.data?.message,
});

/* ------------- Reducers Update Details ------------- */
export const updateDetails = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updateDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isPutDetailsSuccess: true,
    updateData: action.data,
  };
};

export const updateDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isPutDetailsSuccess: false,
  errorUpdateDetails: action?.data?.message,
});

export const updateDetailsEditContent = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updateDetailsEditContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isPutDetailsEditContentSuccess: true,
    updateData: action.data,
  };
};

export const updateDetailsEditContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isPutDetailsEditContentSuccess: false,
  errorUpdate: action.error,
});

/* ------------- Reducers Remove Details ------------- */
export const removeDetails = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetchingDetails: true,
});

export const removeDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isRemoveFetchingDetails: false,
    isDeleteDetailsSuccess: true,
    removeData: action.data,
  };
};

export const removeDetailsFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetchingDetails: false,
  isDeleteDetailsSuccess: false,
  errorRemove: action.error,
});

// Details content
export const getDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingDetailsContent: true,
});

export const getDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingDetailsContent: false,
    isGetDetailsContentSuccess: true,
    dataDetailsContent: action.data,
  };
};

export const getDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingDetailsContent: false,
  isGetDetailsContentSuccess: false,
  error: action.error,
});

export const getDetailsContentUid = (state = INITIAL_STATE) => ({
  ...state,
  isFetchingDetailsContent: true,
});

export const getDetailsContentUidSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetchingDetailsContent: false,
    isSuccessDetailsContentUid: true,
    dataDetailsContentUid: action.data,
  };
};

export const getDetailsContentUidFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetchingDetailsContent: false,
  isSuccessDetailsContentUid: false,
  error: action.error,
});

/* ------------- Reducers Create DetailsContent ------------- */
export const createDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isCreateFetching: true,
});

export const createDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreateFetching: false,
    isPostDetailsContentSuccess: true,
    createData: action.data,
  };
};

export const createDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isCreateFetching: false,
  isPostDetailsContentSuccess: false,
  errorCreateDetailsContent: action?.data?.message,
});

/* ------------- Reducers Update DetailsContent ------------- */
export const updateDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isUpdateFetching: true,
});

export const updateDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdateFetching: false,
    isPutDetailsContentSuccess: true,
    errorUpdateDetailsContent: action?.data?.message,
  };
};

export const updateDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isUpdateFetching: false,
  isPutDetailsContentSuccess: false,
  errorUpdate: action.error,
});

/* ------------- Reducers Remove DetailsContent ------------- */
export const removeDetailsContent = (state = INITIAL_STATE) => ({
  ...state,
  isRemoveFetching: true,
});

export const removeDetailsContentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isRemoveFetchingDetailsContent: false,
    isDeleteDetailsContentSuccess: true,
    removeData: action.data,
  };
};

export const removeDetailsContentFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  isRemoveFetchingDetailsContent: false,
  isDeleteDetailsContentSuccess: false,
  errorRemove: action.error,
});

export const reset = (state = INITIAL_STATE) => ({
  ...state,
  isGetSuccess: false,
  isDeleteSuccess: false,
  isPutSuccess: false,
  isPostSuccess: false,
  isGetSuccessItem: false,
  isGetUidSuccessItem: false,
  isPostSuccessItem: false,
  isDeleteSuccessItem: false,
  isPutSuccessItem: false,
  isGetDetailsSuccess: false,
  isPostDetailsSuccess: false,
  isPutDetailsSuccess: false,
  isPutDetailsEditContentSuccess: false,
  isDeleteDetailsSuccess: false,
  isGetDetailsContentSuccess: false,
  isPostDetailsContentSuccess: false,
  isPutDetailsContentSuccess: false,
  isDeleteDetailsContentSuccess: false,
  isGetSuccessItemUidCategory: false,
});

export const resetError = (state = INITIAL_STATE) => ({
  ...state,
  errorUpdateItem: null,
  errorCreateItem: null,
  errorCreateCategory: null,
  errorUpdateCategory: null,
  errorUpdateDetails: null,
  errorCreateDetails: null,
  errorUpdateDetailsContent: null,
  errorCreateDetailsContent: null,
});

/* ------------- Mapping ------------- */
export const HANDLERS = {
  [Types.GET_PRODUCT_CATEGORY]: getProductCategory,
  [Types.GET_PRODUCT_CATEGORY_SUCCESS]: getProductCategorySuccess,
  [Types.GET_PRODUCT_CATEGORY_FAILURE]: getProductCategoryFailure,

  [Types.CREATE_PRODUCT_CATEGORY]: createProductCategory,
  [Types.CREATE_PRODUCT_CATEGORY_SUCCESS]: createProductCategorySuccess,
  [Types.CREATE_PRODUCT_CATEGORY_FAILURE]: createProductCategoryFailure,

  [Types.UPDATE_PRODUCT_CATEGORY]: updateProductCategory,
  [Types.UPDATE_PRODUCT_CATEGORY_SUCCESS]: updateProductCategorySuccess,
  [Types.UPDATE_PRODUCT_CATEGORY_FAILURE]: updateProductCategoryFailure,

  [Types.REMOVE_PRODUCT_CATEGORY]: removeProductCategory,
  [Types.REMOVE_PRODUCT_CATEGORY_SUCCESS]: removeProductCategorySuccess,
  [Types.REMOVE_PRODUCT_CATEGORY_FAILURE]: removeProductCategoryFailure,

  [Types.GET_PRODUCT_ITEM]: getProductItem,
  [Types.GET_PRODUCT_ITEM_SUCCESS]: getProductItemSuccess,
  [Types.GET_PRODUCT_ITEM_FAILURE]: getProductItemFailure,

  [Types.GET_PRODUCT_ITEM_UID_CATEGORY]: getProductItemUidCategory,
  [Types.GET_PRODUCT_ITEM_UID_CATEGORY_SUCCESS]: getProductItemUidCategorySuccess,
  [Types.GET_PRODUCT_ITEM_UID_CATEGORY_FAILURE]: getProductItemUidCategoryFailure,

  [Types.CREATE_PRODUCT_ITEM]: createProductItem,
  [Types.CREATE_PRODUCT_ITEM_SUCCESS]: createProductItemSuccess,
  [Types.CREATE_PRODUCT_ITEM_FAILURE]: createProductItemFailure,

  [Types.UPDATE_PRODUCT_ITEM]: updateProductItem,
  [Types.UPDATE_PRODUCT_ITEM_SUCCESS]: updateProductItemSuccess,
  [Types.UPDATE_PRODUCT_ITEM_FAILURE]: updateProductItemFailure,

  [Types.REMOVE_PRODUCT_ITEM]: removeProductItem,
  [Types.REMOVE_PRODUCT_ITEM_SUCCESS]: removeProductItemSuccess,
  [Types.REMOVE_PRODUCT_ITEM_FAILURE]: removeProductItemFailure,

  // Details
  [Types.GET_DETAILS]: getDetails,
  [Types.GET_DETAILS_SUCCESS]: getDetailsSuccess,
  [Types.GET_DETAILS_FAILURE]: getDetailsFailure,

  [Types.GET_DETAILS_UID]: getDetailsUid,
  [Types.GET_DETAILS_UID_SUCCESS]: getDetailsUidSuccess,
  [Types.GET_DETAILS_UID_FAILURE]: getDetailsUidFailure,

  [Types.CREATE_DETAILS]: createDetails,
  [Types.CREATE_DETAILS_SUCCESS]: createDetailsSuccess,
  [Types.CREATE_DETAILS_FAILURE]: createDetailsFailure,

  [Types.UPDATE_DETAILS]: updateDetails,
  [Types.UPDATE_DETAILS_SUCCESS]: updateDetailsSuccess,
  [Types.UPDATE_DETAILS_FAILURE]: updateDetailsFailure,

  [Types.UPDATE_DETAILS_EDIT_CONTENT]: updateDetailsEditContent,
  [Types.UPDATE_DETAILS_EDIT_CONTENT_SUCCESS]: updateDetailsEditContentSuccess,
  [Types.UPDATE_DETAILS_EDIT_CONTENT_FAILURE]: updateDetailsEditContentFailure,

  [Types.REMOVE_DETAILS]: removeDetails,
  [Types.REMOVE_DETAILS_SUCCESS]: removeDetailsSuccess,
  [Types.REMOVE_DETAILS_FAILURE]: removeDetailsFailure,

  [Types.GET_DETAILS_CONTENT]: getDetailsContent,
  [Types.GET_DETAILS_CONTENT_SUCCESS]: getDetailsContentSuccess,
  [Types.GET_DETAILS_CONTENT_FAILURE]: getDetailsContentFailure,

  [Types.GET_DETAILS_CONTENT_UID]: getDetailsContentUid,
  [Types.GET_DETAILS_CONTENT_UID_SUCCESS]: getDetailsContentUidSuccess,
  [Types.GET_DETAILS_CONTENT_UID_FAILURE]: getDetailsContentUidFailure,

  [Types.CREATE_DETAILS_CONTENT]: createDetailsContent,
  [Types.CREATE_DETAILS_CONTENT_SUCCESS]: createDetailsContentSuccess,
  [Types.CREATE_DETAILS_CONTENT_FAILURE]: createDetailsContentFailure,

  [Types.UPDATE_DETAILS_CONTENT]: updateDetailsContent,
  [Types.UPDATE_DETAILS_CONTENT_SUCCESS]: updateDetailsContentSuccess,
  [Types.UPDATE_DETAILS_CONTENT_FAILURE]: updateDetailsContentFailure,

  [Types.REMOVE_DETAILS_CONTENT]: removeDetailsContent,
  [Types.REMOVE_DETAILS_CONTENT_SUCCESS]: removeDetailsContentSuccess,
  [Types.REMOVE_DETAILS_CONTENT_FAILURE]: removeDetailsContentFailure,

  [Types.RESET_MN_PRODUCT]: reset,
  [Types.RESET_ERROR_MN_PRODUCT]: resetError,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, HANDLERS);
